import React from 'react'
import { observer } from 'mobx-react'
import { cartContainer, checkoutContainer } from 'src/models'
import { CommonForm, storePickupState } from 'src/views/components'
import * as yup from 'yup'
import { getEmailSchema, getNameSchema } from 'src/deps'
import { Button } from 'react-bootstrap'
import { getLocalStorage, trackEnhancedCheckout } from 'src/utils'

function pickupSchema() {
  const pickupModel = Object.assign(
    {},
    getEmailSchema({ inputName: 'email' }),
    getNameSchema({ inputName: 'firstName' }),
    getNameSchema({ inputName: 'lastName', isLastName: true })
  )
  return yup.object(pickupModel)
}

const renderPickupFormInput = () => {
  const fieldConfig = [
    [
      {
        name: 'email',
        labelKey: 'email',
      },
      {
        name: 'firstName',
        labelKey: 'firstName',
      },
      {
        name: 'lastName',
        labelKey: 'lastName',
      },
    ],
  ]
  return fieldConfig
}

@observer
class PickupAccordion extends React.Component {
  constructor(props) {
    super(props)
  }

  renderSubmitButton = () => {
    return (
      <div className="justify-content-end no-gutters row">
        <Button
          type="submit"
          variant="primary"
          className="rounded-0 shipping-continue mt-3 px-5 py-2">
          Continue
        </Button>
      </div>
    )
  }

  renderShippingAddress = async () => {
    const storeProducts = Object.assign(
      getLocalStorage('storeProducts') || {},
      storePickupState.storePickupProducts
    )
    const { title } = this.props
    const products = cartContainer?.cartResponse?.items || []
    trackEnhancedCheckout({
      type: 'checkout',
      action: { step: '8', option: title },
      products: products,
    })

    const storeProductKeys = Object.keys(storeProducts) || []

    const shouldMakeApi =
      storeProducts &&
      storeProductKeys.length === cartContainer.cartResponse?.items?.length

    if (shouldMakeApi) {
      await checkoutContainer.setShippingAddress()
    }

    this.props.onContinue()
  }

  render() {
    return (
      <CommonForm
        schema={pickupSchema()}
        formTypeJson={renderPickupFormInput()}
        hasInlineSubmitButton={false}
        SubmitButtonProps={this.renderSubmitButton}
        onSubmit={this.renderShippingAddress}
      />
    )
  }
}

export { PickupAccordion }
export default PickupAccordion
