import React, { useState, useEffect } from 'react'
import { FormCheck } from 'react-bootstrap'
import { FormInputLabels } from 'src/deps'
import { APPConfig } from 'config/appConfig'
import { IS_BROWSER } from 'src/utils'
import { getSubscriptionDate } from 'src/deps/NuskinSubscriptionDeps'
import { i18nTranslate } from 'src/utils'
import './styles.scss'

function SubscriptionProductInfo(props) {
  /**
   * @todo
   * name change required
   */
  const [checkboxValue, setCheckboxValue] = useState({
    first: false,
    second: false,
  })

  const [links, setLinks] = useState({})
  const [lastOrderProcessedDate, setLastProcessingDate] = useState('')
  const [nextOrderProcessingDate, setNextOrderProcessingDate] = useState('')

  const termsAndLinksCTA =
    links.retailCustomerLinks?.subscriptionAgreement || '#'

  const onCheckboxChange = (e, name) => {
    checkboxValue[name] = e.target.checked
    setCheckboxValue({ ...checkboxValue })
  }

  const navigateToTermsAndConditions = e => {
    e.preventDefault()
    if (IS_BROWSER) {
      window.open(termsAndLinksCTA)
    }
  }
  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      navigateToTermsAndConditions(e)
    }
  }

  useEffect(async () => {
    setLinks(new FormInputLabels())

    const processedDate = await getSubscriptionDate()
    const date = processedDate?.orderProcessDay || ''
    // let nextDate = [29, 30, 31].indexOf(date) > -1 ? 28 : date
    const defaultProcessingDate =
      APPConfig?.getAppConfig()?.defaultProcessingDate || {}

    let nextDate = date
    if (defaultProcessingDate?.[date]) {
      nextDate = defaultProcessingDate[date] || ''
    }
    let ordinalDate = date => {
      return (
        date +
        (date % 10 == 1 && date != 11
          ? 'st'
          : date % 10 == 2 && date != 12
          ? 'nd'
          : date % 10 == 3 && date != 13
          ? 'rd'
          : 'th')
      )
    }
    setLastProcessingDate(processedDate?.orderProcessDate)
    setNextOrderProcessingDate(ordinalDate(nextDate))
  }, [])

  useEffect(() => {
    /**
     * @todo
     * the checkbox value required to be passed
     * to outside of the component
     */
    props.onCheckboxStatusChange(checkboxValue)
  }, [checkboxValue])

  useEffect(() => {
    setCheckboxValue({
      first: false,
      second: false,
    })
  }, [props.isOneTimeFrequency])

  const isToShowMultipleItemsText = props?.isToShowMultipleItemsText || false
  const itemTotalText = i18nTranslate(
    'subscriptionModal.itemTotalText',
    'Subtotal does not include shipping, taxes or items already on subscription (see Subscription Dashboard for monthly totals).'
  )
  const subscriptionProcessText = i18nTranslate(
    'subscriptionModal.subscriptionProcessText',
    'You may change or cancel your Subscriptions at any time through your Subscription Dashboard (processing times apply).'
  )

  const agreementCheckboxText = i18nTranslate(
    'subscriptionModal.agreementCheckboxText',
    'I agree the credit card saved on my Subscription will be charged each processing date'
  )

  const termsAndConditionCheckboxText = i18nTranslate(
    'subscriptionModal.termsAndConditionCheckboxText',
    'I have read and agree to the'
  )

  const termsAndConditionCheckboxLink = i18nTranslate(
    'subscriptionModal.termsAndConditionLink',
    'Subscription Terms and Conditions'
  )

  const subscriptionProcessOneTimeText = i18nTranslate(
    'subscriptionModal.itemOneTimeText',
    'One-time items will automatically drop off your subscription after processing.'
  )
  const subscriptionProcessOneTimeTextBold = i18nTranslate(
    'subscriptionModal.itemOneTimeTextBold',
    'Shipment must include subscribed items in order to process.'
  )

  if (props.isOneTimeFrequency) {
    return (
      <div className="ssm-subscription-info">
        <p className="h6 text-left font-weight-bold">
          {isToShowMultipleItemsText
            ? i18nTranslate(
                'subscriptionModal.itemsProcessText',
                'These items will process'
              )
            : i18nTranslate(
                'subscriptionModal.itemProcessText',
                'This item will process'
              )}{' '}
          {lastOrderProcessedDate}
          {'.'}
        </p>
        <p className="text-left mb-2">{itemTotalText}</p>
        <p className="text-left">
          {subscriptionProcessOneTimeText}{' '}
          <strong>{subscriptionProcessOneTimeTextBold} </strong>
        </p>

        <FormCheck className="mt-3 popup-sub">
          <FormCheck.Input
            checked={checkboxValue.first}
            onChange={e => onCheckboxChange(e, 'first')}
            type="checkbox"
            className="overlay-checkbox"
            id="agreement_checkbox"
          />
          <FormCheck.Label
            className="cursor-default"
            htmlFor="agreement_checkbox">
            {agreementCheckboxText}
          </FormCheck.Label>
        </FormCheck>
      </div>
    )
  }

  return (
    <div className="ssm-subscription-info">
      <p className="h6 text-left font-weight-bold">
        {isToShowMultipleItemsText
          ? i18nTranslate(
              'subscriptionModal.itemsProcessText',
              'These items will process'
            )
          : i18nTranslate(
              'subscriptionModal.itemProcessText',
              'This item will process'
            )}{' '}
        {lastOrderProcessedDate}{' '}
        {i18nTranslate('subscriptionModal.andOnText', 'and on the')}{' '}
        {nextOrderProcessingDate}{' '}
        {i18nTranslate(
          'subscriptionModal.futureDayText',
          'day of future months, based on chosen frequency.'
        )}
      </p>
      <p className="text-left mb-2">{itemTotalText}</p>
      <p className="text-left">{subscriptionProcessText}</p>
      <FormCheck className="mt-3 popup-sub">
        <FormCheck.Input
          checked={checkboxValue.first}
          onChange={e => onCheckboxChange(e, 'first')}
          type="checkbox"
          className="overlay-checkbox"
          id="agreement_checkbox"
        />
        <FormCheck.Label
          className="cursor-default"
          htmlFor="agreement_checkbox">
          {agreementCheckboxText}
        </FormCheck.Label>
      </FormCheck>
      <FormCheck className="mt-3 popup-sub">
        <FormCheck.Input
          checked={checkboxValue.second}
          onChange={e => onCheckboxChange(e, 'second')}
          type="checkbox"
          className="overlay-checkbox second"
          id="terms_checkbox"
        />
        <FormCheck.Label className="cursor-default" htmlFor="terms_checkbox">
          {termsAndConditionCheckboxText}{' '}
          <div
            className="ssm-terms-and-conditions-link"
            role="link"
            tabIndex="0"
            onClick={navigateToTermsAndConditions}
            onKeyPress={navigateToTermsAndConditions}>
            <u>{termsAndConditionCheckboxLink}</u>
          </div>
        </FormCheck.Label>
      </FormCheck>
    </div>
  )
}

export { SubscriptionProductInfo }
export default SubscriptionProductInfo
