import { CommonContainer } from 'src/models/Common'
import { observable } from 'mobx'
import { pageNames } from 'src/routes/pathParams'
import {
  i18nTranslate,
  IS_BROWSER,
  trackErrorInInstana,
  getLocalStorage,
} from 'src/utils'
import {
  cartContainer,
  customerContainer,
  checkoutContainer,
  sessionContainer,
} from 'src/models'
import {
  toastState,
  klarnaOverlayState,
  overlayState,
} from 'src/views/components'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'
import { APPConfig } from 'config/appConfig'

class KlarnaContainer extends CommonContainer {
  @observable isKlarnaTransactionStatusApiInProgress = false
  @observable isKlarnaSubmitOrderApiInProgress = false
  @observable isKlarnaBroadcastMessageReceived = false
  @observable isklarnaBroadCastInstanceCreated = false

  @observable klarnaWindowURL = null
  @observable latestKlarnaStatusCode = {}
  @observable isCloseWindowFromCallback = false
  @observable klarnaBrowserPopupBlock = false
  klarnaTransactionStatusRefreshInterval = null
  submitOrderProps = {}
  windowCloseTimer = null
  klarnaStatusRetryCount = 0
  maxRetryForKlarnaStatus = 3
  klarnaBrowserPopupBlockCount = 0
  maxRetryKlarnaBrowserPopupBlock = 1

  handleLoading(isLoading) {
    if (isLoading) {
      overlayState.showLoader()
      document.body.classList.add('no-scroll')
    } else {
      overlayState.hideLoader()
      document.body.classList.remove('no-scroll')
    }
  }

  handleKlarnaLoading(isLoading) {
    if (isLoading) {
      klarnaOverlayState.showKlarnaLoader()
      document.body.classList.add('no-scroll')
    } else {
      klarnaOverlayState.hideKlarnaLoader()
      document.body.classList.remove('no-scroll')
    }
  }

  handleKlarnaPaymentRedirection = async (options = {}) => {
    const { klarnaStatus = '', successCodeFrom = '' } = options

    if (!this.isKlarnaSubmitOrderApiInProgress) {
      const userId = customerContainer?.userId || ''
      // KLARNA Redirection after Payment - Success Scenario
      if (klarnaStatus === 'success') {
        this.isKlarnaSubmitOrderApiInProgress = true
        this.clearKlarnaTransactionStatusTimer()
        this.isklarnaBroadCastInstanceCreated = false
        this.handleLoading(true)
        let cartResponse = (await cartContainer.viewCart()) || {}
        let cartCount = cartResponse?.value?.count || 0
        if (cartCount > 0) {
          trackErrorInInstana({
            errorReport: 'Klarna - Initiating Submit Order API',
            errorData: klarnaStatus,
          })
          await this.handleKlarnaSubmitAfterRedirection()
        } else {
          trackErrorInInstana({
            errorReport: `Klarna - As CartCount is Zero, Navigating back to HomePage for User ${userId}`,
            errorData: cartContainer.cartCount || 0,
          })
          this.handleLoading(false)
          this.submitOrderProps.history?.replace(`${pageNames.home}`)
        }
      }

      // KLARNA Redirection after Payment - Failure Scenario
      if (klarnaStatus === 'failure') {
        const klarnaPaymentStatus = `Klarna Payment Failure for User ${userId}`
        const klarnaFailureMessage = i18nTranslate(
          'checkout.klarnaFailureMessage',
          "We are sorry, your Klarna payment can't successful. Please select another payment option and try again."
        )

        this.handleKlarnaFailureAfterRedirection(
          klarnaPaymentStatus,
          klarnaFailureMessage
        )
      }

      // KLARNA Redirection after Payment - Cancel Scenario
      if (klarnaStatus === 'cancel') {
        const klarnaPaymentStatus = `Klarna Payment Cancelled for User ${userId}`
        const klarnaFailureMessage = i18nTranslate(
          'checkout.klarnaCancelMessage',
          'Your Klarna payment request was successfully cancelled. Please select a payment option and try again.'
        )

        this.handleKlarnaFailureAfterRedirection(
          klarnaPaymentStatus,
          klarnaFailureMessage
        )
      }
    }
  }

  clearKlarnaState = () => {
    checkoutContainer.isKlarnaPaymentAdded = false
    //this.isKlarnaSubmitOrderApiInProgress = false
    this.isKlarnaBroadcastMessageReceived = false
    checkoutContainer.isToInitiateKlarnaPayment = false
    this.isklarnaBroadCastInstanceCreated = false
    this.klarnaBrowserPopupBlock = false
    this.isCloseWindowFromCallback = false
  }

  handleKlarnaSubmitAfterRedirection = async () => {
    this.clearKlarnaTransactionStatusTimer()
    checkoutContainer.isKlarnaPaymentAdded = true
    const userId = customerContainer?.userId || ''
    const submitOrderResponse = await checkoutContainer.submitOrder()
    if (checkoutContainer.isSuccessResponse(submitOrderResponse)) {
      trackErrorInInstana({
        errorReport: `Klarna Submit Order API - Success for User ${userId}`,
        errorData: submitOrderResponse,
      })
      this.handleLoading(false)

      toastState.setToastMessage(
        i18nTranslate('order.submitSuccess', 'Your order has been submitted.'),
        true
      )
      this.clearKlarnaState()
      let isNativeApp = checkIsNativeApp() || false
      if (isNativeApp) {
        this.submitOrderProps.history?.push(
          `${pageNames.orderConfirmation}?isNativeApp=true`
        )
      } else {
        this.submitOrderProps.history?.push(`${pageNames.orderConfirmation}`)
      }
      await cartContainer.viewCart()
    } else {
      let errorMessage = ''
      if (submitOrderResponse?.code == 'ECKLPY0001') {
        errorMessage = i18nTranslate(
          'checkout.paymentGeneralError',
          'Sorry, we were unable to process your payment. Please try again.'
        )
      } else {
        errorMessage =
          submitOrderResponse?.responseMessage ||
          submitOrderResponse?.message ||
          submitOrderResponse?.errorMessage ||
          i18nTranslate(
            'order.generalError',
            'Sorry,we are unable to place order. Please try again.'
          )
      }
      this.isKlarnaSubmitOrderApiInProgress = false
      this.clearKlarnaState()
      toastState.setToastMessage(errorMessage)
      const sessionId = getLocalStorage('sessionId')
      trackErrorInInstana({
        errorReport: `Klarna Session - ${sessionId} - ${userId}`,
        errorData: { errorMessage },
      })
      trackErrorInInstana({
        errorReport: `Klarna Submit Order API - Failed for User ${userId}`,
        errorData: { errorMessage },
      })
      this.handleLoading(false)
      checkoutContainer.activePath = 'payment'
    }
  }

  handleKlarnaFailureAfterRedirection = (
    klarnaPaymentStatus,
    klarnaFailureMessage
  ) => {
    this.isKlarnaSubmitOrderApiInProgress = false
    checkoutContainer.activePath = 'payment'
    trackErrorInInstana({
      errorReport: klarnaPaymentStatus,
      errorData: klarnaFailureMessage,
    })
    checkoutContainer.isKlarnaPaymentAdded = false
    toastState.setToastMessage(klarnaFailureMessage)
    this.handleKlarnaLoading(false)
  }

  handleKlarnaPaymentWindowCancel = () => {
    klarnaContainer.klarnaWindowURL && this.closeKlarnaWindow()

    checkoutContainer.activePath = 'payment'
    const klarnaFailureMessage = i18nTranslate(
      'checkout.klarnaCancelMessage',
      'Your Klarna payment request was successfully cancelled. Please select a payment option and try again.'
    )
    toastState.setToastMessage(klarnaFailureMessage)
    this.handleKlarnaLoading(false)
    this.handleLoading(false)
    this.clearKlarnaState()
    this.isKlarnaSubmitOrderApiInProgress = false
  }

  closeKlarnaWindow = () => {
    this.isCloseWindowFromCallback = false
    if (
      klarnaContainer.klarnaWindowURL &&
      !klarnaContainer.klarnaWindowURL?.closed
    ) {
      this.isCloseWindowFromCallback = true
      klarnaContainer.klarnaWindowURL?.close?.()
    }
  }

  clearKlarnaTransactionStatusTimer = () => {
    if (this.klarnaTransactionStatusRefreshInterval) {
      clearInterval(this.klarnaTransactionStatusRefreshInterval)
      this.klarnaTransactionStatusRefreshInterval = null
    }
  }

  getKlarnaTransactionStatus = async (options = {}) => {
    const { isFromBroadcast = false, isFromModalClose = false } = options

    if (!this.isKlarnaTransactionStatusApiInProgress) {
      this.isKlarnaTransactionStatusApiInProgress = true
      const confirmationCode =
        checkoutContainer.klarnaInitiateResponse?.transaction?.properties?.find(
          property => property?.name === 'confirmationCode'
        )?.value || ''
      const currencyCode = cartContainer?.cartResponse?.currencyCode || 'USD'
      let klarnaTransactionResponse =
        await checkoutContainer.initiateKlarnaTransactionStatusAPI({
          confirmationCode,
          currencyCode,
        })
      if (checkoutContainer.isSuccessResponse(klarnaTransactionResponse)) {
        this.isKlarnaTransactionStatusApiInProgress = false
        trackErrorInInstana({
          errorReport: 'Klarna - Transaction Status API Response',
          errorData: klarnaTransactionResponse,
        })
        this.latestKlarnaStatusCode =
          klarnaTransactionResponse?.code?.toLowerCase() || ''

        if (!isFromBroadcast && !isFromModalClose) {
          if (this.latestKlarnaStatusCode === 'success') {
            trackErrorInInstana({
              errorReport: 'Klarna - Transaction Status Success',
              errorData: klarnaTransactionResponse,
            })

            this.handleKlarnaPaymentRedirection({
              klarnaStatus: 'success',
              successCodeFrom: 'api',
            })
            this.closeKlarnaWindow()
          } else if (
            this.latestKlarnaStatusCode === 'fail' ||
            this.latestKlarnaStatusCode === 'cancelled'
          ) {
            trackErrorInInstana({
              errorReport: 'Klarna - Transaction Status Fail',
              errorData: klarnaTransactionResponse,
            })
            checkoutContainer.activePath = 'payment'
            this.closeKlarnaWindow()
          }
        } else {
          this.clearKlarnaTransactionStatusTimer()
        }
        return klarnaTransactionResponse
      } else {
        //Set klarna status failed count to reach maxRetryForKlarnaStatus, once reached cancel the payment
        this.isKlarnaTransactionStatusApiInProgress = false
        await klarnaContainer.retryKlarnaStatusCall(klarnaTransactionResponse)
      }
    }
  }

  checkForKlarnaTransactionStatus = async () => {
    const intervalTime =
      Number(APPConfig.getAppConfig().klarnaTransactionStatusTimer) || 300
    if (!this.klarnaTransactionStatusRefreshInterval) {
      this.clearKlarnaTransactionStatusTimer()
      this.klarnaTransactionStatusRefreshInterval = setInterval(async () => {
        await this.getKlarnaTransactionStatus()
      }, intervalTime)
    }
  }

  clearKlarnaWindowCloseTimer = () => {
    if (this.windowCloseTimer) {
      clearInterval(this.windowCloseTimer)
      this.windowCloseTimer = null
    }
  }

  checkChildWindow = async () => {
    if (klarnaContainer.klarnaWindowURL?.closed) {
      if (!this.isKlarnaBroadcastMessageReceived) {
        const klarnaStatusResponse = await this.getKlarnaTransactionStatus({
          isFromModalClose: true,
        })

        if (checkoutContainer.isSuccessResponse(klarnaStatusResponse)) {
          const statusCode = klarnaStatusResponse?.code?.toLowerCase() || ''
          if (statusCode === 'success') {
            let cartResponse = (await cartContainer.viewCart()) || {}
            let cartCount = cartResponse?.value?.count || 0
            if (cartCount > 0) {
              await this.handleKlarnaPaymentRedirection({
                klarnaStatus: statusCode,
                successCodeFrom: 'modalClose',
              })
            }
          }

          this.resetKlarnaPayment()
          this.manualCloseReset(statusCode)
        } else {
          this.resetKlarnaPayment()
        }
      } else {
        this.resetKlarnaPayment()
      }
    }
  }

  resetKlarnaPayment = async () => {
    this.clearKlarnaWindowCloseTimer()
    this.clearKlarnaTransactionStatusTimer()
    this.handleKlarnaLoading(false) //Hiding klarna timer mask after checking with status call
    this.handleLoading(false)
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
    window.removeEventListener('unload', this.handleUnloadEvent)
  }

  manualCloseReset = (status = '') => {
    if (status !== 'success') {
      trackErrorInInstana({
        errorReport: 'Klarna Modal Force Close Callback - Reset',
        errorData: this.isCloseWindowFromCallback,
      })
      if (!this.isCloseWindowFromCallback) {
        //Force close of klarna window
        trackErrorInInstana({
          errorReport: 'Klarna Modal Force Closed',
          errorData: klarnaContainer.klarnaWindowURL,
        })
        const klarnaFailureMessage = i18nTranslate(
          'checkout.klarnaCancelMessage',
          'Your Klarna payment request was successfully cancelled. Please select a payment option and try again.'
        )
        toastState.setToastMessage(klarnaFailureMessage)
        this.isKlarnaSubmitOrderApiInProgress = false
        checkoutContainer.activePath = 'payment'
      }
      this.clearKlarnaState()
      this.isKlarnaSubmitOrderApiInProgress = false
    }
  }

  appendKlarnaPaymentWindow = () => {
    const klarnaPaymentURL = checkoutContainer.klarnaInitiateResponse?.url || ''
    try {
      if (klarnaContainer.klarnaWindowURL && klarnaPaymentURL) {
        klarnaContainer.klarnaWindowURL?.document?.write?.(
          `<script>window.location.href='${klarnaPaymentURL}'</script>`
        )
        klarnaContainer.klarnaWindowURL?.document?.close?.()

        this.windowCloseTimer = setInterval(() => {
          if (klarnaContainer.klarnaWindowURL) {
            this.checkChildWindow()
          }
        }, 200)
      }
    } catch (e) {
      trackErrorInInstana({
        errorReport: 'Klarna - Append Klarna Payment Window Failed',
        errorData: e,
      })
    }
  }
  /**
   * Handles the 'beforeunload' event, which is triggered when the user is about to leave the page.
   * This function displays a confirmation message to the user, asking them if they are sure they want to leave the page.
   * The confirmation message is returned as the event's `returnValue` property, which is used by the browser to display the message.
   */
  handleKlarnaWindowListeners = () => {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    window.addEventListener('unload', this.handleUnloadEvent)
  }

  createKlarnaPaymentWindow = () => {
    try {
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600`
      if (IS_BROWSER) {
        klarnaContainer.klarnaWindowURL = window.open(
          '',
          'KlarnaPaymentURL',
          params
        )
        if (klarnaContainer.klarnaWindowURL) {
          this.handleKlarnaWindowListeners()
          trackErrorInInstana({
            errorReport: 'Klarna - Create Klarna Payment Window Success',
            errorData: klarnaContainer.klarnaWindowURL,
          })

          klarnaContainer.klarnaWindowURL?.document?.write?.(
            '<html><head><title>Klarna Payment Window</title></head><body>Loading...</body></html>'
          )
        } else {
          trackErrorInInstana({
            errorReport: 'Klarna - Create Klarna Payment Window Failed',
            errorData: 'Klarna Window URL is null',
          })

          this.handleLoading(false)
          this.klarnaBrowserPopupBlock = true
          this.handleKlarnaWindowOnRetry()
        }
      }
    } catch (e) {
      const isNativeApp = checkIsNativeApp() || false
      trackErrorInInstana({
        errorReport: 'Klarna - Create Klarna Payment Window Failed',
        errorData: {
          isNativeApp: isNativeApp,
          error: e,
        },
      })
    }
  }

  handleBeforeUnload = event => {
    event?.preventDefault()
    trackErrorInInstana({
      errorReport: 'Klarna - add/remove beforeunload event listener',
      errorData: event,
    })
    const confirmationMessage = 'Are you sure you want to leave this page?'
    event.returnValue = confirmationMessage

    return confirmationMessage
  }

  handleUnloadEvent = () => {
    trackErrorInInstana({
      errorReport:
        'Klarna - Unload Event - User chose to reload or navigate away',
      errorData: klarnaContainer.klarnaWindowURL,
    })
    if (
      klarnaContainer.klarnaWindowURL &&
      !klarnaContainer.klarnaWindowURL?.closed
    ) {
      klarnaContainer.klarnaWindowURL?.close?.()
    }
  }

  handleKlarnaPaymentWindowRedirection = async (props = {}) => {
    this.submitOrderProps = props
    this.appendKlarnaPaymentWindow()

    if (klarnaContainer.klarnaWindowURL) {
      this.klarnaBrowserPopupBlock = false
      this.handleKlarnaLoading(true)

      if (!this.isklarnaBroadCastInstanceCreated) {
        this.isklarnaBroadCastInstanceCreated = true

        //BroadCast Creation
        let klarnaBroadCastInstances = new BroadcastChannel('klarnaPayment')
        trackErrorInInstana({
          errorReport: 'Klarna - Initiate Response',
          errorData: checkoutContainer.klarnaInitiateResponse,
        })
        if (this.isklarnaBroadCastInstanceCreated) {
          trackErrorInInstana({
            errorReport: 'Klarna - BroadCast Instance Created in SubmitOrder',
            errorData: this.isklarnaBroadCastInstanceCreated,
          })
          //Receiving Broadcast Message
          klarnaBroadCastInstances.onmessage = async event => {
            this.isKlarnaBroadcastMessageReceived = true
            trackErrorInInstana({
              errorReport: 'Klarna - Received BroadCast Channel Message',
              errorData: event?.data,
            })
            const klarnaRedirectUrlQueryParam = event?.data || ''
            const klarnaStatus =
              klarnaRedirectUrlQueryParam?.klarna_status || ''

            if (klarnaStatus === 'success') {
              if (!this.isKlarnaTransactionStatusApiInProgress) {
                const klarnaStatusResponse =
                  await this.getKlarnaTransactionStatus({
                    isFromBroadcast: true,
                  })

                if (checkoutContainer.isSuccessResponse(klarnaStatusResponse)) {
                  const statusCode =
                    klarnaStatusResponse?.code?.toLowerCase() || ''
                  let cartResponse = (await cartContainer.viewCart()) || {}
                  let cartCount = cartResponse?.value?.count || 0
                  if (cartCount > 0) {
                    this.handleKlarnaPaymentRedirection({
                      klarnaStatus: statusCode,
                      successCodeFrom: 'broadcast',
                    })
                  }
                }
                //else {
                //   await this.retryKlarnaStatusCall()
                // }
              }
            } else {
              this.handleKlarnaPaymentRedirection({
                klarnaStatus: klarnaStatus,
                successCodeFrom: 'broadcast',
              })
            }
            this.closeKlarnaWindow()
            klarnaBroadCastInstances.close()
          }
        }
      }
    } else {
      // this.klarnaBrowserPopupBlock = true
      // this.handleKlarnaWindowOnRetry()
    }

    //await this.checkForKlarnaTransactionStatus()
  }

  handleKlarnaWindowOnRetry = async () => {
    this.handleKlarnaLoading()

    if (klarnaContainer.klarnaWindowURL) {
      klarnaContainer.klarnaWindowURL?.close?.()
    }
    let errorMessage = ''
    if (
      this.klarnaBrowserPopupBlockCount < this.maxRetryKlarnaBrowserPopupBlock
    ) {
      errorMessage = i18nTranslate(
        'checkout.klarnaBrowserPopupBlocked',
        'Klarna - Browser Popup Blocked. Please enable'
      )
      toastState.setToastMessage(errorMessage)
      this.klarnaBrowserPopupBlockCount++
    } else if (
      this.klarnaBrowserPopupBlockCount === this.maxRetryKlarnaBrowserPopupBlock
    ) {
      this.handleKlarnaPaymentWindowCancel()
    }
  }

  retryKlarnaStatusCall = async (klarnaTransactionResponse = {}) => {
    if (this.klarnaStatusRetryCount < this.maxRetryForKlarnaStatus) {
      trackErrorInInstana({
        errorReport: 'Klarna - Transaction Status API Failed',
        errorData: klarnaTransactionResponse,
      })

      this.klarnaStatusRetryCount++

      await klarnaContainer.getKlarnaTransactionStatus()
    } else if (this.klarnaStatusRetryCount === this.maxRetryForKlarnaStatus) {
      this.handleKlarnaFailureAfterRedirection(
        'Klarna - Transaction Status API Failed',
        'Sorry,we are unable to place order. Please try again.'
      )
      this.closeKlarnaWindow()
    }
  }

  createOrAppendKlarnaPaymentWindow = () => {
    if (klarnaContainer.klarnaWindowURL) {
      this.appendKlarnaPaymentWindow()
    } else {
      this.createKlarnaPaymentWindow()

      setTimeout(() => {
        if (klarnaContainer.klarnaWindowURL) {
          this.appendKlarnaPaymentWindow()
        }
      }, 300)
    }
  }

  handleKlarnaSubmitOrderV2 = async (options = {}) => {
    const { submitOrderBtnProps = {} } = options
    const isNativeApp = checkIsNativeApp() || false

    const sessionResponse =
      customerContainer?.isRegisterUser &&
      (await sessionContainer.refreshSession())
    if (!sessionContainer.isSuccessResponse(sessionResponse)) {
      trackErrorInInstana({
        errorReport: 'Klarna Refresh Session - Failure',
        errorData: sessionResponse,
      })
      klarnaContainer.closeKlarnaWindow()
      this.handleLoading(false)
      checkoutContainer.isToInitiateKlarnaPayment = false
    }

    if (this.klarnaBrowserPopupBlock) {
      trackErrorInInstana({
        errorData: this.klarnaBrowserPopupBlock,
        errorReport: 'Klarna - Browser Popup Blocked',
      })
      this.handleKlarnaLoading(true)
      this.createOrAppendKlarnaPaymentWindow()
    } else {
      if (!isNativeApp) {
        klarnaContainer.createKlarnaPaymentWindow()
      }

      this.handleKlarnaNavigation(submitOrderBtnProps)
    }
  }

  handleKlarnaNavigation = async submitOrderBtnProps => {
    const isNativeApp = checkIsNativeApp() || false

    const klarnaInitiateResponse =
      await checkoutContainer.initiateKlarnaPayment(window?.location?.href)
    if (
      klarnaInitiateResponse?.result === 'success' &&
      klarnaInitiateResponse?.result != undefined &&
      typeof window !== 'undefined'
    ) {
      trackErrorInInstana({
        errorReport: 'Klarna Initiate API - Success',
        errorData: klarnaInitiateResponse,
      })
      this.handleLoading(false)
      if (isNativeApp) {
        const klarnaPaymentURL = klarnaInitiateResponse?.url || ''
        window.location.replace(`${klarnaPaymentURL}`)
        checkoutContainer.isToInitiateKlarnaPayment = false
      } else {
        klarnaContainer.handleKlarnaPaymentWindowRedirection(
          submitOrderBtnProps
        )
      }
    } else {
      window.removeEventListener('beforeunload', this.handleBeforeUnload)
      window.removeEventListener('unload', this.handleUnloadEvent)
      trackErrorInInstana({
        errorReport: 'Klarna Initiate API - Failure',
        errorData: klarnaInitiateResponse,
      })
      klarnaContainer.closeKlarnaWindow()
      this.handleLoading(false)
      checkoutContainer.isToInitiateKlarnaPayment = false
      if (typeof window !== 'undefined' && IS_BROWSER) {
        window?.location?.reload()
      }
    }
  }
}
const klarnaContainer = new KlarnaContainer()

export { KlarnaContainer, klarnaContainer }
export default klarnaContainer
