import React from 'react'
import { Col, InputGroup, Row } from 'react-bootstrap'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { i18nTranslate } from 'src/utils'

const EmailVerifiedSMSVerify = props => {
  const className = props.isInvalid ? 'is-invalid' : ''
  return (
    <InputGroup className="phoneNotVerified alert-success hide-success-alert">
      <Row className="dTextInvalidEmail flex-column">
        <Col className="phoneNotVerifiedClass">
          <IoIosCheckmarkCircle className="checkVal" />
          <div className="emailVerifiedText">
            {' '}
            {i18nTranslate('emailVerified', 'Email is Verified')}
          </div>
        </Col>
      </Row>
    </InputGroup>
  )
}

export { EmailVerifiedSMSVerify }
