import { pageNames } from 'src/routes/pathParams'
import { currencyFormat } from 'src/utils'
import { IS_BROWSER } from 'src/utils/application'
import i18next from 'i18next'
import { localeList } from 'src/App/localeList'
import { storeContainer } from 'src/models'

/**
 * Generates schema.org ItemList markup for a list of items
 */
function itemListSchema(itemList) {
  const listPageURL = IS_BROWSER ? window.location.href : ''
  const itemListElement = itemList.map((item, index) => {
    const name = item?.properties?.name || ''
    const description = item?.properties?.description || ''
    const price = item?.prices?.[0]?.price || ''
    // const currencyCode = get(item, 'currency', '')
    const formatedPrice = currencyFormat({
      // currency: currencyCode,
      price: price,
    })
    // : price
    const identifier = item?.identifier || ''
    const brand = item?.properties?.brand || ''
    const url =
      IS_BROWSER &&
      `${window.location.origin}${pageNames.product}/${identifier}`
    const selectedLocale = storeContainer.selectedLocaleList
    const listOfLocale = selectedLocale.length ? selectedLocale : localeList()
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'Product',
        name: name,
        url: url,
        description: description,
        identifier: identifier,
        brand: {
          '@type': 'Thing',
          name: brand,
        },
        offers: {
          '@type': 'Offer',
          availability: 'https://schema.org/InStock',
          price: formatedPrice,
          priceCurrency: (
            listOfLocale.find(locale => locale.value === i18next.language) || {}
          ).currency,
          itemCondition: 'https://schema.org/NewCondition',
        },
      },
    }
  })
  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    url: listPageURL,
    itemListOrder: 'https://schema.org/ItemListOrderDescending',
    itemListElement: itemListElement,
  }
}

export { itemListSchema }
