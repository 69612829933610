// import { isEmpty } from 'lodash'
import { subscriptionContainer } from 'src/models'
// import { application } from 'src/utils'
// import { getFilterParams, constructQueryParam } from 'src/deps'
import { i18nTranslate } from 'src/utils'

/**
 * Returns the translated text for the given frequencyType.
 *
 * @param {string} frequencyType - The frequency type, e.g. 'DAYS', 'MONTHS'
 * @returns {string} The translated text for the frequency duration, e.g. 'day', 'month'
 */
function getFrequencyType(frequencyType) {
  let frequencyDuration = ''
  const frequencyMonth = i18nTranslate('frequency.month', 'month', {
    nameSpace: 'ssr-resource',
  })
  switch (frequencyType) {
    case 'DAYS':
      frequencyDuration = i18nTranslate('frequency.day', 'day', {
        nameSpace: 'ssr-resource',
      })
      break
    case 'MONTHS':
      frequencyDuration = frequencyMonth
      break
    case 'WEEKS':
      frequencyDuration = i18nTranslate('frequency.week', 'week', {
        nameSpace: 'ssr-resource',
      })
      break
    case 'YEARS':
      frequencyDuration = i18nTranslate('frequency.year', 'year', {
        nameSpace: 'ssr-resource',
      })
      break
    default:
      frequencyDuration = frequencyMonth
      break
  }
  return frequencyDuration
}

/**
 * Gets the list of frequency options from the given properties.
 *
 * @param {Object} properties - The properties to get the frequency options from
 * @param {string} frequencyText - Optional default frequency text
 * @returns {Array} The list of frequency options
 */
function getFrequencyList(properties, frequencyText = '') {
  const { intervalType = '' } = properties
  const splittedIntervalType = intervalType.split(',')
  let frequencyOptionList = []
  return frequencyOptionList
}

/**
 * Fetches the subscription preference list for the given user and month.
 *
 * @param {string} userId - The ID of the user to get the subscription preferences for
 * @param {number} month - The month to get the subscription preferences for, as a number
 * @returns {Promise} A promise that resolves to the subscription preference list
 */
async function fetchSubscriptionPreferenceList(userId, month) {
  return await subscriptionContainer.getAllSubscriptionsPreference(
    userId,
    month
  )
}

/**
 * @date 9/19/2023 - 12:08:03 PM
 *
 * CX15-9326 - this will be fixed in backend
 */
/**
 * Sums the scanQualifiedCount property for non-bundle SKUs in the given list.
 * Bundles and SKU kits are skipped since their counts are already included
 * in their constituent SKUs.
 *
 * @param {Array} skus - List of SKUs to sum scanQualifiedCount for
 * @returns {number} The sum of scanQualifiedCount for non-bundle SKUs
 */
function getScanQualifiedCountSumForBundleSubItems(skus = []) {
  return (
    skus?.reduce((accumulator, currentValue) => {
      if (currentValue?.type === 'BUNDLE' || currentValue?.type === 'SKUKIT') {
        return accumulator
      } else {
        return (
          accumulator +
          (currentValue?.skuQuantity || 1) *
            parseInt(currentValue?.skuProperties?.scanQualifiedCount || 0)
        )
      }
    }, 0) || 0
  )
}

export {
  getFrequencyType,
  getFrequencyList,
  fetchSubscriptionPreferenceList,
  getScanQualifiedCountSumForBundleSubItems,
}
