import React from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import './styles.scss'

class ErrorFallback extends React.PureComponent {
  render() {
    const errorWrapperClassName = this.props.errorWrapperClassName || ''
    const errorContentClassName = this.props.errorContentClassName || ''

    return (
      <div className={`error-wrapper ${errorWrapperClassName}`}>
        <div className={`error-content ${errorContentClassName}`}></div>
      </div>
    )
  }
}

class ErrorBoundary extends React.Component {
  handleError = (error, info) => {
    // Error logging
    console.warn('error: ', error, ' info: ', info)
    if (this.props.onError) {
      this.props.onError?.({ error, info })
    }
  }

  render() {
    return (
      <ReactErrorBoundary
        FallbackComponent={this.props?.FallbackComponent || ErrorFallback}
        onError={this.handleError}>
        {this.props.children}
      </ReactErrorBoundary>
    )
  }
}

export { ErrorBoundary }
export default ErrorBoundary
