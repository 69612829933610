import { observable } from 'mobx'
import { CommonContainer, customerContainer } from 'src/models'
import { getLocalStorage } from 'src/utils'
import { storeContainer } from 'src/models/Store'
// import { APPConfig } from 'config/appConfig'

/**
 * LoyaltyContainer class.
 * Handles loyalty related state and operations.
 */
class LoyaltyContainer extends CommonContainer {
  loyaltyAPIinProgress = false
  @observable loyaltyPointsResponse = {}
  @observable isLoyaltyCallMade = false
  @observable storeCreditPoints = 0

  /*getLoyalty = async () => {
    const userId = get(customerContainer.profileResponse, 'id')
    const loadParams = {
      endPointName: 'getLoyalty',
      pathParams: userId,
    }
    let response = {}
    if (customerContainer.isRegisterUser) {
      response = await this.fetchResponse(loadParams)
    }
    this.loyaltyResponse = response
  }*/
  /**
   * @note
   * changes made as per given pr - EQPLAT-8517
   */
  /**
   * Checks if loyalty rewards are allowed for discount models.
   * Looks for a 'discountModelRewardTypes' property in the active store response that includes 'loyalty'.
   * Returns true if found, false otherwise.
   */
  getDiscountModelRewardTypes = () => {
    let allowedDiscountModel = false
    const isDiscountType = storeContainer.activeStoreResponse?.properties || []
    const valueAvailable = isDiscountType?.find(ele => {
      return (
        ele?.name?.toLowerCase() == 'discountmodelrewardtypes' &&
        ele?.value?.toLowerCase().includes('loyalty')
      )
    })
    allowedDiscountModel = valueAvailable ? true : false
    return allowedDiscountModel
  }

  /**
   * Gets the loyalty transactions list for the logged in user.
   *
   * Calls the getLoyaltyPointHistory API endpoint to fetch the transactions.
   *
   * Constructs the filters and sort params to filter for approved, redeemed etc transactions
   * and sort by createdOn date descending.
   *
   * Returns the API response.
   */
  getLoyaltyTransactionsList = async params => {
    let filterParams = ''
    filterParams = {
      filters: [
        {
          field: 'type',
          operation: 'IN',
          value: 'APPROVED||REDEEMED||FORFEITED||EXPIRED',
        },
        {
          field: 'rewardType',
          operation: '=',
          value: 'LOYALTY',
        },
        {
          field: 'notes',
          operation: '!=',
          value: 'Approve - YL sync',
        },
      ],
    }
    const loadParams = {
      endPointName: 'getLoyaltyPointHistory',
      pathParams: '',
      queryParams: {
        ...params,
        filters: this.generateMultipleQryParam(filterParams),
        sort: JSON.stringify({
          sort: [{ field: 'createdOn', direction: 'DESC' }],
        }),
      },
    }

    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Generates a query parameter string from a filters object.
   * Iterates through the filters object and constructs a query param string
   * with the field, operation and value for each filter.
   * Joins the params with AND.
   */
  generateMultipleQryParam = filterParam => {
    const str = []
    const filter = filterParam?.filters || {}
    for (let p in filter)
      if (filter.hasOwnProperty(p)) {
        str.push(
          filter[p].field +
            ' ' +
            filter[p].operation +
            ' ' +
            '"' +
            filter[p].value +
            '"'
        )
      }
    return str.join(' AND ')
  }

  /**
   * Fetches loyalty points for the current user.
   *
   * Checks if loyalty is enabled via config.
   * Sets a flag to prevent concurrent API calls.
   * Calls API if user is registered.
   * Stores response and returns loyalty points.
   */
  getLoyaltyPoints = async isRegisteredUser => {
    // if (APPConfig?.getAppConfig()?.hideLoyaltyAlways === 'true') {
    //   return ''
    // }
    if (!this.loyaltyAPIinProgress) {
      this.loyaltyAPIinProgress = true
      const isRegisteredUserLabel =
        customerContainer.isRegisterUser || isRegisteredUser
      if (isRegisteredUserLabel) {
        const loadParams = {
          endPointName: 'getLoyalty',
          pathParams: '',
          queryParams: {
            fullProfile: true,
          },
        }

        const type = getLocalStorage('accountType')

        const response = await this.fetchResponse(loadParams)
        this.loyaltyPointsResponse = response
        this.getStoreCredits()
        this.loyaltyAPIinProgress = false
        return response
      }
    }
    this.loyaltyAPIinProgress = false
    return ''
  }
  /**
   * Gets the total store credit points from the loyalty API response.
   * Checks the userMath.STORECREDIT.total.openToRedeem value and assigns it to storeCreditPoints.
   */
  getStoreCredits = () => {
    let storeCredit =
      (this.loyaltyPointsResponse.userMath &&
        this.loyaltyPointsResponse.userMath['STORECREDIT'] &&
        this.loyaltyPointsResponse.userMath['STORECREDIT'].total &&
        this.loyaltyPointsResponse.userMath['STORECREDIT'].total
          .openToRedeem) ||
      0
    this.storeCreditPoints = storeCredit
    return storeCredit
  }

  /**
   * Retrieves the user's loyalty progress by calling the loyalty API.
   * Constructs the API request parameters.
   * Checks the user's account type from localStorage.
   * Calls the API and stores the response if user is a Retail Customer.
   * Returns the API response.
   */
  getLoyaltyProgress = async () => {
    const loadParams = {
      endPointName: 'getLoyalty',
      pathParams: '',
      queryParams: {
        fullProfile: true,
      },
    }
    const type = getLocalStorage('accountType')
    if (type === 'Retail Customer') {
      const response = await this.fetchResponse(loadParams)
      this.loyaltyPointsResponse = response
      return response
    }
  }

  /**
   * Gets the total open loyalty points from the loyalty API response.
   * Checks the userMath.LOYALTY.total.openToRedeem value and returns it.
   */
  getOpenLoyaltyPoints = () => {
    const loyaltyPoints =
      this.loyaltyPointsResponse?.userMath?.LOYALTY?.total?.openToRedeem || 0

    return loyaltyPoints
  }
}

const loyaltyContainer = new LoyaltyContainer()

export { LoyaltyContainer, loyaltyContainer }
export default loyaltyContainer
