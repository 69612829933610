/* eslint-disable react/jsx-key */
import React from 'react'
import { i18nTranslate, convertToBoolean } from 'src/utils'
import { observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import Slider from 'react-slick'
import TagManager from 'react-gtm-module'
import { Button, Col } from 'react-bootstrap'
import { IS_BROWSER, APPConfig } from 'config/appConfig'
import NuskinArrowRight from 'src/views/components/Icons/NuskinArrowRight'
import NuskinArrowLeft from 'src/views/components/Icons/NuskinArrowLeft'
import { ImageComponent } from 'src/views/components'
import { productDeps } from 'src/deps'
import './styles.scss'

@observer
class ProductImageCarousel extends React.Component {
  @observable carouselNavs = {
    nav1: null,
    nav2: null,
    currentSlideIndex: 0,
  }

  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.carouselNavs = {
      nav1: this.slider1,
      nav2: this.slider2,
      currentSlideIndex: 0,
    }
    reaction(
      () => {
        try {
          return JSON.stringify(this.props?.product?.skuSelected)
        } catch (e) {
          console.log('Data parse error::: ProductImageCarousel' + e)
        }
      },
      () => {
        setTimeout(() => {
          this.carouselNavs.currentSlideIndex = 0
          this.carouselNavs.nav1?.slickGoTo(0)
          this.carouselNavs.nav2?.slickGoTo(0)
        }, 600)
      }
    )
  }

  isYouTubeVideo = (videoUrl = '') => {
    if (videoUrl != '') {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = videoUrl && videoUrl.match(regExp)
      let videoId = match?.[2].length === 11 ? match[2] : null
      if (videoUrl && videoUrl.match(regExp)) {
        return true
      }
    }
    return false
  }

  /**
   * @note
   * fix for ADA as child anchor tags are also included in tab navigation
   * inside child elements
   */
  methodToReuseAdaFix = () => {
    if (IS_BROWSER) {
      // document
      //   .querySelectorAll(`#pdp-content .slick-list .thumbnailSlider`)
      //   .forEach(anchorTag => anchorTag.setAttribute('tabindex', '-1'))
      document
        .querySelectorAll(`#pdp-content div[aria-hidden=false] `)
        .forEach(anchorTag => anchorTag.removeAttribute('tabindex', '-1'))

      document
        .querySelectorAll(
          `#pdp-content div[aria-hidden=false] .thumbnailSlider`
        )
        .forEach(anchorTag => anchorTag.removeAttribute('tabindex', '-1'))
    }
  }
  handleTabIndex = () => {
    this.methodToReuseAdaFix()
  }

  pauseVideo = () => {
    // We can't create Multiple refs in class component so used query selector
    document
      .querySelectorAll('.productcarousel video')
      .forEach(video => video.pause())
  }

  handleTabIndexAndGTMCarouselClick = () => {
    this.pauseVideo()
    this.methodToReuseAdaFix()
    TagManager.dataLayer({
      dataLayer: {
        event: 'carouselClicked',
        pagePath: `/product/:id`,
        pageTitle: 'Product Details Page',
        section: 'Carousel in Product Details Page',
        platform: 'equinox',
      },
    })
  }

  handlePDPCarouselVideoPlay = () => {
    const product = this.props?.product || {}
    const { name, productId, skuId } = product

    /**
     * @info
     * GTM Datalayer - Send event when video play button is clicked on Product Details Carousel.
     */
    TagManager.dataLayer({
      dataLayer: {
        event: 'videoPlayed',
        name: name || '',
        productId: productId || '',
        skuId: skuId || '',
        pagePath: `/product/:id`,
        pageTitle: 'Product Details Page',
        platform: 'equinox',
      },
    })
  }
  getCustomDots = (productImageList = [], dots) => {
    const customDots = []
    productImageList?.forEach((block, index) => {
      customDots.push(
        <li {...dots?.[index]?.props}>
          <button
            aria-label={this.getDotsAriaLabel({ productImageList, index })}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })

    return <ul className="slick-dots">{customDots}</ul>
  }

  getDotsAriaLabel = props => {
    const { productImageList, index } = props || {}
    const selectedTxt =
      this.carouselNavs.currentSlideIndex == index
        ? i18nTranslate('pdp.selected', `, selected `, {
            nameSpace: 'ssr-resource',
          })
        : ''
    return (
      i18nTranslate('pdp.CarouselDots', `product image `, {
        nameSpace: 'ssr-resource',
      }) +
      parseInt(index + 1) +
      i18nTranslate('pdp.of', ` of `, {
        nameSpace: 'ssr-resource',
      }) +
      productImageList?.length +
      selectedTxt
    )
  }

  getMedia = mediaProps => {
    const {
      url = '',
      alt = i18nTranslate('pdp.defaultAlt', 'Product carousel image', {
        nameSpace: 'ssr-resource',
      }),
      index,
      isToShowControls = true,
      ref,
      isFromThumbnail = false,
      customClassName = '',
      customParentClassName = '',
      overrideParentClass = false,
      loadingType = 'eager',
    } = mediaProps
    const videoUrlRegEx = /^https?:\/\/.*\/.*\.(mp4|webm|ogg)\??.*$/gim
    if (this.isYouTubeVideo(url)) {
      return (
        <iframe
          tabIndex={index}
          width="100%"
          className="media"
          src={`https://www.youtube.com/embed/${videoId}?controls=${
            isToShowControls ? '0' : '1'
          }`}
        />
      )
    } else if (url.match(videoUrlRegEx)) {
      return (
        <video
          onPlay={this.handlePDPCarouselVideoPlay}
          tabIndex={index}
          width="100%"
          controls={isToShowControls}>
          {/* to do for product video caption or subtitle */}
          {/* <track
            src="https://raderain.sirv.com/Presales/Nuskin/Nuskin%20New%20Demo/nuskin%20(2)/nuskin/teagreen.vtt"
            label="English"
            srcLang="en"
            kind="subtitles"
          /> */}
          <source src={url} />
        </video>
      )
    }
    return (
      // <img
      //   tabIndex={index}
      //   className="media"
      //   src={url}
      //   alt={alt}
      //   onError={e => (e.target.src = imagePlaceholder)}
      // />
      <ImageComponent
        tabIndex={index}
        className={`media ${customClassName}`}
        customParentClassName={customParentClassName}
        overrideParentClass={overrideParentClass}
        src={url}
        alt={alt}
        optimizeImage={isFromThumbnail}
        width={isFromThumbnail ? '44px' : 'auto'}
        loadingType={loadingType}
        // height={'375px'}
        // width={'375px'}
      />
    )
  }

  render() {
    const renderThumbnailImage = () => {
      return (
        <>
          {productImageList && productImageList.length > 0 ? (
            <Slider
              className="product-thubnailSlider mt-3"
              asNavFor={this.carouselNavs?.nav1}
              ref={slider => (this.slider2 = slider)}
              slidesToShow={
                (productImageList?.length >= 6
                  ? 6
                  : productImageList?.length) || 0
              }
              variableWidth={true}
              slidesToScroll={1}
              swipeToSlide={true}
              arrows={false}
              infinite={true}
              afterChange={index =>
                (this.carouselNavs.currentSlideIndex = index)
              }
              focusOnSelect={true}>
              {productImageList?.map((image, index) => {
                return (
                  <Button
                    key={index}
                    className="thumbnailSlider productcarousel_slider"
                    aria-label={this.getDotsAriaLabel({
                      productImageList,
                      index,
                    })}
                    data-testid={`qa-pdp-thumbnail-image-${index + 1}`}>
                    {this.getMedia({
                      url: image?.url || image || '',
                      alt: image?.alt || productName || '',
                      loadingType: 'eager',
                      isToShowControls: false,
                      isFromThumbnail: true,
                      index,
                    })}
                    {/* <img className="media" src={image.url} alt={image.alt} /> */}
                    {/* <object data={this.getSustainabilityVideo(image?.url)}>
                          <img src={imagePlaceholder} alt={productName} />
                        </object> */}
                  </Button>
                )
              })}
            </Slider>
          ) : (
            <>
              {this.getMedia({
                url: product?.primaryimage || '',
                alt: productName || 'thumbnail image',
                isToShowControls: false,
                isFromThumbnail: true,
                loadingType: 'eager',
                customClassName: 'bundle_image_thumbnail',
                customParentClassName: 'w-100',
                overrideParentClass: true,
              })}
            </>
          )}
        </>
      )
    }
    const renderProductImage = () => {
      return (
        <>
          {productImageList && productImageList.length > 0 ? (
            <Slider
              {...settings}
              asNavFor={this.carouselNavs?.nav2}
              ref={slider => (this.slider1 = slider)}>
              {productImageList?.map((image, index) => {
                return (
                  <div
                    key={`productcarousel-${index}`}
                    role="img"
                    aria-live="polite"
                    aria-label={this.getDotsAriaLabel({
                      productImageList,
                      index,
                    })}
                    className={`productcarousel_slider ${
                      isReplacementProductStatus
                        ? 'discontinue-slider discontinued'
                        : ''
                    }`}>
                    {/* <img
                        tabIndex={index}
                        className="media"
                        src={image.url}
                        alt={image.alt}
                      /> */}
                    {isReplacementProductStatus && (
                      <div className="discontinued-text">
                        {i18nTranslate(
                          'pdp.ItemDiscontinued',
                          'Item Discontinued',
                          {
                            nameSpace: 'ssr-resource',
                          }
                        )}
                      </div>
                    )}
                    {this.getMedia({
                      url: image?.url || image || '',
                      alt: image?.alt || productName || '',
                      index,
                      loadingType: index == 0 ? 'eager' : 'lazy',
                    })}
                    {/* <object
                        tabIndex={index}
                        data={this.getSustainabilityVideo(image?.url)}>
                        <img
                          src={imagePlaceholder}
                          alt={
                            image?.alt ||
                            i18nTranslate('pdp.defaultAlt', 'Product carousel image')
                          }
                        />
                      </object> */}
                  </div>
                )
              })}
            </Slider>
          ) : (
            <>
              <div
                className={`${
                  isReplacementProductStatus
                    ? 'discontinue-slider discontinued'
                    : ''
                }`}>
                {isReplacementProductStatus && (
                  <div className="discontinued-text">
                    {i18nTranslate(
                      'pdp.ItemDiscontinued',
                      'Item Discontinued',
                      {
                        nameSpace: 'ssr-resource',
                      }
                    )}
                  </div>
                )}
                {this.getMedia({
                  url: product?.primaryimage || '',
                  alt: productName || '',
                  loadingType: 'eager',
                  customClassName: 'bundle_image',
                })}
              </div>
            </>
          )}
          <div className="express-image-count text-center f-14 mt-4">
            {this.carouselNavs?.currentSlideIndex +
              1 +
              ' / ' +
              productImageList?.length || 1}
          </div>
        </>
      )
    }
    const product = this.props?.product || {}
    const selectedSku =
      product?.selectedSku ||
      productDeps.getUserSelectedSku({
        sku: product?.sku,
        skuId: product?.skuId,
      })
    const isproductStatus =
      selectedSku?.properties?.productStatus?.toLowerCase() == 'discontinued'
    let isBundleProduct = productDeps.isBundleData(product)
    const enableDiscontinuedSKUReplacement =
      convertToBoolean(
        APPConfig?.getAppConfig()?.enableDiscontinuedSKUReplacement
      ) || false
    const isReplacementProductStatus =
      Array.isArray(product?.replacementProduct) &&
      product?.replacementProduct?.every(
        item => typeof item === 'object' && item !== null
      ) &&
      isproductStatus &&
      !isBundleProduct &&
      enableDiscontinuedSKUReplacement

    let imageList =
      product?.productImages ||
      product?.bundleMandatoryProducts?.[0]?.selectedSku?.properties
        ?.productImages ||
      []
    const productName = product.name || ''
    let productImageList = [product.primaryimage]
    try {
      productImageList = (imageList && JSON.parse(imageList)) || []
    } catch (e) {}

    const nextIcon = (
      <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="0"
        viewBox="0 0 24 24"
        data-role="none"
        className="d-block"
        currentSlide="0"
        slideCount="7"
        height="1em"
        width="1em">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 5l7 7-7 7"></path>
      </svg>
    )
    const prevIcon = (
      <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="0"
        viewBox="0 0 24 24"
        data-role="none"
        className="d-block"
        currentSlide="0"
        slideCount="7"
        height="1em"
        width="1em">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"></path>
      </svg>
    )
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      onInit: this.handleTabIndex,
      afterChange: this.handleTabIndexAndGTMCarouselClick,
      nextArrow: (
        <NuskinArrowRight customIcon={nextIcon} styles={{ width: '1.5rem' }} />
      ),
      prevArrow: (
        <NuskinArrowLeft customIcon={prevIcon} styles={{ width: '1.5rem' }} />
      ),
      responsive: [
        {
          breakpoint: this.props.isExpressPDP ? 786 : 389,
          settings: {
            arrows: false,
            dots: true,
            appendDots: dots => this.getCustomDots(productImageList, dots),
          },
        },
      ],
    }

    return (
      <>
        <div
          className="productcarousel product_video pdp-main-image-slider"
          data-testid="qa-pdp-product-image-carousel">
          {renderProductImage()}
          {renderThumbnailImage()}
        </div>
      </>
    )
  }
}

export { ProductImageCarousel }
