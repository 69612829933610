import React from 'react'
import { i18nTranslate } from 'src/utils'
import { observer } from 'mobx-react'
import { Image } from 'react-bootstrap'
import freeShipping from 'src/images/free-shipping.png'
import './styles.scss'

@observer
class ProductShipping extends React.Component {
  nameSpace = 'ssr-resource'
  render() {
    const product = this.props?.product || {}
    let productShipping = product.shipping || '{}'
    let shippingText = ''
    try {
      shippingText = JSON.parse(productShipping)
    } catch (e) {
      console.log('Data parse error::: ProductShipping' + e)
    }

    let shippingTextmsg = shippingText?.message?.replace('{boldText}', '') || ''

    return (
      <div className="shipping-details">
        <div className="shipping-text mt-2">
          {shippingText && shippingTextmsg && (
            <p className="paragraph-m mt-2 mb-2">
              <Image src={freeShipping} alt="free-shipping icon" />
              <span>
                <strong>
                  {i18nTranslate('pdp.shippingText', shippingText?.boldText, {
                    nameSpace: this.nameSpace,
                  })}
                </strong>
                {i18nTranslate('pdp.shippingTextmsg', shippingTextmsg, {
                  nameSpace: this.nameSpace,
                })}
              </span>
            </p>
          )}
        </div>
      </div>
    )
  }
}

export { ProductShipping }
