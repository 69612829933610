import { observable } from 'mobx'

class KlarnaOverlayState {
  @observable isKlarnaLoading = false

  togglerKlarnaLoader = () => {
    this.isKlarnaLoading = !this.isKlarnaLoading
  }

  showKlarnaLoader = () => {
    this.isKlarnaLoading = true
  }

  hideKlarnaLoader = () => {
    this.isKlarnaLoading = false
  }
}

const klarnaOverlayState = new KlarnaOverlayState()

export { klarnaOverlayState }
