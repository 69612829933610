import { observable } from 'mobx'
import { i18nTranslate } from 'src/utils'

import teamContainer from './Team'
import { CommonContainer } from 'src/models/Common'
import { customerContainer } from 'src/models'
import { toastState } from 'src/views/components'
import { getLocaleCodeFromUrl, IS_BROWSER } from 'src/utils'

/**
 * UserContainer class that extends CommonContainer.
 * This class likely contains user management logic and state.
 */
class UserContainer extends CommonContainer {
  @observable userData = []
  @observable userDataWithPaginationInfo = {}
  @observable userDataWithoutSize = {}
  @observable associateActivation = ''
  @observable associateActivationRessponse = {}

  constructor(props) {
    super(props)
  }

  /**
   * Constructs a filter string for querying users based on the provided filter parameters.
   *
   * Checks for team, role, and searchUser in the filterParams and adds the appropriate
   * clauses to the filter string. Returns an object with the filter string, or an empty
   * object if no filter clauses were added.
   */
  constructTeamFilter = (filterParams = {}) => {
    let filterString = ''
    if (filterParams.team) {
      filterString += `team IN "${filterParams.team}",`
    }
    if (filterParams.role) {
      filterString += `role IN "${filterParams.role}",`
    }
    if (filterParams.searchUser) {
      if (isNaN(filterParams.searchUser) === false) {
        filterString += `id IN "${filterParams.searchUser}"`
      } else {
        filterString += `%name LIKE "${filterParams.searchUser}%"`
      }
    }

    if (filterString) {
      return {
        filter: filterString,
      }
    }
    return {}
  }

  /**
   * Sends an invitation email to a new user to join as an associate.
   *
   * @param {Object} postData - Request payload containing email and other invite details
   * @returns {Promise} Promise that resolves if successful, rejects if failed
   */
  sendAssociateInvitationLink = async postData => {
    const loadParams = {
      endPointName: 'sendAssociateInviteLink',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'userInvite.addSuccess',
          'Your invitation has been sent!'
        ),
        true
      )
      this.getUsersList()
      teamContainer.getAllTeams()
    } else {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'user.inviteFailure',
            'Sorry, we are unable to send the invite. Please try again.'
          ),
        false
      )
    }
  }

  /**
   * Deletes an associate invitation link.
   *
   * @param {Object} postData - Request payload containing the invitation ID
   * @returns {Promise} Promise that resolves if successful, rejects if failed
   */
  deleteAssociateInvitationLink = async postData => {
    const loadParams = {
      endPointName: 'patchAssociateInviteLink',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'userInvite.deleteSuccess',
          'The invitation has been deleted successfully'
        ),
        true
      )
      this.getUsersList()
      teamContainer.getAllTeams()
    } else {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'user.deleteinviteFailure',
            'Sorry, we are unable to delete the invite. Please try again.'
          ),
        false
      )
    }
  }

  /**
   * Redirects to the home page after accepting an associate invitation.
   * Gets the current locale code, splits it into language and country,
   * then redirects to the home page URL with the locale applied.
   */
  associateInviteRedirectToHomePage = async () => {
    const curLocale = getLocaleCodeFromUrl({
      isReverseType: true,
      defaultLocale: 'en_US',
    })

    const [language, country] = curLocale.includes('_')
      ? curLocale.split('_')
      : curLocale.split('-')

    setTimeout(() => {
      if (IS_BROWSER) {
        window.location.href =
          `${window.location.origin}/${country}/${language}`.toLowerCase()
      }
    }, 1000)
  }

  /**
   * Fetches details about an associate invitation and handles the response.
   *
   * Calls the 'getAssociateInvitedetails' endpoint to get details on an invitation.
   * Checks the response status and associateInvitationStatus value.
   * If accepted, shows a success toast and redirects.
   * If invalid, shows an error toast and redirects.
   *
   * Returns the API response.
   */
  getAssociateInviteDetail = async () => {
    const loadParams = {
      endPointName: 'getAssociateInvitedetails',
      queryParams: { activeparam: this.associateActivation },
    }

    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.associateActivationRessponse = response
      if (
        response?.customProperties?.associateInvitationStatus ===
        'INVITATION_ACCEPTED'
      ) {
        toastState.setToastMessage(
          i18nTranslate(
            'associateUser.updateAccepted',
            'You have already accepted the invitation'
          ),
          true
        )
        this.associateInviteRedirectToHomePage()
      }
      return response
    } else {
      toastState.setToastMessage(
        this.associateActivationRessponse?.message ||
          i18nTranslate(
            'associateUser.updateFailure',
            'The invitation is no longer valid'
          ),
        false
      )
      this.associateInviteRedirectToHomePage()
    }
  }

  /**
   * Fetches a list of users without pagination.
   *
   * Gets the account ID.
   * Sets the page size to the total user count.
   * Calls the accountProfile API to get the full list of users.
   * Saves the response to userDataWithoutSize.
   */
  getUserListWithoutSize = async () => {
    const accountId = await this.getAccountId()
    const size = this.userDataWithPaginationInfo?.pageableInfo?.totalCount || 10
    const queryParams = {
      size,
      page: 1,
    }
    const loadParams = {
      endPointName: 'accountProfile',
      pathParams: `${accountId}/buyers`,
      queryParams,
    }
    this.userDataWithoutSize = await this.fetchResponse(loadParams)
  }
  /**
   * Fetches a paginated list of users.
   *
   * Accepts filterParams containing page number and search criteria.
   * Gets account ID.
   * Constructs filter props from filterParams.
   * Calls accountProfile API with page, size, fullProfile and filters.
   * Maps response to extract key user details.
   * Saves response and mapped user list to properties.
   * Returns API response.
   */
  getUsersList = async filterParams => {
    const page = filterParams?.page || 1
    const accountId = await this.getAccountId()

    if (accountId !== '') {
      const filterProps = this.constructTeamFilter(filterParams)
      const params = {
        page: page,
        size: 5,
        fullProfile: true,
        ...filterProps,
      }
      const loadParams = {
        endPointName: 'accountProfile',
        pathParams: `${accountId}/buyers`,
        queryParams: params,
      }
      const response = await this.fetchResponse(loadParams)
      if (this.isSuccessResponse(response)) {
        this.userDataWithPaginationInfo = response
        const teamsData = teamContainer?.teamsDataWithoutSize?.teams || []
        const userDataList =
          response &&
          response.accountBuyer.map(userDetails => {
            const team = teamsData.find(teamBuyer => {
              return parseInt(userDetails.teamId) === teamBuyer.id
            })
            return {
              ...userDetails.buyerInfo,
              userId: userDetails?.id || '',
              team: team?.name || '',
            }
          })
        this.userData = userDataList
      } else {
        this.userData = []
        this.userDataWithPaginationInfo = {}
      }
      return response
    }
  }

  /**
   * Updates user details by making an API call.
   *
   * Accepts userInfo object containing userId and updated details.
   * Constructs request payload and hits updateUserList API endpoint.
   * On success, shows success toast and refetches latest user list.
   * On failure, shows error toast.
   */
  updateUserList = async userInfo => {
    const { userId, ...remainingProps } = userInfo
    const loadParams = {
      endPointName: 'updateUserList',
      pathParams: userId,
      postData: remainingProps,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate('user.updateSuccess', 'User updated successfully'),
        true
      )
      await this.getUsersList()
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'user.updateFailure',
          'Sorry, we are unable to update the user. Please try again.'
        ),
        false
      )
    }
  }

  /**
   * Fetches updated user list data by calling API.
   *
   * Accepts filterParams object containing search criteria.
   * Constructs request payload with filter params.
   * Calls getUpdateUserList API endpoint to fetch latest user list.
   * On success, updates this.userData with response data.
   */
  getUpdateUserList = async filterParams => {
    const loadParams = {
      endPointName: 'getUpdateUserList',
      postData: filterParams,
    }
    const response = await this.fetchResponse(loadParams)
    this.userData = response
  }

  /**
   * Retrieves the ID for a team given its name.
   *
   * Searches through the teams data to find the team object
   * matching the provided name. Returns the ID property for that
   * team object, or an empty string if no match is found.
   */
  getTeamIdFromName = currentTeam => {
    return (
      teamContainer.teamsDataWithoutSize.teams.find(
        (team, index) =>
          team.name === currentTeam[`${team.name}_${index}`] ||
          team.name === currentTeam
      )?.id || ''
    )
  }

  /**
   * Retrieves the cost centre ID for a given team ID.
   *
   * Searches through the teams data to find the team object
   * matching the provided ID. Returns the costCentreId property
   * for that team, or an empty string if no match found.
   */
  getCostCentreId = teamId => {
    const teamData = teamContainer.teamsDataWithoutSize.teams.find(
      list => list.id === teamId
    )
    return teamData?.costCentreId || ''
  }

  /**
   * Updates the buyer user details for a given team.
   *
   * Accepts parameters containing account ID, team ID, user ID and name.
   * Retrieves the cost centre ID for the given team.
   * Constructs request payload with name, cost centre ID and user/team mapping.
   * Calls userInvite API endpoint to update buyer user details for the team.
   */
  updateBuyerUser = params => {
    const { accountId, teamId, userId, name } = params
    const costCentreId = this.getCostCentreId(teamId)
    const postData = {
      name,
      costCentreId,
      teamBuyer: [
        {
          userId,
          teamId,
        },
      ],
    }
    const loadParams = {
      endPointName: 'userInvite',
      postData,
      pathParams: `${accountId}/team/${teamId}`,
    }
    this.fetchResponse(loadParams)
  }

  /**
   * Makes API call to userInvite endpoint.
   *
   * Accepts parameters containing request payload, account ID, and team ID.
   * Constructs request parameters with endpoint name, payload, and path params.
   * Calls fetchResponse method to make API request.
   * Returns promise resolving to API response.
   */
  userInviteApi = params => {
    const { postData, accountId, teamId } = params
    const loadParams = {
      endPointName: 'userInvite',
      postData,
      pathParams: `${accountId}/teams/${teamId}`,
    }
    return this.fetchResponse(loadParams)
  }
  /**
   * Invites a new user by making API calls to userInvite and updateBuyerUser endpoints.
   *
   * Accepts user parameters containing email, team name, first name, last name.
   * Gets account ID and constructs request payload.
   * Calls userInviteApi to invite user to first team.
   * Loops through any additional teams to invite user as buyer for those teams.
   * On success, displays success toast and refreshes user and team lists.
   * On failure, displays error toast.
   */
  userInvite = async params => {
    const { email, team, firstName, lastName } = params[0]
    const accountId = customerContainer.accountId
    const postData = {
      name: Object.values(team[0])[0],
      customers: [{ firstName, lastName, email }],
    }
    const response = await this.userInviteApi({
      postData,
      accountId,
      teamId: this.getTeamIdFromName(team[0]),
    })
    if (this.isSuccessResponse(response)) {
      Promise.all(
        team &&
          team.map(async (teamName, index) => {
            const postBody = {
              name: Object.values(teamName)[0],
              customers: [{ email }],
            }
            if (index !== 0) {
              const teamBuyerLastIndex = response.teamBuyer.length - 1
              const teamBuyerData = response.teamBuyer[teamBuyerLastIndex]
              const userId = teamBuyerData?.userId || ''
              return this.updateBuyerUser({
                ...postBody,
                accountId,
                userId,
                teamId: this.getTeamIdFromName(teamName),
              })
            }
          })
      ).then(data => {
        if (
          this.isSuccessResponse(data[0]) ||
          this.isSuccessResponse(response)
        ) {
          toastState.setToastMessage(
            i18nTranslate(
              'userInvite.addSuccess',
              'Your invitation has been sent!'
            ),
            true
          )
          this.getUsersList()
          teamContainer.getAllTeams()
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'user.inviteFailure',
              'Sorry, we are unable to send the invite. Please try again.'
            ),
            false
          )
        }
      })
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'user.inviteFailure',
          'Unable to invite user, please try again.'
        ),
        false
      )
    }
  }
}

const userContainer = new UserContainer()

export { userContainer }
