import { i18nTranslate } from 'src/utils'
import { getAppConfig } from 'config/appConfig'
import { CommonContainer } from 'src/models/Common'
import { toastState } from 'src/views/components'

/**
 * GeocodeApi class that extends CommonContainer.
 * Used for geocoding APIs.
 */
class GeocodeApi extends CommonContainer {
  /**
   * Fetches geocoding data from the Google API based on the provided zip code.
   * @param {string} zipCode - The zip code to geocode
   * @returns {Promise} Promise resolving to the API response
   */
  fetchGoogleApi = async zipCode => {
    const loadParams = {
      endPointName: 'geoCode',
      ignoreCommonHeader: true,
      queryParams: {
        address: zipCode,
        key: getAppConfig().googleApiKey,
      },
    }
    const geoResponse = await this.fetchResponse(loadParams)
    if (geoResponse.error_message) {
      toastState.setToastMessage(
        i18nTranslate(
          'cart.zipcodeEstimationError',
          'Sorry, we are unable to estimate your shipping cost. Please try again.'
        ),
        false
      )
    }
    return geoResponse
  }
}

const geocodeApi = new GeocodeApi()

export { GeocodeApi, geocodeApi }
export default geocodeApi
