import { observable, action, computed } from 'mobx'
import { i18nTranslate } from 'src/utils'
import { CommonContainer } from 'src/models/Common'
import { toastState } from 'src/views/components'
import { listsContainer } from './Lists'
import { pageNames } from 'src/routes/pathParams'

/**
 * FavoritesContainer class that extends CommonContainer.
 * Used to manage state and operations related to the user's favorites list.
 */
class FavoritesContainer extends CommonContainer {
  favoritesListId = ''
  pageSizeForIds = 50
  // max size allowed per page is 50
  pageSizeForFavoritesPage = 12
  // pagination size is 12
  @observable favoritesListProductIds = []
  @observable isFavoritesIdFetched = false
  // while directly hitting favorites page, isFavoritesIdFetched is used
  @observable favoritesCount = 0
  @observable activePage = 1
  @observable favoritesPageResponse = ''
  @observable favoritesPageProducts = []
  @observable isFavoriteCallOver = false

  /**
   * Checks if the given product identifier exists in the favorites list.
   * @param {string} identifier - The product identifier to check for
   * @returns {boolean} True if the product is found in the favorites list, false otherwise
   */
  checkForFavorites = identifier => {
    if (this.favoritesListProductIds.length > 0) {
      const found = this.favoritesListProductIds.find(
        item => item.key === identifier
      )
      return found != undefined
    }
    return false
  }

  getFavoriteProductsForFavoritesPage = async page => {
    this.isFavoriteCallOver = false
    // while directly hitting favorites page,
    // isFavoritesIdFetched is used
    // to check whether the favorites list ID is fetched or not
    if (this.isFavoritesIdFetched && this.favoritesListId != '') {
      const response = await listsContainer.getListItems({
        listId: this.favoritesListId,
        page: page || this.activePage,
        size: this.pageSizeForFavoritesPage,
      })
      const listItems = response?.listItems || []
      this.favoritesPageResponse = response
      this.favoritesPageProducts = listItems
      this.favoritesCount =
        response?.pageableInfo?.totalCount || listItems.length
      this.isFavoriteCallOver = true
    } else {
      setTimeout(() => {
        this.getFavoriteProductsForFavoritesPage(page)
      }, 300)
    }
  }

  makeAPIRequestForIds = async (page = 1) => {
    const response = await listsContainer.getListItems({
      listId: this.favoritesListId,
      page,
      size: this.pageSizeForIds,
      includeOnlyKeys: true,
    })
    return response
  }

  getFavoriteProductIdsOnly = async () => {
    if (this.favoritesListId != '') {
      let ids = []
      const initialResponse = await this.makeAPIRequestForIds(1)
      const totalCount = initialResponse?.pageableInfo?.totalCount || 0
      if (totalCount > this.pageSizeForIds) {
        const totalPages = Math.ceil(totalCount / this.pageSizeForIds)
        const promises = []
        for (let i = 2; i <= totalPages; i++) {
          promises.push(this.makeAPIRequestForIds(i))
        }
        const responses = await Promise.all(promises)
        responses.forEach(response => {
          ids = [...ids, ...(response?.listItemsKeys || [])]
        })
      }
      this.favoritesListProductIds = [
        ...(initialResponse.listItemsKeys || []),
        ...ids,
      ]
    }
  }

  deleteProductFromFavorites = async identifier => {
    const listItemId =
      this.favoritesListProductIds.find(item => item.key === identifier)
        ?.listItemId || ''

    if (this.favoritesListId != '') {
      const response = await listsContainer.deleteItemFromList({
        listId: this.favoritesListId,
        listItemId: listItemId,
      })
      if (this.isSuccessResponse(response)) {
        toastState.setToastMessage(
          i18nTranslate(
            'favorite.removeSuccess',
            'Your item has been removed.'
          ),
          true
        )
        await this.makeGetFavoritesCallsBasedOnPage()
      } else {
        const errorMessage =
          (response && response.message) ||
          i18nTranslate(
            'favorite.removeFailure',
            'Sorry, this item cannot be removed from your favorites. Please try again.'
          )
        toastState.setToastMessage(errorMessage, false)
      }
      return response
    }
  }

  calculatePageAndUpdateFavoritesPage = async (isFromAdd = false) => {
    let pageToBeFetched = this.activePage
    if (isFromAdd) {
      if (this.favoritesPageProducts.length === this.pageSizeForFavoritesPage) {
        pageToBeFetched = this.activePage + 1
      }
    } else {
      if (this.favoritesPageProducts.length == 1 && this.activePage > 1) {
        pageToBeFetched = this.activePage - 1
      }
    }
    await this.getFavoriteProductsForFavoritesPage(pageToBeFetched)
    this.activePage = pageToBeFetched
  }

  makeGetFavoritesCallsBasedOnPage = async (isFromAdd = false) => {
    const pageUrl = window.location.pathname
    const isFavoritesPage = pageUrl?.includes(pageNames.favorites)

    if (isFavoritesPage) {
      await this.calculatePageAndUpdateFavoritesPage(isFromAdd)
      // for getting page data
      await this.getFavoriteProductIdsOnly()
      // for getting ids to fill icon
    } else {
      await this.getFavoriteProductIdsOnly()
    }
  }

  addProductToFavorites = async favoriteProduct => {
    const { identifier, name, categoryId, productId } = favoriteProduct
    const listData = {
      skuId: identifier,
      name,
      productId,
      quantity: 1,
      categoryId,
      itemKey: productId,
    }
    if (this.favoritesListId != '') {
      const response = await listsContainer.addItemToList({
        listId: this.favoritesListId,
        listData,
      })
      if (this.isSuccessResponse(response)) {
        toastState.setToastMessage(
          i18nTranslate(
            'favorite.addSuccess',
            'Item added to favorites successfully'
          ),
          true
        )
        await this.makeGetFavoritesCallsBasedOnPage(true)
      } else {
        const errorMessage =
          (response && response.message) ||
          i18nTranslate(
            'favorite.addFailure',
            'Sorry, this item cannot be added to your favorites. Please try again.'
          )
        toastState.setToastMessage(errorMessage, false)
      }
      return response
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'favorites.missingFavoritesListId',
          'Missing favorites list id'
        ),
        false
      )
    }
  }

  /**
   * Checks if a favorites list exists for the user, creates one if not.
   *
   * Gets the available lists from the lists API.
   * Checks if a list of type 'FAVORITES' exists.
   * If yes, sets the favorites list ID and gets & updates the list data.
   * If no, initializes the favorites list state, creates a 'MyFavorites' list via the API,
   * sets the favorites list ID if successful.
   *
   * Returns the API response from list creation, if performed.
   */
  checkAndCreateFavorites = async () => {
    const { availableLists } = listsContainer
    if (availableLists && availableLists.length > 0) {
      const found = availableLists.find(list => list.type === 'FAVORITES')
      if (found != undefined) {
        this.favoritesListId = found.id
        this.isFavoritesIdFetched = true
        await this.getFavoriteProductIdsOnly()
        return false
      }
    }

    this.favoritesPageProducts = []
    this.favoritesCount = 0
    this.favoritesListId = ''

    const response = await listsContainer.createList({
      name: 'MyFavorites',
      type: 'FAVORITES',
      itemType: 'PRODUCT',
    })
    if (this.isSuccessResponse(response)) {
      this.favoritesListId = response.listId
    }
    this.isFavoritesIdFetched = true
    return response
  }

  /**
   * Toggles a product's favorite status.
   * If not currently a favorite, adds it to favorites.
   * If currently a favorite, removes it from favorites.
   *
   * @param {Object} favoriteProduct - The product to toggle favorite status for
   */
  toggleFavorites = async favoriteProduct => {
    const { isFavoritesSelected, identifier } = favoriteProduct
    if (isFavoritesSelected === false) {
      await this.addProductToFavorites(favoriteProduct)
    } else {
      await this.deleteProductFromFavorites(identifier)
    }
  }
}
const favoritesContainer = new FavoritesContainer()

export { FavoritesContainer, favoritesContainer }
export default favoritesContainer
