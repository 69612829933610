import React from 'react'
import { Button } from 'react-bootstrap'
import { checkoutFormInput } from './formInput'
import { CommonForm } from 'src/views/components'
import { customerContainer, accountsContainer } from 'src/models'
import { getDefaultSchema } from './validationSchema'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  IS_BROWSER,
  checkMysiteOrPersonalOffer,
  isGuestCheckoutEnabledForWeb,
  trackSignUpEvents,
} from 'src/utils'
import { i18nTranslate, getGuestSignupOrigin } from 'src/utils'
import { getSessionStorage, APPConfig } from 'config/appConfig'

@observer
class CheckoutUserForm extends React.Component {
  @observable isSponsorValid = true
  renderSubmitButton = () => {
    return (
      <Button
        block
        data-testid="qa-guest-checkout-button"
        type="submit"
        disabled={!this.isSponsorValid}
        className="okta-button text-uppercase">
        {i18nTranslate('guest.continue', 'Continue as a Guest', {
          nameSpace: 'ssr-resource',
        })}
      </Button>
    )
  }

  componentDidMount() {
    //Note : SponserId Validation is now made for po & mysite alone, for web need to check with UTM queryparam
    //or need to make this validation call before making submit order
    const enabledGuestForWeb = isGuestCheckoutEnabledForWeb()
    if (!enabledGuestForWeb) {
      this.checkSponsorValidation()
    }
  }

  checkSponsorValidation = async () => {
    const sponsorId = getSessionStorage('sponsor') || ''
    const response = await customerContainer?.sponsorIdValidation(sponsorId)
    if (customerContainer?.isSuccessResponse(response)) {
      this.isSponsorValid = true
    } else {
      this.isSponsorValid = false
    }
  }

  handleSubmit = formData => {
    const signupOrigin = getGuestSignupOrigin()

    delete formData.createAccount
    const { email } = formData || {}
    const postData = {
      ...formData,
      email: email?.toLowerCase(),
      customProperties: {
        personType: 'PRIMARY',
        signupOrigin,
      },
    }

    this.props.onSubmit(postData)
    trackSignUpEvents({
      eventName: 'guest_checkout',
      eventType: 'guest_checkout',
    })
  }

  render() {
    return (
      <React.Fragment>
        <CommonForm
          classNameValue={this.props.classNameValue}
          schema={getDefaultSchema()}
          formTypeJson={checkoutFormInput()}
          customHandleBlur={this.props?.customHandleBlur}
          SubmitButtonProps={this.renderSubmitButton}
          onSubmit={this.handleSubmit}
          hasInlineSubmitButton={true}
        />
        {!this.isSponsorValid && (
          <p className="mt-2 text-danger font-weight-bold">
            {i18nTranslate(
              'invite.sponsorNameErrorMsg',
              'Oops! There is an issue with the shared link. Please try again. If the problem persists, kindly contact the person who shared the link with you.'
            )}
          </p>
        )}
      </React.Fragment>
    )
  }
}

export default CheckoutUserForm
export { CheckoutUserForm }
