import React from 'react'
import { i18nTranslate } from 'src/utils'

class ModalCloseIcon extends React.PureComponent {
  render() {
    const {
      viewBox = '0 0 20 20',
      color = '#252525',
      width = '20',
      height = '20',
      onClose = function () {},
      dataTestId = 'close-icon',
      ariaLabel = i18nTranslate('icons.close', 'close icon', {
        nameSpace: 'ssr-resource',
      }),
      fill = 'none',
      customClass = '',
    } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        tabIndex={0}
        aria-label={ariaLabel}
        dataTestId={dataTestId}
        className={customClass}
        onClick={onClose}>
        <path
          d="M3 3L17 17"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M17 3L3 17"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    )
  }
}

export { ModalCloseIcon }
export default ModalCloseIcon
