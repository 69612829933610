import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Link } from 'react-router-dom'
import { pageNames } from 'src/routes/pathParams'
import { Row, Col } from 'react-bootstrap'
import CategorySuggestion from './CategorySuggestion'
import { studioContainer } from 'src/models'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { checkMysiteOrPersonalOffer, getLiveEventStatus } from 'src/utils'
import './styles.scss'

@observer
class EmptyProduct extends React.Component {
  nameSpace = 'ssr-resource'
  @observable studioData = []
  enableLiveEvent = getLiveEventStatus()

  constructor(props, context) {
    super(props)
    this.context = context
    this.contextName = 'studioData'
    this.getDataFromSource()
  }

  getDataFromSource = async () => {
    const studioData = this.context?.data?.studioData || {}

    if (Object.keys(studioData).length > 0) {
      // studioContainer.studioData = this.studioData
      this.studioData = studioData
    } else {
      await this.getStudioData()
    }
  }

  getStudioData = async () => {
    this.studioData = await studioContainer.getStudioResponse({
      context: this.context,
      contextName: this.contextName,
    })
    studioContainer.studioData = this.studioData
  }

  getCategory = studioEntries => {
    let categoryRes = {}
    studioEntries &&
      studioEntries.length > 0 &&
      studioEntries?.map((section, key) => {
        if (section && section['category_slider']) {
          categoryRes = section['category_slider']
        }
        return
      })
    return categoryRes
  }

  render() {
    const pathname = this.props?.location?.pathname || ''
    const searchValue = this.props?.match?.params?.searchTerm || ''
    const searchTerm = decodeURIComponent(`"${searchValue}"`)

    let studioRes = this.studioData?.entries?.[0]?.sections
    let categoryRes = this.getCategory(studioRes)
    // let shoppingContext = getLocalStorage('shoppingContext') || {}
    // let contextText = shoppingContext?.context || ''
    const contextText = checkMysiteOrPersonalOffer()

    return (
      <>
        <Row
          noGutters
          className="empty-product-wrap text-center flex-column flex-md-row  pt-5 pb-5 align-items-center">
          {/*<Col className="empty-product-image w-100 h-auto">
           No need Lazy load since it doesn't have more images 
            <Image
              src="https://raderain.sirv.com/tgiant/tshirts/T-Shrit-01.png"
              className="mw-100 align-bottom"
              onError={e => (e.target.src = imagePlaceholder)}
            />       
            </Col>
        */}
          <Col className="empty-product-details pt-4 pt-md-0 pl-3 pr-3">
            <div className="mb-3 font-weight-bold something-wrong-text">
              {pathname.includes(pageNames.search) ? (
                <span data-testid="qa-search-could-not-find">
                  {i18nTranslate(
                    'pdp.noProductsSearchText',
                    'We could not find anything for',
                    { nameSpace: this.nameSpace }
                  )}{' '}
                  {searchTerm}
                </span>
              ) : (
                <span data-testid="qa-search-no-products-found">
                  {i18nTranslate('pdp.noProductsText', 'No Product(s) Found', {
                    nameSpace: this.nameSpace,
                  })}
                </span>
              )}
            </div>
            <h1
              className="empty-product-description pb-3"
              data-testid="qa-search-try-different">
              {i18nTranslate(
                'pdp.noProductsDescription',
                'Try searching for a different term or check the spelling',
                { nameSpace: this.nameSpace }
              )}
              ?
            </h1>
            {/* <a href="/" className="pr-3  border-right border-dark">
            <Trans i18nKey="pdp.goBack">Go Back</Trans>
          </a> */}
            {contextText == '' && (
              <Link
                to={pageNames.home}
                className="px-3 btn-primary p-2 btn-font-size"
                data-testid="qa-search-keep-shopping-link">
                {i18nTranslate('pdp.keepShopping', 'Keep Shopping', {
                  nameSpace: this.nameSpace,
                })}
              </Link>
            )}
          </Col>
        </Row>
        {/* Commented category section & it is in scope for V2 - CX121-3023 */}
        {pathname.includes(pageNames.search) &&
          contextText == '' &&
          this.enableLiveEvent !== 'true' && (
            <CategorySuggestion data={categoryRes} />
          )}
      </>
    )
  }
}

export { EmptyProduct }
export default EmptyProduct
