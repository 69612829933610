export function fromInput(props) {
  const { formEmailFieldName } = props
  return [
    {
      name: formEmailFieldName,
      ariaLabel: 'emailOrPhoneNumber',
      placeholderKey: 'emptyPlaceHolder',
      columnType: {
        lg: '12',
      },
      customClassName: 'my-1',
      dataTestId: 'qa-signin-email',
      required: true,
    },
    {
      name: 'password',
      type: 'password',
      placeholderKey: 'emptyPlaceHolder',
      columnType: {
        lg: '12',
      },
      customClassName: 'my-1',
      dataTestId: 'qa-signin-password',
      ariaLabel: 'password',
      required: true,
    },
    {
      name: 'signin',
      type: 'inlineSubmitButton',
      columnType: {
        lg: '12',
      },
      customClassName: 'my-1 signin-button',
      dataTestId: 'qa-signin-signin',
    },
  ]
}
