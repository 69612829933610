export function isPaylaterMessageRequired(country) {
  switch (country) {
    case 'US':
      return true

    case 'Canada':
      return false

    default:
      return true
  }
}
