import React, { useState, useEffect } from 'react'
import { FormCheck } from 'react-bootstrap'
import {
  i18nTranslate,
  trackGTMEvents,
  getProductsForAgreementSection,
  isExpressCheckout,
} from 'src/utils'
import { APPConfig } from 'config/appConfig'
import {
  FormInputLabels,
  dateFormatDeps,
  isFlexibleSubscriptionFlagEnabled,
} from 'src/deps'
import { cartContainer } from 'src/models'
import { IS_BROWSER, getMonthStringList } from 'src/utils'
import ExpressSectionHeader from '../../ExpressSectionHeader'
import './styles.scss'

function SubscriptionAccordion(props) {
  const [processingDate, setProcessingDate] = useState('')
  const [processingMonth, setProcessingMonth] = useState('')
  const [nextProcDate, setNextProcDate] = useState('')
  const [termsCheckbox, setTermsCheckbox] = useState(false)
  const [isPaymentAgreementChecked, setIsPaymentAgreementChecked] =
    useState(false)
  const [links, setLinks] = useState({})
  const [showAgreement, setShowAgreement] = useState(false)
  const termsAndLinksCTA =
    links.retailCustomerLinks?.subscriptionAgreement || '#'

  //CX16-9904 - Flexible Subscription - Start
  useEffect(() => {
    handleTermsCheck()
  }, [termsCheckbox, isPaymentAgreementChecked])
  //CX16-9904 - Flexible Subscription - End

  useEffect(async () => {
    //CX16-9904 - Flexible Subscription - Start
    const isFlexibleSubscription = isFlexibleSubscriptionFlagEnabled() || false

    let currentDate = new Date()
    let month = currentDate.getMonth()
    let date = currentDate.getDate()
    // Set nextDate as 2 if date is 1 amd 28 if 29/30/31
    // let nextDate = [29, 30, 31].indexOf(date) > -1 ? 28 : date === 1 ? 2 : date
    const defaultProcessingDate =
      APPConfig?.getAppConfig()?.defaultProcessingDate || {}

    let nextDate = date
    if (defaultProcessingDate?.[date]) {
      nextDate = defaultProcessingDate[date] || ''
    }
    let ordinalDate = date => {
      return (
        date +
        (date % 10 == 1 && date != 11
          ? 'st'
          : date % 10 == 2 && date != 12
          ? 'nd'
          : date % 10 == 3 && date != 13
          ? 'rd'
          : 'th')
      )
    }

    //CX16-9904 - Flexible Subscription - Start
    if (isFlexibleSubscription) {
      setProcessingDate(dateFormatDeps?.getDateAsStringWithDay(currentDate))
    } else {
      setProcessingDate(ordinalDate(date))
    }
    //CX16-9904 - Flexible Subscription - End
    setNextProcDate(ordinalDate(nextDate))
    setProcessingMonth(getMonthStringList()[month])
    setLinks(new FormInputLabels())
  }, [])

  // pass checkbox state reference to the call back
  const handleTermsCheck = () => {
    if (isFlexibleSubscriptionFlagEnabled() || false) {
      props?.termsCheck?.(termsCheckbox && isPaymentAgreementChecked)
    } else {
      props?.termsCheck?.(termsCheckbox)
    }
  }

  const navigateToTermsAndConditions = e => {
    e.preventDefault()
    if (IS_BROWSER) {
      window.open(termsAndLinksCTA)
    }
  }
  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      navigateToTermsAndConditions(e)
    }
  }

  const subsCustomerSupport =
    APPConfig?.getAppConfig()?.subscriptionCustomerSupport || ''
  const processOnText = i18nTranslate(
    'subscriptionAccordion.processOnText',
    'Your first order, will be processed on'
  )

  const nextOrderProcessingText = i18nTranslate(
    'subscriptionAccordion.nextOrderProcessingText',
    'Subsequent orders under this Subscription will automatically process on the'
  )

  const frequencySelectedText = i18nTranslate(
    'subscriptionAccordion.frequencySelectedText',
    'day of the month based on the frequency you have selected.'
  )

  const creditCardChargesText = i18nTranslate(
    'subscriptionAccordion.creditCardChargesText',
    'The credit card used to purchase this subscription will be charged each processing date.'
  )

  const chargesText = i18nTranslate(
    'subscriptionAccordion.chargesText',
    'Applicable taxes and shipping charges may vary.'
  )

  const cancellationText = i18nTranslate(
    'subscriptionAccordion.cancellationText',
    'You may change or cancel your Subscriptions at any time by logging into your Nu Skin account and accessing your Subscription Dashboard or by contacting Customer Support at '
  )

  const textProcessTimesApply = i18nTranslate(
    'subscriptionAccordion.processTimesText',
    ' (processing times apply).'
  )

  const termsWarningText = i18nTranslate(
    'subscriptionAccordion.termsWarningText',
    "Before we can process your Subscription, you must agree to our Subscription Terms and Conditions. Please review these terms carefully, as they place limits on Nu Skin's liability and contain important information about your legal rights and obligations. You may download a copy"
  )

  const hereLink = i18nTranslate('subscriptionAccordion.hereLink', 'here.')

  const agreementText = i18nTranslate(
    'subscriptionAccordion.agreementText',
    'I have read, understand, and agree to the'
  )

  const termsAndConditionLink = i18nTranslate(
    'subscriptionAccordion.termsAndConditionLink',
    'Subscription Terms and Conditions.'
  )

  const paymentAgreementCheckboxLabel = i18nTranslate(
    'cartDrawer.paymentAgreementCheckboxLabel',
    'I agree the credit card saved on my Subscription will be charged each processing date'
  )

  const isFlexibleSubscriptionEnabled =
    isFlexibleSubscriptionFlagEnabled() || false

  const gtmData = cartContainer?.cartResponse || {}
  const checkoutProducts = getProductsForAgreementSection(gtmData?.items) || {}
  return (
    <div className="sub-acc-container">
      {/* <div>
        Your first order will be processed on {processingMonth} {processingDate}
        .
      </div>
      <div>
        Your Subscription order will process on the {nextProcDate} of every
        month. If any changes need to be made to a future Subscription or you
        wish to cancel at anytime, please manage your Subscription.
      </div> */}
      {isExpressCheckout() && (
        <ExpressSectionHeader
          title="View Subscription Agreement"
          isOpen={showAgreement}
          onClick={() => setShowAgreement(!showAgreement)}
        />
      )}

      {(!isExpressCheckout() || showAgreement) && (
        <>
          <div className="mb-2">
            <span>{processOnText}</span>
            {isFlexibleSubscriptionEnabled ? (
              <>
                :<br />
              </>
            ) : (
              ' '
            )}
            <span className="font-weight-bold">
              {!isFlexibleSubscriptionEnabled && `${processingMonth} `}
              {processingDate}
              {!isFlexibleSubscriptionEnabled && '.'}
            </span>
          </div>
          <div className="mb-2">
            <span className="font-weight-bold">{nextOrderProcessingText}</span>{' '}
            <span className="font-weight-bold">{nextProcDate}</span>{' '}
            <span className="font-weight-bold">{frequencySelectedText}</span>{' '}
            <span>
              {creditCardChargesText} {chargesText}
            </span>
          </div>
          <p className="mb-2">
            {cancellationText}
            {subsCustomerSupport}
            {textProcessTimesApply}
          </p>
          <div className="mb-2">
            <span>{termsWarningText}</span>{' '}
            <span
              className="sub-acc-terms"
              role="link"
              tabIndex="0"
              onClick={navigateToTermsAndConditions}
              onKeyDown={handleKeyPress}
              target="_blank"
              rel="noreferrer noopener">
              <u>{hereLink}</u>
            </span>
          </div>
        </>
      )}
      <FormCheck className="sub-acc-checkbox">
        <FormCheck.Input
          className="term-condition"
          type="checkbox"
          id="terms_checkbox"
          onChange={e => {
            setTermsCheckbox(e.target.checked)
            trackGTMEvents({
              event: 'subscriptionAgree',
              products: checkoutProducts,
              type: 'checkout subscription agreement',
            })
          }}
        />
        <FormCheck.Label htmlFor="terms_checkbox">
          {agreementText}{' '}
          <p
            className="sub-acc-terms"
            role="link"
            tabIndex="0"
            onClick={navigateToTermsAndConditions}
            onKeyDown={handleKeyPress}
            target="_blank"
            rel="noreferrer noopener">
            <u>{termsAndConditionLink}</u>
          </p>
        </FormCheck.Label>
      </FormCheck>
      {isFlexibleSubscriptionEnabled && (
        <FormCheck className="sub-acc-checkbox">
          <FormCheck.Input
            onChange={e => {
              setIsPaymentAgreementChecked(e.target.checked)
              trackGTMEvents({
                event: 'subscriptionCreditCardAgree',
                products: checkoutProducts,
                type: 'checkout subscription agreement',
              })
            }}
            type="checkbox"
            className="payment-agreement-checkbox"
            id="payment_agreement_checkbox"
          />
          <FormCheck.Label
            className="payment_agreement_label"
            htmlFor="payment_agreement_checkbox">
            {paymentAgreementCheckboxLabel}
          </FormCheck.Label>
        </FormCheck>
      )}
    </div>
  )
}

export { SubscriptionAccordion }
export default SubscriptionAccordion
