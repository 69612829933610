import React from 'react'
import { Modal } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import './styles.scss'

class EventModal extends React.Component {
  handleClose = () => {
    this.props?.disableEventModal()
  }

  render() {
    const {
      show = false,
      modalTitle = '',
      modalMessage = '',
      showRedirection = true,
    } = this.props

    return (
      <>
        <Modal
          className="event-timeline-modal"
          show={show}
          onHide={this.handleClose}>
          <Modal.Header className="event-modal-header" closeButton>
            <Modal.Title>
              {modalTitle
                ? modalTitle
                : i18nTranslate(
                    'event.homeLive2024',
                    'Thank you for coming to L!VE 2024!',
                    {
                      nameSpace: 'ssr-resource',
                    }
                  )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="event-modal-body">
            <div className="event-modal-body-primary">{modalMessage || ''}</div>
            {showRedirection && (
              <div className="event-modal-body-redirection">
                {i18nTranslate(
                  'event.homeOrderHistory',
                  'L!VE Event order history is available at',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )}{' '}
                <a
                  className="event-modal-redirection-link"
                  href="https://www.nuskin.com/us/en/"
                  target="_blank"
                  rel="noopener noreferrer">
                  nuskin.com
                </a>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export { EventModal }
