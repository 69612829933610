import { observable, computed, action } from 'mobx'
import { i18nTranslate } from 'src/utils'
import {
  APPConfig,
  deleteFromSessionStorage,
  getAppConfig,
  setSessionStorage,
} from 'config/appConfig'
import {
  setCookie,
  deleteCookie,
  setLocalStorage,
  deleteFromLocalStorage,
  isB2BAccount,
  isB2B2C,
  getLocalStorage,
  addUserIdForTracking,
  fetchResponseFromAPI,
  IS_BROWSER,
  filterWhiteLabelEmails,
  trackErrorInInstana,
  getLiveEventStatus,
  convertToBoolean,
} from 'src/utils'
import {
  CommonContainer,
  accountsContainer,
  storeContainer,
  addressContainer,
  loyaltyContainer,
  cartContainer,
  catalogContainer,
} from 'src/models'
import { toastState } from 'src/views/components'
import TagManager from 'react-gtm-module'
import { dateFormatDeps } from 'src/deps'
import dayjs from 'dayjs'
import {
  parsePhoneNumberField,
  getFraudDetectionProperties,
} from 'src/utils/signUpUtils'
import { pageNames } from 'src/routes/pathParams'
import ContentStack from 'src/api/contentStack'
import { signInwithRedirect } from 'src/utils/signInUtils'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { alertMessageKeys } from 'src/views/components/CartBlock/fixture'
import { checkMysiteOrPersonalOffer } from 'src/utils'

/**
 * CustomerContainer class extends CommonContainer
 * and contains customer data and functionality.
 */
class CustomerContainer extends CommonContainer {
  @observable storeIDValue = ''
  @observable isOktaUser = false
  @observable isRegisterUser = false
  @observable isSessionAvailable = false
  @observable guestUserCreated = false
  @observable profileResponse = {}
  @observable paymentResponse = []
  @observable paymentsForDashboard = []
  @observable lastPaymentResponse = {}
  // @observable showSelectRegionModal = false
  // Subcription Fix ECS-1312
  @observable isSubscriptionPreference = false
  @observable isSubscriptionAdded = false
  @observable signUpUserEmails = ''
  @observable sponsorName = ''
  @observable isProfileCallOver = false
  @observable qualificationResponse = []
  @observable qualificationData = []
  @observable qualificationStoreResponse = ''
  // @observable signUpConsentsValues = ''
  @observable selectedMarket = storeContainer.defaultShippingRegion
  @observable isToCheckBackgroundSignIn = false
  @observable isPaymentCardState = false
  @observable verifyPhonePopup = false
  @observable fromSessionVerifyPhonePopup = false
  @observable setVerifyPhone = {}
  @observable triggerSaveChangesOnPhoneVerifySuccess = false
  accountId = ''
  typeId = ''
  type = ''
  isSessionExpired = false
  navigeBackTo = ''
  userId = ''
  sponsorCache = {
    value: '',
    response: '',
  }
  getProfileTime = new Date().getTime()
  checkSessionInterval = 2 * 60000
  validateEmailResponse = {
    email: '',
    response: '',
  }

  @computed
  get isGuestB2B() {
    if (isB2B2C() && !this.isRegisterUser) {
      return false
    }
    return isB2BAccount() && !this.isRegisterUser
  }

  constructor(props) {
    super(props)

    /**
     * @todo
     * !!! this needs to be moved to singUp page specific
     */
    // this.signupConsents()
  }

  /**
   * Validates a sponsor ID asynchronously.
   *
   * Checks cache first before making API call.
   * Caches response if enabled.
   *
   * @param {string} data - Sponsor ID to validate
   * @param {Object} additionalProps - Additional options
   * @param {boolean} additionalProps.isToCacheData - Whether to cache response
   * @returns {Promise} Promise resolving to validation response
   */
  sponsorIdValidation = async (data = '', additionalProps) => {
    const { isToCacheData = true } = additionalProps || {}
    if (this.sponsorCache.value == data && isToCacheData) {
      return Promise.resolve(this.sponsorCache.response)
    }
    const loadParams = {
      endPointName: 'sponsorIdValidation',
      pathParams: data,
    }
    const response = await this.fetchResponse(loadParams)
    if (isToCacheData) {
      this.sponsorCache.response = response
      this.sponsorCache.value = data
      this.sponsorName = { ...response }
    }
    return response
  }

  /**
   * Validates a signup email ID asynchronously.
   *
   * Makes an API call to validate the email ID.
   *
   * @param {string} data - Email ID to validate
   * @returns {Promise} Promise resolving to validation response
   */
  signupEmailIdValidation = async (data = '') => {
    const loadParams = {
      endPointName: 'signupEmailIdValidation',
      postData: { email: data },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Fetches signup consent options from the server asynchronously.
   *
   * Builds request parameters based on locale.
   * Calls fetchResponse method to get consent options.
   * Returns promise resolving to response.
   */
  signupConsents = async () => {
    const defaultLocale = getLocaleCodeFromUrl({
      isReverseType: true,
    })

    const [lang = 'en', country = 'us'] = (
      storeContainer?.defaultLocale || defaultLocale
    ).split('_')

    const loadParams = {
      endPointName: 'signupConsents',
      isToSendLocaleInQueryParam: false,
      queryParams: {
        page: 1,
        size: 50,
        //locale: `${lang}_${country.toUpperCase()}`,
      },
    }

    const response = await this.fetchResponse(loadParams)
    // this.signUpConsentsValues = response

    return response
  }

  /**
   * Gets the user consents from the server.
   *
   * Gets the user ID from the profile response.
   * Builds the API request parameters.
   * Calls fetchResponse() to get the user consents.
   * Handles success and error responses:
   * - On failure, shows toast message if regular user.
   * - On failure for business users, shows message that only primary owner can update consents.
   * Returns the API response.
   */
  getUserConsents = async () => {
    const userId = customerContainer.profileResponse.id || ''
    const accType = getLocalStorage('accountType')
    const loadParams = {
      endPointName: 'getUserConsents',
      queryParams: {
        page: 1,
        size: 50,
        userId,
      },
    }
    const response = await this.fetchResponse(loadParams)

    if (
      !this.isSuccessResponse(response) &&
      accType !== 'Brand Affiliate - Business Entity'
    ) {
      toastState.setToastMessage(
        i18nTranslate(
          'consent.fetchConsentsFailure',
          'You have not opt in or out to sms and email consents yet.'
        )
      )
    } else if (
      !this.isSuccessResponse(response) &&
      accType === 'Brand Affiliate - Business Entity'
    ) {
      toastState.setToastMessage(
        i18nTranslate(
          'consent.privacyRestriction',
          'Promotional correspondence and account privacy can only be updated by primary account owner.'
        )
      )
    }
    return response
  }

  /**
   * Updates the user's privacy consents on the server.
   *
   * Accepts the updated consents data.
   * Calls the API to update the consents.
   * Shows a success/error toast message based on the response.
   * Returns the API response.
   */
  updateUserConsents = async data => {
    const loadParams = {
      endPointName: 'updateUserConsents',
      postData: data,
    }
    const response = await this.fetchResponse(loadParams)

    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'consent.updateSuccess',
          'Your consent update is successful'
        ),
        true
      )
    } else {
      const errorMessage =
        response.message ||
        i18nTranslate(
          'consent.updateFailure',
          'Consent update failed, please try again'
        )
      toastState.setToastMessage(errorMessage, false)
    }
    return response
  }

  /**
   * Updates the user's privacy consents on the server.
   *
   * @param {Object} privacyOption - The privacy consent option to update
   * @returns {Promise} The API response
   */
  updateUserPrivacyConsents = async privacyOption => {
    const loadParams = {
      endPointName: 'updateUserPrivacyConsents',
      postData: privacyOption,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  login = async (credentials = {}) => {
    const {
      identity = '',
      password = '',
      isToShowToastMessage = true,
    } = credentials
    const loadParams = {
      endPointName: 'login',
      postData: { identity, password },
    }
    let response
    if (identity && password) {
      response = await this.fetchResponse(loadParams)
    }
    if (this.isSuccessResponse(response)) {
      isToShowToastMessage === true &&
        toastState.setToastMessage(
          i18nTranslate('signin.success', 'User signed in successfully'),
          true
        )

      this.isRegisterUser = true
    } else {
      const errorMessage =
        isToShowToastMessage === true
          ? response.message ||
            i18nTranslate('signin.failure', 'Sign in failed, please try again')
          : response.message

      toastState.setToastMessage(errorMessage, false)
    }
    return response
  }

  /**
   * Validates the signup email ID by calling the signupEmailIdValidation API endpoint.
   *
   * @param {string} data - The email ID to validate
   * @returns {Promise} The API response containing the validation result
   */
  signupEmailIdValidation = async (data = '') => {
    const loadParams = {
      endPointName: 'signupEmailIdValidation',
      postData: { email: data },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Validates the signup email form by calling the signupEmailIdValidation API endpoint.
   *
   * @param {string} data - The email ID entered in the signup form
   * @returns {Promise} The API response containing the validation result
   */
  signupEmailformValidation = async data => {
    const response = await this.signupEmailIdValidation(data)
    this.signUpUserEmails = response
    return response
  }

  /**
   * Gets the home country code for the logged in user from their profile response.
   *
   * @returns {string} The ISO 3166-1 alpha-2 country code for the user's home country.
   */
  getUserHomeCountry = () => {
    const response = this.profileResponse
    return (
      response?.accounts?.properties?.find(
        element => element.attributeId === 'countryCode'
      )?.value || ''
    )
  }

  /**
   * Sets Okta ID token and access token in localStorage and sessionStorage
   *
   * @param {Object} options - Options object containing Okta tokens
   * @param {string} options.idToken - Okta ID token
   * @param {string} options.accessToken - Okta access token
   */
  setOktaTokens = options => {
    try {
      const { idToken = '', accessToken = '' } = options || {}
      localStorage.setItem('idToken', idToken)
      localStorage.setItem('accessToken', accessToken)
      // sessionStorage.setItem('idToken', idToken)
      // sessionStorage.setItem('oktaAccessToken', accessToken)
      sessionStorage.setItem(
        'oktaTokens',
        JSON.stringify({ accessToken, idToken })
      )
    } catch (e) {
      console.warn('Fn setOktaTokens', e)
    }
  }

  /**
   * Logs in a user with Okta authentication.
   *
   * @param {Object} credentials - Login credentials
   * @param {string} credentials.identity - Username or email
   * @param {string} credentials.identityType - Type of identity - "username" or "email"
   * @param {string} credentials.password - User's password
   * @param {boolean} credentials.isToShowToastMessage - Whether to show toast message on login result
   * @returns {Object} Response object containing API response, user's home country code, and active country code
   */
  oktaLogin = async (credentials = {}) => {
    const {
      identity = '',
      identityType = '',
      password = '',
      isToShowToastMessage = true,
    } = credentials

    const stateValue = getLocalStorage('oktaUrlState') || ''
    const nonceParameter = getLocalStorage('nonceParam') || ''
    let stateValueFromQueryParam = location?.search || ''
    stateValueFromQueryParam = new URLSearchParams(
      stateValueFromQueryParam
    )?.get('state')

    const loadParams = {
      endPointName: 'login',
      postData: {
        identity,
        identityType,
        password,
        customProperties: { nonce: nonceParameter },
      },
    }

    let response
    let stateFailed = ''
    if (stateValue === stateValueFromQueryParam) {
      deleteFromLocalStorage('oktaUrlState')
      deleteFromLocalStorage('nonceParam')
      if (identity && password) {
        response = await this.fetchResponse(loadParams)
      }
    } else {
      stateFailed = 'State - failed'
    }
    let homeCountry = ''
    let activeCountryCode = ''
    if (this.isSuccessResponse(response)) {
      const idToken = response?.oktaToken || ''
      const accessToken = response?.oktaAccessToken || ''
      this.setOktaTokens({ idToken, accessToken })
      const sessionId = response?.sessionId || ''
      setLocalStorage('sessionId', sessionId)
      trackErrorInInstana({
        errorReport: `Login Session - ${sessionId}`,
        errorData: loadParams,
      })
      this.isRegisterUser = true
      this.isOktaUser = true
      setLocalStorage('check', true)
      setLocalStorage('phoneVerifyFromSession', true)

      /**
       * @important
       * !!! checking user home country code
       * window check required may be??
       */
      const activeLocale = getLocalStorage('defaultLocale') || ''
      activeCountryCode = activeLocale.split('_')[1] || ''
      // homeCountry = await this.getUserHomeCountry()
      // setLocalStorage('homeCountry', homeCountry)
      // this.showSelectRegionModal = true

      if (isToShowToastMessage) {
        toastState.setToastMessage(
          i18nTranslate('signin.success', 'User signed in successfully'),
          true
        )

        homeCountry = homeCountry.toLowerCase()
      }
      const enableLiveEvent = getLiveEventStatus()
      if (enableLiveEvent === 'true') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'signin',
          },
        })
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'nuskin-account-login',
          pagePath: `customers/login`,
          pageTitle: 'Login Page',
          platform: 'equinox',
        },
      })
    } else {
      trackErrorInInstana({
        errorReport: `login error identity - ${identity} ${stateFailed}`,
        errorData: loadParams,
      })

      trackErrorInInstana({
        errorReport: `login error verifier - ${password} ${stateFailed}`,
        errorData: loadParams,
      })

      const errorMessage =
        isToShowToastMessage === true
          ? response?.message ||
            i18nTranslate('signin.failure', 'Sign in failed, please try again')
          : response?.message

      toastState.setToastMessage(errorMessage, false)
      //Redirecting to homepage for inactive accounts
      if (response?.statusCode !== 200 && typeof window !== 'undefined') {
        const { oktaDomain } = APPConfig?.getAppConfig() || ''
        const response = await this.oktaClearUserSession(oktaDomain)
        /**
         * @info Below timeout is added in order to show the error
         * message to the end user in the toast message for 3 seconds
         *
         */
        let locale = getLocaleCodeFromUrl() || ''
        locale = locale?.toLowerCase()?.split('_')
        setTimeout(() => {
          window.location.href = `${window.location.origin}/${locale?.[1]}/${locale?.[0]}`
        }, 3000)
      }
    }
    return { response, homeCountry, activeCountryCode }
  }

  /**
   * Clears the Okta user session.
   *
   * @param {string} oktaDomain - The Okta domain
   * @returns {Promise} The response from the API call
   */
  oktaClearUserSession = async oktaDomain => {
    try {
      const response = await fetchResponseFromAPI({
        endPointName: 'clearOktaUserSession',
        isToSendStoreId: false,
        isToSendLocaleInQueryParam: false,
        mode: 'no-cors',
        isToDeleteHeader: true,
      })
      return response
    } catch (error) {
      console.log('failure', error)
    }
  }

  /**
   * Authorizes the user with Okta.
   *
   * Calls the getOktaAuthorize endpoint to authorize the user and get a response.
   *
   * @returns {Promise} The response from the API call containing the authorization result.
   */
  oktaAuthorize = async () => {
    const response = await this.fetchResponse({
      endPointName: 'getOktaAuthorize',
    })

    return response
  }

  /**
   * Clears user details from localStorage and cookies.
   */
  clearUserDetails = async () => {
    try {
      const { csrAdminCookie } = getAppConfig()
      // Temp Store ID based on User
      deleteFromLocalStorage('dynamicStoreId')
      deleteFromLocalStorage('check')
      deleteCookie('x-role-user')
      deleteCookie('x-sk-refresh-id')
      deleteCookie('x-sk-session-id')
      deleteCookie('customerType')
      deleteCookie(csrAdminCookie)
      deleteFromLocalStorage('csrAdminName')
      deleteFromLocalStorage('IsAuthenticated')
      deleteFromLocalStorage('isOktaUser')
      deleteFromLocalStorage('idToken')
      deleteFromLocalStorage('accountType')
      deleteFromLocalStorage('homeCountry')
      deleteFromLocalStorage('closeManually')
      deleteFromSessionStorage('mem')
      deleteFromSessionStorage('switchsponsor')
      deleteFromSessionStorage('conv-sponsor')
      // deleteFromLocalStorage('nuskin.account')
      // deleteFromLocalStorage('nuskin.account.data')
      // sessionStorage.removeItem('idToken')
      // sessionStorage.removeItem('oktaAccessToken')
      sessionStorage.removeItem('oktaTokens')
      deleteFromLocalStorage('sessionId')
      await this.clearNuskinSession()
    } catch (e) {
      console.warn('Fn clearUserDetails', e)
    }
  }

  /**
   * Clears the NuSkin login session by calling the logout function from
   * the @nuskin/login-helper package.
   */
  clearNuskinSession = async () => {
    const { logout } = await import('@nuskin/login-helper')
    logout?.()
  }

  /**
   * Logs the user out by calling the logout API endpoint, clearing user details
   * from storage, resetting state, and showing a success/error toast message.
   *
   * @param {boolean} isToHideToastMessage - Whether to suppress the toast message on logout.
   * @returns {Promise} The response from the logout API call.
   */
  logout = async (isToHideToastMessage = false) => {
    const { csrAdminCookie } = getAppConfig()
    const loadParams = {
      endPointName: 'logout',
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      const { oktaDomain } = APPConfig?.getAppConfig() || ''
      if (!checkMysiteOrPersonalOffer()) {
        await this.oktaClearUserSession(oktaDomain)
      }
      // Temp Store ID based on User
      await this.clearUserDetails()
      this.isRegisterUser = false
      !isToHideToastMessage &&
        toastState.setToastMessage(
          i18nTranslate('signOut.success', 'Signed out'),
          true
        )
    } else {
      const errorMessage =
        (response && response.message) ||
        i18nTranslate('signOut.failure', 'Unable to sign out, please try again')
      !isToHideToastMessage && toastState.setToastMessage(errorMessage, false)
    }
    return response
  }

  /**
   * Logs the user out by calling the logout API endpoint, clearing user details
   * from storage, resetting state, and showing a success/error toast message.
   */
  csrlogout = async () => {
    // const { csrAdminCookie } = getAppConfig()
    const loadParams = {
      endPointName: 'logout',
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      const { oktaDomain } = APPConfig?.getAppConfig() || ''
      await this.oktaClearUserSession(oktaDomain)
      // deleteCookie(csrAdminCookie)
      // deleteFromLocalStorage('csrAdminName')
      await this.clearUserDetails()
      toastState.setToastMessage(
        i18nTranslate('signOut.success', 'Signed out'),
        true
      )
    } else {
      const errorMessage =
        (response && response.message) ||
        i18nTranslate('signOut.failure', 'Unable to sign out, please try again')
      toastState.setToastMessage(errorMessage, false)
    }
  }

  /**
   * Fetches payments for the dashboard.
   *
   * @param {boolean} fromPaymentUpdate - Whether this call is from a payment update.
   * @param {number} activePage - The active page number for pagination.
   *
   * @returns {Promise} The response from the API call.
   */
  getPaymentsForDashboard = async (
    fromPaymentUpdate = false,
    activePage = 1
  ) => {
    const loadParams = {
      endPointName: 'getPayment',
      queryParams: {
        showMultiPayment: true,
        timestamp: new Date().getTime(), //CX121-4652
      },
    }
    //CX16-10453
    if (APPConfig?.getAppConfig()?.isPaymentPaginationEnabled === 'true') {
      loadParams.queryParams.page = activePage
      loadParams.queryParams.isPaginationEnabled = true
    }
    const response = await this.fetchResponse(loadParams)
    /**
     * @info
     * Payment response transformed here, to avoid breaking page when pagination backend support is not available
     */
    this.paymentsForDashboard = this.transformPaymentResponse(
      response,
      activePage
    )
    if (fromPaymentUpdate) {
      this.paymentResponse = this.transformPaymentResponse(response, activePage)
    }
    return response
  }

  /**
   * Registers a new user account.
   *
   * @param {Object} signUpDetails - Object containing user details like firstName, lastName etc.
   * @returns {Promise} Response from the register API call
   */
  register = async (signUpDetails = {}) => {
    const {
      firstName = '',
      lastName = '',
      email = '',
      phoneNumber = '',
      isGuestCustomer = true,
      password = '',
      //question,
      //answer,
      loyaltyOpt = '',
    } = signUpDetails

    const postParameters = {
      firstName,
      lastName,
      email,
      phoneNumber,
      isGuestCustomer,
      credentials: {
        password,
        /*securityQuestions: [
          {
            answer,
            question,
          },
        ],*/
      },
      loyalty: {
        optIn: loyaltyOpt,
        rewardType: 'LOYALTY',
      },
    }
    const loadParams = {
      endPointName: 'register',
      postData: postParameters,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.profileResponse = response.customer
      this.isRegisterUser = true

      toastState.setToastMessage(
        i18nTranslate('signup.success', 'Your account has been created.'),
        true
      )
    } else {
      const errorMessage =
        response.message ||
        i18nTranslate(
          'signup.failure',
          'Sorry, we are unable to create your account. Please try again.'
        )
      toastState.setToastMessage(errorMessage, false)
    }
    return response
  }

  /**
   * Registers a guest customer session.
   *
   * Creates a guest customer session by making a register API call with
   * isGuestCustomer set to true. Sets session state based on the response.
   */
  guestRegister = async () => {
    const loadParams = {
      endPointName: 'register',
      postData: {
        isGuestCustomer: true,
      },
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.isSessionAvailable = true
      this.isSessionExpired = false
      this.isRegisterUser = false
    }
    return response
  }

  /**
   * Updates the customer profile without updating the preferred name.
   *
   * @param {Object} formValues - Object containing the updated profile values
   * @returns {Promise} Promise resolving to the API response
   */
  updateProfileWithoutPreferredName = async (formValues = {}) => {
    const accType = getLocalStorage('accountType')
    const { firstName, lastName } =
      accType !== 'Brand Affiliate' ||
      accType !== 'Brand Affiliate - Business Entity'
        ? formValues
        : customerContainer.profileResponse || {}
    const {
      email = '',
      mobilePhoneNumber = '',
      phoneCountryCode = '',
      DoB = '',
      DoB2 = '',
      status = '',
    } = formValues
    const phoneObj =
      (await parsePhoneNumberField(mobilePhoneNumber, phoneCountryCode)) || {}
    const phoneNumber =
      customerContainer.triggerSaveChangesOnPhoneVerifySuccess &&
      customerContainer.profileSetPhoneNumber
        ? customerContainer.profileSetPhoneNumber
        : phoneObj?.number || ''
    const gender = formValues.gender || ''
    const preferredLanguage =
      formValues.preferredLanguageValue ||
      formValues.preferredLanguage ||
      'English'

    let dateOfBirth = ''
    if (DoB !== '' || DoB2 !== '') {
      dateOfBirth =
        dateFormatDeps?.getNormalFormattedDate(DoB) !== 'NaN-NaN-NaN'
          ? dateFormatDeps?.getNormalFormattedDate(DoB)
          : dateFormatDeps?.getNormalFormattedDate(DoB2)
    }
    dateOfBirth = dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : ''
    const isEmailVerifiedFlag =
      email === customerContainer.profileResponse.email
        ? customerContainer.profileResponse.customProperties
            .isEmailVerifiedFlag || false
        : false
    const params = {
      endPointName: 'updateProfile',
      postData: {
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        phoneNumber,
        email,
        // gender,
        dateOfBirth,
        status,
        customProperties: {
          preferredLanguage,
          isEmailVerifiedFlag,
        },
      },
    }
    const response = await this.fetchResponse(params)
    if (status.toLowerCase() === 'freeze') {
      if (this.isSuccessResponse(response)) {
        await this.logout()
        if (typeof window === 'object') {
          window.location.reload()
        }
        toastState.setToastMessage(response.message)
      } else {
        toastState.setToastMessage(response.message)
      }
    } else {
      if (this.isSuccessResponse(response)) {
        toastState.setToastMessage(
          i18nTranslate('profile.updateSuccess', 'Changes saved successfully'),
          true
        )
        this.getProfile()
        /**
         * @info
         * GTM Datalayer -  Profile update
         */

        TagManager.dataLayer({
          dataLayer: {
            event: 'updateProfie',
            pagePath: pageNames.b2bMyProfile,
            pageTitle: 'Profile Page',
            firstName: customerContainer.profileResponse.firstName || '',
            lastName: customerContainer.profileResponse.lastName || '',
            platform: 'equinox',
          },
        })
      } else if (response?.code === 'AOACHLD0001') {
        toastState.setToastMessage(
          i18nTranslate(
            'myaccount.userHoldStatus',
            'There is an issue with your account. Please contact you local account manager.'
          ),
          false
        )
      } else {
        const defaultMessage = i18nTranslate(
          'profile.updateFailure',
          'Unable to save changes, please try again'
        )
        if (response?.code === 'EOUS0005') {
          toastState.setToastMessage(response?.message || defaultMessage, false)
          accountsContainer.duplicateEmailExist = true
        } else {
          toastState.setToastMessage(defaultMessage, false)
        }
      }
    }
    return response
  }

  /**
   * Updates the customer profile by making an API call with the provided form values.
   *
   * Handles different account types, parses phone numbers, sets date of birth, and makes additional fraud check API call if enabled.
   * Shows success/error toasts, refreshes profile data on success, and triggers GTM data layer event.
   *
   * @param {Object} formValues - Object containing updated form values
   * @returns {Promise} API response
   */
  updateProfile = async (formValues = {}) => {
    const accType = getLocalStorage('accountType')
    const { firstName, lastName } =
      accType !== 'Brand Affiliate' ||
      accType !== 'Brand Affiliate - Business Entity'
        ? formValues
        : customerContainer.profileResponse
    const {
      email = '',
      mobilePhoneNumber = '',
      phoneCountryCode = '',
      DoB = '',
      DoB2 = '',
      status = '',
    } = formValues
    const phoneObj =
      (await parsePhoneNumberField(mobilePhoneNumber, phoneCountryCode)) || {}
    const phoneNumber =
      customerContainer.triggerSaveChangesOnPhoneVerifySuccess &&
      customerContainer.profileSetPhoneNumber
        ? customerContainer.profileSetPhoneNumber
        : phoneObj?.number || ''

    const gender = formValues.gender || ''
    const preferredLanguage =
      formValues.preferredLanguageValue ||
      formValues.preferredLanguage ||
      'English'
    const preferredName = formValues.preferredName || firstName
    let dateOfBirth = ''
    if (DoB !== '' || DoB2 !== '') {
      dateOfBirth =
        dateFormatDeps?.getNormalFormattedDate(DoB) !== 'NaN-NaN-NaN'
          ? dateFormatDeps?.getNormalFormattedDate(DoB)
          : dateFormatDeps?.getNormalFormattedDate(DoB2)
    }
    dateOfBirth = dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : ''
    const isEmailVerifiedFlag =
      email === customerContainer.profileResponse.email
        ? customerContainer.profileResponse.customProperties
            .isEmailVerifiedFlag || false
        : false
    //adding extra condition to make sure DOB is VALID
    if (accountsContainer?.isDateOfBirthInValid) {
      return false
    }
    const params = {
      endPointName: 'updateProfile',
      postData: {
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        phoneNumber,
        email: email?.toLowerCase(),
        // gender,
        dateOfBirth,
        status,
        customProperties: {
          preferredLanguage,
          preferredName,
          isEmailVerifiedFlag,
        },
      },
    }
    const profileFraudCheckEnabled =
      APPConfig?.getAppConfig()?.ProfileFraudCheckEnabled === 'true' || false
    const accertifyProps = (await getFraudDetectionProperties()) || {}
    if (profileFraudCheckEnabled) {
      params.postData.customProperties = {
        ...params.postData.customProperties,
        ...accertifyProps,
      }
    }
    const response = await this.fetchResponse(params)
    if (status.toLowerCase() === 'freeze') {
      if (this.isSuccessResponse(response)) {
        await this.logout()
        if (typeof window === 'object') {
          window.location.reload()
        }
        toastState.setToastMessage(response.message)
      } else {
        toastState.setToastMessage(response.message)
      }
    } else {
      if (this.isSuccessResponse(response)) {
        toastState.setToastMessage(
          i18nTranslate('profile.updateSuccess', 'Changes saved successfully'),
          true
        )
        await this.getProfile()
        /**
         * @info
         * GTM Datalayer -  Profile update
         */

        TagManager.dataLayer({
          dataLayer: {
            event: 'updateProfie',
            pagePath: pageNames.b2bMyProfile,
            pageTitle: 'Profile Page',
            firstName: params.postData.firstName,
            lastName: params.postData.lastName,
            platform: 'equinox',
          },
        })
      } else if (response?.code === 'AOACHLD0001') {
        toastState.setToastMessage(
          i18nTranslate(
            'myaccount.userHoldStatus',
            'There is an issue with your account. Please contact you local account manager.'
          ),
          false
        )
      } else {
        const defaultMessage = i18nTranslate(
          'profile.updateFailure',
          'Unable to save changes, please try again'
        )
        if (response?.code === 'EOUS0005') {
          toastState.setToastMessage(response?.message || defaultMessage, false)
          accountsContainer.duplicateEmailExist = true
        } else {
          toastState.setToastMessage(defaultMessage, false)
        }
      }
    }
    return response
  }

  /**
   * Updates the user's account profile information.
   *
   * @param {Object} formValues - Object containing updated profile values
   * @param {boolean} isProfileUpdated - Whether profile was successfully updated
   * @returns {Object} Response from the server
   */
  updateForAccountProfile = async (
    formValues = {},
    isProfileUpdated = true
  ) => {
    const accType = getLocalStorage('accountType')
    const {
      businessEmail = '',
      phone = '',
      mobilePhoneNumber = '',
      phoneCountryCode = '',
    } = formValues
    let phoneObj = {}
    if (mobilePhoneNumber) {
      phoneObj =
        (await parsePhoneNumberField(mobilePhoneNumber, phoneCountryCode)) || {}
    } else if (phone) {
      phoneObj = (await parsePhoneNumberField(phone, phoneCountryCode)) || {}
    }
    const phoneNumber = phoneObj?.number || ''

    const {
      name = '',
      dunsNumber = '',
      taxNumber = '',
    } = accountsContainer?.profileResponse

    const email =
      accType === 'Brand Affiliate - Business Entity'
        ? businessEmail
        : formValues.email
    const { firstName, lastName } =
      accType !== 'Brand Affiliate' &&
      accType !== 'Brand Affiliate - Business Entity'
        ? formValues
        : customerContainer.profileResponse || {}
    let isEmailVerifiedFlag = true
    if (accType !== 'Brand Affiliate - Business Entity') {
      isEmailVerifiedFlag =
        email === customerContainer.profileResponse.email
          ? customerContainer.profileResponse.customProperties
              .isEmailVerifiedFlag || false
          : false
    } else {
      isEmailVerifiedFlag =
        customerContainer.profileResponse.customProperties
          .isEmailVerifiedFlag || false
    }
    let params = {
      endPointName: 'updateProfile',
      postData: {
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        email: email?.toLowerCase(),
        phoneNumber,
        customProperties: {
          isEmailVerifiedFlag,
        },
      },
    }
    if (accType === 'Brand Affiliate - Business Entity') {
      params = {
        endPointName: 'accountProfilePatch',
        pathParams: this.accountId,
        postData: {
          name,
          dunsNumber,
          taxNumber,
          status: 'active',
          phoneNumber,
          properties: [
            {
              attributeId: 'businessEmail',
              value: email?.toLowerCase() || '',
            },
          ],
        },
      }
    }
    const profileFraudCheckEnabled =
      APPConfig?.getAppConfig()?.ProfileFraudCheckEnabled === 'true' || false
    const accertifyProps = (await getFraudDetectionProperties()) || {}
    const enableJITSU = convertToBoolean(APPConfig?.getAppConfig()?.enableJITSU)
    if (
      profileFraudCheckEnabled &&
      accType === 'Brand Affiliate - Business Entity'
    ) {
      params.postData.additionalProperties = accertifyProps || {}
    } else if (profileFraudCheckEnabled) {
      params.postData.customProperties = {
        ...params.postData.customProperties,
        ...accertifyProps,
      }
    }

    const response = await this.fetchResponse(params)
    if (status.toLowerCase() === 'freeze') {
      if (this.isSuccessResponse(response)) {
        await this.logout()
        if (typeof window === 'object') {
          window.location.reload()
        }
        toastState.setToastMessage(response.message)
      } else {
        toastState.setToastMessage(response.message)
      }
    } else if (response?.code === 'AOACHLD0001') {
      toastState.setToastMessage(
        i18nTranslate(
          'myaccount.userHoldStatus',
          'There is an issue with your account. Please contact you local account manager.'
        ),
        false
      )
    } else {
      if (this.isSuccessResponse(response)) {
        isProfileUpdated &&
          toastState.setToastMessage(
            i18nTranslate(
              'profile.updateSuccess',
              'Changes saved successfully'
            ),
            true
          )
        await this.getProfile()
      } else {
        toastState.setToastMessage(
          i18nTranslate(
            'profile.updateFailure',
            'Unable to save changes, please try again'
          ),
          false
        )
      }
    }
    return response
  }

  /**
   * Updates the user's opt-in status for the loyalty rewards program.
   * Sends a request to update the opt-in status on the server.
   * Displays success/failure toast messages.
   * Refreshes loyalty points if successful.
   *
   * @param {boolean} status - New opt-in status
   * @returns {Object} Response from the server
   */
  updateRewardsLoyaltyOptIn = async status => {
    const params = {
      endPointName: 'updateRewardsLoyaltyOptIn',
      postData: {
        optIn: status,
        rewardType: 'LOYALTY',
      },
    }
    const response = await this.fetchResponse(params)
    if (this.isSuccessResponse(response)) {
      if (status) {
        toastState.setToastMessage(
          i18nTranslate(
            'loyalty.updateOptInSuccess',
            'You have now Opted In to the Nu Skin Rewards Program!'
          ),
          true
        )
      } else {
        toastState.setToastMessage(
          i18nTranslate(
            'loyalty.updateOptOutSuccess',
            'You have now Opted Out to the Nu Skin Rewards Program!'
          ),
          true
        )
      }
      await loyaltyContainer.getLoyaltyPoints(true)
    } else {
      toastState.setToastMessage(
        response.message ||
          i18nTranslate(
            'loyalty.updateFailure',
            'Unable to save changes, please try again'
          ),
        false
      )
    }

    return response
  }

  /**
   * Deletes the customer account.
   * Sends a request to the server to delete the account.
   * Logs the user out.
   * Displays success/failure toast messages.
   * Reloads the page on success.
   *
   * @returns {Object} Response from the server
   */
  deleteCustomer = async () => {
    const params = {
      endPointName: 'deleteCustomer',
    }
    const response = await this.fetchResponse(params)
    if (this.isSuccessResponse(response)) {
      await this.logout()
      if (typeof window === 'object') {
        window.location.reload()
      }

      toastState.setToastMessage(
        i18nTranslate(
          'profile.deletedAccountSuccessMessage',
          'A confirmation email will be sent once your account has been deleted'
        ),
        true
      )
    } else {
      // toastState.setToastMessage(
      //   i18nTranslate(
      //     'profile.deleteAccountFailureMessage',
      //     'Your account has orders that are under progress. Please perform the delete operation later'
      //   )
      // )
      toastState.setToastMessage(response.message)
    }
    return response
  }
  /**
   * Fetches user qualification data from the server.
   * Sets the qualificationResponse property with the response.
   */
  qualificationUser = async () => {
    let qualificationStoreData = {}
    const qualificationConfig =
      storeContainer.activeStoreResponse?.properties?.find(
        property => property?.name === 'enableThirdPartyValidator'
      )?.value ||
      storeContainer.globalStoreResponse?.properties?.find(
        property => property?.name === 'enableThirdPartyValidator'
      )?.value ||
      '{}'

    try {
      if (qualificationConfig) {
        qualificationStoreData = JSON.parse(qualificationConfig) || {}
      }
      if (
        qualificationStoreData?.enableConsumeCustomerQualification ||
        qualificationStoreData?.enableFetchCustomerQualification
      ) {
        const loadParams = {
          endPointName: 'qualificationUser',
          queryParams: {
            timestamp: new Date()?.getTime(),
          },
        }
        const response = await this.fetchResponse(loadParams)
        this.qualificationResponse = response
      }
    } catch (e) {
      console.log('JSON.parse error in qualificationConfig', e)
    }
  }
  /**
   * Updates the user's password.
   * @param {Object} formValues - Object containing old password, new password, and confirm new password
   * @returns {Promise} Makes API call to update password and returns response
   */
  updatePassword = async (formValues = {}) => {
    const {
      oldPassword = '',
      newPassword = '',
      confirmPassword = '',
    } = formValues
    const params = {
      endPointName: 'updatePassword',
      postData: {
        oldPassword,
        newPassword,
        confirmPassword,
      },
    }
    const response = await this.fetchResponse(params)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'profile.updatePasswordSuccess',
          'Your password has been updated.'
        ),
        true
      )
    } else {
      toastState.setToastMessage(
        response.message ||
          i18nTranslate(
            'profile.updatePasswordFailure',
            'Unable to update password, please try again'
          ),
        false
      )
    }
  }

  /**
   * Updates the user's security questions.
   * @param {Object} formValues - Object containing question and answer for security question
   * @returns {Promise} Makes API call to update security questions and returns response
   */
  updateSecurityQuestions = async (formValues = {}) => {
    const { question = '', answer = '' } = formValues
    const params = {
      endPointName: 'updateProfile',
      postData: {
        securityQuestions: {
          question,
          answer,
        },
      },
    }
    const response = await this.fetchResponse(params)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'profile.updateSecurityQuestionSuccess',
          'Security questions updated successfully'
        ),
        true
      )
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'profile.updateSecurityQuestionFailure',
          'Unable to update security questions, please try again'
        ),
        false
      )
    }
  }

  /**
   * Parses a JWT token by decoding the base64 encoded middle section
   * and returning the JSON payload
   *
   * @param {string} token - The JWT token to parse
   * @returns {Object|null} The decoded JSON payload, or null if parsing fails
   */
  parseJwt = token => {
    try {
      return JSON.parse(atob(token?.split('.')[1]) || '')
    } catch (e) {
      return null
    }
  }

  /**
   * Checks if the user is logged in and redirects to sign in if not.
   * Gets the Okta session to check if a user is logged in.
   * If a user is logged in, redirects to sign in.
   * If no user is logged in, clears user details from storage.
   */
  checkAndRedirectToSignIn = async () => {
    try {
      if (IS_BROWSER) {
        const url = location.href
        const loginCallback = url?.includes(pageNames.loginCallback)
        const logoutCallback = url?.includes(pageNames.logoutCallback)
        if (loginCallback || logoutCallback) {
          return
        }
        const params = {
          endPointName: 'getOktaSession',
          isToSendStoreId: false,
          isToSendLocaleInQueryParam: false,
        }
        const oktaSession = await this.fetchResponse(params)

        if (oktaSession?.id) {
          signInwithRedirect()
        } else {
          if (!url?.includes(pageNames.myAccountPrefix)) {
            await this.clearUserDetails()
          }
        }
      }
    } catch (e) {
      console.warn('exception in checkAndRedirectToSignIn ', e)
    }
  }

  /**
   * For Klarna and Alipay payments, to resolve session related issues
   * After redirection from Payment window to checkout page,
   * Need to pass the SessionID from Redirected URL [if any] to Customer API as queryParam
   * @date 10/11/2023 - 1:25:07 PM
   *
   * @returns {string}
   */

  /**
   * Gets the session ID from the current URL.
   * Checks if the URL contains the 'sessionVerifier' query parameter and returns its value if so.
   * Otherwise returns an empty string.
   * Catches any errors and logs them before returning empty string.
   */
  getSessionIdFromURL = () => {
    try {
      if (IS_BROWSER) {
        const domain = window?.location?.href
        const hasExistingSession = domain?.includes('sessionVerifier')
        let sessionId = ''

        if (hasExistingSession) {
          const queryParamData = domain?.split?.('?')?.[1]?.split?.('&') || []
          queryParamData?.forEach(param => {
            const hasSessionVerifierKey = param?.includes('sessionVerifier')
            if (hasSessionVerifierKey) {
              sessionId = param?.split?.('=')?.[1] || ''
            }
          })
        }
        return sessionId
      }
    } catch (e) {
      trackErrorInInstana({
        errorReport: 'Unable to get SessionId from URL',
        errorData: e,
      })
      return ''
    }
  }

  /**
   * Retrieves the profile information of the customer.
   * @param {Object} getProfileOptions - Options for retrieving the profile.
   * @param {boolean} getProfileOptions.isToCheckSession - Flag indicating whether to check the session.
   * @returns {Promise<Object>} - A promise that resolves to the profile response object.
   */
  @action
  getProfile = async getProfileOptions => {
    const { isToCheckSession } = getProfileOptions || {}

    if (
      isToCheckSession &&
      this.getProfileTime + this.checkSessionInterval >= new Date().getTime()
    ) {
      return
    }

    this.getProfileTime = new Date().getTime()
    const loadParams = {
      endPointName: 'getProfile',
    }

    const sessionId = this.getSessionIdFromURL() || ''
    if (sessionId) {
      // @note, As per BE's request, passing sessionId as a queryParam to Customer API.
      loadParams.queryParams = {
        sessionVerifier: sessionId,
      }
    }
    trackErrorInInstana({
      errorReport: 'GET Customer API - LoadParams',
      errorData: loadParams,
    })

    this.isProfileCallOver = false
    const response = await this.fetchResponse(loadParams)
    if (response?.phoneNumber?.includes('-')) {
      response.phoneNumber = response.phoneNumber.replaceAll('-', '')
    }
    if (response?.email) {
      response.email = filterWhiteLabelEmails({ email: response.email })
    }

    if (isToCheckSession) {
      this.isProfileCallOver = true
      return
    }

    if (response?.accounts?.type || '') {
      setLocalStorage('accountType', response.accounts.type)
    }
    const { code } = response
    if (code && code == 'AUTH_105') {
      this.isSessionExpired = true
    }
    if (this.isSuccessResponse(response)) {
      this.isSessionAvailable = true
      this.isSessionExpired = false
      // cx13-20292 cx13-20293 logout the inactive and deleted user based status value
      if (
        typeof response?.accounts?.id !== 'undefined' &&
        response?.accounts?.id !== null &&
        response?.accounts?.status?.toLowerCase() !== 'active'
      ) {
        const errorInfo = i18nTranslate(
          'viewcart.userHoldStatus',
          'There is an issue with your account, please contact your local account manager.'
        )
        setLocalStorage('errorInfo', errorInfo)
        await this.logout(true)
        const locale = getLocaleCodeFromUrl()?.toLowerCase()?.split('_')
        window.location.href = `/${locale[1]}/${locale[0]}`
      } else {
        // Temp Store ID based on User
        if (response?.storeId || '') {
          setLocalStorage('dynamicStoreId', response?.storeId)
        }
        this.profileResponse = response
        let isGuestUser = response?.guest || false
        /* registeruser important flag set
         * @customercontainer.isRegisterUser
         * @ customercontainer.isRegisterUser =  is set here
         */
        this.isRegisterUser =
          (response?.email ||
            response?.phoneNumber ||
            response?.userName ||
            response?.userrole ||
            false) &&
          response?.referenceAttribute != null &&
          !isGuestUser

        if (!this.isRegisterUser) {
          deleteCookie('x-role-user')
          // disabling this get okta session. We are getting error only in my site for Safari
          if (IS_BROWSER && !document?.domain?.includes('.mynuskin.com')) {
            this.checkAndRedirectToSignIn(response)
          }
        }

        this.userId = response?.id
        if (isB2BAccount()) {
          const role = response?.customProperties?.role || ''

          if (role) {
            // We are using this cookie for SSR rendering too
            setCookie({
              cookieName: `x-role-user`,
              cookieValue: role,
            })
          }

          this.accountId = response?.accounts?.id || ''
          this.typeId = response?.accounts?.typeId || ''
          this.type = response?.accounts?.type || ''
          await accountsContainer.getProfile(this.accountId)
        }
      }
    } else {
      this.isRegisterUser = false

      // const errorMessage =
      //   response.error ||
      //   response.message ||
      //   i18nTranslate(
      //     'profile.getProfileFailure',
      //     'Something went wrong, please try again'
      //   )
      // toastState.setToastMessage(errorMessage)
    }
    this.isProfileCallOver = true
    addUserIdForTracking({ userId: this.userId })
    return response
  }

  /**
   * Fetches the user's saved payment methods from the API.
   *
   * Calls the API endpoint to get the user's saved payments.
   * Transforms the response to handle pagination if enabled.
   * Sends the payment data to the GTM data layer for analytics.
   *
   * @param {number} activePage - The current pagination page number.
   * @returns {Promise} The API response.
   */
  getPayment = async (activePage = 1) => {
    const loadParams = {
      endPointName: 'getPayment',
    }

    if (APPConfig?.getAppConfig()?.isPaymentPaginationEnabled === 'true') {
      loadParams.queryParams = {
        page: activePage,
        isPaginationEnabled: true,
      }
    }
    const response = await this.fetchResponse(loadParams)
    /**
     * @info
     * Payment response transformed here, to avoid breaking page when pagination backend support is not available
     */
    this.paymentResponse = this.transformPaymentResponse(response, activePage)

    /**
     * @info
     * GTM Datalayer - To get Saved payments
     */

    TagManager.dataLayer({
      dataLayer: {
        event: 'paymentUsedPreviouslySaved',
        pagePath: pageNames.myaccountpayment,
        pageTitle: 'Payment Details',
        savedPayments: this.paymentResponse,
        platform: 'equinox',
      },
    })

    return response
  }

  /**
   * Transforms the payment API response to handle pagination if enabled.
   *
   * Checks if pagination is enabled and formats the response accordingly:
   * - Sets up pageableInfo object with pagination metadata
   * - Uses original payments array if available
   * - Otherwise uses full response array as payments
   *
   * This ensures a consistent payment response format whether pagination is enabled or not.
   */
  transformPaymentResponse = (response = {}, activePage = 1) => {
    const paymentResp = response?.payments || []
    const pageableInfo = response.pageableInfo || {}

    if (
      Array.isArray(response) &&
      response?.length > 0 &&
      paymentResp.length == 0 &&
      typeof pageableInfo?.totalCount === 'undefined'
    ) {
      return {
        payments: [...response],
        pageableInfo: {
          count: response.length,
          page: activePage,
          size: response.length,
          totalCount: response.length,
        },
      }
    } else {
      return response
    }
  }

  /**
   * Validates the original address from the payload
   * and calls the addPayment method if valid.
   * Handles invalid address errors and displays
   * error messages.
   *
   * @param {object} payload - The request payload
   * @returns {object} The API response
   */
  validateOriginalAddress = async payload => {
    let addressAddResponse = {}
    let response = await addressContainer?.originalAddressApiCall(
      payload?.billingAddress
    )
    if (this.isSuccessResponse(response)) {
      addressAddResponse = this.addPayment(payload)
      return addressAddResponse
    } else {
      if (response.message === 'input.validation.failed') {
        response.message = alertMessageKeys(
          'Please correct the address to proceed.'
        )
      }
      toastState.setToastMessage(response.message, false)
      return response
    }
  }
  /**
   * Validates the original address from the payload
   * and return true if api success
   * Handles invalid address errors and displays
   * error messages.
   *
   * @param {object} payload - The request payload
   * @returns {object} The API response
   */
  validateOriginalAddressOnEdit = async (payload = {}) => {
    let response = await addressContainer?.originalAddressApiCall(
      payload?.billingAddress
    )
    if (!this.isSuccessResponse(response)) {
      let errorMessage = response?.message || ''
      if (errorMessage === 'input.validation.failed') {
        errorMessage = alertMessageKeys(
          'Please correct the address to proceed.'
        )
      } else if (response?.code === 'ONESOURCE_ADPLGIN_VAL_ERROR') {
        errorMessage = i18nTranslate(
          'address.invalidAddress',
          'Address provided is invalid'
        )
      }
      toastState.setToastMessage(errorMessage, false)
    }
    return response
  }
  /**
   * Adds a payment method to the customer's profile.
   *
   * @param {object} cardDetail - Object containing card details
   * @param {boolean} isFromSubscription - Whether this is called from a subscription flow
   * @param {boolean} isToHideToast - Whether to suppress toast notifications
   * @returns {object} API response
   */
  addPayment = async (
    cardDetail = {},
    isFromSubscription = false,
    isToHideToast = false
  ) => {
    const loadParams = {
      endPointName: 'addPayment',
      postData: cardDetail,
      queryParams: { timestamp: new Date().getTime() }, //CX121-4652
    }
    if (
      loadParams.postData?.option &&
      typeof loadParams.postData.option == 'object'
    ) {
      loadParams.postData.option.addPaymentToProfile = true
    } else {
      loadParams.postData.option = {
        addPaymentToProfile: true,
      }
    }

    const response = await this.fetchResponse(loadParams)

    if (this.isSuccessResponse(response)) {
      if (this.isPaymentCardState) {
        this.isPaymentCardState = false
      }
      !isToHideToast &&
        toastState.setToastMessage(
          i18nTranslate(
            'payment.addPaymentSuccess',
            'Your payment information has been added.'
          ),
          true
        )
      /**
       * @info
       * GTM Datalayer -  Add payment under profile
       */

      const cardInfo = cardDetail.accountInfo?.cardNumber || ''
      const enableLiveEvent = getLiveEventStatus()
      if (enableLiveEvent === 'true') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'add_payment_method',
            billing_address_same: '',
            payment_type: cardDetail?.type || '',
            save_payment: cardDetail?.option?.addPaymentToProfile || '',
            set_to_default_payment: cardDetail.accountInfo?.isDefault || '',
          },
        })
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'paymentAddedComplete',
          pagePath: pageNames.myaccountpayment,
          pageTitle: 'Payment Details',
          firstName: cardDetail.accountInfo?.name || '',
          lastName: cardDetail.accountInfo?.lastName || '',
          cardType: cardInfo.startsWith('5')
            ? 'Master Card'
            : cardInfo.startsWith('4')
            ? 'Visa'
            : '',
          isDefault: cardDetail.accountInfo?.isDefault || '',
          platform: 'equinox',
        },
      })

      this.lastPaymentResponse = response
      if (isFromSubscription) {
        await this.getPaymentsForDashboard()
      } else {
        this.getPayment()
      }
    } else {
      if (response?.code?.includes('PYMT_DUP_ENTRY')) {
        this.isPaymentCardState = true
        toastState.setToastMessage(
          i18nTranslate(
            'payment.duplicatePaymentCard',
            'Payment card added already exists'
          ),
          false
        )
      } else if (response?.code == 'AOACHLD0001') {
        toastState.setToastMessage(
          i18nTranslate(
            'myaccount.userHoldStatus',
            'There is an issue with your account. Please contact you local account manager.'
          ),
          false
        )
      } else if (response?.message) {
        let errorMessage = response?.message || ''
        toastState.setToastMessage(errorMessage, false)
      } else {
        toastState.setToastMessage(
          i18nTranslate(
            'payment.addPaymentFailure',
            'Sorry, we are unable to add your payment information. Please try again.'
          ),
          false
        )
      }
    }
    return response
  }
  /**
   * Fetches qualification data from ContentStack based on configuration.
   * Parses enableThirdPartyValidator global store property to get qualification configuration.
   * Fetches qualification_common_strings entry from ContentStack.
   * Saves qualification data and configuration to component state.
   */
  fetchQualificationData = async () => {
    let qualificationStoreData = {}

    const qualificationConfig =
      storeContainer.activeStoreResponse?.properties?.find(
        property => property?.name === 'enableThirdPartyValidator'
      )?.value ||
      storeContainer.globalStoreResponse?.properties?.find(
        property => property?.name === 'enableThirdPartyValidator'
      )?.value ||
      '{}'

    const locale =
      getLocaleCodeFromUrl()?.split('_')?.reverse()?.join('-') || 'US-en'

    try {
      if (qualificationConfig) {
        qualificationStoreData = JSON.parse(qualificationConfig) || {}
      }

      if (
        qualificationStoreData?.enableConsumeCustomerQualification ||
        qualificationStoreData?.enableFetchCustomerQualification
      ) {
        let uid = 'bltf8a8c88b3a43cb42'
        const response = await ContentStack.getQualificationReference(
          'qualification_common_strings',
          uid,
          locale
        )
        this.qualificationData = response || []
      }
    } catch (e) {
      console.log('JSON.parse error in qualificationConfig', e)
    }
    this.qualificationStoreResponse =
      qualificationStoreData?.enableConsumeCustomerQualification
  }
  /**
   * Updates the payment details for the customer.
   *
   * @param {Object} updatedDetail - Object containing the updated payment details
   * @param {boolean} [showToastMessage=true] - Whether to show a toast message on success/failure
   * @returns {Promise<void>}
   */
  updatePayment = async (updatedDetail = {}, showToastMessage = true) => {
    if (!updatedDetail.isFromValidateAddress) {
      const loadParams = {
        endPointName: 'updatePayment',
        pathParams: `${updatedDetail.id}`,
        postData: updatedDetail.postData,
      }
      if (updatedDetail.postData?.expiryDate) {
        loadParams.postData = {
          accountInfo: {
            name: updatedDetail.postData?.cardFirstName,
            expiryMonth: updatedDetail.postData?.expiryDate.split('/')[0],
            expiryYear: '20' + updatedDetail.postData?.expiryDate.split('/')[1],
            lastName: updatedDetail.postData?.cardFirstName,
            isDefault: false,
            id: updatedDetail.id,
          },
        }
      }

      delete updatedDetail.postData?.cardFirstName
      delete updatedDetail.postData?.expiryDate
      if (
        loadParams.postData?.option &&
        typeof loadParams.postData.option == 'object'
      ) {
        loadParams.postData.option.addPaymentToProfile = true
      } else {
        loadParams.postData.option = {
          addPaymentToProfile: true,
        }
      }
      loadParams.postData = {
        isDefault: updatedDetail.postData?.isDefault,
        billingAddress: { ...updatedDetail.postData?.billingAddress },
        accountInfo: { ...loadParams.postData?.accountInfo },
      }
      const response = await this.fetchResponse(loadParams)

      if (this.isSuccessResponse(response)) {
        showToastMessage &&
          toastState.setToastMessage(
            i18nTranslate(
              'payment.updateSuccess',
              'Payment updated successfully'
            ),
            true
          )
        this.getPayment()
      } else {
        showToastMessage &&
          toastState.setToastMessage(
            i18nTranslate(
              'payment.updateFailure',
              'Unable to update payment, please try again'
            ),
            false
          )
      }
    } else {
      const loadParams = {
        endPointName: 'updatePayment',
        pathParams: `${updatedDetail.id}`,
        postData: updatedDetail.reqParams,
      }

      const response = await this.fetchResponse(loadParams)

      if (this.isSuccessResponse(response)) {
        showToastMessage &&
          toastState.setToastMessage(
            i18nTranslate(
              'payment.updateSuccess',
              'Payment updated successfully'
            ),
            true
          )
        this.getPayment()
      } else {
        showToastMessage &&
          toastState.setToastMessage(
            i18nTranslate(
              'payment.updateFailure',
              'Unable to update payment, please try again'
            ),
            false
          )
      }
    }
  }
  /**
   * Updates the payment details for a subscription.
   *
   * @param {object} editDetails - Object containing the updated payment details.
   * @param {string} editDetails.id - ID of the payment to update.
   * @param {object} editDetails.payload - Updated payment details to save.
   * @param {boolean} [showToastMessage=true] - Whether to show a toast message on success/failure.
   * @returns {Promise<boolean>} Promise resolving to true if payment updated successfully, false otherwise.
   */
  editSubscriptionPayment = async (
    editDetails = {},
    showToastMessage = true
  ) => {
    const loadParams = {
      endPointName: 'updateExpiryPayment',
      pathParams: editDetails?.id,
      postData: editDetails?.payload,
    }

    const response = await this.fetchResponse(loadParams)

    if (this.isSuccessResponse(response)) {
      const enableLiveEvent = getLiveEventStatus()
      if (enableLiveEvent === 'true') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'update_payment_method',
            payment_type: response?.properties?.cardType || '',
            save_payment: '',
            set_to_dafault_paymet: response?.isDefault || '',
            billing_address_same: '',
          },
        })
      }
      showToastMessage &&
        toastState.setToastMessage(
          i18nTranslate(
            'payment.updateSuccess',
            'Payment updated successfully'
          ),
          true
        )
      await this.getPaymentsForDashboard(true, editDetails?.page)
      return true
    } else if (response.code === 'EOUPPYMT002') {
      showToastMessage &&
        toastState.setToastMessage(
          i18nTranslate(
            'payment.differentCardAdded',
            'If you would like to add a different card, please use the add new payment process.'
          ),
          false
        )
    } else {
      showToastMessage &&
        toastState.setToastMessage(
          i18nTranslate(
            'payment.updateFailure',
            'Unable to update payment, please try again'
          ),
          false
        )
    }
  }

  /**
   * Updates the default payment method for the customer.
   *
   * @param {object} updatedDetail - Details of payment to set as default
   * @param {string} updatedDetail.id - ID of payment to set as default
   * @param {object} updatedDetail.postData - Updated payment details
   * @param {boolean} [showToastMessage=true] - Whether to show toast message
   * @returns {Promise} Promise resolving to API response
   */
  updateDefaultPayment = async (
    updatedDetail = {},
    showToastMessage = true
  ) => {
    const loadParams = {
      endPointName: 'updatePayment',
      pathParams: `${updatedDetail.id}`,
      postData: updatedDetail.postData,
    }
    const response = await this.fetchResponse(loadParams)

    if (this.isSuccessResponse(response)) {
      showToastMessage &&
        toastState.setToastMessage(
          i18nTranslate(
            'payment.updateSuccess',
            'Payment updated successfully'
          ),
          true
        )
      this.getPayment()
    } else {
      showToastMessage &&
        toastState.setToastMessage(
          i18nTranslate(
            'payment.updateFailure',
            'Unable to update payment, please try again'
          ),
          false
        )
    }
  }

  /**
   * Deletes a payment method for the customer.
   *
   * @param {object} cardDetail - Details of payment to delete
   * @param {string} cardDetail.id - ID of payment to delete
   * @returns {Promise} Promise resolving to API response
   */
  deletePayment = async (cardDetail = {}) => {
    const loadParams = {
      endPointName: 'deletePayment',
      pathParams: `${cardDetail.id}`,
    }

    const response = await this.fetchResponse(loadParams)

    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate('payment.deleteSuccess', 'Payment removed successfully'),
        true
      )
      /**
       * @info
       * GTM Datalayer -  Remove payment under profile
       */
      const { accountInfo, isDefault, type } = cardDetail || {}
      const enableLiveEvent = getLiveEventStatus()
      if (enableLiveEvent === 'true') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'delete_payment_method',
            payment_type: type || '',
          },
        })
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'paymentRemovedComplete',
          pagePath: pageNames.myaccountpayment,
          pageTitle: 'Payment Details',
          firstName: accountInfo?.name || '',
          lastName: accountInfo?.lastName || '',
          cardType: type || '',
          isDefault: isDefault || '',
          platform: 'equinox',
        },
      })
      /**
       * @info
       * cardDetail.page param is received here to retain in same pagination when we delete payments
       */

      if (cardDetail?.isFromSubscription) {
        await this.getPaymentsForDashboard(false, cardDetail?.page)
      } else {
        this.getPayment(cardDetail?.page)
      }
    } else if (response?.code === 'EO0003') {
      toastState.setToastMessage(
        i18nTranslate(
          'payment.cannotDeletePayment',
          'Cannot delete this payment as it is already associated with subscription. Kindly update your subscription with a new payment and then proceed with removal.'
        )
      )
    } else {
      toastState.setToastMessage(
        response.message
          ? response.message
          : i18nTranslate(
              'payment.deleteFailure',
              'Unable to remove payment, please try again'
            ),
        false
      )
    }
  }

  /**
   * Sends a request to reset the password for the given email address.
   *
   * @param {string} emailId - The email address to reset the password for.
   * @returns {Promise} A promise that resolves to the API response.
   */
  resetPasswordParam = async (emailId = '') => {
    const loadParams = {
      endPointName: 'resetPassword',
      postData: {
        email: emailId,
      },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Sends a request to activate the user account with the provided data.
   *
   * @param {Object} postData - The activation data to send in the request.
   * @returns {Promise} A promise that resolves to the API response.
   */
  userActivation = async postData => {
    const loadParams = {
      endPointName: 'userActivation',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Sends a request to verify the user's email address with the provided data.
   *
   * @param {Object} postData - The verification data to send in the request.
   * @returns {Promise} A promise that resolves to the API response.
   */
  verifyUserEmail = async postData => {
    const loadParams = {
      endPointName: 'userEmailActivation',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Sends a request to reset the password with the provided data.
   *
   * @param {Object} postData - The reset password data to send in the request.
   * @returns {Promise} A promise that resolves to the API response.
   */
  resetPassword = async postData => {
    const loadParams = {
      endPointName: 'validateResetPassword',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Sends a request to reset the password by answering security questions with the provided data.
   *
   * @param {Object} postData - The security question answers data to send in the request.
   * @returns {Promise} A promise that resolves to the API response.
   */
  resetPasswordBySecurityQuestion = async postData => {
    const loadParams = {
      endPointName: 'resetBySecurityQuestions',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Sends a request to export the user's personal data.
   *
   * @returns {Promise} A promise that resolves to the API response.
   */
  exportPersonalData = async () => {
    const loadParams = {
      endPointName: 'exportData',
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'profile.exportStatus',
          'Your account data will be exported and delivered to your email shortly'
        ),
        true
      )
    }
    return response
  }

  /**
   * Sends a request to send an email notification for email verification.
   *
   * @returns {Promise} A promise that resolves to the API response.
   */
  emailNotification = async () => {
    const loadParams = {
      endPointName: 'emailNotification',
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'emailVerificationNotification: ',
          'Verification link is sent to your email, please check.'
        ),
        true
      )
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'emailVerificationNotificationFailure',
          'Unable to send verification email , please try again later'
        ),
        false
      )
    }
    return response
  }
  /**
   * Validates an email by looking up the MX records.
   *
   * @param {Object} options - The options object
   * @param {Object} [options.postData] - The post data containing the email to validate
   * @returns {Promise} A promise that resolves to the API response
   */
  validateEmailMxLookup = async options => {
    // const enableLiveEvent = convertToBoolean(getLiveEventStatus())
    const { emailLookupInfo } = APPConfig?.getAppConfig() || ''
    const { postData } = options || {}
    const loadParams = {
      endPointName: 'emailLookupMx',
      postData,
      credentials: 'omit',
    }
    // //Live Event - credentials need to set back to omit post removal of maintenance page
    // if (enableLiveEvent) {
    //   loadParams.credentials = 'include'
    // }
    let response
    if (this.validateEmailResponse.email == postData?.email) {
      return this.validateEmailResponse.response
    }

    if (convertToBoolean(emailLookupInfo?.isToEnableEmailLookup)) {
      response = await this.validateEmailInfo(options)
    } else {
      response = await this.fetchResponse(loadParams)
    }
    this.validateEmailResponse.email = postData?.email
    this.validateEmailResponse.response = response
    return response
  }
  validateEmailInfo = async options => {
    const { emailLookupInfo } = APPConfig?.getAppConfig() || ''
    const { postData } = options || {}
    const loadParams = {
      endPointName: 'emailLookupInfo',
      credentials: 'omit',
      deleteContentType: true,
      isToSendLocaleInQueryParam: false,
      headers: {
        Client_id: emailLookupInfo?.client_id || '',
      },
      queryParams: {
        email: postData?.email || '',
      },
    }
    let modifiedResponse = {}
    const response = await this.fetchResponse(loadParams)
    if (response?.data) {
      modifiedResponse.status = 'success'
      modifiedResponse.isEmailValid =
        response?.data?.isDomainValid && response?.data?.isFormatValid
      modifiedResponse.isFormatValid = response?.data?.isFormatValid || false
      modifiedResponse.isDomainValid = response?.data?.isDomainValid || false
      modifiedResponse.isAvailable = response?.data?.isAvailable || false
      modifiedResponse.isVerified = response?.data?.isVerified || false
    }
    return modifiedResponse
  }
  /**
   * Gets the user's country code from their profile, falling back to the store's
   * country if not available. Defaults to 'US'.
   *
   * Loops through the user's profile properties to find their countryCode attribute value,
   * assigning it to userCountry. If undefined, assigns the store's country code from
   * storeContainer. If still undefined, defaults to 'US'.
   *
   * @returns {string} The user's country code
   */
  getUsersCountry = () => {
    let userCountry = ''
    customerContainer?.profileResponse?.accounts?.properties?.forEach(
      element => {
        if (element.attributeId === 'countryCode') {
          userCountry = element.value === 'CA' ? 'Canada' : element.value
        }
      }
    )
    let storeCountry =
      storeContainer.storeIDValue === 'CA'
        ? 'Canada'
        : storeContainer.storeIDValue
    userCountry = userCountry || storeCountry
    return userCountry || 'US'
  }
  /**
   * This function is to check if the exclusive product is eligible for reorder or buyitagain
   * @date 1/12/2024 - 2:25:26 PM
   *
   * @param {[]} [orderItems=[]] orderItems from order history page
   * @param {} [reorderQty = 0] reorder input qty from reorder page
   */

  checkConditionsForExclusiveItems = (orderItems = [], reorderQty) => {
    const currentLocale = catalogContainer.getCurrentMarket() || 'US'
    //Find Exclusive Product
    let exclusiveItems = { available: [], not_available: [] }
    const exclusivePdts =
      orderItems?.filter(
        orderItem =>
          orderItem?.item?.itemInfo?.otherProperties?.isExclusive === 'true'
      ) || []

    if (exclusivePdts.length > 0) {
      exclusivePdts.forEach(product => {
        const productId = product?.item?.itemInfo?.sku?.productId || ''
        const orderhistoryQty =
          reorderQty && parseInt(reorderQty) !== 0
            ? parseInt(reorderQty)
            : product?.item?.itemInfo?.quantity || 0
        const cartItems = cartContainer.cartResponse?.items || []
        let maxProductQty = 0
        const productQualificationResponse =
          this.qualificationResponse?.qualification?.filter(qualification => {
            return (
              qualification?.productId === productId &&
              qualification?.markets?.includes(currentLocale)
            )
          })

        if (
          productQualificationResponse &&
          productQualificationResponse.length > 0
        ) {
          productQualificationResponse.forEach(qualification => {
            if (qualification?.isConsumable) {
              const customerQualificationQty = qualification?.quantity || 0

              let cartQty = 0
              let cartItem = {}
              const checkProductId = (skus = []) => {
                return (
                  skus?.length > 0 &&
                  skus?.some(sku => {
                    return sku?.productId === productId
                  })
                )
              }
              if (cartItems && cartItems.length > 0) {
                cartItems.some(item => {
                  const skus = item?.skus || []

                  if (checkProductId(skus)) {
                    cartQty = item?.quantity
                  }
                })
              }
              if (cartQty + orderhistoryQty < customerQualificationQty) {
                exclusiveItems.available.push(productId)
              } else if (cartQty + orderhistoryQty > customerQualificationQty) {
                exclusiveItems.not_available.push(productId)
              }
            }
          })
        }
      })
    }
    return exclusiveItems
  }
  updateVerifiedPhone = async (phoneNumber, verifyCode) => {
    const params = {
      endPointName: 'updateProfile',
      postData: {
        phoneNumber,
        email: this.profileResponse?.email,
        otp: verifyCode,
        customProperties: {},
      },
    }

    const profileFraudCheckEnabled =
      APPConfig?.getAppConfig()?.ProfileFraudCheckEnabled === 'true' || false
    const accertifyProps = (await getFraudDetectionProperties()) || {}
    if (profileFraudCheckEnabled) {
      params.postData.customProperties = {
        ...params.postData.customProperties,
        ...accertifyProps,
      }
    }
    const response = await this.fetchResponse(params)
    return response
  }
}

const customerContainer = new CustomerContainer()

export { customerContainer }
export default customerContainer
