import React from 'react'
// import { observable } from 'mobx'
import { V2ProductQuantity } from 'src/views/components/ProductComponents/ProductSkuDetails/ProductQuantity/V2ProductQuantity'
import { observer } from 'mobx-react'
import { PriceButtonsForPDP } from 'src/views/components/ProductComponents/ProductSkuDetails/PriceButtonsForPDP'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import {
  currencyFormat,
  i18nTranslate,
  checkEnableButton,
  ApiContent,
  getLiveEventStatus,
  convertToBoolean,
  trackExpressCheckoutEvents,
} from 'src/utils'
import { Button, Col, Row, Card, Container } from 'react-bootstrap'
import {
  customerContainer,
  promotionContainer,
  nuskinSubscriptionContainer,
  checkoutContainer,
} from 'src/models'
import {
  PayLaterMessage,
  isPaylaterMessageRequired,
} from 'src/views/components/PayPal'
import { productStatusDeps } from 'src/views/components/ProductComponents/V2ProductQuantityAndButtons/ProductStatusDeps'
import {
  KlarnaMessage,
  getTranslationKeyForCurrentTime,
} from 'src/views/components'
import isEmpty from 'lodash/isEmpty'
import { FrequencyDropdownV2 } from 'src/views/components/FrequencyDropdownV2'
import { productDeps } from 'src/deps'
import TagManager from 'react-gtm-module'
import {
  trackV2SubscriptionButtonClick,
  getEventNotificationTimeline,
} from 'src/utils'
import { IoIosArrowBack } from 'react-icons/io'
import { IS_BROWSER, APPConfig } from 'config/appConfig'
import './styles.scss'
import { cartContainer } from 'src/models'
import { DrawerModal } from 'src/views/components'
import { ExpressCheckout } from 'src/views/pages/ExpressCheckout'
import { observable } from 'mobx'
import Skeleton from 'react-loading-skeleton'
import { pageNames } from 'src/routes/pathParams'
import { getLocalStorage, deleteFromLocalStorage } from 'src/utils'
import { ProductReplacement } from '../ProductReplacement/ProductReplacement'
@observer
class V2ProductQuantityAndButtons extends React.Component {
  static contextType = ApiContent
  @observable isExpressCartBtn = false
  @observable showRightDrawer = true
  @observable showModalBody = false
  @observable isLoading = false

  constructor(props, context) {
    super(props, context)
    const isPaypalEnabled =
      APPConfig.getAppConfig()?.enablePaypal == 'true' || false
    if (isPaypalEnabled) {
      checkoutContainer.loadBraintreeCheckoutScript()
      checkoutContainer.loadBraintreeClientScript()
    }
  }
  enableLiveEvent = getLiveEventStatus()
  isEventRegisteredUser = ''

  checkEventRegisteredUser() {
    const profileResponse = customerContainer?.profileResponse
    const accountProperties = profileResponse?.accounts?.properties || []
    const userProperty = accountProperties?.filter(
      property => property?.attributeId === 'registeredEvent'
    )
    return userProperty?.[0]?.value || 'false'
  }

  getAccountType = () => {
    if (IS_BROWSER) {
      return customerContainer.profileResponse?.accounts?.type || ''
    }
    return this?.context?.customerType
  }

  isBAorPreferred = () => {
    const accountType = this.getAccountType()
    return (
      accountType === 'Brand Affiliate' ||
      accountType === 'Brand Affiliate - Business Entity' ||
      accountType === 'Preferred Customer/Member' ||
      false
    )
  }

  checkAndSetPriceOptionOnMountAndUpdate = (isFromComponentMount = false) => {
    const { shouldShowOneTimeButton, shouldShowSubscriptionButton } =
      this.getAvailableChannels()
    productState.currentlySelectedPriceOption = shouldShowOneTimeButton
      ? 'one-time-price-button'
      : 'subscription-price-button'
    // below call was made to get subscription promotion on page load
    // this is not required as both subscription & onetime facets are received in pdp call
    // hence commenting this

    // if (isFromComponentMount && !shouldShowOneTimeButton) {
    //   promotionContainer.makeV2PromotionCall(
    //     this.props.product,
    //     this.props.productCount,
    //     true
    //   )
    // }
  }
  componentDidMount = () => {
    this.checkAndSetPriceOptionOnMountAndUpdate(true)
    if (
      this.props?.isExpressPDP &&
      customerContainer?.isRegisterUser &&
      getLocalStorage('isExpressPDPLogin')
    ) {
      this.isExpressCartBtn = true
      this.showRightDrawer = true
      this.showModalBody = true
      deleteFromLocalStorage('isExpressPDPLogin')
    }
    if (this.props?.isExpressPDP && IS_BROWSER) {
      window.addEventListener('focus', this.checkForMultipleTabs)
    }
    // this.initializeCheckForManageSubscription()
    // currently commenting this out since there is no "manage subscription" feature
  }
  checkForMultipleTabs = async () => {
    if (this.isExpressCartBtn && this.showRightDrawer && this.showModalBody) {
      await cartContainer.viewCart()
      if (
        cartContainer.cartResponse?.items?.[0]?.skus?.[0]?.productId ===
          this.props?.product?.productId ||
        cartContainer.cartResponse?.items?.[0]?.properties?.slug ===
          this.props?.product?.slug
      ) {
        return
      }
      this.isExpressCartBtn = this.showRightDrawer = this.showModalBody = false
    }
  }

  componentWillUnmount = () => {
    if (IS_BROWSER) {
      window.removeEventListener('focus', this.checkForMultipleTabs)
    }
  }

  checkAndMakeSubscriptionCall = async () => {
    await nuskinSubscriptionContainer.init(this.props)
  }

  initializeCheckForManageSubscription = () => {
    const customerResponse =
      customerContainer?.profileResponse?.customer ||
      customerContainer?.profileResponse
    if (customerResponse?.id) {
      this.checkAndMakeSubscriptionCall()
    } else {
      setTimeout(() => {
        this.initializeCheckForManageSubscription()
      }, 300)
    }
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.product.availableChannels !=
      prevProps.product.availableChannels
    ) {
      this.checkAndSetPriceOptionOnMountAndUpdate()
    }
  }

  getPriceWithCurrency = priceValue => {
    const formattedPrice = currencyFormat({
      price: priceValue || 0,
    })
    return formattedPrice
  }

  isDiscountedPrice = totalValue =>
    totalValue?.priceAfterDiscount < totalValue?.originalPrice

  isNegativeDiscount = totalValue =>
    totalValue?.priceAfterDiscount > totalValue?.originalPrice

  isBundleOrKit =
    this.props.product?.type === 'bundle' || this.props.product?.type == 'kit'

  getAvailableChannels = () => {
    const availableChannelsList = this.isBundleOrKit
      ? this.props.product.properties?.availableChannels || ''
      : this.props.product.availableChannels || ''

    const shouldShowOneTimeButton = checkEnableButton(
      availableChannelsList,
      'web'
    )
    const shouldShowSubscriptionButton = checkEnableButton(
      availableChannelsList,
      'subscription'
    )
    return {
      shouldShowOneTimeButton,
      shouldShowSubscriptionButton,
    }
  }
  getTotalPriceFromTotalValueNode = value => {
    // we are manually selecting the total based on price option selected
    // here only one promotion call made
    const priceFacets = value?.priceFacets || {}
    let totalValue = ''
    if (productState.currentlySelectedPriceOption === 'one-time-price-button') {
      if (this.isBAorPreferred()) {
        const isZeroPriceAfterDiscount =
          priceFacets?.['Wholesale Price']?.['Wholesale PriceAfterDiscount'] ===
          0
        totalValue = isZeroPriceAfterDiscount
          ? 0
          : priceFacets?.['Wholesale Price']?.[
              'Wholesale PriceAfterDiscount'
            ] || priceFacets?.['Wholesale Price']?.['Wholesale Price']
      } else {
        const isZeroPriceAfterDiscount =
          priceFacets?.['Regular Price']?.['Regular PriceAfterDiscount'] === 0
        totalValue = isZeroPriceAfterDiscount
          ? 0
          : priceFacets?.['Regular Price']?.['Regular PriceAfterDiscount'] ||
            priceFacets?.['Regular Price']?.['Regular Price']
      }
    } else {
      if (this.isBAorPreferred()) {
        const isZeroPriceAfterDiscount =
          priceFacets?.['Sub Wholesale Price']?.[
            'Sub Wholesale PriceAfterDiscount'
          ] === 0
        totalValue = isZeroPriceAfterDiscount
          ? 0
          : priceFacets?.['Sub Wholesale Price']?.[
              'Sub Wholesale PriceAfterDiscount'
            ] || priceFacets?.['Sub Wholesale Price']?.['Sub Wholesale Price']
      } else {
        const isZeroPriceAfterDiscount =
          priceFacets?.['Sub Retail Price']?.[
            'Sub Retail PriceAfterDiscount'
          ] === 0
        totalValue = isZeroPriceAfterDiscount
          ? 0
          : priceFacets?.['Sub Retail Price']?.[
              'Sub Retail PriceAfterDiscount'
            ] || priceFacets?.['Sub Retail Price']?.['Sub Retail Price']
      }
    }
    return totalValue
  }

  getTotalDiscountFromTotalValueNode = value => {
    // we are manually selecting the total based on price option selected
    // here only one promotion call made
    const priceFacets = value?.priceFacets || {}
    let totalDiscount = ''
    if (productState.currentlySelectedPriceOption === 'one-time-price-button') {
      if (this.isBAorPreferred()) {
        totalDiscount =
          priceFacets?.['Wholesale Price']?.['Wholesale PriceDiscount'] || 0
      } else {
        totalDiscount =
          priceFacets?.['Regular Price']?.['Regular PriceDiscount'] || 0
      }
    } else {
      if (this.isBAorPreferred()) {
        totalDiscount =
          priceFacets?.['Sub Wholesale Price']?.[
            'Sub Wholesale PriceDiscount'
          ] || 0
      } else {
        totalDiscount =
          priceFacets?.['Sub Retail Price']?.['Sub Retail PriceDiscount'] || 0
      }
    }
    return totalDiscount
  }
  getTotalPriceWithCurrency = () => {
    const totalValueNode = productDeps.getTotalValueNode(this.props)
    const totalValue = this.getTotalPriceFromTotalValueNode(totalValueNode)
    return this.getPriceWithCurrency(totalValue)
  }

  renderTotalPrice = () => {
    if (this.props?.isExpressPDP) {
      return null
    }
    return (
      <div className="total-price-with-label-wrapper">
        <span className="total-label" data-testid="qa-pdp-total-text">
          {i18nTranslate('pdp.total', 'Total', {
            nameSpace: 'ssr-resource',
          })}
          :
        </span>
        <span className="total-price-wrapper" data-testid="qa-pdp-total-price">
          <span className="total-price-after-discount pl-2">
            {this.getTotalPriceWithCurrency()}
          </span>
        </span>
      </div>
    )
  }

  renderInventoryStatus = (
    messageToDisplay = '',
    isGreenBackOrderMessage = false,
    dataTestId = ''
  ) => {
    if (messageToDisplay) {
      return (
        <Row
          noGutters
          className="w-100 v2-inventory-message"
          data-testid={dataTestId}>
          <p
            className={`availability-text font-weight-bold p-0 m-0 ${
              isGreenBackOrderMessage ? 'text-success' : 'text-bundle-danger'
            }`}
            aria-live="polite"
            role="alert">
            {messageToDisplay}
          </p>
        </Row>
      )
    }
    return ''
  }
  getAddToCartButtonText = () => {
    const { product = {} } = this.props
    const { dateOfAvailablity = 0, isPreOrder = false } = product
    const preOrderDateTimeStamp = new Date(dateOfAvailablity).getTime()
    const currentDateTimeStamp = Date.now()
    const isPreOrderProduct =
      isPreOrder &&
      dateOfAvailablity &&
      preOrderDateTimeStamp >= currentDateTimeStamp
    if (this.props?.isExpressPDP) {
      return (
        <>
          <span className="buynow-btn">
            {i18nTranslate('expressCheckout.buyNow', 'BUY NOW', {
              nameSpace: 'ssr-resource',
            })}
          </span>
          <span>{this.getTotalPriceWithCurrency()}</span>
          {this.isLoading && (
            <span
              className="ml-2 spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
          )}
        </>
      )
    }
    return isPreOrderProduct
      ? i18nTranslate('pdp.b2cPreOrder', 'Preorder', {
          nameSpace: 'ssr-resource',
        })
      : i18nTranslate('addToCart', 'Add to cart', {
          nameSpace: 'ssr-resource',
        })
  }

  resetAndTrackFrequencyView = () => {
    productState.resetFrequencyStateInPDP()
    this.trackSubscriptionFrequencyView()
  }

  checkAndRenderFrequencyDropdown = () => {
    return (
      productState.currentlySelectedPriceOption ==
        'subscription-price-button' && (
        <Col
          lg={6}
          sm={12}
          xs={12}
          className="v2-subscription-frequency-dropdown">
          <FrequencyDropdownV2
            title={i18nTranslate('cart.frequency', 'Frequency', {
              nameSpace: 'ssr-resource',
            })}
            isFromPDP={true}
            handleSubscriptionFrequency={this.handlePDPSubscriptionFrequency}
            handleFrequencyDidMount={this.resetAndTrackFrequencyView}
            handleFrequencyUnmount={productState.resetFrequencyStateInPDP}
          />
        </Col>
      )
    )
  }
  renderQuantity = () => {
    const { handleQuantityChange, productCount, product = {} } = this.props
    return (
      <V2ProductQuantity
        handleQuantityChange={handleQuantityChange}
        productCount={productCount}
        handleQuantityChangePromotion={promotionContainer.makeV2PromotionCall}
        product={product}
      />
    )
  }
  renderDeviceSpecificView = () => {
    return (
      <>
        {this.checkAndRenderFrequencyDropdown()}
        <Row noGutters className="d-flex price-section-view-wrapper">
          {this.renderQuantity()}
          {this.renderTotalPrice()}
        </Row>
      </>
    )
  }
  shouldDisableButton = (
    shouldShowOneTimeButton = false,
    shouldShowSubscriptionButton = false,
    disableOneTime = false,
    disableSubscription = false,
    isAlreadySubscribedProduct = false,
    disableBundleKitOneTime = false,
    disableBundleKitSubscription = false
  ) => {
    // July Live event conditions for constraing add to cart button
    if (this.enableLiveEvent === 'true') {
      this.isEventRegisteredUser = this.checkEventRegisteredUser()
      // Disable add to cart button for below mentioned event timelines
      let evntKeysToDisable = [
        'beforeEvent',
        'afterEvent1',
        'afterEvent2',
        'eventOffline',
      ]
      let eventNotificationTimeline = getEventNotificationTimeline() || []
      let key = getTranslationKeyForCurrentTime(eventNotificationTimeline) || ''
      const { enableCartForLiveEvent } = APPConfig?.getAppConfig()

      if (
        (customerContainer.isRegisterUser &&
          this.isEventRegisteredUser === 'false') ||
        evntKeysToDisable.includes(key)
      ) {
        if (!convertToBoolean(enableCartForLiveEvent)) {
          return true
        }
      }
    }
    // CN locale users might get migrated for the July event site
    // Add to cart is disabled for them for non-event sites
    if (this.enableLiveEvent !== 'true') {
      const homeCountry = customerContainer?.getUserHomeCountry()
      const isUserFromCN = homeCountry === 'CN'
      if (isUserFromCN) {
        return true
      }
    }

    if (!shouldShowOneTimeButton && !shouldShowSubscriptionButton) {
      return true
    }
    if (productState.currentlySelectedPriceOption == 'one-time-price-button') {
      return disableOneTime || disableBundleKitOneTime
    } else {
      return (
        disableSubscription || disableBundleKitSubscription
        // ||  isAlreadySubscribedProduct
        // || nuskinSubscriptionContainer.isToDisableSubscriptionButton
      )
      // isAlreadySubscribedProduct - currently we are allowing all users
      // irrespective of their existing subscription status
      // isToDisableSubscriptionButton to disable when subscription call is in progress
      // if user has already subscribed, we need to prevent from adding to cart
    }
  }

  handlePDPSubscriptionFrequency = selectedValue => {
    const [frequencyPeriod, frequencyType] = selectedValue?.split?.('-')
    productState.selectedFrequencyValueInPDP = {
      frequencyPeriod,
      frequencyType,
    }
  }

  toggleSubscriptionModal = () => {
    if (nuskinSubscriptionContainer.isToShowDisabledModal) {
      nuskinSubscriptionContainer.isToShowDisabledModal = false
    } else {
      this.isToShowSubscriptionModal = !this.isToShowSubscriptionModal
      nuskinSubscriptionContainer.enableCartConvertMessage = false
      nuskinSubscriptionContainer.enableGoToCart = false
    }
  }

  toggleExpressCheckoutModal = async () => {
    this.showRightDrawer = false
    this.isExpressCartBtn = false
    this.showModalBody = false
    if (cartContainer.cartResponse?.items?.length) {
      await cartContainer.deleteCartItem(
        '',
        '',
        '',
        false,
        false,
        null,
        null,
        null,
        false
      )
    }
  }

  renderExpressCheckoutSkeleton = () => {
    return (
      <Container>
        <Row>
          <Col xs={12} className="d-flex justify-content-center my-2">
            <Skeleton rectangle={true} height={10} width={250} />
          </Col>
          <Col xs={6} className="my-2 px-3">
            <Skeleton
              rectangle={true}
              height={50}
              className="paymentButtonSkeleton"
            />
          </Col>
          <Col xs={6} className="my-2 px-3">
            <Skeleton
              rectangle={true}
              height={50}
              className="paymentButtonSkeleton"
            />
          </Col>
          <Col xs={12} className="mb-1">
            <hr />
          </Col>
          <Col xs={12}>
            <Skeleton
              className="mb-3"
              rectangle={true}
              height={25}
              width={80}
            />
            <Skeleton
              className="mb-3"
              rectangle={true}
              height={10}
              width={250}
            />
          </Col>
          <Col xs={9}></Col>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(item => (
            <Col xs={6}>
              <Skeleton rectangle={true} height={10} width={100} />
              <Skeleton className="mb-3" rectangle={true} height={35} />
            </Col>
          ))}
        </Row>
      </Container>
    )
  }

  renderExpressCheckout = () => {
    return <ExpressCheckout isFromPDP={true} history={this.props?.history} />
  }

  renderDrawer = () => {
    let title = cartContainer?.cartResponse?.items?.[0]?.skus?.[0]?.title
    if (title?.length > 40) title = 'Product Details'
    return (
      <DrawerModal
        renderView={
          this.showModalBody
            ? this.renderExpressCheckout
            : this.renderExpressCheckoutSkeleton
        }
        onHide={this.showModalBody ? this.toggleExpressCheckoutModal : ''}
        show={this.showRightDrawer}
        showCloseButton={false}
        testId="qa-express-buy-now-modal-header-wrapper"
        label="express checkout modal"
        title={
          <>
            {!this.showModalBody ? (
              <Skeleton rectangle={true} height={20} width={400} />
            ) : (
              <div
                role="button"
                className="modal-custom-title"
                tabIndex={0}
                onClick={this.toggleExpressCheckoutModal}>
                <IoIosArrowBack />
                <span>
                  {i18nTranslate('expresscart.backtotitle', 'Back to')} {title}
                </span>
              </div>
            )}
          </>
        }
        customClass="cart-single-subscription-modal modal-dialog-scrollable express-checkout-modal"
      />
    )
  }

  handleAddToCartClick = async props => {
    const { isReplacementProduct = false } = props || {}
    const totalValueNode = productDeps.getTotalValueNode(this.props)
    const totalPrice = this.getTotalPriceFromTotalValueNode(totalValueNode)
    const { product = {}, productCount = 1, isQuickview = false } = this.props
    const frequencyPeriod =
      productState.selectedFrequencyValueInPDP.frequencyPeriod || ''

    if (isReplacementProduct) {
      product.skuId =
        product?.replacementProduct?.[0]?.sku?.[0]?.identifier || ''
      product.productId = product?.replacementProduct?.[0]?.identifier || ''
      if (product?.properties) {
        product.properties.slug =
          product?.[0]?.replacementProduct?.[0]?.properties?.slug
      }
    }

    if (this.props?.isExpressPDP) {
      this.isLoading = true
      trackExpressCheckoutEvents({
        event: 'BuyNow',
        pagePath: pageNames.expressPDP,
        pageTitle: 'Express Product Details',
      })
      await cartContainer.viewCart()
      if (cartContainer.cartResponse?.items?.length) {
        await cartContainer.deleteCartItem(
          '',
          '',
          '',
          false,
          false,
          null,
          null,
          null,
          true
        )
      }
      const response = await productState.handleV2OneTimeAddToCart(
        product,
        productCount
      )
      this.isExpressCartBtn =
        this.showModalBody =
        this.showRightDrawer =
          response.status === 'success'
      if (
        this.showModalBody ||
        response.status === 'failure' ||
        response.status === 'error'
      ) {
        this.isLoading = false
      }
    } else {
      if (
        productState.currentlySelectedPriceOption == 'one-time-price-button'
      ) {
        productState.handleV2OneTimeAddToCart(
          product,
          productCount,
          totalPrice,
          isQuickview
        )
      } else {
        // @##pdp_scancard
        const scanCount = product.properties?.scanQualifiedCount || ''
        let postParameters =
          nuskinSubscriptionContainer.getPostBodyForAddSubscription(
            {
              product,
              frequency: frequencyPeriod,
              quantity: productCount,
              frequencyOrder: 'VARIABLE_MULTI_ORDER',
            },
            scanCount
          )
        /**
         * @info
         * GTM Event for Add to subscription Cart
         *triggers on click of subscribe button in pdp page
         */
        if (postParameters?.[0]) {
          postParameters[0].price = totalPrice || 0
        }
        TagManager.dataLayer({
          dataLayer: {
            event: 'addToSubscriptionCart',
            pagePath: this.props.history?.location?.pathname || '',
            pageTitle: 'Product Details',
            subscriptionInfo: postParameters,
            platform: 'equinox',
          },
        })
        await nuskinSubscriptionContainer.addSubscription(
          postParameters,
          product || {},
          true
        )
      }
    }
  }
  trackSubscriptionFrequencyView = () => {
    const frequencyPeriod =
      productState.selectedFrequencyValueInPDP.frequencyPeriod || ''
    const frequencyType =
      productState.selectedFrequencyValueInPDP.frequencyType || ''
    const totalValueNode = productDeps.getTotalValueNode(this.props)
    const totalValue = this.getTotalPriceFromTotalValueNode(totalValueNode)
    const totalDiscount =
      this.getTotalDiscountFromTotalValueNode(totalValueNode)
    trackV2SubscriptionButtonClick({
      event: 'subscriptionDetailItem',
      item_id: this.props.product.productId || '',
      discount: totalDiscount,
      total: totalValue || 0,
      quantity: this.props.productCount || '1',
      frequency: frequencyPeriod + ' ' + frequencyType,
      processing_day: '',
    })
  }

  render() {
    const { handleQuantityChange, productCount, product = {} } = this.props
    let isBundleProduct = productDeps.isBundleData(product)
    const selectedSku =
      product?.selectedSku ||
      productDeps.getUserSelectedSku({
        sku: product?.sku,
        skuId: product?.skuId,
      })
    const isproductStatus =
      selectedSku?.properties?.productStatus?.toLowerCase() == 'discontinued'
    const enableDiscontinuedSKUReplacement =
      convertToBoolean(
        APPConfig?.getAppConfig()?.enableDiscontinuedSKUReplacement
      ) || false
    const isReplacementProductStatus =
      Array.isArray(product?.replacementProduct) &&
      product?.replacementProduct?.every(
        item => typeof item === 'object' && item !== null
      ) &&
      isproductStatus &&
      !isBundleProduct &&
      enableDiscontinuedSKUReplacement
    const totalValueNode = productDeps.getTotalValueNode(this.props)
    const totalPrice = this.getTotalPriceFromTotalValueNode(totalValueNode)
    const userCountry = customerContainer.getUsersCountry()
    const { shouldShowOneTimeButton, shouldShowSubscriptionButton } =
      this.getAvailableChannels()
    const {
      messageToDisplay = '',
      disableOneTime = false,
      disableSubscription = false,
      isGreenBackOrderMessage = false,
      disableBundleKitOneTime = false,
      disableBundleKitSubscription = false,
      dataTestId = '',
    } = productStatusDeps.getV2ProductLevelInventoryStatus(
      product,
      productCount
    )
    const isAlreadySubscribedProduct =
      nuskinSubscriptionContainer.isAlreadySubscribedProduct(this.props)
    const unitValue = productDeps.getUnitValue(this.props)
    const isPaypalEnabled =
      APPConfig.getAppConfig()?.enablePaypal == 'true' || false

    return (
      <>
        {isReplacementProductStatus ? (
          <ProductReplacement
            {...this.props}
            handleAddToCartClick={this.handleAddToCartClick}
          />
        ) : (
          <Row
            noGutters
            className={`${isPaypalEnabled ? '' : 'v2-pdp-wrapper'} w-100`}>
            <>
              {!this.props?.isExpressPDP && (
                <Row noGutters className="w-100 price-buttons-wrapper">
                  <PriceButtonsForPDP
                    {...this.props}
                    unitValue={unitValue}
                    shouldShowOneTimeButton={shouldShowOneTimeButton}
                    shouldShowSubscriptionButton={shouldShowSubscriptionButton}
                  />
                </Row>
              )}
            </>
            <Col
              noGutters
              className="w-100 v2-quantity-total-frequency-wrapper">
              {this.renderDeviceSpecificView()}
            </Col>
            {this.renderInventoryStatus(
              messageToDisplay,
              isGreenBackOrderMessage,
              dataTestId
            )}

            {(shouldShowOneTimeButton || shouldShowSubscriptionButton) && (
              <Row
                noGutters
                className="w-100 v2-add-to-cart-wrapper  buy-now-button">
                <Button
                  className="v2-add-to-cart-button"
                  data-testid="qa-pdp-add-to-cart"
                  onClick={this.handleAddToCartClick}
                  disabled={this.shouldDisableButton(
                    shouldShowOneTimeButton,
                    shouldShowSubscriptionButton,
                    disableOneTime,
                    disableSubscription,
                    isAlreadySubscribedProduct,
                    disableBundleKitOneTime,
                    disableBundleKitSubscription
                  )}>
                  <div className="add-to-cart-button-label">
                    {this.getAddToCartButtonText()}
                  </div>
                </Button>
              </Row>
            )}

            {/* Klarna to be shown only when one time option is available */}
            {/*enableBtn?.atbBtn &&  */}
            {shouldShowOneTimeButton && totalPrice > 0 && (
              <div className="klarna-bundle-total-price-wrapper">
                <KlarnaMessage
                  price={totalPrice || 0}
                  id={'klarna-pdp-message-bundle-total-price'}
                  data-key={'credit-promotion-badge'}
                />
              </div>
            )}

            {/* Paypal paylater message for Bundle and Kit */}
            {isPaylaterMessageRequired(userCountry) &&
              isPaypalEnabled &&
              totalPrice > 0 && (
                <div className={'paylater-bundle-total-price-wrapper'}>
                  <PayLaterMessage
                    amount={totalPrice.toString()}
                    key={'paylater-bundle-total-price-wrapper'}
                    id={'paylater-bundle-total-price-wrapper'}
                  />
                </div>
              )}
            {this.isExpressCartBtn && this.renderDrawer()}
          </Row>
        )}
      </>
    )
  }
}

export { V2ProductQuantityAndButtons }
export default V2ProductQuantityAndButtons
