import React from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import './styles.scss'

export default class ExpressSectionHeader extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const renderArrows = () => {
      return this.props?.isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />
    }
    return (
      <h5
        role={this.props.disableAccordion ? 'heading' : 'button'}
        aria-expanded={!!this.props?.isOpen}
        tabIndex={0}
        className="express-accordion-header"
        onClick={() => !this.props.disableAccordion && this.props?.onClick()}>
        <span>{this.props.title}</span>
        {!this.props.disableAccordion && renderArrows()}
      </h5>
    )
  }
}
