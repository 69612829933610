import React, { useEffect, useState } from 'react'
import { Formik, useFormikContext } from 'formik'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import get from 'lodash/get'
import {
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Button,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap'
import { FormInputLabels } from 'src/deps'
import { DatePickerField } from './DatePickerField'
import { MaskInput } from './MaskInput'
import { PasswordField } from './PasswordField'
import { SponsorIdField } from './SponsorIdField'
import { SelectWithCheckbox } from './SelectWithCheckBox'
import './styles.scss'
import { EmailField } from './EmailField'
import { EmailVerified } from './EmailVerified'
import { EmailNotVerified } from './EmailNotVerified'
import { EmailVerifiedSMSVerify } from './EmailVerifiedSMSVerify'
import { EmailNotVerifiedSMSVerify } from './EmailNotVerifiedSMSVerify'
import { PreferedNameToolTip } from './PreferedNameToolTip'
import { i18nTranslate } from 'src/utils'
import { LinkAccount } from './LinkAccount'
import { FormToolTip } from './FormToolTip'
import { IoIosInformationCircleOutline, IoIosArrowDown } from 'react-icons/io'
import {
  BsFillExclamationCircleFill,
  BsFillCheckCircleFill,
} from 'react-icons/bs'
import { modalState } from '../ModalView'
import { checkoutContainer, accountsContainer } from 'src/models'
import BASponsorMessage from '../BASponsorMessage'
import { PhoneNotVerified } from './PhoneNotVerified'
import { PhoneVerified } from './PhoneVerified'

const RenderOptions = (optionJson, idx) => {
  const { value = '', label = '', disabled = false } = optionJson
  const optionValue = value || ''
  return (
    <React.Fragment key={`${label}-${idx}`}>
      <option value={optionValue} data-testid="qa-options" disabled={disabled}>
        {label}
      </option>
    </React.Fragment>
  )
}

const returnFormValues = props => {
  const {
    values,
    touched,
    getFormValuesOnChange,
    isFormValid,
    customHandleBlur,
  } = props
  // On change event passed to parent to get values on event blur
  if (typeof getFormValuesOnChange === 'function') {
    getFormValuesOnChange({ values, touched, isFormValid })
  }
  if (customHandleBlur) {
    customHandleBlur({ values, touched, isFormValid })
  }
}
const acceptOnlyNumbers = event => {
  let invalidChars = ['-', 'e', '+', 'E']
  if (invalidChars.includes(event.key)) {
    event.preventDefault()
  }
}

const getIsValidOrInvalidFeild = props => {
  const { errors, touched, name, values } = props
  accountsContainer.onSubmitInValid = accountsContainer?.submitCountValid
  if (errors && errors[name]) {
    if (
      !isArray(touched[name]) &&
      touched[name] &&
      values[name] === '' &&
      !accountsContainer?.onSubmitInValid
    ) {
      return false
    } else if (!isArray(touched[name]) && touched[name]) {
      return true
    } else if (isArray(touched[name]) && touched[name].length === 0) {
      return true
    } else {
      return false
    }
  }
  return false
}

const RenderInput = props => {
  // const { i18n } = useTranslation()
  // eslint-disable-next-line react/prop-types
  const formInputLabels = new FormInputLabels()
  const {
    touched,
    errors,
    name,
    type = 'text',
    isToHideLabel = false,
    labelKey,
    placeholderKey,
    onChange,
    handleBlur,
    customHandleBlur,
    values,
    options,
    inlineLabelKey,
    path,
    defaultValue,
    defaultChecked,
    disabled,
    maxLength,
    dataTestId,
    onOptionChange,
    classNameValue,
    className,
    onValueChange,
    getFormValuesOnChange,
    ariaLabel,
    isValid: isFormValid,
    autoComplete,
    customAttributes = {},
    attributeLabelName,
    labelInfo,
    ...remainingProps
  } = props
  const [countryLabel, setCountryLabel] = useState(false)
  const onBlur = event => {
    accountsContainer.onSubmitInValid = false
    handleBlur && handleBlur(event)
    returnFormValues({
      values,
      touched,
      getFormValuesOnChange,
      isFormValid,
      customHandleBlur,
      ...remainingProps,
    })
    setCountryLabel(false)
  }

  const onFocus = event => {
    setCountryLabel(true)
  }

  const i18Label = labelKey ? labelKey : name
  const labelName = attributeLabelName
    ? attributeLabelName
    : formInputLabels.getFormLabels(i18Label)
  const label = labelName || name

  const i18PlaceHolder = placeholderKey ? placeholderKey : name
  const placeholder = formInputLabels.getFormLabels(i18PlaceHolder) || name

  const isValid =
    type !== 'checkbox' && type !== 'radio' && touched[name] && !errors[name]
  const isInvalid = getIsValidOrInvalidFeild(props)
  const labelClass = classNameValue ? classNameValue.formLabelClassName : ''

  const value = name ? values?.[name] || defaultValue : defaultValue
  const shouldShowPlaceholder =
    value === '' || value === undefined || value === null

  let translatedAriaLabel = formInputLabels.getFormAriaLabels(ariaLabel)
  translatedAriaLabel = translatedAriaLabel ? translatedAriaLabel : label

  const controlProps = {
    type,
    name,
    label,
    isValid,
    isInvalid,
    onChange,
    onOptionChange,
    touched,
    placeholder: shouldShowPlaceholder ? placeholder : '',
    errors,
    onBlur,
    onFocus,
    values,
    options,
    // defaultValue,
    defaultChecked,
    disabled,
    className,
    inline: inlineLabelKey,
    path,
    maxLength,
    'data-testid': isInvalid ? `${dataTestId}-invalid` : dataTestId,
    onValueChange,
    value: get(values, name, defaultValue),
    'aria-label': isToHideLabel ? translatedAriaLabel : null,
    'aria-invalid': isInvalid,
    'aria-describedby': isInvalid
      ? name + '-feedback'
      : isValid
      ? 'success_entry'
      : null,
    'aria-valid': isValid,
    // 'aria-hidden': true,
    // 'aria-describedby': isValid ? 'success_entry' : null,
    autoComplete,
    dataTestId,
    countryLabel,
  }

  const renderInputContents = () => {
    return (
      <>
        <RenderFormControl
          {...controlProps}
          {...customAttributes}
          {...remainingProps}
          formInputLabels={formInputLabels}
          id={dataTestId}
        />
        <div className="display-duplicate-error">
          {accountsContainer?.duplicateEmailExist
            ? i18nTranslate(
                'signup.accountCreationFailure',
                'Something went wrong'
              )
            : accountsContainer?.duplicateTaxIdExist
            ? i18nTranslate(
                'signup.accountCreationTaxFailure',
                'This tax id is already in use'
              )
            : ''}
        </div>
        <Form.Control.Feedback
          id={name + '-feedback'}
          type="invalid"
          data-testid={`${dataTestId}-error`}>
          {errors[name]}
          {type === 'checkbox' || type === 'date' ? (
            <></>
          ) : (
            <BsFillExclamationCircleFill
              size="1rem"
              className={`tick-icon red valid-absolute`}
            />
          )}
        </Form.Control.Feedback>
        {controlProps?.value ? (
          <Form.Control.Feedback
            id={name + '-success'}
            type="isvalid"
            data-testid={`${dataTestId}-success`}>
            <BsFillCheckCircleFill
              size="1rem"
              className={`tick-icon green valid-absolute`}
            />
            <span id="success_entry">entry is valid</span>
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </>
    )
  }
  const sponserHintText = i18nTranslate('signup.whatIsThis', 'What is this?')
  return (
    <React.Fragment>
      {isToHideLabel && renderInputContents()}
      {!isToHideLabel && (
        <>
          <Form.Label
            className={labelClass}
            htmlFor={dataTestId}
            aria-label={translatedAriaLabel}
            data-testid={`${dataTestId}-label`}>
            {label}
            {props?.showToolTips && (
              <span className="sponsor-text">
                <a
                  aria-label={`${label} ${sponserHintText}`}
                  name="link"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  tabIndex={0}
                  onClick={event => {
                    let modalMsg = ''
                    if (props.values?.accountType === 'Brand Affiliate') {
                      modalMsg = (
                        <BASponsorMessage
                          showBAMessage={true}
                          isFromSignup={true}
                        />
                      )
                    } else {
                      modalMsg = (
                        <BASponsorMessage
                          showBAMessage={false}
                          isFromSignup={true}
                        />
                      )
                    }
                    event.preventDefault()
                    modalState.setModalMessage(
                      modalMsg,
                      i18nTranslate('catalog.OKButton', 'OK', {
                        nameSpace: 'ssr-resource',
                      })
                    )
                  }}>
                  <u>{sponserHintText}</u>
                </a>
              </span>
            )}{' '}
            <FormToolTip {...props} />
            {renderInputContents()}
          </Form.Label>
          {labelInfo}
        </>
      )}
    </React.Fragment>
  )
}

const OverlayTriggerField = props => {
  const { value } = props
  return (
    <OverlayTrigger
      placement="bottom-end"
      delay={{ show: 100, hide: 250 }}
      overlay={<div className="text-tooltip">{value}</div>}>
      <Form.Control {...props} />
    </OverlayTrigger>
  )
}

const handlePostalKeyUp = args => {
  const keyCode = args.event.keyCode
  const value = args.event.target.value || ''
  const upperValues = value.toUpperCase()
  args.event.target.value = upperValues
}

const RenderFormControl = props => {
  // const { i18n } = useTranslation()
  const {
    type,
    options,
    name,
    label,
    autoComplete = 'on',
    customId,
    value,
    handlePostalCodeKeyUp,
    handleDistrictList,
    handleWardList,
    setFieldValue,
    setFieldTouched,
    formjson,
    customClassName,
    handleStateListChange,
    countryLabel,
  } = props
  const [preferredLanguage, setPreferredLanguage] = useState('')

  if (type === 'select') {
    const { onOptionChange, toolTip, onChange, ...remainingProps } = props
    const handleChange = event => {
      if (customClassName?.split(' ')[0] === 'signup-country') {
        accountsContainer.countryDropdownValue = event?.target?.value
      }
      if (customClassName?.split(' ')[0] === 'payment-form-country') {
        checkoutContainer.selectedMarket = event?.target?.value
      }
      if (isFunction(onChange)) {
        onChange(event)
      }
      if (isFunction(onOptionChange)) {
        onOptionChange(event)
      }
      if (['stateList'].includes(name)) {
        if (handleStateListChange) {
          handleStateListChange(event, setFieldTouched)
        }
        handleDistrictList && handleDistrictList(event, formjson, setFieldValue)
      }
      if (['districtList'].includes(name)) {
        handleWardList && handleWardList(event, formjson)
      }
    }
    if (toolTip == 'langauge') {
      return (
        <>
          <InputGroup>
            <Form.Control
              as={type}
              {...remainingProps}
              onChange={handleChange}
              style={{ WebkitAppearance: 'none' }}
              autoComplete={autoComplete}>
              {options.map(RenderOptions)}
            </Form.Control>
            <OverlayTrigger
              trigger="click"
              rootClose={true}
              overlay={
                <Tooltip id={`tooltip`}>
                  {i18nTranslate(
                    'preferedLangaugeTooltip',
                    'Preferred language is the language used for marketing emails and SMS messaging.',
                    {
                      nameSpace: 'ssr-resource',
                    }
                  )}
                </Tooltip>
              }>
              <div className="info">
                <Button
                  aria-label="more information about preferred language"
                  className="infoIconBtn">
                  <IoIosInformationCircleOutline className=" infoIcon" />
                </Button>
              </div>
            </OverlayTrigger>
          </InputGroup>
          <IoIosArrowDown className="langauge-select-arrow" />
        </>
      )
    } else {
      return props?.phoneNumberDropDown === true ? (
        <>
          <Form.Control
            name="phoneCountryCode"
            as={type}
            {...remainingProps}
            autoComplete={autoComplete}
            style={{ WebkitAppearance: 'none' }}
            onChange={handleChange}>
            {props?.options.map((country, index) => (
              <option key={index} value={country.value}>
                {countryLabel ? country.label : country.displayValue}
              </option>
            ))}
          </Form.Control>
          <IoIosArrowDown
            className="phoneCountryCode-select-arrow"
            disabled={remainingProps?.disabled}
          />
        </>
      ) : (
        <>
          <Form.Control
            as={type}
            {...remainingProps}
            onChange={handleChange}
            style={{ WebkitAppearance: 'none' }}
            autoComplete={autoComplete}>
            {options.map(RenderOptions)}
          </Form.Control>
          <IoIosArrowDown className="select-arrow" />
        </>
      )
    }
  } else if (type === 'checkbox' || type === 'radio') {
    const { 'data-testid': dataTestId, errors } = props
    // TODO : tobe modified export props and remaining props
    const handleChange = event => {
      const valueToSet = event.currentTarget.checked
        ? `${dataTestId}-selected`
        : dataTestId
      event.currentTarget.setAttribute('data-testid', valueToSet)
      if (isFunction(props.onValueChange)) {
        props.onValueChange(event, setFieldValue)
      }
      props.onChange(event)
    }
    const testId =
      props.defaultChecked || props.checked
        ? `${dataTestId}-selected`
        : dataTestId
    // as={type} // removed since form has a different behavior if we give AS property
    const id = customId !== undefined ? customId : name
    const randomNumber = Math.floor(Math.random() * 100)
    return props?.isFieldSet === true ? (
      <fieldset>
        <legend>{label}</legend>
        {props.optionSet.map((data, idx) => (
          <>
            <Form.Check
              key={idx}
              id={`custom${idx}-${randomNumber}`}
              {...data}
              label={
                props?.formInputLabels.getFormLabels(data.name) || data.name
              }
              onChange={handleChange}
              data-testid={testId}
              feedback={errors[data.name]}
              value={true}
            />
            {errors && (
              <Form.Control.Feedback
                id={name + '-feedback'}
                type="invalid"
                data-testid="qa-invalid-value-error"
                name={(data?.name || 'form') + '-error'}
                className="mb-1">
                {errors[data.name]}
              </Form.Control.Feedback>
            )}
          </>
        ))}
      </fieldset>
    ) : (
      <Form.Check
        id={`custom${id}-${randomNumber}`}
        {...props}
        label={label}
        onChange={handleChange}
        data-testid={testId}
        feedback={errors[name]}
      />
    )
  } else if (type === 'password') {
    return <PasswordField {...props} />
  } else if (type === 'dropdown') {
    const {
      onOptionChange,
      toolTip,
      onChange,
      disabled = false,
      value,
      ...remainingProps
    } = props
    const handleOptionChange = (event, optionValue) => {
      setPreferredLanguage(event.target.text)
      onChange(event, setFieldValue, optionValue)
    }
    const RenderDropdownOptions = (optionJson, idx) => {
      const { value = '', label = '', disabled = false } = optionJson
      const optionValue = value || ''
      return (
        <Dropdown.Item
          key={`${label}-${idx}`}
          eventKey={optionValue}
          role="menu"
          aria-label={label}
          data-testid="qa-options"
          onClick={event => handleOptionChange(event, optionValue)}
          disabled={disabled}>
          {label}
        </Dropdown.Item>
      )
    }
    return (
      <>
        <InputGroup className="lang-dropdown-type">
          <DropdownButton
            title={value ? value : preferredLanguage}
            disabled={disabled}
            id="preferred-Language"
            data-testid="qa-prefered-language-dropdown">
            {options.map(RenderDropdownOptions)}
          </DropdownButton>
          <IoIosArrowDown className="langauge-select-arrow" />
          <OverlayTrigger
            trigger="click"
            rootClose={true}
            overlay={
              <Tooltip id={`tooltip`} role="alert">
                {i18nTranslate(
                  'preferedLangaugeTooltip',
                  'Preferred language is the language used for marketing emails and SMS messaging.',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )}
              </Tooltip>
            }>
            <div className="info">
              <Button
                aria-label="more information about preferred language"
                className="infoIconBtn"
                data-testid="qa-prefered-language-info">
                <IoIosInformationCircleOutline className=" infoIcon" />
              </Button>
            </div>
          </OverlayTrigger>
        </InputGroup>
      </>
    )
  } else if (type === 'accEmail') {
    return <EmailField {...props} />
  } else if (type === 'valEmail') {
    return <EmailVerified />
  } else if (type === 'notValEmail') {
    return <EmailNotVerified />
  } else if (type === 'valEmailSMSVerify') {
    return <EmailVerifiedSMSVerify />
  } else if (type === 'notValEmailSMSVerify') {
    return <EmailNotVerifiedSMSVerify />
  } else if (type === 'notValPhone') {
    return <PhoneNotVerified />
  } else if (type === 'valPhone') {
    return <PhoneVerified />
  } else if (type === 'convertAccount') {
    return <LinkAccount {...props} />
  } else if (type === 'prNToolTip') {
    return <PreferedNameToolTip {...props} />
  } else if (type === 'textarea') {
    return <Form.Control as="textarea" {...props} />
  } else if (type === 'selectWithCheckbox') {
    return <SelectWithCheckbox {...props} />
  } else if (type === 'date') {
    return <DatePickerField {...props} />
  } else if (type === 'number') {
    return <Form.Control {...props} onKeyDown={e => acceptOnlyNumbers(e)} />
  } else if (
    type === 'text' &&
    value &&
    value.length >= 50 &&
    props?.isOverlayRequired === true
  ) {
    return <OverlayTriggerField {...props} />
  } else if (type === 'label') {
    return <label {...props} />
  } else if (type === 'div') {
    return <div {...props} />
  } else if (type === 'mask') {
    return <MaskInput {...props} />
  } else if (
    type === 'text' &&
    ['postalCode', 'signupPostalCode'].includes(name)
  ) {
    const onKeyUp = event => {
      handlePostalCodeKeyUp &&
        handlePostalCodeKeyUp(event, setFieldValue, formjson, setFieldTouched)
    }
    return <Form.Control {...props} onKeyUp={event => onKeyUp(event)} />
  } else if (
    type === 'text' &&
    name === 'sponsorId' &&
    props?.sponsorFromSignup === true
  ) {
    return <SponsorIdField {...props} />
  }

  return <Form.Control {...props} />
}

const RenderFormContent = props => {
  const { type, RenderProps, SubmitButtonProps } = props
  if (type == 'inlineSubmitButton') {
    return <SubmitButtonProps />
  } else if (RenderProps) {
    return RenderProps
  }
  return <RenderInput {...props} />
}

const RenderFormWithColumn = props => {
  // eslint-disable-next-line react/prop-types
  const {
    columnType,
    classNameValue,
    customClassName = '',
    customDataTestId = '',
  } = props
  const classStyles = classNameValue?.formColumnClassName || ''
  return (
    <Col
      {...columnType}
      className={`${classStyles} ${customClassName}`}
      data-testid={customDataTestId}>
      <RenderFormContent {...props} />
    </Col>
  )
}

const RenderFormInputsFromJson = props => {
  const { formjson, classNameValue, name, touched, values } = props
  const classStyles = classNameValue?.formRowClassName || ''

  return formjson?.map((inputJsonArray, arrrayIdx) => {
    if (isArray(inputJsonArray)) {
      return (
        <Form.Row
          className={`${classStyles} ${name}`}
          key={`${name}${arrrayIdx}`}>
          {inputJsonArray.map((inputJson, inputIdx) => {
            const { name } = inputJson
            return (
              <RenderFormWithColumn
                {...props}
                {...inputJson}
                key={`${name}${inputIdx}`}
              />
            )
          })}
        </Form.Row>
      )
    } else {
      const { name } = inputJsonArray
      return (
        <Form.Row
          className={`${classStyles} ${name}`}
          key={`${name}${arrrayIdx}`}>
          <RenderFormWithColumn {...props} {...inputJsonArray} />
        </Form.Row>
      )
    }
  })
}

class CommonForm extends React.Component {
  currentRef = React.createRef
  handleSubmit = (validatedData, { resetForm }) => {
    const { onSubmit, isToResetForm = false } = this.props
    if (isToResetForm) {
      resetForm()
    }
    if (typeof onSubmit === 'function') {
      onSubmit(validatedData)
    }
  }

  getInitialValues = formInput => {
    let initialValue = {}
    const formattedFormInput = formInput?.flat()
    formattedFormInput?.forEach(input => {
      const { type, defaultChecked, defaultValue, name, initValue } =
        input || {}
      const isCheckBoxOrRadio = type === 'checkbox' || type === 'radio'
      const value = isCheckBoxOrRadio ? defaultChecked : defaultValue
      initialValue[name] =
        initValue !== undefined
          ? initValue[name]
          : value
          ? value
          : isCheckBoxOrRadio
          ? false
          : ''
    })
    return initialValue
  }
  render() {
    const {
      schema,
      formTypeJson,
      SubmitButtonProps,
      hasInlineSubmitButton = true,
      classNameValue,
      id,
      addNewAddressList,
      customHandleBlur,
      enableReinitialize,
    } = this.props

    const classStyles = classNameValue ? classNameValue.formClassName : ''
    const initialValues = this.getInitialValues(formTypeJson)

    const getFieldErrorNames = formikErrors => {
      const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
        Object.keys(obj).forEach(key => {
          const value = obj[key]
          if (!value) return

          const nextKey = prefix ? `${prefix}.${key}` : key
          if (typeof value === 'object') {
            transformObjectToDotNotation(value, nextKey, result)
          } else {
            result.push(nextKey)
          }
        })
        return result
      }

      return transformObjectToDotNotation(formikErrors)
    }

    const ScrollToFieldError = ({
      scrollBehavior = { behavior: 'smooth', block: 'center' },
    }) => {
      const { submitCount, isValid, errors, values, dirty, touched } =
        useFormikContext()
      accountsContainer.submitCountValid = submitCount > 0 ? true : false
      let continueBtnFocus =
        dirty &&
        Object.keys(errors).length == 0 &&
        Object.keys(touched).length >= 7

      //once shipping subscription form required fields are entered, continueBtn is focused
      if (continueBtnFocus) {
        document.querySelector('#continueBtn')?.focus()
      }
      // ------if field value is present we can avoid the invalid msg--------
      if (values?.accountType !== '') {
        delete errors.accountType
      }
      // --------------------------------------------------------------------
      useEffect(() => {
        if (isValid) return

        const fieldErrorNames = getFieldErrorNames(errors)
        if (fieldErrorNames.length <= 0) return
        let element = null

        const accTypeIndex = fieldErrorNames?.findIndex(
          val => val === 'accountType' || val === 'stateList'
        )
        const dobIndex = fieldErrorNames?.findIndex(
          val => val === 'dateOfBirth'
        )
        if (accTypeIndex >= 0) {
          element = document.querySelector(
            `select[name='${fieldErrorNames[accTypeIndex]}']`
          )
        } else if (dobIndex >= 0) {
          element = document.querySelector(
            `input[id='${fieldErrorNames[dobIndex]}']`
          )
        } else {
          element = document.querySelector(
            `input[name='${fieldErrorNames[0]}']`
          )
        }

        if (!element) return
        // Scroll to first known error into view
        element.scrollIntoView(scrollBehavior)
        // Formik doesn't (yet) provide a callback for a client-failed submission,
        // thus why this is implemented through a hook that listens to changes on
        // the submit count.
      }, [submitCount])
      return null
    }

    //https://github.com/jaredpalmer/formik/issues/1116#issuecomment-486598649 for isInitialValid

    /**
     * @note
     * isInitialValid was deprecated in 2.x. Formik version in package.json: "^2.1.5"
     * Current error in the console: "Warning: isInitialValid has been deprecated and will be removed in future versions of Formik. Please use initialErrors or validateOnMount instead."
     * async validation of some form fields with defaultValues cause error on initial/first render. So, validation on initial render is disabled with validateOnMount={false}.
     */
    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        innerRef={this.props?.reference || this.currentRef}
        enableReinitialize={enableReinitialize === false ? false : true}
        validateOnMount={false}>
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setTouched,
          resetForm,
          validateForm,
          setFieldTouched,
          setFieldValue,
          // ...remainingProps
        }) => {
          const propsToPass = {
            onChange: handleChange,
            touched,
            errors,
            handleBlur,
            customHandleBlur,
            values,
            formjson: formTypeJson,
            classNameValue,
            SubmitButtonProps,
            getFormValuesOnChange: this.props.getFormValuesOnChange,
            setTouched,
            isValid,
            resetForm,
            validateForm,
            setFieldTouched,
            setFieldValue,
          }
          isFunction(addNewAddressList) &&
            addNewAddressList({ validateForm, values, setTouched, isValid })
          return (
            <Form
              className={classStyles}
              onSubmit={handleSubmit}
              id={id}
              data-testid={this.props.dataTestId}>
              <ScrollToFieldError />
              {<RenderFormInputsFromJson {...propsToPass} />}
              {!hasInlineSubmitButton && <SubmitButtonProps {...propsToPass} />}
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default CommonForm
export { CommonForm }
