import React, { Component } from 'react'
import { i18nTranslate } from 'src/utils'
import { Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LocaleModal from '../../LocaleModal'
import { customerContainer } from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import { getLocalStorage } from 'src/utils'
import { KoreChat } from 'src/views/components/KoreChat/KoreChat'
import { getLocaleBasedLink } from './staticDropDownLinks'
import { APPConfig } from 'config/appConfig'

/**
 * LocaleSection React component class.
 * Extends React Component.
 */
class LocaleSection extends Component {
  nameSpace = 'ssr-resource'
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  /**
   * Returns the link URL for joining/registering based on whether the
   * user is already registered.
   *
   * Checks the isRegisterUser value from the customerContainer service
   * to determine if the user is already registered.
   *
   * Returns the myaccountsubscriptions page name if registered,
   * otherwise returns the /signup path.
   */
  getJoiningLink = () => {
    const isRegisterUser = customerContainer.isRegisterUser
    return isRegisterUser ? pageNames.myaccountsubscriptions : '/signup'
  }

  /**
   * Renders a link to the 'Chat With Us' page using the
   * getLocaleBasedLink() method to construct the locale-specific URL.
   *
   * Returns a <Col> component containing the localized chat link.
   */
  renderChatWithUs = () => {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={getLocaleBasedLink()}
        //onclick={this.getLocaleBasedLink()}
      >
        {i18nTranslate('header.chat', 'Chat', { nameSpace: this.nameSpace })}
      </a>
    )
  }

  /**
   * Renders the locale section of the header.
   *
   * Gets account type from localStorage, base URL, AI chat config,
   * and chat config from APPConfig.
   *
   * Conditionally renders the LocaleModal, legal links, AI chat,
   * chat with us link, and join our team link based on config.
   *
   * Returns a React section component containing the locale section contents.
   */
  render() {
    const accountType = getLocalStorage('accountType')
    const baseurl = 'https://www.nuskin.com'
    const enableAIChat =
      APPConfig?.getAppConfig()?.enableAIChat == 'true' || false
    const enableChatWithUs =
      APPConfig?.getAppConfig()?.enableChatWithUs == 'true' || false
    const { isFromPersonalHeader = false } = this.props
    return (
      <section className="px-lg-3 locale-section d-none d-md-block">
        <Container
          className="px-sm-3 px-lg-0 locale-container"
          style={{ maxWidth: '1366px' }}>
          <div className="d-flex h-100 align-items-center justify-content-start">
            <div className="pr-3">
              <LocaleModal
                history={this.props.history}
                isNuskinHeader
                {...this.props}
              />
            </div>
            {/* {this.props.data?.market?.legalLinks.map((legalLink, index) => {
              return (
                <a
                  key={index}
                  className="nu-legallinks pr-3"
                  href={baseurl + legalLink.href}>
                  {legalLink.name}
                </a>
              )
            })} */}
            {enableAIChat && !isFromPersonalHeader && (
              <Col className="kore-ai-chat mt-1">
                <KoreChat />
              </Col>
            )}
            {enableChatWithUs && !isFromPersonalHeader && (
              <Col className="chat-with-us mt-1 px-0">
                {this.renderChatWithUs()}
              </Col>
            )}
          </div>
        </Container>
      </section>
    )
  }
}

export default LocaleSection
export { LocaleSection }
