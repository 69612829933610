import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Row, Container, Col } from 'react-bootstrap'
import { tryJSONParse } from 'src/utils'
import { AccordionBlockPDP } from 'src/views/components/AccordionBlockPDP'
import { MarkdownComponent } from 'src/views/components/MarkdownComponent'
import { getProductPDPTitle } from 'src/utils/commonUtils'
import { APPConfig } from 'config/appConfig'
import './styles.scss'

@observer
class ProductSustainability extends React.Component {
  nameSpace = 'ssr-resource'
  
  LinkRenderer = link => {
    return (
      <a className="sustainability-link" href={link.href} target="_blank" rel="noreferrer">
        {link.children}
      </a>
    )
  }

  isYouTubeVideo = (videoUrl = '') => {
    if (videoUrl != '') {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = videoUrl && videoUrl.match(regExp)
      let videoId = match?.[2].length === 11 ? match[2] : null
      if (videoUrl && videoUrl.match(regExp)) {
        return true
      }
    }
    return false
  }

  // duplicate code need to clean up
  getMedia = mediaProps => {
    const {
      url = '',
      alt = i18nTranslate('pdp.defaultAlt', 'Sustainability Video', {
        nameSpace: this.nameSpace,
      }),
      index = 0,
      isToShowControls = true,
      thumbnail = '',
    } = mediaProps
    const videoUrlRegEx = /^https?:\/\/.*\/.*\.(mp4|webm|ogg)\??.*$/gim
    const imageUrlRegEx = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i
    if (this.isYouTubeVideo(url)) {
      return (
        <iframe
          tabIndex={index}
          width="100%"
          className="media"
          src={`https://www.youtube.com/embed/${videoId}?controls=${
            isToShowControls ? '0' : '1'
          }`}
        />
      )
    } else if (url.match(videoUrlRegEx)) {
      return (
        <video
          tabIndex={index}
          className="sustainability-video"
          width="100%"
          controls={isToShowControls}
          poster={thumbnail}>
          <source src={url} />
        </video>
      )
    } else if (url.match(imageUrlRegEx)) {
      return (
        <img
          alt=""
          tabIndex={index}
          width="100%"
          controls={isToShowControls}
          src={url}
        />
      )
    }
    return <></>
  }

  renderTitle = () => {
    const { isAccordionType = false } = this.props
    //Sustainability yet to map from api so it is undefined now
    const sustainabilityLable = getProductPDPTitle(
      this.props.product?.tempProperties,
      'sustainability'
    )
    return (
      <Row
        className={`about-title ${
          isAccordionType ? 'text-uppercase' : 'border-light-grey'
        }`}
        noGutters>
        {sustainabilityLable ||
          i18nTranslate(
            'pdp.sustainability',
            'Social Impact and Sustainability',
            {
              nameSpace: this.nameSpace,
            }
          )}
      </Row>
    )
  }

  renderDescription = (Data = '[]') => {
    const parsedDescription = tryJSONParse(Data)
    const isArray = Array.isArray(parsedDescription)
    if (isArray) {
      return (
        <ul>
          {parsedDescription.map((desc, index) => {
            if (typeof desc === 'object') {
              return <li key={`desc-${index}`}>{desc?.description}</li>
            } else if (desc) {
              return <li key={`desc-${index}`}>{desc}</li>
            }
            return ''
          })}
        </ul>
      )
    }
    return (
      <div>
        <Row
          className="about-product pb-3 text-dark"
          noGutters
          dangerouslySetInnerHTML={{
            __html: Data,
          }}
        />
      </div>
    )
  }

  renderHightLights = highlight => {
    const { description = '', image } = highlight
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    // use image component to get the optimized image
    return (
      <Col
        className={`${
          enableNewPDPStyle
            ? 'v2-substainability-img-container'
            : 'col-md-6 d-flex mb-3 pr-0 align-items-center'
        }`}>
        {image?.url && (
          <div className="sustainability-img-desc">
            <img
              className="sustainability-img"
              src={image?.url || ''}
              alt={image?.alt || ''}
              // aria-hidden="true"
            />
          </div>
        )}
        <div className="para-substainability paragraph-s">         
          <MarkdownComponent>
            {({ default: Markdown }) =>
              <Markdown components={{ a: this.LinkRenderer }}>{description || ' '}</Markdown>
            }
          </MarkdownComponent>
        </div>
      </Col>
    )
  }

  getHighLights = highlights => {
    if (highlights) {
      const highLightsEl = highlights?.map?.((highlight, idx) => {
        return <>{this.renderHightLights(highlight)}</>
      })
      return <Row className="d-flex highLights">{highLightsEl}</Row>
    }
    return <></>
  }

  renderOldPDPView = (highlights, description) => {
    return (
      <div
        className={`sustainability-accordion d-flex flex-wrap align-items-start justify-content-between`}>
        <div className="sustainability-content paragraph-m">
          <p className="paragraph-m">{description}</p>
        </div>
        <div className="sustainability-inner">
          <div className="product-det-lists">
            {this.getHighLights(highlights)}
          </div>
        </div>
      </div>
    )
  }
  renderNewPDPView = (highlights, description) => {
    return (
      <div
        className={`sustainability-accordion d-flex flex-wrap align-items-start justify-content-between`}>
        <div className="product-det-lists">
          {this.getHighLights(highlights)}
        </div>
        <div className="sustainability-content paragraph-m">
          <p className="paragraph-m">{description}</p>
        </div>
      </div>
    )
  }

  render() {
    const { product } = this.props || {}
    let sustainability = {}

    if (product?.sustainability) {
      try {
        sustainability = JSON.parse(product?.sustainability)
      } catch (e) {}
    } else {
      return ''
    }

    const {
      description = '',
      isAccordionType = false,
      video = '',
      ingredients = '',
      youTubeVideoId,
      highlights,
      image,
    } = sustainability

    if (!description) {
      return <></>
    }
    const hasMedia = youTubeVideoId || image?.url || false
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    return (
      <>
        <div className="product-accordion-wrapper ">
          <AccordionBlockPDP
            title={this.renderTitle()}
            isDefaultOpen={false}
            className="accordion-description"
            dataTestId="qa-pdp-product-sustainability">
            <Container className="pdp-accordion-content-container">
              {hasMedia && (
                <div className="my-3 media-container">
                  {this.getMedia({
                    url: youTubeVideoId || image?.url,
                    thumbnail: image?.thumbnail,
                    alt: image?.alt,
                  })}
                </div>
              )}
              {enableNewPDPStyle
                ? this.renderNewPDPView(highlights, description)
                : this.renderOldPDPView(highlights, description)}
            </Container>
          </AccordionBlockPDP>
        </div>
      </>
    )
  }
}

export { ProductSustainability }
