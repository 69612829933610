import { i18nTranslate } from 'src/utils'

export const countryData = () => {
  return [
    {
      label: i18nTranslate('country.argentinaPhoneCode', 'Argentina(+54)'),
      value: 'AR',
      displayValue: 'AR(+54)',
    },
    {
      label: i18nTranslate('country.austriaPhoneCode', 'Austria(+43)'),
      value: 'AT',
      displayValue: 'AT(+43)',
    },
    {
      label: i18nTranslate('country.australiaPhoneCode', 'Australia(+61)'),
      value: 'AU',
      displayValue: 'AU(+61)',
    },
    {
      label: i18nTranslate('country.belgiumPhoneCode', 'Belgium(+32)'),
      value: 'BE',
      displayValue: 'BE(+32)',
    },
    {
      label: i18nTranslate('country.bruneiPhoneCode', 'Brunei(+673)'),
      value: 'BN',
      displayValue: 'BN(+673)',
    },
    {
      label: i18nTranslate('country.canadaPhoneCode', 'Canada (+1)'),
      value: 'CA',
      displayValue: 'CA(+1)',
    },
    {
      label: i18nTranslate('country.switzerlandPhoneCode', 'Switzerland(+41)'),
      value: 'CH',
      displayValue: 'CH(+41)',
    },
    {
      label: i18nTranslate('country.chilePhoneCode', 'Chile(+56)'),
      value: 'CL',
      displayValue: 'CL(+56)',
    },
    {
      label: i18nTranslate('country.colombiaPhoneCode', 'Colombia(+57)'),
      value: 'CO',
      displayValue: 'CO(+57)',
    },
    {
      label: i18nTranslate('country.czechPhoneCode', 'Czech Republic(+420)'),
      value: 'CZ',
      displayValue: 'CZ(+420)',
    },
    {
      label: i18nTranslate('country.germanyPhoneCode', 'Germany(+49)'),
      value: 'DE',
      displayValue: 'DE(+49)',
    },
    {
      label: i18nTranslate('country.denmarkPhoneCode', 'Denmark(+45)'),
      value: 'DK',
      displayValue: 'DK(+45)',
    },
    {
      label: i18nTranslate('country.spainPhoneCode', 'Spain(+34)'),
      value: 'ES',
      displayValue: 'ES(+34)',
    },
    {
      label: i18nTranslate('country.finlandPhoneCode', 'Finland(+358)'),
      value: 'FI',
      displayValue: 'FI(+358)',
    },
    {
      label: i18nTranslate('country.francePhoneCode', 'France(+33)'),
      value: 'FR',
      displayValue: 'FR(+33)',
    },
    {
      label: i18nTranslate(
        'country.unitedkingdomPhoneCode',
        'United Kingdom(+44)'
      ),
      value: 'GB',
      displayValue: 'GB(+44)',
    },
    {
      label: i18nTranslate('country.hongkongPhoneCode', 'Hong Kong(+852)'),
      value: 'HK',
      displayValue: 'HK(+852)',
    },
    {
      label: i18nTranslate('country.hungaryPhoneCode', 'Hungary(+36)'),
      value: 'HU',
      displayValue: 'HU(+36)',
    },
    {
      label: i18nTranslate('country.indonesiaPhoneCode', 'Indonesia(+62)'),
      value: 'ID',
      displayValue: 'BN(+62)',
    },
    {
      label: i18nTranslate('country.irelandPhoneCode', 'Ireland(+353)'),
      value: 'IE',
      displayValue: 'IE(+353)',
    },
    {
      label: i18nTranslate('country.israelPhoneCode', 'Israel(+972)'),
      value: 'IL',
      displayValue: 'IL(+972)',
    },
    {
      label: i18nTranslate('country.icelandPhoneCode', 'Iceland(+354)'),
      value: 'IS',
      displayValue: 'IS(+354)',
    },
    {
      label: i18nTranslate('country.italyPhoneCode', 'Italy(+39)'),
      value: 'IT',
      displayValue: 'IT(+39)',
    },
    {
      label: i18nTranslate('country.japanPhoneCode', 'Japan(+81)'),
      value: 'JP',
      displayValue: 'JP(+81)',
    },
    {
      label: i18nTranslate('country.southkoreaPhoneCode', 'South Korea(+82)'),
      value: 'KR',
      displayValue: 'KR(+82)',
    },
    {
      label: i18nTranslate('country.luxembourgPhoneCode', 'Luxembourg(+352)'),
      value: 'LU',
      displayValue: 'LU(+352)',
    },
    {
      label: i18nTranslate('country.macauPhoneCode', 'Macau(+853)'),
      value: 'MO',
      displayValue: 'MO(+853)',
    },
    {
      label: i18nTranslate('country.mexicoPhoneCode', 'Mexico(+52)'),
      value: 'MX',
      displayValue: 'MX(+52)',
    },
    {
      label: i18nTranslate('country.malaysiaPhoneCode', 'Malaysia(+60)'),
      value: 'MY',
      displayValue: 'MY(+60)',
    },
    {
      label: i18nTranslate(
        'country.newcaledoniaPhoneCode',
        'New Caledonia(+687)'
      ),
      value: 'NC',
      displayValue: 'NC(+687)',
    },
    {
      label: i18nTranslate(
        'country.netherlandsPhoneCode',
        'The Netherlands(+31)'
      ),
      value: 'NL',
      displayValue: 'NL(+31)',
    },
    {
      label: i18nTranslate('country.norwayPhoneCode', 'Norway(+47)'),
      value: 'NO',
      displayValue: 'NO(+47)',
    },
    {
      label: i18nTranslate('country.newzealandPhoneCode', 'New Zealand(+64)'),
      value: 'NZ',
      displayValue: 'NZ(+64)',
    },
    {
      label: i18nTranslate('country.peruPhoneCode', 'Peru(+51)'),
      value: 'PE',
      displayValue: 'PE(+51)',
    },
    {
      label: i18nTranslate(
        'country.polynesiaPhoneCode',
        'French Polynesia(+689)'
      ),
      value: 'PF',
      displayValue: 'PF(+689)',
    },
    {
      label: i18nTranslate('country.philippinesPhoneCode', 'Philippines(+63)'),
      value: 'PH',
      displayValue: 'PH(+63)',
    },
    {
      label: i18nTranslate('country.polandPhoneCode', 'Poland(+48)'),
      value: 'PL',
      displayValue: 'PL(+48)',
    },
    {
      label: i18nTranslate('country.portugalPhoneCode', 'Portugal(+351)'),
      value: 'PT',
      displayValue: 'PT(+351)',
    },
    {
      label: i18nTranslate('country.romaniaPhoneCode', 'Romania(+40)'),
      value: 'RO',
      displayValue: 'RO(+40)',
    },
    {
      label: i18nTranslate('country.russiaPhoneCode', 'Russia(+7)'),
      value: 'RU',
      displayValue: 'RU(+7)',
    },
    {
      label: i18nTranslate('country.swedenPhoneCode', 'Sweden(+46)'),
      value: 'SE',
      displayValue: 'SE(+46)',
    },
    {
      label: i18nTranslate('country.singaporePhoneCode', 'Singapore(+65)'),
      value: 'SG',
      displayValue: 'SG(+65)',
    },
    {
      label: i18nTranslate('country.slovakiaPhoneCode', 'Slovakia(+421)'),
      value: 'SK',
      displayValue: 'SK(+421)',
    },
    {
      label: i18nTranslate('country.thailandPhoneCode', 'Thailand(+66)'),
      value: 'TH',
      displayValue: 'TH(+66)',
    },
    {
      label: i18nTranslate('country.taiwanPhoneCode', 'Taiwan(+886)'),
      value: 'TW',
      displayValue: 'TW(+886)',
    },

    {
      label: i18nTranslate('country.ukrainePhoneCode', 'Ukraine(+380)'),
      value: 'UA',
      displayValue: 'UA(+380)',
    },
    {
      label: i18nTranslate(
        'country.unitedstatesPhoneCode',
        'United States(+1)'
      ),
      value: 'US',
      displayValue: 'US(+1)',
    },
    {
      label: i18nTranslate('country.vietnamPhoneCode', 'Vietnam(+84)'),
      value: 'VN',
      displayValue: 'VN(+84)',
    },
    {
      label: i18nTranslate('country.southafricaPhoneCode', 'South Africa(+27)'),
      value: 'ZA',
      displayValue: 'ZA(+27)',
    },
  ]
}
