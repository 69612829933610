import React, { useContext, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { HeaderLogo } from 'src/views/components'
import { footerContainer } from 'src/models'
import { Row, Col } from 'react-bootstrap'
import { ApiContent, getDeviceType } from 'src/utils'
import './styles.scss'

function PersonalFooter() {
  const [footerData, setFooterData] = useState({})
  const [footerLegalInfo, setFooterLegalInfo] = useState({})
  const isTabletorDesktop =
    getDeviceType() === 'tablet' || getDeviceType() === 'desktop' || false
  const isMobile = getDeviceType() === 'mobile' || false
  const context = useContext(ApiContent)
  const getFooterDetailLinks = async () => {
    const promises = await footerContainer.getFooterData({
      contextName: 'footerData',
      context: context,
      queryParams: {
        locale: 'en_US',
      },
    })
    setFooterLegalInfo(promises)
  }
  useEffect(() => {
    /**
     * @note
     * Update Cart quantity on Page load
     * Member for PersonalHeader
     */
    let fDataAsString = sessionStorage.getItem('footerData') || ''
    let userDataAsString = sessionStorage.getItem('storefront') || ''

    const footerInfo = {}

    try {
      if (fDataAsString) {
        fDataAsString = JSON.parse(fDataAsString)

        footerInfo.contactInfo = fDataAsString?.storefront?.contactInfoTitle
        footerInfo.privacyPolicyLabel =
          fDataAsString?.storefront?.detailsLinks[0]?.name
        footerInfo.termsAndConditionsLabel =
          fDataAsString?.storefront?.detailsLinks[1]?.name
        footerInfo.poweredByLabel = fDataAsString?.storefront?.poweredByLabel
        footerInfo.facebookLabel =
          fDataAsString?.socialMediaList[0]?.socialIconUrl
        footerInfo.twitterLabel =
          fDataAsString?.socialMediaList[1]?.socialIconUrl
        footerInfo.pinterestLabel =
          fDataAsString?.socialMediaList[2]?.socialIconUrl
      }

      if (userDataAsString) {
        userDataAsString = JSON.parse(userDataAsString)

        footerInfo.userName = userDataAsString?.displayName
        footerInfo.userEmail = userDataAsString?.email
        footerInfo.userPhone = userDataAsString?.phone
        footerInfo.userPhoto = userDataAsString?.profilePhoto
        footerInfo.socialAccounts = userDataAsString?.socialAccounts || {}
      }
    } catch (e) {
      console.error('JSON.parse may be an error >>> PersonalFooter', e)
    }
    setFooterData({ ...footerInfo })
    if (context?.data?.footerData) {
      setFooterLegalInfo(context?.data?.footerData)
    } else {
      getFooterDetailLinks()
    }
  }, [])

  const privacyTermsFooterLink = name => {
    if (typeof window !== 'undefined') {
      footerLegalInfo?.detailsLinks.filter(data => {
        if (data?.name === name)
          window.open(
            data?.href?.includes('https://')
              ? `${data?.href}`
              : `https://www.nuskin.com${data?.href}`
          )
      })
    }
  }
  const renderUserDetails = () => {
    return (
      <div className="footer-usr-detail">
        <div className="user-name">{footerData.userName}</div>
        <div className="user-email">{footerData.userEmail}</div>
        <div className="user-phone">{footerData.userPhone}</div>
      </div>
    )
  }
  const footerLogoData = () => {
    return (
      <div className="power-nuskin">
        <div className="power-by-txt">{footerData.poweredByLabel}</div>
        <div className="footer-nuskin-logo">
          <HeaderLogo />
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="personalFooter">
        <div className="personalFooter-contgainer">
          <div className="contact-info-txt">{footerData.contactInfo}</div>
          <Row className="footer-user-info d-flex">
            <Col className="user-logo-info d-flex">
              <img
                src={footerData.userPhoto}
                className="rounded-circle shadow-4 user-logo"
                style={{ width: '75px' }}
                alt="Avatar"
              />
              {renderUserDetails()}
            </Col>
            {!isEmpty(footerData?.socialAccounts) &&
              footerData?.socialAccounts?.map((e, index) => {
                return (
                  <div className="social-media-link d-flex">
                    <div className="s-media-link" key={index}>
                      <div className="media-name">
                        <a
                          href={
                            e?.url.includes('https://')
                              ? e?.url
                              : `https://${e?.url}`
                          }
                          target="_blank"
                          rel="noopener noreferrer">
                          {e?.name}
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })}
            {isTabletorDesktop && footerLogoData()}
          </Row>
          {isMobile && footerLogoData()}
        </div>
        <div className="privacy-policy-service">
          <Link
            className="privacy-policy"
            onClick={() => privacyTermsFooterLink('Privacy')}
            rel="noopener noreferrer">
            {footerData.privacyPolicyLabel}
          </Link>
          <div className="divider">|</div>
          <Link
            className="privacy-policy"
            onClick={() => privacyTermsFooterLink('Terms of Use')}
            rel="noopener noreferrer">
            {footerData.termsAndConditionsLabel}
          </Link>
        </div>
      </div>
    </>
  )
}

export { PersonalFooter }
export default withTranslation(['csr-resource'])(PersonalFooter)
