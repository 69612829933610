import { APPConfig, getLocalStorage } from 'config/appConfig'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { storeContainer } from 'src/models/Store'
import { getResourceFrom3rdPartyUrl } from 'src/api/localeResources'

function setAppConfigByStore(options) {
  const { pageUrl, localeProp = 'defaultLocale', cbk, host, hostName } = options

  const appConfigFromJSON = APPConfig.getConfigFromJSON()
  //const hostName = new URL(pageUrl).host
  /**
   * @info
   *  when the request comes like IP address
   */
  const activeEnv = APPConfig.getLocalEnv()
  // const localDomainNames = appConfigFromJSON?.localEnvs
  // const isLocalEnv = localDomainNames.some(domainName =>
  //   hostName.includes(domainName)
  // )

  // const activeEnv = isLocalEnv ? APPConfig.getLocalEnv() : hostName
  // //const activeEnv = hostName.includes('local') ? localActiveEnv : hostName

  const curLocaleInLS = getLocalStorage(localeProp) || ''
  const defaultLocale =
    curLocaleInLS || appConfigFromJSON.defaultProps.defaultLocale
  const curLocaleFromUrl = getLocaleCodeFromUrl({
    url: pageUrl,
    at: 'pathParam',
    defaultLocale: defaultLocale,
    isReverseType: true,
  })

  let defaultConfigs =
    appConfigFromJSON.domainsConfig[host] ||
    appConfigFromJSON.domainsConfig[hostName] ||
    appConfigFromJSON.domainsConfig[activeEnv]

  // console.log({
  //   curLocaleFromUrl,
  //   localeFromCookie: getCookie(localeProp),
  //   localeFromLS: getLocalStorage(localeProp),
  // })

  // setting the global config replaces the older config
  // and the comparison is failing in getGlobalStoreResponse
  const mergedAppConfig = {
    ...appConfigFromJSON.defaultProps,
    ...defaultConfigs,
    defaultLocale: curLocaleFromUrl,
  }

  APPConfig.setAppConfig(mergedAppConfig, true, curLocaleFromUrl)
  const activeAppConfig = APPConfig.getActiveAppConfig(curLocaleFromUrl)

  storeContainer.getStoreListByLocale(
    mergedAppConfig.globalStoreId,
    pageUrl,
    defaultLocale,
    async (activeStoreResponse, globalStoreResponse, newProps, storeConfig) => {
      storeContainer.storeConfigAtGlobal(storeConfig, curLocaleFromUrl)
      let storefrontConfigFromGlobalStoreAdmin = {}
      let activeStoreCommonConfigs = {}
      let storefrontConfigFromActiveStoreAdmin = {}
      try {
        let storefrontConfig =
          globalStoreResponse?.properties?.find(
            property => property?.name == 'storefrontConfig'
          ) || ''
        let cspDomains =
          globalStoreResponse?.properties?.find(
            property => property?.name == 'cspDomains'
          ) || ''
        if (storefrontConfig) {
          storefrontConfigFromGlobalStoreAdmin = JSON.parse(
            storefrontConfig?.value || {}
          )
          if (cspDomains) {
            storefrontConfigFromGlobalStoreAdmin['serverProperties'][
              'cspDomains'
            ] = JSON.parse(cspDomains.value || {})
          }
        }
      } catch (e) {
        console.log(
          'error while parsing storefrontConfigFromGlobalStoreAdmin ',
          e
        )
      }
      try {
        const ignoreStoreConfig = [
          'activateB2B2C',
          'discountModelRewardTypes',
          'tierConfigMap',
          'PriceRuleConfigMap',
          'allowedRewardTypesForOptIn',
          'saleEventStartTime',
          'saleEventEndTime',
          'storefrontConfig',
        ]

        try {
          activeStoreResponse?.properties?.forEach(property => {
            if (!ignoreStoreConfig?.includes(property?.name)) {
              try {
                activeStoreCommonConfigs[property.name] = JSON.parse(
                  property.value || {}
                )
              } catch (e) {
                activeStoreCommonConfigs[property.name] = property.value
              }
            }
          })
        } catch (e) {
          console.error('Fn setAppConfigByStore ', e)
        }

        let storefrontConfig =
          activeStoreResponse?.properties?.find(
            property => property?.name == 'storefrontConfig'
          ) || ''

        if (storefrontConfig) {
          storefrontConfigFromActiveStoreAdmin = JSON.parse(
            storefrontConfig?.value || {}
          )
        }
      } catch (e) {
        console.log('error while parsing storefrontConfigFromActiveStoreAdmin')
      }
      // This is to combine store level common config(SFO + SF shares the same config)
      const commonProperties = {}
      const ignoreGlobalStoreProperties = [
        'activateB2B2C',
        'countryConfig',
        'PriceRuleConfigMap',
        'storefrontConfig',
        'cspDomains',
      ]

      globalStoreResponse?.properties?.forEach(property => {
        if (!ignoreGlobalStoreProperties?.includes(property?.name)) {
          commonProperties[property.name] = property.value
        }
      })
      const finalConfig = {
        ...mergedAppConfig,
        ...newProps,
        ...storefrontConfigFromGlobalStoreAdmin,
        ...storefrontConfigFromActiveStoreAdmin,
        ...activeStoreCommonConfigs,
        ...commonProperties,
        // @note: we have to stick with user selected locale
        defaultLocale: curLocaleFromUrl,
        pageUrl,
      }
      try {
        const loadParams = {
          fileName: 'redirectUrls',
          locale: curLocaleFromUrl.replace('_', '-'),
          domainName: finalConfig.localeResourceFilesDomain || 'dev.nuskin.com',
        }

        let redirectUrls = await getResourceFrom3rdPartyUrl(loadParams)
        if (redirectUrls.statusCode === 200) {
          delete redirectUrls.responseStatus
          delete redirectUrls.status
          delete redirectUrls.statusCode
          delete redirectUrls.totalCount
          finalConfig.redirectUrls = redirectUrls
        } else {
          finalConfig.redirectUrls = {}
        }
      } catch (e) {
        console.log('error in receiving redirectionUrls', e)
      }
      APPConfig.updateAppConfig(finalConfig, true, curLocaleFromUrl)
      cbk(storeConfig)
    },
    options
  )
}

export { setAppConfigByStore }
