import React, { useEffect, useState } from 'react'
import { Form, Button, InputGroup } from 'react-bootstrap'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { BsFillCheckCircleFill, BsFillFileExcelFill } from 'react-icons/bs'
import { i18nTranslate } from 'src/utils'
import { accountsContainer } from 'src/models'

const PasswordField = props => {
  const { value, errors, touched, name, values } = props
  const [passwordVisible, setPasswordVisibility] = useState(false)

  //Validations for ADA

  const lower = new RegExp('(?=.*[a-z])')
  const upper = new RegExp('(?=.*[A-Z])')
  const number = new RegExp('(?=.*[0-9])')
  const special = new RegExp(
    '(?=.*[\\"!@#\\$%^&*()_+{}\\[\\]:;<>,.?~`|\\\\/\\-=\'])'
  )
  const length = new RegExp('(?=.{8,})')
  const [lowerVal, setLowerVal] = useState(false)
  const [upperVal, setUpperVal] = useState(false)
  const [numberVal, setNumberVal] = useState(false)
  const [specialVal, setSpecialVal] = useState(false)
  const [lengthVal, setLengthVal] = useState(false)
  const [strongPassword, setStrongPassword] = useState(false)

  const [labelDisplayPassword, setlabelDisplayPassword] = useState(true)
  const [fieldFlagValueSet, setFieldFlagValueSet] = useState(false)
  const [passwordTouched, setPasswordTouched] = useState(false)

  const handlePasswordFocus = () => {
    setFieldFlagValueSet(true)
  }

  const handleTouchedFocus = () => {
    setFieldFlagValueSet(false)
    setPasswordTouched(true)
  }

  useEffect(() => {
    lower.test(props?.values?.password) ? setLowerVal(true) : setLowerVal(false)

    upper.test(props?.values?.password) ? setUpperVal(true) : setUpperVal(false)

    number.test(props?.values?.password)
      ? setNumberVal(true)
      : setNumberVal(false)

    special.test(props?.values?.password)
      ? setSpecialVal(true)
      : setSpecialVal(false)

    length.test(props?.values?.password)
      ? setLengthVal(true)
      : setLengthVal(false)

    lowerVal && upperVal && numberVal && specialVal && lengthVal
      ? setStrongPassword(true)
      : setStrongPassword(false)

    strongPassword
      ? setlabelDisplayPassword(false)
      : setlabelDisplayPassword(true)
  })

  //End of Validations for ADA

  const type = passwordVisible ? 'text' : 'password'
  const passwordClassName =
    !strongPassword &&
    accountsContainer?.onSubmitInValid &&
    values?.password === ''
      ? 'is-invalid'
      : passwordTouched && !strongPassword && values?.password !== ''
      ? 'is-invalid'
      : strongPassword
      ? 'is-valid'
      : ''

  const className = props.isInvalid ? 'is-invalid' : 'is-valid'

  const ariaDescription = {
    'aria-describedby':
      fieldFlagValueSet && !strongPassword
        ? 'password-length password-uppercase password-lowercase password-number password-special password-feedback'
        : strongPassword
        ? 'password-strong'
        : null,
  }
  return (
    <>
      <InputGroup
        className={props?.isPasswordFromSignup ? passwordClassName : className}>
        {props?.isPasswordFromSignup ? (
          <Form.Control
            {...props}
            type={type}
            onClick={handlePasswordFocus}
            onFocus={handlePasswordFocus}
            onBlur={handleTouchedFocus}
            {...ariaDescription}
          />
        ) : (
          <Form.Control {...props} type={type} />
        )}

        <InputGroup.Append
          onClick={() => setPasswordVisibility(!passwordVisible)}
          data-testid="qa-password-showtext">
          <InputGroup.Text>
            {passwordVisible ? (
              <Button
                aria-label={i18nTranslate(
                  'icons.passwordEyeOn',
                  'show password',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )}>
                <IoMdEye />
              </Button>
            ) : (
              <Button
                aria-label={i18nTranslate(
                  'icons.passwordEyeOff',
                  'hide password',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )}>
                <IoMdEyeOff />
              </Button>
            )}
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      {/*Start of ADA display*/}
      {fieldFlagValueSet && (
        <div
          className={
            fieldFlagValueSet ? 'passwordShow show-block' : 'passwordShow '
          }>
          <div>
            {lengthVal && !strongPassword ? (
              <span className="password-correct">
                <BsFillCheckCircleFill className="marketing" />{' '}
                {i18nTranslate(
                  'signupPassword.RuleOne',
                  '8 or more characters in length'
                )}
              </span>
            ) : (
              labelDisplayPassword && (
                <span className="password-wrong" id="password-length">
                  <BsFillFileExcelFill className="marketing" />{' '}
                  {i18nTranslate(
                    'signupPassword.RuleOne',
                    '8 or more characters in length'
                  )}
                </span>
              )
            )}
          </div>
          <div>
            {upperVal && !strongPassword ? (
              <span className="password-correct">
                <BsFillCheckCircleFill className="marketing" />
                {i18nTranslate(
                  'signupPassword.RuleTwo',
                  'Use a upper case letter (eg. ABC)'
                )}
              </span>
            ) : (
              labelDisplayPassword && (
                <span className="password-wrong" id="password-uppercase">
                  <BsFillFileExcelFill className="marketing" />
                  {i18nTranslate(
                    'signupPassword.RuleTwo',
                    'Use a upper case letter (eg. ABC)'
                  )}
                </span>
              )
            )}
          </div>
          <div>
            {lowerVal && !strongPassword ? (
              <span className="password-correct">
                <BsFillCheckCircleFill className="marketing" />
                {i18nTranslate(
                  'signupPassword.RuleThree',
                  'Use an lower case letter (eg. abc)'
                )}
              </span>
            ) : (
              labelDisplayPassword && (
                <span className="password-wrong" id="password-lowercase">
                  <BsFillFileExcelFill className="marketing" />
                  {i18nTranslate(
                    'signupPassword.RuleThree',
                    'Use an lower case letter (eg. abc)'
                  )}
                </span>
              )
            )}
          </div>
          <div>
            {numberVal && !strongPassword ? (
              <span className="password-correct">
                <BsFillCheckCircleFill className="marketing" />
                {i18nTranslate(
                  'signupPassword.RuleFour',
                  'Use a number (eg. 123)'
                )}
              </span>
            ) : (
              labelDisplayPassword && (
                <span className="password-wrong" id="password-number">
                  <BsFillFileExcelFill className="marketing" />
                  {i18nTranslate(
                    'signupPassword.RuleFour',
                    'Use a number (eg. 123)'
                  )}
                </span>
              )
            )}
          </div>
          <div>
            {specialVal && !strongPassword ? (
              <span className="password-correct">
                <BsFillCheckCircleFill className="marketing" />
                {i18nTranslate(
                  'signupPassword.RuleFive',
                  'Use a special character "!#$%&’()*+,-./\\;<:>=?@[]^_`{|}~'
                )}
              </span>
            ) : (
              labelDisplayPassword && (
                <span className="password-wrong" id="password-special">
                  <BsFillFileExcelFill className="marketing" />
                  {i18nTranslate(
                    'signupPassword.RuleFive',
                    'Use a special character "!#$%&’()*+,-./\\;<:>=?@[]^_`{|}~'
                  )}
                </span>
              )
            )}
          </div>
          <div>
            {strongPassword && (
              <span className="password-correct" id="password-strong">
                <BsFillCheckCircleFill className="marketing" />
                {i18nTranslate('signupPassword.strong', 'Strong Password')}
              </span>
            )}
          </div>
        </div>
      )}

      {/*End of ADA display*/}
    </>
  )
}

export { PasswordField }
