import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Quantity } from 'src/views/components'
import { Row } from 'react-bootstrap'
import { cartContainer, customerContainer } from 'src/models'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { quickViewState } from 'src/views/components/QuickView/state'
import { APPConfig } from 'config/appConfig'
import { observer } from 'mobx-react'

@observer
class ProductQuantity extends React.Component {
  totalTxt = ''

  constructor(props) {
    super(props)
    this.totalTxt = i18nTranslate('pdp.total', 'Total', {
      nameSpace: 'ssr-resource',
    })
  }

  renderTotal() {
    const product = this.props?.product
    const { transactionPrice, totalValue = {} } = product
    const regprice =
      transactionPrice?.unitPrice || transactionPrice?.price || ''
    const currencySymbol = transactionPrice?.currencySymbol || '$'
    // const quantity =
    //   this.props?.edit === 'noEdit' ? 1 : this.props?.productCount
    // const totalcount = quantity * regprice
    const priceAfterDiscount = totalValue?.priceAfterDiscount || 0
    // const totalPriceAfterDiscount = quantity * priceAfterDiscount

    const totalcount =
      this.props?.isFromQuickView === true
        ? quickViewState?.pdpPromotionResponse?.totalValue?.originalPrice ||
          regprice ||
          0
        : productState?.pdpPromotionResponse?.totalValue?.originalPrice ||
          regprice ||
          0
    const totalPriceAfterDiscount =
      this.props?.isFromQuickView === true
        ? quickViewState?.pdpPromotionResponse?.totalValue
            ?.priceAfterDiscount ||
          priceAfterDiscount ||
          0
        : productState?.pdpPromotionResponse?.totalValue?.priceAfterDiscount ||
          priceAfterDiscount ||
          0
    const totaldiscount =
      this.props?.isFromQuickView === true
        ? quickViewState?.pdpPromotionResponse?.totalValue?.totaldiscount ||
          totalValue?.totaldiscount ||
          0
        : productState?.pdpPromotionResponse?.totalValue?.totaldiscount ||
          totalValue?.totaldiscount ||
          0
    const oldPrice = currencySymbol + totalcount.toFixed(2)
    const newPrice = currencySymbol + totalPriceAfterDiscount.toFixed(2)
    const totalTxt = this.totalTxt
    const overallPriceForAria =
      i18nTranslate('strikeThroughPrice', 'Strike through ') +
      oldPrice +
      ' ' +
      newPrice +
      ' ' +
      this.totalTxt
    {
      /* @##pdp_price total for normal product */
      /* not for bundle */
      /* for both PDP and QuickView */
    }
    /* negative discount (negative promotion) */
    // https://nuskin.atlassian.net/browse/CX12-10248
    const isNegativePromotion = (totaldiscount && totaldiscount < 0) || false
    return totaldiscount && totalPriceAfterDiscount ? (
      <>
        {!isNegativePromotion && (
          <span className="strikeThrough pr-2" aria-label={overallPriceForAria}>
            {oldPrice}
          </span>
        )}
        <span aria-hidden="true">{newPrice}</span>
      </>
    ) : totalPriceAfterDiscount === 0 && totaldiscount === totalcount ? (
      <>
        <span className="strikeThrough pr-2" aria-label={overallPriceForAria}>
          {oldPrice}
        </span>
        <span aria-hidden="true">{newPrice}</span>
      </>
    ) : (
      <span aria-label={`${totalcount} ${this.totalTxt}`}>
        {currencySymbol + totalcount.toFixed(2)}
      </span>
    )
  }
  render() {
    const { productCount, circularIcons, hideText = false } = this.props
    const product = this.props?.product
    const transactionPrice = product?.transactionPrice
    // const totalPrice = this.renderTotal()
    // const skuId = product?.skuId
    const productId = this.props?.product?.productId
    const cartItems = cartContainer?.cartResponse?.items || []
    const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99
    let maxProductQty
    const productQualificationResponse =
      customerContainer?.qualificationResponse?.qualification?.filter(p => {
        return p?.productId === productId
      })
    // condition to check quanity limit for exclusive products based on cart items
    if (
      productQualificationResponse &&
      productQualificationResponse.length > 0
    ) {
      if (cartItems && cartItems.length > 0) {
        cartItems.forEach(item => {
          if (item?.skus?.[0]?.productId == productId) {
            maxProductQty =
              productQualificationResponse?.[0]?.quantity - item?.quantity
          } else if (productQualificationResponse?.[0]?.quantity > maxLimit) {
            maxProductQty = maxLimit
          } else {
            maxProductQty = productQualificationResponse?.[0]?.quantity
          }
        })
      } else {
        maxProductQty = productQualificationResponse?.[0]?.quantity
      }
    }

    if (maxProductQty > maxLimit) {
      maxProductQty = maxLimit
    }

    return (
      <Row
        noGutters
        className="align-items-sm-start align-items-md-center justify-content-between w-100 d-flex flex-column flex-md-row pb-3">
        <Row className="d-block align-items-center flex-row quantity-height">
          {!hideText && (
            <h3 className=" quantity-text mb-1 ml-3">
              {i18nTranslate('pdp.quantityText', 'Quantity', {
                nameSpace: 'ssr-resource',
              })}
            </h3>
          )}

          <Quantity
            maxValue={maxProductQty}
            circularIcons={circularIcons}
            onQuantityChange={this.props.handleQuantityChange}
            handleQuantityChangePromotion={
              this.props.handleQuantityChangePromotion
            }
            product={this.props.product}
            defaultValue={productCount}
          />
        </Row>
        <div className="mt-2 mt-md-0">
          {transactionPrice?.price && (
            <p className="heading-3 m-0 mt-4" aria-live="assertive">
              {this.renderTotal()}
              <span className="paragraph-m pl-1" aria-hidden="true">
                {this.totalTxt}
              </span>
            </p>
          )}
        </div>
      </Row>
    )
  }
}

export { ProductQuantity }
