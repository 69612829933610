import React from 'react'
import {
  i18nTranslate,
  jitsuSignUpRedirect,
  trackGTMEvents,
  convertToBoolean,
} from 'src/utils'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { VscClose as CloseIcon } from 'react-icons/vsc'
import { Button, Col, ListGroup, Row } from 'react-bootstrap'
import { APPConfig, IS_BROWSER, getAppConfig } from 'config/appConfig'
import {
  application,
  getLocalStorage,
  getLocaleCodeFromUrl,
  getShoppingContext,
} from 'src/utils'
import { signInwithRedirect, signOutWithRedirect } from 'src/utils/signInUtils'
import { pageNames } from 'src/routes/pathParams'
import { LocaleModal, mobileMenuState } from 'src/views/components'
import { customerContainer } from 'src/models'
import { PageLink as PageLinks } from '../../../PageLink'
import NuskinAccountIcon from '../../Components/UserMenu/NuskinAccountIcon'
import { MobileMenuCategories } from './MobileMenuCategories'
import NuskinLogoMobile from './NuskinLogoMobile'
import { getLocaleBasedLink } from '../staticDropDownLinks'
import './styles.scss'

/**
 * MobileSignout component that renders the sign out button
 * in the mobile menu for authenticated users.
 * Extends React.Component.
 */
/**
 * MobileSignout component that renders the sign out button
 * in the mobile menu for authenticated users.
 * Extends React.Component.
 */
class MobileSignout extends React.Component {
  nameSpace = 'ssr-resource'
  /**
   * Toggles the mobile menu visibility when the screen size is tablet or smaller.
   */

  handleMobileMenu = () => {
    if (application.isTabletOrSmaller) {
      mobileMenuState.toggleMenuVisibility()
    } else {
      trackGTMEvents({ event: 'Signup', eventType: 'simpCustColdFormStart' })
    }
  }

  render() {
    if (!IS_BROWSER) {
      return <></>
    }

    const enableJITSU = convertToBoolean(getAppConfig()?.enableJITSU)

    const { businessRelationship } = getAppConfig()
    let pageLink = pageNames.createAccount
    if (businessRelationship === 'B2B' || isB2B2C()) {
      pageLink = pageNames.signUp
    }

    if (enableJITSU) {
      pageLink = jitsuSignUpRedirect({ isToReturnUrl: true })
    }

    let mysite
    try {
      if (typeof window !== 'undefined') {
        // const shoppingContext = getLocalStorage('shoppingContext') || {}
        // if (Object.keys(shoppingContext).length > 0) {
        // const contextValue = shoppingContext?.context || ''
        const contextValue = getShoppingContext()
        if (contextValue == 'storefront') {
          mysite = {
            display: 'none',
          }
        }
        // }
      }
    } catch (e) {
      console.error('JSON.parse may be an error SignUpForm.formInputOkta', e)
    }

    const componentToRender = customerContainer.isRegisterUser ? (
      <div className="nu-mob-signout">
        <Button className="nu-mob-signout-btn" onClick={signOutWithRedirect}>
          {i18nTranslate('header.signOut', 'Sign Out', {
            nameSpace: this.nameSpace,
          })}
        </Button>
      </div>
    ) : (
      <div className="nu-mob-signout">
        <Button
          // to={pageNames.signIn}
          className={`nu-mob-signout-btn`}
          onClick={signInwithRedirect}
          data-testid="qa-signin">
          <NuskinAccountIcon
            className="mr-1"
            style={{ fontSize: '24px', verticalAlign: 'middle' }}
            aria-label="sign in icon"
          />
          {i18nTranslate('header.signIn', 'Sign In', {
            nameSpace: this.nameSpace,
          })}
        </Button>
        <span className="mx-1">/</span>
        {enableJITSU ? (
          <a
            href={pageLink}
            className={`nu-mob-signout-btn`}
            onClick={this.handleMobileMenu}
            data-testid="qa-signup"
            role="button"
            aria-label={i18nTranslate('header.signUp', 'Sign Up', {
              nameSpace: this.nameSpace,
            })}
            style={mysite}>
            {i18nTranslate('header.signUp', 'Sign Up', {
              nameSpace: this.nameSpace,
            })}
          </a>
        ) : (
          <Link
            to={pageLink}
            className={`nu-mob-signout-btn`}
            onClick={this.handleMobileMenu}
            data-testid="qa-signup"
            role="button"
            aria-label={i18nTranslate('header.signUp', 'Sign Up', {
              nameSpace: this.nameSpace,
            })}
            style={mysite}>
            {i18nTranslate('header.signUp', 'Sign Up', {
              nameSpace: this.nameSpace,
            })}
          </Link>
        )}
      </div>
    )

    // return ReactDOM.createPortal(
    //   componentToRender,
    //   document.getElementById('app-root')
    // )
    return componentToRender
  }
}

@observer
class MobileMenu extends React.Component {
  nameSpace = 'ssr-resource'
  @observable toggleIcon = false
  @observable currentCategoryPath = ['category']

  getJoiningLink = () => {
    const isRegisterUser = customerContainer.isRegisterUser
    return isRegisterUser ? pageNames.myaccountsubscriptions : '/signup'
  }

  handleLinkClick = () => {
    this.currentCategoryPath.splice(1)
    mobileMenuState.toggleMenuVisibility()
  }

  myFirstName = text => {
    let rtext = ''
    let len = String(text).length
    if (len > 12) {
      rtext = String(text).slice(0, 11).concat('...')
    } else {
      rtext = text
    }
    return rtext
  }

  render() {
    const baseurl = 'https://www.nuskin.com'
    const accountType = getLocalStorage('accountType')
    const localeValue = getLocaleCodeFromUrl({
      defaultLocale: 'en_US',
      isReverseType: true,
    })
    const baseUrl = `/content/nuskin/${localeValue}`
    // const cookieValue = getLocalStorage('locale')
    // const finalcookieValue = cookieValue?.replace('-', '_')
    // console.log('>>>>>>>MobileMenu', mobileMenuState?.isMenuVisible)
    const enableChatWithUs =
      APPConfig?.getAppConfig()?.enableChatWithUs == 'true' || false

    return (
      <aside
        className={`hide-on-desktop pancake fixed-top h-100 w-100 bg-white nu-mobilemenu ${
          mobileMenuState.isMenuVisible ? 'open' : 'closed'
        }`}>
        {this.currentCategoryPath.length === 1 && (
          <div className="nu-mobilemenu-header">
            <Row className="w-100 p-2 pl-3" noGutters>
              <Col xs={9}>
                <NuskinLogoMobile />
                {customerContainer.isRegisterUser && (
                  <span className={`ml-3 nu-sign-text`}>
                    {i18nTranslate('header.welcome', 'Hi', {
                      nameSpace: this.nameSpace,
                    })}
                    <span> </span>
                    {this.myFirstName(
                      customerContainer.profileResponse.firstName
                    )}
                  </span>
                )}
              </Col>
              <Col xs={3} className="text-right">
                <Button
                  aria-label="closebutton"
                  variant="none"
                  onClick={mobileMenuState.toggleMenuVisibility}>
                  <CloseIcon size="24px" />
                </Button>
              </Col>
            </Row>
          </div>
        )}
        <div className="mobile-menu-container">
          <MobileMenuCategories
            {...this.props}
            currentCategoryPath={this.currentCategoryPath}
          />
          {this.currentCategoryPath.length === 1 && (
            <ListGroup className="nu-mobilemenu-link-list">
              {accountType !==
                ('Brand Affiliate' || 'Brand Affiliate - Business Entity') && (
                <ListGroup.Item>
                  <PageLinks
                    linkInfo={{ link: `${baseUrl}/nu-skin-rewards-info.html` }}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span data-testid="qa-nuskinEnjoyRewards">
                      {i18nTranslate(
                        'nuskinEnjoyRewards.title',
                        'Nu Skin Rewards',
                        { nameSpace: this.nameSpace }
                      )}
                    </span>
                  </PageLinks>
                </ListGroup.Item>
              )}
              {enableChatWithUs && (
                <ListGroup.Item>
                  <a
                    href={getLocaleBasedLink()}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.handleLinkClick}>
                    {i18nTranslate('header.chat', 'Chat', {
                      nameSpace: this.nameSpace,
                    })}
                  </a>
                </ListGroup.Item>
              )}
              {/* {this.props.data?.market?.legalLinks.map((legalLink, index) => {
              return (
                <ListGroup.Item key={index}>
                  <a
                    href={baseurl + legalLink.href}
                    onClick={this.handleLinkClick}>
                    {legalLink.name}
                  </a>
                </ListGroup.Item>
              )
            })} */}
              <ListGroup.Item>
                <LocaleModal history={this.props.history} isNuskinHeader />
              </ListGroup.Item>
            </ListGroup>
          )}
        </div>
        <MobileSignout />
      </aside>
    )
  }
}

export default MobileMenu
export { MobileMenu }
