// import { datadogRum } from '@datadog/browser-rum'
import { APPConfig } from 'config/appConfig'

async function initDatadog() {
  const monitoringConfig = APPConfig?.getAppConfig()?.monitoring || {}
  const {
    isToEnableMonitor,
    applicationId,
    clientToken,
    site,
    service,
    allowedTracingUrls,
  } = monitoringConfig || {}

  if (
    isToEnableMonitor == 'true' &&
    applicationId &&
    clientToken &&
    site &&
    service
  ) {
    const { datadogRum } = await import('@datadog/browser-rum')
    datadogRum?.init({
      applicationId,
      clientToken,
      site,
      service,
      //  env: 'production',
      //  version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100, // if not included, the default is 100
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      allowedTracingUrls,
    })
    datadogRum?.startSessionReplayRecording()
  }
}

export { initDatadog }
