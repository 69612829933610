import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Button, Row, Col } from 'react-bootstrap'
import { formInput } from './formInput'
import { schema } from './validationSchema'
import { CommonForm, toastState } from 'src/views/components'
import { i18nTranslate } from 'src/utils'
import { accountsContainer, customerContainer } from 'src/models'
import { parsePhoneNumberField } from 'src/utils/signUpUtils'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import './styles.scss'

@observer
class VerifyPhoneNumber extends Component {
  @observable isErrors = false
  constructor(props) {
    super(props)

    this.state = {
      btnDisabled: false,
    }
  }

  handleSubmit = async formData => {
    this.setState({ btnDisabled: true })
    const phoneNumber = await this.props.profileSetPhoneNumber()
    const phoneNumberData = this.props.getProfilePhone
    let localValue = getLocaleCodeFromUrl() || ''
    localValue = localValue?.toLowerCase()?.split('_')
    const marketRegion = localValue?.[1]?.toUpperCase() || 'US'
    const locale = localValue?.[0] || 'en'

    let sendCodeResponse = 'noResponse'
    const sendCodeResponseCbk = async () => {
      sendCodeResponse = await accountsContainer.sendPhoneVerificationCode(
        phoneNumber,
        locale
      )
      if (!accountsContainer.isSuccessResponse(sendCodeResponse)) {
        const defaultMessage = i18nTranslate(
          'verifyPhone.updateFailure',
          'Something went wrong, please try again'
        )
        toastState.setToastMessage(
          sendCodeResponse?.message || defaultMessage,
          false
        )
      }
    }
    if (
      (phoneNumberData?.phoneCountryCode ===
        customerContainer?.setVerifyPhone?.phoneCountryCode &&
        phoneNumberData?.phoneNumber ===
          customerContainer?.setVerifyPhone?.phoneNumber) ||
      (!customerContainer?.setVerifyPhone?.phoneNumber &&
        !customerContainer?.setVerifyPhone?.phoneCountryCode)
    ) {
      await sendCodeResponseCbk()
    } else {
      const response = await accountsContainer.checkPhoneNumberUniqueness(
        phoneNumber,
        marketRegion
      )
      if (response?.data?.checkPhoneNumberUniqueness?.isUnique === true) {
        await sendCodeResponseCbk()
      } else if (
        response?.data?.checkPhoneNumberUniqueness?.isUnique === false
      ) {
        this.isErrors = true
      } else {
        const defaultMessage = i18nTranslate(
          'verifyPhone.updateFailure',
          'Something went wrong, please try again'
        )
        toastState.setToastMessage(response?.message || defaultMessage, false)
      }
    }

    if (
      !this.isErrors &&
      accountsContainer.isSuccessResponse(sendCodeResponse) &&
      sendCodeResponse !== 'noResponse'
    ) {
      this.props.setPageToShow('codeVerify')
    }
    this.setState({ btnDisabled: false })
  }

  renderSubmitButton = () => {
    return (
      <Row className="">
        <Col className="">
          <Button
            variant="primary"
            className="confirm-btn w-100 mt-2"
            data-testid="qa-continue-btn"
            size={'md'}
            type="submit"
            disabled={this.state.btnDisabled}>
            {i18nTranslate('accountProfile.continueButton', 'Continue')}
          </Button>
        </Col>
      </Row>
    )
  }

  hideError = () => {
    this.isErrors = false
  }

  render() {
    return (
      <div className="verifyNumber">
        <h2 className="verify-number-heading pt-0 px-3">
          {i18nTranslate('phoneVerifyText', 'Please verify your phone number!')}
        </h2>

        <p className="confirm-text">
          {i18nTranslate('phoneNoVerify.continue', 'Confirm or make changes')}
        </p>
        <CommonForm
          classNameValue={{
            formRowClassName: 'verify-phone-number-form',
          }}
          formTypeJson={formInput(
            this.isErrors,
            this.hideError,
            this.props.getProfilePhone
          )}
          schema={schema()}
          SubmitButtonProps={this.renderSubmitButton}
          hasInlineSubmitButton={false}
          onSubmit={this.handleSubmit}
        />
      </div>
    )
  }
}

export default VerifyPhoneNumber
export { VerifyPhoneNumber }
