import React from 'react'
import { Collapse, Button, Container } from 'react-bootstrap'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import {
  IoIosArrowDown as DownwardArrowIcon,
  IoIosArrowUp as UpwardArrowIcon,
} from 'react-icons/io'
import './styles.scss'
import { APPConfig } from 'config/appConfig'

@observer
class AccordionBlockFAQ extends React.Component {
  @observable open = this.props.isDefaultOpen || false
  static defaultProps = {
    title: 'submit',
    onClick: () => {},
    index: 1,
    isActive: '',
    hasArrow: true,
    disabled: false,
    isOutsideAccordion: false,
  }

  handleClick = event => {
    event.persist()
    // prop type for value not from text content
    const { isActive, index, onClick, type } = this.props
    const selectedFacet = event.currentTarget.textContent
    this.open = isActive !== '' ? !isActive : !this.open
    const eventData = {
      index,
      isOpen: this.open,
      selectedFacet,
      type,
    }
    onClick(eventData)
  }

  render() {
    const {
      isActive,
      dataTestId,
      title,
      hasArrow,
      children,
      isMerchPLP,
      renderIcon,
      disabled,
    } = this.props
    const isOpen = isActive !== '' ? isActive : this.open
    const isVisible = isMerchPLP ? (isOpen ? 'active' : 'disable') : ''
    const updateIcon = renderIcon ? (
      renderIcon(isOpen)
    ) : isOpen ? (
      <UpwardArrowIcon className="faq-arrow-icon-size" aria-hidden={true} />
    ) : (
      <DownwardArrowIcon className="faq-arrow-icon-size" aria-hidden={true} />
    )
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'

    return (
      <>
        <Container>
          <div
            noGutters
            className={` faq-accordion ${
              enableNewPDPStyle
                ? 'v2-faq-accordion-block-wrapper'
                : 'py-1 accordion-block-wrapper'
            }`}>
            <div className="faq-accordion-title">
              <Button
                data-testid={dataTestId}
                variant="light"
                disabled={disabled}
                className={`${
                  enableNewPDPStyle
                    ? 'v2-faq-accordion-block-title-button'
                    : 'py-2 accordion-block-title-button'
                }  justify-content-end d-flex bg-transparent shadow-none p-0 border-0`}
                size="lg"
                block
                aria-expanded={this.open}
                onClick={this.handleClick}>
                <span
                  className={`${
                    enableNewPDPStyle
                      ? 'v2-faq-accordion-block-text'
                      : 'accordion-block-text'
                  } ${isVisible} flex-grow-1 flex-shrink-1 text-left text-capitalize ${
                    this.props.textClass
                  } text-break`}>
                  {title}
                </span>
                {hasArrow && (
                  <div className="accordion-block-icon justify-content-center d-flex align-items-center">
                    {updateIcon}
                  </div>
                )}
              </Button>
            </div>
            {this.props.isOutsideAccordion === false && (
              <Collapse
                in={isOpen}
                className="accordion-block-collapse-section w-100 mt-2 ">
                <div id="collapse-text">{children}</div>
              </Collapse>
            )}
          </div>
          {this.props.isOutsideAccordion && (
            <Collapse
              timeout={300}
              in={isOpen}
              className="accordion-block-collapse-section mt-2 w-100 mb-2 p-0">
              <div id="collapse-text">{children}</div>
            </Collapse>
          )}
        </Container>
      </>
    )
  }
}

export default AccordionBlockFAQ
export { AccordionBlockFAQ }
