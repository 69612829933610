import {
  getCookie,
  setCookie,
  deleteCookie,
  setLocalStorage,
  getLocalStorage,
  deleteFromLocalStorage,
} from './configUtils'

const allowedParams = [
  'storeId',
  'localeType',
  'globalStoreId',
  'csrAdminCookie',
  'defaultLocale',
]

class ConfigKey {
  key = ''
  value = ''
  refKey = ''
  refValue = ''
  defValue = ''
  defRefValue = ''
  storage = '' // cookie | ls
  isCommonHeaders = false

  constructor(options) {
    // actual value (expect to be updated)
    this.key = options.key
    this.value = options.value

    // alternate value (expect to be updated)
    this.refKey = options.refKey
    this.refValue = options.refValue

    // used to reset (first value)
    this.defValue = options.defValue
    this.defRefValue = options.defRefValue

    this.storage = options.storage
    this.isCommonHeaders = options.isCommonHeaders

    this.set(options.defValue, options.refValue)
  }

  reset(shouldClear = false) {
    this.set(this.defValue, this.refValue)

    if (shouldClear) {
      this.clear()
    }
  }

  clear() {
    const clearLS = () => {
      deleteFromLocalStorage(this.key)
      deleteFromLocalStorage(this.refKey)
    }

    const clearCookies = () => {
      deleteCookie(this.key)
      deleteCookie(this.refKey)
    }

    if (this.storage === 'ls') {
      clearLS()
    }

    if (this.storage === 'cookie') {
      clearCookies()
    }

    if (this.storage === 'both') {
      clearLS()
      clearCookies()
    }
  }

  get() {
    let val = null
    if (this.storage === 'ls') {
      val = getLocalStorage(this.refKey || this.key)
    } else if (this.storage === 'cookie') {
      val = getCookie(this.refKey || this.key)
    }

    if (!val) {
      val = this.value || this.defValue
    }

    return val
  }

  set(val, refValue) {
    this.value = val
    this.refValue = refValue

    const storeAtLS = () => {
      setLocalStorage(this.key, val)
      if (this.refKey) {
        setLocalStorage(this.refKey, refValue)
      }
    }

    const storeAtCookie = () => {
      setCookie({
        cookieName: this.key,
        cookieValue: val,
      })

      if (this.refKey) {
        setCookie({
          cookieName: this.refKey,
          cookieValue: refValue,
        })
      }
    }

    if (allowedParams.includes(this.key)) {
      if (this.storage === 'ls') {
        storeAtLS()
      } else if (this.storage === 'cookie') {
        storeAtCookie()
      } else if (this.storage === 'both') {
        storeAtLS()
        storeAtCookie()
      }
    }
  }
}

export default ConfigKey
