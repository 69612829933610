import { i18nTranslate } from 'src/utils'

export const countriesJson = () => {
  return [
    {
      label: i18nTranslate('country.default', 'Select Your Country'),
      value: '',
    },
    {
      label: i18nTranslate('country.AR', 'Argentina'),
      value: 'AR',
    },
    {
      label: i18nTranslate('country.AU', 'Australia'),
      value: 'AU',
    },
    {
      label: i18nTranslate('country.AT', 'Austria'),
      value: 'AT',
    },
    {
      label: i18nTranslate('country.BE', 'Belgium'),
      value: 'BE',
    },
    {
      label: i18nTranslate('country.BN', 'Brunei'),
      value: 'BN',
    },
    {
      label: i18nTranslate('country.CA', 'Canada'),
      value: 'CA',
    },
    {
      label: i18nTranslate('country.CL', 'Chile'),
      value: 'CL',
    },
    {
      label: i18nTranslate('country.CO', 'Colombia'),
      value: 'CO',
    },
    {
      label: i18nTranslate('country.CZ', 'Czech Republic'),
      value: 'CZ',
    },
    {
      label: i18nTranslate('country.DK', 'Denmark'),
      value: 'DK',
    },
    {
      label: i18nTranslate('country.FI', 'Finland'),
      value: 'FI',
    },
    {
      label: i18nTranslate('country.FR', 'France'),
      value: 'FR',
    },
    {
      label: i18nTranslate('country.PF', 'French Polynesia'),
      value: 'PF',
    },
    {
      label: i18nTranslate('country.DE', 'Germany'),
      value: 'DE',
    },
    {
      label: i18nTranslate('country.HK', 'Hong Kong'),
      value: 'HK',
    },
    {
      label: i18nTranslate('country.HU', 'Hungary'),
      value: 'HU',
    },
    {
      label: i18nTranslate('country.IS', 'Iceland'),
      value: 'IS',
    },
    {
      label: i18nTranslate('country.ID', 'Indonesia'),
      value: 'ID',
    },
    {
      label: i18nTranslate('country.IE', 'Ireland'),
      value: 'IE',
    },
    {
      label: i18nTranslate('country.IL', 'Israel'),
      value: 'IL',
    },
    {
      label: i18nTranslate('country.IT', 'Italy'),
      value: 'IT',
    },
    {
      label: i18nTranslate('country.JP', 'Japan'),
      value: 'JP',
    },
    {
      label: i18nTranslate('country.LU', 'Luxembourg'),
      value: 'LU',
    },
    {
      label: i18nTranslate('country.MY', 'Malaysia'),
      value: 'MY',
    },
    {
      label: i18nTranslate('country.MX', 'Mexico'),
      value: 'MX',
    },
    {
      label: i18nTranslate('country.NC', 'New Caledonia'),
      value: 'NC',
    },
    {
      label: i18nTranslate('country.NZ', 'New Zealand'),
      value: 'NZ',
    },
    {
      label: i18nTranslate('country.NO', 'Norway'),
      value: 'NO',
    },
    {
      label: i18nTranslate('country.PE', 'Peru'),
      value: 'PE',
    },
    {
      label: i18nTranslate('country.PH', 'Philippines'),
      value: 'PH',
    },
    {
      label: i18nTranslate('country.PL', 'Poland'),
      value: 'PL',
    },
    {
      label: i18nTranslate('country.PT', 'Portugal'),
      value: 'PT',
    },
    {
      label: i18nTranslate('country.RO', 'Romania'),
      value: 'RO',
    },
    {
      label: i18nTranslate('country.RU', 'Russia'),
      value: 'RU',
    },
    {
      label: i18nTranslate('country.SG', 'Singapore'),
      value: 'SG',
    },
    {
      label: i18nTranslate('country.SK', 'Slovakia'),
      value: 'SK',
    },
    {
      label: i18nTranslate('country.ZA', 'South Africa'),
      value: 'ZA',
    },
    {
      label: i18nTranslate('country.ES', 'Spain'),
      value: 'ES',
    },
    {
      label: i18nTranslate('country.SE', 'Sweden'),
      value: 'SE',
    },
    {
      label: i18nTranslate('country.CH', 'Switzerland'),
      value: 'CH',
    },
    {
      label: i18nTranslate('country.TH', 'Thailand'),
      value: 'TH',
    },
    {
      label: i18nTranslate('country.NL', 'The Netherlands'),
      value: 'NL',
    },
    {
      label: i18nTranslate('country.TR', 'Turkey'),
      value: 'TR',
    },
    {
      label: i18nTranslate('country.UA', 'Ukraine'),
      value: 'UA',
    },
    {
      label: i18nTranslate('country.US', 'United States'),
      value: 'US',
    },
    {
      label: i18nTranslate('country.GB', 'United Kingdom'),
      value: 'GB',
    },
    {
      label: i18nTranslate('country.VN', 'Vietnam'),
      value: 'VN',
    },
  ]
}

const getCountriesList = () => {
  return {
    AF: i18nTranslate('country.AF', 'Afghanistan'),
    AL: i18nTranslate('country.AL', 'Albania'),
    DZ: i18nTranslate('country.DZ', 'Algeria'),
    AS: i18nTranslate('country.AS', 'American Samoa'),
    AD: i18nTranslate('country.AD', 'Andorra'),
    AO: i18nTranslate('country.AO', 'Angola'),
    AI: i18nTranslate('country.AI', 'Anguilla'),
    AQ: i18nTranslate('country.AQ', 'Antarctica'),
    AG: i18nTranslate('country.AG', 'Antigua and Barbuda'),
    AR: i18nTranslate('country.AR', 'Argentina'),
    AM: i18nTranslate('country.AM', 'Armenia'),
    AW: i18nTranslate('country.AW', 'Aruba'),
    AU: i18nTranslate('country.AU', 'Australia'),
    AT: i18nTranslate('country.AT', 'Austria'),
    AZ: i18nTranslate('country.AZ', 'Azerbaijan'),
    BS: i18nTranslate('country.BS', 'Bahamas'),
    BH: i18nTranslate('country.BH', 'Bahrain'),
    BD: i18nTranslate('country.BD', 'Bangladesh'),
    BB: i18nTranslate('country.BB', 'Barbados'),
    BY: i18nTranslate('country.BY', 'Belarus'),
    BE: i18nTranslate('country.BE', 'Belgium'),
    BZ: i18nTranslate('country.BZ', 'Belize'),
    BJ: i18nTranslate('country.BJ', 'Benin'),
    BM: i18nTranslate('country.BM', 'Bermuda'),
    BT: i18nTranslate('country.BT', 'Bhutan'),
    BO: i18nTranslate('country.BO', 'Bolivia'),
    BA: i18nTranslate('country.BA', 'Bosnia and Herzegovina'),
    BW: i18nTranslate('country.BW', 'Botswana'),
    BV: i18nTranslate('country.BV', 'Bouvet Island'),
    BR: i18nTranslate('country.BR', 'Brazil'),
    IO: i18nTranslate('country.IO', 'British Indian Ocean Territory'),
    BN: i18nTranslate('country.BN', 'Brunei Darussalam'),
    BG: i18nTranslate('country.BG', 'Bulgaria'),
    BF: i18nTranslate('country.BF', 'Burkina Faso'),
    BI: i18nTranslate('country.BI', 'Burundi'),
    KH: i18nTranslate('country.KH', 'Cambodia'),
    CM: i18nTranslate('country.CM', 'Cameroon'),
    CA: i18nTranslate('country.CA', 'Canada'),
    CV: i18nTranslate('country.CV', 'Cape Verde'),
    KY: i18nTranslate('country.KY', 'Cayman Islands'),
    CF: i18nTranslate('country.CF', 'Central African Republic'),
    TD: i18nTranslate('country.TD', 'Chad'),
    CL: i18nTranslate('country.CL', 'Chile'),
    CN: i18nTranslate('country.CN', "People's Republic of China"),
    CX: i18nTranslate('country.CX', 'Christmas Island'),
    CC: i18nTranslate('country.CC', 'Cocos (Keeling) Islands'),
    CO: i18nTranslate('country.CO', 'Colombia'),
    KM: i18nTranslate('country.KM', 'Comoros'),
    CG: i18nTranslate('country.CG', 'Republic of the Congo'),
    CD: i18nTranslate('country.CD', 'Democratic Republic of the Congo'),
    CK: i18nTranslate('country.CK', 'Cook Islands'),
    CR: i18nTranslate('country.CR', 'Costa Rica'),
    CI: i18nTranslate('country.CI', "Cote D'Ivoire"),
    HR: i18nTranslate('country.HR', 'Croatia'),
    CU: i18nTranslate('country.CU', 'Cuba'),
    CY: i18nTranslate('country.CY', 'Cyprus'),
    CZ: i18nTranslate('country.CZ', 'Czech Republic'),
    DK: i18nTranslate('country.DK', 'Denmark'),
    DJ: i18nTranslate('country.DJ', 'Djibouti'),
    DM: i18nTranslate('country.DM', 'Dominica'),
    DO: i18nTranslate('country.DO', 'Dominican Republic'),
    EC: i18nTranslate('country.EC', 'Ecuador'),
    EG: i18nTranslate('country.EG', 'Egypt'),
    SV: i18nTranslate('country.SV', 'El Salvador'),
    GQ: i18nTranslate('country.GQ', 'Equatorial Guinea'),
    ER: i18nTranslate('country.ER', 'Eritrea'),
    EE: i18nTranslate('country.EE', 'Estonia'),
    ET: i18nTranslate('country.ET', 'Ethiopia'),
    FK: i18nTranslate('country.FK', 'Falkland Islands (Malvinas)'),
    FO: i18nTranslate('country.FO', 'Faroe Islands'),
    FJ: i18nTranslate('country.FJ', 'Fiji'),
    FI: i18nTranslate('country.FI', 'Finland'),
    FR: i18nTranslate('country.FR', 'France'),
    GF: i18nTranslate('country.GF', 'French Guiana'),
    PF: i18nTranslate('country.PF', 'French Polynesia'),
    TF: i18nTranslate('country.TF', 'French Southern Territories'),
    GA: i18nTranslate('country.GA', 'Gabon'),
    GM: i18nTranslate('country.GM', 'Republic of The Gambia'),
    GE: i18nTranslate('country.GE', 'Georgia'),
    DE: i18nTranslate('country.DE', 'Germany'),
    GH: i18nTranslate('country.GH', 'Ghana'),
    GI: i18nTranslate('country.GI', 'Gibraltar'),
    GR: i18nTranslate('country.GR', 'Greece'),
    GL: i18nTranslate('country.GL', 'Greenland'),
    GD: i18nTranslate('country.GD', 'Grenada'),
    GP: i18nTranslate('country.GP', 'Guadeloupe'),
    GU: i18nTranslate('country.GU', 'Guam'),
    GT: i18nTranslate('country.GT', 'Guatemala'),
    GN: i18nTranslate('country.GN', 'Guinea'),
    GW: i18nTranslate('country.GW', 'Guinea-Bissau'),
    GY: i18nTranslate('country.GY', 'Guyana'),
    HT: i18nTranslate('country.HT', 'Haiti'),
    HM: i18nTranslate('country.HM', 'Heard Island and McDonald Islands'),
    VA: i18nTranslate('country.VA', 'Holy See (Vatican City State)'),
    HN: i18nTranslate('country.HN', 'Honduras'),
    HK: i18nTranslate('country.HK', 'Hong Kong'),
    HU: i18nTranslate('country.HU', 'Hungary'),
    IS: i18nTranslate('country.IS', 'Iceland'),
    IN: i18nTranslate('country.IN', 'India'),
    ID: i18nTranslate('country.ID', 'Indonesia'),
    IR: i18nTranslate('country.IR', 'Islamic Republic of Iran'),
    IQ: i18nTranslate('country.IQ', 'Iraq'),
    IE: i18nTranslate('country.IE', 'Ireland'),
    IL: i18nTranslate('country.IL', 'Israel'),
    IT: i18nTranslate('country.IT', 'Italy'),
    JM: i18nTranslate('country.JM', 'Jamaica'),
    JP: i18nTranslate('country.JP', 'Japan'),
    JO: i18nTranslate('country.JO', 'Jordan'),
    KZ: i18nTranslate('country.KZ', 'Kazakhstan'),
    KE: i18nTranslate('country.KE', 'Kenya'),
    KI: i18nTranslate('country.KI', 'Kiribati'),
    KP: i18nTranslate('country.KP', 'North Korea'),
    KR: i18nTranslate('country.KR', 'South Korea'),
    KW: i18nTranslate('country.KW', 'Kuwait'),
    KG: i18nTranslate('country.KG', 'Kyrgyzstan'),
    LA: i18nTranslate('country.LA', "Lao People's Democratic Republic"),
    LV: i18nTranslate('country.LV', 'Latvia'),
    LB: i18nTranslate('country.LB', 'Lebanon'),
    LS: i18nTranslate('country.LS', 'Lesotho'),
    LR: i18nTranslate('country.LR', 'Liberia'),
    LY: i18nTranslate('country.LY', 'Libya'),
    LI: i18nTranslate('country.LI', 'Liechtenstein'),
    LT: i18nTranslate('country.LT', 'Lithuania'),
    LU: i18nTranslate('country.LU', 'Luxembourg'),
    MO: i18nTranslate('country.MO', 'Macao'),
    MG: i18nTranslate('country.MG', 'Madagascar'),
    MW: i18nTranslate('country.MW', 'Malawi'),
    MY: i18nTranslate('country.MY', 'Malaysia'),
    MV: i18nTranslate('country.MV', 'Maldives'),
    ML: i18nTranslate('country.ML', 'Mali'),
    MT: i18nTranslate('country.MT', 'Malta'),
    MH: i18nTranslate('country.MH', 'Marshall Islands'),
    MQ: i18nTranslate('country.MQ', 'Martinique'),
    MR: i18nTranslate('country.MR', 'Mauritania'),
    MU: i18nTranslate('country.MU', 'Mauritius'),
    YT: i18nTranslate('country.YT', 'Mayotte'),
    MX: i18nTranslate('country.MX', 'Mexico'),
    FM: i18nTranslate('country.FM', 'Micronesia, Federated States of'),
    MD: i18nTranslate('country.MD', 'Moldova, Republic of'),
    MC: i18nTranslate('country.MC', 'Monaco'),
    MN: i18nTranslate('country.MN', 'Mongolia'),
    MS: i18nTranslate('country.MS', 'Montserrat'),
    MA: i18nTranslate('country.MA', 'Morocco'),
    MZ: i18nTranslate('country.MZ', 'Mozambique'),
    MM: i18nTranslate('country.MM', 'Myanmar'),
    NA: i18nTranslate('country.NA', 'Namibia'),
    NR: i18nTranslate('country.NR', 'Nauru'),
    NP: i18nTranslate('country.NP', 'Nepal'),
    NL: i18nTranslate('country.NL', 'Netherlands'),
    NC: i18nTranslate('country.NC', 'New Caledonia'),
    NZ: i18nTranslate('country.NZ', 'New Zealand'),
    NI: i18nTranslate('country.NI', 'Nicaragua'),
    NE: i18nTranslate('country.NE', 'Niger'),
    NG: i18nTranslate('country.NG', 'Nigeria'),
    NU: i18nTranslate('country.NU', 'Niue'),
    NF: i18nTranslate('country.NF', 'Norfolk Island'),
    MK: i18nTranslate('country.MK', 'The Republic of North Macedonia'),
    MP: i18nTranslate('country.MP', 'Northern Mariana Islands'),
    NO: i18nTranslate('country.NO', 'Norway'),
    OM: i18nTranslate('country.OM', 'Oman'),
    PK: i18nTranslate('country.PK', 'Pakistan'),
    PW: i18nTranslate('country.PW', 'Palau'),
    PS: i18nTranslate('country.PS', 'State of Palestine'),
    PA: i18nTranslate('country.PA', 'Panama'),
    PG: i18nTranslate('country.PG', 'Papua New Guinea'),
    PY: i18nTranslate('country.PY', 'Paraguay'),
    PE: i18nTranslate('country.PE', 'Peru'),
    PH: i18nTranslate('country.PH', 'Philippines'),
    PN: i18nTranslate('country.PN', 'Pitcairn'),
    PL: i18nTranslate('country.PL', 'Poland'),
    PT: i18nTranslate('country.PT', 'Portugal'),
    PR: i18nTranslate('country.PR', 'Puerto Rico'),
    QA: i18nTranslate('country.QA', 'Qatar'),
    RE: i18nTranslate('country.RE', 'Reunion'),
    RO: i18nTranslate('country.RO', 'Romania'),
    RU: i18nTranslate('country.RU', 'Russian Federation'),
    RW: i18nTranslate('country.RW', 'Rwanda'),
    SH: i18nTranslate('country.SH', 'Saint Helena'),
    KN: i18nTranslate('country.KN', 'Saint Kitts and Nevis'),
    LC: i18nTranslate('country.LC', 'Saint Lucia'),
    PM: i18nTranslate('country.PM', 'Saint Pierre and Miquelon'),
    VC: i18nTranslate('country.VC', 'Saint Vincent and the Grenadines'),
    WS: i18nTranslate('country.WS', 'Samoa'),
    SM: i18nTranslate('country.SM', 'San Marino'),
    ST: i18nTranslate('country.ST', 'Sao Tome and Principe'),
    SA: i18nTranslate('country.SA', 'Saudi Arabia'),
    SN: i18nTranslate('country.SN', 'Senegal'),
    SC: i18nTranslate('country.SC', 'Seychelles'),
    SL: i18nTranslate('country.SL', 'Sierra Leone'),
    SG: i18nTranslate('country.SG', 'Singapore'),
    SK: i18nTranslate('country.SK', 'Slovakia'),
    SI: i18nTranslate('country.SI', 'Slovenia'),
    SB: i18nTranslate('country.SB', 'Solomon Islands'),
    SO: i18nTranslate('country.SO', 'Somalia'),
    ZA: i18nTranslate('country.ZA', 'South Africa'),
    GS: i18nTranslate(
      'country.GS',
      'South Georgia and the South Sandwich Islands'
    ),
    ES: i18nTranslate('country.ES', 'Spain'),
    LK: i18nTranslate('country.LK', 'Sri Lanka'),
    SD: i18nTranslate('country.SD', 'Sudan'),
    SR: i18nTranslate('country.SR', 'Suriname'),
    SJ: i18nTranslate('country.SJ', 'Svalbard and Jan Mayen'),
    SZ: i18nTranslate('country.SZ', 'Eswatini'),
    SE: i18nTranslate('country.SE', 'Sweden'),
    CH: i18nTranslate('country.CH', 'Switzerland'),
    SY: i18nTranslate('country.SY', 'Syrian Arab Republic'),
    TW: i18nTranslate('country.TW', 'Taiwan, Province of China'),
    TJ: i18nTranslate('country.TJ', 'Tajikistan'),
    TZ: i18nTranslate('country.TZ', 'United Republic of Tanzania'),
    TH: i18nTranslate('country.TH', 'Thailand'),
    TL: i18nTranslate('country.TL', 'Timor-Leste'),
    TG: i18nTranslate('country.TG', 'Togo'),
    TK: i18nTranslate('country.TK', 'Tokelau'),
    TO: i18nTranslate('country.TO', 'Tonga'),
    TT: i18nTranslate('country.TT', 'Trinidad and Tobago'),
    TN: i18nTranslate('country.TN', 'Tunisia'),
    TR: i18nTranslate('country.TR', 'Turkey'),
    TM: i18nTranslate('country.TM', 'Turkmenistan'),
    TC: i18nTranslate('country.TC', 'Turks and Caicos Islands'),
    TV: i18nTranslate('country.TV', 'Tuvalu'),
    UG: i18nTranslate('country.UG', 'Uganda'),
    UA: i18nTranslate('country.UA', 'Ukraine'),
    AE: i18nTranslate('country.AE', 'United Arab Emirates'),
    GB: i18nTranslate('country.GB', 'United Kingdom'),
    US: i18nTranslate('country.US', 'United States of America'),
    UM: i18nTranslate('country.UM', 'United States Minor Outlying Islands'),
    UY: i18nTranslate('country.UY', 'Uruguay'),
    UZ: i18nTranslate('country.UZ', 'Uzbekistan'),
    VU: i18nTranslate('country.VU', 'Vanuatu'),
    VE: i18nTranslate('country.VE', 'Venezuela'),
    VN: i18nTranslate('country.VN', 'Vietnam'),
    VG: i18nTranslate('country.VG', 'Virgin Islands, British'),
    VI: i18nTranslate('country.VI', 'Virgin Islands, U.S.'),
    WF: i18nTranslate('country.WF', 'Wallis and Futuna'),
    EH: i18nTranslate('country.EH', 'Western Sahara'),
    YE: i18nTranslate('country.YE', 'Yemen'),
    ZM: i18nTranslate('country.ZM', 'Zambia'),
    ZW: i18nTranslate('country.ZW', 'Zimbabwe'),
    AX: i18nTranslate('country.AX', 'Åland Islands'),
    BQ: i18nTranslate('country.BQ', 'Bonaire, Sint Eustatius and Saba'),
    CW: i18nTranslate('country.CW', 'Curaçao'),
    GG: i18nTranslate('country.GG', 'Guernsey'),
    IM: i18nTranslate('country.IM', 'Isle of Man'),
    JE: i18nTranslate('country.JE', 'Jersey'),
    ME: i18nTranslate('country.ME', 'Montenegro'),
    BL: i18nTranslate('country.BL', 'Saint Barthélemy'),
    MF: i18nTranslate('country.MF', 'Saint Martin (French part)'),
    RS: i18nTranslate('country.RS', 'Serbia'),
    SX: i18nTranslate('country.SX', 'Sint Maarten (Dutch part)'),
    SS: i18nTranslate('country.SS', 'South Sudan'),
    XK: i18nTranslate('country.XK', 'Kosovo'),
  }
}

// Replace it with a common country + State npm package
export const allCountriesJson = options => {
  const { isToReturnAllCountries, countryCode = '' } = options || {}

  const countriesList = getCountriesList()
  if (isToReturnAllCountries) {
    return countriesList
  } else if (countryCode) {
    return countriesList?.[countryCode]
  }
}
