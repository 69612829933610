import { IS_BROWSER } from 'config/appConfig'

export const createFocusTrap = modalSelector => {
  if (IS_BROWSER) {
    const handleTabKey = e => {
      const modal = document.querySelector(modalSelector || '.modal-dialog')
      if (!modal) return

      const focusableElements = modal.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      const firstElement = focusableElements[0]
      const lastElement = focusableElements[focusableElements.length - 1]

      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            lastElement.focus()
            e.preventDefault()
          }
        } else {
          if (document.activeElement === lastElement) {
            firstElement.focus()
            e.preventDefault()
          }
        }
      }
    }

    const initializeFocus = () => {
      const modal = document.querySelector(modalSelector)
      const firstFocusable = modal?.querySelector(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      if (firstFocusable) {
        firstFocusable.focus()
      }
    }

    return {
      enable: () => {
        document.addEventListener('keydown', handleTabKey)
        initializeFocus()
      },
      disable: () => {
        document.removeEventListener('keydown', handleTabKey)
      },
    }
  }
}
