import { i18nTranslate } from 'src/utils'

export const countriesList = (isToSendAsCA = false) => {
  return [
    {
      label: i18nTranslate('country.default', 'Select Your Country'),
      value: '',
    },
    {
      label: i18nTranslate('country.US', 'US'),
      value: 'US',
    },
    {
      label: i18nTranslate('country.canada', 'Canada'),
      value: isToSendAsCA ? 'CA' : 'Canada',
    },
    // {
    //   label: i18nTranslate('country.china', 'China'),
    //   value: 'China',
    // },
    // {
    //   label: i18nTranslate('country.Kuwait', 'Kuwait'),
    //   value: 'Kuwait',
    // },
  ]
}
