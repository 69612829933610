import React from 'react'

const ProductContext = React.createContext({
  history: {},
  location: {},
  match: {},
})

const PDPContext = React.createContext({
  isPDP: false,
})

export { ProductContext, PDPContext }
