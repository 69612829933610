import React from 'react'
import {
  ApiContent,
  application,
  IS_BROWSER,
  getLocaleCodeFromUrl,
} from 'src/utils'
import { APPConfig, getAppConfig } from 'config/appConfig'
import { getResourceFrom3rdPartyUrl } from 'src/api/localeResources'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { customerContainer } from 'src/models'
import './styles.scss'

@observer
class HeaderMarketing extends React.Component {
  @observable headerDataFromGit = {}
  static contextType = ApiContent
  constructor(props, context) {
    super(props)
    this.context = context
    this.contextName = 'headerDataFromGitFromContext'
    this.currentLocale =
      this.context?.curLocaleFromUrl ||
      getLocaleCodeFromUrl({
        locationProps: props,
        at: 'pathParam',
        defaultLocale: 'en_US',
        isReverseType: true,
      })
    this.getDataFromSource()
  }

  getDataFromSource = async () => {
    let headerDataFromGitFromContext =
      this.context?.data?.headerDataFromGitFromContext || {}
    if (Object.keys(headerDataFromGitFromContext).length > 0) {
      this.headerDataFromGit = headerDataFromGitFromContext
    } else {
      await this.getHeaderDataFromGit()
    }
  }

  getHeaderDataFromGit = async () => {
    const locale = (this.props?.queryParams?.locale || 'en_US').replace(
      '_',
      '-'
    )
    const loadParams = {
      contextName: this.contextName,
      credentials: 'omit',
      isToIncludeCommomHeaders: false,
      deleteContentType: true,
      fileName: 'header',
      locale,
      domainName:
        APPConfig?.getAppConfig().localeResourceFilesDomain || 'dev.nuskin.com',
    }

    let response = {}

    if (IS_BROWSER) {
      response = await getResourceFrom3rdPartyUrl(loadParams)
    } else {
      this.context.load(getResourceFrom3rdPartyUrl(loadParams))
    }

    if (customerContainer.isSuccessResponse(response)) {
      this.headerDataFromGit = response
    } else {
      console.error('Fn Error in header response from git', response)
    }
  }

  render() {
    const { link = '', text = '' } =
      this.headerDataFromGit?.headerTopBanner?.[0] || {}
    // currently we have only one entry ( design is also static for one entry )
    // to be made dynamic in later designs
    if (!text) {
      return null
    }
    return (
      <div className="top-header-banner">
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span>{text}</span>
        </a>
      </div>
    )
  }
}

export { HeaderMarketing }
export default HeaderMarketing
