import { observable } from 'mobx'
import omit from 'lodash/omit'
import { productDeps } from 'src/deps'
import { catalogContainer, cartContainer, promotionContainer } from 'src/models'
import { getProductProperties } from 'src/views/components/BaseProductComponent/deps'
import { productState } from 'src/views/components/BaseProductComponent'
import { catalogDeps } from 'src/deps'
import { APPConfig } from 'config/appConfig'

class QuickViewState {
  @observable isQuickViewOpen = false
  @observable productResponse = {}
  @observable product = {}
  @observable idToReFocus = ''
  @observable subscribeAndSave = 0
  @observable productCount = 1
  @observable pdpPromotionResponse = {}
  @observable quantityActionDisable = false
  @observable normalPdt_unitValueForSubscriptionPopup = {}
  @observable normalPdt_totalValueForSubscriptionPopup = {}
  resetValues = () => {
    this.pdpPromotionResponse = {}
    productState.currentlySelectedPriceOption = 'one-time-price-button'
    promotionContainer.subscriptionV2PromoResponse = {}
  }

  toggleQuickView = props => {
    this.isQuickViewOpen = !this.isQuickViewOpen
    this.idToReFocus()
    this.productCount = 1
    this.resetValues()
  }
  getProduct = async props => {
    const productId = props?.productId || 110001
    const categoryId = props?.categoryId || ''
    const defaultVariantForQuickView = props?.defaultVariantForQuickView || ''
    const isMultiVariant = props?.isMultiVariant || false
    this.idToReFocus = props.handleFocus
    const response = await catalogContainer.getProduct({
      productId,
      categoryId,
    })
    this.constructDataAndSetProductResponse(
      response,
      '',
      defaultVariantForQuickView
    )
    await this.getQuickViewPromotion(defaultVariantForQuickView, isMultiVariant)
    this.isQuickViewOpen = true
  }

  getQuickViewPromotion = async (
    defaultVariantForQuickView = '',
    isMultiVariant = false
  ) => {
    const isSubscriptionProductObject = {
      productId: this.product.productId,
      categoryId: this.product.categoryId || '',
      type: this.product.type || 'DEFAULT',
      quantity: String(this.productCount ? this.productCount : 1),
      skuId: this.product.skuId,
      isSubscriptionIncludedInPromotion: true,
    }

    const browseProductObject = JSON.parse(
      JSON.stringify(isSubscriptionProductObject)
    )
    browseProductObject.isSubscriptionIncludedInPromotion = false
    const isToEnableProductPromotionAPI =
      APPConfig?.getAppConfig()?.enableProductPromotionAPI == 'true' || false
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    let browseResponse = {}
    if (enableNewPDPStyle) {
      // @##makenewpromo
      if (defaultVariantForQuickView && isMultiVariant) {
        promotionContainer.makeV2PromotionCall(this.product, this.productCount)
      }
    } else {
      if (
        isToEnableProductPromotionAPI ||
        (defaultVariantForQuickView && isMultiVariant)
      ) {
        // for multivariant, we have to make API to get correct isSubscribeSaveEnabled
        browseResponse = await cartContainer.makePromotionCall(
          browseProductObject
        )
      } else {
        if (this.productResponse?.product) {
          const defaultSku = catalogDeps.getDefaultSelectedSku(
            this.productResponse?.product || {}
          )
          const defaultSkuPrice = defaultSku?.[0]?.totalValue || ''
          const defaultSkuPromotion = defaultSku?.[0]?.promotion || []
          // CX12-10291
          // quick view initial promotion
          // fixed to sku level ( should not show from product level )
          browseResponse = {
            ...this.productResponse?.product,
            status: 'success',
          }
          if (defaultSkuPrice) {
            browseResponse.unitValue = defaultSkuPrice
            browseResponse.promotion = defaultSkuPromotion
            /* @##pdp_price_QUICKVIEW unit price */
            /* @##pdp_price_QUICKVIEW promotion */
          }
        }
      }
      if (browseResponse) {
        if (browseResponse?.unitValue) {
          /* @##pdp_price_QUICKVIEW unit price */
          this.pdpPromotionResponse = browseResponse
        }
      }

      /* @##pdp_call_init for quickview */
      let subscriptionResponse = {}
      if (isToEnableProductPromotionAPI) {
        subscriptionResponse =
          await cartContainer.makePromotionCallsForNormalProduct(
            isSubscriptionProductObject
          )
      } else {
        // skip making API
        // works fine for now,
        // no need to set data from pdp call to promotion observable
      }

      this.setValuesForSubscribeAndSaveText(subscriptionResponse)
    }
  }

  compareNormalProductPromotionsAndUpdate = async (
    product = {},
    latestQtyResponse
  ) => {
    /*
    adding this code for 
    previously, we make promotion call and update only quantity based total price
    currently we are making promotion call and updating promotion detail and unit price
    */

    try {
      if (
        this.pdpPromotionResponse?.promotion &&
        latestQtyResponse?.promotion &&
        JSON.stringify(this.pdpPromotionResponse?.promotion) !==
          JSON.stringify(latestQtyResponse?.promotion)
      ) {
        const promotionProductObject = {
          productId: product.productId || product.identifier || '',
          categoryId: product.categoryId || '',
          type: product.type || 'DEFAULT',
          quantity: '1',
          skuId: product.skuId || '',
        }

        // get promo with qty 1
        // for showing Single unit discounted price in modal
        // call has to be made for pdp also
        // to display correct unit price
        const responseWithQtyAs1 =
          await cartContainer.makePromotionCallsForNormalProduct(
            promotionProductObject
          )

        // total value with quantity 1 can be used as unit price

        if (responseWithQtyAs1?.totalValue) {
          this.product.totalValue = responseWithQtyAs1?.totalValue
          /* ^ for pdp - normal pdt  */
          // this.singleQtyNormalProductResponse.totalValue =
        }
        if (latestQtyResponse?.promotion) {
          this.product.promotion = latestQtyResponse?.promotion
          // latest promotion data to be updated for labels
        }
      }
    } catch (error) {
      console.log(
        'error while comparing promotions and updating unit price quickView compareNormalProductPromotionsAndUpdate',
        error
      )
    }
  }

  makePromotionCallsForNormalProductQuickViewQtyChange = async (
    product = {},
    productCount = 1
  ) => {
    this.quantityActionDisable = true
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    if (enableNewPDPStyle) {
      await promotionContainer.makeV2PromotionCall(
        this.product,
        product?.productCount
      )
    } else {
      const productObject = {
        productId: this.product?.productId || '',
        categoryId: this.product?.categoryId || '',
        type: this.product?.type || 'DEFAULT',
        quantity: productCount.toString(),
        skuId: this.product?.skuId || '',
      }
      const isSubscriptionProductObject = {
        productId: this.product?.productId,
        categoryId: this.product?.categoryId || '',
        type: this.product?.type || 'DEFAULT',
        quantity: String(productCount ? productCount : this.productCount),
        skuId: this.product?.skuId,
        isSubscriptionIncludedInPromotion: true,
        isSubscriptionPromotion: product?.isSubscriptionPromotion || false,
      }

      const response = await cartContainer.makePromotionCallsForNormalProduct(
        productObject
      )
      if (!product.isFromSubscriptionModal) {
        // while changing qty, when promotion changes, we need to update unit price and promotion display
        // not required for modal
        this.compareNormalProductPromotionsAndUpdate(product, response)
      }
      const subscriptionResponse =
        await cartContainer.makePromotionCallsForNormalProduct(
          isSubscriptionProductObject
        )

      if (
        response &&
        cartContainer.isSuccessResponse(response) &&
        !product.isFromSubscriptionModal
      ) {
        this.pdpPromotionResponse = response
      }

      if (product.isFromSubscriptionModal) {
        // only qty change scenario
        // for normalproduct in quickview
        // @set_subscription_pop_up_price
        this.normalPdt_unitValueForSubscriptionPopup =
          subscriptionResponse.unitValue || {}
        this.normalPdt_totalValueForSubscriptionPopup =
          subscriptionResponse.totalValue || {}
      }

      this.setValuesForSubscribeAndSaveText(
        subscriptionResponse,
        product.isFromSubscriptionModal
      )
    }
    this.quantityActionDisable = false
  }

  setValuesForSubscribeAndSaveText = (
    response,
    isFromSubscriptionModal = false
  ) => {
    if (response && catalogContainer.isSuccessResponse(response)) {
      const isToEnableProductPromotionAPI =
        APPConfig?.getAppConfig()?.enableProductPromotionAPI == 'true' || false
      if (isToEnableProductPromotionAPI) {
        const discountWithoutSubscription =
          this.pdpPromotionResponse?.totalValue?.totaldiscount || 0
        const discountWithSubscription =
          response?.totalValue?.totaldiscount || 0
        this.subscribeAndSave =
          discountWithoutSubscription === discountWithSubscription
            ? 0
            : discountWithSubscription - discountWithoutSubscription
      }
    } else {
      this.subscribeAndSave = 0
    }
  }

  constructDataAndSetProductResponse = (
    response,
    skuId,
    defaultVariantForQuickView
  ) => {
    if (response && response.product) {
      const data = catalogContainer.isSuccessResponse(response)
        ? productDeps?.transformProductResponse(
            response,
            skuId,
            null,
            defaultVariantForQuickView
          )
        : response

      const product = data?.product?.[0] || {}
      const categoryId = response?.category?.identifier || ''
      const additionalProps = { categoryId }
      const productProperties = getProductProperties(product, additionalProps)
      this.product = {
        skuSelected: product.skuSelected,
        skuList: product.sku,
        productOption: product.productOption,
        productId: product.identifier,
        ...productProperties,
      }

      this.pdpPromotionResponse = this.product
      this.productResponse = {
        ...response,
        product: omit(product, [
          'skuSelected',
          'selectedSku',
          'previousSku',
          'productOption',
        ]),
      }
    } else {
      this.product = {}
      this.productResponse = {}
    }
  }

  updateSku = async product => {
    const productProperties = getProductProperties(
      {
        previousSku: product.previousSku,
        ...this.productResponse?.product,
      },
      {}
    )

    this.product = {
      skuSelected: product.skuSelected,
      skuList: product.skuList,
      productOption: product.productOption,
      ...productProperties,
    }
    // @##makenewpromo
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    if (enableNewPDPStyle) {
      await promotionContainer.makeV2PromotionCall(
        this.product,
        product?.productCount
      )
    } else {
      await this.makePromotionCallsForNormalProductQuickViewQtyChange(
        {},
        product?.productCount || 1
      )
    }
  }
}

const quickViewState = new QuickViewState()

export { quickViewState }
