import path from 'path'
import i18n from 'i18next'
// import Backend from 'i18next-node-fs-backend'
import Backend from 'i18next-fs-backend'
import i18nextMiddleware from 'i18next-http-middleware'
import { initReactI18next } from 'react-i18next'
import I18nextHttpBackend from 'i18next-http-backend'
import BrowserLanguageDetector from 'i18next-browser-languagedetector'
import { IS_BROWSER } from 'src/utils'
import { APPConfig } from 'config/appConfig'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'

// for SSR
const localePathAtSSR = './dist/locale/{{lng}}/{{ns}}.json'

// for CSR
const localePathAtCSR = '/locale/{{lng}}/{{ns}}.json'

// for yarn dev & local run
const localePathAtLocal = '/src/static/locale/{{lng}}/{{ns}}.json'

const i18nBaseOptions = {
  ns: ['ssr-resource'],
  defaultNS: 'ssr-resource',
  fallbackLng: false,
  fallbackNS: ['ssr-resource'],
  // debug: true,
  load: 'currentOnly',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['customLngDetector', 'path', 'cookie', 'localStorage'],
    lookupFromPathIndex: [0, 1],
    lookupQuerystring: 'locale',
    lookupCookie: 'locale',
    lookupLocalStorage: 'locale',
    lookupSessionStorage: 'locale',
    lookupSession: 'locale',
    caches: false,
  },
  backend: {
    ajax: async (url, options, callback) => {
      const xhr = { status: 200 }
      console.log({ url, options, callback })

      if (url) {
        try {
          if (typeof window != 'undefined' && window.initialI18nStore) {
            const localeValue = window.initialLocale || 'en-US'
            console.log({
              localeValue,
            })
            return callback(window.initialI18nStore[localeValue], {
              status: 200,
            })
          }
        } catch (e) {
          // console.log('i18n data fetch: ', e)
          callback({}, { status: 500 })
        }
      } else {
        const data = {}
        callback(data, xhr)
      }
    },
    parse: data => data,
  },
}

function getCustomLanguageDetector() {
  const detectorOptions = {
    name: 'customLngDetector',
    lookup(options) {
      var language = window.location.pathname.match(/\/([a-zA-Z-]*)/g)
      var found = ''

      if (options.lookupFromPathIndex.length) {
        options.lookupFromPathIndex.map(index => {
          if (language[index]) {
            found =
              index === 0
                ? language[index].replace('/', '')
                : language[index].replace('/', '') + '-' + found.toUpperCase()
          }
        })
      }
      console.log({ found })
      return found
    },
    cacheUserLanguage(lng, options) {},
  }

  const lngDetector = new BrowserLanguageDetector()
  lngDetector.addDetector(detectorOptions)

  return lngDetector
}

function initI18nForServer(cbk) {
  if (!i18n.isInitialized) {
    i18n
      .use(Backend)
      .use(i18nextMiddleware.LanguageDetector)
      .init(
        {
          ...i18nBaseOptions,
          // debug: true,
          backend: {
            loadPath: path.join(process.cwd(), localePathAtSSR),
          },
        },
        () => {
          console.log('initI18nForServer ')
          if (cbk) {
            cbk(i18n, i18nextMiddleware)
          }
        }
      )
  }
}

function initI18nForClient(isFromHydrate, cbk) {
  if (!i18n.isInitialized && IS_BROWSER) {
    const customLangDetector = getCustomLanguageDetector()
    let currentLocale = getLocaleCodeFromUrl()?.split('_')
    currentLocale =
      '/' +
      currentLocale[1]?.toLowerCase() +
      '/' +
      currentLocale[0]?.toLowerCase()

    const appConfig = APPConfig?.getAppConfig()
    const shouldLoadResourcesFromUrl =
      appConfig?.shouldLoadResourcesFromUrl == 'true'
    const localeResourceFilesDomain = appConfig?.localeResourceFilesDomain

    let localePath = ''
    if (shouldLoadResourcesFromUrl) {
      // localePath = `https://${localeResourceFilesDomain}/assets/translations/{{lng}}/{{ns}}.json`
      localePath = `https://${document.domain}${currentLocale}/assets/translations/{{lng}}/{{ns}}.json`
    } else {
      localePath = currentLocale + localePathAtCSR
    }

    i18n
      .use(initReactI18next)
      .use(customLangDetector)
      .use(I18nextHttpBackend)
      .init(
        {
          ...i18nBaseOptions,
          backend: {
            loadPath: isFromHydrate ? localePath : localePathAtLocal,
          },
        },
        () => {
          if (cbk) {
            cbk()
          }
        }
      )
  }
}

export {
  i18nBaseOptions,
  localePathAtSSR,
  localePathAtCSR,
  localePathAtLocal,
  initI18nForServer,
  initI18nForClient,
  getCustomLanguageDetector,
}

export default i18n
