import React from 'react'
import { Spinner } from 'react-bootstrap'

class Loader extends React.Component {
  render() {
    return (
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }
}

export { Loader }
