import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { isToEnableSSR } from 'config/appConfig'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import './styles.scss'

@observer
class KoreChat extends Component {
  @observable
  isToLoadResources = false

  showChatWindow = () => {
    console.log('show chat window')
    const element = document.querySelector('.kore-chat-window')
    if (element) {
      const minimizedElement = element.querySelector('.minimized')
      if (minimizedElement) {
        minimizedElement.click()
      }
    }
  }

  handleClick = async () => {
    this.isToLoadResources = true
    this.checkForScriptAndInitiateClick()
  }

  checkForScriptAndInitiateClick = () => {
    if (document?.querySelector('.kore-chat-window')) {
      this.showChatWindow()
    } else {
      setTimeout(this.checkForScriptAndInitiateClick, 100)
    }
  }

  getAIResources = () => {
    let urlPrefix = '/storefront/client/lib'

    if (!isToEnableSSR) {
      urlPrefix = 'https://raderain.sirv.com/ns'
    }

    return (
      <Helmet>
        <link href={`${urlPrefix}/kore-ai-sdk.min.css`} rel="stylesheet" />
        <script defer src={`${urlPrefix}/kore-ai-sdk.min.js`}></script>
      </Helmet>
    )
  }

  render() {
    return (
      <span className="kore-chat">
        <button className="btn" onClick={e => this.handleClick()}>
          {i18nTranslate('header.chat', 'Chat', { nameSpace: 'ssr-resource' })}
        </button>
        {this.isToLoadResources && this.getAIResources()}
      </span>
    )
  }
}

export default KoreChat
export { KoreChat }
