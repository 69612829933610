import { i18nTranslate } from 'src/utils'
import { action, observable } from 'mobx'
import {
  CommonContainer,
  subscriptionContainer,
  cartContainer,
  customerContainer,
} from 'src/models'
import { productDeps } from 'src/deps'
import { quickViewState, toastState } from 'src/views/components'
import { pageNames } from 'src/routes/pathParams'
import { getProductDetailsForCart } from 'src/views/components/CartBlock/deps'
import { APPConfig } from 'config/appConfig'
import { IS_BROWSER, checkMysiteOrPersonalOffer } from 'src/utils'
import { productState } from 'src/views/components/BaseProductComponent'

/**
 * NuskinSubscriptionContainer class.
 * Extends CommonContainer.
 */
class NuskinSubscriptionContainer extends CommonContainer {
  @observable isToDisableSubscriptionButton = false
  @observable isViewCartCallInProgress = true
  @observable enableEmptyCartMessage = false
  @observable enableItemSubscribedMessage = false
  @observable disabledItemSubscribeMessage = false
  @observable enableCartConvertMessage = false
  @observable OTPConvertedProductName = ''
  @observable enableGoToCart = false
  @observable isAPICallInProgress = false
  @observable isAlreadySubscribedProduct = false
  @observable showModalContent = true
  @observable isToShowDisabledModal = false
  @observable clickedProductSkuId = ''
  @observable displaySubscribeAndSavePrice = false
  @observable subscriptionData = {}
  @observable cartSubscriptionDrawerData = {}
  @observable subscriptionPreferenceResponse = {}
  @observable isSubscriptionApiInProgress = false
  activeProductSkuId = ''

  /**
   * Returns the total count of active subscription items from the cart subscription response.
   *
   * @returns {number} The total count of active subscription items.
   */
  getTotalActiveSubscriptionItemCount = () => {
    return (
      cartContainer.subscriptionResponse
        ?.totalActiveSubscriptionItemQuantityCount || 0
    )
  }

  /**
   * Returns the user's subscription preference from the cart subscription response.
   *
   * @returns {boolean} The user's subscription preference value.
   */
  hasSubscriptionPreference = () => {
    return cartContainer.subscriptionResponse?.isSubscriptionPreference || false
  }

  /**
   * Returns the total count of subscriptions from the cart subscription response.
   *
   * @returns {number} The total subscription count.
   */
  getTotalSubscriptionCount = () => {
    return cartContainer.subscriptionResponse?.totalSubscriptionCount || 0
  }

  /**
   * Returns the user's subscription preference value
   * from the cart subscription response.
   *
   * @returns {boolean} The user's subscription preference value.
   */
  getUserSubscriptionPreference = () => {
    return cartContainer.subscriptionResponse?.isSubscriptionPreference || false
  }

  /**
   * Returns the subscription list from the subscription data.
   *
   * @returns {Array} The subscriptions array from the subscriptionData object.
   */
  getSubscriptionList = () => {
    return this.subscriptionData?.subscriptions || []
  }

  @action
  collectAllSubscriptions = async (subscribedSkus = []) => {
    /**
     * @note
     * collecting all the subscription list
     * and save in the NuskinSubscription container
     * when user has more than 50 subscriptions, we getting subscriptions list call is failing (409)
     * due to this, the button state is not updated correctly - CX15-7983
     * subscriptions list call updated with skuId for pdp/plp quickView - output will single sku
     * for multipleskus in viewcart - calls splitted into 2, with size and page
     * subscribedSkus - have skuId alone of active subscriptions which we trying to add to dashboard/place order
     */

    const totalActiveSubscriptionCount =
      this.getTotalActiveSubscriptionItemCount()

    //Multiple sku id support will be available in future , at the time need to combine skus with comma separated
    let skuId = ''
    let skuIdArray = []

    for (const sku of subscribedSkus) {
      const skuIds = sku?.skuId || ''
      if (Array.isArray(skuIds) && skuIds?.length > 1) {
        for (const innerSku of skuIds) {
          skuIdArray.push(innerSku)
        }
      } else {
        skuIdArray.push(sku?.skuId || '')
      }
    }

    skuId = skuIdArray?.join(',') || subscribedSkus?.[0]?.skuId
    let skuType = subscribedSkus?.[0]?.isBundle
      ? 'BUNDLE,SKUKIT,OPTIONAL'
      : 'DEFAULT'

    let params = {}
    let subscriptionRefineData = {}

    if (IS_BROWSER && typeof window !== 'undefined') {
      let isSubscriptionPage =
        window.location.pathname.includes(pageNames?.myaccountsubscriptions) ||
        false
      let isViewCartPage =
        window.location.pathname.includes(pageNames?.viewCart) || false

      const isToEnableOneTimeFrequency =
        APPConfig?.getAppConfig()?.oneTimeFrequencyEnabled === 'true'

      subscriptionRefineData =
        isToEnableOneTimeFrequency && isSubscriptionPage
          ? {
              filters: [
                { field: 'status', operation: 'IN', value: 'ACTIVE,PENDING' },
                {
                  field: 'type',
                  operation: 'IN',
                  value: 'VARIABLE_MULTI_ORDER,VARIABLE_SINGLE_ORDER',
                },
              ],
            }
          : {
              filters: [
                { field: 'status', operation: 'IN', value: 'ACTIVE,PENDING' },
              ],
            }
      if (isToEnableOneTimeFrequency && isSubscriptionPage) {
        params = { enabledefaulttype: false }
      }
      if (isViewCartPage || isSubscriptionPage) {
        if (totalActiveSubscriptionCount > 50) {
          params.size = 50
          params.page = 1

          let subscriptionListFirst =
            await subscriptionContainer.getAllSubscriptions(
              subscriptionRefineData,
              params
            )
          params.size = 50
          params.page = 2

          let subscriptionListSecond =
            await subscriptionContainer.getAllSubscriptions(
              subscriptionRefineData,
              params
            )

          const subscriptionsMergedList = [
            ...(subscriptionListFirst?.subscriptions || []),
            ...(subscriptionListSecond?.subscriptions || []),
          ]

          this.subscriptionData = {
            subscriptions: subscriptionsMergedList,
          }
        } else if (totalActiveSubscriptionCount < 50) {
          params.size = totalActiveSubscriptionCount || 50
          params.page = 1

          this.subscriptionData =
            await subscriptionContainer.getAllSubscriptions(
              subscriptionRefineData,
              params
            )
        }
      } else {
        if (subscribedSkus.length === 1) {
          if (isToEnableOneTimeFrequency) {
            subscriptionRefineData = {
              filters: [
                { field: 'status', operation: 'IN', value: 'ACTIVE,PENDING' },
                {
                  field: 'type',
                  operation: 'IN',
                  value: 'VARIABLE_MULTI_ORDER,VARIABLE_SINGLE_ORDER',
                },
                {
                  field: 'skuId',
                  operation: skuIdArray.length > 1 ? 'IN' : 'EQUALS_IC',
                  value: skuId,
                },
                {
                  field: 'skuType',
                  operation: 'IN',
                  value: skuType,
                },
              ],
            }
          } else {
            subscriptionRefineData = {
              filters: [
                { field: 'status', operation: 'IN', value: 'ACTIVE,PENDING' },
                {
                  field: 'skuId',
                  operation: skuIdArray.length > 1 ? 'IN' : 'EQUALS_IC',
                  value: skuId,
                },
                {
                  field: 'skuType',
                  operation: 'IN',
                  value: skuType,
                },
              ],
            }
          }
          if (isToEnableOneTimeFrequency) {
            params = { enabledefaulttype: false, size: skuIdArray.length || 1 }
          } else {
            params = { size: skuIdArray.length || 1 }
          }
          this.subscriptionData =
            await subscriptionContainer.getAllSubscriptions(
              subscriptionRefineData,
              params
            )
        }
      }
    }

    // if (totalActiveSubscriptionCount > 0) {
    //   await subscriptionContainer.getAllSubscriptions(
    //     subscriptionRefineData,
    //     params
    //   )
    // }
  }

  /**
   * Sets the value of the disabledItemSubscribeMessage property.
   *
   * @param {boolean} value - The new value to set. Default is false.
   */
  updateDisabledItemSubscribeMessage = (value = false) => {
    this.disabledItemSubscribeMessage = value
  }

  /**
   * Checks if a product is already subscribed by the current user.
   *
   * @param {object} props - The product details
   * @returns {boolean} - True if the product is already subscribed, false otherwise
   */
  isAlreadySubscribedProduct = props => {
    const isBundle = props?.isBundle || props.product?.isBundle || false
    const totalSubscriptionCount = this.getTotalSubscriptionCount()
    const subscriptionList = this.getSubscriptionList()
    const checkBundleProduct = () => {
      // Comparing 'productId' to check if bundle / kit is subscribed
      const bundleKitProductId =
        props.product?.productId || props.productId || ''

      return subscriptionList?.some(item => {
        let skus = (item?.itemInfo?.length && item.itemInfo[0].skus) || []
        return skus.some(sku => {
          return (
            bundleKitProductId?.toLowerCase() === sku?.productId?.toLowerCase()
          )
        })
      })
    }

    const checkOtherProduct = () => {
      const skuId = props?.skuId || props.product?.skuId || ''
      return subscriptionList.some(item => {
        if (item?.itemInfo?.[0].skus?.length <= 1) {
          // here only check with normal skus product
          const id = item?.itemInfo?.[0].skus?.[0]?.id || ''
          return id == skuId
        }
        return false
      })
    }

    if (!totalSubscriptionCount) {
      // this.isAlreadySubscribedProduct = false
      return false
    } else if (isBundle) {
      const isBundleSubscribedAlready = checkBundleProduct()
      // this.isAlreadySubscribedProduct = isBundleSubscribedAlready
      return isBundleSubscribedAlready
    } else {
      const isOtherProductSubscribedAlready = checkOtherProduct()
      // this.isAlreadySubscribedProduct = isOtherProductSubscribedAlready
      return isOtherProductSubscribedAlready
    }
  }

  /**
   * Checks if the given product is already subscribed by the user.
   * For bundle products, checks if the bundle is subscribed.
   * For other products, checks if the SKU is subscribed.
   * Returns true if product/bundle/SKU is already subscribed, false otherwise.
   */
  getClickedProductDetails = props => {
    const isBundle = props?.isBundle || props.product?.isBundle || false
    const totalSubscriptionCount = this.getTotalSubscriptionCount()
    const subscriptionList = this.getSubscriptionList()
    const checkBundleProduct = () => {
      // Comparing 'productId' to check if bundle / kit is subscribed
      const bundleKitProductId =
        props.product?.productId || props.productId || ''

      return subscriptionList?.filter(item => {
        let skus = (item?.itemInfo?.length && item.itemInfo[0].skus) || []
        return skus.filter(sku => {
          return bundleKitProductId === sku.productId
        })
      })
    }

    const checkOtherProduct = () => {
      const skuId = props?.skuId || props.product?.skuId || ''
      return subscriptionList.filter(item => {
        const id = item?.itemInfo?.[0]?.skus?.[0]?.id || ''
        return id === skuId
      })
    }

    if (!totalSubscriptionCount) {
      return false
    } else if (isBundle) {
      const isBundleSubscribedAlready = checkBundleProduct()
      return isBundleSubscribedAlready
    } else {
      const isOtherProductSubscribedAlready = checkOtherProduct()
      return isOtherProductSubscribedAlready
    }
  }
  /**
   * Sets the customer subscription status by checking if the given product is already subscribed.
   * @param {Object} props - The product data
   */
  setUserSubscriptionStatus = props => {
    const isAlreadySubscribed = this.isAlreadySubscribedProduct(props)
    customerContainer.isSubscriptionAdded = isAlreadySubscribed
  }

  /**
   * Sets the customer subscription preference status from the subscription response.
   */
  setUserSubscriptionPreference = () => {
    customerContainer.isSubscriptionPreference =
      cartContainer.subscriptionResponse?.isSubscriptionPreference || ''
  }

  /**
   * Constructs the post body for adding a subscription to the cart.
   *
   * @param {Object} modalData - Data from the subscription modal, including product info, frequency, quantity
   * @param {number} scanCount - Optional scan count to include in the subscription properties
   * @returns {Array} The subscription post body
   */
  getPostBodyForAddSubscription = (modalData, scanCount = '') => {
    const {
      product = {},
      frequency = 1,
      quantity = 1,
      frequencyOrder = 'VARIABLE_MULTI_ORDER',
    } = modalData
    const isBundle = productDeps.isBundleData(product)
    const {
      productId = '',
      skuId = '',
      categoryId = '',
      productSlug = '',
    } = product

    const scanQualifiedCount = scanCount || ''
    subscriptionContainer.scanQualifiedCount = scanQualifiedCount
    let isBackOrderedForBundle = false
    let isIndividualProductBackOrdered = false
    let skus = [
      {
        skuId: skuId,
        productId: productId,
        type: 'DEFAULT',
      },
    ]
    // @##backorder_logic_cart for cart as subscription
    if (isBundle) {
      const bundleData = productDeps.getBundleData(product, {
        compareAndSetBackorderBasedOnQuantity: true,
        currentlySelectedQuantity: quantity,
      })
      skus = bundleData
      isBackOrderedForBundle = skus?.skus?.some(
        sku => sku?.isBackOrdered || sku?.backOrdered
      )
    } else {
      isIndividualProductBackOrdered =
        productDeps.checkBackOrderBasedOnCurrentQuantityForCartFlag(
          product.selectedSku,
          quantity
        )
    }

    let postData = [
      {
        quantity: quantity,
        skus: skus,
        isSubscription: true,
        subscription: {
          frequencyPeriod: frequency,
          frequencyType: 'MONTHS',
          type: frequencyOrder,
        },
        properties: {
          category: categoryId,
          slug: productSlug,
          scanQualifiedCount: scanQualifiedCount,
        },
        frequencyPeriod: frequency,
        frequencyType: 'MONTHS',
        category: categoryId,
        isSubscriptionPreference: customerContainer.isSubscriptionPreference,
        productId: productId,
        skuId: isBundle ? productId : skuId,
      },
    ]
    const isBackOrdered = isBundle
      ? isBackOrderedForBundle
      : isIndividualProductBackOrdered
    if (isBackOrdered) {
      postData[0].properties.isBackOrdered = true
    }
    return postData
  }

  @action
  /**
   * Sets the subscription data and status for the user based on the given product props.
   *
   * It fetches the updated subscription count for the user from the cart.
   * Gets the product SKUs from the props.
   * Collects all the user's active subscriptions.
   * Sets the user's subscription preference flag.
   * Sets the subscription status on the props.
   */
  setSubscriptionDataAndStatus = async props => {
    /**
     * @note
     * here livecount call is made only to fetch "size"
     * offer node is not required
     * hence we are sending skipPromotion as true
     */
    this.setUserSubscriptionPreference()

    if (customerContainer.isRegisterUser) {
      await cartContainer.getSubscriptionCount() //get updated subscription count
      const subscribedSkus = []
      const { product = {} } = props
      const { productId = '', skuId = '', isBundle = false } = product
      const skuIds = !isBundle ? this.getProductSkus(props) : []
      subscribedSkus.push({
        skuId: skuIds?.length > 0 ? skuIds : isBundle ? productId : skuId,
        isBundle: isBundle || false,
      })
      await this.collectAllSubscriptions(subscribedSkus) // this code alone doing
      this.setUserSubscriptionStatus(props)
    }
  }
  /**
   * Gets the SKU IDs from the given product props.
   *
   * It first tries to get the SKUs from props.product.sku,
   * then tries props.sku, and falls back to an empty array.
   *
   * It then loops through the SKUs, gets the identifier property from each SKU
   * and pushes it into the skuIdArray.
   *
   * Returns the array of SKU IDs.
   */
  getProductSkus = (props = {}) => {
    const skuArray = props?.product?.sku || props?.sku || []
    const skuIdArray = []
    for (const sku of skuArray) {
      skuIdArray.push(sku?.identifier || '')
    }
    return skuIdArray
  }
  /**
   * Checks if the user was subscribed before.
   *
   * It gets the total subscription count for the user
   * and checks if they have set the subscription preference.
   *
   * Returns true if the user has subscriptions or has set
   * the preference, false otherwise.
   */
  isUserSubscribedBefore = () => {
    const totalSubscriptionCount = this.getTotalSubscriptionCount()
    const isSubscriptionPreference = this.getUserSubscriptionPreference()
    return (isSubscriptionPreference && totalSubscriptionCount > 0) || false
  }

  /**
   * add subscription
   */
  async addSubscription(postBody, productData, isFromNewPDP = false) {
    const response = await subscriptionContainer.addToSubscription(
      postBody,
      isFromNewPDP
    )
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    if (this.isSuccessResponse(response)) {
      if (enableNewPDPStyle && isFromNewPDP) {
        quickViewState.isQuickViewOpen = false
        quickViewState.productCount = 1
        // toastState.setToastMessage(
        //   i18nTranslate(
        //     'subscription.successItem',
        //     'Item(s) successfully added to subscription'
        //   ),
        //   true,
        //   6000,
        //   null,
        //   true
        // )
        cartContainer.showAddToCartSuccessMessage()
      } else {
        if (customerContainer.isRegisterUser) {
          await cartContainer.getSubscriptionCount()
        }
        customerContainer.isSubscriptionAdded = true
        this.isToDisableSubscriptionButton = true
        this.enableGoToCart = false

        const hasSubscription = this.setUserSubscriptionPreference()
        const totalActiveSubscriptionItemCount =
          this.getTotalActiveSubscriptionItemCount()
        const totalSubscriptionCount = this.getTotalSubscriptionCount()

        if (
          totalSubscriptionCount > 0 ||
          (hasSubscription && totalActiveSubscriptionItemCount === 0)
        ) {
          const subscribedSkus = []
          const { productId = '', skuId = '', isBundle = false } = productData
          const skuIds = !isBundle ? this.getProductSkus(productData) : []
          subscribedSkus.push({
            skuId: skuIds?.length > 0 ? skuIds : isBundle ? productId : skuId,
            isBundle: isBundle || false,
          })
          await this.collectAllSubscriptions(subscribedSkus)
          let qualifiedCount = 0
          if (productData?.isBundle) {
            // @##pdp_scancard
            productData?.skuKit?.forEach(newitem => {
              const scanCount = parseInt(
                newitem?.sku[0]?.skuProperties?.scanQualifiedCount || 0
              )
              qualifiedCount = scanCount + qualifiedCount
            })
          } else {
            qualifiedCount = productData?.scanQualifiedCount || 0
          }
          if (qualifiedCount > 0) {
            toastState.setToastMessage(
              i18nTranslate(
                'subscription.addSubscription',
                'Item successfully added to subscription. Click here to add Scan Card information and manage subscriptions.'
              ),
              true,
              7000,
              pageNames.myaccountsubscriptions
            )
          } else {
            toastState.setToastMessage(
              i18nTranslate(
                'subscription.manageSubscription',
                'Item successfully added to subscription. Click here to manage subscriptions.'
              ),
              true,
              7000,
              pageNames.myaccountsubscriptions
            )
          }
        } else {
          quickViewState.isQuickViewOpen = false
          quickViewState.productCount = 1
          toastState.setToastMessage(
            i18nTranslate(
              'subscription.successItem',
              'Item(s) successfully added to subscription'
            ),
            true,
            6000,
            null,
            true
          )
        }
      }
    } else {
      const errorCode = response?.responseCode || response?.code || ''
      const editFreezeErrorCode =
        errorCode === 'error.subscription.editfreeze.date.reached'
      const responseErrorMessage =
        response?.responseMessage || response?.message || ''
      const unProcessableRequestMessage = i18nTranslate(
        'cart.unableToProcessMessage',
        'We are currently unable to process your request or it may already be processed. Please refresh your screen and try again'
      )
      const errorMessage = editFreezeErrorCode
        ? i18nTranslate(
            'subscription.editFreezeErrorMessage',
            'This Subscription order is currently being processed and at this time no changes will be allowed. Please return after your Subscription order has shipped to make any changes to your next order.'
          )
        : errorCode === 'EOCACG0001'
        ? unProcessableRequestMessage
        : responseErrorMessage ||
          i18nTranslate(
            'subscription.failure',
            'Sorry, we are unable to update your subscription. Please try again.'
          )
      toastState.setToastMessage(errorMessage, false)
    }

    /**
     * @note
     * updating the mini-cart
     */
    if (
      !customerContainer.isSubscriptionPreference ||
      (enableNewPDPStyle && isFromNewPDP)
    ) {
      await cartContainer.viewCart()
    }

    if (quickViewState.isQuickViewOpen) {
      quickViewState?.toggleQuickView()
    }

    return response
  }

  /**
   * Initialize the subscription model when component mounts
   * Set isToDisableSubscriptionButton to true initially to disable the subscribe button
   * Get activeProductSkuId from product props passed in
   * If user is registered, get their subscription count from the cart
   * Check the subscription response to see if successfully added to cart
   * Finally, set isToDisableSubscriptionButton back to false to enable subscribe button
   */
  init = async props => {
    this.isToDisableSubscriptionButton = true
    this.activeProductSkuId =
      props?.product?.skuId || props?.product?.productId || ''
    if (customerContainer.isRegisterUser) {
      await cartContainer.getSubscriptionCount()
    }
    this.checkSubscriptionResponseCount(props)

    this.isToDisableSubscriptionButton = false
  }

  /**
   * Sets the active product SKU ID to use for the subscription.
   * Priority is given to the skuId if provided, otherwise defaults to the productId.
   *
   * @param {string} skuId - The SKU ID of the product
   * @param {string} productId - The product ID
   */
  setActiveProductSkuId = (skuId, productId) => {
    this.activeProductSkuId = skuId || productId
  }

  /**
   * Checks the subscription response count from the cart to see if a subscription product was successfully added.
   *
   * If successfully added, calls `setSubscriptionDataAndStatus` to update component state.
   * If not successful, recursively calls this method again after a 1 second timeout.
   *
   * @param {Object} props - The component props passed in.
   */
  checkSubscriptionResponseCount = props => {
    if (
      customerContainer.isSuccessResponse(cartContainer.subscriptionResponse)
    ) {
      this.setSubscriptionDataAndStatus(props)
    } else {
      setTimeout(() => this.checkSubscriptionResponseCount(), 1000)
    }
  }

  /**
   * Checks if there is a subscription product in the cart.
   * Gets the current product ID from props or activeProductSkuId.
   * Loops through cart items to find if there is one with isSubscription=true
   * whose SKU ID matches the current product ID.
   * Returns true if a matching subscription product is found in cart.
   */
  isSubscriptionProductInCart = props => {
    let currentProductId =
      props?.product?.skuId ||
      props?.product?.productId ||
      this.activeProductSkuId ||
      ''
    return cartContainer?.cartResponse?.items?.some(item => {
      const skuId = item?.skus?.[0].skuId || ''
      const isSubscription = item?.isSubscription || false
      return isSubscription ? currentProductId === skuId : false
    })
  }

  /**
   * Gets the label for the subscribe button based on the product title and properties.
   * Checks if subscribe and save is enabled to return a different message.
   * Falls back to generic "Subscribe" text if no title.
   */
  getSubscribeButtonLabel = props => {
    const { title = '', product } = props
    const add = i18nTranslate('bundle.add', 'Add', {
      nameSpace: 'ssr-resource',
    })
    const subscription = i18nTranslate(
      'bundle.subscription',
      'to Subscription',
      {
        nameSpace: 'ssr-resource',
      }
    )
    let message = add + ' ' + title + ' ' + subscription
    let properties = product?.properties

    if (product?.type != 'kit' && product?.type != 'bundle') {
      properties = product?.skuProperties || product?.sku?.[0]?.properties
    }

    const isSubscribeSaveEnabled = props?.isFromQuickView
      ? quickViewState.pdpPromotionResponse?.isSubscribeSaveEnabled ||
        properties?.isSubscribeSaveEnabled
      : productState.pdpPromotionResponse?.isSubscribeSaveEnabled ||
        properties?.isSubscribeSaveEnabled
    if (isSubscribeSaveEnabled) {
      message = i18nTranslate('pdp.subscribePromo', 'Subscribe and save 10%', {
        nameSpace: 'ssr-resource',
      })
      return message
    }

    if (title) {
      return message
    }

    return i18nTranslate('pdp.subscribe', 'Subscribe', {
      nameSpace: 'ssr-resource',
    })
  }

  /**
   * Checks if a subscription product is already in the cart.
   * Sets isToDisableSubscriptionButton to true if a subscription
   * product is found in the cart.
   * @param {Object} props - The component props.
   */
  isToDisableButton = props => {
    let isSubscriptionProductInCart = this.isSubscriptionProductInCart(props)
    this.isToDisableSubscriptionButton = isSubscriptionProductInCart || false
  }

  @action
  /**
   * Checks if there is a subscription product in the cart
   * and if the user has subscription preferences set.
   * Returns true to show the subscription modal if both conditions are met.
   */
  showSubscriptionModal = () => {
    let isSubscriptionProductInCart =
      cartContainer.hasSubscriptionProduct() || false

    return isSubscriptionProductInCart && this.hasSubscriptionPreference()
  }

  /**
   * Checks if subscription checkout messaging should be enabled.
   * Returns true if a subscription product is in the cart,
   * the user does not have subscription preferences set,
   * and the user is registered.
   */
  enableCheckoutSubsProductMessage = () => {
    let isSubscriptionProductInCart =
      cartContainer.hasSubscriptionProduct() || false

    return (
      this.hasSubscriptionPreference() === false &&
      isSubscriptionProductInCart &&
      customerContainer.isRegisterUser
    )
  }

  /**
   * Updates the quantity of a subscription item in the cart.
   * Checks if the item is backordered and updates the cart appropriately.
   * @param {number} count - The new quantity for the item
   * @param {number} itemId - The cart item ID
   * @returns {Promise} - The response from updating the cart
   */
  subscriptionItemQuantityChange = async (count = 0, itemId = 0) => {
    const cartResponse = cartContainer?.cartResponse?.items || []
    let inventoryerrorcode = cartResponse?.find(
      e => e.errorMessage == 'Item is BackOrdered'
    )
    let inventoryitemId = inventoryerrorcode?.itemId
    if (inventoryitemId) {
      await cartContainer.updateCart({
        id: inventoryitemId,
        quantity: count,
        properties: {
          isBackOrdered: true,
        },
      })
    } else {
      const response = await cartContainer.updateCart({
        id: itemId,
        quantity: count,
      })
      const InventoryResponse = cartContainer?.inventoryResponse || {}
      if (InventoryResponse.message == 'Item is BackOrdered') {
        await cartContainer.updateCart({
          id: itemId,
          quantity: count,
          properties: {
            isBackOrdered: true,
          },
        })
      }
      return response
    }
  }

  /**
   * Deletes a subscription item from the cart.
   * @param {Object} props - The product properties
   * @param {number} [props.cartItemId=0] - The cart item ID
   * @param {string} [props.skuId=''] - The product SKU ID
   * @param {string} [props.productId=''] - The product ID
   * @param {string} [props.slug] - The product slug
   * @param {string} [props.productName] - The product name
   * @param {boolean} [props.isSubscription=false] - Whether the product is a subscription
   * @returns {Promise} - The response from deleting the cart item
   */
  deleteSubscriptionItem = async (props = {}) => {
    const {
      cartItemId = 0,
      skuId = '',
      productId = '',
      slug,
      productName,
      isSubscription = false,
    } = props?.product || {}
    const response = await cartContainer.deleteCartItem(
      productId,
      isSubscription,
      cartItemId,
      false,
      skuId,
      slug,
      productName
    )

    if (this.isSuccessResponse(response)) {
      this.enableCartConvertMessage = false
      this.enableGoToCart = !cartContainer.hasSubscriptionProduct() || false
    }
  }

  /**
   * Updates the frequency/interval of a subscription item in the cart.
   * @param {number} count - The new quantity for the subscription item
   * @param {number} itemId - The cart item ID of the subscription item
   * @param {Object} subscription - The new subscription data to update
   * @returns {Promise<boolean>} - Promise resolving to true if the update succeeded, false otherwise
   */
  subscriptionItemFrequencyChange = async (
    count = 0,
    itemId = 0,
    subscription = {}
  ) => {
    const response = await cartContainer.updateCart({
      id: itemId,
      quantity: count,
      subscription: subscription,
    })
    return this.isSuccessResponse(response) ? true : false
  }

  /**
   * Converts a subscription product in the cart to a one-time purchase.
   * @param {number} cartItemId - The cart item ID of the product to convert
   * @param {string} productName - The name of the product being converted
   * Updates the cart to change the given item to a one-time purchase instead of a subscription.
   * Checks the response and enables messages/buttons if successful.
   */
  oneTimeProductConversion = async (cartItemId, productName) => {
    const subscriptionParams = {
      id: cartItemId,
      subscriptionData: {
        isSubscription: false,
      },
      type: 'oneTime',
    }

    const response = await cartContainer.updateCart(subscriptionParams, false)
    if (this.isSuccessResponse(response)) {
      this.enableCartConvertMessage = true
      this.OTPConvertedProductName = productName
      this.enableGoToCart = !cartContainer.hasSubscriptionProduct() || false
    }
  }

  /**
   * Checks if the cart has any one-time purchase products
   *
   * Gets the cart items, filters out subscription products
   * and free gift products, subtracts the remaining
   * subscription product count from the total cart count.
   *
   * Returns true if there is at least 1 one-time purchase
   * product in the cart.
   */
  hasOneTimePurchaseProduct = () => {
    let cartProducts = cartContainer.cartResponse?.items || []
    let subscriptionProductLength =
      cartProducts.filter(item => item.isSubscription === true).length || 0
    let freeGiftProductLength =
      cartProducts.filter(item => item?.properties?.isGiftItem === true)
        .length || 0
    let onetimeProductCount =
      cartContainer.cartCount -
      (subscriptionProductLength + freeGiftProductLength)
    return onetimeProductCount > 0
  }

  /**
   * Prepares the post data for making an API call to update
   * a subscription in the cart.
   *
   * Loops through the provided product details, extracts the relevant
   * data, and builds the post data array that will be sent to the
   * updateCartToSubscription API endpoint.
   *
   * @param {Array} productDetails - Details of the products to build post data for
   * @returns {Array} The array of post data for the updateSubscription API call
   */
  getPostDataForUpdateSubscriptionCall = (productDetails = {}) => {
    let postData = []
    productDetails?.forEach((product, id) => {
      let productDetails = getProductDetailsForCart(product)
      const {
        quantity = 0,
        skus = [],
        itemId = '',
        subscription = {},
        properties = {},
      } = product || {}
      const {
        isBundle = false,
        skuId = '',
        productId = '',
      } = productDetails || {}

      let skuDetails = isBundle
        ? skus
        : [
            {
              skuId,
              productId,
              type: 'DEFAULT',
            },
          ]

      let isSubscribed =
        this.isAlreadySubscribedProduct(productDetails) || false

      postData.push({
        quantity,
        skus: skuDetails,
        itemId,
        isSubscription: !isSubscribed,
        subscription,
        properties,
      })
    })

    return postData
  }

  /**
   * Builds an array of post data for updating a NuSkin subscription cart,
   * given details of the subscription products.
   *
   * Loops through the provided product details object, extracting the relevant
   * data for each product, and pushes it into the post data array in the format
   * expected by the NuSkin updateSubscription API endpoint.
   *
   * @param {Object} productDetails - Object containing details of subscription products
   * @returns {Array} Array of subscription product data for API call
   */
  getPostDataForUpdateSubscriptionCallV2 = (productDetails = {}) => {
    let postData = []
    for (const skuId in productDetails) {
      const {
        quantity = 0,
        skus = [],
        itemId = '',
        subscription = {},
        properties = {},
        isBundle = false,
        productId = '',
        orderProcessedDate = '',
        orderProcessedMonth = '',
      } = productDetails?.[skuId] || {}

      let skuDetails = isBundle
        ? skus
        : [
            {
              skuId,
              productId,
              type: 'DEFAULT',
            },
          ]
      //TODO: Need to check for already subscribed or not with proper props
      let isSubscribed =
        this.isAlreadySubscribedProduct(productDetails?.[skuId]) || false

      postData.push({
        quantity,
        skus: skuDetails,
        itemId,
        isSubscription: !isSubscribed,
        subscription,
        properties,
        orderProcessedDate,
        month: orderProcessedMonth,
      })
    }
    return postData
  }

  /**
   * Updates the user's subscription cart with the provided product details.
   *
   * Calls the NuSkin updateCartToSubscription API to update the user's subscription cart.
   * Builds the API post data from the passed in product details.
   * Handles various cart types like storefront, personal offers etc.
   * On success, refreshes the cart and shows success messages.
   * On failure, shows error message from API or generic message.
   */
  updateSubscription = async (
    productDetails = {},
    isFlexiSubscription = false
  ) => {
    this.isAPICallInProgress = true
    let cartType = ''
    // let updatedProductDetails =
    //   (await this.getPostDataForUpdateSubscriptionCall(productDetails)) || []
    let updatedProductDetails = isFlexiSubscription
      ? (await this.getPostDataForUpdateSubscriptionCallV2(productDetails)) ||
        []
      : (await this.getPostDataForUpdateSubscriptionCall(productDetails)) || []

    // important code for mysite. condition to check cart type for update subscription
    if (IS_BROWSER && typeof window !== 'undefined') {
      // const shoppingContext = getLocalStorage('shoppingContext') || {}
      // const shoppingContext = getShoppingContext()
      // if (Object.keys(shoppingContext).length > 0) {
      try {
        const contextValue = checkMysiteOrPersonalOffer()
        if (contextValue === 'personal_offer') {
          cartType = 'POCART'
        } else if (contextValue === 'storefront') {
          cartType = 'MSCART'
        } else {
          cartType = 'USER'
        }
      } catch (e) {
        console.error('JSON.parse may be an error > update subscription ', e)
      }
      // }
    }
    const loadParams = {
      endPointName: 'updateCartToSubscription',
      postData: updatedProductDetails,
      queryParams: {
        isDeleteFromCart: true,
        cartType: cartType || 'USER',
      },
    }

    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      await cartContainer.viewCart()
      this.enableCartConvertMessage = false
      if (!this.disabledItemSubscribeMessage) {
        if (customerContainer.isRegisterUser) {
          await cartContainer.getSubscriptionCount()
          if (nuskinSubscriptionContainer.hasSubscriptionPreference()) {
            await nuskinSubscriptionContainer.getSubscriptionOrderProcessingDate(
              customerContainer?.userId
            )
          }
        }
        if (cartContainer.cartCount === 0) {
          this.enableEmptyCartMessage = true
        } else {
          this.enableItemSubscribedMessage = true
        }
      }
    } else {
      const errorCode = response?.responseCode || response?.code || ''
      const editFreezeErrorCode =
        errorCode === 'error.subscription.editfreeze.date.reached'
      const responseErrorMessage =
        response?.responseMessage || response?.message || ''
      const errorMessage = editFreezeErrorCode
        ? i18nTranslate(
            'subscription.editFreezeErrorMessage',
            'This Subscription order is currently being processed and at this time no changes will be allowed. Please return after your Subscription order has shipped to make any changes to your next order.'
          )
        : responseErrorMessage ||
          i18nTranslate(
            'cart.updateSubscriptionFailure',
            'Unable to update your Subscription Dashboard, please try again'
          )
      toastState.setToastMessage(errorMessage, false)
    }
    this.isAPICallInProgress = false
    return response
  }

  /**
   * Checks if the subscription item quantity can be decremented based on the number of scan cards associated with it.
   * @param {number} count - The current quantity of the subscription item
   * @param {number} step - The decrement step size
   * @returns {boolean} - Whether the quantity can be decremented
   */
  subscriptionItemDecrementCheck = (count = 0, step = 0) => {
    let quantityDecrement = true
    const cartResponse = cartContainer?.cartResponse?.items || []
    let item = cartResponse?.filter(
      item => item?.itemId === this.props?.cartItemId
    )
    let properties = item[0]?.properties || {}
    let scanArr = []
    try {
      scanArr = properties?.scanCardDetails
        ? JSON.parse(properties?.scanCardDetails)
        : []
    } catch (e) {
      console.log(e)
    }
    let scanCount = item[0]?.skus[0]?.skuProperties?.scanQualifiedCount || 0
    let scanQualifiedCount = (count - step) * scanCount
    if (scanArr?.length > scanQualifiedCount) {
      quantityDecrement = false
      let x = scanArr?.length - scanQualifiedCount
      let plsMsg = i18nTranslate('scan.please', 'Please remove')
      let manualMsg = i18nTranslate(
        'scan.manual',
        ' scancard(s) manually to proceed further'
      )
      let msg = plsMsg + x + manualMsg
      toastState.setToastMessage(msg)
    }

    return quantityDecrement
  }

  /**
   * Gets the subscription order processing date for the given user ID.
   * @param {string} userId - The user ID to get subscription order processing date for
   * @returns {Promise} Promise resolving to the subscription order processing date response
   */
  getSubscriptionOrderProcessingDate = async (userId = '') => {
    const loadParams = {
      endPointName: 'getAllSubscriptionsPreference',
      pathParams: userId,
      queryParams: {
        showAll: true,
      },
    }
    let response = await this.fetchResponse(loadParams)
    this.subscriptionPreferenceResponse = response
    return response
  }

  /**
   * Gets the number of items with the given SKU ID in the cart.
   * @param {string} clickedProductSkuId - The SKU ID to count
   * @returns {Array} An array of cart items with the matching SKU ID
   */
  getClickedProductCountInCart(clickedProductSkuId) {
    let items = cartContainer?.cartResponse?.items || []

    const sameProductBlockCount = items.filter(obj => {
      return (
        !obj?.properties?.hasOwnProperty('isGiftItem') &&
        obj?.skus?.[0]?.skuId === clickedProductSkuId
      )
    })

    return sameProductBlockCount
  }

  /**
   * Displays a toast message indicating that the item is already subscribed in the cart.
   */
  handleAlreadySubscribedToast() {
    toastState.setToastMessage(
      i18nTranslate(
        'cart.alreadySubscribedItem',
        'You have this item already subscribed in the cart, please update the quantity if required'
      ),
      false,
      9000
    )
  }

  /**
   * Displays a toast message indicating that the item is already in the cart.
   * @function handleAlreadyOTPToast
   */
  handleAlreadyOTPToast() {
    toastState.setToastMessage(
      i18nTranslate(
        'cart.alreadyOTPItem',
        'You have this item already added in the cart, please update the quantity if required'
      ),
      false,
      9000
    )
  }

  removeAlreadySubscribedItem = async (subscribedItems = []) => {
    //if cartItemCount excluding gift item, is equal to requestedItemCount to delete
    // then delete all items without sending filter to the api
    // else delete only requested items with filter passed to the api

    const cartItems = cartContainer?.cartResponse?.items || []
    let cartItemCountExcludingGift =
      cartItems.filter(item => {
        return item?.properties && item?.properties?.isGiftItem !== 'true'
      }).length || 0

    const toBeDeletedSubsItemCount = subscribedItems.length || 0
    let loadParams = {}
    if (cartItemCountExcludingGift === toBeDeletedSubsItemCount) {
      //call delete api without filter
      loadParams = {
        endPointName: 'removeSubscriptionCartItem',
      }
    } else {
      let filterInit = 'id IN "'
      let itemIds = ''
      subscribedItems.map((item, index) => {
        itemIds += index === 0 ? item?.itemId : '||' + item?.itemId
      })
      let filterStr = filterInit + itemIds + '"'
      //call delete api with filter
      loadParams = {
        endPointName: 'removeSubscriptionCartItem',
        queryParams: {
          filter: filterStr,
        },
      }
    }

    let response = await this.fetchResponse(loadParams)
    return response
  }
}

/**
 * Defines the state for a single product item.
 */
class SingleProductItemState {
  @observable productQuantity = 1
  @observable prevProductQuantity = 1
  @observable productFrequency = '1-MONTHS'
  @observable isOneTimeFrequency = false
  @observable frequencyOrder = 'VARIABLE_MULTI_ORDER'

  /**
   * Updates the product frequency based on the given subscription request.
   * @param {number} quantity - The quantity of the product
   * @param {string} itemId - The ID of the product item
   * @param {Object} subscriptionReq - The subscription request object
   * @param {boolean} isOneTime - Whether this is a one-time order
   */
  updateFrequency = (quantity, itemId, subscriptionReq, isOneTime = false) => {
    const { subscription } = subscriptionReq
    const { frequencyPeriod, frequencyType } = subscription
    this.productFrequency = [frequencyPeriod, frequencyType].join('-')

    if (isOneTime) {
      this.isOneTimeFrequency = true
      this.frequencyOrder = 'VARIABLE_SINGLE_ORDER'
    } else {
      this.isOneTimeFrequency = false
      this.frequencyOrder = 'VARIABLE_MULTI_ORDER'
    }
  }

  /**
   * Resets the product frequency state to 1 month.
   */
  resetFrequency = () => {
    this.productFrequency = '1-MONTHS'
  }

  /**
   * Updates the product quantity state based on the given count.
   */
  updateQuantity = count => {
    this.productQuantity = count
  }

  /**
   * Updates the previous product quantity state based on the given count.
   */
  updatePrevQuantity = count => {
    this.prevProductQuantity = count
  }
}

const nuskinSubscriptionContainer = new NuskinSubscriptionContainer()
// const singleProductItemState = new SingleProductItemState()

export { nuskinSubscriptionContainer, SingleProductItemState }
export default nuskinSubscriptionContainer
