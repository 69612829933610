import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Helmet } from 'react-helmet'
import { i18nTranslate, getParsedPhoneNumber, setLocalStorage } from 'src/utils'
import { Button } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import { VerifyPhoneNumber } from './VerifyPhoneNumber/VerifyPhoneNumber'
import { CodeVerification } from './CodeVerification/CodeVerification'
import { CancelModal } from './CancelModal/CancelModal'
import { SuccessModal } from './SuccessModal/SuccessModal'
import { storeContainer, customerContainer } from 'src/models'
import { countryData } from 'src/views/components/SignUpForms/countryCodeData'
import { parsePhoneNumberField } from 'src/utils/signUpUtils'
import './styles.scss'

@observer
class PhoneVerificationModal extends Component {
  @observable pageToShow = 'phoneNumber'
  @observable setProfilePhone = {}
  @observable acctype1 = ''
  constructor(props) {
    super(props)
    this.profileData()
  }

  setPageToShow = pageName => {
    this.pageToShow = pageName
  }

  handleFinish = async () => {
    if (customerContainer.triggerSaveChangesOnPhoneVerifySuccess) {
      await this.props.onComplete(this.props.formData)
    } else {
      this.props.successUpdate && this.props.successUpdate()
    }
    this.onClose()
    this.setPageToShow('phoneNumber')
  }

  handleDontVerifyClose = () => {
    if (this.props?.formData) {
      const phoneNumber =
        this.props.formData?.businessPhoneNumber ||
        this.props.formData?.mobilePhoneNumber ||
        this.props.formData?.phoneNumber ||
        this.props.formData?.phone ||
        ''
      const phoneCountryCode = this.props.formData?.phoneCountryCode || 'US'

      customerContainer.setVerifyPhone.phoneCountryCode = phoneCountryCode
      customerContainer.setVerifyPhone.phoneNumber = phoneNumber
    }
    this.onClose()
    this.setPageToShow('phoneNumber')
  }

  handlePhoneVerificationClose = () => {
    if (this.pageToShow !== 'success' && this.pageToShow !== 'cancel') {
      this.pageToShow = 'cancel'
    } else if (this.pageToShow === 'cancel') {
      this.handleDontVerifyClose()
      this.onClose()
      this.setPageToShow('phoneNumber')
    } else if (this.pageToShow === 'success') {
      this.handleFinish()
    }
  }

  onClose = () => {
    if (this.props?.fromSessionCheck) {
      setLocalStorage('phoneVerifyFromSession', false)
      customerContainer.fromSessionVerifyPhonePopup = false
    } else {
      customerContainer.verifyPhonePopup = false
    }
  }

  getProfilePhone = async () => {
    const userResponse = customerContainer.profileResponse || {}
    const phoneNumber = userResponse?.phoneNumber || ''
    //Start of parse phonenumber code
    const userCountry =
      userResponse?.accounts?.properties?.find(
        element => element.attributeId === 'countryCode'
      )?.value || ''
    const localCountry = userCountry
      ? userCountry
      : storeContainer?.storeIDValue === 'Canada'
      ? 'CA'
      : storeContainer?.storeIDValue
    let inputCode = countryData()?.find(
      element => element.value === localCountry
    )?.value
    let phoneNumberCode = ''
    let parsedPhoneNumber = ''
    if (phoneNumber) {
      let phoneCountry = ''
      parsedPhoneNumber = await getParsedPhoneNumber(phoneNumber)

      phoneNumberCode = parsedPhoneNumber?.phoneNumberCode
      if (phoneNumberCode === undefined) {
        phoneNumberCode = phoneNumber
      }
      phoneCountry = parsedPhoneNumber?.phoneCountry

      inputCode = phoneCountry || inputCode
    }
    this.acctype1 = userResponse?.accounts?.type || ''
    const firstName =
      this.acctype1 === 'Brand Affiliate - Business Entity'
        ? userResponse?.accounts?.name || ''
        : userResponse?.firstName || ''

    const isPhoneNumberVerified =
      userResponse?.customProperties?.isPhoneNumberVerified || ''
    if (isPhoneNumberVerified === 'true' && this.props?.fromSessionCheck) {
      this.onClose()
    }

    this.setProfilePhone = {
      phoneCountryCode: inputCode,
      phoneNumber: phoneNumberCode,
    }
    customerContainer.setVerifyPhone.name = firstName
  }

  profileData = async () => {
    if (Object.keys(customerContainer?.profileResponse)?.length > 0) {
      this.getProfilePhone()
    } else {
      setTimeout(() => {
        this.profileData()
      }, 100)
    }
  }

  profileSetPhoneNumber = async () => {
    const phoneNumberData = this.props?.fromSessionCheck
      ? this.setProfilePhone
      : this.props?.profileDataPhone
    const phoneNumberObj = await parsePhoneNumberField(
      customerContainer.setVerifyPhone.phoneNumber ||
        phoneNumberData?.phoneNumber,
      customerContainer.setVerifyPhone.phoneCountryCode ||
        phoneNumberData?.phoneCountryCode
    )
    const phoneNumber = phoneNumberObj?.number

    return phoneNumber
  }

  renderPage = pageName => {
    switch (pageName) {
      case 'phoneNumber':
        return (
          <VerifyPhoneNumber
            profileSetPhoneNumber={this.profileSetPhoneNumber}
            getProfilePhone={this.setProfilePhone}
            setPageToShow={this.setPageToShow}
            {...this.props}
          />
        )
      case 'codeVerify':
        return (
          <CodeVerification
            profileSetPhoneNumber={this.profileSetPhoneNumber}
            setPageToShow={this.setPageToShow}
            {...this.props}
          />
        )
      case 'success':
        return (
          <SuccessModal
            onClose={this.onClose}
            setPageToShow={this.setPageToShow}
            handleFinish={this.handleFinish}
            {...this.props}
          />
        )
      case 'cancel':
        return (
          <CancelModal
            handleDontVerifyClose={this.handleDontVerifyClose}
            onClose={this.onClose}
            setPageToShow={this.setPageToShow}
            {...this.props}
          />
        )
      default:
        return ''
    }
  }

  render() {
    const { showModal } = this.props
    return (
      <>
        <Helmet>
          {
            <link
              href={
                'https://fonts.googleapis.com/css2?family=Lora:wght@400;700'
              }
              rel="stylesheet"
            />
          }
        </Helmet>
        <div className="phone-verify-modal-cont">
          <Modal
            className="phone-verify-cont"
            show={showModal}
            backdrop="static"
            keyboard={false}
            onHide={this.handlePhoneVerificationClose}>
            <Modal.Header
              closeButton
              className="phone-verification-modal-header border-0 pb-0"
            />

            <Modal.Body
              className="phone-verification-body pt-0"
              aria-live="assertive">
              {this.renderPage(this.pageToShow)}
            </Modal.Body>
          </Modal>
        </div>
      </>
    )
  }
}

export default PhoneVerificationModal
export { PhoneVerificationModal }
