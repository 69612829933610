import { observable } from 'mobx'

const modalState = observable({
  isCancelModalVisible: false,
  text: '',
  callback: () => {},
})

function toggleRemoveModal(confirmationText, callback) {
  modalState.isCancelModalVisible = !modalState.isCancelModalVisible
  if (modalState.isCancelModalVisible) {
    modalState.text = confirmationText
    modalState.callback = callback
  }
}

export { modalState, toggleRemoveModal }
