import React, { lazy, Suspense } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import { Button, Row } from 'react-bootstrap'
import {
  addressContainer,
  accountsAddressContainer,
  storeContainer,
  checkoutContainer,
} from 'src/models'
import { AddressValidation, toastState } from 'src/views/components'
import { isB2BUser } from 'src/utils'
import AddressDetail2 from './AddressDetail2'
import './styles.scss'
import { handleAddressValidation } from 'src/deps'
import { deleteFromSessionStorage, IS_BROWSER } from 'config/appConfig'

@observer
class AddressList2 extends React.Component {
  @observable show = false
  @observable addressInvalidFlag = false
  @observable recommendations = []
  @observable isDefault = false
  validateTimer

  AddressForm = ''
  currentMarket = storeContainer?.defaultShippingRegion
    ? 'Address' + storeContainer?.defaultShippingRegion
    : 'AddressUS'

  // use loadable component instead of React lazy
  AddressForm = lazy(() =>
    import(
      'src/views/addresses/' + this.currentMarket + '/' + this.currentMarket
    )
  )

  constructor(props) {
    super(props)
    this.submitButtonRef = React.createRef()
  }

  componentDidMount() {
    const data = this.getDataModal()
    // Fix for CX121-2532
    // Showing all the form validation error messages
    if (
      data?.isEdit &&
      data?.isEdit == data?.addressInfo?.addressId &&
      !this.props.isValidAddress
    ) {
      this.props?.handleContinueBtnState?.(true)
      this.validateAddress()
    }
  }

  componentWillUnmount() {
    //Reset dropship flags when address component is unmounted on switching between addresses
    checkoutContainer.dropShippingPayLoad = false
    checkoutContainer.giftPayLoad = false
    checkoutContainer.giftMessage = ''
    deleteFromSessionStorage('vipCheckboxStatus')
  }

  // Auto triggering submit button to show form validation error
  validateAddress = () => {
    this.validateTimer = setTimeout(() => {
      if (this.submitButtonRef.current) {
        this.submitButtonRef.current.click()
        clearTimeout(this.validateTimer)
      } else {
        this.validateAddress()
      }
    }, 10)
  }

  toggleEditAddress = (isToDisableContinue = false) => {
    this.props?.handleContinueBtnState?.(isToDisableContinue)
    this.props.parentCallback(this.props.addressInfo?.addressId)
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }

  handleDefaultAddress = () => {
    if (/*isB2BAdmin() ||*/ isB2BUser()) {
      accountsAddressContainer.updateDefaultAddress(this.props?.addressInfo)
    } else {
      addressContainer.updateDefault(this.props?.addressInfo)
    }
  }

  handleUpdateAddress = async (formData = {}, addressInfo = {}) => {
    const defaultFlag = addressInfo?.isDefault || false
    this.isDefault =
      defaultFlag === true ||
      (defaultFlag === false && formData?.setDefaultAddress === true) ||
      false

    formData = {
      ...formData,
      isDefault: this.isDefault,
      addressId: addressInfo?.addressId,
    }
    const formDataTemp = {
      ...formData,
      shippingInstructions: formData?.shippingInstructions || '',
      setDefaultAddress: this.isDefault || false,
    }

    const addressValditationFlag = await handleAddressValidation(
      formDataTemp,
      addressInfo
    )
    if (addressValditationFlag === true) {
      const res = await addressContainer.validateAddress(formData)
      if (res?.code === 'ADCOVAL0003') {
        this.recommendations = [{ ...formDataTemp }, []]
        this.formDataEnter = { ...formDataTemp }
        this.addressInvalidFlag = true
        this.show = true
      } else if (res?.code == 'ONESOURCE_ADPLGIN_VAL_ERROR') {
        this.setState({
          show: false,
        })
        toastState.setToastMessage(
          i18nTranslate('address.NotAdded', 'Address not added')
        )
        return
      } else {
        // const validationcode = res.address.validationDetails[0].responseCode
        const recommendationAddress =
          res?.address?.validationDetails?.[0]?.recommendations || []
        let firstAddress = recommendationAddress?.[0] || {}
        if (
          Object.keys(firstAddress)?.length > 0 &&
          handleAddressValidation(formDataTemp, firstAddress)
        ) {
          this.recommendations = [{ ...formDataTemp }, ...recommendationAddress]
          this.formDataEnter = { ...formDataTemp }
          this.addressInvalidFlag = false
          this.show = true
        } else {
          this.handleClickOnPro(formDataTemp)
        }
      }
    } else {
      this.handleClickOnPro(formData)
    }
  }

  handleClickOnPro = async (formData = {}) => {
    if (/*isB2BAdmin() ||*/ isB2BUser()) {
      await accountsAddressContainer.updateAddress(
        formData,
        this.props?.addressInfo
      )
      this.handleClose()
      this.toggleEditAddress(false)
    } else {
      if (formData.selectedIndex <= 0) {
        const response = await addressContainer.validateUpdatedAddress(
          formData,
          this.props?.addressInfo
        )
        this.handleClose()
        if (addressContainer.isSuccessResponse(response))
          this.toggleEditAddress(false)
      } else {
        let response = await addressContainer.updateAddress(
          formData,
          this.props?.addressInfo
        )
        formData.addressId = formData?.id || this.props.addressInfo?.addressId
        this.handleClose()
        this.toggleEditAddress(false)
      }
      this.props.validationCallback && this.props.validationCallback()
    }

    this.props.handleSelect(formData)
  }

  handleClose = () => {
    this.show = false
  }

  renderCheckoutEditSubmitButton = () => {
    return (
      <Row noGutters className="justify-content-start">
        <div className="d-flex">
          <div>
            <Button
              ref={this.submitButtonRef}
              variant="primary"
              className="add-button-addressBook mt-2 shipping-button-addressBook justify-content-center align-items-center"
              type="submit"
              data-testid="qa-submit-changes">
              {i18nTranslate('address.save', 'Save Changes')}
            </Button>
          </div>
          <div className="ml-3">
            <Button
              variant="outline-primary"
              className="cancel-button-addressBook mt-2 shipping-button-addressBook justify-content-center align-items-center"
              onClick={() => this.toggleEditAddress(false)}
              data-testid="qa-address-cancel">
              {i18nTranslate('address.cancel', 'Cancel')}
            </Button>
          </div>
        </div>
      </Row>
    )
  }

  getDataModal() {
    return {
      addressInfo: this.props?.addressInfo || {},
      index: this.props?.index,
      saveChangesText: i18nTranslate('address.save', 'Save Changes'),
      editIconLabel: i18nTranslate('address.editIcon', 'Open edit Information'),
      show: this.show || false,
      addressInvalidFlag: this.addressInvalidFlag || false,
      recommendations: this.recommendations || [],
      isEdit: this.props?.isEdit || false,
      editText: i18nTranslate('payment.edit', 'Edit'),
    }
  }

  render() {
    const data = this.getDataModal()
    return (
      <div className="justify-content-between d-flex">
        <span
          noGutters
          className="mb-2 w-100 address-card-inner bg-white"
          data-testid="qa-address-container">
          <span className="d-flex flex-column">
            {data?.show && (
              <AddressValidation
                open={data?.show}
                addressInvalidFlag={data?.addressInvalidFlag}
                recommendations={data?.recommendations}
                handleClose={this.handleClose}
                handleClickOnPro={this.handleClickOnPro}
                validationCallback={this.props.validationCallback}
              />
            )}
            {data?.isEdit != data?.addressInfo?.addressId ? (
              <AddressDetail2
                addressInfo={data?.addressInfo}
                index={data?.index}
              />
            ) : (
              <span noGutters className="w-100">
                <span className="p-0">
                  {IS_BROWSER && (
                    <Suspense fallback="">
                      <this.AddressForm
                        index={data?.index}
                        addressInfo={data?.addressInfo}
                        onSubmit={this.handleUpdateAddress}
                        renderSubmitButton={this.renderCheckoutEditSubmitButton}
                        type="checkout"
                      />
                    </Suspense>
                  )}
                </span>
              </span>
            )}
          </span>
        </span>
        <span className="custom-edit-btn justify-content-end mt-1">
          {data?.isEdit !== data?.addressInfo?.addressId && (
            <span
              role="button"
              className={`custom-edit-btn justify-content-end ${
                this.props.radioBtnDisabled ? 'pointer-event-none' : ''
              }`}
              onClick={() => this.toggleEditAddress(true)}
              onKeyPress={() => this.toggleEditAddress(true)}
              tabIndex={0}
              aria-label={data?.editIconLabel}>
              {i18nTranslate('cart.edit', data?.editText)}
            </span>
          )}
        </span>
      </div>
    )
  }
}

export { AddressList2 }
export default AddressList2
