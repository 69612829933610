import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { pageNames } from 'src/routes/pathParams'
import { Link } from 'react-router-dom'
import { i18nTranslate } from 'src/utils'
import uniqBy from 'lodash/uniqBy'
import { Price } from 'src/views/components'
import './styles.scss'

const Freegifts = (props = {}) => {
  const { promotion = [] } = props

  const uniquePromotion =
    (promotion.length > 0 && uniqBy(promotion, promo => promo.skuId)) || []
  const gift = uniquePromotion?.find(gift => {
    return gift?.giftItems
  })

  return (
    <>
      {gift && (
        <div>
          <Col
            lg={12}
            className={
              'freegift-text d-block text-success font-weight-bolder justify-content-end mt-3 mb-2 p-0 h5'
            }
            data-testid="qa-free-gift-textpdp">
            <span className="left-span mr-1">
              {i18nTranslate('orderCard.freeGifts', 'Free Gifts')}
            </span>
          </Col>

          {uniquePromotion.map(promotionDetails => {
            const imageURL =
              promotionDetails?.giftItems?.[0]?.sku?.[0]?.properties
                ?.imageURL ||
              promotionDetails?.giftItems?.[0]?.properties?.imageURL ||
              ''
            const giftdataMsg =
              promotionDetails?.giftItems?.[0]?.properties?.message || ''
            const isGiftNotFound =
              giftdataMsg?.toLowerCase()?.indexOf('product not found') > -1
            const giftdata =
              promotionDetails?.giftItems?.[0]?.properties ||
              promotionDetails?.giftItems?.[0]?.sku?.[0]?.properties ||
              ''
            const productId = promotionDetails?.productId || ''
            const productUrl = `${pageNames.product}/${productId}`
            const isOutOfStock =
              giftdata?.isInventoryAvailable &&
              giftdata.isInventoryAvailable === 'false' &&
              typeof giftdata?.giftOutOfStockQty === 'undefined' &&
              (giftdata?.giftStockAvailableQty === '0' ||
                giftdata?.giftStockAvailableQty <= 0)
            const isPartialOutOfStock =
              giftdata?.isInventoryAvailable &&
              giftdata.isInventoryAvailable === 'true' &&
              giftdata?.giftOutOfStockQty &&
              giftdata?.giftStockAvailableQty
            return (
              <>
                <div>
                  {giftdata && !isGiftNotFound && (
                    <div
                      className={`free-gift-product pb-3 mb-2`}
                      data-testid="qa-free-gift-container">
                      <Row noGutters className="m-0">
                        <Col xs={12} className="p-0">
                          <Row noGutters>
                            {imageURL && (
                              <Col xs={3} className="pr-2">
                                <Link to={productUrl}>
                                  <Card.Img
                                    className="image-border"
                                    data-testid="qa-free-gift-image"
                                    src={imageURL}
                                    alt={giftdata.name || 'free gift image'}
                                  />
                                </Link>
                              </Col>
                            )}
                            <Col xs={9} className="pr-0">
                              <Row noGutters>
                                {/*  <Col xs={9}>
                                  <Link to={productUrl}>
                                    <Card.Title
                                      className="text-truncate mb-1 font-weight-bolder text-dark free-gift"
                                      data-testid="qa-free-gift-brand">
                                      {giftdata.brand}
                                    </Card.Title>
                                  </Link>
                                </Col> */}
                                <Col xs={12}>
                                  <Card.Title
                                    className="text-truncate mb-1 font-weight-normal free-gift-product-name"
                                    data-testid="qa-free-gift-name">
                                    {giftdata.name}
                                  </Card.Title>
                                </Col>
                                <Col xs={12}>
                                  <Price
                                    price={'00'}
                                    classStyles={'d-flex justify-content-start'}
                                  />
                                </Col>
                                {!isPartialOutOfStock && (
                                  <Col xs={12}>
                                    <div
                                      className="text-truncate mb-1 font-weight-normal free-gift-quantity"
                                      data-testid="qa-free-gift-name">
                                      {i18nTranslate('pdp.QtyTxt', 'QTY', {
                                        nameSpace: 'ssr-resource',
                                      })}{' '}
                                      :{giftdata?.giftStockAvailableQty || 0}
                                    </div>
                                  </Col>
                                )}
                                {isOutOfStock ? (
                                  <Col xs={12}>
                                    <Card.Title
                                      className="text-truncate mb-1 font-weight-bolder text-danger"
                                      data-testid="qa-free-gift-OOS">
                                      {i18nTranslate(
                                        'pdp.pdpOOSTxt',
                                        'Out of Stock',
                                        {
                                          nameSpace: 'ssr-resource',
                                        }
                                      )}
                                    </Card.Title>
                                  </Col>
                                ) : (
                                  isPartialOutOfStock && (
                                    <Col xs={12}>
                                      <h6
                                        className="mb-1 font-weight-bolder text-danger"
                                        data-testid="qa-free-gift-OOS">
                                        {i18nTranslate(
                                          'pdp.sorryFreeGiftOOSTxt',
                                          'Sorry, few gifts are out of stock',
                                          {
                                            nameSpace: 'ssr-resource',
                                          }
                                        )}
                                      </h6>
                                      <div
                                        className="mb-1 small"
                                        data-testid="qa-free-gift-name">
                                        {i18nTranslate(
                                          'pdp.AvailableQtyTxt',
                                          'Available : ',
                                          {
                                            nameSpace: 'ssr-resource',
                                          }
                                        )}
                                        {giftdata?.giftStockAvailableQty || 0}
                                      </div>
                                      <div
                                        className="mb-1 small"
                                        data-testid="qa-free-gift-name">
                                        {i18nTranslate(
                                          'pdp.OOSQtyTxt',
                                          'Out of stock : ',
                                          {
                                            nameSpace: 'ssr-resource',
                                          }
                                        )}
                                        {giftdata?.giftOutOfStockQty || 0}
                                      </div>
                                    </Col>
                                  )
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </>
            )
          })}
        </div>
      )}
    </>
  )
}
export { Freegifts }
export default Freegifts
