import React from 'react'
import { application } from 'src/utils'
import { PageLink } from '../PageLink'
import './styles.scss'
import { getFooterBynderImage } from 'src/utils'
import { ImageComponent } from 'src/views/components'
import { Row, Col } from 'react-bootstrap'

const checkForHrefUrl = item => {
  const baseurl = 'https://www.nuskin.com'
  return item?.href?.includes('https://') ? item.href : baseurl + item?.href
}

class CopyRightsLink extends React.Component {
  handleNavigation = () => {
    const { item = {} } = this.props

    item?.href?.includes('.pdf') ? window.open(item.href) : undefined
  }
  handleKeyPress = e => {
    if (e.keyCode == 13) {
      this.handleNavigation()
    }
  }
  render() {
    const { item = {} } = this.props

    if (!item?.name || !item?.href) {
      return ''
    }
    return item?.href && item?.href?.includes('.pdf') ? (
      <a
        style={{ cursor: 'pointer' }}
        onClick={() => this.handleNavigation()}
        target="_blank"
        rel="noopener noreferrer"
        onKeyDown={this.handleKeyPress}>
        <span>{item.name}</span>
      </a>
    ) : (
      <a
        style={{ cursor: 'pointer' }}
        href={checkForHrefUrl(item)}
        target="_blank"
        rel="noopener noreferrer">
        <span>{item.name}</span>
      </a>
    )
  }
}

class CopyRights extends React.Component {
  renderCopyrights = () => {
    const cookiePolicyName = 'cookiePolicy'
    const copyRightLink = this.props.data?.detailsLinks || []

    if (copyRightLink?.[copyRightLink?.length - 1]?.name != cookiePolicyName) {
      copyRightLink.push({ name: cookiePolicyName })
    }

    return (
      copyRightLink &&
      copyRightLink.map((item, index) => {
        return (
          <div
            // className={index === 0 ? '' : `pll-1`}
            key={`copyRights-${index}`}>
            {item?.name == cookiePolicyName && <div id="teconsent"></div>}
            <CopyRightsLink item={item} />
            <span className={`px-1`}>
              {index == copyRightLink?.length - 1 ? '' : '|'}
            </span>
          </div>
        )
      })
    )
  }

  render() {
    // const copyRightLink = this.props.data?.detailsLinks
    const isTabletView =
      (application.isTablet || application.isTabletOrSmaller) &&
      application.width > 576 &&
      application.width < 1366
    // &&
    // copyRightLink?.length > 6

    const isPartnersLogoAvail =
      this?.props?.partnerLogos?.length <= 0 ? 'hide-partner-logo' : ''

    return (
      <Row
        className={`${
          application.isMobile
            ? 'footer'
            : isTabletView
            ? 'tab-footer'
            : 'd-flex footer'
        }  justify-content-between align-items-center`}>
        <Col
          className={`${
            application.isMobile
              ? 'justify-content-center border-bottom pt-3 '
              : isTabletView
              ? 'justify-content-start pt-3'
              : ''
          } d-flex flex-wrap`}>
          {this.renderCopyrights()}
        </Col>
        <Col
          className={`${
            application.isMobile || isTabletView
              ? 'justify-content-center p-2 flex-wrap'
              : ' '
          } d-flex partner-logo-box ${isPartnersLogoAvail}`}>
          {this?.props?.partnerLogos?.map(({ icon, link }, key) => (
            <div
              className={`p-1 footer-partner-logo ${
                isTabletView && this.props?.partnerLogos?.length === key + 1
                  ? 'mr-5'
                  : ''
              }`}
              key={key}>
              <PageLink
                linkInfo={{ link: link?.href }}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={link?.title || ''}>
                <ImageComponent
                  className="partner-logo"
                  src={getFooterBynderImage({ bynderImage: icon?.[0] }) || ''}
                  alt={link?.title || ''}
                />
              </PageLink>
            </div>
          ))}
        </Col>
      </Row>
    )
  }
}

export default CopyRights
export { CopyRights }
