import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { i18nTranslate } from 'src/utils'
import { IoMdCalendar } from 'react-icons/io'
import { customerContainer } from 'src/models'
import { LocaleModal, mobileMenuState } from 'src/views/components'
import { getLocalStorage } from 'src/utils'
import { getFooterLinks } from './fixture'
import { pageNames } from 'src/routes/pathParams'
import { CLIENT_NAME } from 'config/appConfig'

const RouterLink = props => {
  return (
    <Link {...props} onClick={mobileMenuState.toggleMenuVisibility}>
      {props.children}
    </Link>
  )
}

const RenderLink = props => {
  // eslint-disable-next-line react/prop-types
  const { label, Icon, link, external } = props
  const attributes = external
    ? { href: link, target: '_blank', rel: 'noopener noreferrer' }
    : { as: RouterLink, to: link }
  return (
    <Nav.Link
      {...attributes}
      key={label}
      className="text-dark"
      aria-label="cartpagelink">
      {Icon && <Icon className="mr-3" />}
      <span>{label || 'footer-link'}</span>
    </Nav.Link>
  )
}

function renderLinks(links) {
  return links.map((item, index) => {
    return <RenderLink key={index} {...item} />
  })
}

class FooterLinks extends React.Component {
  render() {
    const footerLinks = getFooterLinks()
    const date = new Date().getFullYear().toString()
    return (
      <div className="menu-footer-wrapper">
        <Nav className="flex-column menu-footer-links">
          {renderLinks(footerLinks)}
        </Nav>
        <span>
          © {CLIENT_NAME},{' '}
          {i18nTranslate('home.copyrights.inc', 'INC.', {
            nameSpace: 'ssr-resource',
          })}{' '}
          + {date} +{' '}
          {i18nTranslate(
            'home.copyrights.allrightsreserved',
            ' | ALL RIGHTS RESERVED',
            {
              nameSpace: 'ssr-resource',
            }
          )}
        </span>
      </div>
    )
  }
}

class AdditionalLinks extends React.Component {
  render() {
    let accountType = getLocalStorage('accountType') || ''
    return (
      <>
        <Nav className="additional-links flex-column bg-light">
          {/* This feature is not in nuksin. so commented gift card option */}
          {/* <RenderLink
            label={i18nTranslate('menu.giftCards', 'Gift Cards')}
            link="#"
            external={false}
            Icon={MdCardGiftcard}
          /> */}
          {!customerContainer.isRegisterUser && (
            <RenderLink
              label={i18nTranslate('menu.trackOrder', 'Track Order', {
                nameSpace: 'ssr-resource',
              })}
              link={pageNames.orderLookup}
              external={false}
              Icon={IoMdCalendar}
            />
          )}
          {/* Will Add this once store locator implementated - CX121-1877*/}
          {/* <RenderLink
            label={i18nTranslate('menu.storeLocator', 'Store Locator')}
            link="#"
            external={false}
            Icon={MdLocationOn}
          /> */}
        </Nav>
        <LocaleModal className="py-2 px-3 text-dark bg-light" />
      </>
    )
  }
}

export { FooterLinks, AdditionalLinks, RouterLink }
