import { i18nTranslate } from 'src/utils'
import { toastState } from 'src/views/components'
import { currencyFormat } from 'src/utils'

/**
 * The Builder class is used to construct Cart instances.
 */
class Builder {
  /**
   * Checks if the given address data includes the specified address type.
   *
   * @param {Object} addressData - The address data object to check
   * @param {string} addressTypeToCheck - The address type to look for
   * @returns {boolean} True if the address data includes the specified type
   */
  getMatchedData = (addressData, addressTypeToCheck) => {
    return (addressData?.types || []).includes(addressTypeToCheck)
  }
  /**
   * Transforms geocode API response data into address fields.
   *
   * @param {Object} response - Geocode API response
   * @returns {Object} Mapped address data
   */
  transformGeoCodeData = response => {
    const addressDataFromResponse =
      response?.results?.[0]?.address_components || []
    const getAddressData = addressTypeToCheck => {
      const matchedAddressData =
        addressDataFromResponse.find(addressData =>
          this.getMatchedData(addressData, addressTypeToCheck)
        ) || {}
      return matchedAddressData.short_name || ''
    }

    return {
      postalCode: getAddressData('postal_code'),
      state: getAddressData('administrative_area_level_1'),
      country: getAddressData('country'),
      city: getAddressData('locality'),
      county: getAddressData('administrative_area_level_2'),
    }
  }

  /**
   * Transforms geocode API response data and zipcode into address fields for shipping estimation.
   *
   * @param {Object} geocodeResponse - Geocode API response object
   * @param {string} zipcode - Zipcode to use in address
   * @returns {Object} Address object with city, state, country from geocode response and hardcoded address line
   */
  transformEstimateShippingInput = (geocodeResponse = {}, zipcode = '') => {
    const validationProps = {
      validation: [
        {
          type: 'PHYSICAL',
          validated: true,
          overridden: true,
        },
      ],
    }
    const {
      city = '',
      state = '',
      country = '',
    } = this.transformGeoCodeData(geocodeResponse)
    return {
      estimationAddress: {
        /**
         * addressLine1 is hardcoded because
         * @see https://jira.skava.net/browse/SECOM-27555
         */
        addressLine1: '1 Broad Way',
        city,
        state,
        country,
        zip: zipcode,
        ...validationProps,
      },
    }
  }
  /**
   * Handles the response from the estimate zipcode API call.
   * Checks for errors and extracts the estimated shipping cost if successful.
   *
   * @param {Object} estimatedResponse - Response from the estimate zipcode API
   * @returns {Object} Object indicating if there was an error and the estimated shipping cost if available
   */
  handleEstimateZipcodeResponse = (estimatedResponse = {}) => {
    const errorCode = estimatedResponse?.code || ''
    if (errorCode === 'SH0000009') {
      return {
        hasError: true,
      }
    } else if (errorCode) {
      //    show toast if required
      toastState.setToastMessage(
        i18nTranslate(
          'message.estimateShippingError',
          'Unable to calculate estimate shipping cost, please try again',
          {
            nameSpace: 'ssr-resource',
          }
        ),
        false
      )
      return {
        hasError: true,
      }
    } else {
      const isItemSelected = item => item.isSelected && item.isSelected === true
      // const currencyCode = get(estimatedResponse, 'currencyCode', '')
      const defaultShippingMethod = (
        estimatedResponse?.deliveryDetails?.methods || []
      ).find(isItemSelected)
      const estTotalShippingPrice = defaultShippingMethod
        ? defaultShippingMethod.cost
        : ''
      const estimatedShippingCost = currencyFormat({
        // currency: currencyCode,
        price: estTotalShippingPrice,
      })
      return {
        hasError: false,
        shippingCost: estimatedShippingCost.toFixed(2),
      }
    }
  }
}
const builder = new Builder()

export default builder
export { builder }
