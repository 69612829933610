import {
  getMerchPlp,
  convertToBoolean,
  getStudioPreview,
  getIsMerchandising,
} from './configUtils'
export const isProduction = !!process.env.NODE_ENV
export const enableProxy = false
export const SSL_PORT = 443
export const PROXY_PORT = 4000
export const API_ABORT_DELAY = 5000
export const isToLoadFromFixture = false
export const isToEnableInLineCss = isProduction

/**
 * @note
 * Need to create dot env files
 */
export const isToEnableSSR =
  convertToBoolean(process.env.IS_TO_ENABLE_SSR) || false

export const APP_PORT =
  isProduction && isToEnableSSR ? 5000 : process.env.PORT ?? SSL_PORT
export const isToEnableCompression = isProduction && isToEnableSSR
export const isMerchPLP = getMerchPlp()
export const isMerchandising = getIsMerchandising()
export const localDomain = 'local.nuskin.com'
export const consentIpAddress = '125.0.0.1'

/**
 * @note
 * accepted values are 'gzip' || 'br'
 *
 * @todo
 * need to import this variable to webpack
 */
export const compressAlgorithm = 'gzip'
export const isToEnableSSL = isProduction || APP_PORT == SSL_PORT

/**
 * @note
 * This locale files are to be used in client side rendering
 */

/**
 * @todo
 * !!! required to be a function to dynamic value
 */
// export const BUSINESS_RELATIONSHIP = getAppConfig()?.businessRelationship

/**
 * @todo
 * !!! isB2B reference should be dynamic
 * !!! move to `getAppConfig` at the runtime
 */

/************* PRODUCT FEATURE RELATED **************/

export const isToEnablePDPCarousel = true
export const isResetPasswordDirectly = false
export const enableQuickView = true
export const CLIENT_NAME = 'Nu Skin'
export const useSkavaLogo = false
export const isStudioPreview = getStudioPreview()
export const minimumSearchCharLength = 2

/**
 * @variant_autoselect
 * @description this array below is used to identify the API nodes which indicates whether the sku is variant related sku
 * @example if my sku has a node called variantLabel (as in below array), then it is a variant sku
 * @date 10/3/2023 - 12:55:25 PM
 */
export const VARIANT_NODES = [
  // 'color',
  // 'size',
  // 'fit',
  // 'skintype',
  'variantLabel',
]
export const COLOR_BASED_VARIANT_NODES = ['color']

export const enableProductCompare = true
export const categoryLandingTemplateId = 1 // 1,2 possible values
// 'list', 'grid' -> possible values
export const PDPBundleProductType = 'list'
export const shouldShowAddedBundleDetails = true
// group bundle optional products by type
export const groupBundleProductsByType = true
export const enableQuantityForBundle = false
export const enableOutfits = false
export const isToEnableItemLevelShipping = true
export const enableStorePickup = true
export const useStickyTop = false
export const PLPProductsPerPage = 12
export const filtersOpenByDefault = false
export const sortFilterValuesKeys = ['productagegroup']
export const PDPRecommendationFromApi = true
export const useCartTableView = false
export const productEnableZoom = true
// for B2C
// should be enabled for Checkout template2

/**
 * @todo
 * !!! isB2B reference should be dynamic
 * !!! move to `getAppConfig` at the runtime
 */

// 'checkout', 'checkoutView2' possible values
export const checkoutComponent = 'CheckoutView2'
export const reviewPageSize = 5
export const enableSubscription = true
export const enableWishList = true
export const enableReorder = true

/**************************************************/
export const REACT_VOLUMES_REDIRECT_LINK =
  'https://www.nuskin.com/content/nuskin/en_US/home.html'
export const MYSITE_URL = '/static/cart/cart.html#/<%LOCALE%>/mysite/cart'
export const DIRECT_DEPOSIT_URL = '/vgclient/#/directDeposit'
export const VOLUME_URL = '/vgclient/'
export const MYSITE_TOOLS_URL = '/content/nuskin/<%LOCALE%>/tools/mysite.html'
export const CREATE_WEB_ACCOUNT_URL =
  '/static/web-account-create/?locale=<%LOCALE%>#.html'
// let cookieValue = getLocalStorage('locale') || ''
// let finalcookieValue = cookieValue.replace('-', '_')
export const QUALIFICATION_URL = `/static/exclusive-offer/index.html?locale=`
export const REGION_SELECTOR_URL =
  '/content/region-selector.html#region-selection'
export const RETURN_REDIRECTION_URL =
  'https://returns.narvar.com/nuskin/returns'

// July Live Event URLs - start
export const REGION_SELECTOR_URL_EVENT =
  'https://www.nuskin.com/content/region-selector.html#region-selection'
export const EVENT_REFUND_POLICY_URL =
  'https://legal.nuskin.com/live.html#template-xg8yqbsek'
export const EVENT_TERMS_URL =
  'https://legal.nuskin.com/live.html#template-6fvyu7uwl'
// July Live Event URLs - end

export default isMerchPLP
