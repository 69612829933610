import { fetchResponseFromAPI, getLocaleCodeFromUrl } from 'src/utils'
import {
  getAppConfig,
  IS_BROWSER,
  setLocalStorage,
  APPConfig,
} from 'config/appConfig'

/**
 * StoreContainer class. Appears to handle store data and configuration.
 */
class StoreContainer {
  globalStoreResponse = undefined
  activeStoreResponse = undefined
  countryConfig = []
  currentRegion = ''
  defaultShippingRegion = ''
  storeIDValue = ''
  activeGlobalStoreId = undefined
  activeStoreId = undefined
  activeLocale = undefined
  storeResponse = {}
  selectedLocaleList = []
  responseCbk = undefined
  accountKey = 'AccountType'
  isPriceRuleEnabled = false
  priceAccountTypes = []
  CACHE_MINS = 5
  CACHE_TTL = 1000 * 60 * this.CACHE_MINS
  cachedTime = 0

  runFromLocal = false

  constructor() {
    this.getDataFromSource()
  }

  /**
   * Sets the store configuration on the global or window object.
   * Used to pass store config between client and server.
   * @param {Object} config - The store configuration object
   * @param {string} [locale] - Optional locale code to associate config with
   */
  storeConfigAtGlobal(config, locale) {
    // We are not using this object for SSR in nodejs
    if (typeof global === 'object') {
      global.STORE_CONFIG = config
      if (locale) {
        if (!global.STORE_CONFIG_WITH_LOCALE) {
          global.STORE_CONFIG_WITH_LOCALE = {}
        }
        global.STORE_CONFIG_WITH_LOCALE[locale] = config
      }
    } else if (typeof window === 'object') {
      window.STORE_CONFIG = config
    }
  }

  /**
   * Gets the store configuration from the global or window object.
   * Used to retrieve the configuration set by storeConfigAtGlobal().
   * Returns the configuration object or an empty object if not found.
   */
  getConfigFromGlobal() {
    // Getting config only in CSR not in SSR from nodejs
    return (
      (typeof global === 'object'
        ? global?.STORE_CONFIG
        : typeof window === 'object'
        ? window?.STORE_CONFIG
        : {}) || {}
    )
  }

  /**
   * Gets the store configuration data from the global or window object set by storeConfigAtGlobal().
   * If no config is set, tries to load it from the activeStoreConfig constant.
   * Sets various store state variables from the loaded config.
   * Also saves the currentRegion to localStorage if in the browser.
   */
  getDataFromSource() {
    const activeStoreConfig = this.getConfigFromGlobal()

    if (
      this.globalStoreResponse === undefined &&
      Object.keys(activeStoreConfig).length > 0
    ) {
      this.globalStoreResponse = activeStoreConfig.globalStoreResponse
      this.activeStoreResponse = activeStoreConfig.activeStoreResponse
      this.countryConfig = activeStoreConfig.countryConfig
      this.selectedLocaleList = activeStoreConfig.selectedLocaleList
      this.activeStoreId = activeStoreConfig.activeStoreId
      this.activeLocale = activeStoreConfig.activeLocale
      this.isPriceRuleEnabled = activeStoreConfig.isPriceRuleEnabled
      this.priceAccountTypes = activeStoreConfig.priceAccountTypes
      this.currentRegion = activeStoreConfig.currentRegion
      this.defaultShippingRegion = activeStoreConfig.defaultShippingRegion
      this.storeIDValue = activeStoreConfig.storeIDValue

      if (IS_BROWSER) {
        setLocalStorage('currentRegion', this.currentRegion)
      }
    }
  }

  /**
   * Checks if the given response is a success response.
   * @param {Object} response - The response object to check
   * @returns {boolean} True if the response has a 'success' or empty status, false otherwise
   */
  isSuccessResponse(response) {
    if (response) {
      const status = response?.status || ''
      return status === 'success' || status === ''
    }
    return false
  }

  /**
   * Sets the selected locale list from the global store response locales,
   * by mapping the locales to their country code and removing duplicates.
   */
  setSelectedLocaleList() {
    const countries = this.globalStoreResponse?.locales?.map(
      x => x.split('_')[1]
    )

    /**
     * @description
     * removing duplicates
     */
    this.selectedLocaleList = [...new Set(countries)]
  }

  /**
   * Parses pricing configuration from the active store response
   * and sets related state properties. Checks if pricing rules are enabled,
   * sets account key and available account types for pricing.
   *
   * @param {Object} activeStoreResponse - The active store response object
   */
  setPriceConfig(activeStoreResponse) {
    let priceRule = activeStoreResponse?.associations?.find(
      item => item?.name?.toLowerCase() === 'pricing'
    )

    let priceProps = activeStoreResponse?.properties?.find(
      item => item?.name?.toLowerCase() === 'priceruleconfigmap'
    )

    if (priceRule?.jurisdiction && priceRule?.jurisdiction.length) {
      this.isPriceRuleEnabled = priceRule?.jurisdiction?.[0]?.documentId
        ?.toLowerCase()
        .includes('rule')
    }

    try {
      const priceConfig = JSON.parse(priceProps?.value || '{}')
      this.accountKey = priceConfig?.id || this.accountKey
      this.priceAccountTypes =
        this.isPriceRuleEnabled && priceConfig?.options
          ? priceConfig?.options
          : []
    } catch (e) {
      console.error('Error @setPriceConfig JSON parse fails >>>')
    }
  }

  /**
   * Fetches response from API based on given load parameters
   *
   * @param {Object} loadParams - Parameters to load data from API
   * @returns {Promise} Promise resolving to API response
   */
  async fetchResponse(loadParams) {
    const response = await fetchResponseFromAPI(loadParams)
    return response
  }

  /**
   * Parses the country configuration from the global store response
   * and sets it to the countryConfig state property.
   * Handles errors while parsing the JSON string.
   */
  setCountryConfig(options) {
    if (this.globalStoreResponse) {
      const { pageUrl } = options || {}
      const countryConfig =
        this.globalStoreResponse?.properties?.find(
          x => x.name === 'countryConfig'
        )?.value || '{}'

      try {
        // const activeLocale = getLocaleCodeFromUrl({
        //   url: pageUrl,
        //   at: 'pathParam',
        //   defaultLocale: '',
        //   isReverseType: true,
        // })
        // const liveEventConfig = this.getLiveEventConfig({
        //   ...options,
        //   activeLocale,
        // })
        // let updatedCountryConfig = JSON.parse(countryConfig) || {}

        // if (liveEventConfig?.isLiveEvent) {
        //   const updatedConfig = JSON.parse(countryConfig)
        //   for (const countryKey in updatedConfig) {
        //     if (countryKey.includes('EVENT_')) {
        //       const eventCountry = countryKey?.replace('EVENT_', '')
        //       updatedConfig[eventCountry] = updatedConfig[countryKey]
        //     }
        //   }
        //   updatedCountryConfig = updatedConfig
        // }
        // this.countryConfig = updatedCountryConfig
        this.countryConfig = JSON.parse(countryConfig) || {}
      } catch (e) {
        console.log('JSON.parse error in countryConfig', e)
      }
    }
  }

  /**
   * Sets the store ID value used for shipping region based on the provided default shipping region.
   *
   * @param {string} defaultShippingRegion - The default shipping region to use for determining the store ID value.
   * @returns {string} The store ID value determined from the default shipping region.
   */
  setShippingRegion = defaultShippingRegion => {
    let storeIDValue = 'US'
    if (defaultShippingRegion === 'US') {
      storeIDValue = 'US'
    } else if (defaultShippingRegion === 'CA') {
      storeIDValue = 'Canada'
    } else {
      storeIDValue = defaultShippingRegion
    }
    this.storeIDValue = storeIDValue
    return storeIDValue
  }

  /**
   * Finds the active store ID and region based on the page URL and default locale.
   *
   * Iterates through the countryConfig to find the matching store ID for the
   * active locale derived from the page URL. Falls back to second level iteration
   * if no match found initially.
   *
   * Sets the currentRegion and returns the current app store ID and region.
   */
  findActiveStoreData = (pageUrl, defaultLocale) => {
    let storeId = -1
    const activeLocale = getLocaleCodeFromUrl({
      url: pageUrl,
      at: 'pathParam',
      defaultLocale: defaultLocale,
      isReverseType: true,
    })
    let currentRegion = ''
    const findId = localeObj => {
      let curStoreId = null
      Object.keys(localeObj).forEach((region, idx) => {
        const curRegionObj = localeObj[region]
        const curCountryProps = curRegionObj[activeLocale]
        if (curCountryProps) {
          curStoreId = curCountryProps?.storeId
          this.currentRegion = region?.replace('EVENT_', '')
          currentRegion = region?.replace('EVENT_', '')
          setLocalStorage('currentRegion', this.currentRegion)
        }
      })
      return curStoreId
    }

    if (this.countryConfig) {
      // top level iteration
      let foundStoreId = findId(this.countryConfig)

      // second level iteration
      if (foundStoreId === null) {
        Object.keys(this.countryConfig).forEach((region, idx) => {
          foundStoreId = findId(this.countryConfig[region])
        })
      }

      storeId = foundStoreId

      // console.log({ foundStoreId, storeId })
    }

    this.setSelectedLocaleList()
    return {
      curAppStoreId: storeId,
      currentRegion: currentRegion,
    }
  }

  getLiveEventConfig = options => {
    const { host, hostName, activeLocale } = options
    const currentRegion = activeLocale?.split('_')?.[1]?.toUpperCase() || ''
    const currentEnv = host || hostName
    let liveEventConfig = {
      isLiveEvent: false,
      isLiveEventDomain: false,
    }
    try {
      let storeConfig = JSON.parse(
        this.globalStoreResponse?.properties?.find(
          x => x.name === 'storefrontConfig'
        )?.value || '{}'
      )

      const eventServerConfig = storeConfig?.serverProperties?.liveEventConfig
      if (eventServerConfig?.liveEventDomain?.includes(currentEnv)) {
        liveEventConfig = eventServerConfig
        liveEventConfig.isLiveEvent = false
        liveEventConfig.isLiveEventDomain = true
        if (
          eventServerConfig?.liveEventSupportedCountries?.includes(
            currentRegion
          )
        ) {
          liveEventConfig.storeId =
            eventServerConfig?.liveEventstoreId?.[currentRegion]
          liveEventConfig.isLiveEvent = true
        }
      }
    } catch (e) {
      console.log('Error in getLiveEventConfig ', e)
    }
    return liveEventConfig
  }

  /**
   * Fetches the active store ID and response based on the given page URL and default locale.
   * Sets various store state based on the response.
   * Calls the callback with the active and global store responses and other updated state.
   */
  getActiveStoreIdSettings = async (pageUrl, defaultLocale, cbk, options) => {
    const { curAppStoreId, currentRegion } = this.findActiveStoreData(
      pageUrl,
      defaultLocale
    )
    const activeLocale = getLocaleCodeFromUrl({
      url: pageUrl,
      at: 'pathParam',
      defaultLocale: defaultLocale,
      isReverseType: true,
    })

    let updatedStoreId = curAppStoreId
    const liveEventConfig = this.getLiveEventConfig({
      ...options,
      activeLocale,
    })

    if (liveEventConfig?.isLiveEvent && liveEventConfig?.storeId) {
      updatedStoreId = liveEventConfig?.storeId
    }

    const isSameStoreId = this.activeStoreId === updatedStoreId
    const activeStoreId = updatedStoreId || getAppConfig()?.storeId
    let activeStoreResponse = {}

    const loadParams = {
      endPointName: 'getStores',
      // contextName: props?.contextName,
      isToSendStoreId: false,
      isToSendLocaleInQueryParam: false,
      queryParams: {
        storeId: activeStoreId,
        locale: activeLocale,
      },
    }

    // console.time('inside getactiveStoreIdSettings')
    const storeResponse =
      this.runFromLocal === true ? false : await this.fetchResponse(loadParams)
    // console.timeEnd('inside getActiveStoreIdSettings')

    if (this.isSuccessResponse(storeResponse)) {
      // console.log({ storeResponse })
      this.activeStoreResponse = storeResponse
      activeStoreResponse = storeResponse
    } else {
      /**
       * @note
       * setting the mock response
       * if the APIs failing to response or delayed/aborted
       */
      // this.activeStoreResponse =
      //   require('./storeResponseMock')?.storeSettingsResponse
      activeStoreResponse = storeResponse
      if (!activeStoreResponse?.error) {
        activeStoreResponse['error'] = ''
      }
      activeStoreResponse.error = 'else error'
    }

    /**
     * @todo
     * storeResponse is having wrong defaultLocale value
     * so, it needs to be enabled, when api gets fixed
     */
    // this.activeLocale = storeResponse?.defaultLocale
    const defaultShippingRegion =
      activeStoreResponse?.defaultShippingRegion || 'US'
    this.defaultShippingRegion = defaultShippingRegion

    this.setPriceConfig(activeStoreResponse)
    const storeIDValue = this.setShippingRegion(defaultShippingRegion)
    // }

    if (cbk) {
      const newProps = {
        defaultLocale: activeLocale,
        storeId: activeStoreId,
        dynamicStoreId: activeStoreId,
        // businessRelationship: 'B2B',
        activateB2B2C:
          activeStoreResponse?.properties?.find(x => x.name === 'activateB2B2C')
            ?.value === 'true',
        isPriceRuleEnabled: this.isPriceRuleEnabled,
        activeLoadParams: loadParams,
      }

      cbk(this.activeStoreResponse, this.globalStoreResponse, newProps, {
        globalStoreResponse: this.globalStoreResponse,
        activeStoreResponse: activeStoreResponse,
        countryConfig: this.countryConfig,
        selectedLocaleList: this.selectedLocaleList,
        activeStoreId: activeStoreId,
        activeLocale: activeLocale,
        isPriceRuleEnabled: this.isPriceRuleEnabled,
        priceAccountTypes: this.priceAccountTypes,
        currentRegion: currentRegion,
        defaultShippingRegion: defaultShippingRegion,
        storeIDValue: storeIDValue,
        activeLoadParams: loadParams,
      })
    }
  }

  /**
   * Fetches the global store response from the API.
   *
   * Checks if the cached global store response is still valid before making an API call.
   * Sets the active global store ID, makes the API call to get stores endpoint if cache expired.
   * Handles success and error response from API.
   * Sets country config on the store instance.
   * Calls method to get active store ID before returning.
   *
   * @param {string} globalStoreId - The ID of the global store
   * @param {string} pageUrl - The URL of the current page
   * @param {string} defaultLocale - The default locale code
   * @param {Function} cbk - Callback function
   */
  async getGlobalStoreResponse(
    globalStoreId,
    pageUrl,
    defaultLocale,
    cbk,
    options
  ) {
    const date = new Date()
    if (
      this.globalStoreResponse === undefined ||
      this.activeGlobalStoreId !== globalStoreId ||
      date.getTime() > this.cachedTime
    ) {
      this.cachedTime = date.getTime() + this.CACHE_TTL
      const loadParams = {
        endPointName: 'getStores',
        isToSendStoreId: false,
        isToSendLocaleInQueryParam: false,
        queryParams: {
          storeId: globalStoreId,
          // locale: this.activeLocale,
        },
      }
      let globalStoreResponse = {}
      this.activeGlobalStoreId = globalStoreId
      const response =
        this.runFromLocal === true
          ? false
          : await this.fetchResponse(loadParams)

      if (this.isSuccessResponse(response)) {
        response.globalLoadParams = loadParams
        this.globalStoreResponse = response
        globalStoreResponse = response
      } else {
        /**
         * @note
         * setting the mock response
         * if the APIs failing to response or delayed/aborted
         */
        // this.globalStoreResponse =
        //   require('./storeResponseMock')?.globalStoreResponse
      }
      this.setCountryConfig(options)
    }

    // if (this.countryConfig.length === 0 && this.currentRegion === '') {
    // this.setCountryConfig()
    // }
    const myStoreIDFetcher = this.getActiveStoreIdSettings
    const fetchedResponse = await myStoreIDFetcher(
      pageUrl,
      defaultLocale,
      cbk,
      options
    )
  }

  /**
   * Gets the store list by locale.
   *
   * @param {string} globalStoreId - The global store ID
   * @param {string} pageUrl - The page URL
   * @param {string} defaultLocale - The default locale code
   * @param {Function} cbk - The callback function
   */
  async getStoreListByLocale(
    globalStoreId,
    pageUrl,
    defaultLocale,
    cbk,
    options
  ) {
    this.activeLocale = getLocaleCodeFromUrl({
      url: pageUrl,
      at: 'pathParam',
      defaultLocale: defaultLocale,
      isReverseType: true,
    })
    this.responseCbk = cbk
    await this.getGlobalStoreResponse(
      globalStoreId,
      pageUrl,
      defaultLocale,
      cbk,
      options
    )
  }

  /**
   * Gets the enable zero checkout property value from the active store response.
   * Checks if enableZeroCheckout property exists in the properties array.
   * Filters the properties array to get the enableZeroCheckout property.
   * Gets the value of the first matching enableZeroCheckout property.
   * Defaults to 'false' if property is not found.
   * Returns true if trimmed lower case value is 'true', false otherwise.
   */
  getEnableZeroCheckout = () => {
    const enableZeroCheckoutProps = this.activeStoreResponse?.properties || []
    const enableZeroCheckoutArray = enableZeroCheckoutProps?.filter(el => {
      return el.name == 'enableZeroCheckout'
    })
    const enableZeroCheckoutVal =
      enableZeroCheckoutArray?.length > 0
        ? enableZeroCheckoutArray?.[0]?.value
        : 'false'
    return enableZeroCheckoutVal?.trim?.().toLowerCase?.() === 'true'
  }

  getTaxConfig = () => {
    const storeProperties = this.activeStoreResponse?.properties || []
    const activeStoreResponse = this.activeStoreResponse
    const filteredArray = storeProperties?.filter(el => {
      return el.name == 'taxConfig'
    })
    const taxConfig =
      filteredArray?.length > 0
        ? filteredArray?.[0]?.value
        : APPConfig?.getAppConfig()?.taxConfig
    let taxConfigJSON = taxConfig
    try {
      if(typeof taxConfig === 'string') {
        taxConfigJSON = JSON.parse(taxConfig)
      }
    } catch(e) {
      console.log('error parsing taxConfig JSON: ', e)
    }
    return taxConfigJSON
  }

  /**
   * Gets the allowed reward type for opt-in from the active store response.
   * Checks if the 'allowedRewardTypesForOptIn' property exists in the properties array.
   * Filters the properties array to get the 'allowedRewardTypesForOptIn' property.
   * Gets the value of the first matching 'allowedRewardTypesForOptIn' property.
   * Defaults to empty string if property is not found.
   * Splits the value into an array.
   * Transforms the array values to lowercase with trimmed whitespace.
   * Returns true if the array contains 'loyalty', false otherwise.
   */
  getAllowedRewardTypeForOptIn = () => {
    const allowedRewardTypeForOptInProps =
      this.activeStoreResponse?.properties || []
    const allowedRewardTypeForOptInArray =
      allowedRewardTypeForOptInProps.filter(el => {
        return el.name == 'allowedRewardTypesForOptIn'
      })
    const allowedRewardTypeForOptInVal =
      allowedRewardTypeForOptInArray.length > 0
        ? allowedRewardTypeForOptInArray[0].value
        : ''
    let allowedRewardTypeForOptInValArr =
      allowedRewardTypeForOptInVal.split(',')
    allowedRewardTypeForOptInValArr = allowedRewardTypeForOptInValArr.map(
      key => {
        return key.trim().toLowerCase()
      }
    )
    return allowedRewardTypeForOptInValArr.indexOf('loyalty') > -1
  }

  /**
   * Gets the tier config map value for the given tier from the active store response.
   * Checks if the 'tierConfigMap' property exists in the properties array and parses it.
   * If the parsed JSON has the given tier, returns an object indicating the map exists and contains the tier.
   * If display value is requested, returns a formatted display value string for the tier.
   * Otherwise returns an object indicating no valid map was found, but still contains the requested tier value.
   */
  getTierConfigMapValue = (tier, isTierDisplayVal) => {
    const tierConfigMapProps = this.activeStoreResponse?.properties || []
    const tierConfigMapArray = tierConfigMapProps.filter(el => {
      return el.name == 'tierConfigMap'
    })
    const tierConfigMapVal =
      tierConfigMapArray.length > 0 ? tierConfigMapArray[0].value : ''
    let tierConfigMapJSON = {}
    try {
      tierConfigMapJSON =
        typeof tierConfigMapVal !== 'undefined' && tierConfigMapVal
          ? JSON.parse(tierConfigMapVal)
          : {}
    } catch {
      tierConfigMapJSON = {}
    }
    if (
      typeof tierConfigMapJSON === 'object' &&
      Object.keys(tierConfigMapJSON).length &&
      tierConfigMapJSON?.tier &&
      Object.keys(tierConfigMapJSON.tier).length &&
      Object.keys(tierConfigMapJSON.tier).indexOf(tier) > -1
    ) {
      if (isTierDisplayVal === true) {
        const tierDisplayValJSON = tierConfigMapJSON.tier[tier] || {}
        const tierDisplayName = tierDisplayValJSON?.displayName || ''
        const tierDisplayPercentage =
          tierDisplayValJSON?.displayPercentage || ''
        const tierDisplayValue =
          tierDisplayName +
          (tierDisplayName && tierDisplayPercentage ? '-' : '') +
          tierDisplayPercentage
        return tierDisplayValue
      }
      return { isTierConfigMap: true, tier: tierConfigMapJSON.tier }
    }
    if (isTierDisplayVal === true) {
      return tier
    }
    return { isTierConfigMap: false, tier: tier }
  }

  /**
   * Gets the region part of the active locale string,
   * which indicates the user's country/region.
   * Returns empty string if no region found.
   */
  getLocaleRegion = () => {
    return this.activeLocale && this.activeLocale.indexOf('_') > -1
      ? this.activeLocale.split('_')[1]
      : ''
  }

  /**
   * Checks if the current locale region is 'CA', indicating Canada.
   * Returns true if region is 'CA', false otherwise.
   */
  isCARegion = () => {
    return this.getLocaleRegion() === 'CA'
  }
}

const storeContainer = new StoreContainer()

export { StoreContainer, storeContainer }
export default storeContainer
