import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col } from 'react-bootstrap'
import { getAppConfig, APPConfig } from 'config/appConfig'
import { application, isB2B2C } from 'src/utils'
import { SkuTable } from 'src/views/components'
import { IndividualProductQuantityAndButtons } from './IndividualProductQuantityAndButtons'
import {
  ProductAddToList,
  ProductSubscribeButton,
  ProductAddToCart,
} from './ProductButtons'
import { ProductSkuSelection } from './ProductSkuSelection'
import { ProductShortDescription } from './ProductShortDescription'
import ProductLeaderPreviewMessages from './ProductLeaderPreviewMessages'
import { V2ProductQuantityAndButtons } from 'src/views/components/ProductComponents/V2ProductQuantityAndButtons/V2ProductQuantityAndButtons'
import { cartContainer } from 'src/models'
import './styles.scss'

@observer
class ProductDetailQuickView extends React.Component {
  setCurrentSku = data => {
    const { selectSkuById } = this.props
    const skuId = data.identifier
    selectSkuById(skuId)
  }

  renderTitle = () => {
    // return <ProductTitle {...this.props.product} />
  }

  getIsBundleOrCollection = (isBundleOrCollection, businessRelationship) => {
    const { product } = this.props
    const { isCollection, isBundle } = product
    if (isBundleOrCollection) {
      return isCollection || isBundle
    } else {
      return (
        !isCollection &&
        !isBundle &&
        getAppConfig()?.businessRelationship === businessRelationship
      )
    }
  }

  renderButtonsForB2B = handleAddToBag => {
    const { product, ...remainingProps } = this.props
    return (
      <Row noGutters className="w-100 py-3">
        <Col xs={12} md={12} className="pb-3">
          <ProductAddToCart
            {...product}
            {...remainingProps}
            handleAddToBag={handleAddToBag}
          />
        </Col>
        <Col xs={12} md={6} className="pb-1">
          <ProductAddToList {...this.props} />
        </Col>
        <Col xs={12} md={6}>
          <ProductSubscribeButton {...this.props} />
        </Col>
      </Row>
    )
  }

  renderQtyAndButtons = (edit = '') => {
    const { product } = this.props
    const inventory = this.props.inventory
    const { subscriptions } = product

    return (
      <IndividualProductQuantityAndButtons
        {...this.props}
        inventory={inventory}
        edit={edit}
        isFromQuickView={true}
        isAddToCartDisable={cartContainer.isDisableAddToCart}
      />
    )
  }

  render() {
    const { product } = this.props
    const isExclusive = product?.sku?.[0]?.properties?.isExclusive || ''
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    return (
      <>
        <div id="zoom-image-wrap" />
        {!application.isMobile && this.renderTitle()}
        <ProductSkuSelection {...this.props} />
        <ProductLeaderPreviewMessages
          isExclusive={isExclusive}
          {...this.props}
          isQuickview="true"
        />
        {/* <ProductPromotion {...product} isProductCard={true} /> */}
        <ProductShortDescription {...product} />
        {!enableNewPDPStyle &&
          (isB2B2C() || this.getIsBundleOrCollection(false, 'B2C')) &&
          this.renderQtyAndButtons('noEdit')}
        {enableNewPDPStyle && (
          <V2ProductQuantityAndButtons
            {...this.props}
            isQuickview={'true'}></V2ProductQuantityAndButtons>
        )}
        {/* <ProductAddToList {...product} /> */}
      </>
    )
  }
}

export { ProductDetailQuickView }
