import React from 'react'
import isObject from 'lodash/isObject'
import { observer } from 'mobx-react'
import { Form } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { catalogContainer } from 'src/models'
import { toastState } from 'src/views/components'

@observer
class ProductCompare extends React.Component {
  handleCompare = () => {
    const { categoryId, productId, imageURL } = this.props
    const index = catalogContainer.productCompareList.findIndex(
      product => product.productId === productId
    )
    const { productCompareList, productCompareLimit } = catalogContainer
    if (index > -1) {
      catalogContainer.productCompareList.splice(index, 1)
    } else if (
      productCompareList.length > 0 &&
      categoryId !== productCompareList[0].categoryId
    ) {
      toastState.setToastMessage(
        i18nTranslate(
          'product.diffCategoryError',
          'Products belong to different category, please remove them to compare',
          {
            nameSpace: 'ssr-resource',
          }
        )
      )
    } else if (productCompareList.length < productCompareLimit) {
      catalogContainer.productCompareList.push({
        productId,
        categoryId,
        imageURL,
      })
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'product.compareLimitError',
          'You can compare only {{count}} products at a time',
          { count: catalogContainer.productCompareLimit }
        )
      )
    }
  }

  render() {
    const { productId = '' } = this.props
    const isProductAvailable = catalogContainer.productCompareList.find(
      product => product.productId === productId
    )
    const checked = isObject(isProductAvailable)

    return (
      <div>
        <Form.Check
          type="checkbox"
          className="pl-0 py-3"
          label={i18nTranslate('product.compare', 'Compare', {
            nameSpace: 'ssr-resource',
          })}
          id={`checkbox_${productId}`}
          checked={checked}
          onChange={this.handleCompare}
        />
      </div>
    )
  }
}

export { ProductCompare }
