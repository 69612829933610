import React from 'react'
import loadable from '@loadable/component'

const LazyMarkDown = loadable.lib(() => import('react-markdown'), {
  ssr: false,
})

class MarkdownComponent extends React.Component {
  render() {
    return <LazyMarkDown>{this.props.children}</LazyMarkDown>
  }
}

export default MarkdownComponent
export { MarkdownComponent }
