import * as yup from 'yup'
import { getNameSchema, getEmailSchema } from 'src/deps'
export const getDefaultSchema = (
  isStateFieldDropDown,
  isGuestOrUser,
  countryValue
) => {
  let schemaData = Object.assign(
    {},
    getNameSchema(),
    getNameSchema({ isLastName: true, inputName: 'lastName' }),
    getEmailSchema({ inputName: 'email' })
  )

  return yup.object(schemaData)
}
