import React from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { cartContainer, customerContainer } from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import { overlayState } from 'src/views/components'
import { signInwithRedirect } from 'src/utils/signInUtils'
import {
  i18nTranslate,
  setLocalStorage,
  trackGTMEvents,
  convertToBoolean,
  jitsuSignUpRedirect,
  getLiveEventStatus,
  trackSignUpEvents,
  getGuestSignupOrigin,
} from 'src/utils'
import { APPConfig, getSessionStorage } from 'config/appConfig'
import { GLOBAL_PRIVACY_LINK } from 'src/static/fixtures/links'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import './styles.scss'

@observer
class GuestCheckoutV2 extends React.Component {
  @observable isSponsorValid = true
  componentDidMount() {
    this.checkSponsorValidation()
  }
  checkSponsorValidation = async () => {
    const sponsorId = getSessionStorage('sponsor') || ''
    //Make sponsorId validation call when sponsorId is its exists in sessionStorage
    if (sponsorId) {
      const response = await customerContainer?.sponsorIdValidation(sponsorId)
      if (customerContainer?.isSuccessResponse(response)) {
        this.isSponsorValid = true
      } else {
        this.isSponsorValid = false
      }
    }
  }
  handleDataSubmit = async () => {
    trackSignUpEvents({
      eventName: 'guest_checkout',
      eventType: 'guest_checkout',
    })
    this.props?.checkoutUrl?.history?.push(pageNames?.checkout)
  }

  signupcheck = () => {
    trackGTMEvents({ event: 'Signup', eventType: 'simpCustColdFormStart' })
    setLocalStorage('mysiteCheckout', 'mysiteCheckout')
    if (convertToBoolean(APPConfig?.getAppConfig()?.enableJITSU)) {
      jitsuSignUpRedirect({ isFromGuestCheckout: true })
    } else {
      this.props?.checkoutUrl?.history?.push(pageNames.signUp)
    }
  }

  render() {
    const enableJITSU = convertToBoolean(APPConfig?.getAppConfig()?.enableJITSU)
    const checkout = 'mysiteCheckout'
    const enableLiveEvent = getLiveEventStatus()
    return (
      <Container className="py-2 mb-4 checkout-signin-container">
        <Row noGutters className="guest-checkout-section">
          <Col
            sm={12}
            xs={12}
            lg={6}
            className="signin-checkout-container pl-4 pr-4 signin-container">
            <Button
              block
              data-testid="qa-okta-signin"
              onClick={() => signInwithRedirect(checkout)}
              className="sign-in-btn">
              {i18nTranslate('header.signIn', 'Sign-in', {
                nameSpace: 'ssr-resource',
              })}
            </Button>
            <div className="guest-signup-wrapper">
              <div className="do-not-have-account-text">
                {i18nTranslate(
                  'guest.doNotHaveAnAccount',
                  'Don’t have an account yet?',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )}
              </div>
              <Button
                block
                className="sign-up-btn mt-3"
                data-testid="qa-okta-signup"
                onClick={this.signupcheck}>
                {i18nTranslate('header.signUp', 'Sign Up', {
                  nameSpace: 'ssr-resource',
                })}
              </Button>
            </div>
          </Col>
          {enableLiveEvent !== 'true' && (
            <Col
              sm={12}
              xs={12}
              lg={6}
              className="signup-checkout-container pl-4 pr-4">
              <Button
                block
                data-testid="qa-guest-checkout-button"
                type="submit"
                disabled={!this.isSponsorValid}
                onClick={() => this.handleDataSubmit()}
                className={`sign-in-btn ${
                  !this.isSponsorValid ? 'disabled' : ''
                }`}>
                {i18nTranslate(
                  'guest.continueCheckout',
                  'Continue to Guest Checkout',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )}
              </Button>
              <div className="privacyPolicy-wrapper mt-3">
                <span className="read-text">
                  {i18nTranslate('guest.readText', 'Read up on our', {
                    nameSpace: 'ssr-resource',
                  })}
                </span>{' '}
                <span className="privacy-text">
                  <a href={GLOBAL_PRIVACY_LINK} target="_blank">
                    {i18nTranslate(
                      'guest.privacyPolicy',
                      'data privacy policy',
                      {
                        nameSpace: 'ssr-resource',
                      }
                    )}
                  </a>
                </span>
              </div>
              {!this.isSponsorValid && (
                <p className="mt-2 text-danger font-weight-bold">
                  {i18nTranslate(
                    'invite.sponsorNameErrorMsg',
                    'Oops! There is an issue with the shared link. Please try again. If the problem persists, kindly contact the person who shared the link with you.'
                  )}
                </p>
              )}
            </Col>
          )}
        </Row>
      </Container>
    )
  }
}

export { GuestCheckoutV2 }
export default GuestCheckoutV2
