import {
  enableOutfits,
  enableProductCompare,
  checkoutComponent,
  enableWishList,
  enableSubscription,
  // getLocalStorage,
  IS_BROWSER,
} from 'config/appConfig'

import { getSeparateCartAndCheckout } from 'config/appConfig/configUtils'

const pageNames = {
  home: '/',
  loginCallback: '/login/callback',
  logoutCallback: '/logout/callback',
  catalog: '/catalog',
  // template1: '/template1',
  // template2: '/template2',
  // template3: '/template3',
  // template4: '/template4',
  // template5: '/template5',
  // template6: '/template6',
  // template7: '/template7',
  categoryLanding: '/categoryLanding',
  subscription: '/product/subscribe',
  product: '/product',
  expressPDP: '/expressProduct',
  // producttemplate2: '/producttemplate2',
  // producttemplate3: '/producttemplate3',
  // producttemplate4: '/producttemplate4',
  checkout: '/checkout',
  expressCheckout: '/expressCheckout',
  orderConfirmation: '/orderConfirmation',
  signUp: '/signup',
  signUpModalBrand: `/static/signup/:type/signup-page`,
  signUpModalAssociate: `/associateActivation`,
  signIn: '/signin',
  associateStatic: `/associateInvitationStatic`,
  search: '/search',
  orderLookup: '/orderlookup',
  resetPassword: '/resetPassword',
  userActivation: '/userActivation',
  configs: '/configs',
  favorites: '/favorites',
  storeLocator: '/storeLocator',
  createAccount: '/register',
  myaccount: '/myaccount/:accountTab?',
  myaccountprofile: '/myaccount/welcome',
  myaccountorders: '/myaccount/orderhistory',
  security: '/myaccount/security',
  myaccountvolumes: '/myaccount/volumes',
  myaccountshipping: '/myaccount/address',
  myaccountpayment: '/myaccount/payments',
  myaccountsettings: '/myaccount/settings',
  myaccountsubscriptions: '/myaccount/subscriptions',
  myaccountmycloset: '/myaccount/mycloset',
  myaccountlists: '/myaccount/listsmanagement',
  myaccountrewardsandcredits: '/myaccount/rewardsandcredits',
  myaccountfavourites: '/myaccount/favorites',
  myaccountprivacy: '/myaccount/privacy',
  myaccountdeposit: '/myaccount/directdeposit',
  mysite: '/myaccount/mysite',
  b2bDashBoard: '/myaccount/dashboard',
  b2bMyProfile: '/myaccount/myprofile',
  b2bAccountProfile: '/myaccount/accountprofile',
  b2bAccountTypeConvert: '/myaccount/accounttype',
  b2bApprovals: '/myaccount/approvals',
  b2bUserManagement: '/myaccount/usermanagement',
  b2bContractList: '/myaccount/contracts',
  b2bAddressBook: '/myaccount/address',
  b2bCostCenter: '/myaccount/costcenter',
  b2bTeamManagement: '/myaccount/teammanagement',
  viewCart: '/viewCart',
  SharedList: '/sharedlist',
  productCompare: '/products/compare',
  SelectRegion: '/selectregion',
  LoyaltyDashboard: '/loyalty_landing',
  myAccountPrefix: '/myaccount',
  poSubscripiton: '/subscriptions',
  klarnaCheckout: '/klarnaCheckout',
}

const b2cAccountSubRoutes = [
  {
    path: `${pageNames.myaccountprofile}`,
    pageComponentName: 'MyAccount/MyAccountProfile',
  },
  {
    path: `${pageNames.myaccountorders}`,
    pageComponentName: 'MyAccount/Orders',
  },
  {
    path: `${pageNames.myaccountshipping}`,
    pageComponentName: 'MyAccount/Address',
  },
  {
    path: `${pageNames.myaccountpayment}`,
    pageComponentName: 'MyAccount/Payments',
  },
  {
    path: `${pageNames.myaccountsettings}`,
    pageComponentName: 'MyAccount/Settings',
  },
  {
    path: `${pageNames.myaccountrewardsandcredits}`,
    pageComponentName: 'MyAccount/Loyalty',
  },
  {
    path: `${pageNames.myaccountrewardsandcredits}`,
    pageComponentName: 'MyAccount/Loyalty',
  },
  {
    path: `${pageNames.myaccountprivacy}`,
    pageComponentName: 'MyAccount/Privacy',
  },
  {
    path: `${pageNames.myaccountfavourites}`,
    pageComponentName: 'MyAccount/Favorites',
  },
  {
    path: `${pageNames.myaccountdeposit}`,
    pageComponentName: 'MyAccount/DirectDeposite',
  },
  enableWishList && {
    path: `${pageNames.myaccountlists}`,
    pageComponentName: 'MyAccount/ListsManagement',
  },
  enableSubscription && {
    path: `${pageNames.myaccountsubscriptions}`,
    pageComponentName: 'MyAccount/Subscriptions',
  },

  enableOutfits && {
    path: `${pageNames.myaccountmycloset}`,
    pageComponentName: 'MyAccount/MyCloset',
  },
].filter(Boolean)

// enableWishList &&
//   b2cAccountSubRoutes.push({
//     path: `${pageNames.myaccountlists}`,
//     pageComponentName: 'MyAccount/ListsManagement',
//   })

// b2cAccountSubRoutes.push({
//   path: `${pageNames.myaccountsettings}`,
//   pageComponentName: 'MyAccount/Settings',
// })

// enableSubscription &&
//   b2cAccountSubRoutes.push({
//     path: `${pageNames.myaccountsubscriptions}`,
//     pageComponentName: 'MyAccount/Subscriptions',
//   })

// b2cAccountSubRoutes.push({
//   path: `${pageNames.myaccountrewardsandcredits}`,
//   pageComponentName: 'MyAccount/Loyalty',
// })

// b2cAccountSubRoutes.push({
//   path: `${pageNames.myaccountprivacy}`,
//   pageComponentName: 'MyAccount/Privacy',
// })

// b2cAccountSubRoutes.push({
//   path: `${pageNames.myaccountfavourites}`,
//   pageComponentName: 'MyAccount/Favorites',
// })

// b2cAccountSubRoutes.push({
//   path: `${pageNames.myaccountdeposit}`,
//   pageComponentName: 'MyAccount/DirectDeposite',
// })

// enableOutfits &&
//   b2cAccountSubRoutes.push({
//     path: `${pageNames.myaccountmycloset}`,
//     pageComponentName: 'MyAccount/MyCloset',
//   })

const b2bAdminSubRoutes = [
  {
    path: `${pageNames.b2bDashBoard}`,
    pageComponentName: 'MyAccount/Dashboard/Dashboard',
  },
  {
    path: `${pageNames.b2bMyProfile}`,
    pageComponentName: 'MyAccount/MyAccountProfile',
  },
  {
    path: `${pageNames.b2bAccountProfile}`,
    pageComponentName: 'MyAccount/B2BAccountProfile',
  },
  {
    path: `${pageNames.b2bAccountTypeConvert}`,
    pageComponentName: 'MyAccount/ConvertAccountType', //B2BAccountConvert',
  },
  {
    path: `${pageNames.myaccountorders}`,
    pageComponentName: 'MyAccount/Orders',
  },
  {
    path: `${pageNames.security}`,
    pageComponentName: 'MyAccount/Security',
  },
  {
    path: `${pageNames.mysite}`,
    pageComponentName: 'MyAccount/MySite',
  },
  {
    path: `${pageNames.myaccountvolumes}`,
    pageComponentName: 'MyAccount/Volumes',
  },
  {
    path: `${pageNames.b2bUserManagement}`,
    pageComponentName: 'MyAccount/UserManagement',
  },
  {
    path: `${pageNames.b2bCostCenter}`,
    pageComponentName: 'MyAccount/CostCenter',
  },
  {
    path: `${pageNames.b2bTeamManagement}`,
    pageComponentName: 'MyAccount/TeamManagement',
  },
  {
    path: `${pageNames.b2bContractList}`,
    pageComponentName: 'MyAccount/Contracts',
  },
]

const combineSubRoutes = [...b2bAdminSubRoutes, ...b2cAccountSubRoutes]
const accountSubRoutes = Array.from(
  new Set(combineSubRoutes.map(route => route.path))
).map(path => {
  return combineSubRoutes.find(route => route.path === path)
})

const isDevEnvironment = () => {
  /**
   * @note
   * here, we are skipping the configs page
   * for higher environments
   */
  if (IS_BROWSER) {
    return (
      window.location.host.includes('-dev1') ||
      window.location.host.includes('-dev2') ||
      window.location.host.includes('local')
    )
  }
  return false
}

const pathParams = [
  {
    path: pageNames.home,
    pageComponentName: 'StudioPage',
  },
  {
    path: pageNames.loginCallback,
    pageComponentName: 'LoginCallback',
  },
  {
    path: pageNames.logoutCallback,
    pageComponentName: 'LogoutCallback',
  },
  {
    path: `${pageNames.search}/:searchTerm`,
    pageComponentName: 'Catalog',
  },
  {
    path: `${pageNames.catalog}/:catalogId`,
    pageComponentName: 'Catalog',
  },
  // {
  //   path: `${pageNames.template1}/:catalogId?`,
  //   pageComponentName: 'Catalog',
  // },
  // {
  //   path: `${pageNames.template2}/:catalogId?`,
  //   pageComponentName: 'CatalogViewWithTopFilter',
  // },
  {
    path: `${pageNames.product}/:productId`,
    pageComponentName: 'Product',
  },
  {
    path: `${pageNames.expressPDP}/:productId`,
    pageComponentName: 'ExpressPDP',
  },
  // {
  //   path: `${pageNames.producttemplate2}/:productId`,
  //   pageComponentName: 'ProductTemplate2',
  // },
  // {
  //   path: `${pageNames.producttemplate3}/:productId`,
  //   pageComponentName: 'ProductTemplate3',
  // },
  // {
  //   path: `${pageNames.producttemplate4}/:productId`,
  //   pageComponentName: 'ProductTemplate4',
  // },
  {
    path: `${pageNames.signUp}`,
    pageComponentName: 'SignUp',
  },
  {
    path: `${pageNames.signUpModalBrand}`,
    pageComponentName: 'SignUpModal',
    isExact: false,
  },
  {
    path: `${pageNames.signUpModalAssociate}`,
    pageComponentName: 'SignUpModal',
    isExact: false,
  },
  {
    path: `${pageNames.associateStatic}`,
    pageComponentName: 'AssociateStatic',
    isExact: false,
  },
  {
    path: `${pageNames.myaccount}`,
    pageComponentName: 'MyAccount',
    routes: accountSubRoutes,
  },
  /**
   * @todo
   * commented out the below lines since it is causing issue at SSR compilation
   * need to fix the issue after root cause analysis of this backup route
   */

  {
    path: `${pageNames.signIn}`,
    pageComponentName: 'SignIn',
  },
  {
    path: `${pageNames.checkout}`,
    pageComponentName: checkoutComponent,
  },
  {
    path: `${pageNames.expressCheckout}`,
    pageComponentName: 'ExpressCheckout',
  },
  {
    path: `${pageNames.orderLookup}`,
    pageComponentName: 'OrderLookUp',
  },
  {
    path: `${pageNames.orderConfirmation}`,
    pageComponentName: 'OrderConfirmation',
  },
  // {
  //   path: `${pageNames.resetPassword}`,
  //   pageComponentName: 'ResetPassword',
  //   isExact: false,
  // },
  {
    path: `${pageNames.userActivation}`,
    pageComponentName: 'ResetPassword',
    isExact: false,
  },
  {
    path: `${pageNames.categoryLanding}/:categoryId`,
    pageComponentName: 'CategoryLanding',
  },
  {
    path: `${pageNames.favorites}`,
    pageComponentName: 'Favorites',
  },
  isDevEnvironment() && {
    path: `${pageNames.configs}`,
    pageComponentName: 'Config',
  },
  {
    path: `${pageNames.status}`,
    pageComponentName: 'Status',
  },
  // {
  //   path: `${pageNames.createAccount}`,
  //   pageComponentName: 'CreateAccount',
  // },
  // {
  //   path: `${pageNames.template3}/:catalogId?`,
  //   pageComponentName: 'CatalogListView',
  // },
  // {
  //   path: `${pageNames.template4}/:catalogId?`,
  //   pageComponentName: 'CatalogViewWithLazyLoad',
  // },
  // {
  //   path: `${pageNames.template5}/:catalogId?`,
  //   pageComponentName: 'CatalogViewWithColor',
  // },
  // {
  //   path: `${pageNames.template6}/:catalogId?`,
  //   pageComponentName: 'CatalogViewWithDropdown',
  // },
  // {
  //   path: `${pageNames.template7}/:catalogId?`,
  //   pageComponentName: 'CatalogTableView',
  // },
  {
    path: `${pageNames.SharedList}`,
    pageComponentName: 'SharedList',
  },
  {
    path: `${pageNames.SelectRegion}`,
    pageComponentName: 'SelectRegion',
  },
  {
    path: `${pageNames.klarnaCheckout}`,
    pageComponentName: 'KlarnaCheckout',
  },
  enableSubscription && {
    path: `${pageNames.subscription}/:productId`,
    pageComponentName: 'Subscription',
  },
  getSeparateCartAndCheckout() && {
    path: `${pageNames.viewCart}`,
    pageComponentName: 'ViewCart',
  },
  enableProductCompare && {
    path: `${pageNames.productCompare}`,
    pageComponentName: 'ProductsCompare',
  },
  {
    path: `${pageNames.LoyaltyDashboard}`,
    pageComponentName: 'LoyaltyDashboard',
  },
  {
    path: `*`,
    pageComponentName: 'NotFound',
  },
].filter(Boolean)

// enableSubscription &&
//   pathParams.push({
//     path: `${pageNames.subscription}/:productId`,
//     pageComponentName: 'Subscription',
//   })

// enableProductCompare &&
//   pathParams.push({
//     path: `${pageNames.productCompare}`,
//     pageComponentName: 'ProductsCompare',
//   })

// pathParams.push({
//   path: `*`,
//   pageComponentName: 'NotFound',
// })

export {
  pathParams,
  pageNames,
  accountSubRoutes,
  b2bAdminSubRoutes,
  b2cAccountSubRoutes,
}
export default pathParams
