import React from 'react'
import { Dropdown, DropdownButton, Row, Col } from 'react-bootstrap'
import { observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { i18nTranslate } from 'src/utils'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { getLocaleString } from 'src/utils'

@observer
class ProductSkuOption extends React.Component {
  showErrorForSkuSelection = type => {
    const ErrorText = i18nTranslate('product.selectSkuError', 'Choose the', {
      nameSpace: 'ssr-resource',
    })
    const errorView =
      this.props.skuSelected[type] === '' ? (
        <span className="font-weight-bold text-danger">
          {ErrorText} {type}
        </span>
      ) : (
        <React.Fragment />
      )
    return errorView
  }

  renderSkuDetails = (item, index) => {
    const type = item.type
    const selectedSku = !isEmpty(this.props.skuSelected[type])
      ? this.props.skuSelected[type]
      : this.showErrorForSkuSelection(type)

    return (
      <Col key={index} data-testid={`qa-select-${type}`}>
        <Dropdown.Header className="p-0 font-weight-bold text-capitalize">
          {getLocaleString()[type]}
        </Dropdown.Header>
        <DropdownButton
          size="md"
          variant="light"
          title={selectedSku}
          className="collection-dropdown">
          {item.values.map((skuValues, idx) => (
            <Row noGutters key={idx} className="collection-skuDetails">
              {item && type.toLowerCase() === 'color' && (
                <Col xs={1}>
                  <LazyLoadImage
                    src={skuValues.url || imagePlaceholder}
                    alt={imagePlaceholder}
                    className={'collection-color-image'}
                    onError={e => {
                      if (e.target.src != imagePlaceholder) {
                        e.target.onerror = null
                        e.target.src = imagePlaceholder
                      }
                    }}
                  />
                </Col>
              )}
              <Col xs={11}>
                <Dropdown.Item
                  key={idx}
                  className="collection-sku-items"
                  active={skuValues.disable}
                  onClick={() => {
                    this.props.handleSkuSelection(
                      {
                        type: type,
                        value: skuValues.label,
                      },
                      this.props.index
                    )
                  }}>
                  {skuValues.label}
                </Dropdown.Item>
              </Col>
            </Row>
          ))}
        </DropdownButton>
      </Col>
    )
  }

  render() {
    const { options } = this.props
    return options.map(this.renderSkuDetails)
  }
}

export { ProductSkuOption }
export default ProductSkuOption
