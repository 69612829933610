import React, { Component } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row } from 'react-bootstrap'

class ScrollingInfoSection extends Component {
  constructor(props, context) {
    super(props, context)
    this.slider = React.createRef(null)
    this.mainTitle = 'scrollingInfoSlider'
  }

  componentDidUpdate() {
    if (this.slider.current) {
      this.slider.current.slickGoTo(0, true)
    }
  }

  handleTabIndexAndGTMCarouselClick = () => {
    document
      .querySelectorAll(`#${this.mainTitle} .slick-list .slick-cloned a`)
      .forEach(anchorTag => anchorTag.setAttribute('tabindex', '-1'))

    document
      .querySelectorAll(`#${this.mainTitle} .slick-list .slick-slide`)
      .forEach(ele => ele.setAttribute('aria-hidden', 'false'))
  }

  handleBeforeChanges = () => {
    document
      .querySelectorAll(`#${this.mainTitle} .slick-list .slick-slide`)
      .forEach(ele => ele.setAttribute('aria-hidden', 'false'))
  }

  getSliderSettings = () => {
    return {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      // vertical: true,
      // verticalSwiping: true,
      rtl: true,
      onReInit: this.handleTabIndexAndGTMCarouselClick,
      afterChange: this.handleTabIndexAndGTMCarouselClick,
      beforeChange: this.handleBeforeChanges,
      arrows: false,
      adaptiveHeight: true,
      variableHeight: false,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    }
  }

  render() {
    const { scrollingInfo } = this.props
    const settings = this.getSliderSettings()
    return (
      <Container
        fluid
        className="scrollInfoSec text-center align-items-center p-1">
        <Row className="container-fluid nu-dynamic-info-font align-items-center h-100">
          <Col zIndex={0} id="scrollingInfoSlider">
            <Slider
              {...settings}
              ref={slider => (this.slider.current = slider)}>
              {scrollingInfo?.length > 0 &&
                scrollingInfo?.map(item => (
                  <div key={item?.title}>
                    <div className="scrollInfo-title line-height">
                      <a
                        href={item?.href}
                        aria-label={item?.title}
                        aria-hidden={false}>
                        <b>{item?.title}</b>
                      </a>
                    </div>
                    {item?.description && (
                      <p className="scrollInfo-desc">{item?.description}</p>
                    )}
                  </div>
                ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ScrollingInfoSection
