import { customerContainer } from 'src/models'
import { IS_BROWSER } from './application'
import { setLocalStorage } from 'config/appConfig/configUtils'
import { trackErrorInInstana } from './commonUtils'

let isSessionEventReceivedFromNativeApp = false

/**
 * Publishes an event to the React Native WebView by posting a message
 * containing the event name and payload.
 *
 * @param {string} eventType - The name of the event to publish
 * @param {object} eventDetail - The payload to pass with the event
 */
function publishEvent(eventType, eventDetail) {
  window?.ReactNativeWebView?.postMessage(
    JSON.stringify({ name: eventType, payload: eventDetail })
  )
}

/**
 * Sends a 'ready_to_receive' event to notify the native app that the webview is ready to receive events.
 * Adds event listeners for handling incoming messages from the native app.
 *
 * @param {Object} eventDetail - Details to pass along with the event.
 */
export function sendReadyToReceive(eventDetail) {
  window.addEventListener('message', eventListener)
  document.addEventListener('message', eventListener)
  publishEvent('ready_to_receive', eventDetail)
  trackErrorInInstana({
    errorReport: 'Ready to recieve Event',
    errorData: {
      eventDetail,
    },
  })
}

/**
 * Publishes a 'need_to_signin' event to notify the native app that the user needs to sign in.
 *
 * @param {Object} eventDetail - Details to pass along with the event.
 */
export function sendNeedToSignIn(eventDetail) {
  trackErrorInInstana({
    errorReport: 'Need to signin Event',
    errorData: {
      eventDetail,
    },
  })
  publishEvent('need_to_signin', eventDetail)
}

/**
 * Sends the current cart quantity to the native app.
 *
 * Checks if we are in a browser and the ReactNativeWebView is available,
 * then publishes a 'cart_quantity' event with the provided eventDetail payload.
 */
export function sendCartCount(eventDetail) {
  if (IS_BROWSER) {
    if (window.ReactNativeWebView) {
      trackErrorInInstana({
        errorReport: 'Cart Quantity Event',
        errorData: {
          eventDetail,
        },
      })
      publishEvent('cart_quantity', eventDetail)
    }
  }
}

/**
 * Sends an Okta session token to the native app.
 *
 * Checks if we are in a browser and the ReactNativeWebView is available,
 * then publishes a 'okta_session_token' event with the provided eventDetail payload.
 */
export function sendOktaSession(eventDetail) {
  if (IS_BROWSER) {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      trackErrorInInstana({
        errorReport: 'Send Okta Session Event',
        errorData: {
          eventDetail,
        },
      })
      window.ReactNativeWebView.postMessage(JSON.stringify(eventDetail))
    }
  }
}

/**
 * Checks if the app is running in a native mobile app context.
 * In the browser, checks `window` for flags indicating a native app.
 * Returns true if running in a native mobile app, false otherwise.
 */
export function checkIsNativeApp() {
  if (IS_BROWSER) {
    const isNativeMobileApp =
      window?.isNativeApp == true || window?.isVeraApp == true
    return isNativeMobileApp
  }
  return false
}

/**
 * Listens for messages from the native app and handles updating session data.
 *
 * Parses the message data as JSON and updates localStorage with the latest
 * session token or shopping context. Also updates the customerContainer's
 * registerUser flag and the IsAuthenticated flag based on the session token.
 */
const eventListener = async message => {
  let data = {}

  // getting multiple events from dev tools
  if (message?.data?.source == 'react-devtools-content-script') {
    return
  }

  try {
    trackErrorInInstana({
      errorReport: 'Event from Native App',
      errorData: {
        message,
      },
    })

    data = JSON.parse(message?.detail?.data || message?.data || '{}') || {}
    if (data?.shoppingContext) {
      setLocalStorage('shoppingContext', { context: data?.shoppingContext })
    }
  } catch (e) {
    console.warn('JSON.parse may failed at eventListener >>>', e)
  }

  if (IS_BROWSER) {
    if (data?.skSessionId) {
      localStorage.removeItem('accessToken')
      setLocalStorage('x-sk-session-id', data.skSessionId)
      trackErrorInInstana({
        errorReport: 'SK session Id Event',
        errorData: {
          sessionId: data?.skSessionId || '',
        },
      })
      triggerInitViewCart({ data })
    } else if (data?.accessToken) {
      localStorage.removeItem('x-sk-session-id')
      setLocalStorage('accessToken', data.accessToken)
      customerContainer.isRegisterUser = true
      setLocalStorage('IsAuthenticated', true)

      trackErrorInInstana({
        errorReport: 'accessToken Event',
        errorData: {
          accessToken: data?.accessToken || '',
        },
      })
      triggerInitViewCart({ data })
    }
  }
}

function triggerInitViewCart(options) {
  const { data } = options || {}
  if (isSessionEventReceivedFromNativeApp) {
    trackErrorInInstana({
      errorReport: 'Duplicate Event from Native App',
      errorData: {
        data: data,
      },
    })
  }
  const event = new CustomEvent('initViewCart', {
    detail: {},
  })
  document.dispatchEvent(event)
  isSessionEventReceivedFromNativeApp = true
}
