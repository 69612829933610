import React from 'react'
import { CustomPrice } from 'src/views/components'
import './styles.scss'

class SubscriptionProductPrice extends React.Component {
  render() {
    const {
      priceInfo = {},
      type = '',
      isBundle = false,
      isCurrencySymbolRequired = false,
    } = this.props || {}
    const {
      originalPrice = '',
      priceAfterDiscount = '',
      totalPriceDiscount = 0,
    } = priceInfo || {}
    const priceFreePromotion =
      originalPrice - totalPriceDiscount == priceAfterDiscount &&
      priceAfterDiscount == 0
        ? true
        : false

    return (
      <div>
        <div className="flexible-subs-price">
          <CustomPrice
            custompriceValue={originalPrice}
            customDiscountPriceValue={priceAfterDiscount}
            isCurrencySymbol={isCurrencySymbolRequired}
            dataTestId="qa-cart-drawer-unit-price"
            priceFreePromotion={priceFreePromotion}
            type={type}
            isbundle={isBundle}
          />
        </div>
      </div>
    )
  }
}

export default SubscriptionProductPrice
export { SubscriptionProductPrice }
