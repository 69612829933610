import React from 'react'
import { JsonLd } from 'src/views/components'
import { headerLogoSchema } from 'src/models/Schema'
import logo from 'src/images/logo_horizontal.png'
import './styles.scss'

class HeaderLogo extends React.PureComponent {
  render() {
    const {
      requestDomain,
      isNuskinHeader = false,
      headerLogoUrl = '',
    } = this.props
    const schemaData = headerLogoSchema({ requestDomain })
    const byDefaultPlaceHolder =
      'https://media.nuskin.com/m/5759f4991d36b1fe/original/nu-skin-logo.svg'
    return isNuskinHeader ? (
      <img
        className="nu-logo-image"
        alt="Nu Skin logo,HomePage"
        src={headerLogoUrl || byDefaultPlaceHolder}
        data-testid="qa-nuskin-logo"
      />
    ) : (
      <>
        {/* <HeaderLogoView /> */}
        <img
          className="logo-image des-display"
          alt="Nu Skin logo,HomePage"
          src="https://media.nuskin.com/m/2c95048647c42590/Vector_to_PNG-logo.png"
          data-testid="qa-nuskin-logo"
        />
        <img
          className="logo-image mob-display-logo"
          alt="Nu Skin logo,HomePage"
          src={logo}
        />
        <JsonLd data={schemaData} key="schema-headerLogo" />
      </>
    )
  }
}

export { HeaderLogo }
export default HeaderLogo
