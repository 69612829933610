import { observable } from 'mobx'
import { catalogContainer, customerContainer, storeContainer } from 'src/models'
import { application } from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import { constructQueryParam } from 'src/deps'

/**
 * CatalogDeps class
 */
class CatalogDeps {
  isLoadFromSKU = false
  // used to track whether user clicks the suggestion or uses keyboard
  isPlainSearch = false
  @observable queryParam = {}
  @observable totalPage = 1
  @observable activePage = 1
  @observable isFilterOpen = application.isTabletOrLarger
  // filter will be open initially for desktop
  // closed for mobile
  @observable isFeaturedSelected = false
  @observable showProductBlock =
    application.isTabletOrLarger === true ? true : this.isFilterOpen === false
  // will be always "true" for desktop
  // In mobile, productblock will be hidden when filter is open
  /**
   * Toggles the filter panel open/closed on mobile devices.
   * On mobile, closes the filter panel and shows the product block when opened,
   * or opens the filter panel and hides the product block when closed.
   * No effect on tablet/desktop where filter panel remains open.
   */
  toggleFilter = () => {
    if (!application.isTabletOrLarger) {
      this.isFilterOpen = !this.isFilterOpen
      this.showProductBlock = !this.showProductBlock
    }
  }
  /**
   * Calculates pagination info based on response and props
   * @param {Object} response - API response object
   * @param {Object} props - Component props object
   * Sets activePage and totalPage computed values based on response
   */
  calculatePagination = (response = {}, props = {}) => {
    this.activePage = parseInt(props.location?.queryParams?.page || '1')
    this.totalPage = Math.ceil(
      (response.pageableInfo?.totalCount || 1) /
        (response.pageableInfo?.size || 1)
    )
  }

  /**
   * Gets the default SKU from a product's SKUs array
   * @param {Object} product - The product object
   * @returns {Object} The default SKU object
   */
  getDefaultSelectedSku = (product = {}) => {
    const skus = product.sku || []
    return skus.filter(sku => {
      return sku.default === true && sku
    })
  }
  /**
   * Sets the searchTerm query parameter based on the provided searchTerm argument.
   * Decodes any URL encoding in the searchTerm first.
   * If decoding fails, just sets the searchTerm directly without decoding.
   */
  setQueryParamsData = (searchTerm = '') => {
    console.log('searchTerm setQueryParamsData', searchTerm)
    try {
      this.queryParam.searchTerm = decodeURIComponent(searchTerm)
    } catch {
      this.queryParam.searchTerm = searchTerm
    }
  }
  /**
   * Retrieves catalog data from the API based on route parameters and query params.
   * Handles fetching product search results or category data.
   * Applies price rules based on customer account type.
   * Returns the API response after transforming and calculating pagination.
   */
  getCatalog = async (props = {}) => {
    const isRegisterUser = customerContainer.isRegisterUser
    let profileResponse = customerContainer.profileResponse
    const price_accountTypes = storeContainer.priceAccountTypes
    /**
     * @note
     * Commenting redundant calls for refactoring
     */
    let accounts = profileResponse.accounts || {}
    let accountType = accounts.type || ''
    if (accountType && price_accountTypes.includes(accountType)) {
      this.queryParam.priceParam = JSON.stringify({
        [storeContainer.accountKey]: accountType,
      })
    }
    // else if (storeContainer.isPriceRuleEnabled) {
    //   this.queryParam.priceParam = JSON.stringify({})
    // }
    const pathname = props.location?.pathname || ''
    const matchParams = props.match?.params || {}
    let response = {}
    const contextUrlPathFromSSR = props.location?.pathname || ''
    const contextQueryParamsFromSSR = props.location?.search || ''

    if (pathname?.includes(pageNames.search)) {
      this.setQueryParamsData(matchParams.searchTerm || '')

      response = await catalogContainer.getSearchProducts({
        queryParams: this.queryParam,
        context: props.context,
        contextName: props.contextName,
        contextUrlPathFromSSR,
        contextQueryParamsFromSSR,
      })
      catalogContainer.shouldShowPlaceholder = false
    } else {
      const categoryId = matchParams.catalogId || ''
      response = await catalogContainer.getCatalog({
        categoryId,
        page: props.page,
        queryParams: this.queryParam,
        context: props.context,
        contextName: props.contextName,
        contextUrlPathFromSSR,
        contextQueryParamsFromSSR,
        ...props,
      })
      catalogContainer.shouldShowPlaceholder = false
    }
    return this.getModifiedResponse({ response, props })
  }
  /**
   * Gets the response data from the context for the given context name.
   * Transforms the product data if available.
   * Then calls getModifiedResponse to process the response before returning it.
   *
   * @param {Object} params - The parameters
   * @param {Object} params.context - The context data
   * @param {string} params.contextName - The name of the context to get data for
   * @param {Object} params.props - The props passed to the component
   * @returns {Object} The processed response data
   */
  getResponseFromContext = (params = {}) => {
    const { context = {}, contextName = '', props = '' } = params
    let response = context.data?.[contextName] || {}
    if (response.product) {
      response = catalogContainer.transformCatalog(response)
    }
    return this.getModifiedResponse({ response, props })
  }
  /**
   * Gets the product response data from the context for the given context name.
   * Transforms the product data if available using transformProduct.
   * Then returns the response.
   *
   * @param {Object} params - The parameters
   * @param {Object} params.context - The context data
   * @param {string} params.contextName - The name of the context to get data for
   * @param {Object} params.props - The props passed to the component
   * @returns {Object} The product response data
   */
  getProductResponseFromContext = (params = {}) => {
    const { context = {}, contextName = '', props } = params
    let response = context.data?.[contextName] || {}
    if (response.product) {
      response = catalogContainer.transformProduct(response, props)
    }
    return response
  }
  /**
   * Modifies the catalog response before returning it.
   * If there is only 1 product result, redirects to the product page.
   * Calculates pagination info and adds it to the response.
   *
   * @param {Object} params - Function parameters
   * @param {Object} params.response - Catalog response data
   * @param {Object} params.props - Component props passed in
   * @returns {Object} Modified response
   */
  getModifiedResponse = params => {
    const { response = {}, props = {} } = params || {}
    const matchQueryParam = ['page', 'filter']
    const hasDidYouMeanResults = response?.didYouMean || []
    if (
      response.pageableInfo?.totalCount == 1 &&
      hasDidYouMeanResults?.length <= 0 &&
      !matchQueryParam.find(param => param in this.queryParam)
    ) {
      const productIdentifier = response.product?.[0]?.identifier || ''
      const productSlug = response.product?.[0]?.properties?.slug || ''
      const productOrSlugId = productSlug ? productSlug : productIdentifier
      const defaultSku =
        response.product?.[0]?.sku?.filter(sku => {
          return sku.default === true
        }) || []
      const defaultSkuSlug = defaultSku?.[0]?.properties?.slug || ''
      const skuId = defaultSku?.[0]?.identifier || ''

      let url = `${pageNames.product}/${productOrSlugId}`
      const hasMoreThanOneSku = (response.product?.[0]?.sku?.length || 1) > 1
      const variantParameter = defaultSkuSlug || skuId || ''
      /* @variant_autoselect */
      if (hasMoreThanOneSku && variantParameter) {
        url += `?variant=${variantParameter}`
      }
      // Using replace to prevent back button from redirecting to the search page
      props.history.replace(url)
    }
    this.calculatePagination(response, { ...props })
    return response
  }
  /**
   * Handles updating the filter query parameter and
   * constructing the full query parameter object when a filter is applied.
   *
   * @param {Object} filterValue - The selected filter value
   * @param {Object} props - The component props
   */
  handleFilter = async (filterValue, props) => {
    if (filterValue) {
      this.queryParam.filter = JSON.stringify({
        filters: filterValue,
      })
    } else {
      delete this.queryParam.filter
    }
    constructQueryParam(props, this.queryParam, true)
    // await this.getCatalog(props)
    application.isMobile && this.toggleFilter()
  }
  /**
   * Handles updating the sort query parameter and
   * updating the isFeaturedSelected property when a
   * sort option is selected.
   *
   * @param {Object} sortValue - The selected sort value
   * @param {Object} props - The component props
   */
  handleSort = async (sortValue, props) => {
    this.isFeaturedSelected = sortValue.sortField === 'featured' ? true : false
    if (sortValue.sortField != 'featured') {
      this.queryParam.sort = JSON.stringify({
        sort: [
          { field: sortValue.sortField, direction: sortValue.sortDirection },
        ],
      })
    } else {
      delete this.queryParam.sort
    }
    constructQueryParam(props, this.queryParam, true)
  }

  /**
   * Handles updating the pagination query parameter and
   * constructing the full query parameter object when the pagination changes.
   *
   * @param {number} value - The selected pagination page
   * @param {Object} props - The component props
   */
  handlePagination = async (value, props) => {
    this.queryParam.page = value
    constructQueryParam(props, this.queryParam)
  }
  /**
   * Extracts unique promotion text messages from an array of promotion details.
   *
   * @param {Object[]} promotionDetails - Array of promotion detail objects
   * @returns {string[]} Array of unique promotion text messages
   */
  getPromoText = promotionDetails => {
    let uniquePromotion = new Set()
    {
      promotionDetails.length > 0 &&
        promotionDetails.map(promotion => {
          const displayMessage = promotion?.displayMessage || ''
          const message = promotion?.message || displayMessage
          uniquePromotion.add(message)
        })
    }
    return (uniquePromotion = [...uniquePromotion])
  }
}

const catalogDeps = new CatalogDeps()

export { catalogDeps }
export default catalogDeps
