import React from 'react'
import { withRouter } from 'react-router'
import { pageNames } from './pathParams'

class ScrollToTop extends React.Component {
  componentDidUpdate() {
    /**
     * @info
     * added condition to support tab index from header on page navigation except myaccount.
     */
    if (!window?.location?.pathname.includes(pageNames.myAccountPrefix)) {
      const container = document.getElementById('app-root')
      if (container) {
        container.tabIndex = -1
        container.focus()
        setTimeout(() => container.removeAttribute('tabindex'), 1000)
      }
    }
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

const ScrollToTopWithRouter = withRouter(ScrollToTop)

export { ScrollToTopWithRouter }
