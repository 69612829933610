import React from 'react'
import { observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Row, Col, Button, Container } from 'react-bootstrap'
import { shouldShowAddedBundleDetails } from 'config/appConfig'
import {
  checkEnableButton,
  modifyAndGetCustomerType,
  modifyAndGetAccountType,
  checkCustomerTypeEligibility,
} from 'src/utils'
import { productDeps, dateFormatDeps } from 'src/deps'
import { i18nTranslate } from 'src/utils'
import {
  cartContainer,
  customerContainer,
  nuskinSubscriptionContainer,
} from 'src/models'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { V2ProductQuantityAndButtons } from 'src/views/components/ProductComponents/V2ProductQuantityAndButtons/V2ProductQuantityAndButtons'
import './styles.scss'

@observer
class V2BundleFloatingBar extends React.Component {
  @observable isUserSubscribedBefore = false

  constructor(props) {
    super(props)
  }
  async componentDidMount() {
    // @##makenewpromo

    reaction(
      () => [
        cartContainer.subscriptionResponse?.totalSubscriptionCount,
        cartContainer.subscriptionResponse?.isSubscriptionPreference,
      ],
      () => {
        this.isUserSubscribedBefore =
          nuskinSubscriptionContainer.isUserSubscribedBefore()
        this.forceUpdate()
      }
    )
  }

  renderFloatingBar = () => {
    if (this.props.isExpressPDP) {
      return <></>
    }
    return (
      <Row noGutters className="justify-content-between">
        <Col className="d-flex flex-column">
          <Row noGutters>
            <V2ProductQuantityAndButtons
              {...this.props}></V2ProductQuantityAndButtons>
          </Row>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <>
        <div className={`floating-bar-container w-100`}>
          {shouldShowAddedBundleDetails ? (
            <Container>{this.renderFloatingBar()}</Container>
          ) : (
            <>
              {/* shouldShowAddedBundleDetails is always true, below is not used */}
              <Button
                variant="primary"
                className="px-4 d-block ml-auto"
                onClick={this.props.handleAddToBag}>
                {i18nTranslate('bundle.checkout', 'Continue to Checkout', {
                  nameSpace: 'ssr-resource',
                })}
              </Button>
            </>
          )}
        </div>
      </>
    )
  }
}

export { V2BundleFloatingBar }
export default V2BundleFloatingBar
