//import parsePhoneNumber from 'libphonenumber-js'
import React from 'react'
import { IoMdHelpCircle as QuestionCricle } from 'react-icons/io'
import { modalState } from 'src/views/components'
import { countryData } from 'src/views/components/SignUpForms/countryCodeData'
import {
  cartContainer,
  customerContainer,
  storeContainer,
  accountsContainer,
} from 'src/models'
import {
  application,
  getLiveEventStatus,
  i18nTranslate,
  contentFromCS,
  getParsedPhoneNumber,
  isExpressCheckout,
} from 'src/utils'
import { allCountriesJson } from 'src/static/geography'
import {
  convertToBoolean,
  getSessionStorage,
  setSessionStorage,
} from 'config/appConfig'
import parse from 'html-react-parser'

const nameFields = (firstName = '', lastName = '', disabled = false) => {
  const prefilledFirstName =
    customerContainer?.profileResponse?.firstName ||
    cartContainer?.cartPrefilledData?.firstName ||
    ''
  const prefilledLastName =
    customerContainer?.profileResponse?.lastName ||
    cartContainer?.cartPrefilledData?.lastName ||
    ''
  return [
    {
      name: 'firstName',
      ariaLabel: 'firstName',
      columnType: {
        lg: '6',
      },
      defaultValue: firstName || prefilledFirstName || '',
      dataTestId: 'qa-first-name',
      disabled: disabled,
      maxLength: 50,
      placeholderKey: 'firstNamePlaceholder',
      customClassName: 'addressfield-wrapper',
    },
    {
      name: 'lastName',
      ariaLabel: 'lastName',
      columnType: {
        lg: '6',
      },
      defaultValue: lastName || prefilledLastName || '',
      dataTestId: 'qa-last-name',
      disabled: disabled,
      maxLength: 50,
      placeholderKey: 'lastNamePlaceholder',
      customClassName: 'addressfield-wrapper addressBook-mobile-wrapper',
    },
  ]
}

const contactInfoField = () => {
  return [
    {
      type: 'div',
      name: 'contactInformation',
      columnType: {
        lg: '6',
      },
      customClassName: `mt-2 contact-info`,
    },
  ]
}

const emailField = (
  email = '',
  isToChangeColType = false,
  isToDisable = false
) => {
  const prefilledEmail =
    customerContainer?.profileResponse?.email ||
    cartContainer?.cartPrefilledData?.email ||
    ''
  return {
    name: 'email',
    dataTestId: 'qa-email',
    placeholderKey: 'emailAddressPlaceHolder',
    defaultValue: email || prefilledEmail || '',
    columnType: {
      lg: isToChangeColType ? '12' : '6',
    },
    maxLength: 254,
    customClassName: 'addressfield-wrapper email-field',
    disabled: isToDisable,
  }
}

const addressLine1Field = (
  addressLine1 = '',
  isAddressLine1Disabled = false,
  label = '',
  placeholder = ''
) => {
  return [
    {
      name: 'addressLine1',
      placeholderKey: placeholder ? placeholder : 'addressLine1PlaceHolder',
      labelKey: label ? label : 'addressLine1',
      columnType: {
        lg: '12',
      },
      defaultValue: addressLine1 || '',
      dataTestId: 'qa-address1',
      maxLength: 250,
      disabled: isAddressLine1Disabled,
      customClassName: 'addressfield-wrapper',
    },
  ]
}

const addressLine2Field = (
  addressLine2 = '',
  isAddressLine2Disabled = false,
  label = '',
  placeholder = ''
) => {
  return [
    {
      name: 'addressLine2',
      ariaLabel: 'addressLine2',
      placeholderKey: placeholder ? placeholder : 'addressLine2PlaceHolder',
      labelKey: label ? label : 'addressLine2',
      columnType: {
        lg: '12',
      },
      defaultValue: addressLine2 || '',
      dataTestId: 'qa-address2',
      maxLength: 250,
      disabled: isAddressLine2Disabled,
      customClassName: 'addressfield-wrapper',
    },
  ]
}

const cityField = (
  city = '',
  colType = '12',
  label = '',
  isToDisable = false,
  placeholder = 'cityPlaceHolder'
) => {
  return [
    {
      name: 'city',
      ariaLabel: 'city',
      labelKey: label ? label : 'city',
      columnType: {
        lg: colType ? colType : '12',
      },
      defaultValue: city || '',
      dataTestId: 'qa-city',
      placeholderKey: placeholder ? placeholder : 'cityPlaceHolder',
      maxLength: 189,
      disabled: isToDisable,
      customClassName: 'addressfield-wrapper',
    },
  ]
}

const wardField = (wards = [], colType = '12', label = '', ward = '') => {
  return [
    {
      name: 'wardList',
      ariaLabel: 'wardList',
      labelKey: label || 'wardList',
      type: 'select',
      options: wards,
      columnType: {
        lg: colType || '12',
      },
      defaultValue: ward,
      dataTestId: 'qa-ward',
      disabled: true,
      customClassName: 'addressfield-wrapper',
    },
  ]
}

const cityFieldSelect = (
  cities = [],
  city = '',
  isCityDisabled = false,
  colType = '',
  label = ''
) => {
  return {
    name: 'city',
    ariaLabel: 'city',
    labelKey: label ? label : 'city',
    type: 'select',
    options: cities,
    columnType: {
      lg: colType ? colType : '6',
    },
    defaultValue: city,
    dataTestId: 'qa-city',
    placeholderKey: 'cityPlaceHolder',
    disabled: isCityDisabled,
    customClassName: 'addressfield-wrapper',
  }
}

const districtField = (
  districts = [],
  district = '',
  label = '',
  type = 'select',
  col = '6',
  isDistrictDisabled = false
) => {
  return {
    name: 'districtList',
    ariaLabel: 'districtList',
    labelKey: label ? label : 'district',
    type: type,
    options: districts ? districts : '',
    columnType: {
      lg: col,
    },
    defaultValue: district,
    dataTestId: 'qa-district',
    disabled: isDistrictDisabled,
    customClassName: 'addressfield-wrapper',
    placeholderKey: 'districtListPlaceHolder',
  }
}

const districtInput = (
  district = '',
  customClassName = '',
  districtInputColType = '6',
  label = '',
  placeholder = 'suburbPlaceHolder'
) => {
  return {
    name: 'district',
    ariaLabel: 'district',
    labelKey: label ? label : 'district',
    placeholderKey: placeholder ? placeholder : 'suburbPlaceHolder',
    columnType: {
      lg: districtInputColType ? districtInputColType : '6',
    },
    customClassName,
    defaultValue: district,
    maxLength: 189,
    dataTestId: 'qa-district-input',
  }
}

const stateField = (
  states = [],
  state = '',
  isStateDisabled = false,
  colType = '',
  label = ''
) => {
  const isStateExist = states.find(
    availState => availState?.value?.toLowerCase() === state?.toLowerCase()
  )

  if (!isStateExist) {
    states.push({ label: state, value: state })
  }

  return {
    name: 'stateList',
    ariaLabel: 'stateList',
    labelKey: label ? label : 'stateList',
    type: 'select',
    options: states,
    columnType: {
      lg: colType ? colType : '6',
    },
    defaultValue: state,
    dataTestId: 'qa-state',
    disabled: isStateDisabled,
    customClassName: 'addressfield-wrapper accountProfile-mobile-wrapper',
  }
}
const stateFieldInput = (
  state = '',
  isStateDisabled = false,
  colType = '',
  label = '',
  placeholder = ''
) => {
  return {
    name: 'state',
    ariaLabel: 'state',
    labelKey: label ? label : 'state',
    columnType: {
      lg: colType ? colType : '6',
    },
    placeholderKey: placeholder ? placeholder : 'statePlaceHolder',
    defaultValue: state,
    dataTestId: 'qa-state',
    disabled: isStateDisabled,
  }
}

const countriesField = (countries = [], selectedCountry = '') => {
  let countryObj = {}
  countryData()?.find(element => {
    if (element.value === selectedCountry) {
      let label = element.label.split('(')
      countryObj = {
        label: label[0],
        value: element.value,
      }
    }
  })
  /**
   * @info
   * Current countriesList.js file includes only 2 countries (US and Canada).
   * This will handle if a selected country different from US and Canada (for instance, Iceland account published from SAP - CX121-3206).
   */
  if (
    selectedCountry !== '' &&
    (selectedCountry !== 'US' ||
      selectedCountry !== 'CA' ||
      selectedCountry !== 'Canada')
  ) {
    const otherCountry = allCountriesJson({
      countryCode: countryObj?.value,
    })

    if (otherCountry) {
      countries.push(countryObj)
    }
  }

  return {
    name: 'country',
    type: 'select',
    options: countries,
    disabled: true,
    columnType: {
      lg: isExpressCheckout() ? '12' : '6',
    },
    maxLength: 50,
    dataTestId: 'qa-country',
    defaultValue: selectedCountry || storeContainer.storeIDValue,
    ariaLabel: 'country',
    customClassName: 'addressfield-wrapper',
  }
}

const provinceField = (province = '', isToDisable = false) => {
  return {
    name: 'province',
    ariaLabel: 'province',
    columnType: {
      lg: '6',
    },
    defaultValue: province || '',
    dataTestId: 'qa-province',
    placeholderKey: 'provincePlaceHolder',
    maxLength: 189,
    disabled: isToDisable,
  }
}

const postalCodeField = (
  zip = '',
  customClassName = 'addressfield-wrapper zipcode-mobile',
  removeMaxLen = false,
  postalColType = '6',
  postalCodeLength = 13,
  isPostalCodeDisabled = false,
  label = ''
) => {
  return {
    name: 'postalCode',
    ariaLabel: 'postalCode',
    labelKey: label ? label : 'postalCode',
    columnType: {
      lg: postalColType ? postalColType : '6',
    },
    customClassName: 'addressfield-wrapper zipcode-mobile',
    defaultValue: zip || '',
    dataTestId: 'qa-code',
    placeholderKey: 'postalCodePlaceHolder',
    maxLength: !removeMaxLen && postalCodeLength,
    disabled: isPostalCodeDisabled,
  }
}
const zipCodeField = (
  zip = '',
  removeMaxLenForZip = false,
  colType = '',
  iszipCodeDisabled = false
) => {
  return {
    name: 'zip',
    ariaLabel: 'zip',
    disabled: iszipCodeDisabled,
    columnType: {
      lg: colType ? colType : '6',
    },
    defaultValue: zip || '',
    dataTestId: 'qa-code',
    placeholderKey: 'zipCodePlaceHolder',
    maxLength: !removeMaxLenForZip && 10,
    customClassName:
      'addressfield-wrapper accountProfile-mobile-wrapper zipcode-mobile',
  }
}

const phoneField = async props => {
  const {
    localCountry = '',
    phone = '',
    isToChangeColType = false,
    customClassName = '',
    // selectedCountry = '',
    isToDisable = false,
    isOptionalPhoneNumberField = false,
    addressType = '',
  } = props || {}
  /**
   * @todo
   * !!! Phone Number Validation Needs to be Checked
   */
  let phoneNumber =
    phone || customerContainer?.profileResponse?.phoneNumber || ''
  let phoneNumberCode = phoneNumber
  let inputCode = countryData()?.find(
    element => element.value === localCountry
  )?.value

  if (phoneNumber) {
    const parsedPhoneNumber = await getParsedPhoneNumber(phoneNumber)
    const phoneCountry = parsedPhoneNumber?.phoneCountry

    inputCode = phoneCountry || localCountry
    phoneNumberCode = parsedPhoneNumber?.phoneNumberCode
    accountsContainer.countryDropdownValue = inputCode
  }

  let phoneCountryCodeOptions = []
  countryData()?.forEach(val => phoneCountryCodeOptions.push(val))

  const enableLiveEvent = convertToBoolean(getLiveEventStatus())
  if (enableLiveEvent) {
    // Push China country code in phone codes list
    phoneCountryCodeOptions.push({
      label: i18nTranslate('country.china', 'China') + '(+86)',
      value: 'CN',
      displayValue: 'CN(+86)',
    })

    phoneCountryCodeOptions.push({
      label: i18nTranslate('country.KR', 'South Korea') + '(+82)',
      value: 'KR',
      displayValue: 'KR(+82)',
    })

    phoneCountryCodeOptions.push({
      label: i18nTranslate('country.AM', 'Armenia') + '(+374)',
      value: 'AM',
      displayValue: 'AM(+374)',
    })

    phoneCountryCodeOptions.push({
      label: i18nTranslate('country.PR', 'Puerto Rico') + '(+1)',
      value: 'PR',
      displayValue: 'PR(+1)',
    })

    phoneCountryCodeOptions.push({
      label: i18nTranslate('country.VE', 'Venezuela') + '(+58)',
      value: 'VE',
      displayValue: 'VE(+58)',
    })

    phoneCountryCodeOptions.push({
      label: i18nTranslate('country.MC', 'Monaco') + '(+377)',
      value: 'MC',
      displayValue: 'MC(+377)',
    })

    phoneCountryCodeOptions.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
  }

  return [
    {
      name: 'phoneCountryCode',
      type: 'select',
      labelKey: isOptionalPhoneNumberField
        ? 'optionalPhoneCountryCode'
        : 'phoneCountryCode',
      options: [...phoneCountryCodeOptions],
      columnType: {
        lg: `${
          isExpressCheckout() ||
          (application.width > 1024 && application.width < 1080)
            ? '6'
            : '4'
        }`,
      },
      dataTestId: 'qa-dropdown-countrycode',
      defaultValue: inputCode,
      disabled: isToDisable,
      ariaLabel: isOptionalPhoneNumberField
        ? 'optionalPhoneCountryCode'
        : 'phoneCountryCode',
      phoneNumberDropDown: true,
      customClassName:
        'addressfield-wrapper addressBook-mobile-wrapper pay-countrycode subs-countrycode',
    },
    {
      name: 'phone',
      ariaLabel: isOptionalPhoneNumberField
        ? 'optionalPhone'
        : addressType === 'checkout'
        ? 'checkoutPhone'
        : 'phone',
      labelKey: isOptionalPhoneNumberField
        ? 'optionalPhone'
        : addressType === 'checkout'
        ? 'checkoutPhone'
        : 'phone',
      columnType: {
        lg: `${
          isExpressCheckout()
            ? '12'
            : application.width > 1024 && application.width < 1080
            ? '6'
            : '8'
        }`,
      },
      // customClassName: `${
      //   application.width > 1024 && application.width < 1080 ? 'pt-2' : ''
      // }`,
      dataTestId: 'qa-telephone',
      placeholderKey: 'phoneNoPlaceHolder',
      defaultValue: phoneNumberCode,
      maxLength: 15,
      customClassName: 'addressfield-wrapper addressBook-mobile-phno',
      type: 'mask',
      disabled: isToDisable,
    },
  ]
}

const shippingInstructionsField = (deliveryInstruction = '') => {
  return [
    {
      name: 'shippingInstructions',
      dataTestId: 'qa-instructions',
      defaultValue: deliveryInstruction || '',
      placeholderKey: '',
      columnType: {
        lg: '12',
      },
      maxLength: 254,
      ariaLabel: 'instructions',
    },
  ]
}

const defaultAddressCheckboxField = (index = 0, isDefault = false) => {
  return [
    {
      name: 'setDefaultAddress',
      type: 'checkbox',

      columnType: {
        lg: '6',
      },
      customClassName: 'address-default-checkbox my-2',
      dataTestId: 'qa-default-address',
      isToHideLabel: true,
      inlineLabelKey: 'setDefaultAddress',
      disabled: isDefault,
      defaultChecked: isDefault,
      customAttributes: {
        customId: index,
      },
    },
  ]
}
const defaultSubscriptionShippingAddressCheckboxField = (
  index = 0,
  isDefault = false
) => {
  return [
    {
      name: 'setDefaultShippingAddress',
      type: 'checkbox',

      columnType: {
        lg: '12',
      },
      customClassName: 'address-default-checkbox my-2',
      dataTestId: 'qa-default-address',
      isToHideLabel: true,
      inlineLabelKey: 'setDefaultShippingAddress',
      disabled: isDefault,
      defaultChecked: isDefault,
      customAttributes: {
        customId: index,
      },
    },
  ]
}

const requiredNoteField = () => {
  return [
    {
      name: 'required_note',
      type: 'div',
      columnType: {
        lg: '12',
      },
      customClassName: 'my-1 required-note',
    },
  ]
}
const handleVIPCheckForGuestflow = event => {
  setSessionStorage('vipCheckboxStatus', event?.target?.checked)
}

const handlePromotionSmsCheckForGuest = event => {
  const eventTargetChecked = event?.target?.checked
  setSessionStorage('smsOptInStatus', eventTargetChecked)
}

const handlePromotionEmailCheckForGuest = event => {
  const eventTargetChecked = event?.target?.checked
  setSessionStorage('emailOptInStatus', eventTargetChecked)
}

const guestVIPCheckboxField = loadContentFromCS => {
  const vipCheckboxStatus = getSessionStorage('vipCheckboxStatus') || false
  const vipCheckboxInput = [
    {
      name: 'signupBrandRepresentative',
      type: 'checkbox',
      columnType: {
        lg: '12',
      },
      customClassName: 'vip-checkbox my-2',
      dataTestId: 'qa-vip-experience-checkbox',
      onValueChange: handleVIPCheckForGuestflow,
      isToHideLabel: true,
      inlineLabelKey: 'signupBrandRepresentative',
      disabled: false,
      defaultChecked: vipCheckboxStatus,
      ariaLabel: 'signupBrandRepresentative',
    },
  ]

  if (loadContentFromCS) {
    vipCheckboxInput[0].label = (
      <>
        {contentFromCS?.optinBrandRepresentative
          ? contentFromCS?.optinBrandRepresentative
          : "I'd like to be contacted by a Nu Skin Brand Affiliate to learn more about Nu Skin products and services."}

        <a
          name="link"
          href="#"
          tabIndex={0}
          target="_blank"
          rel="noopener noreferrer"
          onClick={event => {
            event.preventDefault()
            modalState.setModalMessage(
              contentFromCS?.optInBrandRepresentativeInfo ? (
                <div className="learnMoreCont">
                  {parse(contentFromCS?.optInBrandRepresentativeInfo)}
                </div>
              ) : (
                <div>
                  {`Nu Skin will provide your contact details and order history to an 
                  independent brand representative, who may reach out to you with more 
                  information about our exclusive beauty and wellness products and special 
                  offers. You can withdraw your consent at any time. See our`}{' '}
                  <a
                    name="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    tabIndex={0}
                    href="https://legal.nuskin.com/privacy.html">
                    <u> {'Privacy Notice'}</u>
                  </a>{' '}
                  {'to learn more.'}
                </div>
              ),
              i18nTranslate('catalog.OKButton', 'Ok', {
                nameSpace: 'ssr-resource',
              })
            )
          }}>
          {' '}
          {<QuestionCricle size="1.5rem" color="#0049B0" />}
        </a>
      </>
    )
  }

  return vipCheckboxInput
}
const guestPromotionCheckbox = () => {
  return [
    {
      name: 'guestPromotionCheckbox',
      type: 'div',
      columnType: {
        lg: '12',
      },
      customClassName: 'vip-checkbox my-2 promotionalCumminication',
    },
  ]
}

const guestPromotionSmsCheckbox = () => {
  const smsOptInStatus = getSessionStorage('smsOptInStatus') || false
  return [
    {
      name: 'smsOptIn',
      type: 'checkbox',
      columnType: {
        lg: '12',
      },
      customClassName: `${'vip-checkbox my-2 ml-4 promotionalCumminicationSms'}`,
      dataTestId: 'qa-vip-promotional-sms-checkbox',
      onValueChange: event => {
        handlePromotionSmsCheckForGuest(event)
      },
      isToHideLabel: true,
      inlineLabelKey: 'smsOptIn',
      disabled: false,
      defaultChecked: smsOptInStatus,
      ariaLabel: 'smsOptIn',
    },
  ]
}
const guestPromotionEmailCheckbox = () => {
  const emailOptInStatus = getSessionStorage('emailOptInStatus') || false
  return [
    {
      name: 'emailOptIn',
      type: 'checkbox',
      columnType: {
        lg: '12',
      },
      customClassName: `${'vip-checkbox my-2 ml-4 promotionalCumminication'}`,
      dataTestId: 'qa-vip-promotional-email-checkbox',
      onValueChange: event => {
        handlePromotionEmailCheckForGuest(event)
      },
      isToHideLabel: true,
      inlineLabelKey: 'emailOptIn',
      disabled: false,
      defaultChecked: emailOptInStatus,
      ariaLabel: 'emailOptIn',
    },
  ]
}

const getGuestAddressDetails = () => {
  return cartContainer?.cartResponse?.deliveryDetails || {}
}

export {
  nameFields,
  contactInfoField,
  emailField,
  addressLine1Field,
  addressLine2Field,
  cityField,
  cityFieldSelect,
  districtField,
  districtInput,
  stateField,
  stateFieldInput,
  provinceField,
  postalCodeField,
  countriesField,
  zipCodeField,
  phoneField,
  shippingInstructionsField,
  defaultAddressCheckboxField,
  defaultSubscriptionShippingAddressCheckboxField,
  requiredNoteField,
  wardField,
  guestVIPCheckboxField,
  getGuestAddressDetails,
  guestPromotionCheckbox,
  guestPromotionSmsCheckbox,
  guestPromotionEmailCheckbox,
}
