import React, { Fragment } from 'react'
import { i18nTranslate, getCountryConfig } from 'src/utils'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import {
  APPConfig,
  REGION_SELECTOR_URL,
  REGION_SELECTOR_URL_EVENT,
  deleteFromLocalStorage,
} from 'config/appConfig'
import {
  setLocalStorage,
  getLocalStorage,
  IS_BROWSER,
  checkMysiteOrPersonalOffer,
  getLiveEventStatus,
} from 'src/utils'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { storeContainer } from 'src/models/Store'
import NuskinDropDownArrowIcon from './NuskinDropDownArrowIcon'
import './styles.scss'

@observer
class LocaleModal extends React.Component {
  @observable curLocaleCode

  constructor(props) {
    super(props)
    const curLocaleFromUrl = getLocaleCodeFromUrl({
      locationProps: props,
      at: 'pathParam',
      defaultLocale: 'en_US',
      // todo @vetri @anto
      // in header dropdown,
      // locale is undefined, when url has no locale
      // so added en_US for unblocking
      isReverseType: true,
    })

    this.curLocaleCode =
      curLocaleFromUrl || APPConfig.getAppConfig().defaultLocale
  }

  handleClick = (value, at = 'pathParam') => {
    /**
     * @note
     * adding this safer side code, for redirection
     * locale overwriting at storages, cookie and window.APP_CONFIG
     */
    const newLocale = (() => {
      let val = (value.includes('-') ? value.replace('-', '_') : value)?.split(
        '_'
      )
      return {
        ls: [val[0], val[1].toUpperCase()].join('_'),
        cookie: [val[0], val[1].toUpperCase()].join('-'),
        pathParam: `${val[1]}/${val[0]}`.toLowerCase(),
      }
    })()

    const localeFormatForCookie = newLocale.cookie
    const oldPathParam = this.curLocaleCode
      .split('_')
      .reverse()
      .join('/')
      .toLowerCase()

    this.curLocaleCode = newLocale.ls

    if (IS_BROWSER) {
      setLocalStorage('defaultLocale', newLocale.ls)
      setLocalStorage('locale', newLocale.cookie)

      let urlToNavigate = ''
      if (at === 'pathParam') {
        const localeAsPathParam = localeFormatForCookie
          ?.split('-')
          ?.reverse()
          ?.join('/')
          ?.toLowerCase()

        const urlObj = new URL(window.location.href)

        urlToNavigate = `${
          urlObj.pathname === '/'
            ? localeAsPathParam
            : urlObj.pathname.replace(oldPathParam, newLocale.pathParam)
        }`
        if (urlObj?.search) {
          urlToNavigate += urlObj?.search
        }
      } else if (at === 'queryParam') {
        /**
         * @todo
         * !!! if its queryParam
         */
        // urlToNavigate = `${pageNames.home}?locale=${newLocale}`
      }

      deleteFromLocalStorage('subscriptionNextOrderSelectedDate')
      deleteFromLocalStorage('scheduleOrderSelectedDate')

      window.location.href = urlToNavigate
    }
  }

  getLocalLabel = (lcode, localeListObj = {}, isForTitle = false) => {
    const [lang, country] = lcode?.split('_') || []

    function convertLanguage(name) {
      switch (name) {
        case 'English':
          return 'English'
        case 'French':
          return 'Français'
        case 'Chinese':
          return '中文'
        case 'Spanish':
          return 'Español'
        case 'Vietnamese':
          return 'Tiếng Việt'
        case 'Japanese':
          return '日本語'
        default:
          return ''
      }
    }
    const { currency = '', name = '' } =
      localeListObj[`${lang}_${country.toUpperCase()}`] || {}
    if (isForTitle) {
      return `${currency} | ${convertLanguage(name)}`
    } else {
      return convertLanguage(name)
    }
  }

  renderDDLocaleItem = (key, localeList, activeLocaleCode) => {
    if (activeLocaleCode === key) {
      return <></>
    }

    return (
      <Dropdown.Item
        onSelect={() => {
          this.handleClick(localeList[key]?.value || '')
        }}
        data-testid={`qa-locale-drop-value-${this.getLocalLabel(
          key,
          localeList,
          false
        )}`}>
        {this.getLocalLabel(key, localeList, true)}
      </Dropdown.Item>
    )
  }

  render() {
    const { className = '', isNuskinHeader = false } = this.props

    const countryConfig = getCountryConfig()

    // const curLocaleCode =
    //   storeContainer.activeLocale || getLocalStorage('defaultLocale')

    // console.log(`LocalModal >>> curLocaleCode >>> `, {
    //   curLocaleCode,
    //   node: global?.STORE_CONFIG.activeLocale,
    // })

    const currentRegion =
      storeContainer.currentRegion || getLocalStorage('currentRegion')
    const curRegionLocales = countryConfig[currentRegion] || {}

    const title = this.getLocalLabel(this.curLocaleCode, curRegionLocales, true)

    let localModalClassName = `header-language-text align-items-center ${className}`

    if (!isNuskinHeader) {
      localModalClassName += ' d-flex'
    }

    const localeView = Object.keys(curRegionLocales).map((key, index) => {
      return (
        <Fragment key={`locale-dd-${index}`}>
          {this.renderDDLocaleItem(key, curRegionLocales, this.curLocaleCode)}
        </Fragment>
      )
    })

    // console.log('Local Modal >>> title >>> ', {
    //   title,
    //   curLocaleCode: this.curLocaleCode,
    //   defLocale: APPConfig.getAppConfig().defaultLocale,
    //   storeLocale: storeContainer.activeLocale,
    // })
    let contextText = ''

    if (IS_BROWSER) {
      contextText = checkMysiteOrPersonalOffer()
      // const shoppingContext = getLocalStorage('shoppingContext') || {}
      // contextText = shoppingContext?.context || ''
    }

    const enableLiveEvent = getLiveEventStatus()
    const regionSelector =
      enableLiveEvent === 'true'
        ? REGION_SELECTOR_URL_EVENT
        : REGION_SELECTOR_URL

    return (
      <div className="language-container d-flex mr-1">
        <div className={`${!isNuskinHeader && 'row'}`}>
          {!isNuskinHeader && (
            <div>
              <label
                id="Language"
                aria-labelledby="Language"
                className="language-label">
                Language:
              </label>
            </div>
          )}
          <div className={localModalClassName}>
            <Dropdown
              className="language-dropdown"
              data-testid="qa-locale-dropdown">
              <DropdownButton
                variant="none"
                title={
                  isNuskinHeader ? (
                    <>
                      {title}
                      <NuskinDropDownArrowIcon className="nu-dropdown-icon" />
                    </>
                  ) : (
                    title
                  )
                }
                data-testid={`qa-options-${this.getLocalLabel(
                  this.curLocaleCode,
                  curRegionLocales,
                  false
                )}`}
                className={`cost-center-dropdownbutton-wrapper ${
                  isNuskinHeader &&
                  'border locale-dropdown-rounded nu-dropdown-toggle'
                }`}
                bsPrefix={`${isNuskinHeader ? 'py-0 nu-dropdown-button' : ''}`}>
                {localeView}
                {contextText == '' && enableLiveEvent !== 'true' && (
                  <>
                    <Dropdown.Divider />
                    <a
                      className="dropdown-item"
                      href={regionSelector}
                      data-testid="qa-locale-drop-value-other">
                      {i18nTranslate(
                        'selectAnotherRegion',
                        'Select another region',
                        {
                          nameSpace: 'ssr-resource',
                        }
                      )}
                    </a>
                  </>
                )}
              </DropdownButton>
            </Dropdown>
          </div>
        </div>
      </div>
    )
  }
}

export { LocaleModal }
export default LocaleModal
