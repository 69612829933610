import React from 'react'
import { i18nTranslate } from 'src/utils'

class PickUpAddress extends React.Component {
  render() {
    const { address = {}, email = '' } = this.props || {}
    const {
      address1 = '',
      address2 = '',
      address3 = '',
      city = '',
      state = '',
      postalCode = '',
    } = address
    return (
      <div data-testid="qa-address" className="event-checkout-pickupaddress">
        <div className="event-pickupaddress-label">
          {i18nTranslate('event.pickupAdddressLabel', 'Order Pick-up')}
        </div>
        <div className="event-pickupaddress-line">
          {i18nTranslate(
            'event.pickupEventAddress',
            'Salt Palace Convention Center'
          )}
        </div>
        {address1 ? (
          <div className="event-pickupaddress-line">{address1}</div>
        ) : null}
        <div className="event-pickupaddress-line">
          {city}, {state} {postalCode}
        </div>
        <div className="event-pickupaddress-email">{email}</div>
        <div className="event-pickupaddress-confirmation">
          {i18nTranslate(
            'event.pickupAdddressConfirmaiton',
            'Order confirmation will be sent to this email address'
          )}
        </div>
      </div>
    )
  }
}

export { PickUpAddress }
