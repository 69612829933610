import React from 'react'
import { Button, Card, Modal, ButtonGroup, Row } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import { IoMdClose as CloseIcon } from 'react-icons/io'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import {
  FiToggleLeft as ToggleOff,
  FiToggleRight as ToggleON,
} from 'react-icons/fi'
import {
  isLoyEnableInProf,
  isBrandAffiliateTypeProfile,
  isAllowedRewardTypeForOptIn,
  switchActive,
  setSwitchActive,
  isBuyerUser,
  checkoutOptBtnUpdate,
  setCheckoutOptBtnUpdate,
} from './fixture'
import { LoyaltyPoint } from 'src/views/components/CheckoutAccordions/LoyaltyPoint'
import {
  cartContainer,
  customerContainer,
  checkoutContainer,
  storeContainer,
  loyaltyContainer,
} from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import enjoyRewards from 'src/images/enJoy_nuskinrewards_white1.png'
import { FormInputLabels } from 'src/deps'
import './styles.scss'
import { trackEnhancedCheckout } from 'src/utils'

@observer
class LoyaltyForm extends React.Component {
  @observable show = false
  @observable showCheckoutLoyaltySection = false
  @observable hideOptBtn = false
  @observable optBtnUpdate = false
  @observable loyaltyPointRender = false
  @observable showTermsPopup = false
  @observable termsAgreed = false
  @observable userDetails = {}
  @observable getOpenLoyaltyPoints = loyaltyContainer.getOpenLoyaltyPoints()
  @observable isLoyaltyCallIsInProgress = false

  deletePayments = async () => {
    if (cartContainer?.cartResponse?.payments) {
      await checkoutContainer.deletePayment(this.loyaltyPointShow)
    } else {
      this.loyaltyPointRender = true
    }
    if (cartContainer?.cartResponse?.loyaltyDetails) {
      await checkoutContainer.deleteDiscountReward()
    }
  }

  loyaltyPointShow = async () => {
    this.loyaltyPointRender = true
  }

  async componentDidMount() {
    await this.deletePayments()
    const response = await customerContainer.getProfile()
    this.userDetails = response
  }

  handleClick = async () => {
    if (switchActive) {
      this.show = true
    } else {
      // commented for loyalty CR - CX121-2283 SL.No: 1 & 2
      // this.showTermsPopup = true
      this.show = false
    }
  }

  handlePopupClose = () => {
    this.show = false
    setSwitchActive(true)
  }

  handleOptOut = () => {
    setSwitchActive(false)
    this.show = false
    customerContainer.updateRewardsLoyaltyOptIn(switchActive)
  }

  handleNext = () => {
    this.isLoyaltyCallIsInProgress = true
    const { onContinue, title } = this.props
    const products = cartContainer?.cartResponse?.items || []
    trackEnhancedCheckout({
      type: 'checkout',
      action: { step: '4', option: title },
      products: products,
    })
    if (!this.optBtnUpdate && !isLoyEnableInProf()) {
      onContinue()
    } else if (
      (this.optBtnUpdate || checkoutOptBtnUpdate) &&
      !this.getOpenLoyaltyPoints
    ) {
      onContinue()
    } else {
      this.showCheckoutLoyaltySection = true
    }
    this.isLoyaltyCallIsInProgress = false
  }

  renderModalPopup = () => {
    return (
      <Modal
        size="xl"
        show={this.show}
        className="loyalty-Popup"
        onHide={this.handlePopupClose}>
        <div>
          <Modal.Body className="border-0 px-0 pt-0">
            <Row
              noGutters
              className="loyaltyPopupHead flex-column flex-md-row justify-content-center w-50 mx-auto text-center">
              <span className="mt-4">
                {i18nTranslate(
                  'loyalty.loyaltyPopupHead',
                  'If you Opt-Out, you will loose all the earned points and will not be able to Opt-In again. Are you sure to Opt-Out?'
                )}
              </span>
            </Row>
            <div
              className="d-flex justify-content-end"
              style={{ position: 'absolute', top: 0, right: 0 }}>
              <Button
                aria-label={i18nTranslate('loyalty.closeIconPopup', 'Close')}
                onClick={this.handlePopupClose}
                variant="light"
                className="bg-transparent close-icon">
                <CloseIcon size="1rem" />
              </Button>
            </div>
            <Row noGutters>
              <ButtonGroup className="mx-auto w-75">
                <Button
                  onClick={this.handleOptOut}
                  variant="outline-primary"
                  className="d-flex m-2 w-100 justify-content-center">
                  {i18nTranslate('loyalty.loyaltyPopupYes', 'Yes')}
                </Button>
                <Button
                  onClick={this.handlePopupClose}
                  variant="primary"
                  className="d-flex m-2 w-100 justify-content-center">
                  {i18nTranslate('loyalty.loyaltyPopupNo', 'No')}
                </Button>
              </ButtonGroup>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    )
  }

  handleLoyaltyOptInClick = async () => {
    const { isCheckout } = this.props

    setSwitchActive(!switchActive)
    this.optBtnUpdate = true

    const response = await customerContainer.updateRewardsLoyaltyOptIn(
      switchActive
    )

    if (customerContainer.isSuccessResponse(response)) {
      if (isCheckout) {
        this.hideOptBtn = true
        setCheckoutOptBtnUpdate(true)
      }
    } else {
      this.optBtnUpdate = false
      if (isCheckout) {
        this.hideOptBtn = false
        setCheckoutOptBtnUpdate(false)
      }
    }

    this.termsAgreed = false
    this.showTermsPopup = false
  }

  renderTermsAndConditionModal = () => {
    /**
     * @info
     * GTM Datalayer -  Send event when Privacy (Loyalty) terms&conditions modal opens.
     */
    const { myaccountprivacy } = pageNames
    if (
      this.props.hasOwnProperty('parentComponentName') &&
      this.props.parentComponentName === 'LoyaltyCard'
    ) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'NuskinProfilePrivacyDialog',
          pagePath: myaccountprivacy,
          loyaltyOptInStatus: switchActive,
          userDetails: {
            userId: this.userDetails?.id || '',
            email: this.userDetails?.email || '',
            firstName: this.userDetails?.firstName || '',
            lastName: this.userDetails?.lastName || '',
          },
          pageTitle: 'Privacy',
          platform: 'equinox',
        },
      })
    }
    const formInputLabels = new FormInputLabels()

    return (
      <Modal
        className="body-container mt-4"
        show={this.showTermsPopup}
        animation={true}
        onHide={() => {
          this.termsAgreed = false
          this.showTermsPopup = false
        }}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="terms-modal-title">
              <div className="img-loyalty">
                <img src={enjoyRewards} alt="" />
              </div>
              <div className="terms-user-heading">
                {i18nTranslate('nuskinEnjoyRewards.messageTitle', 'Hey ')}
                <span>{this.userDetails.firstName},</span>
                {i18nTranslate(
                  'nuskinEnjoyRewards.message',
                  'are you ready to join?'
                )}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-modal-body">
            <div className="custom-modal-body-heading">
              {i18nTranslate('nuskinEnjoyRewards.title', 'Nu Skin Rewards', {
                nameSpace: 'ssr-resource',
              })}
            </div>
            <div className="custom-modal-body-text">
              {i18nTranslate(
                'nuskinEnjoyRewards.context',
                `Nu Skin Rewards is a rewards based program based on how
                you spend. The more you shop, the more rewards you'll discover!`
              )}
            </div>
            <div>
              <div>
                <input
                  type="checkbox"
                  id="agree"
                  onChange={() => {
                    this.termsAgreed = !this.termsAgreed
                  }}
                />
                <label htmlFor="agree">
                  {i18nTranslate(
                    'nuskinEnjoyRewards.terms',
                    'I have read, understand and agree to the'
                  )}{' '}
                </label>
                <span
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.open(
                        formInputLabels.retailCustomerLinks
                          ?.enjoyTermsAndConsitions
                      )
                    }
                  }}>
                  <b className="terms-text-link">
                    {i18nTranslate(
                      'nuskinEnjoyRewards.termsCondition',
                      'Terms and Conditions.'
                    )}
                  </b>
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              this.termsAgreed = false
              this.showTermsPopup = false
            }}>
            Cancel
          </Button>
          <Button
            disabled={!this.termsAgreed}
            variant="primary"
            onClick={this.handleLoyaltyOptInClick}>
            Opt in
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  renderOptInOptOutSection = () => {
    const {
      label = '',
      dataTestId = 'loyalty-rewards',
      offTitle,
      onTitle,
      optOutTxt,
      optInTxt,
      brandAffiliateUserTxt,
      buyerUserTxt,
      isCheckout,
      isRewardsCredits,
    } = this.props || {}

    const classCont =
      isCheckout || isRewardsCredits ? 'checkoutClass' : 'profileClass'
    const hideOptBtn =
      this.hideOptBtn || checkoutOptBtnUpdate ? 'hideOptBtn' : ''

    const storeIdBasedOnCountry = storeContainer.storeIDValue

    let homeCountry = ''
    customerContainer?.profileResponse?.accounts?.properties?.forEach(
      element => {
        if (element.attributeId === 'countryCode') {
          homeCountry = element.value
        }
      }
    )
    if (homeCountry === 'CA') {
      homeCountry = 'Canada'
    }

    const hideMessage =
      homeCountry && homeCountry !== storeIdBasedOnCountry ? 'd-none' : ''

    setSwitchActive(isLoyEnableInProf())

    const title =
      isBrandAffiliateTypeProfile() && !isLoyEnableInProf()
        ? brandAffiliateUserTxt
        : isBuyerUser() && !isLoyEnableInProf()
        ? buyerUserTxt
        : switchActive
        ? onTitle
        : offTitle

    const buyerFlag =
      (isBrandAffiliateTypeProfile() || isBuyerUser()) && !isLoyEnableInProf()

    return (
      <div className={`${classCont} ${hideMessage}`}>
        <Card className="loyalty-card" data-testid={`${dataTestId}-container`}>
          <Card.Body>
            {label && (
              <Card.Title
                data-testid={`${dataTestId}-heading`}
                className="card-title heading-2">
                {label}
              </Card.Title>
            )}
            <div className="mb-3 mt-3">
              <span>{title}</span>
            </div>
            {!buyerFlag && (
              <>
                <Button
                  variant="light"
                  className={`loyalty-switch-icon bg-transparent border-0 p-0 ${hideOptBtn}`}
                  onClick={() => this.handleClick()}
                  disabled={
                    isBrandAffiliateTypeProfile() && !isLoyEnableInProf()
                  }>
                  {switchActive && (
                    <>
                      <span aria-label="checked"></span>
                      <ToggleON
                        viewBox={'0 0 35 24'}
                        fill="#0086A5"
                        stroke="#0086A5"
                        className="custom-class-name custom-on"
                        data-testId="qa-optOut-Yes"
                      />
                    </>
                  )}
                  {!switchActive && (
                    <>
                      <span aria-label="unchecked"></span>
                      <ToggleOff
                        viewBox={'0 0 35 24'}
                        fill="#c4c4c4"
                        stroke="#c4c4c4"
                        className="custom-class-name custom-off"
                        data-testid="qa-optOut-No"
                      />
                    </>
                  )}
                  <span className="optTxt">
                    {switchActive ? optInTxt : optOutTxt}
                  </span>
                </Button>
              </>
            )}
          </Card.Body>
        </Card>
        {isCheckout && (
          <Row className="justify-content-end" noGutters>
            <Button
              className="px-5 py-2 loyalty-rewards-continue btn-bg-blue"
              onClick={this.handleNext}
              disabled={this.isLoyaltyCallIsInProgress}
              data-testid="qa-loyalty-rewards-continue">
              {i18nTranslate('loyalty.loyaltyContinue', 'Continue')}
            </Button>
          </Row>
        )}
      </div>
    )
  }

  renderCheckoutLoyaltySection = () => {
    const { onContinue } = this.props
    return (
      <div>
        {this.loyaltyPointRender && (
          <LoyaltyPoint rem={cartContainer.getRedeemLoyaltyPoints()} />
        )}
        <Row className="justify-content-end" noGutters>
          <Button
            className="px-5 py-2 loyalty-rewards-continue btn-bg-blue"
            onClick={onContinue}
            data-testid="qa-loyalty-rewards-continue">
            {i18nTranslate('loyalty.loyaltyContinue', 'Continue')}
          </Button>
        </Row>
      </div>
    )
  }

  render() {
    const { isCheckout } = this.props

    return (
      <>
        {isAllowedRewardTypeForOptIn() ? (
          <>
            {isCheckout &&
            ((!this.optBtnUpdate &&
              isLoyEnableInProf() &&
              !checkoutOptBtnUpdate) ||
              isBrandAffiliateTypeProfile() ||
              this.showCheckoutLoyaltySection)
              ? this.renderCheckoutLoyaltySection()
              : isCheckout &&
                (!isLoyEnableInProf() ||
                  isBuyerUser() ||
                  (this.optBtnUpdate && isLoyEnableInProf()) ||
                  checkoutOptBtnUpdate)
              ? this.renderOptInOptOutSection()
              : !isCheckout && this.renderOptInOptOutSection()}
            {this.show && this.renderModalPopup()}
            {this.showTermsPopup && this.renderTermsAndConditionModal()}
          </>
        ) : (
          <>{isCheckout && this.renderCheckoutLoyaltySection()}</>
        )}
      </>
    )
  }
}

export { LoyaltyForm }
export default LoyaltyForm
