import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import VerificationInput from 'react-verification-input'
import { customerContainer, accountsContainer } from 'src/models'
import { Button } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { parsePhoneNumberField } from 'src/utils/signUpUtils'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { toastState } from 'src/views/components'
import './styles.scss'

@observer
class CodeVerification extends Component {
  @observable isError = false
  constructor(props) {
    super(props)

    this.state = {
      verificationCode: '',
      btnDisabled: true,
    }
  }

  handleVerificationCode = value => {
    this.setState({ verificationCode: value })
    this.isError = false
    if (value.length === 6) {
      this.setState({ btnDisabled: false })
    } else {
      this.setState({ btnDisabled: true })
    }
  }

  handleSubmit = async () => {
    this.setState({ btnDisabled: true })
    const phoneNumber = await this.props.profileSetPhoneNumber()
    const updatePhoneResponse = await customerContainer.updateVerifiedPhone(
      phoneNumber,
      this.state.verificationCode
    )

    if (customerContainer.isSuccessResponse(updatePhoneResponse)) {
      // toastState.setToastMessage(
      //   i18nTranslate('profile.updateSuccess', 'Changes saved successfully'),
      //   true
      // )
      await customerContainer.getProfile()
      this.props.setPageToShow('success')
      customerContainer.profileSetPhoneNumber = phoneNumber
    } else {
      if (updatePhoneResponse?.code === 'NXUSER_233') {
        this.isError = true
      } else {
        this.isError = false
        const defaultMessage = i18nTranslate(
          'verifyPhone.updateFailure',
          'Something went wrong, please try again'
        )
        toastState.setToastMessage(
          updatePhoneResponse?.message || defaultMessage,
          false
        )
      }
    }
    this.setState({ btnDisabled: false })
  }

  handleResendCode = async () => {
    this.setState({ btnDisabled: true })
    const phoneNumber = await this.props.profileSetPhoneNumber()
    let localValue = getLocaleCodeFromUrl() || ''
    localValue = localValue?.toLowerCase()?.split('_')
    const marketRegion = localValue?.[1]?.toUpperCase() || 'US'
    const locale = localValue?.[0] || 'en'
    const sendCodeResponse = await accountsContainer.sendPhoneVerificationCode(
      phoneNumber,
      locale
    )
    this.isError = false
    if (!accountsContainer.isSuccessResponse(sendCodeResponse)) {
      const defaultMessage = i18nTranslate(
        'verifyPhone.updateFailure',
        'Something went wrong, please try again'
      )
      toastState.setToastMessage(
        sendCodeResponse?.message || defaultMessage,
        false
      )
    } else {
      this.setState({ verificationCode: '' })
      toastState.setToastMessage(
        i18nTranslate(
          'verifyPhone.smsSuccess',
          'Code verification sent successfully'
        ),
        true
      )
    }
    //this.setState({ btnDisabled: false })
  }

  render() {
    return (
      <div className="code-verification">
        <h2 className="welcome-text">
          {i18nTranslate('phoneVerify.Thanks', 'Thanks')}{' '}
          <span className="name-highlight">
            {customerContainer?.setVerifyPhone?.name || ''}
          </span>
          ,
          <h2 className="check-text">
            {i18nTranslate('phoneVerify.checkText', 'check your texts!')}
          </h2>
        </h2>
        <h6 className="verification-text">
          {i18nTranslate(
            'PhoneNoVerify.Msg',
            'We texted you a verification code'
          )}
        </h6>
        <VerificationInput
          value={this.state.verificationCode}
          placeholder=""
          validChars="0-9"
          inputProps={{ inputMode: 'numeric' }}
          onChange={this.handleVerificationCode}
          classNames={{ character: 'verification-character' }}
          autoFocus={true}
        />
        <label
          className={`codeVerifyError text-danger ${
            this.isError && this.state.verificationCode ? '' : ' d-none'
          }`}>
          {i18nTranslate('codeVerifyError', 'Invalid verification code')}
        </label>
        <p className="valid-code">
          {i18nTranslate(
            'codeValidTime',
            'Codes are only valid for 10 minutes'
          )}
        </p>
        <Button
          className="resend-code btn-outline-primary border-0 p-0"
          //disabled={this.state.btnDisabled}
          onClick={() => {
            this.handleResendCode()
          }}>
          {i18nTranslate('phoneVerify.resend', 'Resend code')}
        </Button>
        <Button
          disabled={this.state.btnDisabled}
          className="confirm-btn"
          onClick={() => {
            this.handleSubmit()
          }}>
          {i18nTranslate('phoneVerify.confirm', 'Confirm')}
        </Button>
      </div>
    )
  }
}

export default CodeVerification
export { CodeVerification }
