import React from 'react'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import { Quantity } from 'src/views/components'
import { Row } from 'react-bootstrap'
import { cartContainer, customerContainer } from 'src/models'
import { APPConfig } from 'config/appConfig'
import { observer } from 'mobx-react'
import './styles.scss'

@observer
class V2ProductQuantity extends React.Component {
  totalTxt = ''

  constructor(props) {
    super(props)
  }

  render() {
    const { productCount, circularIcons, hideText = false } = this.props
    const product = this.props?.product
    const productId = this.props?.product?.productId
    const cartItems = cartContainer?.cartResponse?.items || []
    const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99
    let maxProductQty
    const productQualificationResponse =
      customerContainer?.qualificationResponse?.qualification?.filter(p => {
        return p?.productId === productId
      })
    // condition to check quantity limit for exclusive products based on cart items
    if (
      productQualificationResponse &&
      productQualificationResponse.length > 0
    ) {
      if (cartItems && cartItems.length > 0) {
        cartItems.forEach(item => {
          if (item?.skus?.[0]?.productId == productId) {
            maxProductQty =
              productQualificationResponse?.[0]?.quantity - item?.quantity
          } else if (productQualificationResponse?.[0]?.quantity > maxLimit) {
            maxProductQty = maxLimit
          } else {
            maxProductQty = productQualificationResponse?.[0]?.quantity
          }
        })
      } else {
        maxProductQty = productQualificationResponse?.[0]?.quantity
      }
    }

    if (maxProductQty > maxLimit) {
      maxProductQty = maxLimit
    }

    return (
      <div noGutters className="v2-pdp-quantity-wrapper">
        {!hideText && (
          <div className="quantity-text mb-3" data-tesid="qa-pdp-quantity-text">
            {i18nTranslate('product.quantity', 'Quantity', {
              nameSpace: 'ssr-resource',
            })}
          </div>
        )}
        <Quantity
          maxValue={maxProductQty}
          circularIcons={circularIcons}
          onQuantityChange={this.props.handleQuantityChange}
          handleQuantityChangePromotion={
            this.props.handleQuantityChangePromotion
          }
          product={this.props.product}
          defaultValue={productCount}
          isV2QuantityStyle={true}
        />
      </div>
    )
  }
}

export { V2ProductQuantity }
