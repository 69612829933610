import React, { useEffect, useState } from 'react'
import { Col, InputGroup, Row } from 'react-bootstrap'
import { IoIosCloseCircle, IoIosCheckmarkCircle } from 'react-icons/io'
import { i18nTranslate } from 'src/utils'
import { customerContainer } from 'src/models'
import { APPConfig } from 'config/appConfig'

const EmailNotVerifiedSMSVerify = props => {
  let [isToEnableEmailVerification, setEmailVerification] = useState(false)
  let [errorClassName, setErrorClassName] = useState(
    'ancher-default-accounts email-notverified'
  )
  let [clickedForVerification, setClickedForVerification] = useState(false)
  useEffect(() => {
    isToEnableEmailVerification =
      APPConfig?.getAppConfig()?.enableEmailVerification == 'true' || false
    setEmailVerification(isToEnableEmailVerification)
  }, [])

  const className = props.isInvalid ? 'is-invalid' : ''

  const sendEmailNotification = e => {
    e.stopPropagation()
    e.preventDefault()
    if (e.code === 'Enter') {
      customerContainer.emailNotification()
    }
    if (e.type === 'click') {
      customerContainer.emailNotification()
    }
  }

  const isEmailVerifiedFlag =
    customerContainer?.profileResponse?.customProperties
      ?.isEmailVerifiedFlag === 'true' || false

  if (!isToEnableEmailVerification) {
    return ''
  }

  function renderEmailVerificationStatus() {
    if (isEmailVerifiedFlag) {
      return i18nTranslate('emailVerified', 'Email is verified.')
    } else if (!isEmailVerifiedFlag && !clickedForVerification) {
      return i18nTranslate('emailNotVerified', 'Email is not verified.')
    }
  }

  function renderEmailVerificationLink() {
    if (isEmailVerifiedFlag) {
      return i18nTranslate('emailVerifiedLink', 'Already Verified.')
    } else if (!isEmailVerifiedFlag && !clickedForVerification) {
      return i18nTranslate('emailNotVerifiedLinkText', 'Click here')
    } else if (!isEmailVerifiedFlag && clickedForVerification) {
      return i18nTranslate(
        'emailVerificationLinkSent',
        'Verification email sent.'
      )
    }
  }
  return (
    <InputGroup
      className={`emailNotVerifiedSMSVerify ${
        !isEmailVerifiedFlag && !clickedForVerification
          ? 'alert-danger'
          : clickedForVerification
          ? 'alert-success'
          : ''
      }`}>
      {clickedForVerification ? (
        <IoIosCheckmarkCircle className="checkVal" />
      ) : (
        <IoIosCloseCircle className="checkNotVal circleclose" />
      )}
      <Row className="dTextInvalidEmail flex-row w-100">
        <div
          className="emailNotVerifiedText w-100 px-3"
          data-testid="qa-email-not-verified">
          {renderEmailVerificationStatus()}
        </div>
        <div className={errorClassName}>
          <div
            tabIndex="0"
            onKeyPress={e => {
              sendEmailNotification(e)
            }}
            onClick={() => {
              customerContainer.emailNotification().then(response => {
                setClickedForVerification(true)
                setErrorClassName(
                  'ancher-default-accounts email-notverified email-verification-sent'
                )
              })
            }}>
            {renderEmailVerificationLink()}
          </div>
        </div>
        <div
          className={`emailNotVerifiedLinkFinishText ${
            !isEmailVerifiedFlag && !clickedForVerification ? '' : 'd-none'
          }`}>
          &nbsp;
          {i18nTranslate(
            'emailNotVerifiedLinkFinishText',
            'to send email verification link'
          )}
        </div>
      </Row>
    </InputGroup>
  )
}

export { EmailNotVerifiedSMSVerify }
