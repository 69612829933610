import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Nav } from 'react-bootstrap'
import {
  AiOutlineInstagram as InstagramIcon,
  AiFillFacebook as FacebookIcon,
  AiFillYoutube as YouTubeIcon,
} from 'react-icons/ai'
import { FaTiktok as TikTokIcon } from 'react-icons/fa'
import { APPConfig } from 'config/appConfig'
import { application } from 'src/utils'
import './styles.scss'

class SocialIcon extends React.Component {
  nameSpace = 'ssr-resource'
  getSocialIcon = (iconLink, link) => {
    switch (iconLink) {
      case 'Facebook':
        return <FacebookIcon size={'2em'} aria-label={iconLink} href={link} />
      case 'instagram.svg':
        return <InstagramIcon size={'2em'} aria-label={iconLink} href={link} />
      case 'YouTube':
        return <YouTubeIcon size={'2em'} aria-label={iconLink} href={link} />
      // case 'TheSource':
      //   return <StiprIcon size={'2em'} aria-label={iconLink} href={link} />
      case 'TikTok':
        return <TikTokIcon size={'2em'} aria-label={iconLink} href={link} />
    }
  }

  renderSocialIcons = () => {
    const baseurl = 'https://www.nuskin.com'
    let socialLinks = this.props.socialLinks?.socialMediaList

    const getFooterDataFromCS =
      APPConfig?.getAppConfig()?.enableFooterFromCS === 'true'
    let altData

    return (
      socialLinks &&
      socialLinks.map((data, index) => {
        if (!getFooterDataFromCS) {
          const altDataArray = data.socialIconUrl?.split('/') || []
          altData =
            altDataArray.length > 0
              ? altDataArray[altDataArray.length - 1]
              : 'social media icon'
        } else {
          altData = data.socialIconUrl?.trim()
        }

        if (altData == 'TheSource') {
          return ''
        }
        return (
          <Nav.Item
            key={`socialIcon-${index}`}
            className={`${data?.socialIconUrl?.className || ''} `}>
            <Nav.Link
              href={data.socialIconLink}
              target="_blank"
              rel="noopener noreferrer"
              className={index === 0 ? 'p-0' : 'p-0 ml-2'}
              aria-label={
                altData +
                i18nTranslate('link.title', '', { nameSpace: this.nameSpace })
              }
              // title={i18nTranslate('link.title', '')}
            >
              {this.getSocialIcon(
                altData,
                getFooterDataFromCS
                  ? data.socialIconUrl
                  : `${baseurl}${data.socialIconUrl}`
              )}
            </Nav.Link>
          </Nav.Item>
        )
      })
    )
  }
  render() {
    const { className, isMobile } = this.props
    return (
      <div
        className={
          application.isDesktop
            ? 'pb-2'
            : !isMobile
            ? 'd-flex align-items-center pl-2 p-2 tab-social'
            : ''
        }>
        {!isMobile && (
          <span className="social">
            {i18nTranslate('footer.connectWithNuSkin', 'connect with Nu Skin', {
              nameSpace: this.nameSpace,
            })}
          </span>
        )}
        <div className="justify-content-center">
          <Nav
            className={`${
              application.isMobile ? 'justify-content-center p-2' : className
            } `}>
            {this.renderSocialIcons()}
          </Nav>
        </div>
      </div>
    )
  }
}
export { SocialIcon }
export default SocialIcon
