import React from 'react'
import { observer } from 'mobx-react'
import { Button, Card, Container, Col, Row } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import { cartContainer } from 'src/models'
import { Summary, CartProducts } from 'src/views/components'
import { isB2BAccount } from 'src/utils'
import { SubmitOrder } from '../SubmitOrder'
import './styles.scss'

@observer
class ReviewAndSubmitAccordion extends React.Component {
  handleEditCart = () => {
    const { onEdit } = this.props

    if (isB2BAccount()) {
      this.props.history.push(`${pageNames.viewCart}`)
    } else {
      onEdit({ index: 'cart' })
    }
  }

  renderReviewLeftSection = () => {
    const view = (
      <Card className="border-0">
        <Card.Body className="p-0 mr-4">
          <Card.Title>
            {i18nTranslate(
              'review.satisfactionText',
              '100% Satisfaction Guaranteed'
            )}
          </Card.Title>
          {/* Comments the below test as per Anitha's comments  */}
          {/* <Card.Text>
            <Trans i18nKey="review.descriptionText">Review And Submit Order Description About The Product</Trans>
          </Card.Text> */}
          <Card.Title>
            {i18nTranslate(
              'review.finalText',
              'Your order is FINAL after clicking "Submit Order".'
            )}
          </Card.Title>
          <Card.Text>
            {i18nTranslate(
              'review.agreeText',
              'By submitting your order, you agree and consent to {CLIENT_NAME}'
            )}
            <Card.Link
              target="_blank"
              className="m-0 mx-2"
              href="https://www.myfoodandfamily.com/useragreement">
              {i18nTranslate('review.termsText', 'Terms of Use')}
            </Card.Link>
            {i18nTranslate('review.andText', 'and')}
            <Card.Link
              target="_blank"
              className="m-0 mx-2"
              href="https://www.kraftheinzcompany.com/NewPrivacyPolicy.html">
              {i18nTranslate('review.privacyPolicy', 'Privacy Policy')}
            </Card.Link>
          </Card.Text>
        </Card.Body>
      </Card>
    )
    return view
  }

  renderReviewRightSection = () => {
    const currencyCode = cartContainer?.cartResponse?.currentCode || ''
    const redeemedLoyaltyPoints = cartContainer.redeemedPoints()
    const storeCredit = cartContainer.redeemedStoreCreditPoints()
    const value = cartContainer?.cartResponse?.value || ''
    const balance = cartContainer?.cartResponse?.loyalty?.totalpoints || 0
    const promotionDetails =
      cartContainer?.cartResponse?.promotionDetails?.promotions
    const summaryValues = {
      priceAfterMarkdown: value.priceAfterMarkdown,
      cartDeliveryCharge: value.cartDeliveryCharge,
      overAllTax: value.overAllTax,
      grandTotal: value.grandTotal - redeemedLoyaltyPoints - storeCredit,
      overAllDiscount: value.overAllDiscount,
      redeemedLoyaltyPoints: redeemedLoyaltyPoints,
      redeemableLoyaltyPoints: value.totalLoyaltyPointsToPay,
      openLoyaltyPoints: balance,
      loyaltyDiscount: value?.loyaltyDiscount,
      count: value.count,
      storeCredit: storeCredit,
      cartDeliveryChargeAfterDiscount: value.cartDeliveryChargeAfterDiscount,
    }
    console.log('summaryValues', summaryValues)
    const { isMultipleAdressActive } = this.props
    const view = (
      <Row noGutters className="review-right-section flex-column">
        <Col lg={12} className="mb-2">
          <Button
            className="w-100"
            variant="secondary"
            data-testid="qa-submit-edit"
            onClick={this.handleEditCart}>
            {i18nTranslate('review.editCart', 'Edit Cart')}
          </Button>
        </Col>
        <Col lg={12}>
          <Summary
            isCheckout={true}
            isReviewSection={true}
            math={summaryValues}
            currencyCode={currencyCode}
            promotionDetails={promotionDetails}
            isMultipleAdressActive={isMultipleAdressActive}
            showRedeemableSection={false}
          />
        </Col>
        <SubmitOrder history={this.props.history} onEdit={this.props.onEdit} />
      </Row>
    )
    return view
  }
  render() {
    const { isMultipleAdressActive } = this.props
    return (
      <Container className="p-0 flex-column review-container">
        <CartProducts
          isCheckout={true}
          isReviewSection={true}
          isSaveForLater={false}
          enableQuantityEdit={false}
          isMultipleAdressActive={isMultipleAdressActive}
        />
        <Row
          noGutters
          className="justify-content-space review-edit-section mt-3 p-2">
          <Col lg={8} md={8} sm={12} xs={12} data-testid="qa-submit-summary">
            {this.renderReviewLeftSection()}
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            {this.renderReviewRightSection()}
          </Col>
        </Row>
      </Container>
    )
  }
}

export { ReviewAndSubmitAccordion }
export default ReviewAndSubmitAccordion
