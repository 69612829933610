import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import { APPConfig } from 'config/appConfig'
import { convertToBoolean } from 'src/utils'

const addressFields = [
  'firstName',
  'middleName',
  'lastName',
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'country',
  'zip',
  'phone',
  'email',
  'deliveryInstruction',
]

function isAddressEqual(address1, address2) {
  return isEqual(pick(address1, addressFields), pick(address2, addressFields))
}

function isPromoWalletEnabled() {
  return (
    convertToBoolean(APPConfig?.getAppConfig()?.enablePromoWallet === 'true') ||
    false
  )
}

export { isAddressEqual, isPromoWalletEnabled }
