import React from 'react'
import TagManager from 'react-gtm-module'
import { Link } from 'react-router-dom'
import { Col, InputGroup, Row } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { getLocalStorage } from 'config/appConfig'

function LinkAccount(props) {
  const { customClassName = '', path } = props || {}
  const currentAccountType = getLocalStorage('accountType') || ''
  const handleGTMEventTrigger = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'AccountTypeConversionButtonClicked',
        pagePath: path,
        pageTitle: 'Account Type Convert',
        currentAccountType,
        platform: 'equinox',
      },
    })
  }
  return (
    <InputGroup className={`${customClassName}`}>
      <Row>
        <Col className="fitToContent">
          <Link
            to={path}
            className="ancher-default-accounts"
            data-testid="qa-ChangeAccountType"
            onClick={handleGTMEventTrigger}>
            {i18nTranslate('changeAccountType', 'Change Account Type', {
              nameSpace: 'ssr-resource',
            })}
          </Link>
        </Col>
      </Row>
    </InputGroup>
  )
}

export { LinkAccount }
