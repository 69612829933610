import { observable } from 'mobx'
import { CommonContainer } from 'src/models/Common'
import { checkoutContainer } from 'src/models'
import { APPConfig } from 'config/appConfig'
import { tokenExIframe } from 'src/views/components'
import { IS_BROWSER } from 'src/utils/application'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
/**
 * TokenExContainer class that extends CommonContainer
 */
class TokenExContainer extends CommonContainer {
  @observable tokenExFormData = ''
  @observable tokenExValidData = ''
  @observable tokenExErrorData = ''
  @observable tokenExAuthKey = {}
  @observable tokenExSavePaymentTypeValid = false
  tokenExIns = null
  formSubmitCallBack = null
  tokenExRetryCount = 0
  maxRetryForTokenEx = 3

  constructor(props) {
    super(props)
    /**
     * @note
     * !!! moved this code into Payment.jsx file
     * !!! because this script try to load for all pages
     */
  }

  /**
   * Fetches the TokenEx authentication key from the server.
   *
   * @param {Object} options - Options object
   * @returns {Promise} Promise resolving to the auth key
   */
  getTokenExAuthKeyFromSFO = async options => {
    if (IS_BROWSER) {
      const loadParams = {
        endPointName: 'getToken',
        queryParams: {
          source: `https://${document.domain}`,
        },
      }
      return await this.fetchResponse(loadParams)
      // this.tokenExAuthKey = await this.fetchResponse(loadParams)
    }
  }

  /**
   * Loads the TokenEx third party script.
   *
   * @param {Object} options - Options object
   * @param {boolean} [options.isFromRetry=false] - Whether this call is from a retry attempt
   */
  loadTokenExScript = options => {
    const { isFromRetry = false } = options || {}

    if (!isFromRetry) {
      this.tokenExRetryCount = 0
    }

    try {
      if (IS_BROWSER) {
        const scriptEl = document.querySelectorAll('script#tokenEx')
        const tokenExSrcUrl = APPConfig?.getAppConfig()?.tokenExSrcUrl
        // const tokenExSrcUrl = 'https://test-htp.tokenex.com/Iframe/Iframe-v3.js'
        if (scriptEl.length == 0) {
          const script = document.createElement('script')
          script.id = 'tokenEx'
          script.src = tokenExSrcUrl
          script.onerror = error => {
            this.retryTokenExScript({
              errorReport: 'Error loading TokenEx Script',
              errorData: error,
            })
          }
          document.body.appendChild(script)
        }
      }
    } catch (e) {
      this.retryTokenExScript({
        errorReport: 'Error loading TokenEx Script',
        errorData: e,
      })
    }
  }

  /**
   * Retries loading the TokenEx script on error.
   * Removes any existing TokenEx scripts from the DOM.
   * Increments a retry counter and tracks errors.
   * Loads the TokenEx script again if below the max retry count.
   */
  retryTokenExScript = error => {
    document.querySelectorAll('script#tokenEx').forEach(item => {
      item.remove()
    })
    if (this.tokenExRetryCount == 0) {
      this.trackErrorInInstana({
        errorReport: error?.errorReport || 'Error loading TokenEx Script',
        errorData: error?.errorData || 'Error loading TokenEx Script',
      })
    }
    this.tokenExRetryCount++
    if (this.tokenExRetryCount < this.maxRetryForTokenEx) {
      this.loadTokenExScript({ isFromRetry: true })
    }
  }

  /**
   * Logs TokenEx errors to the console and Instana monitoring
   * @param {Object} options - The error info
   * @param {string} options.errorReport - The error message
   * @param {any} options.errorData - Any additional error data
   */
  trackErrorInInstana = options => {
    const { errorReport, errorData } = options
    console.warn(errorReport, errorData)
    if (window?.ineum != 'undefined') {
      ineum('reportEvent', `TokenEx: ${errorReport}`, {
        meta: errorData,
      })
    }
  }

  /**
   * Retries loading the TokenEx iframe session.
   * Removes any existing TokenEx iframes from the DOM.
   * Loads the TokenEx iframe again.
   */
  retryTokenExSession = async () => {
    this.loadTokenExIframe()
  }

  /**
   * Loads the TokenEx iframe for collecting credit card details.
   * Configures the iframe appearance and validation behavior.
   * Saves card data and validation status to component state.
   * Handles iframe session expiration and errors.
   * Retries loading the iframe on failure.
   */
  loadTokenExIframe = async () => {
    let tokenExOriginUrl

    const tokenExResponse = await this.getTokenExAuthKeyFromSFO()

    if (IS_BROWSER) {
      tokenExOriginUrl = `https://${location?.host}`
    }

    const iframeConfig = {
      origin: tokenExOriginUrl,
      authenticationKey:
        tokenExResponse?.authKey || this.tokenExAuthKey.authKey,
      timestamp: tokenExResponse?.timestamp || this.tokenExAuthKey.timestamp,
      tokenExID: tokenExResponse?.tokenExID || this.tokenExAuthKey.tokenExID,
      tokenScheme: 'PCI',
      enablePrettyFormat: true,
      enableValidateOnBlur: true,
      font: true,
      returnKhash: true,
      inputMode: 'text',
      cvvInputType: 'password',
      placeholder: i18nTranslate('payment.tokenexCardNumber', 'Card Number'),
      cvvPlaceholder: 'CVV',
      pci: true,
      cvv: true,
      inputType: 'text',
      inputMaxLength: 16,
      enableValidateOnKeyUp: true,
      cvvContainerID: 'cvvID',
      title: 'Iframe Payment Information',
      // expiresInSeconds: 120,
      styles: {
        base: `padding: 0 10px;border-radius: ${
          isExpressCheckout() ? '4' : '0'
        }px !important;border: 1px solid ${
          isExpressCheckout() ? '#cccccc' : '#252525'
        };margin: 0;width: 100%;font-size: 16px;line-height: 22px;height: 44px;box-sizing: border-box;-moz-box-sizing: border-box;color:#252525'`,
        focus:
          'box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);border: 3px solid #94D6E9;outline: 0;',
        error:
          'box-shadow: 0 0 2px 0 rgb(224 57 57 / 0%);border: 1px solid rgb(220 53 69);',
        cvv: {
          base: `padding: 0 10px;border-radius: ${
            isExpressCheckout() ? '4' : '0'
          }px !important;border: 1px solid ${
            isExpressCheckout() ? '#cccccc' : '#252525'
          };margin: 0;width: 98%;font-size: 16px;line-height: 22px;height: 44px;box-sizing: border-box;-moz-box-sizing: border-box;color:#252525`,
          focus:
            'box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);border: 3px solid #94D6E9;outline: 0;',
          error:
            'box-shadow: 0 0 2px 0 rgb(224 57 57 / 0%);border: 1px solid rgb(220 53 69);',
        },
      },
    }
    const saveTokenExData = (data = {}) => {
      this.tokenExFormData = {
        ...this.tokenExFormData,
        ...data,
      }

      if (this.formSubmitCallBack) {
        this.formSubmitCallBack()

        /**
         * @note
         * to reset the callback to avoid duplicate calls
         */
        this.formSubmitCallBack = ''
      }
    }

    const saveTokenExValidData = (data = {}) => {
      tokenExIframe.isValidCard = false
      tokenExIframe.isCvvValidCard = false
      this.tokenExValidData = {
        ...this.tokenExValidData,
        ...data,
      }
    }

    const saveTokenExErrorData = (data = {}) => {
      const sessionExpired = 'Session expired'
      if (data?.error?.includes(sessionExpired)) {
        this.tokenExIns?.remove?.()
        this.retryTokenExSession()
      }

      this.trackErrorInInstana({
        errorReport: 'Error TokenEx iframe',
        errorData: data,
      })

      this.tokenExErrorData = {
        ...this.tokenExErrorData,
        ...data,
      }
    }

    try {
      const tokenExIframeEl = document.getElementById('tokenExIframeDiv')
      if (tokenExIframeEl) {
        this.tokenExErrorData = {}
        this.tokenExIns = new TokenEx.Iframe(tokenExIframeEl.id, iframeConfig)
        // the final value of the tokenEx form
        this.tokenExIns.on('tokenize', saveTokenExData)
        // // after form validation
        this.tokenExIns.on('validate', saveTokenExValidData)
        // validate the tokenEx form fields
        this.tokenExIns.on('blur', () => {
          this.tokenExIns.validate()
        })
        this.tokenExIns.on('error', saveTokenExErrorData)

        this.tokenExIns.load()
      }
    } catch (e) {
      this.trackErrorInInstana({
        errorReport: 'Error in TokenEx iframe config',
        errorData: e,
      })
    }
  }

  /**
   * Tokenizes the payment data from the TokenEx iframe.
   * Calls the tokenize method on the TokenEx instance to generate a token.
   * Saves the callback function to submit the form after tokenization.
   * Scrolls the page to the error section if tokenization fails or data is invalid.
   */
  tokenize = cbk => {
    if (this.tokenExIns) {
      this.tokenExIns.tokenize()
      this.formSubmitCallBack = cbk
      let section = document.querySelector('#checkout-floating-bar')
      if (tokenExContainer?.tokenExValidData) {
        const { isValid, isCvvValid } = tokenExContainer?.tokenExValidData
        if (
          !isValid ||
          !isCvvValid ||
          (tokenExContainer?.tokenExSavePaymentTypeValid &&
            !checkoutContainer?.addPaymentToProfile)
        ) {
          section = document.querySelector('[class="token-block"]')
        }
        section?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }
  }
}

const tokenExContainer = new TokenExContainer()

export { tokenExContainer }
export default tokenExContainer
