import React from 'react'
import { observable } from 'mobx'
import { Modal } from 'react-bootstrap'
import { overlayState, AddressValidation } from 'src/views/components'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import {
  customerContainer,
  tokenExContainer,
  checkoutContainer,
  addressContainer,
} from 'src/models'
import PaymentIcon from 'src/views/components/PaymentIcon'
import { dateFormatDeps, getCardDetails } from 'src/deps'
import { observer } from 'mobx-react'
import { PaymentMarketForm } from 'src/views/components/PaymentIntlShipping'
import { capitalizeFirstLetter } from 'src/utils'
import './styles.scss'

/**
 * @description new modal introduced for edit and update payment card details (checkout page and myaccount payments )
 * @date 9/22/2023
 *
 * @class EditUpdatePaymentModal
 * @typedef {EditUpdatePaymentModal}
 * @extends {React.Component}
 */
@observer
class EditUpdatePaymentModal extends React.Component {
  @observable show = false
  @observable addressInvalidFlag = false
  @observable recommendations = []
  @observable formDataEnter = {}
  isCardExpired = ''
  constructor(props) {
    super(props)
  }
  getPaymentResponseFromAPI = () => {
    const paymentResponse =
      this.props.billingAddressType === 'subscription'
        ? customerContainer?.paymentsForDashboard || {}
        : customerContainer?.paymentResponse || {}
    return paymentResponse
  }
  onEditFormSubmit = async cardDetailFromForm => {
    this.formDataEnter = cardDetailFromForm
    // Payload for updating expired subscription payment method
    let payload = {
      option: { addPaymentToProfile: true },
    }

    // Get card details from container
    const { payments = [], pageableInfo = {} } =
      this.getPaymentResponseFromAPI()
    let cardDetailsFromContainer = payments?.find(
      item => item?.id === this.props.IDofCardToBeEdited?.toString()
    )

    payload.accountInfo = {
      name:
        cardDetailFromForm?.cardFirstName ||
        cardDetailFromForm?.firstName ||
        '',
      lastName:
        cardDetailFromForm?.cardFirstName || cardDetailFromForm?.lastName || '',
      cardNumber: cardDetailFromForm?.cardNumber || '',
      expiryMonth: cardDetailFromForm?.expiryDate?.split?.('/')?.[0] || '',
      expiryYear:
        '20' + cardDetailFromForm?.expiryDate?.split?.('/')?.[1] || '',
      provider: cardDetailFromForm?.provider || '',
      isDefault: cardDetailFromForm?.setToDefaultPayment || '',
    }
    /* backend team wanted to send isDefault value in both accountInfo and outer level  */
    payload.isDefault = cardDetailFromForm?.setToDefaultPayment || ''
    // Address if billing is not same as shipping
    if (!cardDetailFromForm.billingSameAsShipping) {
      payload.billingAddress = {
        firstName: cardDetailFromForm?.firstName || '',
        lastName: cardDetailFromForm?.lastName || '',
        addressLine1: cardDetailFromForm?.addressLine1 || '',
        addressLine2: cardDetailFromForm?.addressLine2 || '',
        city: cardDetailFromForm?.city || '',
        zip: cardDetailFromForm?.zip || '',
        postalCode: cardDetailFromForm?.zip || '',
        email: cardDetailFromForm?.email || '',
        phone: cardDetailFromForm?.phone || '',
        state: cardDetailFromForm?.state || '',
        county: cardDetailFromForm?.county || '',
        country: cardDetailFromForm?.country || '',
        phoneCountryCode: cardDetailFromForm?.phoneCountryCode || '',
        stateList: cardDetailFromForm?.state || '',
        contactInformation: cardDetailFromForm?.contactInformation || '',
        required_note: cardDetailFromForm?.required_note || '',
        addPaymentToProfile: true,
      }
    }

    // Add tokenex details
    if (tokenExContainer?.tokenExFormData) {
      payload.properties = {
        ...tokenExContainer?.tokenExFormData,
      }
    }
    overlayState.toggleLoader()
    /**
     * ValidateAddress call api will be triggered only if billing address is not same as shipping address
     * and for US and CA markets alone
     * if billingSameAsShipping is true, just update the payment data alone
     */
    if (
      (checkoutContainer.selectedMarket === 'US' ||
        checkoutContainer.selectedMarket === 'CA') &&
      !cardDetailFromForm.billingSameAsShipping
    ) {
      const res = await addressContainer.validateAddress(
        payload?.billingAddress
      )

      if (res?.code === 'ADCOVAL0003') {
        this.addressInvalidFlag = true
        this.recommendations = [{ ...payload?.billingAddress }, []]
        this.params = { ...payload }
        this.show = true
        if (isExpressCheckout()) {
          checkoutContainer.expressPaymentAdded = false
        }
      } else if (res?.code == 'ONESOURCE_ADPLGIN_VAL_ERROR') {
        this.setState({
          show: false,
        })
        toastState.setToastMessage(
          i18nTranslate('address.NotAdded', 'Address not added')
        )
        if (isExpressCheckout()) {
          checkoutContainer.expressPaymentAdded = false
        }
        return
      } else {
        const recommendationAddress =
          res?.address?.validationDetails?.[0]?.recommendations || []
        this.addressInvalidFlag = false
        let firstAddress = recommendationAddress?.[0] || {}

        if (Object.keys(firstAddress)?.length > 0) {
          this.recommendations = [
            { ...payload?.billingAddress },
            ...recommendationAddress,
          ]
          this.params = { ...payload }
          this.show = true
        } else {
          this.params = { ...payload }
          this.handleClickOnPro(payload)
        }
      }
    } else {
      this.params = {
        ...payload,
        billingSameAsShipping: cardDetailFromForm.billingSameAsShipping,
      }
      this.handleClickOnPro(payload)
      //Called on clicking SaveChanges when billingSameAsShipping is true
      //and when international address is chosen in country dropdown
    }

    overlayState.toggleLoader()
  }

  handleClose = () => {
    this.show = false
  }

  /**
   * Update Payment API called on Edit payment fields and address fields of payment
   * @date 1/24/2024 - 12:30:51 PM
   * @param {} updatePaymentPayload - Payload for updating expired subscription payment method
   * @type {*}
   */
  updatePaymentOnEdit = async (updatePaymentPayload = {}) => {
    // Update payment call - (PATCH)
    const isEditSuccessful = await customerContainer.editSubscriptionPayment(
      updatePaymentPayload
    )
    const selectedId = this.props?.cardDetails?.id?.toString() || ''
    const defaultSelectedCardId =
      this.props?.selectedDefaultCardId?.toString() || ''
    if (isEditSuccessful) {
      const { updateSubscription, billingAddressType } = this.props
      updateSubscription && (await updateSubscription())
      if (
        billingAddressType === 'checkout' &&
        this.props?.handleCheckoutChange
      ) {
        this.props?.handleCheckoutChange()
      }
      this.props.closeModalCallback && this.props.closeModalCallback()
      if (
        defaultSelectedCardId == selectedId ||
        this.formDataEnter?.setDefaultSubscriptionPayment === true
      ) {
        await this.props?.isDisablePaymentModal()
        const data = {
          id: selectedId,
        }
        this.props?.handlePaymentSuggestionContinue(data)
      }
    }
  }

  /**
   * Validate original address on given and call updatePaymentOnEdit function
   * for patch update
   */
  async handleClickOnPro(formData) {
    const {
      accountInfo = {},
      billingAddress = {},
      properties = {},
      isDefault = false,
      billingSameAsShipping = false,
    } = this.params

    let reqParams = {
      option: { addPaymentToProfile: true },
      accountInfo: { ...accountInfo },
      properties: { ...properties },
      isDefault: isDefault,
    }

    if (!billingSameAsShipping) {
      const billingAddressFields = formData?.billingAddress
        ? { ...formData?.billingAddress }
        : { ...formData }
      reqParams.billingAddress = billingAddressFields || {}
    }

    const { pageableInfo = {} } = this.getPaymentResponseFromAPI()

    const updatePaymentPayload = {
      id: this.props?.IDofCardToBeEdited,
      payload: reqParams,
      page: pageableInfo?.page,
    }
    let isAddressValidOrNoChangeInAddressFields = false
    if (formData?.selectedIndex <= 0) {
      const response = await customerContainer?.validateOriginalAddressOnEdit(
        reqParams
      )
      if (customerContainer.isSuccessResponse(response)) {
        isAddressValidOrNoChangeInAddressFields = true
      }
    } else {
      isAddressValidOrNoChangeInAddressFields = true
    }
    if (isAddressValidOrNoChangeInAddressFields) {
      if (isExpressCheckout()) {
        checkoutContainer.expressPaymentAdded = false
      }
      await this.updatePaymentOnEdit(updatePaymentPayload)
    }
    this.handleClose()
  }

  render() {
    const {
      cardDetails = {},
      closeButton,
      billingAddressType = '',
      isFromCheckout = false,
      isFromSubscription = false,
      IDofCardToBeEdited = '',
      selectedDefaultCardId = '',
    } = this.props

    const cardData = getCardDetails(cardDetails?.accountInfo)
    const billingAddress = cardDetails?.billingAddress || {}
    const iconType = cardData?.provider?.toLowerCase()
    const currentDateTimeStamp = Date.now()
    const isDefault = cardData?.isDefault
    const { month, year } =
      dateFormatDeps.convertTimeStampToDate(currentDateTimeStamp)
    this.isCardExpired =
      year < cardData.expiryYear
        ? true
        : year == cardData.expiryYear
        ? month <= cardData.expiryMonth
          ? true
          : false
        : false

    const isSubscriptionDefaultPayment =
      IDofCardToBeEdited?.toString() === selectedDefaultCardId?.toString()
        ? true
        : false

    const endingText = i18nTranslate('payment.cardEndingTxt', '-****')
    const expiryText = i18nTranslate('payment.cardExpiresText', 'Expires')
    const expiredText = i18nTranslate('payment.expiredText', 'Expired')

    return (
      <>
        <Modal
          aria-hidden={false}
          show={this.props?.show}
          onHide={closeButton}
          className="update-payment-form"
          centered>
          <Modal.Header closeButton className="header-wrapper ms-auto">
            <Modal.Title as="div" className="title-wrapper ms-auto">
              {i18nTranslate(
                'checkout.updatePayment',
                'Update your payment information'
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex">
              <PaymentIcon type={iconType} />
              <h5 className="card-wrapper ml-2">
                <span>{capitalizeFirstLetter(cardData?.provider)} &nbsp;</span>
                <span>
                  {endingText}
                  {cardData?.cardNumber}&nbsp;
                </span>
                <span> | &nbsp; </span>
              </h5>
              <span
                className={
                  this.isCardExpired
                    ? 'paragraph-s ml-1 d-flex expiry-text'
                    : 'paragraph-s text-danger ml-1 d-flex expiry-text'
                }>
                <span>
                  {this.isCardExpired ? expiryText : expiredText}&nbsp;
                </span>
                <span>{`${cardData?.expiryMonth}/${cardData?.expiryYear?.substr(
                  2
                )}`}</span>
              </span>
            </div>
            <PaymentMarketForm
              onSubmitForm={this.onEditFormSubmit}
              isPaymentEdit={true}
              isFromEditSubscription={true}
              hideDefaultSubPaymentCheckbox={!isFromSubscription}
              isFromCheckout={isFromCheckout}
              className="tab-content"
              isDefault={isDefault}
              billingAddressType={billingAddressType}
              editUpdateModalEnabled={true}
              billingAddress={billingAddress}
              isSubscriptionDefaultPayment={isSubscriptionDefaultPayment}
            />
            {this.show && (
              <AddressValidation
                open={this.show}
                addressInvalidFlag={this.addressInvalidFlag}
                recommendations={this.recommendations}
                handleClose={() => this.handleClose()}
                handleClickOnPro={dataAfterValidation =>
                  this.handleClickOnPro(dataAfterValidation)
                }
              />
            )}
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default EditUpdatePaymentModal
export { EditUpdatePaymentModal }
