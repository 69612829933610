import React from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { i18nTranslate, getLiveEventStatus, isExpressCheckout } from 'src/utils'
import { loyaltyContainer, checkoutContainer, cartContainer } from 'src/models'
import {
  application,
  trackEnhancedCheckout,
  trackEnhancedCheckoutEvent,
} from 'src/utils'
import { CommonForm, toastState, Icons } from 'src/views/components'
import { FaMoneyBillAlt as Bill } from 'react-icons/fa'
import { pageNames } from 'src/routes/pathParams'
import { formInput } from './formInput'
import { schema } from './validationSchema'
import './styles.scss'
import ExpressSectionHeader from '../ExpressSectionHeader'

@observer
class StoreCredits extends React.Component {
  @observable isStoreCreditEnable = false
  @observable shouldShowAppliedText = false
  @observable appliedStoreCreditPoints = ''
  @observable isCallInProgress = false
  isExpressCheckout = isExpressCheckout() || false
  @observable showList = !!!this.isExpressCheckout
  enableLiveEvent = getLiveEventStatus()
  constructor(props) {
    super(props)
  }

  getLoyaltyPoints = async () => {
    await loyaltyContainer.getLoyaltyPoints()
  }

  async componentDidMount() {
    let payments = cartContainer?.cartResponse?.payments || []

    this.isStoreCreditEnable =
      payments?.some(item => {
        return item.type == 'STORECREDIT'
      }) && checkoutContainer.enableGlobalAccountCredits

    // for viewcart page, call is made in cartview.jsx
    //  here we are making call to get "latest" value for checkout payment sections
    let pathname = this.props?.location?.pathname || ''
    if (!pathname) {
      pathname = (typeof window === 'object' && window.location.pathname) || ''
    }

    if (
      !pathname.includes(pageNames.viewCart) &&
      !pathname.includes(pageNames.checkout) &&
      !pathname.includes(pageNames.expressCheckout)
      // For checkout page, call has been made in PaymentAccordion
    ) {
      this.getLoyaltyPoints()
    }

    const storeCreditPayment = payments?.some(option => {
      return option.type === 'STORECREDIT'
    })

    const totalAmountUnpaid =
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || 0.0
    const currentPoints = loyaltyContainer.getStoreCredits() || 0
    this.appliedStoreCreditPoints = storeCreditPayment
      ? storeCreditPayment?.amount || 0
      : currentPoints > totalAmountUnpaid
      ? totalAmountUnpaid
      : currentPoints

    if (storeCreditPayment) {
      await checkoutContainer.deleteAllStoreCreditPayments()
      checkoutContainer.shouldEnablePlaceOrder = false
      await cartContainer.viewCart()
      this.shouldShowAppliedText = false
    }

    checkoutContainer.checkAvailableStoreCreditPoints()
    // this.appliedStoreCreditPoints = checkoutContainer.availableAccountCredits
  }

  // all payments will be deleted on clicking "Use points" button on loyalty payment
  // so compare and change checkbox status.
  componentDidUpdate() {
    let payments = cartContainer?.cartResponse?.payments || []

    let isStoreCreditAvailable = payments?.some(item => {
      return item.type == 'STORECREDIT'
    })

    if (this.shouldShowAppliedText !== isStoreCreditAvailable) {
      this.shouldShowAppliedText = isStoreCreditAvailable
      cartContainer.isStoreCreditChecked = isStoreCreditAvailable
    }
  }

  handleUndoAppliedSC = async () => {
    let payments = cartContainer?.cartResponse?.payments || []
    const hasStoreCredit = payments?.find(option => {
      return option.type === 'STORECREDIT'
    })

    if (hasStoreCredit) {
      await checkoutContainer.deleteAllStoreCreditPayments()
    }

    if (payments?.length) {
      await checkoutContainer.deleteAllCreditCardPayments()
    }

    await cartContainer.viewCart()

    // const totalAmountUnpaid =
    //   get(cartContainer, 'cartResponse.paymentValue.totalAmountUnpaid') || 0.0

    // const currentPoints = loyaltyContainer.getStoreCredits() || 0

    checkoutContainer.checkAvailableStoreCreditPoints()
    this.shouldShowAppliedText = false
  }

  handleApply = async (data, resetForm) => {
    let payments = cartContainer?.cartResponse?.payments || []

    const hasStoreCredit = payments?.find(option => {
      return option.type === 'STORECREDIT'
    })

    if (hasStoreCredit) {
      await checkoutContainer.deleteAllStoreCreditPayments()
    }

    if (payments?.length) {
      await checkoutContainer.deleteAllCreditCardPayments()
    }

    const response = await checkoutContainer.addStoreCreditPayment(
      data.storeCredit
    )

    if (checkoutContainer.isSuccessResponse(response)) {
      checkoutContainer.isPaymentCallsInProgress = false
      await cartContainer.viewCart()

      resetForm && resetForm()

      toastState.setToastMessage(
        i18nTranslate(
          'storeCredit.success',
          'You have added store credit to your cart.'
        ),
        true
      )

      this.shouldShowAppliedText = true
      if (this.isExpressCheckout) {
        this.showList = false
      }
      //this.appliedStoreCreditPoints = ''

      let totalAmountUnpaid =
        cartContainer.cartResponse?.paymentValue?.totalAmountUnpaid || 0.0

      if (this.props.isCheckOutPage && totalAmountUnpaid === 0) {
        const communicationResponse =
          await checkoutContainer.setCommunicationPreference(
            cartContainer.cartResponse?.deliveryDetails?.address || {}
          )

        if (!checkoutContainer.isSuccessResponse(communicationResponse)) {
          toastState.setToastMessage(
            communicationResponse.message || communicationResponse?.errorMessage
          )
          return
        }

        checkoutContainer.shouldEnablePlaceOrder = true
        checkoutContainer.isToDisableSubmitOrderButton = false
        checkoutContainer.isToInitiateGlobalPayment = false
        checkoutContainer.activePath = 6
        checkoutContainer.isCreditCardEnable = false

        if (
          cartContainer?.cartResponse?.value?.grandTotal ===
          cartContainer.cartResponse?.paymentValue?.totalAmountPaid
        ) {
          this.props.handlePaypalButton(false)
        }
      }
    } else {
      const errorCode = (response && response.code) || ''

      let toastMessage = ''
      if (
        (errorCode == 'EOCA0000005' && response.message) ||
        (errorCode == 'validation.transaction.redeem.transaction.blocked' &&
          response.message) ||
        (errorCode ==
          'validation.transaction.minimum.redeem.value.not.reached' &&
          response.message)
      ) {
        toastMessage = response.message
      } else {
        toastMessage = i18nTranslate(
          'storeCredit.failure',
          'Unable to add store credit to cart, please try again'
        )
      }
      toastState.setToastMessage(toastMessage)
    }
  }

  renderUndoCreditButton = () => {
    // const { isCheckOutPage = false } = this.props

    return (
      <Button
        data-testid="qa-store-credit-undo"
        className="mb-2 checkout-undo-button text-uppercase"
        variant="primary"
        type="submit"
        disabled={
          this.isCallInProgress || checkoutContainer.isPaymentCallsInProgress
        }
        onClick={this.handleUndoAppliedSC}>
        {i18nTranslate('storecredits.undoCredit', 'Undo')}
      </Button>
    )
  }

  renderSubmitButton = () => {
    const { isCheckOutPage = false } = this.props
    return (
      <div className="apply-credit-container">
        <Button
          data-testid="qa-store-credit-apply"
          className={
            !isCheckOutPage
              ? 'checkout-apply-button w-100 pb-2 text-uppercase'
              : 'checkout-apply-button ml-2 text-uppercase'
          }
          variant="primary"
          type="submit"
          disabled={
            this.isCallInProgress ||
            checkoutContainer.isPaymentCallsInProgress ||
            this.shouldShowAppliedText
          }>
          {i18nTranslate('storecredits.applyCredit', 'Apply Credit')}
        </Button>
        {this.shouldShowAppliedText && (
          <div className="ml-2 applied-credits text-capitalize">
            <Icons color="#3E7957" width="15px" name="CRUD" icontype={'tick'} />
            {i18nTranslate('storecredits.applied', 'Applied')}
          </div>
        )}
      </div>
    )
  }

  handleStoreCreditCheck = async () => {
    this.isStoreCreditEnable = !this.isStoreCreditEnable
    let payments = cartContainer?.cartResponse?.payments || []
    const hasStoreCredit = payments?.find(option => {
      return option.type === 'STORECREDIT'
    })
    if (!this.isStoreCreditEnable && hasStoreCredit) {
      this.isStoreCreditEnable = !this.isStoreCreditEnable
      // await checkoutContainer.deleteAllCreditCardPayments()
      // checkoutContainer.isCreditCardEnable = false
      await checkoutContainer.deleteAllStoreCreditPayments()
      checkoutContainer.shouldEnablePlaceOrder = false
      await cartContainer.viewCart()

      const creditCardPaymentInCart = payments?.find(option => {
        return option.type == 'CREDITCARD'
      })
      if (creditCardPaymentInCart) {
        // patch the removed store credit amount
        const totalAmountUnpaid =
          cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || 0
        const amountToBePatched = parseFloat(
          totalAmountUnpaid + (creditCardPaymentInCart.amount || 0)
        ).toFixed(2)
        await checkoutContainer.updatePaymentWithCartValue(
          creditCardPaymentInCart.id,
          amountToBePatched
        )
      }
      await cartContainer.viewCart()
      const totalAmountUnpaidAfterUpdate =
        cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || 0
      if (totalAmountUnpaidAfterUpdate == 0) {
        checkoutContainer.shouldEnablePlaceOrder = true
        //cartContainer.checkoutActiveTabIndex = 6
      }
      const updatePayments = cartContainer?.cartResponse?.payments || []
      const storeCreditPayment = updatePayments.find(option => {
        return option.type === 'STORECREDIT'
      })
      this.appliedStoreCreditPoints = ''
      const currentPoints = loyaltyContainer.getStoreCredits() || 0
      this.appliedStoreCreditPoints = storeCreditPayment
        ? storeCreditPayment?.amount || 0
        : currentPoints > totalAmountUnpaidAfterUpdate
        ? totalAmountUnpaidAfterUpdate
        : currentPoints
    }
  }

  handleValueChange = event => {
    checkoutContainer.availableAccountCredits = event.target.value
  }

  handleNext = () => {
    const { onContinue, title } = this.props
    const products = cartContainer?.cartResponse?.items || []

    if (this.enableLiveEvent === 'true') {
      trackEnhancedCheckoutEvent({
        type: 'store_credits',
        action: { step: '5', option: title },
        value: loyaltyContainer.getStoreCredits(),
      })
    }
    trackEnhancedCheckout({
      type: 'checkout',
      action: { step: '5', option: title },
      products: products,
    })
    onContinue()
  }

  render() {
    const { isCheckOutPage } = this.props
    let payments = cartContainer?.cartResponse?.payments || []
    const storeCreditOption = payments?.find(option => {
      return option.type === 'STORECREDIT'
    })
    const creditCardOption = payments?.find(option => {
      return option?.type === 'CREDITCARD' || option?.type === 'DEBITCARD'
    })
    const totalAmountUnpaid =
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || 0.0

    const appliedValueViaStoreCredit = storeCreditOption
      ? storeCreditOption.amount
      : 0
    const openToRedeemStoreCreditPoints = loyaltyContainer.getStoreCredits()
    const appliedValueViaCreditCard = creditCardOption
      ? creditCardOption.amount
      : 0
    const currentPoints = openToRedeemStoreCreditPoints || 0
    // shouldDisableCheckbox, when total amount is paid with creditcard
    // In the below condition, total amount will be paid through other payments
    const shouldDisableStoreCreditCheckbox =
      totalAmountUnpaid == 0 && appliedValueViaStoreCredit == 0

    return (
      <div
        className={
          'store-credit-container w-100' +
          (shouldDisableStoreCreditCheckbox && !isExpressCheckout
            ? ' payment-disabled'
            : '')
        }>
        {/*<h3 className="store-credit-heading">
          <Trans i18nKey="storecredits.title">Account Credit</Trans>
      </h3>*/}
        {this.isExpressCheckout && (
          <>
            <ExpressSectionHeader
              title={i18nTranslate('loyalty.storeCredit', 'Store Credit')}
              isOpen={this.showList}
              onClick={() => {
                if (!this.showList) {
                  this.handleUndoAppliedSC()
                }
                this.showList = !this.showList
              }}
            />
            {!this.showList && (
              <div>
                <span className="express-shiping-address-data pb-3 pr-2">
                  {i18nTranslate(
                    'expressCheckout.StoreCreditsApplied',
                    'Store Credits Applied'
                  )}
                </span>
                <span className="express-shiping-address-data pb-3 ">
                  {'$'}
                  {appliedValueViaStoreCredit}
                </span>
              </div>
            )}
          </>
        )}
        {this.showList && (
          <>
            <div data-testid="coupon-form-wrapper" className="">
              <div className={'check-box-wrap'}>
                {/* <input
              type="checkbox"
              className="custom-control-input"
              checked={}
              onChange={}
            /> */}
                {/* <Form.Check
              className={`check-box-wrap text-break`}
              custom
              id="store-credit"
              checked={cartContainer.isStoreCreditChecked}
              disabled={shouldDisableStoreCreditCheckbox}
              label={
                isCheckOutPage ? (
                  <span className={'pl-2 text-capitalize check-box-label'}>
                    <Trans i18nKey="storecredits.storecredit">
                      Apply Nuskin store credit
                    </Trans>
                    <span className={'pl-1'}>
                      {' ($'}
                      {(currentPoints - appliedValueViaStoreCredit).toFixed(
                        2
                      )}{' '}
                    </span>
                    <Trans i18nKey="storecredits.heading">
                      Available Credits
                    </Trans>
                    {').'}
                  </span>
                ) : (
                  <span className={'pl-1 check-box-label'}>
                    <Trans i18nKey="storecredits.usestorecredit">
                      Use account credits
                    </Trans>
                  </span>
                )
              }
              onChange={
                cartContainer.isStoreCreditChecked == false
                  ? () =>
                      (cartContainer.isStoreCreditChecked = !cartContainer.isStoreCreditChecked)
                  : () => (
                      this.handleStoreCreditCheck() &
                        (cartContainer.isStoreCreditChecked = !cartContainer.isStoreCreditChecked),
                      (this.shouldShowAppliedText = false),
                      (this.appliedStoreCreditPoints = '')
                    )
              }
              // aria-label={value.name}
            />{' '} */}
                <div>
                  <Bill
                    data-testid="qa-moneybill-storecredit"
                    ariaLabel={i18nTranslate(
                      'checkout.moneybillIcon',
                      'Money Bill Icon'
                    )}
                    alt={i18nTranslate(
                      'checkout.moneybillIcon',
                      'Money Bill Icon'
                    )}
                  />

                  <span className="pl-2 text-capitalize store-credit-text express-shiping-address-data">
                    {i18nTranslate(
                      'checkout.StoreCreditYouHaveText',
                      'You have'
                    )}{' '}
                    <span className="store-credit-value">
                      {'$'}
                      {(currentPoints - appliedValueViaStoreCredit).toFixed(
                        2
                      )}{' '}
                    </span>{' '}
                    {i18nTranslate(
                      'checkout.StoreCreditAvailableText',
                      'Account Credit available.'
                    )}
                  </span>
                </div>
                {/* {cartContainer.isStoreCreditChecked ? ( */}
                <div className="store-credit-form-wrapper">
                  <CommonForm
                    schema={schema({ currentPoints, totalAmountUnpaid })}
                    formTypeJson={formInput({
                      isCheckOutPage: this.props.isCheckOutPage,
                      handleValueChange: this.handleValueChange,
                      appliedStoreCreditPoints:
                        checkoutContainer.availableAccountCredits,
                      isAppliedTextShown:
                        this.shouldShowAppliedText && application.isDesktop,
                    })}
                    SubmitButtonProps={this.renderSubmitButton}
                    //onSubmit={this.//handleApply}
                    onSubmit={async (formValues, resetForm) => {
                      if (!this.isCallInProgress) {
                        this.isCallInProgress = true
                        checkoutContainer.isPaymentCallsInProgress = true
                        await this.handleApply(formValues, resetForm)
                        checkoutContainer.isPaymentCallsInProgress = false

                        this.isCallInProgress = false
                      }
                    }}
                    hasInlineSubmitButton={true}
                    classNameValue={{
                      formLabelClassName: 'text-uppercase email-label',
                      formRowClassName: 'mt-3 mb-2 express-store-credit-row',
                    }}
                  />
                  {this.shouldShowAppliedText && (
                    <div className="checkout-undo-wrapper pl-1 ml-3 ">
                      {this.renderUndoCreditButton()}
                    </div>
                  )}
                </div>
                {/* ) : (
              ''
            )} */}
              </div>
              {/* <div>
            <span className={'pl-2 pr-1'}>
              <Trans i18nKey="storecredits.applied">Applied</Trans>
            </span>
            :
            <span className={'pl-1'}>
              ${appliedValueViaStoreCredit.toFixed(2)}
            </span>
          </div> */}
            </div>
            {/* <div
            className={
              'store-credit-wrapper ' +
              (this.isStoreCreditEnable ? '' : 'payment-disabled')
            }>
            <div
              data-testid="coupon-form-wrapper"
              className="coupon-form-wrapper">
              <label className="mt-2 text-capitalize font-weight-normal">
                <span className="pr-1">
                  <Trans i18nKey="storecredits.heading">
                    Available Credits
                  </Trans>
                </span>
                :
                <span className={'pl-1'}>
                  {' '}
                  ${(currentPoints - appliedValueViaStoreCredit).toFixed(2)}
                </span>
              </label>

              {/* <CommonForm
                  schema={schema({ currentPoints, totalAmountUnpaid })}
                  formTypeJson={formInputJson}
                  SubmitButtonProps={this.renderSubmitButton}
                  // onSubmit={this.handleApply}
                  onSubmit={async (formValues, resetForm) => {
                    if (!this.state.isCallInProgress) {
                      this.setState({
                        isCallInProgress: true,
                      })
                      checkoutContainer.isPaymentCallsInProgress = true
                      await this.handleApply(formValues, resetForm)
                      checkoutContainer.isPaymentCallsInProgress = false
                      this.setState({
                        isCallInProgress: false,
                      })
                    }
                  }}
                  hasInlineSubmitButton={true}
                  classNameValue={{
                    formLabelClassName: 'text-uppercase email-label',
                  }}
                />}
            </div>
          </div> */}
            <Form>
              <Row className="justify-content-end" noGutters>
                <Button
                  className="rounded-0 px-5 py-2 mb-3 shipping-method-continue btn-bg-blue express-d-none"
                  onClick={this.handleNext}
                  disabled={this.isCallInProgress}
                  data-testid="qa-store-credits-continue">
                  {i18nTranslate('shippingMethod.continue', 'Continue')}
                </Button>
              </Row>
            </Form>
          </>
        )}
      </div>
    )
  }
}

export default StoreCredits
export { StoreCredits }
