import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import { customerContainer, outFitsContainer } from 'src/models'
import { outfitModalState } from 'src/views/components/OutfitComponents/OutFitsModel'

@observer
class ProductAddToOutfit extends Component {
  renderOutfitItem = (item = {}, index = '') => {
    return (
      <Dropdown.Item
        as="button"
        key={index}
        value={item.id}
        eventKey={item.name}
        data-testid="qa-dropdown-outfit">
        {item.name}
      </Dropdown.Item>
    )
  }

  handleAddtoOutfit = async (outfitName = '') => {
    const { product = {}, history = {}, location = {} } = this.props || {}
    if (!customerContainer.isRegisterUser) {
      const { pathname, search } = location
      customerContainer.navigeBackTo = pathname + search
      history.push(pageNames.signIn)
      return
    }

    const {
      skuId = '',
      name = '',
      imageURL = '',
      productType = '',
      productId = '',
    } = product
    const currentSkuOutfitProperties = {
      key: skuId,
      name,
      outFitItem: 'new',
      properties: {
        imageURL,
        type: productType,
        productId,
      },
    }
    if (outfitName === 'newoutfit') {
      outFitsContainer.setNewOutfit()
      outFitsContainer.currentOutFit.listItems.push(currentSkuOutfitProperties)
    } else {
      const outFitsListId = outFitsContainer.outFitsList.find(
        outfit => outfit.name === outfitName
      ).id
      outFitsContainer.setCurrentOutFit(outFitsListId)
      if (
        !outFitsContainer.currentOutFit.listItems.some(
          item => item.key === skuId
        )
      ) {
        outFitsContainer.currentOutFit.listItems.push(
          currentSkuOutfitProperties
        )
      }
      outFitsContainer.setListType('outFit')
    }
    outfitModalState.toggleModal()
  }

  render() {
    return (
      <div className="outfit-list-cont bg-white">
        <Dropdown
          className="text-center bg-white"
          onSelect={this.handleAddtoOutfit}>
          <Dropdown.Toggle variant="" id="dropdown-basic" className="p-0">
            <span className="addtoOufut-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                width="37.5"
                height="37.5"
                viewBox="0 0 900.000000 900.000000"
                preserveAspectRatio="xMidYMid meet">
                <g
                  transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none">
                  <path d="M4045 7293 c-293 -62 -528 -273 -626 -563 -27 -79 -33 -114 -37 -212 -4 -112 -3 -120 22 -172 50 -102 140 -159 251 -159 83 0 129 18 190 74 58 53 85 114 95 215 10 99 33 147 96 203 113 99 273 94 380 -13 79 -80 102 -173 68 -286 -14 -47 -31 -68 -163 -199 -159 -158 -229 -251 -292 -386 -63 -137 -80 -217 -88 -404 l-6 -164 -210 -112 c-115 -61 -464 -246 -775 -410 -311 -164 -947 -500 -1415 -748 -467 -247 -949 -501 -1070 -565 -199 -105 -227 -123 -290 -187 -82 -82 -118 -144 -145 -251 -50 -195 -6 -373 128 -516 73 -77 136 -120 230 -157 l67 -26 3730 -3 c3689 -2 3731 -2 3808 18 180 45 330 181 393 356 88 242 -16 537 -235 663 -36 21 -264 143 -506 271 -242 128 -660 350 -930 493 -1329 705 -1827 969 -2012 1066 l-203 106 0 105 c0 58 6 128 14 155 30 112 99 211 217 310 145 122 252 281 299 445 31 106 38 281 16 388 -33 162 -100 290 -214 413 -167 179 -379 270 -619 268 -54 0 -129 -7 -168 -16z m985 -2986 c1181 -625 1567 -830 1815 -962 127 -67 399 -211 605 -320 206 -109 379 -202 385 -206 5 -5 -1596 -9 -3620 -9 -2132 0 -3625 4 -3620 9 10 9 148 83 1045 556 300 158 696 367 880 465 184 97 556 293 825 436 270 142 576 304 680 359 186 100 191 101 220 87 17 -8 370 -195 785 -415z" />
                </g>
              </svg>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {outFitsContainer.outFitsList.map((item, index) => {
              return this.renderOutfitItem(item, index)
            })}
            <Dropdown.Item
              as="button"
              value="newoutfit"
              eventKey="newoutfit"
              data-testid="qa-dropdown-outfit">
              {i18nTranslate('pdp.newOutfit', 'Create New Oufit', {
                nameSpace: 'ssr-resource',
              })}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
}

export { ProductAddToOutfit }
