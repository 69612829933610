import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { i18nTranslate } from 'src/utils'
import { Accordion, Card, ListGroup, Button } from 'react-bootstrap'
import { VscChevronUp, VscChevronDown } from 'react-icons/vsc'
import isArray from 'lodash/isArray'
import { getCategoryLinks } from './deps'
import { BrowseLink } from './MobileMenuCategories'

const SubMenuAccordian = ({ categories, handleLinkClick }) => {
  const nameSpace = 'ssr-resource'
  const [selectedKey, setSelectedKey] = useState(null)
  return (
    <Accordion onSelect={setSelectedKey}>
      {categories?.map((category, categoryIndex) => {
        const eventKey = categoryIndex + 1
        const categoryName = category?.properties?.name || ''

        if (isArray(category.subCategories)) {
          // Fix for CX121-4435
          let isToHideCategory =
            category?.identifier?.toLowerCase() ==
              'skin_and_beauty_categories' ||
            category?.identifier?.toLowerCase() == 'nutrition_categories' ||
            category?.subCategories?.[0]?.isFromOurImpact

          return (
            <Card key={category?.identifier}>
              <Card.Header className="bg-white p-0">
                <Accordion.Toggle
                  as={Button}
                  eventKey={eventKey}
                  className="nu-menu-subheading w-100 bg-white text-left"
                  aria-expanded={eventKey === selectedKey}>
                  {categoryName}
                  {eventKey === selectedKey ? (
                    <VscChevronUp className="float-right" size="24px" />
                  ) : (
                    <VscChevronDown className="float-right" size="24px" />
                  )}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={eventKey}>
                <Card.Body>
                  <ListGroup>
                    {category.subCategories.map(
                      (subCategory, subCategoryIndex) => {
                        return (
                          <BrowseLink
                            key={`browse-link-${subCategoryIndex}`}
                            category={subCategory}
                            handleLinkClick={handleLinkClick}
                          />
                        )
                      }
                    )}
                  </ListGroup>
                  {!isToHideCategory && (
                    <Link
                      className="list-group-item nu-remove-border nu-showall-link"
                      to={getCategoryLinks(category, false)}
                      onClick={handleLinkClick}
                      role="button">
                      {i18nTranslate('menu.seeAll', 'See All', {
                        nameSpace: 'ssr-resource',
                      })}{' '}
                      {categoryName}
                    </Link>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        } else {
          return (
            <BrowseLink
              key={`browse-link-${eventKey}`}
              category={category}
              handleLinkClick={handleLinkClick}
            />
          )
        }
      })}
    </Accordion>
  )
}

export default SubMenuAccordian
