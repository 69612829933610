// import get from 'lodash/get'

function getCategoryLinks(category, isTopLevelCategory) {
  const { identifier } = category
  const templateUrl = category?.milestone?.templateUrl || ''
  const templateId = category?.milestone?.templateId || ''
  const templateName = templateUrl
    .split('/')
    .find(item => item.match(/template[\d]/))
  const template =
    templateName || (templateId ? `template${templateId}` : `catalog`)
  const url = isTopLevelCategory ? '/categoryLanding/' : `/${template}/`

  return `${url}${identifier}`
}

export { getCategoryLinks }
