import { i18nTranslate } from 'src/utils'

const localeList = () => {
  return [
    {
      name: 'English',
      key: 'en',
      value: 'en-US',
      country: 'UNITED STATES',
      currency: 'USD',
      image: 'https://raderain.sirv.com/flags/usa.png',
      alt: i18nTranslate('icons.usFlag', 'united states flag icon', {
        nameSpace: 'ssr-resource',
      }),
      studioPageId: 1,
      title: 'EN IN USD',
    },
    {
      name: 'English',
      key: 'en',
      value: 'en-CA',
      country: 'Canada',
      currency: 'CAD',
      image: 'https://raderain.sirv.com/flags/usa.png',
      studioPageId: 2,
      title: 'CAD | English',
    },
    {
      name: 'French',
      key: 'fr',
      value: 'fr-CA',
      country: 'Canada',
      currency: 'CAD',
      image: 'https://raderain.sirv.com/flags/usa.png',
      studioPageId: 2,
      title: 'CAD | French',
    },
  ]
}
const transformLocalList = () => {
  const countryList = localeList().reduce((acc, item) => {
    const locale = item.value
    const country = locale.split('-').length ? locale.split('-')[1] : ''
    if (acc.hasOwnProperty(country) && country) {
      acc[country] = [...acc[country], item]
    } else {
      acc[country] = [item]
    }
    return acc
  }, {})
  return countryList
}

export { localeList, transformLocalList }
