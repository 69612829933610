import React from 'react'
import { i18nTranslate, enablePhoneVerification } from 'src/utils'
import { brandAffiliate, retailCustomer } from 'src/static/fixtures/links'
import { modalState } from 'src/views/components'
import { APPConfig, getLocalStorage, getSessionStorage } from 'config/appConfig'
import { IS_BROWSER, convertToBoolean } from 'src/utils'
import { accountsContainer } from 'src/models'

// local & session storages are deleted so kept outside the function to keep it in the filescope
// instead of function scope
let isMemberConversion = false

function TermsOfUse(props) {
  const conversionMember = getSessionStorage('mem') || ''
  const sponsorIdInSession = getSessionStorage('conv-sponsor') || ''
  const IsAuthenticated = getLocalStorage('IsAuthenticated') || ''

  if (
    conversionMember == 'ok' &&
    sponsorIdInSession !== '' &&
    IsAuthenticated != ''
  ) {
    isMemberConversion = true
  }

  if (
    accountsContainer?.selectedAccountType === 'Preferred Customer/Member' ||
    isMemberConversion
  ) {
    return <MemberTermsOfUse {...props} />
  }
  return <RetailTermsOfUse {...props} />
}

function RetailTermsOfUse(props) {
  const { retailCustomerLinks, clientDomainName, openDocument } = props || {}
  return (
    <>
      {i18nTranslate(
        'signup.acknowledge',
        'I acknowledge I have read and understand the'
      )}
      <a
        name="link"
        href={'https://' + clientDomainName + retailCustomerLinks?.termsOfUse}
        target="_blank"
        rel="noopener noreferrer"
        onClick={event => {
          event.preventDefault()
          openDocument(
            'https://' + clientDomainName + retailCustomerLinks?.termsOfUse
          )
        }}>
        {' '}
        <u>{i18nTranslate('signup.termsOfUse', 'Terms of Use')}</u>
      </a>
    </>
  )
}

function MemberTermsOfUse(props) {
  const { retailCustomerLinks, clientDomainName, openDocument } = props || {}
  return (
    <>
      {i18nTranslate(
        'signup.member',
        'I have read, understand, and agree to the'
      )}
      <a
        name="link"
        href={'https://' + clientDomainName + retailCustomerLinks?.termsOfUse}
        target="_blank"
        rel="noopener noreferrer"
        onClick={event => {
          event.preventDefault()
          openDocument(
            'https://' + clientDomainName + retailCustomerLinks?.termsOfUse
          )
        }}>
        {' '}
        <u>{i18nTranslate('signup.termsOfUse', 'Terms of Use')}</u>
      </a>{' '}
      {i18nTranslate('signup.and', 'and')}
      <a
        name="link"
        href={retailCustomerLinks?.memberAgreement}
        target="_blank"
        rel="noopener noreferrer"
        onClick={event => {
          event.preventDefault()
          openDocument(retailCustomerLinks?.memberAgreement)
        }}>
        {' '}
        <u>{i18nTranslate('signup.memberAgreement', 'Member Agreement')}</u>
      </a>{' '}
      {i18nTranslate(
        'signup.memberAgreementPrefix',
        'including the Arbitration Policy and Limitation of Liability.'
      )}
    </>
  )
}

/**
 * FormInputLabels class handles localization and linking
 * for legal document links displayed in form input labels.
 */
class FormInputLabels {
  inputLocalelabel = {}
  inputAriaLabelJson = {}
  brandAffiliateLinks = {}
  retailCustomerLinks = {}
  showModal = false

  constructor() {
    this.composeLocaleBasedLegalLinks()
  }

  /**
   * Opens the given document link in a new browser window.
   * Checks if window is available before trying to open.
   */
  openDocument = documentLink => {
    if (typeof window !== 'undefined') {
      window.open(documentLink)
    }
  }

  /**
   * Composes legal document links based on the
   * default locale. Filters the brandAffiliate
   * and retailCustomer link configs to only
   * include links for the active locale.
   * Sets the brandAffiliateLinks and
   * retailCustomerLinks class properties.
   */
  composeLocaleBasedLegalLinks = () => {
    const defaultLocale =
      APPConfig?.getActiveAppConfig()?.defaultLocale ||
      getLocalStorage('defaultLocale')

    const locale = defaultLocale?.replaceAll('-', '_')?.toLowerCase()
    const activeLocale = [locale]

    const filteredBrandLocale = Object.keys(brandAffiliate)
      .filter(key => activeLocale.includes(key))
      .reduce((obj, key) => {
        obj[key] = brandAffiliate[key]
        return obj
      }, {})
    this.brandAffiliateLinks = filteredBrandLocale[locale]

    const filteredRetailLocale = Object.keys(retailCustomer)
      .filter(key => activeLocale.includes(key))
      .reduce((obj, key) => {
        obj[key] = retailCustomer[key]
        return obj
      }, {})

    this.retailCustomerLinks = filteredRetailLocale[locale]
  }

  /*
    This function returns the label translated values
    JSON key is either 'name' or 'labelKey' or 'placeholderKey' for form input 
  */
  getFormLabels = inputLabel => {
    const enableJITSU = convertToBoolean(APPConfig?.getAppConfig()?.enableJITSU)
    if (this.inputLocalelabel[inputLabel]) {
      return this.inputLocalelabel[inputLabel]
    }
    const { clientDomainName = '' } = APPConfig?.getAppConfig() || {}
    const countryCode =
      APPConfig?.getActiveAppConfig()?.defaultLocale?.slice(-2) || ''
    this.inputLocalelabel = {
      couponCode: i18nTranslate('ariaLabel.couponCode', 'Coupon code'),
      promoCode: i18nTranslate('ariaLabel.promoCode', 'Promo code'),
      discountCode: i18nTranslate('ariaLabel.discountCode', 'Discount code'),
      reviewDesclength: i18nTranslate(
        'reviewDesclength',
        'Max allowed character is 1000'
      ),
      anonymous: i18nTranslate('anonymous', 'Make me Anonymous'),
      reviewTitle: i18nTranslate('reviewTitle', 'Review Title *'),
      reviewDescription: i18nTranslate('reviewDescription', 'Review *'),
      firstName: i18nTranslate('firstName', 'First Name *'),
      businessAddress: i18nTranslate(
        'businessAddress',
        'Business Legal Address'
      ),
      legalAddress: i18nTranslate('legalAddress', 'Legal Physical Address'),
      bEFirstName: i18nTranslate('bEFirstName', 'Business Entity Name *'),
      bEToolTip: i18nTranslate(
        'bEToolTip',
        'Enter the official name of your business entity here. This could be a partnership, limited liability company, or any other type of business organization permitted in your market.'
      ),
      lastName: i18nTranslate('lastName', 'Last Name *'),
      // dateOfBirth: i18nTranslate('dateOfBirth', 'Date Of Birth '),
      sponsoringBAID: i18nTranslate(
        'sponsoringBAID',
        'Sponsoring Brand Affiliate ID'
      ),
      sponsorID: i18nTranslate('sponsorID', 'Sponsor ID *'),
      sponsorIDConvert: i18nTranslate('sponsorIDConvert', 'Sponsor ID *'),
      sponsorName: i18nTranslate('sponsorName', 'Sponsor Name'),
      sponsoringAffiliateName: enableJITSU
        ? i18nTranslate(
            'sponsorChange.affiliateNameLabel',
            'Sponsoring affiliate name *'
          )
        : i18nTranslate(
            'sponsorChange.affiliateName',
            'Sponsoring affiliate name'
          ),
      info2: i18nTranslate(
        'info2',
        'Upon conversion to a Brand Affiliate, you will no longer be eligible to earn Nu Skin Reward points. Existing points will be accessible for redemption until they have been consumed or until they expire.'
      ),
      // email: i18nTranslate('email', 'Email Address *'),
      businessEmail: i18nTranslate('businessEmail', 'Business Email *'),
      businessPhoneNumber: i18nTranslate(
        'businessPhoneNumber',
        'Business Mobile Phone *'
      ),
      DoB: enableJITSU
        ? i18nTranslate('dob.label', 'Date of Birth')
        : i18nTranslate('dob', 'Date of Birth (Optional)'),
      gender: i18nTranslate('gender', 'Gender (Optional)', {
        nameSpace: 'ssr-resource',
      }),
      preferredLanguage: i18nTranslate(
        'preferredLanguage',
        'Preferred Language (Optional)'
      ),
      preferredName: i18nTranslate(
        'preferredName',
        'Preferred Name (Optional)'
      ),
      organizationName: i18nTranslate(
        'organizationName',
        'Organization Name *'
      ),
      addressLine1: i18nTranslate('addressLine1', 'Address Line 1 *'),
      hongKongAddressLine1: i18nTranslate(
        'hongKong.addressline1',
        'Address (Room, Floor, Building) *',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      addressLine1PlaceHolder: i18nTranslate(
        'addressLine1PlaceHolder',
        'Street address, PO box, etc.'
      ),
      leagalAddressLine1: i18nTranslate(
        'leagalAddressLine1',
        'Legal Physical Address *'
      ),
      leagalAddressLine1PlaceHolder: i18nTranslate(
        'leagalAddressLine1PlaceHolder',
        'Street address, PO box, etc.'
      ),
      addressLine2: i18nTranslate('addressline2', 'Address Line 2'),
      hongKongAddressLine2: i18nTranslate(
        'hongKong.addressline2',
        'Address (Court, Street)',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      address: i18nTranslate('address', 'Address *'),
      hongKongDistrict: i18nTranslate(
        'hongKong.district',
        'District: (Hong Kong address only) *',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      addressLine2PlaceHolder: i18nTranslate(
        'addressLine2PlaceHolder',
        'Appartment, building, floor, etc'
      ),
      city: i18nTranslate('city', 'City *'),
      ville: i18nTranslate('ville', 'Ville *'),
      province: i18nTranslate('province', 'Province *'),
      TownCity: i18nTranslate('townCity', 'Town/City *'),
      townCityLocality: i18nTranslate(
        'townCityLocality',
        'Town/City/Locality *'
      ),
      state: i18nTranslate('states', 'State *'),
      stateList: i18nTranslate('stateLists', 'State *'),
      streetNumber: i18nTranslate('streetNumber', 'Street and Number *'),
      buildingFlat: i18nTranslate(
        'buildingFlat',
        'Building, flat, floor, suite, unit',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      county: i18nTranslate('county', 'County *'),
      // districtList: i18nTranslate('districtLists', 'District *'),
      wardList: i18nTranslate('wardLists', 'Ward *'),
      region: i18nTranslate('region', 'Region *'),
      districtList: i18nTranslate(
        'districtList',
        'Suburb / District / Commune *'
      ),
      districtListPlaceHolder: i18nTranslate(
        'districtListPlaceHolder',
        'Suburb / District / Commune'
      ),
      zipCode: i18nTranslate('zipCode', 'Zip Code *'),
      zip: i18nTranslate('zipCode', 'Zip Code *'),
      postalCode: i18nTranslate('postalCode', 'Postal Code *', {
        nameSpace: 'ssr-resource',
      }),
      postalCodePT: i18nTranslate('postalCodePT', 'Postal Code (XXXX-XXX)*', {
        nameSpace: 'ssr-resource',
      }),
      postalCodePlaceHolder: i18nTranslate(
        'postalCodePlaceHolder',
        'Postal Code',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      country: i18nTranslate('country', 'Country *'),
      isDefault: i18nTranslate('isDefault', 'Save as my default address'),
      firstNamePlaceHolder: i18nTranslate('firstNamePlaceHolder', 'First Name'),
      bEFirstNamePlaceHolder: i18nTranslate(
        'bEFirstNamePlaceHolder',
        'Business Legal Name'
      ),
      sponsorAffiliateNamePlaceHolder: i18nTranslate(
        'sponsorAffiliateNamePlaceHolder',
        'Sponsoring Affiliate Name *'
      ),
      sponsorAffiliateName: enableJITSU
        ? i18nTranslate(
            'sponsorChange.affiliateNameLabel',
            'Sponsoring affiliate name *'
          )
        : i18nTranslate(
            'sponsorChange.affiliateName',
            'Sponsoring affiliate name'
          ),
      lastNamePlaceHolder: i18nTranslate('lastNamePlaceHolder', 'Last Name'),
      sponsorIDPlaceHolder: i18nTranslate(
        'sponsorIDPlaceHolder',
        'Sponsor ID *'
      ),
      sponsorIDConvertPlaceHolder: i18nTranslate(
        'sponsorIDConvertPlaceHolder',
        'Sponsor ID *'
      ),
      sponsorNamePlaceHolder: i18nTranslate(
        'sponsorNamePlaceHolder',
        'Sponsor Name'
      ),
      middleName: i18nTranslate('middleName', 'M.I'),
      middleNamePlaceHolder: i18nTranslate('middleNamePlaceHolder', 'M.I'),
      cityPlaceHolder: i18nTranslate('cityPlaceHolder', 'City'),
      villePlaceHolder: i18nTranslate('villePlaceHolder', 'Ville *'),
      townCityPlaceHolder: i18nTranslate('townCityPlaceHolder', 'Town/City'),
      townCityLocalityPlaceHolder: i18nTranslate(
        'townCityLocalityPlaceHolder',
        'Town/City/Locality'
      ),
      suburb: i18nTranslate('suburb', 'Suburb *'),
      suburbPlaceHolder: i18nTranslate('suburbPlaceHolder', 'Suburb'),
      addressLabel: i18nTranslate('addressLabel', 'Address *'),
      addressPlaceHolder: i18nTranslate('addressPlaceHolder', 'Address *'),
      address2Label: i18nTranslate(
        'address2Lable',
        'Building Name, Room Number'
      ),
      address2LabelPlaceholder: i18nTranslate(
        'address2LabelPlaceholder',
        'Building Name, Room Number'
      ),
      stateName: i18nTranslate('stateName', 'Prefectures *'),
      stateNamePlaceHolder: i18nTranslate(
        'stateNamePlaceHolder',
        'Select Your Prefectures *'
      ),
      cityName: i18nTranslate('cityName', 'Municipalities *'),
      cityNamePlaceholder: i18nTranslate(
        'cityNamePlaceholder',
        'Municipalities *'
      ),
      districtName: i18nTranslate('districtName', 'District *'),
      districtInputPlaceholder: i18nTranslate(
        'districtInputPlaceholder',
        'District *'
      ),
      streetNoOutsideInside: i18nTranslate(
        'districtName',
        'Street/no. outside/no. Inside *'
      ),
      betweenStreet: i18nTranslate(
        'betweenStreet',
        'Between streets/complement for street [Optional]',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      cityMunicipality: i18nTranslate(
        'cityMunicipality',
        'City/Municipality/City Hall *'
      ),
      coloniaArea: i18nTranslate('coloniaArea', 'Colonia/Area *', {
        nameSpace: 'ssr-resource',
      }),
      cityPlaceHolderMX: i18nTranslate(
        'cityPlaceHolderMX',
        'City/Municipality/City Hall'
      ),
      addressLine1PlaceholderMX: i18nTranslate(
        'addressLine1PlaceholderMX',
        'Street/no. outside/no. Inside'
      ),
      addressLine2PlaceholderMX: i18nTranslate(
        'addressLine2PlaceholderMX',
        'Between streets/complement for street [Optional]'
      ),
      provincePlaceHolder: i18nTranslate('provincePlaceHolder', 'Province'),
      statePlaceHolder: i18nTranslate('statePlaceHolder', 'State'),
      countryName: i18nTranslate('countryName', 'Country *'),
      countryPlaceHolder: i18nTranslate('countryPlaceHolder', 'Country'),
      emailAddress: i18nTranslate(
        'emailAddress',
        'Email Address For Order Confirmation *'
      ),
      emailAddressPlaceHolder: i18nTranslate(
        'emailAddressPlaceHolder',
        'Email address'
      ),
      shippingInstructionsPlaceHolder: i18nTranslate(
        'shippingInstructionsPlaceHolder',
        'Shipping Instructions'
      ),
      contactInformation: i18nTranslate(
        'contactInformation',
        'Contact Information'
      ),
      defaultAddressPlaceHolder: i18nTranslate('defaultAddressPlaceHolder', ''),
      setDefaultAddress: i18nTranslate(
        'setDefaultAddress',
        'Save as my default address'
      ),
      setDefaultShippingAddress: i18nTranslate(
        'setDefaultShippingAddress',
        'Set to Default Subscription Shipping Address'
      ),
      estimateShipping: i18nTranslate('estimateShipping', 'Estimate Shipping'),
      zipCodePlaceHolder: i18nTranslate('zipCodePlaceHolder', 'Zip Code'),
      oldPassword: i18nTranslate('oldPassword', 'Old Password'),
      newPassword: i18nTranslate('newPassword', 'New Password'),
      confirmNewPassword: i18nTranslate(
        'confirmNewPassword',
        'Confirm New Password'
      ),
      passwordPlaceHolder: i18nTranslate(
        'passwordPlaceHolder',
        'Must be at least 8 characters'
      ),
      signInpasswordPlaceHolder: i18nTranslate(
        'signInpasswordPlaceHolder',
        'Please Enter your password'
      ),
      enterAnswer: i18nTranslate('enterAnswer', 'Enter Answer'),
      mobilePhoneNumber: i18nTranslate(
        'mobilePhoneNumber',
        'Mobile Phone Number *'
      ),
      phoneText: i18nTranslate('phoneText', 'Phone *'),
      phoneNumber: i18nTranslate('mobilePhoneNumber', 'Mobile Phone Number *'),
      phoneCountryCode: i18nTranslate('phoneCountryCode', 'Country Code *'),
      optionalPhoneCountryCode: i18nTranslate(
        'optionalPhoneCountryCode',
        'Country Code'
      ),
      dateOfBirthPlaceHolder: i18nTranslate(
        'dateOfBirthPlaceHolder',
        'Date Of Birth'
      ),
      sponsorId: i18nTranslate('sponsorId', 'Sponsor ID *'),
      sponsorIdPlaceHolder: i18nTranslate(
        'sponsorIdPlaceHolder',
        'Sponsor ID *'
      ),
      // signupTaxId: i18nTranslate('accounts.taxID', 'Social Security Number *'),
      signupTaxIdPlaceHolder: i18nTranslate(
        'signupTaxIdPlaceHolder',
        'Social Security Number'
      ),
      signupAddressLine1: i18nTranslate(
        'signupAddressLine1',
        'Address Line1 *'
      ),
      signupAddressLine1PlaceHolder: i18nTranslate(
        'signupAddressLine1PlaceHolder',
        'Address Line1 *'
      ),
      signupAddressLine2: i18nTranslate(
        'signupAddressLine2',
        'Address Line2 (Optional)'
      ),
      signupAddressLine2PlaceHolder: i18nTranslate(
        'signupAddressLine2PlaceHolder',
        'Address Line2'
      ),
      homeCountry:
        !enableJITSU && i18nTranslate('account.homeCountry', 'Home Country'),
      signupCity: i18nTranslate('signupCity', 'City *'),
      signupCityPlaceHolder: i18nTranslate('signupCityPlaceHolder', 'City *'),
      signupState: i18nTranslate('signupState', 'State *'),
      signupStatePlaceHolder: i18nTranslate(
        'signupStatePlaceHolder',
        'State *'
      ),
      signupPostalCode: i18nTranslate('signupPostalCode', 'Postal Code *'),
      signupZip: i18nTranslate('signupZip', 'Zip Code *'),
      signupPostalCodePlaceHolder: i18nTranslate(
        'signupPostalCodePlaceHolder',
        'Postal Code *'
      ),
      socialSecuityNumberTooltip: i18nTranslate(
        'socialSecurity.numberTooltip',
        `Why is this required?
        In order to comply with federal tax requirements we obtain a Social Security Number to report paid commissions.`
      ),
      distributorMinAge: i18nTranslate(
        'distributorMinAge',
        `You must be 18 years of age to sign up as a Brand Affiliate.`
      ),
      whatIsThisMessage: i18nTranslate(
        'signup.whatIsThisMessage',
        'A Brand Affiliate is a Nu Skin sales representative who sells Nu Skin products. If someone referred you to Nu Skin but you don’t know their ID, you can ask them for their number or for a special invite link to create your account. Nu Skin will link your account to the Brand Affiliate you identify, and they may reach out to you to provide product support based on your order history. Please see our Privacy Notice for more details.'
      ),
      whatIsThisBAMessage: i18nTranslate(
        'signup.whatIsThisBAMessage',
        'A Brand Affiliate is a Nu Skin sales representative who sells Nu Skin® products. If someone referred you, enter their Nu Skin member ID here or ask them for a special link to create your account. We will link your account to the Brand Affiliate you identify, and they may contact you to provide product support based on your order history. See our Privacy Notice for more info.'
      ),
      refundAndPrivacyMember: (
        <>
          {i18nTranslate(
            'signup.understand',
            'I have read, understand, and agree to the'
          )}{' '}
          <a
            name="link"
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
            href="https://nuglobalbasev1-dev1.skavacommerce.com/">
            <u> {i18nTranslate('signup.termsOfUse', 'Terms of Use')}</u>
          </a>{' '}
          {i18nTranslate('signup.and', 'and')}{' '}
          <a
            name="link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://nuglobalbasev1-dev1.skavacommerce.com/">
            <u>{i18nTranslate('signup.memberAgreement', 'Member Agreement')}</u>
          </a>{' '}
          {i18nTranslate(
            'signup.arbitrationPolicies',
            'including the Limitation of Liability and Arbitration Policies.*'
          )}
        </>
      ),
      refundAndPrivacy: (
        <>
          {i18nTranslate(
            'signup.acknowledge',
            'I acknowledge I have read and understand the'
          )}{' '}
          <a
            name="link"
            href={this.retailCustomerLinks?.refundPolicy}
            tabIndex={0}
            target="_blank"
            rel="noopener noreferrer"
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.retailCustomerLinks?.refundPolicy)
            }}>
            <u> {i18nTranslate('signup.refundPolicy', 'Refund Policy')}</u>
          </a>{' '}
          {i18nTranslate('signup.and', 'and')}{' '}
          <a
            name="link"
            href={this.retailCustomerLinks?.privacyNotice}
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.retailCustomerLinks?.privacyNotice)
            }}>
            <u>{i18nTranslate('signup.privacyNotice', 'Privacy Notice')}</u>
          </a>
        </>
      ),
      refundAndPrivacyBrand: (
        <>
          {i18nTranslate(
            'signup.acknowledge',
            'I acknowledge I have read and understand the'
          )}
          <a
            href={this.brandAffiliateLinks?.salesPerformancePlan}
            name="link"
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.brandAffiliateLinks?.salesPerformancePlan)
            }}>
            {' '}
            <u>
              {i18nTranslate(
                'signup.salesPerformance',
                'Sales Performance Plan'
              )}
            </u>
          </a>
          ,{' '}
          <a
            name="link"
            href={this.brandAffiliateLinks?.privacyNotice}
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.brandAffiliateLinks?.privacyNotice)
            }}>
            <u> {i18nTranslate('signup.privacyNotice', 'Privacy Notice')}</u>
          </a>
          ,{' '}
          <a
            name="link"
            href={this.brandAffiliateLinks?.refundPolicy}
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.brandAffiliateLinks?.refundPolicy)
            }}>
            <u> {i18nTranslate('signup.refundPolicy', 'Refund Policy')}</u>
          </a>{' '}
          {i18nTranslate('signup.and', 'and')}{' '}
          <a
            name="link"
            href={this.brandAffiliateLinks?.salesCompensationSummary}
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
            onClick={event => {
              event.preventDefault()
              this.openDocument(
                this.brandAffiliateLinks?.salesCompensationSummary
              )
            }}>
            {' '}
            <u>
              {i18nTranslate(
                'signup.salesCompensation',
                'Sales Compensation Summary'
              )}
            </u>{' '}
          </a>
        </>
      ),
      termsOfUse: (
        <TermsOfUse
          openDocument={this.openDocument}
          clientDomainName={clientDomainName}
          retailCustomerLinks={this.retailCustomerLinks}
        />
      ),

      termsOfUseBrand: (
        <>
          {i18nTranslate(
            'signup.understand',
            'I have read, understand, and agree to the'
          )}
          <a
            name="link"
            href={this.brandAffiliateLinks?.brandAffiliateAgreement}
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
            onClick={event => {
              event.preventDefault()
              this.openDocument(
                this.brandAffiliateLinks?.brandAffiliateAgreement
              )
            }}>
            {' '}
            <u>
              {i18nTranslate(
                'signup.brandAffiliateAgreement',
                'Brand Affiliate Agreement'
              )}
            </u>
          </a>
          ,
          <a
            name="link"
            href={this.brandAffiliateLinks?.policiesAndProcedures}
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.brandAffiliateLinks?.policiesAndProcedures)
            }}>
            {' '}
            <u>{i18nTranslate('signup.policies', 'Policies and Procedures')}</u>
          </a>{' '}
          {i18nTranslate('signup.and', 'and')}{' '}
          <a
            name="link"
            href={this.retailCustomerLinks?.termsOfUse}
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.retailCustomerLinks?.termsOfUse)
            }}>
            <u>{i18nTranslate('signup.termsOfUse', 'Terms of Use')}</u>
          </a>{' '}
          {i18nTranslate(
            'signup.arbitrationPolicies',
            'including the Limitation of Liability and Arbitration Policies.*'
          )}
        </>
      ),
      signupHeader1: i18nTranslate(
        'signupHeader1',
        'Legal Documents & Agreements'
      ),
      legalHeaderRetail: i18nTranslate(
        'signupHeader1',
        'Legal Documents & Agreements'
      ),
      required_note: i18nTranslate(
        'required_note',
        'Fields marked with * are required'
      ),
      legalHeaderBrand: i18nTranslate(
        'signupHeader1',
        'Legal Documents & Agreements'
      ),
      legalHeaderMember: i18nTranslate(
        'signupHeader1',
        'Legal Documents & Agreements'
      ),
      signupPassword1: i18nTranslate(
        'signupPassword1',
        'Password must have at least 8 characters [ 1 upper case, 1 lower case, 1 special case, 1 number]'
      ),
      marketingEmailAndSms: i18nTranslate(
        'signupHeader2',
        'How would you like to receive promotional communications from Nu Skin? (Optional) This won’t affect communications you may receive relating to your Nu Skin business.'
      ),
      marketingEmailAndSmsBrand: i18nTranslate(
        'signupHeader2',
        'How would you like to receive promotional communications from Nu Skin? (Optional) This won’t affect communications you may receive relating to your Nu Skin business.'
      ),
      marketingEmailAndSmsRetail: i18nTranslate(
        'signupHeaderRetail',
        'How would you like to receive promotional communications from Nu Skin? (Optional)'
      ),
      signUpRewardLabel: i18nTranslate(
        'signupHeader3',
        'Nu Skin Rewards is a rewards-based program based on how you spend. The more you shop, the more rewards you will discover! (Optional)'
      ),
      signupBrandRepresentativeLabel: i18nTranslate(
        'signupHeader4',
        'Want to experience VIP-like product support and recommendations?'
      ),
      marketingEmail: i18nTranslate(
        'marketingEmail',
        'Opt in to emails and social media messages and notifications'
      ),
      signupSms: (
        <>
          {' '}
          {i18nTranslate('privacy.SMS', 'Opt in to SMS')} <br></br>{' '}
          {i18nTranslate(
            'signup.understand',
            'I have read, understand, and agree to the'
          )}{' '}
          <a
            name="link"
            href={this.retailCustomerLinks?.smsAgreement}
            target="_blank"
            rel="noopener noreferrer"
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.retailCustomerLinks?.smsAgreement)
            }}>
            <u> {i18nTranslate('signup.smsAgreement', 'SMS Agreement')}. </u>
          </a>
          {i18nTranslate(
            'signup.messageFrequency',
            'Message frequency will vary. Message and data rates may apply.'
          )}
        </>
      ),
      signupBrandSms: (
        <>
          {i18nTranslate('privacy.SMS', 'Opt in to SMS')} <br></br>{' '}
          {i18nTranslate(
            'signup.understand',
            'I have read, understand, and agree to the'
          )}{' '}
          <a
            href={this.brandAffiliateLinks?.smsAgreement}
            name="link"
            target="_blank"
            rel="noopener noreferrer"
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.brandAffiliateLinks?.smsAgreement)
            }}>
            <u>{i18nTranslate('signup.smsAgreement', 'SMS Agreement')}.</u>
          </a>
          {i18nTranslate(
            'signup.messageFrequency',
            'Message frequency will vary. Message and data rates may apply.'
          )}
        </>
      ),
      signupRewards: (
        <>
          {i18nTranslate('signup.rewards', 'Opt in to Nu Skin Rewards')}
          <br></br>
          {i18nTranslate(
            'signup.understand',
            'I have read, understand, and agree to the'
          )}{' '}
          <a
            name="link"
            href={this.retailCustomerLinks?.enjoyTermsAndConsitions}
            target="_blank"
            rel="noopener noreferrer"
            onClick={event => {
              event.preventDefault()
              this.openDocument(
                this.retailCustomerLinks?.enjoyTermsAndConsitions
              )
            }}>
            <u>
              {' '}
              {i18nTranslate('signup.termsConditions', 'Terms and Conditions.')}
            </u>
          </a>
        </>
      ),
      signupBrandRepresentative: (
        <>
          {i18nTranslate(
            'signup.contact',
            'Opt in to be contacted by NuSkin Brand Representative '
          )}

          <a
            name="link"
            href="#"
            tabIndex={0}
            target="_blank"
            rel="noopener noreferrer"
            onClick={event => {
              event.preventDefault()
              modalState.setModalMessage(
                <div>
                  {i18nTranslate(
                    'signup.learnMoreDesc',
                    `Nu Skin will link your
                  account to a Nu Skin sales representative (known as a Brand
                  Representative or Brand Affiliate). They will have access to
                  certain personal information, including your contact details
                  and order history. They may reach out to you to provide
                  customer support and more information about our products and
                  company. You can withdraw your consent at any time. Please see
                  our`
                  )}{' '}
                  <a
                    name="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    tabIndex={0}
                    href="https://legal.nuskin.com/privacy.html">
                    <u>
                      {' '}
                      {i18nTranslate('signup.privacyNotice', 'Privacy Notice')}
                    </u>
                  </a>{' '}
                  {i18nTranslate('signup.formoredetails', 'for more details.')}
                </div>,
                i18nTranslate('catalog.OKButton', 'Ok', {
                  nameSpace: 'ssr-resource',
                })
              )
            }}>
            <u>{i18nTranslate('signup.learnmore', 'Learn More')}</u>
          </a>
        </>
      ),
      guestPromotionCheckbox: (
        <>
          {i18nTranslate(
            'signup.promotionalCommunication',
            'How would you like to receive promotional communications from Nu Skin?'
          )}
        </>
      ),
      smsOptIn: (
        <>
          <span>{i18nTranslate('signup.sms', 'SMS')}</span>
          <br />
          {i18nTranslate(
            'signup.smsOptIn',
            'I have read, understand and agree to the'
          )}{' '}
          <a
            name="link"
            href={this.retailCustomerLinks?.smsAgreement}
            target="_blank"
            className="font-weight-bold"
            rel="noopener noreferrer"
            onClick={event => {
              event.preventDefault()
              this.openDocument(this.retailCustomerLinks?.smsAgreement)
            }}>
            <u> {i18nTranslate('signup.smsAgreement', 'SMS Agreement')}</u>
          </a>
          {'. '}
          {i18nTranslate(
            'signup.smsOptInMessage',
            'Message frequency will vary. Message and data rates may apply.'
          )}
        </>
      ),
      emailOptIn: <>{i18nTranslate('signup.emailOptIn', 'Email')}</>,
      dateOfBirth: i18nTranslate('ariaLabel.dateOfBirth', 'Date Of Birth'),
      //termsOfUse: i18nTranslate('ariaLabel.termsOfUse', 'Terms of use agreement *'),
      //sponsor: i18nTranslate('ariaLabel.sponsorId', 'Sponsor ID'),
      signupTaxId: i18nTranslate(
        'ariaLabel.signupTaxId',
        'Social Security Number (xxx-xxx-xxx)'
      ),
      // termsOfUse: i18nTranslate('ariaLabel.termsOfUse', 'Terms of use agreement *'),
      phone: i18nTranslate('mobilePhoneNumber', 'Mobile Phone Number *'),
      optionalPhone: i18nTranslate(
        'optionalPhoneNumber',
        'Mobile Phone Number'
      ),
      checkoutPhone: i18nTranslate(
        'ariaLabel.phoneNumber',
        'Mobile Phone Number *'
      ),
      phoneNumberPlaceHolder: i18nTranslate(
        'phoneNumberPlaceHolder',
        'Mobile Phone Number *',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      businessPhoneNumberPlaceHolder: i18nTranslate(
        'businessPhoneNumberPlaceHolder',
        'Business Mobile Phone'
      ),
      email:
        enablePhoneVerification() &&
        IS_BROWSER &&
        window.location.pathname.indexOf('/myaccount/') > -1
          ? i18nTranslate('emailText', 'Email *')
          : i18nTranslate('email', 'Email Address *'),
      password: i18nTranslate('passwordMessage', 'Password *'),
      passwordPh: i18nTranslate('passwordPh', 'Password'),
      confirmPasswordPh: i18nTranslate(
        'confirmPasswordPh',
        'Re-enter Password'
      ),
      confirmPassword: i18nTranslate('confirmPassword', 'Re-enter Password *'),
      securityQuestion: i18nTranslate('securityQuestion', 'Security Question'),
      answer: i18nTranslate('answer', 'Answer'),
      emailAlerts: i18nTranslate(
        'emailAlerts',
        'Send me emails about my orders'
      ),
      loyaltyOpt: i18nTranslate(
        'loyaltyOpt',
        'Opt in to Nu Skin Rewards Program'
      ),
      createAccount: i18nTranslate('createAccount', 'Create My Account'),
      cardFirstName: i18nTranslate('cardFullName', 'Name on Card *'),
      expiryDate: i18nTranslate('paymentData.expiryDate', 'Expiration Date *'),
      cardLastName: i18nTranslate('cardLastName', 'Last Name'),
      cardSponsorID: i18nTranslate('cardSponsorID', 'Sponsor ID *'),
      cardNumber: i18nTranslate('payment.cardNumber', 'Card Number *'),
      reEnterCardNumber: i18nTranslate(
        'payment.reEnterCardNumber',
        'Re-Enter Card Number *'
      ),
      expiryMonth: i18nTranslate('payment.expiryMonth', 'Month'),
      expMonthPlaceholder: i18nTranslate('payment.expMonthPlaceholder', 'MM'),
      expDatePlaceholder: i18nTranslate(
        'payment.expDatePlaceholder',
        'MM / YY'
      ),
      expressExpDatePlaceholder: i18nTranslate(
        'expressCheckout.expDatePlaceholder',
        'Expiration date (MM/YY)'
      ),
      cardFullNamePlaceholder: i18nTranslate(
        'cardFullNamePlaceholder',
        'Name on Card'
      ),
      expiryYear: i18nTranslate('payment.expiryYear', 'Year'),
      expYearPlaceholder: i18nTranslate('payment.expYearPlaceholder', 'YY'),
      cardCVV: i18nTranslate('payment.cardCVV', 'Security Code*'),
      cardcvvPlaceHolder: i18nTranslate('payment.cardCVV', ''),
      PONumber: i18nTranslate('payment.PONumber', 'PO Number'),
      setToDefaultPayment: i18nTranslate(
        'payment.setToDefaultPayment',
        'Set as Default'
      ),
      orderNumber: i18nTranslate(
        'orderHistory.orderNumber',
        'Order Number (Found in confirmation email)*'
      ),
      billingZipCode: i18nTranslate(
        'billingaddress.billingzipCode',
        'Billing Zip code *'
      ),
      billingSameAsShipping: i18nTranslate(
        'billingaddress.billingSameAsShipping',
        'Billing Address same as Shipping Address'
      ),
      billingSameAsDefaultShipping: i18nTranslate(
        'billingaddress.billingSameAsDefaultShipping',
        'Billing Address same as Default Shipping Address'
      ),
      billingAddressTitle: i18nTranslate(
        'billingaddress.billingAddressTitle',
        'Billing Address'
      ),
      setDefaultSubscriptionPayment: i18nTranslate(
        'payment.setDefaultSubscriptionPayment',
        'Set to Default Subscription Payment Method'
      ),
      addPaymentToProfile: (
        <div className="pb-2" data-testid="qa-save-payment-type-label">
          {i18nTranslate(
            'billingaddress.addPaymentToProfile',
            'Save Payment Type'
          )}
          <br></br>
          <p>
            {i18nTranslate(
              'billingaddress.addPaymentToProfileConsentText',
              'By saving your payment details you agree to the'
            )}{' '}
            <a
              name="link"
              target="_blank"
              rel="noopener noreferrer"
              href={this.retailCustomerLinks?.storedCredentialsConsentAgreement}
              tabIndex={0}
              data-testid="qa-stored-credentials-consent-agreement"
              onClick={event => {
                event.preventDefault()
                this.openDocument(
                  this.retailCustomerLinks?.storedCredentialsConsentAgreement
                )
              }}>
              <u>
                {/* CX121-4791 - Google Translated*/}
                {i18nTranslate(
                  'billingaddress.addPaymentToProfile_consent_link',
                  'Stored Credentials Consent Agreement'
                )}
              </u>
            </a>
          </p>
        </div>
      ),
      legalMarket: (
        <div className="legal-market-consent">
          {i18nTranslate(
            'legalMarket',
            `I certify that I am a legal resident of ${countryCode}`
          )}
        </div>
      ),
      totalBudget: i18nTranslate('costcenter.totalBudget', 'Budget *'),
      budgetPeriod: i18nTranslate('costcenter.budgetPeriod', 'Budget Period *'),
      startDate: i18nTranslate('costcenter.startDate', 'Start Date *'),
      endDate: i18nTranslate('costcenter.endDate', 'End Date *'),
      apidomain: 'Enter API domain name',
      studiodomain: 'Enter Studio domain name',
      campaignid: 'Enter Studio Campaign Id',
      studioenv: 'Enter Studio Env',
      studioenabled: 'Enable Studio',
      businessRelationship: 'Business Relationship',
      activateB2B2C: 'Activate B2B2C',
      googleapikey: 'Enter Google API key name',
      storeid: 'Enter Store Id',
      globalstoreid: 'Enter global Store Id',
      locale: 'Enter Default locale',
      'x-skava': 'Enter x-skava value',
      'x-api-key': 'Enter x-api-key value',
      taxId: i18nTranslate('accounts.taxID', 'Social Security Number *'),
      businessTaxId: i18nTranslate(
        'accounts.businessTaxId',
        'Social Security Number (xxx-xx-xxxx) *'
      ),
      taxIdOptions: i18nTranslate(
        'accounts.taxIdOptions',
        'Select Business Tax ID *'
      ),
      taxIdOptionsLabel: i18nTranslate(
        'accounts.taxIdOptionsLabel',
        'Select Business Tax ID *'
      ),
      employeeTaxId: i18nTranslate(
        'accounts.employeeTaxId',
        'Employer Identification Number (xx-xxxxxxx) *'
      ),
      businessTaxIdDropdownPlaceHolder: i18nTranslate(
        'accounts.businessTaxIdDropdownPlaceHolder',
        'Social Security Number (xxx-xx-xxxx) '
      ),

      employeeTaxIdDropdownPlaceHolder: i18nTranslate(
        'accounts.employeeTaxIdDropdownPlaceHolder',
        'Employer Identification Number (xx-xxxxxxx) '
      ),
      businessTaxIdDropdown: i18nTranslate(
        'accounts.businessTaxIdDropdown',
        'Social Security Number (xxx-xx-xxxx) *'
      ),
      businessInformation: i18nTranslate(
        'convertAccount.businessInfo',
        'Business Information'
      ),
      employeeTaxIdDropdown: i18nTranslate(
        'accounts.employeeTaxIdDropdown',
        'Employer Identification Number (xxxxxxx-xx) *'
      ),
      duns: i18nTranslate('accounts.dunsNumber', 'DUNS Number *'),
      industry: i18nTranslate('accounts.industryName', 'Industry *'),
      accountType: i18nTranslate('accounts.accountTypeName', 'Account Type *'),
      changeAccountTypeName: enablePhoneVerification()
        ? i18nTranslate('sponsorChange.accountType', 'Account Type *')
        : i18nTranslate('sponsorChange.current', 'Your current account type '),
      personalInformation: i18nTranslate(
        'sponsorChange.personalinfo',
        'Personal Information'
      ),
      industrySize: i18nTranslate(
        'accounts.industrysize',
        'Number of Employees *'
      ),
      emailField: i18nTranslate('accounts.emailfield', 'Email Address *'),
      name: i18nTranslate('name', 'Name *'),
      id: i18nTranslate('id', 'ID'),
      team: i18nTranslate('team', 'Team'),
      role: i18nTranslate('role', 'Role'),
      status: i18nTranslate('status', 'Status *'),
      userStatus: i18nTranslate('status', 'Status'),
      teamName: i18nTranslate('team.teamNameTitle', 'Team Name *'),
      costCenter: i18nTranslate('costcenter.costCenterTitle', 'Cost Center *'),
      addCostCenter: i18nTranslate(
        'costcenter.addCostCenter',
        'Add Cost Center'
      ),
      description: i18nTranslate('description', 'Description'),
      descriptionPlaceholder: i18nTranslate(
        'descriptionPlaceholder',
        'Description Text'
      ),
      teamNamePlaceholder: i18nTranslate('teamNamePlaceholder', 'Team Name'),
      costCenterFilter: i18nTranslate(
        'costcenter.costCenterFilter',
        'Cost Center'
      ),
      userEmailAddress: i18nTranslate('userEmailAddress', 'Email Address'),
      businessEmailAddress: i18nTranslate(
        'businessEmailAddress',
        'Business Email'
      ),
      shippingInstructions: i18nTranslate(
        'shippingInstructions',
        'Shipping Instructions'
      ),
      giftMessage: i18nTranslate('giftMessage', 'Gift Message', {
        nameSpace: 'ssr-resource',
      }),
      userTeam: i18nTranslate('userTeam', 'Team'),
      userRole: i18nTranslate('userRole', 'Role'),
      emptyPlaceHolder: '',
      dunsPlaceholder: '00-000-0000',
      taxIdPlaceholder: '000-000-0000',
      phoneNumberPlaceholder: '00-000-0000',
      firstNamePlaceholder: i18nTranslate('firstNamePlaceholder', 'First Name'),
      bEFirstNamePlaceholder: i18nTranslate(
        'bEFirstNamePlaceholder',
        'Business Legal Name'
      ),
      lastNamePlaceholder: i18nTranslate('lastNamePlaceholder', 'Last Name'),
      sponsorIDPlaceholder: i18nTranslate('sponsorIDPlaceholder', 'Sponsor ID'),
      phoneNoPlaceHolder: i18nTranslate(
        'phoneNoPlaceHolder',
        'Mobile Phone Number'
      ),
      organizationNamePlaceholder: i18nTranslate(
        'accounts.organizationNamePlaceholder',
        'Organization Name'
      ),
      userAddressNamePlaceHolder: i18nTranslate('namePlaceHolder', 'Name'),
      cityPlaceholder: i18nTranslate('cityPlaceholder', 'City'),
      textAreaEmailPlaceholder: i18nTranslate(
        'textAreaEmailPlaceholder',
        "Enter the email addresses of the people  you'd like to share"
      ),
      listAccessType: i18nTranslate('listAccessType', 'Make this list public'),
      ShareSendMeCopy: i18nTranslate(
        'share.sendMeCopy',
        'Send me a copy of this Email'
      ),
      userName: i18nTranslate('userName', 'Email /Mobile Phone Number *'),
      addressUserName: i18nTranslate('addressUserName', 'Name *'),
      returnReason: i18nTranslate(
        'orderhistory.returnReason',
        'Return Reason *'
      ),
      cancellationReason: i18nTranslate(
        'orderhistory.cancellationReason',
        'Cancel Reason *'
      ),
      others: i18nTranslate(
        'orderhistory.otherReason',
        'Other Reasons or Comments'
      ),
      listName: i18nTranslate('listName', 'List Name'),

      /* Sponsor Texts */
      sponsorConfirmText: i18nTranslate(
        'info.sponsorConfirmText',
        'Please confirm this is correct before proceeding. Once your account is converted, you may only switch Sponsors pursuant to our Policies and Procedures.'
      ),
      brandAffiliateSponsorText: i18nTranslate(
        'info.brandAffiliateSponsor',
        'Your new Brand Affiliate Account will be linked to'
      ),
      isBrandAffiliateSponsor: i18nTranslate(
        'info.isBrandAffiliateSponsor',
        'Your Brand Affiliate Account is linked to'
      ),
      memberSponsorText: i18nTranslate(
        'info.memberSponsor',
        'Your new Member Account will be linked to'
      ),
      isSponsorText: i18nTranslate('info.isSponsorText', 'as your Sponsor.'),
      /* Sponsor Texts Ends */
      privacyNoticeAndTermsOfUse: i18nTranslate(
        'privacyNoticeAndTermsOfUse',
        'Privacy Notice & Terms of Use'
      ),
      privacyNoticeAndTermsOfUseRetail: i18nTranslate(
        'privacyNoticeAndTermsOfUseRetail',
        'Privacy Notice, Terms of Use, & Refund Policy'
      ),
      privacyNoticeAndTermsOfUseRetailContent: i18nTranslate(
        'privacyNoticeAndTermsOfUseRetailContent',
        ' '
      ),
      privacyNoticeAndTermsOfUseContent: i18nTranslate(
        'privacyNoticeAndTermsOfUseContent',
        'Nu Skin collects certain personal information about you to manage your relationship with us as a Brand Affiliate. We may share this information with service providers, your sponsor, Brand Affiliates and customers you sponsor, and other members of your team so that you may provide and receive training and support for your Nu Skin business. Please review our Privacy Notice for more information.'
      ),
    }
    return this.inputLocalelabel[inputLabel]
  }
  /**
   * Retrieves the ARIA label for a given input.
   * @param {string} inputAriaLabel - The input ARIA label.
   * @returns {string} - The corresponding ARIA label.
   */
  getFormAriaLabels = inputAriaLabel => {
    if (this.inputAriaLabelJson[inputAriaLabel]) {
      return this.inputAriaLabelJson[inputAriaLabel]
    }

    this.inputAriaLabelJson = {
      email: i18nTranslate('ariaLabel.email', 'Email Address *'),
      emailOrPhoneNumber: i18nTranslate(
        'ariaLabel.emailOrPhone',
        'Email id or Mobile phone number'
      ),
      password: i18nTranslate('ariaLabel.password', 'Password *'),
      firstName: i18nTranslate('ariaLabel.firstName', 'First Name *'),
      bEFirstName: i18nTranslate(
        'ariaLabel.bEFirstName',
        'Account name of the user'
      ),
      middleName: i18nTranslate(
        'ariaLabel.middleName',
        'Middle name of the user'
      ),
      lastName: i18nTranslate('ariaLabel.lastName', 'Last Name *'),
      sponsoringBAID: i18nTranslate(
        'sponsoringBAID',
        'Sponsoring Brand Affiliate ID'
      ),
      sponsorID: i18nTranslate('ariaLabel.sponsorID', 'Sponsor ID *'),
      sponsorIDConvert: i18nTranslate(
        'areaLabel.sponsorIDConvert',
        'Sponsor ID of the user'
      ),
      sponsorName: i18nTranslate(
        'areaLabel.sponsorName',
        'Sponsor Name of the user'
      ),
      addressLine1: i18nTranslate('ariaLabel.addressLine1', 'Address line 1 *'),
      addressLine2: i18nTranslate('ariaLabel.addressLine2', 'Address line 2'),
      city: i18nTranslate('ariaLabel.city', 'City *'),
      province: i18nTranslate('ariaLabel.province', 'Province *'),
      stateList: i18nTranslate('ariaLabel.stateList', 'State *'),
      districtList: i18nTranslate(
        'districtList',
        'Suburb / District / Commune *'
      ),
      wardList: i18nTranslate('ariaLabel.wardList', 'Ward *'),
      state: i18nTranslate('ariaLabel.stateTextbox', 'State *'),
      country: i18nTranslate('ariaLabel.countryList', 'Country *'),
      zip: i18nTranslate('ariaLabel.zipcode', 'Zip Code *'),
      phone: i18nTranslate('ariaLabel.phoneNumber', 'Mobile Phone Number *'),
      optionalPhone: i18nTranslate(
        'ariaLabel.optionalPhoneNumber',
        'Mobile Phone Number'
      ),
      estimateShipping: i18nTranslate(
        'estimateShipping',
        'estimate shipping input textbox'
      ),
      guestPromotionCheckbox: i18nTranslate(
        'signup.promotionalCommunication',
        'How would you like to receive promotional communications from Nu Skin?'
      ),
      smsOptIn: i18nTranslate(
        'signup.smsOptIn',
        'I have read, understand and agree to the'
      ),
      smsOptInMessage: i18nTranslate(
        'signup.smsOptInMessage',
        'Message frequency will vary. Message and data rates may apply.'
      ),
      emailOptIn: i18nTranslate('signup.emailOptIn', 'Email'),
      facebook: i18nTranslate('ariaLabel.facebookIcon', 'Facebook icon'),
      twitter: i18nTranslate('ariaLabel.twitterIcon', 'Twitter icon'),
      pinterest: i18nTranslate('ariaLabel.pinterestIcon', 'Pinterest icon'),
      instagram: i18nTranslate('ariaLabel.instagramIcon', 'Instagram icon'),
      youtube: i18nTranslate('ariaLabel.youtubeIcon', 'Youtube icon'),
      cardNumber: i18nTranslate('ariaLabel.cardNumber', 'Card number'),
      reEnterCardNumber: i18nTranslate(
        'ariaLabel.reEnterCardNumber',
        'Re-Enter Card Number *'
      ),
      expiryMonth: i18nTranslate('ariaLabel.expiryMonth', 'Expiry month'),
      expiryYear: i18nTranslate('ariaLabel.expiryYear', 'Expiry year'),
      cardCVV: i18nTranslate('ariaLabel.cvv', 'CVV number'),
      securityQuestions: i18nTranslate(
        'ariaLabel.securityQuestions',
        'Security questions'
      ),
      securityAnswer: i18nTranslate(
        'ariaLabel.securityAnswer',
        'Security answer'
      ),
      oldPassword: i18nTranslate('profile.oldPassword', 'Old password'),
      newPassword: i18nTranslate('ariaLabel.newPassword', 'New password'),
      confirmPassword: i18nTranslate(
        'ariaLabel.confirmPassword',
        'Re-enter Password *'
      ),
      emailAlerts: i18nTranslate('ariaLabel.emailAlert', 'Email alert'),
      couponCode: i18nTranslate('ariaLabel.couponCode', 'Coupon code'),
      discountCode: i18nTranslate('ariaLabel.discountCode', 'Discount code'),
      promoCode: i18nTranslate('ariaLabel.promoCode', 'Promo code'),
      loyaltyPoint: i18nTranslate('ariaLabel.loyaltyPoint', 'Loyalty Points'),
      addPaymentToProfile: i18nTranslate(
        'billingaddress.addPaymentToProfile',
        'Save Payment Type'
      ),
      selectReason: i18nTranslate('ariaLabel.selectReason', 'Select reason'),
      others: i18nTranslate('ariaLabel.otherReason', 'Other reasons'),
      refundAndPrivacyMember: i18nTranslate(
        'ariaLabel,refundAndPrivacyMember',
        'I have read, understand, and agree to the Terms of Use  and Member Agreement  including the Arbitration Policy and Limitation of Liability. *'
      ),
      refundAndPrivacy: i18nTranslate(
        'ariaLabel.signup.acknowledge',
        'I acknowledge I have read and understand the Refund Policy and Privacy Notice*'
      ),
      refundAndPrivacyBrand: i18nTranslate(
        'ariaLabel.signup.acknowledge',
        'I acknowledge I have read and understand the Sales Performance Plan ,Privacy Notice Refund Policy and Sales Compensation Summary *'
      ),
      termsOfUse: i18nTranslate(
        'ariaLabel.termsOfUse',
        'Terms of use agreement *'
      ),
      termsOfUseBrand: i18nTranslate(
        'ariaLabel.signup.termsOfUseBrand',
        'I have read, understand and agree to the Brand Affiliate Agreement and Policies and Procedure including the Limitation of Liability and Arbitration Policies.*'
      ),
      signupSms:
        ('ariaLabel.signup.sms',
        'Opt in to SMS  I have read, understand, and agree to the SMS Agreement.  Message frequency will vary. Message and data rates may apply.'),

      signupBrandRepresentative:
        ('ariaLabel.signup.contact',
        'Opt in to be contacted by Nu Skin Brand Representative '),
    }

    return this.inputAriaLabelJson[inputAriaLabel]
  }
}

export { FormInputLabels }
export default FormInputLabels
