import React from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { i18nTranslate, trackCheckoutCouponCodeEvents } from 'src/utils'
import { IoIosClose as CloseIcon } from 'react-icons/io'
import { checkoutContainer, cartContainer } from 'src/models'
import { IS_BROWSER, getLocalStorage, trackEnhancedCheckout } from 'src/utils'
import { convertToBoolean, APPConfig } from 'config/appConfig'
import { CommonForm, toastState } from 'src/views/components'
import { formInput } from './formInput'
import { schema } from './validationSchema'
import './styles.scss'

@observer
class CouponCode extends React.Component {
  @observable isCallInProgress = false
  async componentDidMount() {
    checkoutContainer.isToDisableSubmitOrderButton = true
    if (cartContainer?.cartResponse?.payments) {
      await checkoutContainer.deletePayment()
    }
  }
  handleApplyCouponCode = async formData => {
    let couponCode = formData?.couponCode?.trim?.() || '' //CX16-10863
    const response = await checkoutContainer.addPromo(couponCode)
    trackCheckoutCouponCodeEvents({
      coupon: couponCode,
    })
    if (checkoutContainer.isSuccessResponse(response)) {
      formData.couponCode = ''
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.SuccessCoupon',
            'Coupon code applied successfully'
          ),
        true
      )
    } else {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.failureCoupon',
            'unable to add the coupon code'
          ),
        false
      )
    }
  }

  handleDeleteCouponCode = async couponCode => {
    const response = await checkoutContainer.deletePromo(couponCode)
    if (checkoutContainer.isSuccessResponse(response)) {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.deleteCoupon',
            'Coupon code removed successfully'
          ),
        true
      )
    } else {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.failureDelete',
            'unable remove the coupon code'
          ),
        false
      )
    }
  }

  handleNext = () => {
    this.isCallInProgress = true
    const { onContinue, title } = this.props
    if (onContinue) {
      onContinue()
    }
    this.isCallInProgress = false
    const products = cartContainer?.cartResponse?.items || []
    trackEnhancedCheckout({
      type: 'checkout',
      action: { step: '3', option: title },
      products: products,
    })
  }

  renderSubmitButton = () => {
    return (
      <Button
        data-testid="qa-pay-coupon-apply"
        className="rounded-0 coupon-apply-button coupon-code w-100 text-capitalize mt-sm-0 btn-bg-blue"
        variant="primary"
        disabled={this.hasPromoStackEnable()}
        type="submit">
        {i18nTranslate('couponCode.submit', 'apply')}
      </Button>
    )
  }

  renderPromos = promotionDetails => {
    return (
      <Button
        className="promo-code mr-2"
        variant="outline-dark"
        onClick={() => this.handleDeleteCouponCode(promotionDetails.code)}>
        <span> {promotionDetails.code}</span>
        <CloseIcon />
      </Button>
    )
  }

  hasPromoStackEnable = () => {
    const promotionCodesExist =
      cartContainer?.cartResponse?.promotionDetails?.codes?.length > 0
    const enablePromoCodeStack = convertToBoolean(
      APPConfig?.getAppConfig()?.enablePromoCodeStack
    )
    return (!enablePromoCodeStack && promotionCodesExist) || false
  }

  render() {
    const promoCode = cartContainer?.cartResponse?.promotionDetails?.codes || ''
    const hasPromoStackEnable = this.hasPromoStackEnable()
    const formInputJson = formInput({
      hasPromoStackEnable: hasPromoStackEnable,
    })
    const classNameValues = {
      formRowClassName: 'promo-code-form',
    }
    let productData

    try {
      let hideContinueShopBtn
      if (IS_BROWSER) {
        /**
         * @note
         * Get cart quantity from local storage
         */
        let shoppingCart = getLocalStorage('shop-cart')
        if (shoppingCart && typeof shoppingCart != 'object') {
          shoppingCart = JSON.parse(shoppingCart) || {}
        }
        productData = shoppingCart?.products?.length || 0
        if (productData > 0) {
          hideContinueShopBtn = {
            display: 'none',
          }
        }
      }
    } catch (e) {
      console.error('JSON.parse may be an error >>> ', e)
    }

    return (
      <div className="coupon-code-container py-3">
        <span
          className="coupon-title font-weight-bold text-black-50 pb-3"
          data-testid="coupon-title-text">
          {i18nTranslate(
            'couponCode.title',
            'Have a Coupon or Promotional code'
          )}
          ?
        </span>
        <div data-testid="coupon-form-wrapper">
          {/* <label className="coupon-heading text-capitalize font-weight-normal">
            {i18nTranslate('couponCode.heading', 'Coupon code')}
          </label> */}
          <CommonForm
            schema={schema}
            formTypeJson={formInputJson}
            SubmitButtonProps={this.renderSubmitButton}
            onSubmit={this.handleApplyCouponCode}
            hasInlineSubmitButton={true}
            classNameValue={classNameValues}
          />
          {promoCode && (
            <div
              className="coupon-list mt-3 py-3"
              data-testid="qa-applied-coupon">
              {promoCode.map(this.renderPromos)}
            </div>
          )}
        </div>
        <Form>
          <Row className="coupon-code-continue justify-content-end" noGutters>
            <Button
              className="rounded-0 px-5 py-2 shipping-method-continue btn-bg-blue mt-sm-4"
              onClick={this.handleNext}
              disabled={this.isCallInProgress}
              data-testid="qa-coupon-code-continue">
              {i18nTranslate('couponCode.continue', 'Continue')}
            </Button>
          </Row>
        </Form>
      </div>
    )
  }
}

export { CouponCode }
export default CouponCode
