import React from 'react'
// import ReactDOM from 'react-dom'
import { observer } from 'mobx-react'
// import { Draggable } from 'react-beautiful-dnd'

class DraggableContainer extends React.Component {
  render() {
    const { id, index, outFitId } = this.props
    // const draggableId = `${id}-${outFitId}`

    return <PortalAwareItem {...this.props} />

    // return (
    //   <Draggable key={draggableId} draggableId={draggableId} index={index}>
    //     {(provided, snapshot) => (
    //       <PortalAwareItem
    //         provided={provided}
    //         snapshot={snapshot}
    //         {...this.props}
    //       />
    //     )}
    //   </Draggable>
    // )
  }
}

@observer
class PortalAwareItem extends React.Component {
  render() {
    const { provided, snapshot } = this.props
    const usePortal = false // snapshot.isDragging

    const child = (
      <div
        className={`outfit-drag outfit-image ${usePortal ? 'in-portal' : ''}`}
        // ref={provided.innerRef}
        // {...provided.draggableProps}
        // {...provided.dragHandleProps}
      >
        {this.props.children}
      </div>
    )

    if (!usePortal) {
      return child
    }

    // if dragging - put the item in a portal
    // return ReactDOM.createPortal(child, document.getElementById('portal'))
  }
}

export { DraggableContainer }
