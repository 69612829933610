/**
 * Transforms a recommended product into an outfit item.
 * @param {Object} recommendedProduct - The recommended product object.
 * @returns {Object} The transformed outfit item.
 */
function transformRecommendation(recommendedProduct) {
  return {
    key: recommendedProduct.SkuId,
    id: recommendedProduct.SkuId,
    name: recommendedProduct.name,
    outfitItemType: 'recommended',
    outFitItem: 'new',
    properties: {
      itemType: 'SKU',
      quantity: '1',
      productId: recommendedProduct.ProductId,
      imageURL: recommendedProduct.imageURL,
      type: recommendedProduct.fit || recommendedProduct.itemType,
    },
  }
}

export { transformRecommendation }
