/**
 * Regular expressions to validate zip codes for different countries.
 *
 * US: 5 or 9 digit zip codes
 * FR: 5 or 9 digit French postal codes
 * CA: Canadian postal codes with alphabet and number combinations
 * DEFAULT: 5 or 9 digit zip codes
 */
const countryZipcodesRegex = {
  US: /^[0-9]{5}(?:-[0-9]{4})?$/,
  FR: /(?:0[1-9]|[13-8][0-9]|2[ab1-9]|9[0-5])(?:[0-9]{3})?|9[78][1-9](?:[0-9]{2})?/,
  CA: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
  DEFAULT: /^[0-9]{5}(?:-[0-9]{4})?$/,
}
export { countryZipcodesRegex }
