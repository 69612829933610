import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Tab,
  Tabs,
  Form,
  Button,
  Modal,
  Row,
  Col,
  Tooltip,
  Spinner,
} from 'react-bootstrap'
import {
  CommonForm,
  tokenExIframe,
  PaymentMarketForm,
  toastState,
  Pagination,
  ImageComponent,
} from 'src/views/components'
import {
  application,
  trackEnhancedCheckout,
  trackCustomCheckoutEvent,
  capitalizeFirstLetter,
  getTotalPagesForPagination,
  isGPSEnabled,
  i18nTranslate,
} from 'src/utils'
import {
  renderFormInput,
  cardCVVSchema,
} from 'src/views/components/PaymentListWithForm/PaymentAccordionInput'
import isArray from 'lodash/isArray'
import { PaymentIcon } from 'src/views/components/PaymentIcon'
import {
  customerContainer,
  checkoutContainer,
  cartContainer,
  storeContainer,
  tokenExContainer,
} from 'src/models'
import { APPConfig, IS_BROWSER } from 'config/appConfig'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { dateFormatDeps, getCardDetails, FormInputLabels } from 'src/deps'
import { IoIosInformationCircle as InformationIcon } from 'react-icons/io'
import { TokenExCvvContainer, savedCardsContainer } from 'src/models/TokenEx'
import { EditUpdatePaymentModal } from 'src/views/pages/MyAccount/Payments/EditUpdatePaymentModal'
import './styles.scss'

const constructStyle = {
  formRowClassName: 'ml mr-1 from-saved-list',
}

@observer
class GlobalPaymentsOptionsList extends React.Component {
  @observable selectedPayment = []
  @observable paymentList = customerContainer.paymentResponse?.payments || []
  @observable isToShowDeleteConfirmationModal = false
  @observable isDefaultCardId = ''
  @observable idToBeDeleted = ''
  @observable savedCardValidStatus = false
  @observable IdofCardToBeEdited = ''
  @observable isAddPaymentCallInProgress = false
  @observable isToShowEditUpdatePaymentModal = false
  @observable cardsDetails = ''
  @observable selectedPaymentTypeForAdd = ''
  tokenPathParam = ''
  previousCardID = ''
  isFromDidMount = false
  loadIframeInitially = false
  theDefaultCardId = ''
  isNotSelectedDefaultCard = false
  isFromDeleteCard = false
  currentTab = ''
  isCardExpired = true
  totalPagesLength = 0
  currentPage = 1

  constructor(props) {
    super(props)
    if (this.props.initiallySelectedPayment) {
      this.selectedPayment = this.props.initiallySelectedPayment
    }
    const updatePaymentList =
      this.paymentList &&
      this.paymentList?.length &&
      this.paymentList?.filter(list => {
        const type = list?.type?.toLowerCase() || ''
        return type === 'creditcard'
      })
    this.paymentList = updatePaymentList
  }

  /**
   * @description Sets the Default Selected Tab and Default Selected Card
   * Loads CVV Field for Default Selected Card.
   */
  async componentDidMount() {
    this.currentTab = this.getDefaultPaymentTab()
    this.handleDefaultChecked()
    tokenExContainer.tokenExValidData = false
    let selectedCardId = this.selectedPayment?.id || ''
    if (
      this.selectedPayment?.isDefault &&
      this.previousCardID !== selectedCardId
    ) {
      this.isFromDidMount = !this.loadIframeInitially
      this.tokenExCvvIframeLoader()
    }
  }

  /**
   * @description Loads CVV Field for Selected Card
   */
  componentDidUpdate() {
    let selectedCardId = this.selectedPayment?.id || ''
    if (
      this.currentTab == 'savedcards' &&
      (this.previousCardID !== selectedCardId || this.isFromDidMount)
    ) {
      this.isFromDidMount = false
      this.tokenExCvvIframeLoader()
    }
  }

  /**
   * @description Gets the default payment tab to show on initial load.
   * If Credit card is added to Profile, and if GPS is enabled and,
   * credit cards are supported in GPS, returns 'savedcards'.
   * Else if, GPS is not enabled and if payments are added to profile, returns 'savedcards'.
   * Otherwise, returns 'newCard'.
   */
  getDefaultPaymentTab = () => {
    const isToShowSavedCardsTabByDefault = this.isToShowSavedCardsTab(
      this.paymentList
    )
    const defaultTab = isToShowSavedCardsTabByDefault ? 'savedcards' : 'newCard'
    return defaultTab
  }

  /**
   * @description Checks if the saved cards tab should be shown by default.
   * Returns true if GPS is enabled, credit cards are supported in GPS,
   * and there are saved cards in the profile.
   * Or, returns true if GPS is disabled and there are saved cards in the profile.
   * Otherwise returns false.
   */
  isToShowSavedCardsTab = (paymentsList = []) => {
    const enableGlobalPaymentSystem = isGPSEnabled()
    const isCreditCardTypeSupportedInGPS =
      Object.keys(checkoutContainer.globalPaymentResponse)?.length > 0 &&
      Object.keys(checkoutContainer.globalPaymentResponse)?.some(
        paymentType => paymentType?.toLowerCase() === 'creditcard'
      )
    let isToShowSavedCardsTabByDefault = enableGlobalPaymentSystem
      ? isCreditCardTypeSupportedInGPS && paymentsList?.length > 0
      : paymentsList?.length > 0
    return isToShowSavedCardsTabByDefault
  }

  /**
   * @description Function to Load CVV FIled from TokenEx for Selected Credit Card.
   */
  tokenExCvvIframeLoader = async () => {
    let selectedCardId = this.selectedPayment?.id || ''
    let tokenExUpdate = savedCardsContainer.savedCardsList[selectedCardId]
    this.previousCardID = selectedCardId || ''
    this.savedCardValidStatus = false
    tokenExUpdate = new TokenExCvvContainer()
    tokenExUpdate.containerId = selectedCardId
    tokenExUpdate.errorValidationCallBack = (containerId, validData) => {
      const errorElement = document.querySelector(
        `#cvvParent${containerId} .cardCvvtoken`
      )
      if (validData.isValid == false) {
        errorElement?.classList?.add('show')
        this.savedCardValidStatus = false
      } else {
        errorElement?.classList?.remove('show')
        this.savedCardValidStatus = true
      }
    }

    savedCardsContainer.savedCardsList[this.selectedPayment.id] = tokenExUpdate

    const tokenValues = customerContainer?.paymentResponse?.payments || []
    checkoutContainer.activeSubmitOrderButton = true
    this.tokenPathParam = tokenValues?.find(
      item => item?.id === selectedCardId
    )?.accountInfo?.token
    this.cardProvider = tokenValues?.find(
      item => item?.id === selectedCardId
    )?.accountInfo?.provider
    tokenExUpdate?.loadTokenExCvvIframe(
      `cvvTokenID${this.selectedPayment.id}`,
      this.tokenPathParam,
      this.cardProvider
    )
  }

  /**
   * @description Function to Handle Page Selection.
   * Triggers GetPayment API with selected Page Number
   * And Sets Default Payment from the list and Loads CVV Field for default Card.
   */
  handlePagination = async (activePage = 1) => {
    const response = await customerContainer.getPayment(activePage)
    const transformedPaymentResponse =
      customerContainer.transformPaymentResponse(response, activePage) || {}
    this.paymentList = transformedPaymentResponse?.payments || []
    this.handleDefaultChecked()
    transformedPaymentResponse?.payments?.find(cards => {
      if (cards?.id === this.isDefaultCardId) {
        this.tokenExCvvIframeLoader()
      }
    })
    this.currentPage = transformedPaymentResponse?.pageableInfo?.page || 1
  }

  /**
   * @description Function to Handle Add Payment to Cart logic for Selected Saved Credit Card.
   */
  handleSavedCreditCardContinue = async () => {
    const { title = '' } = this.props
    const products = cartContainer?.cartResponse?.items || []
    trackEnhancedCheckout({
      type: 'checkout',
      action: { step: '6', option: title },
      products: products,
    })
    checkoutContainer.isPaymentApiInProgress = true
    checkoutContainer.isToDisableSubmitOrderButton = false
    const currentCardId = this.selectedPayment?.id || ''
    const errorElement = document.querySelector(
      `#cvvParent${currentCardId} .cardCvvtoken`
    )
    let isCvvValid = this.savedCardValidStatus
    this.props?.changeCvvValidFlag(isCvvValid)
    if (this.savedCardValidStatus) {
      checkoutContainer.isPaymentApiInProgress = false
      await savedCardsContainer.savedCardsList[currentCardId].getToken(
        tokenExData => {
          this.props.handlePayment?.({
            cardCVV: '',
            ...this.selectedPayment,
            savedCardValidStatus: this.savedCardValidStatus,
            isFromSavedAddress: true,
            tokenExData,
          })
          errorElement?.classList?.remove('show')
        }
      )
      checkoutContainer.expressPaymentAdded = true
    } else {
      errorElement?.classList?.add('show')
      checkoutContainer.isPaymentApiInProgress = false
    }
  }

  /**
   * @description Function to render CVV Field for Selected Saved Credit Card.
   * @param {Object} selectedCard
   */
  handleSavedCardOptionChange = (selectedCard = {}) => {
    /**
     * @note
     * Handling Iframe Loads through this states if any card is deleted
     */
    if (this.isFromDeleteCard) {
      this.isFromDidMount = true
      this.isFromDeleteCard = false
    }
    customerContainer.paymentResponse?.payments?.find(card => {
      if (card?.isDefault === true) {
        card.isDefault = false
      }
      if (card?.id === selectedCard?.id) {
        card.isDefault = true
        if (!savedCardsContainer.savedCardsList[selectedCard?.id]) {
          savedCardsContainer.savedCardsList[selectedCard.id] =
            new TokenExCvvContainer()
        }
      }
    })
    this.selectedPayment = selectedCard
  }

  /**
   * @description Function to set the selected payment type when adding a new payment.
   * @param {String} paymentType
   */
  handlePaymentTypeSelect = (type = '') => {
    this.selectedPaymentTypeForAdd = type?.toLowerCase() || ''
    if (this.selectedPaymentTypeForAdd === 'creditcard') {
      checkoutContainer.addPaymentToProfile = customerContainer.isRegisterUser
    }
  }

  /**
   * @description Function to Handle Add Payment to Cart logic for Added New Paymeents other than CreditCard.
   */
  handleAlternatePayment = async (type = '') => {
    this.isAddPaymentCallInProgress = true

    const communicationResponse =
      await checkoutContainer.setCommunicationPreference(
        cartContainer?.cartResponse?.deliveryDetails?.address || {}
      )

    if (!checkoutContainer.isSuccessResponse(communicationResponse)) {
      checkoutContainer.isPaymentApiInProgress = false
      toastState.setToastMessage(
        communicationResponse.message || communicationResponse?.errorMessage
      )
      this.isAddPaymentCallInProgress = false
      return
    }

    const bagValue = Math.abs(
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || ''
    )
    const postData = [
      {
        name: type?.toLowerCase(),
        isDefault: false,
        status: 'active',
        type: type?.toUpperCase()?.replace(/ /g, '_'), // As per BE request, always send Type in Uppercase and Replace Space with '_'
        amount: bagValue,
        option: {
          addPaymentToProfile: false,
          useDefaultPayment: false,
          setAsDefaultPaymentInProfile: false,
        },
        isValid: true,
      },
    ]

    const response = await checkoutContainer.addAlternatePayment(postData)
    if (checkoutContainer.isSuccessResponse(response)) {
      checkoutContainer.isToInitiateGlobalPayment = true
      this.props.onContinue()
      cartContainer.viewCart()
      checkoutContainer.isToDisableSubmitOrderButton = false
      trackCustomCheckoutEvent({
        eventType: 'payment',
        cartResponse: cartContainer?.cartResponse,
      })
    }
    this.isAddPaymentCallInProgress = false
  }

  /**
   * @description Function to reset Values to defaultValue, upon Cancelling Payment.
   */
  cancelPaymentFlag = () => {
    tokenExIframe.isValidCard = true
    tokenExIframe.isCvvValidCard = true
    this.selectedPaymentTypeForAdd = ''
    checkoutContainer.isToDisableSubmitOrderButton = true
    checkoutContainer.isToInitiateGlobalPayment = false
  }

  /**
   * @description Handles Update/Edit button Click for a payment card.
   * Enables Edit/Update Payment Modal
   *
   * @param {Object} e - The click event object
   * @param {Object} cards - The card object to update
   */
  handleUpdateButtonClick = (e, cards = {}) => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
    this.isToShowEditUpdatePaymentModal = true
    this.cardsDetails = cards
    this.IdofCardToBeEdited = cards?.id
  }

  /**
   * @description Closes the Edit/Update Payment modal.
   */
  handleUpdateModalClose = () => {
    this.isToShowEditUpdatePaymentModal = false
  }

  /**
   * @description Handles Delete button Click for a payment card.
   * Enables Delete Payment Confirmation Modal
   *
   * @param {Object} e - The click event object
   * @param {Object} cards - The card object to update
   */
  handleDeleteButtonClick = (e, card = {}) => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
    this.isToShowDeleteConfirmationModal = true
    this.idToBeDeleted = card?.id
    this.cardsDetails = card
  }

  /**
   * @description Closes the Delete Payment Confirmation Modal.
   */
  handleDeleteConfirmationModalClose = () => {
    this.isToShowDeleteConfirmationModal = false
  }

  /**
   * @description Deletes the selected payment for the customer by ID.
   * Calls the customerContainer deletePayment API.
   * Closes the delete confirmation modal.
   * Handles resetting the selected payment if default was deleted.
   * Sets flags if default card was deleted to reload the iframe.
   */
  handleDeletePayment = (selectedCardId = '') => {
    customerContainer.deletePayment({
      id: selectedCardId,
      page: this.currentPage || '',
    })
    this.isToShowDeleteConfirmationModal = false
    this.cancelPaymentFlag()
    /**
     * @note
     * If non-default card is selected and is deleted, setting selectedPayment to default card, if default card is deleted then passing a state as true to load iframe on didupdate
     */
    if (this.isNotSelectedDefaultCard && ids != this.theDefaultCardId) {
      this.selectedPayment.id = this.theDefaultCardId
    } else if (this.isNotSelectedDefaultCard && ids === this.theDefaultCardId) {
      this.isFromDeleteCard = true
    } else if (!this.isNotSelectedDefaultCard) {
      this.previousCardID = ''
      this.isFromDeleteCard = true
    }
  }

  /**
   * @description Finds the default payment method and sets it as selected payment.
   */
  handleDefaultChecked = e => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
    customerContainer.paymentResponse?.payments?.find(cards => {
      if (cards.isDefault === true) {
        this.isDefaultCardId = cards.id
        this.selectedPayment = cards
      }
    })
  }

  /**
   * @description Triggers Update Payment API to set Selected Card as Default.
   * Calls handleDefaultChecked to reset the selected payment to the new default card.
   */
  handleDefaultChange = (e, selectedCardId = '') => {
    const postData = { isDefault: true }
    customerContainer.updateDefaultPayment({
      postData,
      id: selectedCardId,
    })
    this.handleDefaultChecked(e)
  }

  /**
   * Sets the current tab that is selected.
   * @param {string} selectedTab - The tab id that is currently selected.
   */
  handleSelectedPaymentTab = (selectedTab = '') => {
    this.currentTab = selectedTab
  }

  /**
   * @description Renders a continue button for saved credit card payments.
   * The continue button is disabled while the payment API call is in progress.
   * It triggers the handleSavedCreditCardContinue method on click.
   * @returns {JSX.Element} - The rendered continue button.
   */
  renderContinueButtonForSavedPayments = () => {
    return (
      <Form.Row className="justify-content-end">
        <Button
          className="px-5 py-2 saved-card-continue-button"
          data-testid="qa-pay-continue"
          id="payment-continue-button"
          type="submit"
          disabled={checkoutContainer.isPaymentApiInProgress}
          onClick={event => {
            // CX121-3102 - to prevent multiple clicks
            // https://stackoverflow.com/a/42664936
            if (!event.detail || event.detail == 1) {
              this.handleSavedCreditCardContinue()
            }
          }}>
          {checkoutContainer.isPaymentApiInProgress ? (
            <span className="px-4">
              <Spinner
                animation="border"
                role="status"
                size="md"
                variant="primary"
              />
            </span>
          ) : (
            i18nTranslate('checkout.continue', 'Continue')
          )}
        </Button>
      </Form.Row>
    )
  }

  /**
   * @description Renders confirm delete text for deleting a saved payment method.
   */
  renderDeletePaymentConfirmationText = () => {
    const cardNumber = this.cardsDetails?.accountInfo?.cardNumber || ''
    const cardProvider = this.cardsDetails?.accountInfo?.provider || ''

    return (
      <div>
        <div className="confirm-delete-modal-wrapper">
          <span>
            {i18nTranslate('payment.deleteMessageStart', 'Delete')}&nbsp;
          </span>
          <span>{capitalizeFirstLetter(cardProvider)} &nbsp;</span>
          <span>****{cardNumber}&nbsp;</span>
          <span>
            {i18nTranslate(
              'payment.deleteMessageEnd',
              'from your saved payments?'
            )}
          </span>
        </div>
        <div className="d-flex justify-content-between delete-button-wrapper">
          <Button
            className="btn btn-outline-primary cancel-delete-button"
            data-testid="qa-delete-cancel-button"
            onKeyPress={this.handleDeleteConfirmationModalClose}
            onClick={this.handleDeleteConfirmationModalClose}>
            {i18nTranslate('payment.cancelDelete', 'Cancel')}
          </Button>
          <Button
            className="btn btn-primary confirm-delete-button"
            data-testid="qa-delete-confirm-button"
            onKeyPress={() => {
              this.handleDeletePayment(this.idToBeDeleted)
            }}
            onClick={() => {
              this.handleDeletePayment(this.idToBeDeleted)
            }}>
            {i18nTranslate('payment.confirmDelete', 'Confirm')}
          </Button>
        </div>
      </div>
    )
  }

  /**
   * @description Renders Delete Button, Edit/Update Button and Default Card Checkbox.
   *
   * @param {*} isSelected on which card user taking action
   * @param {*} cards contains all card related details
   * @param {*} deleteLabel delete text
   * @returns {*} edit/update and delete button in checkout page
   */
  renderDeleteCardBlock = (
    isSelected = false,
    cards = {},
    deleteLabel = ''
  ) => {
    if (!isSelected && cards?.id === this.isDefaultCardId) {
      this.isNotSelectedDefaultCard = true
      this.theDefaultCardId = cards?.id || ''
    } else if (isSelected && cards?.id === this.isDefaultCardId) {
      this.isNotSelectedDefaultCard = false
    }

    return (
      <div id="card-handlers-wrapper">
        <div
          style={{
            width: '100%',
            objectFit: 'contain',
          }}
          role="checkbox"
          tabIndex={isSelected ? 0 : -1}
          aria-checked={cards?.id === this.isDefaultCardId}
          onKeyPress={e => {
            this.handleDefaultChange(e, cards?.id)
          }}>
          {isSelected && (
            <Form.Check
              type="checkbox"
              label="Set as Default Card"
              id="set-default-checkout"
              key={'checkbox' + cards?.id}
              checked={cards?.id === this.isDefaultCardId}
              onChange={e => {
                this.handleDefaultChange(e, cards?.id)
              }}
              disabled={cards?.id === this.isDefaultCardId}
              tabIndex={isSelected ? 0 : -1}
            />
          )}
        </div>
        {!isSelected && cards?.id === this.isDefaultCardId && (
          <div>
            <p className="default-payment-title" tabIndex={0}>
              {i18nTranslate('payment.defaultTitle', 'Default Payment')}
            </p>
          </div>
        )}

        <div className="edit-delete-button-wrapper">
          <span>
            <Button
              variant="link"
              disabled={checkoutContainer.isPaymentApiInProgress}
              className="font-weight-bold edit-update-button"
              aria-label="Edit card link"
              onClick={e => this.handleUpdateButtonClick(e, cards)}
              data-testid={
                this.isCardExpired ? 'qa-edit-payment' : 'qa-update-payment'
              }>
              {this.isCardExpired
                ? i18nTranslate('edit', 'Edit')
                : i18nTranslate('catalog.Update', 'Update', {
                    nameSpace: 'ssr-resource',
                  })}
            </Button>
          </span>
          <span>
            <Button
              variant="link"
              disabled={checkoutContainer.isPaymentApiInProgress}
              className="font-weight-bold delete-button"
              aria-label="delete card link"
              onClick={e => this.handleDeleteButtonClick(e, cards)}
              data-testid="qa-delete-payment">
              {deleteLabel}
            </Button>
          </span>
        </div>
        {/** checkout page delete payment cards modal */}
        {this.isToShowDeleteConfirmationModal && (
          <Modal
            className={`overlay visible confirm-delete-modal-wrapper p-0`}
            show={this.isToShowDeleteConfirmationModal}
            onHide={() => (this.isToShowDeleteConfirmationModal = false)}
            centered
            animation={false}>
            <Modal.Header
              closeButton={this.handleDeleteConfirmationModalClose}
              className={'border-bottom-0'}>
              <Modal.Title className="title-Payment-Modal">
                {i18nTranslate('checkout.deletePayment', 'Delete Payment')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.renderDeletePaymentConfirmationText()}
            </Modal.Body>
          </Modal>
        )}
      </div>
    )
  }

  /**
   * @description Renders Info Icon for CVV Field.
   * @returns {JSX.Element} cvv info icon
   */
  renderCVVInfoIcon = () => {
    return (
      <OverlayTrigger
        trigger={'click'}
        placement="top"
        rootClose={true}
        overlay={
          <Tooltip
            id={`tooltip`}
            className="saved-cvv-tooltip"
            data-testid="qa-cvv-info-tooltip">
            {i18nTranslate(
              'payment.cvvInfo',
              'To proceed with your transaction, enter your Card Verification Value found at the back of your card.'
            )}
          </Tooltip>
        }>
        <Button
          className="saved-cvv-tooltip-info-button"
          data-testid="qa-cvv-info-button">
          <InformationIcon
            size="1.5rem"
            id="cvv-info-black-icon"
            className="infotool"
            aria-label="information tool"
          />
        </Button>
      </OverlayTrigger>
    )
  }

  /**
   * @description Renders CVV Field for Saved Credit Card.
   * @returns {JSX.Element} Cvv Field
   */
  renderCvvNumber = ([id, cardLabel]) => {
    return (
      <div className="d-flex align-items-center saved-card-cvv-wrapper">
        <div className="tokenCvv-block ml-md-2" id={`cvvParent${id}`}>
          <div
            className="cvvIframeHeight"
            value=""
            id={`cvvTokenID${id}`}></div>
          <div className="cardCvvtoken">
            {i18nTranslate(
              'validation.cardCvvErrorText',
              'Please enter valid security code'
            )}
          </div>
        </div>
        {this.renderCVVInfoIcon(cardLabel)}
      </div>
    )
  }

  /**
   * @description Renders Card details for Saved Credit Card.
   * @returns {JSX.Element} Card Details
   */
  renderCardDetail = (cardLabel = '', name = '') => {
    const iconType = cardLabel?.provider?.toLowerCase() || ''
    const currentDateTimeStamp = Date.now()
    const { month, year } =
      dateFormatDeps.convertTimeStampToDate(currentDateTimeStamp)
    this.isCardExpired =
      year < cardLabel.expiryYear
        ? true
        : year == cardLabel?.expiryYear
        ? month <= cardLabel?.expiryMonth
          ? true
          : false
        : false

    const endingText = i18nTranslate('payment.cardEndingTxt', '-****')
    const expiryText = i18nTranslate('payment.cardExpiresText', 'Expires')
    const expiredText = i18nTranslate(
      'payment.cardExpiredText',
      'Card is Expired'
    )
    return (
      <>
        <div className="mx-2 pb-2">{`${name?.firstName?.toUpperCase()} ${
          name?.firstName?.toUpperCase() !== name?.lastName?.toUpperCase()
            ? name?.lastName?.toUpperCase()
            : ''
        }`}</div>
        <div className="d-flex mx-2">
          <PaymentIcon type={iconType} />
          <div className="credit-card-info-wrapper">
            <h5 className="card-info ml-1">
              {capitalizeFirstLetter(cardLabel?.provider)} {endingText}{' '}
              {cardLabel?.cardNumber}
            </h5>
            <span
              className={
                this.isCardExpired
                  ? 'paragraph-s ml-1 d-flex'
                  : 'paragraph-s text-danger ml-1 d-flex'
              }>
              {this.isCardExpired ? expiryText : expiredText}{' '}
              {`${cardLabel?.expiryMonth}/${cardLabel?.expiryYear?.substr(2)}`}
            </span>
          </div>
        </div>
      </>
    )
  }

  /**
   * @description Renders Saved Credit Card Options and its details.
   * @returns {JSX.Element} Card Options
   */
  renderSavedPaymentsList = () => {
    let paymentResponseArray = [...customerContainer.paymentResponse?.payments]
    paymentResponseArray.forEach((elem, idx) => {
      if (elem?.id === this.isDefaultCardId) {
        paymentResponseArray?.splice(idx, 1)
        paymentResponseArray?.unshift(elem)
      }
    })

    return paymentResponseArray.map(cards => {
      const {
        accountInfo = {},
        isDefault = false,
        type = '',
        id = '',
      } = cards || {}
      const name = {
        firstName: accountInfo?.name || '',
        lastName: accountInfo?.lastName || '',
      }
      const isSelected = isDefault
      if (isSelected) {
        this.loadIframeInitially = true //passing this as true if there is default card in the payments array list
      }
      const deleteLabel =
        type === 'CREDITCARD'
          ? i18nTranslate('cart.deleteCard', 'Delete Card')
          : i18nTranslate('cart.delete', 'Delete')

      const cardLabel = getCardDetails(accountInfo)

      const isToHideDiscoverCardForCA =
        storeContainer?.storeIDValue === 'Canada' &&
        cardLabel?.provider === 'DISCOVER'
          ? true
          : false

      if (type === 'CREDITCARD' && !isToHideDiscoverCardForCA) {
        return (
          <div
            className={`mb-4 flex-sm-row ${
              checkoutContainer.isPaymentApiInProgress
                ? 'disable-gps-payment-section'
                : ''
            }`}
            key={'container' + id}
            data-testid="qa-saved-card">
            <div
              className="d-sm-flex justify-content-between"
              id="saved-card-wrapper"
              data-testid="qa-saved-card-row">
              <div
                style={{
                  width: '100%',
                  objectFit: 'contain',
                }}>
                <div
                  id="card-details-wrapper"
                  key={'inner' + id}
                  role="radio"
                  aria-checked={isSelected}
                  tabIndex={0}
                  onClick={() => this.handleSavedCardOptionChange(cards)}
                  onKeyPress={() => this.handleSavedCardOptionChange(cards)}>
                  <Form.Check
                    id={`cards-${id}`}
                    name="paymentListRadios"
                    className="saved-payment-info-wrapper"
                    key={'radio' + id}
                    label={this.renderCardDetail(cardLabel, name)}
                    type="radio"
                    checked={isSelected}
                    aria-checked={isSelected}
                    tabIndex={0}
                    data-testid={isSelected ? 'qa-facet-selected' : 'qa-facet'}
                    onClick={() => this.handleSavedCardOptionChange(cards)}
                    onKeyPress={() => this.handleSavedCardOptionChange(cards)}
                    disabled={checkoutContainer.isPaymentApiInProgress}
                  />
                </div>
                {!application.isMobile &&
                  this.renderDeleteCardBlock(isSelected, cards, deleteLabel)}
              </div>
              {isSelected && type === 'CREDITCARD' && (
                <div
                  id="gps-checkout-payment-cvv"
                  className="d-flex align-items-center">
                  <CommonForm
                    schema={cardCVVSchema(cardLabel)}
                    formTypeJson={renderFormInput({
                      renderCvvNumber: this.renderCvvNumber.bind(this, [
                        cards.id,
                        cardLabel,
                      ]),
                    })}
                    SubmitButtonProps={
                      this.renderContinueButtonForSavedPayments
                    }
                    hasInlineSubmitButton={false}
                    classNameValue={constructStyle}
                    onSubmit={() => {
                      this.handleSavedCreditCardContinue()
                    }}
                  />
                </div>
              )}
            </div>
            {application.isMobile &&
              this.renderDeleteCardBlock(isSelected, cards, deleteLabel)}
          </div>
        )
      }
      return <></>
    })
  }

  /**
   * @description Renders Saved Credit Card Options and its details.
   * @returns {JSX.Element} Card Options
   */
  renderNewCreditCardPaymentForm = () => {
    const { handlePayment, isFromCheckout = false } = this.props
    return (
      <>
        <div className="d-flex pt-3 justify-content-between px-3">
          <p className="font-weight-bold">
            {i18nTranslate('payment.CardDetailHeading', 'Credit Card Details')}
          </p>
          <div className="d-flex secureIcon">
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 5.83334C3 3.07192 5.23858 0.833344 8 0.833344C10.7614 0.833344 13 3.07192 13 5.83334V8.33334H13.8333C14.7538 8.33334 15.5 9.07953 15.5 10V17.5C15.5 18.4205 14.7538 19.1667 13.8333 19.1667H2.16667C1.24619 19.1667 0.5 18.4205 0.5 17.5V10C0.5 9.07953 1.24619 8.33334 2.16667 8.33334H3V5.83334ZM4.66667 8.33334H11.3333V5.83334C11.3333 3.99239 9.84095 2.50001 8 2.50001C6.15905 2.50001 4.66667 3.99239 4.66667 5.83334V8.33334ZM2.16667 10V17.5H13.8333V10H2.16667Z"
                fill="#5F5F5F"
              />
            </svg>
            <span className="secureIconText">
              {i18nTranslate('payment.secure', 'SECURE')}
            </span>
          </div>
        </div>
        <PaymentMarketForm
          onSubmitForm={handlePayment}
          isFromCheckout={isFromCheckout}
          cancelPaymentFlag={this.cancelPaymentFlag}
          billingAddressType="checkout"
        />
      </>
    )
  }

  /**
   * @description Renders Continue and Cancel Button for Add New Alternate Payments.
   * @returns {JSX.Element} Continue and Cancel Button
   */
  renderContinueButtonForOtherPayments = (type = '') => {
    return (
      <div className="d-block">
        <div className="mt-4 mb-2">
          <Row className="d-flex w-100 mb-3 mt-4" noGutters>
            <Col className="pr-1" md={6} sm={6} xs={6}>
              <Button
                type="submit"
                data-testid="qa-account-save"
                className="add-payment-continue-button w-100"
                disabled={this.isAddPaymentCallInProgress}
                onClick={() => this.handleAlternatePayment(type)}>
                {i18nTranslate('checkout.continue', 'Continue')}
              </Button>
            </Col>
            <Col className="pl-1" md={6} sm={6} xs={6}>
              <Button
                variant="outline-primary"
                type="submit"
                form="payment-form"
                className="add-payment-cancel-button w-100"
                data-testid="qa-add-new-pay-cancel"
                onClick={this.cancelPaymentFlag}>
                {i18nTranslate('checkout.cancel', 'Cancel')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  /**
   * @description Renders Klarna's terms of service and privacy policy.
   */
  renderKlarnaTermsAndServiceLink = () => {
    if (IS_BROWSER && this.selectedPaymentTypeForAdd === 'klarna') {
      const links = new FormInputLabels()
      return (
        <div
          className="klarna-terms-message"
          data-testid="qa-klarna-terms-message">
          <span className="pr-1">
            {i18nTranslate(
              'checkout.klarnaMessage1',
              'By continuing, I accept the'
            )}
          </span>
          <span
            className="pr-1 klarna-link"
            data-testid="qa-klarna-service-link">
            <a
              href={links?.retailCustomerLinks?.klarnaShoppingServiceLink}
              target="_blank"
              rel="noopener noreferrer">
              {i18nTranslate(
                'checkout.klarnaServiceLink',
                'Klarna Shopping Service'
              )}
            </a>
          </span>
          <span className="pr-1">
            {i18nTranslate(
              'checkout.klarnaMessage2',
              'terms and confirm that I have read the'
            )}
          </span>
          <span data-testid="qa-klarna-policy-link" className="klarna-link">
            <a
              href={links?.retailCustomerLinks?.klarnaPrivacyPolicyLink}
              target="_blank"
              rel="noopener noreferrer">
              {i18nTranslate('checkout.klarnaPrivacyLink', 'Privacy Policy.')}
            </a>
          </span>
        </div>
      )
    }
  }

  /**
   * @description Renders a description for the given payment type.
   */
  renderDescriptionForPayment = (paymentType = '') => {
    if (paymentType === 'klarna') {
      return this.renderKlarnaTermsAndServiceLink()
    }
    return <></>
  }

  /**
   * @description Renders Payment Icon for the add new payment options.
   */
  renderPaymentIcons = paymentDetails => {
    const { paymentInfo = {}, paymentName = '' } = paymentDetails || {}
    const isPaymentIconURLExists = paymentInfo?.some(payment => {
      const iconURL = payment?.iconURL || ''
      return iconURL !== ''
    })
    if (isPaymentIconURLExists) {
      return (
        <Row noGutters className="icon-wrapper">
          {paymentInfo?.map((payment, index) => {
            const { iconURL = '', displayName = '' } = payment
            if (iconURL) {
              return (
                <ImageComponent
                  testId={`qa-${displayName}-icon`}
                  src={iconURL}
                  name={displayName}
                  key={`${displayName}-${index}`}
                  width="40px"
                  height="25px"
                  title={displayName}
                  aria-label={`${displayName}-icon`}
                  className="payment-icons"
                  customParentClassName="mr-1 mb-1"
                  overrideParentClass={true}
                />
              )
            }
          })}
        </Row>
      )
    }

    return <p className="text-capitalize ml-2 mb-0">{paymentName}</p>
  }

  /**
   * @description Renders Payment Option Radio Button for the add new payments list.
   */
  renderPaymentOptionRadioButton = (paymentDetails = {}) => {
    const {
      type = '',
      displayName = '',
      iconType = '',
      dataTestId = 'qa-payment-radio-option',
      paymentId = '',
      isToShowPaymentOption = false,
      isToShowDescription = false,
      paymentInfo = {},
    } = paymentDetails || {}
    const paymentType = type?.toLowerCase() || ''
    return (
      isToShowPaymentOption && (
        <>
          <input
            type="radio"
            name="paymentTypeRadios"
            id={paymentId}
            onChange={() => this.handlePaymentTypeSelect(paymentType)}
            key={'radio' + paymentType}
            checked={this.selectedPaymentTypeForAdd === paymentType}
            className="payment-options w-100"
          />
          <label htmlFor={paymentId}>
            <div className="add-payment-block-wrapper">
              <p className="text-capitalize payment-type-title">
                {displayName}
              </p>
              {this.renderPaymentIcons({
                paymentInfo,
                iconType,
                paymentName: displayName,
              })}
              {isToShowDescription &&
                this.renderDescriptionForPayment(paymentType)}
            </div>
          </label>
        </>
      )
    )
  }

  /**
   * @description Renders Add New Payments List.
   * If GPS is Enabled, then Payment Options from GPS API is listed based on payment type
   * Else, Error Message is shown.
   */
  renderAddNewPaymentOptions = () => {
    const enableGlobalPaymentSystem = isGPSEnabled()
    const isSuccessResponse = checkoutContainer.isSuccessResponse(
      checkoutContainer.globalPaymentResponse
    )

    if (!checkoutContainer.isGlobalPaymentCallInProgress) {
      if (
        enableGlobalPaymentSystem &&
        isSuccessResponse &&
        Object.keys(checkoutContainer.globalPaymentResponse)?.length > 0
      ) {
        return Object.keys(checkoutContainer.globalPaymentResponse)?.map(
          paymentType => {
            const paymentDetails =
              checkoutContainer.globalPaymentResponse[paymentType] || []
            if (isArray(paymentDetails) && paymentDetails?.length > 0) {
              const paymentName = paymentType?.toLowerCase() || ''
              if (paymentName) {
                return this.renderPaymentOptionRadioButton({
                  type: paymentName,
                  displayName: paymentName,
                  iconType: paymentName,
                  isToShowPaymentOption: true, // condition to be added
                  dataTestId: `qa-${paymentName}-option`,
                  paymentId: `payment-${paymentName}`,
                  paymentInfo: paymentDetails,
                })
              }
            }
            return <></>
          }
        )
      } else if (enableGlobalPaymentSystem && !isSuccessResponse) {
        return (
          <p>
            {i18nTranslate(
              'payment.gpsFailureMessage',
              'We ran into a technical error, please try again after sometime'
            )}
          </p>
        )
      } else {
        return (
          <p>
            {i18nTranslate(
              'payment.gpsErrorMessage',
              'We ran into a technical error, please try again after sometime'
            )}
          </p>
        )
      }
    }
    return <></>
  }

  /**
   * @description Renders the section to add a new payment method.
   *
   * Displays a message to add payment methods, then conditionally renders:
   * - The list of options to add a new payment method
   * - The credit card form  and continue button if that option was selected
   * - The continue button for other non-credit card options if one was selected
   */
  renderAddNewPaymentSection = () => {
    return (
      <>
        <div className="add-new-payment-container">
          <p className="font-weight-bold" tabIndex={0}>
            {i18nTranslate('checkout.addPaymentMethods', 'Add Payment Methods')}
          </p>
          <div className="add-new-payment-option">
            {this.renderAddNewPaymentOptions()}
          </div>
        </div>
        {this.selectedPaymentTypeForAdd === 'creditcard' &&
          this.renderNewCreditCardPaymentForm()}
        {this.selectedPaymentTypeForAdd !== 'creditcard' &&
          this.selectedPaymentTypeForAdd !== '' &&
          this.renderContinueButtonForOtherPayments(
            this.selectedPaymentTypeForAdd
          )}
      </>
    )
  }

  /**
   * @description Renders the tabs for Saved Payments and Add New Payment.
   *
   * Sets the default active tab based on saved cards.
   * Renders the Add New Payment section and Saved Payments list conditionally.
   * Handles selecting a new tab.
   * Shows pagination if enabled and multiple pages.
   */
  renderTabs = () => {
    const defaultActiveKey = this.getDefaultPaymentTab()
    const savedCard = i18nTranslate('checkout.savedPayment', 'Saved Payments')
    const addNewCard = i18nTranslate('checkout.addPayment', 'Add New Payment')
    const { payments = [], pageableInfo = {} } =
      customerContainer.paymentResponse || {}
    const { page = 1, count = 1 } = pageableInfo || {}
    this.currentPage = count == 1 ? page - 1 : page
    this.totalPagesLength = getTotalPagesForPagination(pageableInfo)
    const isPaymentPaginationEnabled =
      APPConfig?.getAppConfig()?.isPaymentPaginationEnabled === 'true' || false
    let isToShowSavedCardTab = this.isToShowSavedCardsTab(payments)

    return (
      <div className="position-relative mx-0 mx-lg-3 checkout-payment-form">
        {payments?.length !== 0 && (
          <Tabs
            defaultActiveKey={defaultActiveKey}
            id="uncontrolled-tab-example"
            onSelect={tabName => this.handleSelectedPaymentTab(tabName)}
            data-testid="qa-payment-cards-wrapper">
            <Tab
              eventKey="newCard"
              title={addNewCard}
              data-testid="qa-add-payment-tab">
              {this.renderAddNewPaymentSection()}
            </Tab>

            {isToShowSavedCardTab && (
              <Tab
                className="py-4"
                eventKey="savedcards"
                title={savedCard}
                data-testid="qa-saved-payment-tab">
                {this.renderSavedPaymentsList()}
                {this.totalPagesLength > 1 && isPaymentPaginationEnabled && (
                  <Pagination
                    className="payment-pagination-wrapper"
                    handlePagination={this.handlePagination}
                    totalPages={this.totalPagesLength}
                    active={page}
                    numOfPaginationsToShow={this.totalPagesLength}
                    alignPagination={application.isMobile ? 'center' : 'start'}
                  />
                )}
              </Tab>
            )}
          </Tabs>
        )}

        {payments?.length === 0 && (
          <Tabs
            defaultActiveKey="newcard"
            id="uncontrolled-tab-example"
            data-testid="qa-payment-cards-wrapper">
            <Tab eventKey="newcard" title={addNewCard}>
              {this.renderAddNewPaymentSection()}
            </Tab>
          </Tabs>
        )}
      </div>
    )
  }

  render() {
    if (!checkoutContainer.isGlobalPaymentCallInProgress) {
      return (
        <>
          {this.renderTabs()}
          {this.isToShowEditUpdatePaymentModal && (
            <EditUpdatePaymentModal
              handleCheckoutChange={this.handleDefaultChecked}
              show={this.isToShowEditUpdatePaymentModal}
              closeButton={this.handleUpdateModalClose}
              billingAddressType="checkout"
              IDofCardToBeEdited={this.IdofCardToBeEdited}
              isFromCheckout={true}
              cardDetails={this.cardsDetails}
              closeModalCallback={this.handleUpdateModalClose}
            />
          )}
        </>
      )
    }

    return <></>
  }
}

export { GlobalPaymentsOptionsList }
export default GlobalPaymentsOptionsList
