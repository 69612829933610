import React from 'react'
import { Form, InputGroup, FormControl, Modal } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { IoIosAdd } from 'react-icons/io'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { customerContainer } from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import { SignInAccordion } from '../CheckoutAccordions'

@observer
class CreateListForm extends React.Component {
  @observable listName = ''
  @observable isVisible = false

  handleInputChange = event => {
    this.listName = event.target.value
  }

  renderSignInAccordion = () => {
    return <SignInAccordion {...this.props} onContinue={this.onContinue} />
  }

  navigateToSignIn = () => {
    const { pathname, search } = this.props?.location || {}
    customerContainer.navigeBackTo = pathname + search
    this.props.history.push(pageNames.signIn)
  }

  handleCreateList = event => {
    event.preventDefault()
    if (customerContainer.isRegisterUser) {
      const properties = this.props?.product || this.props?.listItemProps || {}
      this.props.moveToList(this.listName, properties)
    } else {
      this.isVisible = true
    }
    this.listName = ''
  }

  render() {
    return (
      <>
        <Form>
          <Form.Label
            className="mb-0"
            onClick={e => {
              e.stopPropagation()
            }}>
            {i18nTranslate('createList', 'Create List')}
          </Form.Label>
          <InputGroup className="d-flex align-items-center create-list">
            <FormControl
              className="border-left-0 border-right-0 border-top-0 border-bottom py-0 h-auto"
              onClick={e => {
                e.stopPropagation()
                if (!customerContainer.isRegisterUser) {
                  this.isVisible = true
                }
              }}
              onChange={this.handleInputChange}
              value={this.listName}
            />
            <InputGroup.Prepend
              as="button"
              onClick={this.handleCreateList}
              className="border-0 bg-transparent">
              <IoIosAdd className="create-list-button" />
            </InputGroup.Prepend>
          </InputGroup>
        </Form>

        <Modal
          centered={true}
          size={'lg'}
          className="align-modal"
          show={this.isVisible}
          onHide={() => (this.isVisible = !this.isVisible)}
          dialogClassName="checkout-dialog">
          <Modal.Header closeButton className={'border-bottom-0'} />
          <Modal.Body>{this.renderSignInAccordion()}</Modal.Body>
        </Modal>
      </>
    )
  }
}

export { CreateListForm }
