import { observable, action } from 'mobx'
import delay from 'lodash/delay'
import { getLiveEventStatus } from 'src/utils'

class ToastState {
  @observable toastMessage = ''
  @observable isVisible = false
  @observable isViewCartBtnVisible = false
  @observable reDirection = ''
  @observable successCondition = ''
  @observable isLiveEventEnabled = false
  @observable description = ''
  @observable customClass = ''
  toastAnimationAttributes = {
    animationDelay: 6000,
    offSet: 'bottom',
  }
  // Toast list for additional toast when a toast is already visible
  toastList = []
  delayId = null

  @action
  hideVisibility = () => {
    this.isVisible = false
    this.isViewCartBtnVisible = false
    this.toastMessage = ''
    // Show next toast message if available
    if (this.isLiveEventEnabled === true) {
      // Clear existing delay timer to prevent it from hiding the next toast
      clearTimeout(this.delayId)
      if (this.toastList?.length > 0) {
        const nextToast = this.toastList?.pop()
        this.setToastMessage(
          nextToast?.message,
          nextToast?.successCondition,
          nextToast?.delayms,
          nextToast?.hyperLink,
          nextToast?.isViewCartBtnVisible,
          nextToast?.isLiveEventEnabled,
          nextToast?.description,
          nextToast?.customClass
        )
      }
    }
  }

  @action
  setToastMessage = (
    message,
    successCondition,
    delayms,
    hyperLink,
    isViewCartBtnVisible,
    isLiveEventEnabled = false,
    description = '',
    customClass = ''
  ) => {
    // If toast is already visible and new toast message is different, add new toast to the list
    if (
      this.isVisible === true &&
      this.toastMessage !== message &&
      isLiveEventEnabled === true
    ) {
      this.isLiveEventEnabled = true
      // Avoid adding duplicate toast messages
      if (!this.toastList.some(item => item?.message === message)) {
        this.toastList.unshift({
          message,
          successCondition,
          delayms,
          hyperLink,
          isViewCartBtnVisible,
          isLiveEventEnabled,
          description,
          customClass,
        })
      }
    } else {
      // Screen reader don't announce repeated, identical, aria live messages. https://core.trac.wordpress.org/ticket/36853
      // add  space character \u00A0 to fix the issue
      if (this.toastMessage === message) {
        this.toastMessage = message + '\u00A0'
      } else {
        this.toastMessage = message
      }
      this.reDirection = hyperLink
      this.isVisible = true
      this.isViewCartBtnVisible = isViewCartBtnVisible
      this.successCondition = successCondition
      if (delayms && delayms > 6000) {
        this.toastAnimationAttributes.animationDelay = delayms
      } else {
        this.toastAnimationAttributes.animationDelay = 6000
      }

      /* If live event is enabled
    isLiveEventEnabled prop is used to toggle this.isLiveEventEnabled */
      if (isLiveEventEnabled === true) {
        // Clear existing delay timer to prevent it from hiding the next toast
        clearTimeout(this.delayId)
        this.isLiveEventEnabled = true
        if (customClass) {
          this.customClass = customClass
        }
        if (customClass === 'live-event-toast-info') {
          this.toastAnimationAttributes.offSet = 'bottom'
        } else {
          this.toastAnimationAttributes.offSet = 'top'
        }
        this.description = description
      } else if (isLiveEventEnabled === false) {
        this.isLiveEventEnabled = false
        this.toastAnimationAttributes.offSet = 'bottom'
        this.description = ''
      }

      this.delayId = delay(
        this.hideVisibility,
        this.toastAnimationAttributes.animationDelay
      )
    }
  }
}
const toastState = new ToastState()

export { toastState }
