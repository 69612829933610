import React, { Component } from 'react'
import { observable } from 'mobx'
import { i18nTranslate } from 'src/utils'
import { Row, Col, Container } from 'react-bootstrap'
import { MobileMenu } from './MobileMenuNuskin/MobileMenu'
import { APPConfig } from 'config/appConfig'
import { customerContainer } from 'src/models'
import { CategoryMenu } from './CategoryMenu'

class MegaMenuSection extends Component {
  nameSpace = 'ssr-resource'
  middleHeader = ''
  categoryWidth = '66%'
  hardcodedList = []
  mobileHardcodedList = []

  @observable searchOpen = false

  constructor(props, context) {
    const getFooterDataFromCS =
      APPConfig?.getAppConfig()?.enableImpactSectionFromCS == 'true'
    super(props, context)
    this.state = {}
    //  if enableImpactSectionFromCS is false, the harcoded our impact will get loaded
    if (!getFooterDataFromCS) {
      this.getHardCodedMenus()
    }
  }

  getHardCodedMenus = () => {
    const ourImpactText = i18nTranslate('megamenu.ourimpact', 'Our Impact', {
      nameSpace: this.nameSpace,
    })
    const sustainabilityText = i18nTranslate(
      'megamenu.ourimpact.sustainability',
      'Sustainability',
      { nameSpace: this.nameSpace }
    )
    const socialImpactText = i18nTranslate(
      'megamenu.ourimpact.socialimpact',
      'Social Impact',
      { nameSpace: this.nameSpace }
    )
    this.hardcodedList = [
      {
        properties: {
          meta_title: ourImpactText,
          name: ourImpactText,
          description: ourImpactText,
        },
        parentId: 'top',
        subCategories: [
          {
            properties: {
              meta_title: '',
              name: '',
              description: '',
            },
            parentId: 'top',
            subCategories: [
              {
                properties: {
                  meta_title: sustainabilityText,
                  name: sustainabilityText,
                  description: sustainabilityText,
                },
                parentId: 'ourimpact',
                identifier: 'sustainability',
              },
              {
                properties: {
                  meta_title: socialImpactText,
                  name: socialImpactText,
                  description: socialImpactText,
                },
                parentId: 'ourimpact',
                identifier: 'socialimpact',
              },
            ],
            identifier: 'ourimpact',
          },
        ],
        identifier: 'ourimpact',
        isDummyCategory: true,
      },
    ]

    this.mobileHardcodedList = JSON.parse(JSON.stringify(this.hardcodedList))
    this.mobileHardcodedList[0]['subCategories'] =
      this.mobileHardcodedList?.[0].subCategories?.[0]?.subCategories || []
  }

  componentDidMount() {
    const children = this.middleHeader.children
    let totalWidth = 0
    if (children !== undefined) {
      Array.from(children, child => (totalWidth += child.offsetWidth))
    }
    this.categoryWidth = this.middleHeader.clientWidth - totalWidth - 48
  }

  toggleSearch = () => {
    this.searchOpen = !this.searchOpen
  }

  render() {
    const isGuestB2B = customerContainer.isGuestB2B

    const categoryResponseList = [
      ...(this.props?.categoryResponse?.category?.subCategories || []),
      ...this.hardcodedList,
    ]
    const mobileCategoryResponseList = [
      ...(this.props?.categoryResponse?.category?.subCategories || []),
      ...this.mobileHardcodedList,
    ]
    return (
      <>
        <Container
          fluid
          className="header-bottom-container d-none d-lg-flex"
          id="id-header-bottom-container">
          <Row
            noGutters
            className="w-100 m-0 p-0 justify-content-center h-100 d-flex flex-row main-section flex-nowrap"
            ref={element => (this.middleHeader = element)}>
            <Col
              lg={8}
              className="p-0 m-0 order-lg-1 h-100 d-none d-flex justify-content-center"
              style={{
                width: `${this.categoryWidth}px`,
                position: 'initial',
                height: '100%',
              }}>
              {!isGuestB2B &&
                (this.props.categoryResponse?.category?.subCategories || [])
                  .length > 0 && (
                  <CategoryMenu
                    categoryResponse={categoryResponseList}
                    categoryWidth={this.categoryWidth}
                  />
                )}
            </Col>
          </Row>
        </Container>

        {!isGuestB2B && (
          <MobileMenu
            {...this.props}
            data={this.props.data}
            categoryResponse={{
              category: { subCategories: mobileCategoryResponseList },
            }}
            isNuskinHeader
          />
        )}
      </>
    )
  }
}

export default MegaMenuSection
