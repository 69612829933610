import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { getStack } from '@ns/ns-contentstack-lib'
import parse from 'html-react-parser'
import {
  i18nTranslate,
  getLocaleCodeFromUrl,
  convertToBoolean,
} from 'src/utils'
import { Button, Modal } from 'react-bootstrap'
import { checkboxValidationCheck } from 'src/utils/signUpUtils'
import { accountsContainer } from 'src/models'
import { getLocalStorage, APPConfig } from 'config/appConfig'
import { trackSignUpEvents } from 'src/utils'
import { brandAffiliate, retailCustomer } from 'src/static/fixtures/links'
import './styles.scss'

@observer
class IronCladContractPage2 extends Component {
  constructor(props) {
    super(props)
  }
  @observable ironCladContentFromCS = {}

  componentDidMount() {
    this.getIronCladContentFromCS()
  }

  getIronCladContentFromCS = async () => {
    const Stack = getStack('test' || 'prod')
    const curLocale = getLocaleCodeFromUrl({
      isReverseType: true,
      defaultLocale: 'en_US',
    })

    const [language, country] = curLocale?.includes('_')
      ? curLocale?.split('_')
      : curLocale?.split('-')
    const [signup_strings, jitsu_strings] = await Stack.getSingletonEntries({
      contentTypeUIDs: ['signup_strings'],
      country: country,
      language: language,
      camelcase: true,
      mergeWithFallback: true,
    })
    this.ironCladContentFromCS = {
      ...signup_strings,
    }
  }

  renderLegacyIronClad = () => {
    return (
      <Modal
        show={this.props?.isToShowStep2}
        onHide={() => this.props?.handleIronCladContractClose()}
        backdrop="static"
        className="ps-contracts">
        <Modal.Header closeButton>Page 2 of 3</Modal.Header>
        <Modal.Title className="p-3">
          {i18nTranslate('ironClad.almostThere', 'Almost There...')}
        </Modal.Title>
        <Modal.Body>
          <div className="modal-scroll">
            <h3>
              {i18nTranslate(
                'ironClad.importantDisclosures',
                'IMPORTANT DISCLOSURES'
              )}
            </h3>
            <h5>
              {i18nTranslate(
                'ironClad.buyingAndSelling',
                'Buying and Selling Products'
              )}
            </h5>
            <p>
              {i18nTranslate(
                'ironClad.varietyOfProducts',
                'Nu Skin has a variety of products you can purchase and resell to customers. You are not required to purchase any products to earn compensation. Brand Affiliates receive compensation only when products are sold. Please review our Return Policy to learn more.'
              )}
            </p>
            <h5>
              {i18nTranslate(
                'ironClad.salesCompensation',
                'Sales Compensation Plan'
              )}
            </h5>
            <p>
              {i18nTranslate(
                'ironClad.earningCompensation',
                'As a Brand Affiliate you are eligible to earn compensation under Nu Skin’s Sales Compensation Plan by selling Nu Skin products.  You should read the plan carefully, as it sets forth the terms for earning compensation.'
              )}
              <br />
              <br />
              {i18nTranslate(
                'ironClad.salesPlanCompensation',
                'We have the right to amend the Sales Compensation Plan at any time by providing 30 days’ notice. '
              )}
            </p>
            <h5>
              {i18nTranslate(
                'ironClad.bankingInformation',
                'Banking Information'
              )}
            </h5>
            <p>
              {i18nTranslate(
                'ironClad.earnedCompensation',
                'To receive any earned compensation, you must provide your banking information. You can add this to your Profile once your account is created.'
              )}
            </p>
            <h5>
              {i18nTranslate(
                'ironClad.salesSummary',
                'Sales Compensation Summary'
              )}
            </h5>
            <p>
              {i18nTranslate(
                'ironClad.generatingSales',
                'Generating sales compensation as a Brand Affiliate requires time, effort, skill, and commitment. How much compensation you generate will also depend on your talents and leadership abilities. There is no guarantee of financial success, and results vary widely among participants.'
              )}
            </p>
            <h3>
              {i18nTranslate(
                'ironClad.realisticExpectations',
                'It is important that you have realistic expectations about potential earnings. Please review our Sales Compensation Summary carefully.'
              )}
            </h3>
          </div>

          <div
            id="clickwrap-container-2"
            className="click-wrap-container"></div>
          <div className="clickwrapContainerError">
            {' '}
            {i18nTranslate(
              'ironClad.termsAndConditions',
              'Please accept the terms & conditions*'
            )}
          </div>
          <Button
            data-testid="qa-createaccount-createbutton"
            className="mt-3 create-account-btn"
            variant="primary"
            type="submit"
            disabled={this.props.disableButton}
            onClick={() => {
              this.validationChecks()
            }}
            block>
            {i18nTranslate('button.acceptContinue', 'Accept and Continue')}
          </Button>
        </Modal.Body>
      </Modal>
    )
  }

  contentStackTranslation = (key, defaultString) => {
    let localeString = defaultString
    if (this.ironCladContentFromCS?.[key]) {
      localeString = this.ironCladContentFromCS[key]
    }
    return localeString
  }

  renderNewIronClad = () => {
    const {
      isFromSignup = false,
      isFromTerms = false,
      dataIroncladRerender,
    } = this.props

    const buttonRenderedText = isFromSignup
      ? i18nTranslate('signup.submit', 'Create My Account')
      : i18nTranslate('button.acceptContinue', 'Accept and Continue')
    const isCancelText = isFromSignup
      ? i18nTranslate('ironclad.nevermind', 'Return to Home')
      : i18nTranslate('address.cancel', 'Cancel')
    return (
      <Modal
        show={this.props?.isToShowStep2}
        onHide={() => this.props?.handleIronCladContractClose()}
        backdrop="static"
        className="ps-contracts">
        <Modal.Header closeButton className="modal-title-new-ironclad">
          {this.contentStackTranslation('oneLastStep', 'One Last step...')}
        </Modal.Header>
        <Modal.Body className="modal-scroll-new-ironclad">
          <div className="modal-header-new-ironclad">
            {this.contentStackTranslation(
              'importantDisclosures',
              'IMPORTANT DISCLOSURES'
            )}
          </div>
          <div>
            {parse(
              this.contentStackTranslation(
                'importantDisclosuresContent',
                'Important Disclourse Content'
              )
            )}
          </div>
          <div className="modal-header-new-ironclad">
            {this.contentStackTranslation('legalAgreement', 'LEGAL AGREEMENT')}
          </div>
          <div>
            {parse(
              this.contentStackTranslation(
                'legalAgreementContent',
                'Important Disclourse Content'
              )
            )}
          </div>
          <div
            id="clickwrap-container-2"
            className="click-wrap-container"
            data-is_iron_clad_rerender={dataIroncladRerender}></div>
          <div
            id="clickwrap-container-3"
            className="click-wrap-container"
            data-is_iron_clad_rerender={dataIroncladRerender}></div>
          <div className="clickwrapContainerError">
            {' '}
            {i18nTranslate(
              'ironClad.termsAndConditions',
              'Please accept the terms & conditions*'
            )}
          </div>
          <Button
            data-testid="qa-createaccount-createbutton"
            className="mt-3 create-account-btn"
            variant="primary"
            type="submit"
            disabled={this.props.disableButton}
            onClick={() => {
              this.validateIronClad()
            }}
            block>
            {buttonRenderedText}
          </Button>
          <Modal.Footer className="ironclad-popup">
            <Button
              variant="primary"
              className="mt-3 btn-outline-primary"
              onClick={() => {
                accountsContainer.signUpIronCladCheck = {
                  clickWrap1: false,
                  clickWrap2: false,
                  clickWrap3: false,
                }
                this.props?.handleIronCladContractClose()
              }}
              block>
              {isCancelText}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    )
  }

  openDocument = documentLink => {
    if (typeof window !== 'undefined') {
      window.open(documentLink)
    }
  }
  validationChecks = () => {
    const enableJitSuIroncladFeature = convertToBoolean(
      APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
    )
    if (
      accountsContainer?.accountsContractCheck === false &&
      this.props?.isFromAccounts
    ) {
      checkboxValidationCheck('add')
      return false
    } else {
      trackSignUpEvents({
        eventType: this.props?.isFromModal
          ? 'eqDistInviteCompleteForm2'
          : 'eqDistColdCompleteForm2',
      })
      if (enableJitSuIroncladFeature) {
        this.props?.handleProceed()
      } else {
        this.props?.showIronCladPopup()
      }
    }
  }

  validateIronClad = () => {
    const { isFromSignup } = this.props
    const enableJitSuIroncladFeature = convertToBoolean(
      APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
    )
    let ironCladCheckedValue = accountsContainer?.signUpIronCladCheck

    if (
      enableJitSuIroncladFeature &&
      ironCladCheckedValue.clickWrap2 === true &&
      ironCladCheckedValue.clickWrap3 === true
    ) {
      this.validationChecks()
    } else {
      checkboxValidationCheck('add')
      return false
    }
  }
  render() {
    const defaultLocale = getLocalStorage('defaultLocale') || 'en-US'

    const locale = defaultLocale?.toLowerCase?.() || ''
    const refundPolicy = retailCustomer?.[locale]?.refundPolicy || ''
    const salesCompensation =
      brandAffiliate?.[locale]?.salesCompensationSummary || ''
    const enableJitSuIroncladFeature = convertToBoolean(
      APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
    )
    if (enableJitSuIroncladFeature) {
      return this.renderNewIronClad()
    }
    return this.renderLegacyIronClad()
  }
}

export default IronCladContractPage2
export { IronCladContractPage2 }
