import React from 'react'
import { observer } from 'mobx-react'
import { IoIosCloseCircle as CloseIcon } from 'react-icons/io'
import isArray from 'lodash/isArray'
import {
  Button,
  Col,
  Row,
  Card,
  Badge,
  OverlayTrigger,
  Form,
} from 'react-bootstrap'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'
import { enableStorePickup, VARIANT_NODES, APPConfig } from 'config/appConfig'
import {
  getLocalStorage,
  application,
  getLocaleString,
  checkEnableButton,
  currencyFormat,
  checkMysiteOrPersonalOffer,
  getLiveEventStatus,
  i18nTranslate,
} from 'src/utils'
import {
  Quantity,
  AccordionBlock,
  storePickupState,
  SubscriptionDropDown,
  toastState,
  ImageComponent,
  SingleProductSubscriptionModal,
} from 'src/views/components'
import { SingleLineAddress } from '../SingleLineAddress'
import { ProductPrice } from '../../ProductComponents/ProductSkuDetails/ProductPrice'
import { PreOrderTime } from '../../ProductComponents/PreOrderTime'
import { observable, action } from 'mobx'
import {
  customerContainer,
  cartContainer,
  nuskinSubscriptionContainer,
} from 'src/models'
import {
  dateFormatDeps,
  productDeps,
  isFlexibleSubscriptionFlagEnabled,
} from 'src/deps'
import { CartScanCardWithInfo } from 'src/views/components/SubscriptionComponents/CartScanCardWithInfo'
import { MdShoppingCart as ShoppingCartIcon } from 'react-icons/md'
import ViewCartRefreshIcon from 'src/views/components/Icons/ViewCartRefreshIcon'
import { alertMessageKeys } from '../fixture'
import './styles.scss'

@observer
class CheckoutProductBlock extends React.Component {
  @observable subscriptionParameters = {}
  @observable isSubscribeCheckboxChecked = false
  @observable checkBoxLabel = i18nTranslate('cart.subscribe', 'Subscribe')
  @observable isToShowViewCartModal = false
  enableLiveEvent = getLiveEventStatus()
  constructor(props, context) {
    super()
    this.context = context
    this.contextName = 'productData'
    this.handleChange = this.handleChange.bind(this)
  }

  // uncommenting the below lifecyle method to check Auto authorize login for mysite & personal offer
  // async componentDidMount() {
  //   this.signInUrl = await signInwithRedirect(true)
  // }

  componentWillMount() {
    this.id = `toggle_${Math.random().toString().replace(/0\./, '')}`
  }

  @action
  subscriptionBtnClick = async (props = {}, enableToastMessage = true) => {
    let isSubscription = props.isSubscription || false
    this.isSubscribeCheckboxChecked = !this.isSubscribeCheckboxChecked
    const isBundle = productDeps.isBundleData(props)
    const {
      productId = '',
      skuId = '',
      categoryId = '',
      scanQualifiedCount = 0,
      quantity = 0,
      frequency = 1,
    } = props
    let skus = [
      {
        skuId: skuId,
        productId: productId,
        type: 'DEFAULT',
      },
    ]
    if (isBundle) {
      skus = productDeps.getBundleData(props)
    }
    if (!isSubscription) {
      const postParameters = [
        {
          quantity: quantity,
          skus: skus,
          isSubscription: true,
          subscription: {
            frequencyPeriod: frequency,
            frequencyType: 'MONTHS',
          },
          properties: {
            category: categoryId,
            scanQualifiedCount: scanQualifiedCount,
          },
          frequencyPeriod: frequency,
          frequencyType: 'MONTHS',
          category: categoryId,
          isSubscriptionPreference: customerContainer.isSubscriptionPreference,
          productId: productId,
          skuId: isBundle ? productId : skuId,
        },
      ]
      const subscriptionParams = {
        id: this.props.cartItemId,
        subscriptionData: {
          isSubscription: true,
          subscription: { frequencyPeriod: frequency, frequencyType: 'MONTHS' },
          quantity: quantity,
        },
        type: 'subscription',
      }
      this.subscriptionParameters = postParameters
      this.props.handleSubscribeCheckboxClick(
        subscriptionParams,
        enableToastMessage
      )
    } else {
      const subscriptionParams = {
        id: this.props.cartItemId,
        subscriptionData: {
          isSubscription: false,
        },
        type: 'oneTime',
      }
      this.props.handleSubscribeCheckboxClick(
        subscriptionParams,
        enableToastMessage
      )
    }
    this.isToShowViewCartModal = false
  }

  handleUpdateSubscription = async modalData => {
    if (customerContainer.isRegisterUser) {
      const frequency = modalData?.frequency || 1
      const quantity = modalData?.quantity || 1
      const SubscribedProductSkuId = modalData?.product?.skuId || ''
      const items = cartContainer?.cartResponse?.items || []

      // getting deep copy of cart items, so that on API fail, cart items are not modified
      const cartItems = JSON.parse(JSON.stringify(items))

      let subscriptionItems = cartItems?.filter(item => {
        let skuId = item?.skus?.[0]?.skuId || ''
        return (
          !item?.properties?.hasOwnProperty('isGiftItem') &&
          skuId === SubscribedProductSkuId
        )
      })

      subscriptionItems[0].isSubscription = true
      subscriptionItems[0].quantity = quantity
      subscriptionItems[0].subscription = {
        frequencyPeriod: frequency,
        frequencyType: 'MONTHS',
      }

      const response = await nuskinSubscriptionContainer.updateSubscription(
        subscriptionItems
      )
      if (nuskinSubscriptionContainer.isSuccessResponse(response)) {
        this.toggleSubscribeModal()
      }
    } else {
      this.subscriptionBtnClick(modalData)
    }
  }

  cartAddTrigger = async (props = {}) => {
    const { productId = '', skuId = '', quantity = 0 } = props
    const cartArray = [
      {
        productId: productId,
        quantity: quantity,
        skuId: skuId,
      },
    ]
    if (cartArray) {
      cartContainer.toShowToast = false
      const response = await cartContainer.addToCart({
        cartArray,
      })
      return response
    }
  }

  // subscriptionAddTrigger = async () => {
  //   const response = await subscriptionContainer.addToSubscription(
  //     this.subscriptionParameters
  //   )
  //   console.log(customerContainer,response,'response')
  //   let subscriptionMessage = subscriptionContainer.subscriptionMessage || ''
  //   if (customerContainer.isSuccessResponse(response)) {
  //     // await cartContainer.viewCart()
  //     // toastState.setToastMessage(
  //     //   i18nTranslate('subscription.success', 'Added Subscriptions Successfully')
  //     // )
  //     // this.checkBoxLabel = 'Subscribed'
  //     if (customerContainer.isSuccessResponse(response)) {
  //       // await cartContainer.viewCart()
  //       // toastState.setToastMessage(
  //       //   i18nTranslate('subscription.success', 'Added Subscription Successfully')
  //       // )
  //     } else {
  //       const errorMessage =
  //         response?.message ||
  //         i18nTranslate(
  //           'signup.failure',
  //           'Sorry, we are unable to create your account. Please try again.'
  //         )
  //       toastState.setToastMessage(errorMessage, false)
  //     }

  //     if (
  //       customerContainer.isSuccessResponse(response) &&
  //       !customerContainer.isSubscriptionPreference
  //     ) {
  //       await cartContainer.viewCart()
  //       toastState.setToastMessage(subscriptionMessage, true)
  //     }
  //   } else {
  //     this.props?.handleDeleteCartItem(this.props)
  //     if (response.code === 'YLUSSB015') {
  //       toastState.setToastMessage(response.message, false, 6000)
  //     }
  //   }
  // }

  handleChange = (product = {}) => {
    this.subscriptionBtnClick(product)
  }

  toggleSubscribeModal = () => {
    this.isToShowViewCartModal = !this.isToShowViewCartModal
  }

  handleSubscribeCheck = props => {
    let isSubscription = props.isSubscription || false
    const clickedProductSkuId =
      props?.skuId ||
      props.product?.skuId ||
      props.product?.productId ||
      props.productId ||
      ''

    const sameProductBlockCount =
      nuskinSubscriptionContainer.getClickedProductCountInCart(
        clickedProductSkuId
      )

    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false

    if (customerContainer.isRegisterUser) {
      const userHasExistingSubscriptions =
        nuskinSubscriptionContainer.isUserSubscribedBefore()

      if (userHasExistingSubscriptions && !isFlexibleSubscriptionEnabled) {
        const isProductAlreadySubscribed =
          nuskinSubscriptionContainer.isAlreadySubscribedProduct(props)
        // CX121-4082 - showing already subscribed product (disabled) in modal
        if (isProductAlreadySubscribed) {
          if (isSubscription) {
            this.subscriptionBtnClick(props)
          } else {
            nuskinSubscriptionContainer.clickedProductSkuId =
              clickedProductSkuId
            nuskinSubscriptionContainer.isToShowDisabledModal = true
          }
        } else {
          if (!isSubscription) {
            sameProductBlockCount.length > 1
              ? nuskinSubscriptionContainer.handleAlreadySubscribedToast()
              : this.toggleSubscribeModal()
          } else {
            sameProductBlockCount.length > 1
              ? nuskinSubscriptionContainer.handleAlreadyOTPToast()
              : this.subscriptionBtnClick(props)
          }
        }
      } else {
        // CX121-3761 - user does not have any subscriptions currently
        if (!isSubscription) {
          sameProductBlockCount.length > 1
            ? nuskinSubscriptionContainer.handleAlreadySubscribedToast()
            : this.subscriptionBtnClick(props)
        } else {
          sameProductBlockCount.length > 1
            ? nuskinSubscriptionContainer.handleAlreadyOTPToast()
            : this.subscriptionBtnClick(props)
        }
      }
    } else {
      //guest user case
      if (!isSubscription) {
        sameProductBlockCount.length > 1
          ? nuskinSubscriptionContainer.handleAlreadySubscribedToast()
          : isFlexibleSubscriptionEnabled
          ? this.subscriptionBtnClick(props)
          : this.toggleSubscribeModal()
      } else {
        sameProductBlockCount.length > 1
          ? nuskinSubscriptionContainer.handleAlreadyOTPToast()
          : this.subscriptionBtnClick(props)
      }
    }
  }

  quantityDecrementCheck = async (count = 0, itemId = 0) => {
    await nuskinSubscriptionContainer.subscriptionItemDecrementCheck(
      count,
      itemId
    )
  }

  renderSubscriptionModalInViewCart = props => {
    let isUserSubscribedBefore =
      nuskinSubscriptionContainer.isUserSubscribedBefore()
    return (
      <SingleProductSubscriptionModal
        type="cart"
        // don't change this "type" property,
        // it is passed and used in subscriptionquantity and scancard related components
        product={props}
        isSignedUser={customerContainer.isRegisterUser}
        onUserSubscribe={modalData => {
          this.handleUpdateSubscription(modalData)
        }}
        isSubscribedBefore={isUserSubscribedBefore}
        show={this.isToShowViewCartModal}
        handleQuantityChange={props.handleCartQuantityChange}
        decrementCheck={this.quantityDecrementCheck}
        onHide={this.toggleSubscribeModal}
      />
    )
  }

  renderProductDetail = (props = {}) => {
    let {
      productUrl = '',
      imageURL = '',
      productName = '',
      productDescription = '',
      skuId = '',
      productId = '',
      skuQuantity = 0,
      isFromBundle = false,
      transactionPrice = {},
      isSubscription = false,
      isSaveForLater = false,
      isNativeApp = false,
      availableChannels = '',
      bundleProductSlug = '',
      bundleProductVariant = '',
      productStatus = '',
      errorCode = '',
      bundleProducts = {},
    } = props

    const localStore = getLocalStorage('storeProducts') || {}
    const storeProductsInCookie =
      localStore !== null && localStore !== '' ? localStore : {}
    const storeProductState =
      storePickupState.storePickupProducts?.hasOwnProperty(skuId)
    const storeProductCookie =
      storeProductsInCookie.hasOwnProperty(skuId) || false
    const isProdAvailInStore = storeProductState || storeProductCookie
    const isPreOrder =
      this.props?.transactionPrice?.inventoryProperties?.preOrdered || false
    const slugVariant = this?.props?.skuSlug || this?.props?.skuId || ''
    let navigateURL = ''
    const variantLabel = this?.props?.variantLabel || bundleProductVariant || ''
    if (variantLabel) {
      if (isFromBundle) {
        navigateURL = `${productUrl}?variant=${bundleProductSlug}`
      } else {
        navigateURL = `${productUrl}?variant=${slugVariant}`
      }
    } else {
      navigateURL = `${productUrl}`
    }
    if (isNativeApp) {
      // Disabling product link for products in VERA & STELA app
      productUrl = '#'
    }
    const isSubscriptionPreference = customerContainer.isSubscriptionPreference
    const isEnableSubscriptionCheckbox = checkEnableButton(
      availableChannels,
      'subscription'
    )
    // let shoppingContext = getLocalStorage('shoppingContext') || {}
    // let contextText = shoppingContext?.context || ''
    const contextText = checkMysiteOrPersonalOffer()
    const isAvailOnlyInSubscription =
      this.props?.availableChannels == 'subscription'
    const isDiscontinuedProduct =
      productStatus?.toLowerCase() === 'discontinued' ||
      errorCode === 'EOSKUPS002'
    const isToDisableSubscribeCheckbox =
      isAvailOnlyInSubscription || isDiscontinuedProduct
    const isBundle = bundleProducts?.products?.length || 0
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const skuLabel = isFlexibleSubscriptionEnabled
      ? i18nTranslate('viewcart.skuIdLabel', 'SKU')
      : i18nTranslate('cart.itemId', 'SKU #')

    return (
      <Row noGutters>
        <div className="p-0 cart-product-img">
          {contextText == '' ? (
            <Link to={navigateURL}>
              {/* <Card.Img
              data-testid="qa-product-image"
              src={imageURL || imagePlaceholder}
              onError={e => (
                (e.target.onerror = null), (e.target.src = imagePlaceholder)
              )}
              alt={productName}
            /> */}
              <ImageComponent
                testid="qa-product-image"
                src={imageURL}
                alt={productName}
                height={'100px'}
                width={'100px'}
              />
            </Link>
          ) : (
            <ImageComponent
              testid="qa-product-image"
              src={imageURL}
              alt={productName}
              height={'100px'}
              width={'100px'}
            />
          )}
        </div>
        <Col
          lg={8}
          md={8}
          sm={7}
          xs={7}
          className="pl-2 pr-0 view-cart-product-details">
          <Row noGutters>
            {isSubscription &&
              isFlexibleSubscriptionEnabled &&
              !isBundle &&
              this.renderSubscriptionItemLabel()}
            <Col lg={12} className="p-0 view-cart-product-name">
              {contextText == '' ? (
                <Link to={navigateURL} className="text-dark">
                  <Card.Title data-testid="qa-product-name">
                    {productName}
                  </Card.Title>
                </Link>
              ) : (
                <Card.Title data-testid="qa-product-name">
                  {productName}
                </Card.Title>
              )}
            </Col>
            {productDescription && (
              <Col lg={12} className="p-0">
                <Card.Subtitle
                  className="text-truncate cart-product-subtitle"
                  data-testid="qa-product-description">
                  {productDescription}
                </Card.Subtitle>
              </Col>
            )}
            {isPreOrder === true && (
              <Col lg={12}>
                <Badge variant="info" className="checkout-pre-order-badge">
                  {i18nTranslate('checkout.preOrder', 'Preorder')}
                </Badge>
              </Col>
            )}
            <Col lg={12} className="p-0 view-cart-sku-details">
              <Card.Text data-testid="qa-product-number">
                <span className=" checkout-product-num checkout-product-details paragraph-m">
                  {skuLabel}
                  {':'}
                </span>

                {skuId?.length > 15 ? (
                  <OverlayTrigger
                    placement="bottom-start"
                    delay={{ show: 100, hide: 250 }}
                    overlay={
                      <div className="checkout-skuId-tooltip">{skuId}</div>
                    }>
                    <span className="ml-1 checkout-skuId d-inline-block text-truncate w-50">
                      {skuId}
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span className="ml-1 paragraph-m view-cart-sku-id">
                    {skuId}
                  </span>
                )}
              </Card.Text>
            </Col>
            {VARIANT_NODES?.map(skuKey => {
              const skuValue = props[skuKey]
              if (skuValue) {
                return (
                  <Col
                    lg={12}
                    className="p-0 paragraph-l"
                    data-testid={`qa-product-${skuKey}`}>
                    {getLocaleString()[skuKey]}: {skuValue}
                  </Col>
                )
              }
              return <></>
            })}
            {isSubscription &&
              !isFlexibleSubscriptionEnabled &&
              this.renderFrequency()}
            {isFromBundle && (
              <Col lg={12} className="bundle-quantity-wrapper">
                <span className="bundle-quantity-label">
                  {isFlexibleSubscriptionEnabled
                    ? i18nTranslate(
                        'bundle.fixedQuantityTitleV2',
                        'Item Quantity',
                        {
                          nameSpace: 'ssr-resource',
                        }
                      )
                    : i18nTranslate('quantity', 'Quantity')}
                </span>
                <span className="bundle-quantity-colon">:</span>
                <span className="bundle-quantity-value ml-1">
                  {skuQuantity}
                </span>
              </Col>
            )}
            {!isFromBundle && enableStorePickup && (
              <Col
                lg={12}
                className="d-none flex-wrap p-0"
                data-testid="qa-product-fis">
                <span
                  className={`store-name-cart ${
                    isProdAvailInStore ? 'font-weight-bold' : ''
                  }`}>
                  {isProdAvailInStore
                    ? storeProductsInCookie[skuId]
                      ? storeProductsInCookie[skuId]
                      : storePickupState?.storePickupProducts[skuId]
                      ? storePickupState?.storePickupProducts[skuId]
                      : i18nTranslate('bopis.pick', 'Pick It in Store:', {
                          nameSpace: 'ssr-resource',
                        })
                    : i18nTranslate('bopis.pick', 'Pick It in Store:', {
                        nameSpace: 'ssr-resource',
                      })}
                </span>
                <span
                  className="store-pickup-label-cart ml-2"
                  onClick={() =>
                    storePickupState.toggleStorePickupModal(skuId, productId)
                  }>
                  <b>
                    {isProdAvailInStore
                      ? i18nTranslate('bopis.change', 'Change', {
                          nameSpace: 'ssr-resource',
                        })
                      : i18nTranslate('bopis.selectStore', 'Select a Store', {
                          nameSpace: 'ssr-resource',
                        })}
                  </b>
                </span>
              </Col>
            )}
            {isEnableSubscriptionCheckbox &&
              !isFromBundle &&
              !isSaveForLater &&
              this.enableLiveEvent !== 'true' && (
                <>
                  {/* {customerContainer.isSubscriptionAdded ? (
                  <>{i18nTranslate('pdp.subscribed', 'Subscribed')} </>
                ) : ( */}
                  <div
                    className={`view-cart-subscribe ${
                      isToDisableSubscribeCheckbox
                        ? ' disable-sub-checkbox'
                        : ''
                    }`}>
                    <Form.Check
                      disabled={isToDisableSubscribeCheckbox}
                      type="checkbox"
                      className="pl-0 py-2"
                      label={this.checkBoxLabel}
                      id={this.id}
                      checked={isSubscription}
                      onChange={() => {
                        this.handleSubscribeCheck(this.props)
                      }}
                      onKeyPress={() => {
                        this.handleSubscribeCheck(this.props)
                      }}
                    />
                  </div>
                  {/* )} */}
                </>
              )}
            {isSubscription &&
              isFlexibleSubscriptionEnabled &&
              this.renderFrequency(isFlexibleSubscriptionEnabled)}
            <PreOrderTime {...transactionPrice.skuProperties} />
          </Row>
          {!isFromBundle && (
            <div
              className={
                isSaveForLater
                  ? 'justify-content-between'
                  : isFlexibleSubscriptionEnabled
                  ? 'mb-1 cart-product-block'
                  : 'cart-product-block'
              }>
              {!isSaveForLater &&
                this.renderQuantity(isFlexibleSubscriptionEnabled)}
            </div>
          )}
          <div>
            {this.renderPrice(
              this.props,
              isFromBundle,
              isFlexibleSubscriptionEnabled
            )}
          </div>
        </Col>
      </Row>
    )
  }

  renderPromoDetail = (props = {}) => {
    const { promotionDetails = [], freeGiftProductsList = [] } = this.props
    let skus = []
    if (freeGiftProductsList?.length > 0) {
      for (let i = 0; i < freeGiftProductsList?.length; i++) {
        skus.push(freeGiftProductsList?.[i]?.skus?.[0]?.skuId)
      }
    }
    return (
      <>
        {promotionDetails?.length > 0 &&
          promotionDetails?.map((list, index) => {
            const NotOutOfStock = list?.isGift
              ? skus?.includes(list?.skuId)
              : true
            const displayMessage = list?.displayMessage || ''
            const message = list?.message || displayMessage || ''
            return (
              <>
                {NotOutOfStock && (
                  <span
                    className="product-promotion justify-content-start mt-2 mb-2"
                    key={index}>
                    {message}
                  </span>
                )}
              </>
            )
          })}
      </>
    )
  }

  renderPrice = (
    props = {},
    isBundleProduct = false,
    isFlexibleSubscriptionEnabled = false
  ) => {
    const { promotionDetails = [] } = this.props
    const isToShowNewPriceStyling = isFlexibleSubscriptionEnabled || false

    return (
      <Col className="cart-price-wrapper justify-content-md-center justify-content-lg-center p-0 m-md-0 m-lg-0">
        {/* Hide price and promotion section for individual items of Bundle and Kit */}
        {!isBundleProduct && (
          <>
            <ProductPrice
              isToShowNewPriceStyling={isToShowNewPriceStyling}
              {...this.props}
            />
            {promotionDetails?.length > 0 && (
              <div className="checkout-product-promotion-wrapper d-flex flex-column">
                {this.renderPromoDetail(props)}
              </div>
            )}
          </>
        )}
      </Col>
    )
  }

  getPrice = (custompriceValue = 0) => {
    const formattedPrice = currencyFormat({
      price: custompriceValue,
    })
    const formattedCustomPrice = formattedPrice?.replace('$', '')
    return formattedCustomPrice
  }

  renderTotalPrice = (props = {}) => {
    const { isSaveForLater = false, totalPrice = '', grandTotal = '' } = props
    const CVPriceDis =
      this.props?.TotalVal?.priceFacets?.CV?.CVAfterDiscount || 0

    const PVPriceDis =
      this.props?.TotalVal?.priceFacets?.PV?.PVAfterDiscount || 0

    const CVTotalPrice = this.getPrice(CVPriceDis)
    const PVTotalPrice = this.getPrice(PVPriceDis)
    const discountVal = this.props?.TotalVal?.discount || 0
    const isDiscount = discountVal != 0 ? true : false
    const textColor = isDiscount ? 'text-dark font-weight-bold ' : ''

    const priceFreeValue = this.props?.TotalVal?.priceAfterDiscount || 0
    const finalPrice =
      grandTotal == '' && priceFreeValue == 0 ? '$0.00' : grandTotal
    const { isReviewSection = false } = this.props
    const localvalue = getLocalStorage('accountType') || ''
    const reviewSectionClass =
      isReviewSection && isDiscount ? 'font-weight-bold text-danger' : ''
    const userRole = customerContainer?.profileResponse?.userrole || ''

    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    return (
      <>
        {!isSaveForLater && (
          <Col
            data-testid="qa-product-total"
            className={`${textColor} pl-1 p-0 cart-total-price-wrapper`}>
            {totalPrice && (
              <>
                {finalPrice && (
                  <Row noGutters className="mx-0 font-weight-bold">
                    <Col
                      lg={12}
                      className="mt-1 d-flex  p-0  totalValueFlexEnd view-cart-total-value">
                      <p
                        className={`pl-sm-1 pl-xs-1 mb-0 d-sm-block d-xs-block ${
                          this.enableLiveEvent !== 'true' &&
                          isFlexibleSubscriptionEnabled
                            ? 'hide-total-label'
                            : ''
                        }`}>
                        {i18nTranslate('cart.total', 'Total')} :
                      </p>
                      <span
                        className={`${reviewSectionClass} pl-1`}
                        aria-live="polite">
                        {finalPrice != '' ? finalPrice : totalPrice}
                      </span>
                    </Col>
                    {PVTotalPrice && localvalue?.includes('Brand Affiliate') && (
                      <Col
                        lg={12}
                        className="mt-2 d-flex justify-content-end view-cart-sv-value">
                        <p className="pv-cart-values mb-0">
                          {i18nTranslate('cart.itemTotalSV', 'SV')} :
                          <span className="pl-1">{PVTotalPrice}</span>
                        </p>
                      </Col>
                    )}
                    {CVTotalPrice && localvalue?.includes('Brand Affiliate') && (
                      <>
                        {userRole !== 'ROLE_ACCOUNT_BUYER_ADMIN' && (
                          <Col lg={12} className="mt-2 view-cart-cv-value">
                            <p className="pv-cart-values mb-0">
                              {i18nTranslate('cart.itemTotalCV', 'CV')} :
                              <span className="pl-1">{CVTotalPrice}</span>
                            </p>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                )}
              </>
            )}
          </Col>
        )}
      </>
    )
  }

  renderInventoryResponse = () => {
    const bundleResponse = this.props?.bundleProducts?.products || {}
    const items = cartContainer?.cartResponse.items || []
    let item =
      isArray(items) &&
      items?.filter(item => item?.itemId == this.props?.cartItemId)
    let errorCode = item?.[0]?.errorCode || ''
    let inventoryStatusView = ''
    let customFontClass = ''
    let flagInventory = ''

    const errorMessages = {
      EOSKUPS004: i18nTranslate(
        'bundle.replacement',
        'This is a replacement product and not available for purchase',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      EOSKUPS001: i18nTranslate(
        'bundle.preview',
        'Preview product and will be launched soon',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      EOSKUPS003: i18nTranslate(
        'bundle.stopped',
        'This product is temporarily stopped',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      EOSKUPS002: i18nTranslate(
        'bundle.discontinuedV2Message',
        'Discontinued - This product or promotion has been discontinued',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      EOSKUPS000: i18nTranslate(
        'bundle.itemNotAvailable',
        'Item is not available for purchase',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      EOKIT000001: i18nTranslate(
        'product.kitNotAvailable',
        'This kit is no longer available, please select a different kit and try again',
        {
          nameSpace: 'ssr-resource',
        }
      ),
    }

    inventoryStatusView =
      errorMessages[errorCode] || item?.[0]?.errorMessage || ''
    flagInventory = errorCode ? true : false
    if (inventoryStatusView === '') {
      if (bundleResponse?.length >= 1) {
        const inventoryBundle =
          bundleResponse &&
          bundleResponse?.map(
            inventory => inventory?.transactionPrice?.inventoryProperties
          )
        let inventoryStatusViewbundle = []
        let expectedBackOrderDate = ''
        inventoryBundle?.forEach(newitem => {
          if (
            (newitem?.available === false && newitem?.backOrdered === false) ||
            (newitem?.available === false && newitem?.backOrdered === '')
          ) {
            inventoryStatusViewbundle.push(false)
          } else {
            if (newitem?.backOrdered === true) {
              inventoryStatusViewbundle.push(true)
              expectedBackOrderDate =
                newitem?.expectedBackOrderAvailabilityDate || ''
            }
          }
          if (inventoryStatusViewbundle?.includes(false)) {
            flagInventory = true
            inventoryStatusView = 'Out of Stock'
          } else {
            if (inventoryStatusViewbundle?.includes(true)) {
              flagInventory = true
              let backorderTime = ''
              if (expectedBackOrderDate?.toString()?.length <= 10) {
                backorderTime = expectedBackOrderDate + '000'
              } else {
                backorderTime = expectedBackOrderDate
              }
              if (backorderTime) {
                backorderTime = Number(backorderTime)
                /**
                 * @Note
                 * EQH-94 - Back order date shows up incorrectly on EQ
                 * Changed to UTC Format, as per BackEnd Team Elangovan's Request
                 */
                let {
                  date = '',
                  month = '',
                  year = '',
                } = dateFormatDeps.convertTimeStampToDate(backorderTime, true)
                inventoryStatusView =
                  i18nTranslate('backordered', 'Ships by', {
                    nameSpace: 'ssr-resource',
                  }) +
                  ' ' +
                  `${month}/${date}/${year}`

                customFontClass = 'backorder-bold'
              }
            }
          }
        })
      } else {
        const inventoryProperties =
          this.props?.transactionPrice?.inventoryProperties || {}

        let inventoryAvailable = inventoryProperties?.available ?? ''
        let inventoryBackodered = inventoryProperties?.backOrdered ?? ''

        if (
          (inventoryAvailable === false && inventoryBackodered === false) ||
          (inventoryAvailable === false && inventoryBackodered === null)
        ) {
          inventoryStatusView = i18nTranslate(
            'item.notAvailable',
            'Item currently not available'
          )
          flagInventory = true
        } else if (
          (inventoryAvailable === false && inventoryBackodered === true) ||
          (inventoryAvailable === true && inventoryBackodered === true)
        ) {
          flagInventory = true
          let backorderTime = ''
          if (
            inventoryProperties?.expectedBackOrderAvailabilityDate?.toString()
              .length <= 10
          ) {
            backorderTime =
              inventoryProperties?.expectedBackOrderAvailabilityDate + '000'
          } else {
            backorderTime =
              inventoryProperties?.expectedBackOrderAvailabilityDate
          }
          if (
            backorderTime &&
            inventoryProperties?.expectedBackOrderAvailabilityDate !== 0
          ) {
            backorderTime = Number(backorderTime)
            /**
             * @Note
             * EQH-94 - Back order date shows up incorrectly on EQ
             * Changed to UTC Format, as per BackEnd Team Elangovan's Request
             */
            let {
              date = '',
              month = '',
              year = '',
            } = dateFormatDeps.convertTimeStampToDate(backorderTime, true)
            inventoryStatusView =
              i18nTranslate('backordered', 'Ships by', {
                nameSpace: 'ssr-resource',
              }) +
              ' ' +
              `${month}/${date}/${year}`
            customFontClass = 'backorder-bold'
          } else {
            inventoryStatusView = i18nTranslate(
              'orderSearch.backOrdered',
              'BackOrdered'
            )
          }
        } else {
          flagInventory = false
        }
      }
    }
    let fontWeightForStatusView =
      customFontClass === 'backorder-bold'
        ? 'font-weight-bold'
        : 'font-weight-normal'
    return (
      <div>
        <p
          className={`${
            !flagInventory
              ? 'availability-text mt-2 text-success font-weight-bold p-0 mb-0'
              : `availability-text mt-2 text-danger ${fontWeightForStatusView} p-0 mb-0`
          }`}>
          {errorCode === 'EOCA0000012'
            ? alertMessageKeys(
                'Product/SKU got removed from catalog, kindly remove this item to proceed.'
              )
            : alertMessageKeys(inventoryStatusView.trim())}
        </p>
      </div>
    )
  }

  decrementCheck = (count = 0, step = 0) => {
    let quantityDecrement = true
    const cartResponse = cartContainer?.cartResponse?.items || []
    let item = cartResponse?.filter(
      item => item?.itemId === this.props?.cartItemId
    )
    let properties = item[0]?.properties || {}
    let scanArr = []
    try {
      scanArr = properties?.scanCardDetails
        ? JSON.parse(properties?.scanCardDetails)
        : []
    } catch (e) {
      console.log(e)
    }
    // let scanCount = item[0]?.skus[0]?.skuProperties?.scanQualifiedCount || 0
    let scanCount = 0
    item[0]?.skus?.map(data => {
      scanCount =
        parseInt(scanCount) +
        (data?.skuProperties?.scanQualifiedCount
          ? parseInt(data?.skuProperties?.scanQualifiedCount)
          : 0)
    })
    let scanQualifiedCount = (count - step) * scanCount
    if (scanArr?.length > scanQualifiedCount) {
      quantityDecrement = false
      let x = scanArr?.length - scanQualifiedCount
      let plsMsg = i18nTranslate('scan.please', 'Please remove')
      let manualMsg = i18nTranslate(
        'scan.manual',
        ' scancard(s) manually to proceed further'
      )
      let msg = plsMsg + x + manualMsg
      toastState.setToastMessage(msg)
    }

    return quantityDecrement
  }

  renderFrequency = (isFlexibleSubscriptionEnabled = false) => {
    let {
      subscription = {},
      handleSubscriptionChange = function () {},
      cartItemId = 0,
      quantity = 0,
    } = this.props
    const frequencyPeriod = subscription?.frequencyPeriod || ''
    const frequencyType = subscription?.frequencyType || ''

    return (
      <Col lg={12} className="view-cart-frequency-wrapper">
        <SubscriptionDropDown
          frequencyPeriod={frequencyPeriod}
          frequencyType={frequencyType}
          itemId={cartItemId}
          quantity={quantity}
          handleSubscriptionChange={handleSubscriptionChange}
          isToShowNewFrequencyStyling={isFlexibleSubscriptionEnabled}
        />
      </Col>
    )
  }

  renderQuantity = (isFlexibleSubscriptionEnabled = false) => {
    const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99
    const {
      quantity = 0,
      maxCartItemLimit = maxLimit,
      cartItemId = 0,
      handleCartQuantityChange = function () {},
      enableQuantityEdit = true,
    } = this.props
    const productId = this.props?.productId
    const productQualificationResponse =
      customerContainer?.qualificationResponse?.qualification?.filter(p => {
        return p?.productId === productId
      })
    const maxQuantity =
      productQualificationResponse?.[0]?.quantity > maxLimit
        ? maxLimit
        : productQualificationResponse?.[0]?.quantity
    const isToShowNewQuantityField = isFlexibleSubscriptionEnabled || false

    return (
      <Col className="cart-quantity-wrapper view-cart-quantity-wrapper p-0">
        <Row
          noGutters
          className="justify-content-md-left justify-content-lg-left quantity-field-wrapper">
          <div className="quantity-txt">
            {this.enableLiveEvent !== 'true'
              ? i18nTranslate('product.quantity', 'Quantity', {
                  nameSpace: 'ssr-resource',
                })
              : i18nTranslate('product.qty', 'Qty')}
            :
          </div>
          {enableQuantityEdit ? (
            <Quantity
              onQuantityChange={debounce(async (count, cbk) => {
                const response = await handleCartQuantityChange(
                  count,
                  cartItemId
                )
                if (cbk) {
                  cbk(response)
                }
              }, 700)}
              decrementCheck={this.decrementCheck}
              currentPage="cart"
              resetQtyOnFailure={true}
              defaultValue={quantity}
              maxValue={maxQuantity}
              isV2QuantityStyle={isToShowNewQuantityField}
            />
          ) : (
            <>
              {application.isMobile && (
                <span>
                  {i18nTranslate('product.quantity', 'Quantity', {
                    nameSpace: 'ssr-resource',
                  })}
                  :{' '}
                </span>
              )}
              {quantity}
            </>
          )}
        </Row>
      </Col>
    )
  }

  renderButtons = () => {
    const { handleDeleteCartItem = function () {}, productName = '' } =
      this.props
    return (
      <Row noGutters className="cart-product-item-btn-wrapper">
        <Col className="mb-2 px-2 view-cart-delete-icon-wrapper">
          <Button
            className="w-100 cart-remove-button d-flex justify-content-end align-items-end"
            onClick={() => handleDeleteCartItem(this.props)}
            variant="outline-primary"
            data-testid="qa-product-remove">
            <CloseIcon
              className="close-icon mr-1"
              aria-label={`${i18nTranslate(
                'cart.remove',
                'Remove'
              )} ${productName}`}
            />
          </Button>
        </Col>
      </Row>
    )
  }

  renderBundleProducts = (product = {}) => {
    return (
      <Row noGutters className="border-top border-secondary py-3">
        <Col lg={6} md={6} sm={12} xs={12}>
          {this.renderProductDetail({ ...product, isFromBundle: true })}
        </Col>
        {this.renderPrice(product, true)}
        {/* CX121-4017 - BA action item, Storefront UI:enable the scan card option at KIT/Bundle header instead of component level.
        <Col className="col-12 bundleScanCardBtn">
          {this.renderScanCardBtn(product, true)}
        </Col> */}
        {this.renderTotalPrice(product)}
      </Row>
    )
  }

  renderItemLevelAddress = () => {
    const { address = {} } = this.props
    if (
      address &&
      typeof address === 'object' &&
      Object.keys(address)?.length > 0
    ) {
      return (
        <Row className="d-flex flex-column p-0 text-capitalize w-100">
          <h4>
            {i18nTranslate('checkout.shippingAddress', 'Shipping Address')}
          </h4>
          <SingleLineAddress address={address} />
        </Row>
      )
    }
  }

  renderScanCardBtn = (product = {}, isBundle = false) => {
    let productDetails = isBundle ? product : this.props
    let isSubscription = isBundle
      ? product?.transactionPrice?.skuProperties?.issubscription
      : this.props?.isSubscription
    let scanQualifiedCount = 0

    if (isBundle) {
      isSubscription = productDetails?.[0]?.isSubscription
      productDetails?.[0]?.skus?.forEach(newitem => {
        const scanCount = parseInt(
          newitem?.skuProperties?.scanQualifiedCount || 0
        )
        scanQualifiedCount = scanCount + scanQualifiedCount
      })
    } else {
      scanQualifiedCount =
        this.props?.transactionPrice?.skuProperties?.scanQualifiedCount || 0
    }
    let propsToPass = isBundle
      ? { ...productDetails, ...this.props }
      : this.props
    return (
      isSubscription &&
      scanQualifiedCount > 0 && (
        <CartScanCardWithInfo
          page="cart"
          isPopupForMovingCartProductsToDashboard={false}
          bundleInfo={{ isBundle, bundleScanCount: scanQualifiedCount }}
          {...propsToPass}
        />
      )
    )
  }

  renderSubscriptionItemLabel = () => {
    return (
      <div className="subscription-item-label">
        <span className="subscription-icon">
          <ViewCartRefreshIcon
            className="view-cart-refresh-icon"
            width="9"
            height="9"
            fillColor="#252525"
          />
        </span>
        <span className="subscription-item-label-text">
          {i18nTranslate('viewcart.subscriptionItemLabel', 'Subscription item')}
        </span>
      </div>
    )
  }

  render() {
    const {
      isCheckout = false,
      isReviewSection = false,
      isSaveForLater = false,
      bundleProducts = {},
      className = '',
      isMultipleAdressActive = false,
      enableButtons = true,
      isSubscription = false,
      handleSaveForLater = function () {},
    } = this.props
    const isBundle = bundleProducts?.products?.length || 0
    const isToShowButtons = isReviewSection ? false : enableButtons

    const items = cartContainer?.cartResponse.items || []
    let item =
      isArray(items) &&
      items?.filter(item => item?.itemId == this.props?.cartItemId)
    let errorCode = item?.[0]?.errorCode || ''
    const dynamicClassName = isCheckout
      ? 'checkout-container'
      : 'cart-container'
    const isError =
      isCheckout && errorCode === 'EOCA0000012'
        ? 'checkout-container-error'
        : dynamicClassName
    const addOnCount = bundleProducts?.optionalCount || ''
    const userRole = customerContainer?.profileResponse.userrole || ''
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false

    return (
      <>
        <Card
          className={`cart-product-wrapper view-cart-item-wrapper p-2  ${isError} ${className} w-100`}
          data-testid="qa-cart-product">
          {isSubscription &&
            isFlexibleSubscriptionEnabled &&
            isBundle > 0 &&
            this.renderSubscriptionItemLabel()}
          <Row
            noGutters
            className="align-items-center pl-3 py-1 view-cart-header-section">
            {isReviewSection === true &&
              isMultipleAdressActive &&
              this.renderItemLevelAddress()}
            {isBundle ? (
              <>
                <span className="px-2" data-testid="qa-count">
                  {bundleProducts?.optionalCount || ''}
                  {i18nTranslate('cart.adonitem', 'add-on')}
                  &nbsp;{bundleProducts?.mandatoryCount || ''}
                  {i18nTranslate('cart.cartItems', 'items')},
                </span>
                {addOnCount > 0 && (
                  <span className="px-2" data-testid="qa-count">
                    {bundleProducts?.optionalCount || ''}
                    {i18nTranslate('cart.adonitem', 'add-on')}
                  </span>
                )}
                <AccordionBlock
                  className="mr-auto py-0 mt-0 subproducts-collapse"
                  textClass="text-primary"
                  title={i18nTranslate(
                    'checkout.productItem.viewItems',
                    'view items'
                  )}
                  dataTestId="qa-cart-view"
                  isOutsideAccordion={true}>
                  {bundleProducts?.products?.map(this.renderBundleProducts)}
                </AccordionBlock>
              </>
            ) : (
              <React.Fragment />
            )}
            {!isFlexibleSubscriptionEnabled && isToShowButtons === true && (
              <Col className="pb-2 my-0 cart-remove-button-wrapper">
                {this.renderButtons()}
              </Col>
            )}
          </Row>
          {isFlexibleSubscriptionEnabled &&
            isToShowButtons === true &&
            this.renderButtons()}
          <Row noGutters className="cart-product-details">
            <div
              className={
                isSaveForLater
                  ? 'justify-content-between w-100'
                  : 'cart-product-block'
              }>
              <Col
                className="p-0 cart-card-info"
                data-testid="qa-products-container">
                {this.renderProductDetail({ errorCode, ...this.props })}
                <p
                  className="availability-text ml-2 text-success font-weight-bold"
                  data-testid="qa-product-availability">
                  {this.renderInventoryResponse()}
                </p>
                {this.renderScanCardBtn(item, isBundle)}
                <div
                  className={`mt-2 align-items-end justify-content-center view-cart-total-section ${
                    !application.isMobile && !isFlexibleSubscriptionEnabled
                      ? 'd-flex'
                      : ''
                  } `}>
                  {!isBundle &&
                    userRole?.length > 0 &&
                    this.enableLiveEvent !== 'true' && (
                      <Button
                        className="save-for-later-button mr-auto py-0 px-2 d-flex justify-content-center align-items-center cart-product-button"
                        onClick={() => handleSaveForLater(this.props)}
                        variant="outline-primary"
                        data-testid={
                          isSaveForLater
                            ? 'qa-product-movetocart'
                            : 'qa-saveforlater'
                        }>
                        {!isSaveForLater ? (
                          i18nTranslate('cart.saveforlater', 'Save For Later')
                        ) : (
                          <>
                            <ShoppingCartIcon
                              className="close-icon mr-1"
                              aria-label={i18nTranslate(
                                'icons.moveToCart',
                                'move to cart icon',
                                {
                                  nameSpace: 'ssr-resource',
                                }
                              )}
                            />
                            {i18nTranslate('cart.movetocart', 'Move To Cart')}
                          </>
                        )}
                      </Button>
                    )}
                  {this.renderTotalPrice(this.props)}
                </div>
              </Col>
            </div>
          </Row>
        </Card>
        {this.isToShowViewCartModal &&
          this.renderSubscriptionModalInViewCart(this.props)}
      </>
    )
  }
}

export { CheckoutProductBlock }
export default CheckoutProductBlock
