import { i18nTranslate } from 'src/utils'

function getFooterLinks() {
  return [
    {
      label:
        i18nTranslate('menu.aboutUs', 'About Us', {
          nameSpace: 'ssr-resource',
        }) || 'About Us',
      link: 'https://www.nuskin.com/content/corpcom/en_US/company/corporate_press_kit.html',
      external: true,
    },
    {
      label:
        i18nTranslate('menu.mobileCommerce', 'Mobile Commerce', {
          nameSpace: 'ssr-resource',
        }) || 'Mobile Commerce',
      link: '/marketing',
      external: false,
    },
    {
      label:
        i18nTranslate('menu.termsAndConditions', 'Terms & Conditions', {
          nameSpace: 'ssr-resource',
        }) || 'Terms & Conditions',
      link: 'https://www.nuskin.com/content/dam/mecommerce/terms-and-conditions/americas/US/Terms-of-Use_en.html',
      external: true,
    },
    {
      label:
        i18nTranslate('menu.ContactUs', 'Contact Us', {
          nameSpace: 'ssr-resource',
        }) || 'Contact Us',
      link: 'https://nuskin.knowledgeowl.com/help',
      external: true,
    },
  ]
}

export { getFooterLinks }
