import React from 'react'
import { AiOutlineShoppingCart as ShoppingCartIcon } from 'react-icons/ai'
import { observer } from 'mobx-react'
import { Badge, Button } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { getSeparateCartAndCheckout, getShowMiniCart } from 'config/appConfig'
import { application, isB2BAccount, isB2B2C, checkIsMobile } from 'src/utils'
import { clearUserCartCache } from 'src/views/components/CartBlock/deps'
import { pageNames } from 'src/routes/pathParams'
import { Cart } from 'src/views/components'
import { cartContainer, checkoutContainer } from 'src/models'
import NuskinCartIcon from './NuskinCartIcon'
import './styles.scss'

@observer
/*
 * @note
 * changes made as per given PR: EQPLAT - 8517 and 9057
 */
class CartIcon extends React.Component {
  handleCartClick = async () => {
    const { pathname } = this.props.location
    const isCheckoutPage = pathname === pageNames.checkout || false
    if (pathname === pageNames.viewCart || isCheckoutPage) {
      if (isCheckoutPage && getSeparateCartAndCheckout()) {
        if (cartContainer?.cartResponse?.loyaltyDetails) {
          await checkoutContainer.deleteDiscountReward()
        }
        this.props.history.push(`${pageNames.viewCart}`)
      } else {
        event.preventDefault()
      }
    } else if (
      application.isTabletOrLarger &&
      !checkIsMobile() &&
      (getShowMiniCart() === true || isB2B2C())
    ) {
      if (cartContainer?.cartResponse?.loyaltyDetails) {
        await checkoutContainer.deleteDiscountReward()
        cartContainer.toggleCartVisibility()
      } else {
        //await cartContainer.viewCart()
        /**
         * @info Commented above and Called below function for delete payment if exists
         * and make user cart call as a common
         */
        clearUserCartCache(true)
        cartContainer.toggleCartVisibility()
      }
    } else {
      if (getSeparateCartAndCheckout() === true) {
        if (cartContainer?.cartResponse?.loyaltyDetails) {
          await checkoutContainer.deleteDiscountReward()
        }
        //await cartContainer.viewCart()
        /**
         * @info Commented above and Called below function for delete payment if exists
         * and make user cart call as a common
         */
        clearUserCartCache(true)
        this.props.history.push(`${pageNames.viewCart}`)
      } else {
        this.props.history.push(`${pageNames.checkout}`)
      }
    }
  }

  render() {
    const { cartCount = 0 } = cartContainer
    const { pathname = '' } = this.props.location
    const { isNuskinHeader = false } = this.props

    const isCheckout = pathname === pageNames.checkout || false
    const className = `${
      !isNuskinHeader ? 'cart-count-wrapper' : ''
    } d-flex p-0 bg-transparent nu-cart-button align-items-center ${
      isCheckout ? 'checkout' : ''
    }`
    return (
      <>
        <Button
          variant=""
          className={className}
          aria-label={'cart items added' + `${cartCount}`}
          onClick={this.handleCartClick}
          data-testid="qa-cart">
          <div className="cart-cont pr-2">
            {/* <ShoppingCartIcon
              // className="header-cart-icon"
              style={{
                fontSize: isNuskinHeader ? '24px' : '20px',
                verticalAlign: 'top',
              }}
              aria-label={i18nTranslate('icons.cart', 'cart icon')}
            /> */}
            {isNuskinHeader ? (
              <NuskinCartIcon />
            ) : (
              <ShoppingCartIcon
                // className="header-cart-icon"
                style={{
                  fontSize: '20px',
                  verticalAlign: 'top',
                }}
                aria-label={i18nTranslate('icons.cart', 'cart icon', {
                  nameSpace: 'ssr-resource',
                })}
              />
            )}
            {isNuskinHeader && (
              <Badge className="badge-count" pill variant="danger">
                {cartCount > 99 ? '99+' : cartCount}
              </Badge>
            )}
            {!isNuskinHeader && (
              <div className="cart-count pl-1">
                {cartCount > 99 ? '99+' : cartCount}
              </div>
            )}
          </div>
          {!isNuskinHeader && (
            <span className="pl-1 cart-text">
              {i18nTranslate('checkout.cartTitle', 'Cart')}
            </span>
          )}
        </Button>
        {application.isTabletOrLarger && (!isB2BAccount() || isB2B2C()) && (
          <Cart {...this.props} />
        )}
      </>
    )
  }
}

export { CartIcon }
