import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { accountsContainer } from 'src/models'

const SponsorIdField = props => {
  const { touched, values } = props

  /**
   * @todo
   * these has been implemented to avoid too many Network calls while entering sponsor ID
   * Once we got perfect regex pattern we can eliminate this code and we can enable validations from validationschemadeps.js which is already predefined
   */

  const {
    sponsorerName,
    sponsorTouchedStatus,
    isSponsorPrefilled = false,
  } = props || {}
  let sponsorValidField = ''
  const accType = values?.accountType
  if (sponsorerName === 'error') {
    sponsorValidField = false
  } else if (
    (accType === 'Retail Customer' && values?.sponsorId === '') ||
    (accType === 'Retail Customer' && accountsContainer?.sponsorValidationFalse)
  ) {
    sponsorValidField = true
  } else if (
    accType === 'Brand Affiliate' &&
    values?.sponsorId === '' &&
    accountsContainer?.onSubmitInValid
  ) {
    sponsorValidField = false
  } else if (
    touched.sponsorId &&
    sponsorTouchedStatus &&
    !accountsContainer?.onSubmitInValid &&
    values?.sponsorId !== ''
  ) {
    sponsorerName === false || sponsorerName == ' ' || sponsorerName == ''
      ? (sponsorValidField = false)
      : (sponsorValidField = true)
  } else if (
    touched.sponsorId &&
    sponsorTouchedStatus &&
    accountsContainer?.onSubmitInValid &&
    values?.sponsorId !== ''
  ) {
    sponsorerName === false || sponsorerName == ' ' || sponsorerName == ''
      ? (sponsorValidField = false)
      : (sponsorValidField = true)
  } else if (
    isSponsorPrefilled &&
    sponsorTouchedStatus &&
    values?.sponsorId !== ''
  ) {
    sponsorValidField =
      sponsorerName === false || sponsorerName == ' ' || sponsorerName == ''
        ? false
        : true
  } else {
    sponsorValidField = ''
  }

  const sponsorClassName =
    sponsorValidField == true
      ? 'is-valid'
      : sponsorValidField === ''
      ? ''
      : 'is-invalid'

  const errorSponsorName = isSponsorPrefilled
    ? i18nTranslate(
        'invite.sponsorNameErrorMsg',
        'Oops! There is an issue with the shared link. Please try again. If the problem persists, kindly contact the person who shared the link with you.'
      )
    : values.accountType == 'Brand Affiliate'
    ? i18nTranslate(
        'validation.sponsorIdErrorText',
        'Please enter a valid Sponsor Id'
      )
    : i18nTranslate(
        'validation.sponsorIdRetailErrorText',
        'Sponsor Account entered is incorrect, please enter a valid Sponsor Account. If you do not have a Sponsor id, please skip this field and proceed.'
      )
  const sponsorKeyUp = event => {
    event.target.value = event.target.value?.toUpperCase() || ''
  }
  return (
    <>
      <InputGroup className={sponsorClassName}>
        <Form.Control {...props} onKeyUp={sponsorKeyUp} />
      </InputGroup>
      <div className="invalid-feedback">
        {sponsorValidField == false &&
          (touched?.sponsorId || isSponsorPrefilled) &&
          errorSponsorName}
      </div>
      {sponsorValidField && (
        <ul className="valid-sponsor">
          <li>
            {sponsorerName == ' ' || sponsorerName == '' ? '' : sponsorerName}
          </li>
        </ul>
      )}
    </>
  )
}

export { SponsorIdField }
