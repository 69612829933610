import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Col, Row } from 'react-bootstrap'
import isEmpty from 'lodash/isEmpty'
import { tryJSONParse } from 'src/utils'
import { AccordionBlock } from 'src/views/components'
import { ProductVideo } from '../ProductImageList/ProductVideo'
import { MarkdownComponent } from 'src/views/components/MarkdownComponent'
import { APPConfig } from 'config/appConfig'
import './styles.scss'

@observer
class ProductDetailSection extends React.Component {
  renderTitle = () => {
    const { isAccordionType = false } = this.props
    return (
      <Row
        className={`about-title ${
          isAccordionType
            ? 'text-uppercase'
            : 'border-top border-light-grey py-2'
        }`}
        noGutters>
        {i18nTranslate('pdp.aboutText', 'About', {
          nameSpace: 'ssr-resource',
        })}
      </Row>
    )
  }
  LinkRenderer = link => {
    return (
      <a href={link.href} target="_blank" rel="noreferrer">
        {link.children}
      </a>
    )
  }
  renderSalesDisclaimer = Data => {
    return (
      <div className="salesDisclaimer">
        <MarkdownComponent>
          {({ default: Markdown }) => (
            <Markdown components={{ a: this.LinkRenderer }}>
              {Data || ''}
            </Markdown>
          )}
        </MarkdownComponent>
      </div>
    )
  }
  renderDescription = Data => {
    const parsedDescription = tryJSONParse(Data)
    const isArray = Array.isArray(parsedDescription)
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    if (isArray) {
      return (
        <ul>
          {parsedDescription.map((desc, index) => {
            if (typeof desc === 'object') {
              return <li key={`desc-${index}`}>{desc?.description}</li>
            } else if (desc) {
              return <li key={`desc-${index}`}>{desc}</li>
            }
            return <></>
          })}
        </ul>
      )
    }
    return (
      <Row
        className={`${
          enableNewPDPStyle
            ? 'v2-about-product'
            : 'pb-3 paragraph-s about-product text-dark '
        }`}
        noGutters
        dangerouslySetInnerHTML={{
          __html: Data,
        }}
        data-testid="qa-pdp-product-description"
      />
    )
  }

  render() {
    const { description = [], isAccordionType = false, video = '' } = this.props
    const product = this.props || {}
    const nettoWeight = product.sku[0]?.properties?.nettoWeight
    const salesDisclaimer =
      product.sku[0]?.properties?.salesDisclaimer ||
      product?.properties?.salesDisclaimer ||
      []
    return (
      <>
        {!isEmpty(description) &&
          (isAccordionType ? (
            <AccordionBlock
              title={this.renderTitle()}
              isDefaultOpen={true}
              className="accordion-description border-bottom">
              {this.renderDescription(description)}
            </AccordionBlock>
          ) : (
            <Col className=" p-0 pt-3 product-detail-wrapper">
              {this.renderDescription(description)}
              {salesDisclaimer && this.renderSalesDisclaimer(salesDisclaimer)}
              {product?.sku?.[0] && nettoWeight && (
                <p className="netLabel">Netto Weight : {nettoWeight}</p>
              )}
            </Col>
          ))}
        {!isEmpty(video) && (
          <Col className="mt-4 p-0 pt-3 product-detail-wrapper">
            <Row className={`about-title paragraph-s`} noGutters>
              <h3>
                {i18nTranslate('pdp.video', 'Video', {
                  nameSpace: 'ssr-resource',
                })}
              </h3>
            </Row>
            <ProductVideo
              source={video}
              className="px-3 mw-100 mh-100"
              controls={true}
            />
          </Col>
        )}
      </>
    )
  }
}

export { ProductDetailSection }
