import React from 'react'
import { observer } from 'mobx-react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
// import { subscriptionContainer } from 'src/models'
import { i18nTranslate } from 'src/utils'

@observer
class SubscriptionTabs extends React.Component {
  handleSelect = event => {
    this.props?.handleTabChange(event)
  }
  render() {
    const { ScheduleTab, nextOrderTab } = this.props
    return (
      <Tabs
        activeKey={this.props?.activeTab || 'schedule'}
        onSelect={e => this.handleSelect(e)}
        id="uncontrolled-tab-example"
        unmountOnExit={true}
        className="subscription-tabs-wrapper subs-tabs-wrapper"
        fill>
        <Tab
          eventKey="schedule"
          data-testid="subscription_schedule"
          title={i18nTranslate(
            'mysubscriptions.subscriptionSchedule',
            'Subscription Schedule'
          )}>
          {ScheduleTab()}
        </Tab>
        <Tab
          eventKey="nextOrder"
          data-testid="next_Order"
          title={i18nTranslate('mysubscriptions.nextOrders', 'Next Orders')}>
          {nextOrderTab()}
        </Tab>
      </Tabs>
    )
  }
}

export { SubscriptionTabs }
export default SubscriptionTabs
