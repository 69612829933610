import React from 'react'
import { i18nTranslate } from 'src/utils'
import isFunction from 'lodash/isFunction'
import { productDeps } from 'src/deps'
import { getLocaleString } from 'src/utils'
import { ProductSkuOptionDropDown, V2ProductOption } from 'src/views/components'
import { APPConfig } from 'config/appConfig'
// import { catalogContainer } from 'src/models'

class ProductSkuSelection extends React.Component {
  handleSkuSelection = async selectedProp => {
    event.preventDefault()
    const skuSelected = this.props?.product?.skuSelected

    const { handleSkuSelection } = this.props
    skuSelected[selectedProp.type] = selectedProp.value
    const product = productDeps.changeSku({
      product: this.props?.product,
      selectedProp,
    })

    isFunction(handleSkuSelection) &&
      handleSkuSelection({
        ...product,
        productCount: this.props.productCount || 1,
      })
    this.props?.onSubscriptionSkuChange && this.props?.onSubscriptionSkuChange()
  }

  showErrorForSkuSelection = type => {
    const skuSelected = this.props?.product?.skuSelected || {}
    const ErrorText = i18nTranslate('product.selectSkuError', 'Select the', {
      nameSpace: 'ssr-resource',
    })
    const errorView =
      type && skuSelected[type] === '' ? (
        <div className="font-weight-bold text-danger">
          {ErrorText} {getLocaleString()[type]}
        </div>
      ) : (
        <React.Fragment />
      )
    return errorView
  }

  render() {
    const { product } = this.props
    const productOption = product?.productOption || []
    const skuSelected = product?.skuSelected
    const skuList = product?.skuList || []
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    const isColorVariant = skuList?.[0]?.properties?.variantColor || ''
    return (
      productOption.length > 0 && (
        <>
          {enableNewPDPStyle && !isColorVariant ? (
            <V2ProductOption
              options={productOption}
              handleSkuSelection={this.handleSkuSelection}
              skuSelected={skuSelected}
              skuList={skuList}
              history={this.props.history || {}}
            />
          ) : (
            <ProductSkuOptionDropDown
              options={productOption}
              handleSkuSelection={this.handleSkuSelection}
              skuSelected={skuSelected}
              skuList={skuList}
              product={product}
              history={this.props.history || {}}
            />
          )}
        </>
      )
    )
  }
}

export { ProductSkuSelection }
export default ProductSkuSelection
