import React from 'react'
import { Button, Container, Modal } from 'react-bootstrap'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { GrClose } from 'react-icons/gr'
import {
  SubscriptionProductItem,
  SubscriptionProductInfo,
} from '../SubscriptionProductItem'
import {
  SingleProductItemState as SingleProductSubscriptionModalState,
  cartContainer,
} from 'src/models'
import { trackAddToSubscription } from 'src/utils'
import { i18nTranslate } from 'src/utils'
import { quickViewState } from 'src/views/components/QuickView/state'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import './styles.scss'

@observer
class SingleProductSubscriptionModal extends React.Component {
  @observable shouldDisableTheButton = false
  singleProductItemState = null
  pageType = ''

  constructor(props) {
    super(props)

    this.singleProductItemState = new SingleProductSubscriptionModalState()
  }

  componentDidMount() {
    // Set product count from cart to sub modal product count
    this.pageType = this.props.type || ''
    const productType = this.props.product?.type || ''
    const isBundle =
      productType.toLowerCase() === 'kit' ||
      productType.toLowerCase() === 'bundle'
    this.singleProductItemState.productQuantity =
      this.props?.product?.quantity ||
      this.singleProductItemState?.productQuantity

    // Set initial frequency from tile on dash to modal
    if (this.props?.isSubscriptionPage) {
      this.singleProductItemState.productFrequency =
        this.props?.product?.subscription?.frequencyPeriod || 1
    }

    const product = this.props?.product || {}
    let productDetails = {}
    try {
      productDetails = JSON.parse(JSON.stringify(product)) // taking deep copy of that product json, so that existing wont affect
    } catch (e) {
      console.log('error in JSON file', e)
    }

    productDetails.productId =
      productDetails.productId || productDetails.identifier
    productDetails.isSubscriptionIncludedInPromotion = true
    productDetails.isSubscriptionPromotion = true

    productDetails.quantity = this.singleProductItemState?.productQuantity || 1
    isBundle
      ? productState.makePromotionCallsForBundleKits(
          productDetails,
          this.singleProductItemState?.productQuantity
        )
      : this.makePromotionCallsForNormalProduct(productDetails)
    // }
  }
  componentWillUnmount() {
    if (this.pageType === 'cart') {
      productState.bundlePromotionResponse = {}
    }
    productState.singleQtyNormalProductResponse = {}
    productState.normalPdt_unitValueForSubscriptionPopup = {}
    productState.normalPdt_totalValueForSubscriptionPopup = {}
    quickViewState.normalPdt_unitValueForSubscriptionPopup = {}
    quickViewState.normalPdt_totalValueForSubscriptionPopup = {}
    productState.bundle_unitValueForSubscriptionPopup = {}
    productState.bundle_totalValueForSubscriptionPopup = {}
  }

  makePromotionCallsForNormalProduct = async productDetails => {
    const response = await cartContainer.makePromotionCallsForNormalProduct(
      productDetails
    )
    // for normalproduct
    // only init scenario,
    // qty change is in diff file
    // @set_subscription_pop_up_price
    productState.unitValueWithSubscriptionTrueDynamicBasedOnQty =
      response?.unitValue || {}
    if (this.props?.isFromQuickView) {
      quickViewState.normalPdt_unitValueForSubscriptionPopup =
        response?.unitValue || {}
      quickViewState.normalPdt_totalValueForSubscriptionPopup =
        response?.totalValue || {}
    } else {
      productState.normalPdt_unitValueForSubscriptionPopup =
        response?.unitValue || {}
      productState.normalPdt_totalValueForSubscriptionPopup =
        response?.totalValue || {}
    }
  }

  updateTheUserConsentInPostBody = (userInput = {}) => {
    this.shouldDisableTheButton = this.singleProductItemState.isOneTimeFrequency
      ? !userInput.first
      : !(userInput.first && userInput.second)
  }

  // For converting one-time subscription back to monthly subscription
  // This is a patch call which updates frequency, no new subscription is made here
  convertToSubscribe = async props => {
    let response = await this.props?.onUserSubscribe(
      this.singleProductItemState?.productQuantity,
      this.props?.product?.identifier,
      {
        subscription: {
          frequencyPeriod:
            this.singleProductItemState?.productFrequency?.split('-')[0],
          frequencyType: 'MONTHS',
        },
      },
      '',
      true
    )
    this.props?.toggleSingleSubscribeProductModal()
  }

  onBeforeSubscribe = props => {
    const modalData = {
      ...props,
      quantity: this.singleProductItemState.productQuantity,
      frequency: this.singleProductItemState.productFrequency?.split('-')[0],
    }

    modalData.frequencyOrder =
      this.singleProductItemState.frequencyOrder || 'VARIABLE_MULTI_ORDER'
    modalData.isOneTimeFrequency =
      this.singleProductItemState.isOneTimeFrequency || false

    trackAddToSubscription(modalData)
    this.singleProductItemState.resetFrequency()
    props.onUserSubscribe(modalData)
    if (quickViewState.isQuickViewOpen) {
      quickViewState.toggleQuickView()
    }
  }

  singleSubscribeButton = props => {
    const { shouldDisable = '', label = '', remainingProps = {} } = props || {}
    return (
      <Button
        disabled={shouldDisable || false}
        onClick={() => {
          this.props.isSubscriptionPage
            ? this.convertToSubscribe()
            : this.onBeforeSubscribe(remainingProps)
        }}
        variant="primary btn-addToCart single-subs-modal-btn p-2">
        {label ||
          i18nTranslate('pdp.subscribe', 'Subscribe', {
            nameSpace: 'ssr-resource',
          })}
      </Button>
    )
  }

  render() {
    const product = this.props?.product || {}
    const itemType = this.props.productType || ''
    const isSignedUser = this.props.isSignedUser || false
    const isSubscribedBefore = this.props.isSubscribedBefore || false
    const buttonName =
      isSignedUser && isSubscribedBefore
        ? this.singleProductItemState.isOneTimeFrequency
          ? i18nTranslate(
              'subscriptionModal.addToNextShipment',
              'Add to Next Shipment'
            )
          : i18nTranslate('pdp.subscribe', 'Subscribe', {
              nameSpace: 'ssr-resource',
            })
        : i18nTranslate(
            'pdp.addSubscriptionToCart',
            'Add Subscription to Cart',
            {
              nameSpace: 'ssr-resource',
            }
          )
    const isBundleOrKit =
      product?.type?.toLowerCase() === 'kit' ||
      product?.type?.toLowerCase() === 'bundle'
    const type = this.props?.type

    return (
      <Modal show={this.props.show} onHide={() => this.props.onHide()} centered>
        <Modal.Body className="ssm-modal-body">
          <Container>
            <div className="ssm-title-text heading-4">
              {isSubscribedBefore &&
                i18nTranslate(
                  'subscriptionModal.ssmTitle',
                  'Add Item to Existing Subscription'
                )}
            </div>
            <div className="text-right ssm-close-icon" tabIndex={0}>
              <GrClose type="button" onClick={() => this.props.onHide()} />
            </div>
            <SubscriptionProductItem
              product={product}
              itemType={itemType}
              enableShowScanCard={false}
              singleProductItemState={this.singleProductItemState}
              enablePDPNavigation={true}
              handleQuantityChangePromotion={
                /* @##pdp_promotion_call */
                this.props?.isFromQuickView === true
                  ? quickViewState.makePromotionCallsForNormalProductQuickViewQtyChange
                  : isBundleOrKit
                  ? productState?.makePromotionCallsForBundleKits
                  : productState?.handleQuantityChangePromotion
              }
              {...this.props}
            />
            {isSignedUser && isSubscribedBefore && (
              <SubscriptionProductInfo
                isOneTimeFrequency={
                  this.singleProductItemState.isOneTimeFrequency
                }
                onCheckboxStatusChange={this.updateTheUserConsentInPostBody}
              />
            )}
            <div className="d-flex justify-content-center mt-4">
              {this.singleSubscribeButton({
                label: buttonName,
                shouldDisable: this.shouldDisableTheButton,
                remainingProps: this.props,
              })}
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    )
  }
}

export { SingleProductSubscriptionModal }
export default SingleProductSubscriptionModal
