import { CommonContainer } from 'src/models/Common'
import { observable } from 'mobx'
import { APPConfig } from 'config/appConfig'
import { IS_BROWSER } from 'src/utils'

/**
 * KlarnaDepsContainer class extends CommonContainer.
 * It handles loading the Klarna script and
 * tracking if it has loaded.
 */
class KlarnaDepsContainer extends CommonContainer {
  @observable isKlarnaScriptLoaded = false
  /**
   * Checks if the Klarna script is already loaded, and if not, loads it by
   * injecting a script tag. Provides a callback when loaded.
   */
  checkAndLoadKlarnaScript = callback => {
    const enableKlarnaPayment =
      APPConfig.getAppConfig?.()?.enableKlarnaPayment == 'true'
    if (
      enableKlarnaPayment &&
      IS_BROWSER &&
      !this.isKlarnaScriptLoaded &&
      !document.getElementById('klarna-script-element')
    ) {
      const klarnaScriptURL = APPConfig.getAppConfig?.()?.klarnaScriptURL
      const klarnaClientId = APPConfig.getAppConfig?.()?.klarnaClientId
      const klarnaEnvironment = APPConfig.getAppConfig?.()?.klarnaEnvironment
      const script = document.createElement('script')
      script.id = 'klarna-script-element'
      script.src = klarnaScriptURL
      script.async = true
      script.setAttribute('data-environment', klarnaEnvironment)
      script.setAttribute('data-client-id', klarnaClientId)
      document.body.appendChild(script)
      if (callback) {
        script.onload = () => {
          this.isKlarnaScriptLoaded = true
          window?.Klarna?.OnsiteMessaging?.refresh()
          if (callback) {
            callback()
          }
        }
      }
    } else if (
      enableKlarnaPayment &&
      document.getElementById('klarna-script-element')
    ) {
      this.isKlarnaScriptLoaded = true
    }
  }
}
const klarnaDepsContainer = new KlarnaDepsContainer()

export { klarnaDepsContainer, KlarnaDepsContainer }
export default klarnaDepsContainer
