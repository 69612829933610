import React from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { CheckoutUserForm } from './CheckoutUserForm'
import { cartContainer, customerContainer } from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import { overlayState } from 'src/views/components'
import './styles.scss'
import { signInwithRedirect } from 'src/utils/signInUtils'
import {
  i18nTranslate,
  setLocalStorage,
  trackGTMEvents,
  convertToBoolean,
  jitsuSignUpRedirect,
  getLiveEventStatus,
} from 'src/utils'
import { APPConfig } from 'config/appConfig'
import { toastState } from '../../Toast'

class GuestCheckout extends React.Component {
  handleDataSubmit = async data => {
    overlayState.toggleLoader()
    const response = await cartContainer?.updateGuestCustomer(data)
    if (cartContainer?.isSuccessResponse(response)) {
      cartContainer.cartPrefilledData = data
      await customerContainer.getProfile()
      overlayState.toggleLoader()
      this.props?.checkoutUrl?.history?.push(pageNames?.checkout)
      return
    }
    overlayState.toggleLoader()
  }

  signupcheck = () => {
    trackGTMEvents({ event: 'Signup', eventType: 'simpCustColdFormStart' })
    setLocalStorage('mysiteCheckout', 'mysiteCheckout')
    if (convertToBoolean(APPConfig?.getAppConfig()?.enableJITSU)) {
      jitsuSignUpRedirect({ isFromGuestCheckout: true })
    }
  }

  render() {
    const enableJITSU = convertToBoolean(APPConfig?.getAppConfig()?.enableJITSU)
    const checkout = 'mysiteCheckout'
    const enableLiveEvent = getLiveEventStatus()
    return (
      <Container className="py-2 checkout-signin-container">
        <Row noGutters>
          <Col
            sm={12}
            xs={12}
            lg={6}
            className="signin-checkout-container pl-4 pr-4 signin-container">
            <Link
              role="button"
              aria-label={i18nTranslate('header.signUp', 'Sign Up', {
                nameSpace: 'ssr-resource',
              })}
              to={enableJITSU ? '#' : pageNames.signUp}>
              <Button
                block
                data-testid="qa-okta-signup"
                onClick={this.signupcheck}
                className="okta-button text-uppercase">
                {i18nTranslate('header.signUp', 'Sign Up', {
                  nameSpace: 'ssr-resource',
                })}
              </Button>
            </Link>
            <br />
            <h6 className="mt-0 ">
              {i18nTranslate(
                'guest.alreadyHaveAnAccount',
                'Already have an account? Please',
                {
                  nameSpace: 'ssr-resource',
                }
              )}{' '}
              <Button
                className="sign-in"
                data-testid="qa-okta-signin"
                onClick={() => signInwithRedirect(checkout)}>
                {i18nTranslate('header.signIn', 'Sign-in', {
                  nameSpace: 'ssr-resource',
                })}
              </Button>
            </h6>
          </Col>
          {enableLiveEvent !== 'true' && (
            <Col
              sm={12}
              xs={12}
              lg={6}
              className="signup-checkout-container pl-4 pr-4">
              <h6 className="text-left mb-4  signin-title">
                {i18nTranslate('guest.orContinue', 'Checkout as Guest?', {
                  nameSpace: 'ssr-resource',
                })}
              </h6>
              <CheckoutUserForm onSubmit={this.handleDataSubmit} />
            </Col>
          )}
        </Row>
      </Container>
    )
  }
}

export { GuestCheckout }
export default GuestCheckout
