import React from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { IoIosList as ListIcon } from 'react-icons/io'
import { wishListContainer, customerContainer } from 'src/models'
import { CommonForm } from 'src/views/components'
import { listShareState } from './state'
import { formInput } from './formInput'
import { schema } from './validationSchema'
import './styles.scss'

@observer
class ListShare extends React.Component {
  shareList = async formData => {
    const { listDetails, toggleModalVisiblity } = listShareState

    const { emailRecipients, ShareSendMeCopy } = formData
    const emailArray = emailRecipients.split(',')
    if (ShareSendMeCopy) {
      emailArray.push(customerContainer.profileResponse.email)
    }
    await wishListContainer.shareList({
      listId: listDetails.listId,
      recipients: emailArray.map(email => {
        return { email }
      }),
    })
    toggleModalVisiblity()
  }

  renderSubmitButton = () => {
    return (
      <Row noGutters>
        <Col
          lg={{ offset: 6, span: 3 }}
          md={{ offset: 4, span: 4 }}
          xs={12}
          className="pr-1">
          <Button
            className="w-100 mb-2 mb-md-0"
            variant="outline-primary"
            onClick={() => listShareState.toggleModalVisiblity()}>
            {i18nTranslate('list.cancel', 'Cancel')}
          </Button>
        </Col>
        <Col as={Button} type="submit" lg={3} md={4} xs={12} variant="primary">
          {i18nTranslate('list.saveChanges', 'Share')}
        </Col>
      </Row>
    )
  }

  render() {
    const { listDetails, showModal, toggleModalVisiblity } = listShareState
    const name = listDetails?.name
    return (
      <Modal
        className="share-list-modal"
        show={showModal}
        onHide={toggleModalVisiblity}
        centered>
        <Modal.Header
          className="font-weight-bold share-list-header border-0"
          closeButton>
          {listShareState.isFromOutfit
            ? i18nTranslate('list.shareMyOutfit', 'Share My Outfit')
            : i18nTranslate('list.shareMyList', 'Share My List')}
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center share-list-title my-2">
            <ListIcon />
            <div className="pl-3">{name}</div>
          </div>
          <CommonForm
            schema={schema()}
            formTypeJson={formInput()}
            SubmitButtonProps={this.renderSubmitButton}
            onSubmit={this.shareList}
            classNameValue={{ formRowClassName: 'mb-3' }}
          />
        </Modal.Body>
      </Modal>
    )
  }
}

export { ListShare }
