import { isExpressCheckout } from 'src/utils'

export function formInput(props) {
  const { hasPromoStackEnable } = props
  return [
    [
      {
        name: 'couponCode',
        isToHideLabel: true,
        dataTestId: 'qa-pay-coupon',
        maxLength: '64',
        columnType: {
          lg: isExpressCheckout() ? '9' : '4',
          md: isExpressCheckout() ? '9' : '6',
          ...(isExpressCheckout() && { sm: '8', xs: '8' }),
        },
        customClassName: 'mb-2 coupon-code-field',
        ariaLabel: isExpressCheckout() ? 'discountCode' : 'couponCode',
        placeholderKey: isExpressCheckout() ? 'discountCode' : 'couponCode',
        disabled: hasPromoStackEnable,
      },
      {
        name: 'applyButton',
        type: 'inlineSubmitButton',
        dataTestId: 'qa-pay-coupon-apply',
        columnType: {
          lg: isExpressCheckout() ? '3' : '2',
          md: '3',
          ...(isExpressCheckout() && { sm: '4', xs: '4' }),
        },
      },
    ],
  ]
}
