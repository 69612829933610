import React from 'react'
import { CustomPrice } from 'src/views/components'
import { i18nTranslate, getLiveEventStatus } from 'src/utils'
import { getLocalStorage } from 'src/utils'
import { customerContainer } from 'src/models'
import { productState } from 'src/views/components/BaseProductComponent'
import { quickViewState } from 'src/views/components/QuickView/state'
import { APPConfig } from 'config/appConfig'
import { observer } from 'mobx-react'

@observer
class ProductPrice extends React.Component {
  enableLiveEvent = getLiveEventStatus()
  render() {
    const {
      isBundle = false,
      //isCollection,
      transactionPrice = {},
      currency = '',
      prices = '',
      isSaveForLater = false,
      isSubscription = false,
      cartItemId = '',
      isViewCart = false,
      isSubscriptionModalUnitPrice = false,
      showStaticUnitPriceForQuickView = false,
      isFromPDP = false,
      singleQuantityPromotionPrice = {},
      isFromQuickViewUnitPrice = false,
      isToShowNewPriceStyling = false,
      isFromCart = false,
      isToHidePriceLabel = false,
    } = this.props
    const localvalue = getLocalStorage('accountType')

    // const totalValue = this.props.totalValue || {}
    // ^ for pdp,quickview,cart - all SUBSCRIPTION MODAL unitprice
    let totalValue = {}
    if (isFromQuickViewUnitPrice) {
      totalValue = quickViewState.pdpPromotionResponse?.unitValue || {}
    } else if (isFromPDP) {
      if (isBundle) {
        totalValue = this.props.totalValue || {}
      } else {
        totalValue =
          productState.pdpPromotionResponse?.unitValue ||
          this.props.totalValue ||
          {}
      }
    } else {
      // for cart and subscription dashboard -> subscription modal
      totalValue = this.props.totalValue || {}
    }

    const bundleProducts = this.props?.bundleProducts?.products?.[0]
    const CVPrice = !isSaveForLater
      ? this.props?.priceFacets?.CV || 0
      : this.props?.transactionPrice?.priceFacets?.CV || 0
    const PVPrice = !isSaveForLater
      ? this.props?.priceFacets?.PV ||
        this.props?.productDetails?.priceFacets?.PV ||
        0
      : this.props?.transactionPrice?.priceFacets?.PV || 0
    const modifiedTransPrice = isBundle
      ? prices != ''
        ? prices[0]
        : {}
      : transactionPrice
    const isPVCVRange =
      totalValue &&
      totalValue?.priceFacets &&
      ((totalValue?.priceFacets?.PV &&
        totalValue?.priceFacets?.PV?.PVDiscount) ||
        (totalValue?.priceFacets?.CV &&
          totalValue?.priceFacets?.CV?.CVDiscount))
        ? true
        : false
    const isPriceRange =
      totalValue && (totalValue?.totaldiscount || totalValue?.discount)
        ? true
        : false
    const isRange = isPVCVRange || isPriceRange || isBundle ? true : false
    const originalPrice =
      totalValue?.originalPrice ||
      (isBundle && prices != '' && prices[0].price) ||
      transactionPrice?.price ||
      0
    const priceAfterDiscount = totalValue?.priceAfterDiscount || 0
    const priceFreeValue =
      originalPrice - (totalValue?.totaldiscount || totalValue?.discount || 0)

    const priceFreePromotion =
      priceAfterDiscount == 0 && priceFreeValue == 0 ? true : false

    const CVPriceOriginal = totalValue?.priceFacets?.CV?.CV || CVPrice

    const PVPriceOriginal = totalValue?.priceFacets?.PV?.PV || PVPrice

    const CVPriceDiscount = totalValue?.priceFacets?.CV?.CVAfterDiscount || 0

    const PVPriceDiscount = totalValue?.priceFacets?.PV?.PVAfterDiscount || 0

    const priceFreePV =
      PVPriceOriginal - (totalValue?.priceFacets?.PV?.PVDiscount || 0)
    const priceFreePromotionPV =
      PVPriceDiscount == 0 && priceFreePV == 0 ? true : false

    const priceFreeCV =
      CVPriceOriginal - (totalValue?.priceFacets?.CV?.CVDiscount || 0)
    const priceFreePromotionCV =
      CVPriceDiscount == 0 && priceFreeCV == 0 ? true : false

    const cartFlag = cartItemId && cartItemId != '' && isViewCart ? true : false
    const userRole = customerContainer?.profileResponse?.userrole || ''
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'

    return (
      <div data-testid="qa-product-price" className="price-text">
        {isSubscriptionModalUnitPrice ? (
          <>
            {/* for subscription modal */}
            <CustomPrice
              custompriceValue={originalPrice}
              customDiscountPriceValue={priceAfterDiscount}
              custompriceLabel={i18nTranslate('cart.price', 'Price')}
              isCurrencySymbol={true}
              dataTestId="qa-subscription-unit-price"
              priceFreePromotion={priceFreePromotion}
              type={this.props?.type}
              isFromSubscriptionModal={isSubscriptionModalUnitPrice}
              isModalFromPDP={this.props.isModalFromPDP || false}
            />
            {localvalue?.includes('Brand Affiliate') && userRole?.length > 0 && (
              <>
                {/* for subscription modal */}
                <CustomPrice
                  custompriceValue={PVPriceOriginal}
                  customDiscountPriceValue={PVPriceDiscount}
                  custompriceLabel={i18nTranslate('pdp.svprice', 'SV', {
                    nameSpace: 'ssr-resource',
                  })}
                  dataTestId="qa-pdp-pvprice"
                  isSubscription={isSubscription}
                  priceFreePromotion={priceFreePromotionPV}
                  showOfferPriceInRed={cartFlag}
                  isPVCV={true}
                />
              </>
            )}
            {/* for subscription modal ends here */}
          </>
        ) : (
          <>
            {/*isFromPDP || showStaticUnitPriceForQuickView || isFromCartOrCheckoutProductBlock */}
            {isBundle ? (
              <>
                {/* if bundle or kit -- PDP*/}
                <CustomPrice
                  custompriceValue={originalPrice}
                  customDiscountPriceValue={priceAfterDiscount}
                  custompriceLabel={
                    isToHidePriceLabel
                      ? ''
                      : i18nTranslate('cart.price', 'Price')
                  }
                  isCurrencySymbol={true}
                  dataTestId="qa-pdp-price"
                  isSubscription={isSubscription}
                  priceFreePromotion={priceFreePromotion}
                  showOfferPriceInRed={cartFlag}
                  type={this.props?.type}
                  isFromPDP={this.props?.isFromPDP}
                  shouldShowKlarnaMessage={this.props?.shouldShowKlarnaMessage}
                  isbundle={isBundle}
                  shouldShowPaylaterMessage={
                    this.props?.shouldShowPaylaterMessage
                  }
                  userCountry={this.props?.userCountry}
                  isToShowNewPriceStyling={isToShowNewPriceStyling}
                />
              </>
            ) : (
              <>
                {priceFreePromotion ? (
                  <>
                    {/* if pdp normal product with price as zero */}
                    <CustomPrice
                      custompriceValue={originalPrice}
                      customDiscountPriceValue={priceAfterDiscount}
                      custompriceLabel={
                        isToHidePriceLabel
                          ? ''
                          : i18nTranslate('cart.price', 'Price')
                      }
                      isCurrencySymbol={true}
                      dataTestId="qa-pdp-price"
                      isSubscription={isSubscription}
                      priceFreePromotion={priceFreePromotion}
                      showOfferPriceInRed={cartFlag}
                      isToShowNewPriceStyling={isToShowNewPriceStyling}
                    />
                  </>
                ) : (
                  <>
                    {/* normal product */}
                    {/* both PDP and Quickview  */}
                    {/* with price and range */}
                    <CustomPrice
                      custompriceValue={originalPrice}
                      customDiscountPriceValue={priceAfterDiscount}
                      custompriceLabel={
                        isToHidePriceLabel
                          ? ''
                          : i18nTranslate('cart.price', 'Price')
                      }
                      isCurrencySymbol={true}
                      dataTestId="qa-pdp-price"
                      isSubscription={isSubscription}
                      priceFreePromotion={priceFreePromotion}
                      showOfferPriceInRed={cartFlag}
                      shouldShowKlarnaMessage={
                        this.props?.shouldShowKlarnaMessage
                      }
                      isToShowNewPriceStyling={isToShowNewPriceStyling}
                    />
                  </>
                )}
              </>
            )}
            {!enableNewPDPStyle &&
              localvalue?.includes('Brand Affiliate') &&
              userRole?.length > 0 &&
              !(this.enableLiveEvent === 'true' && isFromCart) && (
                <>
                  {/* for pdp and quickview */}
                  <CustomPrice
                    custompriceValue={PVPriceOriginal}
                    customDiscountPriceValue={PVPriceDiscount}
                    custompriceLabel={i18nTranslate('pdp.svprice', 'SV', {
                      nameSpace: 'ssr-resource',
                    })}
                    dataTestId="qa-pdp-pvprice"
                    isSubscription={isSubscription}
                    priceFreePromotion={priceFreePromotionPV}
                    showOfferPriceInRed={cartFlag}
                    isPVCV={true}
                    isToShowNewPriceStyling={isToShowNewPriceStyling}
                  />
                  {/* CX121-258 & CX121-435 CV shouldn't display it to the user
                {userRole != 'ROLE_ACCOUNT_BUYER_ADMIN' && (
                  <CustomPrice
                    custompriceValue={CVPriceOriginal}
                    customDiscountPriceValue={CVPriceDiscount}
                    custompriceLabel={i18nTranslate('pdp.cvprice', 'CV')}
                    dataTestId="qa-pdp-pvprice"
                    isSubscription={isSubscription}
                    priceFreePromotion={priceFreePromotionCV}
                    showOfferPriceInRed={cartFlag}
                    isPVCV={true}
                  />
                )} */}
                  {/* {localvalue?.includes('Brand Affiliate') ? (
                    <>
                      <CustomPrice
                        custompriceValue={PVPrice || PVPriceOriginal}
                        custompriceLabel={i18nTranslate('pdp.pvprice', 'PV')}
                        dataTestId="qa-pdp-pvprice"
                      />
                      <CustomPrice
                        custompriceValue={CVPrice || CVPriceOriginal}
                        custompriceLabel={i18nTranslate('pdp.cvprice', 'CV')}
                        dataTestId="qa-pdp-pvprice"
                      />
                    </>
                  ) : (
                    ''
                  )} */}
                </>
              )}
          </>
        )}
      </div>
    )
  }
}

export { ProductPrice }
