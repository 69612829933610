import { CommonContainer } from 'src/models/Common'
import {
  customerContainer,
  cartContainer,
  listsContainer,
  accountsContainer,
  teamContainer,
  loyaltyContainer,
  accountsAddressContainer,
} from 'src/models'
import {
  setLocalStorage,
  deleteFromLocalStorage,
  isB2BAccount,
  isB2BUser,
  setCookie,
  shouldMakeB2BCalls,
  getAppConfig,
} from 'config/appConfig'
import { toastState } from 'src/views/components'
import {
  getLocaleCodeFromUrl,
  application,
  enablePhoneVerification,
  convertToBoolean,
  checkConsentCookieAndEnableTracking,
  trackImpactIdentify,
} from 'src/utils'

/**
 * SessionContainer class that extends CommonContainer.
 * Manages session state and authentication for the application.
 */
class SessionContainer extends CommonContainer {
  csrAdminName = ''

  /**
   * Initializes the session by:
   * - Checking the user profile and registering guest if needed
   * - Fetching qualification data
   * - Getting subscription count if registered user
   * - Viewing the cart
   */
  init = async () => {
    console.log('^^^^^^^ session init called ^^^^^^^')

    await this.checkProfileMakeRegisterGuest()

    /**
     * @todo
     * !!! required to be revalidated
     */
    customerContainer.fetchQualificationData()
    if (customerContainer.isRegisterUser) {
      await customerContainer.qualificationUser()
      await cartContainer.getSubscriptionCount()
      // customerContainer.fetchQualificationData()
    }
    cartContainer.viewCart(null, true)
  }

  /**
   * Logs in the user with the provided form data.
   *
   * @param {Object} formData - The login form data.
   * @returns {Promise} A promise that resolves to the API response.
   */
  login = async formData => {
    const response = await customerContainer.login(formData)
    if (this.isSuccessResponse(response)) {
      await this.init()
    }
    localStorage.setItem('IsAuthenticated', true)
    return response
  }

  /**
   * Registers a new user with the provided form data.
   *
   * @param {Object} formData - The registration form data.
   * @returns {Promise} A promise that resolves to the API response.
   */
  registerNextGen = async formData => {
    const response = await customerContainer.registerNextGen(formData)
    return response
  }

  /**
   * Logs in the user with Okta authentication.
   *
   * @param {Object} formData - The login form data.
   * @param {Function} successCbk - Optional callback on successful login.
   * @returns {Promise} A promise that resolves to the API response.
   */
  oktaLogin = async (formData, successCbk) => {
    const {
      response,
      homeCountry,
      activeCountryCode = '',
    } = await customerContainer.oktaLogin(formData)
    if (response && this.isSuccessResponse(response)) {
      application.isCallInProgress = true
      setLocalStorage('IsAuthenticated', true)
      setLocalStorage('isOktaUser', true)
      listsContainer.initFavoritesAndSaveForLater(true)
      await this.init()
      application.isCallInProgress = false
      if (successCbk) {
        successCbk(response, homeCountry, activeCountryCode?.toLowerCase())
      }
    } else {
      toastState.setToastMessage(response?.message)

      /**
       * @important
       * we are already redirecting to previous page
       * via LoginCallback component, so we need to skip here
       */
      await customerContainer?.logout(true)
    }
  }

  /**
   * Authorizes the user with Okta authentication.
   *
   * Calls the customerContainer.oktaAuthorize() method to get the Okta authorize URL.
   * Parses the URL to get the redirect URI and nonce parameter.
   * Saves the redirect URI and nonce to local storage.
   *
   * If the authorization is successful, returns the response.
   * If not, sets an error toast message and redirects to the home page after 3 seconds.
   */ oktaAuthorize = async () => {
    const response = await customerContainer.oktaAuthorize()
    const oktaAuthorizeUrl = response?.oktaAuthorizeUrl || ''
    const oktaUrl = new URL(oktaAuthorizeUrl)
    const redirectUri = oktaUrl?.searchParams?.get('state') || ''
    const nonceParam = oktaUrl?.searchParams?.get('nonce') || ''
    setLocalStorage('oktaUrlState', redirectUri)
    setLocalStorage('nonceParam', nonceParam)

    if (this.isSuccessResponse(response)) {
      return response
    } else {
      toastState.setToastMessage(response.message)

      if (typeof window !== 'undefined') {
        let locale = getLocaleCodeFromUrl() || ''
        locale = locale?.toLowerCase()?.split('_')
        setTimeout(() => {
          window.location.href = `${window.location.origin}/${locale?.[1]}/${locale?.[0]}`
        }, 3000)
      }
    }
  }

  /**
   * Registers a new user with the provided form data.
   *
   * Calls the customerContainer to register the user and get a response.
   * If registration is successful:
   * - Views the cart
   * - Initializes favorites and saved for later lists
   * - Gets loyalty points
   *
   * @param {Object} formData - Object containing registration form data
   * @returns {Object} API response
   */
  register = async formData => {
    const response = await customerContainer.register(formData)
    if (this.isSuccessResponse(response)) {
      cartContainer.viewCart()
      listsContainer.initFavoritesAndSaveForLater(true)
      await loyaltyContainer.getLoyaltyPoints(true)
    }
    return response
  }

  /**
   * Registers a guest user.
   *
   * If not a B2B account, calls the customerContainer to register a guest user
   * and initialize favorites and saved for later lists.
   *
   * If a B2B account, gets the account settings from the accounts container.
   *
   * @returns {Object} API response
   */
  guestRegister = async () => {
    if (!isB2BAccount()) {
      const response = await customerContainer.guestRegister()
      listsContainer.initFavoritesAndSaveForLater(false)
      return response
    }
    if (isB2BAccount() === true) {
      await accountsContainer.getAccountSettings()
      //await accountsContainer.getAllAttributes()
      //await accountsContainer.getAllAttributesWithoutSize()
    }
    return ''
  }

  /**
   * Gets the list services, optionally skipping getting the list.
   *
   * @param {boolean} skipGetList - Whether to skip getting the list
   */
  listServices = skipGetList => {
    console.log('list service here')
  }

  /**
   * Checks profile and registers guest if needed
   *
   * Gets customer profile. If unsuccessful and session not expired, registers guest.
   * If B2B account, gets account settings and other account info.
   * If should make B2B calls, gets additional account info.
   * If B2B user, gets account addresses.
   * Gets loyalty points for registered user if missing or request failed.
   *
   * @returns {Object} API response
   */
  checkProfileMakeRegisterGuest = async () => {
    const response = await customerContainer.getProfile()
    if (!this.isSuccessResponse(response)) {
      if (!customerContainer.isSessionExpired) {
        this.guestRegister()
      }
    } else {
      this.trackImpact(customerContainer)
      checkConsentCookieAndEnableTracking(customerContainer)
      if (isB2BAccount()) {
        // accountsContainer.getContracts()
        //await accountsContainer.getAllAttributes()
        //await accountsContainer.getAllAttributesWithoutSize()
      }

      const isPhoneNumberVerified =
        response?.customProperties?.isPhoneNumberVerified || ''
      if (
        customerContainer.isRegisterUser &&
        isPhoneNumberVerified !== 'true' &&
        enablePhoneVerification()
      ) {
        customerContainer.fromSessionVerifyPhonePopup = true
      }

      if (shouldMakeB2BCalls()) {
        await accountsContainer.getAccountSettings()

        accountsAddressContainer.getAllAccountAddress()
        accountsContainer.getAllBuyerRoles()
        await teamContainer.getAllTeams()
        // costCenterContainer.getAllCostCenter()
      }
      if (isB2BUser()) {
        accountsAddressContainer.getAllAccountAddress()
      }
      /**
       * @note
       * NUSKIN-439 Commenting redundant calls for refactoring
       */

      // if (customerContainer.isRegisterUser) {
      //   await addressContainer.getProfileAddress()
      //   await customerContainer.getPayment()
      // }

      // }

      /**
       * @important @note
       * loyalty API is must required for signed in users.
       * So, DON'T comment out the following condition
       */
      if (
        (Object.keys(loyaltyContainer?.loyaltyPointsResponse || {}).length ===
          0 ||
          !this.isSuccessResponse(loyaltyContainer.loyaltyPointsResponse)) &&
        customerContainer.isRegisterUser
      ) {
        await loyaltyContainer.getLoyaltyPoints(true)
      }
      // listsContainer.initFavoritesAndSaveForLater(true)
    }
    return response
  }
  /**
   * Reigsters customer id to impact
   * @param {Object} customerContainer - An object containing customer-related data.
   * @returns {void}
   */
  trackImpact = customerContainer => {
    // Cookie consent is not needed for Impact tracking
    const enableImpactTracking = convertToBoolean(
      getAppConfig().enableImpactTracking
    )
    if (enableImpactTracking) {
      trackImpactIdentify(customerContainer)
    }
  }
  /**
   * Logs in as a CSR admin user using provided credentials
   * @param {Object} props - Object containing token and userId
   * @returns {Promise} Makes API call to log in and sets cookies/localStorage
   */
  loginAsCSR = async props => {
    const { token, userId } = props
    const { csrAdminCookie } = getAppConfig()
    const loadParams = {
      endPointName: 'loginAsCSR',
      queryParams: {
        token,
        userId,
      },
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      setLocalStorage('x-store-id', props.storeId)
      const firstName = response?.firstName || ''
      const lastName = response?.lastName || ''
      const name = firstName || lastName ? `${firstName} ${lastName}` : ''
      setCookie({
        cookieName: csrAdminCookie,
        cookieValue: name,
        ignoreCommonHeader: true,
      })
      if (token) {
        setLocalStorage('check', true)
        setLocalStorage('phoneVerifyFromSession', true)
      }
      if (name) {
        setLocalStorage('csrAdminName', name)
      }
      this.csrAdminName = name
    } else {
      toastState.setToastMessage(response.message)
    }
    deleteFromLocalStorage('dynamicStoreId')
  }

  /**
   * Refreshes the user's session by calling the refreshSession API endpoint.
   * Updates the session data if the API call is successful.
   * Shows a toast message if the API call fails.
   */
  refreshSession = async () => {
    const loadParams = {
      endPointName: 'refreshSession',
    }

    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      console.log('refreshSession Success-response ==>', response)
    } else {
      toastState.setToastMessage(response.message)
    }
    return response
  }
}

const sessionContainer = new SessionContainer()

export { sessionContainer }
export default sessionContainer
