import React from 'react'
// import get from 'lodash/get'
import { i18nTranslate } from 'src/utils'
import isFunction from 'lodash/isFunction'
import { Link } from 'react-router-dom'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Card, Row, Button } from 'react-bootstrap'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { isMerchPLP, enableProductCompare } from 'config/appConfig'
import { pageNames } from 'src/routes/pathParams'
import {
  Price,
  ProductBadge,
  ProductPromotion,
  ProductFavorites,
  ProductSkuOptionDropDown,
  ProductAddToCart,
  CustomPrice,
} from 'src/views/components'
import './styles.scss'
import { application } from 'src/utils'
import { ProductCompare } from '../ProductCompare'
import { MerchAlternateImages } from '../MerchAlternateImages'
import { catalogState } from 'src/models/Catalog/catalogState'
import { specialProductTypes } from '../fixture'

@observer
class ProductCardWithDropDown extends React.Component {
  @observable imageArrayProperties = { images: [], currentIndex: 0 }
  productCard = ''

  constructor(props) {
    super(props)
    if (isMerchPLP) {
      this.imageArrayProperties.images = catalogState.getImageArray(
        props.product
      )
    }
  }

  handleCurrentImage = () => {
    const index = catalogState.handleCurrentImage(this.imageArrayProperties)
    this.imageArrayProperties.currentIndex = index
  }

  renderProductCompare = () => {
    const { categoryId, productId } = this.props
    const { imageURL = '' } = this.props?.product?.properties || {}
    return (
      <ProductCompare
        productId={productId}
        categoryId={categoryId}
        imageURL={imageURL}
      />
    )
  }

  render() {
    const {
      brand,
      name,
      imageURL: image = '',
    } = this.props?.product?.properties || {}
    const { currentIndex, images } = this.imageArrayProperties
    const {
      productBadge,
      showLazyLoad = true,
      categoryId = '',
      productPromotion = [],
      productType,
      isProductComparable,
      productId,
      index,
      productOption,
      skuSelected,
      handleAddToBag,
      product = [],
    } = this.props
    const PVPrice = product?.priceFacets?.PV || 0
    const imageURL = isMerchPLP ? images[currentIndex] : image

    const identifier = this.props?.productId || this.props?.product?.identifier
    const skuPrice = this.props?.product?.prices
    const productOrSKUPrice = this.props?.product?.transactionPrice || skuPrice
    const transactionPrice =
      productOrSKUPrice && productOrSKUPrice.length > 0
        ? productOrSKUPrice[0]
        : productOrSKUPrice
    const descriptionClass = application.isMobile ? 'pl-2' : ''
    const url = `${pageNames.product}/${identifier}`
    const isLinkEnabled = isMerchPLP ? 'disabled' : ''

    const pathName = this.props?.location?.pathname || ''
    const isProductListPage =
      pathName.includes(pageNames.catalog) ||
      pathName.includes('template') ||
      pathName.includes(pageNames.search)

    const skuLevelPromotion = this.props?.product?.promotion || []
    const productAndSkuLevelPromotion = [
      ...skuLevelPromotion,
      ...productPromotion,
    ]

    return (
      <Card
        className={`plp-card-item card-wrap position-relative bg-white mb-0 border-0 w-100 ${isLinkEnabled} border`}
        data-testid="qa-product-container"
        key={`product_card_${identifier}`}
        ref={element => (this.productCard = element)}
        {...(isLinkEnabled === 'disabled' && {
          onClickCapture: e => {
            if (!e.target.classList.contains('allow-merchplp')) {
              e.stopPropagation()
            }
          },
        })}>
        <Link to={url} className="image-container-wrap">
          <ProductBadge
            productBadge={productBadge}
            badgeRendertype={'horizontal'}
          />
          <LazyLoadImage
            data-testid="qa-product-image"
            src={imageURL || imagePlaceholder}
            alt={imagePlaceholder}
            // data-testid={ "qa-product-image"}
            className={'card-image w-auto'}
            onError={e => {
              if (e.target.src != imagePlaceholder) {
                e.target.onerror = null
                e.target.src = imagePlaceholder
              }
            }}
            visibleByDefault={!showLazyLoad}
            {...(isMerchPLP && {
              afterLoad: () => {
                const { handleSizeCalc } = this.props
                isFunction(handleSizeCalc) &&
                  handleSizeCalc(this.productCard.offsetHeight)
              },
            })}
          />
        </Link>
        <ProductFavorites
          {...{ productId: productId || identifier, name, categoryId }}
        />
        <Card.Body
          className={`product-description flex-direction-column pt-3 pr-2 pb-2 ${descriptionClass}`}
          data-testid="qa-figcaption">
          {enableProductCompare &&
            isProductListPage &&
            isProductComparable &&
            this.renderProductCompare()}

          <Link to={url} className="text-decoration-none">
            <Card.Title
              className="brand-name flex-nowrap font-weight-medium text-dark text-left overflow-hidden w-100"
              data-testid="qa-product-brand">
              {brand}
            </Card.Title>
            <Card.Subtitle
              className="catalog-product-name mb-2 flex-nowrap text-left text-dark overflow-hidden h-auto"
              data-testid="qa-product-name">
              {name}
            </Card.Subtitle>
          </Link>
          <Row
            noGutters
            className="product-price flex-row"
            data-testid="qa-product-price">
            <Price
              transactionPrice={transactionPrice}
              currency={this.props.currency}
            />
          </Row>
          <CustomPrice
            custompriceValue={PVPrice}
            custompriceLabel={i18nTranslate('pdp.svprice', 'SV', {
              nameSpace: 'ssr-resource',
            })}
            dataTestId="qa-pdp-pvprice"
          />
          {/* 
          CX121-258 & CX121-435 CV shouldn't display it to the user
          <CustomPrice
            custompriceValue={CVPrice}
            custompriceLabel={i18nTranslate('pdp.cvprice', 'CV')}
            dataTestId="qa-pdp-pvprice"
          /> */}
          <ProductPromotion promotion={productAndSkuLevelPromotion} />
        </Card.Body>
        <MerchAlternateImages
          imageArrayProperties={this.imageArrayProperties}
          handleCurrentImage={this.handleCurrentImage}
        />
        <div className="position-absolute hover-wrapper py-2 px-3 w-100 bg-white">
          {specialProductTypes.includes(productType) ? (
            <Button variant="primary" className="w-100">
              <Link to={url} className="text-decoration-none text-white">
                {i18nTranslate('plp.viewpdp', 'View in PDP', {
                  nameSpace: 'ssr-resource',
                })}
              </Link>
            </Button>
          ) : (
            <>
              <ProductSkuOptionDropDown
                options={productOption}
                index={index}
                handleSkuSelection={this.props.handleSkuSelection}
                skuSelected={skuSelected}
              />
              <ProductAddToCart handleAddToBag={() => handleAddToBag(index)} />
            </>
          )}
        </div>
      </Card>
    )
  }
}

export { ProductCardWithDropDown }
export default ProductCardWithDropDown
