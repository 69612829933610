import React from 'react'
import { i18nTranslate } from 'src/utils'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { pageNames } from 'src/routes/pathParams'
import { Button, Row } from 'react-bootstrap'
import { cartContainer } from 'src/models'
import { CartProducts, Summary } from 'src/views/components'
import './styles.scss'

@observer
class CartView extends React.Component {
  renderCartViewButtons = props => {
    const { onContinue } = props
    return (
      <Row
        className="cart-view-button-wrapper  justify-content-end flex-column flex-md-row"
        noGutters>
        {/* dont use anchor tags use link or history push and fix any issue in it*/}
        {/* <a
          onClick={() => (window.location.href = window.location.origin)}
          // href="javascript:;"
        > */}
        <Link to={pageNames.home}>
          <Button
            className="cart-view-continue-shopping-button mr-2 mb-md-0 mb-2"
            data-testid="qa-cart-continue-shopping">
            {i18nTranslate('cart.continueShopping', 'Continue Shopping')}
          </Button>
        </Link>
        <Button
          className="cart-view-checkout-button"
          onClick={onContinue}
          data-testid="qa-cart-checkout">
          {i18nTranslate('cart.checkout', 'Checkout')}
        </Button>
      </Row>
    )
  }
  render() {
    // const redeemedLoyaltyPoints = cartContainer.redeemedPoints()
    const value = cartContainer?.cartResponse?.value || ''
    const cartResponse = cartContainer?.cartResponse || {}
    const loyaltyAppliedResult = cartResponse.payments?.find(
      item => item?.type?.toLocaleLowerCase() == 'loyalty'
    )
    const redeemedLoyaltyPoints = loyaltyAppliedResult?.amount || 0
    const storeCredit = cartContainer.redeemedStoreCreditPoints()
    const summaryValues = {
      priceAfterMarkdown: value.priceAfterMarkdown,
      cartDeliveryCharge: value.cartDeliveryCharge,
      overAllTax: value.overAllTax,
      grandTotal: value.grandTotal - redeemedLoyaltyPoints - storeCredit,
      overAllDiscount: value.overAllDiscount,
      redeemedLoyaltyPoints: redeemedLoyaltyPoints,
      redeemableLoyaltyPoints: value.totalLoyaltyPointsToPay,
      count: value.count,
      storeCredit: storeCredit,
      cartDeliveryChargeAfterDiscount: value.cartDeliveryChargeAfterDiscount,
      loyaltyDiscount: value?.loyaltyDiscount,
    }
    const currencyCode = cartContainer?.cartResponse?.currencyCode || ''
    const promotionDetails =
      cartContainer?.cartResponse?.promotionDetails?.promotions || []
    const { isMultipleAdressActive } = this.props
    return (
      <div data-testid="qa-cart-products-container">
        <CartProducts isCheckout={true} isSaveForLater={false} />
        <Summary
          math={summaryValues}
          isMultipleAdressActive={isMultipleAdressActive}
          customClass="p-0 summary-wrapper"
          currencyCode={currencyCode}
          isCheckout={true}
          promotionDetails={promotionDetails}
          showRedeemableSection={true}
        />
        {this.renderCartViewButtons(this.props)}
      </div>
    )
  }
}

export default CartView
export { CartView }
