import React from 'react'
import { Nav } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { IS_BROWSER, application } from 'src/utils'
import { AccordionBlock } from '../AccordionBlock'
import isEmpty from 'lodash/isEmpty'
import './styles.scss'

const RenderLinks = props => {
  const nameSpace = 'ssr-resource'
  const { label, href, target = '' } = props

  // Since we are in classic site domain. Base URL is not needed
  // const baseurl = 'https://www.nuskin.com'
  // let hreftext = ''
  // if (href.includes('https://')) {
  //   hreftext = href
  // } else {
  //   hreftext = baseurl + href
  // }

  return (
    <Nav.Item className="item-value pb-2">
      <Nav.Link
        className="p-0 paragraph-s sub-options"
        target={target != '' ? target : '_blank'}
        rel="noopener noreferrer"
        href={href}
        title={i18nTranslate('link.title', 'new window', {
          nameSpace: nameSpace,
        })}>
        {label}
      </Nav.Link>
    </Nav.Item>
  )
}

const RenderTitle = props => {
  return (
    <div className="title-label event-cursor font-weight-bold">
      {props.title}
    </div>
  )
}

const RenderItem = props => {
  const { item, enableLiveEvent = false } = props

  let arr1 = []
  let arr2 = []
  let count = 0

  // Two array causing UI issues in desktop
  // if (application.isDesktop || !IS_BROWSER) {
  //   item?.links.map(items => {
  //     if (count < 7) {
  //       arr1.push(items)
  //       count = count + 1
  //     } else {
  //       arr2.push(items)
  //     }
  //   })
  // } else {
  //   item?.links?.map(items => {
  //     arr1.push(items)
  //   })
  // }

  item?.links?.map(items => {
    arr1.push(items)
  })

  const items = arr1.map(title => {
    return (
      <RenderLinks
        key={title?.name}
        label={title?.name}
        href={title?.href}
        target={title?.target || ''}
      />
    )
  })
  // const accordianClassName = application.isDesktop
  //   ? 'py-3 pr-2'
  //   : application.isTablet
  //   ? 'border-bottom pl-2 mt-2'
  //   : application.isMobile
  //   ? 'border-bottom p-2 mt-0'
  //   : ''

  let accordianClassName = application.isTablet
    ? 'border-bottom pl-2 mt-2'
    : application.isMobile
    ? 'border-bottom p-2 mt-0'
    : 'py-3 pr-2'
  accordianClassName = enableLiveEvent
    ? 'eventfooter-links'
    : accordianClassName
  return (
    <>
      <AccordionBlock
        data-testid={item.dataTestId}
        key={item.title}
        hasArrow={application.isTabletOrSmaller && !enableLiveEvent}
        {...((application.isDesktop || !IS_BROWSER || enableLiveEvent) && {
          isActive: true,
        })}
        title={<RenderTitle title={item.title} />}
        className={accordianClassName}>
        <Nav className="flex-column pt-md-2">{items}</Nav>
      </AccordionBlock>
      {!isEmpty(arr2) && (application.isDesktop || !IS_BROWSER) && (
        <AccordionBlock
          data-testid={item.dataTestId}
          key={item.title}
          title=""
          hasArrow={application.isTabletOrSmaller}
          {...((application.isDesktop || !IS_BROWSER) && { isActive: true })}
          className={
            application.isTablet
              ? 'border-bottom pl-2'
              : application.isMobile
              ? 'border-bottom pl-2'
              : 'py-3'
          }>
          <Nav className="flex-column pt-md-2">
            {arr2.map(title => {
              return (
                <RenderLinks
                  key={title.name}
                  label={title.name}
                  href={title.href}
                  target={title.target || ''}
                />
              )
            })}
          </Nav>
        </AccordionBlock>
      )}
    </>
  )
}

class Links extends React.Component {
  arr2 = []
  render() {
    let footerLinks = this.props.data?.navigation
    let { enableLiveEvent = false } = this.props
    let children =
      footerLinks &&
      footerLinks.map((item, index) => {
        return (
          <RenderItem
            key={index}
            item={item}
            enableLiveEvent={enableLiveEvent}
          />
        )
      })

    return <div className="link-list-wrap">{children}</div>
  }
}

export default Links
export { Links }
