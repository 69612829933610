import React from 'react'
import { observer } from 'mobx-react'
import { observable, reaction } from 'mobx'
// import isArray from 'lodash/isArray'
import without from 'lodash/without'
import { i18nTranslate } from 'src/utils'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Button,
  Tab,
  Row,
  Col,
  Nav,
  Image,
  Form,
  Dropdown,
  Modal,
} from 'react-bootstrap'
import { MdEmail } from 'react-icons/md'
import { pageNames } from 'src/routes/pathParams'
import { outFitsContainer } from 'src/models'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { SocialShare, Overlay, overlayState } from 'src/views/components'
import { listShareState, ListShare } from 'src/views/components/ListShare'
import { Outfits, OutFitsList } from '../Outfits'
import { outfitModalState } from './state'
import { RecommendedSection } from '../RecommendedSection'
import { getCategoryList, searchDate, mainCategories } from '../fixture'
import './styles.scss'

@observer
class OutFitsModel extends React.Component {
  outfitSlider = {}
  categoryList = getCategoryList()
  activeCategory = categoryList[0].categoryId || ''
  @observable activeCategoryTitle = categoryList[0].categoryId || ''
  @observable selectedDate = searchDate()[0].key || ''
  newOutFitInput = ''
  @observable row1Products = []
  @observable row2Products = []
  @observable categorySelected = ''

  disposer = () => {}

  constructor(props) {
    super(props)
    outfitModalState.isModalVisible = false
    outFitsContainer.currentOutFit = {}
  }

  updateRowItems = () => {
    const currentItems = outFitsContainer.currentOutFit?.listItems || []
    this.row2Products =
      currentItems.filter(outfitItemProduct => {
        return (
          outfitItemProduct?.properties?.type === 'shirts' ||
          outfitItemProduct?.properties?.type === 'pants'
        )
      }) || []
    this.row1Products =
      currentItems.filter(outfitItemProduct => {
        return (
          outfitItemProduct?.properties?.type !== 'shirts' &&
          outfitItemProduct?.properties?.type !== 'pants'
        )
      }) || []
  }

  componentDidMount() {
    this.disposer = reaction(
      () => outFitsContainer.currentOutFit.listItems,
      () => {
        this.updateRowItems()
      }
    )
  }

  componentWillUnMount() {
    this.disposer()
  }

  handleClosePhase = () => {
    outFitsContainer.recommendedProducts = []
    this.categorySelected = ''
  }

  changeCategory = value => {
    this.categorySelected = value
  }

  onModalMounted = async () => {
    if (this.newOutFitInput) {
      this.newOutFitInput.focus()
    }
    this.activeCategory = categoryList[0].categoryId || ''
    const currentItems = outFitsContainer.currentOutFit?.listItems || []
    if (
      outFitsContainer.listShowType === 'outFit' &&
      currentItems.length > 1 &&
      currentItems.length < 5
    ) {
      const availableTypes = (
        outFitsContainer.currentOutFit?.listItems || []
      ).map(item => {
        return item?.properties?.type || ''
      })
      const missedCategory = without(mainCategories, ...availableTypes)[0] || ''
      if (missedCategory) {
        // const recommendedProduct = recommendedProducts.find(
        //   product => product.properties.type === missedCategory
        // )
        overlayState.toggleLoader()
        const recommendedProduct = ''
        overlayState.toggleLoader()
        if (recommendedProduct) {
          if (outFitsContainer.getSingleRecommendation) {
            if (!outFitsContainer.currentOutFit.listItems) {
              outFitsContainer.currentOutFit['listItems'] = []
            }
            outFitsContainer.currentOutFit.listItems.push(recommendedProduct)
          }
        }
      }
    }
    this.updateRowItems()
  }

  updateEditedState = (isSuccess = false) => {
    if (isSuccess) {
      outFitsContainer.currentOutFit.isOutFitsEdited = false
      outFitsContainer.setListType('outFit')
      outfitModalState.isModalVisible = false
    }
  }

  handleSaveOutfit = async () => {
    const { listShowType: listType, currentOutFit } = outFitsContainer
    if (listType === 'outFit') {
      const listItems = currentOutFit.listItems.filter(
        outFit => outFit.outFitItem === 'new'
      )
      if (listItems.length > 0) {
        const isSuccess = await outFitsContainer.addItemToOutFit(listItems)
        if (currentOutFit.isNameEdited) {
          await outFitsContainer.updateOutfit(currentOutFit, false)
        }
        this.updateEditedState(isSuccess)
      } else {
        const isSuccess = await outFitsContainer.updateOutfit(currentOutFit)
        this.updateEditedState(isSuccess)
      }
    } else if (listType === 'newOutFit') {
      const isSuccess = await outFitsContainer.createOutFit({
        ...currentOutFit,
      })
      this.updateEditedState(isSuccess)
    }
  }

  renderImages = (item = {}, index = '', listType = '') => {
    return (
      <div>
        <div
          className={`d-flex align-items-center justify-content-center category-product-images`}>
          <Image
            fluid
            key={`image${index}`}
            src={item?.properties?.imageURL || '' || imagePlaceholder}
            className="mw-100 mh-100"
          />
        </div>
      </div>
    )

    // return (
    //   <Draggable
    //     key={`imagedrag${index}${listType}`}
    //     draggableId={`${item.id}-${listType}`}
    //     index={index}>
    //     {(provided, snapshot) => (
    //       <div
    //         ref={provided.innerRef}
    //         {...provided.draggableProps}
    //         {...provided.dragHandleProps}
    //         style={{
    //           ...provided.draggableProps.style,
    //         }}>
    //         <div
    //           className={`d-flex align-items-center justify-content-center category-product-images ${
    //             snapshot.isDragging ? 'border-dark' : ''
    //           }`}>
    //           <Image
    //             fluid
    //             key={`image${index}`}
    //             src={item?.properties?.imageURL || '' || imagePlaceholder}
    //             className="mw-100 mh-100"
    //           />
    //         </div>
    //       </div>
    //     )}
    //   </Draggable>
    // )
  }

  handleCategoryTitle = category => {
    this.activeCategoryTitle = category
  }

  renderTabIcon = (category = {}) => {
    const { icon: Icon, categoryId = '' } = category
    return (
      <Nav.Item
        key={categoryId}
        onClick={() => this.handleCategoryTitle(categoryId)}>
        <Nav.Link eventKey={categoryId} className="p-2">
          <Icon size="2rem" />
        </Nav.Link>
      </Nav.Item>
    )
  }

  renderList = (categoryId = '', products = [], type = '') => {
    return (
      <Tab.Pane
        eventKey={categoryId}
        key={`${categoryId}-${type}`}
        className="category-container px-3">
        {products.length > 0 ? (
          <div className="grid-products">
            {products.map((product, index) =>
              this.renderImages(product, index, type)
            )}
          </div>
        ) : (
          <div className="text-center no-products-text">
            {i18nTranslate(
              'outfit.noProductsAvailable',
              'No products available'
            )}
          </div>
        )}
      </Tab.Pane>
    )

    // if (products.length > 0) {
    //   return (
    //     <Tab.Pane
    //       eventKey={categoryId}
    //       key={`${categoryId}-${type}`}
    //       className="category-container px-3">
    //       <Droppable
    //         isDropDisabled={true}
    //         droppableId={`${type}-droppable`}
    //         type="outfit-drop">
    //         {provided => (
    //           <div
    //             {...provided.droppableProps}
    //             ref={provided.innerRef}
    //             className="grid-products">
    //             {products.map((product, index) =>
    //               this.renderImages(product, index, type)
    //             )}
    //             {provided.placeholder}
    //           </div>
    //         )}
    //       </Droppable>
    //     </Tab.Pane>
    //   )
    // } else {
    //   return (
    //     <Tab.Pane
    //       eventKey={categoryId}
    //       key={`${categoryId}-${type}`}
    //       className="category-container px-3">
    //       <div className="text-center no-products-text">
    //         {i18nTranslate('outfit.noProductsAvailable', 'No products available')}
    //       </div>
    //     </Tab.Pane>
    //   )
    // }
  }

  renderTabPane = (category = {}) => {
    const { categoryId = '' } = category
    const products = outFitsContainer.allWishListProducts.filter(product => {
      return product.properties.type === category.categoryId
    })
    return this.renderList(categoryId, products, 'wishlist')
  }

  renderOrders = (category = {}) => {
    const { categoryId = '' } = category
    const products = outFitsContainer.orderListItems[category.categoryId]
    return this.renderList(categoryId, products, 'order')
  }

  updateEditedProducts = (destination = {}, editeditem = '') => {
    if (destination.droppableId === 'outfit-droppable-0') {
      this.row1Products.splice(destination.index, 0, editeditem)
      // this.row2Products = this.row2Products.filter(
      //   item => item.key !== editeditem.key
      // )
    } else {
      this.row2Products.splice(destination.index, 0, editeditem)
      // this.row1Products = this.row1Products.filter(
      //   item => item.key !== editeditem.key
      // )
    }
  }

  // @action
  // onDragEnd = async (result = {}) => {
  //   const { draggableId = '', source = {}, destination = {} } = result
  //   const { currentOutFit } = outFitsContainer
  //   // dropped outside the list
  //   // if (
  //   //   !result.destination ||
  //   //   destination.droppableId !== `outfit-droppable-${currentOutFit.id}`
  //   // ) {
  //   if (!result.destination) {
  //     return
  //   }
  //   const sourceIndex = source.index
  //   const destinationIndex = destination.index
  //   outFitsContainer.currentOutFit.isOutFitsEdited = true
  //   if (!currentOutFit.listItems) {
  //     currentOutFit['listItems'] = []
  //   }
  //   if (source.droppableId === 'wishlist-droppable') {
  //     const { allWishListProducts } = outFitsContainer
  //     const movedItem = allWishListProducts.find(
  //       product => product.id === draggableId.split('-')[0]
  //     )
  //     const isProdAvailableInOrder = outFitsContainer.orderListItems[
  //       this.activeCategory
  //     ].some(order => order.key === movedItem.key)
  //     const { properties, ...remainingProps } = movedItem
  //     const newOutfit = {
  //       ...remainingProps,
  //       properties: {
  //         ...properties,
  //         ...(!isProdAvailableInOrder && { outFitFrom: 'wishlist' }),
  //       },
  //     }
  //     const editeditem = Object.assign(newOutfit, {
  //       outFitItem: 'new',
  //     })
  //     const isItemAlreadyAvailable =
  //       currentOutFit.listItems.some(
  //         listItem => listItem.key === editeditem.key
  //       ) || false
  //     if (!isArray(currentOutFit?.listItems)) {
  //       outFitsContainer.currentOutFit['listItems'] = [
  //         {
  //           ...editeditem,
  //         },
  //       ]
  //     } else {
  //       if (!isItemAlreadyAvailable)
  //         currentOutFit.listItems.splice(destinationIndex, 0, editeditem)
  //     }
  //     !isItemAlreadyAvailable &&
  //       this.updateEditedProducts(destination, editeditem)
  //   } else if (source.droppableId === 'order-droppable') {
  //     const orderListItems = outFitsContainer.orderListItems
  //     const movedItem = orderListItems[this.activeCategory].find(
  //       product => product.id === draggableId.split('-')[0]
  //     )
  //     const editeditem = Object.assign(movedItem, {
  //       outFitItem: 'new',
  //       outFitFrom: 'order',
  //     })
  //     const isItemAlreadyAvailable =
  //       currentOutFit.listItems.some(
  //         listItem => listItem.key === editeditem.key
  //       ) || false
  //     if (!isArray(currentOutFit?.listItems)) {
  //       outFitsContainer.currentOutFit['listItems'] = [
  //         {
  //           ...editeditem,
  //         },
  //       ]
  //     } else {
  //       if (!isItemAlreadyAvailable)
  //         currentOutFit.listItems.splice(destinationIndex, 0, editeditem)
  //     }
  //     !isItemAlreadyAvailable &&
  //       this.updateEditedProducts(destination, editeditem)
  //   } else if (source.droppableId.includes('outfit-droppable')) {
  //     const currentRowItem =
  //       source.droppableId === 'outfit-droppable-0'
  //         ? this.row1Products
  //         : this.row2Products
  //     currentOutFit.listItems.splice(
  //       destinationIndex,
  //       0,
  //       ...currentOutFit.listItems.splice(sourceIndex, 1)
  //     )
  //     this.updateEditedProducts(
  //       destination,
  //       ...currentRowItem.splice(sourceIndex, 1)
  //     )
  //   } else if (source.droppableId.includes('recommend-droppable')) {
  //     const recommendedProduct = outFitsContainer.recommendedProducts
  //     const movedItem = recommendedProduct.find(
  //       product => product.id === draggableId.split('-')[0]
  //     )
  //     const editeditem = Object.assign(movedItem, {
  //       outFitItem: 'new',
  //       outFitFrom: 'recommend',
  //     })
  //     const isItemAlreadyAvailable =
  //       currentOutFit.listItems.some(
  //         listItem => listItem.key === editeditem.key
  //       ) || false
  //     if (!isArray(currentOutFit?.listItems)) {
  //       outFitsContainer.currentOutFit['listItems'] = [
  //         {
  //           ...editeditem,
  //         },
  //       ]
  //     } else {
  //       if (!isItemAlreadyAvailable)
  //         currentOutFit.listItems.splice(destinationIndex, 0, editeditem)
  //     }
  //     !isItemAlreadyAvailable &&
  //       this.updateEditedProducts(destination, editeditem)
  //   }
  //   if (currentOutFit.listItems.length === 2) {
  //     overlayState.toggleLoader()
  //     const recommendedProduct = ''
  //     overlayState.toggleLoader()
  //     if (recommendedProduct) {
  //       if (outFitsContainer.getSingleRecommendation) {
  //         outFitsContainer.currentOutFit.listItems.push(recommendedProduct)
  //         this.row1Products.push(recommendedProduct)
  //       }
  //     }
  //   }
  // }

  onSubmitDate = async value => {
    this.selectedDate = value
    const monthValue = searchDate().find(date => date.key === value).value
    if (monthValue === 0) {
      outFitsContainer.getOrders()
    } else {
      const startDate = Math.floor(
        new Date().getTime() - monthValue * 31 * 24 * 60 * 60 * 1000
      )
      const endDate = Math.floor(new Date().getTime())
      const filterDateString =
        'createdTime<' + endDate + ',createdTime>' + startDate
      outFitsContainer.getOrders({ filterParams: filterDateString })
    }
  }

  clickHandler = index => {
    outFitsContainer.currentSlideIndex = index
    outFitsContainer.setCurrentOutFitByIndex(index)
    const type =
      outFitsContainer.currentOutFit?.outfitType !== 'new'
        ? 'outFit'
        : 'newOutFit'
    outFitsContainer.setListType(type, false)
  }

  renderMenuItem = (item, index) => {
    return (
      <Dropdown.Item
        as="button"
        key={index}
        value={item.value}
        eventKey={item.key}
        data-testid="qa-dropdown-lists">
        {item.key}
      </Dropdown.Item>
    )
  }

  handleShare = (listId, name, accessType) => {
    listShareState.setListDetails({ listId, name, accessType }, true)
    listShareState.toggleModalVisiblity()
  }

  render() {
    // const { currentOutFit, listShowType, currentSlideIndex } = outFitsContainer
    const { currentOutFit, listShowType } = outFitsContainer
    const isNewItemsAvailable = (currentOutFit?.listItems || []).some(
      item => (item.outFitItem || '') === 'new'
    )
    const isOutFitsEdited = isNewItemsAvailable || currentOutFit.isNameEdited

    return (
      <>
        <Modal
          className="outfit-modal"
          show={outfitModalState.isModalVisible}
          onEntered={this.onModalMounted}
          onExited={this.handleClosePhase}
          onHide={outfitModalState.toggleModal}>
          <Overlay
            textToShow={i18nTranslate(
              'outfit.loadingRecomm',
              'Loading Your Recommendations'
            )}
          />
          <Modal.Header closeButton>
            <Modal.Title className="d-flex justify-content-center w-100">
              {listShowType === 'outFit' &&
              !(outFitsContainer.currentOutFit?.isNameEdited || false) ? (
                <h1
                  onClick={() => {
                    if (listShowType === 'outFit') {
                      outFitsContainer.currentOutFit.isNameEdited = true
                    }
                  }}>
                  {currentOutFit.name}
                </h1>
              ) : (
                <Form.Control
                  className="w-auto outfit-name-input text-center"
                  onBlur={event => (currentOutFit['name'] = event.target.value)}
                  ref={element => (this.newOutFitInput = element)}
                  defaultValue={
                    currentOutFit.name ||
                    i18nTranslate('outfit.new', 'New Outfit')
                  }
                  autoFocus
                  onFocus={event => event.target.select()}
                />
              )}
            </Modal.Title>
          </Modal.Header>
          <Row noGutters className="model-wrapper pb-3">
            {/* <DragDropContext onDragEnd={this.onDragEnd}> */}
            <Col as="aside" className="catagory-wrapper flex-grow-0">
              <div className="filter-orders-cont">
                <Dropdown
                  className="text-center py-2"
                  onSelect={this.onSubmitDate}>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <span className="order-search-dropdown-text text-capitalize">
                      {this.selectedDate}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {searchDate().map((item, index) => {
                      return this.renderMenuItem(item, index)
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Tab.Container
                onSelect={activeKey => (this.activeCategory = activeKey)}
                id="left-tabs-example"
                defaultActiveKey={categoryList[0].categoryId}>
                <Row noGutters className="flex-nowrap">
                  <Col className="flex-grow-0">
                    <Nav variant="pills" className="flex-column">
                      {categoryList.map(this.renderTabIcon)}
                    </Nav>
                  </Col>
                  <Col
                    key="column"
                    className="flex-grow-0 product-list-container px-2">
                    <Tab.Content>
                      <h5 className="text-center my-3">
                        {i18nTranslate('outfit.orders', 'Orders')}
                      </h5>
                      {categoryList.map(this.renderOrders)}
                      <h5 className="text-center my-3">
                        {i18nTranslate('outfit.myWishList', 'Wishlist')}
                      </h5>
                      {categoryList.map(this.renderTabPane)}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
            <Col as="section" className="outfit-section px-3">
              {/* {listShowType === 'outFit' && (
                  <div className="d-flex justify-content-end my-2">
                    <Button
                      variant="primary"
                      onClick={async () => {
                        await outFitsContainer.addOutfitToCart()
                        outfitModalState.setModalVisibility(false)
                      }}>
                      <Trans i18nKey="outfit.addToCart">
                        Add Outfit to cart
                      </Trans>
                    </Button>
                  </div>
                )} */}
              <Row noGutters className="py-2">
                {/* <span
                className={`all-outfits-icon outfit-secondary-btn mr-2 ${
                  ['outFit', 'newOutFit'].includes(listShowType)
                    ? 'bg-primary'
                    : ''
                }`}
                onClick={() => {
                  outFitsContainer.setListType('outFit')
                  if (Object.keys(currentOutFit).length > 0) {
                    outFitsContainer.setCurrentOutFitByIndex(0)
                  }
                }}
              />
              <div
                className={`p-0 border-0 outfit-secondary-text ${
                  listShowType === 'allOutFit' ? 'text-primary' : ''
                }`}
                onClick={() => {
                  outFitsContainer.setListType('allOutFit')
                  outFitsContainer.currentOutFit = {}
                }}>
                <GridIcon size="2rem" />
              </div> */}
                {/* <h2
                className="flex-grow-1 d-flex justify-content-center"
                onClick={() => {
                  if (listShowType === 'outFit') {
                    outFitsContainer.currentOutFit.isNameEdited = true
                  }
                }}>
                {listShowType === 'allOutFit' ? (
                  'Outfits'
                ) : listShowType === 'outFit' &&
                  !get(
                    outFitsContainer.currentOutFit,
                    'isNameEdited',
                    false
                  ) ? (
                  currentOutFit.name
                ) : (
                  <Form.Control
                    className="border-0 w-auto outfit-name-input text-center"
                    onBlur={event =>
                      (currentOutFit['name'] = event.target.value)
                    }
                    ref={element => (this.newOutFitInput = element)}
                    defaultValue={
                      currentOutFit.name || i18nTranslate('outfit.new', 'New Outfit')
                    }
                    autoFocus
                    onFocus={event => event.target.select()}
                  />
                )}
              </h2> */}
                {/* <div
                className="p-0 border-0 text-primary"
                onClick={() => {
                  if (outFitsContainer.listShowType === 'newOutFit') {
                    outFitsContainer.setListType('allOutFit')
                  } else {
                    outFitsContainer.setNewOutfit(index => {
                      this.outfitSlider.slickGoTo(index)
                    })
                  }
                }}> */}
                {/* {outFitsContainer.listShowType === 'newOutFit' ? (
                  <IoIosCloseCircle size="2rem" />
                ) : (
                  <PlusIcon size="2rem" />
                )} */}
                {/* </div> */}
              </Row>
              {outFitsContainer.listShowType === 'allOutFit' ? (
                <OutFitsList />
              ) : (
                // <ReactSlick
                //   ref={slideRef => (this.outfitSlider = slideRef)}
                //   arrows={true}
                //   dots={false}
                //   draggable={false}
                //   infinite={false}
                //   speed={500}
                //   adaptiveHeight={true}
                //   slidesToShow={1}
                //   slidesToScroll={1}
                //   initialSlide={currentSlideIndex}
                //   // https://github.com/akiran/react-slick/issues/1262
                //   // afterChange={this.clickHandler}
                //   beforeChange={(current, next) =>
                //     setTimeout(() => this.clickHandler(next), 500)
                //   }
                //   nextArrow={<IoIosArrowForward size="2rem" />}
                //   prevArrow={<IoIosArrowBack size="2rem" />}>
                // {outFitsContainer.outFitsList.map(outFit => {
                // return (
                // <React.Fragment key={outFit.id}>
                // <Droppable
                //   isDropDisabled={false}
                //   // droppableId={`outfit-droppable-${outFit.id}`}
                //   droppableId={`outfit-droppable-${currentOutFit.id}`}
                //   type="outfit-drop">
                //   {(provided, snapshot) => (
                //     <div
                //       {...provided.droppableProps}
                //       ref={provided.innerRef}
                //       className={`outfit-products m-auto ${
                //         outFitsContainer.listShowType
                //       }`}>
                <Outfits
                  // outfitItem={outFit}
                  updateRowItems={this.updateRowItems}
                  isOutFitView={true}
                  row1Products={this.row1Products}
                  row2Products={this.row2Products}
                  className={`outfit-view ${
                    currentOutFit.isOutFitsEdited ? 'outfit-edited' : ''
                  }`}
                  // className={`outfit-view ${
                  //   currentOutFit.isOutFitsEdited &&
                  //   currentOutFit.id === outFit.id
                  //     ? 'outfit-edited'
                  //     : ''
                  // }`}
                />
                //       {provided.placeholder}
                //     </div>
                //   )}
                // </Droppable>
                // </React.Fragment>
              )}
              {/* )} */}
              {/* </ReactSlick> */}
              {/* )} */}
              <div className="d-flex mt-3 text-right">
                {listShowType === 'outFit' && (
                  <div
                    className={`social-share-icons-cont d-flex ${
                      listShowType === 'outFit' && isOutFitsEdited
                        ? 'justify-content-end'
                        : 'justify-content-center'
                    } flex-grow-1`}>
                    <SocialShare
                      name={currentOutFit.name}
                      shareLink={`${pageNames.SharedList}?listid=${currentOutFit.id}`}
                    />
                    <MdEmail
                      className="mail-svg w-100 h-100"
                      onClick={() =>
                        this.handleShare(
                          currentOutFit.id,
                          currentOutFit.name,
                          currentOutFit.accessType
                        )
                      }
                      aria-label={i18nTranslate('icons.mail', 'mail icon', {
                        nameSpace: 'ssr-resource',
                      })}
                    />
                  </div>
                )}
                {((listShowType === 'outFit' && isOutFitsEdited) ||
                  (listShowType === 'newOutFit' && isNewItemsAvailable)) && (
                  <div className="d-flex justify-content-end flex-grow-1">
                    <Button
                      variant="primary"
                      className="py-2 px-5"
                      onClick={this.handleSaveOutfit}>
                      {i18nTranslate('outfit.save', 'Save')}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
            {outFitsContainer.recommendedProducts.length > 0 && (
              <Col className="recommendation-section pt-5">
                <RecommendedSection
                  renderImages={this.renderImages}
                  recommendedProducts={outFitsContainer.recommendedProducts}
                  categorySelected={this.categorySelected}
                  changeCategory={this.changeCategory}
                />
              </Col>
            )}
            {/* </DragDropContext> */}
            <ListShare />
          </Row>
        </Modal>
        <div id="portal" ref={element => (this.portal = element)} />
      </>
    )
  }
}

export default OutFitsModel
export { OutFitsModel }
