import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Row, Col, Modal } from 'react-bootstrap'
import { pageNames } from 'src/routes/pathParams'
import {
  cartContainer,
  nuskinSubscriptionContainer,
  customerContainer,
  storeContainer,
} from 'src/models'
// import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { ProductEarnAndRedeemText } from 'src/views/components/ProductComponents/ProductSkuDetails/ProductEarnAndRedeemText'
import {
  ProductImageWithFavorites,
  ProductDetailQuickView,
  ProductItemId,
} from 'src/views/components'
import { quickViewState } from './state'
import { SubscribeAndSaveText } from '../ProductComponents/ProductSkuDetails/SubscribeAndSaveText'
import { RetailPrice } from 'src/views/components/ProductComponents/ProductSkuDetails/RetailPrice'
import {
  ProductPrice,
  ProductShare,
  ProductTitle,
  ProductFavoritesWithText,
  V2ProductSVCV,
} from '../ProductComponents'
import { trackQuickViewAddToCart, getLiveEventStatus } from 'src/utils'
import { APPConfig } from 'config/appConfig'
import { productDeps } from 'src/deps'
import { PDPUnitPrice } from 'src/views/components/Price'
import './styles.scss'

@observer
class QuickView extends React.Component {
  nameSpace = 'ssr-resource'
  componentWillUnmount() {
    if (quickViewState.isQuickViewOpen) {
      this.closeQuickView()
    }
  }

  handleQuickView = event => {
    event.preventDefault()
    const productId =
      this.props?.productId || this.props?.product?.identifier || ''
    const categoryId = this.props?.categoryId || ''
    quickViewState.getProduct({ productId, categoryId })
  }

  handleSkuSelection = product => {
    quickViewState.updateSku(product)
  }

  handleQuantityChange = count => {
    quickViewState.productCount = count
  }

  getAddToBagParams = () => {
    const {
      skuId = '',
      productId = '',
      selectedSku,
    } = quickViewState?.product || {}
    // @##backorder_logic_cart
    // for quickview, there is no bundle logic for quickview
    const isBackOrdered =
      productDeps.checkBackOrderBasedOnCurrentQuantityForCartFlag(
        selectedSku,
        quickViewState?.productCount
      )
    const categoryId =
      quickViewState?.productResponse?.category?.identifier || ''

    if (skuId) {
      return [
        {
          skuId,
          productId,
          quantity: quickViewState?.productCount,
          categoryId: categoryId,
          isBackOrdered,
        },
      ]
    }
    return []
  }

  handleAddToBag = async () => {
    const cartArray = this.getAddToBagParams()
    const priceForAnlaytics =
      quickViewState.pdpPromotionResponse?.totalValue?.priceAfterDiscount || ''
    if (cartArray) {
      quickViewState.isQuickViewOpen && quickViewState?.toggleQuickView()
      const response = await cartContainer?.addToCart({
        cartArray,
        price: priceForAnlaytics,
      })
      quickViewState.isQuickViewOpen && quickViewState?.toggleQuickView()
      trackQuickViewAddToCart(response)
      return response
    }
  }

  closeQuickView = () => {
    quickViewState?.toggleQuickView()
  }

  renderRetailPrice = product => {
    let profileResponse = customerContainer.profileResponse || {}
    let accounts = profileResponse?.accounts || {}
    let accType = accounts?.type || ''

    return (
      (accType === 'Brand Affiliate' ||
        accType === 'Brand Affiliate - Business Entity' ||
        accType === 'Preferred Customer/Member') && (
        <RetailPrice {...product} isFromQuickViewUnitPrice={true} />
      )
    )
  }

  render() {
    const product = quickViewState?.product || {}
    const productId = product?.identifier || product?.productId || ''
    const slug = product?.productSlug || product?.slug || productId || ''
    const productOrSlugId = slug ? slug : productId
    const categoryId =
      quickViewState?.productResponse?.category?.identifier || ''
    let url = ''
    const skuSlug = quickViewState?.product?.previousSku?.properties?.slug || ''
    const skuId = quickViewState?.product?.previousSku?.identifier || ''
    const hasMoreThanOneSku = (quickViewState?.product?.sku?.length || 1) > 1
    /* @variant_autoselect */
    const variantParameter = skuSlug || skuId || ''
    const shouldAppendVariant = hasMoreThanOneSku && variantParameter
    if (categoryId && shouldAppendVariant) {
      url = `${pageNames.product}/${productOrSlugId}?categoryId=${categoryId}&variant=${variantParameter}`
    } else if (categoryId) {
      url = `${pageNames.product}/${productOrSlugId}?categoryId=${categoryId}`
    } else if (shouldAppendVariant) {
      url = `${pageNames.product}/${productOrSlugId}?variant=${variantParameter}`
    } else {
      url = `${pageNames.product}/${productOrSlugId}`
    }
    const subscribeAndSaveValue = quickViewState.subscribeAndSave || 0
    let displaySubscribeAndSave =
      subscribeAndSaveValue > 0 &&
      !nuskinSubscriptionContainer.displaySubscribeAndSavePrice
        ? true
        : false

    const hideLoyaltyAlways =
      APPConfig?.getAppConfig()?.hideLoyaltyAlways === 'true'
    const hideLoyaltyForUS =
      APPConfig?.getAppConfig()?.hideLoyaltyForUS === 'true'
    const storeIdBasedOnCountry =
      storeContainer?.storeIDValue?.toLowerCase() || ''
    const isUS = storeIdBasedOnCountry === 'us'
    const isToHideLoyaltyForUS = hideLoyaltyForUS && isUS
    const hideLoyaltyText = hideLoyaltyAlways || isToHideLoyaltyForUS
    // const { isFavorite = false } = this.props

    // const defaultSku = catalogDeps.getDefaultSelectedSku(product)
    // const skuName =
    //   defaultSku && defaultSku?.length
    //     ? defaultSku[0]?.properties?.name || ''
    //     : ''

    // let CVPrice = product?.priceFacets?.CV || 0
    // let PVPrice = product?.priceFacets?.PV || 0

    // const type = get(this.props, 'product.type', '')

    // const identifier =
    //   this.props?.productId || this.props?.product?.identifier || ''

    // const categoryId = get(this.props, 'categoryId', '')
    // const skuPrice = this.props?.product?.prices || ''
    // const productOrSKUPrice = this.props?.product?.transactionPrice || skuPrice

    // let skuPrices = []
    // const skuList = product?.sku || []
    // if (skuList && skuList?.length) {
    //   skuPrices = skuList.filter(item => {
    //     return item.default
    //   })
    // }

    // if (isFavorite) {
    //   CVPrice = CVPrice === 0 ? skuPrices?.[0]?.priceFacets?.CV || 0 : CVPrice
    //   PVPrice = PVPrice === 0 ? skuPrices?.[0]?.priceFacets?.PV || 0 : PVPrice
    // }

    // let pathName = ''
    // if (IS_BROWSER) {
    //   pathName = this.props?.location?.pathname || window?.location?.pathname
    // }
    // const skuLevelPromotion = this.props?.product?.promotion || []
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    const enableLiveEvent = getLiveEventStatus() === 'true'
    return (
      <Modal
        id="pdp-content"
        show={quickViewState?.isQuickViewOpen}
        dialogClassName="modal-90w quick-view-dialog"
        onHide={quickViewState?.toggleQuickView}
        className={`quick-view-modal ${
          enableNewPDPStyle ? 'v2-pdp-style' : ''
        }`}>
        <Modal.Header className="border-0 py-0" closeButton />
        <Modal.Body className="p-0">
          <Row noGutters className="position-relative">
            <Col className="px-2" sm={12} md={6}>
              <ProductImageWithFavorites
                product={quickViewState?.product}
                categoryId={categoryId}
                isFromQuickView={true}
              />
            </Col>
            <Col className="px-2" sm={12} lg={6} md={6}>
              <div className="product-title-wrapper">
                <ProductTitle {...product} />
              </div>
              <Row noGutters className="itemid-and-fav-wrapper">
                <ProductItemId {...product} />
                {enableNewPDPStyle && !enableLiveEvent && (
                  <span>
                    <ProductFavoritesWithText
                      product={product || {}}
                      categoryId={this.props.categoryId || ''}
                      location={this.props.location || {}}
                      history={this.props.history || {}}
                      className="product-fav-with-text-wrapper bg-transparent border-0 text-dark shadow-none'"
                    />
                  </span>
                )}
              </Row>
              {enableNewPDPStyle && <V2ProductSVCV product={product || {}} />}

              {/* @##pdp_price_QUICKVIEW unit price */}
              {/* only for quickview */}
              {enableNewPDPStyle && (
                <PDPUnitPrice
                  {...this.props}
                  product={product}
                  isFromQuickViewUnitPrice={true}
                />
              )}
              {!enableNewPDPStyle && (
                <ProductPrice
                  {...product}
                  isFromQuickViewUnitPrice={true}
                  showStaticUnitPriceForQuickView={true}
                />
              )}
              {!enableNewPDPStyle && this.renderRetailPrice(product)}
              {displaySubscribeAndSave && (
                <SubscribeAndSaveText
                  subscribeAndSave={subscribeAndSaveValue}
                  {...product}
                />
              )}
              {!hideLoyaltyText && <ProductEarnAndRedeemText {...product} />}

              <ProductDetailQuickView
                {...this.props}
                handleSkuSelection={this.handleSkuSelection}
                handleQuantityChange={this.handleQuantityChange}
                handleQuantityChangePromotion={
                  // @##makenewpromo
                  quickViewState.makePromotionCallsForNormalProductQuickViewQtyChange
                }
                product={quickViewState?.product}
                productCount={quickViewState?.productCount}
                handleAddToBag={this.handleAddToBag}
                categoryId={categoryId}
              />
              <ProductShare {...product} className="mt-4" />
              <div className="pt-3">
                <Link to={url}>
                  {i18nTranslate(
                    'plp.fullInfo',
                    'View Full Product Information',
                    { nameSpace: this.nameSpace }
                  )}
                </Link>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

export { QuickView }
export default QuickView
