import React from 'react'

const NuskinAccountIcon = props => (
  <svg
    {...props}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.5C18.0751 23.5 23 18.5751 23 12.5C23 6.42487 18.0751 1.5 12 1.5C5.92487 1.5 1 6.42487 1 12.5C1 18.5751 5.92487 23.5 12 23.5Z"
      stroke="#252525"
      strokeWidth="2"
    />
    <path
      d="M19 20.5C18.9425 19.8229 19 18.0669 17.3793 17.7226C14.7126 17.1144 13.931 16.9078 14.1034 14.7272C15.8046 13.5796 15.7816 11.5252 15.7471 9.24139C15.7471 7.17508 14.0695 5.5 12 5.5C9.93052 5.5 8.25287 7.17508 8.25287 9.24139C8.25287 11.5367 8.25287 13.637 9.89655 14.7272C10.0575 16.9078 9.28736 17.1144 6.62069 17.7226C5 18.0669 5.05747 19.8229 5 20.5"
      stroke="#252525"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export { NuskinAccountIcon }
export default NuskinAccountIcon
