import React from 'react'
import { Button } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { cartContainer } from 'src/models'
import {
  CheckoutProductBlock,
  getProductDetailsForCart,
  renderCartProductsHeader,
  toastState,
} from 'src/views/components'
import { MultipleShippingAddressForm } from './MultipleShippingAddressForm'

class MultipleShipping extends React.Component {
  itemLevelAddress = {}
  newAddressList = {}

  addNewAddressList = props => {
    console.log(props)
  }

  handleContinue = () => {
    const isValidArray = Object.values(this.newAddressList).map(
      addressValidate => {
        if (addressValidate.addressType === 'savedAddress') {
          return true
        }
        const fieldTouched = Object.assign({}, addressValidate.values)
        Object.keys(fieldTouched).forEach(key => (fieldTouched[key] = true))
        addressValidate.setTouched(fieldTouched)
        addressValidate.validateForm(addressValidate.values)
        return addressValidate.isValid
      }
    )
    const isValid = isValidArray.every(item => item === true)
    const cartProductsLength = (cartContainer?.cartResponse?.items || []).length

    if (
      Object.keys(this.itemLevelAddress).length === cartProductsLength &&
      isValid
    ) {
      this.props.onContinue()
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'checkout.shippingError',
          'Please provide shipping address for all items'
        )
      )
    }
  }

  renderProductsWithAddress = cartProduct => {
    const productDetails = getProductDetailsForCart(cartProduct, {
      isCheckout: true,
      enableQuantityEdit: false,
      enableButtons: false,
    })
    const cartAddress = cartProduct?.deliveryDetails?.address || ''
    if (cartAddress && cartAddress.addressLine1) {
      this.itemLevelAddress[cartProduct.itemId] = cartAddress
    }
    return (
      <React.Fragment key={cartProduct.itemId}>
        {renderCartProductsHeader()}
        <CheckoutProductBlock
          key={`checkout-${cartProduct.itemId}`}
          className="mb-3"
          {...productDetails}
        />
        <MultipleShippingAddressForm
          addressList={this.props.addressList}
          addressToSelectOrPrefill={cartAddress}
          shippingMethods={cartProduct?.deliveryDetails?.methods}
          cartItemId={cartProduct.itemId}
          // addNewAddressList={this.addNewAddressList}
          newAddressList={this.newAddressList}
        />
      </React.Fragment>
    )
  }

  render() {
    const cartProducts = cartContainer?.cartResponse?.items || []
    return (
      <div className="d-flex flex-column">
        {cartProducts.map(this.renderProductsWithAddress)}
        <Button
          className="shipping-save-changes px-5 py-2 align-self-end"
          data-testid="qa-ship-continue"
          onClick={this.handleContinue}>
          {i18nTranslate('checkoutAddress.submit', 'Continue')}
        </Button>
      </div>
    )
  }
}

export { MultipleShipping }
