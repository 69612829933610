import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Row } from 'react-bootstrap'
import { AccordionBlockPDP } from 'src/views/components/AccordionBlockPDP'
import { AccordionBlockFAQ } from 'src/views/components/AccordionBlockFAQ'
import { getProductPDPTitle } from 'src/utils/commonUtils'
import { APPConfig } from 'config/appConfig'
import { Container } from 'react-bootstrap'
import './styles.scss'

class Productfaq extends React.Component {
  nameSpace = 'ssr-resource'
  renderTitle = () => {
    const { isAccordionType = false } = this.props
    const faqLable = getProductPDPTitle(
      this.props.product?.tempProperties,
      'faqs'
    )
    return (
      <Row
        className={`about-title ${
          isAccordionType ? 'text-uppercase' : 'border-light-grey'
        }`}
        noGutters>
        {faqLable ||
          i18nTranslate(
            'pdp.frequentlyAskedQuestions',
            'Frequently Asked Questions',
            { nameSpace: this.nameSpace }
          )}
      </Row>
    )
  }

  render() {
    const { product } = this.props || {}
    const faqs = product.faqs || '[]'
    let faqdet = []
    try {
      faqdet = JSON.parse(faqs) || []
    } catch (e) {
      faqdet = []
      console.log('Data parse error::: Productfaq' + e)
    }
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'

    return (
      <div className="product-accordion-wrapper ">
        <AccordionBlockPDP
          title={this.renderTitle()}
          isDefaultOpen={false}
          className="accordion-description"
          dataTestId="qa-pdp-product-faq">
          <Container className="pdp-accordion-content-container">
            {faqdet.length > 0 &&
              faqdet.map((faqsdet, idx) => {
                return (
                  <AccordionBlockFAQ
                    key={`accordionblockfag-${idx}`}
                    title={faqsdet?.question}
                    isDefaultOpen={false}
                    className="accordion-description paragraph-m">
                    {enableNewPDPStyle ? (
                      <ul className="v2-faq-list">
                        <span>{faqsdet?.answers}</span>
                      </ul>
                    ) : (
                      <ul className="faq-list paragraph-s">
                        <li>{faqsdet?.answers}</li>
                      </ul>
                    )}
                  </AccordionBlockFAQ>
                )
              })}
          </Container>
        </AccordionBlockPDP>
      </div>
    )
  }
}

export { Productfaq }
export default Productfaq
