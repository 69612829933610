import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Button, Col, Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
// import get from 'lodash/get'
import {
  i18nTranslate,
  getLiveEventStatus,
  isExpressCheckout,
  getCheckoutGTMPageData,
} from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import {
  IS_BROWSER,
  trackGTMEvents,
  getCheckoutProduct,
  getCheckoutProductV2,
  trackErrorInInstana,
  getBrowserType,
  deleteFromLocalStorage,
  getGuestSignupOrigin,
  convertToBoolean,
} from 'src/utils'
import {
  cartContainer,
  customerContainer,
  checkoutContainer,
  loyaltyContainer,
  paypalContainer,
  venmoContainer,
  sessionContainer,
  googlePayContainer,
  applepayContainer,
  klarnaContainer,
  accountsContainer,
} from 'src/models'
import TagManager from 'react-gtm-module'
import { toastState, overlayState } from 'src/views/components'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'
import { alertMessageKeys } from 'src/views/components/CartBlock/fixture'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import {
  EVENT_TERMS_URL,
  EVENT_REFUND_POLICY_URL,
  APPConfig,
} from 'config/appConfig'
import './styles.scss'

@observer
class SubmitOrder extends Component {
  static defaultProps = {
    enableSubmit: true,
  }
  @observable selectedDate = -1
  @observable endDate = -1
  @observable isValidDate = true
  @observable termsCheckboxChecked = false
  @observable submitDisableInfo = { isDisabled: false, message: '' }
  klarnaTransactionStatusRefreshInterval
  klarnaTransactionTimeOut

  componentDidUpdate() {
    //Paypal braintree
    if (
      this.props.enableSubmit &&
      paypalContainer.paypalInitiate &&
      typeof braintree !== 'undefined' &&
      braintree?.client &&
      !paypalContainer.paypalClientCreated
    ) {
      braintree.client?.create(
        {
          authorization: this.props?.paypalToken,
        },
        function (clientErr, clientInstance) {
          paypalContainer.createPayPalCheckoutComponent(
            clientErr,
            clientInstance
          )
        }
      )
      paypalContainer.paypalClientCreated = true
    }
    paypalContainer.disablePaypalSDKButton()
    //Venmo braintree
    if (
      this.props.enableSubmit &&
      venmoContainer.venmoInitiate &&
      typeof braintree !== 'undefined' &&
      braintree?.client &&
      !venmoContainer.venmoClientCreated
    ) {
      console.log('** Create Venmo Instance')
      if (!this.props?.venmoToken && isExpressCheckout()) {
        this.props?.handleVenmoInitiate()
        return
      }
      braintree.client?.create(
        {
          authorization: this.props?.venmoToken,
        },
        function (clientErr, clientInstance) {
          console.log('** inside Venmo Function')

          venmoContainer.createVenmoCheckoutComponent(clientErr, clientInstance)
        }
      )
      // Added to prevent re-trigger of Venmo Client
      venmoContainer.venmoClientCreated = true
    }

    // Google Pay Client Initialization
    this.initializeGooglePayClient()

    //ApplePay BrainTree integration
    this.initApplePayClient()
  }

  componentWillUnmount() {
    klarnaContainer.closeKlarnaWindow()
    klarnaContainer.clearKlarnaTransactionStatusTimer()
    klarnaContainer.isCloseWindowFromCallback = false
    klarnaContainer.klarnaBrowserPopupBlock = false
  }

  initApplePayClient = () => {
    if (
      this.props?.enableSubmit &&
      applepayContainer.applepayInitiate &&
      typeof braintree !== 'undefined' &&
      braintree?.client &&
      !applepayContainer.applepayClientCreated
    ) {
      braintree.client?.create(
        {
          authorization:
            this.props?.applepayToken || applepayContainer.applepayToken,
        },
        function (clientErr, clientInstance) {
          applepayContainer.initializeApplePayPayment(clientErr, clientInstance)
        }
      )
      // Added to prevent re-trigger the Client
      applepayContainer.applepayClientCreated = true
    }
  }

  /**
   * Initializes the Google Pay client for payment processing.
   */
  initializeGooglePayClient = () => {
    let googlePayProps = { isFromViewCart: false, isFromCheckout: true }
    if (
      this.props?.enableSubmit &&
      googlePayContainer.isToInitiateGooglePayPayment &&
      typeof braintree !== 'undefined' &&
      braintree?.client &&
      !googlePayContainer.googlePayClientCreated &&
      (this.props?.googlePayToken !== '' ||
        googlePayContainer?.googlePayPaymentToken !== '')
    ) {
      braintree.client?.create(
        {
          authorization:
            this.props?.googlePayToken ||
            googlePayContainer?.googlePayPaymentToken,
        },
        function (clientErr, clientInstance) {
          googlePayContainer.initiateGooglePayPayment(
            clientErr,
            clientInstance,
            googlePayProps
          )
        }
      )
      // Added to prevent re-trigger the Client
      googlePayContainer.googlePayClientCreated = true
    }
  }

  handleReviewOrder = () => {
    return checkoutContainer.reviewOrder()
  }

  handleDateChange = (date, event) => {
    event.preventDefault()
    this.selectedDate = date ? date.getTime() : -1
  }

  handleMaxDate = () => {
    const maxBackDatedOrder = new Date().setDate(new Date().getDate())
    this.endDate = maxBackDatedOrder
    return maxBackDatedOrder
  }

  //@todo need to handle if month is specific
  // handleMinDate = () => {
  //   const date = new Date()
  //   date.setDate(date.getDate() - 1)
  //   const monthTimeStamp = date.setMonth(date.getMonth() - 2)
  //   this.startDate = monthTimeStamp
  //   return monthTimeStamp
  // }

  handleBlur = event => {
    event.preventDefault()
    const currentSelectedDate = event.target.value
    const currentTimeStamp =
      currentSelectedDate && new Date(currentSelectedDate).getTime()
    if (currentTimeStamp <= this.endDate) {
      this.isValidDate = true
      this.selectedDate = currentTimeStamp
    } else {
      this.selectedDate = -1
      this.isValidDate = false
    }
  }

  /**
   * @description Condition to Enable/Disable Paypal Submit Order Button
   */
  isToDisablePaypalSubmitButton = () => {
    return paypalContainer.submitOrderEnable == false
      ? true
      : !this.props?.enableSubmit
      ? true
      : false
  }

  /**
   * @description Condition to Enable/Disable Venmo Submit Order Button
   */
  isToDisableVenmoSubmitButton = () => {
    return venmoContainer.submitOrderEnable == false
      ? true
      : !this.props?.enableSubmit
      ? true
      : false
  }

  /**
   * Determines whether the Google Pay submit button should be disabled.
   * @returns {boolean} - True if the Google Pay submit button should be disabled, false otherwise.
   */
  isToDisableGooglePaySubmitButton = () => {
    return !googlePayContainer.isToEnableSubmitOrder
      ? true
      : !this.props?.enableSubmit
  }

  /**
   * @description Condition to Enable/Disable ApplePay Submit Order Button
   */
  isToDisableApplePaySubmitButton = () => {
    return !applepayContainer.submitOrderEnable
      ? true
      : !this.props?.enableSubmit
  }

  handleLoading(isLoading) {
    overlayState.toggleLoader()
    if (isLoading) document.body.classList.add('no-scroll')
    else document.body.classList.remove('no-scroll')
  }

  /**
   * @description Initiates Alipay checkout flow.
   * Checks for valid Alipay response and redirects to Alipay checkout URL if successful.
   * Handles error cases by reloading page or closing Alipay checkout overlay.
   * Logs user and browser details for debugging.
   */
  handleAlipaySubmitOrder = async () => {
    const userId = customerContainer?.userId || ''
    const paymentType =
      cartContainer?.cartResponse?.payments
        ?.map(item => {
          return item?.type || ''
        })
        ?.join(',') || ''
    const browserType = getBrowserType()
    trackErrorInInstana({
      errorReport: `Alipay User and Browser Details - Before Alipay Initiate API - UserId: ${userId}`,
      errorData: {
        browserName: browserType?.name || '',
        browserVersion: browserType?.version || '',
        cartType: cartContainer?.cartResponse?.cartType || '',
        paymentType: paymentType || '',
      },
    })
    customerContainer?.isRegisterUser &&
      (await sessionContainer.refreshSession())
    await checkoutContainer.addAlipayInitiate(window?.location?.href)
    if (
      checkoutContainer.alipayResponse.result === 'success' &&
      checkoutContainer?.alipayResponse?.result != undefined &&
      typeof window !== 'undefined'
    ) {
      window.location.replace(`${checkoutContainer.alipayResponse.url}`)
      this.props.closeAlipayInitiate()
    } else {
      this.handleLoading(false)
      if (typeof window !== 'undefined' && IS_BROWSER) {
        window?.location?.reload()
      }
    }
  }
  /**
   * @description Initiates Klarna payment by calling initiateKlarnaPayment API.
   * Tracks errors using Instana.
   * On success, redirects to Klarna payment URL.
   * On failure, reloads page.
   */
  handleKlarnaSubmitOrder = async () => {
    const userId = customerContainer?.userId || ''
    const paymentType =
      cartContainer?.cartResponse?.payments
        ?.map(item => {
          return item?.type || ''
        })
        ?.join(',') || ''
    const browserType = getBrowserType()
    trackErrorInInstana({
      errorReport: `Klarna User and Browser Details - Before Klarna Initiate API - UserId: ${userId}`,
      errorData: {
        browserName: browserType?.name || '',
        browserVersion: browserType?.version || '',
        cartType: cartContainer?.cartResponse?.cartType || '',
        paymentType: paymentType || '',
      },
    })

    klarnaContainer.handleKlarnaSubmitOrderV2({
      submitOrderBtnProps: this.props,
    })
  }

  /**
   * @description Initiates global payment and redirects to payment provider.
   *
   * Checks if user is registered and refreshes session.
   * Calls checkoutContainer.initiateGlobalPayment method.
   * On success, tracks event and redirects to payment provider URL.
   * On failure, tracks event, sets checkoutContainer.isToInitiateGlobalPayment
   * to false, and sets checkoutContainer.activePath back to 'payment'.
   */
  handleGlobalPaymentSubmitOrder = async () => {
    customerContainer?.isRegisterUser &&
      (await sessionContainer.refreshSession())
    const domain = window?.location?.href || ''
    const globalPaymentInitiateResponse =
      await checkoutContainer.initiateGlobalPayment(domain)
    if (
      checkoutContainer.isSuccessResponse(globalPaymentInitiateResponse) &&
      typeof window !== 'undefined'
    ) {
      trackErrorInInstana({
        errorReport: 'GPS Initiate API - Success',
        errorData: globalPaymentInitiateResponse,
      })
      const globalPaymentRedirectionURL =
        globalPaymentInitiateResponse?.redirectUrl || ''
      window.location.replace(`${globalPaymentRedirectionURL}`)
      checkoutContainer.isToInitiateGlobalPayment = false
    } else {
      trackErrorInInstana({
        errorReport: 'GPS Initiate API - Failure',
        errorData: globalPaymentInitiateResponse,
      })
      this.handleLoading(false)
      checkoutContainer.isToInitiateGlobalPayment = false
      checkoutContainer.activePath = 'payment'
    }
  }

  /**
   * Redirects to the view cart page after a delay.
   * Sets a timeout to first call the viewCart method on the cartContainer,
   * then pushes the viewCart page route.
   */
  redirectToViewCart = () => {
    setTimeout(async () => {
      await cartContainer.viewCart()
      this.props.history?.push(pageNames.viewCart)
    }, 1000)
  }

  /**
   * @description Initiates Submit Order flow.
   * Calls submitOrder API on checkoutContainer.
   * On success, redirects to Order Confirmation Page.
   * On failure, throws proper error message based on errorCode.
   * Logs events and data for debugging.
   */
  handleSubmitOrder = async dateTimeStamp => {
    const errorCodes = [
      'INTL400',
      'INTL401',
      'INTL402',
      'INTL403',
      'INTL404',
      'INTL500',
    ]
    const acceritifyErrorCodes = [
      'ECPREATY0001',
      'ECPOSTATY0001',
      'ECPTC0001',
      'PYMT_ACCERTIFY_CLIENT_ERROR',
      'PYMT_ACCERTIFY_COMMON_ERROR',
      'PYMT_ACCERTIFY_400',
    ]
    if (
      isExpressCheckout() &&
      checkoutContainer.selectedPaymentType === 'CREDITCARD'
    ) {
      checkoutContainer.isToDisableSubmitOrderButton = true
      if (
        !checkoutContainer.autoPaymentSuccess &&
        !checkoutContainer.expressPaymentAdded
      ) {
        checkoutContainer.enableAutoSubmit = true

        const handlePaymentElement =
          document.getElementById('payment-continue-button') ||
          document.getElementById('add-new-pay-save')
        if (handlePaymentElement) {
          handlePaymentElement.click()
          return
        }
      }
    }

    checkoutContainer.autoPaymentSuccess = false
    this.handleLoading(true)

    // let reviewResponse = await this.handleReviewOrder()
    // reviewResponse['items'].forEach(item => {
    //   item &&
    //     item['skus'] &&
    //     item['skus'].map(bundleitem => {
    //       let inventoryProperties = bundleitem.inventoryProperties
    //       if (
    //         (inventoryProperties?.['available'] == false &&
    //           inventoryProperties?.['backOrdered'] == true) ||
    //         (inventoryProperties?.['available'] == false &&
    //           inventoryProperties?.['backOrdered'] == false) ||
    //         (inventoryProperties?.['available'] == false &&
    //           inventoryProperties?.['backOrdered'] == null)
    //       ) {
    //         toastState.setToastMessage(
    //           i18nTranslate(
    //             'checkout.backOrder',
    //             'Sorry, this item is on backorder or out of stock.'
    //           ),
    //           false
    //         )
    //         setTimeout(() => {
    //           window.location.href = window.location.origin + '/viewCart'
    //         }, 1000)
    //       }
    //     })
    // })
    // if (checkoutContainer.isSuccessResponse(reviewResponse)) {
    if (this.props.alipayInitiate && typeof window !== 'undefined') {
      await this.handleAlipaySubmitOrder()
    } else if (
      checkoutContainer.isToInitiateKlarnaPayment &&
      typeof window !== 'undefined'
    ) {
      await this.handleKlarnaSubmitOrder()
    } else if (checkoutContainer.isToInitiateGlobalPayment) {
      await this.handleGlobalPaymentSubmitOrder()
    } else {
      const enableEQFastFollow =
        convertToBoolean(APPConfig?.getAppConfig()?.enableEQFastFollow) || false
      const userTypeSponsorId = accountsContainer?.getUserTypeSponsorId() || ''
      const guestSignupOrigin = getGuestSignupOrigin() || ''
      const submitOrderResponse = await checkoutContainer.submitOrder(
        dateTimeStamp
      )
      const gtmData = cartContainer?.cartResponse || {}
      let orderPlacedDetail = gtmData?.deliveryDetails?.address || ''
      let chekoutShipmentInfo = {
        firstName: orderPlacedDetail?.firstName || '',
        email: orderPlacedDetail?.email || '',
        address:
          orderPlacedDetail?.addressLine1 && orderPlacedDetail?.addressLine2
            ? orderPlacedDetail?.addressLine1 + orderPlacedDetail?.addressLine2
            : '',
        phoneNumber: orderPlacedDetail?.phone || '',
        city:
          orderPlacedDetail?.city && orderPlacedDetail?.phone
            ? orderPlacedDetail?.city + orderPlacedDetail?.phone
            : '',
        state: orderPlacedDetail?.state || '',
        zipCode: orderPlacedDetail?.zip || '',
      }
      let checkoutAccountInfo = {
        name:
          gtmData?.payments?.[0]?.accountInfo?.name &&
          gtmData?.payments?.[0]?.accountInfo?.lastname
            ? gtmData?.payments?.[0]?.accountInfo?.name +
              gtmData?.payments?.[0]?.accountInfo?.lastname
            : '',
        card: gtmData?.payments?.[0]?.accountInfo?.provider || '',
        loginUser: gtmData?.userName || '',
      }
      this.handleLoading(false)
      if (checkoutContainer.isSuccessResponse(submitOrderResponse)) {
        toastState.setToastMessage(
          i18nTranslate(
            'order.submitSuccess',
            'Your order has been submitted.'
          ),
          true
        )
        let isNativeApp = checkIsNativeApp() || false
        if (isNativeApp) {
          this.props.history?.push(
            `${pageNames.orderConfirmation}?isNativeApp=true`
          )
        } else {
          this.props.history?.push(`${pageNames.orderConfirmation}`)
        }
        await cartContainer.viewCart()
        await loyaltyContainer.getLoyaltyPoints(true)
        checkoutContainer.activeSubmitOrderButton = true
        // CX32-7032 - QUALTRICS - After placing order, delete 'shop-cart' value from local storage
        deleteFromLocalStorage('shop-cart')
        trackGTMEvents({
          event: 'CheckoutPurchaseButtonClicked',
          ecommerce: {
            purchase: {
              actionField: {
                id: checkoutContainer.orderId, //transaction id
                affiliation: 'Online Store',
                revenue: gtmData?.properties?.oneTimeItemsTotal || 0, // Total transaction value (incl. tax and shipping)
                tax: gtmData?.value?.overAllTax || '',
                shipping: gtmData?.value?.cartDeliveryCharge || '',
                coupon: gtmData?.promotionDetails?.codes?.[0]?.code || '',
              },
              products: getCheckoutProduct(gtmData?.items),
            },
          },
        })

        const actionField = {
          id: checkoutContainer.orderId,
          memberId: gtmData?.userId || '',
          orderType: 'Standard',
          affiliation: 'Online Store',
          revenue: gtmData?.properties?.oneTimeItemsTotal || 0,
          tax: gtmData?.value?.overAllTax || '',
          shipping: gtmData?.value?.cartDeliveryCharge || '',
          coupon: gtmData?.promotionDetails?.codes?.[0]?.code || '',
          action: 'purchase',
        }
        if (enableEQFastFollow) {
          actionField.sponsor_id = userTypeSponsorId || ''
          actionField.origin = guestSignupOrigin || ''
        }

        trackGTMEvents({
          event: 'checkout-purchase',
          ecommerce: {
            currencyCode: gtmData?.currencyCode || '',
            purchase: {
              actionField: { ...actionField },
              products: getCheckoutProduct(gtmData?.items),
            },
          },
        })
        const isCartHadSubscriptionProducts =
          gtmData?.items?.some(item => {
            return item.isSubscription === true
          }) || false

        isCartHadSubscriptionProducts &&
          isFlexibleSubscriptionFlagEnabled() &&
          trackGTMEvents({
            event: 'subscriptionPurchase',
            ecommerce: {
              currencyCode: gtmData?.currencyCode || '',
              purchase: {
                actionField: {
                  id: checkoutContainer.orderId,
                  memberId: gtmData?.userId || '',
                  orderType: 'Subscription' || '',
                  affiliation: 'Online Store',
                  revenue: gtmData?.properties?.subsctiptionItemsTotal || 0,
                  tax: gtmData?.value?.overAllTax || '',
                  shipping: gtmData?.value?.cartDeliveryCharge || '',
                  coupon: gtmData?.promotionDetails?.codes?.[0]?.code || '',
                  action: 'purchase',
                },
                products: getCheckoutProductV2(gtmData),
              },
            },
          })

        TagManager.dataLayer({
          dataLayer: {
            event: 'checkoutSubmitOrderInfo',
            ...getCheckoutGTMPageData(),
            firstName: chekoutShipmentInfo?.firstName || '',
            email: chekoutShipmentInfo?.email || '',
            address: chekoutShipmentInfo?.address || '',
            phone: chekoutShipmentInfo?.phoneNumber || '',
            city: chekoutShipmentInfo?.city || '',
            state: chekoutShipmentInfo?.state || '',
            zipCode: chekoutShipmentInfo?.zipCode || '',
            cardUser: checkoutAccountInfo?.name || '',
            cardType: checkoutAccountInfo?.card || '',
            loginUserName: checkoutAccountInfo?.loginUser || '',
            productCartType: gtmData?.cartType || '',
            totalPrice:
              gtmData?.currencySign + gtmData?.value?.grandTotal || '',
            discount:
              gtmData?.currencySign + gtmData?.value?.totalDiscount || '',
            deliveryCharge:
              gtmData?.currencySign +
                gtmData?.value?.cartDeliveryChargeAfterDiscount || '',
            platform: 'equinox',
          },
        })
      } else {
        if (isExpressCheckout()) {
          checkoutContainer.activeSubmitOrderButton = true
        }
        submitOrderResponse?.['items']?.forEach(item => {
          item &&
            item['skus'] &&
            item['skus'].map(bundleitem => {
              let inventoryProperties = bundleitem.inventoryProperties
              if (
                (inventoryProperties?.['available'] == false &&
                  inventoryProperties?.['backOrdered'] == true) ||
                (inventoryProperties?.['available'] == false &&
                  inventoryProperties?.['backOrdered'] == false) ||
                (inventoryProperties?.['available'] == false &&
                  inventoryProperties?.['backOrdered'] == null)
              ) {
                toastState.setToastMessage(
                  i18nTranslate(
                    'checkout.backorder',
                    'Sorry, this item is on backorder or out of stock.'
                  ),
                  false
                )
                this.redirectToViewCart()
              }
            })
        })
        if (submitOrderResponse.code === 'ECAPF0001') {
          toastState.setToastMessage(
            i18nTranslate(
              'order.paymentFraudCheck',
              'Sorry, your payment information failed. Please try a different payment method.'
            ),
            false
          )
          TagManager.dataLayer({
            dataLayer: {
              event: 'checkoutSubmitOrderErrorStatus',
              ...getCheckoutGTMPageData(),
              firstName: chekoutShipmentInfo?.firstName || '',
              email: chekoutShipmentInfo?.email || '',
              status: submitOrderResponse.status,
              ErrorMsg:
                'Sorry, your payment information failed. Please try a different payment method.',
            },
          })
        } else if (submitOrderResponse?.code === 'EOCA0000011') {
          /**
           * @info
           * 2 Error scenarios handled here for Out Of Stock and Expired Promotion as error code is same for both.
           * Translations Added based on the comparison of error messages
           */
          let errorMessage =
            submitOrderResponse?.message || submitOrderResponse?.responseMessage
          toastState.setToastMessage(
            alertMessageKeys(errorMessage, submitOrderResponse?.code) ||
              i18nTranslate(
                'order.outOfStock',
                'Few items in the cart are currently Out of Stock. Please check and update your cart to proceed.'
              ),
            false
          )
          this.redirectToViewCart()
        } else if (submitOrderResponse?.code === 'ECBTE0001') {
          let errorMessage =
            submitOrderResponse?.message ||
            submitOrderResponse?.responseMessage ||
            i18nTranslate(
              'order.generalError',
              'Sorry, we are unable to place order. Please try again.'
            )
          toastState.setToastMessage(errorMessage)
          checkoutContainer.activePath = 'payment'
        } else if (acceritifyErrorCodes?.includes(submitOrderResponse?.code)) {
          const errorMessage =
            submitOrderResponse?.message ||
            i18nTranslate(
              'order.generalError',
              'Sorry,we are unable to place order. Please try again.'
            )
          toastState.setToastMessage(errorMessage)
          TagManager.dataLayer({
            dataLayer: {
              event: 'checkoutSubmitOrderErrorStatus',
              ...getCheckoutGTMPageData(),
              firstName: chekoutShipmentInfo?.firstName || '',
              email: chekoutShipmentInfo?.email || '',
              status: submitOrderResponse?.status || '',
              ErrorMsg: submitOrderResponse?.message || '',
            },
          })
          checkoutContainer.activePath = 'payment'
        } else if (
          errorCodes.indexOf(submitOrderResponse?.code?.split?.('_')?.[0]) !==
          -1
        ) {
          let errorMessage =
            submitOrderResponse?.message ||
            submitOrderResponse?.responseMessage ||
            i18nTranslate(
              'order.generalError',
              'Sorry, we are unable to place order. Please try again.'
            )
          toastState.setToastMessage(
            alertMessageKeys(
              errorMessage,
              submitOrderResponse?.code?.split?.('_')?.[0]
            )
          )
          checkoutContainer.activePath = 'payment'
        } else if (
          submitOrderResponse?.message ||
          submitOrderResponse?.errorMessage
        ) {
          toastState.setToastMessage(
            submitOrderResponse.message || submitOrderResponse?.errorMessage
          )
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'order.generalError',
              'Sorry,we are unable to place order. Please try again.'
            ),
            false
          )
          TagManager.dataLayer({
            dataLayer: {
              event: 'checkoutSubmitOrderStatus',
              ...getCheckoutGTMPageData(),
              firstName: chekoutShipmentInfo?.firstName || '',
              email: chekoutShipmentInfo?.email || '',
              status: submitOrderResponse.status,
              ErrorMsg: submitOrderResponse.message,
            },
          })
        }
        checkoutContainer.activeSubmitOrderButton = isExpressCheckout() || false
      }
    }
    // }
    // else {
    //   this.handleLoading(false)
    //   this.props.history.push(`${pageNames.viewCart}`)
    //   const message = reviewResponse?.message || reviewResponse?.errorMessage
    //   toastState.setToastMessage(message)
    //   this.props.onEdit({ index: 'loyalty' })
    //   await cartContainer.viewCart()
    // }
  }

  renderApplePayConfirmationModal = () => {
    return (
      <Modal
        className="apple-pay-confirmation-modal"
        show={applepayContainer.isToShowApplePayConfirmationModal}
        size="md"
        aria-labelledby="confirm-modal-title"
        onHide={applepayContainer.handleApplePayModalClose}
        centered>
        <Modal.Header className="border-0 apple-pay-modal-header" closeButton>
          <Modal.Title className="font-weight-bold confirmation-text">
            Payment Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="apple-pay-modal-body">
          <span>
            Would you like to proceed with Apple Pay as your payment option?
          </span>
        </Modal.Body>
        <Modal.Footer className="apple-pay-modal-footer d-flex">
          <Col className="modal-button-wrapper">
            <Button
              className="confirm-button"
              variant="primary"
              onClick={() => {
                applepayContainer.isToShowApplePayConfirmationModal = false
                applepayContainer.createApplePayPaymentSession(
                  applepayContainer.applepayCheckoutInstance
                )
              }}>
              Yes, Proceed
            </Button>
          </Col>
          <Col className="modal-button-wrapper">
            <Button
              className="cancel-button"
              variant="primary"
              onClick={() => {
                applepayContainer.handleApplePayModalClose()
              }}>
              Cancel
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }

  renderGooglePayConfirmationModal = () => {
    return (
      <Modal
        className="google-pay-confirmation-modal"
        show={googlePayContainer.isToShowConfirmationModal}
        size="md"
        aria-labelledby="confirm-modal-title"
        onHide={googlePayContainer.handleGooglePayModalClose}
        centered>
        <Modal.Header className="border-0 google-pay-modal-header" closeButton>
          <Modal.Title className="font-weight-bold confirmation-text">
            Payment Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="google-pay-modal-body">
          <span>
            Would you like to proceed with Google Pay as your payment option?
          </span>
        </Modal.Body>
        <Modal.Footer className="google-pay-modal-footer d-flex">
          <Col className="modal-button-wrapper">
            <Button
              className="confirm-button"
              variant="primary"
              onClick={() => {
                googlePayContainer.isToShowConfirmationModal = false
                googlePayContainer.createGooglePayOrder(
                  googlePayContainer.googlePaymentInstance,
                  googlePayContainer.paymentsClient
                )
              }}>
              Yes, Proceed
            </Button>
          </Col>
          <Col className="modal-button-wrapper">
            <Button
              className="cancel-button"
              variant="primary"
              onClick={() => {
                googlePayContainer.handleGooglePayModalClose()
              }}>
              Cancel
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }

  /**
   * @description Renders Submit Order Button for Google Pay Payment
   */
  renderGooglePaySubmitOrderButton = (customClass = '') => {
    return (
      <div id="google-pay-button">
        <Button
          className={`w-100 google-pay-submit-button ${customClass}`}
          variant="success"
          disabled={
            this.isToDisableGooglePaySubmitButton() ||
            checkoutContainer.isToDisableSubmitOrderButton
          }
          data-testid="qa-google-pay-submit-order"
          onClick={() => {
            googlePayContainer.handleGooglePaySubmitButtonClick(this.props)
          }}>
          {i18nTranslate('review.submitOrder', 'Submit Order')}
        </Button>
      </div>
    )
  }

  /**
   * @description Renders Submit Order Button for Paypal Payment
   */
  renderPayPalSubmitOrderButton = (customClass = '') => {
    return (
      <div id="paypal-container">
        <Button
          className={`w-100 paypal-order-button-color ${customClass}`}
          variant="success"
          disabled={
            this.isToDisablePaypalSubmitButton() ||
            checkoutContainer.isToDisableSubmitOrderButton
          }
          data-testid="qa-paypal-submit-order">
          {i18nTranslate('review.submitOrder', 'Submit Order')}
        </Button>
      </div>
    )
  }

  /**
   * @description Renders Submit Order Button for Venmo Payment
   */
  renderVenmoSubmitOrderButton = (customClass = '') => {
    return (
      <div id="venmo-container">
        <Button
          className={`w-100 venmo-order-button-color ${customClass}`}
          variant="success"
          disabled={
            this.isToDisableVenmoSubmitButton() ||
            checkoutContainer.isToDisableSubmitOrderButton
          }
          onClick={() => {
            venmoContainer.reviewVenmoOrder(this.props)
          }}
          data-testid="qa-venmo-submit-order">
          {i18nTranslate('review.submitOrder', 'Submit Order')}
        </Button>
      </div>
    )
  }

  /**
   * @description Renders Submit Order Button for ApplePay Payment
   */
  renderApplePaySubmitOrderButton = (customClass = '') => {
    return (
      <div id="applepay-container">
        <Button
          className={`w-100 applepay-checkout-submit-button ${customClass}`}
          variant="success"
          disabled={
            this.isToDisableApplePaySubmitButton() ||
            checkoutContainer.isToDisableSubmitOrderButton
          }
          onClick={() => {
            applepayContainer.reviewApplePayOrder(this.props)
          }}
          data-testid="qa-applepay-submit-order">
          {i18nTranslate('review.submitOrder', 'Submit Order')}
        </Button>
      </div>
    )
  }

  /**
   * @description Renders terms checkbox for live event
   * anchor href will be replaced
   */
  renderTermsCheckbox = () => {
    return (
      <Form>
        <div className="mb-3">
          <Form.Check
            type="checkbox"
            id="event-submit-terms-checkbox"
            label={
              <div className="event-submit-terms-checkbox">
                {i18nTranslate(
                  'event.submitOrderTerms',
                  'By submitting your order, you agree to our'
                )}{' '}
                <a
                  href={EVENT_TERMS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="event-submit-tc-link">
                  {i18nTranslate(
                    'signup.termsConditions',
                    'Terms and Conditions.'
                  ).replace(/[.。]/g, '')}
                </a>
                {', '}
                <a
                  href={EVENT_REFUND_POLICY_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="event-submit-tc-link">
                  {i18nTranslate('signup.refundPolicy', 'Refund Policy')}
                  {'.'}
                </a>
              </div>
            }
            onChange={e => (this.termsCheckboxChecked = e.target.checked)}
          />
        </div>
      </Form>
    )
  }

  /**
   * @description Renders Submit Order Button for Payments except Paypal and Venmo
   */
  renderSubmitOrderButton = (customClass = '') => {
    const isToDisableSubmitButton = checkoutContainer.activeSubmitOrderButton
      ? !this.props.enableSubmit
      : true
    const enableLiveEvent = getLiveEventStatus()
    return (
      <Button
        className={`w-100 submit-order-button-color ${customClass}`}
        variant="success"
        disabled={
          (!this.termsCheckboxChecked && enableLiveEvent === 'true') ||
          isToDisableSubmitButton ||
          (checkoutContainer.isToDisableSubmitOrderButton &&
            !isExpressCheckout())
        }
        id="submit-order-button"
        data-testid="qa-submit-order"
        onClick={() => this.handleSubmitOrder(this.selectedDate)}>
        {i18nTranslate('review.submitOrder', 'Submit Order')}
      </Button>
    )
  }

  /**
   * @description Renders Submit Order Button
   */
  renderSubmitButton = () => {
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    let customClass = isFlexibleSubscriptionEnabled ? 'submit-order-button' : ''
    if (isExpressCheckout()) {
      customClass += ' express-checkout-button'
    }

    const { value = {} } = cartContainer?.cartResponse || {}
    let grandTotal = value?.grandTotal || 0
    if (grandTotal > 0) {
      if (paypalContainer.paypalInitiate) {
        return this.renderPayPalSubmitOrderButton(customClass)
      }
      if (venmoContainer.venmoInitiate) {
        return this.renderVenmoSubmitOrderButton(customClass)
      }
      if (googlePayContainer.isToInitiateGooglePayPayment) {
        return this.renderGooglePaySubmitOrderButton(customClass)
      }
      if (applepayContainer.applepayInitiate) {
        return this.renderApplePaySubmitOrderButton(customClass)
      }
    }
    return this.renderSubmitOrderButton(customClass)
  }

  // returns boolean for event registered user
  checkEventRegisteredUser() {
    const profileResponse = customerContainer?.profileResponse
    const accountProperties = profileResponse?.accounts?.properties || []
    const userProperty = accountProperties?.filter(
      property => property?.attributeId === 'registeredEvent'
    )
    return userProperty?.[0]?.value || 'false'
  }

  // returns boolean for order hold status
  getOrderHoldStatus = async () => {
    const response = await customerContainer.getProfile()
    const accountProperties = response?.accounts?.properties || []
    const userProperty = accountProperties?.filter(function (property) {
      if (property?.attributeId === 'orderHold') {
        return property
      }
    })

    if (
      (userProperty?.length > 0 && userProperty?.[0]?.value === 'CSOH') ||
      userProperty?.[0]?.value === 'SOH'
    ) {
      return true
    }
    return false
  }

  // Updates submitDisableInfo state which disables submit button and shows error message
  submitDisable = async () => {
    const enableLiveEvent = getLiveEventStatus()
    const orderHoldStatus = (await this.getOrderHoldStatus()) || false
    const isEventRegisteredUser = this.checkEventRegisteredUser()

    if (orderHoldStatus) {
      this.submitDisableInfo.isDisabled = true
      this.submitDisableInfo.message = i18nTranslate(
        'account.onhold',
        'Account is on hold'
      )
    } else {
      const itemdetails = cartContainer?.cartResponse?.items || []
      const isSkuNotAvailable = (inventory = {}) =>
        inventory?.available === false &&
        (inventory?.backOrdered === false || inventory?.backOrdered === null)

      const isSomeSkuNotAvailable = itemdetails?.some(item => {
        const { skus = [] } = item
        return skus?.some(newitem => {
          return isSkuNotAvailable(newitem?.inventoryProperties)
        })
      })

      if (isSomeSkuNotAvailable) {
        this.submitDisableInfo.isDisabled = true
        this.submitDisableInfo.message = i18nTranslate(
          'some.items.unavailable',
          'Some of the items are not available.'
        )
      } else if (cartContainer?.cartResponse?.errorCode) {
        this.submitDisableInfo.isDisabled = true
        if (cartContainer.cartResponse?.errorCode === 'EOPLOR001') {
          this.submitDisableInfo.message = i18nTranslate(
            'cart.homeMarket',
            'Please switch to your home market to make a purchase.'
          )
        } else if (cartContainer.cartResponse?.errorCode === 'EOSKUPS002') {
          this.submitDisableInfo.message = i18nTranslate(
            'bundle.discontinuedV2Message',
            'Discontinued - This product or promotion has been discontinued',
            {
              nameSpace: 'ssr-resource',
            }
          )
        } else {
          this.submitDisableInfo.message =
            cartContainer.cartResponse.errorMessage
        }
      }

      // Disable submit conditions for Live Event
      // CN locale users might get migrated for the July event site
      // submit is disabled for them for non-event sites
      const homeCountry = customerContainer?.getUserHomeCountry()
      const isUserFromCN = homeCountry === 'CN'
      if (enableLiveEvent !== 'true') {
        if (isUserFromCN) {
          this.submitDisableInfo.isDisabled = true
          this.submitDisableInfo.message = i18nTranslate(
            'cart.homeMarket',
            'Please switch to your home market to make a purchase.'
          )
        }
      }
      if (enableLiveEvent === 'true') {
        const isEventUnregisteredUser =
          customerContainer.isRegisterUser && isEventRegisteredUser === 'false'
        const isRetailUser =
          customerContainer?.profileResponse?.accounts?.accountType ===
          'Retail Customer'

        // Disable submit in event for unregistered users and retail users from CN
        if (isEventUnregisteredUser || (isUserFromCN && isRetailUser)) {
          this.submitDisableInfo.isDisabled = true
        }
      }
    }
  }

  render() {
    // const csrAdminName = getLocalStorage('csrAdminName') || ''
    const previousDate = this.handleMaxDate()
    const errorMsgClassName = this.isValidDate ? 'is-valid' : 'is-invalid'
    const enableLiveEvent = getLiveEventStatus()
    if (enableLiveEvent === 'true') {
      this.submitDisable()
    }
    return (
      <>
        {/* {csrAdminName !== '' && (
        <Col lg={12}>
            <Row
              noGutters
              className="date-picker-wrapper d-flex position-relative">
              <Col lg={4}>
                <Form.Label>
                  {i18nTranslate('checkout.backDateOrder', 'Effective Date')}
                </Form.Label>
              </Col>
              <Col lg={8} className="pl-md-1">
                <Form.Label
                  className={`date-picker-form ${errorMsgClassName} w-100`}>
                  <DatePicker
                    className="form-input"
                    placeholderText={'YYYY/MM/DD'}
                    dateFormat={'yyyy-MM-dd'}
                    selected={this.selectedDate !== -1 && this.selectedDate}
                    locale={
                      getLocaleCodeFromUrl({
                        locationProps: this.props,
                        defaultLocale: 'en_US',
                        isReverseType: true,
                      })?.split('-')?.[0]
                    }
                    onSelect={(date, event) => {
                      this.handleDateChange(date, event)
                    }}
                    onBlur={event => this.handleBlur(event)}
                    // minDate={new Date()}
                    startDate={new Date()}
                    maxDate={new Date(previousDate)}
                    showDisabledMonthNavigation
                  />
                  <div className="date-picker-icon position-absolute">
                    <DatePickerIcon className="mb-2" />
                  </div>
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {i18nTranslate(
                    'checkout.backDateErrorMessage',
                    'Please select a valid date'
                  )}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Col>
        )} */}
        <Col lg={12} className={isExpressCheckout() ? `my-0 p-0` : `my-2 p-0`}>
          {enableLiveEvent === 'true' && this.renderTermsCheckbox()}
          {this.renderSubmitButton()}
          {enableLiveEvent === 'true' && this.submitDisableInfo?.isDisabled && (
            <p className="text-danger submit-order-error-message">
              {alertMessageKeys(this.submitDisableInfo?.message)}
            </p>
          )}
        </Col>
        {googlePayContainer.isToShowConfirmationModal &&
          this.renderGooglePayConfirmationModal()}
        {/** Confirmation modal integrated for asynchronous calls issue */}
        {applepayContainer.isToShowApplePayConfirmationModal &&
          this.renderApplePayConfirmationModal()}
      </>
    )
  }
}

export { SubmitOrder }
