import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Link } from 'react-router-dom'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Card, Row, Button } from 'react-bootstrap'
import {
  isMerchPLP,
  enableQuickView,
  enableProductCompare,
  APPConfig,
} from 'config/appConfig'
import { AiOutlineShoppingCart as CartIconNew } from 'react-icons/ai'
import { catalogDeps } from 'src/deps'
import { pageNames } from 'src/routes/pathParams'
import {
  Price,
  quickViewState,
  ProductBadge,
  ProductPromotion,
  ProductFavorites,
  CustomPrice,
  ImageComponent,
} from 'src/views/components'
import {
  application,
  checkIsMobile,
  getImageFromProperty,
  trackProductClick,
  trackProductClickEvent,
  getLocalStorage,
  getLiveEventStatus,
  convertToBoolean,
} from 'src/utils'
import { ProductCompare } from '../ProductCompare'
import { MerchAlternateImages } from '../MerchAlternateImages'
import colorCode from 'src/static/fixtures/colorCode.json'
import { catalogState } from 'src/models/Catalog/catalogState'
import { specialProductTypes } from '../fixture'
import { cartContainer, customerContainer, accountsContainer } from 'src/models'
import TagManager from 'react-gtm-module'
import { productState } from '../../../components/BaseProductComponent/ProductState'
import { ProductCardPrice } from 'src/views/components/Price'
import './styles.scss'

@observer
class ProductCard extends React.Component {
  nameSpace = 'ssr-resource'
  @observable imageArrayProperties = { images: [], currentIndex: 0 }
  productCard = ''
  currentRef = React.createRef()
  enableLiveEvent = getLiveEventStatus()

  constructor(props) {
    super(props)
    if (isMerchPLP) {
      this.imageArrayProperties.images = catalogState.getImageArray(
        props?.product
      )
    }
  }

  handleCurrentImage = () => {
    const index = catalogState.handleCurrentImage(this.imageArrayProperties)
    this.imageArrayProperties.currentIndex = index
  }

  handleQuickView = (e, idToFocus) => {
    const handleFocus = () => {
      if (this.currentRef && e.code === 'Enter') {
        setTimeout(
          () => this.currentRef.current.focus({ focusVisible: true }),
          500
        )
      }
    }
    e.preventDefault()
    e.stopPropagation()

    const productId =
      this.props?.product?.properties?.slug ||
      this.props?.productId ||
      this.props?.product?.identifier ||
      ''

    const categoryId = this.props?.categoryId || ''
    const defaultSku = catalogDeps.getDefaultSelectedSku(this.props.product)
    const skuSlug = defaultSku?.[0]?.properties?.slug || ''
    const skuId = defaultSku?.[0]?.identifier || ''
    const hasMoreThanOneSku = (this.props.product?.sku?.length || 1) > 1
    const variantParameter = skuSlug || skuId || ''
    const isMultiVariant = defaultSku?.[0]?.properties?.variantLabel || ''
    /* @variant_autoselect */
    quickViewState.getProduct({
      productId,
      categoryId,
      handleFocus,
      defaultVariantForQuickView: variantParameter,
      isMultiVariant: isMultiVariant,
    })
    TagManager.dataLayer({
      dataLayer: {
        event: 'quickView',
        pagePath: '/catalog/' + categoryId,
        pageTitle: 'Product Detail',
        userName: accountsContainer?.profileResponse?.name || 'Guest',
        userId: accountsContainer?.profileResponse?.customer?.email || 'Guest',
        quikeProductId: productId,
        platform: 'equinox',
      },
    })
  }

  renderProductCompare = () => {
    const { categoryId = '', productId = '' } = this.props || {}
    const { imageURL = '' } = this.props?.product?.properties || {}
    return (
      <ProductCompare
        productId={productId}
        categoryId={categoryId}
        imageURL={imageURL}
      />
    )
  }

  addToCart = async (productId = '', skuId = '', productCount = 0) => {
    const cartArray = [
      {
        productId: productId,
        quantity: productCount,
        skuId: skuId,
      },
    ]
    if (cartArray) {
      const response = await cartContainer.addToCart({
        cartArray,
      })
      return response
    }
  }

  getPdtImage = () => {
    const { product } = this.props || {}
    if (isMerchPLP) {
      const { currentIndex, images } = this.imageArrayProperties
      return images[currentIndex]
    } else if (product?.type == 'kit' || product?.type === 'bundle') {
      return getImageFromProperty(product?.properties)
    }
    const defaultSku = catalogDeps.getDefaultSelectedSku(product)
    return getImageFromProperty(defaultSku?.[0]?.properties)
  }

  handleQuantityChange = count => {
    productState.productCount = count || 0
    this.quantity = count || 0
    if (this.props?.onSubscriptionQuantityChange) {
      this.props?.onSubscriptionQuantityChange(count)
    }
  }

  handleProductClick = () => {
    const product = this.props.product
    const isSpecialProduct = specialProductTypes.includes(product?.type)

    if (this.enableLiveEvent === 'true') {
      trackProductClickEvent({
        type: 'select_item',
        action: { list: 'Search Results' },
        isSpecialProduct,
        categoryId: this.props?.categoryId,
        product,
        promotion: false,
      })

      // select_promotion event is disabled as July Live Event is not having promotions
      // if (this.props?.product?.promotion?.length > 0) {
      //   trackProductClickEvent({
      //     type: 'select_promotion',
      //     action: { list: 'Search Results' },
      //     isSpecialProduct,
      //     categoryId: this.props?.categoryId,
      //     product,
      //     promotionClass: this.props?.product?.promotion?.[0]?.promotionClass,
      //     offerId: this.props?.product?.promotion?.[0]?.offerId,
      //     promotion: true,
      //   })
      // }
    }
    trackProductClick({
      type: 'productClick',
      action: { list: 'Search Results' },
      isSpecialProduct,
      categoryId: this.props?.categoryId,
      product,
    })
  }

  handleAddClick = async () => {
    const productId =
      this.props?.product?.identifier || this.props?.productId || ''

    const defaultSku = catalogDeps.getDefaultSelectedSku(this.props.product)
    const skuSlug = defaultSku?.[0]?.properties?.slug || ''
    const skuId = defaultSku?.[0]?.identifier || ''
    const isBackOrdered =
      defaultSku?.[0]?.inventoryProperties?.backOrdered || ''
    return await cartContainer.addToCart({
      cartArray: [
        {
          productId: productId,
          quantity: 1,
          skuId: skuId,
          slug: skuSlug,
          isBackOrdered: isBackOrdered,
        },
      ],
    })
  }

  render() {
    const {
      productBadge = '',
      showLazyLoad = true,
      categoryId = '',
      productPromotion = [],
      productType = '',
      isProductComparable = false,
      productId = '',
      template6 = false,
      product = {},
      isFavorite = false,
      index = '0',
      isFromCatalog = false,
    } = this.props || {}

    const { totalValue = {} } = product
    const { priceFacets = {} } = totalValue

    const localvalue = getLocalStorage('accountType')
    const defaultSku = catalogDeps.getDefaultSelectedSku(product)
    const skuName =
      defaultSku && defaultSku.length
        ? defaultSku[0]?.properties?.name || ''
        : ''

    const {
      brand,
      name = skuName,
      imageURL: image = '',
    } = this.props?.product?.properties || {}

    let CVPrice = product?.priceFacets?.CV || 0
    let PVPrice = product?.priceFacets?.PV || 0

    const imageURL = this.getPdtImage()
    const identifier =
      this.props?.productId || this.props?.product?.identifier || ''
    const skuPrice = this.props?.product?.prices || ''
    const productOrSKUPrice = this.props?.product?.transactionPrice || skuPrice
    let skuPrices = []
    const skuList = product?.sku || []
    if (skuList && skuList.length) {
      skuPrices = skuList.filter(item => {
        return item.default
      })
    }

    if (isFavorite) {
      CVPrice = CVPrice === 0 ? skuPrices?.[0]?.priceFacets?.CV || 0 : CVPrice
      PVPrice = PVPrice === 0 ? skuPrices?.[0]?.priceFacets?.PV || 0 : PVPrice
    }

    const transactionPrice =
      isFavorite &&
      skuPrices?.length &&
      skuPrices[0]?.prices &&
      skuPrices[0]?.prices?.length
        ? skuPrices[0].prices[0]
        : productOrSKUPrice && productOrSKUPrice?.length > 0
        ? productOrSKUPrice[0]
        : productOrSKUPrice
    const descriptionClass = application?.isMobile ? 'pl-2' : ''
    const slug = this.props.product?.properties?.slug
    const skuId = defaultSku?.[0]?.identifier || ''
    const hasMoreThanOneSku = (this.props.product?.sku?.length || 1) > 1
    const productOrSlugId = slug ? slug : identifier
    const skuSlug = defaultSku?.[0]?.properties?.slug || ''
    const variantParameter = skuSlug || skuId || ''
    /* @variant_autoselect */
    const shouldAppendVariant = hasMoreThanOneSku && variantParameter
    let url = ''
    if (categoryId && shouldAppendVariant) {
      url = `${pageNames.product}/${productOrSlugId}?categoryId=${categoryId}&variant=${variantParameter}`
    } else if (categoryId) {
      url = `${pageNames.product}/${productOrSlugId}?categoryId=${categoryId}`
    } else if (shouldAppendVariant) {
      url = `${pageNames.product}/${productOrSlugId}?variant=${variantParameter}`
    } else {
      url = `${pageNames.product}/${productOrSlugId}`
    }

    const isLinkEnabled = isMerchPLP ? 'disabled' : ''
    const pathName =
      this.props?.location?.pathname ||
      (typeof window === 'object' && (window?.location?.pathname || ''))
    const isProductListPage =
      pathName.includes(pageNames.catalog) ||
      pathName.includes('template') ||
      pathName.includes(pageNames.search)

    const isFavouritePage = pathName.includes(pageNames.favorites)

    const showQuickView =
      enableQuickView &&
      (isProductListPage || isFavouritePage) &&
      specialProductTypes.indexOf(productType) === -1 &&
      !checkIsMobile()
    const productLevelPromotion = this.props?.product?.promotion || []
    const skuLevelPromotion =
      defaultSku && defaultSku.length ? defaultSku[0]?.promotion || [] : []
    const productAndSkuLevelPromotion = [
      ...skuLevelPromotion,
      ...productPromotion,
      ...productLevelPromotion,
    ]

    const isPVCVRange =
      totalValue &&
      priceFacets &&
      ((priceFacets.PV && priceFacets.PV?.PVDiscount) ||
        (priceFacets.CV && priceFacets.CV?.CVDiscount))
        ? true
        : false

    const isPriceRange = totalValue && totalValue?.totaldiscount ? true : false
    const isRange = isPVCVRange || isPriceRange ? true : false
    const originalPrice = (totalValue && totalValue?.originalPrice) || 0
    const priceAfterDiscount =
      (totalValue && totalValue?.priceAfterDiscount) || 0

    const PVPriceOriginal = priceFacets.PV?.PV || PVPrice
    const PVPriceDiscount = priceFacets.PV?.PVAfterDiscount || 0

    const priceFreeValue = originalPrice - totalValue?.totaldiscount || 0
    const priceFreePromotion =
      priceAfterDiscount == 0 && priceFreeValue == 0 ? true : false

    const colorList = this.props?.colorList || []
    const userRole = customerContainer?.profileResponse?.userrole || ''
    const isSpecialProduct = specialProductTypes.includes(product?.type)
    const isAddtoCartPLPEnabled = convertToBoolean(
      APPConfig?.getAppConfig()?.enableAddToCartInPLP
    )
    let categoryNameFlag = false
    return (
      <Card
        className={`plp-card-item card-wrap position-relative bg-white mb-0 border-0 w-100 ${isLinkEnabled} border`}
        data-testid="qa-product-container"
        key={`product_card_${identifier}`}
        ref={element => (this.productCard = element)}
        {...(isLinkEnabled === 'disabled' && {
          onClickCapture: e => {
            if (!e.target.classList.contains('allow-merchplp')) {
              e.stopPropagation()
            }
          },
        })}>
        <Link
          to={url}
          className="image-container-wrap"
          aria-label={name}
          id={`product_card_img_${identifier}`}
          ref={this.currentRef}
          onClick={this.handleProductClick}>
          <ProductBadge
            productBadge={productBadge}
            badgeRendertype={template6 ? 'horizontal' : 'diagonal'}
          />

          <ImageComponent
            testId="qa-plp-product-image"
            className="plp-product-image"
            src={imageURL}
            alt={name}
            optimizeImage={true}
            height={'200px'}
            width={'230px'}
            loadingType={isFromCatalog && index < 8 ? 'eager' : 'lazy'}
          />
        </Link>
        {showQuickView && (
          <button
            onClick={e =>
              this.handleQuickView(e, `product_card_img_${identifier}`)
            }
            onKeyPress={e =>
              this.handleQuickView(e, `product_card_img_${identifier}`)
            }
            tabIndex="0"
            className="text-uppercase quickview-label position-absolute m-auto py-1 px-3"
            data-testid="qa-quick-view-button">
            {i18nTranslate('plp.quickView', 'quick view', {
              nameSpace: this.nameSpace,
            })}
          </button>
        )}
        {this.enableLiveEvent !== 'true' && (
          <ProductFavorites
            {...{ productId: productId || identifier, name, categoryId }}
          />
        )}
        <Card.Body
          className={`product-description flex-direction-column text-center px-2 pt-0 pb-2 mt-3 ${descriptionClass}`}
          data-testid="qa-figcaption">
          {enableProductCompare &&
            isProductListPage &&
            isProductComparable &&
            this.renderProductCompare()}
          {Array.isArray(colorList) &&
            colorList.length > 0 &&
            colorList.map(colorObj => {
              const color = colorObj?.color
              return (
                <Button
                  key={color}
                  variant=""
                  className={`${
                    this.props?.product?.selectedSku?.identifier ===
                    colorObj?.skuId
                      ? 'border-primary'
                      : 'border-none'
                  } mr-0 mb-2 p-1 text-uppercase`}
                  onClick={() =>
                    this.props.changeSku(colorObj?.skuId, identifier)
                  }>
                  <div
                    className="border"
                    style={{
                      background: colorCode[color],
                      height: '25px',
                      width: '25px',
                    }}
                  />
                </Button>
              )
            })}
          <div className="text-decoration-none">
            {categoryNameFlag && (
              <Card.Title
                className="brand-name flex-nowrap font-weight-medium text-dark overflow-hidden w-100"
                data-testid="qa-product-brand">
                {brand}
              </Card.Title>
            )}
            <Link
              to={url}
              onClick={this.handleProductClick}
              className="catalog-product-name flex-nowrap text-dark
              overflow-hidden h-auto paragraph-m"
              data-testid="qa-product-name">
              {name}
            </Link>
          </div>
          {!isRange && (
            <>
              <Row
                noGutters
                className="product-price flex-row"
                data-testid="qa-product-price"
                tabIndex={0}>
                <ProductCardPrice
                  priceLable={this.props?.isToShowPriceLabel ?? true}
                  {...this.props}
                />
              </Row>
              {localvalue?.includes('Brand Affiliate') && userRole?.length > 0 && (
                <>
                  <Row
                    noGutters
                    className="product-ProductPrice pv-price"
                    data-testid="qa-product-price"
                    tabIndex={PVPrice ? 0 : -1}>
                    <CustomPrice
                      custompriceValue={PVPrice}
                      custompriceLabel={i18nTranslate('pdp.svprice', 'SV', {
                        nameSpace: this.nameSpace,
                      })}
                      dataTestId="qa-pdp-pvprice"
                      isPVCV={true}
                    />
                  </Row>
                </>
              )}
            </>
          )}
          {isRange && (
            <>
              <Row
                noGutters
                className="product-price"
                data-testid="qa-product-price"
                tabIndex={0}>
                <ProductCardPrice
                  priceLable={this.props?.isToShowPriceLabel ?? true}
                  {...this.props}
                />
              </Row>
              {localvalue?.includes('Brand Affiliate') && userRole?.length > 0 && (
                <>
                  <Row
                    noGutters
                    className="product-price pv-price"
                    data-testid="qa-product-price"
                    tabIndex={0}>
                    <CustomPrice
                      custompriceValue={PVPriceOriginal}
                      customDiscountPriceValue={PVPriceDiscount}
                      custompriceLabel={i18nTranslate('pdp.svprice', 'SV', {
                        nameSpace: this.nameSpace,
                      })}
                      dataTestId="qa-pdp-pvprice"
                      isPVCV={true}
                    />
                  </Row>
                </>
              )}
            </>
          )}
          <ProductPromotion
            promotion={productAndSkuLevelPromotion}
            isProductCard={true}
            isToShowPromoOnImage={true}
          />
          {isAddtoCartPLPEnabled &&
            this.props.isFromCatalog &&
            !isSpecialProduct && (
              <Button
                className="pop-add-btn d-flex cta text-uppercase w-100 align-items-center mt-2 justify-content-center"
                onKeyPress={e => {
                  this.handleAddClick(e)
                }}
                onClick={e => {
                  this.handleAddClick(e)
                }}>
                <CartIconNew style={{ fontSize: '24px', marginRight: '4px' }} />
                {i18nTranslate('pdp.addToCart', 'Add To Cart', {
                  nameSpace: 'ssr-resource',
                })}
              </Button>
            )}
        </Card.Body>
        <MerchAlternateImages
          imageArrayProperties={this.imageArrayProperties}
          handleCurrentImage={this.handleCurrentImage}
        />
      </Card>
    )
  }
}

export { ProductCard }
export default ProductCard
