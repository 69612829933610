import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Row, Container } from 'react-bootstrap'
import { AccordionBlockPDP } from 'src/views/components/AccordionBlockPDP'
import './styles.scss'
import { getProductPDPTitle } from 'src/utils/commonUtils'
import { application } from 'src/utils'
import { ProductDetailsSectionBundleKits } from '../ProductDetailsSectionBundleKits'

@observer
class ProductDetailsDropdown extends React.Component {
  nameSpace = 'ssr-resource'
  renderTitle = () => {
    const { isAccordionType = false } = this.props
    const productDetailsLable = getProductPDPTitle(
      this.props.product?.tempProperties,
      'highlights'
    )
    const clsName = `about-title ${
      isAccordionType ? 'text-uppercase' : 'border-light-grey'
    }`

    return (
      <Row className={clsName} noGutters>
        {productDetailsLable ||
          i18nTranslate('pdp.productDetails', 'Product Details', {
            nameSpace: this.nameSpace,
          })}
      </Row>
    )
  }
  render() {
    const { product } = this.props
    const productDescription = product?.productDetails || []
    const productDetailDesc = product?.productDetailsDescription || []
    let values = []
    let valuesDetail = []
    try {
      values =
        [productDescription?.length > 0 && JSON.parse(productDescription)] || []
      valuesDetail =
        [productDetailDesc?.length > 0 && JSON.parse(productDetailDesc)] || []
      if (values?.length == 1 && values?.[0]?.includes('\n')) {
        values[0] = values[0].replace(/(\r\n|\n|\r)/gm, '')
        if (!values[0]) {
          values = []
        }
      }
    } catch (e) {
      console.log('Data parse error::: ProductDetailsDropdown' + e)
    }

    let {
      description = [],
      highlights,
      importer,
      includedItems,
      originCountry,
      warnings,
    } = values?.[0] || {}
    let hasDescriptionAlready =
      Array.isArray(description) && description?.length > 0
    if (
      !hasDescriptionAlready &&
      Array.isArray(valuesDetail) &&
      valuesDetail?.length > 0 &&
      Array.isArray(valuesDetail[0]) &&
      valuesDetail?.[0]?.length > 0
    ) {
      description = valuesDetail[0]
    }

    let renderAccordion =
      (Array.isArray(description) && description?.length > 0) ||
      (Array.isArray(highlights) && highlights?.length > 0) ||
      (Array.isArray(includedItems) && includedItems?.length > 0) ||
      originCountry ||
      warnings

    return renderAccordion ? (
      <div className="product-accordion-wrapper ">
        <AccordionBlockPDP
          title={this.renderTitle()}
          isDefaultOpen={application.isDesktop}
          className="accordion-description"
          dataTestId="qa-pdp-product-details">
          <Container className="pdp-accordion-content-container">
            <div className="product-detail-accordion">
              <ProductDetailsSectionBundleKits product={product} />
            </div>
          </Container>
        </AccordionBlockPDP>
      </div>
    ) : (
      <></>
    )
  }
}

export { ProductDetailsDropdown }
export default ProductDetailsDropdown
