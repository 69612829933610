import React from 'react'
import { observer } from 'mobx-react'
import './styles.scss'

@observer
class ProductDetailsSectionBundleKits extends React.Component {
  renderProductDetDesc = description => {
    let descContent = description || ''
    if (Array.isArray(description) && description?.length > 0) {
      descContent = description.map((desc, index) => {
        if (desc && typeof desc === 'string') {
          return (
            <p className="description-text" key={index}>
              {desc}
            </p>
          )
        }
        return <></>
      })
    }

    return <div className="product-det-desc">{descContent}</div>
    // CX121-3606 - "/n" considered using above white-space css properties
  }

  renderProductDetList = highlights => {
    if (highlights && highlights?.length > 0) {
      return (
        <div className="product-det-lists">
          <ul className="d-flex align-items-start justify-content-start flex-wrap text-center p-0">
            {highlights.map((highlights1, id) => {
              return (
                <li key={`highlights1-${id}`}>
                  {highlights1?.iconUrl ? (
                    <img src={highlights1?.iconUrl} alt={`alt-${id}`} />
                  ) : (
                    <div className="blank-image-space"></div>
                  )}
                  <p className="paragraph-s">{highlights1?.label}</p>
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
    return <></>
  }

  productDetIncludedList = (includedItems, originCountry, importer) => {
    return (
      <div className="product-det-included-list">
        {includedItems && includedItems?.length > 0 && (
          <>
            <h5 className="subhead-m">What’s included</h5>
            <ul className="pl-4 paragraph-m">
              {includedItems.map((includedItemsdata, id) => {
                return (
                  <li key={`includedItemsdata-${id}`}>{includedItemsdata}</li>
                )
              })}
            </ul>
          </>
        )}

        {(originCountry || importer) && (
          <div className="product-det-list-desc">
            {originCountry && (
              <p className="paragraph-m">Country of origin: {originCountry}</p>
            )}
            {importer && <p className="paragraph-m">Importer: {importer}</p>}
          </div>
        )}
      </div>
    )
  }

  renderProductWarnings = warnings => {
    return <>{warnings && <div className="warning-text">{warnings}</div>}</>
  }

  render() {
    const { product } = this.props
    const productDescription = product?.productDetails || []
    const productDetailDesc = product?.productDetailsDescription || []
    let values = []
    let valuesDetail = []
    try {
      values =
        [productDescription?.length > 0 && JSON.parse(productDescription)] || []
      valuesDetail =
        [productDetailDesc?.length > 0 && JSON.parse(productDetailDesc)] || []
      if (values?.length == 1 && values?.[0]?.includes('\n')) {
        values[0] = values[0].replace(/(\r\n|\n|\r)/gm, '')
        if (!values[0]) {
          values = []
        }
      }
    } catch (e) {
      console.log('Data parse error::: ProductDetailsSectionBundleKits' + e)
    }

    let detailsValues = values?.length > 0 ? Object.values(...values) : []
    let detailsDescValues =
      valuesDetail && valuesDetail?.[0]?.length > 0 ? true : false
    let renderAccordionValues = detailsValues?.some(item =>
      item?.length > 0 ? true : false
    )

    let {
      description = [],
      highlights,
      importer,
      includedItems,
      originCountry,
      warnings,
    } = values?.[0] || {}

    let hasDescriptionAlready =
      Array.isArray(description) && description?.length > 0
    if (
      !hasDescriptionAlready &&
      Array.isArray(valuesDetail) &&
      valuesDetail?.length > 0 &&
      Array.isArray(valuesDetail[0]) &&
      valuesDetail?.[0]?.length > 0
    ) {
      description = valuesDetail[0]
    }

    let renderAccordion =
      (Array.isArray(description) && description?.length > 0) ||
      (Array.isArray(highlights) && highlights?.length > 0) ||
      (Array.isArray(includedItems) && includedItems?.length > 0) ||
      originCountry ||
      warnings

    return renderAccordion ? (
      <div className="product-details-section">
        {description && this.renderProductDetDesc(description)}
        {highlights && this.renderProductDetList(highlights)}
        {this.productDetIncludedList(includedItems, originCountry, importer)}
        {warnings && this.renderProductWarnings(warnings)}
      </div>
    ) : (
      <></>
    )
  }
}

export { ProductDetailsSectionBundleKits }
export default ProductDetailsSectionBundleKits
