/**
 * Generates schema.org VideoObject metadata for a video, based on the provided videoProps.
 *
 * @param {Object} videoProps - Object containing video metadata like title, slogan, etc.
 * @returns {Object} - The generated VideoObject schema.org metadata object.
 */
function videoSchema(videoProps) {
  const date = videoProps?.uploadDate || '18-07-2018'
  const videoData = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: videoProps.title,
    description: videoProps.slogan,
    thumbnailUrl: `https:${videoProps.thumbnailImage}`,
    duration: convertDurationToISO(videoProps.videoLength),
    embedUrl: videoProps.src,
    uploadDate: date,
  }
  return videoData
}

// Refer https://en.wikipedia.org/wiki/ISO_8601
/**
 * Converts a video duration in HH:MM:SS format to ISO 8601 duration format.
 *
 * @param {string} duration - The video duration in HH:MM:SS format
 * @returns {string} The duration in ISO 8601 format
 */
function convertDurationToISO(duration) {
  // For now adding duration to support only HH:MM:SS
  let durationTime = ['H', 'M', 'S']
  const timeDuration = duration && duration.split(':')
  let durationInISOFormat = 'P'

  if (timeDuration && timeDuration.length) {
    durationTime = durationTime.slice(
      Math.abs(durationTime.length - timeDuration.length),
      durationTime.length
    )

    timeDuration.forEach((item, idx) => {
      if (parseInt(item) != 0) {
        durationInISOFormat += item + durationTime[idx]
      }
    })
  }
  return durationInISOFormat
}

export { videoSchema }
