import {
  getActiveAppConfig,
  IS_BROWSER,
  isToEnableSSR,
  APPConfig,
} from 'config/appConfig'
import { getB2CEndPoints } from './b2c'
import { getB2BEndPoints } from './b2b'
import { getLocaleCodeFromUrl } from 'src/utils'

function getCommonEndPoints(options) {
  const {
    studioCampaignId,
    studioDomain,
    studioEnv,
    apiDomainName,
    mySiteApiDomainName,
    catalogApiDomain,
    sonarIp,
    catalogCollectionId,
    oktaDomain,
    awsRecommendationDomain,
    smsVerifyDomain,
    phoneNumberUniquenessDomainUrl,
    emailLookupInfo,
  } = getActiveAppConfig(options)
  // getAppConfig(options)

  // console.log(`getCommonEndPoints >>>>`, getAppConfig())

  const activeEnvironment = APPConfig.getLocalEnv()

  // Cookie is not setting due to different domain
  // used separate domian only for CSR since store id, etc.., are same
  const orchestrationDomain =
    IS_BROWSER && document.domain?.includes('.mynuskin.com')
      ? mySiteApiDomainName
      : apiDomainName

  const API_BASE_PATH = `https://${orchestrationDomain}/orchestrationservices/storefront`
  const catalogBasePath = `https://${
    catalogApiDomain || orchestrationDomain
  }/orchestrationservices/storefront/catalogs`

  // added <locale> and it will be dynamically replaced based on locale in apiUtils
  const nuskinFooter =
    'https://www.nuskin.com/apps/hello/services/marketfooter.<locale>.json'
  // const csLink =
  //   'https://cdn.contentstack.io/v3/content_types/homepage/entries/?locale=US-en&include_reference_content_type_uid=true&environment=public-test'
  const oktaClearUserSession = `${oktaDomain}/login/signout`

  let emailValidationDomain = `https://${orchestrationDomain}`
  const emailLookupInfoDomain = `https://${emailLookupInfo?.domain}`

  let sponsorLocale = 'US'
  if (IS_BROWSER) {
    let locale = getLocaleCodeFromUrl() || ''
    locale = locale?.toLowerCase()?.split('_')
    sponsorLocale = locale?.[1]?.toUpperCase()
    const domain = isToEnableSSR ? document.domain : activeEnvironment
    emailValidationDomain = `https://${domain}/${locale?.[1]}/${locale?.[0]}`
  }

  const API_URL_CONFIG = {
    basePath: `${API_BASE_PATH}`,
    reviewBasePath: `${API_BASE_PATH}/reviews`,
    accountsBasePath: `${API_BASE_PATH}/accounts`,
    catalogBasePath: `${catalogBasePath}`,
    customerBasePath: `${API_BASE_PATH}/customers`,
    cartBasePath: `${API_BASE_PATH}/carts/USER`,
    checkoutBasePath: `${API_BASE_PATH}/carts/checkoutservice/orders/USER`,
    subscriptionCartBasePath: `${API_BASE_PATH}/carts/SUBSCRIPTION`,
    subscriptionCreateBasePath: `${API_BASE_PATH}/carts/subscriptions`,
    subscriptionBasePath: `${API_BASE_PATH}/subscriptions`,
    subscriptionPreferenceBasePath: `${API_BASE_PATH}/subscriptions/preferences`,
    omsBasePath: `${API_BASE_PATH}/orders`,
    signUpBasePath: `${API_BASE_PATH}/customers`,
    tokenizeTaxBasePath: `${API_BASE_PATH}/customers/tokenize`,
    googleGeoBasePath: 'https://maps.googleapis.com/maps/api/geocode/json',
    loyaltyBasePath: `${API_BASE_PATH}/loyalty`,
    loyaltyPointsHistory: `${API_BASE_PATH}/loyalty/transactions`,
    inventoryPath: `${API_BASE_PATH}/inventory/binitems`,
    studioBasePath: `https://${studioDomain}/skavastream/studio/v3/skava/getPages/${studioCampaignId}/${studioEnv}`,
    tokenNuglobalBasePath: `${API_BASE_PATH}/carts/USER/payments/getTokenExAuthKey`,
    tokenNuglobalCvvPath: `${API_BASE_PATH}/carts/USER/payments/getTokenExAuthKey`,
    // local.skavacommerce is hardcoded
    // tokenBasePath: `${API_BASE_PATH}/carts/USER/paymnets/getTokenExAuthKey?origin=https://local.nuskin.com`,
    // tokenCvvPath: `${API_BASE_PATH}/carts/USER/paymnets/getTokenExAuthKey?origin=https%3A%2F%2Flocal.nuskin.com&token=`,
    signupEmailIdValidation: `${API_BASE_PATH}/customers/checkUser`,
    nextGenAccountsBasePath: `${API_BASE_PATH}/accounts/nextgen`,
    signupSponsorIdValidation: `${API_BASE_PATH}/accounts/nextgen/sponsorer/${sponsorLocale}`,
    signupConsents: `${API_BASE_PATH}/customers/consents`,
    getUserConsents: `${API_BASE_PATH}/customers/userId/consents`,
    updateUserConsents: `${API_BASE_PATH}/customers/userconsents`,
    updateUserPrivacyConsents: `${API_BASE_PATH}/customers/userconsents`,
    userEmailNotification: `${API_BASE_PATH}/customers/emailnotification`,
    qualificationCustomer: `${API_BASE_PATH}/qualification/customerQualifications`,
    foreignOrderLimit: `${API_BASE_PATH}/carts/nuskin/restriction`,
    deleteSubscriptionFromCart: `${API_BASE_PATH}/carts/nuskin/USER`,
    solrBasePath: `http://${sonarIp}/solr/${catalogCollectionId}/select`,
    oktaSession: `${oktaDomain}/api/v1/sessions/me`,
    cartPatchPath: `${API_BASE_PATH}/carts`,
    awsRecommendationBasePath: `https://${awsRecommendationDomain}/v2/market`,
  }

  const COMMON_END_POINTS = {
    mysiteCartPatch: {
      url: `${API_URL_CONFIG.cartPatchPath}`,
      method: 'PATCH',
    },
    sponsorIdValidation: {
      url: `${API_URL_CONFIG.signupSponsorIdValidation}`,
      method: 'GET',
    },
    signupEmailIdValidation: {
      url: `${API_URL_CONFIG.signupEmailIdValidation}`,
      method: 'POST',
    },
    tokenizeTax: {
      url: `${API_URL_CONFIG.tokenizeTaxBasePath}`,
      method: 'POST',
    },
    signupConsents: {
      url: `${API_URL_CONFIG.signupConsents}`,
      method: 'GET',
    },
    getUserConsents: {
      url: `${API_URL_CONFIG.getUserConsents}`,
      method: 'GET',
    },
    updateUserConsents: {
      url: `${API_URL_CONFIG.updateUserConsents}`,
      method: 'PUT',
    },
    updateUserPrivacyConsents: {
      url: `${API_URL_CONFIG.updateUserPrivacyConsents}`,
      method: 'PUT',
    },
    registerNextGen: {
      url: `${API_URL_CONFIG.nextGenAccountsBasePath}`,
      method: 'POST',
    },
    sponsorUpdateAccount: {
      url: `${API_URL_CONFIG.nextGenAccountsBasePath}`,
      method: 'PATCH',
    },
    topCategory: {
      url: `${API_URL_CONFIG.catalogBasePath}/categories/top`,
      method: 'GET',
    },
    getToken: {
      url: `${API_URL_CONFIG.tokenNuglobalBasePath}`,
      method: 'GET',
    },
    getTokenCvv: {
      url: `${API_URL_CONFIG.tokenNuglobalCvvPath}`,
      method: 'GET',
    },
    getCatalog: {
      url: `${API_URL_CONFIG.catalogBasePath}/categories`,
      method: 'GET',
    },
    getProducts: {
      url: `${API_URL_CONFIG.catalogBasePath}/products`,
      method: 'GET',
    },
    search: {
      url: `${API_URL_CONFIG.catalogBasePath}/search`,
      method: 'GET',
    },
    suggestions: {
      url: `${API_URL_CONFIG.catalogBasePath}/search/suggestions`,
      method: 'GET',
    },
    login: {
      url: `${API_URL_CONFIG.customerBasePath}/login`,
      method: 'POST',
    },
    deleteCustomer: {
      url: `${API_URL_CONFIG.customerBasePath}`,
      method: 'DELETE',
    },
    logout: {
      url: `${API_URL_CONFIG.customerBasePath}/logout`,
      method: 'DELETE',
    },
    getProfile: {
      url: `${API_URL_CONFIG.customerBasePath}`,
      method: 'GET',
    },
    updateProfile: {
      url: `${API_URL_CONFIG.customerBasePath}`,
      method: 'PATCH',
    },
    updatePassword: {
      url: `${API_URL_CONFIG.customerBasePath}/password`,
      method: 'PATCH',
    },
    register: {
      url: `${API_URL_CONFIG.customerBasePath}`,
      method: 'POST',
    },
    updateGuestCustomer: {
      url: `${API_URL_CONFIG.customerBasePath}/updateGuestCustomer`,
      method: 'PATCH',
    },
    viewCart: {
      url: `${API_URL_CONFIG.cartBasePath}`,
      method: 'GET',
    },
    viewCartForCheckout: {
      url: `${API_URL_CONFIG.cartBasePath}/checkout`,
      method: 'GET',
    },
    addToCart: {
      url: `${API_URL_CONFIG.cartBasePath}/items`,
      method: 'POST',
    },
    deleteCartItem: {
      url: `${API_URL_CONFIG.cartBasePath}/items`,
      method: 'DELETE',
    },
    removeSubscriptionCartItem: {
      url: `${API_URL_CONFIG.deleteSubscriptionFromCart}/items`,
      method: 'DELETE',
    },
    updateCartItem: {
      url: `${API_URL_CONFIG.cartBasePath}/items`,
      method: 'PATCH',
    },
    viewSubscriptionCart: {
      url: `${API_URL_CONFIG.subscriptionCartBasePath}`,
      method: 'GET',
    },
    getAccountTypes: {
      url: `${API_URL_CONFIG.accountsBasePath}/settings`,
      method: 'GET',
    },
    accounts: {
      url: `${API_URL_CONFIG.accountsBasePath}`,
      method: 'POST',
    },
    accountProfile: {
      url: `${API_URL_CONFIG.accountsBasePath}`,
      method: 'GET',
    },
    accountProfilePatch: {
      url: `${API_URL_CONFIG.accountsBasePath}`,
      method: 'PATCH',
    },
    // nextgenAccountUpgrade: {
    //   url: `${API_BASE_PATH}/accounts/nextgen`,
    //   method: 'PATCH',
    // },
    nextgenAccountUpgrade: {
      url: `${API_BASE_PATH}/accounts/nextgen`,
      method: 'PUT',
    },
    accountCheckSponsorid: {
      url: `${API_BASE_PATH}/accounts/nextgen/sponsorer`,
      method: 'GET',
    },
    nexGenAccountTypes: {
      url: `${API_BASE_PATH}/accounts/nextgen/accountType`,
      method: 'GET',
    },
    sendAssociateInviteLink: {
      url: `${API_BASE_PATH}/accounts/nextgen/associates`,
      method: 'POST',
    },
    patchAssociateInviteLink: {
      url: `${API_BASE_PATH}/accounts/nextgen/associates`,
      method: 'PATCH',
    },

    getAssociateInvitedetails: {
      url: `${API_BASE_PATH}/accounts/nextgen/associates`,
      method: 'GET',
    },

    addToSubscriptionCart: {
      url: `${API_URL_CONFIG.subscriptionCartBasePath}/items`,
      method: 'POST',
    },
    deleteSubscriptionCartItem: {
      url: `${API_URL_CONFIG.subscriptionCartBasePath}/items`,
      method: 'DELETE',
    },
    updateSubscriptionCartItem: {
      url: `${API_URL_CONFIG.subscriptionCartBasePath}/items`,
      method: 'PATCH',
    },
    updateSubscriptionCartAttributes: {
      url: `${API_URL_CONFIG.subscriptionCartBasePath}`,
      method: 'PATCH',
    },
    addPaymentToSubscription: {
      url: `${API_URL_CONFIG.subscriptionCartBasePath}/payments`,
      method: 'POST',
    },
    deletePaymentsFromSubscription: {
      url: `${API_URL_CONFIG.subscriptionCartBasePath}/payments`,
      method: 'DELETE',
    },
    getAllSubscriptions: {
      url: `${API_URL_CONFIG.subscriptionBasePath}`,
      method: 'GET',
    },
    getAllSubscriptionsPreference: {
      url: `${API_URL_CONFIG.subscriptionPreferenceBasePath}`,
      method: 'GET',
    },
    updateSubscriptionsPreference: {
      url: `${API_URL_CONFIG.subscriptionPreferenceBasePath}`,
      method: 'PATCH',
    },
    postSubscriptionsPreference: {
      url: `${API_URL_CONFIG.subscriptionPreferenceBasePath}`,
      method: 'POST',
    },
    postSubscriptionItemLevelProcessing: {
      url: `${API_URL_CONFIG.subscriptionBasePath}`,
      method: 'PATCH',
    },
    getAllPvAssistItem: {
      url: `${API_URL_CONFIG.subscriptionPreferenceBasePath}/pvassist`,
      method: 'GET',
    },
    getSubscriptionById: {
      url: `${API_URL_CONFIG.subscriptionBasePath}`,
      method: 'GET',
    },
    updateSubscription: {
      url: `${API_URL_CONFIG.subscriptionBasePath}`,
      method: 'PATCH',
    },
    calculateSubscriptionDate: {
      url: `${API_URL_CONFIG.subscriptionCreateBasePath}/firstdeliverydate`,
      method: 'GET',
    },
    confirmSubscription: {
      url: `${API_URL_CONFIG.subscriptionCreateBasePath}/confirm`,
      method: 'POST',
    },
    geoCode: {
      url: `${API_URL_CONFIG.googleGeoBasePath}`,
      method: 'GET',
    },
    updateBagAttributes: {
      url: `${API_URL_CONFIG.cartBasePath}`,
      method: 'PATCH',
    },
    getPickUpLocations: {
      url: `${API_URL_CONFIG.basePath}/locations`,
      method: 'GET',
    },
    addPaymentToCart: {
      url: `${API_URL_CONFIG.cartBasePath}/payments`,
      method: 'POST',
    },
    addAlipayToCart: {
      url: `${API_URL_CONFIG.cartBasePath}/payments/alipayIntiate`,
      method: 'POST',
    },
    addKlarnaToCart: {
      url: `${API_URL_CONFIG.cartBasePath}/payments/klarnaIntiate`,
      method: 'POST',
    },
    getPayPalToken: {
      url: `${API_URL_CONFIG.cartBasePath}/payments/braintreeToken`,
      method: 'GET',
    },
    deletePaymentsFromCart: {
      url: `${API_URL_CONFIG.cartBasePath}/payments`,
      method: 'DELETE',
    },
    deletePaymentByIdFromCart: {
      url: `${API_URL_CONFIG.cartBasePath}/payments`,
      method: 'DELETE',
    },
    getBrainTreePaymentToken: {
      url: `${API_URL_CONFIG.cartBasePath}/payments/braintreeToken`,
      method: 'GET',
    },
    deleteLoyaltyRewardsFromCart: {
      url: `${API_URL_CONFIG.cartBasePath}/loyaltyRewards`,
      method: 'DELETE',
    },
    klarnaTransactionStatus: {
      url: `${API_URL_CONFIG.cartBasePath}/payments/klarnaStatus`,
      method: 'POST',
    },
    reviewOrder: {
      url: `${API_URL_CONFIG.checkoutBasePath}`,
      method: 'GET',
    },
    submitOrder: {
      url: `${API_URL_CONFIG.checkoutBasePath}`,
      method: 'POST',
    },
    getGlobalPaymentOptions: {
      url: `${API_URL_CONFIG.cartBasePath}/payments/globalPaymentOptions`,
      method: 'GET',
    },
    submitOrderForGlobalPayment: {
      url: `${API_URL_CONFIG.cartBasePath}/payments/gpsInitiate`,
      method: 'POST',
    },
    getAllOrders: {
      url: `${API_URL_CONFIG.omsBasePath}`,
      method: 'GET',
    },

    suggestedOrders: {
      url: `${API_URL_CONFIG.omsBasePath}`,
      method: 'GET',
    },

    getOrderById: {
      url: `${API_URL_CONFIG.omsBasePath}`,
      method: 'GET',
    },
    cancelItem: {
      url: `${API_URL_CONFIG.omsBasePath}`,
      method: 'PATCH',
    },
    returnItem: {
      url: `${API_URL_CONFIG.omsBasePath}`,
      method: 'POST',
    },
    updateOrder: {
      url: `${API_URL_CONFIG.omsBasePath}`,
      method: 'PATCH',
    },
    signUp: {
      url: `${API_URL_CONFIG.signUpBasePath}`,
      method: 'POST',
    },
    resetPassword: {
      url: `${API_URL_CONFIG.customerBasePath}/resetpassword`,
      method: 'POST',
    },
    validateResetPassword: {
      url: `${API_URL_CONFIG.customerBasePath}/resetpassword`,
      method: 'PATCH',
    },
    resetBySecurityQuestions: {
      url: `${API_URL_CONFIG.customerBasePath}/securityquestions/password`,
      method: 'POST',
    },
    addPromo: {
      url: `${API_URL_CONFIG.cartBasePath}/promos`,
      method: 'POST',
    },
    deletePromo: {
      url: `${API_URL_CONFIG.cartBasePath}/promos`,
      method: 'DELETE',
    },
    getStudioPage: {
      url: `${API_URL_CONFIG.studioBasePath}`,
      method: 'GET',
    },
    exportData: {
      url: `${API_URL_CONFIG.customerBasePath}/export`,
      method: 'POST',
    },
    loginAsCSR: {
      url: `${API_URL_CONFIG.customerBasePath}/sessions`,
      method: 'POST',
    },
    refreshSession: {
      url: `${API_URL_CONFIG.customerBasePath}/sessions/refresh`,
      method: 'POST',
    },
    getLoyalty: {
      url: `${API_URL_CONFIG.loyaltyBasePath}`,
      method: 'GET',
    },
    updateRewardsLoyaltyOptIn: {
      url: `${API_URL_CONFIG.customerBasePath}/opt`,
      method: 'POST',
    },
    getLoyaltyPoints: {
      url: `${API_URL_CONFIG.loyaltyBasePath}`,
      method: 'GET',
    },
    getLoyaltyPointHistory: {
      url: `${API_URL_CONFIG.loyaltyPointsHistory}`,
      method: 'GET',
    },
    getAllRatingsAndReviews: {
      url: `${API_URL_CONFIG.reviewBasePath}`,
      method: 'GET',
    },
    getReviewById: {
      url: `${API_URL_CONFIG.reviewBasePath}`,
      method: 'GET',
    },
    uploadImagesToReviews: {
      url: `${API_URL_CONFIG.reviewBasePath}`,
      method: 'POST',
    },
    createRatingAndReview: {
      url: `${API_URL_CONFIG.reviewBasePath}/createreview`,
      method: 'POST',
    },
    createReviewFeedback: {
      url: `${API_URL_CONFIG.reviewBasePath}/feedback`,
      method: 'POST',
    },
    getRatingsSummary: {
      url: `${API_URL_CONFIG.reviewBasePath}/ratingssummary`,
      method: 'GET',
    },
    getActiveAndLiveCount: {
      url: `${API_URL_CONFIG.subscriptionBasePath}/getactiveandlivecount`,
      method: 'GET',
    },
    updateCartToSubscription: {
      url: `${API_URL_CONFIG.subscriptionBasePath}/`,
      method: 'POST',
    },
    skipSubscription: {
      url: `${API_URL_CONFIG.subscriptionBasePath}`,
      method: 'PATCH',
    },
    addToSubscription: {
      url: `${API_URL_CONFIG.subscriptionBasePath}`,
      method: 'POST',
    },
    cancelAllSubscription: {
      url: `${API_URL_CONFIG.subscriptionBasePath}/user`,
      method: 'PATCH',
    },
    getStores: {
      url: `${API_BASE_PATH}/foundation/store`,
      method: 'GET',
    },
    getProductbyIdandPromotion: {
      url: `${API_URL_CONFIG.catalogBasePath}/products`,
      method: 'POST',
    },
    scanCardValidate: {
      url: `${API_URL_CONFIG.basePath}/scancard/validatescancard`,
      method: 'GET',
    },
    getscanCardDetails: {
      url: `${API_URL_CONFIG.basePath}/scancard`,
      method: 'GET',
    },
    getPromoCodeList: {
      url: `${API_URL_CONFIG.cartBasePath}/promocodes`,
      method: 'GET',
    },
    createscanCard: {
      url: `${API_URL_CONFIG.basePath}/scancard`,
      method: 'POST',
    },
    removeScanCardDetails: {
      url: `${API_URL_CONFIG.basePath}/scancard`,
      method: 'DELETE',
    },
    getInventory: {
      url: `${API_URL_CONFIG.inventoryPath}`,
      method: 'GET',
    },
    getOktaAuthorize: {
      url: `${API_URL_CONFIG.customerBasePath}/nextgen/okta/authorize`,
      method: 'GET',
    },
    emailNotification: {
      url: `${API_URL_CONFIG.userEmailNotification}`,
      method: 'POST',
    },
    qualificationUser: {
      url: `${API_URL_CONFIG.qualificationCustomer}`,
      method: 'GET',
    },
    foreignOrderLimit: {
      url: `${API_URL_CONFIG.foreignOrderLimit}`,
      method: 'POST',
    },
    // footerData: {
    //   url: nuskinFooter,
    //   method: 'GET',
    // },
    footerData: {
      // url: csLink,
      // URL is constructed in apiUtils-> fetchResponseFromAPI function
      method: 'GET',
    },
    studioData: {
      // url: csLink,
      // URL is constructed in apiUtils-> fetchResponseFromAPI function
      method: 'GET',
    },
    liveEventStudioData: {
      // url: csLink,
      // URL is constructed in apiUtils-> fetchResponseFromAPI function
      method: 'GET',
    },
    postalCodeLookup: {
      url: `${API_URL_CONFIG.customerBasePath}/plugin/postalcode`,
      method: 'POST',
    },
    emailLookupMx: {
      url: `${emailValidationDomain}/validateEmail`,
      method: 'POST',
    },
    emailLookupInfo: {
      url: `${emailLookupInfoDomain}/email-tools/v1/emailInfo`,
      method: 'GET',
    },
    clearOktaUserSession: {
      url: oktaClearUserSession,
      method: 'GET',
    },
    getOktaSession: {
      url: API_URL_CONFIG.oktaSession,
      method: 'GET',
    },
    getAwsRecommendedProducts: {
      url: API_URL_CONFIG.awsRecommendationBasePath,
      method: 'GET',
    },
    checkPhoneNumberUniqueness: {
      url: phoneNumberUniquenessDomainUrl,
      method: 'POST',
    },
    sendPhoneVerificationCode: {
      url: `${smsVerifyDomain}/v1/mobile/verify`,
      method: 'POST',
    },
    checkPhoneVerificationCode: {
      url: `${smsVerifyDomain}/v1/mobile/verify/check`,
      method: 'POST',
    },
  }

  if (sonarIp && catalogCollectionId) {
    COMMON_END_POINTS['productFromSolr'] = {
      url: `${API_URL_CONFIG.solrBasePath}`,
      method: 'GET',
    }
  }

  return {
    ...COMMON_END_POINTS,
    ...getB2CEndPoints(API_BASE_PATH),
    ...getB2BEndPoints(API_BASE_PATH),
  }
}
export { getCommonEndPoints }
