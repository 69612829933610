import React from 'react'
import { Collapse, Button } from 'react-bootstrap'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { application, getLiveEventStatus } from 'src/utils'
import {
  IoIosArrowDown as DownwardArrowIcon,
  IoIosArrowUp as UpwardArrowIcon,
} from 'react-icons/io'
import './styles.scss'

@observer
class AccordionBlock extends React.Component {
  @observable open = this.props?.isDefaultOpen || false

  static defaultProps = {
    title: 'submit',
    onClick: () => {},
    index: 1,
    isActive: '',
    hasArrow: true,
    disabled: false,
    isOutsideAccordion: false,
    type: '',
  }
  enableLiveEvent = getLiveEventStatus()
  handleClick = event => {
    let isAccordianClicked = true
    event.persist()
    // prop type for value not from text content
    const {
      isActive = this.defaultProps.isActive,
      index = this.defaultProps.index,
      onClick = this.defaultProps.onClick,
      type = this.defaultProps.type,
      uniqueKey = '',
    } = this.props || {}

    const selectedFacet = event.currentTarget.textContent
    this.open = isActive !== '' ? !isActive : !this.open

    const eventData = {
      index,
      isOpen: this.open,
      selectedFacet,
      type,
      isAccordianClicked,
      uniqueKey,
    }
    onClick(eventData)
  }

  render() {
    const {
      isActive = false,
      dataTestId = '',
      title = '',
      hasArrow = false,
      children = '',
      isMerchPLP = false,
      renderIcon = false,
      disabled = false,
      capitalize = true,
      textClass = '',
      iconClass = '',
      subItemsClass = '',
      isOutsideAccordion = false,
    } = this.props || {}

    const isOpen = isActive !== '' ? isActive : this.open
    const isVisible = isMerchPLP ? (isOpen ? 'active' : 'disable') : ''

    const updateIcon = renderIcon ? (
      renderIcon(isOpen)
    ) : isOpen ? (
      <UpwardArrowIcon aria-hidden={true} />
    ) : (
      <DownwardArrowIcon aria-hidden={true} />
    )

    const className = this.props?.className
      ? this.props.className
      : 'mb-2 px-2 py-3'

    return (
      <>
        {!application.isMobile ? (
          <div
            no-gutters="true"
            className={`accordion-block-wrapper ${className} ${
              isOpen ? 'accordion-block-wrapper-active' : ''
            }`}>
            {title && (
              <Button
                data-testid={dataTestId}
                variant="light"
                disabled={disabled}
                className={`accordion-block-common-title-button justify-content-end d-flex bg-transparent p-0 border-0 align-items-center ${
                  this.enableLiveEvent === 'true' ? 'event-cursor' : ''
                }`}
                size="lg"
                aria-expanded={isOpen}
                block="true"
                onClick={this.handleClick}>
                <span
                  className={`accordion-block-text accordion-kit-text ${isVisible} flex-grow-1 flex-shrink-1 text-left ${
                    capitalize ? 'text-capitalize' : ''
                  } ${textClass} text-break`}>
                  {title}
                </span>
                {hasArrow && (
                  <div
                    className={`accordion-block-icon justify-content-center d-flex align-items-center ${iconClass}`}>
                    {updateIcon}
                  </div>
                )}
              </Button>
            )}
            {isOutsideAccordion === false && (
              <Collapse
                in={isOpen}
                className="accordion-block-collapse-section w-100 mt-3 ">
                <div id="collapse-text">{children}</div>
              </Collapse>
            )}
          </div>
        ) : (
          <div
            no-gutters="true"
            className={`accordion-block-wrapper ${className}`}>
            {title && (
              <Button
                aria-expanded={isOpen}
                data-testid={dataTestId}
                variant="dark"
                disabled={disabled}
                className={`accordion-block-common-title-button justify-content-end d-flex bg-transparent p-0 border-0 align-items-center mt-0 ${
                  this.enableLiveEvent === 'true' ? 'event-cursor' : ''
                }`}
                size="lg"
                block="true"
                onClick={this.handleClick}>
                <span
                  className={`accordion-block-text ${isVisible} flex-grow-1 flex-shrink-1 text-left text-dark ${
                    capitalize ? 'text-capitalize' : ''
                  } ${textClass} text-break`}>
                  {title}
                </span>
                {hasArrow && (
                  <div
                    className={`accordion-block-icon justify-content-center d-flex align-items-center text-primary ${iconClass}`}>
                    {updateIcon}
                  </div>
                )}
              </Button>
            )}
            {isOutsideAccordion === false && (
              <Collapse
                in={isOpen}
                className="accordion-block-collapse-section w-100 mt-3 col-10 offset-1 px-0">
                <div id="collapse-text">{children}</div>
              </Collapse>
            )}
          </div>
        )}
        {isOutsideAccordion && (
          <Collapse
            timeout={300}
            in={isOpen}
            className={`accordion-block-collapse-section mt-2 w-100 mb-2 p-0 ${subItemsClass}`}>
            <div id="collapse-text">{children}</div>
          </Collapse>
        )}
      </>
    )
  }
}

export default AccordionBlock
export { AccordionBlock }
