import { i18nTranslate } from 'src/utils'
import {
  customerContainer,
  accountsContainer,
  storeContainer,
} from 'src/models'
import { toastState } from 'src/views/components'
import { getLocalStorage } from 'src/utils'
import { consentIpAddress, APPConfig } from 'config/appConfig'

function getCategoryLinks(category, isTopLevelCategory) {
  const { identifier, isFromOurImpact = false, properties } = category
  const templateUrl = category?.milestone?.templateUrl || ''
  const templateId = category?.milestone?.templateId || ''
  const templateName = templateUrl
    .split('/')
    .find(item => item.match(/template[\d]/))
  if (isFromOurImpact) {
    return properties?.href
  } else {
    const template =
      templateName || (templateId ? `template${templateId}` : `catalog`)
    const url = isTopLevelCategory ? '/categoryLanding/' : `/${template}/`
    return `${url}${identifier}`
  }
}

async function getConsent(term1, term2, acceptancePayload) {
  const { consents } = await customerContainer.signupConsents()
  const isToEnableIronClad =
    APPConfig?.getAppConfig()?.FEATURE_IRONCLAD_IMPLEMENTATION === 'true' ||
    false
  const otherInfo = {
    browser: 'chrome',
    device: 'mobile',
    geoLocation:
      storeContainer.currentRegion || getLocalStorage('currentRegion') || '',
    ipAddress: consentIpAddress,
  }
  const signUpConsents = []

  consents.forEach(consent => {
    const Id = consent.id
    let consentInfo = ''

    if (
      term1 &&
      consent.title === 'I acknowledge I have read Sales performance'
    ) {
      consentInfo = {
        id: Id,
        consentId: Id,
        ...otherInfo,
        createdTimestamp: consent.createdTimestamp,
        updatedTimestamp: consent.updatedTimestamp,
      }
      signUpConsents.push(consentInfo)
    }
    if (
      term2 &&
      consent.title === 'I have Read, Understand, Agree to BA agreement'
    ) {
      consentInfo = {
        id: Id,
        consentId: Id,
        ...otherInfo,
        createdTimestamp: consent.createdTimestamp,
        updatedTimestamp: consent.updatedTimestamp,
      }
      signUpConsents.push(consentInfo)
    }
    if (consent.title === 'Terms & Conditions') {
      consentInfo = {
        id: Id,
        consentId: Id,
        ...otherInfo,
        createdTimestamp: consent.createdTimestamp,
        updatedTimestamp: consent.updatedTimestamp,
      }
      signUpConsents.push(consentInfo)
    }
  })

  if (isToEnableIronClad) {
    const acceptanceInfoPage1 = {
      ...otherInfo,
      id: accountsContainer?.IronCladGroupId,
      title: 'contracts_ironclad_page1',
      acceptanceData: acceptancePayload?.[0],
    }
    const acceptanceInfoPage2 = {
      ...otherInfo,
      id: accountsContainer?.IronCladGroupId,
      title: 'contracts_ironclad_page2',
      acceptanceData: acceptancePayload?.[1],
    }
    const acceptanceInfoPage3 = {
      ...otherInfo,
      id: accountsContainer?.IronCladGroupId,
      title: 'contracts_ironclad_page3',
      acceptanceData: acceptancePayload?.[2],
    }
    signUpConsents.push(
      acceptanceInfoPage1,
      acceptanceInfoPage2,
      acceptanceInfoPage3
    )
  }
  const accType = getLocalStorage('accountType')
  const accountId = customerContainer.accountId
  const typeId = accountsContainer.profileResponse.typeId
  const name = accountsContainer.profileResponse.name

  const postData = {
    conversionType: 'TEMP_2_PERM',
    userConsent: [],
    account: {
      id: accountId,
      name: name,
      typeId: typeId,
      type: accType,
      addresses: [{}],
      customer: {},
    },
  }
  postData.userConsent = signUpConsents
  const loadParams = {
    endPointName: 'nextgenAccountUpgrade',
    pathParams: `${accountId}/upgrade`,
    postData,
  }

  const response = await customerContainer?.fetchResponse(loadParams)
  if (customerContainer?.isSuccessResponse(response)) {
    accountsContainer.signUpIronCladCheck = {
      clickWrap1: false,
      clickWrap2: false,
      clickWrap3: false,
    }
    toastState?.setToastMessage(
      i18nTranslate(
        'temptoPermSuccess',
        'Congratulations! Your account has been successfully converted from temporary to permanent',
        { nameSpace: 'ssr-resource' }
      ),
      true
    )
    return response
  } else {
    toastState.setToastMessage(
      i18nTranslate(
        'temptoPermFailure',
        'Temporary to Permanent account conversion failure, please try again later ',
        { nameSpace: 'ssr-resource' }
      ),
      false
    )
  }
}

export { getCategoryLinks, getConsent }
