import { observable, action } from 'mobx'
import { productState } from 'src/views/components'

class StorePickupState {
  @observable storePickupProducts = {}
  @observable skuId = ''
  @observable zipcode = ''
  @observable showStorePickup = false
  productId = ''

  handleStoreChange = (e, props = {}) => {
    const { store = {} } = props
    const storeName = store.storeName + ',' + store.storeAddress || ''
    this.storePickupProducts[this.skuId] = storeName
    productState.selectPickupType = 'pick-in-store'

    this.toggleStorePickupModal()
  }

  @action
  toggleStorePickupModal = (skuId = '', productId = '') => {
    this.showStorePickup = !this.showStorePickup
    this.skuId = skuId
    this.productId = productId
  }

  removeFromStorePickup = (skuId = '') => {
    delete this.storePickupProducts[skuId]
  }
}

const storePickupState = new StorePickupState()

export { storePickupState }
export default storePickupState
