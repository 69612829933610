import React from 'react'
import {
  Col,
  Row,
  OverlayTrigger,
  Form,
  Tooltip,
  Button,
} from 'react-bootstrap'
import { IoIosInformationCircleOutline as InformationIcon } from 'react-icons/io'
import {
  isLoyEnableInProf,
  isAllowedRewardTypeForOptIn,
  isBrandAffiliateTypeProfile,
} from 'src/views/pages/MyAccount/MyAccountProfile/LoyaltyForm/fixture'
import { i18nTranslate, getLiveEventStatus, isExpressCheckout } from 'src/utils'
import { currencyFormat } from 'src/utils'
import {
  cartContainer,
  customerContainer,
  checkoutContainer,
  loyaltyContainer,
  CheckoutContainer,
} from 'src/models'
import './styles.scss'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { CommonForm } from 'src/views/components'
import { formInput } from './formInput'
import { schema } from './validationSchema'
import { pageNames } from 'src/routes/pathParams'
import { getLocalStorage, IS_BROWSER } from 'src/utils'
import { APPConfig, convertToBoolean } from 'config/appConfig'
import { storeContainer } from 'src/models/Store'

@observer
class Summary extends React.Component {
  constructor(props) {
    super(props)
  }

  static defaultProps = {
    showColon: true,
  }

  enableLiveEvent = getLiveEventStatus()
  enableInclusiveTaxInCheckout = convertToBoolean(
    APPConfig?.getAppConfig()?.taxConfig?.inclusiveTaxEnabled
  )
  regionTaxPrice = 0
  categoryTaxPrice = 0
  taxConfig = storeContainer.getTaxConfig() || {}
  isRegionTaxenable =
    convertToBoolean(this.taxConfig?.isRegionTaxenable) || false
  isCategoryTaxenable =
    convertToBoolean(this.taxConfig?.isCategoryTaxenable) || false
  isInclusiveTaxEnable =
    convertToBoolean(this.taxConfig?.inclusiveTaxEnabled) || false
  formatPrice = (priceLabel = '') => {
    // const { math, currencyCode, locale = '' } = this.props
    const { math = {} } = this.props
    let price = math[priceLabel] || '0.00'
    if (isNaN(price)) {
      price = '0.00'
    }

    const finalCost = currencyFormat({
      // currency: currencyCode,
      price,
      // defaultLocale: locale,
    })
    return finalCost
  }

  countt = (count = 0) => {
    const { math = {} } = this.props
    let countt = math[count] || 0
    return countt
  }

  freeProducts = () => {
    let skus = []
    let freeGiftProducts = []
    const items = cartContainer?.cartResponse?.items
    items?.map(item => {
      if (item.properties && item.properties.isGiftItem) {
        freeGiftProducts.push(item)
      }
    })
    if (freeGiftProducts.length > 0) {
      for (let i = 0; i < freeGiftProducts.length; i++) {
        skus.push(freeGiftProducts[i].skus[0].skuId)
      }
    }
    return skus
  }

  /**
   * @description Show both items level and cart level promotions , on hovering the info icon near discounts in order summary
   * @date 10/16/2023 - 12:05:22 PM
   *
   */
  getCartPromotions = () => {
    //CX16-11092 - For minicart view no itemPromotions passed as props, so we only displayed cart level promotions - isMiniCart true only for minicart view
    //We could see both cart level and item level promotions display from viewcart and checkout summary section
    const { promotionDetails = [], itemsPromotions = [] } = this.props
    const overallPromotions = [...promotionDetails, ...itemsPromotions]
    let result = []
    overallPromotions?.map(promotion => {
      const promoBreakup = promotion?.breakup || []
      const promoBreakupWithDiscount = promoBreakup?.filter(promo => {
        return (
          promo?.isGift ||
          (promo?.totalValue && promo?.identifier.toLowerCase() != 'loyalty')
        )
      })
      result.push(...promoBreakupWithDiscount)
    })
    return result
  }
  renderCartPromotions = (promoBreakup = []) => {
    let tempPromoArray = []
    return promoBreakup?.map((promotion, index) => {
      // Excluding shipping promotions from overall cart promotions - CX16-11092
      if (promotion?.type !== 'DELIVERYCHARGE') {
        const NotOutOfStock = promotion?.isGift
          ? this.freeProducts()?.includes(promotion?.skuId)
          : true
        const displayMessage = promotion?.displayMessage || ''
        const message = promotion?.message || displayMessage || ''
        if (!tempPromoArray?.includes(promotion?.identifier)) {
          tempPromoArray?.push(promotion?.identifier)
          return (
            <>
              {NotOutOfStock && (
                <div className="cart-promotions" key={index} role="alert">
                  {message}
                </div>
              )}
            </>
          )
        }
      }
    })
  }
  renderCartPromotionsWithHeading = () => {
    /* to display cartLevelPromotions & itemLevelPromotions in summary*/
    const cartPromotions = this.getCartPromotions()
    if (cartPromotions?.length) {
      const promotionsView = this.renderCartPromotions(cartPromotions)
      return (
        <Col
          lg={12}
          className="shopping-cart-promotions p-0 d-flex flex-column text-lg-left text-sm-right mb-2 mt-2"
          data-testid="qa-cart-promotions">
          <strong>
            {i18nTranslate('cart.promotions', 'Promotions Applied')}
          </strong>
          {promotionsView}
        </Col>
      )
    }
    return <></>
  }
  renderPromotionsToolTip = (disountLabel = '') => {
    /* to display cartLevelPromotions as tooltip*/
    const cartPromotions = this.getCartPromotions()
    if (cartPromotions?.length) {
      const promotionsView = this.renderCartPromotions(cartPromotions)
      return (
        <>
          <OverlayTrigger
            placement="top-end"
            delay={{ show: 100, hide: 250 }}
            overlay={
              <div className="promotions-tooltip">{promotionsView}</div>
            }>
            <InformationIcon
              className="cart-promo-info-icon"
              tabIndex={0}
              aria-label={disountLabel}
            />
          </OverlayTrigger>
        </>
      )
    }
    return <></>
  }

  renderOrderSummaryDiscountDetails = orderDiscounts => {
    return orderDiscounts?.map((discount, index) => {
      const discountLabel = discount?.name || ''
      return <div key={`discount-${discountLabel}`}>{discountLabel}</div>
    })
  }

  renderOrderSummaryDiscountToolTip = () => {
    const orderPromotions = this.props?.orderPromotions || []
    if (orderPromotions?.length) {
      const promotionsView =
        this.renderOrderSummaryDiscountDetails(orderPromotions)
      return (
        <OverlayTrigger
          placement="top"
          rootClose
          delay={{ show: 100, hide: 250 }}
          overlay={
            <Tooltip
              id="discount-details-tooltip"
              className="discounts-tooltip">
              <div className="discount-details">{promotionsView}</div>
            </Tooltip>
          }>
          <InformationIcon
            aria-label="Information tooltip"
            tabIndex={0}
            size="1.5rem"
            id="info-icon-discount-details"
            className="infotool ml-1"
          />
        </OverlayTrigger>
      )
    }
    return <></>
  }

  renderCOStateTaxTooltip = () => {
    let customClassName =
      this.props?.isMiniCart === true
        ? 'state-tax-tooltip'
        : 'state-tax-overlay'

    const coloradoDeliveryFee = i18nTranslate(
      'summary.coloradoDeliveryFee',
      'Colorado Delivery Fee'
    )
    return (
      <OverlayTrigger
        trigger="click"
        placement="top"
        rootClose
        tabIndex={0}
        overlay={
          <Tooltip
            id="state-tax-tooltip"
            className={`${customClassName}`}
            tabIndex={0}
            data-testid="qa-attribute-colorado-delivery-fees-tooltip">
            <div className="state-tax-details">
              <span className="pr-1">
                {i18nTranslate(
                  'summary.coloradoDeliveryFeeText',
                  'Colorado Delivery Fee per'
                )}
              </span>
              <span className="co-tax-link">
                <a
                  name="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="qa-attribute-senate-bill"
                  href="https://tax.colorado.gov/retail-delivery-fee">
                  <u>
                    {i18nTranslate(
                      'summary.senateBillText',
                      'Senate Bill 23-143'
                    )}
                  </u>
                </a>
              </span>
            </div>
          </Tooltip>
        }>
        <Button
          aria-label={coloradoDeliveryFee}
          className="state-tooltip-button p-0"
          data-testid="qa-attribute-colorado-delivery-fees-icon">
          <InformationIcon
            aria-label={coloradoDeliveryFee}
            size="1.5rem"
            id="info-icon-discount-details"
            className="infotool"
          />
        </Button>
      </OverlayTrigger>
    )
  }
  getPrice = (custompriceValue = 0) => {
    const formattedPrice = currencyFormat({
      price: custompriceValue,
    })
    const formattedCustomPrice = formattedPrice
    return formattedCustomPrice
  }

  @action
  handleGiftSelectCheckbox = () => {
    checkoutContainer.giftPayLoad = !checkoutContainer.giftPayLoad
    checkoutContainer.giftMessage = ''
  }

  @action
  /**
   * Toggles the dropShippingPayLoad boolean and sets
   * giftPayLoad to true if dropShippingPayLoad is now true.
   */
  handleDropShippingSelectCheckbox = () => {
    checkoutContainer.dropShippingPayLoad =
      !checkoutContainer.dropShippingPayLoad
    if (checkoutContainer.dropShippingPayLoad) {
      checkoutContainer.giftPayLoad = true
    }
  }
  handleChangeValue = e => {
    const { value = '' } = e.target
    checkoutContainer.giftMessage = value
  }

  handleSubmit = (props = {}) => {
    checkoutContainer.giftMessage = props?.giftMessage || ''
  }
  getPriceWithCurrency = (priceValue = 0) => {
    const formattedPrice = currencyFormat({
      price: priceValue,
    })
    return formattedPrice
  }

  getTotalInclusiveTax = (totalInclusiveTax = 0) => {
    return (
      <div data-testid="qa-inclusiveTaxEnabled">
        <p data-testid="qa-totalInclusiveTax">
          {i18nTranslate(
            'checkout.summary.totalInclusiveTax',
            `Taxes: ({totalInclusiveTax})`
          ).replace('{totalInclusiveTax}', totalInclusiveTax)}
        </p>
      </div>
    )
  }
  getSummaryText = () => {
    return (
      <div>
        <p data-testid="qa-summary-declined-messages">
          {i18nTranslate(
            'checkout.summary.declinedMessage',
            'If your card was declined and has funds, please contact your issuing bank to authorize the purchase.'
          )}
        </p>
        <p data-testid="qa-summary-hoursToProcess-messages">
          {i18nTranslate(
            'checkout.summary.hoursToProcess',
            'The order takes 24-48 hours to process after payment is made.'
          )}
        </p>
        <p data-testid="qa-summary-totalTaxes-messages">
          {i18nTranslate(
            'checkout.summary.totalTaxes',
            'The total taxes include:'
          )}
        </p>
        <ul>
          <li data-testid="qa-summary-productTax-messages">
            {i18nTranslate(
              'checkout.summary.productTax',
              'Product Tax (Included in the product price)'
            )}
          </li>
          <li data-testid="qa-summary-shippingTax-messages">
            {i18nTranslate(
              'checkout.summary.shippingTax',
              'Shipping Tax (Included in the total shipping)'
            )}
          </li>
          <li data-testid="qa-summary-categoryTax-messages">
            {i18nTranslate('checkout.summary.categoryTax', 'Tax Category Tax')}
          </li>
          <li data-testid="qa-summary-regionalTax-messages">
            {i18nTranslate(
              'checkout.summary.regionalTax',
              'Regional Tax (If applicable)'
            )}
          </li>
        </ul>
      </div>
    )
  }

  render() {
    const {
      math = {},
      isReviewSection = false,
      isOrderHistory = false,
      isCheckout = false,
      isMiniCart = false,
      customClass = '',
      isMultipleAdressActive = false,
      showColon = false,
      isViewCart = false,
      totalValue = {},
    } = this.props

    const oneTimeItemsTotal =
      cartContainer.cartResponse?.properties?.oneTimeItemsTotal
    const formattedPrice = oneTimeItemsTotal?.replace(/[$,]/g, '') || '' // Removes $ and ,
    const formattedOneTimeItemsTotal = this.getPriceWithCurrency(formattedPrice)
    const subsctiptionItemsTotal =
      cartContainer.cartResponse?.properties?.subsctiptionItemsTotal
    const localvalue = getLocalStorage('accountType')
    const CVPrice = totalValue?.CV?.CV || 0
    const PVPrice = totalValue?.PV?.PV || 0
    const CVPriceDis = totalValue?.CV?.CVAfterDiscount || 0
    const PVPriceDis = totalValue?.PV?.PVAfterDiscount || 0
    const overAllDiscount = math?.overAllDiscount || 0
    const totalDiscountWithoutShipping = math?.totalDiscount || 0
    const loyaltyDiscount = math?.loyaltyDiscount || 0
    const redeemedLoyaltyPoints = math?.redeemedLoyaltyPoints || 0
    const discountNodeFromMathObj = isOrderHistory
      ? overAllDiscount
      : totalDiscountWithoutShipping
    const discount = Math.abs(discountNodeFromMathObj) - loyaltyDiscount
    const CVPriceOriginal = parseFloat(CVPrice).toFixed(2)
    const PVPriceOriginal = parseFloat(PVPrice).toFixed(2)
    const CVPriceDiscount = parseFloat(CVPriceDis).toFixed(2)
    const PVPriceDiscount = parseFloat(PVPriceDis).toFixed(2)
    const totalDiscount = this.getPrice(discount)
    const earnedLoyaltypoint = cartContainer.earnedLoyaltyPoints()
    const isShippingFree =
      math.cartDeliveryCharge &&
      math.cartDeliveryChargeDiscount === math.cartDeliveryCharge &&
      math.cartDeliveryCharge !== 0
        ? true
        : false
    const shippingCost = isMultipleAdressActive
      ? math.itemDeliveryCharge
      : math.cartDeliveryCharge
    const displayShippingCost = this.formatPrice('cartDeliveryCharge')
    const displayShippingDiscount = this.formatPrice('cartDeliveryDiscount')
    const cartShippingDiscount =
      (math?.cartDeliveryChargeDiscount > 0 ? '-' : '') +
      this.formatPrice('cartDeliveryChargeDiscount')
    const loyaltydetails = cartContainer?.cartResponse?.loyaltyDetails
    const discountModel = loyaltyContainer.getDiscountModelRewardTypes()
    const isGuestOrUser = customerContainer.isRegisterUser
    const totalText =
      (isOrderHistory === true &&
        i18nTranslate('summary.totalText', 'Total')) ||
      (isReviewSection === true
        ? i18nTranslate('summary.grandText', 'Grand Total')
        : i18nTranslate('summary.estimatedText', 'Estimated Total'))

    const wrapperClassName =
      isOrderHistory === true ||
      isMiniCart === true ||
      isReviewSection === true ||
      isCheckout === true
        ? ''
        : 'text-right'
    const reviewSecloyaltyTextAlign = isReviewSection ? 'text-left' : ''
    const orderHisClassName =
      isOrderHistory === true
        ? 'order-summary-price-panel'
        : 'shopping-cart-price-panel'
    const customClassName = isOrderHistory === true ? '' : 'mr-1'
    const rightSpanCls = !isOrderHistory ? 'right-span' : ''
    const userRole = customerContainer.profileResponse?.userrole || ''
    const overAllTax = math.overAllTax || 0
    const totalInclusiveTax = this.isInclusiveTaxEnable
      ? this.formatPrice('totalInclusiveTax')
      : ''

    const subsSubTotalLabel = i18nTranslate(
      'cart.subsSubTotal',
      'Subs. SubTotal'
    )
    const oneTimeSubTotalLabel = i18nTranslate(
      'cart.oneTimeSubTotal',
      'One time subtotal'
    )

    const subTotalLabel =
      i18nTranslate('cart.subtotal', 'Subtotal') +
      ' ' +
      this.countt('count') +
      ' ' +
      i18nTranslate('cart.cartItems', 'items')

    const priceAfterMarkdown = this.formatPrice('priceAfterMarkdown')

    const shippingLabel =
      isOrderHistory || isReviewSection === true
        ? i18nTranslate('cart.shipping', 'Shipping')
        : i18nTranslate('cart.estimateShipping', 'Estimated Shipping')
    const disountLabel = i18nTranslate('cart.discount', 'Discount')
    const shippingDisountLabel = i18nTranslate(
      'cart.shippingDiscount',
      'Shipping Discount'
    )

    const totalDiscountPrice =
      isOrderHistory === true
        ? (overAllDiscount < 0 ? '-' : '') + totalDiscount
        : (totalDiscountWithoutShipping > 0 ? '-' : '') + totalDiscount

    const loyaltyPointsLabel = i18nTranslate(
      'summary.loyaltyPoints',
      'Loyalty Points'
    )
    const reedeemedLoyalPoints =
      (redeemedLoyaltyPoints > 0 ? '-' : '') +
      this.formatPrice('redeemedLoyaltyPoints')

    const storeCreditLabel = i18nTranslate(
      'summary.storeCredit',
      'Store Credit'
    )
    const storeCredit =
      (math?.storeCredit > 0 ? '-' : '') + this.getPrice(math.storeCredit)

    const walletBalanceLabel = i18nTranslate(
      'cart.walletBalnce',
      'Wallet balance'
    )
    const walletBalance = this.formatPrice('openLoyaltyPoints')

    const redeemablePointsLabel = i18nTranslate(
      'cart.redeemablePoints',
      'Redeemable Points'
    )
    const redeemablePoints = this.formatPrice('redeemableLoyaltyPoints')

    const loyaltyDiscountLabel = i18nTranslate(
      'summary.loyaltyDiscount',
      'Loyalty Discount'
    )

    const loyaltyDiscountPrice =
      (math?.loyaltyDiscount > 0 ? '-' : '') +
      this.formatPrice('loyaltyDiscount')

    const taxLabel =
      this.enableLiveEvent === 'true' || isOrderHistory
        ? i18nTranslate('summary.tax', 'Tax')
        : isOrderHistory || isReviewSection === true
        ? i18nTranslate('summary.tax', 'Tax')
        : i18nTranslate('summary.estimatedTax', 'Estimated Sales Tax')

    const overAllTaxPrice = this.formatPrice('overAllTax')
    const grandTotalPrice = this.formatPrice('grandTotal')
    const coloradoStateTax = math?.otherChargesIncludingTax || 0
    const displayColoradoStateFee = this.formatPrice('otherChargesIncludingTax')
    const coloradoStateTaxLabel = i18nTranslate(
      'summary.mandatoryCODeliveryFee',
      'Mandatory Colorado Delivery Fee'
    )

    const strikeLabel = i18nTranslate('strikeThroughPrice', 'Strike through ')
    const totalPVLabel = i18nTranslate('price.TotalSV', 'Total SV', {
      nameSpace: 'ssr-resource',
    })
    let pvPriceWithLabel = `${totalPVLabel} ${PVPriceDiscount}`
    if (!isOrderHistory && PVPriceOriginal != PVPriceDiscount) {
      pvPriceWithLabel = `${totalPVLabel} ${strikeLabel} ${PVPriceOriginal} ${PVPriceDiscount}`
    }

    const totalCVLabel = i18nTranslate('summary.totalcv', 'Total CV')
    let cvPriceWithLabel = `${totalCVLabel} ${CVPriceDiscount}`
    if (!isOrderHistory && CVPriceOriginal != CVPriceDiscount) {
      cvPriceWithLabel = `${totalCVLabel} ${strikeLabel} ${CVPriceOriginal} ${CVPriceDiscount}`
    }

    const { enableGiftMessage = false } = APPConfig?.getAppConfig()
    const { dropShippingEnabled = 'false' } =
      cartContainer?.cartResponse?.properties || {}
    const isToEnableGiftMessage = enableGiftMessage == 'true'

    return (
      <Row className={wrapperClassName + ' ' + customClass} noGutters>
        <Col xs="auto">{/*this.renderCartPromotionsWithHeading()*/}</Col>

        <Col
          className="cart-right-total-wrapper"
          lg={isViewCart ? 6 : 12}
          aria-live="polite">
          {isGuestOrUser &&
            isLoyEnableInProf() &&
            isAllowedRewardTypeForOptIn() &&
            !isBrandAffiliateTypeProfile() &&
            !isOrderHistory &&
            this.enableLiveEvent !== 'true' && (
              <>
                {/* Commented below  line for CX121-2283 as per loyalty CA Change Request  */}
                {/* <Col
                lg={12}
                className={`${orderHisClassName}
                   d-flex mt-2 justify-content-end mb-2 p-0 heading-5 ${reviewSecloyaltyTextAlign}`}
                data-testid="qa-earnedLoyalty">
                <span className="left-span mr-1">
                  <div className="ordr-sub-txt">
                    {i18nTranslate('cart.earnedLoyaltyPoints', 'Points you will earn')}
                  </div>
                </span>
                {showColon && ':'}
                <span className="right-span">
                  {'$'}
                  {earnedLoyaltypoint?.toFixed(2) || earnedLoyaltypoint}
                </span>
              </Col> */}
                <Col
                  lg={12}
                  className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
                  data-testid="qa-ordertotal">
                  <span className="left-span mr-1">
                    <div
                      className="ordr-sub-txt"
                      // aria-hidden={true}
                    >
                      {oneTimeSubTotalLabel}
                    </div>
                  </span>
                  {/* {showColon && ':'} */}
                  <span
                    className="right-span"
                    aria-label={`${oneTimeSubTotalLabel} $${oneTimeItemsTotal}`}>
                    ${oneTimeItemsTotal}
                  </span>
                </Col>
                <Col
                  lg={12}
                  className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
                  data-testid="qa-subscriptiontotal">
                  <span
                    className="left-span mr-1"
                    // aria-hidden={true}
                  >
                    <div className="ordr-total-txt">{subsSubTotalLabel}</div>
                  </span>
                  {/* {showColon && ':'} */}
                  <span
                    className="right-span"
                    aria-label={`${subsSubTotalLabel} $${subsctiptionItemsTotal}`}>
                    ${subsctiptionItemsTotal}
                  </span>
                </Col>
              </>
            )}
          {!isOrderHistory &&
            oneTimeItemsTotal > 0 &&
            this.enableLiveEvent !== 'true' && (
              <div className="shopping-cart-price-panel d-flex justify-content-end ordrs-price-info d-flex mt-0 mb-3">
                <div
                  className="left-span ordr-sub-txt paragraph-l"
                  data-testid="qa-onetime-sub-total"
                  // aria-hidden={true}
                >
                  {oneTimeSubTotalLabel}
                </div>
                {/* {oneTimeItemsTotal && showColon && ':'} */}
                <div
                  className="right-span ordr-total-price paragraph-l "
                  data-testid="qa-onetime-sub-total-price"
                  aria-label={`${oneTimeSubTotalLabel} $${oneTimeItemsTotal}`}>
                  {formattedOneTimeItemsTotal}
                </div>
              </div>
            )}

          <Col
            lg={12}
            className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
            data-testid="qa-subtotal">
            <span
              className="left-span mr-1"
              // aria-hidden={true}
            >
              {subTotalLabel}
            </span>
            {/* {showColon && ':'} */}
            <span
              className="right-span"
              aria-label={`${subTotalLabel} ${priceAfterMarkdown}`}>
              {priceAfterMarkdown}
            </span>
          </Col>
          {(isOrderHistory ||
            isShippingFree ||
            shippingCost > 0 ||
            (this.enableLiveEvent === 'true' && isReviewSection)) && (
            <Col
              lg={12}
              className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
              data-testid="qa-estimation">
              <span
                className="left-span mr-1"
                // aria-hidden={true}
                data-testid="qa-shipping-charge">
                {shippingLabel}
              </span>
              <span
                className="right-span"
                data-testid="qa-shipping-charge-value"
                aria-label={`${shippingLabel} ${displayShippingCost}`}>
                {displayShippingCost}
              </span>
            </Col>
          )}
          {!isOrderHistory &&
            math?.cartDeliveryChargeDiscount > 0 &&
            cartShippingDiscount !== 0 && (
              <Col
                lg={12}
                className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
                data-testid="qa-estimation-shipping-discount">
                <span
                  className="left-span mr-1"
                  // aria-hidden={true}
                >
                  {shippingDisountLabel}
                </span>
                <span
                  className="right-span"
                  aria-label={`${shippingDisountLabel} ${cartShippingDiscount}`}>
                  {cartShippingDiscount}
                </span>
              </Col>
            )}
          {isOrderHistory && math && math?.cartDeliveryDiscount != 0 && (
            <Col
              lg={12}
              className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
              data-testid="qa-estimation">
              <span
                className="left-span mr-1"
                // aria-hidden={true}
              >
                {shippingDisountLabel}
              </span>
              <span
                className="right-span"
                aria-label={`${shippingDisountLabel} ${displayShippingDiscount}`}>
                {displayShippingDiscount}
              </span>
            </Col>
          )}
          {(discount !== 0 ||
            (this.enableLiveEvent === 'true' && isReviewSection)) && (
            <Col
              lg={12}
              className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
              data-testid="qa-Discount">
              <span
                className="left-span mr-1"
                // aria-hidden={true}
              >
                {disountLabel}
                {discount > 0 && this.renderPromotionsToolTip(disountLabel)}
                {isOrderHistory && this.renderOrderSummaryDiscountToolTip()}
              </span>
              {/* {showColon && ':'} */}
              <span
                className="right-span"
                aria-label={`${disountLabel} ${totalDiscountPrice}`}>
                {totalDiscountPrice}
                {/* {overAllDiscount > 0 && '-'}
                {totalDiscount} */}
                {/* {this.formatPrice('overAllDiscount') ||
                  currencyFormat({
                    price: totalDiscount,
                  })} */}
              </span>
            </Col>
          )}
          {isGuestOrUser &&
            math &&
            math.redeemedLoyaltyPoints > 0 &&
            !discountModel && (
              <Col
                lg={12}
                className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
                data-testid="qa-Loyalty">
                <span
                  className="left-span mr-1"
                  // aria-hidden={true}
                >
                  {loyaltyPointsLabel}
                </span>
                {/* {showColon && ':'} */}
                <span
                  className="right-span"
                  aria-label={`${loyaltyPointsLabel} ${reedeemedLoyalPoints}`}>
                  {reedeemedLoyalPoints}
                </span>
              </Col>
            )}
          {isGuestOrUser && math && math.storeCredit > 0 && !isOrderHistory && (
            <Col
              lg={12}
              className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
              data-testid="qa-storeCredit">
              <span
                className="left-span mr-1"
                // aria-hidden={true}
              >
                {storeCreditLabel}
              </span>
              {/* {showColon && ':'} */}
              <span
                className="right-span"
                aria-label={`${storeCreditLabel} ${storeCredit}`}>
                {storeCredit}
              </span>
            </Col>
          )}
          {isViewCart && isGuestOrUser && !isOrderHistory && (
            <Col
              lg={12}
              className="shopping-cart-price-panel d-flex justify-content-end mb-2 p-0"
              data-testid="qa-Wallet-balance">
              <span
                className="left-span mr-1"
                // aria-hidden={true}
              >
                {walletBalanceLabel}
              </span>
              {/* {showColon && ':'} */}
              <span
                className="right-span"
                aria-label={`${walletBalanceLabel} ${walletBalance}`}>
                {walletBalance}
              </span>
            </Col>
          )}
          {isViewCart && isGuestOrUser && !isOrderHistory && (
            <Col
              lg={12}
              className="shopping-cart-price-panel d-flex justify-content-end mb-2 p-0"
              data-testid="qa-Redeemable-points">
              <span
                className="left-span mr-1"
                // aria-hidden={true}
              >
                {redeemablePointsLabel}
              </span>
              {/* {showColon && ':'} */}
              <span
                className="right-span"
                aria-label={`${redeemablePointsLabel} ${redeemablePoints}`}>
                {redeemablePoints}
              </span>
            </Col>
          )}
          {/*!isViewCart && isGuestOrUser && !isOrderHistory && (
            <Col
              lg={12}
              className="shopping-cart-price-panel d-flex justify-content-start mb-2 p-0"
              data-testid="qa-Wallet-balance">
              <span className="left-span mr-1">Wallet balance</span>
              {showColon && ':'}
              <span className="right-span">
                {this.formatPrice('openLoyaltyPoints')}
              </span>
            </Col>
          )*/}
          {/*!isViewCart && isGuestOrUser && !isOrderHistory && (
            <Col
              lg={12}
              className="shopping-cart-price-panel d-flex justify-content-end mb-2 p-0"
              data-testid="qa-Redeemable-points">
              <span className="left-span mr-1">Redeemable Points</span>
              {showColon && ':'}
              <span className="right-span">
                {this.formatPrice('redeemableLoyaltyPoints')}
              </span>
            </Col>
          )*/}
          {isGuestOrUser && discountModel && math.loyaltyDiscount > 0 && (
            <Col
              lg={12}
              className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0`}
              data-testid="qa-Loyalty">
              <span
                className="left-span mr-1"
                // aria-hidden={true}
              >
                {loyaltyDiscountLabel}
              </span>
              {/* {showColon && ':'} */}
              <span
                className="right-span"
                aria-label={`${loyaltyDiscountLabel} ${loyaltyDiscountPrice}`}>
                {loyaltyDiscountPrice}
              </span>
            </Col>
          )}
          {(overAllTax > 0 || this.enableLiveEvent === 'true') &&
            !this.enableInclusiveTaxInCheckout && (
              <Col
                lg={12}
                className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m`}
                data-testid="qa-submit-estimated-shipping-tax">
                <span
                  className="left-span mr-1"
                  // aria-hidden={true}
                >
                  {taxLabel}
                </span>
                {/* {showColon && ':'} */}
                <span
                  className="right-span"
                  aria-label={`${taxLabel} ${overAllTaxPrice}`}>
                  {overAllTaxPrice}
                </span>
              </Col>
            )}
          {coloradoStateTax > 0 && (
            <Col
              lg={12}
              className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0 paragraph-m state-tax-wrapper`}
              data-testid="qa-colorado-tax-fee">
              <div
                className={`left-span ${customClassName} state-tax-fee`}
                // aria-hidden={true}

                data-testid="qa-attribute-colorado-delivery-fees">
                <span className="state-tax-label">{coloradoStateTaxLabel}</span>
                <span>{this.renderCOStateTaxTooltip()}</span>
              </div>
              <span
                className="right-span d-flex align-items-end colorado-state-value"
                aria-label={`${coloradoStateTaxLabel} ${displayColoradoStateFee}`}>
                {displayColoradoStateFee}
              </span>
            </Col>
          )}
          <Col
            lg={12}
            className={`${orderHisClassName} d-flex justify-content-end mt-2 mb-2 p-0 paragraph-m grand-total-wrapper`}
            data-testid="qa-estimated-total">
            <strong
              className="left-span mr-2"
              // aria-hidden={true}
            >
              {totalText}
            </strong>
            {/* {showColon && ':'} */}
            <strong
              className="right-span"
              aria-label={`${totalText} ${grandTotalPrice}`}>
              {grandTotalPrice}
            </strong>
          </Col>
          <div className="d-none grand-total-border border-top mt-3 mb-3" />
          {(this.isInclusiveTaxEnable ||
            this.isCategoryTaxenable ||
            this.isRegionTaxenable) &&
            !isMiniCart &&
            isReviewSection && (
              <>
                {this.getTotalInclusiveTax(totalInclusiveTax)}
                {this.getSummaryText()}
              </>
            )}
          {isOrderHistory &&
            (this.isInclusiveTaxEnable ||
              this.isCategoryTaxenable ||
              this.isRegionTaxenable) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {this.getTotalInclusiveTax(totalInclusiveTax)}
                <OverlayTrigger
                  trigger="click"
                  placement="right-end"
                  rootClose
                  tabIndex={0}
                  overlay={
                    <Tooltip
                      id="InclusiveTax-tooltip"
                      tabIndex={0}
                      data-testid="qa-attribute-tax-text-tooltip">
                      <span>{this.getSummaryText()}</span>
                    </Tooltip>
                  }>
                  <Button
                    className="state-tooltip-button p-0"
                    data-testid="qa-attribute-tax-text-icon"
                    style={{ marginLeft: '5px', marginBottom: '15px' }}>
                    <InformationIcon
                      size="1.5rem"
                      id="info-icon-tax-details"
                      className="infotool"
                    />
                  </Button>
                </OverlayTrigger>
              </div>
            )}
          {/* <Col></Col> */}
          {/* <Col
            lg={12}
            className={`${orderHisClassName} d-flex justify-content-end mt-2 mb-2 p-0 paragraph-m grand-total-wrapper`}
            data-testid="qa-estimated-total">
            <strong className="left-span mr-2"
// aria-hidden={true}
>
              {totalText}
            </strong>
            {/* {showColon && ':'} */}
          {/* <strong
              className="right-span"
              aria-label={`${totalText} ${grandTotalPrice}`}>
              {grandTotalPrice} - {overAllTax}
            </strong>
          </Col> */}
          {totalValue && localvalue?.includes('Brand Affiliate') && (
            <>
              {!isOrderHistory && (
                <div className="total-pv-border border-top mt-3 mb-3"></div>
              )}
              <Col
                lg={12}
                className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0`}
                data-testid="qa-minicart-totalPV">
                <span
                  className="left-span mr-1"
                  // aria-hidden={true}
                >
                  {totalPVLabel}
                </span>
                {/* {showColon && ':'} */}
                <span className="right-span d-flex">
                  {/* {!isOrderHistory && PVPriceOriginal != PVPriceDiscount && (
                    <span
                      className="strikeThrough strikeValueUpdate"
// aria-hidden={true}
>
                      {PVPriceOriginal}
                    </span>
                  )} */}
                  <span
                    className={`${rightSpanCls} ml-0 font-weight-bold`}
                    aria-label={pvPriceWithLabel}>
                    {PVPriceDiscount}
                  </span>
                </span>
              </Col>
              {userRole != 'ROLE_ACCOUNT_BUYER_ADMIN' && (
                <Col
                  lg={12}
                  className={`${orderHisClassName} d-flex justify-content-end mb-2 p-0`}
                  data-testid="qa-minicart-totalCV">
                  <span
                    className="left-span"
                    // aria-hidden={true}
                  >
                    {totalCVLabel}
                  </span>
                  {/* {showColon && ':'} */}
                  <span className="right-span d-flex">
                    {!isOrderHistory && CVPriceOriginal != CVPriceDiscount && (
                      <span
                        className="strikeThrough strikeValueUpdate"
                        aria-hidden={true}>
                        {CVPriceOriginal}
                      </span>
                    )}

                    <span
                      className={`${rightSpanCls} ml-0 font-weight-bold`}
                      aria-label={cvPriceWithLabel}>
                      {CVPriceDiscount}
                    </span>
                  </span>
                </Col>
              )}
            </>
          )}
        </Col>
        {IS_BROWSER &&
          (window.location.pathname.includes(pageNames.checkout) ||
            window.location.pathname.includes(pageNames.expressCheckout) ||
            window.location.pathname.includes(pageNames.expressPDP)) &&
          this.enableLiveEvent !== 'true' && (
            <div className="gift-receipt-container">
              <div
                id="gift-receipt"
                key="gift-receipt-wrapper"
                role="checkbox"
                aria-checked={checkoutContainer.giftPayLoad ? true : false}
                tabIndex={0}
                onKeyPress={e => {
                  this.handleGiftSelectCheckbox(e)
                }}
                data-testid="qa-gift-receipt-wrapper">
                <Form.Check
                  type="checkbox"
                  id="summary-gift-receipt-checkbox"
                  className="d-flex gift-receipt-checkbox"
                  aria-hidden="true"
                  data-testid="qa-gift-receipt-checkbox"
                  label={i18nTranslate('checkout.giftreceipt', 'Gift Receipt')}
                  checked={checkoutContainer.giftPayLoad}
                  onChange={this.handleGiftSelectCheckbox}
                />
              </div>
              {isToEnableGiftMessage && checkoutContainer.giftPayLoad && (
                <CommonForm
                  schema={schema()}
                  formTypeJson={formInput({
                    handleChangeValue: this.handleChangeValue,
                    giftMessage: checkoutContainer.giftMessage,
                    dataTestid: 'qa-gift-receipt-message',
                  })}
                  onSubmit={this.handleSubmit}
                  hasInlineSubmitButton={true}
                  dataTestId="qa-gift-message-textbox"
                  classNameValue={{
                    formRowClassName: 'mt-1 mb-2',
                    formColumnClassName: 'gift-message-wrapper',
                  }}
                />
              )}
            </div>
          )}

        {IS_BROWSER &&
          (window?.location?.pathname?.includes(pageNames.checkout) ||
            window?.location?.pathname?.includes(pageNames.expressCheckout)) &&
          dropShippingEnabled === 'true' &&
          this.enableLiveEvent !== 'true' && (
            <div className="dropship-container">
              <div
                className="d-flex"
                id="drop-shipping"
                key="dropship-wrapper"
                role="checkbox"
                aria-checked={checkoutContainer.dropShippingPayLoad}
                tabIndex={0}
                onKeyPress={e => {
                  this.handleDropShippingSelectCheckbox(e)
                }}>
                <Form.Check
                  type="checkbox"
                  id="drop-shipping-checkbox"
                  className="d-flex dropship-checkbox"
                  label={i18nTranslate(
                    'checkout.dropshipment',
                    'Drop Shipment'
                  )}
                  checked={checkoutContainer.dropShippingPayLoad}
                  onChange={this.handleDropShippingSelectCheckbox}
                />
                <span className="dropship-info-icon">
                  <OverlayTrigger
                    trigger="click"
                    rootClose={true}
                    overlay={
                      <Tooltip id="dropship-tooltip">
                        {i18nTranslate(
                          'checkout.dropShipTooltip',
                          'Ship the order from warehouse to the customer'
                        )}
                      </Tooltip>
                    }>
                    <InformationIcon
                      size="1rem"
                      id="dropship-info"
                      className="info-icon"
                      aria-label={i18nTranslate(
                        'checkout.dropshipment',
                        'Drop Shipment'
                      )}
                    />
                  </OverlayTrigger>
                </span>
              </div>
              {/* <div>
                {this.enableInclusiveTaxInCheckout &&
                  this.getSummaryText(totalInclusiveTax)}
              </div> */}
            </div>
          )}
      </Row>
    )
  }
}

export { Summary }
export default Summary
