export const brandAffiliate = {
  en_us: {
    salesPerformancePlan:
      'https://www.nuskin.com/content/dam/office/n_america/shared/en/business_materials/sales-performance-plan-us.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    refundPolicy:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/nuskin_refund_policy.pdf',
    salesCompensationSummary:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/distearnings.pdf',
    brandAffiliateAgreement:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/brand-affiliate-agreement.pdf',
    policiesAndProcedures:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/Policies_Proced_US.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/SMS-Terms-of-Service_en_US.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/en_US/TermsAndConditions.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/en_US/loyalty/faq.html',
  },
  es_us: {
    salesPerformancePlan:
      'https://www.nuskin.com/content/dam/office/n_america/shared/en/business_materials/sales-performance-plan-us.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    refundPolicy:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/nuskin_refund_policy.pdf',
    salesCompensationSummary:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/distearnings.pdf',
    brandAffiliateAgreement:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/brand-affiliate-agreement.pdf',
    policiesAndProcedures:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/Policies_Proced_US.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/SMS-Terms-of-Service_en_US.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/es_US/TermsAndConditions.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/es_US/loyalty/faq.html',
  },
  zh_us: {
    salesPerformancePlan:
      'https://www.nuskin.com/content/dam/office/n_america/shared/en/business_materials/sales-performance-plan-us.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    refundPolicy:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/nuskin_refund_policy.pdf',
    salesCompensationSummary:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/distearnings.pdf',
    brandAffiliateAgreement:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/brand-affiliate-agreement.pdf',
    policiesAndProcedures:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/Policies_Proced_US.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/SMS-Terms-of-Service_en_US.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/zh_US/TermsAndConditions.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/zh_US/loyalty/faq.html',
  },
  vi_us: {
    salesPerformancePlan:
      'https://www.nuskin.com/content/dam/office/n_america/shared/en/business_materials/sales-performance-plan-us.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    refundPolicy:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/nuskin_refund_policy.pdf',
    salesCompensationSummary:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/distearnings.pdf',
    brandAffiliateAgreement:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/brand-affiliate-agreement.pdf',
    policiesAndProcedures:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/Policies_Proced_US.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/SMS-Terms-of-Service_en_US.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/vi_US/TermsAndConditions.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/vi_US/loyalty/faq.htm',
  },
  en_ca: {
    salesPerformancePlan:
      'https://www.nuskin.com/content/dam/office/n_america/shared/en/business_materials/sales-performance-plan-ca.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_CA/corporate/privacy.html',
    refundPolicy:
      'https://www.nuskin.com/content/dam/global/library/pdf/nuskin_refund_policy.pdf',
    salesCompensationSummary:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/distearnings.pdf',
    brandAffiliateAgreement:
      'https://www.nuskin.com/content/dam/office/n_america/CA/en/business_materials/brand-affiliate-agreement-ca.pdf',
    policiesAndProcedures:
      'https://www.nuskin.com/content/dam/office/n_america/CA/en/business_materials/Policies_Proced_CA.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/CA/en/CA_SMS-Terms-of-Service.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/en_CA/TermsAndConditions.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/en_CA/loyalty/faq.html',
  },
  fr_ca: {
    salesPerformancePlan:
      'https://www.nuskin.com/content/dam/office/n_america/shared/en/business_materials/sales-performance-plan-ca.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/fr_CA/corporate/privacy.html',
    refundPolicy:
      'https://www.nuskin.com/content/dam/global/library/pdf/nuskin_refund_policy.pdf',
    salesCompensationSummary:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/distearnings.pdf',
    brandAffiliateAgreement:
      'https://www.nuskin.com/content/dam/office/n_america/CA/en/business_materials/brand-affiliate-agreement-ca.pdf',
    policiesAndProcedures:
      'https://www.nuskin.com/content/dam/office/n_america/CA/en/business_materials/Policies_Proced_CA.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/CA/en/CA_SMS-Terms-of-Service.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/fr_CA/TermsAndConditions.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/fr_CA/loyalty/faq.html',
  },
  zh_ca: {
    salesPerformancePlan:
      'https://www.nuskin.com/content/dam/office/n_america/shared/en/business_materials/sales-performance-plan-us.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    refundPolicy:
      'https://www.nuskin.com/content/dam/global/library/pdf/nuskin_refund_policy.pdf',
    salesCompensationSummary:
      'https://www.nuskin.com/content/dam/office/n_america/US/en/business_materials/distearnings.pdf',
    brandAffiliateAgreement:
      'https://www.nuskin.com/content/dam/office/n_america/CA/en/business_materials/brand-affiliate-agreement-ca.pdf',
    policiesAndProcedures:
      'https://www.nuskin.com/content/dam/office/n_america/CA/en/business_materials/Policies_Proced_CA.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/CA/en/CA_SMS-Terms-of-Service.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/zh_CA/TermsAndConditions.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/zh_CA/loyalty/faq.html',
  },
}

export const retailCustomer = {
  en_us: {
    refundPolicy:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/nuskin_refund_policy.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    termsOfUse: '/content/nuskin/en_US/corporate/terms.html',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/SMS-Terms-of-Service_en_US.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/en_US/TermsAndConditions.pdf',
    subscriptionAgreement:
      'https://www.nuskin.com/content/dam/account/US/subscription-terms-and-conditions-053023-en-US.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/en_US/loyalty/faq.html',
    storedCredentialsConsentAgreement:
      'https://media.nuskin.com/m/2155b8a224aeb6e4/original/Stored-Credentials-US-06-2023.pdf',
    klarnaShoppingServiceLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/user',
    klarnaPrivacyPolicyLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/privacy',
  },
  es_us: {
    refundPolicy:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/nuskin_refund_policy.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    termsOfUse: '/content/nuskin/es_US/corporate/terms.html',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/SMS-Terms-of-Service_en_US.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/es_US/TermsAndConditions.pdf',
    subscriptionAgreement:
      'https://www.nuskin.com/content/dam/account/US/subscription-terms-and-conditions-053023-es-US.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/es_US/loyalty/faq.html',
    storedCredentialsConsentAgreement:
      'https://media.nuskin.com/m/79ece3820482a7cd/original/Stored-Credentials-US-06-2023-ES.pdf',
    klarnaShoppingServiceLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/es_us/user',
    klarnaPrivacyPolicyLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/es_us/privacy',
  },
  zh_us: {
    refundPolicy:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/nuskin_refund_policy.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    termsOfUse: '/content/dam/account/north-america/US/en/Terms-of-Use_en.html',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/SMS-Terms-of-Service_en_US.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/zh_US/TermsAndConditions.pdf',
    subscriptionAgreement:
      'https://www.nuskin.com/content/dam/account/US/subscription-terms-and-conditions-053023-en-US.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/zh_US/loyalty/faq.html',
    storedCredentialsConsentAgreement:
      'https://media.nuskin.com/m/376745116711eadb/original/Stored-Credentials-US-06-2023-ZH.pdf',
    klarnaShoppingServiceLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/user',
    klarnaPrivacyPolicyLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/privacy',
  },
  vi_us: {
    refundPolicy:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/nuskin_refund_policy.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    termsOfUse: '/content/dam/account/north-america/US/en/Terms-of-Use_en.html',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/US/en/SMS-Terms-of-Service_en_US.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/vi_US/TermsAndConditions.pdf',
    subscriptionAgreement:
      'https://www.nuskin.com/content/dam/account/US/subscription-terms-and-conditions-053023-en-US.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/vi_US/loyalty/faq.html',
    storedCredentialsConsentAgreement:
      'https://media.nuskin.com/m/bb1ea9c4fe25cdd/original/Stored-Credentials-US-06-2023-VI.pdf',
    klarnaShoppingServiceLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/user',
    klarnaPrivacyPolicyLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/privacy',
  },
  en_ca: {
    refundPolicy:
      'https://www.nuskin.com/content/dam/global/library/pdf/nuskin_refund_policy.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_CA/corporate/privacy.html',
    termsOfUse: '/content/nuskin/en_CA/corporate/terms.html',
    memberAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/CA/en/member-agreement-ca.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/CA/en/CA_SMS-Terms-of-Service.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/en_CA/TermsAndConditions.pdf',
    subscriptionAgreement:
      'https://www.nuskin.com/content/dam/account/CA/subscription-terms-and-conditions-053023-en-CA.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/en_CA/loyalty/faq.html',
    storedCredentialsConsentAgreement:
      'https://media.nuskin.com/m/66613768d6366fa2/original/Stored-Credentials-CA-06-2023.pdf',
    klarnaShoppingServiceLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_ca/user',
    klarnaPrivacyPolicyLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_ca/privacy',
  },
  fr_ca: {
    refundPolicy:
      'https://www.nuskin.com/content/dam/global/library/pdf/nuskin_refund_policy.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/fr_CA/corporate/privacy.html',
    termsOfUse: '/content/nuskin/fr_CA/corporate/terms.html',
    memberAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/CA/en/member-agreement-ca.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/CA/en/CA_SMS-Terms-of-Service.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/fr_CA/TermsAndConditions.pdf',
    subscriptionAgreement:
      'https://www.nuskin.com/content/dam/account/CA/subscription-terms-and-conditions-053023-fr-CA.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/fr_CA/loyalty/faq.html',
    storedCredentialsConsentAgreement:
      'https://media.nuskin.com/m/64700eceebca9e12/original/Stored-Credentials-CA-06-2023-FR.pdf',
    klarnaShoppingServiceLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/fr_ca/user',
    klarnaPrivacyPolicyLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/fr_ca/privacy',
  },
  zh_ca: {
    refundPolicy:
      'https://www.nuskin.com/content/dam/global/library/pdf/nuskin_refund_policy.pdf',
    privacyNotice:
      'https://www.nuskin.com/content/nuskin/en_US/corporate/privacy.html',
    termsOfUse: '/content/nuskin/en_CA/corporate/terms.html',
    memberAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/CA/en/member-agreement-ca.pdf',
    smsAgreement:
      'https://www.nuskin.com/content/dam/account/north-america/CA/en/CA_SMS-Terms-of-Service.pdf',
    enjoyTermsAndConsitions:
      'https://www.nuskin.com/content/dam/apps/loyalty/zh_CA/TermsAndConditions.pdf',
    subscriptionAgreement:
      'https://www.nuskin.com/content/dam/account/CA/subscription-terms-and-conditions-053023-en-CA.pdf',
    faq: 'https://www.nuskin.com/content/nuskin/zh_CA/loyalty/faq.html',
    storedCredentialsConsentAgreement:
      'https://media.nuskin.com/m/23069f06dd1b76c7/original/Stored-Credentials-CA-06-2023-ZH.pdf',
    klarnaShoppingServiceLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_ca/user',
    klarnaPrivacyPolicyLink:
      'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_ca/privacy',
  },
}

export const SMS_TERMS_US =
  'https://www.nuskin.com/content/dam/account/north-america/US/en/SMS-Terms-of-Service_en_US.pdf'

export const SMS_TERMS_CA =
  'https://www.nuskin.com/content/dam/account/north-america/CA/en/CA_SMS-Terms-of-Service.pdf'

export const GLOBAL_PRIVACY_LINK =
  'https://www.nuskin.com/content/global-privacy.html'
