import React from 'react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import {
  Tab,
  Tabs,
  Form,
  Button,
  Modal,
  Row,
  Col,
  Tooltip,
  Spinner,
  Accordion,
  Card,
} from 'react-bootstrap'
import { IoIosAdd } from 'react-icons/io'
import {
  CommonForm,
  tokenExIframe,
  PaymentMarketForm,
  toastState,
  overlayState,
  Pagination,
} from 'src/views/components'
import {
  isB2BUser,
  isB2BAccount,
  isB2B2C,
  application,
  trackEnhancedCheckout,
  trackCustomCheckoutEvent,
  capitalizeFirstLetter,
  getTotalPagesForPagination,
  i18nTranslate,
  getLiveEventStatus,
  convertToBoolean,
  isExpressCheckout,
} from 'src/utils'
import {
  renderFormInput as renderPOFormInput,
  schema as POSchema,
} from './PurchaseOrder'
import { renderFormInput, cardCVVSchema } from './PaymentAccordionInput'
import { MultiplePayment } from './MultiplePaymentForm'
import { PaymentIcon } from 'src/views/components/PaymentIcon'
import {
  accountsContainer,
  customerContainer,
  checkoutContainer,
  cartContainer,
  storeContainer,
  tokenExContainer,
  venmoContainer,
  paypalContainer,
  googlePayContainer,
  applepayContainer,
  klarnaContainer,
  loyaltyContainer,
} from 'src/models'
import { APPConfig, IS_BROWSER } from 'config/appConfig'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { dateFormatDeps, getCardDetails, FormInputLabels } from 'src/deps'
import { IoIosInformationCircle as InformationIcon } from 'react-icons/io'
import { TokenExCvvContainer, savedCardsContainer } from 'src/models/TokenEx'
import { EditUpdatePaymentModal } from 'src/views/pages/MyAccount/Payments/EditUpdatePaymentModal'
import { getPaymentsForAddNewSection } from './fixture'
import ExpressSectionHeader from '../ExpressSectionHeader'
import './styles.scss'

const constructStyle = {
  formRowClassName: 'ml mr-1 from-saved-list',
}

@observer
class PaymentListWithForm extends React.Component {
  @observable selectedPayment = []
  @observable paymentList = customerContainer.paymentResponse?.payments || []
  @observable paymentCredits = [] // For MultiPayment Form
  @observable paymentType = '' // For PO Payment form
  @observable isToShowDeleteConfirmationModal = false
  @observable isDefaultCardId = ''
  @observable cardId = ''
  @observable deleteId = ''
  tokenPathParam = ''
  tokenCvvApiValues = ''
  @observable selectedAddPaymentType = ''
  @observable savePaymentCheckbox = false
  @observable alternateDefaultPaymentCheckbox = false
  @observable hasSubscriptionProductInCart = false
  @observable savedCardValidStatus = false
  @observable IdofCardToBeEdited = ''
  @observable isAddPaymentCallInProgress = false
  @observable showAddNewCardForm = false
  @observable showUseDifferentCardForm = false
  @observable showPaymentList = false
  @observable activePaymentKey = ''
  previousCardID = ''
  isFromDidMount = false
  loadIframeInitially = false
  theDefaultCardId = ''
  isNotSelectedDefaultCard = false
  isFromDeleteCard = false
  currentTab = ''
  isCardExpired = true
  @observable isToShowEditUpdatePaymentModal = false
  @observable cardsDetails = {}
  totalPagesLength = 0
  currentPage = 1
  enableLiveEvent = getLiveEventStatus()

  constructor(props) {
    super(props)
    if (this.props.initiallySelectedPayment) {
      this.selectedPayment = this.props.initiallySelectedPayment
    }
    const updatedList = this.props?.paymentList?.filter(item => {
      const skipList = ['creditcard', 'purchaseorder']
      return skipList?.includes(item?.type?.toLowerCase()) === false
    })
    this.paymentCredits = updatedList
    const updatePaymentList =
      this.paymentList &&
      this.paymentList?.length &&
      this.paymentList?.filter(list => {
        const type = list?.type?.toLowerCase() || ''
        return (
          type === 'creditcard' ||
          type === 'debitcard' ||
          type === 'purchaseorder'
        )
      })
    this.paymentList = updatePaymentList

    // if user choose any of express payment type, then the respective radio button
    // will be pre-selected automatically

    this.selectedAddPaymentType = checkoutContainer.paypalCheckout
      ? 'PAYPAL'
      : checkoutContainer.gPayCheckout
      ? 'GOOGLEPAY'
      : checkoutContainer.applePayCheckout
      ? 'APPLEPAY'
      : ''
  }

  /**
   * @description Loads the TokenEx CVV iframe for the selected payment method.
   */
  tokenExCvvIframeLoader = async () => {
    let selectedCardId = this.selectedPayment?.id || ''
    let tokenExUpdate = savedCardsContainer.savedCardsList?.[selectedCardId]
    this.previousCardID = selectedCardId || ''
    this.savedCardValidStatus = false
    tokenExUpdate = new TokenExCvvContainer()
    tokenExUpdate.containerId = selectedCardId || ''
    tokenExUpdate.errorValidationCallBack = (containerId, validData) => {
      const errorElement = document.querySelector(
        `#cvvParent${containerId} .cardCvvtoken`
      )
      if (validData.isValid == false) {
        errorElement?.classList?.add('show')
        this.savedCardValidStatus = false
      } else {
        errorElement?.classList?.remove('show')
        this.savedCardValidStatus = true
      }
    }

    savedCardsContainer.savedCardsList[this.selectedPayment.id] = tokenExUpdate

    //getting accountinfo
    /**
     * @description
     * Performance Fix - Making multiple get all payment API is not needed here
     * as payment list is already available from observable
     * so commented below line of making payment API
     */
    const tokenValues = customerContainer?.paymentResponse?.payments || [] //await customerContainer.getPaymentsForDashboard()
    checkoutContainer.activeSubmitOrderButton = true
    this.tokenPathParam = tokenValues?.find(
      item => item?.id === selectedCardId
    )?.accountInfo?.token
    this.cardProvider = tokenValues?.find(
      item => item?.id === selectedCardId
    )?.accountInfo?.provider
    // await savedCardsContainer.getTokenExCvvAuthKeyFromSFO(this.tokenPathParam)
    tokenExUpdate?.loadTokenExCvvIframe(
      `cvvTokenID${this.selectedPayment.id}`,
      this.tokenPathParam,
      this.cardProvider
    )
  }

  async componentDidMount() {
    if (isExpressCheckout()) {
      const { payments = [], pageableInfo = {} } =
        customerContainer.paymentResponse || {}
      this.handlePaymentTypeSelect('CREDITCARD', 0)
      if (
        !customerContainer.isRegisterUser ||
        (payments?.length === 0 && customerContainer.isRegisterUser)
      ) {
        this.showAddNewCardForm = true
        this.showUseDifferentCardForm = true
        this.showPaymentList = true
      }
    }

    this.currentTab = this.getDefaultPaymentTab()
    this.handleDefaultChecked()
    this.checkIfCartHasSubscriptionProducts()
    tokenExContainer.tokenExValidData = false
    klarnaContainer.isKlarnaTransactionStatusApiInProgress = false
    klarnaContainer.isKlarnaSubmitOrderApiInProgress = false
    klarnaContainer.isKlarnaBroadcastMessageReceived = false
    klarnaContainer.klarnaBrowserPopupBlock = false
    klarnaContainer.isCloseWindowFromCallback = false
    let selectedCardId = this.selectedPayment?.id || ''
    if (
      this.selectedPayment?.isDefault &&
      this.previousCardID !== selectedCardId
    ) {
      this.isFromDidMount = !this.loadIframeInitially ? true : false
      this.tokenExCvvIframeLoader()
    }
  }

  //to load the Multiple CVV Iframes
  componentDidUpdate(prevProps) {
    let selectedCardId = this.selectedPayment?.id || ''
    if (
      this.currentTab == 'savedcards' &&
      (this.previousCardID !== selectedCardId || this.isFromDidMount)
    ) {
      this.isFromDidMount = false
      this.tokenExCvvIframeLoader()
    }
    if (
      isExpressCheckout() &&
      !cartContainer.cartResponse?.payments &&
      (venmoContainer.venmoInitiate ||
        venmoContainer.venmoClientCreated ||
        paypalContainer.paypalInitiate ||
        paypalContainer.paypalClientCreated ||
        googlePayContainer.isToInitiateGooglePayPayment ||
        googlePayContainer.googlePayClientCreated ||
        applepayContainer.applepayInitiate ||
        applepayContainer.applepayClientCreated)
    ) {
      this.handlePaymentTypeSelect('CREDITCARD', 0)
    }
  }

  @action
  togglePaymentAccordion = () => {
    if (!isExpressCheckout()) {
      return
    }
    this.showPaymentList = !this.showPaymentList
    this.tokenExCvvIframeLoader()
    if (
      !this.showUseDifferentCardForm &&
      !this.showAddNewCardForm &&
      this.showPaymentList
    ) {
      venmoContainer.venmoInitiate = false
      venmoContainer.venmoClientCreated = false
      paypalContainer.paypalInitiate = false
      paypalContainer.paypalClientCreated = false
      googlePayContainer.isToInitiateGooglePayPayment = false
      googlePayContainer.googlePayClientCreated = false
      applepayContainer.applepayInitiate = false
      applepayContainer.applepayClientCreated = false
      this.deleteMultiplePayment()
    }
    if (!customerContainer.isRegisterUser) {
      this.handlePaymentTypeSelect('CREDITCARD', 0)
    }
  }

  /**
   * @description Determines the default payment tab to display based on the current checkout state.
   * @returns {string} The default payment tab, which can be 'PO', 'newCard', or 'savedcards'.
   */
  getDefaultPaymentTab = () => {
    const { isPaymentTypePONumber = false } = this.conditionBasedTabs()
    const defaultTab = isPaymentTypePONumber
      ? 'PO'
      : checkoutContainer.paypalCheckout ||
        checkoutContainer.applePayCheckout ||
        checkoutContainer.gPayCheckout
      ? 'newCard'
      : this.paymentList?.length > 0
      ? 'savedcards'
      : 'newCard'
    return defaultTab
  }

  /**
   * @description Handles the continuation of the saved credit card payment flow.
   * - If the CVV is valid, it retrieves the token for the selected payment method and calls the `handlePayment` callback with the necessary data.
   * - If the CVV is invalid, it displays an error message.
   */
  handleSavedCreditCardContinue = async () => {
    const { title = '' } = this.props
    const products = cartContainer?.cartResponse?.items || []
    trackEnhancedCheckout({
      type: 'checkout',
      action: { step: '6', option: title },
      products: products,
    })
    checkoutContainer.isPaymentApiInProgress = true
    venmoContainer.venmoClientCreated = false
    venmoContainer.venmoInitiate = false
    paypalContainer.paypalInitiate = false
    paypalContainer.paypalClientCreated = false
    googlePayContainer.isToInitiateGooglePayPayment = false
    applepayContainer.applepayInitiate = false
    applepayContainer.applepayClientCreated = false
    googlePayContainer.googlePayClientCreated = false
    checkoutContainer.isToDisableSubmitOrderButton = false
    const currentCardId = this.selectedPayment?.id || ''
    const errorElement = document.querySelector(
      `#cvvParent${currentCardId} .cardCvvtoken`
    )
    let tokenExUpdate =
      savedCardsContainer?.savedCardsList?.[this.selectedPayment?.id]
    let isCvvValid = this.savedCardValidStatus
    this.props?.changeCvvValidFlag(isCvvValid)
    if (this.savedCardValidStatus) {
      // checkoutContainer.isPaymentApiInProgress = true
      checkoutContainer.isPaymentApiInProgress = false
      await savedCardsContainer.savedCardsList[currentCardId].getToken(
        tokenExData => {
          this.props.handlePayment?.({
            cardCVV: '',
            ...this.selectedPayment,
            savedCardValidStatus: this.savedCardValidStatus,
            isFromSavedAddress: true,
            tokenExData,
          })
          errorElement?.classList?.remove('show')
        }
      )
      // this.showPaymentList = false
    } else {
      errorElement?.classList?.add('show')
      checkoutContainer.isPaymentApiInProgress = false
    }
  }

  /**
   * @description Renders a continue button for saved payments.
   * The button text changes to show a spinner while the payment API is in progress.
   * @returns {React.ReactElement} The continue button for saved payments.
   */
  renderContinueButtonForSavedPayments = () => {
    const dataTestId =
      this.paymentType === 'PO' ? 'qa-save-or-po-continue' : 'qa-pay-continue'
    return (
      <Button
        className="px-5 py-2 add-payment-continue list-specific-continue continue-btn-saved-payments "
        data-testid={dataTestId}
        type="submit"
        id="payment-continue-button"
        disabled={checkoutContainer.isPaymentApiInProgress}
        onClick={event => {
          // CX121-3102 - to prevent multiple clicks
          // https://stackoverflow.com/a/42664936
          if (!event.detail || event.detail == 1) {
            this.handleSavedCreditCardContinue()
          }
        }}>
        {checkoutContainer.isPaymentApiInProgress ? (
          <span className="px-4">
            <Spinner
              animation="border"
              role="status"
              size="md"
              variant="primary"
            />
          </span>
        ) : (
          i18nTranslate('checkout.continue', 'Continue')
        )}
      </Button>
    )
  }

  /**
   * @description Renders a "Save Changes" button for editing a payment method.
   * @returns {React.ReactElement} The "Save Changes" button component.
   */
  renderSaveChangesButtonForEditPayment = () => {
    return (
      <Button
        className="px-5 py-2 save-payment list-specific-continue"
        type="submit">
        {i18nTranslate('checkout.saveChanges', 'Save Changes')}
      </Button>
    )
  }

  /**
   * @description Renders the submit button for the payment list with form component.
   * @returns {JSX.Element} The submit button element.
   */
  renderSubmitButton = () => {
    const { isFromEditSubscription = false } = this.props
    const buttonView = isFromEditSubscription
      ? this.renderSaveChangesButtonForEditPayment()
      : this.renderContinueButtonForSavedPayments()
    return (
      <Form.Row className="button-wrapper justify-content-end">
        {buttonView}
      </Form.Row>
    )
  }

  /**
   * @description Renders the confirmation text and buttons for deleting a saved payment method.
   * @returns {JSX.Element} The rendered confirmation text and buttons.
   */
  renderDeletePaymentConfirmationText = () => {
    const cardNumber = this.cardsDetails?.accountInfo?.cardNumber || ''
    const cardProvider = this.cardsDetails?.accountInfo?.provider || ''

    return (
      <div>
        <div className="delete-msg-wrapper">
          <span>
            {i18nTranslate('payment.deleteMessageStart', 'Delete')}&nbsp;
          </span>
          <span>{capitalizeFirstLetter(cardProvider)} &nbsp;</span>
          <span>****{cardNumber}&nbsp;</span>
          <span>
            {i18nTranslate(
              'payment.deleteMessageEnd',
              'from your saved payments?'
            )}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-outline-primary cancel-btn"
            data-testid="delete-cancel-btn"
            onKeyPress={this.handleDeleteConfirmationModalClose}
            onClick={this.handleDeleteConfirmationModalClose}>
            {i18nTranslate('payment.cancelDelete', 'Cancel')}
          </button>
          <button
            className="btn btn-primary confirm-btn"
            data-testid="delete-confirm-btn"
            onKeyPress={() => {
              this.handleDeletePayment(this.deleteId)
            }}
            onClick={() => {
              this.handleDeletePayment(this.deleteId)
            }}>
            {i18nTranslate('payment.confirmDelete', 'Confirm')}
          </button>
        </div>
      </div>
    )
    //this.deleteId = ''
  }

  /**
   * @description Handles the selection of a payment card.
   * @param {Object} [selectedCard={}] - The selected payment card.
   * @returns {void}
   */
  handleSelect = (selectedCard = '') => {
    /**
     * @note
     * Handling Iframe Loads through this states if any card is deleted
     */
    if (this.isFromDeleteCard) {
      this.isFromDidMount = true
      this.isFromDeleteCard = false
    }
    customerContainer.paymentResponse?.payments?.find(card => {
      if (card.isDefault === true) {
        card.isDefault = false
      }
      if (card.id === selectedCard.id) {
        card.isDefault = true
        if (!savedCardsContainer.savedCardsList[selectedCard.id]) {
          savedCardsContainer.savedCardsList[selectedCard.id] =
            new TokenExCvvContainer()
        }
      }
    })
    this.selectedPayment = selectedCard
  }

  /**
   * @description Handles the selection of a payment type for adding a new payment method.
   * @param {string} [type=''] - The selected payment type, defaults to an empty string.
   * @returns {void}
   */
  handlePaymentTypeSelect = (type = '', key) => {
    this.selectedAddPaymentType = type
    if (isExpressCheckout()) {
      this.activePaymentKey = key
      this.handleSubmitButtonState(type)
      if (type !== 'CREDITCARD') {
        overlayState.toggleLoader()
        this.handleAlternatePayment(type)
      }
    }
    if (this.selectedAddPaymentType === 'CREDITCARD') {
      this.tokenExCvvIframeLoader()
      checkoutContainer.addPaymentToProfile = customerContainer.isRegisterUser
    }
  }

  deleteMultiplePayment = async () => {
    const paymentList = cartContainer?.cartResponse?.payments || []
    const paymentTypes = ['LOYALTY', 'STORECREDIT']
    await Promise.all(
      paymentList.map(async payment => {
        if (!paymentTypes.includes(payment?.type)) {
          await checkoutContainer.deletePaymentById(payment.id)
        }
      })
    )
  }

  /**
   * @description Handles the addition of an alternate payment method to the user's payment profile.
   * @param {string} [type=''] - The type of alternate payment method to add, such as 'PAYPAL', 'VENMO', 'ALIPAY', or 'KLARNA'.
   * @returns {Promise<void>} - A Promise that resolves when the payment method has been added successfully.
   */
  handleAlternatePayment = async (type = '') => {
    this.isAddPaymentCallInProgress = true
    const communicationResponse =
      await checkoutContainer.setCommunicationPreference(
        cartContainer?.cartResponse?.deliveryDetails?.address || {}
      )

    if (!checkoutContainer.isSuccessResponse(communicationResponse)) {
      checkoutContainer.isPaymentApiInProgress = false
      toastState.setToastMessage(
        communicationResponse.message || communicationResponse?.errorMessage
      )
      this.isAddPaymentCallInProgress = false
      if (isExpressCheckout()) {
        this.handlePaymentTypeSelect('CREDITCARD', 0)
        overlayState.toggleLoader()
      }
      return
    }
    const bagValue = Math.abs(
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || ''
    )

    const postData = [
      {
        name: type?.toLowerCase(),
        isDefault: this.alternateDefaultPaymentCheckbox,
        status: 'active',
        type: type?.toUpperCase()?.replace(/ /g, '_'), // As per BE request, always send Type in Uppercase and Replace Space with '_'
        amount: bagValue,
        option: {
          addPaymentToProfile: this.savePaymentCheckbox,
          useDefaultPayment: false,
          setAsDefaultPaymentInProfile: false,
        },
        isValid: true,
      },
    ]
    const response = await checkoutContainer.addAlternatePayment(postData)
    if (checkoutContainer.isSuccessResponse(response)) {
      venmoContainer.venmoClientCreated = false
      venmoContainer.venmoInitiate = false
      paypalContainer.paypalInitiate = false
      paypalContainer.paypalClientCreated = false
      googlePayContainer.isToInitiateGooglePayPayment = false
      googlePayContainer.googlePayClientCreated = false
      applepayContainer.applepayInitiate = false
      applepayContainer.applepayClientCreated = false
      if (type === 'PAYPAL') {
        const paypaltoken = await checkoutContainer.getPayPalToken()
        this.props.handlePaypalInitiate(paypaltoken?.token)
      }
      if (type === 'VENMO') {
        const venmotoken = await checkoutContainer.getBrainTreePaymentToken(
          'VENMO'
        )
        this.props.handleVenmoInitiate(venmotoken?.token)
      }
      type === 'ALIPAY' && this.props.handleAlipayInitiate()
      if (type === 'KLARNA') {
        this.props.handleKlarnaInitiate()
      }

      if (type === 'GOOGLEPAY') {
        const googlePayToken =
          (await checkoutContainer.getBrainTreePaymentToken('GOOGLEPAY')) || {}
        await this.props.handleGooglePayInitiate(googlePayToken?.token)
      }
      if (type === 'APPLEPAY') {
        const applepayToken =
          (await checkoutContainer.getBrainTreePaymentToken('APPLEPAY')) || {}
        await this.props.handleApplePayInitiate(applepayToken?.token)
      }
      this.props.onContinue()
      await cartContainer.viewCart()
      checkoutContainer.isToDisableSubmitOrderButton = false
      trackCustomCheckoutEvent({
        eventType: 'payment',
        cartResponse: cartContainer?.cartResponse,
      })
      if (isExpressCheckout()) {
        this.showUseDifferentCardForm = false
        this.showAddNewCardForm = false
        this.showPaymentList = false
        overlayState.toggleLoader()
      }
    } else {
      if (isExpressCheckout()) {
        overlayState.toggleLoader()
      }
    }
    this.isAddPaymentCallInProgress = false
  }

  /**
   * @description Handles the process of adding an alternate saved payment method to the checkout flow.
   * @param {string} [type=''] - The type of payment method being added (e.g. 'PAYPAL', 'VENMO', 'ALIPAY', 'KLARNA').
   * @returns {Promise<void>} - A Promise that resolves when the payment method has been added successfully.
   */
  handleAlternateSavedPayment = async (type = '') => {
    const { title = '' } = this.props
    const products = cartContainer?.cartResponse?.items || []
    trackEnhancedCheckout({
      type: 'checkout',
      action: { step: '6', option: title },
      products: products,
    })

    const communicationResponse =
      await checkoutContainer.setCommunicationPreference(
        cartContainer?.cartResponse?.deliveryDetails?.address || {}
      )

    if (!checkoutContainer.isSuccessResponse(communicationResponse)) {
      checkoutContainer.isPaymentApiInProgress = false
      toastState.setToastMessage(
        communicationResponse?.message || communicationResponse?.errorMessage
      )
      return
    }

    const bagValue = Math.abs(
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || ''
    )

    const postData = [
      {
        name: type?.toLowerCase(),
        status: 'active',
        type,
        amount: bagValue,
        isValid: true,
      },
    ]
    const response = await checkoutContainer.addAlternatePayment(postData)
    venmoContainer.venmoClientCreated = false
    venmoContainer.venmoInitiate = false
    paypalContainer.paypalInitiate = false
    paypalContainer.paypalClientCreated = false
    googlePayContainer.isToInitiateGooglePayPayment = false
    googlePayContainer.googlePayClientCreated = false
    checkoutContainer.isToDisableSubmitOrderButton = false
    applepayContainer.applepayInitiate = false
    applepayContainer.applepayClientCreated = false
    if (checkoutContainer.isSuccessResponse(response) && type === 'PAYPAL') {
      const paypaltoken = await checkoutContainer.getPayPalToken()
      this.props.handlePaypalInitiate(paypaltoken?.token)
    }
    if (checkoutContainer.isSuccessResponse(response) && type === 'VENMO') {
      const venmotoken = await checkoutContainer.getBrainTreePaymentToken(
        'VENMO'
      )
      this.props.handleVenmoInitiate(venmotoken?.token)
    }
    this.props.onContinue()
    type === 'ALIPAY' && this.props.handleAlipayInitiate()
    cartContainer.viewCart()
    trackCustomCheckoutEvent({
      eventType: 'payment',
      cartResponse: cartContainer?.cartResponse,
    })
  }

  /**
   * @description Handles the click event of the delete button for a payment card.
   * @param {Event} e - The click event object.
   * @param {Object} [card={}] - The payment card object to be deleted.
   */
  handleDeleteButtonClick = (e, card = {}) => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
    this.isToShowDeleteConfirmationModal = true
    this.deleteId = card?.id || ''
    this.cardsDetails = card || {}
  }

  /**
   * @description Handles the click event of the update button for a payment card.
   * @param {Event} e - The click event object.
   * @param {Object} [cards={}] - The details of the payment card to be updated.
   */
  handleUpdateModal = (e, cards = {}) => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
    this.isToShowEditUpdatePaymentModal = true
    this.cardsDetails = cards || {}
    this.IdofCardToBeEdited = cards?.id || ''
  }

  /**
   * @description Closes the edit/update payment modal.
   */
  handleUpdateModalClose = () => {
    this.isToShowEditUpdatePaymentModal = false
  }

  /**
   * @description Closes the delete confirmation modal.
   */
  handleDeleteConfirmationModalClose = () => {
    this.isToShowDeleteConfirmationModal = false
  }

  /**
   * @description Deletes a payment from the customer's payment methods.
   * @param {string} [ids=''] - The ID of the payment to delete. If not provided, the current page's payment will be deleted.
   * @returns {void}
   */
  handleDeletePayment = (ids = '') => {
    customerContainer.deletePayment({
      id: ids || '',
      page: this.currentPage || 1,
    })
    this.isToShowDeleteConfirmationModal = false
    this.cancelPaymentFlag()
    /**
     * @note
     * If non-default card is selected and is deleted, setting selectedPayment to default card, if default card is deleted then passing a state as true to load iframe on didupdate
     */
    if (this.isNotSelectedDefaultCard && ids != this.theDefaultCardId) {
      this.selectedPayment.id = this.theDefaultCardId
    } else if (this.isNotSelectedDefaultCard && ids === this.theDefaultCardId) {
      this.isFromDeleteCard = true
    } else if (!this.isNotSelectedDefaultCard) {
      this.previousCardID = ''
      this.isFromDeleteCard = true
    }
  }

  /**
   * @description Handles the default payment card selection.
   * @param {React.SyntheticEvent} e - The event object passed to the event handler.
   */
  handleDefaultChecked = e => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
    customerContainer.paymentResponse?.payments?.find?.(cards => {
      if (cards.isDefault === true) {
        this.isDefaultCardId = cards?.id || ''
        this.selectedPayment = cards || {}
      }
    })
  }

  /**
   * @description Checks if the cart contains any subscription products.
   */
  checkIfCartHasSubscriptionProducts = () => {
    cartContainer.cartResponse?.items?.map(item => {
      if (item?.isSubscription === true) {
        this.hasSubscriptionProductInCart = true
      }
    })
  }

  /**
   * @description Handles the default payment change event.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
   * @param {string} [ids=''] - The ID of the payment to set as default.
   * @returns {void}
   */
  handleDefaultChange = (e, ids = '') => {
    const postData = { isDefault: true }
    customerContainer.updateDefaultPayment({
      postData,
      id: ids,
    })
    this.handleDefaultChecked(e)
  }

  /**
   * @description Renders a form for submitting a Purchase Order (PO) payment.
   * @returns {JSX.Element} The rendered PO form.
   */
  renderPOForm = () => {
    const constructPOStyle = {
      formRowClassName: 'mx-0 px-2',
    }
    this.paymentType = 'PO'
    return (
      <CommonForm
        schema={POSchema}
        formTypeJson={renderPOFormInput()}
        SubmitButtonProps={this.renderSubmitButton}
        hasInlineSubmitButton={false}
        classNameValue={constructPOStyle}
        onSubmit={data =>
          this.props.handlePayment({ ...data, isPONumber: true })
        }
      />
    )
  }

  /**
   * @description Renders the CVV number input field and associated UI elements.
   * @param {Array} [id, cardLabel] - An array containing the ID and card label for the CVV input.
   * @returns {JSX.Element} - The rendered CVV number input and associated UI elements.
   */
  renderCvvNumber = ([id, cardLabel]) => {
    return (
      <div className="d-flex align-items-center cvv-info-wrapper">
        <div className="tokenCvv-block ml-md-2" id={`cvvParent${id}`}>
          <div
            className="cvvIframeHeight"
            value=""
            id={`cvvTokenID${id}`}></div>
          <div className="cardCvvtoken">
            {i18nTranslate(
              'validation.cardCvvErrorText',
              'Please enter valid security code'
            )}
          </div>
        </div>
        {this.renderCVVInfoIcon(cardLabel)}
      </div>
    )
  }

  /**
   * @description Renders the card detail UI component.
   * @param {string} [cardLabel=''] - The label of the card, including the provider and last 4 digits.
   * @param {string} [name=''] - The name of the cardholder.
   * @returns {JSX.Element} - The rendered card detail component.
   */
  renderCardDetail = (cardLabel = '', name = '', billingAddress = null) => {
    const iconType = cardLabel?.provider && cardLabel?.provider?.toLowerCase()
    const currentDateTimeStamp = Date.now()
    const { month, year } =
      dateFormatDeps.convertTimeStampToDate(currentDateTimeStamp)
    this.isCardExpired =
      year < cardLabel.expiryYear
        ? true
        : year == cardLabel.expiryYear
        ? month <= cardLabel.expiryMonth
          ? true
          : false
        : false

    const endingText = i18nTranslate('payment.cardEndingTxt', '-****')
    const expiryText = i18nTranslate('payment.cardExpiresText', 'Expires')
    const expiredText = i18nTranslate(
      'payment.cardExpiredText',
      'Card is Expired'
    )
    return (
      <>
        {!isExpressCheckout() && (
          <div className="mx-2 pb-2">{`${name?.firstName?.toUpperCase()} ${
            name?.firstName?.toUpperCase() !== name?.lastName?.toUpperCase()
              ? name?.lastName?.toUpperCase()
              : ''
          }`}</div>
        )}
        <div className="d-flex mx-2">
          <PaymentIcon type={iconType} />
          <div className="creditCard-label">
            <h5 className="card-info ml-1">
              {capitalizeFirstLetter(cardLabel?.provider)} {endingText}{' '}
              {cardLabel?.cardNumber}
            </h5>
            {!isExpressCheckout() && (
              <span
                className={
                  this.isCardExpired
                    ? 'paragraph-s ml-1 d-flex'
                    : 'paragraph-s text-danger ml-1 d-flex'
                }>
                {this.isCardExpired ? expiryText : expiredText}{' '}
                {`${cardLabel?.expiryMonth}/${cardLabel?.expiryYear?.substr(
                  2
                )}`}
              </span>
            )}
          </div>
        </div>
        {billingAddress && (
          <span className="d-flex flex-row express-address-payment mt-2 flex-wrap">
            <span className="fname-normal-shiping">
              {`${name?.firstName?.toUpperCase()} ${
                name?.firstName?.toUpperCase() !== name?.lastName?.toUpperCase()
                  ? name?.lastName?.toUpperCase()
                  : ''
              }`}
            </span>
            <span className="fname-normal-shiping" data-testid="qa-street">
              {billingAddress?.addressLine1 || ''}
            </span>
            <span className="fname-normal-shiping" data-testid="qa-street">
              {billingAddress?.addressLine2 === ''
                ? billingAddress?.addressLine2 || ''
                : `${billingAddress?.addressLine2}, ` || ''}
              {billingAddress?.city || ''}, {billingAddress?.state || ''},{' '}
              {billingAddress?.zip && (
                <span className="fname-normal-shiping" data-testid="qa-zip">
                  {billingAddress?.zip || ''},{' '}
                </span>
              )}
            </span>
            <span className="fname-normal-shiping" data-testid="qa-country">
              {billingAddress?.country || ''}
            </span>
          </span>
        )}
      </>
    )
  }

  /**
   * @description Renders an alternate payment detail block with an icon and text.
   * @param {string} [type=''] - The type of alternate payment method, such as 'ALIPAY', 'PAYPAL', 'VENMO', or 'KLARNA'.
   * @returns {JSX.Element} - A React component that displays the alternate payment detail block.
   */
  renderAlternatePaymentDetail = (type = '') => {
    return (
      <div className="d-flex mx-2 paymentType-block">
        <PaymentIcon type={type.toLowerCase()} />
        <p className="paymentType-block-texts ml-3">
          {type === 'ALIPAY' && i18nTranslate('checkout.alipayText', 'Alipay')}
          {type === 'PAYPAL' && i18nTranslate('checkout.paypalText', 'PayPal')}
          {type === 'VENMO' && i18nTranslate('checkout.venmoText', 'Venmo')}
          {type === 'KLARNA' && i18nTranslate('checkout.klarnaText', 'Klarna')}
          {type === 'BANKTRANSFER' &&
            i18nTranslate('checkout.banktransferText', 'Bank Deposit')}
        </p>
      </div>
    )
  }

  /**
   * @description Renders a list of payment icons based on the store's country.
   * @returns {JSX.Element[]} An array of `PaymentIcon` components representing the payment icons.
   */
  renderCreditCardPaymentIcons = () => {
    const iconTypes =
      storeContainer.storeIDValue === 'US'
        ? ['visa', 'mastercard', 'discover', 'amex']
        : ['visa', 'mastercard', 'amex']
    return iconTypes?.map((iconType, index) => {
      return <PaymentIcon key={index} type={iconType} />
    })
  }

  /**
   * @description Renders the Klarna terms and service link for the payment form.
   * It displays a message with links to the Klarna Shopping Service terms and the Klarna Privacy Policy.
   */
  renderKlarnaTermsAndServiceLink = () => {
    if (IS_BROWSER) {
      const links = new FormInputLabels()
      return (
        <div
          className="klarna-terms-message"
          data-testid="qa-klarna-terms-message">
          <span className="pr-1">
            {i18nTranslate(
              'checkout.klarnaMessage1',
              'By continuing, I accept the'
            )}
          </span>
          <span
            className="pr-1 klarna-link"
            data-testid="qa-klarna-service-link">
            <a
              href={links?.retailCustomerLinks?.klarnaShoppingServiceLink}
              target="_blank"
              rel="noopener noreferrer">
              {i18nTranslate(
                'checkout.klarnaServiceLink',
                'Klarna Shopping Service'
              )}
            </a>
          </span>
          <span className="pr-1">
            {i18nTranslate(
              'checkout.klarnaMessage2',
              'terms and confirm that I have read the'
            )}
          </span>
          <span data-testid="qa-klarna-policy-link" className="klarna-link">
            <a
              href={links?.retailCustomerLinks?.klarnaPrivacyPolicyLink}
              target="_blank"
              rel="noopener noreferrer">
              {i18nTranslate('checkout.klarnaPrivacyLink', 'Privacy Policy.')}
            </a>
          </span>
        </div>
      )
    }
  }

  /**
   * @description Renders a description for the payment type, displaying the Klarna terms and service link if the payment type is 'KLARNA' and is the selected add payment type.
   * @param {string} paymentType - The payment type to render the description for.
   * @returns {JSX.Element} - The rendered description, either the Klarna terms and service link or an empty fragment.
   */
  renderDescriptionForPayment = (paymentType = '') => {
    if (
      paymentType === this.selectedAddPaymentType &&
      paymentType === 'KLARNA'
    ) {
      return this.renderKlarnaTermsAndServiceLink()
    }
    return <></>
  }

  /**
   * @description Renders the appropriate payment icon based on the provided payment type.
   * @param {string} paymentType - The payment type to render the icon for.
   * @returns {React.ReactNode} - The payment icon component.
   */
  renderPaymentIcons = (paymentType = '') => {
    if (paymentType === 'creditcard') {
      return this.renderCreditCardPaymentIcons()
    }

    return <PaymentIcon type={paymentType} />
  }

  /**
   *  @description Handles the state of the submit button based on the payment type.
   *               This function is only executed if the checkout is in express mode.
   * @param {string} type - The type of payment selected.
   *                        Expected values are 'CREDITCARD', 'GOOGLEPAY', 'APPLEPAY, 'ALIPAY' ...
   *                        If the type is 'CREDITCARD', the submit order button will be enabled.
   *                        Otherwise, the submit order button will be disabled.
   */
  @action
  handleSubmitButtonState(type) {
    if (!isExpressCheckout()) {
      return
    }
    checkoutContainer.selectedPaymentType = type
    checkoutContainer.isToDisableSubmitOrderButton = type !== 'CREDITCARD'
    if (checkoutContainer.selectedPaymentType === 'CREDITCARD') {
      venmoContainer.venmoClientCreated = false
      venmoContainer.venmoInitiate = false
      paypalContainer.paypalInitiate = false
      paypalContainer.paypalClientCreated = false
      googlePayContainer.isToInitiateGooglePayPayment = false
      applepayContainer.applepayInitiate = false
      applepayContainer.applepayClientCreated = false
    }
  }

  /**
   * @description Renders a radio option for adding a new payment method.
   * @returns {JSX.Element} - The rendered payment radio option.
   */
  renderAddNewPaymentRadioOption = (paymentDetails = {}, key) => {
    const {
      type = '',
      displayName = '',
      dataTestId = 'qa-payment-radio-option',
      paymentId = '',
      iconType = '',
      isToShowPaymentOption = false,
      isToShowDescription = false,
    } = paymentDetails || {}
    const paymentType = type?.toLowerCase() || ''
    if (isExpressCheckout()) {
      return (
        isToShowPaymentOption && (
          <Accordion
            activeKey={this.activePaymentKey.toString()}
            defaultActiveKey="0">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={key.toString()}
                className={`${
                  this.selectedAddPaymentType === type ? 'active-accordion' : ''
                }`}>
                <div className="payment-option-container pt-3">
                  <input
                    type="radio"
                    name="paymentTypeRadios"
                    id={paymentId}
                    aria-label={`Selected ${displayName}`}
                    onChange={() => this.handlePaymentTypeSelect(type, key)}
                    key={'radio' + paymentType}
                    checked={this.selectedAddPaymentType === type}
                    className="payment-options"
                    data-testid={dataTestId}
                    tabIndex={0}
                    style={{ top: '0' }}
                  />
                  <label htmlFor={paymentId} className="payment-label">
                    <div className="payment-logo-name">
                      {type === 'CREDITCARD' && (
                        <div className="text-capitalize payment-title mb-0 font-weight-bold">
                          {displayName}
                        </div>
                      )}
                      <div className="mb-0 d-flex gap-1">
                        {this.renderPaymentIcons(iconType)}
                      </div>
                    </div>
                  </label>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey={key.toString()}
                className="active-accordion-view">
                <Card.Body className="p-0">
                  {this.selectedAddPaymentType === 'CREDITCARD' &&
                    this.selectedAddPaymentType === type &&
                    this.renderCreditCardAddPaymentForm(
                      this.selectedAddPaymentType
                    )}
                  {this.selectedAddPaymentType !== 'CREDITCARD' &&
                    this.selectedAddPaymentType === type &&
                    this.selectedAddPaymentType !== '' &&
                    this.renderContinueButtonForAlternatePayments(
                      this.selectedAddPaymentType
                    )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )
      )
    }
    return (
      isToShowPaymentOption && (
        <>
          <input
            type="radio"
            name="paymentTypeRadios"
            id={paymentId}
            onChange={() => this.handlePaymentTypeSelect(type)}
            key={'radio' + paymentType}
            checked={this.selectedAddPaymentType === type}
            className="payment-options w-100"
            data-testid={dataTestId}
          />
          <label htmlFor={paymentId}>
            <div>
              <p className="text-capitalize payment-title">{displayName}</p>
              <p className="mb-0">{this.renderPaymentIcons(iconType)}</p>
              {isToShowDescription && this.renderDescriptionForPayment(type)}
            </div>
          </label>
        </>
      )
    )
  }

  /**
   * @description Renders the payment type selection options for adding a new payment method.
   * @returns {JSX.Element} The payment type selection options.
   */
  renderPaymentType = (removeTitle = false) => {
    // @note Add New Payment Section - Payment Options and its details are configured in getPaymentsForAddNewSection function
    // To add a new Payment Option, add its config in getPaymentsForAddNewSection function.
    const addNewPaymentOptions = getPaymentsForAddNewSection() || []

    return (
      <div className="paymentType-container expressPaymentType-container">
        {isExpressCheckout() ? (
          <>
            {!removeTitle && (
              <>
                <ExpressSectionHeader
                  title={i18nTranslate(
                    'expressCheckout.addPaymentMethods',
                    'Payment'
                  )}
                  disableAccordion={true}
                />
                <p className="mb-0 payment-description" tabIndex={0}>
                  {i18nTranslate(
                    'expressCheckout.addPaymentMethodsDesc',
                    'All transactions are secure and encrypted'
                  )}
                </p>
              </>
            )}
          </>
        ) : (
          <p
            className="font-weight-bold"
            tabIndex={0}
            data-testid="qa-add-payment-methods">
            {i18nTranslate('checkout.addPaymentMethods', 'Add Payment Methods')}
          </p>
        )}
        <div className="paymentTypeRadio">
          {addNewPaymentOptions?.map((paymentDetails, key) => {
            if (
              this.enableLiveEvent === 'true' &&
              (paymentDetails?.type === 'PAYPAL' ||
                paymentDetails?.type === 'VENMO' ||
                paymentDetails?.type === 'GOOGLEPAY' ||
                paymentDetails?.type === 'APPLEPAY' ||
                paymentDetails?.type === 'BANKTRANSFER')
            ) {
              return
            }
            return this.renderAddNewPaymentRadioOption(paymentDetails, key)
          })}
        </div>
      </div>
    )
  }

  /**
   * @description Cancels the payment flag and resets various payment-related states.
   */
  cancelPaymentFlag = () => {
    tokenExIframe.isValidCard = true
    tokenExIframe.isCvvValidCard = true
    this.selectedAddPaymentType = ''
    paypalContainer.paypalInitiate = false
    paypalContainer.paypalClientCreated = false
    googlePayContainer.isToInitiateGooglePayPayment = false
    googlePayContainer.googlePayClientCreated = false
    venmoContainer.venmoInitiate = false
    checkoutContainer.isToDisableSubmitOrderButton = isExpressCheckout()
      ? false
      : true
    venmoContainer.venmoClientCreated = false
    applepayContainer.applepayInitiate = false
    applepayContainer.applepayClientCreated = false
    this.showUseDifferentCardForm = false
  }

  @action
  createPaymentListArray(selectedCard, renderOneCard = false) {
    if (isExpressCheckout()) {
      return selectedCard && renderOneCard
        ? [selectedCard]
        : [...customerContainer.paymentResponse?.payments]
    }

    return selectedCard
      ? [...customerContainer.paymentResponse?.payments]
      : [selectedCard]
  }
  /**
   * @description Renders the payment list with a form for the user to select a payment method.
   * This method first filters the `paymentResponse.payments` array to ensure the default card is displayed first. It then maps over the filtered array and renders a payment option for each card, including credit/debit cards and alternate payment methods like Alipay, Paypal, Venmo, and Klarna.
   * For credit/debit cards, it displays the card details and allows the user to select the card. If the card is selected, it renders a CVV form for the user to enter the CVV.
   * For alternate payment methods, it renders a "Continue" button that the user can click to proceed with that payment method.
   */
  renderPaymentList = (renderOneCard = false) => {
    const selectedCard =
      customerContainer.paymentResponse?.payments?.find(
        card => card.isDefault === true || card.id === this.isDefaultCardId
      ) || customerContainer.paymentResponse?.payments[0]
    let paymentResponseArray = this.createPaymentListArray(
      selectedCard,
      renderOneCard
    )
    paymentResponseArray?.forEach?.((elem, idx) => {
      if (elem?.id === this.isDefaultCardId) {
        paymentResponseArray?.splice(idx, 1)
        paymentResponseArray?.unshift(elem)
      }
    })

    return paymentResponseArray?.map(cards => {
      const name = {
        firstName: cards?.accountInfo?.name || '',
        lastName: cards?.accountInfo?.lastName || '',
      }
      const isSelected = cards?.isDefault
      if (isSelected) {
        this.loadIframeInitially = true //passing this as true if there is default card in the payments array list
      }
      const deleteLabel =
        cards?.type === 'CREDITCARD' || cards?.type === 'DEBITCARD'
          ? i18nTranslate('cart.deleteCard', 'Delete Card')
          : i18nTranslate('cart.delete', 'Delete')

      const cardLabel = getCardDetails(cards.accountInfo)

      const isToHideDiscoverCardForCA =
        storeContainer?.storeIDValue === 'Canada' &&
        cardLabel?.provider === 'DISCOVER'
          ? true
          : false
      const billingAddress = isExpressCheckout() && cards?.billingAddress
      return (
        (cards?.type === 'CREDITCARD' ||
          cards?.type === 'DEBITCARD' ||
          !this.hasSubscriptionProductInCart) &&
        !isToHideDiscoverCardForCA && (
          <div
            className={`flex-sm-row  mb-4 expresss-payment-card ${
              checkoutContainer.isPaymentApiInProgress
                ? 'disable-payment-section'
                : ''
            }
            ${
              isExpressCheckout() && isSelected
                ? 'expresss-payment-card-selected'
                : ''
            }
            `}
            key={'container' + cards?.id}
            data-testid="qa-saved-card">
            <div
              className="d-sm-flex justify-content-between saved-card-block "
              id="block1"
              /**
               * https://nuskin.atlassian.net/browse/CX121-5519
               * Commented this ADA Fix, as it affected the Saved Payment Card Flow
               */
              // role="radio"
              // tabIndex={0}
              // onClick={() => this.handleSelect(cards)}
              // onKeyPress={() => this.handleSelect(cards)}
              data-testid="qa-saved-card-row">
              <div
                style={{
                  width: '100%',
                  objectFit: 'contain',
                }}>
                <div
                  id="block-inner"
                  key={'inner' + cards?.id}
                  /**
                   * https://nuskin.atlassian.net/browse/CX121-5519
                   * adding this ADA Fix, as it affected the Saved Payment Card Flow
                   */
                  role="radio"
                  aria-checked={isSelected ? true : false}
                  tabIndex={0}
                  onClick={() => this.handleSelect(cards)}
                  onKeyPress={() => this.handleSelect(cards)}>
                  <Form.Check
                    id={`cards-${cards?.id}`}
                    name="paymentListRadios"
                    className="paymentListRadios w-100"
                    key={'radio' + cards?.id}
                    label={
                      cards?.type === 'CREDITCARD' ||
                      cards?.type === 'DEBITCARD'
                        ? this.renderCardDetail(cardLabel, name, billingAddress)
                        : this.renderAlternatePaymentDetail(cards?.type)
                    }
                    type="radio"
                    checked={isSelected ? true : false}
                    aria-checked={isSelected ? true : false}
                    tabIndex={0}
                    data-testid={isSelected ? 'qa-facet-selected' : 'qa-facet'}
                    onClick={() => this.handleSelect(cards)}
                    onKeyPress={() => this.handleSelect(cards)}
                    disabled={checkoutContainer.isPaymentApiInProgress}
                  />
                </div>
                {!application.isMobile &&
                  this.renderDeleteCardBlock(isSelected, cards, deleteLabel)}
              </div>
              {isSelected &&
                (cards?.type === 'CREDITCARD' ||
                  cards?.type === 'DEBITCARD') && (
                  <>
                    {isExpressCheckout() && (
                      <button
                        disabled={checkoutContainer.isPaymentApiInProgress}
                        className="font-weight-bold delete-btn btn express-delete-btn"
                        aria-label="Edit card link"
                        onClick={e => this.handleUpdateModal(e, cards)}
                        data-testid={
                          this.isCardExpired
                            ? 'qa-edit-payment'
                            : 'qa-update-payment'
                        }>
                        {this.isCardExpired
                          ? i18nTranslate('edit', 'Edit')
                          : i18nTranslate('catalog.Update', 'Update', {
                              nameSpace: 'ssr-resource',
                            })}
                      </button>
                    )}
                    <div
                      id="checkout-payment-cvv"
                      className="d-flex align-items-center selected-card-cvv-detail ">
                      <CommonForm
                        schema={cardCVVSchema(cardLabel)}
                        formTypeJson={renderFormInput({
                          renderCvvNumber: this.renderCvvNumber.bind(this, [
                            cards.id,
                            cardLabel,
                          ]),
                        })}
                        SubmitButtonProps={this.renderSubmitButton}
                        hasInlineSubmitButton={false}
                        classNameValue={constructStyle}
                        onSubmit={data => {
                          this.handleSavedCreditCardContinue()
                          // this.props.handlePayment({
                          //   ...data,
                          //   ...this.selectedPayment,
                          //   savedCardValidStatus: this.savedCardValidStatus,
                          //   isFromSavedAddress: true,
                          // })
                        }}
                      />
                    </div>
                  </>
                )}
              {isSelected && cards?.type === 'ALIPAY' && (
                <Button
                  className="px-5 py-2 add-payment-continue list-specific-continue"
                  type="submit"
                  data-testid="qa-continue-btn"
                  onClick={() => this.handleAlternateSavedPayment('ALIPAY')}>
                  {i18nTranslate('checkout.continue', 'Continue')}
                </Button>
              )}
              {isSelected && cards?.type === 'PAYPAL' && (
                <Button
                  className="px-5 py-2 add-payment-continue list-specific-continue"
                  type="submit"
                  data-testid="qa-continue-btn"
                  onClick={() => this.handleAlternateSavedPayment('PAYPAL')}>
                  {i18nTranslate('checkout.continue', 'Continue')}
                </Button>
              )}
              {isSelected && cards?.type === 'VENMO' && (
                <Button
                  className="px-5 py-2 add-payment-continue list-specific-continue"
                  type="submit"
                  data-testid="qa-continue-btn"
                  onClick={() => this.handleAlternateSavedPayment('VENMO')}>
                  {i18nTranslate('checkout.continue', 'Continue')}
                </Button>
              )}
              {isSelected && cards?.type === 'KLARNA' && (
                <Button
                  className="px-5 py-2 add-payment-continue list-specific-continue"
                  type="submit"
                  data-testid="qa-continue-btn"
                  onClick={() => this.handleAlternateSavedPayment('KLARNA')}>
                  {i18nTranslate('checkout.continue', 'Continue')}
                </Button>
              )}
            </div>
            {application.isMobile &&
              this.renderDeleteCardBlock(isSelected, cards, deleteLabel)}
          </div>
        )
      )
    })
  }

  /**
   * @description Determines the payment type and user type based on the active contract's payment method and the user's account type.
   * @returns {object} An object with the following properties:
   *   - `isPaymentTypePONumber`: `true` if the payment method includes 'po' or 'purchase order', `false` otherwise
   *   - `isPaymentTypeCardNumber`: `true` if the payment method includes 'ali', `false` otherwise
   *   - `isBuyerUser`: `true` if the user is a buyer user, `false` otherwise
   */
  conditionBasedTabs = () => {
    if (isB2BAccount() && !isB2B2C()) {
      const isBuyerUser = isB2BUser()
      const { paymentMethod = '' } = accountsContainer?.activeContract || {}
      const isPaymentTypePONumber =
        paymentMethod?.toLowerCase()?.includes?.('po') ||
        paymentMethod?.toLowerCase()?.includes?.('purchase order')
      const isPaymentTypeCardNumber = paymentMethod
        ?.toLowerCase()
        ?.includes?.('ali')
      return {
        isPaymentTypePONumber,
        isPaymentTypeCardNumber,
        isBuyerUser,
      }
    }
    return {}
  }

  /**
   * @description Renders a CVV information icon with a tooltip that displays information about the CVV.
   * @param {string} [cardLabel=''] - The label for the card, which is used in the tooltip text.
   * @returns {React.ReactElement} - A React element representing the CVV information icon and tooltip.
   */
  renderCVVInfoIcon = (cardLabel = '') => {
    return (
      <OverlayTrigger
        trigger={'click'}
        placement="top"
        rootClose={true}
        overlay={
          <Tooltip
            id={`tooltip`}
            className="saved-cvv-tooltip"
            data-testid="qa-cvv-tooltip">
            {i18nTranslate(
              'payment.cvvInfo',
              'To proceed with your transaction, enter your Card Verification Value found at the back of your card.'
            )}
          </Tooltip>
        }>
        <Button
          className="saved-cvv-tooltip-info-button"
          data-testid="qa-cvv-info-icon">
          <InformationIcon
            size="1.5rem"
            id="info-black-button"
            className="infotool"
            aria-label="information tool"
          />
        </Button>
      </OverlayTrigger>
    )
  }

  /**
   * @description if card is expired then update button will render else else button will render.   * @date 9/28/2023 - 12:36:56 PM
   * @date 9/28/2023 - 12:36:56 PM
   *
   * @param {*} isSelected on which card user taking action
   * @param {*} cards contains all card related details
   * @param {*} deleteLabel delete text
   * @returns {*} edit/update and delete button in checkout page
   */
  renderDeleteCardBlock = (
    isSelected = false,
    cards = {},
    deleteLabel = ''
  ) => {
    if (!isSelected && cards?.id === this.isDefaultCardId) {
      this.isNotSelectedDefaultCard = true
      this.theDefaultCardId = cards?.id
    } else if (isSelected && cards?.id === this.isDefaultCardId) {
      this.isNotSelectedDefaultCard = false
    }

    let { enableCardPaymentInfo = true } = APPConfig?.getAppConfig()

    enableCardPaymentInfo = convertToBoolean(enableCardPaymentInfo)

    return (
      <div id="block2">
        <div
          style={{
            width: '100%',
            objectFit: 'contain',
          }}
          role="checkbox"
          tabIndex={isSelected ? 0 : -1}
          aria-checked={cards.id === this.isDefaultCardId}
          onKeyPress={e => {
            this.handleDefaultChange(e, cards?.id)
          }}>
          {isSelected && !isExpressCheckout() && (
            <Form.Check
              type="checkbox"
              label="Set as Default Card"
              id="set-default-checkout"
              key={'checkbox' + cards?.id}
              checked={cards.id === this.isDefaultCardId ? true : false}
              onChange={e => {
                this.handleDefaultChange(e, cards?.id)
              }}
              disabled={cards?.id === this.isDefaultCardId ? true : false}
              tabIndex={isSelected ? 0 : -1}
            />
          )}
        </div>
        {!isSelected && cards?.id === this.isDefaultCardId && (
          <div>
            <p className="text-default-payment" tabIndex={0}>
              {i18nTranslate('payment.defaultTitle', 'Default Payment')}
            </p>
          </div>
        )}

        <div>
          {enableCardPaymentInfo && !isExpressCheckout() && (
            <span>
              <button
                disabled={checkoutContainer.isPaymentApiInProgress}
                className="font-weight-bold delete-btn btn"
                aria-label="Edit card link"
                onClick={e => this.handleUpdateModal(e, cards)}
                data-testid={
                  this.isCardExpired ? 'qa-edit-payment' : 'qa-update-payment'
                }>
                {this.isCardExpired
                  ? i18nTranslate('edit', 'Edit')
                  : i18nTranslate('catalog.Update', 'Update', {
                      nameSpace: 'ssr-resource',
                    })}
              </button>
            </span>
          )}
          {!isExpressCheckout() && (
            <span>
              <button
                disabled={checkoutContainer.isPaymentApiInProgress}
                className="font-weight-bold delete-btn btn"
                aria-label="delete card link"
                onClick={e => this.handleDeleteButtonClick(e, cards)}
                data-testid="qa-delete-payment">
                {deleteLabel}
              </button>
            </span>
          )}
        </div>
        {/** checkout page delete payment cards modal */}
        {this.isToShowDeleteConfirmationModal && (
          <Modal
            className={`overlay visible modal-wrapper p-0`}
            show={this.isToShowDeleteConfirmationModal}
            onHide={() => (this.isToShowDeleteConfirmationModal = false)}
            centered
            animation={false}>
            <Modal.Header
              closeButton={this.handleDeleteConfirmationModalClose}
              className={'border-bottom-0'}>
              <Modal.Title className="title-Payment-Modal">
                {i18nTranslate('checkout.deletePayment', 'Delete Payment')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.renderDeletePaymentConfirmationText()}
            </Modal.Body>
          </Modal>
        )}
      </div>
    )
  }

  /**
   * @description Renders the credit card add payment form.
   * @returns {JSX.Element} - The rendered credit card add payment form.
   */
  renderCreditCardAddPaymentForm = () => {
    const {
      isFromEditSubscription = false,
      handlePayment,
      isFromSubscription = false,
      isMultipleAdressActive = false,
    } = this.props
    return (
      <div class="express-checkout-payment-form">
        <div className="d-flex justify-content-between pt-3 px-3 credit-card-title-section">
          <p
            className="font-weight-bold card-detail-head"
            data-testid="qa-credit-card-details">
            {i18nTranslate('payment.CardDetailHeading', 'Credit Card Details')}
          </p>
          <div
            className="d-flex secureIcon"
            data-testid="qa-credit-card-secure-wrapper">
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-testid="qa-credit-card-secure-icon">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 5.83334C3 3.07192 5.23858 0.833344 8 0.833344C10.7614 0.833344 13 3.07192 13 5.83334V8.33334H13.8333C14.7538 8.33334 15.5 9.07953 15.5 10V17.5C15.5 18.4205 14.7538 19.1667 13.8333 19.1667H2.16667C1.24619 19.1667 0.5 18.4205 0.5 17.5V10C0.5 9.07953 1.24619 8.33334 2.16667 8.33334H3V5.83334ZM4.66667 8.33334H11.3333V5.83334C11.3333 3.99239 9.84095 2.50001 8 2.50001C6.15905 2.50001 4.66667 3.99239 4.66667 5.83334V8.33334ZM2.16667 10V17.5H13.8333V10H2.16667Z"
                fill="#5F5F5F"
              />
            </svg>
            <span
              className="secureIconText"
              data-testid="qa-credit-card-secure">
              {i18nTranslate('payment.secure', 'SECURE')}
            </span>
          </div>
        </div>
        <PaymentMarketForm
          multiAddressRenderProp={this.props.multiAddressRenderProp}
          onSubmitForm={handlePayment}
          isMultipleAdressActive={isMultipleAdressActive}
          isFromSubscription={isFromSubscription || false}
          isFromCheckout={this.props.isFromCheckout || false}
          isFromEditSubscription={isFromEditSubscription}
          cancelPaymentFlag={this.cancelPaymentFlag}
          billingAddressType="checkout"
        />
      </div>
    )
  }

  /**
   * @description Renders a continue button and a cancel button for alternate payment methods.
   * @param {string} [paymentType=''] - The type of the alternate payment method.
   * @returns {JSX.Element} - A React component that renders the continue and cancel buttons.
   */
  renderContinueButtonForAlternatePayments = (paymentType = '') => {
    return (
      <div className="section-payment-save-button d-block">
        <div className="mt-4 mb-2">
          <Row className="d-flex w-100 mb-3 mt-4" noGutters>
            <Col className="pr-1" md={6} sm={6} xs={6}>
              <Button
                type="submit"
                data-testid="qa-account-save"
                className="payment-save-button w-100"
                disabled={this.isAddPaymentCallInProgress}
                onClick={() => this.handleAlternatePayment(paymentType)}>
                {i18nTranslate('checkout.continue', 'Continue')}
              </Button>
            </Col>
            <Col className="pl-1" md={6} sm={6} xs={6}>
              <Button
                variant="outline-primary"
                type="submit"
                form="payment-form"
                className="payment-cancel-button w-100"
                data-testid="qa-add-new-pay-cancel"
                onClick={this.cancelPaymentFlag}>
                {i18nTranslate('checkout.cancel', 'Cancel')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  /**
   * @description Updates the current tab selection.
   * @param {string} [selectedTab=''] - The selected tab.
   */
  handlePaymentSelect = (selectedTab = '') => {
    this.currentTab = selectedTab
    //canceling the previous payment form
    const cancelButton = document.getElementById(
      'new-payment-cancel-btn-checkout'
    )
    if (cancelButton) {
      cancelButton.click()
    }
    if (this.enableLiveEvent === 'true') {
      // In Live Event, for add new card billing address is always required,
      // so paymentBillingSameAsShippingFlag is false
      if (this.props?.isFromCheckout) {
        if (selectedTab === 'newCard') {
          checkoutContainer.paymentBillingSameAsShippingFlag = false
        }
      }
    }
  }

  /**
   * @description Handles pagination for the payment list.
   * @param {number} [activePage=1] - The active page number to fetch.
   * @returns {Promise<void>} - A Promise that resolves when the payment list is updated.
   */
  handlePagination = async (activePage = 1) => {
    const response = await customerContainer.getPayment(activePage)
    const transformedPaymentResponse =
      customerContainer.transformPaymentResponse(response, activePage) || {}
    this.paymentList = transformedPaymentResponse?.payments || []
    this.handleDefaultChecked()
    transformedPaymentResponse?.payments?.find(cards => {
      if (cards?.id === this.isDefaultCardId) {
        this.tokenExCvvIframeLoader()
      }
    })
    this.currentPage = transformedPaymentResponse?.pageableInfo?.page || 1
  }

  /**
   * @description Renders the options for adding a new payment type, including the payment type selection and the corresponding form or continue button.
   * @returns {JSX.Element} The rendered payment type options.
   */
  renderAddNewPaymentTabOptions = (showForm = false) => {
    if (showForm && this.selectedAddPaymentType === 'CREDITCARD') {
      return this.renderCreditCardAddPaymentForm(this.selectedAddPaymentType)
    }

    return (
      <>
        {this.renderPaymentType(showForm)}
        {this.selectedAddPaymentType === 'CREDITCARD' &&
          !isExpressCheckout() &&
          this.renderCreditCardAddPaymentForm(this.selectedAddPaymentType)}
        {this.selectedAddPaymentType === 'BANKTRANSFER' &&
          this.renderDescriptionForBankTransferPayment(
            this.selectedAddPaymentType
          )}
        {this.selectedAddPaymentType !== 'CREDITCARD' &&
          this.selectedAddPaymentType !== '' &&
          !isExpressCheckout() &&
          this.renderContinueButtonForAlternatePayments(
            this.selectedAddPaymentType
          )}
      </>
    )
  }

  renderCardDetails = (payments = {}) => {
    let card = payments?.accountInfo || {}
    const provider = card?.provider?.toLowerCase() || ''
    const cardNumber = card?.cardNumber || ''
    const endingText = i18nTranslate('payment.cardEndingTxt', '-****')
    if (!cardNumber) {
      return <></>
    }

    return (
      <div
        className="d-flex payment-details-wrapper mt-2"
        data-testid="payment-details-wrapper">
        <PaymentIcon type={provider} />
        <div className="d-flex font-weight-bold">
          <span className="selected-card-info ml-1 d-flex align-items-center font-weight-bold text-capitalize">
            {provider} {endingText} {cardNumber}
          </span>
        </div>
      </div>
    )
  }

  /**
   * @description Renders the payment tabs and associated content for the payment list with form component.
   * @returns {JSX.Element} The rendered payment tabs and associated content.
   */
  renderTabs = () => {
    const { isPaymentTypePONumber = false, isPaymentTypeCardNumber = true } =
      this.conditionBasedTabs()
    const {
      isFromEditSubscription = false,
      handlePayment,
      isFromSubscription = false,
      isMultipleAdressActive = false,
      sectionClassName = '',
    } = this.props
    const defaultActiveKey = this.getDefaultPaymentTab()
    // this.currentTab = defaultActiveKey
    // uncommenting the above code for hotfix @anto @vetri
    // const defaultActiveKey = isPaymentTypePONumber ? 'PO' : 'newCard'
    //  By default we will show the add new payments - CX121-2932
    const savedCard = i18nTranslate('checkout.savedPayment', 'Saved Payments')
    const purchaseOrder = i18nTranslate(
      'checkout.purchaseOrder',
      'Purchase Order'
    )
    const addNewCard = i18nTranslate('checkout.addPayment', 'Add New Payment')
    const { payments = [], pageableInfo = {} } =
      customerContainer.paymentResponse || {}
    const { page = 1, count = 1 } = pageableInfo || {}
    this.currentPage = count == 1 ? page - 1 : page
    this.totalPagesLength = getTotalPagesForPagination(pageableInfo)
    const isPaymentPaginationEnabled =
      APPConfig?.getAppConfig()?.isPaymentPaginationEnabled === 'true' || false
    const paymentsSupported = [
      'creditcard',
      'debitcard',
      'paypal',
      'venmo',
      'klarna',
      'alipay',
      'googlepay',
      'applepay',
    ]

    const isStoreCreditAvailable =
      cartContainer?.cartResponse?.payments?.filter(
        payment => payment?.type !== 'STORECREDIT'
      )?.length > 0
    if (
      isExpressCheckout() &&
      isStoreCreditAvailable &&
      !this.showPaymentList
    ) {
      return (
        <div className="express-payment-list position-relative">
          <ExpressSectionHeader
            title={i18nTranslate(
              'expressCheckout.addPaymentMethods',
              'Payment'
            )}
            isOpen={this.showPaymentList}
            onClick={this.togglePaymentAccordion}
          />
          <>
            <p className="payment-description">
              {i18nTranslate(
                'expressCheckout.addPaymentMethodsDesc',
                'All transactions are secure and encrypted'
              )}
            </p>
            {cartContainer?.cartResponse?.payments?.map(payment => {
              const paymentType = payment?.type?.toLowerCase() || ''
              if (paymentsSupported?.includes(paymentType)) {
                if (paymentType === 'creditcard' || paymentType === 'debitcard')
                  return this.renderCardDetails(payment)
                else {
                  return (
                    <div
                      className="d-flex align-items-center"
                      data-testid="payment-details-wrapper">
                      <span>{<PaymentIcon type={paymentType} />}</span>
                      <span className="ml-1">{paymentType?.toUpperCase()}</span>
                    </div>
                  )
                }
              }
            })}
          </>
        </div>
      )
    }

    if (isExpressCheckout()) {
      return (
        <div className="express-payment-list position-relative">
          <ExpressSectionHeader
            title={i18nTranslate(
              'expressCheckout.addPaymentMethods',
              'Payment'
            )}
            isOpen={this.showPaymentList}
            onClick={() => {
              this.togglePaymentAccordion()
              this.showUseDifferentCardForm = false
              this.showAddNewCardForm = false
            }}
          />

          {(this.showPaymentList || !this.showPaymentList) &&
            !customerContainer.isRegisterUser && (
              <>
                <p className="payment-description">
                  {i18nTranslate(
                    'expressCheckout.addPaymentMethodsDesc',
                    'All transactions are secure and encrypted'
                  )}
                </p>
                {this.renderAddNewPaymentTabOptions()}
              </>
            )}

          {this.showPaymentList && customerContainer.isRegisterUser && (
            <p
              className={`payment-description ${
                payments?.length === 0 ? 'mb-0' : 'mb-3'
              }`}>
              {i18nTranslate(
                'expressCheckout.addPaymentMethodsDesc',
                'All transactions are secure and encrypted'
              )}
            </p>
          )}
          {customerContainer.isRegisterUser &&
            payments?.length > 0 &&
            (isPaymentTypeCardNumber || payments?.type === 'ALIPAY') &&
            !this.showUseDifferentCardForm && (
              <>{this.renderPaymentList(!this.showPaymentList)}</>
            )}

          {this.showAddNewCardForm &&
            this.showUseDifferentCardForm &&
            customerContainer.isRegisterUser &&
            this.renderAddNewPaymentTabOptions()}

          {this.showPaymentList && customerContainer.isRegisterUser && (
            <h6
              onClick={() => {
                this.showAddNewCardForm = true
                this.showUseDifferentCardForm = !this.showUseDifferentCardForm
                this.handlePaymentTypeSelect('CREDITCARD', 0)
              }}
              className="my-2 d-flex align-items-center payment-tab-express">
              <IoIosAdd />
              <span>
                {i18nTranslate(
                  'expressCheckout.expressUseDifferentCard',
                  'Use a different card'
                )}
              </span>
            </h6>
          )}
        </div>
      )
    }

    return (
      <div
        className={`position-relative checkout-payment-form ${
          isExpressCheckout() ? 'expressCheckout-paymentList' : 'mx-0 mx-lg-3'
        }`}>
        {payments?.length !== 0 && (
          <Tabs
            defaultActiveKey={defaultActiveKey}
            id="uncontrolled-tab-example"
            // onSelect={this.handlePaymentSelect}
            onSelect={tabName => this.handlePaymentSelect(tabName)}
            data-testid="qa-payment-cards-wrapper">
            {isPaymentTypeCardNumber && (
              <Tab
                eventKey="newCard"
                title={addNewCard}
                data-testid="qa-add-payment-tab">
                {this.renderAddNewPaymentTabOptions()}
              </Tab>
            )}

            {payments?.length > 0 &&
              (isPaymentTypeCardNumber || payments?.type === 'ALIPAY') && (
                <Tab
                  className="py-4"
                  eventKey="savedcards"
                  title={savedCard}
                  data-testid="qa-saved-payment-tab">
                  {this.renderPaymentList()}
                  {this.totalPagesLength > 1 && isPaymentPaginationEnabled && (
                    <Pagination
                      className="checkout-payment-continue"
                      handlePagination={this.handlePagination}
                      totalPages={this.totalPagesLength}
                      active={page}
                      numOfPaginationsToShow={this.totalPagesLength}
                      alignPagination={
                        application.isMobile ? 'center' : 'start'
                      }
                    />
                  )}
                </Tab>
              )}
            {isPaymentTypePONumber && (
              <Tab eventKey="PO" title={purchaseOrder}>
                {this.renderPOForm()}
              </Tab>
            )}
          </Tabs>
        )}
        <>
          {payments?.length === 0 && (
            <Tabs
              defaultActiveKey="newcard"
              id="uncontrolled-tab-example"
              data-testid="qa-payment-cards-wrapper">
              <Tab eventKey="newcard" title={null}>
                {this.renderAddNewPaymentTabOptions()}
              </Tab>
              {isPaymentTypePONumber && (
                <Tab eventKey="PO" title={purchaseOrder}>
                  {this.renderPOForm()}
                </Tab>
              )}
            </Tabs>
          )}
        </>
      </div>
    )
  }

  /**
   * @description Renders either the payment tabs or a `MultiplePayment` component based on the length of `this.paymentCredits`.
   */
  renderMultiplePayment = () => {
    if (this.paymentCredits.length === 0) {
      return this.renderTabs()
    }
    return (
      <MultiplePayment
        paymentCredits={this.paymentCredits}
        renderTabs={this.renderTabs}
        {...this.props}
      />
    )
  }

  /**
   * @description Renders the payment options based on the user's account type and subscription status.
   * If the user is from a subscription or a B2B account, it will render the payment tabs. If the user has no payment methods, it will render the payment market form.
   * If the user is not from a subscription or B2B account, it will render the multiple payment options.
   * @returns {JSX.Element} The rendered payment options.
   */
  renderPayment = () => {
    if (this.props.isFromSubscription || isB2BAccount()) {
      if ((this.paymentList && this.paymentList.length) || isB2BAccount()) {
        return this.renderTabs()
      } else {
        return (
          <div>
            <PaymentMarketForm
              onSubmitForm={this.props.handlePayment}
              isFromSubscription={this.props.isFromSubscription || false}
              isFromCheckout={this.props.isFromCheckout || false}
              multiAddressRenderProp={this.props.multiAddressRenderProp}
              isMultipleAdressActive={this.props.isMultipleAdressActive}
              billingAddressType="checkout"
            />
          </div>
        )
      }
    } else {
      return this.renderMultiplePayment()
    }
  }

  render() {
    return (
      <>
        {this.renderPayment()}
        {/** checkout page edit/update modal */}
        {this.isToShowEditUpdatePaymentModal && (
          <EditUpdatePaymentModal
            handleCheckoutChange={this.handleDefaultChecked}
            show={this.isToShowEditUpdatePaymentModal}
            closeButton={this.handleUpdateModalClose}
            billingAddressType="checkout"
            IDofCardToBeEdited={this.IdofCardToBeEdited}
            isFromCheckout={true}
            cardDetails={this.cardsDetails}
            closeModalCallback={this.handleUpdateModalClose}
          />
        )}
      </>
    )
  }

  /**
   * @description Renders the banktransfer option`.
   */

  renderBankTransfer = () => {
    const bankTransferConfig =
      APPConfig?.getAppConfig()?.bankTransferPayment || {}
    const {
      accountHolder,
      bankAccountNumber,
      codeNumber,
      branch,
      referenceNumber,
      bankName,
      bankTransferLink,
    } = bankTransferConfig || {}

    return (
      <div className="bank-transfer-container">
        <p className="bank-transfer-heading">
          {i18nTranslate('checkout.bankTransfer', 'Bank deposit')}
        </p>
        <img
          className="bank-transfer-image"
          src="https://media.nuskin.com/m/69e64c39bc30ac7c/original/CC-25.png"
          alt="bank-transfer"
        />
        <p className="banktransfer-text">
          {i18nTranslate(
            'checkout.bankTransfermessage1',
            'The following options are the different ways to make an electronic money transfer:'
          )}
        </p>
        <p className="banktransfer-textpayment">
          {i18nTranslate('checkout.RapiPago', 'RapiPago')}
        </p>
        <p className="banktransfer-text">
          {i18nTranslate('checkout.easyPayment', 'Easy Payment')}
        </p>
        <p className="banktransfer-text">
          {i18nTranslate(
            'checkout.bankTransfermessage2',
            'If you choose the cash payment method, you will have to make your payment on the same business day until midnight local time. '
          )}
          <span className="wire-payment-desc">
            {i18nTranslate(
              'checkout.bankTransfermessage4',
              'If you choose to save the order as payment with Deposit, it must be paid only with Deposit. It cannot be paid at the Support Center or at the Experience Center with a card. '
            )}
          </span>
        </p>
        <p className="banktransfer-text">
          {i18nTranslate(
            'checkout.bankTransfermessage3',
            'If your card was declined and has funds, please contact your issuing bank to authorize the purchase.'
          )}
        </p>
        <p className="banktransfer-text">
          <span className="wire-payment-desc">
            {i18nTranslate(
              'checkout.bankTransfermessage5',
              'When selecting Bank Deposit (cash), a barcode is required for cash payment via Rapipago or Easy Payment.  '
            )}
          </span>
          {i18nTranslate(
            'checkout.bankTransfermessage6',
            'To create your coupon, contact the Support Center or send an email to '
          )}
          <span className="bank-transfer-link">{bankTransferLink}</span>
          {i18nTranslate(
            'checkout.bankTransfermessage7',
            ' with your order number, amount, and email address where you want to receive the payment coupon.'
          )}
        </p>
        <p className="wire-payment-desc">
          {i18nTranslate('checkout.bankDetails1', 'Account holder : ')}{' '}
          {accountHolder}
        </p>
        <p className="wire-payment-desc">
          {i18nTranslate('checkout.bankDetails2', 'Bank account number: ')}{' '}
          {bankAccountNumber}
        </p>
        <p className="wire-payment-desc">
          {i18nTranslate('checkout.bankDetails3', 'Code number: ')} {codeNumber}
        </p>
        <p className="wire-payment-desc">
          {i18nTranslate('checkout.bankDetails4', 'Branch: ')} {branch}
        </p>
        <p className="wire-payment-desc">
          {i18nTranslate('checkout.bankDetails5', 'Reference Number: ')}{' '}
          {referenceNumber}
        </p>
        <p className="wire-payment-desc">
          {i18nTranslate('checkout.bankDetails6', 'Bank name: ')} {bankName}
        </p>
      </div>
    )
  }

  /**
   * @description Renders a description for the payment type, displaying the Klarna terms and service link if the payment type is 'KLARNA' and is the selected add payment type.
   */
  renderDescriptionForBankTransferPayment = (paymentType = '') => {
    if (
      paymentType === this.selectedAddPaymentType &&
      paymentType === 'BANKTRANSFER'
    ) {
      return this.renderBankTransfer()
    }
    return <></>
  }
}

export { PaymentListWithForm }
export default PaymentListWithForm
