import dayjs from 'dayjs'
import { getMonthStringList, getDaysStringList } from 'src/utils'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'

/**
 * Date formatting utility class.
 */
class DateFormatDeps {
  /**
   * Formats a timestamp into a date string based on the locale.
   * For 'en_US' locale, returns month-date-year.
   * For 'fr_CA' locale, returns date-month-year.
   * Defaults to 'en_US' format if locale is unspecified.
   */
  getFormattedDate = timeStamp => {
    const { date, month, year } = this.convertTimeStampToDate(timeStamp)
    // const locale = getLocalStorage('locale') || 'en-US'
    const locale = getLocaleCodeFromUrl({
      defaultLocale: 'en_US',
      isReverseType: true,
    })

    if (locale === 'en_US') {
      return `${month}-${date}-${year}`
    } else {
      if (locale === 'fr_CA') {
        return `${date}-${month}-${year}`
      }
    }
  }
  /**
   * Formats a timestamp into a date string in YYYY/MM/DD format.
   * @param {number} timeStamp - Timestamp to format
   * @returns {string} Date string in YYYY/MM/DD format
   */
  getNormalFormattedDate = timeStamp => {
    const dateString = new Date(timeStamp)
    const { date, month, year } = this.convertTimeStampToDate(dateString)
    return `${year}/${month}/${date}`
  }
  /**
   * Converts a timestamp to a date object with day, month and year properties
   * @param {number} timeStamp - The timestamp to convert
   * @param {boolean} [UTCdate=false] - Whether to use UTC methods to get the date parts
   * @returns {{date: number, month: number, year: number}} The date object
   */
  convertTimeStampToDate = (timeStamp, UTCdate) => {
    const dateString = new Date(timeStamp)
    const date = UTCdate ? dateString.getUTCDate() : dateString.getDate()
    const month = UTCdate
      ? dateString.getUTCMonth() + 1
      : dateString.getMonth() + 1
    const year = UTCdate
      ? dateString.getUTCFullYear()
      : dateString.getFullYear()
    return {
      date,
      month,
      year,
    }
  }
  /**
   * Converts a month number (1-12) to the corresponding month name string.
   *
   * @param {number} month - The month number to convert
   * @returns {string} The month name string
   */
  matchMonthFromNumberToString = month => {
    return getMonthStringList()[month - 1]
  }
  /**
   * Converts a timestamp to a date string with a 3 letter month abbreviation.
   *
   * @param {number} timeStamp - The timestamp to convert
   * @returns {string} The date string in `MMM D, YYYY` format
   */
  getMonthInShortString = timeStamp => {
    const { date, month, year } = this.convertTimeStampToDate(timeStamp)
    const monthName = this.matchMonthFromNumberToString(month)
    const monthString = monthName.substr(0, 3)
    return `${monthString} ${date}, ${year}`
  }
  /**
   * Converts a timestamp to a date string with full month name.
   *
   * @param {number} timeStamp - The timestamp to convert
   * @param {boolean} [UTCdate=false] - Whether to use UTC methods to get the date parts
   * @returns {string} The date string in `MonthName Date, Year` format
   */
  getMonthInString = (timeStamp, UTCdate) => {
    const { date, month, year } = this.convertTimeStampToDate(
      timeStamp,
      UTCdate
    )
    const monthString = this.matchMonthFromNumberToString(month)

    return `${monthString} ${date}, ${year}`
  }

  /**
   * Converts a date to a string with the day of week, month name, date,
   * and "日" for Chinese locales.
   *
   * @param {Date|number|string} date - The date to format
   * @returns {string} The formatted date string
   */
  getDateAsStringWithDay = date => {
    const locale = getLocaleCodeFromUrl({
      defaultLocale: 'en_US',
      isReverseType: true,
    })
    let zhData = ''
    if (locale === 'zh_US' || locale === 'zh_CA') {
      zhData = '日'
    }
    if (typeof date !== 'object') {
      date = new Date(date)
    }
    return (
      getDaysStringList()[date.getDay()] +
      ', ' +
      getMonthStringList()[date.getMonth()] +
      ' ' +
      date.getDate() +
      ' ' +
      zhData
    )
  }

  /**
   * Converts a date to a string with the month name and date.
   *
   * @param {Date|number|string} date - The date to format
   * @returns {string} The formatted date string in `MonthName Date` format
   */
  getDateAsString = date => {
    if (typeof date !== 'object') {
      date = new Date(date)
    }
    // CX121-3121
    return getMonthStringList()[date.getMonth()] + ' ' + date.getDate()
    // return (
    //   getDaysStringList()[date.getDay()] +
    //   ', ' +
    //   getMonthStringList()[date.getMonth()] +
    //   ' ' +
    //   date.getDate()
    // )
  }

  // Ex: April 10th
  // currentMonth + orderProcessingDate + suffix
  /**
   * Converts a date to a string with the month name, date, and ordinal suffix.
   *
   * @param {string} orderProcessingDate - The date of the order processing to format
   * @param {string} currentMonth - The current month
   * @param {string} lastOrderMonth - The month of the last order
   * @returns {string} The formatted date string with month name and ordinal date suffix
   */
  getOrdinalDateAsString = (
    orderProcessingDate = '',
    currentMonth = '',
    lastOrderMonth = ''
  ) => {
    let ordinalDate = date => {
      return (
        date +
        (date % 10 == 1 && date != 11
          ? 'st'
          : date % 10 == 2 && date != 12
          ? 'nd'
          : date % 10 == 3 && date != 13
          ? 'rd'
          : 'th')
      )
    }
    const month =
      currentMonth === ''
        ? 0
        : lastOrderMonth === currentMonth
        ? currentMonth
        : currentMonth - 1
    return (
      getMonthStringList()[month > 11 ? 0 : month] +
      ' ' +
      ordinalDate(orderProcessingDate)
    )
  }

  /**
   * Formats a date as a string with the full year included
   *
   * @param {Date|number|string} date - The date to format
   * @returns {string} The formatted date string in `MMM D, YYYY` format
   */ getDateAsStringWithYear = date => {
    if (typeof date !== 'object') {
      date = new Date(date)
    }

    return `${dayjs(date).format('MMM D, YYYY')}`
  }

  // Input: "2024-06-10T12:00:00"
  // Output: "10:00am"
  getTime = (time = '') => {
    let date = new Date(time)
    let hours = date?.getHours()
    let minutes = date?.getMinutes()
    let ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    let strTime = hours + ':' + minutes + ampm
    return strTime
  }

  // Input: Date()
  // Output: "th"
  getOrdinalSuffix = (date = '') => {
    let day = date?.getDate()
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  // Input: "2024-06-10T12:00:00"
  // Output: "October 10th"
  getMonthAndDate = (time = '') => {
    let date = new Date(time)
    let options = { month: 'long', day: 'numeric' }
    let formattedDate =
      new Intl.DateTimeFormat('en-US', options).format(date) || ''
    let ordinalSuffix = this.getOrdinalSuffix(date)
    return formattedDate + ordinalSuffix
  }
}

const dateFormatDeps = new DateFormatDeps()
export default dateFormatDeps
export { dateFormatDeps }
