import React from 'react'
import { Modal, Container, Button } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { i18nTranslate } from 'src/utils'
import { GrClose } from 'react-icons/gr'
import { nuskinSubscriptionContainer, cartContainer } from 'src/models'
import { MultipleProductSubscription } from './MultipleProductSubscription'
import { SubscriptionProductInfo } from '../SubscriptionProductItem'
import { SubscriptionToastMessage } from 'src/views/components/CartBlock/SubscriptionToastMessage'
import './styles.scss'

@observer
class MultipleProductSubscriptionModal extends React.Component {
  @observable userInput = {}
  @observable termsCheckbox = false
  @observable shouldDisableTheButton = true

  constructor(props) {
    super(props)
  }

  componentWillUnmount() {
    nuskinSubscriptionContainer.showModalContent = true
    nuskinSubscriptionContainer.clickedProductSkuId = ''
    nuskinSubscriptionContainer.isToShowDisabledModal = false
  }

  handleUpdateSubscription = async subscriptionItems => {
    nuskinSubscriptionContainer.updateDisabledItemSubscribeMessage(false)
    const response = await nuskinSubscriptionContainer.updateSubscription(
      subscriptionItems
    )
    if (nuskinSubscriptionContainer.isSuccessResponse(response)) {
      this.props?.toggleSubscriptionModal()
    }
  }

  handleDisabledSubscription = async subscriptionItems => {
    nuskinSubscriptionContainer.updateDisabledItemSubscribeMessage(true)
    nuskinSubscriptionContainer.enableItemSubscribedMessage = false

    const response = await nuskinSubscriptionContainer.updateSubscription(
      subscriptionItems
    )

    if (nuskinSubscriptionContainer.isSuccessResponse(response)) {
      this.props?.toggleSubscriptionModal()
    }
  }

  updateTheUserConsentInPostBody = (userInput = {}) => {
    this.shouldDisableTheButton = !(userInput.first && userInput.second)
  }

  multipleSubscribeButton = (label, shouldDisable, subscriptionItems) => {
    return (
      <Button
        disabled={shouldDisable || false}
        onClick={() => this.handleUpdateSubscription(subscriptionItems)}
        variant="primary btn-updateSubscribe w-50 text-uppercase">
        {label || 'UPDATE SUBSCRIPTION'}
      </Button>
    )
  }

  renderGoToCartButton = () => {
    return (
      <Button
        onClick={this.props?.toggleSubscriptionModal}
        variant="primary btn-goToCart w-50 text-uppercase">
        {i18nTranslate('subscriptionModal.goToYourCart', 'Go To Your Cart')}
      </Button>
    )
  }

  renderReturnToCartButton = subscriptionItems => {
    return (
      <Button
        onClick={() => this.handleDisabledSubscription(subscriptionItems)}
        variant="primary btn-goToCart w-25 text-uppercase">
        {i18nTranslate('subscriptionModal.returnToCart', 'Return To Cart')}
      </Button>
    )
  }

  renderOTPConvertMessage = () => {
    if (nuskinSubscriptionContainer.enableCartConvertMessage) {
      let productName = nuskinSubscriptionContainer.OTPConvertedProductName
      let conversionMessage = i18nTranslate(
        'subscriptionModal.otpConversionMessage',
        'has been moved to the cart as One-Time Purchase'
      )
      let message = productName + ' ' + conversionMessage
      return (
        <div className="otp-convert-message">
          <SubscriptionToastMessage message={message} />
        </div>
      )
    }
  }

  renderCartUpdateButton = subscriptionItems => {
    let isToDisableButton =
      this.shouldDisableTheButton ||
      nuskinSubscriptionContainer.isAPICallInProgress ||
      cartContainer.cartUpdateInProgress
    return (
      <div className="d-flex justify-content-center mt-4">
        {nuskinSubscriptionContainer.enableGoToCart
          ? this.renderGoToCartButton()
          : nuskinSubscriptionContainer.showModalContent
          ? this.multipleSubscribeButton(
              i18nTranslate(
                'subscriptionModal.updateSubscription',
                'UPDATE SUBSCRIPTION'
              ),
              isToDisableButton,
              subscriptionItems
            )
          : this.renderReturnToCartButton(subscriptionItems)}
      </div>
    )
  }

  renderEmptyModalContent = () => {
    return (
      <p className="h6 text-center empty-modal-message">
        {i18nTranslate(
          'subscriptionModal.modifiedMessage',
          'Items in your cart have been modified.'
        )}
      </p>
    )
  }

  renderModalContent = () => {
    return nuskinSubscriptionContainer.enableGoToCart ? (
      this.renderEmptyModalContent()
    ) : (
      <SubscriptionProductInfo
        onCheckboxStatusChange={this.updateTheUserConsentInPostBody}
        subscribeDate="DATE"
        isToShowMultipleItemsText={true}
      />
    )
  }

  render() {
    const { isToShowModal = false } = this.props
    let items = cartContainer?.cartResponse?.items || []
    let subscriptionItems = items?.filter(item => item?.isSubscription === true)
    const clickedProductSkuId =
      nuskinSubscriptionContainer?.clickedProductSkuId || ''
    if (clickedProductSkuId) {
      subscriptionItems = items?.filter(item => {
        let skuId = item?.skus?.[0]?.skuId || ''
        return (
          !item?.properties?.hasOwnProperty('isGiftItem') &&
          skuId === clickedProductSkuId
        )
      })
    }

    return (
      <Modal
        centered={true}
        show={isToShowModal}
        onHide={this.props?.toggleSubscriptionModal}
        dialogClassName="subscription-dialog">
        <Modal.Body className="msm-modal-body">
          <Container className="p-0">
            <div
              tabIndex={0}
              className="text-right msm-close-icon"
              aria-label={i18nTranslate('icons.close', 'close icon', {
                nameSpace: 'ssr-resource',
              })}>
              <GrClose
                type="button"
                onClick={this.props?.toggleSubscriptionModal}
                size="20px"
              />
            </div>
            <div className="text-center subscription-modal-title">
              <h4>
                {i18nTranslate(
                  'subscriptionModal.modalTitle',
                  'Manage Subscription Items'
                )}
              </h4>
              {!nuskinSubscriptionContainer.enableGoToCart && (
                <h6>
                  {i18nTranslate(
                    'subscriptionModal.modalDesc',
                    'Manage Subscription Items added to your cart.'
                  )}
                </h6>
              )}
            </div>
            {this.renderOTPConvertMessage()}
            {subscriptionItems?.length > 0 && (
              <div className="subscription-products">
                <MultipleProductSubscription
                  {...this.props}
                  subscriptionItems={subscriptionItems}
                />
              </div>
            )}
            <div className="subscription-form mx-auto mt-3">
              {nuskinSubscriptionContainer.showModalContent &&
                this.renderModalContent()}
              {this.renderCartUpdateButton(subscriptionItems)}
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    )
  }
}

export { MultipleProductSubscriptionModal }
export default MultipleProductSubscriptionModal
