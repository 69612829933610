import { observable } from 'mobx'
import { CommonContainer, cartContainer } from 'src/models'
import { productState } from 'src/views/components/BaseProductComponent'
import { productDeps } from 'src/deps'

class PromotionContainer extends CommonContainer {
  @observable subscriptionV2PromoResponse = {}

  getPostDataForPromoCall = (product = {}, productCount = 1) => {
    let postData = {
      productId: product.productId || '',
      categoryId: product.categoryId || '',
      type: product.type || 'DEFAULT',
      quantity: String(productCount || 1),
      // isSubscriptionIncludedInPromotion: true,
    }
    if (product.type == 'bundle' || product.type == 'kit') {
      const bundleData = productDeps?.getBundleData(product)
      postData = { ...postData, ...bundleData }
    } else {
      postData.skuId = product.skuId
    }
    return postData
  }

  resetV2PromoResponse = () => {
    this.subscriptionV2PromoResponse = {}
  }

  makeV2PromotionCall = async (product = {}, quantity = 1) => {
    const postData = this.getPostDataForPromoCall(product, quantity)
    const subscriptionPostData = {
      ...postData,
      isSubscriptionIncludedInPromotion: true,
      isSubscriptionPromotion: true,
      isCallMadeFromNewDesignPDP: true,
    }
    await this.makeSubscriptionPromoAPI(subscriptionPostData)
  }

  makeSubscriptionPromoAPI = async postData => {
    productState.quantityActionDisable = true
    const response = await cartContainer.makePromotionCall(postData)
    if (cartContainer.isSuccessResponse(response)) {
      this.subscriptionV2PromoResponse = response
    }
    productState.quantityActionDisable = false
  }
}

const promotionContainer = new PromotionContainer()

export { promotionContainer, PromotionContainer }
export default promotionContainer
