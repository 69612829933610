import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Button } from 'react-bootstrap'
import { Card, Form, ListGroup, Row, Col } from 'react-bootstrap'
import {
  catalogDeps,
  productDeps,
  isFlexibleSubscriptionFlagEnabled,
} from 'src/deps'
import { addtoCartDisable, getPdtImage } from 'src/deps/QuickAddDeps'
import { cartContainer, catalogContainer } from 'src/models'
import { checkEnableButton } from 'src/utils'
import { observable } from 'mobx'
import { minimumSearchCharLength, APPConfig } from 'config/appConfig'
import { observer } from 'mobx-react'
import { Icons, ImageComponent } from 'src/views/components'
import { ProductPrice } from '../ProductComponents/ProductSkuDetails/ProductPrice'
import { IoMdAddCircleOutline as CircleAdd } from 'react-icons/io'
import debounce from 'lodash/debounce'
import './styles.scss'

/**
 * @date 9/28/2023
 *
 * @class ViewCartSearch
 * @typedef {ViewCartSearch}
 * @extends {React.Component}
 * @description New component created for Search and Add to cart feature implementation on View Cart.
 */
@observer
class ViewCartSearch extends React.Component {
  @observable productSuggestions = []
  @observable isSearchApiInProgress = true
  @observable inputValue = ''
  @observable height = 70
  @observable page = 1
  @observable size = 5
  @observable totalCount = 0
  @observable isActive = false
  @observable isListening = false

  /**
   * @description constructor for the class component.
   * @date 9/28/2023
   * @constructor
   */
  constructor(props, context) {
    super(props)
    this.language = {
      SEARCH_CARD_ADD: i18nTranslate('viewCart.searchCardAdd', 'ADD'),
      SEARCH_CARD_OOS: i18nTranslate('viewCart.searchCardOOS', 'OOS'),
      SEARCH_CARD_UNAVAILABLE: i18nTranslate(
        'viewCart.searchCardUnavailable',
        'Unavailable'
      ),
    }
  }

  /**
   * @description getProductResponse function is used to make search call,
   * using the input text entered by user, and returns the result product list.
   * @date 9/28/2023
   * @function getProductResponse
   * @returns {object} JSON Object
   */
  getProductResponse = async () => {
    const searchTerm = this.inputValue?.trim()
    /*const filterValue = [
      {
        field: 'facet_availableforsubscriptions',
        operation: 'IN',
        value: 'true',
      },
    ]*/
    const response = await catalogContainer.getSearchProducts({
      queryParams: {
        searchTerm,
        page: this.page,
        skipSearchContentful: true,
      },
      size: this.size,
    })
    return response
  }

  /**
   * @description loadMoreData function is used to fetch the next set of products, and add to state object, on scroll end.
   * @date 9/28/2023
   * @function loadMoreData
   * @returns {void}
   */
  loadMoreData = async () => {
    if (this.inputValue.trim().length >= minimumSearchCharLength) {
      const response = await this.getProductResponse()
      this.productSuggestions = [
        ...this.productSuggestions,
        ...(response?.product || []),
      ]
    }
  }

  /**
   * @description handlerScroll function handles the scroll event of list and calls loadMoreData
   * , on scroll end.
   * @date 9/28/2023
   * @function handlerScroll
   * @param {Event} e
   * @returns {void}
   */
  handlerScroll = e => {
    if (
      e?.target?.scrollHeight - e?.target?.clientHeight - e?.target?.scrollTop <
      1
    ) {
      if (this.totalCount > 5) {
        this.page = this.page + 1
        this.loadMoreData()
      }
    }
  }

  /**
   * @description handleAddToCart function handles the ADD button click event for the products listed
   * in search dropdown, make addToCart API call and refresh the cart.
   * @date 9/28/2023
   * @function handleAddToCart
   * @param {object} product
   * @returns {void}
   */
  handleAddToCart = async (product = {}) => {
    const postData = {
      quantity: 1,
    }
    if (productDeps.isBundleData(product)) {
      // Product call made for bundle/kit product, because we do not have required sku data available in search call response.
      const response = await catalogContainer.getProduct({
        productId: product?.identifier,
      })
      const {
        bundleMandatoryProducts = [],
        skuKit = [],
        identifier = '',
        type = 'DEFAULT',
        properties: {
          availableChannels = '',
          scanQualifiedCount = 0,
          name = '',
          slug = '',
        },
      } = response?.product?.[0] || {}
      const productType = type == 'kit' ? skuKit?.[0]?.type : type || 'DEFAULT'

      postData['skus'] = [
        {
          productId: identifier,
          skuId: identifier,
          type: productType?.toUpperCase(),
          availableChannels,
        },
      ]
      const mandatoryProducts =
        type == 'bundle' ? bundleMandatoryProducts : skuKit

      let isBundleBackOrdered = false
      mandatoryProducts.forEach(mandatoryProduct => {
        const defaultSku =
          catalogDeps.getDefaultSelectedSku(mandatoryProduct)?.[0] || {}
        postData['skus'].push({
          productId: mandatoryProduct?.identifier || '',
          skuId: defaultSku?.identifier || '',
          type: 'MANDATORY',
          skuQuantity: mandatoryProduct?.properties?.quantity || 1,
          availableChannels: defaultSku?.properties?.availableChannels || '',
          backOrdered: defaultSku?.inventoryProperties?.backOrdered || false,
        })
        if (
          !isBundleBackOrdered &&
          defaultSku?.inventoryProperties?.backOrdered == true
        )
          isBundleBackOrdered = true
      })

      postData['properties'] = {
        categoryId: response?.category?.identifier || '',
        slug,
        scanQualifiedCount: Number(scanQualifiedCount || 0),
        isBackOrdered: isBundleBackOrdered,
        name,
        productId: identifier,
      }
    } else {
      postData['skus'] = [
        {
          skuId: product?.sku?.[0]?.identifier || '',
          productId: product?.identifier || '',
          type: product?.properties?.producttype || 'DEFAULT',
        },
      ]
      postData['properties'] = {
        categoryId:
          (product?.properties?.productCategory || '').split(',')?.[0] || '',
        slug: product?.properties?.slug,
        scanQualifiedCount:
          product?.sku?.[0]?.properties?.scanQualifiedCount || 0,
        isBackOrdered:
          product?.sku?.[0]?.inventoryProperties?.backOrdered || false,
      }
    }

    cartContainer.addToCartFromViewCart([postData])
    this.isActive = false
    this.handleClear()
    this.disableListener()
  }

  renderNoSearchResultsText = () => {
    const searchNoResults = i18nTranslate(
      'viewCart.searchNoResults',
      'No Results Found'
    )
    return (
      <div className="dropdown-subscription">
        <div className="subscription-result">
          <Card className="item-input-card">
            <Card.Body className="p-2 result-item-wrapper">
              <Row className="infinite-search-custom-row m-0">
                <Col className="pl-0">
                  <div className="custom-infinite-scroll">
                    <strong>{searchNoResults}</strong>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
    )
  }

  renderPrice = (props = {}) => {
    const { isBundleOrKitProduct = false, productDetails = {} } = props || {}
    const { type = '' } = productDetails || {}
    return (
      <ProductPrice
        isBundle={isBundleOrKitProduct}
        type={type}
        isToShowNewPriceStyling={true}
        isToHidePriceLabel={true}
        {...productDetails}
      />
    )
  }
  /**
   * @description renderDropDown function is used to prepare/render the JSX for search dropdown.
   * @date 9/28/2023
   * @function renderDropDown
   * @returns {JSX.Element} <></>
   */
  renderDropDown = (isFlexibleSubscriptionEnabled = false) => {
    if (!this.isSearchApiInProgress) {
      const filteredProducts =
        this.productSuggestions?.filter(product => {
          const isBundle = productDeps.isBundleData(product)
          const availableChannels = isBundle
            ? product?.properties?.availableChannels || ''
            : product?.sku?.[0]?.properties?.availableChannels || ''
          return checkEnableButton(availableChannels, 'web')
        }) || []

      if (this.inputValue?.length < minimumSearchCharLength) return null
      if (!filteredProducts || filteredProducts?.length == 0) {
        return this.renderNoSearchResultsText()
      } else {
        return (
          <div className="dropdown-subscription" id="dropdownsubscriptionId">
            <div
              className="subscription-result"
              id="subscription-result-id"
              onScroll={this.handlerScroll}
              style={{ overflowY: 'auto' }}>
              {typeof filteredProducts != 'undefined' &&
                filteredProducts.length > 0 &&
                filteredProducts.map((product, index) => {
                  const type = product?.type || ''
                  const nonSearchableTypes = ['fixedpricebundle', 'collection']
                  if (
                    typeof type == 'undefined' ||
                    type === '' ||
                    !nonSearchableTypes?.includes(type?.toLowerCase())
                  ) {
                    const selectedSKU =
                      catalogDeps.getDefaultSelectedSku(product)
                    const productDetails = selectedSKU?.[0] || product || {}

                    const dangerousGoods =
                      productDetails?.skuProperties?.dangerousGoods ===
                        'true' ||
                      productDetails?.properties?.dangerousGoods === 'true' ||
                      product?.properties?.dangerousGoods === 'true' ||
                      false

                    const name =
                      productDetails?.properties?.name ||
                      product?.properties?.name ||
                      ''
                    const imageURL = getPdtImage(product) || ''
                    const variant =
                      productDetails?.properties?.variant ||
                      product?.properties?.variant ||
                      product?.identifier ||
                      ''
                    const itemValue = productDetails?.identifier || ''
                    const { btnLabel, message, isToDisableAddButton } =
                      addtoCartDisable(product, this.language)
                    const showSearchSuggestionImage =
                      APPConfig?.getAppConfig()?.showSearchSuggestionImage ===
                        'true' || isFlexibleSubscriptionEnabled
                    const isBundleOrKitProduct =
                      productDeps.isBundleData(product)
                    const isToShowAddToCartButton =
                      btnLabel === this?.language?.SEARCH_CARD_ADD

                    return (
                      <Card className="item-input-card" key={index}>
                        <ListGroup variant="flush">
                          <ListGroup.Item className="p-0" key={index}>
                            <Card.Body className="p-2 result-item-wrapper">
                              <Row
                                className={`infinite-search-custom-row m-0 ${
                                  showSearchSuggestionImage
                                    ? 'with-image'
                                    : 'without-image'
                                }`}>
                                {showSearchSuggestionImage && (
                                  <ImageComponent
                                    testid="qa-product-search-image"
                                    overrideParentClass={true}
                                    customParentClassName="product-search-wrapper mr-2"
                                    className="product-search-image"
                                    src={imageURL || ''}
                                    alt={name}
                                    optimizeImage={true}
                                    height={'50px'}
                                    width={'50px'}
                                  />
                                )}
                                <Col className="pl-0 search-text-section">
                                  <div className="custom-infinite-scroll">
                                    <p className="product-title m-0">{name}</p>
                                    <p className="m-0 product-sku">
                                      {/* <span>{variant}</span>  CX15-9055-  identifiers should not be shown*/}
                                      {isFlexibleSubscriptionEnabled && (
                                        <>
                                          <span className="sku-title">
                                            {i18nTranslate(
                                              'viewCart.skuTitle',
                                              'SKU'
                                            )}
                                          </span>
                                          <span className="sku-colon">:</span>
                                        </>
                                      )}
                                      {!isFlexibleSubscriptionEnabled && (
                                        <span>#</span>
                                      )}
                                      <span className="sku-value">
                                        {itemValue}
                                      </span>
                                    </p>
                                    {isFlexibleSubscriptionEnabled &&
                                      this.renderPrice({
                                        isBundleOrKitProduct,
                                        productDetails,
                                      })}
                                  </div>
                                </Col>
                                <Col className="pr-0 pl-0 infinite-search-add-button-wrapper">
                                  {isFlexibleSubscriptionEnabled ? (
                                    <Button
                                      className={`float-right infinite-search-add-button ${
                                        isToShowAddToCartButton
                                          ? 'btn-color-enabled search-add-button'
                                          : 'btn-color-disabled search-disabled-button'
                                      }`}
                                      variant="primary"
                                      disabled={isToDisableAddButton}
                                      onClick={() => {
                                        this.handleAddToCart(product)
                                      }}>
                                      {isFlexibleSubscriptionEnabled &&
                                      isToShowAddToCartButton ? (
                                        <CircleAdd
                                          className="circle-add-button"
                                          size="20px"
                                          fill="#252525"
                                        />
                                      ) : (
                                        <>
                                          {btnLabel}
                                          {isToShowAddToCartButton ? (
                                            <Icons
                                              name={'CRUD'}
                                              icontype={'plus'}
                                              color="white"
                                              className="ml-1 mb-1"
                                              width="14px"
                                            />
                                          ) : (
                                            <Icons
                                              name={'header-icons'}
                                              icontype={'close-circle'}
                                              color="white"
                                              className="ml-1"
                                              width="14px"
                                            />
                                          )}
                                        </>
                                      )}
                                    </Button>
                                  ) : (
                                    <Button
                                      className={`float-right infinite-search-add-button ${
                                        isToShowAddToCartButton
                                          ? 'btn-color-enabled'
                                          : 'btn-color-disabled'
                                      }`}
                                      variant="primary"
                                      disabled={isToDisableAddButton}
                                      onClick={() => {
                                        this.handleAddToCart(product)
                                      }}>
                                      {btnLabel}
                                      {isToShowAddToCartButton ? (
                                        <Icons
                                          name={'CRUD'}
                                          icontype={'plus'}
                                          color="white"
                                          className="ml-1 mb-1"
                                          width="14px"
                                        />
                                      ) : (
                                        <Icons
                                          name={'header-icons'}
                                          icontype={'close-circle'}
                                          color="white"
                                          className="ml-1"
                                          width="14px"
                                        />
                                      )}
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </Card.Body>
                          </ListGroup.Item>
                        </ListGroup>
                      </Card>
                    )
                  }
                })}
            </div>
          </div>
        )
      }
    } else {
      return <></>
    }
  }

  /**
   * @description handleInputChange function handles the text change event of search text box.
   * @date 9/28/2023
   * @function handleInputChange
   * @param {Event}
   * @returns {void}
   */
  handleInputChange = event => {
    this.inputValue = event?.target?.value
    if (this.inputValue?.trim()?.length >= minimumSearchCharLength) {
      //make the call to api and fetch the response and assign it to the userSuggestion
      this.isSearchApiInProgress = true
      this.debounceQuickAddProduct()
    } else {
      this.productSuggestions = []
      this.page = 1
    }
  }

  /**
   * @description debounceQuickAddProduct function is called by handleInputChange if minimum characters are typed by user,
   * with added debouncer to avoid unnecessary calls. It sets the fetched product list in respective state.
   * @date 9/28/2023
   * @function debounceQuickAddProduct
   * @returns {void}
   */
  debounceQuickAddProduct = debounce(async () => {
    this.page = 1
    // page resetting is done here when call is made for new search keyword
    const response = await this.getProductResponse()
    this.productSuggestions = response?.product || []
    this.totalCount = response?.pageableInfo?.totalCount || 0
    this.isSearchApiInProgress = false
  }, 700)

  /**
   * @description setRef function is used to set reference to the search container node.
   * @date 9/28/2023
   * @function setRef
   * @param {*} node
   * @returns {void}
   */
  setRef = node => {
    this.searchContainerNode = node
  }

  /**
   * @description disableListener function is used to remove outside click event listener.
   * @date 9/28/2023
   * @function disableListener
   * @returns {void}
   */
  disableListener = () => {
    if (this.isListening === true) {
      if (typeof window === 'object') {
        window.removeEventListener('click', this.handleOutsideClick)
        this.isListening = false
      }
    }
  }

  /**
   * @description handleOutsideClick function is called when click event is received by event listener and it calls handleClear and disableListener, reset state values and disable click event listener.
   * @date 9/28/2023
   * @function handleOutsideClick
   * @param {Event}
   * @returns {void}
   */
  handleOutsideClick = event => {
    if (
      this.searchContainerNode &&
      !this.searchContainerNode?.contains(event?.target)
    ) {
      this.isActive = false
      this.handleClear()
      this.disableListener()
    }
  }

  /**
   * @description enableListener function is called by handleBoundaryBlur and is used to add event listener which listens for click event an calls handleOutsideClick when click event occurs.
   * @date 9/28/2023
   * @function enableListener
   * @returns {void}
   */
  enableListener = () => {
    if (this.isActive === true && this.isListening === false) {
      if (typeof window === 'object') {
        window.addEventListener('click', this.handleOutsideClick)
        this.isListening = true
      }
    }
  }

  /**
   * @description handleBoundaryBlur function is used handle blur event of input box div.
   * @date 9/28/2023
   * @function handleBoundaryBlur
   * @returns {void}
   */
  handleBoundaryBlur = () => {
    this.isActive = true
    this.enableListener()
  }

  /**
   * @description handleClear function is used to reset state values.
   * @date 9/28/2023
   * @function handleClear
   * @returns {void}
   */
  handleClear = () => {
    this.inputValue = ''
    this.productSuggestions = []
    this.page = 1
  }

  renderSearchIcon = isFlexibleSubscriptionEnabled => {
    return isFlexibleSubscriptionEnabled ? (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        className="enable-search-icon"
        data-testid="qa-search-icon"
        ariaLabel={i18nTranslate('icons.searchicon', 'search icon', {
          nameSpace: 'ssr-resource',
        })}
        alt={i18nTranslate('icons.searchicon', 'search icon', {
          nameSpace: 'ssr-resource',
        })}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.729736 9.98777C0.729736 4.47167 5.19572 0 10.7048 0C16.2139 0 20.6798 4.47167 20.6798 9.98777C20.6798 11.7568 20.2197 13.4205 19.4126 14.8629L23.9664 19.4844C24.9891 20.5223 24.9835 22.1921 23.9538 23.2231C22.9052 24.273 21.2001 24.2567 20.1718 23.1869L15.7613 18.5988C14.2782 19.4736 12.5489 19.9755 10.7048 19.9755C5.19572 19.9755 0.729736 15.5039 0.729736 9.98777ZM17.3685 17.42L21.5943 21.8159C21.8563 22.0884 22.2906 22.0926 22.5576 21.8251C22.8199 21.5625 22.8214 21.1372 22.5609 20.8728L18.2606 16.5087C17.9828 16.8311 17.6848 17.1355 17.3685 17.42ZM10.7048 1.97696C6.28617 1.97696 2.70418 5.56352 2.70418 9.98777C2.70418 14.412 6.28617 17.9986 10.7048 17.9986C12.4323 17.9986 14.0292 17.4515 15.3361 16.5208C16.1245 15.9594 16.8071 15.2583 17.3477 14.454C18.205 13.1784 18.7054 11.6431 18.7054 9.98777C18.7054 5.56352 15.1234 1.97696 10.7048 1.97696Z"
          fill="#5F5F5F"
        />
      </svg>
    ) : (
      <Icons
        name="header-icons"
        icontype="search"
        width="13px"
        className="enable-search-icon"
        data-testid="qa-search-icon"
        ariaLabel={i18nTranslate('icons.searchicon', 'search icon', {
          nameSpace: 'ssr-resource',
        })}
        alt={i18nTranslate('icons.searchicon', 'search icon', {
          nameSpace: 'ssr-resource',
        })}
      />
    )
  }

  /**
   * @description render function is used to render JSX for this component.
   * @date 9/28/2023
   * @function render
   * @returns {JSX.Element} <></>
   */
  render() {
    const searchPlaceholder = i18nTranslate(
      'viewCart.searchPlaceholder',
      'Product Search Quick-Add'
    )
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false

    return (
      <div className="search-box-container">
        <div
          ref={this.setRef}
          className="cart-search-input-parent d-flex"
          onBlur={this.handleBoundaryBlur}>
          {this.renderSearchIcon(isFlexibleSubscriptionEnabled)}
          <Form.Control
            id="itemSearchInput"
            type="text"
            className={`search-textBox bg-transparent `}
            data-testid="qa-search-add-button"
            placeholder={searchPlaceholder}
            onChange={this.handleInputChange}
            autoComplete="off"
            value={this.inputValue}
            disabled={this.props.isPvAssistPage && !this.props.isPvActive}
            role="searchbox"
            aria-label="item search input"
          />
          {this.renderDropDown(isFlexibleSubscriptionEnabled)}
        </div>
      </div>
    )
  }
}

export default ViewCartSearch
export { ViewCartSearch }
