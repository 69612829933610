import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Dropdown, Form, Button, Row, InputGroup } from 'react-bootstrap'
import {
  IoIosSearch as SearchIcon,
  IoIosClose as CloseIcon,
} from 'react-icons/io'
import { Link } from 'react-router-dom'
import {
  IS_BROWSER,
  minimumSearchCharLength,
  APPConfig,
  convertToBoolean,
} from 'config/appConfig'
import { pageNames } from 'src/routes/pathParams'
import { catalogContainer, customerContainer } from 'src/models'
import {
  application,
  getLocalStorage,
  setLocalStorage,
  getImageFromProperty,
  trackSearchSuggestions,
  trackRecentSearch,
  trackPredictiveSearch,
  trackSearchResult,
  trackPredictiveSearchEvent,
  trackRecentSearchEvent,
  i18nTranslate,
  getLiveEventStatus,
  getDeviceType,
} from 'src/utils'
import TagManager from 'react-gtm-module'
import { catalogDeps, searchDeps } from 'src/deps'
import { ImageComponent } from 'src/views/components'
import { NuskinPlusCartIcon } from '../CartIcon/NuskinCartIcon'
import './styles.scss'

const RenderForm = React.forwardRef((props, ref) => {
  const {
    value = '',
    onFocus,
    onChange,
    placeHolder = '',
    removeSearch,
    isNuskinHeader,
    onClick,
    onKeyDown,
  } = props
  let placeHolderText = ''
  if (isNuskinHeader) {
    placeHolderText = i18nTranslate('header.globalSearch', 'Search', {
      nameSpace: 'ssr-resource',
    })
  } else {
    placeHolderText =
      placeHolder ||
      i18nTranslate(
        'header.globalSearchProduct',
        'Search for products, devices and information',
        {
          nameSpace: 'ssr-resource',
        }
      )
  }

  const attributes = { value, onFocus, onChange, onClick, onKeyDown }
  return (
    <div className="position-relative flex-grow-1">
      <Form.Control
        ref={ref}
        type="text"
        placeholder={placeHolderText}
        // disabled={customerContainer.isGuestB2B}
        {...attributes}
        data-testid="qa-search-input"
        aria-label={i18nTranslate('input.searchInput', 'search input textbox', {
          nameSpace: 'ssr-resource',
        })}
      />
    </div>
  )
})

RenderForm.displayName = 'RenderForm'

@observer
class SearchBox extends React.Component {
  static defaultProps = {
    className: '',
  }

  isActive = false
  isListening = false
  refSearchInput = React.createRef()
  dropDownSelectedValue = ''
  userEnteredValue = ''
  suggestionResponse = {}

  @observable isSuggestionVisible = false
  @observable searchValue = ''
  @observable suggestionList = []
  @observable limitSuggestion = []
  @observable didYouMean = []
  @observable recentSearch = getLocalStorage('recentSearch') || []
  @observable searchLimit = 6
  @observable showMore = false
  @observable indexLimit = 6
  @observable limitRecentSearch = 10
  @observable isSearchFocused = false
  @observable dropDownSelectedIdx = -1
  @observable hoverIndex = null

  enableLiveEvent = getLiveEventStatus()
  /**
   * Why we need ref?
   * here we need to find that user has clicked outside the search box and suggestion dropDown
   * so the current dom has been taken through ref,
   * and finding the dom which is clicked is outside or inside the parent
   * Reference Link:
   * @see https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-react-component-5604830beb7f
   * @see https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
   * below functions are for the boundary clicking check
   */

  componentDidMount() {
    const pathname = this.props.location?.pathname || ''
    if (
      pathname.includes(`${pageNames.search}/`) &&
      !this.props?.nuskinReadOnly
    ) {
      let searchTermParam
      try {
        searchTermParam = decodeURIComponent(
          catalogDeps?.queryParam?.searchTerm
        )
      } catch {
        searchTermParam = catalogDeps?.queryParam?.searchTerm
      }

      this.searchValue = this.props.searchCleared ? '' : searchTermParam
    }
  }

  componentDidUpdate(prevProps) {
    const pathname = this.props?.location?.pathname || '/'
    if (prevProps?.location?.pathname !== pathname) {
      if (pathname?.indexOf(pageNames.search) === -1) {
        this.searchValue = ''
      }
    }
  }

  setRef = node => {
    this.searchContainerNode = node
  }

  enableListener = () => {
    if (this.isActive === true && this.isListening === false) {
      if (typeof window === 'object') {
        window.addEventListener('click', this.handleOutsideClick, true)
        this.isListening = true
      }
    }
  }

  disableListener = () => {
    if (this.isListening === true) {
      if (typeof window === 'object') {
        window.removeEventListener('click', this.handleOutsideClick, true)
        this.isListening = false
      }
    }
  }

  handleBoundaryFocus = () => {
    this.isActive = false
    this.isSearchFocused = true
  }

  handleBoundaryBlur = event => {
    this.isActive = true
    this.enableListener()
    if (!event?.currentTarget?.contains(event?.relatedTarget)) {
      this.isSearchFocused = false
      if (!application.isMobile) {
        this.isSuggestionVisible = false
      }
    }
  }

  handleOutsideClick = (event, isToCloseSuggestion = false) => {
    this.dropDownSelectedIdx = -1
    if (
      (this.searchContainerNode &&
        !this.searchContainerNode?.contains(event.target)) ||
      isToCloseSuggestion
    ) {
      if (!application.isMobile) {
        this.isSuggestionVisible = false
      }
      this.isSearchFocused = false
      this.isActive = false
      this.disableListener()
    }
  }

  handleDropdownVisibility = () => {
    const isVisible =
      this.suggestionList?.length > 0 ||
      this.didYouMean?.length > 0 ||
      this.recentSearch?.length > 0
        ? true
        : false
    return isVisible
  }

  getSuggestionList = productList => {
    let searchResult = []
    productList?.forEach(product => {
      searchResult.push(product)
    })
    return searchResult
  }

  getSuggestionListNames = () => {
    return (
      this.suggestionList.map?.(product => product?.properties?.name || '') ||
      []
    )
  }

  /**
   * below functions is for search value update
   * and redirect to the search page
   */
  getSearchSuggestions = async (handleFocus = '') => {
    catalogDeps.isPlainSearch = false
    if (
      this.searchValue !== '' &&
      this.searchValue?.length >= minimumSearchCharLength
    ) {
      let searchTermForApi = this.searchValue
      const response = await catalogContainer.getSearchSuggestions(
        this.searchValue,
        1,
        this.searchLimit
      )
      const isSameSearchValue = searchTermForApi === this.searchValue
      // CX26-2169
      // when multiple characters are typed at same time
      // sometimes previous API's response is set due to API latencies / response time
      //  so we are comparing the latest search value
      if (isSameSearchValue) {
        this.suggestionResponse = response
        if (response?.product && response?.product?.length) {
          this.suggestionList = this.getSuggestionList(response?.product)
          const totalCount = response?.pageableInfo?.totalCount

          if (!handleFocus) {
            this.limitSuggestion = this.suggestionList?.slice(
              0,
              this.searchLimit
            )
            if (totalCount > this.limitSuggestion?.length) {
              this.showMore = true
            } else {
              this.showMore = false
            }
          }

          //-------- DataLayer object to be sent to GTM -----------------

          trackSearchSuggestions({
            searchTerm: this.searchValue,
            searchResult: this.getSuggestionListNames(),
          })

          !this.props.searchCleared && this.props.clearSearchModal(false)
        } else {
          this.resetData()
        }

        this.didYouMean =
          // isSameSearchValue &&
          response?.didYouMean ? response?.didYouMean : []
        if (this.didYouMean?.length > 0) {
          this.resetData()
        }
      }
    } else {
      this.resetData()
      this.didYouMean = []
    }
  }

  resetData = () => {
    this.limitSuggestion = []
    this.searchLimit = 6
    this.indexLimit = 6
    this.showMore = false
    this.suggestionList = []
    this.suggestionResponse = {}
  }
  isJsonString = str => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  handleSuggestionVisibility = () => {
    const isDropdownVisible = this.handleDropdownVisibility()
    if (isDropdownVisible) {
      this.isSuggestionVisible = true
    }
  }
  handleChange = async event => {
    if (!application.isDesktopOrLarger && this.props?.nuskinReadOnly) {
      this.searchValue = ''
      return
    }

    // regex pattern for zero width space and tab encode values
    var re = /(%E2%80%86|%09)/g
    let result = encodeURIComponent(event?.target?.value || '')
    let searchVal = decodeURIComponent(result?.replace(re, ' ')?.trim() || '')
    this.searchValue = searchVal
    this.userEnteredValue = this.searchValue
    await this.getSearchSuggestions()
    if (this.isSearchFocused) {
      /*
       * https://nuskin.atlassian.net/browse/CX26-1392
       * when multiple characters are typed and ENTER key is hit,
       * when suggestion call has latency, the search dropdown opens up in search page
       * so opening the searchbox only when the textbox is focused
       */
      this.handleSuggestionVisibility()
    }
  }

  handleFocus = async e => {
    if (!application.isDesktopOrLarger && this.props?.nuskinReadOnly) {
      e?.target?.blur()
      return
    }
    let handleFocus = true
    await this.getSearchSuggestions(handleFocus)
    if (this.isSearchFocused) {
      /*
       * https://nuskin.atlassian.net/browse/CX26-1392
       * when multiple characters are typed and ENTER key is hit,
       * when suggestion call has latency, the search dropdown opens up in search page
       * so opening the searchbox only when the textbox is focused
       */
      this.handleSuggestionVisibility()
    }
  }

  handleSearchClick = () => {
    if (this.props?.isNuskinHeader && this.props?.toggleSearchModal) {
      this.props?.toggleSearchModal(true)
    }
  }

  // hideSearchModal = () => {
  //   if (this.props?.isNuskinHeader && this.props?.toggleSearchModal) {
  //     this.props?.toggleSearchModal(false)
  //   }
  // }

  checkDuplicateValue = searchValue => {
    const list =
      this.recentSearch?.filter(listValue => {
        return listValue === searchValue
      }) || []
    const isUniqueSearchValue = list.length > 0 ? true : false
    return isUniqueSearchValue
  }
  handleRecentSearch = () => {
    const isValid = this.checkDuplicateValue(this.searchValue)
    const indexOfDuplicate = this.recentSearch?.indexOf(this.searchValue)
    !isValid && this.recentSearch?.unshift(this.searchValue)
    isValid && this.recentSearch?.splice(indexOfDuplicate, 1)
    isValid && this.recentSearch?.unshift(this.searchValue)
    this.recentSearch = this.recentSearch?.slice(0, this.limitRecentSearch)
    setLocalStorage('recentSearch', this.recentSearch)
  }
  handleSubmit = event => {
    event.preventDefault()
    if (this.searchValue !== '' && this.searchValue) {
      catalogDeps.isPlainSearch = false
      if (event?.type == 'click') {
        trackSearchResult({
          searchType: 'fullSearch',
          searchTerm: this.searchValue,
        })
      } else {
        catalogDeps.isPlainSearch = true
      }

      const encodedURL =
        encodeURIComponent(encodeURIComponent(this.searchValue)) || ''
      this.props?.history?.push(`${pageNames.search}/${encodedURL}`)
      this.handleRecentSearch()
      this.isSuggestionVisible = false
      this.isSearchFocused = false
      this.props?.closeSearchModal?.()
    }
  }

  removeSelectedSearch = (item, event) => {
    event.persist()
    this.recentSearch?.map((listItem, index) => {
      if (listItem === item) {
        this.recentSearch.splice(index, 1)
        setLocalStorage('recentSearch', this.recentSearch)
      }
    })
    if (this.recentSearch?.length === 0 && this.suggestionList?.length === 0) {
      this.isSuggestionVisible = false
    }
  }

  handleRecentSearchList = () => {
    const list = this.recentSearch?.map((item, index) => {
      const handleDropDownChange = () => {
        this.searchValue = item
        this.handleRecentSearch()
        this.isSuggestionVisible = false
        this.isSearchFocused = false
        this.props?.closeSearchModal?.()
        if (this.enableLiveEvent === 'true') {
          trackRecentSearchEvent({
            searchTerm: item,
            recentSearchList: this.recentSearch.map(item => item),
          })
        }
        trackRecentSearch({ searchTerm: item })
      }
      const encodedURL = encodeURIComponent(encodeURIComponent(item))

      return (
        <Row noGutters key={item} className="flex-nowrap recent-search-wrap">
          <Dropdown.Item
            key={index + item}
            to={`${pageNames.search}/${encodedURL}`}
            as={Link}
            tabIndex={-1}
            onClick={handleDropDownChange}
            className={
              'w-100 ' +
              (this.dropDownSelectedIdx == index
                ? 'dropdown-item-selected'
                : '')
            }>
            {item}
          </Dropdown.Item>
          {/* <Button
            type="submit"
            variant="light"
            className="close-icon-button h-auto border-white bg-white p-0 pl-2"
            onClick={event => this.removeSelectedSearch(item, event)}>
            <CiruclarCloseIcon
              className="close-icon h-auto"
              aria-label={i18nTranslate('icons.close', 'close icon')}
            />
          </Button> */}
        </Row>
      )
    })
    return list
  }
  getPdtImage = (product = {}) => {
    // if (isMerchPLP) {
    //   const { currentIndex, images } = this.imageArrayProperties
    //   return images[currentIndex]
    // } else
    if (product?.type == 'kit' || product?.type === 'bundle') {
      return getImageFromProperty(product?.properties)
    }
    const defaultSku = catalogDeps.getDefaultSelectedSku(product)
    return getImageFromProperty(defaultSku?.[0]?.properties)
  }
  /* reverting as per latest comment CX12-9374 */
  /* getURLforRedirecting = (suggestion, isFromDidYouMean, parsedItem = '') => {
    /*
     * https://nuskin.atlassian.net/browse/CX12-9374
     * If the user clicks on the did you mean suggestion, it should take them to the search results page
     * else if they click on product name, it should take them to the product details page
     * 
    let url = ''
    if (isFromDidYouMean) {
      const encodedURL =
        encodeURIComponent(encodeURIComponent(parsedItem)) || ''
      url = `${pageNames.search}/${encodedURL}`
    } else {
      /*
       * constructing the url for product details page with variant
       * to be changed to SKUID later when slug is not present
       * @variant_autoselect - redundant/can be ignored based on CX12-9374
       * 
      /* update: this code is redundant now,
       * as per latest comments,
       * when we click on product suggestion,
       * redirection always happens to search page and not to PDP 
       * 
      const slug = suggestion?.properties?.slug || ''
      const identifier = suggestion?.properties?.identifier || ''
      const productOrSlugId = slug ? slug : identifier
      const defaultSku = catalogDeps.getDefaultSelectedSku(suggestion)
      const skuSlug = defaultSku?.[0]?.properties?.slug || ''
      const skuId = defaultSku?.[0]?.identifier || ''
      const hasMoreThanOneSku = (suggestion?.sku?.length || 1) > 1
      const variantParameter = skuSlug || skuId || ''

      url = `${pageNames.product}/${productOrSlugId}`
      /* @variant_autoselect *
      if (hasMoreThanOneSku && variantParameter) {
        url += `?variant=${variantParameter}`
      }
    }
    return url
  }*/

  renderAddToCart = index => {
    const isDesktop = getDeviceType() === 'desktop'
    const product = this.suggestionList[index]
    const buttonDisabled = convertToBoolean(
      searchDeps.isAddToCartDisabled(product)
    )
    const renderButton = () => {
      return (
        <Button
          className="search-addtocart-btn"
          variant="light"
          disabled={buttonDisabled}
          data-testid="qa-search-add-to-cart"
          onClick={e => this.handleAddToCartClick(e, isDesktop)}
          onKeyDown={e => {
            if (e?.keyCode === 13) {
              this.handleAddToCartClick(e, isDesktop)
            } else {
              this.handleKeyDown(e)
            }
          }}>
          <span>
            <NuskinPlusCartIcon />
          </span>
          <span>{isDesktop && i18nTranslate('bundle.add', 'Add')}</span>
        </Button>
      )
    }
    return (
      <div className="search-suggestion-addtocart col">
        {isDesktop
          ? this.hoverIndex === index || this.dropDownSelectedIdx === index
            ? renderButton()
            : null
          : renderButton()}
      </div>
    )
  }

  handleMouseEnter = index => {
    this.hoverIndex = index
  }

  handleMouseLeave = () => {
    this.hoverIndex = null
  }
  handleAddToCartClick = async (e, isDesktop) => {
    const product =
      this.suggestionList[
        this.hoverIndex !== null ? this.hoverIndex : this.dropDownSelectedIdx
      ]
    searchDeps.handleAddtoCart(product)
    this.isActive = false
    this.disableListener()
    // As per legacy site we are closing only the search box/dropdown and not removing the search keyword
    if (isDesktop) {
      this.handleOutsideClick(e, true)
    } else {
      this.props?.closeSearchModal?.()
    }
  }
  renderSuggestionList = (suggestion, index, isFromDidYouMean = false) => {
    const name = isFromDidYouMean
      ? suggestion || ''
      : suggestion?.properties?.name || ''

    const handleDropDownChange = () => {
      this.searchValue = name
      this.handleRecentSearch()
      this.isSuggestionVisible = false
      this.isSearchFocused = false
      this.props?.closeSearchModal?.()

      let product = {}

      if (this.suggestionResponse?.product) {
        product = this.suggestionResponse?.product?.[index]
      }

      if (this.enableLiveEvent === 'true') {
        trackPredictiveSearchEvent({
          typedQuery: this.userEnteredValue,
          searchTerm: name,
          product,
          suggestionProductList: this.getSuggestionListNames(),
        })
      }
      trackPredictiveSearch({
        typedQuery: this.userEnteredValue,
        searchTerm: name,
        product,
      })
    }
    const parsedItem = name || ''
    const encodedURL = encodeURIComponent(encodeURIComponent(parsedItem)) || ''
    /* reverting as per latest comment CX12-9374 */
    /* let url = this.getURLforRedirecting(
      suggestion,
      isFromDidYouMean,
      parsedItem
    )*/
    const imageURL = this.getPdtImage(suggestion)
    const showSearchSuggestionImage =
      APPConfig?.getAppConfig()?.showSearchSuggestionImage === 'true'
    const isToEnableAddToCart = convertToBoolean(
      APPConfig?.getAppConfig()?.enableSearchAddToCart
    )

    return (
      parsedItem && (
        <Dropdown.Item
          key={index + parsedItem}
          /* reverting as per latest comment CX12-9374 */
          /* to={url} */
          // to={`${pageNames.search}/${encodedURL}`}
          // as={Link}
          tabIndex={-1}
          className={
            this.dropDownSelectedIdx == index ? 'dropdown-item-selected' : ''
          }
          onMouseEnter={() => this.handleMouseEnter(index)}
          onMouseLeave={this.handleMouseLeave}>
          <div className="d-flex align-items-center search-suggestion-wrapper">
            <Link
              to={`${pageNames.search}/${encodedURL}`}
              onClick={handleDropDownChange}
              className={`d-flex align-items-center image-text-wrapper ${
                showSearchSuggestionImage ? 'suggestion-wrapper-with-image' : ''
              }`}
              tabIndex={-1}
              data-testid="qa-search-suggestion">
              {!isFromDidYouMean && showSearchSuggestionImage && (
                <ImageComponent
                  testid="qa-product-search-image"
                  overrideParentClass={true}
                  customParentClassName="product-search-wrapper"
                  className="product-search-image"
                  src={imageURL}
                  alt={parsedItem}
                  optimizeImage={true}
                  height={'50px'}
                  width={'50px'}
                />
              )}
              <div className="search-text-display">{parsedItem}</div>
            </Link>
            {isToEnableAddToCart && this.renderAddToCart(index)}
          </div>
        </Dropdown.Item>
      )
    )
  }

  clearAllSearch = () => {
    setLocalStorage('recentSearch', [])
    this.recentSearch = []
    this.removeSearch()
  }

  renderSuggestion() {
    const { isNuskinHeader = false } = this.props
    const recentSearchView = this.handleRecentSearchList()
    const isToHighlightShowMore =
      this.dropDownSelectedIdx == this.getCurrentlyDisplayedArray()?.length

    const recentSearchBlock = (
      <>
        <Dropdown.Header className="px-3">
          <div className="recent-search-title">
            {i18nTranslate('recent.search', 'Your Recent Searches', {
              nameSpace: 'ssr-resource',
            })}
          </div>
          <div className="recent-search-clear">
            <Button
              className="recent-search-clear-btn"
              onClick={this.clearAllSearch}
              tabIndex="0">
              {i18nTranslate('search.clearall', 'Clear All', {
                nameSpace: 'ssr-resource',
              })}
            </Button>
          </div>
        </Dropdown.Header>
        {recentSearchView}
      </>
    )

    const suggestionView = (
      <Dropdown.Menu
        id="suggestionDropdown"
        className={`${
          isNuskinHeader
            ? 'py-0 search-box-modal'
            : 'search-suggestion-dropdown'
        }`}
        show={this.isSuggestionVisible}
        onFocus={() => {
          this.isSearchFocused = true
          this.isSuggestionVisible = true
        }}
        onBlur={event => {
          if (!event?.currentTarget?.contains(event?.relatedTarget)) {
            this.isSearchFocused = false
            this.isSuggestionVisible = false
          }
        }}
        data-testid="qa-search-suggestion">
        {this.limitSuggestion?.length > 0 && (
          <div className="search-inner" id="search-inner-element">
            {this.limitSuggestion?.map((product, index) => {
              return this.renderSuggestionList(product, index)
            })}
          </div>
        )}
        {this.didYouMean.length > 0 &&
          (isNuskinHeader ? (
            <>
              <div className="nu-popular-searches">
                <div className="font-weight-bold px-3 h-100 nu-popular-searches-innerbox">
                  {/*i18nTranslate('search.popularSearches', 'Popular searches')*/}
                  {/* CX121-2016 */}
                  {i18nTranslate('search.didyoumean', 'Did You Mean?', {
                    nameSpace: 'ssr-resource',
                  })}
                </div>
              </div>
              <div>
                {this.didYouMean.map((prompt, index) => {
                  return this.renderSuggestionList(prompt, index, true)
                })}
              </div>
            </>
          ) : (
            <>
              <div className="font-weight-bold px-3">
                {i18nTranslate('search.didyoumean', 'Did You Mean?', {
                  nameSpace: 'ssr-resource',
                })}
              </div>
              <div>
                {this.didYouMean.map((prompt, index) => {
                  return this.renderSuggestionList(prompt, index, true)
                })}
              </div>
            </>
          ))}
        {this.showMore &&
          (isNuskinHeader ? (
            <div
              className={`nu-show-more px-3 d-flex align-middle w-100 ${
                isToHighlightShowMore && 'dropdown-item-selected'
              }`}>
              <button
                className="nu-show-more-button w-100 d-flex"
                tabIndex="0"
                // Pointerdown will affect ADA don't use it
                // onPointerDown={this.loadMore}
                onClick={this.loadMore}
                data-testid="qa-search-suggestion-show-more">
                {i18nTranslate('search.seeMoreResults', 'See More Results', {
                  nameSpace: 'ssr-resource',
                })}
              </button>
            </div>
          ) : (
            <button
              className="search-loadmore"
              tabIndex="0"
              // onPointerDown={this.loadMore}
              onClick={this.loadMore}>
              {i18nTranslate('search.showmore', 'Show More', {
                nameSpace: 'ssr-resource',
              })}
            </button>
          ))}
        {(this.searchValue?.length == 0 ||
          this.searchValue == this.dropDownSelectedValue) &&
          this.limitSuggestion.length == 0 &&
          this.didYouMean.length == 0 &&
          (isNuskinHeader ? <div>{recentSearchBlock}</div> : recentSearchBlock)}
      </Dropdown.Menu>
    )

    return suggestionView
  }

  loadMore = async () => {
    this.isSuggestionVisible = true
    let newIndex = this.indexLimit + this.searchLimit
    TagManager.dataLayer({
      dataLayer: {
        event: 'searchLoadMoreButtonUsed',
        searchType: 'searchLoadMoreButtonUsed',
        typedQuery: 'textSearch',
        pageTitle: 'Home Page',
        query: 'textSearch',
        resultObject: {
          userName:
            customerContainer?.profileResponse?.userName &&
            customerContainer?.profileResponse?.userName != ''
              ? customerContainer?.profileResponse?.userName
              : 'Guest User',
          userAccountId:
            customerContainer?.accountId > 0
              ? customerContainer?.accountId
              : 'Guest Account',
        },
        searchMode: 'custom',
        platform: 'equinox',
      },
    })
    const page = newIndex ? newIndex / 6 : 1
    await catalogContainer
      .getSearchSuggestions(this.searchValue, page, this.searchLimit)
      .then(response => {
        this.suggestionList = this.getSuggestionList(response.product)
        const totalCount = response?.pageableInfo?.totalCount
        const newShowMore = newIndex < 50
        let limitSuggestionLength = this.limitSuggestion?.length
        let suggestionListLength = this.suggestionList?.length
        let newList = []
        if (limitSuggestionLength + suggestionListLength > totalCount) {
          let diff = totalCount - limitSuggestionLength
          newList = this.limitSuggestion?.concat(
            this.suggestionList.slice(0, diff)
          )
        } else {
          newList = this.limitSuggestion?.concat(this.suggestionList)
        }
        this.indexLimit = newIndex
        this.limitSuggestion = newList
        if (totalCount === this.limitSuggestion?.length) {
          this.showMore = false
          this.indexLimit = 6
          newIndex = 0
        }
        // after load more content is loaded, element was hidden at bottom
        // auto scroll wasn't working as expected
        if (IS_BROWSER) {
          setTimeout(() => {
            const scrollBehavior = {
              behavior: 'smooth',
              block: 'center',
              inline: 'start',
            }

            document
              .querySelector(
                '#suggestionDropdown > div.search-inner > a.dropdown-item-selected.dropdown-item'
              )
              ?.scrollIntoView(scrollBehavior)
          }, 300)
        }
      })
  }

  removeSearch = () => {
    this.isSuggestionVisible = false
    this.searchValue = ''
    this.props.clearSearchModal(true)
  }

  setSearchBoxRef = input => {
    if (
      this.props?.isNuskinHeader &&
      !application.isDesktopOrLarger &&
      input &&
      this.props.shouldFocusOnRender
    ) {
      this.refSearchInput = input
      setTimeout(() => {
        this.refSearchInput?.focus()
      }, 1000)
    }
  }

  handleMaskClick = e => {
    this.handleOutsideClick(e, true)
  }

  handleKeyDown = e => {
    const upArrowCode = 38
    const downArrowCode = 40
    const enterCode = 13

    if (e?.keyCode == enterCode) {
      this.handleSearchEnter(e)
    } else if (e?.keyCode == upArrowCode) {
      this.handleUpArrow(e)
    } else if (e?.keyCode == downArrowCode) {
      this.handleDownArrow(e)
    }
  }

  handleSearchEnter = e => {
    let currentlyDisplayedArray = this.getCurrentlyDisplayedArray()
    if (this.dropDownSelectedIdx == currentlyDisplayedArray.length) {
      e?.preventDefault?.()
      e?.stopPropagation?.()
      this.loadMore()
    }
    /* reverting as per latest comment CX12-9374 */

    /* else if (this.dropDownSelectedIdx != -1) {
      /*
       * added fix for dropdown selection using enter key
       * https://nuskin.atlassian.net/browse/CX12-9374
       * If the user clicks on the did you mean suggestion, it should take them to the search results page
       * else if they click on product name, it should take them to the product details page
       
      let currentlyDisplayedArray = this.getCurrentlyDisplayedArray()
      let currentItem = currentlyDisplayedArray?.[this.dropDownSelectedIdx]
      let isProduct = currentItem?.identifier ? true : false
      let url = ''
      if (isProduct) {
        url = this.getURLforRedirecting(currentItem, false)
      } else {
        url = this.getURLforRedirecting(currentItem, true, this.searchValue)
        this.handleRecentSearch()
      }
      this.props?.history?.push(url)
      this.isSuggestionVisible = false
      this.isSearchFocused = false
      this.props?.closeSearchModal?.()
      e?.preventDefault?.()
      e?.stopPropagation?.()
    }*/
  }

  handleUpArrow = e => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
    if (this.dropDownSelectedIdx != -1) {
      this.dropDownSelectedIdx = this.dropDownSelectedIdx - 1
    }
    this.setSelectedValue(e)
  }

  handleDownArrow = e => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
    let currentlyDisplayedArray = this.getCurrentlyDisplayedArray()
    if (this.dropDownSelectedIdx < currentlyDisplayedArray.length - 1) {
      this.dropDownSelectedIdx = this.dropDownSelectedIdx + 1
    } else if (
      this.showMore &&
      this.dropDownSelectedIdx != currentlyDisplayedArray.length
    ) {
      this.dropDownSelectedIdx = currentlyDisplayedArray.length
      this.searchValue = this.userEnteredValue
    }
    this.setSelectedValue(e)
  }

  getCurrentlyDisplayedArray = () => {
    let currentlyDisplayedArray = this.limitSuggestion
    if (
      this.recentSearch.length > 0 &&
      this.limitSuggestion.length <= 0 &&
      this.didYouMean.length == 0
    ) {
      currentlyDisplayedArray = this.recentSearch
    } else if (this.didYouMean.length > 0) {
      currentlyDisplayedArray = this.didYouMean
    }
    return currentlyDisplayedArray
  }
  isProductArrayDisplayedCurrently = () => {
    let isProductArrayDisplayed = true
    if (
      this.recentSearch.length > 0 &&
      this.limitSuggestion.length <= 0 &&
      this.didYouMean.length == 0
    ) {
      isProductArrayDisplayed = false
    } else if (this.didYouMean.length > 0) {
      isProductArrayDisplayed = false
    }
    return isProductArrayDisplayed
  }
  setSelectedValue = e => {
    const scrollBehavior = {
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    }
    let currentlyDisplayedArray = this.getCurrentlyDisplayedArray()

    this.dropDownSelectedValue =
      currentlyDisplayedArray.length > this.dropDownSelectedIdx
        ? currentlyDisplayedArray[this.dropDownSelectedIdx]
        : ''
    if (this.dropDownSelectedValue) {
      this.searchValue =
        this.dropDownSelectedValue?.properties?.name ||
        this.dropDownSelectedValue ||
        ''
    }

    e?.preventDefault?.()
    e?.stopPropagation?.()
    if (IS_BROWSER) {
      document
        .querySelector(
          '#suggestionDropdown > div.search-inner > a.dropdown-item-selected.dropdown-item'
        )
        ?.scrollIntoView(scrollBehavior)
    }
  }

  render() {
    const { placeHolder = '', isNuskinHeader = false } = this.props
    const isShowSearchDropDown = this.handleDropdownVisibility()
    const highLightSearchClass =
      isNuskinHeader && this.isSearchFocused ? 'search-highlight' : ''

    const searchGroupView = (
      <section
        className={`search-group d-flex flex-column position-relative flex-grow-1 ${
          this.isSuggestionVisible && 'search-suggestion-visible'
        }`}
        ref={this.setRef}
        onFocus={this.handleBoundaryFocus}
        onBlur={this.handleBoundaryBlur}>
        <Form
          className={`${
            isNuskinHeader ? 'nu-search-box' : 'search-box'
          } ${highLightSearchClass}`}
          onSubmit={this.handleSubmit}
          data-testid="qa-search">
          <InputGroup className="flex-nowrap flex-row-reverse">
            <div className="d-flex align-items-center">
              {this.searchValue && (
                <Button
                  size="sm"
                  variant="light"
                  onClick={this.removeSearch}
                  className={
                    isNuskinHeader ? 'nu-cancel-search' : 'search-remove'
                  }
                  data-testid="qa-search-clear">
                  <CloseIcon
                    size="1.5rem"
                    className="text-dark"
                    aria-label="clear input"
                    data-testid="qa-search-clear-icon"
                  />
                </Button>
              )}
            </div>
            <RenderForm
              value={this.searchValue}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              placeHolder={placeHolder}
              onKeyDown={this.handleKeyDown}
              removeSearch={this.removeSearch}
              isNuskinHeader={isNuskinHeader}
              onClick={this.handleSearchClick}
              ref={this.setSearchBoxRef}
            />
            <InputGroup.Append className="d-flex align-items-center search-icon-button border-0">
              {isNuskinHeader ? (
                <Button
                  aria-label={i18nTranslate('searchBar.placeholder', 'Search', {
                    nameSpace: 'ssr-resource',
                  })}
                  className="border-0"
                  {...(!customerContainer.isGuestB2B && {
                    onClick: this.handleSubmit,
                  })}
                  data-testid="qa-search-button">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    className="search-box-svg"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.729736 9.98777C0.729736 4.47167 5.19572 0 10.7048 0C16.2139 0 20.6798 4.47167 20.6798 9.98777C20.6798 11.7568 20.2197 13.4205 19.4126 14.8629L23.9664 19.4844C24.9891 20.5223 24.9835 22.1921 23.9538 23.2231C22.9052 24.273 21.2001 24.2567 20.1718 23.1869L15.7613 18.5988C14.2782 19.4736 12.5489 19.9755 10.7048 19.9755C5.19572 19.9755 0.729736 15.5039 0.729736 9.98777ZM17.3685 17.42L21.5943 21.8159C21.8563 22.0884 22.2906 22.0926 22.5576 21.8251C22.8199 21.5625 22.8214 21.1372 22.5609 20.8728L18.2606 16.5087C17.9828 16.8311 17.6848 17.1355 17.3685 17.42ZM10.7048 1.97696C6.28617 1.97696 2.70418 5.56352 2.70418 9.98777C2.70418 14.412 6.28617 17.9986 10.7048 17.9986C12.4323 17.9986 14.0292 17.4515 15.3361 16.5208C16.1245 15.9594 16.8071 15.2583 17.3477 14.454C18.205 13.1784 18.7054 11.6431 18.7054 9.98777C18.7054 5.56352 15.1234 1.97696 10.7048 1.97696Z"
                      fill="#5F5F5F"
                    />
                  </svg>
                </Button>
              ) : (
                <SearchIcon
                  className="search-icon"
                  {...(!customerContainer.isGuestB2B && {
                    onClick: this.handleSubmit,
                  })}
                  size="1.25rem"
                  data-testid="qa-search-icon"
                  aria-label={i18nTranslate('icons.searchicon', 'search icon', {
                    nameSpace: 'ssr-resource',
                  })}
                />
              )}
            </InputGroup.Append>
          </InputGroup>
        </Form>
        {isShowSearchDropDown && this.renderSuggestion()}
        {this.isSuggestionVisible && !isNuskinHeader && (
          <div className="search-mask"></div>
        )}
        {this.isSearchFocused &&
          isNuskinHeader &&
          application.isDesktopOrLarger && (
            <div className="search-mask" onClick={this.handleMaskClick}></div>
          )}
      </section>
    )

    return searchGroupView
  }
}

export default SearchBox
export { SearchBox }
