import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Row, Col } from 'react-bootstrap'

const productLabelList = (isFromSaveForLater = 'false') => {
  const productLabel = i18nTranslate('checkout.productTitle', 'product')
  const quantityLabel = i18nTranslate('checkout.quantityTitle', 'quantity')
  const priceLabel = i18nTranslate('checkout.itemPriceTitle', 'item price')
  const totalLabel = i18nTranslate('checkout.totalTitle', 'total')

  if (isFromSaveForLater) {
    return [
      { title: productLabel, width: { lg: 7, md: 8, sm: 12 } },
      { title: priceLabel, width: { lg: 4, md: 3, sm: 8 } },
      //{ title: totalLabel, width: { lg: 1, md: 1, sm: 8 } },
    ]
  }
  return [
    { title: productLabel, width: { lg: 5, md: 6, sm: 12 } },
    { title: quantityLabel, width: { lg: 4, md: 2, sm: 8 } },
    { title: priceLabel, width: { lg: 2, md: 3, sm: 8 } },
    { title: totalLabel, width: { lg: 1, md: 1, sm: 8 } },
  ]
}

function cartTitleView(isFromSaveForLater = 'false') {
  const listToShow = productLabelList(isFromSaveForLater)
  const cartHeaderView = listToShow?.map((listItem, index) => {
    return (
      <Col
        lg={listItem.width.lg}
        md={listItem.width.md}
        sm={listItem.width.sm}
        key={listItem.title}
        className={
          'cart-view-title ' +
          listItem.title.replace(/\s/g, '') +
          ' d-flex align-items-center' +
          (index == 0 || listItem.title.toLowerCase() == 'item price'
            ? ''
            : isFromSaveForLater
            ? ''
            : ' justify-content-center ')
        }>
        {listItem.title}
      </Col>
    )
  })
  return cartHeaderView
}

function renderCartProductsHeader(isFromSaveForLater = false) {
  // this is for rendering header for cart products
  // made this common, as we can use it for both accordion #1 and review accordion
  return (
    <Row
      noGutters
      className="px-2 cart-view-title-wrapper text-capitalize font-weight-bold d-none d-md-flex mt-3 mx-0 mb-1 w-100 paragraph-m-bold">
      {cartTitleView(isFromSaveForLater)}
    </Row>
  )
}

export { renderCartProductsHeader }
// export default renderCartProductsHeader
