import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { pageNames } from 'src/routes/pathParams'
import isArray from 'lodash/isArray'
import { SubCategories } from './SubCategories'
import { getCategoryLinks } from '../deps'
import { IS_BROWSER } from 'src/utils'
import { staticDropDownLinks } from './staticDropDownLinks'
import { PageLink } from '../../PageLink'

@observer
class CategoryMenu extends React.Component {
  @observable currentCategory = ''

  /**
   * https://nuskin.atlassian.net/browse/CX12-9396
   * when newly added categories exceeds more than 6 columns in megamenu,
   * UI wraps to bottom and looks odd
   * so adding temporary fix for display alignments
   * @todo remove this once new figma is received for accomodating more that 6 columns in menu
   * @date 9/14/2023 - 1:05:33 AM
   */
  hardCodedCategoryIds = [
    'nutrition_kits_and_bundles',
    'product_lines_kits_and_bundles',
    'product_lines_promotions',
  ]

  categoryWraper = ''
  mainCategoryWrapper = ''

  // for only top category with 2 levels
  isDropDownStyle = false

  static defaultProps = {
    className: '',
  }

  componentDidMount() {
    if (typeof window === 'object') {
      window.addEventListener('click', event => {
        if (
          this.categoryWraper &&
          !this.categoryWraper.contains(event.target)
        ) {
          this.handleToggle()
        }
      })
      window.addEventListener('keydown', this.hideSubCategory)
    }
  }

  componentWillUnmount() {
    if (IS_BROWSER) {
      window.removeEventListener('keydown', this.hideSubCategory)
    }
  }

  hideSubCategory = event => {
    if (event.key === 'Escape') {
      this.currentCategory = ''
    }
  }

  handleToggle = (categoryId = '') => {
    if (categoryId) {
      this.currentCategory = categoryId
    }
  }

  renderStaticDropDownLinks = (dropdownMenuList = {}) => {
    const lists = staticDropDownLinks() || []
    let list = lists?.filter(
      list => list.parentId === this.currentCategory?.replaceAll('_', '')
    )
    /*
     * https://nuskin.atlassian.net/browse/CX12-9396
     * temporary fix added for display alignments of the megamenu
     */
    // loop through dropdownMenuList and if any item's identifier is in hardCodedCategoryIds
    // push the item to hardcoded links array
    dropdownMenuList?.subCategories?.forEach(subCategory => {
      if (this.hardCodedCategoryIds.includes(subCategory?.identifier || '')) {
        list.push({
          linkInfo: getCategoryLinks(subCategory, false),
          label: subCategory?.properties?.name || '',
          isPlpCategoryAndNotLegacyPage: true,
          identifier: subCategory?.identifier || '',
        })
      }
    })

    if (list.length > 0) {
      return list?.map((item, index) => {
        if (item.isPlpCategoryAndNotLegacyPage) {
          return (
            <div className="font-weight-bold sub-item" key={item?.label}>
              {/*
               * https://nuskin.atlassian.net/browse/CX12-9396
               * temporary fix added for display alignments of the megamenu
               */}
              <Link
                onClick={this.handleToggle}
                to={item?.linkInfo}
                key={item?.identifier || 'hardcode_item_' + key}
                className="py-2 ">
                {item?.label || ''}
              </Link>
            </div>
          )
        } else {
          return (
            <div className="font-weight-bold sub-item" key={item?.label}>
              <PageLink
                linkInfo={item?.linkInfo}
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer">
                <span data-testid="qa-category">{item?.label}</span>
              </PageLink>
            </div>
          )
        }
      })
    }
  }

  renderSubCategory = (subCategory, index) => {
    const hasSubcategories = subCategory?.subCategories?.length > 0
    // const hasImage = subCategory?.properties?.imageURL || ''

    if (this.hardCodedCategoryIds.includes(subCategory?.identifier)) {
      {
        /*
         * https://nuskin.atlassian.net/browse/CX12-9396
         * temporary fix added for display alignments of the megamenu
         */
      }
      return <></>
    }
    const hasImage = ''
    return (
      <Col
        key={`subcategory-${index}`}
        className={`${
          hasSubcategories || !hasImage ? 'col-2' : 'col'
        } subcategory-wrapper mx-xxl-3 mx-lg-3`}>
        <SubCategories
          handleToggle={this.handleToggle}
          subCategory={subCategory || []}
        />
      </Col>
    )
  }

  renderCategoryList = (category, index) => {
    const categoryId = category?.identifier || ''
    const categoryName = category?.properties?.name || ''
    const hasSubCategories = isArray(category?.subCategories)
    const propsForComponent = {
      className: `category-link d-md-block ${category?.identifier || ''}`,
      role: 'button',
      'data-testid': 'qa-departments',
      onMouseOver: () => {
        this.currentCategory = categoryId
      },
    }
    /*
     * https://nuskin.atlassian.net/browse/CX32-6916
     * https://nuskin.atlassian.net/browse/CX32-7086
     * for our impacts, isDummyCategory will be true,
     * links should not be clickable
     */
    if (!category?.isDummyCategory) {
      propsForComponent.to = hasSubCategories
        ? `${pageNames.categoryLanding}/${categoryId}`
        : `${getCategoryLinks(category, false)}`
    }
    return (
      <div
        key={`category-${index}`}
        className={`pb-3 current-category flex-shrink-0 mx-md-4 ${
          this.currentCategory === categoryId
            ? 'border-bottom-category border-primary'
            : ''
        } ${this.isDropDownStyle ? 'position-relative' : ''}`}>
        {index === 0 && <span>&nbsp;</span>}
        {/* Commented below code for CX32-7641 */}
        {category?.isDummyCategory || hasSubCategories ? (
          <div {...propsForComponent}>{categoryName}</div>
        ) : (
          <Link {...propsForComponent}>{categoryName}</Link>
        )}
        {this.isDropDownStyle &&
          this.currentCategory === categoryId &&
          hasSubCategories && (
            <div
              className="position-absolute sub-category-wrapper dropdown-style py-2 px-5 d-flex flex-column"
              ref={element => (this.categoryWraper = element)}>
              {category.subCategories.map(subCategory => {
                return (
                  <Link
                    onClick={this.handleToggle}
                    to={getCategoryLinks(subCategory, false)}
                    key={subCategory.identifier}
                    className="py-2 ">
                    {subCategory?.properties?.name || ''}
                  </Link>
                )
              })}
            </div>
          )}
      </div>
    )
  }

  render() {
    const lists = this.props.categoryResponse || []
    const isListNonEmpty = isArray(lists) && lists.length > 0
    const dropdownMenuList =
      isListNonEmpty &&
      lists.find(list => list.identifier === this.currentCategory)

    return (
      <div
        className={`d-flex flex-column align-items-center flex-wrap category-wrap-middle ${
          this.isDropDownStyle ? 'dropdown-style-category' : ''
        }`}>
        <div
          className="d-flex category-wrapper"
          ref={element => (this.mainCategoryWrapper = element)}>
          {lists.map(this.renderCategoryList)}
        </div>
        {dropdownMenuList?.subCategories?.length > 0 && !this.isDropDownStyle && (
          <div
            className="position-absolute w-100 sub-category-wrapper"
            ref={element => (this.categoryWraper = element)}>
            <Row
              noGutters
              className={`list-wrap mt-3 mb-3  ${
                this.currentCategory !== 'ourimpact'
                  ? 'justify-content-center w-100'
                  : ''
              }`}>
              <div className="d-flex flex-column mr-5">
                {this.renderStaticDropDownLinks(dropdownMenuList)}
              </div>
              {dropdownMenuList.subCategories.map(this.renderSubCategory)}
            </Row>
          </div>
        )}
      </div>
    )
  }
}

export default CategoryMenu
export { CategoryMenu }
