import { i18nTranslate } from 'src/utils'
import delay from 'lodash/delay'
import throttle from 'lodash/throttle'
import {
  deleteFromLocalStorage,
  getAppConfig,
  getSessionStorage,
} from 'config/appConfig'
import {
  fetchResponseFromAPI,
  IS_BROWSER,
  deleteCookie,
  getLocalStorage,
  trackErrorInInstana,
} from 'src/utils'
import { toastState } from 'src/views/components'
import { customerContainer } from 'src/models'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'

function onSessionExpired() {
  const { csrAdminCookie } = getAppConfig()

  const sessionId = getLocalStorage('sessionId')
  const oktaTokens = getSessionStorage('oktaTokens')
  try {
    trackErrorInInstana({
      errorReport: `Login Session Expired - ${sessionId}`,
      errorData: JSON.stringify(oktaTokens),
    })
  } catch (error) {
    console.log('login session expired error', error)
  }

  const isSignedInUser = customerContainer.isRegisterUser
  const isAuthenticated = getLocalStorage('IsAuthenticated')
  // const curLocale = getLocalStorage('defaultLocale').replace(/"/g, '')
  const curLocale = getLocaleCodeFromUrl({
    isReverseType: true,
    defaultLocale: 'en_US',
  })

  const [language, country] = curLocale.includes('_')
    ? curLocale.split('_')
    : curLocale.split('-')

  if (
    isAuthenticated &&
    (isAuthenticated === true || isAuthenticated === 'true')
  ) {
    deleteCookie(csrAdminCookie)
    deleteFromLocalStorage('isOktaUser')
    deleteFromLocalStorage('IsAuthenticated')
    /**
     * @info
     * Session Expired issue will be shown only for Logged In users
     */
    toastState.setToastMessage(
      i18nTranslate('session.expired', 'Your session has expired.'),
      false
    )

    delay(() => {
      if (IS_BROWSER) {
        /**
         * @todo
         * Previously redirection was sent to SignIn page which needs to be confirmed
         */
        window.location.href =
          `${window.location.origin}/${country}/${language}`.toLowerCase()
      }
    }, 700)
  } else {
    if (IS_BROWSER) {
      /**
       * @info
       * Guest user flow
       */
      // This is failing for deeplink
      // if (window.location.href !== window.location.origin) {
      //   delay(() => {
      //     window.location.href =
      //       `${window.location.origin}/${country}/${language}`.toLowerCase()
      //   }, 700)
      // }
    }
  }
}

const isSessionExpired = throttle(onSessionExpired, 1000)

/**
 * CommonContainer class definition.
 */
class CommonContainer {
  /**
   * Fetches the response from the API based on the provided load parameters.
   * If the code is running on the server-side, it directly fetches the response from the API.
   * If the code is running on the client-side, it checks if the request is allowed based on the URL and the endpoint name.
   * If the request is allowed, it fetches the response from the API and transforms it using the transformResponse method.
   * @param {Object} loadParams - The parameters for the API request.
   * @returns {Promise} - The response from the API.
   */
  fetchResponse = async loadParams => {
    // We cant validate session expiry in SSR
    if (!IS_BROWSER) {
      return fetchResponseFromAPI(loadParams)
    } else {
      // Inside iframe preventing all the duplicate APIs we need only the signin APis to be made for background check
      const url = new URL(location.href)
      const allowedAPIs = ['getOktaAuthorize', 'login', 'getProfile']
      const logoutAPIs = ['logout', 'getProfile']
      if (
        url.searchParams.get('isBackgroundSignIn') == 'true' &&
        !allowedAPIs.includes(loadParams.endPointName)
      ) {
        return {}
      } else if (
        url.pathname.includes('/logout/callback') &&
        !logoutAPIs.includes(loadParams.endPointName)
      ) {
        return {}
      }
      const response = await fetchResponseFromAPI(loadParams)
      return this.transformResponse(response, loadParams)
    }
  }

  /**
   * Transforms the response received from the server.
   * @param {Object} response - The response object.
   * @param {Object} loadParams - The load parameters.
   * @returns {Object} - The transformed response.
   */
  transformResponse = (response, loadParams) => {
    const { code, statusCode } = response

    /**
     * @todo
     * !!! temp fix added for site reloading infinity issue
     * !!! required to be revalidated
     */
    if (IS_BROWSER) {
      if (
        ((code && code === 'AUTH_105') || statusCode === 401) &&
        !window?.isNativeApp
      ) {
        isSessionExpired()
      }
    }
    return response
  }

  /**
   * Checks if the response is a success response.
   * @param {Object} response - The response object.
   * @returns {boolean} - Returns true if the response is a success response, otherwise false.
   */
  isSuccessResponse = response => {
    if (response) {
      const status = response?.status || ''
      return status === 'success' || status === ''
    }
    return false
  }

  /**
   * Retrieves the account ID.
   *
   * Gets the account ID from the customer profile response if available.
   * Otherwise calls getProfile() to retrieve the customer profile and returns
   * the account ID from the response.
   *
   * @returns {Promise} The account ID.
   */
  getAccountId = async () => {
    const accountId = customerContainer?.profileResponse?.accounts?.id || ''
    if (accountId !== '') {
      return accountId
    } else {
      const response = await customerContainer.getProfile()
      return response?.accounts?.id || ''
    }
  }
}

const commonContainer = new CommonContainer()

export default CommonContainer
export { CommonContainer, commonContainer }
