import React from 'react'
import loadable from '@loadable/component'

// import { AccountSection } from './AccountSection'
// import { SocialMedia } from './SocialMedia'
// import { CRUD } from './CRUD'
// import { HeaderIcons } from './HeaderIcons'
// import { Arrows } from './Arrows'
// import { ProductIcons } from './ProductIcons'
// import { StarIcons } from './StarIcons'
// import { FilterIcons } from './FilterIcons'
// import { FormIcons } from './FormIcons'
// // import { PaymentIcons } from './PaymentIcons'
// import { FlagIcons } from './FlagIcons'
// import { DSAicon } from './DSAicon'
// import { CarouselIcons } from './CarouselIcons'
// import { Checkout } from './Checkout'

class Icons extends React.PureComponent {
  static defaultProps = {
    name: '',
  }
  matchIcon = name => {
    switch (name) {
      case 'account':
        return 'AccountSection'
      case 'CRUD':
        return 'CRUD'
      case 'social-media':
        return 'SocialMedia'
      case 'header-icons':
        return 'HeaderIcons'
      case 'arrows':
        return 'Arrows'
      case 'product-icon':
        return 'ProductIcons'
      case 'star':
        return 'StarIcons'
      case 'filter-icon':
        return 'FilterIcons'
      case 'form-icon':
        return 'FormIcons'
      // case 'payment-icon':
      //   return 'PaymentIcons
      case 'flag-icon':
        return 'FlagIcons'
      case 'dsa-icon':
        return 'DSAicon'
      case 'carousel-icons':
        return 'CarouselIcons'
      case 'checkout':
        return 'Checkout'
      default:
        return undefined
    }
  }
  render() {
    const { name, ...remainingProps } = this.props
    const componentName = this.matchIcon(name)
    let LoadableComponent = ''
    if (componentName) {
      LoadableComponent = loadable(props =>
        import(`src/views/components/Icons/${componentName}`)
      )
    }

    return LoadableComponent ? <LoadableComponent {...remainingProps} /> : ''
  }
}

export { Icons }
export default Icons
