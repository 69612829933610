import { customerContainer, nuskinSubscriptionContainer } from 'src/models'
import {
  getConvertedYear,
  getMonthStringList,
  getConvertedMonth,
} from 'src/utils'
import { dateFormatDeps } from './DateFormatDeps'
/**
 * Checks if the given date value is a valid JavaScript Date object
 * and not NaN.
 *
 * @param {Date} date - The date to check
 * @returns {boolean} True if valid Date object, false otherwise
 */
function isValidDate(date) {
  return date instanceof Date && !isNaN(date)
}

/**
 * Gets the subscription order processing date for the logged in customer.
 * Calls the nuskinSubscriptionContainer to get the subscription order details.
 * Parses the response to construct a Date object for the order processing.
 * Returns the formatted order process date string and day.
 */ async function getSubscriptionDate() {
  let response =
    await nuskinSubscriptionContainer.getSubscriptionOrderProcessingDate(
      customerContainer.userId
    )
  if (nuskinSubscriptionContainer.isSuccessResponse(response)) {
    const { lastOrderProcessingDate = 0, currentDateInMillis = '' } =
      response || {}

    const getDate = response?.orderProcessingDate || 0

    const timeZone = response?.timeZone || 'US/Mountain'

    // const lastOrderProcessingMonth = new Date(
    //   lastOrderProcessingDate
    // ).getMonth()
    let lastOrderProcessingMonth = getConvertedMonth(
      lastOrderProcessingDate,
      timeZone
    )
    // const date = new Date(currentDateInMillis)
    // const currentMonth = date.getMonth()
    const currentMonth = getConvertedMonth(currentDateInMillis, timeZone)
    const currentSelectedMonth =
      lastOrderProcessingMonth == currentMonth ? currentMonth + 1 : currentMonth

    const year = getConvertedYear(currentDateInMillis, timeZone)

    const getYear = currentSelectedMonth - 1 >= 12 ? parseInt(year) + 1 : year
    const constructedDate = new Date(year, currentSelectedMonth - 1, getDate)
    const orderProcessDate = isValidDate(constructedDate)
      ? dateFormatDeps.getDateAsStringWithYear(Date.parse(constructedDate))
      : ''
    const orderProcessDateLocalized =
      getMonthStringList()[constructedDate.getMonth()] +
      ' ' +
      getDate +
      ', ' +
      getYear
    return {
      orderProcessDate: orderProcessDateLocalized,
      orderProcessDay: getDate,
    }
  }
  return ''
}

// Function to verify if card is expired or from card details
/**
 * Checks if a credit card is expired based on the expiryMonth and expiryYear.
 *
 * @param {string} expiryMonth - The expiry month of the credit card
 * @param {string} expiryYear - The expiry year of the credit card
 * @returns {boolean} - True if the card is expired, false otherwise
 */
function checkIfCardIsExpired(expiryMonth = '', expiryYear = '') {
  // Get current date details
  const currentDateTimeStamp = Date.now()
  const { month, year } =
    dateFormatDeps.convertTimeStampToDate(currentDateTimeStamp)

  // Check if cureent date is greater than card expiry date
  const isCardExpired =
    year > parseInt(expiryYear)
      ? true
      : year === parseInt(expiryYear)
      ? month > parseInt(expiryMonth)
        ? true
        : false
      : false

  return isCardExpired
}

export { isValidDate, getSubscriptionDate, checkIfCardIsExpired }
