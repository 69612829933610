import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { withRouter } from 'react-router'
import { observer } from 'mobx-react'
import { observable, reaction } from 'mobx'
import { Links } from './Links'
import { APPConfig } from 'config/appConfig'
import { SocialIcon } from './SocialIcons'
import { CopyRights } from './CopyRights'
import { pageNames } from 'src/routes/pathParams'
import {
  ApiContent,
  application,
  IS_BROWSER,
  getLocaleCodeFromUrl,
  convertToBoolean,
  jitsuSignUpRedirect,
  i18nTranslate,
  enableJITSU,
} from 'src/utils'
import { footerContainer, customerContainer } from 'src/models'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'
import { Link } from 'react-router-dom'

@observer
class NuSkinFooter extends React.Component {
  nameSpace = 'ssr-resource'
  static contextType = ApiContent
  @observable footerResponse = {}
  @observable link = ''
  @observable screenHeight = ''
  @observable screenWidth = ''
  @observable isMobile = false
  @observable partnerLogos = []

  constructor(props, context) {
    super(props)
    this.context = context
    this.contextName = 'footerData'
    this.currentLocale =
      this.context?.curLocaleFromUrl ||
      getLocaleCodeFromUrl({
        locationProps: props,
        at: 'pathParam',
        defaultLocale: 'en_US',
        isReverseType: true,
      })
    this.getDataFromSource()
  }

  updateDimensions = () => {
    // this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight
    this.isMobile = window.innerWidth < 576 ? true : false
  }

  componentDidMount() {
    this.updateDimensions()
    window?.addEventListener('resize', this.updateDimensions)
    if (customerContainer.isProfileCallOver) {
      this.setLink()
    }

    reaction(
      () => customerContainer.isProfileCallOver,
      (isProfileCallOverNewValue, reaction) => {
        this.setLink()
        // reaction.dispose() // reaction is disposed ( reaction happens only once )
      }
    )

    // let localeValue =
    //   this.currentLocale?.split('_')?.reverse()?.join('-') || 'US-en'
    // let uid = 'blt0779283eb7dd0800'
    // ContentStack.getQualificationReference('footer', uid, localeValue).then(
    //   response => {
    //     this.partnerLogos = response?.affiliated_navigation_?.links || []
    //   }
    // )
  }

  setLink() {
    const isRegisterUser = customerContainer.isRegisterUser
    this.link = isRegisterUser
      ? pageNames.myaccountsubscriptions
      : pageNames.signUp
  }

  setJITSULink() {
    if (enableJITSU()) {
      jitsuSignUpRedirect()
    }
  }

  getDataFromSource = async () => {
    let footerData = this.context?.data?.footerData || {}
    if (Object.keys(footerData).length > 0) {
      const getFooterDataFromCS =
        APPConfig?.getAppConfig()?.enableFooterFromCS == 'true'

      footerData = getFooterDataFromCS
        ? footerContainer.transformFooter(footerData)
        : footerData
      this.setFooterData(footerData)
    } else {
      await this.getFooterDataFromAPI()
    }
  }

  setFooterData = response => {
    this.footerResponse = response
    footerContainer.footerResponse = response
    this.partnerLogos = response?.affiliated_navigation_?.links || []
  }

  async getFooterDataFromAPI() {
    this.footerResponse = await footerContainer.getFooterData({
      context: this.context,
      contextName: this.contextName,
      queryParams: {
        locale: this.currentLocale,
      },
    })
    this.setFooterData(this.footerResponse)
  }

  nuskinLogo = () => (
    <img src={footerContainer.nuskinLogoURL} alt="" height="30px" />
  )

  renderDesktopView = () => {
    return (
      <div className="desktop">
        <section className="border-top border-bottom">
          <Container style={{ maxWidth: '1366px' }}>
            <Row className="d-flex justify-content-center">
              <Col lg={9} className="">
                <Links data={this.footerResponse} />
                <SocialIcon socialLinks={this.footerResponse} />
              </Col>
              <Col lg={3} className="pt-5 border-left">
                <Row className="pt-5 justify-content-center">
                  <span>{this.nuskinLogo()}</span>
                </Row>
                <Row className="pt-3 justify-content-center">
                  <span>{this.checkAndRenderJoinNuskinButton()}</span>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container style={{ maxWidth: '1366px' }}>
            <CopyRights
              data={this.footerResponse}
              partnerLogos={this.partnerLogos}
            />
          </Container>
        </section>
      </div>
    )
  }

  renderTabletView = () => {
    return (
      <div>
        <section className="border-top tab">
          <Container style={{ maxWidth: '1366px' }}>
            <Row className="pr-4 pl-4 pt-4">
              <Col>
                <div className="d-flex justify-content-between align-items-center pb-4 pl-3 pr-3">
                  <span>{this.nuskinLogo()}</span>
                  <span>{this.checkAndRenderJoinNuskinButton()}</span>
                </div>
                <Col md={12} className="border-top">
                  <Links data={this.footerResponse} />
                  <SocialIcon
                    isMobile={this.isMobile}
                    className="pl-2"
                    socialLinks={this.footerResponse}
                  />
                </Col>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="border-top">
          <Container style={{ maxWidth: '1366px' }}>
            <Row>
              <Col>
                <CopyRights
                  className="pl-1"
                  data={this.footerResponse}
                  partnerLogos={this.partnerLogos}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    )
  }

  renderMobileView = () => {
    return (
      <Container className="border-top mobile">
        <Row className="p-4 justify-content-center">
          <div>
            <span className="d-flex justify-content-center">
              {this.nuskinLogo()}
            </span>
            <span className="d-flex justify-content-center">
              {this.checkAndRenderJoinNuskinButton('mt-3')}
            </span>
          </div>
        </Row>
        <Row className="pl-3 pr-3">
          <Col className="border-top">
            <Links data={this.footerResponse} />
            <SocialIcon
              isMobile={this.isMobile}
              socialLinks={this.footerResponse}
            />
          </Col>
        </Row>
        <Row className="border-top">
          <Col>
            <CopyRights
              className="pl-3"
              data={this.footerResponse}
              partnerLogos={this.partnerLogos}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  checkAndRenderJoinNuskinButton = (customClassName = '') => {
    if (!customerContainer.isRegisterUser) {
      return (
        <Link
          role="button"
          aria-label={i18nTranslate('header.signUp', 'Sign Up', {
            nameSpace: 'ssr-resource',
          })}
          to={enableJITSU() ? '#' : this.link}>
          <Button
            block
            data-testid="qa-signup"
            onClick={this.setJITSULink}
            className={`join_nuskin_btn btn-primary btn-font-size align-items-center d-flex ${customClassName}`}>
            {i18nTranslate('title.joinnuskin', 'JOIN NU SKIN!', {
              nameSpace: this.nameSpace,
            })}
          </Button>
        </Link>
      )
    }
    return null
  }

  renderIpadView = () => {
    return (
      <div className="pl-3 ipad">
        <Row className="d-flex justify-content-between align-items-center border-top border-bottom p-4">
          <span>{this.nuskinLogo()}</span>
          <span>{this.checkAndRenderJoinNuskinButton()}</span>
        </Row>
        <Row className="border-bottom">
          <Col lg={12} className="">
            <Links data={this.footerResponse} />
            <SocialIcon socialLinks={this.footerResponse} />
          </Col>
        </Row>
        <CopyRights
          className="pl-3"
          data={this.footerResponse}
          partnerLogos={this.partnerLogos}
        />
      </div>
    )
  }

  getFooter = () => {
    if (IS_BROWSER) {
      if (!application.isDesktop && this.screenWidth < 576) {
        return this.renderMobileView()
      } else if (this.screenWidth > 576 && this.screenWidth <= 1366) {
        return this.renderTabletView()
      } else {
        // rendering desktop by default for SSR
        return this.renderDesktopView()
      }
    } else {
      return this.renderDesktopView()
    }
  }

  render() {
    const searchParams = this.props.location?.search || ''

    const isNativeAppQueryParamString = new URLSearchParams(searchParams).get(
      'isNativeApp'
    )

    const isKlarnaCheckoutPage =
      this.props?.location?.pathname === pageNames.klarnaCheckout
    const isURLNativeApp = isNativeAppQueryParamString == 'true'
    const isNativeApp = checkIsNativeApp() || false
    // Footer should not be rendered for Stella/Vera APP.
    const isToHideFooter = isURLNativeApp || isNativeApp || isKlarnaCheckoutPage
    if (isToHideFooter) {
      return <></>
    }

    return (
      <div
        className="device-footer"
        id="id-footer-container"
        data-testid="qa-footer-container">
        {this.getFooter()}
      </div>
    )
  }
}
export { NuSkinFooter }
export default withRouter(NuSkinFooter)
