import React, { Component } from 'react'
import { observer } from 'mobx-react'
import {
  MdFavorite as FavoriteIcon,
  MdFavoriteBorder as FavoriteIconBorder,
} from 'react-icons/md'
import { Link } from 'react-router-dom'
import { i18nTranslate } from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import { favoritesContainer, customerContainer } from 'src/models'
import './styles.scss'

class FavIcon extends React.PureComponent {
  render() {
    return favoritesContainer?.favoritesCount > 0 ? (
      <FavoriteIcon {...this.props} />
    ) : (
      <FavoriteIconBorder {...this.props} />
    )
  }
}

@observer
class FavoriteIconWithLink extends Component {
  render() {
    const isGuestB2B = customerContainer.isGuestB2B
    const { className } = this.props

    return (
      <Link
        to={!isGuestB2B ? pageNames.favorites : '#'}
        className={className}
        aria-label={`my favorites ${
          favoritesContainer?.favoritesCount || 0
        } item added`}>
        <FavIcon
          className={`header-favorite-icon ${
            favoritesContainer?.favoritesCount > 0 ? 'text-danger' : ''
          }`}
          style={{ fontSize: '20px' }}
          data-testid="qa-header-favorites"
          aria-label={i18nTranslate('icons.favorite', 'favorite icon', {
            nameSpace: 'ssr-resource',
          })}
        />
        {favoritesContainer?.favoritesCount > 0 && (
          <div className="favorite-count" data-testid="qa-favorites-count">
            {favoritesContainer.favoritesCount}
          </div>
        )}
      </Link>
    )
  }
}

export { FavoriteIconWithLink }
