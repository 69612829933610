import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import './styles.scss'

class CancelModal extends Component {
  render() {
    return (
      <div className="cancel-modal">
        <h2 className="confirm-text">
          {i18nTranslate('confirmationModal.confirmTitle', 'Are you sure ?')}
        </h2>
        <p className="cancel-text">
          {i18nTranslate(
            'confirmationModal.confirmText',
            'Phone number must be verified, if you leave your changes will not be saved')}
        </p>
        <Button
          className="exist-verification btn-primary"
          onClick={() => {
            this.props.handleDontVerifyClose()
          }}>
          {i18nTranslate('goToProfile', `Don't verify phone number`)}
        </Button>
        <Button
          variant="light"
          className="continue-verification btn-outline-primary"
          onClick={() => {
            this.props.setPageToShow('phoneNumber')
          }}>
          {i18nTranslate(
            'continueVerification',
            'Actually, continue verification'
          )}
        </Button>
      </div>
    )
  }
}

export default CancelModal
export { CancelModal }
