import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import i18n from 'i18next'
import { i18nTranslate } from 'src/utils'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import isArray from 'lodash/isArray'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { mobileMenuState } from 'src/views/components'
import { getCategoryLinks } from './deps'

class CategoryItem extends React.Component {
  handleClick = () => {
    const { index, currentCategoryPath } = this.props
    currentCategoryPath.push('subCategories', index.toString())
  }

  render() {
    const { category } = this.props
    const categoryName = category?.properties?.name || ''
    const ForwardIcon =
      i18n.dir() === 'rtl' ? IoIosArrowBack : IoIosArrowForward
    return (
      <ListGroup.Item key={categoryName} onClick={this.handleClick}>
        <a href="javascript:void(0)" role="button">
          {categoryName}
        </a>
        <ForwardIcon className="ml-auto arrow-icon" />
      </ListGroup.Item>
    )
  }
}

class BrowseLink extends React.Component {
  handleClick = () => {
    this.props.handleLinkClick()
  }

  render() {
    const { category } = this.props
    const categoryName = category?.properties?.name || ''
    return (
      <ListGroup.Item>
        <Link
          className="text-dark"
          to={getCategoryLinks(category)}
          onClick={this.handleClick}
          key={categoryName}>
          {categoryName}
        </Link>
      </ListGroup.Item>
    )
  }
}

class SubCategoryHeader extends React.Component {
  handleClick = () => {
    const { currentCategoryPath } = this.props
    currentCategoryPath.splice(-2, 2)
  }
  render() {
    const { category, currentCategoryPath, handleLinkClick } = this.props
    const { imageURL, name } = category.properties || {}
    const isTopLevelCategory =
      currentCategoryPath.filter(categoryPath => {
        return categoryPath == 'subCategories'
      }).length === 1
    const BackIcon = i18n.dir() === 'rtl' ? IoIosArrowForward : IoIosArrowBack
    return (
      <>
        {imageURL && (
          <LazyLoadImage
            src={imageURL || imagePlaceholder}
            alt=""
            aria-hidden="true"
            className={'mw-100 mh-100'}
            onError={e => {
              if (e.target.src != imagePlaceholder) {
                e.target.onerror = null
                e.target.src = imagePlaceholder
              }
            }}
          />
        )}
        <div
          className="subcategory-header list-group-item"
          onClick={this.handleClick}
          role="button">
          <BackIcon className="mr-2" />
          {name}
        </div>
        <Link
          className="list-group-item text-dark"
          to={getCategoryLinks(category, isTopLevelCategory)}
          onClick={handleLinkClick}
          role="button">
          {i18nTranslate('menu.shopAll', 'Shop All', {
            nameSpace: 'ssr-resource',
          })}{' '}
          {name}
        </Link>
      </>
    )
  }
}

@observer
class MobileMenuCategories extends React.Component {
  @observable currentCategoryPath = ['category']
  handleLinkClick = () => {
    this.currentCategoryPath.splice(1)
    mobileMenuState.toggleMenuVisibility()
  }
  render() {
    const { categoryResponse } = this.props
    const response = categoryResponse?.category || {}
    const categories = response?.subCategories || []
    return (
      <div>
        {this.currentCategoryPath.length > 1 && (
          <SubCategoryHeader
            category={response}
            currentCategoryPath={this.currentCategoryPath}
            handleLinkClick={this.handleLinkClick}
            {...this.props}
          />
        )}
        <ListGroup>
          {isArray(categories) &&
            categories.map((category, index) =>
              isArray(category.subCategories) ? (
                <CategoryItem
                  category={category}
                  index={index}
                  key={`category-${index}`}
                  currentCategoryPath={this.currentCategoryPath}
                />
              ) : (
                <BrowseLink
                  key={`browse-link-${index}`}
                  category={category}
                  parentCategory={categories.identifier}
                  currentCategoryPath={this.currentCategoryPath}
                  handleLinkClick={this.handleLinkClick}
                />
              )
            )}
        </ListGroup>
      </div>
    )
  }
}

export { MobileMenuCategories }
