import React from 'react'
import TagManager from 'react-gtm-module'
import { observable, computed } from 'mobx'
import { observer } from 'mobx-react'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import { Button } from 'react-bootstrap'
import {
  MdFavorite as FavoriteIconFill,
  MdFavoriteBorder as FavoriteIconBorder,
} from 'react-icons/md'
import { favoritesContainer, customerContainer } from 'src/models'
import { getCookie } from 'config/appConfig'
import { Icons } from 'src/views/components'
@observer
class ProductFavorites extends React.Component {
  static defaultProps = {
    className: '',
    showTextWithIcon: false,
  }
  // isFavoriteDisabled is used to prevent multiple clicks before API completes
  @observable isFavoriteDisabled = false
  @observable userDetails = {}

  @computed
  get isFavoritesSelected() {
    const { productId = '' } = this.props || {}
    return favoritesContainer.checkForFavorites(productId)
  }

  componentDidMount() {
    const response = customerContainer.profileResponse
    this.userDetails = response
  }

  handleClick = async () => {
    this.isFavoriteDisabled = true
    const { productId = '', name = '', categoryId = '' } = this.props || {}

    await favoritesContainer.toggleFavorites({
      isFavoritesSelected: this.isFavoritesSelected,
      productId,
      identifier: productId,
      name,
      categoryId,
    })
    this.isFavoriteDisabled = false

    if (this.isFavoritesSelected) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'add-to-favorites-list-message',
          pagePath: `/product/:id`,
          pageTitle: 'Product Details',
          userDetails: {
            userId: this.userDetails?.id || '',
            email: this.userDetails?.email || '',
            firstName: this.userDetails?.firstName || '',
            lastName: this.userDetails?.lastName || '',
          },
          productId,
          identifier: productId,
          name,
          categoryId,
          platform: 'equinox',
        },
      })
    }
  }
  showFavoritesIcon(attributes) {
    const registeredUser = getCookie('x-role-user') ? true : false
    if (this.isFavoritesSelected === false && registeredUser) {
      return (
        <FavoriteIconBorder
          className="favorite favorite-border"
          {...attributes}
        />
      )
    } else if (this.isFavoritesSelected === true && registeredUser) {
      return (
        <FavoriteIconFill
          className="favorite favorite-fill text-danger"
          {...attributes}
        />
      )
    } else {
      return <></>
    }
  }

  showV2FavoritesIcon(attributes = {}) {
    const registeredUser = getCookie('x-role-user') ? true : false
    const colorCode = this.isFavoritesSelected === false ? '#252525' : '#dc3545'
    const iconType =
      this.isFavoritesSelected === false ? 'V2-heart' : 'V2-heart-filled'
    if (registeredUser) {
      return (
        <Icons
          name="product-icon"
          icontype={iconType}
          width="24px"
          height="24px"
          color={colorCode}
          viewBox="0 0 24 24"
          ariaLabel={attributes['aria-label'] || iconType}
        />
      )
    } else {
      return <></>
    }
  }

  renderFav() {
    const registerdUser = getCookie('x-role-user') ? true : false
    const addText = i18nTranslate('icons.addToFavorite', 'Add to favorites', {
      nameSpace: 'ssr-resource',
    })
    const removeText = i18nTranslate(
      'icons.removeFromFavorite',
      'Remove from favorites',
      { nameSpace: 'ssr-resource' }
    )
    const translatedTextForFavoriteIcon = this.isFavoritesSelected
      ? removeText
      : addText
    const attributes = {
      'aria-label': translatedTextForFavoriteIcon.toLowerCase(),
      size: '1.5rem',
    }
    const registeredUser = getCookie('x-role-user') ? true : false
    const {
      className = defaultProps.className,
      showTextWithIcon = defaultProps.showTextWithIcon,
    } = this.props || {}
    if (registerdUser && !isExpressCheckout()) {
      return (
        <Button
          className={`favorite-icon ${
            className
              ? className
              : 'bg-transparent border-0 text-dark shadow-none'
          }`}
          onClick={this.handleClick}
          disabled={this.isFavoriteDisabled}
          data-testid={
            this.isFavoritesSelected ? 'qa-favorites-selected' : 'qa-favorites'
          }>
          <div className="fav-icon-text-wrapper">
            <span>
              {this.props.enableNewPDPStyle
                ? this.showV2FavoritesIcon(attributes)
                : this.showFavoritesIcon(attributes)}
            </span>
            {showTextWithIcon && (
              <span className="fav-text-wrapper">
                {translatedTextForFavoriteIcon}
              </span>
            )}
          </div>
        </Button>
      )
    } else {
      return <></>
    }
  }

  render() {
    return this.renderFav()
  }
}

export { ProductFavorites }
