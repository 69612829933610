import { merchProductContainer } from 'src/models'
/**
 * Sends product save data to parent window
 * @param {string} targetOrgin - Domain to send message to
 */
export const handleSaveData = targetOrgin => {
  if (typeof window === 'object') {
    /** @note window.parent.postMessage throws
     * following sonarqube error
     * Make sure this cross-domain message
     * is being sent to the intended domain
     * https://stackoverflow.com/questions/54139298/sonarjs-still-shows-warning-about-postmessage-cross-domain-issue
     * */
    const content = window
    content.parent.postMessage(
      JSON.stringify(merchProductContainer.handleSaveAction()),
      targetOrgin
    )
  }
}

/**
 * Sends product save data to parent window
 * @param {string} targetOrgin - Domain to send message to
 */
export const handleProductSaveData = targetOrgin => {
  if (typeof window === 'object') {
    const content = window
    content.parent.postMessage(
      JSON.stringify({
        id: 'save',
        uxData: null,
      }),
      targetOrgin
    )
  }
}
