import React from 'react'
import { currencyFormat, i18nTranslate } from 'src/utils'

/**
 * Renders the price component for a product card.
 *
 * This component calculates the unit price for a product based on its type (bundle, kit, or other).
 * If the product is a bundle or kit, the unit price is set to the total value of the product.
 * Otherwise, the unit price is set to the total value of the first SKU of the product.
 *
 * It handles cases where the price is discounted, has a negative discount, or is zero.
 * The component then renders the price information, including the original price, discounted price (if applicable), and a strikethrough for the original price (if discounted).
 *
 */

class ProductCardPrice extends React.Component {
  getProductPrice = () => {
    const { product = {}, productType } = this.props
    let unitPrice = {}
    if (productType === 'bundle' || productType === 'kit') {
      unitPrice = product?.totalValue || product?.prices?.[0] // fallback value if totalValue node is missing in product for bundle and kit
    } else {
      unitPrice =
        product?.sku?.[0]?.totalValue || product?.sku?.[0]?.prices?.[0] // fallback value if totalValue node is missing in sku for reguler product
    }
    return unitPrice || {}
  }
  renderPrice = () => {
    const { priceLable = false } = this.props
    const {
      originalPrice,
      priceAfterDiscount,
      price = '',
    } = this.getProductPrice() || {}
    const isNegetiveDiscount = priceAfterDiscount > originalPrice
    const isDiscountedPrice = priceAfterDiscount < originalPrice
    const isZeroDiscount = priceAfterDiscount == 0

    if (isNegetiveDiscount) {
      return (
        <span className="negetive-discount" dataTestId="qa-product-sale-price">
          {currencyFormat({ price: priceAfterDiscount || 0.0 })}
        </span>
      )
    } else if (isZeroDiscount) {
      return (
        <span className="zero-discount" dataTestId="qa-product-sale-price">
          {currencyFormat({ price: originalPrice || 0.0 })}
        </span>
      )
    } else if (isDiscountedPrice) {
      return (
        <div className="discounted-price">
          <span className="discounted-price" dataTestId="qa-product-sale-price">
            {currencyFormat({ price: priceAfterDiscount || 0.0 })}
          </span>
          <span
            className={`strikeThrough  ${
              priceLable ? 'stike-price-value' : 'pl-2'
            }`}
            dataTestId="qa-product-reg-price">
            {currencyFormat({ price: originalPrice || 0.0 })}
          </span>
        </div>
      )
    } else {
      return (
        <span className="reguler-price" dataTestId="qa-product-sale-price">
          {currencyFormat({ price: priceAfterDiscount || price || 0.0 })}
        </span>
      )
    }
  }
  render() {
    const { priceLable = false } = this.props

    return (
      <div className="product-card-price">
        {priceLable && (
          <div>
            {i18nTranslate('plp.price', 'Price', {
              nameSpace: 'ssr-resource',
            })}
            <span className="pr-1">:</span>
          </div>
        )}
        {this.renderPrice()}
      </div>
    )
  }
}

export { ProductCardPrice }
export default ProductCardPrice
