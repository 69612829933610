import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { application } from 'src/utils'
import './styles.scss'

@observer
class AppLoader extends React.Component {
  render() {
    const ariaLive =
      application.IsAppLoading || application.isCallInProgress
        ? application.isAlertLoader
          ? 'assertive'
          : 'polite'
        : 'off'
    const className = `appLoader w-100 m-0 ${
      application.IsAppLoading || application.isCallInProgress
        ? 'd-flex'
        : 'd-none'
    }`
    let isToEnableADA =
      application.isAlertLoader &&
      (application.IsAppLoading || application.isCallInProgress)

    return (
      <div
        className={className}
        data-testid="qa-app-loader"
        aria-live={ariaLive}
        role={isToEnableADA ? 'alert' : ''}
        aria-hidden={isToEnableADA ? false : true}>
        {isToEnableADA && (
          <span className="sr-only">
            {i18nTranslate('loading', 'Loading...', {
              nameSpace: 'ssr-resource',
            })}
          </span>
        )}
      </div>
    )
  }
}

export default AppLoader
export { AppLoader }
