import { observable } from 'mobx'
import { CommonContainer } from 'src/models/Common'

/**
 * SavedCardsContainer class extends CommonContainer.
 * Represents a container for managing saved cards.
 */
class SavedCardsContainer extends CommonContainer {
  @observable savedCardCvvData = {}
  savedCardsList = {}
  tokenExCvvData = {}
  tokenCvvValidData = {}
  tokenExIns = null
  formSubmitCallBack = null
  constructor(props) {
    super(props)
  }

  /**
   * Gets the TokenEx CVV auth key for the given token value from the server.
   * @param {string} tokenValue - The token value to get the CVV auth key for
   * @returns {Promise<string>} Promise resolving to the TokenEx CVV auth key
   */
  getTokenExCvvAuthKeyFromSFO = async tokenValue => {
    const loadParams = {
      endPointName: 'getTokenCvv',
      // addPathParams: tokenValue,
      queryParams: {
        source: `https://${document.domain}`,
        token: tokenValue,
      },
    }
    this.tokenExCvvAuthKey = await this.fetchResponse(loadParams)
    return this.tokenExCvvAuthKey
  }
}
const savedCardsContainer = new SavedCardsContainer()
export { savedCardsContainer }
export default savedCardsContainer
