import React from 'react'
import { i18nTranslate } from 'src/utils'
import { currencyFormat } from 'src/utils'
import { quickViewState } from 'src/views/components/QuickView/state'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { observer } from 'mobx-react'

@observer
class RetailPrice extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    /* hot fix added for bundle related promotions */
    /* for event on 08 september as suggested by poovarasan
      const { selectedSku = {} } = this.props


      const defaultpriceFacets =
      selectedSku?.defaultpriceFacets || props?.defaultpriceFacets || {}
      const priceFacets = selectedSku?.priceFacets || props?.priceFacets || {}
      const regularPrice =
      props.type?.toLowerCase?.() == 'bundle'
        ? defaultpriceFacets?.['Regular Price'] ||
          priceFacets?.['Regular Price'] ||
          0
        : priceFacets?.['Regular Price'] ||
          defaultpriceFacets?.['Regular Price'] ||
          0
      */
    let unitValue = {}
    const isBundle = this.props.type === 'bundle'
    // refer
    // src\views\components\ProductComponents\ProductSkuDetails\ProductPrice.jsx
    if (this.props.isFromQuickViewUnitPrice) {
      unitValue = quickViewState.pdpPromotionResponse?.unitValue || {}
    } else {
      if (isBundle) {
        unitValue =
          productState?.bundlePromotionResponse?.unitValue ||
          this.props.totalValue ||
          {}
        // 1) for initial load props.totalValue is used
        // 2) when there is change in bundle sub item/Qty,
        // then bundlePromotionResponse is used
      } else {
        unitValue =
          productState.pdpPromotionResponse?.unitValue ||
          this.props.totalValue ||
          {}
      }
    }
    const regularPrice =
      unitValue.priceFacets?.['Regular Price']?.['Regular Price'] || 0

    const getFormattedPrice = priceValue => {
      const formattedPrice = currencyFormat({
        price: priceValue,
      })
      return formattedPrice
    }
    const retailPrice = getFormattedPrice(regularPrice)
    {
      /*
       * https://nuskin.atlassian.net/browse/CX12-9816
       * as a temporary solution, with BA confirmation,
       *  we are hiding retail price when there is negative discount (negative promotion)
       *
       */
    }
    let isNegativeDiscount = false
    try {
      isNegativeDiscount =
        unitValue.priceAfterDiscount > unitValue?.originalPrice
    } catch (e) {
      console.log(
        'error while comparing priceAfterDiscount and originalPrice for -ve discount scenario',
        e
      )
    }
    return regularPrice > 0 && !isNegativeDiscount ? (
      <div className="cls-retail-price">
        <span>
          {i18nTranslate('pdp.retailPrice', 'Retail Price', {
            nameSpace: 'ssr-resource',
          })}
          : {retailPrice}
        </span>
      </div>
    ) : (
      <></>
    )
  }
}
export { RetailPrice }
export default RetailPrice
