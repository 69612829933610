import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Form, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { pageNames } from 'src/routes/pathParams'
import { CommonForm } from 'src/views/components'
import { isB2BAccount } from 'src/utils'
import { fromInput } from './formInput'
import { schema } from './validationSchema'
import { signInwithRedirect } from 'src/utils/signInUtils'
import './styles.scss'
import { i18nTranslate } from 'src/utils'

const constructStyle = {
  formRowClassName: 'justify-content-md-center mb-2 ',
}

@observer
class SignInForm extends React.Component {
  @observable isB2BAccount = false

  componentDidMount() {
    /* @note on reload email field name for B2B and B2C is different
     * So, in formik rerendering is not working,
     * when the value is not observable */
    this.isB2BAccount = isB2BAccount()
  }
  renderSubmitButton = () => {
    return (
      <Button
        block
        variant="primary"
        data-testid="qa-signin-signin"
        type="submit"
        className="my-2">
        {i18nTranslate('signin.submit', 'Sign In')}
      </Button>
    )
  }

  handleSubmit = formData => {
    const { onSignIn } = this.props
    onSignIn(formData)
  }
  render() {
    const formEmailFieldName = this.isB2BAccount === true ? 'email' : 'userName'
    const signInFormParams = {
      formEmailFieldName,
    }
    const formInputJson = fromInput(signInFormParams)
    const subscription = this.props?.location?.queryParams?.subscription || ''
    const signUpUrl = subscription
      ? pageNames.signUp + '?subscription=' + subscription
      : pageNames.signUp
    return (
      <React.Fragment>
        <div className="signIn-block">
          <Form.Row className="justify-content-md-center">
            <Col lg="12" className="mb-2">
              <button
                className="okta-button"
                data-testid="qa-okta-login"
                onClick={signInwithRedirect}>
                {i18nTranslate('signin.okta', 'Okta SignIn')}
              </button>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm={6} xs={12} lg={12}>
              <h6 className=" text-center mt-4 mb-2 text-uppercase font-weight-bold signin-title heading-2">
                {i18nTranslate('equinox.signin.title', 'Equinox Sign-In')}
              </h6>
            </Col>
          </Form.Row>
          <CommonForm
            schema={schema}
            formTypeJson={formInputJson}
            SubmitButtonProps={this.renderSubmitButton}
            onSubmit={this.handleSubmit}
            classNameValue={constructStyle}
          />
          <Form.Row className="justify-content-md-center">
            <Col lg="12" className="mb-2">
              <Link
                to={pageNames.resetPassword}
                data-testid="qa-signin-forgot-password">
                {i18nTranslate('signin.forgotPassWord', 'Forgot Password')}?
              </Link>
            </Col>
          </Form.Row>
          {!isB2BAccount() && (
            <Form.Row className="justify-content-md-center signin-create-account">
              <Col lg="12">
                <Link
                  role="button"
                  aria-label={i18nTranslate(
                    'signin.createAccount',
                    'Create Account'
                  )}
                  to={signUpUrl}>
                  <Button
                    block
                    variant="outline-primary"
                    data-testid="qa-createaccount-createbutton"
                    className="create-account-button">
                    {i18nTranslate('signin.createAccount', 'Create Account')}
                  </Button>
                </Link>
              </Col>
            </Form.Row>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default SignInForm

export { SignInForm }
