import { observable } from 'mobx'
import { CommonContainer } from 'src/models/Common'

/**
 * TaxIDContainer class that extends CommonContainer.
 * Used to handle tax ID tokenization.
 */
class TaxIDContainer extends CommonContainer {
  @observable taxTokenExData = ''
  @observable taxTokenExDupCharData = ''

  constructor(props) {
    super(props)
  }

  /**
   * Posts tax ID information to the tokenization endpoint, uppercases the input,
   * constructs the load parameters, makes the API call, and saves the response to
   * the taxTokenExData observable property.
   */
  postTaxTokenExInfo = async TaxData => {
    const taxIDValue = TaxData?.toUpperCase()
    const loadParams = {
      endPointName: 'tokenizeTax',
      postData: { data: taxIDValue },
    }

    const response = await this.fetchResponse(loadParams)

    this.taxTokenExData = response
  }

  /**
   * Posts tax ID information to the tokenization endpoint, uppercases the input,
   * constructs the load parameters, makes the API call, and saves the response to
   * the taxTokenExDupCharData observable property.
   */
  postDupCharTaxTokenExInfo = async TaxData => {
    const taxIDValue = TaxData?.toUpperCase()
    const loadParams = {
      endPointName: 'tokenizeTax',
      postData: { data: taxIDValue },
    }

    const response = await this.fetchResponse(loadParams)

    this.taxTokenExDupCharData = response
  }
}

const taxIDContainer = new TaxIDContainer()
export { taxIDContainer }
export default taxIDContainer
