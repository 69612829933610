import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { IS_BROWSER } from 'src/utils/application'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'

function MetaDecorator({
  title = '',
  description = '',
  imageUrl = '',
  children = '',
  canonicalUrl = '',
  location,
}) {
  const metaDecorator = require('./metaDecor.json')
  const pageURL_origin = IS_BROWSER ? window?.location?.origin : ''
  const pageURL_pathname = IS_BROWSER ? window?.location?.pathname : ''
  const canonical = canonicalUrl.includes('http')
    ? canonicalUrl
    : pageURL_origin + pageURL_pathname
  const pageURL_search = IS_BROWSER ? window?.location?.search : ''
  // const locale = getLocalStorage('locale')
  // const language = locale?.split('-')?.length ? locale?.split('-')[0] : ''
  const locale = getLocaleCodeFromUrl({
    locationProps: location,
    defaultLocale: 'en_US',
    isReverseType: true,
  })
  const language = locale?.split('_')?.length ? locale?.split('_')[0] : ''

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="title" content={title} />
      <meta name="author" content={metaDecorator.author} />
      {/* need to work */}
      <link rel="canonical" href={canonical} />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="Content-Language" content={language.toUpperCase()} />
      <meta name="hide-in-nav" content="true" />
      {/* Open Graph data */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={pageURL_origin + imageUrl} />
      <meta
        property="og:url"
        content={pageURL_origin + pageURL_pathname + pageURL_search}
      />
      <meta property="og:image:width" content="470" />
      <meta property="og:image:height" content="246" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:site_name" content="Nu Skin" />
      <meta property="og:locale" content={locale?.replace('-', '_')} />
      {/* <!-- Facebook tags --> */}
      <meta
        property="article:author"
        content="https://www.facebook.com/nuskin"
      />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/nuskin"
      />
      {/* <!-- Schema.org markup for Google+ --> */}
      <meta itemProp="name" content="Home" />
      <meta itemProp="description" content={description} />
      <meta
        itemProp="image"
        content="https://www.nuskin.com/content/dam/logos/Nu%20Skin-logo-tagline-DTBY.png"
      />
      {/* <!-- Twitter Card data --> */}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@nuskin" />
      <meta name="twitter:title" content="Home" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="@nuskin" />
      <meta
        name="twitter:image"
        content="https://www.nuskin.com/content/dam/logos/Nu%20Skin-logo-tagline-DTBY.png"
      />
      {/* <!-- Nu Skin meta-tags --> */}
      <meta
        property="ns:url"
        content="https://www.nuskin.com/en_US/home.html"
      />
      <meta
        property="ns:server_url"
        content="https://www.nuskin.com/content/nuskin/en_US/home.html"
      />
      <meta
        property="ns:full_url"
        content="https://www.nuskin.com/content/nuskin/en_US/home.html"
      />
      <meta
        property="ns:mapped_url"
        content="https://www.nuskin.com/en_US/home.html"
      />
      {children}
    </Helmet>
  )
}

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
}

export { MetaDecorator }
export default MetaDecorator
