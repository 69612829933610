import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Button, Modal /* , Col, Row */ } from 'react-bootstrap'
import { application } from 'src/utils'
//import { Icons } from 'src/views/components'
import { pageNames } from 'src/routes/pathParams'
import {
  subscriptionContainer,
  /* customerContainer,
  loyaltyContainer, */
} from 'src/models'
import { Link } from 'react-router-dom'
// import links from '../HelpFulLinks/fixture'
import './styles.scss'

class ConfirmationModal extends React.Component {
  renderTitle = () => {
    const isSubscriptionDelete = this.props?.isSubscriptionDelete || false
    const totalSubs = this.props?.totalSubscriptionLength || 0
    const totalSubSelected = this.props?.totalSubscriptionSelected || 0
    return (totalSubs === 1 || totalSubs === totalSubSelected) &&
      isSubscriptionDelete
      ? i18nTranslate(
          'confirmationModal.confirmSubscriptionTitle',
          'cancel subscription ?'
        )
      : i18nTranslate('confirmationModal.confirmTitle', 'Are you sure ?')
  }

  renderMessageText = () => {
    const isSubscriptionDelete = this.props?.isSubscriptionDelete || false
    const totalSubs = this.props?.totalSubscriptionLength || 0
    const totalSubSelected = this.props?.totalSubscriptionSelected || 0
    const isToShowCancelButton = this.props?.isToShowCancelButton || false
    return isToShowCancelButton == false
      ? (totalSubs === 1 || totalSubs === totalSubSelected) &&
        isSubscriptionDelete
        ? this.renderCancelSubsPopUp()
        : i18nTranslate(
            'confirmationModal.confirmMessage',
            'Clicking "Confirm Delete" below will result in the permanent deletion of this item.'
          )
      : i18nTranslate(
          'confirmationModal.confirmDeleteAllMessage',
          'Clicking "Confirm Delete" below will result in the permanent cancellation of this subscription.'
        )
  }

  renderCancelSubsPopUp = () => {
    let textCancelPopUpQuestion = i18nTranslate(
      'confirmationModal.confirmSubscriptionMessage6',
      `Would you like to proceed?`
    )
    return (
      <p className="popup-information text-justify">
        {textCancelPopUpQuestion}
      </p>
    )
  }

  renderConfirmButtonText = () => {
    const showCancelButtonText = this.props?.isToShowCancelButton || false
    return showCancelButtonText
      ? i18nTranslate('confirmationModal.confirmCancelAll', 'Confirm Cancel')
      : i18nTranslate('confirmationModal.confirmDelete', 'Confirm Delete')
  }

  renderCancelButtonText = () => {
    return i18nTranslate('confirmationModal.confirmCancel', 'Cancel')
  }

  render() {
    const {
      renderSubsciptionMessage,
      renderText = this.renderTitle(),
      modalMessage = this.renderMessageText(),
      confirmButtonText = this.renderConfirmButtonText(),
      cancelButtonText = this.renderCancelButtonText(),
      handleConfirm = function () {},
      handleCancel,
      handleHide,
      isFromSubscriptions = false,
      showConfirmationModal = false,
      shouldRedirectToPaymentMethod = false,
      path = '',
      startMonth = 0,
      isToDisableConfirmButton = false,
      isPvModal,
      // totalSubscriptionLength = 0,
      // totalSubscriptionSelected = 0,
      isAllSubscriptionsSelected = false,
      isFromSubscriptionProductItem = false,
      isToHideCancelButton = false,
      isMigratedSubscriptionDelete = false,
    } = this.props

    return (
      <Modal
        className={
          isFromSubscriptionProductItem
            ? 'confirmation-modal'
            : isMigratedSubscriptionDelete
            ? 'confirm-delete-modal'
            : 'confirm-remove-model p-0'
        }
        show={showConfirmationModal}
        aria-labelledby="contained-model-title-vcenter"
        onHide={handleHide}>
        <Modal.Header
          closeButton
          className={
            isFromSubscriptionProductItem
              ? 'confirmation-modal-header border-0'
              : 'border-0 confirm-remove-header'
          }
        />
        <Modal.Title
          className={
            isFromSubscriptionProductItem
              ? 'd-none'
              : 'card-title text-uppercase'
          }
          as="h4">
          {isFromSubscriptions && renderText ? '' : renderText}
        </Modal.Title>
        <Modal.Body
          className={
            isFromSubscriptions
              ? 'card-body-subscription'
              : 'p-0 model-card-body'
          }>
          <p>{isFromSubscriptions ? renderSubsciptionMessage : modalMessage}</p>
          <div
            className={`d-flex ${
              isPvModal ? 'justify-content-end flex-row-reverse' : ' flex-row'
            } ${isMigratedSubscriptionDelete ? 'justify-content-center' : ''}`}>
            <Button
              className={`cancel-button text-uppercase rounded-0 justify-content-center p-0 px-3 shadow-none ${
                isToHideCancelButton ? 'd-none' : ''
              }`}
              onClick={handleCancel}
              size={application.isMobile ? 'sm' : 'lg'}
              data-testid="qa-confirm-no">
              {cancelButtonText}
            </Button>
            {isFromSubscriptions ? (
              shouldRedirectToPaymentMethod ? (
                <Link to={path}>
                  <Button
                    className={`confirm-button text-uppercase rounded-0 justify-content-center p-0 px-3 shadow-none ${
                      !application.isMobile ? 'mx-2' : 'mt-3'
                    }`}>
                    {i18nTranslate(
                      'confirmationModal.confirmAddPayment',
                      'ADD PAYMENT METHOD'
                    )}
                  </Button>
                </Link>
              ) : (
                <Link
                  to={`${pageNames.myaccountsubscriptions}?month=${startMonth}`}>
                  <Button
                    className={`confirm-button text-uppercase rounded-0 justify-content-center p-0 px-3 shadow-none ${
                      !application.isMobile ? 'mx-2' : 'mt-3'
                    }`}
                    onClick={() => {
                      subscriptionContainer.showEditPaymentFromModal = true
                    }}>
                    {i18nTranslate(
                      'confirmationModal.confirmAddPayment',
                      'ADD PAYMENT METHOD'
                    )}
                  </Button>
                </Link>
              )
            ) : isPvModal ? (
              <Link
                to={`${pageNames.myaccountsubscriptions}?month=pvassistant`}>
                <Button
                  className={`custom-width mr-2 text-uppercase rounded-0 justify-content-center p-0 px-3 shadow-none ${
                    !application.isMobile ? '' : 'mt-0'
                  }`}
                  onClick={handleConfirm}>
                  {i18nTranslate(
                    'confirmationModal.confirmPvAssistant',
                    'Manage PV Assistant'
                  )}
                </Button>
              </Link>
            ) : (
              !isAllSubscriptionsSelected && (
                <Button
                  className={`confirm-button confirm-delete text-uppercase rounded-0 justify-content-center p-0 px-3 shadow-none ${
                    !application.isMobile ? 'mx-2' : ''
                  }`}
                  onClick={handleConfirm}
                  size={application.isMobile ? 'sm' : 'lg'}
                  disabled={isToDisableConfirmButton}
                  data-testid="qa-confirm-yes">
                  {confirmButtonText}
                </Button>
              )
            )}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export { ConfirmationModal }
export default ConfirmationModal
