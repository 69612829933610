import React from 'react'

class ProductShare extends React.Component {
  static defaultProps = {
    showTitle: true,
  }
  render() {
    const { name, productId, className, showTitle, imageURL } = this.props

    return (
      <section className={`social-share-wrap ${className}`}>
        {/* {showTitle && (
          <h5>
            <Trans i18nKey="pdp.shareText">Share</Trans>
          </h5>
        )}
        <SocialShare
          name={name}
          shareLink={`${pageNames.product}/${productId}`}
          imageURL={imageURL}
        /> */}
      </section>
    )
  }
}

export { ProductShare }
