import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Modal, Button, Col, Form } from 'react-bootstrap'
import storePickupState from './state'
import { StoreList } from './StoreList'
import { storeLocatorJson } from './fixture'
import './styles.scss'

@observer
class StorePickup extends React.Component {
  @observable distance = ''
  @observable showStores = false

  constructor(props) {
    super(props)

    this.miles = storeLocatorJson.storeMiles
    this.allowableZipCodes = storeLocatorJson.storeZipcodes
    this.stores = storeLocatorJson.storeDetails
  }

  getMilesResponse = miles => {
    const milesRes = miles.map((mile, index) => {
      return <option key={index}>{mile}</option>
    })
    return milesRes
  }

  handleSubmit = e => {
    e.preventDefault()

    this.distance = e.target.elements[1].value
    this.showStores = true
  }

  renderStores = e => {
    if (!storePickupState.zipcode) {
      return
    }

    if (this.allowableZipCodes.includes(storePickupState.zipcode) === true) {
      const stores = this.stores.map((store, index) => {
        if (store.storeDistance <= parseInt(this.distance)) {
          return <StoreList store={store} key={`store-${index}`} />
        }
      })

      return (
        <div className="stores-wrapper">
          <div className="store-distance-text">
            {i18nTranslate('bopis.storesNear', 'Stores Near', {
              nameSpace: 'ssr-resource',
            })}{' '}
            {storePickupState.zipcode}
          </div>
          {stores}
        </div>
      )
    }

    return (
      <div>
        <div className="store-distance-text">
          {i18nTranslate('bopis.storesNear', 'Stores Near', {
            nameSpace: 'ssr-resource',
          })}{' '}
          {storePickupState.zipcode}
        </div>
        <div className="no-store-text not-available">
          {i18nTranslate('bopis.noStores', 'No Stores Found', {
            nameSpace: 'ssr-resource',
          })}{' '}
        </div>
      </div>
    )
  }

  handleZipCodeChange = e => {
    const zipCode = e.target.value
    this.showStores = false

    if (zipCode.match(/^[0-9]{1,5}$/) || zipCode === '') {
      storePickupState.zipcode = zipCode
    }
  }

  render() {
    const { show } = this.props

    return (
      <Modal
        show={show}
        onHide={storePickupState.toggleStorePickupModal}
        className="store-pickup-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {i18nTranslate('bopis.availablitiy', 'Check Availability', {
              nameSpace: 'ssr-resource',
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit} className="mt-4">
            <Form.Row>
              <Form.Group as={Col} lg={5} sm={5} xs={12}>
                <Form.Control
                  type="text"
                  name="checkAvailabilitySearch"
                  placeholder={i18nTranslate(
                    'bopis.enterZip',
                    'Enter Zipcode',
                    {
                      nameSpace: 'ssr-resource',
                    }
                  )}
                  value={storePickupState.zipcode}
                  onChange={this.handleZipCodeChange}
                />
              </Form.Group>
              <Form.Group className="d-flex" as={Col} lg={3} sm={3} xs={6}>
                <select className="w-100">
                  {this.getMilesResponse(this.miles)}
                </select>
              </Form.Group>
              <Form.Group as={Col} lg={4} sm={4} xs={6}>
                <Button
                  type="submit"
                  variant="primary"
                  name="checkAvailabilitySearch"
                  disabled={storePickupState.zipcode === '' ? true : ''}
                  className={`store-availability w-100`}>
                  {i18nTranslate('bopis.findStore', 'FIND STORES', {
                    nameSpace: 'ssr-resource',
                  })}
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          {this.showStores && this.renderStores()}
        </Modal.Body>
      </Modal>
    )
  }
}

export { StorePickup }
export default StorePickup
