import { enableOutfits } from 'config/appConfig'
import { CommonContainer } from 'src/models/Common'
import { favoritesContainer } from './Favorites'
import { saveForLaterContainer } from './SaveForLater'
import { wishListContainer } from './wishList'
import { outFitsContainer } from './OutFits'

/**
 * ListsContainer class extends CommonContainer.
 * Represents the container for managing user lists.
 */
class ListsContainer extends CommonContainer {
  availableLists = []
  /**
   * Initializes the favorites and save for later lists by fetching all lists from the API if shouldMakeGetAllListAPI is true,
   * and updating the favorites, outfits, wishlist and save for later containers accordingly.
   */
  initFavoritesAndSaveForLater = async shouldMakeGetAllListAPI => {
    if (shouldMakeGetAllListAPI) {
      await this.getAllLists()
    }
    if (enableOutfits) {
      outFitsContainer.updateFromGetAllList(this.availableLists)
    }
    wishListContainer.updateFromGetAllList(this.availableLists)
    await favoritesContainer.checkAndCreateFavorites()
    await saveForLaterContainer.checkAndCreateSaveForLater()
  }

  /**
   * Creates a new list with the given parameters
   * @param {Object} createListParam - Object containing list details
   * @param {string} createListParam.name - Name of the list
   * @param {string} [createListParam.type] - Type of the list (e.g. WISHLIST)
   * @param {string} [createListParam.itemType] - Type of items in the list (e.g. SKU)
   * @param {string} [createListParam.accessType] - Access type of the list (e.g. PUBLIC)
   * @param {Object[]} [createListParam.listItems] - Array of initial list items
   * @param {Object} [createListParam.properties] - Custom properties for the list
   * @returns {Promise<Object>} Response from the API
   */
  createList = async createListParam => {
    const {
      name,
      type = 'WISHLIST',
      itemType = 'SKU',
      accessType = 'PUBLIC',
      listItems = [],
      properties = {},
    } = createListParam
    const loadParams = {
      endPointName: 'createList',
      postData: {
        name,
        type,
        itemType,
        accessType,
        listItems,
        properties,
      },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Fetches all available lists from the API.
   *
   * @param {Object} params - Additional query parameters to pass to the API
   * @returns {Promise} Response from the API call
   */
  getAllLists = async (params = {}) => {
    const loadParams = {
      endPointName: 'getLists',
      queryParams: { size: 50, page: 1, ...params },
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.availableLists = response.lists
    } else {
      this.availableLists = []
    }
    return response
  }

  /**
   * Fetches details for a specific list by ID from the API.
   *
   * @param {string} [listId] - ID of the list to get. If empty, gets all lists.
   * @returns {Promise} Response from the API call containing list data
   */
  getList = async (listId = '') => {
    const loadParams = {
      endPointName: 'getLists',
      queryParams: { size: 50, page: 1 },
      pathParams: listId,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Fetches the items for a specific list by ID from the API.
   *
   * @param {Object} params - Parameters including list ID, page number, and page size
   * @returns {Promise} Response from the API call containing the requested list items
   */
  getListItems = async params => {
    const page = params.page || 1
    const size = params.size || 50
    const loadParams = {
      endPointName: 'getLists',
      pathParams: `${params.listId}/items`,
      queryParams: {
        page: page,
        size: size,
      },
    }
    if (params?.includeOnlyKeys) {
      loadParams.queryParams.includeOnlyKeys = true
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Adds an item to the specified list by calling the API.
   *
   * @param {Object} params - Parameters including list ID and item data to add
   * @returns {Promise} Response from the API call
   */
  addItemToList = async params => {
    const {
      name,
      productId,
      quantity = 1,
      categoryId = '',
      itemKey,
      imageURL,
      slug,
      type = '',
    } = params.listData
    const loadParams = {
      endPointName: 'addToList',
      pathParams: `${params.listId}/items`,
      postData: [
        {
          key: itemKey,
          name,
          properties: {
            productId,
            quantity,
            categoryId,
            imageURL,
            slug,
            type,
          },
        },
      ],
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Adds multiple items to the specified list by calling the API.
   *
   * @param {Object} params - Parameters including list ID and array of item data to add
   * @returns {Promise} Response from the API call
   */
  multipleAddItemToList = async params => {
    const { items } = params
    const loadParams = {
      endPointName: 'addToList',
      pathParams: `${params.listId}/items`,
      postData: items,
    }

    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Deletes an item from the specified list by calling the API.
   *
   * @param {Object} params - Parameters including list ID and list item ID to delete
   * @returns {Promise} Response from the API call
   */
  deleteItemFromList = async params => {
    const { listId, listItemId } = params
    const loadParams = {
      endPointName: 'deleteFromList',
      pathParams: `${listId}/items/${listItemId}`,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Deletes a list by calling the API.
   *
   * @param {string} listId - The ID of the list to delete
   * @returns {Promise} Response from the API call
   */
  deleteList = async listId => {
    const loadParams = {
      endPointName: 'deleteList',
      pathParams: listId,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Updates a list by calling the API.
   *
   * @param {Object} params - Parameters including list ID and updated list data
   * @returns {Promise} Response from the API call
   */
  updateList = async params => {
    const { listId, listData } = params
    const loadParams = {
      endPointName: 'updateList',
      pathParams: listId,
      postData: listData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Shares a list with other users by calling the API.
   *
   * @param {Object} params - Parameters including list ID and recipients to share with
   * @returns {Promise} Response from the API call
   */
  shareList = async params => {
    const { listId, recipients } = params
    const postData = {
      listId,
      recipients,
    }
    const loadParams = {
      endPointName: 'shareList',
      pathParams: `${listId}/share`,
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Updates a list item by calling the API.
   *
   * @param {Object} props - Parameters including list ID, item ID and updated item properties
   * @returns {Promise} Response from the API call
   */
  updateListItem = async props => {
    const { listId, listItemId, properties } = props
    const postData = {
      properties,
    }
    const loadParams = {
      endPointName: 'updateListItem',
      pathParams: `${listId}/items/${listItemId}`,
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
}

const listsContainer = new ListsContainer()

export { ListsContainer, listsContainer }
export default listsContainer
