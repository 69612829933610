// import { Stack } from 'contentstack'
// import {
//   CS_ENV,
//   CS_ID,
//   CS_TOKEN,
//   QUALIFICATION_CS_API_TOKEN,
//   // CS_API_REGION,
// } from 'config/appConfig'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { APPConfig } from 'config/appConfig'

// const ContentStackAPI = Stack({
//   api_key: CS_ID,
//   delivery_token: CS_TOKEN,
//   environment: CS_ENV,
//   region: CS_API_REGION,
// })

// const QualificationContentStackAPI = Stack({
//   api_key: CS_ID,
//   delivery_token: QUALIFICATION_CS_API_TOKEN,
//   environment: CS_ENV,
//   region: CS_API_REGION,
// })

// Reference URLs
// {
//   "header_prototype": "https://cdn.contentstack.io/v3/content_types/header_prototype/entries/bltcb3502baadc6a2d9?environment=public-test",
//   "qualification_common_strings": "https://cdn.contentstack.io/v3/content_types/qualification_common_strings/entries/bltf8a8c88b3a43cb42?environment=public-test",
//   "footer": "https://cdn.contentstack.io/v3/content_types/footer/entries/blt0779283eb7dd0800?environment=public-test",
//   "web_page_title_banner": "https://cdn.contentstack.io/v3/content_types/web_page_title_banner/entries/blt8e7cb73167bc1997?locale=US-en&include[]=award_references&environment=public-test",
//   "featured_product_tab": "https://cdn.contentstack.io/v3/content_types/featured_product_tab/entries/blt12dacdb24519a5ce?locale=US-en&include[]=award_references&environment=public-test",
//   "brand_slider": "https://cdn.contentstack.io/v3/content_types/brand_slider/entries/blt4bbba2fc9175ca9c?locale=US-en&include[]=award_references&environment=public-test",
//   "featured_in": "https://cdn.contentstack.io/v3/content_types/featured_in/entries/bltb4cdf169215871a8?locale=US-en&include[]=award_references&environment=public-test",
//   "home_page_tagline": "https://cdn.contentstack.io/v3/content_types/home_page_tagline/entries/bltb8aca06a0750cefe?locale=US-en&include[]=award_references&environment=public-test",
//   "awards": "https://cdn.contentstack.io/v3/content_types/awards/entries/blt859970745cd8c4e8?locale=US-en&include[]=award_references&environment=public-test",
//   "news_slider": "https://cdn.contentstack.io/v3/content_types/news_slider/entries/blt7eaa44b8e16dfa44?locale=US-en&include[]=award_references&environment=public-test",
//   "home_page_disclaimer": "https://cdn.contentstack.io/v3/content_types/home_page_disclaimer/entries/blt88039e8d967b4346?locale=US-en&include[]=award_references&environment=public-test"
// }

// https://cdn.contentstack.io/v3/content_types/homepage/entries/?locale=US-en&include_reference_content_type_uid=true&environment=public-test

async function getResponseFromContentStack(options) {
  const { csContentType, locale = 'US-en', entryUid = '' } = options

  const { CS_ENV = '', CS_ID = '', CS_TOKEN = '' } = APPConfig?.getAppConfig()
  const token = options?.token || CS_TOKEN
  let csUrl = `https://cdn.contentstack.io/v3/content_types/${csContentType}/entries/`

  const skipIncludes = [
    'header_prototype',
    'qualification_common_strings',
    'footer',
  ].includes(csContentType)

  if (entryUid) {
    csUrl += `${entryUid}/`
  }

  if (skipIncludes) {
    csUrl += `?environment=${CS_ENV}&locale=${locale}`
  } else {
    csUrl += `?locale=${locale}&include[]=award_references&environment=${CS_ENV}`
  }

  try {
    const response = await fetch(csUrl, {
      method: 'GET',
      headers: {
        api_key: CS_ID,
        access_token: CS_TOKEN,
        token,
      },
    })
      .then(res => res.json())
      .then(data => data?.entry || data?.entries?.[0] || {})
    if (response) {
      return { ...response, apiUrl: csUrl }
    }
    return { apiUrl: csUrl, isEmptyResponse: true }
  } catch (e) {
    return {}
  }
}

export default {
  getEntry(contentTypeUid, locale = 'en-us') {
    console.log(`ContentStack.getEntry ==> `, {
      contentTypeUid,
      locale,
    })

    return getResponseFromContentStack({
      csContentType: contentTypeUid,
      locale,
    })

    // const data = new Promise((resolve, reject) => {
    //   ContentStackAPI.ContentType(contentTypeUid)
    //     .Query()
    //     .language(locale)
    //     .includeReferenceContentTypeUID()
    //     .toJSON()
    //     .find()
    //     .then(
    //       function success(result) {
    //         if (result) {
    //           resolve(result[0])
    //         } else {
    //           return reject('Internal Error')
    //         }
    //       },
    //       function error(error) {
    //         return reject(error)
    //       }
    //     )
    // })
    // console.log('data', data)
    // return data
  },
  getReference(contentTypeUid, entryUid, props = {}) {
    let activeLocale = getLocaleCodeFromUrl({
      locationProps: props,
      defaultLocale: 'en_US',
      isReverseType: true,
    })

    activeLocale =
      activeLocale?.split?.('_')?.reverse?.().join?.('-') || 'US-en'

    if (activeLocale === 'CN-zh') {
      activeLocale = 'CA-zh'
    }

    // console.log(`ContentStack.getReference ==> `, {
    //   contentTypeUid,
    //   activeLocale,
    //   entryUid,
    // })

    return getResponseFromContentStack({
      csContentType: contentTypeUid,
      entryUid,
      locale: activeLocale,
    })

    // const data = new Promise((resolve, reject) => {
    //   ContentStackAPI.ContentType(contentTypeUid)
    //     .Entry(entryUid)
    //     .language(activeLocale)
    //     .includeReference(['award_references'])
    //     .toJSON()
    //     .fetch()
    //     .then(
    //       function success(result) {
    //         if (result) {
    //           resolve(result)
    //         } else {
    //           return reject('Internal Error')
    //         }
    //       },
    //       function error(error) {
    //         return reject(error)
    //       }
    //     )
    // })
    // return data
  },
  getQualificationReference(contentTypeUid, entryUid, locale = 'US-en') {
    const { QUALIFICATION_CS_API_TOKEN = '' } = APPConfig?.getAppConfig()
    // console.log(`ContentStack.getQualificationReference ==> `, {
    //   contentTypeUid,
    //   locale,
    //   entryUid,
    // })

    return getResponseFromContentStack({
      csContentType: contentTypeUid,
      entryUid,
      token: QUALIFICATION_CS_API_TOKEN,
      locale,
    })

    // const data = new Promise((resolve, reject) => {
    //   QualificationContentStackAPI.ContentType(contentTypeUid)
    //     .Entry(entryUid)
    //     .toJSON()
    //     .fetch()
    //     .then(
    //       function success(result) {
    //         if (result) {
    //           resolve(result)
    //         } else {
    //           return reject('Internal Error')
    //         }
    //       },
    //       function error(error) {
    //         return reject(error)
    //       }
    //     )
    // })
    // return data
  },
}
