import { i18nTranslate, convertToBoolean } from 'src/utils'
import { accountsContainer, customerContainer } from 'src/models'
//import parsePhoneNumber from 'libphonenumber-js'
//import parseMax from 'libphonenumber-js/max'
import { APPConfig, IS_BROWSER } from 'config/appConfig'
import {
  getLocaleCodeFromUrl,
  getBrowserIpAddress,
  getFraudDetectionCbkStatus,
  trackSignUpEvents,
} from 'src/utils'

/**
 * Validates sponsor ID entered by user.
 *
 * @param {string} value - Sponsor ID entered by user
 * @param {string} sponsorIdFieldName - Name of sponsor ID field
 * @param {string} accountType - User account type (e.g. Retail Customer)
 * @param {string} currentPage - Current page user is on (e.g. signUp)
 * @returns {Object} Object containing sponsor name if valid, or error if invalid
 */
async function sponsorIdValidCondition(
  value,
  sponsorIdFieldName,
  accountType,
  currentPage
) {
  const res = await customerContainer.sponsorIdValidation(value)
  let sponsorFieldName = {}

  if (res.firstName !== '' && res.firstName !== undefined) {
    const sponsorerName = document.querySelector(
      `[name=${sponsorIdFieldName}] +.invalid-feedback`
    )
    const profilePageSponsorer = document?.querySelector(`[name='sponsorName']`)

    sponsorerName?.classList?.remove('show-red')
    if (currentPage == 'signUp') {
      sponsorerName?.classList?.add('show')
      if (sponsorerName) {
        sponsorerName.innerHTML = `${res.firstName} ${res.lastName}`
      }
    } else {
      profilePageSponsorer.value = `${res.firstName} ${res.lastName}`
    }
    sponsorFieldName = `${res.firstName} ${res.lastName}`
    return { sponsorBoxName: sponsorFieldName, sponsorStatus: true }
  } else if (
    res.firstName === undefined ||
    res.firstName === '' ||
    res.code === 'EOUS0002'
  ) {
    const sponsorerName = document.querySelector(
      `[name=${sponsorIdFieldName}] +.invalid-feedback`
    )
    if (sponsorerName) {
      sponsorerName.classList.add('show-red')
      sponsorerName.innerHTML =
        accountType === 'Retail Customer'
          ? i18nTranslate(
              'signup.retailSponsorError',
              `Sponsor Account entered is incorrect, please enter a valid Sponsor Account. If you do not have a Sponsor id, please skip this field and proceed.`
            )
          : i18nTranslate(
              'signup.sponsorError',
              `Sponsor Account entered is incorrect, please enter a valid Sponsor Account.`
            )
    }
    return { sponsorBoxName: 'Sponsor Name', sponsorStatus: false }
  }
}

/**
 * Parses a phone number string into a formatted, valid phone number object.
 *
 * @param {string} phoneNumber - The phone number to parse.
 * @param {string} phoneCountryCode - The country code for the phone number.
 * @returns {Object} - The parsed and formatted phone number object.
 */
const parsePhoneNumberField = async (
  phoneNumber = '',
  phoneCountryCode = ''
) => {
  let phoneParsedNumber = {}
  if (phoneNumber !== '') {
    const { parsePhoneNumber } = await import('libphonenumber-js')
    phoneParsedNumber = parsePhoneNumber(phoneNumber, phoneCountryCode)
  }

  return phoneParsedNumber
}
// async function parsePhoneNumberField(phoneNumber, phoneCountryCode) {
//   console.log('input => ', phoneCountryCode, phoneNumber)
//   const { parsePhoneNumber } = await import('libphonenumber-js')
//   const phoneParsedNumber = parsePhoneNumber(phoneNumber, phoneCountryCode)
//   console.log('phin', phoneParsedNumber, phoneParsedNumber.number)
// }

/**
 * Validates if the passed phone number object is a valid mobile number.
 *
 * @param {Object} phoneNumberCode - The phone number object to validate.
 * @returns {boolean} - Returns true if valid mobile number, false otherwise.
 */
const validLibMobilePhoneNumber = async (phoneNumberCode = {}) => {
  if (Object.keys(phoneNumberCode).length > 0) {
    const phoneValid = phoneNumberCode?.isValid()
    const { parsePhoneNumberFromString } = await import('libphonenumber-js/max')
    const getTypeValid = parsePhoneNumberFromString(
      phoneNumberCode?.number
    )?.getType()
    const isTypeValid =
      getTypeValid === 'FIXED_LINE_OR_MOBILE' || getTypeValid === 'MOBILE'
        ? true
        : false

    return isTypeValid && phoneValid
  }
  return false
}

/**
 * Checks if a form field should be read-only based on the field name and country.
 *
 * @param {string} fieldName - The name of the form field to check.
 * @param {string} countryName - The country to check read-only rules for.
 * @returns {boolean} - True if the field should be read-only, false otherwise.
 */
function getReadOnlyFields(fieldName, countryName) {
  const nameField = APPConfig?.getAppConfig()?.enableReadonlyFields
  if (nameField?.defaultReadOnly?.fields?.includes(fieldName)) {
    const readOnlyField = nameField?.[fieldName]?.country
    const fieldReadonly =
      readOnlyField.includes(countryName?.toLowerCase()) || false
    return fieldReadonly
  } else {
    const readOnlyDefaultField = nameField?.defaultReadOnly?.country || []
    const fieldReadonly =
      readOnlyDefaultField.includes(countryName?.toLowerCase()) || false
    return fieldReadonly
  }
}

/**
 *retrieveAccountTypeGroupKey : Based on accountType Param we select the groupKey
 * @param {*} accountType loads based on signup dropdown selection
 * @returns {object} the required group keys
 */
const retrieveAccountTypeGroupKey = accountType => {
  const enableJitSuIroncladFeature = convertToBoolean(
    APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
  )
  const customerICGroupKey =
    (enableJitSuIroncladFeature
      ? APPConfig.getAppConfig().ironCladCustomerCWGKey
      : APPConfig?.getAppConfig()?.customerICGroupKey) || 'embedded-y6ffygeua'
  const brandAffiliateICGroupKey =
    (enableJitSuIroncladFeature
      ? APPConfig.getAppConfig().ironCladBACWG1Key
      : APPConfig?.getAppConfig()?.brandAffiliateICGroupKey) ||
    'embedded-npi-usvqn'
  const memberICGroupKey =
    (enableJitSuIroncladFeature
      ? APPConfig.getAppConfig().ironCladMemberCWG1Key
      : APPConfig?.getAppConfig()?.memberICGroupKey) || 'embedded-6rf-dqkit'
  if (accountType === 'Retail Customer') {
    return customerICGroupKey
  } else if (
    accountType === 'Brand Affiliate' ||
    accountType === 'Brand Affiliate - Business Entity'
  ) {
    return brandAffiliateICGroupKey
  } else if (accountType === 'Preferred Customer/Member') {
    return memberICGroupKey
  } else {
    return customerICGroupKey
  }
}

/**
 * loadClickWrapContainer dynamically loads the Ironclad clickwrap library and configures it for the current page context.
 *
 * It first checks if the browser environment is available before proceeding.
 * It then creates a script tag and injects the Ironclad clickwrap bootstrap code into it.
 *
 * Some key steps:
 * - Load the Ironclad JS library from a CDN
 * - Initialize the global _ps namespace for Ironclad
 * - Handle errors loading the library
 * - Insert the script tag into the document to trigger execution
 *
 * After library loads, locale is formatted and customerOptions is called to configure the specific clickwrap options for this page context.
 */
const loadClickWrapContainer = () => {
  if (IS_BROWSER) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = `(function(w,d,s,c,f,n,t,g,a,b,l){w["PactSafeObject"]=n;w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},w[n].on=function(){(w[n].e=w[n].e||[]).push(arguments)},w[n].once=function(){(w[n].eo=w[n].eo||[]).push(arguments)},w[n].off=function(){(w[n].o=w[n].o||[]).push(arguments)},w[n].t=1*new Date(),w[n].l=0;a=d.createElement(s);b=d.getElementsByTagName(s)[0];a.async=1;a.src=c;a.onload=a.onreadystatechange=function(){w[n].l=1};a.onerror=a.onabort=function(){w[n].l=0};b.parentNode.insertBefore(a,b);setTimeout(function(){if(!w[n].l&&!w[n].loaded){w[n].error=1;a=d.createElement(s);a.async=1;a.src=f;a.onload=a.onreadystatechange=function(){w[n].l=1};a.onerror=a.onabort=function(){w[n].l=0};b.parentNode.insertBefore(a,b);l=function(u,e){try{e=d.createElement("img");e.src="https://d3r8bdci515tjv.cloudfront.net/error.gif?t="+w[n].t+"&u="+encodeURIComponent(u);d.getElementsByTagName("body")[0].appendChild(e)}catch(x){}};l(c);setTimeout(function(){if(!w[n].l&&!w[n].loaded){w[n].error=1;if(g&&"function"==typeof g){g.call(this);}l(f)}},t)}},t)})(window,document,"script","https://vault.pactsafe.io/ps.min.js","https://d3l1mqnl5xpsuc.cloudfront.net/ps.min.js","_ps",5000,function(){window.console&&console.error&&console.error("Unable to load Ironclad Clickwrap Library.")});`
    document.body.appendChild(script)
  }
}

let formattedLocale = getLocaleCodeFromUrl() || ''
formattedLocale = formattedLocale?.replace('_', '-')
/**
 *createSiteObject creates and loads the Ironclad Contracts w.r.t given siteobject and groupkey
 * @param {*} siteObject contains the siteAccess Key
 * @param {*} groupKey contains the groupKey based on account type
 */
const siteObject =
  APPConfig?.getAppConfig()?.ironCladSiteAccessId ||
  '1afda702-5218-40d2-a5e0-95da5e891fa8'
const createSiteObject = (groupKey, contactPageId) => {
  const enableJitSuIroncladFeature = convertToBoolean(
    APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
  )
  const acceptance =
    (contactPageId === 'member'
      ? i18nTranslate('signup.iAgreeTo', 'I agree to the')
      : i18nTranslate(
          'signup.understand',
          'I have read, understand, and agree to the'
        )) || 'I have read, understand, and agree to the'
  accountsContainer.accountsTermCheck = false
  let options = {}
  if (contactPageId === 'contractPage-1') {
    options.containerSelector = 'clickwrap-container-1'
    options.tag = 'page1'
  } else if (contactPageId === 'contractPage-2') {
    options.containerSelector = 'clickwrap-container-2'
    options.tag = 'page2'
  } else if (contactPageId === 'contractPage-3') {
    options.containerSelector = 'clickwrap-container-3'
    options.tag = 'page3'
  } else if (contactPageId === 'member') {
    options.containerSelector = enableJitSuIroncladFeature
      ? 'clickwrap-container-2'
      : 'clickwrap-container-1'
    options.tag = 'member'
  } else if (contactPageId === 'general') {
    options.containerSelector = 'clickwrap-container-1'
    options.tag = enableJitSuIroncladFeature ? 'general' : 'customer'
  } else if (contactPageId === 'memagree') {
    options.containerSelector = 'clickwrap-container-2'
    options.tag = contactPageId
  } else {
    options.containerSelector = 'clickwrap-container-1'
    options.tag = 'customer'
  }
  options.acceptance = acceptance
  const optionsSelected = getIronCladOptions(options)

  removeSiteObject(siteObject)
  if (_ps) {
    let jurisdiction_locale = formattedLocale?.split('-')?.[0] || ''
    _ps('create', siteObject, {
      disable_sending: 'true',
      jurisdiction: jurisdiction_locale,
    })
    _ps('load', `"${groupKey}"`, optionsSelected)
  }
  accountsContainer.accountsTermCheck = true
  accountsContainer.accountsContractCheck = false
}

const getIronCladOptions = props => {
  const { tag, acceptance, containerSelector } = props

  const conjunction = i18nTranslate('ironcladConjunction', 'and')
  return {
    container_selector: containerSelector, // HTML Element ID of where we want the clickwrap to load in the page.
    filter: `locales==${formattedLocale} AND tags==${tag}`,
    acceptance_language: `${acceptance} {{contracts " , " " ${conjunction} "}}`,
    display_all: true, // Always display the contracts, even if previously signed by the Signer.
    signer_id_selector: 'email', // Uses the email input field value as the Signer ID and listen to the field.'
  }
}
/**
 * removeSiteObject removes the previous loaded site key
 * @param {*} siteObject
 */
const removeSiteObject = siteObject => {
  if (_ps) {
    _ps('remove', siteObject)
  }
}

const checkboxValidationCheck = attribute => {
  if (_ps) {
    const ironcCladClass = document.querySelector('.clickwrapContainerError')
    if (ironcCladClass && attribute === 'add') {
      ironcCladClass?.classList?.add('show')
    } else {
      ironcCladClass?.classList?.remove('show')
    }
  }
}

/**
 * This is used to get the data for the corresponding groupkey
 * @param {*} groupKey
 * @returns {object}
 */
const getIroncladAcceptanceData = groupKey => {
  // Grab the payload (encoded URL params) to be sent once accepted and ready.
  let myPayload = {}
  try {
    myPayload = _ps.getByKey(groupKey).getPayload() || {}
    const formattedData = formatIroncladAcceptanceData(myPayload)
    accountsContainer.IronCladGroupId = formattedData?.gid || ''
    let acceptancePayload = ''
    try {
      acceptancePayload = JSON.stringify(formattedData)
    } catch (err) {
      console.log(err)
    }
    return acceptancePayload
  } catch (err) {
    console.log(err)
  }
}

/**
 * Formats the Ironclad acceptance payload query string into an object.
 *
 * Accepts the encoded acceptance payload query string.
 * Splits it into key/value pairs.
 * Decodes each value.
 * Returns object with query param keys and decoded values.
 */
const formatIroncladAcceptanceData = acceptancePayload => {
  const ironcladAcceptanceData = {}
  if (acceptancePayload) {
    const acceptancePayloadParams = acceptancePayload?.split('&') || ''
    acceptancePayloadParams?.forEach(param => {
      const paramArry = param?.split('=') || ''
      const paramKey = paramArry[0] || ''
      const paramValue = paramArry[1] || ''
      if (paramArry) {
        ironcladAcceptanceData[paramKey] = decodeURIComponent(paramValue)
      }
    })
  }
  return ironcladAcceptanceData
}

/**
 * @description adding Accertify properties to the payload
 * @date 11/24/2023 - 12:15:00 PM
 *
 * @async
 */
const getFraudDetectionProperties = async () => {
  let fraudDetectionProperties = {}
  if (IS_BROWSER) {
    const isFraudDetectionScriptSuccess = getFraudDetectionCbkStatus()
    const ipAddress = (await getBrowserIpAddress()) || ''
    const deviceId = isFraudDetectionScriptSuccess
      ? window?._bcn?.dvc?.getTID()
      : ''
    const pageId = window?._bcn?.getPageId() || ''
    const ubaId = window?._bcn?.getToken() || ''
    const ubaSessionId = window?._bcn?.getUbaSessionId() || ''
    const ubaEvents = window?._bcn?.getEvents() || ''
    const ubaAgent = navigator?.userAgent || ''

    fraudDetectionProperties = {
      ipAddress: ipAddress,
      deviceId: deviceId,
      pageId: pageId,
      ubaId: ubaId,
      ubaSessionId: ubaSessionId,
      ubaEvents: ubaEvents,
      ubaAgent: ubaAgent,
    }
  }
  return fraudDetectionProperties
}

const trackAgreementEvents = (formName, isFromModal) => {
  if (accountsContainer?.selectedAccountType === 'Brand Affiliate') {
    let eventType = ''
    if (formName === 'form1') {
      eventType = isFromModal
        ? 'eqDistInviteSelectAgreement1'
        : 'eqDistColdSelectAgreement1'
    } else if (formName === 'form2') {
      eventType = isFromModal
        ? 'eqDistInviteSelectAgreement2'
        : 'eqDistColdSelectAgreement2'
    } else if (formName === 'form3') {
      eventType = isFromModal
        ? 'eqDistInviteSelectAgreement3'
        : 'eqDistColdSelectAgreement3'
    }
    trackSignUpEvents({
      eventType: eventType,
    })
  } else if (accountsContainer?.selectedAccountType === 'Retail Customer') {
    trackSignUpEvents({
      eventType: isFromModal
        ? 'eqRetailInviteSelectAgreement'
        : 'eqRetailCustSelectAgreement',
    })
  } else if (
    accountsContainer?.selectedAccountType === 'Preferred Customer/Member'
  ) {
    trackSignUpEvents({
      eventType: 'eqCustInviteSelectAgreement',
    })
  }
}

const toggleIronCladCheck = (isIronCladChecked, element) => {
  const enableJitSuIroncladFeature = convertToBoolean(
    APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
  )
  const id = element?.getAttribute('id')?.split('-') || []
  const ironCladId = id ? id[id.length - 1] : ''
  const clickWrapName = document
    ?.querySelector(`div[data-gid="${ironCladId}"]`)
    ?.parentElement?.getAttribute('id')
  if (clickWrapName == 'clickwrap-container-1') {
    accountsContainer.signUpIronCladCheck.clickWrap1 = isIronCladChecked
  } else if (clickWrapName == 'clickwrap-container-2') {
    accountsContainer.signUpIronCladCheck.clickWrap2 = isIronCladChecked
  } else {
    accountsContainer.signUpIronCladCheck.clickWrap3 = isIronCladChecked
  }
  if (
    enableJitSuIroncladFeature &&
    isIronCladChecked &&
    (clickWrapName == 'clickwrap-container-2' ||
      clickWrapName == 'clickwrap-container-3')
  ) {
    const accountType = accountsContainer?.convertAccoutType
    if (accountType === 'Preferred Customer/Member') {
      if (
        accountsContainer.signUpIronCladCheck.clickWrap1 &&
        accountsContainer.signUpIronCladCheck.clickWrap2
      ) {
        checkboxValidationCheck('remove')
      }
    } else if (
      accountsContainer.signUpIronCladCheck.clickWrap2 &&
      accountsContainer.signUpIronCladCheck.clickWrap3
    ) {
      checkboxValidationCheck('remove')
    }
  } else {
    checkboxValidationCheck(isIronCladChecked ? 'remove' : 'add')
  }
  accountsContainer.accountsContractCheck = isIronCladChecked
  return `${clickWrapName}-${isIronCladChecked}`
}

const getMemberGroupKey = groupKey => {
  const enableJitSuIroncladFeature = convertToBoolean(
    APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
  )
  if (enableJitSuIroncladFeature) {
    const memberAgreeGroupKey = APPConfig?.getAppConfig()?.ironCladMemberCWG2Key
    createSiteObject(groupKey, 'general')
    createSiteObject(memberAgreeGroupKey, 'memagree')
  } else {
    createSiteObject(groupKey, 'member')
  }
}

export {
  sponsorIdValidCondition,
  parsePhoneNumberField,
  validLibMobilePhoneNumber,
  getReadOnlyFields,
  createSiteObject,
  getIroncladAcceptanceData,
  retrieveAccountTypeGroupKey,
  loadClickWrapContainer,
  removeSiteObject,
  checkboxValidationCheck,
  getFraudDetectionProperties,
  trackAgreementEvents,
  toggleIronCladCheck,
  getMemberGroupKey,
}
