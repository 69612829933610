import { observable, action } from 'mobx'

class MobileMenuState {
  @observable isMenuVisible = false

  @action
  toggleMenuVisibility = () => {
    this.isMenuVisible = !this.isMenuVisible
    this.isMenuVisible
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll')

    // The page content to be visible until the pancake covers 100% in the screen
    if (this.isMenuVisible) {
      setTimeout(() => {
        document.body.classList.add('no-scroll-animate')
      }, 350)
    } else {
      document.body.classList.remove('no-scroll-animate')
    }
  }
}

const mobileMenuState = new MobileMenuState()

export { mobileMenuState }
