import { observable } from 'mobx'
import { getAppConfig, APPConfig } from 'config/appConfig'
import { IS_BROWSER, getLiveEventStatus } from 'src/utils'
import { CommonContainer } from 'src/models/Common'
import { getResourceFrom3rdPartyUrl } from 'src/api/localeResources'

/**
 * FooterContainer class that extends CommonContainer.
 * Represents the footer container component.
 */
class FooterContainer extends CommonContainer {
  @observable footerResponse = {}
  @observable nuskinLogoURL = ''

  /**
   * Fetches footer data from the Content Service or a 3rd party URL based on config.
   * Transforms the response into the footer model used in the app.
   *
   * Async function that takes in component props.
   * Checks config to see if footer data should come from Content Service or external URL.
   * Constructs params for each data source, making call and transforming response.
   * Returns promise resolving to transformed footer data model.
   */
  getFooterData = async props => {
    const getFooterDataFromCS =
      APPConfig?.getAppConfig()?.enableFooterFromCS == 'true'

    const enableLiveEvent = getLiveEventStatus()
    const footerFileName =
      enableLiveEvent === 'true' ? 'liveEventFooter' : 'footer'

    const locale = (props?.queryParams?.locale || 'en_US').replace('_', '-')
    const csLoadParams = {
      endPointName: 'footerData',
      contextName: props.contextName,
      curLocaleFromUrl: locale,
    }
    const loadParams = {
      // endPointName: 'footerData',
      contextName: props.contextName,
      credentials: 'omit',
      isToIncludeCommomHeaders: false,
      // curLocaleFromUrl: props.context?.curLocaleFromUrl,
      deleteContentType: true,

      // new method props
      fileName: footerFileName,
      locale,
      domainName: getAppConfig().localeResourceFilesDomain || 'dev.nuskin.com',
    }

    let response = {}

    if (IS_BROWSER) {
      if (getFooterDataFromCS) {
        response = await this.fetchResponse(csLoadParams)
      } else {
        response = await getResourceFrom3rdPartyUrl(loadParams)
      }
    } else {
      if (props.context && !props.context.data) {
        if (getFooterDataFromCS) {
          props.context.load(this.fetchResponse(csLoadParams))
        } else {
          props.context.load(getResourceFrom3rdPartyUrl(loadParams))
        }
      }
    }

    if (this.isSuccessResponse(response)) {
      this.footerResponse = getFooterDataFromCS
        ? this.transformFooter(response)
        : response
    } else {
      console.error('Fn Error in Footer response ', response)
    }

    return this.footerResponse
  }

  /**
   * Transforms the footer response data into a model used in the application.
   * Maps response data to navigation, social media, affiliated links
   * and privacy/settings link models.
   */
  transformFooter = response => {
    const transformModel = {
      navigation: [],
      socialMediaHeaderText: '',
      socialMediaList: [],
      affiliated_navigation_: '',
      detailsLinks: [],
    }
    // navigation model
    let navigationLinks = response?.entry?.main_navigation || []
    navigationLinks.forEach(links => {
      let navObj = {
        title: '',
        links: [],
      }
      navObj['title'] = links?.heading || ''
      links?.links?.forEach(link => {
        let obj = {
          name: '',
          href: '',
        }
        obj['name'] = link?.link?.title || ''
        obj['href'] = link?.link?.href || ''
        navObj['links'].push(obj)
      })
      transformModel['navigation'].push(navObj)
    })

    // social links model
    let socialLinks = response?.entry?.social_navigation || []
    transformModel['socialMediaHeaderText'] = socialLinks?.heading || ''
    socialLinks?.links?.forEach(link => {
      let obj = {
        socialIconLink: '',
        socialIconUrl: '',
      }
      obj['socialIconLink'] = link?.link?.href || ''
      obj['socialIconUrl'] = link?.link?.title || ''
      transformModel['socialMediaList'].push(obj)
    })

    // affiliatedLinks model
    let affiliatedLinks = response?.entry?.affiliated_navigation_ || []
    transformModel['affiliated_navigation_'] = affiliatedLinks

    // privacy links model
    let privacyLinks = response?.entry?.settings_navigation || []
    privacyLinks?.links?.forEach(link => {
      let obj = {
        name: '',
        href: '',
      }
      obj['name'] = link?.link?.title || ''
      obj['href'] = link?.link?.href || ''
      transformModel['detailsLinks'].push(obj)
    })

    return transformModel
  }
}

const footerContainer = new FooterContainer()

export { footerContainer, FooterContainer }
export default footerContainer
