import * as yup from 'yup'
import { getLocalStorage } from 'src/utils'
import { getPhoneSchema as getMobilePhoneSchema } from 'src/deps'

const schema = () => {
  const accType = getLocalStorage('accountType')
  let schemaData = {}
  Object.assign(schemaData)
  if (accType == 'Brand Affiliate - Business Entity') {
    Object.assign(schemaData)
  } else if (accType == 'Brand Affiliate') {
    Object.assign(
      schemaData,
      getMobilePhoneSchema({
        inputName: 'mobilePhoneNumber',
      })
    )
  } else {
    Object.assign(
      schemaData,
      getMobilePhoneSchema({
        inputName: 'mobilePhoneNumber',
      })
    )
  }
  return yup.object(schemaData)
}

export { schema }
