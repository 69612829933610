import React, { Component } from 'react'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { customerContainer } from 'src/models'
import { AiOutlineMenu as MenuIcon } from 'react-icons/ai'
import { Container, Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { pageNames } from 'src/routes/pathParams'

import {
  HeaderLogo,
  mobileMenuState,
  UserMenu,
  SearchBox,
  CartIcon,
  // FavoriteIconWithLink,
} from 'src/views/components'
import { application } from 'src/utils'

@observer
class SearchSection extends Component {
  nameSpace = 'ssr-resource'
  @observable searchFocused = false
  @observable searchCleared = false

  constructor(props, context) {
    super(props, context)
  }

  toggleSearchModal = value => {
    this.searchFocused = value
  }

  closeSearchModal = () => {
    this.searchFocused = false
    this.searchCleared = false
  }

  closeSearchSectionModal = () => {
    this.searchFocused = false
  }

  clearSearchModal = value => {
    this.searchCleared = value
  }
  harmsburg = (isGuestB2B, requestDomain) => {
    !isGuestB2B && mobileMenuState?.toggleMenuVisibility()
    // const toggle = !isGuestB2B && mobileMenuState?.toggleMenuVisibility()
    // console.log(mobileMenuState?.toggleMenuVisibility, 'toggle Check')
    // console.log(isGuestB2B, customerContainer?.isGuestB2B, 'Guest Check')
    // console.log('check')
  }

  render() {
    const isGuestB2B = customerContainer.isGuestB2B
    const requestDomain = this.props?.staticContext?.requestDomain || ''

    return (
      <section className="header-middle-container px-md-3 h-100">
        <Container className="h-100 p-0" style={{ maxWidth: '1366px' }}>
          <div className="h-100 m-3 m-md-0 d-flex flex-wrap align-items-center justify-content-between">
            <Link
              to={pageNames?.home}
              data-testid="qa-nuskin-logo"
              className="header-Logo">
              <HeaderLogo
                requestDomain={requestDomain}
                headerLogoUrl={this.props.headerLogoUrl}
                isNuskinHeader
              />
            </Link>
            <div className="nu-header-search-box mt-3 mt-md-0 order-md-2 order-3 w-50 w-sm-100">
              <SearchBox
                {...this.props}
                isNuskinHeader
                toggleSearchModal={this.toggleSearchModal}
                clearSearchModal={this.clearSearchModal}
                nuskinReadOnly
              />
            </div>
            <div className="d-flex justify-content-between justify-content-lg-end order-2 order-md-3 align-items-center nu-search-right">
              <div>
                <UserMenu {...this.props} isNuskinHeader />
              </div>
              {/* <FavoriteIconWithLink className="text-decoration-none favorite-wrapper" /> */}
              {!isExpressCheckout(true) && (
                <div className="ml-lg-3">
                  <CartIcon {...this.props} isNuskinHeader />
                </div>
              )}
              <div>
                <button
                  onClick={() => {
                    this.harmsburg(isGuestB2B, requestDomain)
                  }}
                  className="hide-on-desktop nu-header-pancake-button px-0 header-mobile-view  bg-transparent border-0 pl-0"
                  aria-label="navigation menu"
                  title="navigation menu">
                  <MenuIcon aria-label="navigation menu" />
                </button>
              </div>
            </div>
          </div>
          <Modal
            className="search-modal"
            show={this.searchFocused && !application.isDesktopOrLarger}
            dialogClassName="search-modal-fullscreen"
            onHide={() => (this.searchFocused = false)}>
            <Modal.Header className="p-0">
              <div className="w-100 h-100 ml-3 my-3">
                <SearchBox
                  shouldFocusOnRender={true}
                  isNuskinHeader
                  closeSearchModal={this.closeSearchModal}
                  clearSearchModal={this.clearSearchModal}
                  searchCleared={this.searchCleared}
                  {...this.props}
                />
              </div>
              <div className="h-100 text-center d-flex justify-content-center nu-mobile-search-cancel">
                <Button
                  className="search-modal-cancel-button"
                  variant="none"
                  onClick={this.closeSearchSectionModal}>
                  {i18nTranslate('search.cancel', 'CANCEL', {
                    nameSpace: this.nameSpace,
                  })}
                </Button>
              </div>
            </Modal.Header>
            <Modal.Body></Modal.Body>
          </Modal>
        </Container>
      </section>
    )
  }
}

export default SearchSection
