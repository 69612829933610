import React from 'react'
import { observer } from 'mobx-react'
import { Row } from 'react-bootstrap'
import { ProductFavorites } from '../ProductFavorites'
import { productDeps } from 'src/deps'
import './styles.scss'

@observer
class ProductFavoritesWithText extends React.Component {
  renderFavorites = () => {
    const { enableFavorites = true, product, className = '' } = this.props
    let isReplacementItem = false
    if (product?.type === 'bundle' || product?.type === 'kit') {
      isReplacementItem = productDeps.getIsReplacementItem(product)
    } else {
      const selectedSku = productDeps.getUserSelectedSku(product)
      isReplacementItem = productDeps.getIsReplacementItem(selectedSku)
    }
    let enableFavoritesIcon = enableFavorites && !isReplacementItem

    return (
      enableFavoritesIcon && (
        <ProductFavorites
          {...this.props?.product}
          showTextWithIcon={true}
          className={className}
          enableNewPDPStyle={true}
        />
      )
    )
  }

  render() {
    return (
      <Row noGutters className="product-favorites-with-text">
        {this.renderFavorites()}
      </Row>
    )
  }
}

export { ProductFavoritesWithText }
