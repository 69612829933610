/**
 * homepageSchema function
 * Generates schema data for the homepage
 * @param {object} props - Props passed to the component
 * @returns {object} schemaData - Schema data object
 */
function homepageSchema(props) {
  let schemaData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    Logo: 'https://media.nuskin.com/m/2c95048647c42590/Vector_to_PNG-logo.png',
    description:
      "Discover the best you with Nu Skin's innovative anti-aging skin care products and rewarding business opportunities, while making a difference in the world through our force for good initiatives. No matter where you are in life Nu Skin can help you grow through our uplifting culture.",
  }
  return schemaData
}

export { homepageSchema }
