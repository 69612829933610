import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { observer } from 'mobx-react'
import { COLOR_BASED_VARIANT_NODES } from 'config/appConfig'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import colorCode from 'src/static/fixtures/colorCode.json'
import { isValidUrl } from 'src/utils'
import './styles.scss'

@observer
class ProductOption extends React.Component {
  static defaultProps = {
    selectionType: 'border',
  }
  render() {
    const disableSkuSelectionClass = this.props.disableSkuSelection
      ? 'disable-selection'
      : ''
    const { options = [], skuSelected = {}, selectionType } = this.props
    const skuGroup =
      options.length > 0 &&
      options.map(key => {
        const isColorBasedSku = COLOR_BASED_VARIANT_NODES.includes(
          key.type.toLowerCase()
        )

        const buttonGroup = (values, index) => {
          const value = values.label
          const type = key.type
          const shouldDisable =
            values.disable === true ||
            (this.props.isFromSubscription && !values.isSubscribable)
          const padding = isColorBasedSku ? 'p-1' : ''
          const className = shouldDisable
            ? ' disabled'
            : skuSelected[type] === value
            ? selectionType === 'border' || isColorBasedSku
              ? ` selected border-primary text-primary sku-border-thick font-weight-bold ${padding}`
              : ` selected bg-primary border-0 text-white font-weight-bold ${padding}`
            : ` ${padding}`

          const attribute = {
            className: type + className + ' mr-2 mb-2 text-uppercase',
            key: `${key.type}_${index}`,
          }

          return (
            <Button
              {...attribute}
              variant="outline-secondary"
              data-testid="qa-facet"
              onClick={() =>
                this.props.handleSkuSelection &&
                this.props.handleSkuSelection({ type, value })
              }>
              {isColorBasedSku ? (
                isValidUrl(values.url) ? (
                  <LazyLoadImage
                    src={values.url || imagePlaceholder}
                    alt={values.label}
                    className={'align-top'}
                    onError={e => {
                      if (e.target.src != imagePlaceholder) {
                        e.target.onerror = null
                        e.target.src = imagePlaceholder
                      }
                    }}
                  />
                ) : (
                  <div
                    className="border"
                    style={{
                      background: colorCode[value],
                      height: '25px',
                      width: '25px',
                    }}
                  />
                )
              ) : (
                // <Image
                //   className="align-top"
                //   src={values.url}
                //   onError={e => (e.target.src = imagePlaceholder)}
                //   alt={values.label}
                // />
                value
              )}
            </Button>
          )
        }
        return (
          <section
            key={`product-option-${key.type}`}
            data-testid={`qa-select-${key.type}`}
            className={`product-option-list py-2 align-items-center ${disableSkuSelectionClass}`}>
            <Row noGutters className="pb-2 align-items-center">
              <h5 className="text-uppercase mb-0">
                {/* {getLocaleString()[key.type]} */}
                {key?.values?.length > 1
                  ? i18nTranslate(
                      'product.selectOption',
                      'Select your option',
                      {
                        nameSpace: 'ssr-resource',
                      }
                    ) + ': '
                  : i18nTranslate('product.yourOption', 'Your option') + ': '}
              </h5>
              {COLOR_BASED_VARIANT_NODES.includes(key.type.toLowerCase()) && (
                <>
                  <div className="mx-1"> : </div>
                  <label className="sku-option text-uppercase pl-1 mb-0">
                    {skuSelected[key.type]}
                  </label>
                </>
              )}
            </Row>
            <Row
              noGutters
              className={`product-options ${key.type}-wrapper`}
              aria-label={i18nTranslate(
                'productDetails.skuOption',
                'Sku Options',
                {
                  nameSpace: 'ssr-resource',
                }
              )}>
              {key.values.map((keyObj, index) => buttonGroup(keyObj, index))}
            </Row>
            {this.props.showError && this.props.showError(key.type)}
          </section>
        )
      })
    return skuGroup
  }
}

export { ProductOption }
export default ProductOption
