import isFunction from 'lodash/isFunction'
import { tokenExIframe } from 'src/views/components/TokenExIframe'
import {
  checkoutContainer,
  storeContainer,
  addressContainer,
  accountsAddressContainer,
  subscriptionContainer,
  cartContainer,
  customerContainer,
} from 'src/models'
import { countriesJson } from 'src/static/geography'
import {
  IS_BROWSER,
  getLiveEventStatus,
  i18nTranslate,
  isExpressCheckout,
} from 'src/utils'
import { isEmptyArray } from 'formik'

const renderFormInput = props => {
  const handleExpressCheckout = isExpressCheckout()
  const {
    isFromPayment = false,
    isFromEditPayment = false,
    isFromSubscription = false,
    isPaymentEdit = false,
    isFromEditSubscription = false,
    isMultipleAdressActive = false,
    isFromCheckout = false,
    hasSubscriptionProduct = false,
    firstNameFlag = false,
    savePaymentCheckboxFlag = false,
    cardnumberFlag = false,
    cardFirstname = '',
    cardnumber = '',
    handleDefaultPaymentCheckbox = function () {},
    inputFirstname = function () {},
    multiAddressRenderProp = function () {},
    hideDefaultSubPaymentCheckbox = false,
    editUpdateModalEnabled = false,
    billingAddressType,
    selectedBillingAddress = {},
    isDefault = false,
    isSubscriptionDefaultPayment = false,
    ...paymentInfo
  } = props

  const enableLiveEvent = getLiveEventStatus()

  let shouldRenderAddressForm = false

  const {
    expiryYear = '',
    expiryMonth = '',
    cardNumber = '',
    name = '',
  } = paymentInfo?.paymentData?.accountInfo || {}

  const twoDigitYear = expiryYear?.slice(2, 4)

  const onKeyDownFunction = event => {
    if (
      event.which != 8 &&
      isNaN(String.fromCharCode(event.which)) &&
      event.which != 37 &&
      event.which != 39
    ) {
      event.preventDefault() //stop character from entering input
    }
  }

  const handleAddressCheckbox = () => {
    shouldRenderAddressForm = !shouldRenderAddressForm
    checkoutContainer.paymentBillingSameAsShippingFlag =
      !checkoutContainer.paymentBillingSameAsShippingFlag
    // In live event billing address will shouwn by default for new payment
    // Do not hide country list
    if (IS_BROWSER) {
      let countryField = document.getElementsByClassName('payment-form-country')
      if (enableLiveEvent === 'true') {
        countryField = Array.from(countryField).filter(
          element => !element.closest('.live-event-enabled')
        )
      }

      if (
        !checkoutContainer.paymentBillingSameAsShippingFlag &&
        !isEmptyArray(countryField)
      ) {
        countryField[0]?.classList?.remove('d-none')
        document
          .querySelectorAll('.payment-form-country option')
          .forEach(opt => {
            if (opt.value == '') {
              opt.disabled = true
            }
          })
      } else {
        countryField[0]?.classList?.add('d-none')
      }
    }
  }

  const handleAddPaymentCheckbox = () => {
    checkoutContainer.addPaymentToProfile =
      !checkoutContainer.addPaymentToProfile
    if (IS_BROWSER) {
      const defaultPaymentCheckbox = document.getElementsByClassName(
        'set-default-payment'
      )
      const defaultPaymentMethodCheckbox =
        document.getElementById('qa-default-payment')
      if (
        checkoutContainer.addPaymentToProfile === true &&
        !isEmptyArray(defaultPaymentCheckbox)
      ) {
        defaultPaymentCheckbox[0]?.classList?.remove('disable')
        defaultPaymentMethodCheckbox.setAttribute('tabindex', '0')
      } else {
        defaultPaymentCheckbox[0]?.classList?.add('disable')
        defaultPaymentMethodCheckbox.checked = false
        defaultPaymentMethodCheckbox.setAttribute('tabindex', '-1')
      }

      const checkBoxValidations = document.querySelector(
        "[name='addPaymentToProfile']"
      )
      if (checkBoxValidations?.checked) {
        checkBoxValidations?.classList?.remove('is-invalid')
      } else {
        checkBoxValidations?.classList?.add('is-invalid')
      }
    }
  }

  const fieldConfig = []
  const requiredNote = {
    name: 'required_note',
    type: 'div',
    columnType: {
      lg: '12',
    },
    dataTestId: 'qa-required-fields-note',
    customDataTestId: 'qa-required-field',
    customClassName: 'my-1 required-note',
  }
  const cardFieldLabel = editUpdateModalEnabled
    ? 'reEnterCardNumber'
    : 'cardNumber'

  const cardNumberField = [
    isFromEditPayment
      ? {
          name: cardFieldLabel,
          ariaLabel: cardFieldLabel,
          labelKey: cardFieldLabel,
          dataTestId: 'qa-pay-card',
          columnType: {
            lg: '12',
          },
          defaultValue:
            cardnumber == ''
              ? cardnumberFlag
                ? `**** **** **** ${cardNumber}`
                : ''
              : cardnumber,
          disabled: isFromEditPayment,
          maxLength: 16,
          customClassName: 'pb-3',
          isToHideLabel: !editUpdateModalEnabled && handleExpressCheckout,
        }
      : {
          labelKey: cardFieldLabel,
          columnType: {
            lg: '12',
          },
          RenderProps: tokenExIframe?.isFromCreditCard(editUpdateModalEnabled),
          name: cardFieldLabel,
          ariaLabel: cardFieldLabel,
          customClassName: 'pb-3',
          isToHideLabel: !editUpdateModalEnabled && handleExpressCheckout,
        },
  ]
  const firstNameField = {
    name: 'cardFirstName',
    ariaLabel: 'firstName',
    dataTestId: 'qa-card-first-name',
    placeholderKey: 'cardFullNamePlaceholder',
    customClassName: 'mt-2 pb-3 card-name-input',
    isToHideLabel: !editUpdateModalEnabled && handleExpressCheckout,
    columnType: {
      lg: '12',
    },
    customDataTestId: 'qa-credit-card-name-field',
  }
  const expiryField = [
    {
      name: 'expiryDate',
      labelKey: 'expiryDate',
      type: 'mask',
      dataTestId: 'qa-pay-date',
      ariaLabel: 'expiryDate',
      placeholderKey: isExpressCheckout()
        ? 'expressExpDatePlaceholder'
        : 'expDatePlaceholder',
      isToHideLabel: !editUpdateModalEnabled && handleExpressCheckout,
      columnType: {
        lg: handleExpressCheckout ? '12' : isFromEditSubscription ? '6' : '3',
      },
      customClassName: 'expirydate-wrapper pb-3',
    },
    isFromEditPayment
      ? {
          RenderProps: tokenExIframe?.isDisplayNoneCVV(),
          columnType: {
            lg: isFromSubscription ? '3' : '4',
            sm: '4',
          },
          name: 'cardCVV',
          ariaLabel: 'cardCVV',
          customClassName: 'pb-3 ',
        }
      : {
          RenderProps: tokenExIframe?.isFromCVV(editUpdateModalEnabled),
          columnType: {
            lg: handleExpressCheckout
              ? '12'
              : isFromEditSubscription
              ? '6'
              : '5',
            sm: handleExpressCheckout ? '12' : '6',
          },
          name: 'cardCVV',
          ariaLabel: 'cardCVV',
          customClassName: 'cvv-wrapper pb-3',
          isToHideLabel: !editUpdateModalEnabled && handleExpressCheckout,
        },
  ]

  const paymentDefaultConfig = {
    name: 'setToDefaultPayment',
    type: 'checkbox',
    customClassName: !isFromPayment
      ? 'set-default-payment'
      : 'set-default-payment disable',
    inlineLabelKey: 'setToDefaultPayment',
    isToHideLabel: true,

    columnType: {
      lg: '8',
    },
    dataTestId: 'qa-default-payment',
    disabled: isDefault,
    defaultChecked: isDefault,
    customAttributes: {
      customId: paymentInfo?.index,
    },
    customDataTestId: 'qa-default-payment-field',
  }

  const defaultSubscriptionPaymentCheckboxField = {
    name: 'setDefaultSubscriptionPayment',
    type: 'checkbox',
    columnType: {
      lg: '12',
    },
    customClassName: 'default-subscription-payment',
    dataTestId: 'qa-default-subs-payment',
    isToHideLabel: true,
    inlineLabelKey: 'setDefaultSubscriptionPayment',
    defaultChecked: isSubscriptionDefaultPayment,
    disabled: isSubscriptionDefaultPayment,
    customDataTestId: 'qa-default-subscription-payment-field',
  }

  const addressDefaultConfig = () => {
    const accountAddress =
      accountsAddressContainer.addressData?.length > 0
        ? accountsAddressContainer.addressData
        : []
    const addressData =
      addressContainer.addressData?.length > 0
        ? addressContainer.addressData
        : accountAddress
    let defaultAddress = addressData?.some(
      address => address?.isDefault === true
    )
    let checkoutDeliveryAddress =
      cartContainer.cartResponse?.deliveryDetails?.address || {}
    let subscriptionShippingAddress =
      subscriptionContainer.subscriptionPreferenceResponse?.addresses || {}
    let isShippingAddressAvailable =
      checkoutDeliveryAddress &&
      Object.keys(checkoutDeliveryAddress) &&
      Object.keys(checkoutDeliveryAddress)?.length > 0
        ? true
        : defaultAddress
    let isSubscriptionAddressAvailable =
      subscriptionShippingAddress &&
      Object.keys(subscriptionShippingAddress) &&
      Object.keys(subscriptionShippingAddress)?.length > 0
        ? true
        : defaultAddress
    let isBillingAddressAvailable =
      selectedBillingAddress &&
      Object.keys(selectedBillingAddress) &&
      Object.keys(selectedBillingAddress)?.length > 0
        ? true
        : defaultAddress

    let isAddressAvailable = editUpdateModalEnabled
      ? isBillingAddressAvailable
      : isFromCheckout
      ? isShippingAddressAvailable
      : isFromEditSubscription
      ? isSubscriptionAddressAvailable
      : defaultAddress

    const defaultAddressData = addressData?.filter(
      address => address?.isDefault === true
    )
    const selectedAddress = selectedBillingAddress
      ? selectedBillingAddress
      : billingAddressType === 'checkout'
      ? checkoutDeliveryAddress
      : defaultAddressData?.[0]
    let shippingAddress = ''
    if (isAddressAvailable) {
      const {
        addressLine1 = '',
        addressLine2 = '',
        city = '',
        state = '',
        country = '',
        zip = '',
      } = selectedAddress || {}
      shippingAddress = `${addressLine1},${
        addressLine2 ? ` ${addressLine2},` : ''
      } ${city ? ` ${city},` : ''} ${state ? ` ${state},` : ''} ${
        country ? ` ${country},` : ''
      } ${zip ? ` ${zip}` : ''}`
    }

    return [
      editUpdateModalEnabled && isAddressAvailable
        ? [
            {
              name: 'billingSameAsShipping',
              type: 'checkbox',
              customClassName: 'billing-sameAs-shipping',
              inlineLabelKey: 'billingAddressTitle',
              labelKey: 'billingAddressTitle',
              isToHideLabel: true,
              columnType: {
                lg: isPaymentEdit || handleExpressCheckout ? '12' : '8',
              },
              dataTestId: 'qa-billing-address',
              customDataTestId: 'qa-billing-address-field',
              defaultChecked: isFromEditPayment ? true : isAddressAvailable,
              onChange: handleAddressCheckbox,
              disabled: isFromEditPayment ? false : !isAddressAvailable,
            },
            {
              columnType: {
                lg: '12',
              },
              disabled: true,
              isToHideLabel: true,
              RenderProps: shippingAddress,
              customClassName: 'pb-3 shippingAddress-wrapper',
            },
          ]
        : [
            {
              name: 'billingSameAsShipping',
              type: 'checkbox',
              customClassName: 'billing-sameAs-shipping',
              inlineLabelKey: 'billingSameAsDefaultShipping',
              labelKey: 'billingSameAsDefaultShipping',
              isToHideLabel: true,
              customDataTestId: 'qa-billing-address-field',
              columnType: {
                lg: isPaymentEdit || handleExpressCheckout ? '12' : '8',
              },
              dataTestId: 'qa-billing-address',
              defaultChecked:
                handleExpressCheckout && !customerContainer?.isRegisterUser
                  ? true
                  : isFromEditPayment
                  ? true
                  : isAddressAvailable,
              onChange: handleAddressCheckbox,
              disabled:
                handleExpressCheckout && !customerContainer?.isRegisterUser
                  ? false
                  : isFromEditPayment
                  ? false
                  : !isAddressAvailable,
            },
          ],
    ]
  }
  const addPaymentDefaultConfig = {
    name: 'addPaymentToProfile',
    type: 'checkbox',
    customClassName: 'add-paymentTo-profile pb-2',
    inlineLabelKey: 'addPaymentToProfile',
    isToHideLabel: true,
    columnType: {
      lg: '9',
    },
    dataTestId: 'qa-addPaymentTo-profile',
    customDataTestId: 'qa-add-to-payment-field',
    defaultChecked: !isFromPayment ? true : false,
    onValueChange: handleAddPaymentCheckbox,
    disabled: isDefault || isFromEditPayment,
    required:
      (isFromCheckout && hasSubscriptionProduct) || isFromEditSubscription,
    ariaLabel: 'addPaymentToProfile',
  }

  const addressTitle = {
    name: 'billingAddressTitle',
    columnType: {
      lg: '12',
    },
    RenderProps: isFunction(multiAddressRenderProp) && multiAddressRenderProp(),
  }
  if (editUpdateModalEnabled) {
    fieldConfig.push(firstNameField, cardNumberField, expiryField)
  } else if (handleExpressCheckout) {
    fieldConfig.push(firstNameField, cardNumberField, expiryField)
  } else {
    fieldConfig.push(requiredNote, cardNumberField, firstNameField, expiryField)
  }
  if (isFromPayment) {
    fieldConfig.push(addPaymentDefaultConfig, paymentDefaultConfig)
  }
  const shouldRenderCheckBox = isMultipleAdressActive
  if (
    customerContainer.isRegisterUser &&
    (isFromCheckout || isFromEditSubscription)
  ) {
    fieldConfig?.push(addPaymentDefaultConfig)
  }

  isFromEditSubscription &&
    !hideDefaultSubPaymentCheckbox &&
    fieldConfig?.push(defaultSubscriptionPaymentCheckboxField)

  if (
    customerContainer.isRegisterUser &&
    (isFromCheckout || isFromEditSubscription)
  ) {
    fieldConfig?.push(paymentDefaultConfig)
  }
  !shouldRenderCheckBox && fieldConfig.push(...addressDefaultConfig())

  const shouldRenderAddress = isFromEditPayment
    ? shouldRenderAddressForm
    : addressContainer.addressData.length === 0
    ? true
    : shouldRenderAddressForm
  isMultipleAdressActive && fieldConfig.push(addressTitle)

  const countryForm = ({ customClassName = '', addressInfo = {} }) => {
    /**
     * @note
     * NUSKIN-1054 - Change 'Canada' to 'CA' in PostData of Address And Payment Calls
     */
    const countriesOption = countriesJson()
    if (enableLiveEvent === 'true') {
      // Push China in country list
      countriesOption.push({
        label: i18nTranslate('country.china', 'China'),
        value: 'CN',
      })

      countriesOption.push({
        label: i18nTranslate('country.KR', 'South Korea'),
        value: 'KR',
      })

      countriesOption.push({
        label: i18nTranslate('country.TW', 'Taiwan'),
        value: 'TW',
      })

      countriesOption.push({
        label: i18nTranslate('country.AM', 'Armenia'),
        value: 'AM',
      })

      countriesOption.push({
        label: i18nTranslate('country.PR', 'Puerto Rico'),
        value: 'PR',
      })

      countriesOption.push({
        label: i18nTranslate('country.VE', 'Venezuela'),
        value: 'VE',
      })

      countriesOption.push({
        label: i18nTranslate('country.MC', 'Monaco'),
        value: 'MC',
      })

      // Don't sort default value
      countriesOption.sort((a, b) => {
        if (a.value === '') return 0
        if (b.value === '') return 0
        return a.label.localeCompare(b.label)
      })
      // Disable option select form default value
      document.querySelectorAll('.payment-form-country option').forEach(opt => {
        if (opt.value == '') {
          opt.disabled = true
        }
      })
    }
    let country = addressInfo?.country || storeContainer.storeIDValue
    country = country?.toLowerCase() === 'canada' ? 'CA' : country
    // In live event billing address will shouwn by default for new payment
    // Do not hide country list
    const isNewPaymentFomEventCheckout =
      enableLiveEvent === 'true' && isFromCheckout && !isPaymentEdit
    const data = [
      {
        name: 'country',
        ariaLabel: 'country',
        type: 'select',
        options: countriesOption,
        disabled: false,
        columnType: {
          lg: isPaymentEdit || handleExpressCheckout ? '12' : '6',
        },
        customClassName:
          !checkoutContainer.paymentBillingSameAsShippingFlag ||
          isNewPaymentFomEventCheckout
            ? 'payment-form-country'
            : 'payment-form-country d-none',
        defaultValue: country || countriesOption[0],
        dataTestId: 'qa-country',
      },
    ]

    return data
  }

  const billingAddress = paymentInfo?.paymentData?.billingAddress || {}
  const addressInfo = { ...billingAddress }
  const formInput = []

  formInput.push([
    ...countryForm({
      addressInfo,
    }),
  ])
  fieldConfig.push(...formInput)

  return fieldConfig
}

export { renderFormInput }
