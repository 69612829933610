import React from 'react'
import { observer } from 'mobx-react'
import isFunction from 'lodash/isFunction'
import { observable, reaction } from 'mobx'
import { i18nTranslate, getLiveEventStatus, isExpressCheckout } from 'src/utils'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { quickViewState } from 'src/views/components/QuickView/state'
import { Button, Row, Form, Col } from 'react-bootstrap'
import { toastState } from 'src/views/components'
import {
  AiOutlinePlus as PlusIcon,
  AiOutlineMinus as MinusIcon,
} from 'react-icons/ai'
import { customerContainer } from 'src/models'
import { IoIosRemove, IoIosAdd } from 'react-icons/io'
import { APPConfig } from 'config/appConfig'
import './styles.scss'

@observer
class Quantity extends React.Component {
  static defaultProps = {
    minValue: 1,
    step: 1,
    defaultValue: 1,
    maxValue: 99,
    classNameValue: '',
    circularIcons: true,
  }
  @observable count = this.props.defaultValue
  @observable
  shouldIncrement = true
  @observable
  shouldDecrement = false
  @observable
  autoFocus = false
  enableLiveEvent = getLiveEventStatus()
  isEventRegisteredUser = ''

  checkEventRegisteredUser() {
    const profileResponse = customerContainer?.profileResponse
    const accountProperties = profileResponse?.accounts?.properties || []
    const userProperty = accountProperties?.filter(
      property => property?.attributeId === 'registeredEvent'
    )
    return userProperty?.[0]?.value || 'false'
  }

  componentDidUpdate = prevProps => {
    if (prevProps.defaultValue != this.props.defaultValue) {
      this.count = this.props.defaultValue
    }
    this.update()
  }

  update = () => {
    const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99
    const {
      maxValue = maxLimit,
      defaultValue = 1,
      minValue = this.defaultProps.minValue,
    } = this.props
    switch (true) {
      case maxValue <= defaultValue: {
        this.shouldIncrement = false
        this.shouldDecrement = true
        break
      }
      case minValue !== defaultValue: {
        this.shouldIncrement = true
        this.shouldDecrement = true
        break
      }
      default: {
        this.shouldIncrement = true
        this.shouldDecrement = false
        break
      }
    }
  }
  handleIncrement = event => {
    const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99
    const { maxValue = maxLimit, step = 1 } = this.props
    if (this.count < maxValue) {
      this.count += step
      this.shouldDecrement = true
    }
    if (this.count === maxValue) {
      this.shouldIncrement = false
    }
    this.handleChange(event, true)
    this.autoFocus = false
  }
  handleDecrement = event => {
    const {
      minValue = 1,
      step = 1,
      decrementCheck = function () {},
      currentPage = '',
    } = this.props
    if (currentPage === 'cart') {
      if (decrementCheck(this.count, step)) {
        if (this.count > minValue) {
          this.count -= step
          this.shouldIncrement = true
        }
        if (this.count === minValue) {
          this.shouldDecrement = false
        }
        this.handleChange(event, true)
      }
    } else {
      if (this.count > minValue) {
        this.count -= step
        this.shouldIncrement = true
      }
      if (this.count === minValue) {
        this.shouldDecrement = false
      }
      this.handleChange(event, true)
      this.autoFocus = false
    }
  }
  handleChange = (event, isButtonClick = false) => {
    const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99
    const {
      minValue = 1,
      maxValue = maxLimit,
      onQuantityChange = function () {},
      handleQuantityChangePromotion = function () {},
      product = {},
    } = this.props

    let value = !isButtonClick ? event.target.value : undefined
    value = value === '' ? '' : Number(value)
    if (value === '' || (value >= minValue && value <= maxValue)) {
      this.count = value
    }
    if (value === 0) {
      this.count = 1
    }
    if (value > maxValue) {
      this.count = maxValue
      {
        this.enableLiveEvent !== 'true'
          ? toastState.setToastMessage(
              i18nTranslate(
                'cart.maxLimitExceed',
                'Sorry, you have reached the max quantity limit'
              ),
              false
            )
          : toastState.setToastMessage(
              i18nTranslate(
                'cart.eventMaxLimitExceed',
                "Oops! You've reached the maximum quantity allowed for this item."
              ),
              false,
              6000,
              '',
              false,
              true,
              i18nTranslate(
                'cart.eventMaxLimitExceeded',
                'The maximum quantity per item is {quantity}. Please adjust your quantity accordingly. Thank you!'
              ).replace('{quantity}', maxValue),
              'live-event-toast-error'
            )
      }
    }
    this.shouldDecrement = this.count > minValue
    this.shouldIncrement = this.count < maxValue

    onQuantityChange(this.count, response => {
      if (this.props.resetQtyOnFailure && response?.status === 'failure') {
        this.count = this.props.defaultValue
      }
    })
    typeof handleQuantityChangePromotion === 'function' &&
      handleQuantityChangePromotion(product, this.count)
    this.autoFocus = true
  }
  handleBlur = event => {
    const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99
    const { minValue = 1, maxValue = maxLimit } = this.props
    let value = event.target.value
    const prevValue = this.count
    if (value === '' || value <= minValue) {
      value = minValue
      this.count = value
    } else if (value >= maxValue) {
      value = maxValue
      this.count = value
    }
    if (prevValue !== this.count && this.count !== '') {
      /*
      when qty input field is made empty,
      and the focused out of input field(blur),
      we are setting value to 1 ( min value)
      but in pdp, price remains 0, 
      so we have to trigger handlechange on focusout (blur)
      */
      this.handleChange(event, true)
      this.autoFocus = false
    }
  }
  componentDidMount() {
    this.update()
    /**
     * Need to update the count value, when props defaultValue changed
     * Since, componentWillReceiveProps is deprecated
     * used reaction from Mobx
     * @see https://mobx.js.org/refguide/reaction.html
     */
    this.disposer = reaction(
      () => this.props?.defaultValue,
      (value, reaction) => {
        this.count = value
      }
    )
  }
  componentWillUnmount() {
    if (isFunction(this.disposer)) {
      this.disposer()
    }
  }
  handleReviewSectionQuantity() {
    this.shouldIncrement = false
    this.shouldDecrement = false
  }
  render() {
    const {
      classNameValue = '',
      circularIcons = true,
      tabIndex = false,
      isHidden = false,
      disableTabIndex = false,
      isV2QuantityStyle = false,
      isToDisableIcons = false,
    } = this.props
    const minusIconAttributes = {
      className: 'minus-svg-icon',
      'aria-label': i18nTranslate('quantityIcons.minus', 'decrease quantity'),
    }
    const plusIconAttributes = {
      className: 'plus-svg-icon',
      'aria-label': i18nTranslate('quantityIcons.plus', 'increase quantity'),
    }

    const isDisabled =
      !this.shouldDecrement ||
      productState.quantityActionDisable ||
      quickViewState?.quantityActionDisable ||
      disableTabIndex ||
      isToDisableIcons
    const MinusIconComponentName = isV2QuantityStyle
      ? IoIosRemove
      : circularIcons
      ? MinusIcon
      : IoIosRemove
    const PlusIconComponentName = isV2QuantityStyle
      ? IoIosAdd
      : circularIcons
      ? PlusIcon
      : IoIosAdd
    const customClassName = isV2QuantityStyle
      ? `${
          isExpressCheckout()
            ? 'mr-2 align-items-center'
            : 'mr-2 v2-qty-design-quantity'
        }`
      : classNameValue
    const disableQuantityChange =
      !this.shouldIncrement ||
      productState?.quantityActionDisable ||
      quickViewState?.quantityActionDisable ||
      isToDisableIcons
    this.isEventRegisteredUser = this.checkEventRegisteredUser()
    return (
      <Row
        noGutters
        className={`bg-white border border-secondary cart-prdct-qntity-count justify-content-center ${customClassName} quantity`}
        data-testid="qa-quantity-field-wrapper">
        <Col className="d-flex justify-content-center">
          <Button
            className="qty_button minus-btn border-0"
            onClick={this.handleDecrement}
            disabled={
              this.enableLiveEvent === 'true'
                ? isDisabled ||
                  (customerContainer.isRegisterUser &&
                    this.isEventRegisteredUser === 'false')
                : isDisabled
            }
            tabIndex={isHidden || isDisabled ? -1 : 0}
            data-testid="qa-quantity-minus">
            {<MinusIconComponentName {...minusIconAttributes} />}
          </Button>
        </Col>
        <Col>
          {!productState?.quantityActionDisable ||
          !quickViewState.quantityActionDisable ||
          !isToDisableIcons ? (
            <Form.Control
              className="p-0 text-center quantity-value"
              type="text"
              value={this.count}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              autoFocus={this.autoFocus}
              tabIndex={disableTabIndex ? -1 : 0}
              data-testid="qa-product-quantity"
              aria-label={i18nTranslate('input.quantityTextbox', 'quantity')}
            />
          ) : (
            <Row
              className="quantity-value m-0 p-0 justify-content-center h-100 align-items-center"
              data-testid="qa-product-quantity">
              {this.count}
            </Row>
          )}
        </Col>
        <Col className="d-flex justify-content-center border-0">
          <Button
            className="qty_button plus-btn"
            onClick={this.handleIncrement}
            tabIndex={disableTabIndex ? -1 : 0}
            disabled={
              this.enableLiveEvent === 'true'
                ? disableQuantityChange ||
                  (customerContainer.isRegisterUser &&
                    this.isEventRegisteredUser === 'false')
                : disableQuantityChange
            }
            data-testid="qa-quantity-plus">
            {<PlusIconComponentName {...plusIconAttributes} />}
          </Button>
        </Col>
      </Row>
    )
  }
}
export { Quantity }
export default Quantity
