import React from 'react'
import { i18nTranslate } from 'src/utils'
import i18n from 'i18next'
import { observer } from 'mobx-react'
import {
  VscClose as CloseIcon,
  VscChevronRight,
  VscChevronLeft,
} from 'react-icons/vsc'
import { Link } from 'react-router-dom'
import { Col, Image, ListGroup, Row } from 'react-bootstrap'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { getCategoryLinks } from './deps'
import { mobileMenuState } from 'src/views/components'
import SubMenuAccordian from './SubMenuAccordian'
import { staticDropDownLinks } from '../staticDropDownLinks'
import { PageLink } from 'src/views/components/PageLink'

class CategoryItem extends React.Component {
  handleClick = () => {
    const { index, currentCategoryPath } = this.props
    currentCategoryPath.push('subCategories', index.toString())
  }

  render() {
    const { category } = this.props
    const categoryName = category?.properties?.name || ''
    const ForwardIcon = i18n.dir() === 'rtl' ? VscChevronLeft : VscChevronRight
    return (
      <ListGroup.Item
        key={categoryName}
        className="nu-menu-item"
        onClick={this.handleClick}>
        <a href="javascript:void(0)" role="button">
          {categoryName}
        </a>
        <ForwardIcon size="24px" className="ml-auto arrow-icon" />
      </ListGroup.Item>
    )
  }
}

class BrowseLink extends React.Component {
  nameSpace = 'ssr-resource'
  hardcodedCategoryLinks = {
    sustainability:
      'https://www.nuskin.com/content/globalffg/sustainability/home.html',
    socialimpact: 'https://www.nuskin.com/content/globalffg/home.html',
  }
  handleClick = () => {
    this.props.handleLinkClick()
  }

  render() {
    const { category } = this.props
    const {
      name: categoryName = '',
      description = '',
      meta_description = '',
      imageURL = '',
    } = category?.properties || {}

    const categoryDescription =
      description ||
      meta_description ||
      i18nTranslate(
        'menu.description',
        'Description not available for this product/category.',
        { nameSpace: this.nameSpace }
      )

    const isThirdPartyPage =
      category?.isFromOurImpact ||
      this.hardcodedCategoryLinks[category?.identifier]

    const subCategoryJSX = isThirdPartyPage ? (
      <a
        href={
          category?.properties?.href ||
          this.hardcodedCategoryLinks[category?.identifier]
        }
        target="_blank"
        rel="noopener noreferrer"
        key={categoryName}>
        {categoryName}
      </a>
    ) : (
      <Link
        to={getCategoryLinks(category)}
        onClick={this.handleClick}
        key={categoryName}>
        {categoryName}
      </Link>
    )

    return imageURL && categoryDescription && category?.parentId !== 'top' ? (
      <Row className="m-2">
        <Col xs={12} className="text-center">
          <Image alt={name} src={imageURL} height="200px" width="auto"></Image>
        </Col>
        <Col xs={12} className="text-left mt-3">
          <b>{subCategoryJSX}</b>
        </Col>
        <Col xs={12} className="text-left mb-3 nu-menu-subcategory-desc">
          <span
            className={'category-description-html'}
            dangerouslySetInnerHTML={{ __html: categoryDescription }}></span>
        </Col>
      </Row>
    ) : (
      <ListGroup.Item className="nu-browse-link nu-menu-item">
        {subCategoryJSX}
      </ListGroup.Item>
    )
  }
}

class SubCategoryHeader extends React.Component {
  handleClick = () => {
    const { currentCategoryPath } = this.props
    currentCategoryPath.splice(-2, 2)
  }
  render() {
    const { category, currentCategoryPath, handleLinkClick } = this.props
    const { imageURL, name } = category.properties

    const BackIcon = i18n.dir() === 'rtl' ? VscChevronRight : VscChevronLeft
    return (
      <div
        className="subcategory-header list-group-item nu-menu-subheading text-uppercase d-flex justify-content-between"
        onClick={this.handleClick}
        role="button">
        <div>
          <BackIcon size="24px" className="mr-2" />
          {name}
        </div>
        <CloseIcon size="24px" className="ml-1" />
      </div>
    )
  }
}

@observer
class MobileMenuCategories extends React.Component {
  handleLinkClick = () => {
    this.props?.currentCategoryPath.splice(1)
    mobileMenuState.toggleMenuVisibility()
  }

  renderStaticDropDownLinks = response => {
    const lists = staticDropDownLinks() || []
    let identifier = ''
    if (response?.identifier?.length > 0) {
      identifier = response?.identifier?.replaceAll('_', '')
    }
    let list = lists?.filter(list => list.parentId === identifier)
    if (list.length > 0) {
      return list?.map(item => {
        return (
          <div className="static-links ml-3" key={item?.label}>
            <PageLink
              linkInfo={item?.linkInfo}
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer">
              <span data-testid="qa-category">{item?.label}</span>
            </PageLink>
          </div>
        )
      })
    }
  }

  render() {
    const { categoryResponse, currentCategoryPath } = this.props
    // const response = categoryResponse?.currentCategoryPath || {}

    /**
     * @todo
     * !!! need to find alternate way to fix this
     * !!! as this costly process
     */
    const response = get(categoryResponse, currentCategoryPath, {})
    const categories = response?.subCategories || []

    const { name = '' } = response?.properties || {}
    return (
      <>
        {currentCategoryPath.length > 1 && (
          <SubCategoryHeader
            category={response}
            currentCategoryPath={currentCategoryPath}
            handleLinkClick={this.handleLinkClick}
            {...this.props}
          />
        )}
        <div className="submenu-accordians">
          {isArray(categories) && currentCategoryPath?.length === 1 && (
            <ListGroup>
              {categories.map((category, index) =>
                isArray(category.subCategories) ? (
                  <CategoryItem
                    category={category}
                    index={index}
                    key={`category-${index}`}
                    currentCategoryPath={currentCategoryPath}
                  />
                ) : (
                  <BrowseLink
                    key={`browse-link-${index}`}
                    category={category}
                    handleLinkClick={this.handleLinkClick}
                  />
                )
              )}
            </ListGroup>
          )}
          {isArray(categories) && currentCategoryPath?.length > 1 && (
            <SubMenuAccordian
              categories={categories}
              handleLinkClick={this.handleLinkClick}
            />
          )}
          <div className="d-flex flex-column mt-3">
            {this.renderStaticDropDownLinks(response)}
          </div>
        </div>
      </>
    )
  }
}

export { MobileMenuCategories, BrowseLink }
