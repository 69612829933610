import { observable, action } from 'mobx'
import sortBy from 'lodash/sortBy'
import { CommonContainer } from 'src/models/Common'

/**
 * MerchProductContainer class that extends CommonContainer.
 * Used to contain state and actions related to merch products.
 */
class MerchProductContainer extends CommonContainer {
  @observable facetPositions = []
  productPositions = []

  /**
   * Handles saving action for merch product positions.
   * Sorts product positions by position value.
   * Constructs saveData object with facet positions from container
   * and sorted product positions.
   * Returns saveData object to be sent to API.
   */
  handleSaveAction = () => {
    this.productPositions = sortBy(this.productPositions, [
      product => {
        return product.position
      },
    ])
    const saveData = {
      id: 'save',
      uxData: {
        facetPositions: this.getFacetPositions(),
        positions: this.productPositions,
      },
    }
    return saveData
  }

  @action
  /**
   * Sets the facet positions based on the given filter list.
   * Maps over the filter list to extract the facet ID and
   * visibility into objects set to the facetPositions array.
   */
  setFacetPositions = filterList => {
    this.facetPositions = filterList.map(item => {
      return {
        facetId: item.name,
        visible: item.isVisible,
      }
    })
  }

  /**
   * Gets the facet positions from the container.
   * Returns the facetPositions array.
   */
  getFacetPositions = filterList => {
    return this.facetPositions
  }
}
const merchProductContainer = new MerchProductContainer()
export default merchProductContainer
export { merchProductContainer }
