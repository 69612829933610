import isArray from 'lodash/isArray'
import { IS_BROWSER } from 'src/utils/application'

/**
 * Converts a breadcrumb item to schema.org BreadcrumbList schema.
 *
 * @param {Object} item - The breadcrumb item.
 * @param {number} index - The index of the breadcrumb item.
 * @param {Object} location - The location object with domainName.
 * @returns {Object|undefined} - The schema.org BreadcrumbList ListItem, or undefined if the label/value are empty.
 */
function toBreadCrumbItemSchema(item, index, location) {
  const { label, value } = item
  if (label != '' && value != '') {
    const BreadCrumbItemSchema = {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        id: getDomainName(value, location),
        name: label,
      },
    }
    return BreadCrumbItemSchema
  }
}

/**
 * Converts a URL value to include the domain name from the location object, if not already a full URL.
 * This handles cases where values are relative links without the origin.
 *
 * @param {string} value - The URL value to convert
 * @param {Object} location - The location object with domainName
 * @returns {string} The URL value converted to include the domain name if needed
 */
function getDomainName(value, location) {
  if (!value.includes('https://')) {
    const domainName =
      location && location.domainName
        ? location.domainName
        : IS_BROWSER
        ? window.location.origin
        : ''
    return `${domainName}${value}`
  }
  return value
}

/**
 * Converts a breadcrumb list to schema.org BreadcrumbList schema.
 *
 * @param {Array} list - The breadcrumb items array
 * @param {Object} location - The location object with domainName
 * @returns {Object} The schema.org BreadcrumbList object
 */
function breadcrumbSchema(list, location) {
  const listElement = []

  if (isArray(list)) {
    list.forEach((item, idx) => {
      const breadCrumbItem = toBreadCrumbItemSchema(item, idx, location)
      if (breadCrumbItem) {
        listElement.push(breadCrumbItem)
      }
    })
  }

  const breadCrumbData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: listElement,
  }
  return breadCrumbData
}
export { breadcrumbSchema }
