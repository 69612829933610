import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { enableWishList } from 'config/appConfig'
import { MoveToListDropDown } from 'src/views/components'

@observer
class ProductAddToList extends Component {
  render() {
    const {
      productCount,
      product = {},
      className = '',
      disabled = false,
    } = this.props
    const { productId, skuId, name, imageURL, productType, slug, categoryId } =
      product

    const listItemProps = {
      productId,
      skuId,
      categoryId,
      quantity: productCount,
      name,
      imageURL,
      slug,
      type: productType,
    }

    if (!enableWishList) {
      return <></>
    }

    return (
      <MoveToListDropDown
        className={`${className ? className : 'move-to-list'} `}
        listItemProps={listItemProps}
        disabled={disabled}
        product={
          Object.keys(product) && Object.keys(product).length
            ? product
            : this.props
        }
      />
    )
  }
}

export default ProductAddToList
export { ProductAddToList }
