import React from 'react'
import { observer } from 'mobx-react'
// import { quickViewState } from 'src/views/components/QuickView/state'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { customerContainer, promotionContainer } from 'src/models'
import { ApiContent, IS_BROWSER } from 'src/utils'
import { currencyFormat, i18nTranslate, getLiveEventStatus } from 'src/utils'
import { productDeps } from 'src/deps'
import { APPConfig, convertToBoolean } from 'config/appConfig'

import './styles.scss'

@observer
class PDPUnitPrice extends React.Component {
  static contextType = ApiContent
  constructor(props, context) {
    super(props, context)
  }
  getAccountType = () => {
    if (IS_BROWSER) {
      return customerContainer.profileResponse?.accounts?.type || ''
    }
    return this?.context?.customerType
  }

  isBAorPreferred = () => {
    const accountType = this.getAccountType()
    return (
      accountType === 'Brand Affiliate' ||
      accountType === 'Brand Affiliate - Business Entity' ||
      accountType === 'Preferred Customer/Member' ||
      false
    )
  }

  getPriceWithCurrency = (priceValue = 0) => {
    const formattedPrice = currencyFormat({
      price: priceValue,
    })
    return <span className="v2-price-value">{formattedPrice}</span>
  }

  renderSalePriceLabel = () => {
    return (
      <div className="sale-title-label">
        {i18nTranslate('pdp.salePrice', 'Sale Price', {
          nameSpace: 'ssr-resource',
        })}
      </div>
    )
  }

  renderWholesalePriceLabel = () => {
    return (
      <div className="wholesale-title-label">
        {i18nTranslate('pdp.wholesalePrice', 'Wholesale Price', {
          nameSpace: 'ssr-resource',
        })}
      </div>
    )
  }

  renderRetailPriceLabel = () => {
    return (
      <span className="retail-title-label pr-1">
        {i18nTranslate('pdp.retailPrice', 'Retail Price', {
          nameSpace: 'ssr-resource',
        })}
      </span>
    )
  }

  renderWholesalePriceAfterDiscount = unitValue => {
    let salePrice = 0
    if (productState.currentlySelectedPriceOption === 'one-time-price-button') {
      salePrice =
        unitValue?.priceFacets?.['Wholesale Price']?.[
          'Wholesale PriceAfterDiscount'
        ] || unitValue?.priceFacets?.['Wholesale Price']?.['Wholesale Price']
    } else {
      salePrice =
        unitValue?.priceFacets?.['Sub Wholesale Price']?.[
          'Sub Wholesale PriceAfterDiscount'
        ] ||
        unitValue?.priceFacets?.['Sub Wholesale Price']?.['Sub Wholesale Price']
    }
    return this.getPriceWithCurrency(salePrice)
  }

  renderWholesalePrice = unitValue => {
    let salePrice = 0
    if (productState.currentlySelectedPriceOption === 'one-time-price-button') {
      salePrice =
        unitValue?.priceFacets?.['Wholesale Price']?.['Wholesale Price']
    } else {
      salePrice =
        unitValue?.priceFacets?.['Sub Wholesale Price']?.['Sub Wholesale Price']
    }
    return this.getPriceWithCurrency(salePrice)
  }

  renderRetailPriceAfterDiscount = unitValue => {
    const retailPrice =
      productState.currentlySelectedPriceOption === 'one-time-price-button'
        ? unitValue?.priceFacets?.['Regular Price']?.[
            'Regular PriceAfterDiscount'
          ] || unitValue?.priceFacets?.['Regular Price']?.['Regular Price']
        : unitValue?.priceFacets?.['Sub Retail Price']?.[
            'Sub Retail PriceAfterDiscount'
          ] ||
          unitValue?.priceFacets?.['Sub Retail Price']?.['Sub Retail Price']

    return this.getPriceWithCurrency(retailPrice)
  }

  renderRetailPrice = unitValue => {
    const retailPrice =
      productState.currentlySelectedPriceOption === 'one-time-price-button'
        ? unitValue?.priceFacets?.['Regular Price']?.['Regular Price']
        : unitValue?.priceFacets?.['Sub Retail Price']?.['Sub Retail Price']

    return this.getPriceWithCurrency(retailPrice)
  }

  checkAndRenderPriceV2 = unitValue => {
    const isBAorPreferred = this.isBAorPreferred()
    const isBundleOrKit =
      this.props?.product?.type === 'bundle' ||
      this.props?.product?.type === 'kit'
    const wholesaleDiscount =
      productState.currentlySelectedPriceOption === 'one-time-price-button'
        ? unitValue?.priceFacets?.['Wholesale Price']?.[
            'Wholesale PriceDiscount'
          ]
        : unitValue?.priceFacets?.['Sub Wholesale Price']?.[
            'Sub Wholesale PriceDiscount'
          ]
    const retailDiscount =
      productState.currentlySelectedPriceOption === 'one-time-price-button'
        ? unitValue?.priceFacets?.['Regular Price']?.['Regular PriceDiscount']
        : unitValue?.priceFacets?.['Sub Retail Price']?.[
            'Sub Retail PriceDiscount'
          ]
    const bundleOnlyDiscountWholesale =
      productState.currentlySelectedPriceOption === 'one-time-price-button'
        ? unitValue?.priceFacets?.['Wholesale Price']?.['bundleDiscount']
        : unitValue?.priceFacets?.['Sub Wholesale Price']?.['bundleDiscount']
    const bundleOnlyDiscountRetail =
      productState.currentlySelectedPriceOption === 'one-time-price-button'
        ? unitValue?.priceFacets?.['Regular Price']?.['bundleDiscount']
        : unitValue?.priceFacets?.['Sub Retail Price']?.['bundleDiscount']
    /* based on https://nuskin.atlassian.net/browse/BRW-3534
     * we are hiding retail price with label for bundle & kits
     */
    const enableBundleKitRetailPriceInNewPDP =
      APPConfig?.getAppConfig()?.enableBundleKitRetailPriceInNewPDP === 'true'

    const enableLiveEvent = convertToBoolean(getLiveEventStatus())
    if (isBundleOrKit) {
      if (isBAorPreferred) {
        if (
          wholesaleDiscount &&
          wholesaleDiscount > 0 &&
          bundleOnlyDiscountWholesale &&
          bundleOnlyDiscountWholesale > 0
        ) {
          /* bundle kit - BA -both sale & bundle discount */
          return (
            <div
              className="d-flex"
              priceType="bundle kit - BA -both sale & bundle discount">
              <div>
                {this.renderSalePriceLabel()}
                {this.renderWholesalePriceAfterDiscount(unitValue)}
              </div>
              <div className="pl-3">
                <div className="strike-price-value">
                  {!enableLiveEvent && this.renderWholesalePriceLabel()}
                  {this.renderWholesalePrice(unitValue)}
                </div>
                {enableBundleKitRetailPriceInNewPDP && !enableLiveEvent && (
                  <div className="retail-price-with-label">
                    {this.renderRetailPriceLabel()}
                    {this.renderRetailPriceAfterDiscount(unitValue)}
                  </div>
                )}
              </div>
            </div>
          )
        } else {
          /* bundle kit - BA - only saleDiscount or no discount */
          return (
            <div
              className="d-flex"
              priceType="bundle kit - BA - only saleDiscount or no discount">
              <div>
                <div>
                  {!enableLiveEvent && this.renderWholesalePriceLabel()}
                  {this.renderWholesalePriceAfterDiscount(unitValue)}
                </div>
                {enableBundleKitRetailPriceInNewPDP && !enableLiveEvent && (
                  <div className="retail-price-with-label">
                    {this.renderRetailPriceLabel()}
                    {this.renderRetailPriceAfterDiscount(unitValue)}
                  </div>
                )}
              </div>
            </div>
          )
        }
      } else {
        if (
          retailDiscount &&
          retailDiscount > 0 &&
          bundleOnlyDiscountRetail &&
          bundleOnlyDiscountRetail > 0 &&
          retailDiscount > bundleOnlyDiscountRetail
        ) {
          /* bundle kit - NON-BA - both sale & bundle discount */
          return (
            <div
              className="d-flex"
              priceType="bundle kit - NON-BA - both sale & bundle discount">
              <div>
                <div>
                  {this.renderSalePriceLabel()}
                  {this.renderRetailPriceAfterDiscount(unitValue)}
                </div>
                {!enableLiveEvent && (
                  <div className="retail-price-with-label">
                    {this.renderRetailPriceLabel()}
                    {this.renderRetailPrice(unitValue)}
                  </div>
                )}
              </div>
            </div>
          )
        } else {
          /* bundle kit - NON-BA - only saleDiscount or no discount */

          return (
            <div
              className="d-flex original-price-value-no-discount"
              priceType="bundle kit - NON-BA - only saleDiscount or no discount">
              <div>{this.renderRetailPriceAfterDiscount(unitValue)}</div>
            </div>
          )
        }
      }
    } else {
      /* for individual products*/
      if (isBAorPreferred) {
        if (wholesaleDiscount && wholesaleDiscount > 0) {
          /* individual - BA - sale discount */
          return (
            <div className="d-flex" priceType="individual - BA - sale discount">
              <div>
                {this.renderSalePriceLabel()}
                {this.renderWholesalePriceAfterDiscount(unitValue)}
              </div>
              <div className="pl-3">
                <div className="strike-price-value">
                  {!enableLiveEvent && this.renderWholesalePriceLabel()}
                  {this.renderWholesalePrice(unitValue)}
                </div>
                {!enableLiveEvent && (
                  <div className="retail-price-with-label">
                    {this.renderRetailPriceLabel()}
                    {this.renderRetailPriceAfterDiscount(unitValue)}
                  </div>
                )}
              </div>
            </div>
          )
        } else {
          /* individual - BA - no discount */
          return (
            <div className="d-flex" priceType="individual - BA - no discount">
              <div>
                <div>
                  {!enableLiveEvent && this.renderWholesalePriceLabel()}
                  {this.renderWholesalePriceAfterDiscount(unitValue)}
                </div>
                {!enableLiveEvent && (
                  <div className="retail-price-with-label">
                    {this.renderRetailPriceLabel()}
                    {this.renderRetailPriceAfterDiscount(unitValue)}
                  </div>
                )}
              </div>
            </div>
          )
        }
      } else {
        if (retailDiscount && retailDiscount > 0) {
          /* individual - NON-BA - sale discount */

          return (
            <div
              className="d-flex"
              priceType="individual - NON-BA - sale discount">
              <div>
                <div>
                  {this.renderSalePriceLabel()}
                  {this.renderRetailPriceAfterDiscount(unitValue)}
                </div>
                {!enableLiveEvent && (
                  <div className="retail-price-with-label">
                    {this.renderRetailPriceLabel()}
                    {this.renderRetailPrice(unitValue)}
                  </div>
                )}
              </div>
            </div>
          )
        } else {
          /* individual - NON-BA - no discount */
          return (
            <div
              className="d-flex original-price-value-no-discount"
              priceType="individual - NON-BA - no discount">
              <div>{this.renderRetailPrice(unitValue)}</div>
            </div>
          )
        }
      }
    }
  }
  render() {
    const unitValue = productDeps.getUnitValue(this.props)
    return (
      <div className="unit-price-with-label-wrapper">
        {this.checkAndRenderPriceV2(unitValue)}
      </div>
    )
  }
}

export { PDPUnitPrice }
export default PDPUnitPrice
