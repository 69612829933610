import React from 'react'
import { observer } from 'mobx-react'
import { klarnaOverlayState } from './state'
//import { Loader } from '../Loader'
import Timer from './Timer'
import { checkoutContainer, klarnaContainer } from 'src/models'
import { APPConfig, convertToBoolean } from 'config/appConfig'
import PaymentIcon from '../PaymentIcon'
import './styles.scss'

@observer
class KlarnaOverlay extends React.Component {
  onCancel = () => {
    klarnaContainer.handleKlarnaPaymentWindowCancel()
  }

  onContinue = () => {
    try {
      const klarnaWindow = klarnaContainer.klarnaWindowURL

      if (typeof klarnaWindow === 'object' && klarnaWindow) {
        if (klarnaWindow?.closed) {
          klarnaContainer.createOrAppendKlarnaPaymentWindow()
        } else {
          klarnaWindow && klarnaWindow?.focus?.()
        }
      } else {
        klarnaContainer.createOrAppendKlarnaPaymentWindow()
      }
    } catch (error) {
      trackErrorInInstana({
        errorReport: 'Klarna - OnContinue Payment Window creation failed',
        errorData: error,
      })
    }
  }

  klarnaOverlay = () => {
    const { position = 'absolute' } = this.props
    return (
      <>
        <div className="klarna-payment-loader">
          <div className={`w-100 h-100 position-${position} modal-overlay`} />
          <div className="position-fixed overlay-loader d-flex flex-column align-items-center">
            <PaymentIcon type="klarna-checkout" />
            <Timer onCancel={this.onCancel} />
            {/* <Loader /> */}

            <br />
            <br />
            <div className="klarna-payment-loader-text text-white">
              <span>Initiating Klarna Payment</span>
              <span className="pt-5">
                Don’t see the secure Klarna browser? We’ll help you re-launch
                the window to complete your purchase
              </span>
            </div>
            <div className="klarna-payment-loader-btn">
              <button
                className="klarna-continue-btn btn text-white"
                onClick={() => this.onContinue()}>
                Click to Continue
              </button>
              <button
                className="klarna-cancel-btn btn text-white"
                onClick={() => this.onCancel()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
  render() {
    const { textToShow, position = 'absolute' } = this.props

    return <>{klarnaOverlayState.isKlarnaLoading && this.klarnaOverlay()}</>
  }
}

export { KlarnaOverlay }
export default KlarnaOverlay
