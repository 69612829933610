import { i18nTranslate } from 'src/utils'

const subscriptionType = () => {
  return [
    {
      label: i18nTranslate('subscription.monthly', 'Monthly'),
      value: '1-MONTHS',
      isMonthly: true,
    },
    {
      label: i18nTranslate('subscription.twoMonth', 'Every 2 Months'),
      value: '2-MONTHS',
    },
    {
      label: i18nTranslate('subscription.threeMonth', 'Every 3 Months'),
      value: '3-MONTHS',
    },
    {
      label: i18nTranslate('subscription.oneTime', 'One-time'),
      value: '1-MONTHS',
      isOneTime: true,
    },
  ]
}

export { subscriptionType }
