import React from 'react'
import { i18nTranslate } from 'src/utils'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import {
  cartContainer,
  customerContainer,
  nuskinSubscriptionContainer,
} from 'src/models'
import {
  modifyAndGetCustomerType,
  modifyAndGetAccountType,
  checkCustomerTypeEligibility,
} from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import { productDeps } from 'src/deps'

@observer
class SubscriptionProductButton extends React.Component {
  shouldDisableSubscription = false
  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    nuskinSubscriptionContainer.isToDisableButton(this.props)
    nuskinSubscriptionContainer.displaySubscribeAndSavePrice =
      this.shouldDisableSubscription
  }
  componentDidUpdate() {
    nuskinSubscriptionContainer.isToDisableButton(this.props)
    nuskinSubscriptionContainer.displaySubscribeAndSavePrice =
      this.shouldDisableSubscription
  }
  subscriptionDisable() {
    const selectedSku = productDeps.getUserSelectedSku(this.props.product)

    let dangerousGoods
    let isPreviewItem = false
    let isReplacementItem = false
    if (this.props.isSkuBundleOnly) {
      return this.props.isSkuBundleOnly
    }

    if (!this.props?.isBundle) {
      isPreviewItem =
        selectedSku?.properties?.productStatus?.toLowerCase?.() === 'preview'
      isReplacementItem =
        selectedSku?.properties?.productStatus?.toLowerCase?.() ===
        'replacement'

      dangerousGoods =
        selectedSku?.properties?.dangerousGoods === 'true' ||
        this?.props?.product?.properties?.dangerousGoods === 'true'
      const isSubscriptionAvail =
        selectedSku?.properties?.availableChannels?.includes('subscription')
      if (
        dangerousGoods ||
        isPreviewItem ||
        !isSubscriptionAvail ||
        isReplacementItem
      ) {
        return true
      }
    } else if (this.props?.disableSubscriptionButton) {
      return true
    }

    const isExclusive = selectedSku?.properties?.isExclusive || ''

    const isQualificationEligible =
      this.props?.isQuickview == 'true'
        ? this.props?.isQualificationEligble
        : this.props?.properties?.isQualificationEligble || ''

    const isToDisableSubscription =
      this.getSkuEligible() &&
      !nuskinSubscriptionContainer.isUserSubscribedBefore()

    // If the item is already not eligible to purchase then we can cut the isExclusive check
    if (isToDisableSubscription) {
      return isToDisableSubscription
    } else if (isExclusive == 'true') {
      if (
        isExclusive == 'true' &&
        isQualificationEligible == 'true' &&
        customerContainer.isRegisterUser
      ) {
        return false
      } else if (
        isExclusive == 'true' &&
        (isQualificationEligible == 'false' || '' || 'undefined') &&
        customerContainer.isRegisterUser
      ) {
        return true
      } else if (isExclusive == 'true' && !customerContainer.isRegisterUser) {
        return true
      }
    }

    return false
  }

  getSkuEligible = () => {
    const productCount = this.props?.productCount
    let arr = []
    this.props?.product?.skuList?.forEach(element => {
      if (
        element?.properties?.variantLabel ===
        this.props?.product?.skuSelected?.variantLabel
      ) {
        arr.push(element)
      }
    })
    const inventory =
      this.props?.product?.inventories ||
      (arr?.length > 0 && arr[0]?.inventoryProperties) ||
      {}
    const { product = '' } = this.props
    let atpQty = inventory?.atpQty
    let outOfStockThreshold = inventory?.outOfStockThreshold
    const status = product.status
    let backOrderedQty = inventory?.backOrderedQty
    const selectedSku = productDeps.getUserSelectedSku(this.props)
    const inventoryStatus = selectedSku?.inventory || ''
    if (
      (atpQty <= 0 && backOrderedQty <= 0) ||
      status === 'inactive' ||
      (inventoryStatus?.toLowerCase() === 'out of stock' &&
        !nuskinSubscriptionContainer.isUserSubscribedBefore()) ||
      inventoryStatus?.toLowerCase() === 'unavailable'
    ) {
      return true
    } else {
      // TODO: negative values to be checked and fixed here
      //example: when atp is 0, when outOfStockThreshold is 2
      if (productCount > atpQty - outOfStockThreshold) {
        let currentDateTimeStamp = Date.now()
        // as per https://nuskin.atlassian.net/browse/INP-1
        // we have remove date related logic for subscription button enabling

        // let backorderTime = ''
        // if (
        //   inventory?.expectedBackOrderAvailabilityDate.toString().length <= 10
        // ) {
        //   backorderTime = inventory?.expectedBackOrderAvailabilityDate + '000'
        // } else {
        //   backorderTime = inventory?.expectedBackOrderAvailabilityDate
        // }
        // backorderTime = Number(backorderTime)

        // @##backorder_logic_pdp_message_or_button

        if (backOrderedQty > 0) {
          let expectedbackorder = productCount - (atpQty - outOfStockThreshold)
          if (expectedbackorder <= backOrderedQty) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return false
      }
    }
  }

  checkAndDisableSubscriptionButton = (
    checkForBackOrderArray = [],
    status = '',
    isUserSubscribedBefore = false,
    isAlreadySubscribedProduct = false
  ) => {
    // @##pdpbutton and message
    const customerTypeResult =
      modifyAndGetCustomerType(this.props?.product?.customerTypes) || ''
    let accountTypeResult =
      modifyAndGetAccountType(
        customerContainer?.profileResponse?.accounts?.accountType
      ) || ''
    const isCustomerEligible = checkCustomerTypeEligibility(
      customerTypeResult,
      accountTypeResult
    )
    const productInventoryDetail = this.props?.isBundle
      ? this.props?.inventoryStatus
      : this.props?.product?.inventory
    const productInventory = this.props?.isBundle
      ? productInventoryDetail?.find(
          inventory => inventory?.toLowerCase() === 'out of stock'
        )
      : productInventoryDetail
    let productInventoryStatusError =
      productInventory === 'OUT OF STOCK' && isUserSubscribedBefore
    const productStatus =
      (this.props.isBundle
        ? this.props.product.properties?.productStatus?.toLowerCase()
        : this.props.product.productStatus?.toLowerCase()) || ''
    // enable subscription button for "MANAGE SUBSCRIPTION" button
    // so if isAlreadySubscribedProduct is true, then  this.shouldDisableSubscription = false
    this.shouldDisableSubscription = isAlreadySubscribedProduct
      ? false
      : status?.toLowerCase() === 'inactive' ||
        productStatus?.toLowerCase() === 'discontinued' ||
        nuskinSubscriptionContainer.isToDisableSubscriptionButton ||
        nuskinSubscriptionContainer.isViewCartCallInProgress ||
        (checkForBackOrderArray?.length > 0 &&
          (checkForBackOrderArray?.[0]?.inventory?.toLowerCase() ===
            'unavailable' ||
            (checkForBackOrderArray?.[0]?.inventory === 'OUT OF STOCK' &&
              !isUserSubscribedBefore))) ||
        !isCustomerEligible ||
        this.subscriptionDisable()

    return this.shouldDisableSubscription
  }

  render() {
    const {
      openSingleSubscribeProductModal = () => {},
      disableSubscriptionButton = false,
    } = this.props || {}
    let isAlreadySubscribedProduct =
      nuskinSubscriptionContainer.isAlreadySubscribedProduct(this.props)
    let isUserSubscribedBefore =
      nuskinSubscriptionContainer.isUserSubscribedBefore()
    let buttonLabel = nuskinSubscriptionContainer.getSubscribeButtonLabel(
      this.props
    )
    let status =
      (this.props.isBundle
        ? this.props.product?.properties?.status
        : this.props.product?.status) || ''

    const getPrimaryButton = (buttonProps = {}) => {
      return (
        <Button
          className="btn-subscribe w-100 btn-outline-primary d-flex justify-content-center align-items-center pdp-subscribe-btn"
          data-testid="qa-subscribe"
          {...buttonProps}>
          <span>
            {isAlreadySubscribedProduct
              ? i18nTranslate(
                  'pdp.manageSubscription',
                  'Manage My Subscription',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )
              : buttonLabel}
          </span>
        </Button>
      )
    }

    const checkForBackOrderArray = []
    this.props?.product?.skuList?.forEach(element => {
      if (
        element?.properties?.variantLabel ===
        this.props?.product?.skuSelected?.variantLabel
      ) {
        checkForBackOrderArray.push(element)
      }
    })

    cartContainer.isDisableSubscribeBtn =
      this.checkAndDisableSubscriptionButton(
        checkForBackOrderArray,
        status,
        isUserSubscribedBefore,
        isAlreadySubscribedProduct
      )

    return (
      <>
        {isAlreadySubscribedProduct && (
          <Link
            to={pageNames.myaccountsubscriptions + '?month=showall'}
            className="w-100 d-flex text-uppercase subscribe-btn-link">
            <div className="subscribe-btn">
              {getPrimaryButton({
                disabled: this.checkAndDisableSubscriptionButton(
                  checkForBackOrderArray,
                  status,
                  isUserSubscribedBefore,
                  isAlreadySubscribedProduct
                ),
              })}
            </div>
          </Link>
        )}
        {!isAlreadySubscribedProduct &&
          getPrimaryButton({
            onClick: openSingleSubscribeProductModal,
            disabled: this.checkAndDisableSubscriptionButton(
              checkForBackOrderArray,
              status,
              isUserSubscribedBefore,
              isAlreadySubscribedProduct
            ),
          })}
      </>
    )
  }
}

export { SubscriptionProductButton }
export default SubscriptionProductButton
