import { currencyFormat } from 'src/utils'

const formatPrice = props => {
  // const { transactionPrice, currencyData } = props
  const { transactionPrice, totalValue = {} } = props
  // #BundlePriceLogic
  if (props.price) {
    return currencyFormat({ price: props.price })
  }
  if (!transactionPrice) {
    return {}
  }
  const { price, maxPrice, minPrice, regularPrice } = transactionPrice
  const { originalPrice, priceAfterDiscount } = totalValue
  const constructedPrice = {}

  const minPriceWithCurrency = currencyFormat({
    // currency: currencyData,
    price: minPrice,
  })
  const maxPriceWithCurrency = currencyFormat({
    // currency: currencyData,
    price: maxPrice,
  })

  const salePriceWithCurrency = currencyFormat({
    // currency: currencyData,
    price,
  })
  const regularPriceWithCurrency = currencyFormat({
    // currency: currencyData,
    price: regularPrice,
  })

  if (price !== regularPrice && regularPrice > 0 && price > 0) {
    constructedPrice.regularPrice = regularPriceWithCurrency

    constructedPrice.hasBothPriceAvail = true
  }

  if (price > 0) {
    constructedPrice.salePrice = salePriceWithCurrency
  }
  if (minPrice > 0) {
    constructedPrice.minPrice = minPriceWithCurrency
  }
  if (maxPrice > 0) {
    constructedPrice.maxPrice = maxPriceWithCurrency
  }
  /* pdp-plp promotion changes - PLT813-646*/
  if (
    originalPrice &&
    priceAfterDiscount &&
    originalPrice != priceAfterDiscount
  ) {
    constructedPrice.hasBothPriceAvail = true
    constructedPrice.regularPrice = currencyFormat({
      // currency: currencyData,
      price: originalPrice,
    })
    constructedPrice.salePrice = currencyFormat({
      // currency: currencyData,
      price: priceAfterDiscount,
    })
  }
  return constructedPrice
}

export default formatPrice
export { formatPrice }
