import React from 'react'
import { i18nTranslate } from 'src/utils'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { BsQuestionCircleFill } from 'react-icons/bs'
import BASponsorMessage from '../BASponsorMessage'

class FormToolTip extends React.Component {
  handleTooltip = e => {
    e.preventDefault()
  }

  render() {
    if (!this.props?.toolTipInfo) {
      return <></>
    }

    let ariaLabel = ''
    if (this.props?.name === 'dateOfBirth' || this.props?.name === 'DoB') {
      ariaLabel = i18nTranslate(
        'ariaLabel.DOB',
        'Date of Birth’s more info icon'
      )
    } else if (this.props?.name === 'signupTaxId') {
      ariaLabel = i18nTranslate(
        'ariaLabel.SSN',
        'Social Security Number’s more info icon'
      )
    } else if (this.props?.name === 'sponsorAffiliateName') {
      ariaLabel = i18nTranslate(
        'ariaLabel.sponsorAffiliateName',
        'Sponsoring affiliate name more info icon'
      )
    } else if (this.props?.name === 'converAccountTitle') {
      ariaLabel = i18nTranslate(
        'ariaLabel.converAccountTitle',
        'Business Entity Name more info icon'
      )
    }
    return (
      <>
        <span onClick={this.handleTooltip}>
          <OverlayTrigger
            // show={this.isToShowTooltip}
            // trigger={['hover', 'focus', 'click']}
            trigger={this.props?.toolTipAction || 'click'}
            placement="top"
            rootClose
            overlay={
              <Tooltip id={this.props?.toolTipId || `tooltip`}>
                {this.props?.toolTipId === 'sponsorTooltip' ? (
                  <BASponsorMessage showBAMessage={true} />
                ) : (
                  this.props?.toolTipInfo
                )}
              </Tooltip>
            }>
            <Button
              aria-label={ariaLabel || ''}
              role="button"
              className="infoIconBtn ariaLabelBtn">
              <BsQuestionCircleFill
                aria-label={this.props?.toolTipInfo || ''}
                className="question-circle-align"
              />
            </Button>
          </OverlayTrigger>
        </span>
        <span> {this?.props?.toolTipSuffix || ''} </span>
      </>
    )
  }
}

export default FormToolTip
export { FormToolTip }
