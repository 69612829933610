import React from 'react'

class ProductVideo extends React.Component {
  render() {
    const { source = '', className = '', controls = true } = this.props || {}
    return (
      <video key={`video-${source}`} className={className} controls={controls}>
        <source src={source} />
      </video>
    )
  }
}

export { ProductVideo }
