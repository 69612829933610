import React from 'react'
import { i18nTranslate } from 'src/utils'
import { observer } from 'mobx-react'
import { Card } from 'react-bootstrap'
import { studioContainer } from 'src/models'
import { CategorySuggestion } from 'src/views/components/EmptyProduct/CategorySuggestion'
import SubscriptionToastMessage from './SubscriptionToastMessage'
import NavigationButtons from './NavigationButtons'
import { IS_BROWSER, checkMysiteOrPersonalOffer } from 'src/utils'
import './styles.scss'
import { trackGTMEvents, getLiveEventStatus } from 'src/utils'

@observer
class EmptyCart extends React.Component {
  enableLiveEvent = getLiveEventStatus()
  constructor(props) {
    super(props)
    this.buttonText = i18nTranslate('cart.startShopping', 'Start Shopping')
  }

  componentDidMount() {
    // move this studio data as a common component
    if (this.props.isViewCartPage || this.props.isCheckout) {
      this.getStudioData()
    }
    this.trackAnalytics()
  }

  trackAnalytics = () => {
    // const user = customerContainer.profileResponse
    // const info = {
    //   id: user?.email,
    //   firstName: user?.firstName || 'Guest',
    // }

    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'EmptyCart',
    //     pageTitle: 'viewCart',
    //     userInfo: info?.firstName || 'Guest',
    //     userId: info?.id || 'Guest',
    //     platform: 'equinox',
    //   },
    // })
    trackGTMEvents({
      event: 'empty_cart',
    })
  }

  getStudioData = async () => {
    await studioContainer.getStudioResponse({
      context: this.context,
      contextName: this.contextName,
    })
  }

  getCategory = studioEntries => {
    let categoryRes = {}
    studioEntries &&
      studioEntries.length > 0 &&
      studioEntries?.map((section, key) => {
        if (section && section['category_slider']) {
          categoryRes = section['category_slider']
        }
        return
      })
    return categoryRes
  }

  checkIsNativeApp = () => {
    let isNativeApp = false
    if (IS_BROWSER) {
      isNativeApp = window?.isNativeApp || false
    }
    return isNativeApp
  }

  render() {
    const {
      isCheckout = false,
      hasNoProductsInCart = false,
      isViewCartPage = false,
    } = this.props

    const dynamicClassName = isCheckout
      ? 'checkout-empty-container'
      : 'cart-empty-container w-auto m-2'

    let studioRes = {}
    let categoryRes = {}
    const isNativeApp = this.checkIsNativeApp()

    if (isCheckout || isViewCartPage) {
      try {
        studioRes = studioContainer.studioData?.entries?.[0]?.sections || {}
        categoryRes = JSON.parse(JSON.stringify(this.getCategory(studioRes)))
      } catch (e) {
        console.log('exception in empty cart ', e)
      }
      if (categoryRes?.main_title) {
        categoryRes.main_title = i18nTranslate(
          'cart.categoriesTitle',
          'Here are our top categories you might like'
        )
      }
    }
    const titleClassName =
      isViewCartPage || isCheckout ? 'text-center' : 'text-uppercase card-title'

    // let shoppingContext = getLocalStorage('shoppingContext') || {}
    // let contextText = shoppingContext?.context || ''
    const contextText = checkMysiteOrPersonalOffer()

    return (
      <div className={`bg-white cart-checkout-container ${dynamicClassName}`}>
        <Card className="border-0">
          <Card.Body>
            {hasNoProductsInCart && (
              <SubscriptionToastMessage
                message={i18nTranslate(
                  'subscription.successItem',
                  'Item(s) successfully added to Subscription'
                )}
              />
            )}
            <Card.Title
              className={`cart-empty-tittle ${titleClassName}`}
              data-testid="qa-no-items-message">
              {i18nTranslate(
                'cart.emptyCartText',
                'There are no items in your cart'
              )}
            </Card.Title>
            {(isViewCartPage || isCheckout) && !isNativeApp && (
              <NavigationButtons
                buttonCustomClassName="justify-content-center"
                buttonText={this.buttonText}
                darkButtons={true}
              />
            )}
          </Card.Body>
        </Card>
        {(isViewCartPage || isCheckout) &&
          !isNativeApp &&
          contextText == '' &&
          this.enableLiveEvent !== 'true' && (
            <div className="p-3">
              <CategorySuggestion
                titleClassName="cart-categories-title"
                data={categoryRes}
              />
            </div>
          )}
      </div>
    )
  }
}

export { EmptyCart }
export default EmptyCart
