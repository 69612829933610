import React, { useState } from 'react'
import { i18nTranslate } from 'src/utils'
import { Button } from 'react-bootstrap'
import {
  IoIosInformationCircle as InformationIcon,
  IoIosInformationCircleOutline as InformationIconOutline,
} from 'react-icons/io'
import { APPConfig } from 'config/appConfig'
import CartScanCard from 'src/views/components/CartBlock/CartScanCard'
import CartScanInfo from './CartScanInfo'
import './styles.scss'

function CartScanCardWithInfo(props) {
  const [showScanCardFormModal, setShowScanCardFormModal] = useState(false)
  const [showScanCardInfoModal, setShowScanCardInfoModal] = useState(false)

  const isFlexibleSubscriptionEnabled =
    APPConfig?.getAppConfig()?.enableFlexibleSubscription === 'true'
  const className = isFlexibleSubscriptionEnabled
    ? 'scancard-btn'
    : 'scan-card-button-wrapper mr-2'
  return (
    <>
      <div className="my-2">
        <Button
          className={className}
          onClick={() => setShowScanCardFormModal(true)}>
          {i18nTranslate('cart.scanCard', 'Scan Card')}
        </Button>
        {isFlexibleSubscriptionEnabled ? (
          <InformationIconOutline
            tabIndex={0}
            title={i18nTranslate(
              'tooltipScanCard',
              'Click here to get information about scan card'
            )}
            className={'scancard-info-icon'}
            onClick={() => setShowScanCardInfoModal(true)}
            type="button"
            size="1rem"
          />
        ) : (
          <InformationIcon
            tabIndex={0}
            title={i18nTranslate(
              'tooltipScanCard',
              'Click here to get information about scan card'
            )}
            className={'scancard-info-icon'}
            onClick={() => setShowScanCardInfoModal(true)}
            type="button"
            size="1.5rem"
          />
        )}
      </div>
      {showScanCardFormModal && (
        <CartScanCard
          {...props}
          isScanModalOpen={showScanCardFormModal}
          toggleQuickView={() => setShowScanCardFormModal(false)}
          page={props?.page}
          scanCardProductDetails={props}
          isFlexibleSubscription={isFlexibleSubscriptionEnabled}
        />
      )}
      {showScanCardInfoModal && (
        <CartScanInfo
          show={showScanCardInfoModal}
          onHide={() => setShowScanCardInfoModal(false)}
          isFlexibleSubscription={isFlexibleSubscriptionEnabled}
        />
      )}
    </>
  )
}

export { CartScanCardWithInfo }
export default CartScanCardWithInfo
