import * as React from 'react'
import { observer } from 'mobx-react'
import { Button, Modal, ModalBody } from 'react-bootstrap'
import { modalState } from './ModalState'
import { IoIosClose as CloseIcon } from 'react-icons/io'
import './styles.scss'

@observer
class ModalView extends React.Component {
  render() {
    const customClassNameForMessage =
      modalState.pageProps?.customClassName || ''

    return (
      <Modal
        className="modal-container"
        show={modalState.isToShow}
        animation={true}
        size="md"
        centered>
        <CloseIcon
          onKeyPress={modalState.hideVisibility}
          tabIndex={0}
          aria-label="close-icon"
          onClick={modalState.hideVisibility}
          className="class-icon-custom"
        />
        <ModalBody className="body-container">
          <p className={customClassNameForMessage}>{modalState.toastMessage}</p>

          <Button className="custom-button" onClick={modalState.hideVisibility}>
            {modalState.buttonText}
          </Button>
        </ModalBody>
      </Modal>
    )
  }
}

export { ModalView }
export default ModalView
