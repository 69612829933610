export const countryRegex = {
  CA: /[a-zA-Z]\d[a-zA-Z] \d[a-zA-Z]\d$/,
  US: /^\d{5}(-\d{4})?$/,
  CO: /^\d\d\d\d\d\d$/,
  AM: /^\d\d\d\d$/,
  AR: /^[a-zA-Z0-9][a-z0-9 ]{0,3}[a-z0-9]$/,
  AU: /^\d\d\d\d$/,
  AT: /^\d\d\d\d$/,
  BE: /^\d\d\d\d$/,
  BN: /[a-zA-Z][a-zA-Z]\d\d\d\d$/,
  CH: /^\d\d\d\d$/,
  CL: /^\d{7}$/,
  CN: /^\d{6}$/,
  CZ: /^\d{3}[ ]?\d{2}$/,
  DE: /^\d\d\d\d\d$/,
  DK: /^\d{4}$/,
  ES: /^(?!5100[0-5]|51070|51071|51081|5200[0-6]|5207[0-1]|52081)\d{5}$/,
  FI: /^\d\d\d\d\d$/,
  FR: /^([0-8]\d{4}|9[0-6,9]\d{3})$/,
  GB: /^[a-zA-Z][a-zA-Z]?\d[a-zA-Z\d]?\s\d[a-zA-Z]{2}$/,
  HU: /^\d\d\d\d$/,
  HK: /^\d\d\d\d\d\d$/,
  ID: /^\d\d\d\d\d$/,
  IE: /^([a-zA-Z0-9 ]{3}|(([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}))$/,
  IL: /^(\d{5}|\d{7})$/,
  IS: /^\d{3}$/,
  IT: /^\d\d\d\d\d$/,
  JP: /^\d{3}(-\d{4})$/,
  // (Only for JP) illegalCharactersRegex: /^([\u30A0-\u30FF]|[\u3000-\u303F]|[a-zA-Z0-9\\s\\.!\\\\/:@#&*-’, ]|[\u4E00-\u9FAF]|[\uFF00-\uFFEE]|[\u3040-\u309F]|\\s)*$/,
  KR: /^\d\d\d\d\d$/,
  LU: /^\d\d\d\d$/,
  MC: /^(MC\d{5}|\d{5})$/,
  MX: /^\d\d\d\d\d$/,
  MY: /^\d\d\d\d\d$/,
  NC: /^\d\d\d\d\d$/,
  NL: /^((\d{4})|((?:NL-)?(\d{4})\s*([A-Z]{2})))$/,
  NO: /^\d\d\d\d$/,
  NZ: /^\d\d\d\d$/,
  PF: /^987\d\d$/,
  PE: /^\d\d\d\d\d$/,
  PH: /^\d\d\d\d$/,
  PL: /^\b\d{5}\b$|\d{2}-\d{3}$/,
  PT: /^\d{4}-\d{3}$/,
  RO: /^\d{6}$/,
  RU: /^\d{6}$/,
  SE: /^\d\d\d\d\d$/,
  SG: /^\d{6}$/,
  SK: /^\d{3}\d{2}$/,
  TH: /^\d\d\d\d\d$/,
  TR: /^\d\d\d\d\d$/,
  TW: /^\d{3}(-\d{2})?$|^\d{3}(-\d{3})?$|^\d{4}$|^\d{5}$|^\d{6}$/,
  UA: /^\d\d\d\d\d$/,
  VE: /^\d{4}$/,
  VN: /^\d{6}$/,
  ZA: /^\d\d\d\d$/,
  DEFAULT: /^[0-9]{5}(?:-[0-9]{4})?$/,
}

export const invalidCharacterRegex =
  /^(?=.*[a-zA-Z0-9.!@#&*\-\u0080-\u052F])[a-zA-Z0-9\s.!@#&*'/(),\-\u0080-\u052F]*$/
