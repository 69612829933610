import React, { Component } from 'react'
import { PriceButton } from 'src/views/components/Price/PriceButton'
import { productState } from 'src/views/components/BaseProductComponent'
import {
  ApiContent,
  IS_BROWSER,
  isNonEventOrEventWithSub,
  i18nTranslate,
} from 'src/utils'
import { Col } from 'react-bootstrap'
import {
  customerContainer,
  promotionContainer,
  nuskinSubscriptionContainer,
} from 'src/models'
// import { pageNames } from 'src/routes/pathParams'
import { observer } from 'mobx-react'
import './styles.scss'

@observer
class PriceButtonsForPDP extends Component {
  static contextType = ApiContent
  constructor(props, context) {
    super(props, context)
    super(props)
  }
  getAccountType = () => {
    if (IS_BROWSER) {
      return customerContainer.profileResponse?.accounts?.type || ''
    }
    return this?.context?.customerType
  }
  isBAorPreferred = () => {
    const accountType = this.getAccountType()
    return (
      accountType === 'Brand Affiliate' ||
      accountType === 'Brand Affiliate - Business Entity' ||
      accountType === 'Preferred Customer/Member' ||
      false
    )
  }

  onPriceButtonClick = id => {
    productState.currentlySelectedPriceOption = id
    const isAlreadySubscribedProduct =
      nuskinSubscriptionContainer.isAlreadySubscribedProduct(this.props)

    // there will be no "manage subscription", hence commeting the below

    // if (id === 'subscription-price-button' && isAlreadySubscribedProduct) {
    //   this.props.history?.push(pageNames.myaccountsubscriptions)
    //   return
    // }

    // since the both subscription and one time value facets are available
    // we don't make additional call

    // promotionContainer.makeV2PromotionCall(
    //   this.props.product,
    //   this.props.productCount,
    //   true
    // )
  }
  getOneTimePriceBasedOnUser = () => {
    const unitValue = this.props.unitValue?.priceFacets || {}
    let oneTimeButtonPrice = ''
    if (this.isBAorPreferred()) {
      oneTimeButtonPrice =
        unitValue?.['Wholesale Price']?.['Wholesale PriceAfterDiscount'] ||
        unitValue?.['Wholesale Price']?.['Wholesale Price']
    } else {
      oneTimeButtonPrice =
        unitValue?.['Regular Price']?.['Regular PriceAfterDiscount'] ||
        unitValue?.['Regular Price']?.['Regular Price']
    }
    return oneTimeButtonPrice || 0
  }
  getSubscriptionPriceBasedOnUser = () => {
    const unitValue = this.props.unitValue?.priceFacets || {}
    let subscriptionButtonPrice = ''
    if (this.isBAorPreferred()) {
      subscriptionButtonPrice =
        unitValue?.['Sub Wholesale Price']?.[
          'Sub Wholesale PriceAfterDiscount'
        ] || unitValue?.['Sub Wholesale Price']?.['Sub Wholesale Price']
    } else {
      subscriptionButtonPrice =
        unitValue?.['Sub Retail Price']?.['Sub Retail PriceAfterDiscount'] ||
        unitValue?.['Sub Retail Price']?.['Sub Retail Price']
    }
    return subscriptionButtonPrice || 0
  }
  render() {
    const {
      product = {},
      shouldShowOneTimeButton = true,
      shouldShowSubscriptionButton = true,
    } = this.props
    const itemType = product?.productType || ''
    const isOneTimeSelected =
      productState.currentlySelectedPriceOption === 'one-time-price-button'
    const isSubscriptionSelected =
      productState.currentlySelectedPriceOption === 'subscription-price-button'

    // let isAlreadySubscribedProduct =
    //   nuskinSubscriptionContainer.isAlreadySubscribedProduct(this.props)
    // this.initializeCheckForManageSubscription()
    // currently commenting this out since there is no "manage subscription" feature
    let isAlreadySubscribedProduct = false
    const subscriptionButtonLabel = isAlreadySubscribedProduct
      ? i18nTranslate('pdp.manageV2Subscription', 'Manage Subscription', {
          nameSpace: 'ssr-resource',
        })
      : this.getSubscriptionPriceBasedOnUser() ===
        this.getOneTimePriceBasedOnUser()
      ? i18nTranslate('pdp.subscribe', 'Subscribe', {
          nameSpace: 'ssr-resource',
        })
      : i18nTranslate('pdp.subscribesave', 'Subscribe & save', {
          nameSpace: 'ssr-resource',
        })
    const subscriptionButtonCustomClass = isAlreadySubscribedProduct
      ? 'manage-subscription-button'
      : ''
    return (
      <Col lg={12} sm={12} xs={12} noGutters className="price-button-container">
        {shouldShowOneTimeButton && (
          <PriceButton
            id={'one-time-price-button'}
            label={i18nTranslate('pdp.onetimeprice', 'One-time price', {
              nameSpace: 'ssr-resource',
            })}
            onPriceButtonClick={this.onPriceButtonClick}
            className="col-6"
            price={this.getOneTimePriceBasedOnUser()}
            isSelected={isOneTimeSelected}
            dataTestId="qa-pdp-one-time-price"
          />
        )}
        {shouldShowSubscriptionButton && isNonEventOrEventWithSub() && (
          <PriceButton
            id={'subscription-price-button'}
            label={subscriptionButtonLabel}
            onPriceButtonClick={this.onPriceButtonClick}
            className={`col-6 ${subscriptionButtonCustomClass}`}
            price={this.getSubscriptionPriceBasedOnUser()}
            isSelected={isSubscriptionSelected}
            dataTestId="qa-pdp-subscribe-and-save"
          />
        )}
      </Col>
    )
  }
}

export { PriceButtonsForPDP }
export default PriceButtonsForPDP
