import { i18nTranslate } from 'src/utils'

/**
 * Returns an array of objects representing different subscription options.
 * Each object contains a label, value and boolean flags indicating if it is monthly or one-time.
 * The labels are translated using the i18nTranslate utility.
 */
const subscriptionType = () => {
  return [
    {
      label: i18nTranslate('subscription.monthly', 'Monthly'),
      value: '1-MONTHS',
      isMonthly: true,
    },
    {
      label: i18nTranslate('subscription.twoMonth', 'Every 2 Months'),
      value: '2-MONTHS',
    },
    {
      label: i18nTranslate('subscription.threeMonth', 'Every 3 Months'),
      value: '3-MONTHS',
    },
    {
      label: i18nTranslate('subscription.oneTimeItem', 'One-time item'),
      value: '1-MONTHS',
      isOneTime: true,
    },
  ]
}

const getSubscriptionFrequencyLabel = value => {
  const options = subscriptionType() || []
  const option = options?.find(opt => opt?.value === value)
  return option ? option.label : ''
}

export { subscriptionType, getSubscriptionFrequencyLabel }
