import React from 'react'
import { scrollState } from 'src/utils'

class ScrollToTopComponent extends React.PureComponent {
  scrollElement = ''

  static defaultProps = {
    Component: 'div',
    isToScroll: false,
    timeout: 0,
  }

  componentDidMount() {
    this.scrollTop()
  }

  componentDidUpdate() {
    this.scrollTop()
  }

  scrollTop = () => {
    const { isToScroll, timeout } = this.props || {}
    if (isToScroll && this.scrollElement) {
      setTimeout(() => {
        scrollState.scrollToTop(this.scrollElement)
      }, timeout)
    }
  }

  render() {
    const { children, Component, attributes } = this.props

    return (
      <Component
        ref={element => (this.scrollElement = element)}
        {...attributes}>
        {children}
      </Component>
    )
  }
}

export { ScrollToTopComponent }
