import React, { useEffect, useState } from 'react'
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker'
import dayjs from 'dayjs'
import { Form } from 'react-bootstrap'
import { FormInputLabels } from 'src/deps'
import { MdDateRange as DatePickerIcon } from 'react-icons/md'
import { i18nTranslate } from 'src/utils'
import { FormToolTip } from './FormToolTip'
import { IS_BROWSER, getLocaleCodeFromUrl, getMonthStringList } from 'src/utils'
// import { enUS, es, fr, vi, zhCN } from 'date-fns/locale'
import { accountsContainer, customerContainer } from 'src/models'
import { APPConfig } from 'config/appConfig'

// For now it is added as variable might need to get it from props for other formats
const DATE_FORMAT = 'YYYY-MM-DD'
const MAX_YEAR = 18
const ALLOWED_YEAR = new Date()?.getFullYear() - MAX_YEAR
const FORMAT_SEPARATOR = '-'
// be default this component uses hypen(-) which will be output by default
const INBUILT_SEPARATOR = '-'
const spearatorPositions = getIndices(DATE_FORMAT, FORMAT_SEPARATOR)
const formInputLabels = new FormInputLabels()

const range = (start, end) => {
  const ans = []
  for (let i = start; i <= end; i++) {
    ans.push(i)
  }
  return ans
}

const getYear = currentDate => {
  let time = new Date(currentDate).getFullYear()
  return time
}

const getMonth = month => {
  let time = new Date(month).getMonth()
  return time
}

const isValidDate = dateString => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/
  if (!dateString.match(regEx)) return false // Invalid format
  let d = new Date(dateString)
  let dNum = d.getTime()
  if (!dNum && dNum !== 0) return false // NaN value, Invalid date

  return d.toISOString().slice(0, 10) === dateString
}

const DATE_MAX_LENGTH = 10

function getIndices(string, char) {
  return [...string].flatMap((c, i) => (c === char ? i + 1 : []))
}

const getDateObject = options => {
  const { modifiedDate, isUserEnteredDate = true } = options || {}
  try {
    return new Date(modifiedDate?.[0], modifiedDate?.[1] - 1, modifiedDate?.[2])
  } catch (e) {
    console.warn('getDate ', e)
    return {}
  }
}

const insertAt = (str, sub, pos) =>
  `${str.slice(0, pos)}${sub}${str.slice(pos)}`

const maskUserEnteredDate = options => {
  const { event } = options || {}
  const isDeleting = event?.nativeEvent?.inputType === 'deleteContentBackward'
  const textWithoutSeparator = event?.target?.value.replace(/-/g, '')
  let addSeparatorInText = textWithoutSeparator

  if (isDeleting) {
    addSeparatorInText = event?.target?.value
  } else {
    for (let i = 1; i <= spearatorPositions.length; i++) {
      if (textWithoutSeparator.length + i >= spearatorPositions[i - 1]) {
        addSeparatorInText = insertAt(
          addSeparatorInText,
          FORMAT_SEPARATOR,
          spearatorPositions[i - 1] - 1
        )
      } else {
        break
      }
    }
  }
  return addSeparatorInText
}

function isFocusedOnDatePicker(className = '') {
  return (
    className?.toLowerCase?.()?.includes?.('date') ||
    className?.includes?.('Datepicker-year-drop') ||
    className?.includes?.('date-picker-icon-holder') ||
    className?.includes?.('dtIcon') ||
    className?.includes?.('Date-picker-button')
  )
}

function isElementFocused(classList = []) {
  return [...classList].some(className => {
    return isFocusedOnDatePicker(className)
  })
}

const DatePickerField = props => {
  const years = range(1901, getYear(new Date()) - MAX_YEAR)
  const months = getMonthStringList()

  const {
    value,
    touched,
    setFieldTouched,
    setFieldValue,
    name,
    values,
    disabled = false,
    required = true,
    isFromProfile = false,
  } = props

  const [startDate, setStartDate] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isValidDateEntered, setIsValidDate] = useState()
  const [isRestrictedAge, setIsRestrictedAge] = useState()
  const [isVaildMonthAndDay, setIsVaildMonthAndDay] = useState()
  const [usedLanguage, setUsedLanguage] = useState('')

  const dateOfBirthTrans = i18nTranslate('dateOfBirth', 'Date Of Birth')
  const nextMonth = i18nTranslate('nextMonth', 'Next Month')
  const previousMonth = i18nTranslate('previousMonth', 'Previous Month')
  const yearDropDown = i18nTranslate('year.DropDown', 'Year Drop Down menu')
  const monthDropDown = i18nTranslate('month.DropDown', 'Month Drop Down menu')

  const dobGetIsInValid =
    values?.dateOfBirth === ''
      ? touched[name] &&
        required &&
        (startDate === '' || startDate === null || !isValidDateEntered) &&
        accountsContainer?.onSubmitInValid
      : touched[name] &&
        required &&
        (startDate === '' || startDate === null || !isValidDateEntered)

  const dobGetIsInValidOptional =
    values?.DoB === ''
      ? false
      : values?.DoB !== '' && touched[name] && !disabled
      ? startDate === '' || startDate === null || !isValidDateEntered
      : false

  //The Profile DOB is Optional so maintaining a seperate condition for isInvalid

  accountsContainer.isDateOfBirthInValid = dobGetIsInValidOptional

  const isInvalid = isFromProfile ? dobGetIsInValidOptional : dobGetIsInValid

  const handleEsc = e => {
    const escKeyCode = 27
    if (e.keyCode === escKeyCode) {
      setIsOpen(false)
    }
  }

  const getFocusedElement = e => {
    if (e?.type == 'focusin' || e?.type == 'click') {
      const currentElFocus = isElementFocused(e?.target?.classList)
      const parentElFocus = isElementFocused(e?.target?.parentNode?.classList)
      return currentElFocus || parentElFocus
    }

    return e?.path?.some(element => {
      if (typeof element?.className === 'string') {
        return isFocusedOnDatePicker(element?.className)
      }
      return false
    })
  }

  const handleMouseClick = e => {
    const isFocusInPicker = getFocusedElement(e)
    if (!isFocusInPicker) {
      setIsOpen(false)
    }
  }

  const handleFocus = () => {
    // used document to get DOM's since some of the needed
    // options are not exposed in react-datepicker
    document.addEventListener('focusin', e => {
      const isFocusInPicker = getFocusedElement(e)
      const yearEl = document.querySelector(`.Datepicker-year-drop`)

      if (!isFocusInPicker && yearEl) {
        setIsOpen(false)
      }
    })
  }

  const registerEventListeners = () => {
    window.addEventListener('keydown', handleEsc)
    window.addEventListener('click', handleMouseClick)
  }

  const removeEventListeners = () => {
    window.removeEventListener('keydown', handleEsc)
    window.removeEventListener('click', handleMouseClick)
  }

  const customHandleChangeEventing = dateValue => {
    const eventing = {
      target: {
        value: dateValue,
      },
    }
    handleDateChange(eventing)
  }

  const getUsedLanguage = async () => {
    let language = ''
    const { enUS, es, fr, vi, zhCN } = await import('date-fns/locale')

    if (IS_BROWSER) {
      let locale = getLocaleCodeFromUrl() || ''
      locale = locale?.toLowerCase()?.split('_')

      language =
        locale[0] === 'zh'
          ? zhCN
          : locale[0] === 'en'
          ? enUS
          : locale[0] === 'fr'
          ? fr
          : locale[0] === 'es'
          ? es
          : locale[0] === 'vi'
          ? vi
          : enUS

      registerLocale(`${language}`, language)
    }
    let promise = new Promise((resolve, reject) => {
      if (language) {
        resolve(language)
      } else {
        reject(Error('Promise rejected'))
      }
    })

    promise.then(
      result => {
        setUsedLanguage(result)
      },
      function (error) {
        setUsedLanguage(error)
      }
    )
  }

  useEffect(() => {
    handleFocus()
    registerEventListeners()
    customHandleChangeEventing(dateOfBirth)
    getUsedLanguage()
    return () => {
      removeEventListeners()
    }
  }, [])

  const lastDayOfMonth = (year, month) => {
    return new Date(year, month, 0).getDate()
  }

  const handleDateChange = e => {
    let date = ''
    let dateForPicker = ''
    let isUserEnteredDate = false
    let isValidDateState = false
    let isRestrictedDateState = false
    let isVaildMonthAndDayState = false
    try {
      if (e?.target) {
        const numberRegEx = `^[0-9${FORMAT_SEPARATOR}\b]+$`
        const regEx = new RegExp(numberRegEx, 'g')
        let typedText = e?.target?.value
        if (
          !typedText?.match(regEx) ||
          // Todo hardcoding as YYYY-MM-DD format and doing manual validation for now

          (typedText.length == 1 && typedText > 2) ||
          (typedText.length == 2 && (typedText < 19 || typedText >= 21))
        ) {
          date = typedText.substr(0, typedText.length - 1)
        }
        // Adding temp condition to support 'YYYY-MM-DD' with 1901 and 18 years from current date condition
        else if (
          typedText.length == 4 &&
          (typedText < 1901 || typedText > ALLOWED_YEAR)
        ) {
          isRestrictedDateState = true
          date = typedText
        } else {
          date = maskUserEnteredDate({ event: e })
          const enteredYear = date.split(INBUILT_SEPARATOR)
          const maxDayOfMonth = lastDayOfMonth(
            enteredYear?.[0],
            enteredYear?.[1]
          )
          if (
            enteredYear?.[0]?.length == 4 &&
            (enteredYear?.[0] < 1901 || enteredYear?.[0] > ALLOWED_YEAR)
          ) {
            isRestrictedDateState = true
          } else if (
            (enteredYear?.[1]?.length == 2 && enteredYear?.[1] > 12) ||
            (enteredYear?.[2]?.length == 2 && enteredYear?.[2] > maxDayOfMonth)
          ) {
            isVaildMonthAndDayState = true
          }
        }
        isUserEnteredDate = true
      } else {
        date = e
      }

      if (date && date.length >= DATE_MAX_LENGTH) {
        let modifiedDate = date
        // future proof
        if (FORMAT_SEPARATOR != INBUILT_SEPARATOR) {
          const regEx = new RegExp(`${FORMAT_SEPARATOR}`, 'g')
          let modifiedDate = date?.replace(regEx, INBUILT_SEPARATOR)
        }
        if (isValidDate(modifiedDate)) {
          modifiedDate = modifiedDate.split(INBUILT_SEPARATOR)
          const maxDayOfMonth = lastDayOfMonth(
            modifiedDate?.[0],
            modifiedDate?.[1]
          )
          if (modifiedDate?.[0] < 1901 || modifiedDate?.[0] > ALLOWED_YEAR) {
            isRestrictedDateState = true
          } else if (
            (modifiedDate?.[1]?.length == 2 && modifiedDate?.[1] > 12) ||
            (modifiedDate?.[2]?.length == 2 &&
              modifiedDate?.[2] > maxDayOfMonth)
          ) {
            isVaildMonthAndDayState = true
          } else if (
            modifiedDate?.[0] === ALLOWED_YEAR?.toString() &&
            modifiedDate?.[1]?.length === 2 &&
            modifiedDate?.[1] >= new Date()?.getMonth() + 1 &&
            modifiedDate?.[2] > new Date()?.getDate()
          ) {
            isRestrictedDateState = true
          } else {
            date = getDateObject({ modifiedDate, isUserEnteredDate: true })
            dateForPicker = getDateObject({
              modifiedDate,
              isUserEnteredDate: false,
            })
            isValidDateState = true
          }
        } else {
          isValidDateState = false
          console.log('error handle invalid date')
        }
      }
    } catch (e) {
      console.warn('handleDateChange ', e)
    }
    setIsVaildMonthAndDay(isVaildMonthAndDayState)
    setIsRestrictedAge(isRestrictedDateState)
    setStartDate(dateForPicker)
    setFieldValue(name, date)
    setIsValidDate(isValidDateState)
    if (isOpen) {
      setIsOpen(false)
    }
  }

  const handleCalendarClick = e => {
    if (e?.type == 'keydown' && e?.key != 'Enter') {
      return
    }
    setIsOpen(!isOpen)
    setTimeout(() => {
      let element = document.querySelector(`.Datepicker-year-drop`)
      if (element) {
        element.click()
      }
    }, 100)
  }

  const datePickerOnChange = selectedDate => {
    const year = selectedDate.getFullYear()
    let month = selectedDate.getMonth() + 1
    month = month <= 9 ? `0${month}` : month
    let day = selectedDate.getDate()
    day = day <= 9 ? `0${day}` : day
    handleDateChange(
      `${year}${FORMAT_SEPARATOR}${month}${FORMAT_SEPARATOR}${day}`
    )
  }

  const DatePickerForm = () => {
    return (
      <Form.Label
        className={'date-picker-form-wrapper m-0 p-0'}
        data-testid="qa-dateOfBirth">
        <DatePicker
          aria-label={dateOfBirthTrans}
          locale={`${usedLanguage}`}
          data-testid="qa-dateOfBirth"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'center',
              }}>
              <button
                className="Date-picker-button button-right"
                role="button"
                onClick={e => {
                  decreaseMonth()
                  e.preventDefault()
                }}
                aria-label={previousMonth || 'Previous Month'}
                disabled={prevMonthButtonDisabled}>
                {'<'}
              </button>
              <div
                aria-label={yearDropDown}
                role="button"
                className="Datepicker-year-drop mr-2"
                onClick={e => {
                  e.preventDefault()
                }}>
                <select
                  className="date-picker-year-select"
                  value={getYear(date)}
                  // eslint-disable-next-line no-shadow
                  onChange={({ target: { value } }) => changeYear(value)}>
                  {years.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div aria-label={monthDropDown} role="button">
                <select
                  className="date-picker-month-select"
                  value={months[getMonth(date)]}
                  // eslint-disable-next-line no-shadow
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }>
                  {months.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="Date-picker-button button-left"
                aria-label={nextMonth || 'Next Month'}
                role="button"
                onClick={e => {
                  increaseMonth()
                  e.preventDefault()
                }}
                disabled={nextMonthButtonDisabled}>
                {'>'}
              </button>
            </div>
          )}
          selected={startDate}
          onChange={datePickerOnChange}
          calendarContainer={MyContainer}
          name={name}
          className={`form-input ${isInvalid ? 'is-invalid-date' : ''}`}
          isInvalid={isInvalid}
          // autoComplete="on"
          // id={name}
          maxDate={new Date(dayjs().subtract(MAX_YEAR, 'years'))}
          inline
        />
      </Form.Label>
    )
  }

  const ErrorMessage = () => {
    const { accounts = {} } = customerContainer?.profileResponse || {}
    if (isRestrictedAge) {
      return (
        <div className={'date-picker-invalid-text invalid-feedback'}>
          {props?.isFromProfile === true &&
          (accounts?.accountType === 'Retail Customer' ||
            accounts?.accountType === 'Preferred Customer/Member')
            ? i18nTranslate(
                'minDobAge.errorMessage',
                'The entered value does not meet the minimum age requirement.'
              )
            : i18nTranslate(
                'validation.dateOfBirthAgeRestrictError',
                'You must be at least 18 years old to join as a Brand Affiliate.'
              )}
        </div>
      )
    } else if (isVaildMonthAndDay) {
      return (
        <div className={'date-picker-invalid-text invalid-feedback'}>
          {i18nTranslate(
            'validation.dobInValidError',
            'Please enter valid Date Of Birth'
          )}
        </div>
      )
    } else if (isInvalid) {
      return (
        <div className={'date-picker-invalid-text invalid-feedback'}>
          {i18nTranslate('dob.error', 'Please enter Date Of Birth')}
        </div>
      )
    }
    return <></>
  }

  const defaultDate = value || ''
  let dateOfBirth = defaultDate
  try {
    if (typeof dateOfBirth == 'object') {
      dateOfBirth = dayjs(dateOfBirth).format(DATE_FORMAT)
    }
  } catch (e) {
    dateOfBirth = ''
    console.warn(e)
  }

  const [onChangeRef, setOnChangeRef] = useState(false)
  const getlocal = getLocaleCodeFromUrl({
    defaultLocale: 'en_US',
    isReverseType: true,
  })
  const language = getlocal?.split('_').length ? getlocal?.split('_')[0] : ''
  const dateLangSpecific =
    APPConfig?.getAppConfig()?.enableDateFormats?.pattern?.localeLanguage || ''
  const dateFormatFeature =
    APPConfig?.getAppConfig()?.enableDateFormats?.enableDateFormatFeature ===
      'true' || false
  const datePatternChange =
    dateLangSpecific?.includes(language?.toLowerCase()) || false
  let patternedDateOfBirth = ''
  if (isValidDateEntered && dateFormatFeature) {
    const displayDateOfBirth = dateOfBirth?.split('-') || ''
    const monthIndex = displayDateOfBirth[1] - 1
    patternedDateOfBirth = datePatternChange
      ? displayDateOfBirth[2] +
        ' ' +
        months[monthIndex] +
        ' ' +
        displayDateOfBirth[0]
      : months?.[monthIndex]
      ? months?.[monthIndex] +
        ' ' +
        displayDateOfBirth?.[2] +
        ' ' +
        displayDateOfBirth?.[0]
      : ''
  } else {
    patternedDateOfBirth = dateOfBirth
  }

  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: 'relative' }}>{children}</div>
      </CalendarContainer>
    )
  }
  const formLabel = formInputLabels.getFormLabels(name)
  const DATE_FORMAT_PLACEHOLDER = i18nTranslate(
    'signup.datepickerplaceholder',
    'YYYY-MM-DD'
  )
  return (
    <div className={`position-relative`}>
      <Form.Label className="data-label-dob" htmlFor={props.dataTestId}>
        {formLabel}
        <FormToolTip {...props} />
      </Form.Label>
      <Form.Control
        maxLength={DATE_MAX_LENGTH}
        id={props.name}
        className={`form-input ${isInvalid == true && 'is-invalid-date'}`}
        value={onChangeRef ? dateOfBirth : patternedDateOfBirth}
        data-testid="qa-dateOfBirth"
        type="text"
        onChange={handleDateChange}
        onClick={() => {
          setOnChangeRef(true)
        }}
        onFocus={() => {
          setOnChangeRef(true)
        }}
        placeholder={DATE_FORMAT_PLACEHOLDER}
        onBlur={() => {
          // mocked event target to prevent
          // error on blur for prefilled dates
          customHandleChangeEventing(dateOfBirth)
          setFieldTouched(name, true)
          setOnChangeRef(false)
        }}
        disabled={disabled}
        required={required}
        isInvalid={isInvalid}
      />
      <div
        aria-Label={i18nTranslate('date.icon', 'Calendar Icon')}
        role="button"
        tabIndex={disabled ? '-1' : '0'}
        className={`date-picker-icon-holder position-absolute ${
          disabled && 'picker-disabled'
        }`}
        onClick={handleCalendarClick}
        onKeyDown={handleCalendarClick}>
        <DatePickerIcon className="dtIcon" />
      </div>
      {isOpen && <DatePickerForm />}
      <ErrorMessage />
    </div>
  )
}
export { DatePickerField }
