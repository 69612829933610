import React from 'react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import isArray from 'lodash/isArray'
import {
  cartContainer,
  checkoutContainer,
  customerContainer,
  addressContainer,
  tokenExContainer,
} from 'src/models'
import {
  GlobalPaymentsOptionsList,
  toastState,
  AddressValidation,
} from 'src/views/components'
import { handleAddressValidation, creditCardValidation } from 'src/deps'
import {
  getNamefromFirstname,
  trackCustomCheckoutEvent,
  isGPSEnabled,
  i18nTranslate,
} from 'src/utils'

@observer
class GlobalPaymentAccordion extends React.Component {
  @observable paymentList = []
  @observable initiallySelectedPayment = []
  @observable isPaymentsApiLoaded = false
  @observable cvvValidFlag = false

  constructor(props) {
    super(props)

    this.state = {
      isToShowAddressValidationModal: false,
      addressInvalidFlag: false,
      recommendations: [],
      params: {},
    }
  }

  @action
  /**
   * @description Sets the cvvValidFlag to the provided value.
   * @param {boolean} val - The new value to set cvvValidFlag to.
   */
  changeCvvValidFlag = val => {
    this.cvvValidFlag = val
  }

  /**
   * @description
   * Triggered Delete Payments API to delete already added Payments in Cart.
   * If GPS is Enabled, then Global PaymentOptions API is triggered and on Success Response,
   * If Credit Card Payment Type is supported, GetPayments API is triggered.
   * If GPS is Disabled, GetPayments API is triggered to fetch Saved Card Details.
   */
  getPaymentDetails = async () => {
    let appliedPaymentsInCart = cartContainer?.cartResponse?.payments || []
    const totalAmountUnpaid =
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || 0

    /**
     * @description
     * when grandtotal is paid with StoreCredit or loyalty completely,
     * user is not allowed to open CreditCard section and submit order button will be enabled
     * if user tries to force open CreditCard section,
     * then loyalty and StoreCredit payment will be auto deleted
     */
    await appliedPaymentsInCart?.map(async payment => {
      const { type = '', id = '' } = payment || {}
      let isToDeletePayment = true
      let paymentTypesToDelete = ['LOYALTY', 'STORECREDIT']

      if (totalAmountUnpaid !== 0 && paymentTypesToDelete?.includes(type)) {
        isToDeletePayment = false
      }

      isToDeletePayment && (await checkoutContainer.deletePaymentById(id))
    })

    const enableGlobalPaymentSystem = isGPSEnabled()
    if (enableGlobalPaymentSystem) {
      const response = await checkoutContainer.getGlobalPaymentOptions()

      if (checkoutContainer.isSuccessResponse(response)) {
        const isCreditCardTypeSupportedInGPS =
          (Object.keys(response)?.length > 0 &&
            Object.keys(response)?.some(
              paymentType => paymentType?.toLowerCase() === 'creditcard'
            )) ||
          false
        isCreditCardTypeSupportedInGPS && (await this.getSavedPaymentsList())
      }
    } else {
      await this.getSavedPaymentsList()
    }
    this.isPaymentsApiLoaded = true
  }

  /**
   * @description Fetches saved payment details for the logged in user from the getPayment API.
   * Transforms the response to support Pagination and sets the default selected card if available.
   */
  getSavedPaymentsList = async () => {
    if (customerContainer.isRegisterUser) {
      const response = await customerContainer.getPayment()
      const transformedPaymentResponse =
        customerContainer.transformPaymentResponse(response) || {}
      if (checkoutContainer.isSuccessResponse(response)) {
        this.getDefaultCard(transformedPaymentResponse?.payments || [])
      }
    }
  }

  /**
   * @description Added Conditions to disable Submit Order and Payment Continue buttons.
   * Triggers getPaymentDetails() to fetch payment info.
   */
  async componentDidMount() {
    checkoutContainer.isToInitiateGlobalPayment = false
    checkoutContainer.isToDisableSubmitOrderButton = true
    await this.getPaymentDetails()
  }

  /**
   * @description Function to find the Default Saved Card from the Payments List.
   * @param {*} paymentResponse
   */
  getDefaultCard = (paymentResponse = []) => {
    if (Array.isArray(paymentResponse) && paymentResponse?.length > 0) {
      this.initiallySelectedPayment = paymentResponse?.find(card => {
        return card.isDefault === true
      })
    }

    this.paymentList = paymentResponse
  }

  /**
   * @description Splits payment data into accountInfo and billingAddress objects.
   * @param {Object} props - Object containing data and paymentData
   * @param {Object} data - Address data
   * @param {Object} paymentData - Payment data from form
   * @returns {Object} Object containing accountInfo and billingAddress
   */
  splitData = props => {
    const { data, paymentData } = props
    const {
      cardFirstName = '',
      cardCVV = '',
      cardNumber = '',
      provider = '',
      expiryDate = '',
      setToDefaultPayment = false,
      addPaymentToProfile = false,
      ...remainingProps
    } = paymentData || {}

    const card =
      cardNumber &&
      Object.entries(creditCardValidation)?.find(cardArr => {
        return cardNumber?.match(cardArr?.[1])
      })

    delete paymentData.expiryDate
    const name = getNamefromFirstname(cardFirstName)
    const accountInfo = {
      name: name?.firstName || '',
      lastName: name?.lastName || '',
      cardNumber,
      type: provider,
      expiryMonth: expiryDate?.split('/')[0],
      expiryYear: '20' + expiryDate?.split('/')[1],
      cardCVV,
      ...(isArray(card) && card[0] && { provider: card[0] }),
    }
    const addressData = paymentData.billingSameAsShipping
      ? data
      : paymentData.isFromSavedAddress
      ? paymentData.billingAddress
      : remainingProps
    const email =
      addressData?.email || customerContainer?.profileResponse?.email || ''
    const billingAddress = {
      ...addressData,
      email,
      canReceiveSMS: false,
      option: {
        addAddressToProfile: false,
        addPaymentToProfile: addPaymentToProfile,
        overrideAddressValidation: false,
        useDefaultAddress: 'false',
        setAsDefaultAddressInProfile: false,
      },
      validation: [
        {
          type: 'PHYSICAL',
          validated: true,
          overridden: true,
        },
      ],
    }
    return {
      isDefault: setToDefaultPayment,
      accountInfo,
      billingAddress,
    }
  }

  /**
   * @description Handles Payment Add to Cart Logic
   * Before Adding Payments to Cart, Communication Preference is Patched to cart and,
   * Billing Address Validation API is triggered and Address Suggestion Modal is Shown.
   * Upon selecting an Address, AddPayment APi is triggered.
   */
  handlePayment = async paymentData => {
    if (
      !this.cvvValidFlag &&
      !tokenExContainer?.tokenExValidData?.isCvvValid &&
      paymentData?.tokenExData?.token
    ) {
      checkoutContainer.isPaymentApiInProgress = false
      return
    }
    checkoutContainer.isPaymentApiInProgress = true
    const { onContinue, isMultipleAdressActive } = this.props
    const data = cartContainer.cartResponse?.deliveryDetails?.address || ''
    const splitData = this.splitData({
      data,
      paymentData,
    })

    const {
      isFromSavedAddress = false,
      isPONumber = '',
      setToDefaultPayment = false,
      isRewardPoints,
    } = paymentData

    const communicationPreferenceData = isMultipleAdressActive
      ? splitData.billingAddress
      : data

    const communicationResponse =
      await checkoutContainer.setCommunicationPreference(
        communicationPreferenceData
      )

    if (!checkoutContainer.isSuccessResponse(communicationResponse)) {
      checkoutContainer.isPaymentApiInProgress = false
      toastState.setToastMessage(
        communicationResponse?.message || communicationResponse?.errorMessage
      )
      return
    }

    const finalData = isPONumber
      ? {
          cardType: 'PURCHASEORDER',
          isDefault: setToDefaultPayment,
          ...paymentData,
          billingAddress: data,
        }
      : isFromSavedAddress || isRewardPoints
      ? paymentData
      : splitData
    delete paymentData.setToDefaultPayment
    if (!paymentData.billingSameAsShipping && !isFromSavedAddress) {
      delete paymentData.cardFirstName
      delete paymentData.cardLastName
      delete paymentData.cardNumber
      delete paymentData.expiryMonth
      delete paymentData.expiryYear
      delete paymentData.expiryDate
      delete paymentData.cardCVV
      delete paymentData.billingSameAsShipping
      delete paymentData.addPaymentToProfile
      delete paymentData.paymentMethodType
      const paymentValue = {
        ...paymentData,
      }
      if (
        checkoutContainer.selectedMarket === 'US' ||
        checkoutContainer.selectedMarket === 'CA'
      ) {
        const res = await addressContainer.validateAddress(paymentValue)

        if (res?.code === 'ADCOVAL0003') {
          this.setState({
            isToShowAddressValidationModal: true,
            addressInvalidFlag: true,
            recommendations: [{ ...paymentValue }, []],
            formDataEnter: { ...paymentValue },
            params: { ...finalData },
          })
        } else if (res?.code == 'ONESOURCE_ADPLGIN_VAL_ERROR') {
          this.setState({
            isToShowAddressValidationModal: false,
          })
          checkoutContainer.isPaymentApiInProgress = false
          toastState.setToastMessage(
            i18nTranslate('address.NotAdded', 'Address not added')
          )
          return
        } else {
          if (
            res?.address?.validationDetails?.[0]?.recommendations?.[0] &&
            handleAddressValidation(
              paymentValue,
              res?.address?.validationDetails?.[0]?.recommendations?.[0]
            )
          ) {
            this.setState({
              isToShowAddressValidationModal: true,
              addressInvalidFlag: false,
              recommendations: [
                { ...paymentValue },
                ...res?.address?.validationDetails?.[0]?.recommendations,
              ],
              params: { ...finalData },
              formDataEnter: { ...paymentValue },
            })
          } else {
            this.setState({
              params: { ...finalData },
            })
            this.handleClickOnPro(paymentValue)
          }
        }
      } else {
        this.setState({
          addressInvalidFlag: true,
          recommendations: [{ ...paymentValue }, []],
          formDataEnter: { ...paymentValue },
          params: { ...finalData },
        })
        this.handleClickOnPro(paymentData)
      }
    } else {
      const response = await checkoutContainer.addPaymentsToCart(finalData)
      if (checkoutContainer.isSuccessResponse(response)) {
        checkoutContainer.isToInitiateGlobalPayment = true
        await cartContainer.viewCart()
        trackCustomCheckoutEvent({
          eventType: 'payment',
          cartResponse: cartContainer?.cartResponse,
        })
        onContinue()
      } else {
        if (response.code === 'PYMT_DUP_ENTRY_409') {
          toastState.setToastMessage(
            i18nTranslate(
              'payment.duplicatePaymentCard',
              'Payment card already exists'
            ),
            false
          )
        } else {
          toastState.setToastMessage(response.message)
        }
      }
    }
    checkoutContainer.isPaymentApiInProgress = false
    checkoutContainer.isToDisableSubmitOrderButton = false
  }

  /**
   * @description Address Validation API is triggered to validate User Selected Billing Address
   * Post that, Add Payments API is triggered to patch Payments to Cart.
   * @param {Object} formData
   */
  async handleClickOnPro(formData) {
    const { accountInfo, isDefault } = this.state.params
    const { onContinue } = this.props

    const reqParams = {
      name: formData?.firstName,
      type: 'CREDITCARD',
      accountInfo,
      billingAddress: {
        ...formData,
        email: this.state.params.billingAddress?.email,
        option: {
          addPaymentToProfile:
            this.state.params.billingAddress?.option?.addPaymentToProfile,
        },
      },
      isDefault,
    }
    let response = {}
    if (formData?.selectedIndex <= 0) {
      response = await checkoutContainer?.validateOriginalAddress(reqParams)
    } else {
      response = await checkoutContainer?.addPaymentsToCart(reqParams)
    }
    if (checkoutContainer.isSuccessResponse(response)) {
      checkoutContainer.isToInitiateGlobalPayment = true
      await cartContainer.viewCart()
      trackCustomCheckoutEvent({
        eventType: 'payment',
        cartResponse: cartContainer?.cartResponse,
      })
      onContinue()
    } else {
      if (response?.code === 'PYMT_DUP_ENTRY_409') {
        toastState.setToastMessage(
          i18nTranslate(
            'payment.duplicatePaymentCard',
            'Payment card already exists'
          ),
          false
        )
      } else {
        const errorMessage =
          response?.message ||
          i18nTranslate(
            'payment.addPaymentFailure',
            'Sorry, we are unable to add your payment information. Please try again.'
          )
        toastState.setToastMessage(errorMessage)
      }
    }
    this.handleAddressValidationModalClose()
  }

  /**
   * @description Closes the address validation modal.
   */
  handleAddressValidationModalClose = () => {
    this.setState({
      isToShowAddressValidationModal: false,
    })
  }

  render() {
    if (this.isPaymentsApiLoaded) {
      return (
        <div>
          <GlobalPaymentsOptionsList
            paymentList={this.paymentList}
            initiallySelectedPayment={this.initiallySelectedPayment}
            handlePayment={this.handlePayment}
            isFromCheckout={this.props.isFromCheckout || false}
            onContinue={this.props.onContinue}
            title={this.props?.title}
            changeCvvValidFlag={this.changeCvvValidFlag}
          />
          {this.state.isToShowAddressValidationModal && (
            <AddressValidation
              open={this.state.isToShowAddressValidationModal}
              addressInvalidFlag={this.state.addressInvalidFlag}
              recommendations={this.state.recommendations}
              handleClose={() => this.handleAddressValidationModalClose()}
              handleClickOnPro={dataAfterValidation =>
                this.handleClickOnPro(dataAfterValidation)
              }
            />
          )}
        </div>
      )
    }

    return <></>
  }
}

export { GlobalPaymentAccordion }
export default GlobalPaymentAccordion
