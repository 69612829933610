import * as React from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Button } from 'react-bootstrap'
import { GrClose } from 'react-icons/gr'
import { toastState } from './ToastState'
import {
  BsFillExclamationCircleFill,
  BsFillCheckCircleFill,
  BsFillExclamationTriangleFill,
  BsFillInfoCircleFill,
  BsXLg,
} from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { alertMessageKeys } from '../CartBlock/fixture'
import './styles.scss'
import { i18nTranslate } from 'src/utils'
import { pageNames } from 'src/routes/pathParams'

@observer
class Toast extends React.Component {
  render() {
    const { animationDelay, offSet } = toastState.toastAnimationAttributes
    const icons = {
      'live-event-toast-info': <BsXLg />,
      'live-event-toast-error': (
        <BsFillExclamationTriangleFill size="24px" className="error-icon red" />
      ),
    }
    if (!toastState.toastMessage) {
      return <></>
    }
    return (
      <Row
        noGutters
        style={{
          animationDuration: `${animationDelay}ms`,
        }}
        className={`toast-wrapper m-auto p-3 ${
          toastState.isVisible ? 'show' : ''
        } ${offSet} ${
          toastState.isViewCartBtnVisible ? 'view-cart-width' : ''
        } ${
          toastState?.isLiveEventEnabled === true ? 'live-event-enabled' : ''
        } ${toastState?.customClass || ''}`}
        data-testid="qa-toast">
        {toastState.successCondition === '' ||
        toastState?.isLiveEventEnabled === true ? (
          ''
        ) : (
          <Col xs={1}>
            {toastState.successCondition == true ? (
              <BsFillCheckCircleFill
                size="1rem"
                className={`tick-icon green`}
              />
            ) : (
              <BsFillExclamationCircleFill
                size="1rem"
                className={`tick-icon red`}
              />
            )}
          </Col>
        )}
        <Col
          lg={10}
          className={`toast-content m-0 d-flex flex-wrap align-items-center ${
            toastState.isViewCartBtnVisible
              ? 'justify-content-center view-cart-width'
              : ''
          }
          ${toastState?.isLiveEventEnabled === true ? '' : 'text-center'}
          `}>
          {toastState?.isLiveEventEnabled &&
            toastState?.customClass === 'live-event-toast-info' && (
              <div className="live-event-toast-info-icon">
                <BsFillInfoCircleFill size="24px" className="info-icon blue" />
              </div>
            )}
          {toastState.reDirection !== '' ? (
            <Link
              className={`${toastState.isViewCartBtnVisible ? 'pb-2' : ''}`}
              to={toastState.reDirection}>
              <b role="alert">
                {alertMessageKeys(toastState?.toastMessage?.trim?.() || '')}
              </b>
            </Link>
          ) : (
            <>
              <b role="alert">
                {alertMessageKeys(toastState?.toastMessage?.trim?.() || '')}
              </b>
              {toastState?.isLiveEventEnabled === true && (
                <p>{toastState?.description}</p>
              )}
            </>
          )}
          {toastState.isViewCartBtnVisible && (
            <div className="ml-3">
              <Link to={pageNames.viewCart}>
                <Button
                  data-testid="qa-cartcheckout"
                  type="button"
                  className="checkout-button btn btn-primary">
                  {i18nTranslate('cart.viewCart', 'View Cart')}
                </Button>
              </Link>
            </div>
          )}
        </Col>

        <Col xs={1} className="close-icon-wrapper">
          <div
            className="toast-button w-100 p-0 text-capitalize border-0 "
            onClick={toastState.hideVisibility}>
            {toastState?.isLiveEventEnabled &&
            icons[toastState?.customClass] ? (
              icons[toastState?.customClass]
            ) : (
              <GrClose />
            )}
          </div>
        </Col>
      </Row>
    )
  }
}

export { Toast }
export default Toast
