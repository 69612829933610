import { observable, action } from 'mobx'
import { CommonContainer, customerContainer } from 'src/models'
import { toastState } from 'src/views/components'
import { i18nTranslate } from 'src/utils'
import {
  getConvertedMonth,
  setLocalStorage,
  IS_BROWSER,
  getBrowserIpAddress,
  trackErrorInInstana,
} from 'src/utils'
import React from 'react'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import Popover from 'react-bootstrap/Popover'
import { APPConfig } from 'config/appConfig'
import { Image } from 'react-bootstrap'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'

/**
 * SubscriptionContainer class that extends CommonContainer.
 * Likely contains subscription related state and actions.
 */
class SubscriptionContainer extends CommonContainer {
  @observable frequencyEndDate = ''
  @observable frequencyEndType = ''
  @observable subscriptionPreferenceResponse = ''
  @observable subscriptionShippingAddress = {}
  @observable subscriptionShippingMethods = {}
  @observable subscriptionShippingContent = {}
  @observable subscriptionPaymentData = {}
  @observable subscriptionCartCount = 0
  @observable subscriptionCartResponse = {}
  @observable subscriptionDateResponse = {}
  @observable subscriptionData = {}
  @observable disableSubscriptionSkuSelection = false
  @observable shouldClearCart = true
  @observable editSubscriptionData = {}
  @observable subscriptionFailure = false
  @observable isSubscriptionLoading = true
  @observable shouldShowListView = true
  @observable showAllSubscriptionResponse = []
  @observable flexibleSubscriptionResponse = []
  @observable subscriptionPromoResponse = []
  @observable subscriptionAddressResponse = ''
  @observable selectedSubscriptionProducts = new Set()
  @observable isDeleteSubsCallInProgress = false
  @observable selectedProductsTotalPV = 0.0
  @observable selectedPvItems = ''
  @observable pvTotal = '0'
  @observable costCenterId = ''
  @observable patchFrequencyResponse = []
  @observable allUsers = false
  @observable selectedSubscription = []
  @observable subscriptionShowAllDeliveryStatus = []
  @observable ccExpiryFlag = false
  @observable ccCardExpired = false
  @observable subscriptionActiveTab = 'schedule'
  scanQualifiedCount = 0
  selectedMonth = ''
  isPreferenceAPIInProgress = true
  isSuggestionSelected = false //to skip address validation for suggested address
  // `shouldClearCart` is to clear cart on page reload
  @observable subscriptionMessage = ''
  @observable isPreferenceCallOver = false
  @observable subscriptionSelectedDate = ''
  @observable subscriptionShowAllResponse = {}

  @action setIsPreferenceCallOver(val) {
    this.isPreferenceCallOver = val
  }

  /**
   * Gets all subscriptions.
   * @param {Object} subscriptionRefineData - Filter parameters
   * @param {Object} params - Additional parameters
   * @returns {Promise} Response from the API
   */
  getAllSubscriptions = async (subscriptionRefineData, params) => {
    let filterParams = {
      ...params,
    }

    if (subscriptionRefineData) {
      filterParams.filter = JSON.stringify(subscriptionRefineData)
    }

    const loadParams = {
      endPointName: 'getAllSubscriptions',
      pathParams: '',
      queryParams: filterParams,
    }

    const response = await this.fetchResponse(loadParams)
    this.subscriptionFailure = response?.isServiceFailure || false
    this.subscriptionData = response
    return response
  }
  /**
   * Gets the shipping address for the given user's subscription from the API.
   * @param {string} userId - The ID of the user to get the subscription shipping address for
   * @returns {Promise} A promise that resolves to the API response
   */
  getSubscriptionShippingAddress = async userId => {
    const loadParams = {
      endPointName: 'getAllSubscriptionsPreference',
      pathParams: `${userId}/address`,
      queryParams: { timestamp: new Date().getTime() }, //CX121-4652
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.subscriptionAddressResponse = response
    }
    return response
  }
  /**
   * Gets the subscription order status for a user.
   * @param {Object} options - The options object
   * @param {string} options.userId - The ID of the user to get the subscription order status for
   * @returns {Promise} A promise that resolves to the API response containing the subscription order status
   */
  getSubscriptionOrderStatus = async options => {
    const { userId } = options
    const loadParams = {
      endPointName: 'getAllSubscriptionsPreference',
      pathParams: userId,
      queryParams: {
        preferenceLookup: true,
      },
    }
    return await this.fetchResponse(loadParams)
  }
  /**
   * Gets the subscription month and related status flags for a user based on their order status.
   * @param {Object} options - The options object
   * @param {Object} options.orderStatus - The subscription order status response for the user
   * @returns {Object} An object with flags indicating if the next month's subscription should be fetched,
   * the current subscription month, and if the subscription is available
   */
  getSubscriptionMonth = options => {
    const { orderStatus } = options || {}
    let isSubscriptionAvailable = true
    let isToFetchNextMonthSubscription = false
    const subscriptionMonth = new Date().getMonth() + 1

    if (orderStatus && this.isSuccessResponse(orderStatus)) {
      const lastOrderProcessingDate = orderStatus?.lastOrderProcessingDate || 0
      const timeZone = orderStatus?.timeZone || 'US/Mountain'
      const lastOrderMonth = getConvertedMonth(
        lastOrderProcessingDate,
        timeZone
      )
      const lastSubscriptionOrderStatus = orderStatus?.lastOrderStatus || ''
      if (
        lastSubscriptionOrderStatus.toLowerCase() == 'order_success' &&
        lastOrderMonth == subscriptionMonth
      ) {
        isToFetchNextMonthSubscription = true
      }
    } else {
      isSubscriptionAvailable = false
    }

    return {
      isToFetchNextMonthSubscription,
      subscriptionMonth,
      isSubscriptionAvailable,
    }
  }
  /**
   * Fetches the subscription preference for a user.
   *
   * @param {Object} options - Options object
   * @param {string} options.userId - User ID
   * @param {boolean} options.showAll - Whether to show all subscriptions
   * @param {boolean} options.isToFetchNextMonthSubscription - Whether to fetch next month's subscription
   * @param {number} options.subscriptionMonth - The current subscription month
   * @returns {Promise} Promise resolving to the API response
   */
  fetchSubsriptionPreference = async options => {
    const {
      userId,
      showAll,
      showAllShippingmethodUpdate,
      isToFetchNextMonthSubscription,
      subscriptionMonth,
      date = '',
    } = options || {}
    let { month } = options || {}

    let paramsForShipMethods = {}
    let resForShipMethods = {}

    if (isToFetchNextMonthSubscription && !showAll) {
      const nextMonthSubscription =
        subscriptionMonth == 12 ? 1 : subscriptionMonth + 1
      month = date ? month : nextMonthSubscription
    }

    const loadParams = {
      endPointName: 'getAllSubscriptionsPreference',
      pathParams: userId,
    }
    if (month && month !== 'pvassistant') {
      if (date) {
        loadParams.queryParams = {
          month: month,
          recurrenceOrderProcessingDate: date,
        }
      } else {
        loadParams.queryParams = {
          month: month,
        }
      }
    } else if (showAll) {
      loadParams.queryParams = {
        showAll: true,
      }

      paramsForShipMethods = {
        endPointName: 'getAllSubscriptionsPreference',
        pathParams: userId,
        queryParams: {
          timestamp: new Date().getTime(),
        },
      }
    }

    //CX121-4652
    loadParams.queryParams = {
      ...(loadParams?.queryParams || {}),
      timestamp: new Date().getTime(),
    }

    // let response = res
    let response = await this.fetchResponse(loadParams)
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    /**
     * @note
     * This call is required for 'shipping' and 'shippingMethods'
     * nodes which are not available in 'month' call
     */
    if (showAll) {
      if (!isFlexibleSubscriptionEnabled) {
        resForShipMethods = await this.fetchResponse(paramsForShipMethods)
      }
      this.subscriptionShowAllResponse = response
    }
    this.flexibleSubscriptionResponse = []
    this.isPreferenceAPIInProgress = false
    this.selectedSubscriptionProducts.clear()
    this.selectedProductsTotalPV = 0.0

    if (this.isSuccessResponse(response)) {
      this.subscriptionShippingContent = {}
      if (showAll) {
        if (
          this.isSuccessResponse(resForShipMethods) &&
          !isFlexibleSubscriptionEnabled
        ) {
          response.shipping = resForShipMethods?.shipping || {}
          response.shippingMethods = resForShipMethods?.shippingMethods || {}
        }
      }

      this.selectedSubscription =
        (response?.selectedSubscriptions?.[0]?.subscriptions).reverse() || []
      setLocalStorage('sub', this.selectedSubscription)
      this.showAllSubscriptionResponse = response?.selectedSubscriptions || []
      if (!showAll) {
        this.flexibleSubscriptionResponse =
          response?.selectedSubscriptions || []
      }
      if (typeof response?.shippingMethods != 'undefined' && showAll) {
        this.subscriptionShippingMethods = response.shippingMethods
      }
      if (response?.shipping?.method != null) {
        this.subscriptionShippingContent = response.shipping
      }
      this.subscriptionPreferenceResponse = response
    } else {
      this.subscriptionPreferenceResponse = response
      this.showAllSubscriptionResponse = []
      this.flexibleSubscriptionResponse = []
    }
    this.isPreferenceCallOver = true
    return response
  }
  /**
   * Gets all subscription preferences for a user.
   *
   * @param {string} userId - The user ID
   * @param {string} month - The month to get preferences for
   * @param {boolean} showAll - Whether to show all preferences
   * @param {boolean} showAllShippingmethodUpdate - Whether to show shipping method updates
   * @returns {Object} The subscription preference response
   */
  getAllSubscriptionsPreference = async (
    userId,
    month,
    showAll,
    showAllShippingmethodUpdate,
    date
  ) => {
    // const orderStatus = await this.getSubscriptionOrderStatus({ userId })
    // const {
    //   isToFetchNextMonthSubscription,
    //   subscriptionMonth,
    //   isSubscriptionAvailable,
    // } = this.getSubscriptionMonth({ orderStatus })
    const isToFetchNextMonthSubscription = false
    const subscriptionMonth = month
    const isSubscriptionAvailable = true
    if (isSubscriptionAvailable) {
      return await this.fetchSubsriptionPreference({
        userId,
        month,
        showAll,
        showAllShippingmethodUpdate,
        isToFetchNextMonthSubscription,
        subscriptionMonth,
        date,
      })
    } else {
      this.subscriptionPreferenceResponse = {}
      this.showAllSubscriptionResponse = []
      this.isPreferenceCallOver = true
    }
  }

  /**
   * Gets the promotional PV gifts response from the API.
   *
   * Makes a call to the getPromoPVGiftsList endpoint to get the list of promotional PV gifts.
   * Saves the response to the subscriptionPromoResponse property if successful.
   */
  getPromoPVGiftsResponse = async () => {
    const loadParams = {
      endPointName: 'getPromoPVGiftsList',
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.subscriptionPromoResponse = response.promotionPVGifts
    }
  }
  /**
   * Updates the subscription preference for the given user.
   *
   * @param {string} userId - The user ID
   * @param {Object} dataToPatch - The data to update in the preference
   * @returns {Object} The API response
   */
  updateSubscriptionsPreference = async (userId, dataToPatch) => {
    const loadParams = {
      endPointName: 'updateSubscriptionsPreference',
      pathParams: userId,
      postData: dataToPatch,
      queryParams: { timestamp: new Date().getTime() }, //CX121-4652
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Updates the subscription processing status to start processing now for the given user.
   */
  updateProcessingNowSubscriptions = async (
    userId,
    queryParamData,
    date = ''
  ) => {
    const postData = {}
    if (IS_BROWSER && window?._bcn != undefined) {
      const deviceToken = window?._bcn?.getToken() || ''
      const deviceId = window?._bcn?.dvc?.getTID() || ''
      const ipAddress = (await getBrowserIpAddress()) || ''

      postData.properties = {
        deviceToken: deviceToken,
        deviceId: deviceId,
        ipAddress: ipAddress,
      }
    }

    const loadParams = {
      endPointName: 'postSubscriptionsPreference',
      pathParams: `${userId}`,
      queryParams: {
        ...(queryParamData || {}),
        timestamp: new Date().getTime(),
      }, //CX121-4652
      postData: postData,
    }
    if (date) {
      loadParams.queryParams.recurrenceOrderProcessingDate = date
    }

    trackErrorInInstana({
      errorReport: `Subscription - Triggered Process Now for User - ${userId}`,
      errorData: loadParams,
    })

    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Gets the subscription shipping methods for the given user by calling the subscriptions preference API.
   * @param {string} userId - The user ID
   * @param {Object} postData - The data to send in the API request body
   * @returns {Object} The API response
   */
  getSubscriptionsPreferenceShippingMethods = async (userId, postData) => {
    const loadParams = {
      endPointName: 'postSubscriptionsPreference',
      pathParams: `${userId}/shipping`,
      postData: postData,
    }

    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.subscriptionShippingMethods = response.shippingMethods
    }
    return response
  }

  /**
   * Gets subscription details by ID.
   *
   * @param {string} subscriptionId - The subscription ID
   * @returns {Object} The API response containing the subscription details
   */
  getSubscriptionById = async subscriptionId => {
    const loadParams = {
      endPointName: 'getSubscriptionById',
      pathParams: subscriptionId,
    }
    const response = await this.fetchResponse(loadParams)
    this.subscriptionFailure = response?.isServiceFailure || false
    const apiStatus = response?.apiStatus || success
    this.subscriptionFailure = apiStatus == 'failure' ? true : false
    this.editSubscriptionData = response
    this.subscriptionShippingAddress = response?.deliveryDetails?.address || {}

    return response
  }

  /**
   * Gets the current subscription cart details.
   *
   * Makes API call to view the subscription cart.
   * Handles clearing cart if needed.
   * Updates component state with latest cart response, count and clearing logic.
   *
   * @returns {Object} The API response with subscription cart details
   */
  viewSubscriptionCart = async () => {
    const loadParams = {
      endPointName: 'viewSubscriptionCart',
      queryParams: {
        timestamp: new Date().getTime(),
      },
    }
    const response = await this.fetchResponse(loadParams)
    const cartCount = response?.value?.count || 0
    if (this.shouldClearCart && cartCount) {
      await this.deleteSubscriptionCartItem()
    } else {
      this.subscriptionCartResponse = response
      this.subscriptionCartCount = cartCount
    }
    this.shouldClearCart = false
    // make shouldClearCart false once the first viewSubscriptionCart call is made
    // i.e, shouldClearCart will be `true` only on init
    return response
  }
  /**
   * Updates the subscription cart with the provided data.
   *
   * @param {Object} dataToPatch - Data to update on the subscription cart
   * @returns {Object} API response from updating the subscription cart
   */
  patchSubscriptionCart = async dataToPatch => {
    const loadParams = {
      endPointName: 'updateSubscriptionCartAttributes',
      postData: dataToPatch,
    }
    const response = await this.fetchResponse(loadParams)
    this.patchFrequencyResponse = response
    return response
  }
  /**
   * Updates the quantity of an item in the subscription cart.
   *
   * @param {Object} cartDetails - Details of cart item to update, containing quantity and id
   */
  updateSubscriptionCartItem = async cartDetails => {
    const { quantity, id } = cartDetails
    const loadParams = {
      endPointName: 'updateSubscriptionCartItem',
      pathParams: id,
      postData: { quantity },
    }
    await this.fetchResponse(loadParams)
    await this.viewSubscriptionCart()
  }
  /**
   * Adds subscription data to the cart.
   * Clears existing cart items first since only single item subscriptions are supported currently.
   * Maps over subscription data to build post data for the API request.
   * Makes additional requests to set cart properties like frequency.
   * Returns final cart response, or first failed response if errors occur.
   */
  addToSubscriptionCart = async subscriptionData => {
    if (this.subscriptionCartCount > 0) {
      // as per PI3 only single item subscription is supported
      // so as per Architect Vanchi's suggestion
      // we have to empty the cart before adding any sku to subscription cart
      await this.deleteSubscriptionCartItem()
    }
    const postData = subscriptionData.map(cart => {
      const { quantity, skuId = '', productId = '', skus = [], slug } = cart
      const skuDetails = skus.length
        ? skus
        : [{ skuId, productId, type: 'DEFAULT' }]
      return {
        quantity,
        skus: skuDetails,
        properties: {
          preparationPeriod: cart.preparationPeriod || 1,
          slug: slug,
        },
      }
    })
    const loadParams = {
      endPointName: 'addToSubscriptionCart',
      postData,
    }
    let responseToReturn = ''
    const addSubscriptionResponse = await this.fetchResponse(loadParams)
    const twoYearsFromNow = new Date()
    twoYearsFromNow.setFullYear(twoYearsFromNow.getFullYear() + 2)

    if (this.isSuccessResponse(addSubscriptionResponse)) {
      const patchFrequencyResponse = await this.patchSubscriptionCart({
        properties: {
          // @todo if type is 'NEVER', frequencyEndDate need not be sent
          // for now we have only 'NEVER' type as default, so commented frequencyEndDate
          // frequencyEndDate: this.frequencyEndDate,
          frequencyEndDate: twoYearsFromNow.getTime(),
          frequencyEndType: this.frequencyEndType,
          frequencyType: subscriptionData[0].selectedFrequencyType,
          frequencyPeriod: subscriptionData[0].selectedFrequencyPeriod,
          type: 'VARIABLE_MULTI_ORDER',
        },
      })
      if (!this.isSuccessResponse(patchFrequencyResponse)) {
        // return patch response to handle patch failure
        responseToReturn = patchFrequencyResponse
        this.patchFrequencyResponse = patchFrequencyResponse
      }
    } else {
      // return add response to handle add failure
      responseToReturn = addSubscriptionResponse
    }
    const subscriptionCartResponse = await this.viewSubscriptionCart()
    return responseToReturn !== '' ? responseToReturn : subscriptionCartResponse
  }
  /**
   * Deletes all items in the subscription cart.
   *
   * @param {boolean} toShowToast - Whether to show a toast notification after deleting the items. Defaults to false.
   * @returns {Promise} Promise that resolves after deleting the items and viewing the updated cart.
   */
  deleteSubscriptionCartItem = async (toShowToast = false) => {
    // deletes all items in subscription cart
    const loadParams = {
      endPointName: 'deleteSubscriptionCartItem',
      // pathParams: cartItemId,
    }
    await this.fetchResponse(loadParams)

    await this.viewSubscriptionCart()
  }
  /**
   * Sets the shipping address for the subscription cart.
   *
   * @param {Object} formData - Object containing the address details.
   * @returns {Promise} Promise that resolves after updating the shipping address and viewing the updated cart.
   */
  setSubscriptionShippingAddress = async formData => {
    const shippingAddressPostData = {
      deliveryDetails: {
        deliveryType: 'PHYSICAL',
        address: {
          ...formData,
          canReceiveSMS: false,
          validation: [
            {
              type: 'PHYSICAL',
              validated: true,
              overridden: true,
            },
          ],
        },
      },
    }
    const loadParams = {
      endPointName: 'updateSubscriptionCartAttributes',
      postData: shippingAddressPostData,
    }
    const response = await this.fetchResponse(loadParams)
    await this.viewSubscriptionCart()
    return response
  }
  /**
   * Sets the communication preference for the subscription cart.
   *
   * @param {Object} formData - Object containing the communication preference details like first name, last name, email, phone and zip code
   * @returns {Promise} Promise that resolves after updating the communication preference and viewing the updated cart
   */
  setSubscriptionCommunicationPreference = async formData => {
    const postData = {
      communicationPreference: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        zipCode: formData.zip,
        preferredContactMethod: 'EMAIL',
      },
    }
    const loadParams = {
      endPointName: 'updateSubscriptionCartAttributes',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    await this.viewSubscriptionCart()
    return response
  }
  /**
   * Deletes the payment from the subscription cart.
   *
   * @returns {Promise} Promise that resolves after deleting the payment and returning the response.
   */
  deleteSubscriptionPayment = async () => {
    const loadParams = {
      endPointName: 'deletePaymentsFromSubscription',
      queryParams: { timestamp: new Date().getTime() }, //CX121-4652
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  /**
   * Adds payment details to the subscription cart.
   *
   * @param {Object} paymentData - Object containing payment details like billing address, card info etc.
   * @returns {Promise} Promise that resolves after adding payment to cart and viewing updated cart.
   */
  addPaymentsToSubscription = async paymentData => {
    // const cardType = getCardType(paymentData.accountInfo.cardNumber)
    const bagValue =
      this.subscriptionCartResponse?.paymentValue?.totalAmountUnpaid || ''

    const cardDetails = {}

    cardDetails.accountInfo = paymentData.accountInfo
    if (paymentData.isFromSavedAddress) {
      cardDetails.paymentItems = [{ paymentRefId: paymentData?.id }]
      cardDetails.accountInfo = { cvv: paymentData.cardCVV }
    }
    const cardType = paymentData.cardType ? paymentData.cardType : 'CREDITCARD'
    const loadParams = {
      endPointName: 'addPaymentToSubscription',
      postData: [
        {
          billingAddress: paymentData.billingAddress,
          ...cardDetails,
          type: cardType,
          valueType: 'CURRENCY',
          value: bagValue,
          amount: bagValue,
          option: {
            addPaymentToProfile: false,
            useDefaultPayment: false,
            setAsDefaultPaymentInProfile: false,
          },
        },
      ],
      queryParams: { timestamp: new Date().getTime() }, //CX121-4652
    }
    let response = await this.fetchResponse(loadParams)
    await this.viewSubscriptionCart()
    if (!this.isSuccessResponse(response)) {
      await this.deleteSubscriptionPayment()
      response = await this.fetchResponse(loadParams)
      await this.viewSubscriptionCart()
    }
    return response
  }
  /**
   * Calculates the subscription date based on the given query parameters.
   *
   * @param {Object} [queryParam={}] - The query parameters to use for the subscription date calculation.
   * @returns {Promise} A promise that resolves to the API response.
   */
  calculateSubscriptionDate = async (queryParam = {}) => {
    const loadParams = {
      endPointName: 'calculateSubscriptionDate',
      queryParams: queryParam,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.subscriptionDateResponse = response
    } else {
      toastState.setToastMessage(response.message || '')
    }
    return response
  }
  /**
   * Confirms the subscription by calling the confirmSubscription API endpoint.
   *
   * @returns {Promise} The API response from confirming the subscription.
   */
  confirmSubscription = async () => {
    const loadParams = {
      endPointName: 'confirmSubscription',
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  /**
   * Updates a subscription by calling the updateSubscription API endpoint.
   *
   * @param {Object} subscriptionData - The subscription data to update.
   * @param {boolean} isFromSubscription - Whether the update originated from the subscription flow.
   * @returns {Promise} The API response from updating the subscription.
   */
  updateSubscription = async (subscriptionData, isFromSubscription = false) => {
    const loadParams = {
      endPointName: 'updateSubscription',
      pathParams: subscriptionData.subscriptionId,
      postData: subscriptionData.patchProperties,
      queryParams: { timestamp: new Date().getTime() }, //CX121-4652
    }
    const response = await this.fetchResponse(loadParams)
    if (!isFromSubscription) {
      if (this.isSuccessResponse(response)) {
        toastState.setToastMessage(
          i18nTranslate(
            'subscription.updateSuccess',
            'Your subscription has been updated.'
          ),
          true
        )
      } else {
        response.code !== 'error.subscription.editfreeze.date.reached' &&
          response.message &&
          toastState.setToastMessage(response.message || '')
      }
      this.getSubscriptionById(loadParams.pathParams)
    }
    return response
  }
  /**
   * Cancels all subscriptions for the given user by calling the cancelAllSubscription API endpoint.
   *
   * @param {Object} subscriptionData - Data about the subscription to cancel.
   * @param {boolean} isFromSubscription - Whether the cancellation originated from the subscription flow.
   * @returns {Promise} The API response from cancelling all subscriptions.
   */
  cancelAllSubscription = async (
    subscriptionData,
    isFromSubscription = false
  ) => {
    const loadParams = {
      endPointName: 'cancelAllSubscription',
      pathParams: subscriptionData.userId,
      postData: subscriptionData.patchProperties,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response) && !isFromSubscription) {
      toastState.setToastMessage(
        i18nTranslate(
          'subscription.updateSuccess',
          'Your subscription has been updated.'
        ),
        true
      )
    } else {
      response.message && toastState.setToastMessage(response.message || '')
    }
    if (!isFromSubscription) {
      this.getSubscriptionById(loadParams.pathParams)
    }
    return response
  }

  /**
   * Skips the next order for the given subscription.
   *
   * @param {Object} subscriptionData - Data about the subscription to skip.
   * @returns {Promise} The API response from skipping the subscription order.
   */
  skipSubscription = async subscriptionData => {
    const loadParams = {
      endPointName: 'skipSubscription',
      pathParams: `${subscriptionData.subscriptionId}/skipNextOrder/${subscriptionData.isSkippedSubscription}`,
      postData: {},
      queryParams: { month: subscriptionData.selectedMonth },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Adds products to the user's subscription or cart.
   *
   * @param {Array} subscriptionArray - Array of products to add.
   * @returns {Promise} API response from adding to subscription or cart.
   */
  addToSubscription = async (subscriptionArray = [], isFromNewPDP = false) => {
    const isPVReserved = subscriptionArray?.[0]?.isPVReserved || false
    const isSubscriptionPreference =
      subscriptionArray?.[0]?.isSubscriptionPreference
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    let endPoint = ''
    if (enableNewPDPStyle && isFromNewPDP) {
      endPoint = 'addToCart'
    } else {
      endPoint = isSubscriptionPreference ? 'addToSubscription' : 'addToCart'
    }

    const getPostData = subscriptionArray?.map(item => {
      const {
        quantity,
        skuId = '',
        productId = '',
        skus = [],
        frequencyPeriod,
        frequencyType,
        category = '',
        properties,
        frequencyOrder = 'VARIABLE_MULTI_ORDER',
        subscription = {},
        orderProcessedDate = '',
        month = '',
      } = item
      let skuObj = skus.skus || []
      const skuDetails =
        skuObj && skuObj.length
          ? skuObj
          : [
              {
                skuId,
                productId,
                type: 'DEFAULT',
              },
            ]
      let property = isPVReserved
        ? {
            categoryId: category,
            category: category,
            slug: properties?.slug,
            scanQualifiedCount: this.scanQualifiedCount,
            itempriority: subscriptionContainer.selectedPvItems.length + 1,
          }
        : {
            categoryId: category,
            category: category,
            slug: properties?.slug,
            scanQualifiedCount: this.scanQualifiedCount,
          }
      if (properties?.isBackOrdered) {
        property['isBackOrdered'] = properties?.isBackOrdered
        // @##backorder_logic_cart
      }
      return {
        quantity,
        skus: skuDetails,
        isSubscription: true,
        subscription: {
          frequencyPeriod,
          frequencyType,
          type: subscription?.type || frequencyOrder,
        },
        properties: property,
        orderProcessedDate,
        month,
      }
    })

    const loadParams = {
      endPointName: endPoint,
      postData: getPostData,
    }
    if (isPVReserved) {
      loadParams.queryParams = { isPVReserved: true }
    }
    const response = await this.fetchResponse(loadParams)
    if (endPoint == 'addToSubscription') {
      this.subscriptionMessage = response.message
    }
    return response
  }

  /**
   * Handles clicking on the subscription tab.
   * Checks if the subscription list view should be shown.
   * If not, sets the loading state to true and sets the flag to show the list view.
   */
  handleSubscriptionTabClick = () => {
    if (!subscriptionContainer.shouldShowListView) {
      subscriptionContainer.isSubscriptionLoading = true
      // fetchSubscriptionList()
      subscriptionContainer.shouldShowListView = true
    }
  }
  /**
   * Resets the subscription response.
   *
   * @param {boolean} showAll - Whether to show all subscriptions.
   * @param {string} month - The month to filter subscriptions by.
   * @param {boolean} allusers - Whether subscriptions are for all users or just the current user. Defaults to false.
   */
  resetSubscriptionResponse = (showAll, month, allusers = false) => {
    this.selectedSubscription = []
    this.isPreferenceAPIInProgress = true
    this.isPreferenceCallOver = false
    document.getElementsByClassName('month-list selected')[0] &&
      document
        .getElementsByClassName('month-list selected')[0]
        .classList.remove('selected')
    if (showAll) {
      this.showAllSubscriptionResponse = ''
    }
    if (allusers) subscriptionContainer.allUsers = allusers
    else {
      subscriptionContainer.allUsers = false
    }
  }
  /**
   * Deletes the given subscription products for the current user.
   *
   * @param {Set} selectedProducts - Set of product IDs to delete
   * @param {string} screenName - Name of the screen this is called from
   * @returns {Promise} Response from the delete subscription API call
   */
  deleteSubscriptionProducts = async (selectedProducts, screenName = '') => {
    this.isDeleteSubsCallInProgress = true
    const productStr = []
    if (selectedProducts.size != 0) {
      this.selectedSubscriptionProducts.forEach(productId => {
        return productStr.push(`${productId}`)
      })
    }
    const filterParams = [
      `{"field":"id","operation":"IN","value":"[${productStr}]"}`,
    ]
    const loadParams = {
      endPointName: 'cancelAllSubscription',
      pathParams: customerContainer.userId,
      queryParams: {
        filter: `{"filters":[${filterParams}]}`,
      },
      postData: { status: 'CANCELED' },
    }
    if (screenName === 'pvAssistPage') {
      loadParams.postData.type = 'VARIABLE_RESERVED'
    }
    const response = await this.fetchResponse(loadParams)

    if (this.isSuccessResponse(response)) {
      this.selectedSubscriptionProducts.clear()
      this.selectedProductsTotalPV = 0.0
      if (screenName === 'pvAssistPage') {
        toastState.setToastMessage(
          i18nTranslate(
            'pvAssistant.updateSuccess',
            'PV assistant item updated successfully'
          ),
          true
        )
      } else {
        toastState.setToastMessage(
          i18nTranslate(
            'subscription.updateSuccess',
            'Your subscription has been updated.'
          ),
          true
        )
      }
      this.selectedSubscription = []
    } else {
      const isServiceFailure = response?.isServiceFailure || false
      const errorCode = response?.responseCode || response?.code || ''
      const editFreezeErrorCode =
        errorCode === 'error.subscription.editfreeze.date.reached'
      const errorMessage = isServiceFailure
        ? i18nTranslate(
            'subscription.somethingwentwrong',
            'Sorry, something went wrong. Please try again.'
          )
        : editFreezeErrorCode
        ? i18nTranslate(
            'subscription.editFreezeErrorMessage',
            'This Subscription order is currently being processed and at this time no changes will be allowed. Please return after your Subscription order has shipped to make any changes to your next order.'
          )
        : (response && response.message) ||
          i18nTranslate(
            'subscription.failure',
            'Sorry, we are unable to update your subscription. Please try again.'
          )
      toastState.setToastMessage(errorMessage, false)
      // if selectedSubscriptionProducts has products already, then it must be deselected onClick
      subscriptionContainer.selectedSubscriptionProducts.clear()
      subscriptionContainer.selectedProductsTotalPV = 0.0
    }
    this.isDeleteSubsCallInProgress = false
    return response
  }

  /**
   * Updates the cost center ID associated with the user's subscription cart.
   *
   * @param {string} costCenterId - The ID of the cost center to associate with the subscription cart
   * @returns {Promise} A promise that resolves to the API response
   */
  updateCostCenterToSubscription = async costCenterId => {
    const loadParams = {
      endPointName: 'updateSubscriptionCartAttributes',
      postData: { costCenterId },
    }
    const response = await this.fetchResponse(loadParams)
    this.costCenterId = response.costCenterId
    return response
  }

  /**
   * Gets all PV Assist products for the given user ID.
   *
   * @param {string} userId - The user ID to get PV Assist products for
   * @returns {Promise} A promise that resolves to the API response
   */
  getAllPvAssistProduct = async userId => {
    const loadParams = {
      endPointName: 'getAllPvAssistItem',
      pathParams: userId,
    }
    let response = await this.fetchResponse(loadParams)
    this.selectedSubscriptionProducts.clear()
    if (this.isSuccessResponse(response)) {
      this.selectedPvItems =
        (response?.pvAssistSubscriptions?.[0]?.subscriptions)
          .slice()
          .reverse() || []
      this.pvTotal = response?.pvCartValue?.monthlyPV || '0'
    }
    return response
  }

  /**
   * Validates the cost center ID associated with the user's subscription cart.
   * Checks if the cost center has sufficient funds to place the order for the current cart total.
   *
   * @returns {Promise} A promise that resolves to the API response indicating if the cost center is valid
   */
  validateCostCenter = async () => {
    const bagValue =
      this.subscriptionCartResponse?.paymentValue?.totalAmountUnpaid || ''

    const loadParams = {
      endPointName: 'validateCostCenter',
      pathParams: `${customerContainer.accountId}/buyers/costcentres/${this.costCenterId}/validateOrderAmount`,
      queryParam: { amount: bagValue },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Patches the priority of PV Assist items for the given user.
   *
   * @param {string} userId - The user ID
   * @param {Object} dataToPatch - The data containing the PV Assist item IDs and priorities to patch
   * @returns {Promise} A promise that resolves to the API response
   */
  patchPvAssistItems = async (userId, dataToPatch) => {
    const loadParams = {
      endPointName: 'patchPvAssistItemsPriority',
      pathParams: `${userId}/pvassist/priorities`,
      postData: dataToPatch,
    }
    let response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.getAllPvAssistProduct(userId)
    }
    return response
  }

  /**
   * Gets bundle data for the given subscription item.
   * Parses the skus in the item's itemInfo and separates them into
   * mandatory, optional and bundle skus.
   *
   * @param {Object} subscriptionItem - The subscription item
   * @returns {Object} Object containing booleans indicating if item is a bundle,
   *  as well as arrays of the mandatory, optional and bundle skus
   */
  getBundleData = subscriptionItem => {
    const skus = subscriptionItem?.itemInfo[0]?.skus || []

    const mandatoryCount = [],
      optionalCount = [],
      bundle = []
    skus.forEach(sku => {
      const type = sku && sku.type ? sku.type.toLowerCase() : ''
      if (type === 'mandatory') {
        mandatoryCount.push(sku)
      } else if (type === 'optional') {
        optionalCount.push(sku)
      } else if (
        type === 'fixedbundle' ||
        type === 'bundle' ||
        type === 'skukit'
      ) {
        bundle.push(sku)
      }
    })
    return {
      isBundle: bundle.length > 0,
      bundle,
      mandatoryCount,
      optionalCount,
    }
  }
  /**
   * Handles item processing for a subscription
   * @param {Object} subscriptionData - The subscription data object
   * @returns {Object} The API response
   */
  handleItemProcessing = async subscriptionData => {
    const loadParams = {
      endPointName: 'postSubscriptionItemLevelProcessing',
      pathParams: `${subscriptionData.subscriptionId}`,
      postData: { enableItemLevelProcessing: false },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  /**
   * Renders a popover component to display free gift data
   * for a user's subscription.
   *
   * @param {Array} freeGiftsData - An array containing the free gift data
   * @returns {JSX.Element} - A Popover JSX element containing the rendered free gift data
   */
  renderFreeGiftSubscriptionPopover = freeGiftsData => {
    return (
      <Popover
        id="popover-free-gifts"
        className={`${
          freeGiftsData?.length > 1
            ? 'popoverFreeGiftTwoAndMore'
            : 'popoverFreeGiftOne'
        }`}>
        <Popover.Content>
          <div className="free-gift-container">
            {freeGiftsData?.map(freeGift => {
              const freeGiftImg =
                freeGift?.skus?.[0]?.skuProperties?.primaryimage ||
                imagePlaceholder
              const freeGiftName = freeGift?.skus?.[0]?.name || ''
              return (
                <>
                  <div
                    className={`free-gift-pdp-cont ${
                      freeGiftsData?.length > 1
                        ? 'contFreeGiftTwoAndMore'
                        : 'contFreeGiftOne'
                    }`}>
                    <div className="free-gift-pdp-cont1">
                      <div
                        className={`prod-image-wrapper d-flex justify-content-center`}>
                        <Image
                          src={freeGiftImg}
                          alt={freeGiftName}
                          className={`prod-img `}
                          style={{
                            width: '-webkit-fill-available',
                            objectFit: 'contain',
                          }}
                          data-testid="qa-user-sub-product-free-gift-image"
                        />
                      </div>
                      <p
                        className="prod-desc-title text-capitalize paragraph-m text-center mb-0"
                        data-testid="qa-user-sub-product-name">
                        {freeGiftName}
                      </p>
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </Popover.Content>
      </Popover>
    )
  }
}

const subscriptionContainer = new SubscriptionContainer()

export { SubscriptionContainer, subscriptionContainer }
export default subscriptionContainer
