import React from 'react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import isArray from 'lodash/isArray'
import {
  cartContainer,
  checkoutContainer,
  customerContainer,
  addressContainer,
  tokenExContainer,
  venmoContainer,
  paypalContainer,
  googlePayContainer,
  applepayContainer,
} from 'src/models'
import {
  PaymentListWithForm,
  toastState,
  AddressValidation,
} from 'src/views/components'
import { cardValidation } from './fixture'
import { getAppConfig } from 'config/appConfig'
import { handleAddressValidation } from 'src/deps'
import { getNamefromFirstname, trackCustomCheckoutEvent } from 'src/utils'

@observer
class PaymentAccordion extends React.Component {
  @observable paymentList = []
  @observable initiallySelectedPayment = []
  @observable flag = false
  @observable cvvValidFlag = false

  constructor(props) {
    super(props)

    this.state = {
      show: false,
      addressInvalidFlag: false,
      recommendations: [],
      params: {},
    }
  }

  @action
  changeCvvValidFlag = val => {
    this.cvvValidFlag = val
  }
  getPaymentDetails = async () => {
    let paymentsArr = cartContainer?.cartResponse?.payments || []
    const totalAmountUnpaid =
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || 0

    /**
     * @description
     * when grandtotal is paid with StoreCredit or loyalty completely,
     * user is not allowed to open CreditCard section and submit order button will be enabled
     * if user tries to force open CreditCard section,
     * then loyalty and StoreCredit payment will be auto deleted
     */
    if (
      checkoutContainer.alipayInCart === false &&
      checkoutContainer.isKlarnaPaymentAdded === false
    ) {
      await paymentsArr?.map(async payment => {
        let paymentDeleteFlag = true
        let paymentTypes = ['LOYALTY', 'STORECREDIT']

        if (totalAmountUnpaid !== 0 && paymentTypes?.includes(payment?.type)) {
          paymentDeleteFlag = false
        }

        paymentDeleteFlag &&
          (await checkoutContainer.deletePaymentById(payment.id))
      })
    }

    if (customerContainer.isRegisterUser) {
      const response = await customerContainer.getPayment()
      const transformedPaymentResponse =
        customerContainer.transformPaymentResponse(response) || {}
      if (checkoutContainer.isSuccessResponse(response)) {
        this.getDefaultCard(transformedPaymentResponse?.payments || [])
      }
    }
    this.flag = true
  }

  async componentDidMount() {
    checkoutContainer.isToDisableSubmitOrderButton = true
    paypalContainer.disablePaypalSDKButton()
    checkoutContainer.isToDisablePaymentContinueBtn = true
    await this.getPaymentDetails()
    getAppConfig()?.businessRelationship === 'B2C' && cartContainer.viewCart()
    checkoutContainer.checkAndDisablePaymentContinueBtn()
  }

  getDefaultCard = (response = []) => {
    response = response?.filter(card => card?.type !== 'PURCHASEORDER') || []

    if (Array.isArray(response) && response?.length > 0) {
      this.initiallySelectedPayment = response?.find(card => {
        return card.isDefault === true
      })
      // @note As per BA's Confirmation, Only Default Card should be pre-selected
      // if (!this.initiallySelectedPayment) {
      //   response[0].isDefault = true
      //   this.initiallySelectedPayment = response[0]
      // }
    }

    this.paymentList = response
  }

  splitData = props => {
    const { data, paymentData } = props
    const {
      cardFirstName,
      cardCVV,
      cardNumber,
      provider,
      expiryDate,
      setToDefaultPayment,
      addPaymentToProfile = false,
      ...remainingProps
    } = paymentData

    const card =
      cardNumber &&
      Object.entries(cardValidation).find(cardArr => {
        return cardNumber.match(cardArr[1])
      })

    delete paymentData.expiryDate
    const name = getNamefromFirstname(cardFirstName)
    const accountInfo = {
      name: name.firstName,
      lastName: name.lastName,
      cardNumber,
      type: provider,
      expiryMonth: expiryDate?.split('/')[0],
      expiryYear: '20' + expiryDate?.split('/')[1],
      cardCVV,
      ...(isArray(card) && card[0] && { provider: card[0] }),
    }
    const addressData = paymentData.billingSameAsShipping
      ? data
      : paymentData.isFromSavedAddress
      ? paymentData.billingAddress
      : remainingProps
    const email =
      addressData?.email || customerContainer?.profileResponse?.email || ''
    const billingAddress = {
      ...addressData,
      email,
      canReceiveSMS: false,
      option: {
        addAddressToProfile: false,
        addPaymentToProfile: addPaymentToProfile,
        overrideAddressValidation: false,
        useDefaultAddress: 'false',
        setAsDefaultAddressInProfile: false,
      },
      validation: [
        {
          type: 'PHYSICAL',
          validated: true,
          overridden: true,
        },
      ],
    }
    return {
      isDefault: setToDefaultPayment,
      accountInfo,
      billingAddress,
    }
  }

  handlePayment = async paymentData => {
    if (
      !this.cvvValidFlag &&
      !tokenExContainer?.tokenExValidData?.isCvvValid &&
      paymentData?.tokenExData?.token
    ) {
      checkoutContainer.isPaymentApiInProgress = false
      return
    }
    venmoContainer.venmoInitiate = false
    paypalContainer.paypalInitiate = false
    googlePayContainer.isToInitiateGooglePayPayment = false
    applepayContainer.applepayInitiate = false
    checkoutContainer.isPaymentApiInProgress = true
    const { onContinue, isMultipleAdressActive } = this.props
    // error scenerio to be handled here
    const data = cartContainer.cartResponse?.deliveryDetails?.address || ''
    const splitData = this.splitData({
      data,
      paymentData,
    })

    const { isFromSavedAddress } = paymentData

    const communicationPreferenceData = isMultipleAdressActive
      ? splitData.billingAddress
      : data

    const communicationResponse =
      await checkoutContainer.setCommunicationPreference(
        communicationPreferenceData
      )

    if (!checkoutContainer.isSuccessResponse(communicationResponse)) {
      checkoutContainer.isPaymentApiInProgress = false
      toastState.setToastMessage(
        communicationResponse.message || communicationResponse?.errorMessage
      )
      return
    }

    const finalData = paymentData.isPONumber
      ? {
          cardType: 'PURCHASEORDER',
          isDefault: paymentData.setToDefaultPayment,
          ...paymentData,
          billingAddress: data,
        }
      : isFromSavedAddress || paymentData.isRewardPoints
      ? paymentData
      : splitData
    delete paymentData.setToDefaultPayment
    if (!paymentData.billingSameAsShipping && !isFromSavedAddress) {
      delete paymentData.cardFirstName
      delete paymentData.cardLastName
      delete paymentData.cardNumber
      delete paymentData.expiryMonth
      delete paymentData.expiryYear
      delete paymentData.expiryDate
      delete paymentData.cardCVV
      delete paymentData.billingSameAsShipping
      delete paymentData.addPaymentToProfile
      delete paymentData.paymentMethodType
      const paymentValue = {
        ...paymentData,
        // country: storeContainer.storeIDValue,
      }
      if (
        checkoutContainer.selectedMarket === 'US' ||
        checkoutContainer.selectedMarket === 'CA'
      ) {
        const res = await addressContainer.validateAddress(paymentValue)

        if (res?.code === 'ADCOVAL0003') {
          this.setState({
            show: true,
            addressInvalidFlag: true,
            recommendations: [{ ...paymentValue }, []],
            formDataEnter: { ...paymentValue },
            params: { ...finalData },
          })
          if (isExpressCheckout()) {
            checkoutContainer.expressPaymentAdded = false
          }
        } else if (res?.code == 'ONESOURCE_ADPLGIN_VAL_ERROR') {
          this.setState({
            show: false,
          })
          if (isExpressCheckout()) {
            checkoutContainer.expressPaymentAdded = false
          }
          checkoutContainer.isPaymentApiInProgress = false
          toastState.setToastMessage(
            i18nTranslate('address.NotAdded', 'Address not added')
          )
          return
        } else {
          const recommendationAddress =
            res?.address?.validationDetails?.[0]?.recommendations || []
          let firstAddress = recommendationAddress?.[0] || {}
          if (
            Object.keys(firstAddress)?.length > 0 &&
            handleAddressValidation(paymentValue, firstAddress)
          ) {
            this.setState({
              show: true,
              addressInvalidFlag: false,
              recommendations: [{ ...paymentValue }, ...recommendationAddress],
              params: { ...finalData },
              formDataEnter: { ...paymentValue },
            })
          } else {
            this.setState({
              params: { ...finalData },
            })
            this.handleClickOnPro(paymentValue)
          }
        }
      } else {
        this.setState({
          addressInvalidFlag: true,
          recommendations: [{ ...paymentValue }, []],
          formDataEnter: { ...paymentValue },
          params: { ...finalData },
        })
        this.handleClickOnPro(paymentData)
      }
    } else {
      const response = await checkoutContainer.addPaymentsToCart(finalData)
      if (checkoutContainer.isSuccessResponse(response)) {
        await cartContainer.viewCart()
        if (checkoutContainer.enableAutoSubmit && isExpressCheckout()) {
          this.doExpressCheckout()
        }
        trackCustomCheckoutEvent({
          eventType: 'payment',
          cartResponse: cartContainer?.cartResponse,
        })
        onContinue()
      } else {
        if (response.code === 'PYMT_DUP_ENTRY_409') {
          toastState.setToastMessage(
            i18nTranslate(
              'payment.duplicatePaymentCard',
              'Payment card already exists'
            ),
            false
          )
        } else {
          toastState.setToastMessage(response.message)
        }
        if (isExpressCheckout()) {
          checkoutContainer.expressPaymentAdded = false
        }
      }
    }
    checkoutContainer.isPaymentApiInProgress = false
    checkoutContainer.isToDisableSubmitOrderButton = false
  }

  doExpressCheckout = async () => {
    checkoutContainer.isPaymentApiInProgress = false
    checkoutContainer.isToDisableSubmitOrderButton = false
    checkoutContainer.enableAutoSubmit = false
    checkoutContainer.autoPaymentSuccess = true
    const handlePaymentElement = document.getElementById('submit-order-button')
    if (handlePaymentElement) {
      handlePaymentElement.click()
    }
  }

  async handleClickOnPro(formData) {
    const { accountInfo, isDefault } = this.state.params
    const { onContinue } = this.props

    const reqParams = {
      name: formData.firstName,
      type: 'CREDITCARD',
      accountInfo,
      billingAddress: {
        ...formData,
        email: this.state.params.billingAddress.email,
        option: {
          addPaymentToProfile:
            this.state.params.billingAddress.option.addPaymentToProfile,
        },
      },
      isDefault,
    }
    let response = {}
    if (formData?.selectedIndex <= 0) {
      response = await checkoutContainer?.validateOriginalAddress(reqParams)
    } else {
      response = await checkoutContainer?.addPaymentsToCart(reqParams)
    }
    if (checkoutContainer.isSuccessResponse(response)) {
      await cartContainer.viewCart()
      trackCustomCheckoutEvent({
        eventType: 'payment',
        cartResponse: cartContainer?.cartResponse,
      })

      if (
        isExpressCheckout() &&
        !checkoutContainer.paymentBillingSameAsShippingFlag
      ) {
        document.getElementById('submit-order-button').click()
      }
      onContinue()
    } else {
      if (response?.code === 'PYMT_DUP_ENTRY_409') {
        toastState.setToastMessage(
          i18nTranslate(
            'payment.duplicatePaymentCard',
            'Payment card already exists'
          ),
          false
        )
      } else {
        const errorMessage =
          response?.message ||
          i18nTranslate(
            'payment.addPaymentFailure',
            'Sorry, we are unable to add your payment information. Please try again.'
          )
        toastState.setToastMessage(errorMessage)
      }
      if (isExpressCheckout()) {
        checkoutContainer.expressPaymentAdded = false
      }
    }
    this.handleClose()
  }

  handleClose() {
    this.setState({
      show: false,
    })
    if (isExpressCheckout()) {
      checkoutContainer.expressPaymentAdded = false
    }
  }

  renderBillingTitle = () => {
    return (
      <h3>{i18nTranslate('payment.billingAddressTitle', 'Billing Address')}</h3>
    )
  }

  render() {
    //const isGuestOrUser = customerContainer.isRegisterUser

    if (this.flag) {
      return (
        <div>
          <PaymentListWithForm
            multiAddressRenderProp={this.renderBillingTitle}
            paymentList={this.paymentList}
            initiallySelectedPayment={this.initiallySelectedPayment}
            handlePayment={this.handlePayment}
            isFromCheckout={this.props.isFromCheckout || false}
            isMultipleAdressActive={this.props.isMultipleAdressActive}
            handleAlipayInitiate={this.props.handleAlipayInitiate}
            handlePaypalInitiate={this.props.handlePaypalInitiate}
            handleVenmoInitiate={this.props.handleVenmoInitiate}
            handleKlarnaInitiate={this.props.handleKlarnaInitiate}
            handleGooglePayInitiate={this.props.handleGooglePayInitiate}
            handleApplePayInitiate={this.props.handleApplePayInitiate}
            onContinue={this.props.onContinue}
            title={this.props?.title}
            changeCvvValidFlag={this.changeCvvValidFlag}
          />
          {this.state.show && (
            <AddressValidation
              open={this.state.show}
              addressInvalidFlag={this.state.addressInvalidFlag}
              recommendations={this.state.recommendations}
              handleClose={() => this.handleClose()}
              handleClickOnPro={dataAfterValidation =>
                this.handleClickOnPro(dataAfterValidation)
              }
            />
          )}
        </div>
      )
    }

    return <React.Fragment />
  }
}

export { PaymentAccordion }
export default PaymentAccordion
