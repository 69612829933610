import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import groupBy from 'lodash/groupBy'
import { i18nTranslate } from 'src/utils'
import { Row, Col } from 'react-bootstrap'
import { SubProduct } from './SubProduct'
import {
  PDPBundleProductType,
  shouldShowAddedBundleDetails,
  groupBundleProductsByType,
} from 'config/appConfig'
import { AccordionBlock } from 'src/views/components'
import { application } from 'src/utils'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'

@observer
class BundlePDP extends React.Component {
  @observable openAccordionTiles = ['mandatory']
  @observable bundleOptionalProducts = {
    optional: this.props.product?.bundleOptionalProducts || [],
  }

  constructor(props) {
    super(props)
    if (groupBundleProductsByType) {
      const optionalProducts = props.product?.bundleOptionalProducts || []
      this.updateBundleOptionalProducts(optionalProducts)
    }
    // productState.makePromotionCallsForBundleKits(
    //   productState.product,
    //   productState.productCount
    // )
  }

  updateBundleOptionalProducts = (
    optionalProducts = (optionalProducts =
      this.props.product.bundleOptionalProducts)
  ) => {
    const groupedBundleProducts = groupBy(
      optionalProducts,
      product => product.properties?.bundletype || 'optional'
    )
    this.bundleOptionalProducts = groupedBundleProducts
    Object.keys(groupedBundleProducts)?.forEach((key, index) => {
      this.openAccordionTiles.push(
        key === 'optional' ? 'optional' : `optional${index}`
      )
    })
  }

  handleSkuSelection = (newData, index, type) => {
    if (type === 'mandatory') {
      if (
        this.props.product?.bundleMandatoryProducts &&
        this.props.product.bundleMandatoryProducts != 'undefined'
      ) {
        this.props.product.bundleMandatoryProducts[index] = newData
        productState.updateBundleKitInventoryStatus()
        // sku selection
      }
    } else {
      if (
        this.props.product?.bundleOptionalProducts &&
        this.props.product.bundleOptionalProducts != 'undefined'
      ) {
        this.props.product.bundleOptionalProducts[index] = newData
        this.updateBundleOptionalProducts(
          this.props.product?.bundleOptionalProducts
        )
      }
    }
  }

  renderSubProduct = (subProduct, index, type) => {
    const bundleType = this.props?.bundleType
    const inventory = this.props?.inventory

    return (
      <SubProduct
        type={type}
        key={`bundle_${index}`}
        subProduct={subProduct}
        index={index}
        updateBundleOptionalProducts={this.updateBundleOptionalProducts}
        handleSkuSelection={this.handleSkuSelection}
        bundleType={bundleType}
        inventory={inventory}
      />
    )
  }

  renderSubProductsWithTitle = (products, title, type) => {
    return (
      <div className="w-100">
        <Row noGutters className="w-100">
          <Col
            xs="2"
            className="justify-content-md-end d-flex order-md-2 align-items-center"
            data-testid="qa-count">
            <span className="pr-2">{products?.length}</span>
            <span>
              {i18nTranslate('product.itemsText', 'items', {
                nameSpace: 'ssr-resource',
              })}
            </span>
          </Col>
          <Col md="10" xs="12" className="order-md-1">
            <h4 data-test-id="qa-new-bundle-products" className="m-0">
              {title}
            </h4>
          </Col>
        </Row>
        {products?.map((subProducts, index) => {
          return this.renderSubProduct(subProducts, index, type)
        })}
      </div>
    )
  }

  toggleAccordion = type => {
    if (this.openAccordionTiles.includes(type)) {
      this.openAccordionTiles = this.openAccordionTiles?.filter(
        name => name !== type
      )
    } else {
      this.openAccordionTiles.push(type)
    }
  }

  renderAccordionView = ({ products, type, title, id }) => {
    return (
      <AccordionBlock
        onClick={() => this.toggleAccordion(id)}
        title={title}
        className="mb-2 px-2 py-3 border-bottom"
        hasArrow={true}
        isActive={this.openAccordionTiles.includes(id)}>
        <Row noGutters>
          {products?.map((subProducts, index) => {
            return (
              <Col
                lg={3}
                md={4}
                xs={6}
                key={subProducts.identifier}
                className="px-1 mb-2">
                {this.renderSubProduct(subProducts, index, type)}
              </Col>
            )
          })}
        </Row>
      </AccordionBlock>
    )
  }

  render() {
    const {
      bundleMandatoryProducts = [],
      bundleOptionalProducts = [],
      skuKit = [],
    } = this.props.product || {}
    if (PDPBundleProductType === 'grid') {
      return (
        <div
          className={`bundle-container ${
            shouldShowAddedBundleDetails && application.isDesktopOrLarger
              ? 'bundle-margin'
              : ''
          }`}>
          {this.renderAccordionView({
            products: bundleMandatoryProducts,
            type: 'mandatory',
            title: i18nTranslate('product.bundleMandatory', 'Product', {
              nameSpace: 'ssr-resource',
            }),
            id: 'mandatory',
          })}
          {bundleOptionalProducts?.length > 0 &&
            Object.entries(this.bundleOptionalProducts).map(
              (groupedProducts, index) => {
                const [name, products] = groupedProducts
                const title =
                  name === 'optional'
                    ? i18nTranslate(
                        'product.bundleOptionalTitle',
                        'Add Items To Your Bundle',
                        {
                          nameSpace: 'ssr-resource',
                        }
                      )
                    : `${i18nTranslate('product.selectProducts', 'Select', {
                        nameSpace: 'ssr-resource',
                      })} ${name}`
                return this.renderAccordionView({
                  products,
                  type: 'optional',
                  title,
                  id: name === 'optional' ? 'optional' : `optional${index}`,
                })
              }
            )}
          {skuKit?.length > 0 &&
            this.renderAccordionView({
              products: skuKit,
              type: 'mandatory',
              title: i18nTranslate(
                'product.bundleOptionalTitle',
                'Add Items To Your Bundle',
                {
                  nameSpace: 'ssr-resource',
                }
              ),
              id: 'skukit',
            })}
        </div>
      )
    } else {
      return (
        <Row
          noGutters
          className="mt-2 pt-2 bundle-container"
          data-testid="qa-bundle-container">
          {bundleMandatoryProducts &&
            bundleMandatoryProducts.length > 0 &&
            this.renderSubProductsWithTitle(
              bundleMandatoryProducts,
              i18nTranslate('product.bundleTitle', 'Bundle', {
                nameSpace: 'ssr-resource',
              }),
              'mandatory'
            )}
          {bundleOptionalProducts.length > 0 &&
            Object.entries(this.bundleOptionalProducts).map(groupedProducts => {
              const [name, products] = groupedProducts
              const title =
                name === 'optional'
                  ? i18nTranslate(
                      'product.bundleOptionalTitle',
                      'Add Items To Your Bundle',
                      {
                        nameSpace: 'ssr-resource',
                      }
                    )
                  : `${i18nTranslate('product.selectProducts', 'Select', {
                      nameSpace: 'ssr-resource',
                    })} ${name}`
              return this.renderSubProductsWithTitle(
                products,
                title,
                'optional'
              )
            })}
          {skuKit &&
            skuKit.length > 0 &&
            this.renderSubProductsWithTitle(
              skuKit,
              i18nTranslate('product.bundleTitle', 'Items in this Kit', {
                nameSpace: 'ssr-resource',
              }),
              'mandatory'
            )}
        </Row>
      )
    }
  }
}

export { BundlePDP }
export default BundlePDP
