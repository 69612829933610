import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { HiCheck as BtnCheck } from 'react-icons/hi'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { PDPContext } from 'src/views/components/BaseProductComponent'
import { productDeps } from 'src/deps'
import './styles.scss'

@observer
class ProductSkuOptionDropDown extends React.Component {
  static contextType = PDPContext
  @observable activeSkuType = ''
  constructor(props) {
    super(props)
    this.state = {
      active: '',
    }
  }

  handleClick = skuValues => {
    this.setState({ active: skuValues?.label })
    const context = this.context
    /* @variant labelchange */
    productDeps.changeVariantInUrlForPDP(
      context,
      skuValues?.variantForUrl || '',
      this.props
    )
  }

  showErrorForSkuSelection = type => {
    const ErrorText = i18nTranslate('product.selectSkuError', 'Choose the', {
      nameSpace: 'ssr-resource',
    })
    const errorView =
      this.props.skuSelected[type] === '' ? (
        <span className="font-weight-bold text-danger">
          {ErrorText} {type}
        </span>
      ) : (
        <React.Fragment />
      )
    return errorView
  }

  handleAccordion = type => {
    if (this.activeSkuType === type) {
      this.activeSkuType = ''
    } else {
      this.activeSkuType = type
    }
  }

  renderTitle = type => {
    const selectedSku = this.props.skuSelected.variantLabel
    return <span>{`${selectedSku}`}</span>
  }

  renderSkuDetails = (item, index) => {
    const type = item.type
    const variantLabel = this.props?.skuSelected?.variantLabel
    const skuList = this.props.skuList
    let colorVariant = []
    let skuVariant = []
    skuList.map(sku => {
      let obj = {}
      obj.label = sku?.properties?.variantLabel || ''
      obj.slug = sku?.properties?.slug || ''
      obj.skuId = sku?.identifier || ''
      /* @variant labelchange */
      obj.variantForUrl = sku?.properties?.slug || sku?.identifier || ''
      if (sku?.properties?.variantColor) {
        obj.color = sku?.properties?.variantColor
        colorVariant.push(obj)
      } else {
        skuVariant.push(obj)
      }
    })

    if (colorVariant.length > 0) {
      return (
        <div className="select-option">
          <div className="select-heading mb-4">
            <p>
              <strong>
                {i18nTranslate('select.YourOption', 'Select your option')}:
              </strong>
              {variantLabel}
            </p>
          </div>
          <div
            key={index}
            data-testid={`qa-select-${type}`}
            className="sku-dropdown p-0 cr-gap d-flex my-3 flex-sm-row flex-md-row flex-wrap">
            {colorVariant.map((skuValues, idx) => (
              <div
                key={idx}
                type={type}
                isActive={this.activeSkuType === type}
                className="variantlist">
                <div
                  className={`nuxt-link-active button_variantcolor rounded ${
                    this.props.skuSelected.variantLabel === skuValues.label
                      ? 'buttonSelected_quulW'
                      : ''
                  } `}
                  style={{
                    backgroundColor: `${skuValues.color}`,
                  }}
                  id={skuValues.label}
                  onClick={() => {
                    this.props.handleSkuSelection(
                      {
                        type: type,
                        value: skuValues.label,
                      },
                      this.props.index
                    )
                    this.activeSkuType = ''
                    this.handleClick(skuValues)
                  }}>
                  <div
                    className={` ${
                      type.toLowerCase() === 'color' ? 'pl-2' : ''
                    }`}
                    active={skuValues.disable}>
                    <span className={`buttonLabel_38IAN ${skuValues.label} `}>
                      {' '}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
    if (skuVariant.length > 1) {
      return (
        <div className="select-option">
          <div className="select-heading mb-2">
            <p>
              <strong>
                {i18nTranslate('product.selectOption', 'Select your option', {
                  nameSpace: 'ssr-resource',
                })}{' '}
                :{' '}
              </strong>
              {variantLabel}
            </p>
          </div>
          <ButtonGroup
            toggle
            className="w-100 flex-wrap select-button"
            data-testid={`qa-select-${type}`}
            aria-label="Select your option">
            {skuVariant.map((skuValues, idx) => (
              <ToggleButton
                className={`m-1 bg-white shadow-none pa-3 border border-primary toggle-btn ${
                  this.props?.skuSelected?.variantLabel === skuValues?.label
                    ? 'sku-values'
                    : ''
                }`}
                key={idx}
                type="radio"
                name="skuvariant-button-group"
                value={skuValues.label}
                disabled={skuValues?.disable ? true : false}
                checked={
                  this.props?.skuSelected?.variantLabel === skuValues.label
                }
                onClick={() => {
                  this?.props?.handleSkuSelection(
                    {
                      type: type,
                      value: skuValues.label,
                    },
                    this.props.index
                  )
                  this.activeSkuType = ''
                  this.handleClick(skuValues)
                }}>
                {this.props?.skuSelected?.variantLabel === skuValues.label ? (
                  <div className="d-flex justify-content-center text-primary align-items-center">
                    <BtnCheck size={20} className="icon" />
                    <span className="sku-label ml-1">{skuValues.label}</span>
                  </div>
                ) : (
                  <span className="text-primary">{skuValues.label}</span>
                )}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
      )
    }
  }
  // commented to disable bin items API call CX121-1697
  // @action
  // async getInventoryDeatils() {
  // const product = this.props.product
  // const Skures = await catalogContainer.getInventoryData(product.skuId)
  // productState.inventory = Skures
  // }

  render() {
    const { options } = this.props
    // {
    //   this.getInventoryDeatils()
    // }
    // return options.slice(1).map(this.renderSkuDetails)
    return options.map(this.renderSkuDetails)
  }
}

const ProductSkuOptionDropDownWithRouter = withRouter(ProductSkuOptionDropDown)

export { ProductSkuOptionDropDownWithRouter as ProductSkuOptionDropDown }
export default ProductSkuOptionDropDown
