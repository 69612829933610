const BLACKLISTED_PROPERTIES = [
  'accessControl',
  'layoutHeight',
  'hasAreaSpecificEvents',
  'lockByGroup',
  'parallaxBgUrlMoveRatio',
  'hideToolbar',
  'parallaxBackground',
  'layoutLeft',
  'absoluteLayout',
  'layoutWidth',
  'cacheControl',
  'hideDimension',
  'layoutTop',
  'idx',
  'zIndex',
]

/**
 * Omits properties from the given properties object that are in the
 * BLACKLISTED_PROPERTIES array.
 * @param {Object} properties - The properties object to filter
 * @param {string[]} BLACKLISTED_PROPERTIES - Array of property names to omit
 * @returns {Object} A new object with the blacklisted properties omitted
 */
const omitProperties = (properties, BLACKLISTED_PROPERTIES) => {
  const newProps = {}
  if (properties) {
    Object.entries(properties).forEach(([key, value]) => {
      if (!BLACKLISTED_PROPERTIES.includes(key)) {
        newProps[key] = value
      }
    })
  }
  return newProps
}

/**
 * Transforms the given properties object by omitting any properties that are
 * in the BLACKLISTED_PROPERTIES array.
 *
 * @param {Object} properties - The properties object to transform
 * @returns {Object} A new object with the blacklisted properties omitted
 */
const transformProperties = properties => {
  const remainingProperties = omitProperties(properties, BLACKLISTED_PROPERTIES)
  return remainingProperties
}

/**
 * Transforms the facet items array by mapping over each item
 * and returning the facets property.
 *
 * @param {Object[]} facetItems - Array of facet items
 * @returns {Object[]} Array containing the facets properties of each item
 */
const transformItemProperties = facetItems => {
  return facetItems.map(item => item.facets)
}

/**
 * Transforms a widget by mapping over its properties and items arrays
 * to transform them, and returning a new object with the transformed
 * data.
 *
 * @param {Object} widget - The widget object to transform
 * @returns {Object} The transformed widget
 */
const transformWidget = widget => {
  const properties = transformProperties(widget.properties)
  const items = transformItemProperties(widget.items)

  const remapped = { ...widget, properties, items }
  return remapped
}

/**
 * Transforms an array of absolute widgets by mapping over each one.
 * For each absolute widget, it extracts the cell ID from the cells object,
 * transforms the widget in that cell using transformWidget(), and returns
 * a new object containing the transformed widget and other remaining props.
 *
 * @param {Object[]} absoluteWidgets - Array of absolute widget objects
 * @returns {Object[]} Array containing the transformed absolute widgets
 */
const transformAbsoluteWidgets = absoluteWidgets => {
  if (absoluteWidgets && absoluteWidgets.length) {
    return absoluteWidgets.map(absWidget => {
      const { cells, ...remainingProps } = absWidget
      const cellId = Object.keys(cells).find(cell =>
        cell.match('skPageLayoutCell_')
      )
      return {
        widget: transformWidget(cells[cellId].widget),
        ...remainingProps,
      }
    })
  }

  return
}

/**
 * Fetches widget components from the page layout cells, cells,
 * and absolute widgets. Transforms the widgets and returns an array
 * of objects containing the transformed widgets and related data.
 */
const fetchWidgetComponents = (layouts, cells, absoluteWidgets) => {
  if (layouts && layouts.length) {
    return layouts.map(layout => {
      if (
        layout &&
        layout.cellId &&
        cells &&
        cells[layout.cellId] &&
        cells[layout.cellId].widget
      ) {
        const absoluteWidgetsForCell = Object.values(absoluteWidgets).filter(
          widget => {
            return (
              (widget?.positioning?.relAbsWrapperId || '') === layout.cellId
            )
          }
        )
        const transformedAbsoluteWidget = transformAbsoluteWidgets(
          absoluteWidgetsForCell
        )
        const widget = cells[layout.cellId].widget
        const transformedwidget = transformWidget(widget)
        const { widgetType, items, properties, widgetName } = transformedwidget
        return {
          widgetType,
          items,
          properties,
          layout,
          widgetName,
          absoluteWidgets: transformedAbsoluteWidget,
        }
      }
      return {}
    })
  }
}

/**
 * Transforms the response from fetching a Studio page layout into an array of widget components.
 * Deconstructs the page layout by extracting the grid layout, cells, and absolute widgets.
 * Calls the fetchWidgetComponents function to transform those into widget components.
 * Returns the array of transformed widget components.
 */
const transformStudioPage = response => {
  const { page = {} } = response
  const gridLayout = page?.gridLayout?.cells || {}
  const cells = page?.cells || {}
  const absoluteWidgets = page?.absoluteWidgets || {}

  const widgetComponents = fetchWidgetComponents(
    gridLayout,
    cells,
    absoluteWidgets
  )

  return widgetComponents
}

/**
 * Transforms the response from fetching a Studio preview page into page data.
 * Extracts the view ID, page ID, transforms the page data,
 * gets the page views from the response, sets the response page to the matching view,
 * and returns the transformed page data.
 */
export const transformPreviewPage = response => {
  const id = response.payload.viewId
  const pageId = response.payload.pageId
  const data = transformStudioPage(response)
  const views = response?.payload?.catalogData?.pages?.[pageId]?.views
  response.page = views?.[id] || 1
  return data
}

export { transformStudioPage }
