import React from 'react'
import { Link } from 'react-router-dom'

import isArray from 'lodash/isArray'
import { getCategoryLinks } from '../../deps'

class SubCategories extends React.PureComponent {
  handleClick = () => {
    this.props.handleToggle()
  }

  hardcodedCategoryLinks = {
    sustainability:
      'https://www.nuskin.com/content/globalffg/sustainability/home.html',
    socialimpact: 'https://www.nuskin.com/content/globalffg/home.html',
  }

  LinkOrA = (identifier, children, navigateTo = '') => {
    if (this.hardcodedCategoryLinks[identifier]) {
      return (
        <a
          href={this.hardcodedCategoryLinks[identifier]}
          target="_blank"
          rel="noopener noreferrer">
          {children}
        </a>
      )
    }

    return (
      <Link onClick={() => this.handleClick(identifier)} to={navigateTo}>
        {children}
      </Link>
    )
  }

  renderSubCategoryList = subCategories => {
    const identifier = subCategories?.identifier || ''
    const navigateTo = getCategoryLinks(subCategories, false)
    const subCategoryName = subCategories?.properties?.name || ''
    return (
      <div className="sub-item" key={identifier}>
        <Link onClick={() => this.handleClick(identifier)} to={navigateTo}>
          <span data-testid="qa-category">{subCategoryName}</span>
        </Link>
      </div>
    )
  }

  render() {
    const { subCategory } = this.props
    const { properties, subCategories } = subCategory
    const navigateTo = getCategoryLinks(subCategory, false)
    const subCategoryName = properties?.name || ''
    return (
      <React.Fragment key={`category_${subCategoryName}`}>
        <div className="font-weight-bold sub-item">
          {this.LinkOrA(
            subCategory.identifier,
            <span data-testid="qa-category">{subCategoryName}</span>,
            navigateTo
          )}
        </div>
        {isArray(subCategories) &&
          subCategories.length &&
          subCategories.map(this.renderSubCategoryList)}
      </React.Fragment>
    )
  }
}

export { SubCategories }
