import { fetchResponseFromAPI, getLiveEventStatus, IS_BROWSER } from 'src/utils'
import { isProduction } from 'config/appConfig'

/**
 * Fetches locale resource from a 3rd party URL based on the provided options.
 *
 * @param {Object} options - Options for fetching the resource.
 * @param {string} [options.locale='en-US'] - Locale code, e.g. 'en-US'.
 * @param {string} [options.domainName='dev.nuskin.com'] - Domain name for the API endpoint.
 * @param {string} [options.fileName='resource'] - Name of the resource file to fetch, without extension.
 * @param {string} [options.contextName=''] - Optional context name.
 * @returns {Promise<Object>} Promise resolving to the fetched locale resource object.
 */
async function getResourceFrom3rdPartyUrl(options) {
  const {
    locale = 'en-US',
    domainName = 'dev.nuskin.com',
    fileName = 'resource',
    contextName = '',
    ...props
  } = options

  const enableLiveEvent = getLiveEventStatus()

  try {
    /**
     * @note
     * url pattern will be:
     * `https://dev.nuskin.com/assets/translations/en-CA/resource.json`
     * `https://dev.nuskin.com/assets/translations/en-CA/header.json`
     * `https://dev.nuskin.com/assets/translations/en-CA/footer.json`
     */
    const fileNameWithExtension = fileName?.includes('.')
      ? fileName
      : `${fileName}.json`

    let url = `https://${domainName}/assets/translations/${locale}/${fileNameWithExtension}`

    if (IS_BROWSER && enableLiveEvent == 'true') {
      let domain = domainName
      if (process.env.NODE_ENV == 'development') {
        domain = 'test.nuskin.com'
      }
      url = `https://${domain}/storefront/client/translations/${locale}/${fileNameWithExtension}`
    }

    const response = await fetchResponseFromAPI({
      url,
      method: 'GET',
      isToSendLocaleInQueryParam: false,
      isToSendStoreId: false,
      contextName: contextName,
      credentials: 'omit',
      // ...(props || {}),
    })

    if (response && typeof response === 'object') {
      return response || {}
    } else {
      throw 'error while loading locale (resource.json) from 3rd party url'
    }
  } catch (e) {
    console.error('Fn getResourceFrom3rdPartyUrl ', e)
    return {}
  }
}

export { getResourceFrom3rdPartyUrl }
