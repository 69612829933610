/**
 * Checks if the current environment supports touch events,
 * indicating the user is on a touch device like a phone or tablet.
 */
export function isTouchDevice() {
  return (
    typeof document.documentElement === 'object' &&
    'ontouchstart' in document.documentElement
  )
}

/**
 * Checks if the browser is Microsoft Edge or Internet Explorer.
 * @see https://stackoverflow.com/questions/33152523/how-do-i-detect-ie-and-edge-browser?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
 */
export function isEdgeOrInternetExplorer() {
  if (
    typeof window === 'object' &&
    typeof document === 'object' &&
    typeof navigator === 'object'
  ) {
    return document.documentMode || /Edge/.test(navigator.userAgent)
  } else if (typeof global === 'object') {
    if (typeof global.oneUrl === 'object') {
      return /Edge/.test(global.oneUrl.userAgent)
    } else {
      // console.warn(
      //   'application: isEdgeOrInternetExplorer is triggered before global.oneUrl'
      // )
      return false
    }
  } else {
    console.warn('could not determine isEdgeOrInternetExplorer')
    return false
  }
}

/**
 * Gets the user agent string from the browser's navigator object.
 * This can be used to detect browser type and version.
 */
export function getUserAgent(ua) {
  // @see @link https://stackoverflow.com/a/34974413
  // ^ reference for below user-agent test statements
  // const browser = /Edge\/\d+/.test(ua)
  //   ? 'ed'
  //   : /MSIE 9/.test(ua)
  //   ? 'ie9'
  //   : /MSIE 10/.test(ua)
  //   ? 'ie10'
  //   : /MSIE 11/.test(ua)
  //   ? 'ie11'
  //   : /MSIE\s\d/.test(ua)
  //   ? 'ie?'
  //   : /rv\:11/.test(ua)
  //   ? 'ie11'
  //   : /Firefox\W\d/.test(ua)
  //   ? 'ff'
  //   : /Chrome\W\d/.test(ua)
  //   ? 'gc'
  //   : /Chromium\W\d/.test(ua)
  //   ? 'oc'
  //   : /\bSafari\W\d/.test(ua)
  //   ? 'sa'
  //   : /\bOpera\W\d/.test(ua)
  //   ? 'op'
  //   : /\bOPR\W\d/i.test(ua)
  //   ? 'op'
  //   : typeof MSPointerEvent !== 'undefined'
  //   ? 'ie?'
  //   : ''
  // const os = /Windows NT 10/.test(ua)
  //   ? 'win10'
  //   : /Windows NT 6\.0/.test(ua)
  //   ? 'winvista'
  //   : /Windows NT 6\.1/.test(ua)
  //   ? 'win7'
  //   : /Windows NT 6\.\d/.test(ua)
  //   ? 'win8'
  //   : /Windows NT 5\.1/.test(ua)
  //   ? 'winxp'
  //   : /Windows NT [1-5]\./.test(ua)
  //   ? 'winnt'
  //   : /Mac/.test(ua)
  //   ? 'mac'
  //   : /Linux/.test(ua)
  //   ? 'linux'
  //   : /X11/.test(ua)
  //   ? 'nix'
  //   : ''
  const isPhone =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      ua
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      ua.substr(0, 4)
    )
  const isTablet = /Tablet|iPad/i.test(ua)
  // if userAgent is tablet,
  // regex tests sets both isTablet and isPhone to 'true'
  return { userAgent: ua, isPhone, isTablet }
}
