import { i18nTranslate } from 'src/utils'
import { observable, computed, action } from 'mobx'
import isArray from 'lodash/isArray'

import { enableOutfits } from 'config/appConfig'
import { cartContainer } from 'src/models'
import { CommonContainer } from 'src/models/Common'
import { toastState } from 'src/views/components'
import { listsContainer } from './Lists'

/**
 * Constructs the list item props object from the given list item properties.
 * @param {Object} listItemProps - The list item properties object.
 * @returns {Array} The constructed list items array.
 */
function constructListItemProps(listItemProps) {
  const { skuId, productId, categoryId, quantity, name, imageURL, type, slug } =
    listItemProps
  let listItems = []
  if (skuId) {
    listItems = [
      {
        key: skuId,
        name,
        properties: {
          productId,
          categoryId,
          quantity,
          imageURL,
          slug,
          type,
        },
      },
    ]
  }
  return listItems
}

/**
 * Creates an error toast message for wish list creation failures.
 * @param {Object} response - The response from the wish list creation request.
 */
function createErrorMessageToast(response = {}) {
  const message =
    response.message ||
    i18nTranslate(
      'wishList.createFailure',
      'Sorry, we are unable to create a list. Please try again.'
    )
  toastState.setToastMessage(message, false)
}
const outfitsListType = 'outfits'
/**
 * WishListContainer class. Represents the container for wish list state.
 */
class WishListContainer extends CommonContainer {
  // currently open listitems will be here
  @observable wishListDetails = {
    listItems: [],
    pageableInfo: {},
    activeListName: '',
  }
  @observable wishLists = []
  @observable activePage = 1
  pageSize = 5
  searchTerm = ''

  @action
  /**
   * Checks if the current page wish lists is empty and the active
   * page is greater than 1, if so sets the active page back to 1.
   * This ensures the user doesn't end up on an empty page.
   */
  checkAndUpdatePagination = () => {
    if (this.currentPageWishLists.length === 0 && this.activePage > 1) {
      this.activePage = 1
    }
  }

  @computed
  /**
   * Gets the current page of wish lists based on
   * the active page and page size. Slices the
   * full list of wish lists accordingly.
   */
  get currentPageWishLists() {
    return this.wishLists.slice(
      (this.activePage - 1) * this.pageSize,
      this.activePage * this.pageSize
    )
  }

  @action
  /**
   * Updates the wishLists property by filtering
   * the provided lists to only include wishlists.
   * Optionally filters out "outfits" type wishlists
   * if enableOutfits config is false.
   *
   * @param {Array} lists - Array of list objects
   */
  updateFromGetAllList = lists => {
    this.wishLists =
      isArray(lists) && lists.length
        ? lists.filter(list => {
            if (enableOutfits) {
              return (
                list.type === 'WISHLIST' &&
                (list?.properties?.type || '') !== outfitsListType
              )
            } else {
              return list.type === 'WISHLIST'
            }
          })
        : []
  }

  /**
   * Retrieves all lists from the listsContainer and updates
   * the local wishLists property with the response.
   */
  makeGetAllList = async () => {
    await listsContainer.getAllLists().then(listResponse => {
      this.updateFromGetAllList(listResponse.lists)
    })
  }

  /**
   * Searches wish lists by name. Sets search term to provided name,
   * constructs search params, calls list container to get lists matching params,
   * sets active page to 1, and updates local wishLists property with response.
   */
  searchWishLists = async listName => {
    this.searchTerm = listName || ''
    const searchParams = {
      type: 'WISHLIST',
      ...(listName && { name: listName }),
    }
    await listsContainer.getAllLists(searchParams).then(listResponse => {
      this.activePage = 1
      this.updateFromGetAllList(listResponse.lists)
    })
  }

  /**
   * Creates a new wish list with the given name and properties.
   * Optionally adds a list item if listItemProps is provided.
   * Handles calling the API to create the list,
   * updating the local list state,
   * and showing success/error toasts.
   */
  createWishList = async props => {
    const { name, listItemProps = {} } = props
    const {
      listId,
      listItemId,
      isFromListPage = false,
    } = props?.listPageProps || {}
    const productProperties = props?.properties || {}
    const listItemProperties =
      Object.keys(listItemProps) && Object.keys(listItemProps).length
        ? listItemProps
        : productProperties
    const response = await listsContainer.createList({
      name: name,
      itemType: 'SKU',
      type: 'WISHLIST',
      ...(listItemProps && {
        listItems: constructListItemProps(listItemProperties),
      }),
      accessType: 'PRIVATE',
      properties: productProperties,
    })

    if (this.isSuccessResponse(response)) {
      if (isFromListPage) {
        await this.deleteItemFromWishList(
          {
            listId,
            listItemId,
          },
          false
        )
      }
      this.makeGetAllList()

      const message =
        Object.keys(listItemProps) && Object.keys(listItemProps).length
          ? i18nTranslate(
              'wishList.createSuccessWithItem',
              'Item added to new list.'
            )
          : i18nTranslate(
              'wishList.createSuccess',
              'Your list has been created.'
            )

      toastState.setToastMessage(message, true)
    } else {
      createErrorMessageToast(response)
    }
  }

  @action
  /**
   * Gets the list items for the given list ID and page.
   *
   * @param {Object} params - The parameters
   * @param {string} params.listId - The ID of the list to get items for
   * @param {number} params.page - The page number to get
   * @returns {Promise} A promise that resolves to the response from the server
   */
  getListItems = async ({ listId, page, activeListName }) => {
    const response = await listsContainer.getListItems({ listId, page })
    const listItems = response?.listItems || []
    if (listItems?.length > 0) {
      const payload =
        wishListContainer.getListPayloadDataForForeignOrderLimitAPI(listItems)
      await cartContainer.getForeignOrderLimitRestriction(payload)
    }
    this.wishListDetails = { activeListName, ...response }
  }

  /**
   * Adds an item to the wish list with the given props.
   *
   * @param {Object} props - The properties for adding the item
   * @param {string} props.skuId - The SKU ID of the item to add
   * @param {string} props.listName - The name of the list
   * @param {string} props.listId - The ID of the list
   * @param {Object} props.remainingProps - Additional properties for the item
   * @param {boolean} [showToast=true] - Whether to show a toast message
   * @returns {Promise} A promise that resolves to the server response
   */
  addItemToWishList = async (props, showToast = true) => {
    /**
     * remainingProps - productId, name, categoryId, quantity
     */
    const { skuId, listName, listId, ...remainingProps } = props
    const listData = { itemKey: skuId, ...remainingProps }
    const response = await listsContainer.addItemToList({ listId, listData })
    if (showToast) {
      if (this.isSuccessResponse(response)) {
        this.makeGetAllList()
        const message = `${i18nTranslate(
          'wishList.addSuccess',
          'Item has been added to your list.'
        )} ${listName}`
        toastState.setToastMessage(message, true)
      } else {
        const message =
          response.message ||
          i18nTranslate(
            'wishList.addFailure',
            'Sorry, we are unable to add this item to your list.'
          )
        toastState.setToastMessage(message, false)
      }
    }
    return response
  }

  /**
   * Updates the list with the given ID.
   *
   * @param {Object} params - The parameters
   * @param {string} [params.accessType] - The new access type for the list
   * @param {string} [params.name] - The new name for the list
   * @param {string} params.listId - The ID of the list to update
   * @returns {Promise} A promise that resolves when the update is complete
   */
  updateList = async params => {
    const { accessType, name, listId } = params
    const listData = {
      ...(accessType && { accessType }),
      ...(name && { name }),
    }
    const response = await listsContainer.updateList({ listId, listData })
    if (this.isSuccessResponse(response)) {
      this.makeGetAllList()
      const message = i18nTranslate(
        'wishList.updateSuccess',
        'List updated successfully'
      )
      toastState.setToastMessage(message, true)
    } else {
      const message =
        response.message ||
        i18nTranslate(
          'wishList.updateFailure',
          'Sorry, we are unable to update your list.'
        )
      toastState.setToastMessage(message, false)
    }
    return response
  }

  /**
   * Deletes an item from the wish list with the given ID.
   *
   * @param {Object} props - The parameters
   * @param {string} props.listId - The ID of the wish list
   * @param {string} props.listItemId - The ID of the item to delete
   * @param {boolean} [showToast=true] - Whether to show a toast message
   * @returns {Promise} A promise that resolves when the delete is complete
   */
  deleteItemFromWishList = async (props, showToast = true) => {
    const { listId, listItemId } = props
    const response = await listsContainer.deleteItemFromList({
      listId,
      listItemId,
    })
    if (showToast) {
      if (this.isSuccessResponse(response)) {
        this.makeGetAllList()
        const message = i18nTranslate(
          'wishList.deleteSuccess',
          'Item has been deleted from your list.'
        )
        toastState.setToastMessage(message, true)
      } else {
        const message =
          response.message ||
          i18nTranslate(
            'wishList.deleteFailure',
            'Sorry, we are unable to delete this item from your list.'
          )
        toastState.setToastMessage(message, false)
      }
    }
    return response
  }

  /**
   * Deletes a wish list with the given ID.
   *
   * @param {string} listId - The ID of the wish list to delete
   * @returns {Promise} A promise that resolves when the delete is complete
   */
  deleteList = async listId => {
    const response = await listsContainer.deleteList(listId)
    if (this.isSuccessResponse(response)) {
      await this.makeGetAllList()
      this.checkAndUpdatePagination()
      const message = i18nTranslate(
        'wishList.deleteListSuccess',
        'Your list has been deleted.'
      )
      toastState.setToastMessage(message, true)
    } else {
      const message =
        response.message ||
        i18nTranslate(
          'wishList.deleteListFailure',
          'Sorry, we are unable to delete your list.'
        )
      toastState.setToastMessage(message, false)
    }
  }

  /**
   * Moves a wish list item to another wish list.
   *
   * @param {Object} props - Object containing:
   * - listItemProps: Details of item to move
   * - addListProps: Details of list to move item to
   * - deleteListProps: Details of original list to delete item from
   * @returns {Promise} Promise that resolves when move is complete
   */
  moveToAnotherList = async props => {
    const { listItemProps, addListProps, deleteListProps } = props
    const { listName } = addListProps
    let errorMessage = ''
    const response = await this.addItemToWishList(
      {
        ...listItemProps,
        ...addListProps,
      },
      false
    )
    if (this.isSuccessResponse(response)) {
      const deleteResponse = await this.deleteItemFromWishList(
        {
          ...deleteListProps,
        },
        false
      )
      if (this.isSuccessResponse(deleteResponse)) {
        const message = `${i18nTranslate(
          'wishList.moveSuccess',
          'You have moved an item to your list.'
        )} ${listName}`
        toastState.setToastMessage(message, true)
        this.makeGetAllList()
        return
      } else {
        errorMessage = response.message
      }
    } else {
      errorMessage = response.message
    }
    // Return here when faiure of delete list or addlist
    const message =
      errorMessage ||
      `${i18nTranslate(
        'wishList.moveFailure',
        'Sorry, we are unable to move your list.'
      )} ${listName}`
    toastState.setToastMessage(message, false)
  }

  /**
   * Handles adding an item to the cart from a wishlist and deleting it from the wishlist on success
   *
   * @param {Object} props - Object containing:
   * - deleteListParams: Parameters for deleting the item from the wishlist
   * - addToCartParams: Parameters for adding the item to the cart
   *
   * Adds the item to cart via the cartContainer.
   * On success, deletes the item from the wishlist and refreshes wishlists.
   * On failure, displays a toast error message.
   */
  handleAddToCart = async props => {
    const { deleteListParams, addToCartParams } = props
    const cartDetails = {
      cartArray: [{ ...addToCartParams }],
    }
    const response = await cartContainer.addToCart(cartDetails)
    if (cartContainer.isSuccessResponse(response)) {
      await this.deleteItemFromWishList({ ...deleteListParams }, false)
      this.makeGetAllList()
    } else {
      let errorMessage =
        (response && response?.message) ||
        i18nTranslate(
          'wishList.moveCartFailure',
          'Unable to move to cart, try again later'
        )
      if (response?.code == 'EOCA0000013') {
        errorMessage = i18nTranslate(
          'cart.moveToCartBackorderMsg',
          'Backordered item cannot be added to cart from save for later and list section'
        )
      }
      toastState.setToastMessage(errorMessage, false)
    }
  }

  /**
   * Shares a wish list with provided parameters
   * @param {Object} params - Parameters for sharing the wish list
   * Makes API call to share list and handles success/error cases
   * On success:
   * - Displays success toast message
   * On failure:
   * - Displays error toast message
   */
  shareList = async params => {
    const response = await listsContainer.shareList({ ...params })
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate('wishList.shareSuccess', 'Your list has been shared.'),
        true
      )
    } else {
      toastState.setToastMessage(response.message || response.responseMessage)
    }
  }

  getListPayloadDataForForeignOrderLimitAPI = (listItems = []) => {
    if (isArray(listItems) && listItems?.length > 0) {
      return listItems?.map((listItem, key) => {
        const skuId = listItem?.sku?.identifier || ''
        const { productId = '' } = listItem?.properties || {}
        const productDetails = { skuId, productId, type: 'DEFAULT' }
        return productDetails
      })
    }
    return []
  }
}

const wishListContainer = new WishListContainer()

export { wishListContainer, WishListContainer, constructListItemProps }
