import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate, getLiveEventStatus } from 'src/utils'
import { Button, Col, Row, Card, Badge, OverlayTrigger } from 'react-bootstrap'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'
import { IoIosCloseCircle as CloseIcon } from 'react-icons/io'
import { MdShoppingCart as ShoppingCartIcon } from 'react-icons/md'
import { VARIANT_NODES, APPConfig } from 'config/appConfig'
import { cartContainer, customerContainer } from 'src/models'
import {
  Price,
  Quantity,
  AccordionBlock,
  SubscriptionDropDown,
  ImageComponent,
} from 'src/views/components'
import ViewCartRefreshIcon from 'src/views/components/Icons/ViewCartRefreshIcon'
import { getLocalStorage, currencyFormat, getLocaleString } from 'src/utils'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import { ProductPrice } from '../../ProductComponents/ProductSkuDetails/ProductPrice'
import './styles.scss'

@observer
class CartProductBlock extends React.Component {
  enableLiveEvent = getLiveEventStatus()

  renderBundle = (productDetails = {}, index = 0) => {
    const {
      skuId = '',
      transactionPrice = {},
      promoText = '',
      productName = '',
      imageURL = '',
    } = productDetails
    const isBundle = this.props?.bundleProducts?.products?.length || 0
    const { productUrl = '' } = this.props
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const skuColon = isFlexibleSubscriptionEnabled ? ':' : '#:'

    return (
      <Card
        key={`cart-${index}`}
        className={`cart-product-wrapper mb-3 p-2 border-0`}
        data-testid="qa-products-container">
        <Row
          noGutters
          className="border-bottom border-dark sub-product-container">
          <Col lg={4} md={4}>
            <Link onClick={cartContainer.toggleCartVisibility} to={productUrl}>
              <ImageComponent
                testId="qa-product-image"
                src={imageURL}
                alt={productName}
                className="sub-product-image"
                height={'75px'}
                width={'75px'}
              />
            </Link>
          </Col>
          <Col lg={8} md={8} className="sub-product-details">
            <Col className="sub-product-title">
              <span data-testid="qa-product-name" className="sub-product-name">
                {productName}
              </span>
            </Col>
            <Col lg={12} className="sub-product-sku">
              <Card.Text className="pb-0" data-testid="qa-product-number">
                <span className="sub-product-sku-label">
                  {i18nTranslate('cart.itemId', 'SKU')}
                </span>
                <span className="sub-product-sku-colon">{skuColon}</span>
                {skuId?.length > 15 ? (
                  <OverlayTrigger
                    placement="bottom-start"
                    delay={{ show: 100, hide: 250 }}
                    overlay={<div className="skuId-tooltip">{skuId}</div>}>
                    <span className="ml-1 cart-skuId d-inline-block text-truncate w-50 sub-product-sku-id">
                      {skuId}
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span className="ml-1 sub-product-sku-id">{skuId}</span>
                )}
              </Card.Text>
            </Col>
            {VARIANT_NODES?.map(skuKey => {
              const skuValue = productDetails?.[skuKey]
              const skuTitle = getLocaleString()[skuKey] || ''
              if (skuValue) {
                return (
                  <Col
                    lg={12}
                    key={skuKey}
                    data-testid={`qa-product-${skuKey}`}
                    className="sub-product-variant-details">
                    <span className="left-span">{skuTitle}</span>
                    <span className="span-colon">:</span>
                    <span className="right-span sale pl-1 ">{skuValue}</span>
                  </Col>
                )
              }
              return <></>
            })}
            <Col lg={12} className="pb-2 pt-2 sub-product-price">
              {!isBundle && <Price transactionPrice={transactionPrice} />}
              <span className="product-bundle-promotion">{promoText}</span>
            </Col>
          </Col>
        </Row>
      </Card>
    )
  }

  getPrice = (customPriceValue = 0) => {
    const formattedPrice = currencyFormat({
      price: customPriceValue,
    })
    const formattedCustomPrice = formattedPrice?.replace('$', '')
    return formattedCustomPrice
  }

  renderBundleSubProducts = () => {
    const { type = '', bundleProducts = {} } = this.props
    const bundleTitle = i18nTranslate(
      'cart.itemInTheBundle',
      'Items in this bundle'
    )
    const title = `${i18nTranslate('cart.itemsInThe', 'Items in the')} ${type}`

    return (
      <Row noGutters className="w-100 sub-bundle-product-container">
        <Col
          md={{
            span: 8,
            offset: 4,
          }}
          className="sub-bundle-product-wrapper">
          <span
            className="px-2 ml-auto qa-count bundle-items-count"
            data-testid="qa-count">
            {bundleProducts?.mandatoryCount || ''}
            {i18nTranslate('cart.cartItems', 'items')}
          </span>
          {bundleProducts?.optionalCount > 0 && (
            <>
              {','}
              <span
                className="px-2 qa-count bundle-items-count"
                data-testid="qa-count">
                {bundleProducts?.optionalCount || ''}
                {i18nTranslate('cart.adonitem', 'ad-on')}
              </span>
            </>
          )}
          <AccordionBlock
            className="mr-auto py-0 px-2 d-flex sub-product-accordion-wrapper"
            textClass="text-primary qa-count bundle-items-title"
            iconClass="qa-count bundle-items-title"
            title={type ? title : bundleTitle}
            dataTestId="qa-cart-view"
            isOutsideAccordion={true}>
            {bundleProducts?.products?.map(this.renderBundle)}
          </AccordionBlock>
        </Col>
      </Row>
    )
  }

  renderQuantity = (isToShowNewQuantityField = false) => {
    const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99
    const {
      isSaveForLater = false,
      quantity = 0,
      cartItemId = 0,
      maxCartItemLimit = maxLimit,
      handleCartQuantityChange = function () {},
    } = this.props
    const productId = this.props?.productId || ''
    const productQualificationResponse =
      customerContainer?.qualificationResponse?.qualification?.filter?.(p => {
        return p?.productId === productId
      })
    const maxQuantity =
      productQualificationResponse?.[0]?.quantity > maxLimit
        ? maxLimit
        : productQualificationResponse?.[0]?.quantity
    const customClassName = isToShowNewQuantityField
      ? 'justify-content-start'
      : 'justify-content-between'
    const quantityTitle = isToShowNewQuantityField
      ? i18nTranslate('quantity', 'Quantity')
      : i18nTranslate('cart.quantity', 'Qty')

    return (
      <Row
        noGutters
        className={`${customClassName} mini-cart-quantity-wrapper`}>
        <span className="d-flex align-items-center text-uppercase break-word quantity-txt">
          {quantityTitle}
        </span>
        {isToShowNewQuantityField && (
          <span className="quantity-colon mr-2">:</span>
        )}
        <span>
          <Quantity
            currentPage="miniCart"
            defaultValue={quantity}
            maxValue={maxQuantity}
            onQuantityChange={debounce(async (count, cbk) => {
              const response = await handleCartQuantityChange(count, cartItemId)
              if (cbk) {
                cbk(response)
              }
            }, 700)}
            resetQtyOnFailure={true}
            isV2QuantityStyle={isToShowNewQuantityField}
          />
        </span>
      </Row>
    )
  }

  renderTotalPrice = () => {
    const { totalPrice = '', grandTotal = '' } = this.props
    const CVPriceDis =
      this.props?.TotalVal?.priceFacets?.CV?.CVAfterDiscount || 0
    const PVPriceDis =
      this.props?.TotalVal?.priceFacets?.PV?.PVAfterDiscount || 0
    const CVTotalPrice = this.getPrice(CVPriceDis) || ''
    const PVTotalPrice = this.getPrice(PVPriceDis) || ''
    const priceOriginal = this.props?.TotalVal?.originalPrice || 0
    const priceDiscount = this.props?.TotalVal?.discount || 0
    const priceAfterDiscount = this.props?.TotalVal?.priceAfterDiscount || 0

    const priceFreePromotion =
      priceOriginal - priceDiscount == priceAfterDiscount &&
      priceAfterDiscount == 0

    const localvalue = getLocalStorage('accountType') || ''
    const userRole = customerContainer?.profileResponse?.userrole || ''

    return (
      <>
        <Col className="p-2 pr-0 mt-2 mini-cart-total-wrapper">
          <Row noGutters className="minicart-total-wrapper">
            <Col
              className="pb-2 pl-2 text-uppercase d-flex paragraph-m-bold total-wrapper"
              data-testid="qa-product-total">
              {totalPrice && (
                <Row noGutters>
                  <Col lg={12} className="d-flex flex-wrap justify-content-end">
                    <span className="break-word product-total-text">
                      {i18nTranslate('cart.total', 'Total')}
                    </span>
                    <span className="total-colon">{':'}</span>
                    <span
                      className="pl-1 break-word product-total-value"
                      aria-live="polite">
                      {priceFreePromotion
                        ? '$0.00'
                        : grandTotal != ''
                        ? grandTotal
                        : totalPrice}
                    </span>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>

        <Col lg={12} className="mini-cart-sv-wrapper">
          <Row noGutters>
            {PVTotalPrice && localvalue?.includes('Brand Affiliate') && (
              <Col lg={6} className="pt-1 product-sv-wrapper">
                <span className="product-sv-text">
                  {i18nTranslate('price.TotalSV', 'Total SV', {
                    nameSpace: 'ssr-resource',
                  })}
                </span>
                <span className="total-colon">{':'}</span>
                <span className="pl-1 product-sv-value">{PVTotalPrice}</span>
              </Col>
            )}
            {CVTotalPrice &&
              localvalue?.includes('Brand Affiliate') &&
              userRole != 'ROLE_ACCOUNT_BUYER_ADMIN' && (
                <Col lg={6} className="pt-1 product-sv-wrapper">
                  <span className="product-cv-text">
                    {i18nTranslate('cart.itemTotalCV', 'Total CV')}
                  </span>
                  <span className="total-colon">{':'}</span>
                  <span className="pl-1 product-cv-value">{CVTotalPrice}</span>
                </Col>
              )}
          </Row>
        </Col>
      </>
    )
  }

  renderTotalAndQuantity = () => {
    const { isSaveForLater = false } = this.props
    return (
      <>
        {!isSaveForLater && (
          <Col className="pt-2 font-weight-bold mt-2">
            {this.renderQuantity()}
          </Col>
        )}
        {this.renderTotalPrice()}
      </>
    )
  }

  getPromoDetails = () => {
    const { promotionDetails = [], freeGiftProductsList = [] } = this.props
    let skus = []
    if (freeGiftProductsList?.length > 0) {
      for (let i = 0; i < freeGiftProductsList?.length; i++) {
        skus?.push(freeGiftProductsList?.[i]?.skus?.[0]?.skuId)
      }
    }
    return (
      <>
        {promotionDetails?.length > 0 &&
          promotionDetails?.map((list, index) => {
            const NotOutOfStock = list?.isGift
              ? skus?.includes(list?.skuId)
              : true
            const displayMessage = list?.displayMessage || ''
            const message = list?.message || displayMessage
            return (
              <>
                {NotOutOfStock && (
                  <div
                    className="product-promotion justify-content-start justify-content-lg-center justify-content-md-center "
                    key={index}>
                    {message}
                  </div>
                )}
              </>
            )
          })}
      </>
    )
  }

  renderProductPrice = () => {
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const itemLevelPromoDetails = this.getPromoDetails() || ''

    return (
      <>
        <ProductPrice
          isToShowNewPriceStyling={isFlexibleSubscriptionEnabled}
          {...this.props}
        />
        <span
          className={`cart-item-level-promotion ${
            isFlexibleSubscriptionEnabled ? 'mt-2' : ''
          }`}>
          {itemLevelPromoDetails}
        </span>
      </>
    )
  }

  renderProductSaveForLaterButton = () => {
    const { handleSaveForLater = function () {}, isSaveForLater = false } =
      this.props
    return (
      <Button
        className="py-0 px-2 ml-1 d-flex justify-content-center align-items-center cart-product-button save-for-later-button"
        onClick={() => handleSaveForLater(this.props)}
        variant="outline-primary"
        data-testid={
          isSaveForLater ? 'qa-product-movetocart' : 'qa-saveforlater'
        }>
        {!isSaveForLater ? (
          i18nTranslate('cart.saveforlater', 'Save For Later')
        ) : (
          <>
            <ShoppingCartIcon
              className="close-icon mr-1"
              aria-label={i18nTranslate(
                'icons.moveToCart',
                'move to cart icon',
                {
                  nameSpace: 'ssr-resource',
                }
              )}
            />
            {i18nTranslate('cart.movetocart', 'Move To Cart')}
          </>
        )}
      </Button>
    )
  }

  renderProductDeleteButton = () => {
    const { productName = '', handleDeleteCartItem = function () {} } =
      this.props

    return (
      <Button
        className="py-0 px-2 mr-1 d-flex justify-content-center align-items-center cart-product-button"
        onClick={() => handleDeleteCartItem(this.props)}
        variant="outline-primary"
        data-testid="qa-product-remove">
        <CloseIcon
          className="close-icon mr-1"
          aria-label={`${i18nTranslate(
            'cart.remove',
            'Remove'
          )} ${productName}`}
        />
        {i18nTranslate('cart.remove', 'Remove')}
      </Button>
    )
  }

  renderProductDeleteIcon = () => {
    const { productName = '', handleDeleteCartItem = function () {} } =
      this.props

    return (
      <Button
        className="mini-cart-remove-icon-wrapper"
        onClick={() => handleDeleteCartItem(this.props)}
        variant="outline-primary"
        data-testid="qa-product-remove">
        <CloseIcon
          className="close-icon"
          aria-label={`${i18nTranslate(
            'cart.remove',
            'Remove'
          )} ${productName}`}
        />
      </Button>
    )
  }

  renderSubscriptionDetails = () => {
    const {
      cartItemId = 0,
      quantity = 0,
      isSubscription = false,
      subscription = {},
      handleSubscriptionChange = function () {},
    } = this.props
    const frequencyPeriod = subscription?.frequencyPeriod || ''
    const frequencyType = subscription?.frequencyType || ''
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false

    return (
      isSubscription && (
        <SubscriptionDropDown
          frequencyPeriod={frequencyPeriod}
          frequencyType={frequencyType}
          itemId={cartItemId}
          quantity={quantity}
          handleSubscriptionChange={handleSubscriptionChange}
          isToShowNewFrequencyStyling={isFlexibleSubscriptionEnabled}
        />
      )
    )
  }

  renderProductVariantDetails = () => {
    return VARIANT_NODES?.map(skuKey => {
      const skuValue = this.props?.[skuKey]
      const skuTitle = getLocaleString()[skuKey] || ''
      if (skuValue) {
        return (
          <Col
            lg={12}
            key={skuKey}
            className="p-0 paragraph-m pt-1 product-variant-details"
            data-testid={`qa-product-${skuKey}`}>
            <span className="left-span">{skuTitle}</span>
            <span className="span-colon">:</span>
            <span className="right-span sale pl-1 ">{skuValue}</span>
          </Col>
        )
      }
      return <></>
    })
  }

  renderSkuId = () => {
    const { skuId = '' } = this.props || {}
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const skuColon = isFlexibleSubscriptionEnabled ? ':' : '#:'

    return (
      <Card.Text className="pb-0 paragraph-m" data-testid="qa-product-number">
        <span className="mini-cart-sku-label">
          {i18nTranslate('cart.itemId', 'SKU')}
        </span>
        <span className="mini-cart-sku-colon">{skuColon}</span>
        {skuId?.length > 15 ? (
          <OverlayTrigger
            placement="bottom-start"
            delay={{ show: 100, hide: 250 }}
            overlay={<div className="skuId-tooltip">{skuId}</div>}>
            <span className="ml-1 cart-skuId d-inline-block text-truncate w-50">
              {skuId}
            </span>
          </OverlayTrigger>
        ) : (
          <span className="ml-1 cart-skuId">{skuId}</span>
        )}
      </Card.Text>
    )
  }

  renderPreOrderLabel = () => {
    const orderTypePreorder =
      this.props?.transactionPrice?.inventoryProperties?.preOrdered || false

    return (
      orderTypePreorder === true && (
        <Col lg={12}>
          <Badge variant="info" className="cart-pre-order-badge">
            {i18nTranslate('cart.preOrder', 'Preorder')}
          </Badge>
        </Col>
      )
    )
  }

  renderProductDescription = (productDescription = '') => {
    return (
      <Card.Subtitle
        className="text-truncate cart-product-subtitle mt-0"
        data-testid="qa-product-description">
        {productDescription}
      </Card.Subtitle>
    )
  }

  renderSubscriptionItemLabel = () => {
    return (
      <div className="subscription-item-label">
        <span className="subscription-icon">
          <ViewCartRefreshIcon
            className="view-cart-refresh-icon"
            width="9"
            height="9"
            fillColor="#252525"
          />
        </span>
        <span className="subscription-item-label-text">
          {i18nTranslate('viewcart.subscriptionItemLabel', 'Subscription item')}
        </span>
      </div>
    )
  }

  renderProductName = () => {
    const {
      productName = '',
      productUrl = '',
      skuId = '',
      variantLabel = '',
      skuSlug = '',
    } = this.props
    const skuSlugId = skuSlug || skuId || ''
    let productUrlWithVariant = variantLabel
      ? `${productUrl}?variant=${skuSlugId}`
      : `${productUrl}`

    return (
      <Link to={productUrlWithVariant} className="text-dark">
        <Card.Title
          className="cart-product-name heading-5"
          data-testid="qa-product-name">
          {productName}
        </Card.Title>
      </Link>
    )
  }

  renderProductImage = () => {
    const {
      productName = '',
      imageURL = '',
      productUrl = '',
      skuId = '',
      variantLabel = '',
      skuSlug = '',
    } = this.props
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const skuSlugId = skuSlug || skuId || ''
    let productUrlWithVariant = variantLabel
      ? `${productUrl}?variant=${skuSlugId}`
      : `${productUrl}`
    const imageSize = isFlexibleSubscriptionEnabled ? '92px' : '100px'

    return (
      <Link
        onClick={cartContainer.toggleCartVisibility}
        to={productUrlWithVariant}>
        <ImageComponent
          testId="qa-product-image"
          src={imageURL}
          alt={productName}
          height={imageSize}
          width={imageSize}
        />
      </Link>
    )
  }

  renderProductDetails = () => {
    const { isSaveForLater = false, bundleProducts = {} } = this.props
    const isBundle = bundleProducts?.products?.length > 0 || false
    const userRole = customerContainer?.profileResponse?.userrole || ''

    return (
      <>
        {isBundle && this.renderBundleSubProducts()}
        {!isSaveForLater && this.renderTotalAndQuantity()}
        <Col
          lg={12}
          className="w-100 d-flex justify-content-start pb-2 mx-0 mt-2 mb-0">
          <Row noGutters className="w-100">
            {this.renderProductDeleteButton()}

            {!isBundle &&
              userRole?.length > 0 &&
              this.enableLiveEvent !== 'true' &&
              this.renderProductSaveForLaterButton()}
          </Row>
        </Col>
      </>
    )
  }

  renderProductDetailsForFlexibleSubscription = () => {
    const { isSaveForLater = false, bundleProducts = {} } = this.props
    const isBundle = bundleProducts?.products?.length > 0 || false
    const userRole = customerContainer?.profileResponse?.userrole || ''

    return (
      <>
        {isBundle && this.renderBundleSubProducts()}
        {!isBundle && userRole?.length > 0 && this.enableLiveEvent !== 'true' && (
          <Col lg={12} className="saver-later-button-wrapper">
            {this.renderProductSaveForLaterButton()}
          </Col>
        )}
        {!isSaveForLater && this.renderTotalPrice()}
      </>
    )
  }

  render() {
    const {
      productDescription = '',
      isCheckout = false,
      promoText = '',
      isSubscription = false,
      isSaveForLater = false,
    } = this.props
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const dynamicClassName = isCheckout
      ? 'checkout-container'
      : 'cart-container'
    const imageColumnSpan = isFlexibleSubscriptionEnabled ? '3' : '4'
    const columnSpan = isFlexibleSubscriptionEnabled ? '9' : '8'

    return (
      <Card
        className={`cart-product-wrapper cart-bg mb-2 p-3 ${dynamicClassName} ${
          isFlexibleSubscriptionEnabled ? 'v2-mini-cart-wrapper' : ''
        }`}
        data-testid="qa-products-container">
        {isFlexibleSubscriptionEnabled && this.renderProductDeleteIcon()}
        <Row noGutters className="m-0">
          {promoText && <Col lg={12}>{promoText}</Col>}
          <Col lg={12} className="p-0">
            <Row noGutters>
              <Col lg={imageColumnSpan} md={imageColumnSpan}>
                {this.renderProductImage()}
              </Col>
              <Col
                lg={columnSpan}
                md={columnSpan}
                className="pl-2 pr-0 product-details-container">
                <Row noGutters>
                  {isFlexibleSubscriptionEnabled &&
                    isSubscription &&
                    this.renderSubscriptionItemLabel()}
                  <Col lg={12}>{this.renderProductName()}</Col>
                  {productDescription && (
                    <Col lg={12}>
                      {this.renderProductDescription(productDescription)}
                    </Col>
                  )}
                  {this.renderPreOrderLabel()}
                  <Col lg={12} className="product-sku-wrapper">
                    {this.renderSkuId()}
                  </Col>
                  {this.renderProductVariantDetails()}
                  {isSubscription && (
                    <Col className="mini-cart-frequency-wrapper">
                      {this.renderSubscriptionDetails()}
                    </Col>
                  )}
                  {isFlexibleSubscriptionEnabled && !isSaveForLater && (
                    <Col className="product-quantity-wrapper">
                      {this.renderQuantity(isFlexibleSubscriptionEnabled)}
                    </Col>
                  )}
                  <Col lg={12} className="pb-2 pt-1 product-price-container">
                    {this.renderProductPrice()}
                  </Col>
                  {isFlexibleSubscriptionEnabled &&
                    this.renderProductDetailsForFlexibleSubscription()}
                </Row>
              </Col>
            </Row>
          </Col>
          {!isFlexibleSubscriptionEnabled && this.renderProductDetails()}
        </Row>
      </Card>
    )
  }
}

export { CartProductBlock }
export default CartProductBlock
