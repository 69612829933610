import React from 'react'
import { observer } from 'mobx-react'
import { DatePickerV2 } from 'src/views/components'
import {
  isToShowTwoMonthCalender,
  getPastSubscriptionNonProcessedDates,
  getFutureSubscriptionDates,
} from 'src/deps'
import './styles.scss'

@observer
class SubscriptionDatePicker extends React.Component {
  /**
   * Handles the change event for the calendar date picker.
   * @param {string} [timeStamp=''] - The timestamp of the selected date.
   * @returns {void}
   */
  handleChangeCalendar = (timeStamp = '') => {
    const date = new Date(timeStamp)
    this.props?.handleCalenderDateChange?.(date)
  }

  getDateDetails = () => {
    const {
      availableSubscriptionDatesOfMonthLimit = [],
      minDateDetails = {},
      maxDateDetails = {},
      alreadyProcessedOrderDates = [],
      orderProcessedFullDate = '',
      availableOrderDates = [],
    } = this.props
    const nonProcessedPastSubscriptionDates =
      getPastSubscriptionNonProcessedDates(
        availableSubscriptionDatesOfMonthLimit,
        minDateDetails
      ) || []
    const nonProcessedFutureSubscriptionDates =
      getFutureSubscriptionDates(
        availableSubscriptionDatesOfMonthLimit,
        minDateDetails
      ) || []
    const minDate = minDateDetails?.minFullDate || ''
    const maxDate = maxDateDetails?.maxFullDate || ''
    const isToShowTwoMonthProcessingDates = isToShowTwoMonthCalender()
    const isToDisableCurrentMonthDatesInNextMonth =
      !isToShowTwoMonthProcessingDates

    return {
      orderProcessedFullDate,
      availableSubscriptionDatesOfMonthLimit,
      alreadyProcessedOrderDates,
      nonProcessedPastSubscriptionDates,
      nonProcessedFutureSubscriptionDates,
      minDate,
      maxDate,
      isToShowTwoMonthProcessingDates,
      isToDisableCurrentMonthDatesInNextMonth,
      availableOrderDates,
    }
  }

  render() {
    const {
      orderProcessedFullDate = '',
      availableOrderDates = [],
      alreadyProcessedOrderDates = [],
      nonProcessedPastSubscriptionDates = [],
      nonProcessedFutureSubscriptionDates = [],
      minDate = '',
      maxDate = '',
      isToShowTwoMonthProcessingDates = false,
      isToDisableCurrentMonthDatesInNextMonth = false,
    } = this.getDateDetails()

    return (
      <div className="cart-date-picker-wrapper">
        <DatePickerV2
          minDate={minDate}
          maxDate={maxDate}
          highlightDates={nonProcessedFutureSubscriptionDates}
          selectedDateCalendar={orderProcessedFullDate}
          subscriptionProcessedDates={alreadyProcessedOrderDates}
          nonProcessedPastSubscriptionDates={nonProcessedPastSubscriptionDates}
          availableOrderDates={availableOrderDates}
          UTCclock={false}
          disableCurrentMonthUnprocessedDatesInNextMonth={
            isToDisableCurrentMonthDatesInNextMonth
          }
          handleChangeCalendar={this.handleChangeCalendar}
        />
      </div>
    )
  }
}

export default SubscriptionDatePicker
export { SubscriptionDatePicker }
