import { getLocalStorage, i18nTranslate } from 'src/utils'
import { toastState } from 'src/views/components'
import { dateFormatDeps } from 'src/deps'

let pickupTime = ''
let eventCloseAlertTime = ''
let pageReloadTimeoutSet = false

// Get translation key for current time from event timeline array
function getTranslationKeyForCurrentTime(arr = []) {
  let latestStartTime = null
  let translationKey = null
  let latestEndTime = null
  let currentTime = null

  // Loop through event timeline array to find the translation key for current time
  // If multiple timeline overlap, get the key of the timeline with the latest start time
  for (let i = 0; i < arr?.length; i++) {
    const timeZone = arr[i]?.timeZone || 'US/Mountain'
    // Current time is converted to the timezone event is in
    currentTime = new Date(new Date().toLocaleString('en-US', { timeZone }))
    const startTime = new Date(arr[i]?.startTime)
    const endTime = new Date(arr[i]?.endTime)
    if (startTime <= currentTime && currentTime <= endTime) {
      if (latestStartTime === null || startTime >= latestStartTime) {
        latestStartTime = startTime
        latestEndTime = endTime
        translationKey = arr[i]?.translationKey
      }
    }
  }

  // Set event close alert start time as latest end time
  if (latestEndTime === null) {
    latestEndTime = eventCloseAlertTime
  }
  // Set timeout to reload page after current event ends
  const delay = latestEndTime - currentTime
  // Max allowed delay is 32-bit signed integer by browsers
  // Hence setting max delay as 24 days
  if (!pageReloadTimeoutSet && delay >= 0 && delay < 2073600000) {
    pageReloadTimeoutSet = true
    setTimeout(() => {
      window.location.reload()
    }, delay)
  }
  return translationKey
}

// Show popup if not shown then show toast after popup is closed by user
// If popup is already shown, show toast only
function showPopupAndToast(
  message = '',
  key = '',
  enableEventModal,
  modalMessage = ''
) {
  let popupShown = getLocalStorage('popupShown' + key) || false

  if (popupShown !== true) {
    enableEventModal(modalMessage)
  } else {
    toastState.setToastMessage(
      message,
      true,
      6000,
      '',
      false,
      true,
      '',
      'live-event-toast-info'
    )
  }
}

// Show popup if not shown
function showPopup(
  modalTitle = '',
  modalMessage = '',
  showRedirection = true,
  key = '',
  enableEventModal
) {
  let popupShown = getLocalStorage('popupShown' + key) || false

  if (popupShown !== true) {
    enableEventModal(modalMessage, modalTitle, showRedirection)
  }
}

// Set pickup time from 'afterEvent1' node
function getPickupTime(arr = []) {
  let event = arr?.find(item => item?.translationKey === 'afterEvent1')
  pickupTime = dateFormatDeps?.getTime(event?.endTime)
}

// Set event close alert time from 'duringEvent' node
function getEventCloseAlertTime(arr = []) {
  let event = arr?.find(item => item?.translationKey === 'duringEvent')
  eventCloseAlertTime = new Date(event?.startTime)
}

function enableEventNotifications(arr = [], enableEventModal) {
  // Set pickup time if not set
  if (pickupTime === '') {
    getPickupTime(arr)
  }
  // Set event end time if not set
  if (eventCloseAlertTime === '') {
    getEventCloseAlertTime(arr)
  }

  let key = getTranslationKeyForCurrentTime(arr)

  // Show toast/popup based on translation key
  switch (key) {
    // Show only toast
    case 'beforeEvent': {
      let event = arr?.find(item => item?.translationKey === 'beforeEvent')
      let message =
        i18nTranslate(
          'event.beforeEvent',
          'Orders will open up on {date} at {time} (MDT)',
          {
            nameSpace: 'ssr-resource',
          }
        )
          ?.replace('{date}', dateFormatDeps?.getMonthAndDate(event?.endTime))
          ?.replace('{time}', dateFormatDeps?.getTime(event?.endTime)) || ''
      toastState?.setToastMessage(
        message,
        true,
        6000,
        '',
        false,
        true,
        '',
        'live-event-toast-info'
      )
      break
    }

    // Show only toast
    case 'duringEvent': {
      let event = arr?.find(item => item?.translationKey === 'duringEvent')
      let message =
        i18nTranslate(
          'event.duringEvent',
          'Orders may be placed until {time}. Pick-up is available until {pickupTime}.',
          {
            nameSpace: 'ssr-resource',
          }
        )
          .replace('{time}', dateFormatDeps?.getTime(event?.endTime))
          .replace('{pickupTime}', pickupTime) || ''
      toastState?.setToastMessage(
        message,
        true,
        6000,
        '',
        false,
        true,
        '',
        'live-event-toast-info'
      )
      break
    }

    // Show popup first then toast
    case 'afterEvent1': {
      let key = 'afterEvent1'
      let message =
        i18nTranslate(
          'event.afterEvent1',
          'Pick up is available until {pickupTime}. Unclaimed orders will be refunded to original payment in 5-7 business days.',
          {
            nameSpace: 'ssr-resource',
          }
        ).replace('{pickupTime}', pickupTime) || ''
      let modalMessage =
        i18nTranslate(
          'event.orderClosePopup',
          'Orders have now been closed. Pick-up is available until {pickupTime}.',
          {
            nameSpace: 'ssr-resource',
          }
        ).replace('{pickupTime}', pickupTime) || ''
      showPopupAndToast(message, key, enableEventModal, modalMessage)
      break
    }

    // Show popup first then toast
    case 'afterEvent2': {
      let key = 'afterEvent2'
      let message =
        i18nTranslate(
          'event.afterEvent2',
          'Orders and order pick-ups have now been closed. Unclaimed orders will be refunded to original payment in 5-7 business days.',
          {
            nameSpace: 'ssr-resource',
          }
        ) || ''
      showPopupAndToast(message, key, enableEventModal, message)
      break
    }

    case 'eventOffline': {
      let key = 'eventOffline'
      let modalTitle = i18nTranslate(
        'event.offlineTitle',
        'July Live Event is currently unavailable.',
        {
          nameSpace: 'ssr-resource',
        }
      )
      let modalMessage = i18nTranslate(
        'event.offlineMessage',
        'Please check back soon!',
        {
          nameSpace: 'ssr-resource',
        }
      )
      let showRedirection = false
      showPopup(
        modalTitle,
        modalMessage,
        showRedirection,
        key,
        enableEventModal
      )
    }

    default:
      break
  }
}

export { enableEventNotifications, getTranslationKeyForCurrentTime }
