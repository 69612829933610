import React from 'react'
import { observer } from 'mobx-react'
import { isBrandAffiliateTypeProfile } from 'src/views/pages/MyAccount/MyAccountProfile/LoyaltyForm/fixture'
import './styles.scss'

@observer
class ProductEarnPointText extends React.Component {
  render() {
    const {
      selectedSku = {},
      pointEarn = 'false',
      isBundle,
      isCollection,
    } = this.props
    const { skuProperties = {} } = this.props
    // const pointEarnVal =
    //   isBundle || isCollection
    //     ? pointEarn
    //     : selectedSku.properties.pointEarn || 'false'
    const pointEarnVal =
      isBundle || isCollection ? pointEarn : skuProperties.pointEarn || 'false'
    const pointEarnStringify =
      typeof pointEarnVal === 'boolean'
        ? String(pointEarn).toLowerCase()
        : pointEarn
    if (pointEarnStringify === 'true' && !isBrandAffiliateTypeProfile()) {
      {
        /* Commented below  line for as per Loyalty CR (CX121-2283)  */
      }
      {
        /*if (
        (isLoyEnableInProf() &&
          isAllowedRewardTypeForOptIn() &&
          customerContainer.isRegisterUser) ||
        (isBuyerUser() && isLoyEnableInProf())
      ) {
        return (
          <div
            data-testid="qa-product-earnPointTxt"
            className="product-earnPointTxt-wrapper d-flex flex-column mt-2">
            <span className="product-earnPointTxt font-weight-bolder text-success">
              {i18nTranslate(
                'pdp.pdpLoyaltyEarnPointTxt',
                'Earn Nu Skin Rewards!'
              )}
            </span>
          </div>
        )
      }

      if (!customerContainer.isRegisterUser || !isLoyEnableInProf()) {
        return (
          <div
            data-testid="qa-product-earnPointTxt"
            className="product-earnPointTxt-wrapper d-flex flex-column mt-2">
            <span className="product-earnPointTxt font-weight-bolder text-primary">
              {i18nTranslate(
                'pdp.pdpLoyaltyEarnPointWaitingTxt',
                'Your Nu Skin Rewards are waiting!'
              )}
            </span>
          </div>
        )
      }*/
      }
    }
    return ''
  }
}

export { ProductEarnPointText }
