import { observable, action } from 'mobx'
import { i18nTranslate } from 'src/utils'
import { CommonContainer } from 'src/models/Common'
import {
  customerContainer,
  storeContainer,
  checkoutContainer,
  nuskinSubscriptionContainer,
  geocodeApi,
  catalogContainer,
} from 'src/models'
import {
  application,
  setLocalStorage,
  IS_BROWSER,
  getShoppingContext,
  trackEnhancedCart,
  trackEnhancedCartEvent,
  clearShoppingContext,
  trackErrorInInstana,
  deleteFromLocalStorage,
  getLocalStorage,
  getLiveEventStatus,
  isGuestCheckoutEnabledForWeb,
  convertToBoolean,
  isExpressCheckout,
} from 'src/utils'
import { toastState, quickViewState } from 'src/views/components'
import { builder } from './builder'
import { pageNames } from 'src/routes/pathParams'
import { sendCartCount } from 'src/utils/reactNativeAppUtils'
import { alertMessageKeys } from 'src/views/components/CartBlock/fixture'
import { getSessionStorage, APPConfig } from 'config/appConfig'
import {
  normalAndSpecialProductSkuType,
  specialProductTypes,
  normalProductSkuType,
} from 'src/views/components/CatalogViews'

/**
 * CartContainer class that extends CommonContainer.
 * Represents the cart data and state for the shopping cart.
 */
class CartContainer extends CommonContainer {
  constructor(props) {
    super(props)
  }
  @observable isCartVisible = false
  @observable cartButtonDisable = false
  @observable cartResponse = {}
  @observable cartCount = 0
  @observable errorInEstimationZipcode = false
  @observable estimatedShippingCost = ''
  @observable scanCardDetails = []
  @observable isCartResponseLoading = true
  @observable subscriptionResponse = {}
  @observable inventoryConfirm = false
  scanCountAddToCart = 0
  scanCardVisibilityCount = 0
  quantityDecrement = true
  @observable inventoryResponse = {}
  toShowToast = true
  isExclusiveProductFromPDP = false
  @observable isDisableAddToCart = ''
  @observable isDisableSubscribeBtn = ''
  @observable cartUserId = ''
  @observable cartUpdateInProgress = false
  @observable cartPrefilledData = {}
  @observable foreignOrderRestrictionResponse = {}
  @observable restrictedItems = []
  @observable isOrderIdAndRestrictionCallInProgress = false
  @observable promoCodesList = []
  enableLiveEvent = getLiveEventStatus()

  @action
  /**
   * Toggles the visibility of the cart.
   *
   * Sets isCartVisible to the opposite of its current value.
   *
   * For larger screens, adds or removes the 'no-scroll' class on the body
   * depending on the new isCartVisible value. This disables scrolling when
   * the cart is open.
   *
   * Also resets the shipping estimation error and cost.
   */
  toggleCartVisibility = () => {
    this.isCartVisible = !this.isCartVisible
    {
      application.isTabletOrLarger && this.isCartVisible
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll')
    }
    this.errorInEstimationZipcode = false
    this.estimatedShippingCost = ''
  }
  /**
   * Toggles the inventoryConfirm state to false.
   *
   * Sets the observable inventoryConfirm property to false.
   */
  toggleinventoryConfirm = () => {
    this.inventoryConfirm = false
  }

  /**
   * Sets the cart products in local storage to be used for Qualtrics survey.
   * Maps the cart response items to an array of product ids.
   * If there are items, stringifies the array and saves to localStorage.
   * If no items, checks if cartProducts is already in localStorage and deletes it if so.
   */
  setCartProductsForQualtrics(cartResponse) {
    const items = cartResponse?.items?.map(item => {
      return { id: item?.itemId }
    })

    if (items?.length > 0) {
      setLocalStorage('cartProducts', { products: items })
    } else {
      let checkCartProducts = getLocalStorage('cartProducts')
      if (checkCartProducts) {
        deleteFromLocalStorage('cartProducts')
      }
    }
  }

  /**
   * Fetches the cart details and updates the cart state.
   *
   * @param {Function} loyaltyPointShow - A callback function to handle loyalty point display.
   * @param {boolean} isFromInit - Indicates if the viewCart function is called from initialization.
   * @returns {Promise<Object>} - The cart response object.
   */
  @action
  viewCart = async (loyaltyPointShow, isFromInit = false) => {
    let shoppingContext
    let sponsor
    let wholesale
    let retailSponsorId
    let contextValue
    let cartResponse
    let cartLoadParams
    let isCheckout = false
    let isExpressCheckout = false

    const userId = customerContainer?.userId || ''
    const cartType = checkoutContainer.getCartTypeFromURL() || ''
    this.isCartResponseLoading = true
    this.inventoryConfirm = false
    if (IS_BROWSER && typeof window !== 'undefined') {
      shoppingContext = getShoppingContext()
      // shoppingContext = getLocalStorage('shoppingContext') || {}
      sponsor = getSessionStorage('sponsor') || ''
      if (Object.keys(shoppingContext).length > 0) {
        try {
          if (cartType === 'USER') {
            // @note If CartType is 'USER', then we should not have ShoppingContext in localStorage
            // added log to track the mismatch scenario
            trackErrorInInstana({
              errorReport: `ViewCart API - Cart Type mismatches with Local Storage ShoppingContext - UserId: ${userId}`,
              errorData: {
                cartTypeInURL: cartType,
                hasShoppingContextInStorage: shoppingContext?.context || {},
              },
            })
            clearShoppingContext()
          } else {
            contextValue = shoppingContext?.context
            const sponsorId = sponsor || ''
            if (
              contextValue === 'personal_offer' ||
              contextValue === 'storefront'
            ) {
              //adding showWholeSalePricing property in cart request header
              const isRetailCustomer =
                customerContainer?.profileResponse?.accounts?.accountType ===
                'Retail Customer'
              if (isRetailCustomer) {
                retailSponsorId = sponsorId
              } else if (!customerContainer.isRegisterUser) {
                wholesale = JSON.stringify(
                  shoppingContext?.overrides?.showWholeSalePricing
                )
              }
            }
          }
        } catch (e) {
          console.error('JSON.parse may be an error > validateLogin ', e)
        }
      }
      //Checking the current page is checkout page or not
      isCheckout = window?.location?.pathname?.includes(pageNames.checkout)
      isExpressCheckout =
        IS_BROWSER &&
        typeof window !== 'undefined' &&
        [pageNames.expressCheckout, pageNames.expressPDP].some(page =>
          window?.location?.pathname?.includes(page)
        )
    }
    /**
     * @note Need to make /carts/USER/checkout API for Tax calculation if the page is checkout page
     * else carts/USER API will be triggered for live cart updates
     */
    isCheckout || isExpressCheckout
      ? (cartLoadParams = {
          endPointName: 'viewCartForCheckout',
          headers: {},
        })
      : (cartLoadParams = {
          endPointName: 'viewCart',
          headers: {},
        })

    if (cartType) {
      // @note For Klarna/Alipay Payment, to avoid the cartType mismatch issue,
      // once redirected from paymentSite, CartType is detected from the RedirectionURL and appended to the Cart API

      trackErrorInInstana({
        errorReport: `Cart API - CartType from Redirection URL - UserId: ${userId}`,
        errorData: {
          cartTypeInURL: cartType,
        },
      })
      cartLoadParams.cartType = cartType
    }

    if (retailSponsorId) {
      cartLoadParams['headers'] = { 'x-nuskin-sponsorid': retailSponsorId }
    }

    if (wholesale == 'true' || wholesale == true) {
      if (cartLoadParams?.headers) {
        cartLoadParams.headers = {
          ...cartLoadParams?.headers,
          showWholesalePricing: wholesale,
        }
      } else {
        cartLoadParams = {
          ...cartLoadParams,
          headers: { showWholesalePricing: wholesale },
        }
      }
    }
    cartResponse = await this.fetchResponse(cartLoadParams)
    this.setCartProductsForQualtrics(cartResponse)
    let itemId = cartResponse?.items?.[0]?.itemId || ''
    this.cartUserId = cartResponse?.userId || ''

    // @note As per BE suggestion, To avoid multiple Patch Updates for MSCart/POCart,
    // triggering PATCH API only on Init
    if (
      (contextValue === 'personal_offer' || contextValue === 'storefront') &&
      itemId &&
      isFromInit
    ) {
      let cartType = contextValue === 'personal_offer' ? 'POCART' : 'MSCART'
      let showWholeSalePricingObj = {
        showWholeSalePricing: new Date().getTime(),
      }
      let payload = {
        properties: showWholeSalePricingObj,
      }

      let loadParams = {
        endPointName: 'mysiteCartPatch',
        pathParams: `${cartType}/items/${itemId}`,
        postData: payload,
      }
      const response = await this.fetchResponse(loadParams)
      cartResponse = await this.fetchResponse(cartLoadParams)
    }

    if (cartResponse.errorMessage === 'Out of stock') {
      this.inventoryConfirm = true
    }
    if (cartResponse.errorMessage === 'Item is BackOrdered') {
      let inventoryerrorcode = cartResponse.items?.find(
        e => e.errorMessage == 'Item is BackOrdered'
      )
      await this.updateCart({
        id: inventoryerrorcode?.itemId || 0,
        properties: {
          isBackOrdered: true,
        },
      })
    }
    this.cartResponse = cartResponse
    this.cartCount = cartResponse?.value?.count || 0
    this.isCartResponseLoading = false
    checkoutContainer.checkAndDisablePaymentContinueBtn()
    // this.cartCount > 0 ? false : this.isCartResponseLoading
    typeof loyaltyPointShow === 'function' && loyaltyPointShow()
    if (typeof cartResponse['errorCode'] !== 'undefined') {
      this.cartButtonDisable = true
      if (cartResponse?.errorCode === 'EOPLOR001') {
        toastState.setToastMessage(
          i18nTranslate(
            'cart.homeMarket',
            'Please switch to your home market to make a purchase.'
          )
        )
      } else {
        const hideToast =
          this.isExclusiveProductFromPDP &&
          cartResponse?.errorCode?.toLowerCase() === 'customernotallowed'
        if (!isFromInit && !hideToast) {
          if (cartResponse.errorMessage === 'Out of stock') {
            toastState.setToastMessage(
              i18nTranslate('cart.outOfStock', 'Out of stock')
            )
          } else if (cartResponse?.errorCode === 'EOSKUPS002') {
            toastState.setToastMessage(
              i18nTranslate(
                'bundle.discontinuedV2Message',
                'Discontinued - This product or promotion has been discontinued',
                {
                  nameSpace: 'ssr-resource',
                }
              )
            )
          } else {
            toastState.setToastMessage(cartResponse.errorMessage)
          }
        }
      }
    } else this.cartButtonDisable = false
    if (isFromInit) {
      await checkoutContainer.deleteMultiplePaymentById()
    }
    nuskinSubscriptionContainer.isToDisableSubscriptionButton =
      nuskinSubscriptionContainer.isSubscriptionProductInCart()
    nuskinSubscriptionContainer.isViewCartCallInProgress = false
    sendCartCount(this.cartCount)
    return cartResponse
  }

  @action
  viewCartForTax = async () => {
    let cartLoadParams = {}
    let cartResponse
    cartLoadParams = {
      endPointName: 'viewCartForCheckout',
      headers: {},
    }
    const cartType = checkoutContainer.getCartTypeFromURL() || ''
    if (cartType) {
      cartLoadParams.cartType = cartType
    }
    cartResponse = await this.fetchResponse(cartLoadParams)
    this.cartResponse = cartResponse
    return cartResponse
  }

  @action
  /**
   * Applies a scan card to the cart.
   *
   * @param {Object} scanCardDetails - Object containing scan card details.
   * @returns {Promise} Promise resolving to API response.
   */
  scanCardApply = async scanCardDetails => {
    let req = {
      scanCardNumber: scanCardDetails.cardNumber,
    }
    const loadParams = {
      endPointName: 'scanCardValidate',
      pathParams: scanCardDetails.cardNumber,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  @action
  /**
   * Fetches scan card details from the API.
   *
   * Calls the 'getscanCardDetails' API endpoint to retrieve the list of scan cards.
   * Sets the response to this.scanCardDetails and returns it.
   */
  scanCardList = async () => {
    const loadParams = {
      endPointName: 'getscanCardDetails',
    }
    const response = await this.fetchResponse(loadParams)
    this.scanCardDetails = response?.subscriptionScanCard
    return response?.subscriptionScanCard
  }

  @action
  /**
   * Creates a new scan card.
   *
   * @param {Object} scanCardDetails - Details of the scan card to create.
   * @returns {Promise} Promise resolving to API response.
   */
  createScanCard = async scanCardDetails => {
    const loadParams = {
      endPointName: 'createscanCard',
      postData: scanCardDetails,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  @action
  /**
   * Removes a scan card by ID.
   *
   * @param {string} id - The ID of the scan card to remove.
   * @returns {Promise} A promise that resolves to the API response.
   */
  removeScanCard = async id => {
    const loadParams = {
      endPointName: 'removeScanCardDetails',
      pathParams: id,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  @action
  /**
   * Adds product(s) to cart by calling addToCart API endpoint.
   *
   * Accepts cartDetails object containing product info like quantity, skuId etc.
   * Maps cart details to expected API payload.
   * Calls addToCart endpoint to add products to cart.
   * On success, shows add to cart success toast, updates cart, toggles cart visibility.
   * On failure, shows add to cart failure toast.
   * Returns API response.
   */
  addToCart = async cartDetails => {
    const scanCount = this.scanCountAddToCart
    this.isExclusiveProductFromPDP = cartDetails?.isExclusiveProductFromPDP
    const {
      isCheckout = false,
      cartArray,
      homePageAddToCart = false,
      isShowToast = true,
      price,
      variant,
    } = cartDetails
    let toShowToast = isShowToast && this.toShowToast
    // const { pathname } = location
    const getPostData = cartArray.map(item => {
      const {
        quantity,
        skuId = '',
        productId = '',
        skus = [],
        categoryId = '',
        isBundle = false,
        brand,
        slug,
        imageURL,
        name,
        isBackOrdered = false,
      } = item
      const skuDetails = skus.length
        ? skus
        : [{ skuId, productId, type: 'DEFAULT' }]
      let isBackOrderedForBundle = false
      if (isBundle) {
        skuDetails?.forEach(sku => {
          if (sku.backOrdered || sku.isBackOrdered) {
            isBackOrderedForBundle = true
          }
        })
      }
      const properties = {
        categoryId: categoryId,
        slug: slug,
        scanQualifiedCount: scanCount,
      }
      // @##backorder_logic_cart
      const isBackOrderedPropertyForCart = isBundle
        ? isBackOrderedForBundle
        : isBackOrdered
      if (isBackOrderedPropertyForCart) {
        properties.isBackOrdered = true
      }
      if (isBundle) {
        properties.brand = brand
        properties.imageURL = imageURL
        properties.name = name
        properties.productId = productId
      }
      return {
        quantity,
        skus: skuDetails,
        properties: properties,
      }
    })
    const loadParams = {
      endPointName: 'addToCart',
      postData: getPostData,
    }
    const response = await this.fetchResponse(loadParams)
    if (toShowToast == true && !isExpressCheckout()) {
      if (this.isSuccessResponse(response)) {
        if (quickViewState.isQuickViewOpen) {
          quickViewState.toggleQuickView()
        }
        // quickViewState.isQuickViewOpen = false
        // quickViewState.productCount = 1
        this.showAddToCartSuccessMessage()
        //----------DataLayer Objectof Product Added To Cart------------
        const user = customerContainer.profileResponse

        const info = {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
        }
        this.enableLiveEvent = getLiveEventStatus()
        if (this.enableLiveEvent === 'true') {
          trackEnhancedCartEvent({
            type: 'add_to_cart',
            product: getPostData,
            apiResponse: response,
            method: 'add',
            price,
            variant,
          })
        }
        trackEnhancedCart({
          type: 'addToCart',
          product: getPostData,
          apiResponse: response,
          method: 'add',
          price,
          variant,
        })
      } else {
        this.showAddToCartFailureMessage(response)
      }
    }
    if (this.isSuccessResponse(response)) {
      await this.viewCart()
      this.isExclusiveProductFromPDP = false
      if (homePageAddToCart) {
        cartContainer.toggleCartVisibility()
      }
    }
    return response
    // return viewBagResponse
  }
  // Special Products Promotion
  @action
  /**
   * Makes a promotion API call to get promotions for a product.
   *
   * @param {Object} prdDetails - Details of product to get promotions for
   * @returns {Promise} - Promise resolving to API response
   */

  // to be moved to src\models\Promotion\Promotion.js promotionContainer
  makePromotionCall = async prdDetails => {
    const {
      skuId = '',
      productId = '',
      quantity = 1,
      skus = [],
      products = [],
      categoryId = '',
      type,
      isSubscriptionIncludedInPromotion = false,
      isSubscriptionPromotion = false,
      isCallMadeFromNewDesignPDP = false,
    } = prdDetails
    const skuOrProducts = skus.length > 0 ? skus : products
    const skuDetails = skuOrProducts.length
      ? skuOrProducts.map(sku => {
          if (productId !== sku.skuId) {
            return {
              skuId: sku.skuId,
              productId: sku.productId,
              quantity: sku.skuQuantity || sku.optionalSkuQuantity,
              type: type,
            }
          }
        })
      : [{ skuId, productId, type: 'DEFAULT' }]

    const getPostData = {
      productId: productId,
      categoryId: [categoryId],
      quantity: quantity,
      skus: skuDetails.filter(function (values) {
        return values != null
      }),
      isSubscriptionIncludedInPromotion: isSubscriptionIncludedInPromotion,
    }

    if (!isSubscriptionIncludedInPromotion) {
      getPostData.isFromBrowse = true
    }
    if (!isCallMadeFromNewDesignPDP && isSubscriptionIncludedInPromotion) {
      getPostData.isPriceMarkdown = true
      /*
       * https://nuskin.atlassian.net/browse/BRW-3434
       * instead of subscription price as original price in pop-ups
       * adding new flag for getting regular one-time price as original price
       *
       * param will be added for:
       *     cart -> subscription pop-up\
       *     dashboard -> quick add pop-up
       *     old pdp -> subscription pop-up
       *     cart new design subscription drawer
       * ---
       * param will "not" be added for:
       *     new pdp subscription scenarios
       *     mysite
       *     po site
       *     all other implementations
       *
       */
    }
    if (isSubscriptionIncludedInPromotion && isSubscriptionPromotion) {
      /*
       * https://nuskin.atlassian.net/browse/CX15-9704
       *  while making promotion call ""ONLY"" from subscription modal
       * this is avoided in other places to avoid "subscribe and save" display
       */
      getPostData.isSubscriptionPromotion = true
    } else if (!isSubscriptionIncludedInPromotion) {
      // To get isSubscribeSaveEnabled flag to show promotion
      getPostData.isFromBrowse = true
    }
    let profileResponse = customerContainer.profileResponse
    const price_accountTypes = storeContainer.priceAccountTypes
    /**
     * @note
     * Commenting redundant calls for refactoring
     */

    let accounts = profileResponse?.accounts || {}
    let accountType = accounts?.type || ''

    const loadParams = {
      endPointName: 'getProductbyIdandPromotion',
      pathParams: `${productId}/promotions`,
      postData: getPostData,
      queryParams: {},
    }

    if (
      accountType &&
      price_accountTypes &&
      price_accountTypes.includes(accountType)
    ) {
      loadParams.queryParams.priceParam = JSON.stringify({
        [storeContainer.accountKey]: accountType,
      })
    }

    const response = await this.fetchResponse(loadParams)
    return response
  }
  /**
   * Updates the scanCardVisibilityCount property with the provided value.
   * If value is greater than 0, sets scanCardVisibilityCount to that value.
   * Otherwise, sets scanCardVisibilityCount to 0.
   */
  updateScanvisibilityCount = value => {
    this.scanCardVisibilityCount = value > 0 ? value : 0
  }

  // Normal Products Promotion
  @action
  /**
   * Fetches promotions for a normal product based on provided product details.
   *
   * Constructs the API request parameters based on the product details, including:
   * - productId, categoryId, quantity
   * - skuId, type
   * - subscription promotion flags
   *
   * Adds wholesale pricing headers if a storefront context is present.
   *
   * Calls API endpoint with constructed parameters.
   *
   * Returns promotion response for normal product.
   */
  makePromotionCallsForNormalProduct = async prdDetails => {
    const {
      skuId = '',
      productId = '',
      categoryId = '',
      quantity = '1',
      type = 'DEFAULT',
      isSubscriptionIncludedInPromotion = false,
      isSubscriptionPromotion = false,
      isCallMadeFromNewDesignPDP = false,
    } = prdDetails

    const skuDetails = [{ skuId, productId, type }]

    const getPostData = {
      productId: productId,
      categoryId: [categoryId],
      quantity: quantity,
      skus: skuDetails,
      isSubscriptionIncludedInPromotion: isSubscriptionIncludedInPromotion,
    }
    if (isSubscriptionIncludedInPromotion && isSubscriptionPromotion) {
      /*
       * https://nuskin.atlassian.net/browse/CX15-9704
       *  while making promotion call ""ONLY"" from subscription modal
       * this is avoided in other places to avoid "subscribe and save" display
       */
      getPostData.isSubscriptionPromotion = true
    } else if (!isSubscriptionIncludedInPromotion) {
      // To get isSubscribeSaveEnabled flag to show promotion
      getPostData.isFromBrowse = true
    }

    if (!isCallMadeFromNewDesignPDP && isSubscriptionIncludedInPromotion) {
      getPostData.isPriceMarkdown = true
      /*
       * https://nuskin.atlassian.net/browse/BRW-3434
       * instead of subscription price as original price in pop-ups
       * adding new flag for getting regular one-time price as original price
       *
       * param will be added for:
       *     cart -> subscription pop-up\
       *     dashboard -> quick add pop-up
       *     old pdp -> subscription pop-up
       *     cart new design subscription drawer
       * ---
       * param will "not" be added for:
       *     new pdp subscription scenarios
       *     mysite
       *     po site
       *     all other implementations
       *
       */
    }

    let profileResponse = customerContainer.profileResponse
    const price_accountTypes = storeContainer.priceAccountTypes
    /**
     * @note
     * Commenting redundant calls for refactoring
     */

    let accounts = profileResponse?.accounts || {}
    let accountType = accounts?.type || ''

    let loadParams = {
      endPointName: 'getProductbyIdandPromotion',
      pathParams: `${productId}/promotions`,
      postData: getPostData,
      queryParams: {},
    }

    let shoppingContext
    let storefront
    let wholesale
    let contextValue

    if (IS_BROWSER && typeof window !== 'undefined') {
      shoppingContext = getShoppingContext()
      // shoppingContext = getLocalStorage('shoppingContext') || {}
      storefront = getSessionStorage('storefront') || {}
      if (Object.keys(shoppingContext).length > 0) {
        try {
          contextValue = shoppingContext?.context
          if (
            contextValue === 'personal_offer' ||
            contextValue === 'storefront'
          ) {
            //adding showWholeSalePricing property in cart request header
            if (!customerContainer.isRegisterUser) {
              wholesale = JSON.stringify(
                shoppingContext?.overrides?.showWholeSalePricing
              )
            } else {
              wholesale =
                JSON.stringify(
                  storefront?.['storefront']?.['mytestsqa']?.[`${country}`]?.[
                    `${language}`
                  ]?.['wholesale']
                ) || ''
            }
          }
          loadParams = {
            ...loadParams,
            headers: { showWholesalePricing: wholesale },
          }
        } catch (e) {
          console.error('JSON.parse may be an error > validateLogin ', e)
        }
      }
    }

    if (
      accountType &&
      price_accountTypes &&
      price_accountTypes.includes(accountType)
    ) {
      try {
        loadParams.queryParams.priceParam = JSON.stringify({
          [storeContainer.accountKey]: accountType,
        })
      } catch (e) {
        loadParams.queryParams.priceParam = '{}'
      }
    }

    const response = await this.fetchResponse(loadParams)
    return response
  }
  //Redeemable Points
  /**
   * Gets the number of loyalty points redeemed towards the order total.
   *
   * Looks at the cart response and returns either:
   * - totalLoyaltyPointsToPay: Total number of points redeemed
   * - loyaltyDiscount: Dollar amount discounted by points, indicating points were redeemed
   *
   * Returns 0 if no points redeemed.
   */
  getRedeemLoyaltyPoints = () => {
    const redeemPoints =
      this.cartResponse?.value?.totalLoyaltyPointsToPay ||
      this.cartResponse?.value?.loyaltyDiscount
    return redeemPoints
  }

  //Earned Loyalty Points
  /**
   * Gets the number of loyalty points earned from the current order.
   *
   * Looks at the cart response and returns the pointEarnedThisOrder value,
   * or 0 if no points were earned.
   */
  earnedLoyaltyPoints = () => {
    const earnedPoints = this.cartResponse?.loyalty?.pointEarnedThisOrder || 0.0
    return earnedPoints === 0 ? 0.0 : earnedPoints
  }

  //redeemed loyalty Points -Pyment through Loyalty Points
  /**
   * Gets the number of loyalty points redeemed towards the order total by looking at the cart response.
   *
   * Returns the redeemed loyalty points amount, or 0 if no points were redeemed.
   */
  redeemedPoints = () => {
    const payment = this.cartResponse?.loyaltyDetails
    // this.cartResponse?.payments || this.cartResponse?.loyaltyDetails

    let loyaltypayment = 0
    if (payment) {
      loyaltypayment = payment?.filter(points => {
        return points?.type == 'LOYALTY'
      })
    }
    const paymentvalue = loyaltypayment?.[0]?.value || '0.00'
    // loyaltypayment?.[0]?.amount || loyaltypayment?.[0]?.value || '0.00'
    return paymentvalue
  }
  /**
   * Gets the amount of store credit redeemed towards the order total.
   *
   * Looks at the cart response payments and filters for store credit.
   * Returns the amount of the first store credit payment,
   * or '0.00' if no store credit was used.
   */
  redeemedStoreCreditPoints = () => {
    const payments = this.cartResponse?.payments
    let storeCredit = 0
    if (payments) {
      storeCredit = payments?.filter(points => {
        return points?.type?.toLowerCase() == 'storecredit'
      })
    }
    const paymentvalue = storeCredit?.[0]?.amount || '0.00'
    return paymentvalue
  }

  /**
   * Updates the cart with the provided cart details.
   * @param {Object} cartDetails - The details of the cart to be updated.
   * @param {boolean} enableToastMessage - Flag indicating whether to display toast messages.
   * @returns {Promise<Object>} - The response from the cart update operation.
   */
  updateCart = async (cartDetails = {}, enableToastMessage = true) => {
    this.cartUpdateInProgress = true
    const {
      quantity,
      id,
      subscription,
      properties,
      subscriptionData = {},
      type = '',
    } = cartDetails

    let loadParams = {}
    if (subscription) {
      loadParams = {
        endPointName: 'updateCartItem',
        pathParams: id,
        postData: { ...subscription },
      }
    } else {
      if (properties) {
        loadParams = {
          endPointName: 'updateCartItem',
          pathParams: id,
          postData: { properties },
        }
      } else if (JSON.stringify(subscriptionData) !== '{}') {
        loadParams = {
          endPointName: 'updateCartItem',
          pathParams: id,
          postData: { ...subscriptionData },
        }
      } else {
        loadParams = {
          endPointName: 'updateCartItem',
          pathParams: id,
          postData: { quantity },
        }
      }
    }
    const response = await this.fetchResponse(loadParams)
    this.inventoryResponse = response
    if (properties) {
      if (!this.isSuccessResponse(response)) {
        const unProcessableRequestMessage = i18nTranslate(
          'cart.unableToProcessMessage',
          'We are currently unable to process your request or it may already be processed. Please refresh your screen and try again'
        )

        const errorMessage =
          response?.code === 'EOCACG0001'
            ? unProcessableRequestMessage
            : response?.message ||
              i18nTranslate(
                'cart.addCartFailure',
                'There is an issue with your account. Please contact you local account manager.'
              )
        toastState.setToastMessage(errorMessage, false)
      }
    } else if (JSON.stringify(subscriptionData) !== '{}') {
      if (type === 'oneTime' && this.isSuccessResponse(response)) {
        enableToastMessage &&
          toastState.setToastMessage(
            i18nTranslate(
              'cart.updateOneTime',
              'Your item has been moved to one-time purchase.'
            ),
            true
          )
      } else if (type === 'subscription' && this.isSuccessResponse(response)) {
        enableToastMessage &&
          toastState.setToastMessage(
            i18nTranslate(
              'cart.updateSubscription',
              'Your item has been moved to subscription.'
            ),
            true
          )
      } else {
        const unProcessableRequestMessage = i18nTranslate(
          'cart.unableToProcessMessage',
          'We are currently unable to process your request or it may already be processed. Please refresh your screen and try again'
        )
        const errorMessage =
          response?.code === 'EOCACG0001'
            ? unProcessableRequestMessage
            : response?.message ||
              i18nTranslate(
                'cart.addCartFailure',
                'There is an issue with your account. Please contact you local account manager.'
              )
        toastState.setToastMessage(errorMessage, false)
      }
    } else {
      if (this.isSuccessResponse(response)) {
        setTimeout(() => {
          toastState.setToastMessage(
            i18nTranslate(
              'cart.updateCartSuccess',
              'Your cart has been updated.'
            ),
            true
          )
        }, 1000)
      } else {
        const unProcessableRequestMessage = i18nTranslate(
          'cart.unableToProcessMessage',
          'We are currently unable to process your request or it may already be processed. Please refresh your screen and try again'
        )
        const errorMessage =
          response?.code === 'EOCACG0001'
            ? unProcessableRequestMessage
            : response?.message ||
              i18nTranslate(
                'cart.addCartFailure',
                'There is an issue with your account. Please contact you local account manager.'
              )
        toastState.setToastMessage(errorMessage, false)
      }
    }

    await this.viewCart()
    this.cartUpdateInProgress = false
    sendCartCount(this.cartCount)
    return response
  }

  /**
   * Deletes a cart item asynchronously.
   *
   * @param {string} productId - The product ID to delete
   * @param {boolean} isSubscription - Whether the product is a subscription
   * @param {string} cartItemId - The cart item ID to delete
   * @param {boolean} isShowToast - Whether to show a toast message
   * @param {string} skuId - The SKU ID of the product
   * @param {string} slug - The product slug
   * @param {string} productName - The product name
   * @param {Object} productData - Data about the product
   *
   * @returns {Promise} The API response
   *
   * Calls the 'deleteCartItem' endpoint to delete the cart item.
   * Shows success/error toasts based on the response.
   * Updates component state on success.
   * Tracks analytics event on success.
   */
  deleteCartItem = async (
    productId,
    isSubscription,
    cartItemId,
    isShowToast = true,
    skuId,
    slug,
    productName,
    productData,
    isSkipViewCart = false
  ) => {
    let toShowToast = isShowToast && this.toShowToast
    const loadParams = {
      endPointName: 'deleteCartItem',
      pathParams: cartItemId,
    }
    const response = await this.fetchResponse(loadParams)
    if (
      this.isSuccessResponse(response) &&
      productId != '' &&
      typeof window === 'object' &&
      window.location.pathname == pageNames.product + '/' + productId &&
      isSubscription == true &&
      customerContainer.isSubscriptionAdded == true &&
      customerContainer.isSubscriptionPreference == false
    ) {
      customerContainer.isSubscriptionAdded = false
    }
    if (toShowToast) {
      if (this.isSuccessResponse(response)) {
        toastState.setToastMessage(
          i18nTranslate(
            'cart.removeCartSuccess',
            'Removed from cart successfully'
          ),
          true
        )
        this.enableLiveEvent = getLiveEventStatus()
        if (this.enableLiveEvent === 'true') {
          trackEnhancedCartEvent({
            type: 'remove_from_cart',
            item: productData,
            method: 'remove',
            price: productData?.totalPrice,
          })
        }
        trackEnhancedCart({
          type: 'removeFromCart',
          item: productData,
          method: 'remove',
        })
      } else {
        const unProcessableRequestMessage = i18nTranslate(
          'cart.unableToProcessMessage',
          'We are currently unable to process your request or it may already be processed. Please refresh your screen and try again'
        )
        const errorMessage =
          response?.code === 'EOCACG0001'
            ? unProcessableRequestMessage
            : (response && response?.message) ||
              i18nTranslate(
                'cart.removeCartFailure',
                'Unable to remove product from cart, please try again'
              )
        toastState.setToastMessage(errorMessage, false)
      }
    }
    if (!isSkipViewCart) {
      await this.viewCart()
    }
    sendCartCount(this.cartCount)
    return response
  }

  /**
   * Updates guest customer data for the current cart.
   *
   * @param {Object} guestData - Guest customer data to update
   * @returns {Promise} Promise that resolves to API response
   */
  updateGuestCustomer = async guestData => {
    const loadParams = {
      endPointName: 'updateGuestCustomer',
      pathParams: `${this.cartUserId}`,
      postData: guestData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Fetches the number of active subscriptions for the current user.
   *
   * Makes an API call to get the active subscription count.
   * Sets the response to the subscriptionResponse property.
   * Extracts the offer count and saves it to localStorage.
   */
  getSubscriptionCount = async () => {
    const loadParams = {
      endPointName: 'getActiveAndLiveCount',
      queryParams: { skipPromotion: true, timestamp: new Date().getTime() }, //CX121-4652
    }
    const response = await this.fetchResponse(loadParams)
    this.subscriptionResponse = response
    let offer = response?.offerDetails?.[0]?.offer || '0'
    setLocalStorage('offer', offer)
  }

  /**
   * Estimates shipping cost for the current cart using a provided zipcode.
   *
   * Makes an API call to geocode the zipcode, transforms the result into shipping estimation parameters,
   * calls the shipping estimation endpoint, and handles the response to set the estimatedShippingCost property.
   *
   * @param {string} zipcode - Zipcode to estimate shipping for
   */
  estimateShippingWithZipcode = async zipcode => {
    cartContainer.errorInEstimationZipcode = false
    // cartContainer.estimatedShippingCost = ''
    const geocodeResponse = await geocodeApi.fetchGoogleApi(zipcode)

    const estimateShippingParams = builder.transformEstimateShippingInput(
      geocodeResponse,
      zipcode
    )
    const loadParams = {
      endPointName: 'updateBagAttributes',
      postData: { ...estimateShippingParams },
    }
    const estimatedResponse = await this.fetchResponse(loadParams)
    const estimatedData =
      builder.handleEstimateZipcodeResponse(estimatedResponse)
    if (estimatedData.hasError === true) {
      this.errorInEstimationZipcode = true
    } else {
      this.estimatedShippingCost = estimatedData.shippingCost
    }
  }

  /**
   * Checks the given array of products and returns the special product type if found.
   *
   * Loops through the provided products array and checks the 'type' property of each product.
   * If any product is found with type 'SKUKIT', returns 'kit'.
   * If any product is found with type 'BUNDLE', returns 'bundle'.
   * Otherwise returns empty string.
   */
  getSpecialProductType(products) {
    let value = ''
    if (products.length > 1) {
      products.map(product => {
        const type = product?.type || ''
        if (type === 'SKUKIT') {
          value = 'kit'
        } else if (type === 'BUNDLE') {
          value = 'bundle'
        }
      })
    }
    return value
  }

  /**
   * Checks if the cart contains any subscription products.
   *
   * Loops through the cart items array and returns true if any
   * item has the isSubscription property set to true.
   */
  hasSubscriptionProduct = () => {
    const cartProducts = this.cartResponse?.items || []
    return cartProducts.some(item => {
      return item.isSubscription === true
    })
  }
  /**
   * Gets the combined quantity and out of stock quantity for a specific free gift item from the free gift products list.
   *
   * Loops through the provided freeGiftProductsList array and totals up the quantities for any product matching the provided uniqueItemSkuID.
   * Returns an object containing the total quantity and total out of stock quantity for that matched product.
   */
  getCombineFreeGiftQty = (uniqueItemSkuID, freeGiftProductsList) => {
    let uniqueFreeGiftItemQuantity = 0
    let uniqueFreeGiftOOSQty = 0
    typeof freeGiftProductsList !== 'undefined' &&
      freeGiftProductsList.length > 0 &&
      freeGiftProductsList.map(freeGiftItem => {
        const freeGiftItemSkuID =
          freeGiftItem?.skus?.[0]?.skuId ||
          freeGiftItem?.item?.itemInfo?.sku?.skuId ||
          ''
        if (freeGiftItemSkuID === uniqueItemSkuID) {
          uniqueFreeGiftItemQuantity =
            uniqueFreeGiftItemQuantity +
            (freeGiftItem?.quantity ||
              freeGiftItem?.item?.itemInfo?.quantity ||
              0)
          uniqueFreeGiftOOSQty =
            uniqueFreeGiftOOSQty +
            (parseInt(freeGiftItem?.properties?.giftOutOfStockQty) || 0)
        }
      })
    return {
      combineFreeGiftQuantity: uniqueFreeGiftItemQuantity,
      combineFreeGiftOOSQty: uniqueFreeGiftOOSQty,
    }
  }

  /**
   * Displays a success toast message when an item is successfully added to the cart.
   *
   * @param {boolean} isToShowViewCartButton - Whether to show View Cart button in toast.
   */
  showAddToCartSuccessMessage = (isToShowViewCartButton = true) => {
    toastState.setToastMessage(
      i18nTranslate('cart.addCartSuccess', 'Item has been added to your cart.'),
      true,
      6000,
      null,
      isToShowViewCartButton
    )
  }

  /**
   * Displays error toast message based on add to cart API response.
   *
   * @param {object} response - Response from add to cart API call
   */
  showAddToCartFailureMessage = response => {
    if (response?.code === 'EOCACU0002') {
      toastState.setToastMessage(alertMessageKeys(response?.message), false)
    } else if (response?.code === 'AOACHLD0001') {
      toastState.setToastMessage(
        i18nTranslate(
          'cart.addCartFailure',
          'There is an issue with your account. Please contact you local account manager.'
        ),
        false
      )
    } else if (response?.code === 'EOCACG0001') {
      const unProcessableRequestMessage = i18nTranslate(
        'cart.unableToProcessMessage',
        'We are currently unable to process your request or it may already be processed. Please refresh your screen and try again'
      )
      toastState.setToastMessage(unProcessableRequestMessage, false)
    } else {
      toastState.setToastMessage(
        alertMessageKeys(response?.message, response?.code) ||
          i18nTranslate(
            'cart.addCartFailure',
            'There is an issue with your account. Please contact you local account manager.'
          ),
        false
      )
    }
  }

  /**
   * Adds an item to the cart from the view cart page.
   * Makes API call to add to cart endpoint.
   * Shows success/error toast message based on response.
   * Refreshes view cart data.
   */
  addToCartFromViewCart = async (
    postData = {},
    isToShowViewCartButton = false
  ) => {
    const loadParams = {
      endPointName: 'addToCart',
      postData,
    }

    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.showAddToCartSuccessMessage(
        isToShowViewCartButton && !isExpressCheckout()
      )
    } else {
      this.showAddToCartFailureMessage(response)
    }

    this.viewCart()
  }

  /**
   * @description
   * For MySite/Personal Offer Site,
   * Guest Checkout is eligible only if all products in cart supports 'retail' customer type.
   */
  isProductUserTypeNotEligibleForGuestCheckout = () => {
    return cartContainer?.cartResponse?.items?.some(item => {
      let eligibleSkus = item?.skus?.filter(sku => {
        const { type = '' } = sku || {}
        const skuType = type?.toLowerCase() || ''
        return normalAndSpecialProductSkuType?.includes?.(skuType)
      })
      const isSkuNotSupportedForRetailType = eligibleSkus?.some(item => {
        let { customerTypes = '' } = item?.skuProperties || {}
        return !customerTypes?.toLowerCase()?.includes?.('retail') || false
      })
      return isSkuNotSupportedForRetailType
    })
  }

  /**
   * Checks if guest checkout is eligible based on:
   * - Feature flag enablement
   * - Presence of customer profile information
   * - Shopping context overrides
   * Returns true if guest checkout eligible, false otherwise
   */
  isGuestCheckoutEligible = () => {
    try {
      if (IS_BROWSER && typeof window !== 'undefined') {
        const isSubscribed = this.hasSubscriptionProduct()
        let isCartEligibleForGuest = false
        let isGuestCheckoutEnabled = false
        const enableGuestForWeb = isGuestCheckoutEnabledForWeb()
        const enableGuestForPoMysite = convertToBoolean(
          APPConfig?.getAppConfig()?.enableGuestCheckoutForPOMysite
        )
        const enableNewGuestModal =
          convertToBoolean(APPConfig.getAppConfig()?.enableNewGuestModal) ||
          false
        const shoppingContext = getShoppingContext()
        const wholesale =
          shoppingContext?.overrides?.showWholeSalePricing || false
        const enableExpressCheckout = isExpressCheckout()
        //not required for new guest checkout modal
        let isGuestAccountCreated
        if (!enableNewGuestModal) {
          const {
            firstName = '',
            lastName = '',
            email = '',
          } = customerContainer.profileResponse || {}
          isGuestAccountCreated = (email && firstName && lastName) || false
        } else {
          isGuestAccountCreated = true
        }

        if (!isSubscribed) {
          if (
            (Object.keys(shoppingContext).length > 0 &&
              !wholesale &&
              enableGuestForPoMysite) ||
            enableGuestForWeb ||
            enableExpressCheckout
          ) {
            isGuestCheckoutEnabled = true //To check pomysite or web
            const isUserTypeNotEligible =
              this.isProductUserTypeNotEligibleForGuestCheckout()
            isCartEligibleForGuest = !isUserTypeNotEligible //To check whether cart is eligible for guest checkout
          } else {
            isCartEligibleForGuest = false
            isGuestCheckoutEnabled = false
          }
        } else {
          isCartEligibleForGuest = false
          isGuestCheckoutEnabled = false
        }

        return (
          isCartEligibleForGuest &&
          isGuestCheckoutEnabled &&
          isGuestAccountCreated
        )
      }
    } catch (e) {
      console.log('Guest Checkout Eligible Check error', e)
    }
  }

  /**
   * Fetches foreign order limit restriction data for a product from the API.
   *
   * @param {Object} productDetails - Details of the product to get foreign order limit for.
   * @returns {Promise} Promise that resolves to the API response.
   */
  getForeignOrderLimitRestriction = async (productDetails = []) => {
    this.foreignOrderRestrictionResponse = {}
    const loadParams = {
      endPointName: 'foreignOrderLimit',
      postData: [{ skus: productDetails }],
    }
    if (productDetails?.length > 0) {
      const response = await this.fetchResponse(loadParams)
      this.foreignOrderRestrictionResponse = response
    }
    return this.foreignOrderRestrictionResponse
  }
  /**
   * @description Returns the minimum value between two quantities.
   * @param {number} qualificationQty - qty from CustomerQualificationAPI
   * @param {numner} foreignOrderQty - qty from restriction API
   * Compares the qualification quantity and foreign order quantity,
   * returning the minimum of the two. If either value is 0, returns
   * the other value. If both values are 0, returns 0.
   * @returns {number} The minimum quantity value
   */
  getQtyMinValue = (qualQty = 0, fOrderQty = 0) => {
    try {
      const qualificationQty = parseInt(qualQty)
      const foreignOrderQty = parseInt(fOrderQty)
      return qualificationQty === 0
        ? foreignOrderQty
        : foreignOrderQty === 0
        ? qualificationQty
        : qualificationQty !== 0 && foreignOrderQty !== 0
        ? Math.min(qualificationQty, foreignOrderQty)
        : 0
    } catch (e) {
      console.log('Error calculating min quantity', e)
      return
    }
  }

  /**
   * Filters the foreign order limit array from the API response
   * to return only the elements for the given product ID.
   *
   * @param {Object} apiResponse - The API response object
   * @param {string} productId - The product ID to filter by
   * @returns {Array} The filtered foreign order limit array
   */
  getQtyOnRestrictionAPIConditions = (apiResponse = {}, productId = '') => {
    const foreignOrderArray = apiResponse?.messages?.ForeignOrderLimit || []
    let restrictedArray = []
    if (Array.isArray(foreignOrderArray) && foreignOrderArray?.length > 0) {
      restrictedArray = foreignOrderArray.filter(order => {
        return order?.sku === productId && order?.type === 'ForeignOrderLimit'
      })
    }
    return restrictedArray
  }

  /**
   * Filters the customer qualification array to return only
   * elements for the given product ID and current locale.
   *
   * @param {string} productId - The product ID to filter by
   * @returns {Array} The filtered customer qualification array
   */
  getQtyOnCustomerQualificationConditions = (productId = '') => {
    const currentLocale = catalogContainer.getCurrentMarket() || 'US'
    const qualificationArray =
      customerContainer.qualificationResponse?.qualification?.filter(
        qualification => {
          return (
            qualification?.productId === productId &&
            qualification?.markets.includes(currentLocale) &&
            qualification?.isConsumable
          )
        }
      )
    return qualificationArray
  }

  /**
   * Filters cart items to return only those containing
   * the provided reOrderSku.
   *
   * @param {string} reOrderSku - The SKU ID to filter cart items by
   * @returns {Array} Array of cart items containing the reOrderSku
   */
  getReorderSkuFromCart = (reOrderSku = '') => {
    const cartItems = this.cartResponse?.items || []
    let reorderSkuArray = []
    reorderSkuArray = cartItems?.filter(item =>
      item?.skus?.some(
        sku =>
          sku?.skuId === reOrderSku &&
          normalAndSpecialProductSkuType.includes(
            sku?.type?.toLowerCase()?.replace('_', '')
          )
      )
    )
    return reorderSkuArray
  }

  /**
   * Gets restricted item data based on order item, reorder quantity,
   * reorder index, and order item loop index.
   *
   * Checks for foreign order restrictions and customer qualifications.
   * Returns object with skuId, quantityLimit, message,
   * and isForeignOrderRestricted flag.
   */
  getRestrictedItemData = (orderItem = {}, reorderQty = '') => {
    try {
      const skuId = orderItem?.item?.itemInfo?.sku?.skuId || orderItem?.skuId
      const productId =
        orderItem?.item?.itemInfo?.sku?.productId || orderItem?.productId
      let orderhistoryQty =
        reorderQty !== ''
          ? parseInt(reorderQty)
          : orderItem?.item?.itemInfo?.quantity || 0
      const foreignOrderSku =
        this.getQtyOnRestrictionAPIConditions(
          this.foreignOrderRestrictionResponse,
          skuId
        ) || []
      //Customer Qualification API conditions here
      const customerQualificationSku =
        this.getQtyOnCustomerQualificationConditions(productId) || []
      let isForeignOrderRestricted = false
      if (foreignOrderSku.length > 0 || customerQualificationSku.length > 0) {
        const {
          quantityLimit = '0',
          currentQuantity = '0',
          message = '',
        } = foreignOrderSku?.[0] || {}
        const customerQualificationQty =
          customerQualificationSku?.[0]?.quantity || 0
        //Get Eligible Limit on checking both the restriction and customer qualification conditions2
        let eligibleQty = parseInt(quantityLimit - currentQuantity) || 0
        const maxLimitQty =
          this.getQtyMinValue(customerQualificationQty, eligibleQty) || 0
        // Check cart items quantity for product
        const cartReOrderSku = this.getReorderSkuFromCart(skuId) || []
        let cartQty = cartReOrderSku?.[0]?.quantity || 0

        let isForeignOrderRestricted =
          maxLimitQty === 0
            ? true
            : maxLimitQty > 0 && cartQty + orderhistoryQty > maxLimitQty
            ? true
            : false

        return {
          skuId,
          quantityLimit,
          eligibleQty,
          message,
          isForeignOrderRestricted,
        }
      } else {
        return {
          skuId,
          quantityLimit: 0,
          eligibleQty: 0,
          message: '',
          isForeignOrderRestricted,
        }
      }
    } catch (e) {
      console.log('Error on foreign order calculation ', e)
    }
  }

  /**
   * @description This function is to check if the exclusive product is eligible for reorder or buyitagain
   * CustomerQualificationAPI qty is used to check exclusive product eligibility based on below conditions
   * ForeignOrderLimitAPI qty is used to check all the product is eligible for reorder or buyiteagain again foreignOrder
   * @date 1/12/2024 - 2:25:26 PM
   *
   * @param {[]} [orderItems=[]] orderItems from order history page
   * @param {} [reorderQty = 1] reorder input qty from reorder page
   */
  checkConditionsForForeignOrderLimit = (
    orderItems = [],
    reOrderQty = [],
    reOrderIndex = 0
  ) => {
    let newRestrictionList = []
    if (orderItems.length > 0) {
      orderItems?.forEach((orderItem, index) => {
        const reOrderIndexQty = reOrderQty[index]?.value || ''
        const rItem = this.getRestrictedItemData(orderItem, reOrderIndexQty)
        newRestrictionList.push(rItem)
        this.restrictedItems = [...newRestrictionList]
      })
    }
  }

  /**
   * Updates the restriction status and message for the item at the given index in the restrictedItems array.
   * Gets the updated restricted item data based on the provided reorder quantity, and updates the restriction properties on the item in the array.
   * Triggers re-render by creating a new copy of the restrictedItems array.
   */
  updateRestrictionStatusForItem = (reorderQty = [], orderItemIndex = '') => {
    let reOrderIndexQty = reorderQty[orderItemIndex]?.value || ''
    let curRestrictedCurItem = this.restrictedItems[orderItemIndex] || {}

    const updateRItem = this.getRestrictedItemData(
      curRestrictedCurItem,
      reOrderIndexQty
    )
    curRestrictedCurItem.isForeignOrderRestricted =
      updateRItem.isForeignOrderRestricted
    curRestrictedCurItem.message = updateRItem.message
    curRestrictedCurItem.quantityLimit = updateRItem.quantityLimit
    curRestrictedCurItem.skuId = updateRItem.skuId
    // re-render starts with new value
    this.restrictedItems = [...this.restrictedItems]
  }

  resetRestrictedItemStatus = () => {
    this.restrictedItems = []
  }

  promoCodeList = async () => {
    const loadParams = {
      endPointName: 'getPromoCodeList',
    }
    const response = await this.fetchResponse(loadParams)
    this.promoCodesList = response?.offers || []
    return response?.offers
  }
}

const cartContainer = new CartContainer()

export default cartContainer
export { CartContainer, cartContainer }
