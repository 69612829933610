import { observable, action } from 'mobx'
import { reviewPageSize } from 'config/appConfig'
import { CommonContainer } from 'src/models/Common'

/**
 * ReviewContainer class that extends CommonContainer.
 * Used for managing review data and state.
 */
class ReviewContainer extends CommonContainer {
  @observable productReviews = {
    pageableInfo: {},
    reviews: [],
    summary: {},
  }
  @observable selectedDropDown = ''
  imgUploadUserToken = ''

  @action
  /**
   * Sets the selected dropdown value and retrieves
   * reviews for the product using the new sort order.
   *
   * @param {Object} option - The selected dropdown option
   * @param {string} option.value - New dropdown value
   * @param {string} productId - The product ID
   */
  setDropDownValue = (option = {}, productId = '') => {
    if (option.value !== this.selectedDropDown) {
      this.selectedDropDown = option.value || ''
      const sort = this.constructSort(option.value || '')
      this.getReviewsOfProduct({
        productId,
        sort,
      })
    }
  }

  /**
   * Constructs a sort object to pass to the
   * getReviewsOfProduct API based on the provided
   * dropdown value. Sorts by rating in the direction
   * specified by the value.
   */
  constructSort = value => {
    return { sort: [{ field: 'rating', direction: value }] }
  }

  @action
  /**
   * Retrieves reviews for a product.
   *
   * @param {Object} props - Function parameters
   * @param {string} props.productId - ID of the product to retrieve reviews for
   * @param {number} props.page - Page number of reviews to retrieve
   * @param {number} props.size - Number of reviews per page
   * @param {Object} props.sort - Sort criteria for the reviews
   *
   * Calls API to get reviews for the product. Handles success and error response
   * to populate review data in the state.
   */
  getReviewsOfProduct = async (props = {}) => {
    const { productId = '', page = 1, size = '', sort = '' } = props
    const loadParams = {
      endPointName: 'getAllRatingsAndReviews',
      queryParams: {
        page: page,
        size: size || reviewPageSize,
        filter: `itemId="${productId}"`,
        sort: sort ? JSON.stringify(sort) : sort,
      },
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.productReviews.reviews = response.ratingReviews
      this.productReviews.pageableInfo = response.pageableInfo
    } else {
      this.productReviews.reviews = []
      this.productReviews.pageableInfo = {}
    }
  }

  @action
  /**
   * Retrieves a review by ID.
   *
   * @param {Object} props - Function parameters
   * @param {string} props.ratingId - ID of the review to retrieve
   * Calls API to get the review with the given ID. Handles success response
   * to update the review data in the state.
   */
  getReviewById = async (props = {}) => {
    const { ratingId = '' } = props
    const loadParams = {
      endPointName: 'getReviewById',
      pathParams: `${ratingId}`,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      const index = this.productReviews.reviews.findIndex(
        review => review.id === ratingId
      )
      this.productReviews.reviews[index] = response
    }
  }

  @action
  /**
   * Retrieves the ratings summary for a product.
   *
   * @param {Object} props - Function parameters
   * @param {string} [props.productId] - ID of the product
   * @param {number} [props.page] - Page number for pagination
   * @param {number} [props.size] - Page size for pagination
   */
  getRatingsSummaryOfProduct = async (props = {}) => {
    const { productId = '', page = 1, size = 10 } = props
    const loadParams = {
      endPointName: 'getRatingsSummary',
      queryParams: {
        page: page,
        size: size,
        filter: `itemId="${productId}"`,
      },
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.productReviews.summary = response.ratingsSummarys[0]
    } else {
      this.productReviews.summary = {}
    }
  }

  /**
   * Creates a new product review.
   *
   * @param {Object} props - Review details
   * @param {string} [props.productId] - ID of the product being reviewed
   * @param {string} [props.rating] - Rating for the product
   * @param {string} [props.reviewTitle] - Title of the review
   * @param {string} [props.reviewDescription] - Text content of the review
   * @param {string} [props.email] - Email of the reviewer
   * @param {string} [props.name] - Name of the reviewer
   * @param {boolean} [props.anonymous] - Whether the review is anonymous
   * @returns {Object} Result indicating success status
   */
  createReview = async (props = {}) => {
    const loadParams = {
      endPointName: 'createRatingAndReview',
      postData: {
        itemId: props.productId || '',
        rating: props.rating || '',
        status: 'APPROVED',
        reviewtitle: props.reviewTitle || '',
        review: props.reviewDescription || '',
        userInfo: {
          email: props.email || '',
          firstName: props.name || '',
          anonymous: props.anonymous || '',
        },
      },
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.imgUploadUserToken = response.userAuthToken
      this.reviewId = response.id
      return { success: true }
    }
    return { success: false }
  }

  /**
   * Creates feedback for a product review.
   *
   * @param {Object} props - Feedback details
   * @param {string} [props.ratingId] - ID of the review to provide feedback for
   * @param {string} [props.feedback] - Text content for the feedback
   * @returns {undefined}
   */
  createReviewFeedback = async (props = {}) => {
    const { ratingId = '', feedback = '' } = props
    const loadParams = {
      endPointName: 'createReviewFeedback',
      queryParams: { ratingid: ratingId },
      postData: { feedback },
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      this.getReviewById({ ratingId })
    }
  }

  /**
   * Uploads an image to an existing product review.
   *
   * @param {Object} props - Image file data to upload
   * @returns {Promise} Result of image upload request
   */
  uploadImageToReview = async (props = {}) => {
    const loadParams = {
      endPointName: 'uploadImagesToReviews',
      queryParams: { imgUploadUserToken: this.imgUploadUserToken },
      postData: props.data,
      pathParams: `${this.reviewId}/images`,
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    await this.fetchResponse(loadParams)
  }

  /**
   * Handles pagination for product reviews by retrieving
   * the reviews for the given page number.
   *
   * @param {number} page - Page number to retrieve reviews for
   */
  handlePagination = page => {
    const productId = this.productReviews?.reviews?.[0]?.itemId || ''
    this.getReviewsOfProduct({
      productId,
      page,
      sort: this.selectedDropDown
        ? this.constructSort(this.selectedDropDown)
        : '',
    })
  }
}

const reviewContainer = new ReviewContainer()

export { reviewContainer }
