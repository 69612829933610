import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Row, Nav } from 'react-bootstrap'
import {
  IoLogoFacebook as FacebookIcon,
  IoLogoTwitter as TwitterIcon,
  IoLogoPinterest as PinterestIcon,
  IoMdLink as LinkIcon,
} from 'react-icons/io'
import { getAppConfig } from 'config/appConfig'
import { socialProfileSchema } from 'src/models'
import { toastState, JsonLd } from 'src/views/components'
import { socialLinks } from './fixture'
import './styles.scss'

class SocialShare extends React.Component {
  facebookAppId = getAppConfig()?.facebookAppId
  productId = '150004'
  /**
   * @todo have to do fromUrl
   */
  // fromUrl = `https://${document.domain}/product/${this.productId}`

  getSocialShareURL = (props, label) => {
    const domainName = typeof document == 'object' ? document.domain : ''
    const productName = encodeURIComponent(props.name)
    const mediaURL = encodeURIComponent(props.imageURL)
    this.productId = props.productId
    const fromURL = encodeURIComponent(
      `https://${domainName}${props.shareLink}`
    )
    switch (label) {
      case 'facebook':
        return `https://www.facebook.com/v2.11/dialog/share?redirect_uri=${encodeURIComponent(
          'https://www.facebook.com/'
        )}&display=popup&href=${fromURL}&client_id=${encodeURIComponent(
          this.facebookAppId
        )}&caption='${productName}'`
      case 'pinterest':
        return `https://pinterest.com/pin/create/button/?url=${fromURL}&description=${productName}&media=${mediaURL}`
      case 'twitter':
        return `https://twitter.com/share?url=${fromURL}&text=${productName}`
      default:
        return ''
    }
  }

  copyLink = event => {
    event.persist()
    const url = event.target.baseURI
    const listener = e => {
      e.clipboardData.setData('text/plain', url)
      e.preventDefault()
    }
    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)
    toastState.setToastMessage(
      i18nTranslate('socialShare.copyLink', 'Link copied successfully'),
      true
    )
  }

  renderSocialLinks = () => {
    const links = socialLinks.links.map(list => list.url)
    return socialProfileSchema(links)
  }

  render() {
    const schemaData = this.renderSocialLinks()
    const { imageURL } = this.props

    return (
      <>
        <Row className="social-share-icons" noGutters>
          <Nav className="justify-content-end align-items-center" activeKey="">
            <Nav.Item className="facebook-icon icon mr-3">
              <Nav.Link
                target="_blank"
                className="p-0"
                rel="noopener noreferrer"
                data-testid="qa-facebook"
                href={this.getSocialShareURL(this.props, 'facebook')}
                // title={i18nTranslate('link.title', 'new window')}
              >
                <FacebookIcon
                  className="facebook-svg w-100 h-100"
                  aria-label={i18nTranslate('icons.facebook', 'facebook icon', {
                    nameSpace: 'ssr-resource',
                  })}
                />
              </Nav.Link>
            </Nav.Item>
            {imageURL && (
              <Nav.Item className="pinterest-icon icon mr-3">
                <Nav.Link
                  className="icon-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="qa-pinterest"
                  href={this.getSocialShareURL(this.props, 'pinterest')}
                  title={i18nTranslate('link.title', 'new window', {
                    nameSpace: 'ssr-resource',
                  })}>
                  <PinterestIcon
                    className="pinterest-svg w-100 h-100"
                    aria-label={i18nTranslate(
                      'icons.pinterest',
                      'pinterest icon',
                      {
                        nameSpace: 'ssr-resource',
                      }
                    )}
                  />
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item className="twitter-icon icon mr-3">
              <Nav.Link
                className="icon-link"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="qa-twitter"
                href={this.getSocialShareURL(this.props, 'twitter')}
                title={i18nTranslate('link.title', 'new window', {
                  nameSpace: 'ssr-resource',
                })}>
                <TwitterIcon
                  className="twitter-svg w-100 h-100"
                  aria-label={i18nTranslate('icons.twitter', 'twitter icon', {
                    nameSpace: 'ssr-resource',
                  })}
                />
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div
            data-testid="qa-copy-link-button"
            className="icon-link pdp-copy-icon d-flex "
            onClick={this.copyLink}>
            <LinkIcon
              className="link-svg w-100 h-100"
              aria-label={i18nTranslate('icons.link', 'link icon', {
                nameSpace: 'ssr-resource',
              })}
            />
          </div>
        </Row>
        <JsonLd data={schemaData} key="schema-socialShare" />
      </>
    )
  }
}

export { SocialShare }
export default SocialShare
