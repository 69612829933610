import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Row, Container } from 'react-bootstrap'
// import ReactMarkdown from 'react-markdown'
import { AccordionBlockPDP } from 'src/views/components/AccordionBlockPDP'
import { MarkdownComponent } from 'src/views/components/MarkdownComponent'
import { getProductPDPTitle } from 'src/utils/commonUtils'
import './styles.scss'

@observer
class ProductBenefitsandFeatures extends React.Component {
  nameSpace = 'ssr-resource'
  // getBenefitFeatureVideo = (vedioUrl = '') => {
  //   if (vedioUrl != '') {
  //     const regExp =
  //       /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  //     const match = vedioUrl.match(regExp)
  //     let videoId = match?.[2].length === 11 ? match?.[2] : null
  //     if (vedioUrl.match(regExp)) {
  //       return 'https://www.youtube.com/embed/' + videoId
  //     } else {
  //       return vedioUrl
  //     }
  //   }
  // }

  isYouTubeVideo = (videoUrl = '') => {
    if (videoUrl != '') {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = videoUrl && videoUrl.match(regExp)
      let videoId = match?.[2].length === 11 ? match[2] : null
      if (videoUrl && videoUrl.match(regExp)) {
        return true
      }
    }
    return false
  }

  // duplicate code need to clean up
  getMedia = mediaProps => {
    const {
      url = '',
      alt = i18nTranslate('pdp.defaultAlt', 'Benefits Video', {
        nameSpace: this.nameSpace,
      }),
      index = 0,
      isToShowControls = true,
      thumbnail = '',
    } = mediaProps
    const videoUrlRegEx = /^https?:\/\/.*\/.*\.(mp4|webm|ogg)\??.*$/gim
    const imageUrlRegEx = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i
    if (this.isYouTubeVideo(url)) {
      return (
        <iframe
          tabIndex={index}
          width="100%"
          className="media"
          src={`https://www.youtube.com/embed/${videoId}?controls=${
            isToShowControls ? '0' : '1'
          }`}
        />
      )
    } else if (url.match(videoUrlRegEx)) {
      return (
        <video
          tabIndex={index}
          width="100%"
          controls={isToShowControls}
          poster={thumbnail}>
          <source src={url} />
        </video>
      )
    } else if (url.match(imageUrlRegEx)) {
      return (
        <img
          alt=""
          tabIndex={index}
          width="100%"
          controls={isToShowControls}
          src={url}
        />
      )
    }
    return <></>
  }

  renderTitle = () => {
    const { isAccordionType = false } = this.props
    const benefitsLable = getProductPDPTitle(
      this.props.product?.tempProperties,
      'benefits'
    )
    return (
      <Row
        className={`about-title ${
          isAccordionType ? 'text-uppercase' : 'border-light-grey'
        }`}
        noGutters>
        {benefitsLable ||
          i18nTranslate('pdp.benefitsTitle', 'Benefits and Key Features', {
            nameSpace: this.nameSpace,
          })}
      </Row>
    )
  }

  renderBenefits = props => {
    const { benefitsListArray, benefitdet, benefits1, Markdown } = props || {}
    // const isHeaderPresent = benefits1?.[0]?.benefits?.startsWith('**')
    return (
      <div>
        <div className="benefits-feature-accordion d-flex flex-wrap align-items-start justify-content-between">
          <div className="benefits-feature-inner">
            {/* {benefitdet && (
              <>
                <Markdown
                  components={{ strong: 'h6' }}
                  className={`${isHeaderPresent ? 'mardown-header' : ''}`}>
                  {benefits1?.[0]?.benefits || ''}
                </Markdown>
              </>
            )} */}
            {benefitsListArray.map((item, index) => {
              const isHeaderPresent = item?.startsWith('**')
              return (
                <span data-testid={`qa-pdp-benefits-point-${index + 1}`}>
                  <Markdown
                    components={{ strong: 'h6' }}
                    className={`${isHeaderPresent ? 'mardown-header' : ''}`}>
                    {item}
                  </Markdown>
                </span>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const product = this.props?.product || {}
    const benefitdet = product?.benefits || []
    let benefits1
    try {
      benefits1 = [JSON.parse(benefitdet)]
    } catch (e) {
      benefits1 = [[]]
      console.log('Data parse error::: ProductBenefitsandFeatures ' + e)
    }

    let values = Object.values(...benefits1)
    let renderAccordion = values.some(item => (item?.length > 0 ? true : false))

    const { benefits, image, youTubeVideoId } = benefits1[0]
    const benefitsListArray = benefits?.split('\n')

    return (
      <>
        {renderAccordion && (
          <div className="product-accordion-wrapper ">
            <AccordionBlockPDP
              title={this.renderTitle()}
              isDefaultOpen={false}
              className="accordion-description benefits-accordion"
              dataTestId="qa-pdp-benefits">
              <Container className="pdp-accordion-content-container">
                {image?.url && (
                  <div>
                    <div className="media-container w-100">
                      {this.getMedia({
                        url: image?.url || youTubeVideoId,
                        thumbnail: image?.thumbnail,
                        alt: image?.alt,
                      })}
                    </div>
                  </div>
                )}
                <MarkdownComponent>
                  {({ default: Markdown }) =>
                    this.renderBenefits({
                      benefitsListArray,
                      benefitdet,
                      benefits1,
                      Markdown,
                    })
                  }
                </MarkdownComponent>
              </Container>
            </AccordionBlockPDP>
          </div>
        )}
      </>
    )
  }
}

export { ProductBenefitsandFeatures }
