import React from 'react'
import { Modal, ModalTitle } from 'react-bootstrap'
import {
  IoIosInformationCircleOutline as InformationIconOutline,
  IoIosClose as InformationCloseIcon,
} from 'react-icons/io'
import { ModalCloseIcon } from 'src/views/components/Icons/ModalCloseIcon'
import { i18nTranslate } from 'src/utils'

function closeSCInfoModal(props = {}) {
  props?.onHide()
}

function CartScanInfo(props) {
  const className = props?.isFlexibleSubscription
    ? 'flexible-scancard-info'
    : 'quick-view-dialog'

  const modalHeader = props?.isFlexibleSubscription ? (
    <Modal.Header className="border-0">
      <ModalTitle className="scancard-title">
        {i18nTranslate('scan.cards', 'Scan Cards')}
        <ModalCloseIcon
          customClass="scancard-close"
          onClose={() => closeSCInfoModal(props)}
        />
      </ModalTitle>
    </Modal.Header>
  ) : (
    <Modal.Header className="border-0" closeButton>
      <ModalTitle className="font-weight-bold">
        <InformationIconOutline size="1.5rem" className="mr-2" />
        {i18nTranslate('scan.cards', 'SCAN CARDS')}
      </ModalTitle>
    </Modal.Header>
  )
  return (
    <Modal
      centered={true}
      show={props.show}
      dialogClassName={className}
      onHide={props?.onHide}>
      {modalHeader}
      <Modal.Body>
        <h5>{i18nTranslate('scan.takeAction', 'Take Action')}</h5>
        <ul>
          <li>
            {i18nTranslate(
              'scan.info1',
              'Obtain a Scan Card from a Scanner Operator when you scan for the first time.'
            )}
          </li>
          <li>
            {i18nTranslate(
              'scan.info2',
              'Activate your Scan Card either by telephone or online in Subscription Management'
            )}
          </li>
        </ul>
        <h5>{i18nTranslate('scan.benefits', 'The Benefits')}</h5>
        <ul className="benefits-list">
          <li>
            {i18nTranslate(
              'scan.info3',
              'The scan card is reusable and once activated it becomes a free monthly scan as a LIFETIME benefit - for as long as you continue your qualified Pharmanex product Subscription.'
            )}
          </li>
          <li>
            {i18nTranslate(
              'scan.info4',
              'There are two parts to the card - a large card (similar in size to a credit card) and a small card that you can attach to your key ring.Both of those will have the same bar coded certificate number(your scan card number) and you may use either for your personal monthly scan.'
            )}
          </li>
          <li>
            {i18nTranslate(
              'scan.info5',
              'Each time you scan with your Scan Card your individual SCS results from the BioPhotonic Scanner will be recorded so you may track your personal progress each month.'
            )}
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  )
}

export { CartScanInfo }
export default CartScanInfo
