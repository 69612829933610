import { i18nTranslate } from 'src/utils'
import { storeContainer } from 'src/models/Store'

/**
 * Converts a timestamp to a numeric month value based on the given timezone.
 *
 * @param {number} timeStampFromResponse - Timestamp to convert
 * @param {string} timeZone - Timezone to use for conversion
 * @param {string} month - 'numeric' to get month as number
 * @returns {number} The numeric month value
 */
export function getConvertedMonth(
  timeStampFromResponse,
  timeZone,
  month = 'numeric'
) {
  /**
   * @todo
   * required to be verified the timeZone
   */

  // console.log({ timeZone })

  const convertedMonth = new Date(timeStampFromResponse).toLocaleDateString(
    [],
    {
      timeZone: 'US/Mountain', //timeZone,
      month,
    }
  )
  return parseInt(convertedMonth)
}

/**
 * Converts a timestamp to a numeric year value based on the given timezone.
 *
 * @param {number} timeStampFromResponse - Timestamp to convert
 * @param {string} timeZone - Timezone to use for conversion
 * @param {string} year - 'numeric' to get year as number
 * @returns {number} The numeric year value
 */
export function getConvertedYear(
  timeStampFromResponse,
  timeZone,
  year = 'numeric'
) {
  /**
   * @todo
   * required to be verified the timeZone
   */

  // console.log({ timeZone })

  const convertedYear = new Date(timeStampFromResponse).toLocaleDateString([], {
    timeZone: 'US/Mountain', //timeZone,
    year,
  })
  return parseInt(convertedYear)
}

/**
 * Parses a UTC date string and returns a Date object.
 * Supports ISO 8601 format.
 */
export const parseUTCDate = (value = '') => {
  let field =
    value.match(
      /^([+-]?\d{4}(?!\d\d\b))(?:-?(?:(0[1-9]|1[0-2])(?:-?([12]\d|0[1-9]|3[01]))?)(?:[T\s](?:(?:([01]\d|2[0-3])(?::?([0-5]\d))?|24\:?00)([.,]\d+(?!:))?)?(?::?([0-5]\d)(?:[.,](\d+))?)?([zZ]|([+-](?:[01]\d|2[0-3])):?([0-5]\d)?)?)?)?$/
    ) || []
  let result = new Date(
    field[1],
    (field[2] - 1) | 0,
    field[3] || 1,
    field[4] | 0,
    field[5] | 0,
    field[7] | 0,
    field[8] | 0
  )
  if (field[9]) {
    result.setUTCMinutes(
      result.getUTCMinutes() -
        result.getTimezoneOffset() -
        (field[10] * 60 + +field[11] || 0)
    )
  }
  return result
}

/**
 * Returns an array of localized day name strings.
 */
export function getDaysStringList() {
  return [
    i18nTranslate('days.sunday', 'Sunday'),
    i18nTranslate('days.monday', 'Monday'),
    i18nTranslate('days.tuesday', 'Tuesday'),
    i18nTranslate('days.wednesday', 'Wednesday'),
    i18nTranslate('days.thursday', 'Thursday'),
    i18nTranslate('days.friday', 'Friday'),
    i18nTranslate('days.saturday', 'Saturday'),
  ]
}

/**
 * Returns an array of localized month name strings.
 */
export function getMonthStringList() {
  return [
    i18nTranslate('months.jan', 'January'),
    i18nTranslate('months.feb', 'February'),
    i18nTranslate('months.mar', 'March'),
    i18nTranslate('months.apr', 'April'),
    i18nTranslate('months.may', 'May'),
    i18nTranslate('months.jun', 'June'),
    i18nTranslate('months.jul', 'July'),
    i18nTranslate('months.aug', 'August'),
    i18nTranslate('months.sep', 'September'),
    i18nTranslate('months.oct', 'October'),
    i18nTranslate('months.nov', 'November'),
    i18nTranslate('months.dec', 'December'),
  ]
}

/**
 * Parses a date string in YYYY-MM-DD format and returns an object
 * with the timestamp and Date object for that date.
 *
 * Accepts a dateOfBirth string and splits it into year, month, day.
 * Creates a Date object from those parts, gets the timestamp,
 * and returns both in an object.
 */
export function getDateFromString(dateOfBirth) {
  const dateResponse = {
    time: '',
    date: '',
  }
  if (dateOfBirth !== '' && dateOfBirth !== null) {
    const dateB = dateOfBirth?.split('-')[2] || ''
    const monthB = dateOfBirth?.split('-')[1] || ''
    const yearB = dateOfBirth?.split('-')[0] || ''
    dateOfBirth = `${yearB}-${monthB}-${dateB}`
    const date = new Date(`${dateOfBirth}T00:00`)
    dateResponse.time = Math.floor(date.getTime())
    dateResponse.date = date
    dateResponse.dateString = dateOfBirth
  }
  return dateResponse
}

/**
 * Returns an object mapping month names to their numeric values,
 * for use in converting date strings to timestamps.
 */
export const getMonthNumberForTimestamp = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
}

/**
 * Checks if a sale event has started, is in progress, or has ended.
 *
 * Compares the current time to the configured start and end times
 * for a sale event. Returns an object indicating the status of the sale.
 */
export function checkIsSaleStarted() {
  let saleEventStartTime
  let saleEventEndTime
  let currentConvertedTime
  // adding two seconds padding for slower system to be in the sale event
  // This lets the sale event to be true for +2 seconds to make the API call
  let paddingTime = 2000
  const eventProperties = {
    isSaleInProgress: false,
    isSaleStarted: false,
    isSaleRegistered: false,
    isSaleEnded: false,
  }

  try {
    saleEventStartTime = storeContainer.activeStoreResponse?.properties?.filter(
      data => data?.name === 'saleEventStartTime'
    )

    saleEventEndTime = storeContainer.activeStoreResponse?.properties?.filter(
      data => data?.name === 'saleEventEndTime'
    )
    saleEventStartTime = new Date(saleEventStartTime?.[0]?.value)
    saleEventEndTime = new Date(saleEventEndTime?.[0]?.value)
    const timeZone = 'US/Mountain'
    currentConvertedTime = new Date(
      new Date().toLocaleString('en-US', { timeZone })
    )
  } catch (e) {
    console.warn('Fn checkIsSaleStarted ', e)
  }

  if (saleEventStartTime && currentConvertedTime) {
    eventProperties.isSaleRegistered = true
    if (
      currentConvertedTime.getTime() >= saleEventStartTime.getTime() &&
      currentConvertedTime.getTime() <=
        saleEventStartTime.getTime() + paddingTime
    ) {
      eventProperties.isSaleStarted = true
    } else if (
      currentConvertedTime > saleEventStartTime &&
      currentConvertedTime <= saleEventEndTime
    ) {
      eventProperties.isSaleInProgress = true
    } else if (currentConvertedTime >= saleEventEndTime) {
      eventProperties.isSaleEnded = true
    }
  }
  return eventProperties
}

/**
 * Returns the number of days in a given month name.
 *
 * @param {string} locale - The locale to use for month name comparison
 * @param {string} monthName - The month name to check
 * @returns {number} The number of days in the month
 */
export function getMonthNumber(locale, monthName) {
  for (let month = 0; month < 12; month++) {
    let date = new Date(2000, month, 1)
    if (
      date.toLocaleString(locale, { month: 'long' }).toLowerCase() ===
      monthName.toLowerCase()
    ) {
      return month + 1 // Months are 0-indexed in JavaScript, so we add 1
    }
  }
  return -1 // Return -1 if the month name wasn't found
}
