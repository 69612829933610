/**
 * Regular expressions to match common credit card numbers.
 * Provides regexes for Visa, Mastercard, American Express,
 * Discover, Diners Club, and JCB card numbers.
 */
export const acceptedCreditCards = {
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard:
    /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  amex: /^3[47][0-9]{13}$/,
  discover:
    /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
}

/**
 * Gets the card type and name based on the card number.
 * Checks against regular expressions for major card providers
 * and returns an object with the card type and a name.
 */
export function getCardType(cardNumber) {
  const isVisaCard = acceptedCreditCards.visa.test(cardNumber)
  const isMasterCard = acceptedCreditCards.mastercard.test(cardNumber)
  const isAmexCard = acceptedCreditCards.amex.test(cardNumber)
  const isDiscoverCard = acceptedCreditCards.discover.test(cardNumber)
  const isDinnerClub = acceptedCreditCards.diners_club.test(cardNumber)

  switch (true) {
    case isVisaCard:
      return {
        type: 'VISA',
        name: '0001',
      }
    case isMasterCard:
      return {
        type: 'MASTER',
        name: '002',
      }
    case isAmexCard:
      return {
        type: 'AMEX',
        name: '003',
      }
    case isDiscoverCard:
      return {
        type: 'DISCOVER',
        name: '004',
      }
    case isDinnerClub:
      return { name: '005', type: 'Diners' }
    // case '006':
    //   return 'Western Union'
    default:
      return {}
  }
}

/**
 * Gets card details from a cards object, including:
 * - provider: Card provider name
 * - cardNumber: Last 4 digits of card number
 * - expiryMonth: Expiry month
 * - expiryYear: Expiry year
 * - id: Card ID
 * - isDefault: Whether it's the default card
 * @param {Object} cards - Object containing card details
 * @returns {Object} Object containing formatted card details
 */
export const getCardDetails = cards => {
  const { provider, cardNumber, expiryMonth, expiryYear, id, isDefault } = cards
  const endingDigits = cardNumber
    ? cardNumber.substr(cardNumber.length - 4)
    : undefined
  return {
    provider,
    cardNumber: endingDigits,
    expiryMonth,
    expiryYear,
    id,
    isDefault,
  }
}

export const creditCardValidation = {
  VISA: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
  MASTERCARD:
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
  DISCOVER: /^(?:6(?:011|5[0-9]{2})[0-9]{12})$/,
  AMERICAN_EXPRESS: /^(?:3[47][0-9]{13})$/,
  DINERS_CLUB: /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/,
  JCB: /^(?:(?:2131|1800|35\d{3})\d{11})$/,
}
