import { i18nTranslate } from 'src/utils'

const cartCategoryType = (isFlexibleSubscriptionEnabled = false) => {
  return {
    subscriptionType: {
      value: isFlexibleSubscriptionEnabled
        ? i18nTranslate('type.subscriptionItem', 'SUBSCRIPTION ITEMS')
        : i18nTranslate('type.subscription', 'SUBSCRIPTION'),
    },
    oneTimeType: {
      value: i18nTranslate('type.oneTime', 'ONE-TIME PURCHASE'),
    },
    typeGift: {
      value: i18nTranslate('type.typeGift', 'FREE GIFTS'),
    },
  }
}
const cartCategoryTypeFlexSubs = () => {
  return {
    subscriptionType: {
      value: i18nTranslate('type.subscriptionTitle', 'Subscription Items'),
    },
    oneTimeType: {
      value: i18nTranslate('type.oneTimeTitle', 'One-time Purchase'),
    },
    giftType: {
      value: i18nTranslate('type.typeGiftTitle', 'Free Gifts'),
    },
  }
}
const alertMessageKeys = (message = '', code = '') => {
  if (message?.toLowerCase() === 'dangerous goods cannot be added to bag') {
    return i18nTranslate(
      'cart.dangerousgoods',
      'Dangerous goods cannot be added to bag'
    )
  } else if (message?.toLowerCase() === 'no results found') {
    return i18nTranslate('order.NoResults', 'No results found')
  } else if (
    message?.toLowerCase() ===
    'product/sku got removed from catalog, kindly remove this item to proceed.'
  ) {
    return i18nTranslate(
      'cart.removedSKU',
      'Product/SKU got removed from catalog, kindly remove this item to proceed.'
    )
  } else if (message?.toLowerCase() === 'skin & beauty') {
    return i18nTranslate('categoryTitle.skinBeauty', 'Skin & Beauty', {
      nameSpace: 'ssr-resource',
    })
  } else if (message?.toLowerCase() === 'nutrition') {
    return i18nTranslate('categoryTitle.nutrition', 'Nutrition', {
      nameSpace: 'ssr-resource',
    })
  } else if (message?.toLowerCase() === 'product lines') {
    return i18nTranslate('categoryTitle.productLines', 'Product Lines', {
      nameSpace: 'ssr-resource',
    })
  } else if (message?.toLowerCase() === 'new & loved') {
    return i18nTranslate('categoryTitle.newLoved', 'New & Loved', {
      nameSpace: 'ssr-resource',
    })
  } else if (
    message?.toLowerCase() ===
    'your subscription is in frozen state and ready to create your new order. you are not allowed to update the subscription, because you have already crossed the edit freeze date.'
  ) {
    return i18nTranslate(
      'subscription.editFreezeErrorMessage',
      'This Subscription order is currently being processed and at this time no changes will be allowed. Please return after your Subscription order has shipped to make any changes to your next order.'
    )
  } else if (
    message?.toLowerCase() === 'please correct the address to proceed.'
  ) {
    return i18nTranslate(
      'address.validationMsgToast',
      'Please correct the address to proceed.'
    )
  } else if (code === 'CRCRVALMAXBAG') {
    return i18nTranslate(
      'cart.totalLimitExceed',
      'The total quantity of items in the cart exceeds the maximum allowed limit configured.'
    )
  } else if (message?.toLowerCase() === 'something went wrong') {
    return i18nTranslate('commonError', 'Something went wrong', {
      nameSpace: 'ssr-resource',
    })
  } else if (code === 'INTL401') {
    return i18nTranslate('INTL401', 'Declined - Insufficient funds.')
  } else if (code === 'INTL400') {
    return i18nTranslate(
      'INTL400',
      'Declined - Please contact the number on the back of the card.'
    )
  } else if (code === 'INTL402') {
    return i18nTranslate('INTL402', 'Declined - Expired card.')
  } else if (code === 'INTL403') {
    return i18nTranslate('INTL403', 'Declined - Authentication requested.')
  } else if (code === 'INTL404') {
    return i18nTranslate(
      'INTL404',
      'Declined - Invalid card number. Please review and try again.'
    )
  } else if (code === 'EOCA0000011') {
    //Return translations based on errorcode and error message for Out of stock and expired promotions
    if (
      message?.toLowerCase() ===
      'order creation failed due to an invalid promotion'
    ) {
      return i18nTranslate(
        'order.promotionExpired',
        'Order creation failed due to an invalid promotion.'
      )
    } else if (message?.toLowerCase() === 'inventory not available') {
      return i18nTranslate(
        'order.outOfStock',
        'Few items in the cart are currently Out of Stock. Please check and update your cart to proceed.'
      )
    }
  } else {
    return message
  }
}

export { cartCategoryType, alertMessageKeys, cartCategoryTypeFlexSubs }
