const Link = (text, url, label) => {
  return { text, url, label }
}
const socialLinks = {
  links: [
    Link('fb', 'https://www.facebook.com/pages/Skava/5526864597', 'facebook'),
    Link('tw', 'https://twitter.com/skava', 'twitter'),
    Link('pin', 'https://www.pinterest.com/SkavaUS/', 'pinterest'),
  ],
}

export { socialLinks }
