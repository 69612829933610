import React, { useState, useEffect } from 'react'
import { Container, Modal, Row } from 'react-bootstrap'
import { FormInputLabels } from 'src/deps'
import { IS_BROWSER, tryJSONParse, getLocaleCodeFromUrl } from 'src/utils'
import { GrClose } from 'react-icons/gr'
import ContentStack from 'src/api/contentStack'
import './styles.scss'

function LearnMoreSubscriptionModal(props) {
  const [links, setLinks] = useState({})
  const [content, setContent] = useState({})

  let locale = getLocaleCodeFromUrl() || ''
  locale = locale?.split?.('_')?.reverse?.().join?.('-') || 'US-en'
  useEffect(() => {
    let uid = 'blta895a523c9556e91'
    ContentStack.getQualificationReference(
      'subscriptions_modal',
      uid,
      locale
    ).then(response => {
      setContent({
        ...content,
        sections: {
          sectionOne: {
            header: response?.content?.[0]?.header || '',
            body: response?.content?.[0]?.body || '',
          },
          sectionTwo: {
            header: response?.content?.[1]?.header || '',
            body: response?.content?.[1]?.body || '',
          },
          sectionThree: {
            header: response?.content?.[2]?.header || '',
            body: response?.content?.[2]?.body || '',
          },
          footer: getFooter(response?.footer),
        },
      })
    })
  }, [])

  const getFooter = content => {
    content = tryJSONParse(content)
    let returnPolicy = content?.return_policy || ''
    let faq = content?.faq || ''
    let terms_conditions = content?.terms_conditions || ''
    let footerText = content?.content_text?.replace(
      '{return_policy}',
      `<a href="${returnPolicy?.href}" filename="downloadfile" target="_blank">
      ${returnPolicy?.title}</a>`
    )
    footerText = footerText?.replace(
      '{terms_conditions}',
      `<a href="${terms_conditions?.href}" filename="downloadfile" " target="_blank">
      ${terms_conditions?.title}</a>`
    )
    footerText = footerText?.replace(
      '{faq}',
      `<a href="${faq?.href}" filename="downloadfile" target="_blank">
      ${faq?.title}</a>`
    )
    footerText = `<em>${footerText}</em>`
    return footerText
  }

  const navigateToUrl = url => {
    if (IS_BROWSER) {
      if (url === 'termsAndConditions') {
        window.open(links.retailCustomerLinks?.subscriptionAgreement)
      } else if (url === 'refundPolicy') {
        window.open(links.retailCustomerLinks?.refundPolicy)
      } else if (url === 'SubsFAQ') {
        window.open(props.subsFAQ())
      }
    }
  }

  useEffect(() => {
    setLinks(new FormInputLabels())
  }, [])

  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Body className="p-4">
        <div className="text-right ">
          <GrClose type="button" onClick={props.onHide} />
        </div>
        <Container>
          <Row>
            <h6 className="heading-5">
              {content?.sections?.sectionOne?.header}
            </h6>
            <p className="paragraph-s">{content?.sections?.sectionOne?.body}</p>
          </Row>
          <Row>
            <h6 className="heading-5">
              {content?.sections?.sectionTwo?.header}
            </h6>
            <p className="paragraph-s">{content?.sections?.sectionTwo?.body}</p>
          </Row>
          <Row>
            <h6 className="heading-5">
              {content?.sections?.sectionThree?.header}
            </h6>
            <p className="paragraph-s">
              {content?.sections?.sectionThree?.body}
            </p>
          </Row>
          <Row className="learn-more-subscription">
            <div
              dangerouslySetInnerHTML={{
                __html: content?.sections?.footer,
              }}></div>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
export { LearnMoreSubscriptionModal }
export default LearnMoreSubscriptionModal
