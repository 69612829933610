import { observable } from 'mobx'
import { useStickyTop } from 'config/appConfig'

/**
 * ScrollState is a class that manages scroll state and operations.
 * It tracks the scroll position and provides methods like scrollToTop()
 * to smooth scroll to a specific position.
 */
class ScrollState {
  @observable headerHeight = 0

  /**
   * Recursively gets the scroll top position of a node by walking up the offsetParent chain and summing the offsetTop values.
   *
   * @param {HTMLElement} node - The node to get the scroll top position for
   * @returns {number} The scroll top position of the node
   */
  getScrollTopPosition = node => {
    const offsetParent = node && node.offsetParent
    if (offsetParent) {
      return node.offsetTop + this.getScrollTopPosition(offsetParent)
    } else {
      return node?.offsetTop
    }
  }

  /**
   * Scrolls the window to the top smoothly.
   *
   * @param {HTMLElement} node - The node to scroll to the top of
   */
  scrollToTop = node => {
    const top =
      this.getScrollTopPosition(node) - (useStickyTop ? this.headerHeight : 200)
    if (typeof window === 'object') {
      window.scroll({
        left: 0,
        top,
        behavior: 'smooth',
      })
    }

    // scroll behaviour not smooth, need support for all browsers
    // node.scrollIntoView({ behaviour: 'smooth' })
  }
}

const scrollState = new ScrollState()

export { scrollState }
