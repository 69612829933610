import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { observer } from 'mobx-react'
import { COLOR_BASED_VARIANT_NODES } from 'config/appConfig'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import colorCode from 'src/static/fixtures/colorCode.json'
import { Icons } from 'src/views/components'
import { PDPContext } from 'src/views/components/BaseProductComponent'
import { productDeps } from 'src/deps'
import './styles.scss'

@observer
class V2ProductOption extends React.Component {
  static contextType = PDPContext
  static defaultProps = {
    selectionType: 'border',
  }
  render() {
    const disableSkuSelectionClass = this.props.disableSkuSelection
      ? 'disable-selection'
      : ''
    const { options = [], skuSelected = {}, selectionType } = this.props
    const skuGroup =
      options.length > 0 &&
      options.map(key => {
        const isColorBasedSku = COLOR_BASED_VARIANT_NODES.includes(
          key.type.toLowerCase()
        )

        const buttonGroup = (values, index) => {
          const value = values.label
          const type = key.type
          const identifier = values.identifier || ''
          const shouldDisable =
            values.disable === true ||
            (this.props.isFromSubscription && !values.isSubscribable)
          const padding = isColorBasedSku ? 'p-1' : ''
          const className = shouldDisable
            ? ' disabled'
            : skuSelected[type] === value
            ? selectionType === 'border' || isColorBasedSku
              ? ` selected border-primary text-primary sku-border-thick font-weight-bold ${padding}`
              : ` selected bg-primary border-0 text-white font-weight-bold ${padding}`
            : ` ${padding}`

          const attribute = {
            className: type + className + 'w-100',
            key: `${key.type}_${index}`,
          }

          return (
            <Button
              {...attribute}
              variant="outline-secondary"
              data-testid="qa-facet"
              onClick={() => {
                this.props.handleSkuSelection &&
                  this.props.handleSkuSelection({ type, value, identifier })
                const context = this.context
                /* @variant labelchange */

                productDeps.changeVariantInUrlForPDP(
                  context,
                  values.variantForUrl || '',
                  this.props
                )
              }}>
              {/*Selected variant tick icon*/}
              {skuSelected[type] === value ? (
                <Icons
                  name="CRUD"
                  icontype="tick-v2"
                  viewBox="0 0 24 17"
                  className="variant-selected-icon mr-2"
                  width="24px"
                  height="24px"
                  color="#252525"
                />
              ) : null}

              {isColorBasedSku ? (
                isValidUrl(values.url) ? (
                  <LazyLoadImage
                    src={values.url || imagePlaceholder}
                    alt={values.label}
                    className={'align-top'}
                    onError={e => {
                      if (e.target.src != imagePlaceholder) {
                        e.target.onerror = null
                        e.target.src = imagePlaceholder
                      }
                    }}
                  />
                ) : (
                  <div
                    className="border"
                    style={{
                      background: colorCode[value],
                      height: '25px',
                      width: '25px',
                    }}
                  />
                )
              ) : (
                // <Image
                //   className="align-top"
                //   src={values.url}
                //   onError={e => (e.target.src = imagePlaceholder)}
                //   alt={values.label}
                // />
                <span className="variant-label"> {value}</span>
              )}
            </Button>
          )
        }
        return (
          <section
            key={`product-option-${key.type}`}
            data-testid={`qa-select-${key.type}`}
            className={`product-option-list align-items-center ${disableSkuSelectionClass} w-100`}>
            <Row noGutters className="align-items-center product-option-title">
              <div className="select-heading mb-2">
                <p>
                  <strong>
                    {/* {getLocaleString()[key.type]} */}
                    {key?.values?.length > 1
                      ? i18nTranslate(
                          'product.selectOption',
                          'Select your option',
                          {
                            nameSpace: 'ssr-resource',
                          }
                        ) + ': '
                      : i18nTranslate('product.yourOption', 'Your option') +
                        ': '}
                  </strong>
                </p>
              </div>
              {key.type && (
                <>
                  <label className="sku-option pl-1 mb-2">
                    {skuSelected[key.type]}
                  </label>
                </>
              )}
            </Row>
            <Col
              noGutters
              className={`product-options ${key.type}-wrapper`}
              aria-label={i18nTranslate(
                'productDetails.skuOption',
                'Sku Options',
                {
                  nameSpace: 'ssr-resource',
                }
              )}>
              {key.values.map((keyObj, index) => (
                <div key={index} className="variantLabel-container">
                  {buttonGroup(keyObj, index)}
                </div>
              ))}
            </Col>
            {this.props.showError && this.props.showError(key.type)}
          </section>
        )
      })
    return skuGroup
  }
}

export { V2ProductOption }
export default V2ProductOption
