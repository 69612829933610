import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, FormCheck } from 'react-bootstrap'
import {
  i18nTranslate,
  IS_BROWSER,
  trackGTMEvents,
  getProductsForAgreementSection,
  getSubscriptionDrawerData,
} from 'src/utils'
import { APPConfig } from 'config/appConfig'
import { nuskinSubscriptionContainer } from 'src/models'
import { FormInputLabels } from 'src/deps'
import './styles.scss'

@observer
class SubscriptionAgreementInfo extends React.Component {
  @observable isPaymentAgreementChecked = false
  @observable isTermsAgreementChecked = false
  @observable payPalPaymentFlag = false

  constructor(props) {
    super(props)
  }

  /**
   * Handles subscribing when user clicks the cart subscribe button.
   * Checks if flexible subscription is enabled, calls updateSubscription API,
   * closes modal on success.
   */
  handleCartSubscribeClick = async () => {
    const isFlexibleSubscriptionEnabled =
      APPConfig?.getAppConfig()?.enableFlexibleSubscription === 'true'

    trackGTMEvents({
      event: 'subscriptionManage',
      products: getSubscriptionDrawerData(
        nuskinSubscriptionContainer.cartSubscriptionDrawerData
      ),
    })

    const response = await nuskinSubscriptionContainer.updateSubscription(
      nuskinSubscriptionContainer.cartSubscriptionDrawerData,
      isFlexibleSubscriptionEnabled
    )
    if (nuskinSubscriptionContainer.isSuccessResponse(response)) {
      this.props?.toggleSubscriptionModal()
    }
  }

  /**
   * Opens a new browser window/tab and navigates to the subscription agreement terms & conditions page.
   *
   * @param {Object} e - The click event object
   */
  navigateToTermsAndConditions = e => {
    // @todo Get Updated Link for Subscription Terms
    const formInputLabels = new FormInputLabels()
    const termsAndLinksCTA =
      formInputLabels?.retailCustomerLinks?.subscriptionAgreement || '#'
    e.preventDefault()
    if (IS_BROWSER) {
      window.open(termsAndLinksCTA)
    }
  }

  /**
   * Toggles the isPaymentAgreementChecked state value.
   * Sets isPaymentAgreementChecked to the opposite of its current value.
   */
  handlePaymentAgreementToggle = () => {
    this.isPaymentAgreementChecked = !this.isPaymentAgreementChecked

    //GTM events tracking start - CX16-11068
    const itemDetailsArr = getProductsForAgreementSection(
      nuskinSubscriptionContainer.cartSubscriptionDrawerData,
      true
    )
    this.isPaymentAgreementChecked &&
      trackGTMEvents({
        event: 'subscriptionCreditCardAgree',
        products: itemDetailsArr,
        type: 'ViewCart Subscription Agreement',
      })
    //GTM events tracking end - CX16-11068
  }

  /**
   * Toggles the isTermsAgreementChecked state value.
   * Sets isTermsAgreementChecked to the opposite of its current value.
   */
  handleTermsAgreementToggle = () => {
    this.isTermsAgreementChecked = !this.isTermsAgreementChecked

    //GTM events tracking start - CX16-11068
    const itemDetailsArr = getProductsForAgreementSection(
      nuskinSubscriptionContainer.cartSubscriptionDrawerData,
      true
    )
    this.isTermsAgreementChecked &&
      trackGTMEvents({
        event: 'subscriptionAgree',
        products: itemDetailsArr,
        type: 'ViewCart Subscription Agreement',
      })
    //GTM events tracking end - CX16-11068
  }

  /**
   * Renders the subscribe button.
   *
   * The button is disabled if the payment and terms agreement
   * checkboxes are not checked.
   *
   * Calls handleCartSubscribeClick when clicked.
   */
  renderSubscribeButton = () => {
    const isToEnableSubmitOrder =
      this.isPaymentAgreementChecked && this.isTermsAgreementChecked
    /** Todo: ADA - Some screenreaders will not read the button even we add aria-disabled attribute,
     * but to make screenreader to read the button as disabled, we should change <button> to <div> element
     * if we change like that, we need to add more ADA attributes also disabled default action is not working,
     * to fix this we need to add some conditions additionally to avoid tha action of the button when its disabled
     */

    return (
      <Button
        className="cart-drawer-subscribe-button"
        disabled={!isToEnableSubmitOrder}
        data-testid="qa-cart-drawer-submit-button"
        onClick={this.handleCartSubscribeClick}>
        {i18nTranslate('cartDrawer.subscribe', 'Subscribe')}
      </Button>
    )
  }

  /**
   * Renders the payment agreement and terms agreement checkboxes.
   *
   * The payment agreement checkbox allows the user to agree to the
   * recurring credit card charges.
   *
   * The terms agreement checkbox allows the user to agree they have
   * read the subscription terms and conditions. It contains a link
   * to navigate to the full terms and conditions page.
   */
  renderAgreementCheckboxes = () => {
    const paymentAgreementCheckboxLabel = i18nTranslate(
      'cartDrawer.paymentAgreementCheckboxLabel',
      'I agree the credit card saved on my Subscription will be charged each processing date'
    )

    const termsAgreementCheckboxLabel = i18nTranslate(
      'cartDrawer.termsAgreementCheckboxLabel',
      'I have read and agree to the'
    )

    const termsAndConditionsLink = i18nTranslate(
      'cartDrawer.termsAndConditionsLink',
      'Subscription Terms and Conditions'
    )

    return (
      <div className="subscription-agreement-wrapper">
        <FormCheck className="subscription-checkbox-wrapper agreement-checkbox-wrapper">
          <FormCheck.Input
            checked={this.isPaymentAgreementChecked}
            onChange={this.handlePaymentAgreementToggle}
            type="checkbox"
            className="payment-agreement-checkbox"
            id="payment_agreement_checkbox"
          />
          <FormCheck.Label
            className="payment_agreement_label"
            htmlFor="payment_agreement_checkbox">
            {paymentAgreementCheckboxLabel}
          </FormCheck.Label>
        </FormCheck>
        <FormCheck className="subscription-checkbox-wrapper">
          <FormCheck.Input
            checked={this.isTermsAgreementChecked}
            onChange={this.handleTermsAgreementToggle}
            type="checkbox"
            className="terms-agreement-checkbox"
            id="terms_agreement_checkbox"
          />
          <FormCheck.Label
            className="terms_agreement_label"
            htmlFor="terms_agreement_checkbox">
            {termsAgreementCheckboxLabel}{' '}
            <span
              className="terms-and-conditions-link"
              role="link"
              tabIndex="0"
              onClick={e => this.navigateToTermsAndConditions(e)}
              onKeyPress={e => this.navigateToTermsAndConditions(e)}>
              <u>{termsAndConditionsLink}</u>
            </span>
          </FormCheck.Label>
        </FormCheck>
      </div>
    )
  }

  /**
   * Renders the subscription info text.
   *
   * Displays the subtotal info and subscription process text.
   */
  renderSubscriptionInfo = () => {
    const subTotalInfo = i18nTranslate(
      'cartDrawer.subTotalInfo',
      'Subtotal does not include shipping or taxes.'
    )
    const subscriptionProcessText = i18nTranslate(
      'cartDrawer.subscriptionProcessText',
      'You may change or cancel your Subscriptions at any time through your Subscription Dashboard (processing times apply).'
    )

    return (
      <div className="cart-drawer-subscription-info">
        <p className="cart-drawer-sub-total-text">{subTotalInfo}</p>
        <p className="cart-drawer-processing-text">{subscriptionProcessText}</p>
      </div>
    )
  }

  render() {
    return (
      <div className="cart-drawer-agreement-section">
        <div className="subscription-info-checkbox-wrapper">
          {this.renderSubscriptionInfo()}
          {this.renderAgreementCheckboxes()}
        </div>
        {this.renderSubscribeButton()}
      </div>
    )
  }
}

export default SubscriptionAgreementInfo
export { SubscriptionAgreementInfo }
