import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import groupBy from 'lodash/groupBy'
import { Row } from 'react-bootstrap'
import { V2SubProduct } from './V2SubProduct'
import { groupBundleProductsByType } from 'config/appConfig'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { i18nTranslate } from 'src/utils'
import { PDPUnitPrice } from '../Price'
import { isExpressCheckout } from 'src/utils'
@observer
class V2BundlePDP extends React.Component {
  @observable openAccordionTiles = ['mandatory']
  @observable bundleOptionalProducts = {
    optional: this.props.product?.bundleOptionalProducts || [],
  }

  constructor(props) {
    super(props)
    if (groupBundleProductsByType) {
      const optionalProducts = props.product?.bundleOptionalProducts || []
      this.updateBundleOptionalProducts(optionalProducts)
    }
    // productState.makePromotionCallsForBundleKits(
    //   productState.product,
    //   productState.productCount
    // )
  }

  updateBundleOptionalProducts = (
    optionalProducts = (optionalProducts =
      this.props.product.bundleOptionalProducts)
  ) => {
    const groupedBundleProducts = groupBy(
      optionalProducts,
      product => product.properties?.bundletype || 'optional'
    )
    this.bundleOptionalProducts = groupedBundleProducts
    Object.keys(groupedBundleProducts)?.forEach((key, index) => {
      this.openAccordionTiles.push(
        key === 'optional' ? 'optional' : `optional${index}`
      )
    })
  }

  handleSkuSelection = (newData, index, type) => {
    if (type === 'mandatory') {
      if (
        this.props.product?.bundleMandatoryProducts &&
        this.props.product.bundleMandatoryProducts != 'undefined'
      ) {
        this.props.product.bundleMandatoryProducts[index] = newData
        productState.updateBundleKitInventoryStatus()
        // sku selection
      }
    } else {
      if (
        this.props.product?.bundleOptionalProducts &&
        this.props.product.bundleOptionalProducts != 'undefined'
      ) {
        this.props.product.bundleOptionalProducts[index] = newData
        this.updateBundleOptionalProducts(
          this.props.product?.bundleOptionalProducts
        )
      }
    }
  }

  renderSubProduct = (subProduct, index, type) => {
    const bundleType = this.props?.bundleType
    const inventory = this.props?.inventory

    return (
      <V2SubProduct
        type={type}
        key={`bundle_${index}`}
        subProduct={subProduct}
        index={index}
        updateBundleOptionalProducts={this.updateBundleOptionalProducts}
        handleSkuSelection={this.handleSkuSelection}
        bundleType={bundleType}
        inventory={inventory}
      />
    )
  }

  renderSubProducts = (products, type) => {
    return (
      <>
        {products?.map((subProducts, index) => {
          return this.renderSubProduct(subProducts, index, type)
        })}
      </>
    )
  }

  toggleAccordion = type => {
    if (this.openAccordionTiles.includes(type)) {
      this.openAccordionTiles = this.openAccordionTiles?.filter(
        name => name !== type
      )
    } else {
      this.openAccordionTiles.push(type)
    }
  }

  render() {
    const {
      bundleMandatoryProducts = [],
      bundleOptionalProducts = [],
      skuKit = [],
    } = this.props.product || {}
    const productCount = skuKit?.length || bundleMandatoryProducts?.length
    const bundleProduct = this?.props?.product?.type

    return (
      <Row noGutters className="all-subproducts-with-bundle-title-wrapper">
        {this.props?.bundleType === 'bundle' && (
          <>
            <PDPUnitPrice {...this.props} />
            <Row noGutters className="bundle-title-container w-100">
              <div
                className="build-bundle-title"
                data-testid="qa-pdp-build-your-bundle">
                {i18nTranslate('bundle.buildBundleV2', 'Build Your Bundle', {
                  nameSpace: 'ssr-resource',
                })}
                :
              </div>
            </Row>
          </>
        )}
        {isExpressCheckout() && (
          <div>
            <b>
              {bundleProduct === 'bundle'
                ? i18nTranslate(
                    'pdp.includedInBundle',
                    'Included in this bundle',
                    {
                      nameSpace: 'ssr-resource',
                    }
                  )
                : i18nTranslate('pdp.includedInKit', 'Included in this Kit', {
                    nameSpace: 'ssr-resource',
                  })}
              {` (${productCount} ${
                productCount > 1
                  ? i18nTranslate('items', 'Items', {
                      nameSpace: 'csr-resource',
                    })
                  : i18nTranslate('item', 'Item', {
                      nameSpace: 'csr-resource',
                    })
              })`}
            </b>
          </div>
        )}
        <div className="subproducts-wrapper w-100">
          {bundleMandatoryProducts.length > 0 &&
            this.renderSubProducts(bundleMandatoryProducts, 'mandatory')}
          {bundleOptionalProducts.length > 0 &&
            this.renderSubProducts(bundleOptionalProducts, 'optional')}
          {skuKit.length > 0 && this.renderSubProducts(skuKit)}
        </div>
      </Row>
    )
  }
}

export { V2BundlePDP }
export default V2BundlePDP
