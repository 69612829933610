const storeLocatorJson = {
  productId: ['110002', '110001'],
  storeZipcodes: ['10001', '10002', '10003', '10004', '10005'],
  storeMiles: ['25mi', '50mi', '100mi'],
  storeDetails: [
    {
      referenceId: 'T-Giant #1117',
      storeName: 'Sunrise Mall',
      storeAddress: '2280 Sunrise Mall',
      storeDistance: 20,
      storeContact: '(516) 541-1201',
      latitude: '40.6815168',
      longitude: '+-73.4344316',
      productId: ['110002', '110001'],
      getLocation:
        'https://maps.google.com/maps?f=d&hl=en&daddr=40.6815168,+-73.4344316',
    },
    {
      referenceId: 'T-Giant #1118',
      storeName: 'Tanger Outlet Center at The Arches',
      storeAddress: '1652 The Arches Circle',
      storeDistance: 22,
      storeContact: '(631) 254-0136',
      latitude: '40.76563211581365',
      longitude: '+-73.30338299274445',
      productId: [],
      getLocation:
        'https://www.google.com/maps?f=d&hl=en&daddr=40.76563211581365,+-73.30338299274445',
    },
    {
      referenceId: 'T-Giant #1282',
      storeName: 'South Shore Mall',
      storeAddress: '1701 Sunrise Hwy',
      storeDistance: 37,
      storeContact: '(631) 665-3758',
      latitude: '40.741436970084024',
      longitude: '+-73.24686884880066',
      productId: ['110002', '110001'],
      getLocation:
        'https://www.google.com/maps?f=d&hl=en&daddr=40.741436970084024,+-73.24686884880066',
    },
    {
      referenceId: 'T-Giant #1110',
      storeName: 'Westfield Connecticut Post Shopping Center',
      storeAddress: '1201 Boston Post Rd',
      storeDistance: 39,
      storeContact: '(203) 874-0424',
      latitude: '41.23602711758131',
      longitude: '+-73.03558051586151',
      productId: [],
      getLocation:
        'https://www.google.com/maps?f=d&hl=en&daddr=41.23602711758131,+-73.03558051586151',
    },
    {
      referenceId: 'T-Giant #21',
      storeName: 'Franklin Mills',
      storeAddress: '1259 Franklin Mills Circle',
      storeDistance: 68,
      storeContact: '(215) 281-0925',
      latitude: '40.0852963',
      longitude: '+-74.9620021',
      productId: ['110002', '110001'],
      getLocation:
        'https://www.google.com/maps?f=d&hl=en&daddr=40.0852963,+-74.9620021',
    },
    {
      referenceId: 'T-Giant #939',
      storeName: 'Moorestown Mall',
      storeAddress: '400 Route 38',
      storeDistance: 75,
      storeContact: '(215) 281-0925',
      latitude: '40.0852963',
      longitude: '+-74.9620021',
      productId: [],
      getLocation:
        'https://www.google.com/maps?f=d&hl=en&daddr=40.0852963,+-74.9620021',
    },
  ],
}

export { storeLocatorJson }
