import React from 'react'
import { observer } from 'mobx-react'
import './styles.scss'

@observer
class ProductShortDescription extends React.Component {
  render() {
    const { shortDescription } = this.props

    return (
      <>
        {shortDescription && (
          <div
            className="product-description"
            data-testid="qa-product-about"
            dangerouslySetInnerHTML={{ __html: shortDescription }}
          />
        )}
      </>
    )
  }
}

export { ProductShortDescription }
