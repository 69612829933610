import i18n from 'i18next'
import { getStack } from '@ns/ns-contentstack-lib'
import { getLocaleCodeFromUrl } from 'src/utils'

export let contentFromCS = ''

/**
 * Translates the given locale key and locale value using i18next.
 *
 * @param {string} localeKey - The locale key to translate
 * @param {string} [localeValue] - The locale value to interpolate
 * @param {Object} options - Additional options
 * @param {('csr-resource'|'ssr-resource')} [options.nameSpace='csr-resource'] - The namespace to prefix the locale key
 * @returns {string} The translated string
 */
function i18nTranslate(localeKey, localeValue, options) {
  const { nameSpace = 'csr-resource' } = options || {}
  return i18n.t(`${nameSpace}:${localeKey}`, localeValue)
}

async function getContentFromCS() {
  if (contentFromCS) {
    return contentFromCS
  }
  const Stack = getStack('test' || 'prod')
  const curLocale = getLocaleCodeFromUrl({
    isReverseType: true,
    defaultLocale: 'en_US',
  })

  const [language, country] = curLocale?.includes('_')
    ? curLocale?.split('_')
    : curLocale?.split('-')
  const [signup_strings, jitsu_strings] = await Stack.getSingletonEntries({
    contentTypeUIDs: ['jitsu_strings'],
    country: country,
    language: language,
    camelcase: true,
    mergeWithFallback: true,
  })
  contentFromCS = {
    ...signup_strings,
  }
  return contentFromCS
}

export { i18nTranslate, getContentFromCS }
