import { observable } from 'mobx'
import { cartContainer } from 'src/models'
import {
  normalAndSpecialProductSkuType,
  specialProductTypes,
} from 'src/views/components/CatalogViews'

class CartSubscriptionState {
  @observable product = {}

  /**
   * Generates an array of bundle data objects for a given product.
   * @param {Object} product - The product object.
   * @returns {Array<Object>} - An array of bundle data objects.
   */
  getPostDataForSpecialProducts = product => {
    let bundleData = product?.skus?.map(sku => {
      const {
        productId = '',
        skuId = '',
        skuQuantity = 0,
        optionalSkuQuantity = 0,
      } = sku || {}
      return { productId, skuId, skuQuantity, optionalSkuQuantity }
    })

    return bundleData
  }

  /**
   * Determines the wrapper type for a given product type.
   *
   * @param {string} [type=''] - The product type.
   * @returns {string} The wrapper type for the given product type.
   */
  getWrapperType = (type = '') => {
    switch (type?.toUpperCase()) {
      case 'FIXED_BUNDLE':
        return 'fixedpricebundle'
      case 'BUNDLE':
        return 'bundle'
      case 'SKUKIT':
        return 'kit'
    }
  }

  /**
   * Generates the necessary data for a promotion API request based on the provided product.
   *
   * @param {Object} [product={}] - The product object.
   * @param {number} [productCount=1] - The quantity of the product.
   * @returns {Object} - The post data for the promotion API request.
   */
  getPostDataForPromotionApi = (product = {}, productCount = 1) => {
    let mainProductData =
      product?.skus?.filter(sku => {
        const { type = '' } = sku || {}
        const skuType = type?.toLowerCase()?.replace('_', '') || ''
        return normalAndSpecialProductSkuType?.includes?.(skuType)
      }) || []
    const { productId = '', skuId = '', type = '' } = mainProductData?.[0] || {}
    const skuType = type?.toLowerCase()?.replace('_', '') || ''
    const { categoryId } = product?.properties || {}
    let postData = {
      productId: productId || '',
      categoryId: categoryId || '',
      type: this.getWrapperType(type) || 'DEFAULT',
      quantity: String(productCount || 1),
    }
    if (specialProductTypes?.includes?.(skuType)) {
      const bundleData = this.getPostDataForSpecialProducts(product)
      postData = { ...postData, skus: bundleData }
    } else {
      postData.skuId = skuId
    }
    return postData
  }

  /**
   * Retrieves the subscription promotion details for the given product and quantity.
   *
   * @param {Object} [product={}] - The product object.
   * @param {number} [quantity=1] - The quantity of the product.
   * @returns {Promise<Object>} - The response from the promotion API call.
   */
  getSubscriptionPromotionDetails = async (product = {}, quantity = 1) => {
    const postData = this.getPostDataForPromotionApi(product, quantity)
    const subscriptionPostData = {
      ...postData,
      isSubscriptionIncludedInPromotion: true,
      isSubscriptionPromotion: true,
    }
    const response = await cartContainer.makePromotionCall(subscriptionPostData)
    return response
  }
}

const cartSubscriptionState = new CartSubscriptionState()

export { cartSubscriptionState }
