import React from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Button, Row, Col } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { storePickupState } from './state'
import './styles.scss'

@observer
class StoreList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { store } = this.props
    const { productId } = storePickupState

    return (
      <Row className="py-4" noGutters id={productId}>
        <Col lg={8}>
          <div>{store.referenceId}</div>
          <div className="store-name">{store.storeName}</div>
          <div>{store.storeAddress}</div>
          <div>{store.storeDistance} mi away</div>
          <div>{store.storeContact}</div>
          <div>
            <Link
              target="_blank"
              className="store-direction text-secondary"
              href={store.getLocation}>
              {i18nTranslate('bopis.getDirections', 'Get Directions', {
                nameSpace: 'ssr-resource',
              })}
            </Link>
          </div>
        </Col>
        <Col lg={4}>
          <div
            className={
              store.productId.includes(productId)
                ? 'text-success'
                : 'text-danger'
            }>
            {store.productId.includes(productId)
              ? i18nTranslate('bopis.available', 'available', {
                  nameSpace: 'ssr-resource',
                })
              : i18nTranslate('bopis.notAvailable', 'Not available', {
                  nameSpace: 'ssr-resource',
                })}{' '}
            {i18nTranslate('bopis.inStore', 'in-store', {
              nameSpace: 'ssr-resource',
            })}
          </div>
          <Button
            variant="primary"
            className="mt-2"
            onClick={e => storePickupState.handleStoreChange(e, this.props)}
            disabled={store.productId.includes(productId) ? '' : true}>
            {i18nTranslate('bopis.selectStore', 'SELECT STORE', {
              nameSpace: 'ssr-resource',
            })}
          </Button>
        </Col>
      </Row>
    )
  }
}

export { StoreList }
export default StoreList
