import React from 'react'
import { Col } from 'react-bootstrap'
import leadership from 'src/images/Leadership.png'
import { observer } from 'mobx-react'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
@observer
class ProductLeaderPreviewMessages extends React.Component {
  render() {
    const { product = {}, isExclusive = '' } = this.props || {}

    const { qualificationMessage, exclusiveTitle } =
      productState.getQualificationMessage({
        productId: this.props?.product?.productId,
        isExclusive,
      })

    return (
      <>
        {qualificationMessage && (
          <Col className="exclusive_messages">
            <div className="leader_preview d-flex align-items-center">
              <div className="leader_preview_icon">
                <img src={leadership} alt="" />
              </div>
              <div>
                <h6> {exclusiveTitle}</h6>
                <p className="p-0">{qualificationMessage}</p>
              </div>
            </div>
          </Col>
        )}
      </>
    )
  }
}
export default ProductLeaderPreviewMessages
export { ProductLeaderPreviewMessages }
