import React from 'react'
import { i18nTranslate, convertToBoolean } from 'src/utils'
import {
  BsFillCheckCircleFill,
  BsFillExclamationCircleFill,
} from 'react-icons/bs'
import { tokenExContainer, storeContainer, accountsContainer } from 'src/models'
import { toastState } from 'src/views/components'
import './styles.scss'
import { IoIosInformationCircle as InformationIcon } from 'react-icons/io'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import appConfig, { APPConfig } from 'config/appConfig'

@observer
class TokenExIframe extends React.Component {
  @observable isValidCard = false
  @observable isCvvValidCard = false
  constructor(props) {
    super(props)
  }

  isFromCreditCard = editUpdateModalEnabled => {
    const { isValid, cardType } = tokenExContainer?.tokenExValidData || {}
    const { error } = tokenExContainer?.tokenExErrorData || {}

    return (
      <div className="token-block" data-testid="qa-pay-card-wrapper">
        <label className="m-0" data-testid="qa-cardNumber">
          {editUpdateModalEnabled
            ? i18nTranslate(
                'payment.reEnterCardNumber',
                'Re-Enter Card Number *'
              )
            : i18nTranslate('payment.cardNumber', 'Card Number *')}
        </label>
        <div
          value=""
          id="tokenExIframeDiv"
          data-testid="qa-iframe_Card"
          title="iframe-payment-div"></div>
        {error &&
          // <div className="justify-content-between error-bold">
          //   {error}
          // </div>
          // disabling error message and passing error message to toast message #CX121-4853
          toastState.setToastMessage(error, false)}
        {isValid === true && (
          <div
            className={
              storeContainer?.storeIDValue === 'Canada' &&
              cardType === 'discover'
                ? 'd-none'
                : 'cardsuccesstoken  justify-content-between'
            }>
            <BsFillCheckCircleFill
              size="1rem"
              className={`tick-icon green valid-absolute`}
            />
          </div>
        )}
        {storeContainer?.storeIDValue === 'Canada'
          ? (cardType === 'discover' ||
              (isValid === false && this.isValidCard === false)) && (
              <div className="cardNumbertoken d-flex justify-content-between">
                <span
                  className="cardnumbererrormsg"
                  data-testid="qa-card-number-invalid">
                  {i18nTranslate(
                    'validation.cardNumberErrorText',
                    'Please enter valid card number'
                  )}
                </span>
                <BsFillExclamationCircleFill
                  size="1rem"
                  className={`tick-icon-red-cardnumber`}
                />
              </div>
            )
          : isValid === false &&
            this.isValidCard === false && (
              <div className="cardNumbertoken  justify-content-between">
                <span
                  className="cardnumbererrormsg"
                  data-testid="qa-card-number-invalid">
                  {i18nTranslate(
                    'validation.cardNumberErrorText',
                    'Please enter valid card number'
                  )}
                </span>
                <BsFillExclamationCircleFill
                  size="1rem"
                  className={`tick-icon-red-cardnumber`}
                />
              </div>
            )}
      </div>
    )
  }

  isFromCVV = editUpdateModalEnabled => {
    const { isCvvValid, isValid, cardType } =
      tokenExContainer.tokenExValidData || {}

    return (
      <div className="token-block" data-testid="qa-pay-code-wrapper">
        <label className="form-label security-code" data-testid="qa-Data">
          {editUpdateModalEnabled
            ? i18nTranslate('payment.CVV', 'CVV/CVC *')
            : i18nTranslate('payment.securityCode', 'Security Code *')}
        </label>
        {/** Element Postition of cvv field and cvv info is changed for tab navigations - CX17-11525 */}
        <div value="" id="cvvID" data-testid="qa-iframe_Cvv"></div>
        <OverlayTrigger
          trigger={'click'}
          placement="top"
          rootClose={true}
          overlay={
            <Tooltip
              id="cvv-tooltip"
              className="new-cvv-tooltip"
              data-testid="qa-cvv-info-tooltip">
              {i18nTranslate(
                'payment.cvvInfo',
                'To proceed with your transaction, enter your Card Verification Value found at the back of your card.'
              )}
            </Tooltip>
          }>
          <Button
            className="new-cvv-tooltip-info-button"
            data-testid="qa-cvv-info-button">
            <InformationIcon
              aria-label="Information tool"
              size="1.5rem"
              id="info-black-button-new-payment"
              className="infotool"
            />
          </Button>
        </OverlayTrigger>
        {isValid === true && isCvvValid === true && (
          <div className="cardsuccesstokencvv  justify-content-between">
            <BsFillCheckCircleFill
              size="1rem"
              className={`tick-icon green valid-absolute`}
            />
          </div>
        )}
        {isCvvValid === false && this.isCvvValidCard === false && (
          <div className="cardtoken justify-content-between">
            <span className="cvverrormsg" data-testid="qa-card-cvv-invalid">
              {i18nTranslate(
                'validation.cardCvvErrorText',
                'Please enter valid security code'
              )}
            </span>
            <BsFillExclamationCircleFill
              size="1rem"
              className={`tick-icon-red-cvv`}
            />
          </div>
        )}
      </div>
    )
  }
  isDisplayNoneCVV = () => {
    return <> </>
  }
  clickwrapContainer = () => {
    const enableMemberClickWrapContainer =
      convertToBoolean(APPConfig?.getAppConfig()?.enableJitSuIroncladFeature) &&
      accountsContainer?.selectedAccountType === 'Preferred Customer/Member'
    return (
      <>
        <div id="clickwrap-container-1" className="click-wrap-container"></div>
        {enableMemberClickWrapContainer && (
          <div
            id="clickwrap-container-2"
            className="click-wrap-container"></div>
        )}
        <div className="clickwrapContainerError">
          {' '}
          {i18nTranslate(
            'ironClad.termsAndConditions',
            'Please accept the terms & conditions*'
          )}
        </div>
      </>
    )
  }
}
const tokenExIframe = new TokenExIframe()
export default tokenExIframe
export { tokenExIframe }
