import { observable, action, reaction } from 'mobx'
import omit from 'lodash/omit'
import {
  VARIANT_NODES,
  enableStorePickup,
  PDPRecommendationFromApi,
  APPConfig,
} from 'config/appConfig'
import {
  getLocalStorage,
  setLocalStorage,
  application,
  trackPDPAddToCart,
  IS_BROWSER,
  isExpressCheckout,
} from 'src/utils'
import {
  catalogContainer,
  subscriptionContainer,
  cartContainer,
  merchBuilder,
  ProductBuilder,
  customerContainer,
  promotionContainer,
} from 'src/models'
import { storePickupState, toastState } from 'src/views/components'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { productDeps, handleProductSaveData } from 'src/deps'
import { trackQuickViewAddToCart, i18nTranslate } from 'src/utils'
import { getProductProperties } from './deps'

class ProductState {
  @observable selectPickupType = 'ship-it'
  @observable productResponse = {}
  @observable product = {}
  @observable productCount = 1
  @observable bundlePromotionResponse = {}
  @observable inventory = {}
  @observable subscribeAndSave = 0
  @observable normalPdt_unitValueForSubscriptionPopup = {}
  @observable normalPdt_totalValueForSubscriptionPopup = {}
  @observable bundle_unitValueForSubscriptionPopup = {}
  @observable bundle_totalValueForSubscriptionPopup = {}
  @observable inventoryStatus = []
  @observable skuLevelSubProductStatus = []
  @observable quantityActionDisable = false
  @observable pdpPromotionResponse = {}
  @observable singleQtyNormalProductResponse = {}
  @observable bundleUnitPriceResponse = {}

  // possible values for currentlySelectedPriceOption are
  // ["one-time-price-button","subscription-price-button"]
  @observable currentlySelectedPriceOption = 'one-time-price-button'

  @observable selectedFrequencyValueInPDP = {
    frequencyPeriod: '1',
    frequencyType: 'MONTHS',
  }
  isBundleSkuChange = false

  resetFrequencyStateInPDP = () => {
    this.selectedFrequencyValueInPDP = {
      frequencyPeriod: '1',
      frequencyType: 'MONTHS',
    }
  }

  resetValues = () => {
    this.productResponse = {}
    this.product = {}
    this.productCount = 1
    this.subscribeAndSave = 0
    this.bundlePromotionResponse = {}
    this.selectPickupType = 'ship-it'
    this.inventory = {}
    this.pdpPromotionResponse = {}
    this.singleQtyNormalProductResponse = {}
    this.bundleUnitPriceResponse = {}
    this.currentlySelectedPriceOption = 'one-time-price-button'
    promotionContainer.resetV2PromoResponse()
    this.resetFrequencyStateInPDP()
  }

  setPickUpType = (type = '') => {
    this.selectPickupType = type
  }

  getCrossSellProducts = async (productId = '') => {
    if (PDPRecommendationFromApi) {
      const crossSellProducts = await catalogContainer.getCrossSellProducts(
        productId
      )

      if (crossSellProducts.length > 0) {
        this.productResponse.crossSellProducts = crossSellProducts
      }
    }
  }
  // @##pdp_button_disable_and_status_display_logic bundle
  updateBundleKitInventoryStatus = () => {
    /* CX12-8823 handle OOS for bundle and kit */
    const product = this.product
    const type = product?.type || ''
    let inventoryStatus = []
    let inventory = []
    let skuLevelSubProductStatus = []
    if (type.toLowerCase() === 'kit') {
      const skuKitProduct = product?.skuKit || []
      skuKitProduct?.forEach(product => {
        const quantityPerIncrement = product?.properties?.quantity || 1
        const subProductSkuLevelProductStatus =
          product?.selectedSku?.properties?.productStatus?.toLowerCase() || ''
        if (product?.selectedSku) {
          inventoryStatus.push(product?.selectedSku?.inventory || '')
          inventory.push({
            ...(product?.selectedSku?.inventoryProperties || {
              inventoryPropertiesUnavailable: true,
            }),
            quantityPerIncrement: quantityPerIncrement,
          })
          skuLevelSubProductStatus.push(subProductSkuLevelProductStatus)
        }
      })
    } else {
      const mandatoryProduct = product?.bundleMandatoryProducts || []
      // const optionalProduct = product?.bundleOptionalProducts || []
      mandatoryProduct?.forEach(data => {
        const quantityPerIncrement = data?.properties?.quantity || 1
        const subProductSkuLevelProductStatus =
          data?.selectedSku?.properties?.productStatus?.toLowerCase() || ''

        if (data?.selectedSku) {
          inventoryStatus.push(data?.selectedSku?.inventory || '')
          inventory.push({
            ...(data?.selectedSku?.inventoryProperties || {
              inventoryPropertiesUnavailable: true,
            }),
            quantityPerIncrement: quantityPerIncrement,
          })
          skuLevelSubProductStatus.push(subProductSkuLevelProductStatus)
        }
      })
      // the below is copied code from add to cart logic
      // ignoring optional product as status is handled in "add to bundle" itself
      // in subproduct component

      // optionalProduct.filter(data => {
      //   if (data.selectedSku && this.optionalData?.includes(data.identifier)) {
      //     bundleData.push(this.updateData(data, 'Optional'))
      //   }
      // })
    }
    // set inventory status for bundle and kit while changing variant
    this.inventoryStatus = inventoryStatus
    this.inventory = inventory
    this.skuLevelSubProductStatus = skuLevelSubProductStatus
  }
  getProduct = async (props = {}, contextdetails = {}) => {
    const isBundleView = props.isBundleView || false
    const productId = props.isFromExpressCheckout
      ? props.productId || ''
      : isBundleView
      ? props.details?.productId || '110001'
      : props.match?.params?.productId || '110001'
    const categoryId = props.location?.queryParams?.categoryId || ''
    const context = contextdetails.context || {}
    const contextName = contextdetails.contextName || ''
    const skuId = props.location?.queryParams?.skuId || props.selectedSku || ''
    const tempProd =
      catalogContainer.catalogResponse?.data?.product?.find(
        prod =>
          prod?.identifier === productId ||
          prod?.properties?.slug == productId ||
          prod?.semantics?.find(semantic => semantic == productId)
      ) || {}

    const hasCacheItem = Object.keys(tempProd).length > 0

    const getProductProps = {
      productId,
      categoryId,
      context: context,
      contextName: contextName,
    }

    // using PLP cached data for initial PDP rendering
    if (hasCacheItem) {
      let tempProductResponse = {
        product: [tempProd],
        status: hasCacheItem ? 'success' : 'failure',
      }
      tempProductResponse = catalogContainer.transformProduct(
        tempProductResponse,
        getProductProps
      )
      this.constructDataAndSetProductResponse(tempProductResponse, skuId, props)
    }
    const response = await catalogContainer.getProduct(getProductProps)
    if (hasCacheItem && !response?.product?.[0]) {
      console.warn('Fn getProduct: product is not available')
      return
    }

    this.constructDataAndSetProductResponse(response, skuId, props)

    if (!customerContainer?.profileResponse?.id) {
      reaction(
        () => customerContainer?.profileResponse,
        async (change, reaction) => {
          /*this.getCrossSellProducts(
            get(this.product, 'previousSku.identifier', productId)
          )*/

          reaction.dispose()
        }
      )
    } /*else {
      this.getCrossSellProducts(
        get(this.product, 'previousSku.identifier', productId)
      )
    }*/
  }

  setValuesForSubscribeAndSaveText = (
    response,
    isNormal,
    isFromSubscriptionModal = false
  ) => {
    if (response && catalogContainer.isSuccessResponse(response)) {
      const isToEnableProductPromotionAPI =
        APPConfig?.getAppConfig()?.enableProductPromotionAPI == 'true' || false
      if (isToEnableProductPromotionAPI) {
        const discountWithoutSubscription = isNormal
          ? this.pdpPromotionResponse?.totalValue?.totaldiscount || 0
          : this.bundlePromotionResponse?.totalValue?.totaldiscount || 0
        const discountWithSubscription =
          response?.totalValue?.totaldiscount || 0 //Promotion Response
        this.subscribeAndSave =
          discountWithoutSubscription === discountWithSubscription
            ? 0
            : discountWithSubscription - discountWithoutSubscription
      }
    } else {
      this.subscribeAndSave = 0
    }
  }

  @action
  constructDataAndSetProductResponse = async (
    response = {},
    skuId = '',
    props = {}
  ) => {
    let inventoryProperties = []
    let inventoryStatus = []
    let skuLevelSubProductStatus = []
    productDeps.optionalData = []
    // resetting optional product data
    // https://nuskin.atlassian.net/browse/CX12-11199

    if (response.product && catalogContainer.isSuccessResponse(response)) {
      this.productCount = props.subscriptionQuantity || 1
      const data = productDeps.transformProductResponse(response, skuId, props)
      const product = data?.product?.[0] || {}

      this.productResponse = {
        ...response,
        product: omit(product, [
          'skuSelected',
          'selectedSku',
          'previousSku',
          'productOption',
        ]),
      }
      this.product = this.transformProductProperties(product)
      this.pdpPromotionResponse = this.product

      /* setting inventory related information to state */
      if (response?.product?.[0]?.bundleMandatoryProducts) {
        const bundleList = response?.product?.[0]?.bundleMandatoryProducts || []
        // defaultSkuId exists for product with multiple variant
        // this should be used only onInitial load
        // after sku selection, default ==  true should be used

        // refer updateBundleKitInventoryStatus
        const bundleInventories = bundleList.map(product => {
          const defaultSkuId = product?.properties?.defaultSkuId || ''
          const quantityPerIncrement = product?.properties?.quantity || 1

          let defaultSku =
            product?.sku?.filter(sku => {
              return defaultSkuId
                ? sku?.identifier == defaultSkuId
                : sku.default === true
            }) || []
          if (defaultSku && defaultSku.length == 0) {
            defaultSku = product?.sku
          }
          const subProductSkuLevelProductStatus =
            defaultSku?.[0]?.properties?.productStatus?.toLowerCase() || ''
          inventoryStatus.push(defaultSku?.[0]?.inventory || '')
          skuLevelSubProductStatus.push(subProductSkuLevelProductStatus)
          inventoryProperties.push({
            ...(defaultSku?.[0]?.inventoryProperties || {
              inventoryPropertiesUnavailable: true,
            }),
            quantityPerIncrement: quantityPerIncrement,
          })
        })
      }
      if (response?.product?.[0]?.skuKit) {
        const skuList = response?.product?.[0]?.skuKit || []
        skuList.forEach(product => {
          const defaultSkuId = product?.properties?.defaultSkuId || ''
          // defaultSkuId exists for product with multiple variant
          // this should be used only onInitial load
          // after sku selection, default ==  true should be used

          // refer updateBundleKitInventoryStatus
          const quantityPerIncrement = product?.properties?.quantity || 1
          let defaultSku =
            product?.sku?.filter(sku => {
              return defaultSkuId
                ? sku?.identifier == defaultSkuId
                : sku.default === true
            }) || []
          if (defaultSku && defaultSku.length == 0) {
            defaultSku = product?.sku
          }
          const subProductSkuLevelProductStatus =
            defaultSku?.[0]?.properties?.productStatus?.toLowerCase() || ''
          skuLevelSubProductStatus.push(subProductSkuLevelProductStatus)

          inventoryProperties.push({
            ...(defaultSku?.[0]?.inventoryProperties || {
              inventoryPropertiesUnavailable: true,
            }),

            quantityPerIncrement: quantityPerIncrement,
          })
          inventoryStatus.push(defaultSku?.[0]?.inventory || '')
        })
      }
    } else {
      this.productResponse = response
    }
    const isNormalProduct =
      !this.product?.isCollection &&
      !this.product?.isBundle &&
      !this.product?.isFixedBundle
        ? true
        : false
    const productObjectWithSubscriptionTrue = {
      productId: this.product?.productId,
      categoryId: this.product?.categoryId || '',
      type: this.product?.type || 'DEFAULT',
      quantity: String(this.productCount ? this.productCount : 1),
      skuId: this.product?.skuId,
      isSubscriptionIncludedInPromotion: true,
    }

    this.inventory = inventoryProperties
    this.inventoryStatus = inventoryStatus
    this.skuLevelSubProductStatus = skuLevelSubProductStatus

    // @##makenewpromo
    this.checkAndMakeProductPromotion({
      isNormalProduct,
      productObjectWithSubscriptionTrue,
    })
  }

  checkAndMakeProductPromotion = (options = {}) => {
    const customerResponse =
      customerContainer?.profileResponse?.customer ||
      customerContainer?.profileResponse
    options.isFromInit = true
    if (IS_BROWSER) {
      if (customerResponse?.id) {
        this.getProductPromotion(options)
      } else {
        // used timer since we don't have direct connection between customer and product state
        setTimeout(() => {
          this.checkAndMakeProductPromotion(options)
        }, 300)
      }
    }
  }

  getProductPromotion = async options => {
    const {
      isNormalProduct,
      productObjectWithSubscriptionTrue,
      isFromInit = false,
    } = options || {}

    if (!this.product?.categoryId) {
      console.log('DEV NOTE: Category not mapped')
    }
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    if (enableNewPDPStyle) {
      // @##makenewpromo
      if (isNormalProduct) {
        const searchParams = new URL(location.href).searchParams
        const variant = searchParams.get('variant')
        if (variant) {
          // variant from url might not be default sku
          // hence making promotion call
          promotionContainer.makeV2PromotionCall(
            this.product,
            this.productCount
          )
        }
      }
    } else {
      if (isNormalProduct) {
        // Added support to make promotion API using browse param to get
        // the sku based subscribe and save promotion property in the response
        if (IS_BROWSER) {
          const searchParams = new URL(location.href).searchParams
          const variant = searchParams.get('variant')

          if (variant) {
            const browseProductObject = JSON.parse(
              JSON.stringify(productObjectWithSubscriptionTrue)
            )
            browseProductObject.isSubscriptionIncludedInPromotion = false
            const browseResponse = await cartContainer.makePromotionCall(
              browseProductObject
            )

            if (browseResponse) {
              if (browseResponse?.unitValue) {
                productState.pdpPromotionResponse = browseResponse
              }
            }
          }
        }
        const isToEnableProductPromotionAPI =
          APPConfig?.getAppConfig()?.enableProductPromotionAPI == 'true' ||
          false
        let subscriptionResponse = {}
        if (isFromInit && !isToEnableProductPromotionAPI) {
          // skip making API
          // works fine for now,
          // no need to set data from pdp call to promotion observable
        } else {
          subscriptionResponse = await cartContainer.makePromotionCall(
            productObjectWithSubscriptionTrue
          )
        }

        // @##pdp_call_for_promotion
        this.setValuesForSubscribeAndSaveText(
          subscriptionResponse,
          isNormalProduct,
          this.product.isFromSubscriptionModal
        )
      } else {
        // @##pdp_call_for_promotion
        productState.makePromotionCallsForBundleKits(
          productState.product,
          productState.productCount,
          isFromInit
        )
      }
    }
  }

  transformProductProperties = (product = {}) => {
    const isCollection = productDeps.isCollectionData(product)
    const isBundle = productDeps.isBundleData(product)
    const isFixedBundle =
      (product.type || 'product').toLowerCase() == 'fixedpricebundle'
        ? true
        : false
    const bundlePromotionOnloadOff = true
    const categoryId = this.productResponse.category?.identifier || ''

    const additionalProps = {
      isCollection,
      isBundle,
      isFixedBundle,
      bundlePromotionOnloadOff,
      categoryId,
    }
    const productProperties = getProductProperties(product, additionalProps)
    return {
      skuSelected: product.skuSelected,
      skuList: product.sku,
      productOption: product.productOption,
      subProducts: product.subProducts,
      bundleMandatoryProducts: product.bundleMandatoryProducts,
      bundleOptionalProducts: product.bundleOptionalProducts,
      skuKit: product.skuKit,
      properties: product.properties,
      ...productProperties,
    }
  }
  updateSku = (product = {}) => {
    const additionalProps = {
      isCollection: product.isCollection,
      isBundle: product.isBundle,
      categoryId: product.categoryId || '',
    }
    const productProperties = getProductProperties(
      {
        previousSku: product.previousSku,
        ...(this.productResponse?.product || {}),
      },
      additionalProps
    )

    this.product = {
      skuSelected: product.skuSelected,
      skuList: product.skuList,
      productOption: product.productOption,
      ...productProperties,
    }
  }

  selectSkuById = (skuId = '') => {
    const currentSku = this.product.sku.find(
      skuObj => skuObj.identifier === skuId
    )
    const product = {
      ...this.product,
      selectedSku: currentSku,
      previousSku: currentSku,
      skuSelected: VARIANT_NODES.reduce((acc, value) => {
        if (currentSku[value]) {
          return { ...acc, [value]: currentSku[value] }
        }
      }, {}),
    }
    this.updateSku(product)
  }

  handleMerchEvent = (event = {}) => {
    try {
      if (event.data && typeof event.data === 'string') {
        const parsedData = JSON.parse(event.data)
        if (parsedData.id === 'load') {
          const products = parsedData?.products.map(
            merchBuilder.transformProduct
          )
          const transformedProduct = ProductBuilder.transformProduct({
            product: products,
            status: 'success',
          })
          this.constructDataAndSetProductResponse(transformedProduct)
          console.log('[parsedData ----- ] - ', parsedData)
        } else if (parsedData.id === 'dbSave') {
          handleProductSaveData(event.origin)
        }
      }
    } catch (error) {
      console.log('[listenLoadEvent] - ', error)
    }
  }

  handleBopis = (skuId = '') => {
    if (
      this.selectPickupType === 'pick-in-store' &&
      storePickupState.storePickupProducts.hasOwnProperty(skuId)
    ) {
      const existingProducts = getLocalStorage('storeProducts') || {}
      setLocalStorage(
        'storeProducts',
        Object.assign(existingProducts, storePickupState.storePickupProducts)
      )
    }
  }

  handleSingleAddToBag = (product = {}) => {
    const { skuId = '', productId = '', categoryId = '' } = product
    const slug = this.productResponse.product?.properties?.slug
    const cartObjArr = []
    if (enableStorePickup) {
      this.handleBopis(skuId)
    }
    if (skuId) {
      // @##backorder_logic_cart

      const isBackOrdered =
        productDeps.checkBackOrderBasedOnCurrentQuantityForCartFlag(
          this.product.selectedSku,
          this.productCount
        )
      const cartObj = {
        skuId,
        productId,
        slug,
        quantity: this.productCount,
        categoryId: categoryId,
        isBackOrdered,
      }

      cartObjArr.push(cartObj)
    }
    return cartObjArr
  }

  handleBundleAddToBag = (product = {}) => {
    const bundleData = productDeps.getBundleData(product, {
      compareAndSetBackorderBasedOnQuantity: true,
      currentlySelectedQuantity: this.productCount,
    })
    // @##backorder_logic_cart
    return [
      {
        quantity: this.productCount || 1,
        categoryId: product.categoryId || '',
        slug: this.productResponse?.product?.properties?.slug || '',
        isBundle: true,
        brand: product.brand || '',
        imageURL: product.imageURL || '',
        name: product.name || '',
        productId: product.productId || '',
        ...bundleData,
      },
    ]
  }

  handleV2SingleAddToBag = (product = {}, productCount = 1) => {
    const { skuId = '', productId = '', categoryId = '' } = product
    const slug = product?.properties?.slug || ''
    const cartObjArr = []
    if (enableStorePickup) {
      this.handleBopis(skuId)
    }
    if (skuId) {
      // @##backorder_logic_cart
      const isBackOrdered =
        productDeps.checkBackOrderBasedOnCurrentQuantityForCartFlag(
          product.selectedSku,
          productCount
        )
      const cartObj = {
        skuId,
        productId,
        slug,
        quantity: productCount,
        categoryId: categoryId,
        isBackOrdered,
      }

      cartObjArr.push(cartObj)
    }
    return cartObjArr
  }

  handleV2BundleAddToBag = (product = {}, productCount = 1) => {
    const bundleData = productDeps.getBundleData(product, {
      compareAndSetBackorderBasedOnQuantity: true,
      currentlySelectedQuantity: productCount,
    })
    // @##backorder_logic_cart
    return [
      {
        quantity: productCount,
        categoryId: product.categoryId || '',
        slug: product?.properties?.slug || '',
        isBundle: true,
        brand: product.brand || '',
        imageURL: product.imageURL || '',
        name: product.name || '',
        productId: product.productId || '',
        ...bundleData,
      },
    ]
  }

  handleV2OneTimeAddToCart = async (
    product = {},
    productCount = 1,
    totalPrice = 0,
    isQuickview = false
  ) => {
    // const  = product.properties?.scanQualifiedCount

    let cartArray = productDeps.isBundleData(product)
      ? this.handleV2BundleAddToBag(product, productCount)
      : this.handleV2SingleAddToBag(product, productCount)

    const price = totalPrice

    const variant = product?.variantLabel || ''
    let response = ''
    if (cartArray) {
      // subscriptionContainer.scanQualifiedCount = scanCount
      const addToCartProps = {
        cartArray,
        price,
        variant,
      }
      if (product?.isExclusive) {
        addToCartProps['isExclusiveProductFromPDP'] = true
      }
      response = await cartContainer.addToCart(addToCartProps)
      if (isQuickview) {
        trackQuickViewAddToCart(response)
      } else if (isExpressCheckout()) {
        if (response?.status == 'success') {
          trackPDPAddToCart({
            ...response,
            event: 'buy_now',
            referrer: 'expressProductPage',
            price: price,
            isExpressCheckout: true,
          })
        } else {
          const errorMessage =
            response?.code === 'EOCACG0001' ||
            response?.code === 'CustomerNotAllowed'
              ? response.message
              : i18nTranslate(
                  'cart.addCartFailure',
                  'There is an issue with your account. Please contact you local account manager.'
                )
          toastState.setToastMessage(errorMessage, false)
        }
      } else {
        trackPDPAddToCart(response)
      }
      return response
    }
  }
  handleAddToBag = async () => {
    const product = this.product
    const scanCount =
      this.productResponse.product?.properties?.scanQualifiedCount

    let cartArray = productDeps.isBundleData(product)
      ? this.handleBundleAddToBag(product)
      : this.handleSingleAddToBag(product)

    const price =
      this.pdpPromotionResponse?.totalValue?.priceAfterDiscount ||
      product?.totalValue?.priceAfterDiscount ||
      this.product?.price ||
      ''

    const variant = this.product?.variantLabel || ''
    let response = ''
    if (cartArray) {
      subscriptionContainer.scanQualifiedCount = scanCount
      const addToCartProps = {
        cartArray,
        price,
        variant,
      }

      if (product?.isExclusive) {
        addToCartProps['isExclusiveProductFromPDP'] = true
      }
      response = await cartContainer.addToCart(addToCartProps)
      // response = product?.isExclusive
      //   ? await cartContainer.addToCart({
      //       cartArray,
      //       isExclusiveProductFromPDP: true,
      //       price,
      //       variant,
      //     })
      //   : await cartContainer.addToCart({ cartArray, price, variant })
      // if (response?.message?.toLowerCase() == 'item is backordered') {
      //   let isBackOrder = true
      //   cartArray = this.handleSingleAddToBag(product, isBackOrder)
      //   console.log('cartArray', cartArray)
      //   response = product?.isExclusive
      //     ? await cartContainer.addToCart({ cartArray, isExclusiveProductFromPDP: true })
      //     : await cartContainer.addToCart({ cartArray })
      // }
      trackPDPAddToCart(response)
      return response
    }
  }

  handleNormalProductSingleQtyPromotion = async (
    productDetails,
    productCount = 1,
    isToMakePromotionCallWithSubscription = false
  ) => {
    this.quantityActionDisable = true
    const product = productDetails || this.product || {}
    const productObjectWithSubscriptionTrue = {
      productId: product.productId || product.identifier || '',
      categoryId: product.categoryId || '',
      type: product.type || 'DEFAULT',
      quantity: String(productCount ? productCount : 1),
      skuId: product.skuId,
      isSubscriptionIncludedInPromotion: isToMakePromotionCallWithSubscription,
      isSubscriptionPromotion: productDetails?.isSubscriptionPromotion || false,
    }
    this.singleQtyNormalProductResponse =
      await cartContainer.makePromotionCallsForNormalProduct(
        productObjectWithSubscriptionTrue
      )
    this.quantityActionDisable = false
  }

  handleBundleSingleQtyPromotionForModal = async (
    productDetails,
    productCount = 1,
    isToMakePromotionCallWithSubscription = false,
    isToChangeBundleUnitPrice = false
  ) => {
    this.quantityActionDisable = true
    const product = productDetails || this.product || {}
    const bundleData =
      (productDetails?.bundleProducts?.mandatoryCount &&
        productDetails?.bundleProducts?.mandatoryCount > 0) ||
      (productDetails?.bundleProducts?.optionalCount &&
        productDetails?.bundleProducts?.optionalCount > 0)
        ? productDetails?.bundleProducts
        : productDeps?.getBundleData(product)
    const productObjectWithSubscription = {
      productId: product?.productId,
      quantity: productCount?.toString(),
      categoryId: product?.categoryId || '',
      type: product?.type,
      isSubscriptionIncludedInPromotion: isToMakePromotionCallWithSubscription,
      isSubscriptionPromotion: productDetails?.isSubscriptionPromotion || false,
      ...bundleData,
    }

    this.quantityActionDisable = false
  }

  makePromotionCallsForBundleKits = async (
    productDetails,
    productCount = 1,
    isFromInit = false
  ) => {
    this.quantityActionDisable = true
    const isNormalProduct = false
    const product = productDetails || this.product || {}
    const bundleData =
      (productDetails?.bundleProducts?.mandatoryCount &&
        productDetails?.bundleProducts?.mandatoryCount > 0) ||
      (productDetails?.bundleProducts?.optionalCount &&
        productDetails?.bundleProducts?.optionalCount > 0)
        ? productDetails?.bundleProducts
        : productDeps?.getBundleData(product)
    const productObjectWithSubscriptionTrue = {
      productId: product?.productId,
      quantity: productCount?.toString(),
      categoryId: product?.categoryId || '',
      type: product?.type,
      isSubscriptionIncludedInPromotion: true,
      isSubscriptionPromotion: product?.isSubscriptionPromotion || false,
      ...bundleData,
    }
    const productObjectWithSubscriptionFalse = {
      productId: product.productId,
      quantity: productCount?.toString(),
      categoryId: product.categoryId || '',
      type: product.type,
      ...bundleData,
    }

    let setPromoResponse = false
    let bundleKitPromoResponse_Without_SubscriptionPromo = {}
    const isToEnableProductPromotionAPI =
      APPConfig?.getAppConfig()?.enableProductPromotionAPI == 'true' || false
    if (
      bundleData &&
      !(product.isFixedBundle && productState.isBundleSkuChange)
    ) {
      /* isSubscriptionIncludedInPromotion:  false */

      let response = {}
      if (isFromInit && !isToEnableProductPromotionAPI) {
        if (this.productResponse?.product) {
          response = {
            ...this.productResponse?.product,
            status: 'success',
            quantity: productCount,
          }
        }
      } else {
        response = await cartContainer.makePromotionCall(
          productObjectWithSubscriptionFalse
        )
      }

      setPromoResponse = true
      bundleKitPromoResponse_Without_SubscriptionPromo = response
    }
    // commenting the below condition:
    // for bundles and kits
    // we have not used issubscription flag for restricting bundle subscription button
    // we are using only available channels

    // this condition was causing issue for product where button was in enabled state
    // but promotion api for subscription price was not made inside pop-up

    // if (
    //   product?.issubscription === 'true' ||
    //   typeof product?.issubscription === 'undefined'
    // ) {
    let subscriptionResponse = {}

    if (isFromInit && !isToEnableProductPromotionAPI) {
      if (this.productResponse?.product) {
        subscriptionResponse = {
          ...this.productResponse?.product,
          status: 'success',
          quantity: productCount,
        }
      }
    } else {
      subscriptionResponse = await cartContainer.makePromotionCall(
        productObjectWithSubscriptionTrue
      )
    }
    // for bundle
    // both init and qty change
    // @set_subscription_pop_up_price
    if (subscriptionResponse?.unitValue) {
      this.bundle_unitValueForSubscriptionPopup =
        subscriptionResponse?.unitValue
    }
    if (subscriptionResponse?.totalValue) {
      this.bundle_totalValueForSubscriptionPopup =
        subscriptionResponse?.totalValue
    }

    // setValuesForSubscribeAndSaveText
    this.setValuesForSubscribeAndSaveText(
      subscriptionResponse,
      isNormalProduct,
      product.isFromSubscriptionModal
    )
    // }

    /* for pdp, not for subscription modal qty change*/
    if (setPromoResponse) {
      if (!product.isFromSubscriptionModal) {
        // while changing qty, when promotion changes, we need to update unit price and promotion display
        // not required for modal
        this.compareBundleKitProductPromotionsAndUpdate(
          bundleKitPromoResponse_Without_SubscriptionPromo,
          productObjectWithSubscriptionFalse
        )
      }
      await this.constructDataAndSetResponseForBundleKit(
        bundleKitPromoResponse_Without_SubscriptionPromo,
        product.isFromSubscriptionModal
      )
    }

    productState.isBundleSkuChange = false
    this.quantityActionDisable = false
  }

  @action
  constructDataAndSetResponseForBundleKit = (
    response,
    isFromSubscriptionModal = false
  ) => {
    if (response && cartContainer.isSuccessResponse(response)) {
      if (!isFromSubscriptionModal) {
        if (response?.quantity == 1) {
          this.bundleUnitPriceResponse = response
        }
        this.bundlePromotionResponse = response
      }
    }
  }

  /*
  for PDP normal product and pdp subscription modal
  not for bundle and not for quickview

  this method is used to compare promotions 
  and update unit price and promotion display
  */
  compareNormalProductPromotionsAndUpdate = async (
    product = {},
    latestQtyResponse
  ) => {
    /*
    adding this code for 
    previously, we make promotion call and update only quantity based total price
    currently we are making promotion call and updating promotion detail and unit price
    */

    // unit price
    try {
      if (
        productState.pdpPromotionResponse?.promotion &&
        latestQtyResponse?.promotion &&
        JSON.stringify(productState.pdpPromotionResponse?.promotion) !==
          JSON.stringify(latestQtyResponse?.promotion)
      ) {
        const promotionProductObject = {
          productId: product.productId || product.identifier || '',
          categoryId: product.categoryId || '',
          type: product.type || 'DEFAULT',
          quantity: '1',
          skuId: product.skuId || '',
        }

        // get promo with qty 1
        // for showing Single unit discounted price in modal
        // call has to be made for pdp also
        // to display correct unit price
        const responseWithQtyAs1 =
          await cartContainer.makePromotionCallsForNormalProduct(
            promotionProductObject
          )

        // total value with quantity 1 can be used as unit price

        if (responseWithQtyAs1?.totalValue) {
          this.product.totalValue = responseWithQtyAs1?.totalValue
          this.product.unitValue = responseWithQtyAs1?.unitValue
          /* ^ for pdp - normal pdt  */
          this.singleQtyNormalProductResponse.totalValue =
            responseWithQtyAs1?.totalValue
          this.singleQtyNormalProductResponse.unitValue =
            responseWithQtyAs1?.unitValue
          /* ^ for subscription modal - normal pdt  */
        }
        if (latestQtyResponse?.promotion) {
          // latest promotion data to be updated for labels
          this.product.promotion = latestQtyResponse?.promotion
        }
      }
    } catch (error) {
      console.log(
        'error while comparing promotions and updating unit price compareNormalProductPromotionsAndUpdate',
        error
      )
    }
  }

  /**
   * for bundle ( not related to subscription modal )
   * when promotion changes while changing qty
   * the total price and promotion label related data is updated here
   */
  compareBundleKitProductPromotionsAndUpdate = async (
    currentQtyResponseToCompare = {},
    productObjectWithSubscriptionFalse
  ) => {
    /*
    adding this code for 
    previously, we make promotion call and update only quantity based total price
    currently we are making promotion call and updating unit price
    */
    // unit price
    try {
      if (
        currentQtyResponseToCompare?.promotion &&
        this.bundlePromotionResponse?.promotion &&
        JSON.stringify(currentQtyResponseToCompare?.promotion) !==
          JSON.stringify(this.bundlePromotionResponse?.promotion)
      ) {
        // get promo with qty 1
        // for showing Single unit discounted price in modal
        // call has to be made for pdp also
        // to display correct unit price
        productObjectWithSubscriptionFalse.quantity = '1'
        const responseWithQtyAs1 = await cartContainer.makePromotionCall(
          productObjectWithSubscriptionFalse
        )
        // total value with quantity 1 can be used as unit price
        if (responseWithQtyAs1?.totalValue) {
          this.bundleUnitPriceResponse.totalValue =
            responseWithQtyAs1?.totalValue
        }
      }
    } catch (error) {
      console.log(
        'error while comparing promotions and updating unit price - compareBundleKitProductPromotionsAndUpdate',
        error
      )
    }
  }

  handleQuantityChangePromotion = async (product = {}, productCount = 1) => {
    // @##makenewpromo
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    if (enableNewPDPStyle && !product.isFromSubscriptionModal) {
      this.quantityActionDisable = true
      application.isAlertLoader = false
      await promotionContainer.makeV2PromotionCall(product, productCount)
      application.isAlertLoader = true
      this.quantityActionDisable = false
    } else {
      const isNormalProduct = true
      this.quantityActionDisable = true
      application.isAlertLoader = false
      const productObject = {
        productId: product.productId || product.identifier || '',
        categoryId: product.categoryId || '',
        type: product.type || 'DEFAULT',
        quantity: productCount.toString(),
        skuId: product.skuId || '',
      }
      const productObjectWithSubscriptionTrue = {
        productId: product.productId || product.identifier || '',
        categoryId: product.categoryId || '',
        type: product.type || 'DEFAULT',
        quantity: String(productCount ? productCount : 1),
        skuId: product.skuId,
        isSubscriptionIncludedInPromotion: true,
        isSubscriptionPromotion: product?.isSubscriptionPromotion || false,
      }
      const response = await cartContainer.makePromotionCallsForNormalProduct(
        productObject
      )
      if (!product.isFromSubscriptionModal) {
        //while changing qty, when promotion changes, we need to update unit price and promotion display
        // not required for modal
        await this.compareNormalProductPromotionsAndUpdate(product, response)
      }
      application.isAlertLoader = true
      if (response && cartContainer.isSuccessResponse(response)) {
        try {
          product = JSON.parse(JSON.stringify(product)) // was getting one console error on assigning any nodes to product json directly
        } catch (e) {
          console.log('error in JSON file', e)
        }
        product.promotion = response?.promotion || []
        const responseTotalValue = response?.totalValue || {}
        const responseUnitValue = response?.unitValue || {}
        product.totalValue = responseTotalValue
        product.unitValue = responseUnitValue

        product.priceFacets = {
          CV: responseTotalValue?.priceFacets?.CV?.CV || '',
          PV: responseTotalValue?.priceFacets?.PV?.PV || '',
          GRP: product.priceFacets?.GRP || '',
          'Regular Price': product.priceFacets?.['Regular Price'] || '',
        }
        product.transactionPrice = {
          type: product.transactionPrice?.type || '',

          price: responseTotalValue?.totaldiscount
            ? responseTotalValue?.priceAfterDiscount
            : responseTotalValue?.originalPrice || '',
          unitPrice: responseTotalValue?.originalPrice || '',
          currency: product.transactionPrice?.currency || '',
          currencySymbol: product.transactionPrice?.currencySymbol || '',
          minPrice: product.transactionPrice?.minPrice || '',
          maxPrice: product.transactionPrice?.maxPrice || '',
          regularPrice: product.transactionPrice?.regularPrice || '',
          priceFacets: product.transactionPrice?.priceFacets || '',
        }
        if (!product.isFromSubscriptionModal) {
          this.pdpPromotionResponse = response
        }
      }

      if (
        product?.issubscription == 'true' ||
        typeof product?.issubscription === 'undefined'
      ) {
        const subscriptionResponse =
          await cartContainer.makePromotionCallsForNormalProduct(
            productObjectWithSubscriptionTrue
          )
        // for normalproduct
        // only qty change scenario
        // @set_subscription_pop_up_price
        this.normalPdt_unitValueForSubscriptionPopup =
          subscriptionResponse.unitValue || {}
        this.normalPdt_totalValueForSubscriptionPopup =
          subscriptionResponse.totalValue || {}

        this.setValuesForSubscribeAndSaveText(
          subscriptionResponse,
          isNormalProduct,
          product.isFromSubscriptionModal
        )
      }

      this.quantityActionDisable = false
    }
  }
  getQualificationItem = props => {
    const { productId = '' } = props || {}

    const exclusiveItem =
      customerContainer?.qualificationResponse?.qualification?.filter(
        product => {
          return product?.productId === productId
        }
      )
    return exclusiveItem?.[0]
  }
  getQualificationMessage = props => {
    const { productId, isExclusive = '' } = props || {}
    const curLocaleFromUrl = getLocaleCodeFromUrl()
    let qualificationMessage = ''
    let exclusiveTitle = ''
    let isQualificationEligible = false
    let isItemEligible = false

    const country = curLocaleFromUrl?.split('_')?.[1]
    const exclusiveItem = this.getQualificationItem(props)

    if (
      exclusiveItem?.markets?.includes(country) &&
      exclusiveItem?.isConsumable
    ) {
      isQualificationEligible = true
    }

    if (
      customerContainer?.qualificationStoreResponse === true &&
      isExclusive == 'true'
    ) {
      exclusiveTitle =
        customerContainer?.qualificationData?.exclusive_offer_title
      if (customerContainer.isRegisterUser) {
        if (isQualificationEligible) {
          const qualificationQuantity =
            customerContainer?.qualificationResponse?.qualification?.find(
              qualification => qualification?.productId === productId
            )?.quantity || 0
          if (qualificationQuantity <= 0) {
            qualificationMessage =
              customerContainer?.qualificationData?.exclusive_offer_used_up_qty
          } else {
            isItemEligible = true
            qualificationMessage = ' '
          }
        } else {
          qualificationMessage =
            customerContainer?.qualificationData
              ?.exclusive_offer_no_qualification
        }
      } else {
        qualificationMessage =
          customerContainer?.qualificationData?.exclusive_offer_message
      }
    } else {
      isItemEligible = true
    }

    return {
      qualificationMessage,
      exclusiveTitle,
      isItemEligible,
      exclusiveItem,
    }
  }
}

const productState = new ProductState()

export { productState }
