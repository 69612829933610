import { observable } from 'mobx'

/**
 * MyAccountMenuDeps class.
 * Handles state and actions related to the my account menu.
 */
class MyAccountMenuDeps {
  @observable isMenuVisible = false

  /**
   * Toggles the visibility state of the my account menu.
   */
  toggleFilter = () => {
    this.isMenuVisible = !this.isMenuVisible
  }
}

const myAccountMenuDeps = new MyAccountMenuDeps()

export default myAccountMenuDeps
export { myAccountMenuDeps }
