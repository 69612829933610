import React from 'react'
import { Row } from 'react-bootstrap'
import { observer } from 'mobx-react'
import './styles.scss'

@observer
class ProductTitle extends React.Component {
  render() {
    const { name, secondaryTitle, isShowSecondaryTitle = true } = this.props

    return (
      <Row noGutters className="flex-column">
        <span>
          <h1
            className={`product-name heading-1 ${secondaryTitle ? 'mb-0' : ''}`}
            data-testid="qa-product-name">
            {name}
          </h1>
          {secondaryTitle && (
            <h2 className="secondary-title">{secondaryTitle}</h2>
          )}
        </span>
      </Row>
    )
  }
}

export { ProductTitle }
