import { customerContainer } from 'src/models'
import { loyaltyContainer } from 'src/models/Loyalty/LoyaltyContainer'
import { storeContainer } from 'src/models'

export const isBrandAffiliateProfile = userType => {
  return userType.indexOf('Brand Affiliate') > -1
}

export let switchActive = false

export let showForceAllowedRewardTypeForOptIn = false

export let checkoutOptBtnUpdate = false

export const setCheckoutOptBtnUpdate = val => {
  checkoutOptBtnUpdate = val
}

export const setSwitchActive = val => {
  switchActive = val
}

export const isLoyEnableInProf = () => {
  const loyaltyRes = loyaltyContainer.loyaltyPointsResponse
  let optInVal = loyaltyRes?.userMath?.LOYALTY?.optInStatus || false
  if (optInVal && isBrandAffiliateTypeProfile()) {
    optInVal = true
  }
  switchActive = optInVal
  return optInVal
}

export const isBuyerUser = () => {
  const profileData = customerContainer.profileResponse
  const profileType = profileData?.customProperties?.role || ''
  return profileType === 'ROLE_ACCOUNT_BUYER'
}

export const isBrandAffiliateTypeProfile = () => {
  const profileData = customerContainer.profileResponse
  const profileType = profileData?.accounts?.type || ''
  return isBrandAffiliateProfile(profileType)
}

export const isAllowedRewardTypeForOptIn = () => {
  if (
    (showForceAllowedRewardTypeForOptIn ||
      storeContainer.getAllowedRewardTypeForOptIn()) &&
    isBuyerUser() &&
    isLoyEnableInProf()
  ) {
    return false
  }
  return (
    showForceAllowedRewardTypeForOptIn ||
    storeContainer.getAllowedRewardTypeForOptIn()
  )
}

export const loyaltyResPoints = () => {
  const loyaltyRes = loyaltyContainer.loyaltyPointsResponse
  return loyaltyRes?.userMath?.LOYALTY?.total?.openToRedeem || 0
}
