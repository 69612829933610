import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Link } from 'react-router-dom'
import { VscClose as CloseIcon } from 'react-icons/vsc'
import { Button, Col, ListGroup, Row } from 'react-bootstrap'
import { IS_BROWSER } from 'config/appConfig'
import { application } from 'src/utils'
import { signInwithRedirect, signOutWithRedirect } from 'src/utils/signInUtils'
import { pageNames } from 'src/routes/pathParams'
import { LocaleModal, mobileMenuState } from 'src/views/components'
import { customerContainer } from 'src/models'
import NuskinAccountIcon from 'src/views/components/Header/Components/UserMenu/NuskinAccountIcon'
import nuskinLogoMobile from 'src/images/live-event-nuskin-mobile-logo.png'
import { observer } from 'mobx-react'
import './styles.scss'

/**
 * MobileSignout component that renders the sign out button
 * in the mobile menu for authenticated users.
 * Extends React.Component.
 */
/**
 * MobileSignout component that renders the sign out button
 * in the mobile menu for authenticated users.
 * Extends React.Component.
 */
class MobileSignout extends React.Component {
  nameSpace = 'ssr-resource'
  /**
   * Toggles the mobile menu visibility when the screen size is tablet or smaller.
   */
  handleMobileMenu = () => {
    if (application.isTabletOrSmaller) {
      mobileMenuState.toggleMenuVisibility()
    }
  }

  render() {
    if (!IS_BROWSER) {
      return <></>
    }

    const componentToRender = customerContainer.isRegisterUser ? (
      <div className="nu-mob-signout">
        <Button className="nu-mob-signout-btn" onClick={signOutWithRedirect}>
          {i18nTranslate('header.signOut', 'Sign Out', {
            nameSpace: this.nameSpace,
          })}
        </Button>
      </div>
    ) : (
      <div className="nu-mob-signout">
        <Button
          className={`nu-mob-signout-btn`}
          onClick={signInwithRedirect}
          data-testid="qa-signin">
          <NuskinAccountIcon
            className="mr-1"
            style={{ fontSize: '24px', verticalAlign: 'middle' }}
            aria-label="sign in icon"
          />
          {i18nTranslate('header.signIn', 'Sign In', {
            nameSpace: this.nameSpace,
          })}
        </Button>
      </div>
    )
    return componentToRender
  }
}

@observer
class EventMobileMenu extends React.Component {
  nameSpace = 'ssr-resource'

  handleLinkClick = () => {
    mobileMenuState.toggleMenuVisibility()
  }

  myFirstName = text => {
    let rtext = ''
    let len = String(text).length
    if (len > 12) {
      rtext = String(text).slice(0, 11).concat('...')
    } else {
      rtext = text
    }
    return ' ' + rtext
  }

  render() {
    return (
      <aside
        className={`eventheader-mobilemenu hide-on-desktop pancake fixed-top h-100 w-100 bg-white nu-mobilemenu ${
          mobileMenuState.isMenuVisible ? 'open' : 'closed'
        }`}>
        <div className="eventheader-mobilemenu-header">
          <Row className="w-100 h-100" noGutters>
            <Col xs={9} className="d-flex">
              <div className="eventheader-mobileeventlogo">
                <img
                  className="eventheader-mobileeventlogo-img"
                  src={nuskinLogoMobile}
                />
              </div>
              {customerContainer.isRegisterUser && (
                <span className={`ml-3 m-auto nu-sign-text`}>
                  {i18nTranslate('header.welcome', 'Hi', {
                    nameSpace: this.nameSpace,
                  })}
                  {this.myFirstName(
                    customerContainer.profileResponse.firstName
                  )}
                </span>
              )}
            </Col>
            <Col xs={3} className="text-right">
              <Button
                className="eventheader-mobilemenu-close"
                aria-label="closebutton"
                variant="none"
                onClick={this.handleLinkClick}>
                <CloseIcon size="24px" />
              </Button>
            </Col>
          </Row>
        </div>

        <div className="eventheader-mobilemenu-container">
          <ListGroup className="">
            <ListGroup.Item onClick={this.handleLinkClick}>
              <Link to={pageNames?.home}>
                {i18nTranslate('breadCrumb.home', 'Home', {
                  nameSpace: 'ssr-resource',
                })}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item onClick={this.handleLinkClick}>
              <Link to="/catalog/products">
                {i18nTranslate('event.shopProducts', 'Shop Products', {
                  nameSpace: 'ssr-resource',
                })}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="eventheader-mobilemenu-locale">
              <LocaleModal history={this.props.history} isNuskinHeader />
            </ListGroup.Item>
          </ListGroup>
        </div>
        <MobileSignout enableLiveEvent={this.props.enableLiveEvent} />
      </aside>
    )
  }
}

export default EventMobileMenu
export { EventMobileMenu }
