const checkoutFormInput = () => {
  return [
    {
      name: 'firstName',
      ariaLabel: 'firstName',
      placeholderKey: 'firstNamePlaceHolder',
      dataTestId: 'qa-first-name',
      columnType: {
        lg: '12',
      },
      maxLength: 50,
    },
    {
      name: 'lastName',
      ariaLabel: 'lastName',
      placeholderKey: 'lastNamePlaceHolder',
      dataTestId: 'qa-last-name',
      columnType: {
        lg: '12',
      },
      maxLength: 50,
    },
    {
      name: 'email',
      columnType: {
        lg: '12',
      },
      customClassName: 'mb-1',
      dataTestId: 'qa-createaccount-email',
      ariaLabel: 'email',
      placeholderKey: 'emailAddressPlaceHolder',
    },
    {
      name: 'createAccount',
      type: 'inlineSubmitButton',
      columnType: {
        lg: '12',
      },
      customClassName: 'mt-5 create-account-btn',
      dataTestId: 'qa-createaccount-createbutton',
    },
  ]
}

export { checkoutFormInput }
