import React from 'react'
import { Row } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { CartProducts } from 'src/views/components'
import './styles.scss'

class SaveForLater extends React.Component {
  render() {
    const { isCheckout = false } = this.props
    return (
      <div className="p-3 saveForLater">
        <Row
          noGutters
          className={`font-weight-bold saveforlater-title heading-3-bold d-flex align-items-center ${
            isCheckout ? '' : 'pl-3'
          }`}>
          {i18nTranslate('cart.saved', 'Saved')}
        </Row>
        <Row noGutters data-testid="qa-saved-products">
          <CartProducts
            isCheckout={isCheckout}
            isSaveForLater={true}
            className={`${isCheckout ? '' : 'my-2'}`}
            history={this.props.history}
          />
        </Row>
      </div>
    )
  }
}

export { SaveForLater }
export default SaveForLater
