import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

class PageLink extends React.PureComponent {
  remainingProps = () => {
    const remainingProps = { ...this.props }
    delete remainingProps.linkInfo
    delete remainingProps.href
    delete remainingProps.className
    return remainingProps
  }

  renderAnchorTag = () => {
    const { linkInfo } = this.props || {}
    const componentName = this.props?.componentName || ''
    const remainingProps = this.remainingProps()
    const fontToggle = this.props?.fontToggle || ''
    const fontClass = fontToggle
      ? 'popular-text-color-white'
      : 'popular-text-color-black'

    return (
      <div className={componentName === 'homepage_product' ? fontClass : ''}>
        <a
          href={linkInfo?.link || '#'}
          className={`link ${this.props.className || ''}`}
          target={this.props.target || '_self'}
          {...remainingProps}>
          {this.props.children}
        </a>
      </div>
    )
  }

  renderLinkTag = () => {
    const { linkInfo } = this.props || {}
    const remainingProps = this.remainingProps()
    const componentName = this.props?.componentName || ''
    const fontToggle = this.props?.fontToggle || ''
    const fontClass = fontToggle
      ? 'popular-text-color-white'
      : 'popular-text-color-black'

    return (
      <div className={componentName === 'homepage_product' ? fontClass : ''}>
        <Link
          to={linkInfo?.link || '#'}
          className={`link ${this.props.className || ''}`}
          {...remainingProps}>
          {this.props.children}
        </Link>
      </div>
    )
  }

  render() {
    const { linkInfo } = this.props || {}
    if (linkInfo?.isThirdPartyPage || this.props?.target == '_blank') {
      return this.renderAnchorTag()
    }
    return this.renderLinkTag()
  }
}

export default PageLink
export { PageLink }
