import { i18nTranslate, getLiveEventStatus } from 'src/utils'
import { APPConfig } from 'config/appConfig'

function getTabKeys() {
  const isFlexibleSubscriptionEnabled =
    APPConfig?.getAppConfig()?.enableFlexibleSubscription === 'true'
  const enableLiveEvent = getLiveEventStatus()
  return [
    {
      index: 0,
      key: 'shipping',
      label:
        enableLiveEvent === 'true'
          ? i18nTranslate('printOrder.shippingAddress', 'Ship To')
          : i18nTranslate('checkout.selectShip', 'Shipping Address'),
      dataTestId: 'qa-ship-title',
    },
    {
      index: 1,
      key: 'pickup',
      label: i18nTranslate('checkout.pickupPerson', 'Pickup Person Name'),
      dataTestId: 'qa-submit-title',
    },
    {
      index: 2,
      key: 'shippingMethods',
      label: i18nTranslate('checkout.shippingMethodTitle', 'Shipping Method'),
      dataTestId: 'qa-shipping-method-title',
    },
    {
      index: 3,
      key: 'CouponCode',
      label: i18nTranslate('checkout.couponTitle', 'Coupon Code'),
      dataTestId: 'qa-coupon-title',
    },
    {
      index: 4,
      key: 'loyalty',
      label: i18nTranslate('nuskinEnjoyRewards.title', 'Nu Skin Rewards', {
        nameSpace: 'ssr-resource',
      }),
      dataTestId: 'qa-shipping-method-title',
    },
    {
      index: 5,
      key: 'storecredit',
      label: i18nTranslate('checkout.StoreCreditTitle', 'Store Credits'),
      dataTestId: 'qa-store-credit-title',
    },
    {
      index: 6,
      key: 'payment',
      label: i18nTranslate(
        'checkout.paymentTitle',
        'Billing and Payment Method'
      ),
      dataTestId: 'qa-pay-title',
    },
    {
      index: 7,
      key: 'subscription',
      label: isFlexibleSubscriptionEnabled
        ? i18nTranslate(
            'checkout.subscriptionAgreementTitle',
            'Subscription Agreement'
          )
        : i18nTranslate('checkout.subscriptionTitle', 'Subscription'),
      dataTestId: 'qa-pay-title',
    },
  ]
}

const flowPaths = [
  'shipping',
  'pickup',
  'shippingMethods',
  'CouponCode',
  'loyalty',
  'storecredit',
  'payment',
  'subscription',
]

const flowSkipPaths = {
  pickUpOnly: ['shipping', 'shippingMethods'],
  shipmentOnly: ['pickup'],
  itemLevelAddress: ['shippingMethods'],
  hideLoyalty: ['loyalty'],
  hideStoreCredit: ['storecredit'],
  hideCouponCode: ['CouponCode'],
  hideSubscription: ['subscription'],
  zeroCheckoutSkipWithoutPayment: [
    'loyalty',
    'CouponCode',
    'storecredit',
    'payment',
  ],
  zeroCheckoutSkipWithPayment: ['loyalty', 'CouponCode'],
  isCouponFullPayment: ['loyalty', 'storecredit', 'payment'],
  isLoyaltyOrCouponFullPayment: ['storecredit', 'payment'],
  isLoyaltyOrCouponOrStoreCreditsFullPayment: ['payment'],
  isCouponFullPaymentWithSubscription: ['loyalty', 'storecredit'],
  isLoyaltyOrCouponFullPaymentWithSubscription: ['storecredit'],
  enableLiveEvent: ['shippingMethods', 'CouponCode'],
}

export const loyaltyRewardsFormData = {
  dataTestId: 'loyalty-rewards',
  offTitle: i18nTranslate(
    'account.loyaltyOffTitle',
    'Would you like to Opt In to Nu Skin Rewards program?'
  ),
  onTitle: i18nTranslate(
    'account.loyaltyOnTitle',
    'You have now Opted in to Nu Skin Rewards program!'
  ),
  optOutTxt: i18nTranslate(
    'account.loyaltyoptOutTxt',
    'You are opted out of the Nu Skin Rewards Program!'
  ),
  optInTxt: i18nTranslate(
    'account.loyaltyoptOffTxt',
    'Please move to My Profile/Rewards & Credits page if you would like to Opt Out.'
  ),
  brandAffiliateUserTxt: i18nTranslate(
    'account.loyaltybrandAffiliateUserTxt',
    'Nu Skin Rewards Program is exclusively for Retail Customers and Members!'
  ),
}

export { getTabKeys, flowPaths, flowSkipPaths }

// For future reference kept copy
// optOutTxt: i18nTranslate(
//   'account.loyaltyoptOutTxt',
//   'Yes, I would like to Opt-in !'
// ),
