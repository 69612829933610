import React from 'react'
import {
  i18nTranslate,
  isExpressCheckout,
  isNonEventOrEventWithSub,
} from 'src/utils'
import { productDeps } from 'src/deps'
import Subscriptions from 'src/images/subscriptions-icon.png'
import Frame from 'src/images/Frame.png'
import { IS_BROWSER, replaceLocaleInURL, getLiveEventStatus } from 'src/utils'
import { Image } from 'react-bootstrap'
import { LearnMoreSubscriptionModal } from 'src/views/components/SubscriptionComponents/LearnMoreSubscriptionModal'
import './styles.scss'
import { EVENT_REFUND_POLICY_URL } from 'config/appConfig'

class ProductRefundPolicy extends React.Component {
  nameSpace = 'ssr-resource'
  enableLiveEvent = getLiveEventStatus()
  constructor(props) {
    super(props)

    this.state = {
      showLearnMoreSubsModal: false,
    }
  }

  toggleLearnMoreSubsModal = () => {
    this.setState(prevState => ({
      showLearnMoreSubsModal: !prevState.showLearnMoreSubsModal,
    }))
  }

  getHelpLinkWithLocale = () => {
    const subsHelpLink =
      'https://www.nuskin.com/content/nuskin/<locale>/corporate/help/customer-service.html'
    const link = replaceLocaleInURL({ url: subsHelpLink })
    if (IS_BROWSER) {
      window.open(link)
    }
  }

  getLearnMoreLink = () => {
    const isToEnableSubscription = productDeps.isToEnableSubscription(
      this.props
    )

    if (
      !isExpressCheckout() &&
      isToEnableSubscription &&
      isNonEventOrEventWithSub()
    ) {
      return (
        <>
          <div className="learn-subscription d-flex align-items-center mb-3">
            <Image
              src={Subscriptions}
              alt="Subscriptions icon"
              data-testid="qa-pdp-subscriptions-icon"
            />
            <p className="paragraph-m mb-0">
              {i18nTranslate('pdp.subscriptionMsg', 'Learn more about', {
                nameSpace: this.nameSpace,
              })}
              <span
                role="button"
                className="subscription-text"
                onClick={this.toggleLearnMoreSubsModal}
                onKeyPress={this.toggleLearnMoreSubsModal}
                data-testid="qa-pdp-subscriptions-text">
                <a tabIndex={0}>
                  <strong>
                    {i18nTranslate('pdp.subscriptionText', 'Subscriptions', {
                      nameSpace: this.nameSpace,
                    })}
                  </strong>
                </a>
              </span>
            </p>
          </div>
          <LearnMoreSubscriptionModal
            show={this.state.showLearnMoreSubsModal}
            onHide={this.toggleLearnMoreSubsModal}
            subsFAQ={this.getHelpLinkWithLocale}
          />
        </>
      )
    }
    return <></>
  }

  render() {
    const product = this.props?.product || {}

    let refundPolicy = product.refund || '{}'
    let refundText = {}

    try {
      refundText = JSON.parse(refundPolicy)
    } catch (e) {
      console.log('Data parse error::: ProductRefundPolicy' + e)
    }

    let refundTextMessage = refundText?.message?.replace('{linkText}', '')

    return (
      <div
        className={`refund-details ${
          this.enableLiveEvent === 'true' ? 'mt-2' : ''
        }`}>
        {refundTextMessage && (
          <div className="refund-text">
            <Image
              src={Frame}
              alt="refund-icon"
              data-testid="qa-pdp-refund-icon"
            />
            <p className="paragraph-m">
              {i18nTranslate('pdp.refundtextmsg', refundTextMessage, {
                nameSpace: this.nameSpace,
              })}
              <a
                href={
                  this.enableLiveEvent === 'true'
                    ? EVENT_REFUND_POLICY_URL
                    : refundText?.linkUrl
                }
                target="_blank"
                data-testid="qa-pdp-refund-policy">
                {i18nTranslate('pdp.refundtext', refundText?.linkText, {
                  nameSpace: this.nameSpace,
                })}
              </a>
            </p>
          </div>
        )}
        {this.getLearnMoreLink()}
      </div>
    )
  }
}

export { ProductRefundPolicy }
export default ProductRefundPolicy
