import React from 'react'
import { Form } from 'react-bootstrap'
import { MdPlace as StoreLocationIcon } from 'react-icons/md'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { currencyFormat } from 'src/utils'
import { storePickupState, productState } from 'src/views/components'
import './styles.scss'

@observer
class StorePickUpOption extends React.Component {
  handlePickupChange = e => {
    const pickUpMethod = e.currentTarget.value
    productState.selectPickupType = pickUpMethod
  }

  render() {
    const { productId, skuId, className } = this.props
    const isProdAvailInStore =
      storePickupState.storePickupProducts.hasOwnProperty(skuId)

    return (
      <div className={`store-pickup-wrapper mt-4 p-4 border ${className}`}>
        <div>
          <Form.Check
            type="radio"
            id="ship-it"
            label={i18nTranslate('bopis.shipIt', 'SHIP IT', {
              nameSpace: 'ssr-resource',
            })}
            className="ship-it mr-2 pl-0"
            onChange={this.handlePickupChange}
            checked={productState.selectPickupType === 'ship-it' ? true : ''}
            value="ship-it"
          />
          <div>
            {i18nTranslate(
              'bopis.freeShipping',
              'Free Shipping on Orders over',
              {
                nameSpace: 'ssr-resource',
              }
            )}{' '}
            {currencyFormat({ price: '75' })}
          </div>
        </div>

        <div className="border-top mt-4 pt-4 pick-up-store-wrapper">
          <Form.Check
            type="radio"
            id="pick-in-store"
            label={i18nTranslate('bopis.inStore', 'PICK IT UP IN-STORE', {
              nameSpace: 'ssr-resource',
            })}
            className="pick-in-store-input mr-2 pl-0"
            value="pick-in-store"
            onChange={this.handlePickupChange}
            checked={
              productState.selectPickupType === 'pick-in-store' ? true : ''
            }
          />
          <div
            className={`pickup-product-availability ${
              isProdAvailInStore ? 'available' : ''
            }`}>
            {isProdAvailInStore
              ? i18nTranslate(
                  'bopis.storeFound',
                  'Got it! Available in-store at:',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )
              : i18nTranslate(
                  'bopis.checkAvailable',
                  'Select a store to check availability',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )}
          </div>
          <div className="d-flex">
            {isProdAvailInStore && (
              <>
                <StoreLocationIcon className="store-map-icon mr-2" />
                <span className="store-name">
                  {storePickupState.storePickupProducts[skuId]}
                </span>
              </>
            )}
            <span
              className={`storepickup-label ${
                isProdAvailInStore ? 'pl-2' : ''
              }`}
              onClick={() =>
                storePickupState.toggleStorePickupModal(skuId, productId)
              }>
              {isProdAvailInStore
                ? i18nTranslate('bopis.change', 'Change', {
                    nameSpace: 'ssr-resource',
                  })
                : i18nTranslate('bopis.selectStore', 'Select a Store', {
                    nameSpace: 'ssr-resource',
                  })}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export { StorePickUpOption }
