import React from 'react'
import { formatPrice } from './deps'
import { Col } from 'react-bootstrap'
import isObject from 'lodash/isObject'
import { KlarnaMessage } from 'src/views/components'
import { productState } from '../BaseProductComponent'
import { PayLaterMessage, isPaylaterMessageRequired } from '../PayPal'
import { APPConfig } from 'config/appConfig'
import { observer } from 'mobx-react'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import './styles.scss'
import { isExpressCheckout } from 'src/utils'

@observer
class CustomPrice extends React.Component {
  getPrice = (custompriceValue = 0) => {
    const formattedPrice = formatPrice({
      price: custompriceValue,
    })
    return formattedPrice || 0
  }

  renderPrice = props => {
    const {
      className = '',
      price = '',
      discountPrice = '',
      dataTestId = '',
      Label = '',
      checkoutPage = false,
      isDiscount = false,
      isCurrencySymbol = false,
      title = '',
    } = props
    let isShowStrikeThroughPrice = true
    const isPriceavaiable = isObject(price)
    if (isPriceavaiable) {
      return <div />
    }
    const divStyle = checkoutPage ? 'justify-content-center' : ''
    const formattedCustomPrice = isCurrencySymbol
      ? price
      : price?.replace?.('$', '')
    const formattedDiscountCustomPrice =
      isCurrencySymbol || !isDiscount
        ? discountPrice
        : discountPrice.replace('$', '')
    const strikeThrough = isDiscount ? 'strikeThrough' : ''
    let regPrice = ''
    let disPrice = ''
    try {
      if (formattedCustomPrice) {
        regPrice = parseFloat(formattedCustomPrice.replace?.(/[$,,]/g, ''))
      }
      if (formattedDiscountCustomPrice) {
        disPrice = parseFloat(
          formattedDiscountCustomPrice.replace?.(/[$,,]/g, '')
        )
      }

      if (isDiscount && regPrice && disPrice && regPrice < disPrice) {
        isShowStrikeThroughPrice = false
        {
          /* https://nuskin.atlassian.net/browse/CX12-8076 */
          /* negative discount (negative promotion) */
        }
      }
    } catch (e) {
      console.log(
        'Error while comparing regPrice and disPrice for -ve discount scenario',
        e
      )
    }
    const handleExpressCheckout = isExpressCheckout()
    let marginClassNames =
      this.props.title === 'order_overview'
        ? 'mt-1'
        : dataTestId !== 'qa-pdp-price'
        ? 'mt-3'
        : ''
    if (handleExpressCheckout) {
      marginClassNames = ''
    }
    let priceColumnWrap = ''
    // let alignPriceLeft = ''
    let priceSpacing = !isExpressCheckout() ? 'pr-2' : ''
    // if (IS_BROWSER) {
    //   if (window.location.pathname.includes(pageNames.catalog)) {
    //     priceColumnWrap = 'd-flex flex-column'
    //     alignPriceLeft = 'd-flex justify-content-lg-start'
    //     priceSpacing = ''
    //   }
    // }
    if (
      (this.props?.type === 'bundle' || this.props?.type === 'kit') &&
      (this.props?.isFromPDP || this.props?.isModalFromPDP) &&
      isDiscount
    ) {
      isShowStrikeThroughPrice = false
      // hide strikeout price for pdp bundle and kit
      // hide strikeout price for pdp bundle and kit - subscriptionModal
      // subscription modal in cart should not be affected
      // CX12-10498, CX12-8898
    }
    const discountJSX = (
      <span
        className={`right-span text-right discount-price ${className} ${
          Label === 'SV' ? '' : 'pr-2'
        }`}
        data-testid="qa-product-sale-price">
        {formattedDiscountCustomPrice}
      </span>
    )

    const { isToShowNewPriceStyling = false } = this.props
    return (
      <Col
        lg={12}
        className={`p-0 d-flex ${marginClassNames} price-wrapper plp-price-md pv-right-align ${
          this.props.isPVCV ? ' pvstyle ' : 'sales '
        } ${title === 'gwp_offer' ? 'gwp-offer-price' : ''} ${divStyle}`}
        data-testid={dataTestId}>
        <div className="price-section d-flex flex-wrap align-items-center">
          {this.props.title != 'order_overview' && Label && (
            <>
              <div
                className={`${!this.props.isPVCV ? ' left-span ' : ' '} ${
                  isToShowNewPriceStyling && this.props.isPVCV
                    ? 'price-title-key'
                    : ''
                }`}>
                {Label}
              </div>
              {this.props.enableNewPDPStyle || isToShowNewPriceStyling ? (
                <div className={`${!this.props.isPVCV ? ' span-colon ' : ' '}`}>
                  {Label != '' && ':'}&nbsp;
                </div>
              ) : (
                <div className={`${!this.props.isPVCV ? ' span-colon ' : ' '}`}>
                  &nbsp;{Label != '' && ':'}&nbsp;
                </div>
              )}
            </>
          )}
          <div
            className={`mini-cart-price ${priceColumnWrap} ${
              title === 'gwp_offer' ? 'd-flex' : ''
            } ${handleExpressCheckout ? 'flex-column' : ''}`}>
            {isDiscount && discountJSX}
            {isShowStrikeThroughPrice && (
              <span
                className={`right-span ${
                  Label === 'SV' ? '' : priceSpacing
                } ${className} ${strikeThrough} ${
                  isDiscount && Label === 'Price'
                    ? `original mini-cart-price-font-size ${priceSpacing}`
                    : ' '
                }`}
                data-testid="qa-product-reg-price"
                aria-hidden={isDiscount ? true : false}>
                {isDiscount && dataTestId === 'qa-pdp-pvprice'
                  ? ''
                  : formattedCustomPrice}
              </span>
            )}
          </div>
        </div>
      </Col>
    )
  }
  renderKlarnaMessageForPDPUnitPrice = (finalPrice = '') => {
    /* below unit price in pdp */
    /* for both normal and bundle,kit */

    return (
      <div className="klarna-pdp-bundle-unit-price-wrapper">
        <KlarnaMessage
          price={finalPrice}
          id={'klarna-pdp-message-bundle-unit-price'}
          data-key={'credit-promotion-badge'}
        />
      </div>
    )
  }

  renderPaylaterMessage = (finalPrice = '') => {
    const { userCountry } = this.props
    /* below unit price in pdp */
    /* for both normal and bundle,kit */

    return (
      isPaylaterMessageRequired(userCountry) &&
      finalPrice &&
      finalPrice > 0 && (
        <div className={'paylater-bundle-unit-price-wrapper'}>
          <PayLaterMessage
            amount={finalPrice?.toString()}
            key={'paylater-bundle-unit-price-wrapper'}
            id={'paylater-bundle-unit-price-wrapper'}
          />
        </div>
      )
    )
  }

  renderDirectPrice = () => {
    const {
      custompriceValue = 0,
      custompriceLabel = '',
      dataTestId = '',
      checkoutPage = false,
      customDiscountPriceValue = '0',
      isCurrencySymbol = false,
      priceFreePromotion = false,
      showOfferPriceInRed = false,
      title = '',
      currencySymbol = '$',
    } = this.props
    const formattedPrice = this.getPrice(custompriceValue)
    const formattedDiscountPrice = priceFreePromotion
      ? customDiscountPriceValue == 0
        ? '$0.00'
        : ''
      : this.getPrice(customDiscountPriceValue)

    const productPrice =
      formattedDiscountPrice?.replace?.(currencySymbol, '') || ''
    const finalPrice = !this.props?.isBundle
      ? productState?.pdpPromotionResponse?.totalValue?.priceAfterDiscount
      : productPrice
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    return (
      <>
        {this.renderPrice({
          className: 'sale',
          price: formattedPrice,
          discountPrice: formattedDiscountPrice,
          Label: custompriceLabel,
          dataTestId: dataTestId,
          checkoutPage: checkoutPage,
          isDiscount: priceFreePromotion
            ? true
            : (customDiscountPriceValue != '0' ||
                customDiscountPriceValue != 0) &&
              custompriceValue != customDiscountPriceValue
            ? true
            : false,
          isCurrencySymbol: isCurrencySymbol,
          showOfferPriceInRed: showOfferPriceInRed,
          title: title,
        })}
        {!enableNewPDPStyle &&
          this.props.shouldShowKlarnaMessage &&
          this.renderKlarnaMessageForPDPUnitPrice(finalPrice)}
        {!enableNewPDPStyle &&
          this.props.shouldShowPaylaterMessage &&
          this.renderPaylaterMessage(finalPrice)}
      </>
    )
  }

  render() {
    return (
      <div className={this.props.classStyles}>{this.renderDirectPrice()}</div>
    )
  }
}

export { CustomPrice }
export default CustomPrice
