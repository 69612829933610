import { i18nTranslate } from 'src/utils'
import { observable, toJS, action } from 'mobx'
import uniqBy from 'lodash/uniqBy'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import { cartContainer, orderHistoryContainer } from 'src/models'
import { CommonContainer } from 'src/models/Common'
import { toastState } from 'src/views/components'
import { getCategoryList } from 'src/views/components/OutfitComponents/fixture'
import { customerContainer } from '../Customer'
import listsContainer from './Lists'
import { transformRecommendation } from './outfitsTransform'

const listType = 'outfits'
const url =
  'https://us-central1-db-inspiredstylist.cloudfunctions.net/Get_BestBets_closet'

/**
 * Sets a toast message using i18n and the toastState.
 * @param {string} key - The i18n key for the message text
 * @param {string} defaultValue - The default message text if no i18n key found
 * @param {string} responseMessage - An alternative message text to use instead of the i18n message
 */
function setToastMessage(key = '', defaultValue = '', responseMessage = '') {
  const message = i18nTranslate(key, defaultValue)
  toastState.setToastMessage(responseMessage || message)
}

/**
 * Get outfit item props
 *
 * @param {Object} props - Outfit item properties
 * @param {string} [props.key] - Key
 * @param {string} [props.name] - Name
 * @param {string} [props.properties] - Properties
 * @param {string} [props.id] - Id
 *
 * @returns {Object} Outfit item props object
 */
function getOutFitItemProps(props = {}) {
  const { key = '', name = '', properties = '', id = '' } = props

  return {
    key,
    name,
    ...(id && { id }),
    properties,
  }
}

/**
 * OutFitsContainer class that extends CommonContainer.
 * Used for managing outfit lists and related state.
 */
class OutFitsContainer extends CommonContainer {
  @observable outFitsList = []
  @observable allWishListProducts = []
  @observable currentOutFit = {}
  categoryList = getCategoryList()
  @observable orderListItems = categoryList.reduce((value, category) => {
    return { ...value, [category.categoryId]: [] }
  }, {})

  @observable listShowType = ''
  @observable searchOutfitTerm = ''
  @observable recommendedProducts = []
  currentSlideIndex = 0
  getSingleRecommendation = false

  /**
   * Fetch all lists from the server and update local state.
   * Async function that calls the listsContainer getAllLists method
   * and handles the response to update local state.
   */
  makeGetAllList = async () => {
    await listsContainer.getAllLists().then(listResponse => {
      this.updateFromGetAllList(listResponse.lists)
    })
  }

  @action
  /**
   * Sets a new outfit by updating state and adding a new outfit object to the outfits list.
   *
   * Sets the list type to 'newOutFit', sets the current slide index to the new length of the outfits list,
   * pushes a new outfit object with default values to the outfits list, sets the current outfit to this new outfit,
   * and calls the callback function if provided, passing the index of the new outfit.
   *
   * @param {Function} [callback] - Optional callback function to call with the index of the new outfit
   */
  setNewOutfit = callback => {
    this.setListType('newOutFit')
    this.currentSlideIndex = this.outFitsList.length
    this.outFitsList.push({
      name: i18nTranslate('outfit.new', 'New Outfit'),
      outfitType: 'new',
      listItems: [],
    })
    this.currentOutFit = this.outFitsList[this.outFitsList.length - 1]
    isFunction(callback) && callback(this.currentSlideIndex)
  }

  @action
  /**
   * Removes the 'new' outfit from the outfits list if present.
   */
  removeNewOutfit = () => {
    this.outFitsList = this.outFitsList.filter(
      list => list.outfitType !== 'new'
    )
  }

  @action
  setListType = (type = '', removeNewOutfit = true) => {
    this.listShowType = type
    if (removeNewOutfit) {
      this.removeNewOutfit()
    }
  }

  @action
  /**
   * Sets the current outfit based on the provided outfit ID.
   * @param {string} outfitId - The ID of the outfit to set as the current outfit
   * Finds the outfit object in the outfits list with the matching ID, converts it to a plain JS object using toJS(),
   * and sets it as the current outfit. Also removes any 'new' outfits from the list.
   */
  setCurrentOutFit = (outfitId = '') => {
    this.currentOutFit = toJS(
      this.outFitsList.find(list => list.id === outfitId)
    )
    this.removeNewOutfit()
  }

  @action
  /**
   * Sets the current outfit to the outfit object at the provided index in the outfits list.
   * Converts the outfit to a plain JS object using toJS() before setting as current outfit.
   *
   * @param {number} index - Index of the outfit in the outfits list to set as current outfit
   */
  setCurrentOutFitByIndex = index => {
    this.currentOutFit = toJS(this.outFitsList[index])
  }

  @action
  /**
   * Updates the current outfit based on the provided type.
   * If type is 'delete', filters out any new outfit items that are not recommended.
   * If deleting and no items left, deletes the outfit.
   * Otherwise, updates the current outfit with the remaining props and items.
   */
  updateCurrentOutFit = async (type = '') => {
    const outFit = this.outFitsList.find(
      list => list.name === this.currentOutFit.name
    )
    const newOutfitItems =
      type === 'delete'
        ? this.currentOutFit.listItems.filter(
            item =>
              (item?.outFitItem || '') === 'new' &&
              (item?.outfitItemType || '') !== 'recommended'
          ) || []
        : []

    if (
      type === 'delete' &&
      newOutfitItems.length === 0 &&
      (outFit?.listItems || []).length === 0
    ) {
      await this.deleteOutfit(this.currentOutFit.id)
      // this.setListType('allOutFit')
      this.currentOutFit.listItems = []
      return
    }

    const { listItems = [], ...remainingProps } = outFit
    this.currentOutFit = {
      ...remainingProps,
      ...(newOutfitItems.length > 0 && { isOutFitsEdited: true }),
      listItems: [...listItems, ...newOutfitItems],
    }
  }

  /**
   * Updates the allWishListProducts property by getting all wishlist items across wishlists,
   * converting them to a consistent format, removing duplicates, and setting on the store.
   *
   * Gets all wishlist items by mapping over wishLists and calling updateAllWishListProducts on each.
   * Waits for all promises to complete, flattens the returned list item arrays,
   * removes duplicates by key, and sets on allWishListProducts.
   */
  updateWishListProducts = async () => {
    const promises = this.wishLists.map(this.updateAllWishListProducts)
    const allProducts = await Promise.all(promises)
    const wishProducts = uniqBy(allProducts.flat(), list => list.key)
    this.allWishListProducts = wishProducts
  }

  /**
   * Updates the allWishListProducts property with all wishlist items
   * from the provided wishlist. Calls getListItems to get the wishlist items,
   * converts them to a consistent format, and returns the formatted list.
   *
   * @param {Object} list - The wishlist to get items for
   * @returns {Array} The formatted wishlist items
   */
  updateAllWishListProducts = async (list = {}) => {
    const response = await listsContainer.getListItems({ listId: list.listId })
    if ((response?.listItems || []).length < 1) {
      return []
    }
    return response.listItems.map(product => {
      const {
        key = '',
        name = '',
        id = '',
        properties = {},
        sku = { properties: {} },
      } = product
      return {
        key,
        id: id,
        name,
        properties: {
          productId: properties.productId,
          imageURL: sku.properties.imageURL,
          type: sku.properties.fit,
        },
      }
    })
  }

  @action
  /**
   * Updates the outfits list and wishlist products from the response of a call to getAllLists.
   * Handles parsing outfits and wishlists into separate lists, and extracting wishlist products.
   * @param {Array} allListsResponse - Response from a getAllLists call
   * @param {boolean} isSearchOutfit - Whether this was called from searchOutfitList
   */
  updateFromGetAllList = async (
    allListsResponse = [],
    isSearchOutfit = false
  ) => {
    const outfits = []
    const wishlists = []
    const wishListProducts = []
    if (allListsResponse.length > 0) {
      allListsResponse.forEach(outfitList => {
        if (outfitList.type === 'WISHLIST') {
          if (
            outfitList.properties &&
            outfitList.properties.type === 'outfits'
          ) {
            outfits.push(outfitList)
          } else if (!isSearchOutfit) {
            wishlists.push(outfitList)
          }
        }
      })
      this.outFitsList = outfits
      if (!isSearchOutfit) {
        // this.wishLists = wishlists
        wishlists.forEach(list => {
          wishListProducts.push(...(list.listItems || []))
        })
        this.allWishListProducts = uniqBy(wishListProducts, list => list.key)
      }
    } else {
      if (isSearchOutfit) {
        this.outFitsList = []
      }
    }
  }

  /**
   * Searches the outfit list for outfits matching the given search term.
   *
   * @param {string} searchTerm - Search term to match outfit names against
   */
  searchOutfitList = async (searchTerm = '') => {
    const searchParams = {
      type: 'WISHLIST',
      ...(searchTerm && { name: searchTerm }),
    }
    await listsContainer.getAllLists(searchParams).then(listResponse => {
      this.updateFromGetAllList(listResponse.lists, true)
    })
  }

  /**
   * Creates a new outfit.
   *
   * @param {Object} props - Properties for creating the outfit
   * @param {string} [props.name=''] - Name of the outfit
   * @param {Object[]} [props.listItems=[]] - List items to add to the outfit
   * @returns {Promise<boolean>} - Promise resolving to true if outfit was created successfully, false otherwise
   */
  createOutFit = async (props = {}) => {
    const { name = '', listItems = [] } = props
    const properties = {
      type: listType,
    }
    const response = await listsContainer.createList({
      name,
      properties,
      listItems: listItems.map(getOutFitItemProps),
    })
    if (this.isSuccessResponse(response)) {
      await this.makeGetAllList()
      this.updateCurrentOutFit('create')
      setToastMessage(
        'wishList.createOutFitSuccess',
        'Outfit created successfully'
      )
      return true
    } else {
      setToastMessage('wishList.createOutFitFailure', 'Unable to create Outfit')
      return false
    }
  }

  /**
   * Updates the name of the given outfit.
   *
   * @param {Object} outfit - Outfit object containing name and listId
   * @param {string} outfit.name - New name for the outfit
   * @param {string} outfit.listId - ID of the outfit list
   * @param {boolean} showToast - Whether to show a toast message on success/failure
   * @returns {Promise} Promise resolving to the API response
   */
  updateOutfit = async (outfit = {}, showToast = false) => {
    const { name = '', listId = '' } = outfit
    const listData = {
      name,
    }
    const response = await listsContainer.updateList({ listId, listData })
    if (this.isSuccessResponse(response)) {
      await this.makeGetAllList()
      this.updateCurrentOutFit('update')
      showToast &&
        setToastMessage(
          'outfit.updateSuccess',
          'Outfit name updated successfully'
        )
    } else {
      const code = response.code
      if (code === 'EOLILC0001') {
        showToast &&
          setToastMessage(
            'outfit.sameNameFailure',
            'Outfit name already exists'
          )
        return
      }
      setToastMessage('outfit.updateFailure', 'Unable to update outfit name')
    }
    return response
  }

  /**
   * Adds list items to the current outfit.
   *
   * @param {Object[]} listItems - Array of outfit item objects to add
   * @returns {Promise<boolean>} Promise resolving to true if successful, false otherwise
   */
  addItemToOutFit = async (listItems = []) => {
    const items = listItems.map(getOutFitItemProps)
    const response = await listsContainer.multipleAddItemToList({
      items,
      listId: this.currentOutFit.listId,
    })
    if (this.isSuccessResponse(response)) {
      await this.makeGetAllList()
      this.updateCurrentOutFit('add')
      setToastMessage('wishList.addOutFitSuccess', 'Outfit saved successfully')
      return true
    } else {
      const code = response.code
      if (code === 'EOLISIT00001') {
        setToastMessage('outfit.sameItemFailure', 'Outfit item already exists')
        return
      }
      setToastMessage(
        'wishList.addOutFitSuccess',
        'Outfit cannot be saved, try again later'
      )
      return false
    }
  }

  /**
   * Deletes an outfit item from the current outfit.
   *
   * @param {string} listId - ID of the outfit list
   * @param {string} listItemId - ID of the outfit list item to delete
   */
  deleteOutFitItem = async (listId = '', listItemId = '') => {
    const response = await listsContainer.deleteItemFromList({
      listId,
      listItemId,
    })
    if (this.isSuccessResponse(response)) {
      await this.makeGetAllList()
      await this.updateCurrentOutFit('delete')
      setToastMessage(
        'wishList.addOutFitSuccess',
        'Outfit item deleted successfully'
      )
    } else {
      setToastMessage(
        'wishList.addOutFitSuccess',
        'Outfit item cannot be deleted, try again later'
      )
    }
  }

  /**
   * Deletes an outfit from the user's outfits list.
   *
   * @param {string} listId - ID of the outfit list to delete
   * @param {boolean} showToast - Whether to show a toast message on success/failure
   */
  deleteOutfit = async (listId = '', showToast = '') => {
    const response = await listsContainer.deleteList(listId)
    if (this.isSuccessResponse(response)) {
      this.makeGetAllList()
      showToast &&
        setToastMessage(
          'wishList.deleteListSuccess',
          'Outfit deleted successfully'
        )
    } else {
      showToast &&
        setToastMessage(
          'wishList.deleteOutfitFailure',
          'Unable to delete Outfit'
        )
    }
  }

  /**
   * Adds an outfit item to the cart.
   *
   * @param {Object} outFitItem - The outfit item object to add to cart
   * @param {string} outFitId - The ID of the outfit that contains the item
   */
  addToCart = async (outFitItem = {}, outFitId = '') => {
    const { productId } = outFitItem.properties
    const cartDetails = {
      cartArray: [{ productId, skuId: outFitItem.key, quantity: 1 }],
    }
    const response = await cartContainer.addToCart(cartDetails)
    if (this.isSuccessResponse(response)) {
      // update the outfit item
      const { properties } = outFitItem
      const { ...remainingProps } = properties
      listsContainer.updateListItem({
        listId: outFitId,
        listItemId: outFitItem.id,
        properties: { ...remainingProps },
      })
      await this.makeGetAllList()
      this.updateCurrentOutFit('cart')
    }
  }

  /**
   * Adds all items from the current outfit to the cart.
   * Maps over the outfit items to build a cartArray with productId, skuId and quantity.
   * Calls addToCart API with the cartArray.
   * On success response, updates the outfit items.
   */
  addOutfitToCart = async () => {
    const cartArray = this.currentOutFit.listItems.map(item => {
      const { productId } = item.properties
      return { productId, skuId: item.key, quantity: 1 }
    })

    const response = await cartContainer.addToCart({ cartArray })
    if (this.isSuccessResponse(response)) {
      // update the outfit item
    }
  }

  /**
   * Fetches order history data for the logged in customer, processes it to extract
   * relevant outfit item data, groups the items by category/fit type, de-duplicates
   * items within each group, and stores the processed data in orderListItems property.
   *
   * @param {Object} props - Function parameters
   * @param {string} props.filterParams - Filters to apply to order history request
   * @param {number} props.page - Page number for pagination
   */
  getOrders = async (props = {}) => {
    const { filterParams = '', page = 1 } = props
    const params = {
      page: page,
      size: 100,
    }
    if (filterParams !== '') {
      params.filter = filterParams
    }
    const myOrders = await orderHistoryContainer.getAllOrders('', params, false)
    if (myOrders && myOrders.length) {
      const orders = []
      myOrders.forEach(order => {
        const orderItems = order?.orderInfo?.orderDetails?.orderItems || {}
        const orderSkusInfo = orderItems.map(orderItem => {
          const skuProperties = orderItem?.item?.itemInfo?.sku || {}
          const { skuId = '', productId = '', skuInfo = {} } = skuProperties
          const { image, fit, name = '' } = skuInfo
          return {
            key: skuId,
            id: skuId,
            name,
            properties: {
              productId,
              imageURL: image,
              type: fit,
            },
          }
        })
        orders.push(...orderSkusInfo)
      })
      const allItems = page ? {} : this.orderListItems
      this.categoryList.forEach(category => {
        const groupedItems = orders.filter(
          outfitItem => category.categoryId == outfitItem.properties.type
        )
        allItems[category.categoryId] = [
          ...(groupedItems || []),
          ...(allItems[category.categoryId] || []),
        ]
      })
      Object.keys(allItems).forEach(key => {
        // let value = allItems[key]
        let skuIdkeyName = ['key']
        const filtered = allItems[key].filter(
          (
            s => o =>
              (k => !s.has(k) && s.add(k))(
                skuIdkeyName.map(k => o[k]).join('|')
              )
          )(new Set())
        )
        allItems[key] = filtered
      })
      this.orderListItems = allItems
    }
  }

  /**
   * Fetches product recommendations from the recommendation API.
   * Encodes the current outfit item SKU IDs to pass to the API.
   * Returns either a single recommendation matching the neededCategory if getSingleRecommendation is true,
   * or an array of 10 recommendations otherwise.
   */
  getRecommendedProducts = async (neededCategory = '') => {
    const skuIds = encodeURIComponent(
      JSON.stringify(
        this.currentOutFit.listItems.map(item => {
          return item.key
        })
      )
    )
    let requestOptions = {
      method: 'GET',
    }
    try {
      const response = await fetch(
        `${url}?CustomerId=${customerContainer.profileResponse.id}&SkuIds=${skuIds}&Recommendations=10`,
        requestOptions
      )
        .then(result => result.json())
        .then(result => {
          return result
        })
      if (this.getSingleRecommendation) {
        const recommendedProduct = response.SkuRecos.find(
          data => (data.fit || data.itemType) === neededCategory
        )
        if (!isEmpty(recommendedProduct)) {
          return transformRecommendation(recommendedProduct)
        }
      } else {
        const skus = response.SkuRecos
        const products =
          isArray(skus) && skus.length > 0
            ? skus.map(transformRecommendation)
            : []
        this.recommendedProducts = products
        return products
      }
    } catch (e) {
      return
    }
  }
}

const outFitsContainer = new OutFitsContainer()

export { outFitsContainer }
