import React from 'react'
import { i18nTranslate } from 'src/utils'
import { observer } from 'mobx-react'
import { ImageComponent, MarkdownComponent } from 'src/views/components'
import { Container } from 'react-bootstrap'
import isArray from 'lodash/isArray'
import { APPConfig } from 'config/appConfig'
import './styles.scss'

@observer
class ProductDisclaimers extends React.Component {
  nameSpace = 'ssr-resource'
  LinkRenderer = link => {
    return (
      <a href={link.href} target="_blank" rel="noreferrer">
        {link.children}
      </a>
    )
  }

  renderDisclaimerWarnings = props => {
    let { warnings = [] } = props || {}
    if (warnings && typeof warnings === 'string') {
      try {
        warnings = JSON.parse(warnings)
      } catch (e) {
        console.log('error while parsing DisclaimerWarnings')
      }
    }
    if (!isArray(warnings) || warnings?.length <= 0) {
      return <></>
    }

    const warningContent = warnings?.map((warning, idx) => {
      return (
        <div key={`warnings-${idx}`} className="py-1 disclaimer-warning">
          <div>
            <ImageComponent
              src={warning.icon?.url || ''}
              alt={warning.icon?.alt || ''}
            />
          </div>
          <div>
            <MarkdownComponent>
              {({ default: Markdown }) => (
                <Markdown components={{ a: this.LinkRenderer }}>
                  {warning.markdownText || warning.text || ''}
                </Markdown>
              )}
            </MarkdownComponent>
          </div>
        </div>
      )
    })
    return <>{warningContent}</>
  }

  render() {
    const product = this.props.product || {}
    let disclaimerWarnings = product?.disclaimerWarnings || []
    let productDisclaimer = []
    try {
      disclaimerWarnings =
        disclaimerWarnings.length > 0 ? JSON.parse(disclaimerWarnings) : []
      productDisclaimer = JSON.parse(product?.disclaimers || '[]')
    } catch (e) {
      console.log('unable to parse productDisclaimer')
    }
    const hasDisclaimer =
      disclaimerWarnings?.length > 0 ||
      // disclaimerWarnings?.icon ||
      // disclaimerWarnings?.text ||
      (productDisclaimer?.length && productDisclaimer?.length > 0)
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'

    return hasDisclaimer ? (
      <div className="product-accordion-wrapper disclaimer-section">
        <Container className="pdp-accordion-content-container">
          <div
            className={`${
              enableNewPDPStyle ? 'v2-disclaimer-heading' : 'disclaimer-heading'
            }`}>
            <span>
              {i18nTranslate('pdp.disclaimer', 'Disclaimer', {
                nameSpace: this.nameSpace,
              })}
            </span>
          </div>

          <div
            className={`${
              enableNewPDPStyle ? 'v2-disclaimer-text' : 'disclaimer-text'
            }`}>
            {productDisclaimer && productDisclaimer?.length > 0 && (
              <ul>
                {productDisclaimer.map((disclaimer, idx) => {
                  return <li key={`product-disclaimers${idx}`}>{disclaimer}</li>
                })}
              </ul>
            )}
          </div>
          {disclaimerWarnings &&
            this.renderDisclaimerWarnings({ warnings: disclaimerWarnings })}
          {/* {(disclaimerWarnings?.icon || disclaimerWarnings?.text) && (
            <div className="disclaimer-warning">
              <div>
                <Image
                  src={disclaimerWarnings?.icon?.url || ''}
                  alt={disclaimerWarnings?.icon?.alt || ''}
                />
              </div>
              <div>
                <p>{disclaimerWarnings?.text || ''}</p>
              </div>
            </div>
          )} */}
        </Container>
      </div>
    ) : (
      <></>
    )
  }
}

export { ProductDisclaimers }
