import React from 'react'
import { i18nTranslate } from 'src/utils'
import { observer } from 'mobx-react'
import {
  isBuyerUser,
  isLoyEnableInProf,
  isAllowedRewardTypeForOptIn,
} from 'src/views/pages/MyAccount/MyAccountProfile/LoyaltyForm/fixture'
import { customerContainer } from 'src/models'
import './styles.scss'

@observer
class ProductRedeemPointText extends React.Component {
  render() {
    const {
      selectedSku = {},
      pointRedeem = 'false',
      isBundle,
      isCollection,
    } = this.props
    const pointRedeemVal =
      isBundle || isCollection
        ? pointRedeem
        : selectedSku?.properties?.pointRedeem || 'false'
    const pointRedeemStringify =
      typeof pointRedeemVal === 'boolean'
        ? String(pointRedeem).toLowerCase()
        : pointRedeem
    if (pointRedeemStringify === 'true') {
      if (
        (isLoyEnableInProf() &&
          isAllowedRewardTypeForOptIn() &&
          customerContainer.isRegisterUser) ||
        (isBuyerUser() && isLoyEnableInProf())
      ) {
        return (
          <div
            data-testid="qa-product-earnPointTxt"
            className="product-earnPointTxt-wrapper d-flex flex-column mt-2">
            <span className="product-earnPointTxt font-weight-bolder text-success">
              {i18nTranslate(
                'pdp.pdpLoyaltyRedeemTxt',
                'Redeem with Nu Skin Rewards!',
                {
                  nameSpace: 'ssr-resource',
                }
              )}
            </span>
          </div>
        )
      }
      return <></>
    }
    return <></>
  }
}

export { ProductRedeemPointText }
