import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import isArray from 'lodash/isArray'
import { VARIANT_NODES, PLPProductsPerPage } from 'config/appConfig'
import {
  getLocaleCodeFromUrl,
  convertToBoolean,
  getLiveEventStatus,
} from 'src/utils'

/**
 * Builder class, used to construct catalog models.
 */
class Builder {
  // Sku options to choose
  VARIANT_NODES = VARIANT_NODES
  /* @variant_autoselect */

  // Get the sku list available
  /**
   * Transforms the SKU list by extracting the unique values for the given property key.
   *
   * It iterates through each SKU, gets the property value for the given key,
   * and adds it to the productOptionList array if it doesn't already exist.
   *
   * It also handles some special cases like setting the image url for 'color',
   * and checking for 'isSubscribable' flag.
   *
   * Returns the final transformed list of unique product option values.
   */
  transformSkuProperties = (skuList, key) => {
    const productOptionList = []
    if (skuList && skuList.length) {
      skuList.forEach(sku => {
        const propValue = sku?.properties?.[key] || ''
        const isSubscribable = sku?.properties?.issubscription || false
        const identifier = sku?.identifier || ''
        /* @variant labelchange */
        const variantForUrl = sku?.properties?.slug || sku?.identifier || ''

        if (propValue) {
          const imageURL = sku?.properties?.imageURL
          const item = {
            identifier: identifier,
            label: propValue,
            isSubscribable,
            variantForUrl,
          }
          if (key.toLowerCase() === 'color') {
            item.url = `${imageURL}`
          }
          if (productOptionList.length) {
            let flag = false
            productOptionList.forEach(list => {
              if (list.label.toLowerCase() === item.label.toLowerCase()) {
                if (item.isSubscribable === 'true') {
                  list.isSubscribable = item.isSubscribable
                }
                flag = true
              }
            })
            flag === false && productOptionList.push(item)
          } else {
            productOptionList.push(item)
          }
        }
      })
      return productOptionList
    } else if (skuList.properties) {
      if (skuList.properties[key]) {
        return [
          {
            label: skuList.properties[key],
            url: skuList.properties['imageURL'],
          },
        ]
      }
    }
  }
  /**
   * Gets the default selected SKU from a list of SKUs.
   *
   * It filters the SKUs where default is true and sku exists.
   * If any matches, returns that SKU.
   * Otherwise, returns the first SKU in the list if there are any SKUs.
   */
  getDefaultSelectedSku = skus => {
    const selectedSku = skus.filter(sku => {
      return sku.default === true && sku
    })
    if (selectedSku.length > 0) {
      return selectedSku
    } else if (skus.length > 0) {
      return [skus[0]]
    }
  }

  /**
   * Gets the unique product options from a list of SKUs.
   *
   * Maps through the variant nodes, transforms the SKU properties into option values,
   * and returns the unique options with values.
   *
   * @param {Array} skuList - The list of SKUs
   * @returns {Array} The unique product options
   */
  getUniqueProductOptions = skuList => {
    /* @variant_autoselect */
    const options = this.VARIANT_NODES.map(key => {
      const values = this.transformSkuProperties(skuList, key)

      if (values && values.length)
        return {
          type: key,
          values,
        }
    })
    return options.filter(option => option)
  }
  /**
   * Gets the upsell products from the API response.
   *
   * @param {Object} response - The API response object.
   * @returns {Array} The upsell products array.
   */
  getUpSellProducts = response => {
    return response?.upSellProducts || []
  }
  /**
   * Gets the cross-sell products from the API response.
   *
   * @param {Object} response - The API response object.
   * @returns {Array} The cross-sell products array.
   */
  getCrossSellProducts = response => {
    return response?.crossSellProducts || []
  }

  /**
   * Parses JSON data, catching any errors.
   *
   * @param {*} data - The data to parse as JSON
   * @returns {Object} The parsed JSON object, or an empty object if parsing fails
   */
  dataParser = data => {
    try {
      return JSON.parse(data)
    } catch (err) {
      return {}
    }
  }
  /**
   * Transforms the searched products response by filtering the SKUs
   * for the current market and returning the transformed products.
   *
   * @param {Object} response - The searched products API response
   * @returns {Object} The transformed products object
   */
  transformSearchedProducts = response => {
    let transformedProducts = {
      product: [],
    }
    if (response?.product) {
      response?.product?.forEach(item => {
        const filteredSkus = this.filterSkusFromMarket(item?.sku)
        transformedProducts.product.push({ ...item, sku: filteredSkus })
      })
    }

    return transformedProducts
  }
  /**
   * Filters SKUs based on the current market.
   *
   * @param {Array} skus - Array of SKU objects
   * @param {Object} props - Component props object
   * @returns {Array} Filtered array containing only SKUs for the current market
   */
  /**
   * Filters an array of SKUs to return only SKUs available in the current market.
   *
   * @param {Array} skus - Array of SKU objects
   * @param {Object} props - Component props object
   * @returns {Array} Filtered array containing only SKUs for the current market
   */
  filterSkusFromMarket = (skus, props) => {
    let currentMarket = props?.curLocaleFromUrl
    const enableLiveEvent = getLiveEventStatus()

    // For live event we are not filtering the SKUs based on the market
    if (convertToBoolean(enableLiveEvent)) {
      return skus
    }

    if (!currentMarket) {
      let defaultLocale = 'en_US'

      if (typeof STORE_CONFIG != 'undefined') {
        defaultLocale = STORE_CONFIG.activeLocale
      }
      currentMarket =
        getLocaleCodeFromUrl({
          defaultLocale,
        }) || ''
    }
    currentMarket = currentMarket?.split?.('_')?.[1]?.toLowerCase() || ''

    let filteredSkus = []
    if (skus && skus.length > 0) {
      filteredSkus = skus?.filter?.(sku => {
        const availableMarkets = sku?.properties?.market?.split?.(',') || []
        const marketMatches = availableMarkets.some(
          availableMarket =>
            availableMarket?.toLowerCase() == currentMarket?.toLowerCase()
        )
        return marketMatches
      })
    }
    return filteredSkus
  }

  /**
   * Transforms a product response by mapping over the response array and transforming each product.
   * If the product has SKUs, it filters the SKUs based on market and sets the default selected SKU.
   * If the product is a SKU kit, it just returns the product as-is without transforming.
   * Otherwise it returns the unmodified product.
   *
   * @param {Array} productResponse - Array of product response objects
   * @param {boolean} isSkuKit - Whether the product is a SKU Kit
   * @param {Object} props - Component props object
   * @returns {Array} Transformed array of product response objects
   */
  transformProductResponse = (productResponse, isSkuKit, props) => {
    return (
      productResponse &&
      productResponse.map(response => {
        if (response.sku) {
          const selectedSku = this.getDefaultSelectedSku(response.sku)
          const skus = this.filterSkusFromMarket(response?.sku, props)
          return {
            productOption: this.getUniqueProductOptions(response.sku),
            selectedSku: selectedSku ? selectedSku[0] : response,
            VARIANT_NODES: this.VARIANT_NODES,
            ...response,
            sku: skus,
          }
        } else if (isSkuKit) {
          return {
            productOption: this.getUniqueProductOptions(response),

            ...response,
          }
        } else {
          return response
        }
      })
    )
  }
  /**
   * Transforms the product response by handling different product types.
   * Gets the first product from the response.
   * Checks the product type and calls transformProductResponse
   * to transform products for different types like bundles, kits etc.
   * Adds upsell and cross sell products to the response.
   * Returns the transformed product response.
   */
  transformProduct(response, props) {
    const product = response?.product?.[0] || {}
    const productType = product?.type || ''

    response.product = this.transformProductResponse(
      response.product,
      null,
      props
    )
    switch (productType.toLowerCase()) {
      case 'fixedpricebundle':
        response.product[0].bundleMandatoryProducts =
          this.transformProductResponse(
            response.product[0].bundleMandatoryProducts,
            null,
            props
          )
        break
      case 'bundle':
        {
          response.product[0].bundleOptionalProducts =
            this.transformProductResponse(
              response.product[0].bundleOptionalProducts,
              null,
              props
            )
          response.product[0].bundleMandatoryProducts =
            this.transformProductResponse(
              response.product[0].bundleMandatoryProducts,
              null,
              props
            )
        }
        break
      case 'kit':
        response.product[0].skuKit = this.transformProductResponse(
          response.product[0].skuKit,
          null,
          props
        )
        break

      // case 'kit':
      //   response.product[0].skuKit = this.transformProductResponse(
      //     response.product[0].skuKit.sku, true
      //   )
      // break
      case 'collection':
        response.product[0].subProducts = this.transformProductResponse(
          response.product[0].subProducts,
          null,
          props
        )
        break
      default:
        break
    }
    const productResponse = {
      ...response,
      upSellProducts: this.getUpSellProducts(product),
      crossSellProducts: this.getCrossSellProducts(product),
    }
    return productResponse
  }

  /**
   * Transforms product positions for pagination.
   * Takes in product positions, pageable info and products array.
   * Returns the products array for the given page by splicing products
   * based on productPositions and page number.
   */
  transformProductPositions = props => {
    const { productPositions, pageableInfo = {}, product = [] } = props
    const { page = 1 } = pageableInfo
    const size = PLPProductsPerPage
    const products = []
    const productForPage = productPositions.slice(
      (page - 1) * size,
      (page + 1) * size - 1
    )
    if (
      isArray(productPositions) &&
      productPositions.length > 0 &&
      productForPage.length > 0
    ) {
      times(Math.min(PLPProductsPerPage, productForPage.length), index => {
        const foundIndex = product.findIndex(
          prop =>
            (prop.identifier || prop.productId) ===
            (productForPage[index].productId || productForPage[index].attr)
        )
        if (foundIndex !== -1) {
          const splicedData = product.splice(foundIndex, 1)
          products.push(...splicedData)
        }
      })
    }
    products.push(...product)
    return products
  }

  /**
   * Transforms the catalog response from the API into a normalized format for the UI.
   * - Parses category uxData to extract facet and product positions.
   * - Sorts products if queryParam.sort is set.
   * - Normalizes facetFilter into a clean array based on visible facet positions.
   * - Returns transformed category, facetFilter, product and other props.
   */
  transformCatalog(response, queryParam) {
    const { category, facetFilter = [], product, ...remainingProps } = response

    const data = category?.milestone?.uxData?.data || {}
    const uxData = this.dataParser(data)
    const facetPositions = uxData?.uxData?.facetPositions || []
    const productPositions = uxData?.uxData?.positions || []

    const facetFilterObj = {}
    const facetFilterArray = []
    const products = queryParam?.sort
      ? product
      : this.transformProductPositions({
          productPositions,
          product,
          pageableInfo: remainingProps.pageableInfo,
        })

    facetFilter.map(item => {
      const name = item?.name || ''
      if (name) {
        facetFilterObj[name.toLowerCase()] = item
      }
    })

    facetPositions.map(item => {
      if (!isEmpty(item)) {
        const facetData = facetFilterObj[item.facetId.toLowerCase()]
        if (facetData) {
          const isVisible = item?.visible || true
          if (isVisible) {
            facetFilterArray.push(facetData)
          }
          delete facetFilterObj[item.facetId.toLowerCase()]
        }
      }
    })

    Object.keys(facetFilterObj).forEach(key =>
      facetFilterArray.push(facetFilterObj[key])
    )

    return {
      category,
      facetFilter: facetFilterArray,
      product: products,
      ...remainingProps,
    }
  }

  /**
   * Sort AWS Recommended products based on the API returned product ids
   * @date 2023/8/24 - 17:19:29
   */
  /**
   * Sorts recommended products based on a list of product IDs.
   * Loops through the products array and adds any products that match the productIds to a new array.
   * Returns the new array of recommended products.
   */
  sortRecommendedItems = props => {
    const { products, productIds } = props || {}

    const recommendedItems = []

    if (isArray(products) && products.length > 0 && productIds) {
      productIds.forEach(productId => {
        products.forEach(products => {
          if (products.identifier == productId) {
            recommendedItems.push(products)
          } else if (products?.sku) {
            products.sku.forEach(skuItem => {
              if (skuItem.identifier == productId) {
                recommendedItems.push(products)
              }
            })
          }
        })
      })
    }
    return recommendedItems
  }
}
const builder = new Builder()

export default builder
export { builder }
