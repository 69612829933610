import uniqBy from 'lodash/uniqBy'
import { VARIANT_NODES, APPConfig } from 'config/appConfig'
import { pageNames } from 'src/routes/pathParams'
import { currencyFormat, getImageFromProperty } from 'src/utils'
import { cartContainer, checkoutContainer } from 'src/models'

const transformBundleData = (productDetail = {}) => {
  const bundleData = productDetail?.skus?.filter(
    sku =>
      sku?.type?.toLowerCase() === 'mandatory' ||
      sku?.type?.toLowerCase() === 'optional'
  )
  const mandatoryCount =
    bundleData?.filter(sku => sku?.type?.toLowerCase() === 'mandatory') || 0
  const optionalCount =
    bundleData?.filter(sku => sku?.type?.toLowerCase() === 'optional') || 0
  const bundle = {
    products: bundleData?.map(product => {
      return transformSku(product, false)
    }),
    optionalCount: optionalCount?.length,
    mandatoryCount: mandatoryCount?.length,
  }
  return bundle
}
const transformSku = (productDetails = {}, isMainProduct) => {
  let sku = isMainProduct ? productDetails?.skus?.[0] : productDetails
  const imageURL = getImageFromProperty(sku?.skuProperties)
  const productId = sku?.productId || ''
  const productName = sku?.skuProperties?.name || ''
  const productDescription = sku?.skuProperties?.shortDescription || ''
  const optionalSkuQuantity = sku?.optionalSkuQuantity || ''
  const skuQuantity = sku?.skuQuantity || ''
  const skuId = sku?.skuId || ''
  const slug = productDetails?.properties?.slug || ''
  const productOrSlugId = slug ? slug : productId
  const bundleProductSlug = sku?.skuProperties?.slug || ''
  const bundleProductVariant = sku?.skuProperties?.variantLabel || ''
  const productStatus = sku?.skuProperties?.productStatus || ''
  const productDetail = {
    productDescription,
    productName,
    imageURL: imageURL,
    skuId,
    productId,
    productStatus,
    bundleProductSlug,
    bundleProductVariant,
    transactionPrice: sku,
    ...VARIANT_NODES.reduce((acc, skuKey) => {
      const skuValue = sku?.skuProperties?.[skuKey] || ''
      if (skuValue) {
        return { ...acc, [skuKey]: skuValue }
      }
      return acc
    }, {}),
    maxCartItemLimit:
      sku?.quantityLimit?.maxValue ||
      APPConfig?.getAppConfig()?.cartMaxLimit ||
      99,
    productUrl: `${pageNames.product}/${productOrSlugId}`,
    skuInventoryAvailable: sku?.inventoryProperties?.available || false,
    skuQuantity: optionalSkuQuantity || skuQuantity || 1,
    availableChannels: sku?.skuProperties?.availableChannels,
  }
  return productDetail
}
const getPriceValue = (price = '') => {
  return {
    // currency: get(cartContainer.cartResponse, 'currencyCode'),
    price,
  }
}
export const getProductDetailsForCart = (productDetail = {}, props = {}) => {
  const totalPrice = productDetail?.totalValue?.originalPrice || 0
  const unitPrice = productDetail?.unitValue?.originalPrice || 0
  const grandTotalvalue = productDetail?.totalValue?.priceAfterDiscount || ''
  const grandTotalText = grandTotalvalue
    ? currencyFormat(getPriceValue(grandTotalvalue))
    : ''
  const totalPriceText = totalPrice
    ? currencyFormat(getPriceValue(totalPrice))
    : ''
  const promotionDetails =
    productDetail?.promotionDetails?.promotions?.[0]?.breakup || []
  const isSubscription = productDetail?.isSubscription || false
  const subscription = productDetail?.subscription || {}
  let skuType = productDetail?.skus?.[0]?.type || ''
  const isBundle =
    skuType?.toUpperCase() === 'BUNDLE' || skuType?.toUpperCase() === 'SKUKIT'
  const productDetails = {
    promotionDetails: uniqBy(promotionDetails, promo => promo?.displayMessage),
    totalValue: productDetail?.unitValue || '',
    TotalVal: productDetail?.totalValue || '',
    bundleProducts: transformBundleData(productDetail),
    cartItemId: productDetail?.itemId || '',
    quantity: productDetail?.quantity || 1,
    totalPrice: totalPriceText,
    grandTotal: grandTotalText,
    unitPrice,
    isCheckout: props?.isCheckout || false,
    isReviewSection: props?.isReviewSection || false,
    isSaveForLater: props?.isSaveForLater || false,
    isSubscription: isSubscription,
    isBundle: isBundle,
    subscription: subscription,
    isFreeGift: productDetail?.properties?.isGiftItem || false,
    categoryId: productDetail?.properties?.categoryId || '',
    slug: productDetail?.properties?.slug || '',
    skuSlug: productDetail?.skus?.[0]?.skuProperties?.slug || '',
    address: productDetail?.deliveryDetails?.address || {},
    originalPrice: totalPrice,
    grandTotalvalue,
  }
  const getSkuDetails = transformSku(productDetail, true)
  return {
    ...productDetails,
    ...getSkuDetails,
    currency: cartContainer.cartResponse?.currencyCode || '',
    currencySign: cartContainer.cartResponse?.currencySign || '$',
    enableQuantityEdit: props?.enableQuantityEdit,
    enableButtons: props?.enableButtons,
  }
}

export const getProductDetailsForSavedCart = (
  productDetail = {},
  props = {}
) => {
  const totalPrice = productDetail?.sku?.prices?.[0].price || 0
  const priceValue = {
    currency: cartContainer.cartResponse?.currencyCode || '',
    price: totalPrice,
  }

  const totalPriceText = totalPrice ? currencyFormat(priceValue) : ''

  // let parsedImage = JSON.parse(get(sku, 'skuProperties.productImages', ''))
  // parsedImage = parsedImage[0].url
  const imageURL = getImageFromProperty(productDetail?.sku?.properties)
  const productId = productDetail?.properties?.productId || ''
  const slug = productDetails?.properties?.slug || ''
  const productOrSlugId = slug ? slug : productId
  const productUrl = `${pageNames.product}/${productOrSlugId}`
  const productDetails = {
    productName: productDetail?.sku?.properties?.name || '',
    imageURL: imageURL,
    productId,
    productUrl,
    productDescription: productDetail?.sku?.properties?.shortDescription || '',
    listItemId: productDetail?.listItemId || '',
    quantity: parseInt(productDetail?.properties?.quantity || 1),
    categoryId: productDetail?.properties?.categoryId || '',
    totalPrice: totalPriceText,
    skuId: productDetail?.key || '',
    skuSlug: productDetail?.sku?.properties?.slug || '',
    transactionPrice: productDetail?.sku?.prices?.[0] || {},
    ...VARIANT_NODES.reduce((acc, skuKey) => {
      const skuValue = productDetail?.sku?.properties?.[skuKey] || ''
      if (skuValue) {
        return { ...acc, [skuKey]: skuValue }
      }
      return acc
    }, {}),
    isCheckout: props?.isCheckout || false,
    isReviewSection: props?.isReviewSection || false,
    isSaveForLater: props?.isSaveForLater || false,
    maxCartItemLimit:
      productDetail?.skus?.[0]?.quantityLimit?.maxValue ||
      APPConfig?.getAppConfig()?.cartMaxLimit ||
      99,
    currency: cartContainer.cartResponse?.currencyCode || '',
  }
  return productDetails
}
/**
 * overAllTax removed from normal user cart and added to checkout cart call,
 * so need to make user cart call on viewcart redirection to remove tax from viewcart/minicart
 * to avoid redundant calls of USER Cart call, made to common place and called on demand
 * @date 2/7/2024 - 5:47:59 PM
 * @param {isToMakeOnlyUSERCart} - boolean - passed as param from home to cart/viewcart redirection
 */
export const clearUserCartCache = async (isToMakeOnlyUSERCart = false) => {
  const { value = {}, payments = [] } = cartContainer?.cartResponse || {}
  const { overAllTax = 0 } = value
  const isToUpdateUserCartForTax = overAllTax > 0 || isToMakeOnlyUSERCart
  const isToDeletePayment = payments.length > 0
  if (isToUpdateUserCartForTax) {
    if (isToDeletePayment) {
      await checkoutContainer.deleteMultiplePaymentById()
    } else {
      await cartContainer.viewCart()
    }
  }
}
