import React from 'react'
import {
  Form,
  Button,
  InputGroup,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { i18nTranslate } from 'src/utils'
const EmailField = props => {
  const type = 'accEmail'
  const className = props.isInvalid ? 'is-invalid' : ''
  const emailTooltip = i18nTranslate(
    'emailTooltip1',
    'This will be your account`s primary email for account correspondence and used for logging in.'
  )
  return (
    <InputGroup className={className}>
      <Form.Control variant="secondary" {...props} type={type} />
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        overlay={
          <Tooltip id={`tooltip`} role="alert">
            {emailTooltip}
          </Tooltip>
        }>
        <div className="info">
          <Button
            aria-label="more information about preferred email address"
            className="infoIconBtn">
            <IoIosInformationCircleOutline
              aria-label={emailTooltip}
              className=" infoIcon"
            />
          </Button>
        </div>
      </OverlayTrigger>
    </InputGroup>
  )
}

export { EmailField }
