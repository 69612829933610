const specialProductTypes = [
  'bundle',
  'collection',
  'fixedbundle',
  'skukit',
  'fixedpricebundle',
  'kit',
]

const normalProductSkuType = ['default']

const normalAndSpecialProductSkuType = [
  ...normalProductSkuType,
  ...specialProductTypes,
]

export {
  specialProductTypes,
  normalProductSkuType,
  normalAndSpecialProductSkuType,
}
