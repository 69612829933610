import React /*, { Component }*/ from 'react'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import TagManager from 'react-gtm-module'
import { i18nTranslate } from 'src/utils'
import { Link } from 'react-router-dom'
import { Button, Image } from 'react-bootstrap'
import { enableSubscription } from 'config/appConfig'
import { pageNames } from 'src/routes/pathParams'
import {
  customerContainer,
  cartContainer,
  subscriptionContainer,
} from 'src/models'
import Subscriptions from 'src/images/subscriptions-icon.png'
import { productDeps } from 'src/deps'
import { quickViewState, SigninModal, toastState } from 'src/views/components'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { IS_BROWSER } from 'src/utils'

@observer
class ProductSubscribeButton extends React.Component {
  @observable subscriptionParameters = {}
  @observable isToShowSignInModal = false

  componentDidMount() {
    this.init()
  }
  init = async () => {
    if (customerContainer.isRegisterUser) {
      await cartContainer.getSubscriptionCount()
      await this.subscriptionCountResponseCheck()
    }
  }

  subscriptionCountResponseCheck = () => {
    if (
      customerContainer.isSuccessResponse(cartContainer.subscriptionResponse)
    ) {
      this.setSubscriptionData()
    } else {
      setTimeout(() => this.subscriptionCountResponseCheck(), 1000)
    }
  }

  @action
  subscriptionBtnClick = (product = {}, productCount = 1) => {
    if (!customerContainer.isRegisterUser) {
      this.isToShowSignInModal = true
    } else {
      const isBundle = productDeps.isBundleData(product)
      const { productId = '', skuId = '', categoryId = '', slug = '' } = product
      const scanQualifiedCount = this.props?.scanCount || ''
      subscriptionContainer.scanQualifiedCount = scanQualifiedCount
      let skus = [
        {
          skuId: skuId,
          productId: productId,
          type: 'DEFAULT',
        },
      ]
      if (isBundle) {
        skus = productDeps?.getBundleData(product)
      }
      const postParameters = [
        {
          quantity: productCount,
          skus: skus,
          isSubscription: true,
          subscription: {
            frequencyPeriod: '1',
            frequencyType: 'MONTHS',
          },
          properties: {
            category: categoryId,
            slug: slug,
            scanQualifiedCount: scanQualifiedCount,
          },
          frequencyPeriod: '1',
          frequencyType: 'MONTHS',
          category: categoryId,
          isSubscriptionPreference: customerContainer.isSubscriptionPreference,
          productId: productId,
          skuId: isBundle ? productId : skuId,
        },
      ]

      /**
       * @info
       * GTM Event for Add to subscription Cart
       *triggers on click of subscribe button in pdp page
       */
      TagManager.dataLayer({
        dataLayer: {
          event: 'addToSubscriptionCart',
          pagePath: `/product/:id`,
          pageTitle: 'Product Details',
          subscriptionInfo: postParameters,
          platform: 'equinox',
        },
      })
      this.subscriptionParameters = postParameters
      this.subscriptionAddTrigger()
    }
  }

  @action
  setSubscriptionData = async () => {
    // here livecount call is made only to fetch "size",
    // offer node is not required,
    // hence we are sending skipPromotion as true
    customerContainer.isSubscriptionPreference =
      cartContainer.subscriptionResponse?.isSubscriptionPreference || ''

    if (customerContainer.isRegisterUser) {
      const totalActiveSubscriptionCount =
        cartContainer.subscriptionResponse
          ?.totalActiveSubscriptionItemQuantityCount || ''

      const subscriptionRefineData = {
        filters: [
          { field: 'status', operation: 'IN', value: 'ACTIVE,PENDING' },
        ],
      }
      const params = { size: totalActiveSubscriptionCount || 100 }
      {
        totalActiveSubscriptionCount > 0 &&
          (await subscriptionContainer.getAllSubscriptions(
            subscriptionRefineData,
            params
          ))
      }

      let isAlreadySubscribedProduct = props => {
        const isBundle = props.product?.isBundle || false
        const totalSubscriptionCount =
          cartContainer.subscriptionResponse?.totalSubscriptionCount || 0
        if (!totalSubscriptionCount) {
          return false
        } else if (isBundle) {
          let mandatoryProductIds =
            props.product?.bundlemandatoryproductids ||
            props.product?.productId ||
            ''

          return (
            subscriptionContainer.subscriptionData?.subscriptions &&
            subscriptionContainer.subscriptionData.subscriptions.some(item => {
              let skus = (item?.itemInfo?.length && item.itemInfo[0].skus) || []
              return skus.some(sku => {
                return mandatoryProductIds?.indexOf(sku.productId) > -1
                  ? true
                  : false
              })
            })
          )
        } else {
          const skuId = props.product?.skuId || ''
          return (
            subscriptionContainer.subscriptionData.subscriptions &&
            subscriptionContainer.subscriptionData.subscriptions.some(item => {
              const id = item?.itemInfo?.[0].skus?.[0]?.id || ''
              return id == skuId
            })
          )
        }
      }

      const isAlreadySubscribed = isAlreadySubscribedProduct(this.props)
      customerContainer.isSubscriptionAdded = isAlreadySubscribed
    }
  }

  subscriptionMessage = () => {
    const isBundleView = this.props?.isBundleView || false
    return (
      <div className="subscription-message text-center">
        <div className="subscription-message-text">
          {i18nTranslate(
            'pdp.subscriptionMessage',
            'You can manage your subscriptions on the My Subscriptions page.',
            {
              nameSpace: 'ssr-resource',
            }
          )}
        </div>
        {
          // !isBundleView &&
          <div className="subscription-message-button">
            <Link to={pageNames.myaccountsubscriptions}>
              <Button className="warning-button-subscription text-uppercase shadow-none border-0 p-2 mt-2">
                {i18nTranslate('pdp.subscriptionButton', 'manage', {
                  nameSpace: 'ssr-resource',
                })}
              </Button>
            </Link>
          </div>
        }
      </div>
    )
  }

  subscriptionAddTrigger = async () => {
    if (document.body.classList.contains('no-scroll') && IS_BROWSER) {
      document.body.classList.remove('no-scroll')
    }
    const totalSubscriptionCount =
      cartContainer.subscriptionResponse?.totalSubscriptionCount || 0

    const totalActiveSubscriptionCount =
      cartContainer.subscriptionResponse
        ?.totalActiveSubscriptionItemQuantityCount || 0

    const hasSubscription =
      cartContainer.subscriptionResponse?.isSubscriptionPreference || false

    if (customerContainer.isRegisterUser) {
      const handleBundleView = this.props?.handleBundleView || {}
      const response = await subscriptionContainer.addToSubscription(
        this.subscriptionParameters
      )
      if (customerContainer.isSuccessResponse(response)) {
        await cartContainer.getSubscriptionCount()
        await this.subscriptionCountResponseCheck()
        // ^ CX121-2540 - fix for inconsistent display of subscription buttons
        customerContainer.isSubscriptionAdded = true
        if (
          (totalSubscriptionCount > 0 &&
            customerContainer.isSubscriptionAdded) ||
          (hasSubscription && totalActiveSubscriptionCount === 0)
        ) {
          if (this.props?.product?.scanQualifiedCount > 0) {
            toastState.setToastMessage(
              'Item successfully added to subscription. Click here to add scan card information and manage subscriptions.',
              true,
              7000,
              pageNames.myaccountsubscriptions
            )
          } else {
            toastState.setToastMessage(
              'Item successfully added to subscription. Click here to manage subscriptions.',
              true,
              7000,
              pageNames.myaccountsubscriptions
            )
          }
        } else {
          quickViewState.isQuickViewOpen = false
          toastState.setToastMessage(
            i18nTranslate(
              'subscription.success',
              'You have added a new subscription.'
            ),
            true,
            6000,
            null,
            true
          )
        }
      } else {
        const errorCode = response?.responseCode || response?.code || ''
        const responseErrorMessage =
          response?.responseMessage || response?.message || ''
        const editFreezeErrorCode =
          errorCode === 'error.subscription.editfreeze.date.reached'
        const errorMessage = editFreezeErrorCode
          ? i18nTranslate(
              'subscription.editFreezeErrorMessage',
              'This Subscription order is currently being processed and at this time no changes will be allowed. Please return after your Subscription order has shipped to make any changes to your next order.'
            )
          : responseErrorMessage ||
            i18nTranslate(
              'signup.failure',
              'Sorry, we are unable to create your account. Please try again.'
            )
        toastState.setToastMessage(errorMessage, false)
      }
      if (
        customerContainer.isSuccessResponse(response) &&
        !customerContainer.isSubscriptionPreference
      ) {
        await cartContainer.viewCart()
      }
      //handleBundleView
      quickViewState.isQuickViewOpen && quickViewState?.toggleQuickView()
    }
  }
  renderTotal() {
    const product = this.props?.product || {}
    const {
      isBundle = false,
      isCollection = false,
      transactionPrice = '',
      totalValue = {},
    } = product
    const regprice =
      transactionPrice && transactionPrice.price ? transactionPrice.price : ''
    const quantity = this.props?.productCount
    const totalcount = quantity * regprice
    const priceAfterDiscount =
      totalValue?.totaldiscount && totalValue?.priceAfterDiscount
        ? totalValue?.priceAfterDiscount
        : 0
    const totalPriceAfterDiscount = quantity * priceAfterDiscount
    return totalValue?.totaldiscount && totalValue?.priceAfterDiscount ? (
      <>
        <span className="strikeThrough pr-2">
          {'$' + totalcount.toFixed(2)}
        </span>
        <span>{'$' + totalPriceAfterDiscount}</span>
      </>
    ) : (
      '$' + totalcount.toFixed(2)
    )
  }

  handleHideSignInModal = () => {
    this.isToShowSignInModal = false
  }

  // renderSignInAccordion = () => {
  //   return <SignInAccordion {...this.props} onContinue={this.onContinue} />
  // }
  render() {
    const {
      product = {},
      isFromSubscription = false,
      isFromEditSubscription = false,
      productCount = 1,
      title,
    } = this.props || {}
    let hideSubscribeIcon
    let subscribeBtn
    let MouseOver
    let buttonLabel = title ? `Add ${title} to Subscription` : 'Subscribe'
    const {
      issubscription = false,
      productId = '',
      categoryId = '',
      transactionPrice = '',
    } = product

    const isSubscribable = issubscription
    const shouldShowSubscriptionButton =
      isSubscribable && !isFromSubscription && !isFromEditSubscription
    const totalSubscriptionCount =
      cartContainer.subscriptionResponse?.totalSubscriptionCount || 0

    //const subscriptionUrl = `${pageNames.subscription}/${productId}?categoryId=${categoryId}&skuId=${skuId}`

    const productUrl = `${pageNames.product}/${productId}${
      categoryId ? '?categoryId=' + categoryId : ''
    }`

    const subscriptionSigninUrl = `${pageNames.signIn}?targetURL=${productUrl}`

    //const userId = get(customerContainer.profileResponse, 'id', '') || ''

    if (!shouldShowSubscriptionButton && !enableSubscription) {
      return ''
    }
    if (buttonLabel != 'Subscribe') {
      ;(hideSubscribeIcon = {
        display: 'none',
      }),
        (subscribeBtn = {
          height: '44px',
          backgroundColor: '#0d0c0c',
          fontSize: '14px !important',
          color: '#fff',
          textTransform: 'initial',
          padding: '6px 12px',
        })
    }
    const isBundle = product?.isBundle

    let status = product?.properties?.status
    let disableSubscribtion = false
    const isExclusive = product?.sku?.[0]?.properties?.isExclusive || ''

    const { isItemEligible } = productState.getQualificationMessage({
      productId,
      isExclusive,
    })

    if (customerContainer?.qualificationStoreResponse === true) {
      if (isExclusive == 'true') {
        disableSubscribtion = !isItemEligible
        // if (
        //   isExclusive == 'true' &&
        //   isQualificationEligible == 'true' &&
        //   customerContainer.isRegisterUser
        // ) {
        //   disableSubscribtion = false
        // } else if (
        //   isExclusive == 'true' &&
        //   (isQualificationEligible == 'false' || '' || 'undefined') &&
        //   customerContainer.isRegisterUser
        // ) {
        //   disableSubscribtion = true
        // } else if (isExclusive == 'true' && !customerContainer.isRegisterUser) {
        //   disableSubscribtion = true
        // }
      }
      if (
        status === 'inactive' ||
        product?.inventory?.toLowerCase() === 'unavailable' ||
        product?.inventory?.toLowerCase() === 'out of stock'
      ) {
        disableSubscribtion = true
      }
    } else {
      if (isBundle) {
        const { status = '' } = product
        if (status === 'inactive') {
          disableSubscribtion = true
        }
      } else {
        const { status = '' } = product.skuProperties || {}
        if (
          status === 'inactive' ||
          product?.inventory?.toLowerCase() === 'unavailable' ||
          product?.inventory?.toLowerCase() === 'out of stock'
        ) {
          disableSubscribtion = true
        }
      }
    }

    return (
      <>
        <a className="w-100 d-flex text-uppercase subscribe-btn-link">
          <div className="subscribe-btn">
            <Button
              className="btn-subscribe w-100 btn-outline-primary d-flex justify-content-center align-items-center pdp-subscribe-btn"
              style={subscribeBtn}
              data-testid="qa-subscribe"
              onClick={() => this.subscriptionBtnClick(product, productCount)}
              disabled={
                customerContainer.isSubscriptionAdded || disableSubscribtion
              }>
              {customerContainer.isSubscriptionAdded ? (
                i18nTranslate('pdp.subscribed', 'Subscribed', {
                  nameSpace: 'ssr-resource',
                })
              ) : this.isSubscriptionPreference ? (
                i18nTranslate('pdp.subscribe', 'Add to Subscriptions', {
                  nameSpace: 'ssr-resource',
                })
              ) : (
                <span>
                  <span
                    style={hideSubscribeIcon}
                    className="pr-1 subscribe-icon">
                    {/* <img
                      className="subscribe-btnIcon"
                      src={rotate_icon}
                      alt="subscribe button icon"
                    /> */}
                    <Image src={Subscriptions} alt="Subscribe icon" />
                  </span>
                  <span>
                    {i18nTranslate('pdp.subscribe', buttonLabel, {
                      nameSpace: 'ssr-resource',
                    })}
                  </span>
                </span>
              )}
            </Button>
          </div>
        </a>
        {totalSubscriptionCount > 0 &&
          customerContainer.isSubscriptionAdded && (
            <div>{this.subscriptionMessage()}</div>
          )}
        <SigninModal
          isVisible={this.isToShowSignInModal}
          onHideSignInModal={this.handleHideSignInModal}
        />
        {/* <Modal
          centered={true}
          size="lg"
          className="align-modal"
          show={this.isVisible}
          onHide={() => (this.isVisible = !this.isVisible)}
          dialogClassName="checkout-dialog">
          <Modal.Header closeButton className={'border-bottom-0'} />
          <Modal.Body>{this.renderSignInAccordion()}</Modal.Body>
        </Modal> */}
      </>
    )
  }
}

export default ProductSubscribeButton
export { ProductSubscribeButton }
