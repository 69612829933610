/**
 * Stringifies an address object into a JSON string.
 *
 * @param {Object} address - The address object to stringify
 * @returns {string} The JSON stringified address object
 */
export function stringifyAddress(address) {
  const obj = {
    firstName: address.firstName,
    lastName: address.lastName,
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    state: address.state,
    country: address.country,
    zip: address.zip,
    phone: address.phone,
    // addressId: address.addressId,
    // ^ commenting this temporarily for CX121-3422
    // to be discussed with naren
  }
  return JSON.stringify(obj)
}

/**
 * Validates if an address object matches the values from a form.
 *
 * @param {Object} formData - The address data from the form
 * @param {Object} addressData - The address object to compare against
 * @returns {boolean} - Returns true if the address does not match the form
 */
export const handleAddressValidation = (formData = {}, addressData = {}) => {
  if (addressData !== '') {
    return !(
      addressData?.addressLine1 === formData?.addressLine1 &&
      addressData?.addressLine2 === formData?.addressLine2 &&
      addressData?.city?.toLowerCase() === formData?.city?.toLowerCase() &&
      addressData?.state === formData?.state &&
      addressData?.country === formData?.country &&
      addressData?.zip === formData?.zip
    )
  } else {
    return true
  }
}
