import { CommonContainer } from 'src/models/Common'
import { observable } from 'mobx'
import {
  cartContainer,
  storeContainer,
  checkoutContainer,
  customerContainer,
  sessionContainer,
  loyaltyContainer,
} from 'src/models'
import { APPConfig } from 'config/appConfig'
import { pageNames } from 'src/routes/pathParams'
import { toastState, overlayState } from 'src/views/components'
import {
  i18nTranslate,
  trackErrorInInstana,
  getBrowserType,
  trackCheckoutCouponCodeEvents,
  getLocaleCodeFromUrl,
} from 'src/utils'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'
import { IS_BROWSER } from 'src/utils/application'

class GooglePayContainer extends CommonContainer {
  @observable googlePayPaymentToken = ''
  @observable isToInitiateGooglePayPayment = false
  @observable googlePayClientCreated = false
  @observable isToEnableSubmitOrder = false
  @observable googlePaymentInstance = null
  @observable isToShowConfirmationModal = false
  @observable paymentsClient = null
  @observable expressPaymentClient = null
  submitOrderProps = {}
  setConfirmationModalTimeout = null
  isExpressCheckout = false
  /**
   * Handles errors that occur during the Google Pay payment process.
   */
  handleGooglePayClientError = async (
    error = {},
    isToShowToastMessage = false
  ) => {
    if (isToShowToastMessage) {
      if (error?.statusCode?.toString?.()?.toLowerCase() === 'canceled') {
        toastState.setToastMessage(
          i18nTranslate(
            'checkout.googlePayCancelMessage',
            'Your Google Pay payment request was successfully cancelled. Please select a payment option and try again.'
          )
        )
      } else {
        toastState.setToastMessage(
          i18nTranslate(
            'checkout.paymentGeneralError',
            'Sorry, we were unable to process your payment. Please try again.'
          )
        )
      }
    }
    overlayState?.toggleLoader()
    trackErrorInInstana({
      errorData: error,
      errorReport: 'Google Pay - Payment failed',
    })
    await checkoutContainer.deletePayment()
    await cartContainer.viewCart()
    googlePayContainer.isToInitiateGooglePayPayment = false
    googlePayContainer.isToEnableSubmitOrder = false
    googlePayContainer.googlePayClientCreated = false
    checkoutContainer.activePath = 'payment'
    overlayState?.toggleLoader()
    overlayState?.hideLoader()
  }

  /**
   * Handles the Order submission of a Google Pay order.
   */
  handleSubmitOrder = async (payload = {}) => {
    overlayState?.toggleLoader()
    const submitOrderResponse = await checkoutContainer.submitOrder({
      properties: {
        nonceToken: payload?.nonce || '',
        postalCode: payload?.postalCode || '',
      },
    })
    if (checkoutContainer.isSuccessResponse(submitOrderResponse)) {
      overlayState?.toggleLoader()
      await toastState.setToastMessage(
        i18nTranslate('order.submitSuccess', 'Your order has been submitted.'),
        true
      )
      trackErrorInInstana({
        errorData: submitOrderResponse,
        errorReport: 'Google Pay - Order Placed Successfully',
      })

      if (typeof window !== 'undefined') {
        localStorage?.setItem('paymentSuccess', submitOrderResponse?.orderId)
        let isNativeApp = checkIsNativeApp() || false
        if (googlePayContainer.submitOrderProps?.history) {
          overlayState?.toggleLoader()
          if (isNativeApp) {
            googlePayContainer.submitOrderProps?.history?.push?.(
              `${pageNames.orderConfirmation}?isNativeApp=true`
            )
          } else {
            googlePayContainer.submitOrderProps?.history?.push?.(
              pageNames.orderConfirmation
            )
          }
          googlePayContainer.googlePayClientCreated = false
          googlePayContainer.isToInitiateGooglePayPayment = false
          googlePayContainer.isToEnableSubmitOrder = false
          overlayState?.toggleLoader()
          await cartContainer.viewCart()
          await loyaltyContainer.getLoyaltyPoints()
        } else {
          overlayState?.toggleLoader()
          const urlMatch = [
            pageNames.checkout,
            pageNames.expressCheckout,
            pageNames.expressPDP,
          ].find(page => window?.location?.href?.includes(page))
          const arr = window?.location?.href?.split(urlMatch)
          if (isNativeApp) {
            window?.location?.replace?.(
              `${arr[0]}${pageNames.orderConfirmation}?isNativeApp=true`
            )
          } else {
            window?.location?.replace?.(
              `${arr[0]}${pageNames.orderConfirmation}`
            )
          }
        }
      }
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'checkout.paymentGeneralError',
          'Sorry, we were unable to process your payment. Please try again.'
        )
      )
      overlayState?.toggleLoader()
      await googlePayContainer.handleGooglePayClientError(submitOrderResponse)
    }
  }

  /**
   * Creates a Google Pay order by loading the payment data and parsing the response.
   */
  createGooglePayOrder = (
    googlePaymentInstance = null,
    paymentsClient = null
  ) => {
    const paymentDataRequest =
      googlePaymentInstance?.createPaymentDataRequest({
        transactionInfo: {
          currencyCode: storeContainer?.storeIDValue === 'US' ? 'USD' : 'CAD',
          totalPriceStatus: 'FINAL',
          totalPrice:
            cartContainer?.cartResponse?.paymentValue?.totalAmountPaid?.toString(),
        },
      }) || {}

    const cardPaymentMethod =
      paymentDataRequest?.allowedPaymentMethods?.[0] || {}
    if (cardPaymentMethod.parameters) {
      cardPaymentMethod.parameters.billingAddressRequired = true
      cardPaymentMethod.parameters.billingAddressParameters = {
        format: 'FULL',
        phoneNumberRequired: true,
      }
    }

    trackErrorInInstana({
      errorData: paymentDataRequest,
      errorReport: 'Google Pay - PaymentDataRequest',
    })

    paymentsClient
      ?.loadPaymentData(paymentDataRequest)
      .then(function (paymentData) {
        trackErrorInInstana({
          errorData: paymentData,
          errorReport: 'Google Pay - PaymentData',
        })
        googlePaymentInstance.parseResponse(
          paymentData,
          async function (err, result) {
            trackErrorInInstana({
              errorData: result,
              errorReport: 'Google Pay - Nonce Token',
            })
            if (result?.nonce) {
              result.postalCode =
                paymentData?.paymentMethodData?.info?.billingAddress
                  ?.postalCode || ''
              await googlePayContainer.handleSubmitOrder(result)
            } else {
              trackErrorInInstana({
                errorData: err,
                errorReport: 'Google Pay - Nonce Error',
              })
              console.log('Error in parsing Payment Data', err)
              await googlePayContainer.handleGooglePayClientError(err, true)
            }
          }
        )
      })
      .catch(async function (err) {
        trackErrorInInstana({
          errorData: err,
          errorReport: 'Google Pay - Payment Data Error',
        })
        console.log('Error in loading Payment Data', err)
        await googlePayContainer.handleGooglePayClientError(err, true)
      })
  }

  handleGooglePayModalClose = () => {
    googlePayContainer.isToShowConfirmationModal = false
    const errorMessage = { statusCode: 'Canceled' }
    googlePayContainer.handleGooglePayClientError(errorMessage, true)
  }

  handleGooglePayRedirection = () => {
    let browser = getBrowserType() || {}
    let isNativeApp = checkIsNativeApp() || false
    const browserName = browser?.name?.toLowerCase() || ''
    const isSafariBrowser = browserName === 'safari' || browserName === 'ios'
    if (isSafariBrowser || isNativeApp) {
      trackErrorInInstana({
        errorData: browserName,
        errorReport: 'Google Pay Confirmation Modal',
      })
      googlePayContainer.isToShowConfirmationModal = true
      this.setConfirmationModalTimeout = setTimeout(() => {
        googlePayContainer.isToShowConfirmationModal = false
      }, 60000)
      window.addEventListener('click', () => {
        clearTimeout(this.setConfirmationModalTimeout)
      })
    } else {
      googlePayContainer.createGooglePayOrder(
        googlePayContainer.googlePaymentInstance,
        googlePayContainer.paymentsClient
      )
    }
  }

  /**
   * Handles the click event of the Google Pay submit button.
   */
  handleGooglePaySubmitButtonClick = async (props = {}) => {
    overlayState?.toggleLoader()
    googlePayContainer.submitOrderProps = props
    const reviewResponse = await checkoutContainer.reviewOrder()
    trackErrorInInstana({
      errorData: reviewResponse,
      errorReport: 'Google Pay - Review Order Response',
    })
    if (checkoutContainer.isSuccessResponse(reviewResponse)) {
      overlayState?.toggleLoader()
      let isNativeApp = checkIsNativeApp() || false
      if (customerContainer?.isRegisterUser && !isNativeApp) {
        const sessionResponse = await sessionContainer.refreshSession()
        trackErrorInInstana({
          errorData: sessionResponse,
          errorReport: 'Google Pay - Session Response',
        })
        if (sessionContainer.isSuccessResponse(sessionResponse)) {
          googlePayContainer.handleGooglePayRedirection()
          return
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'checkout.paymentGeneralError',
              'Sorry, we were unable to process your payment. Please try again.'
            )
          )
          await googlePayContainer.handleGooglePayClientError(sessionResponse)
        }
      } else {
        googlePayContainer.handleGooglePayRedirection()
      }
    } else {
      toastState.setToastMessage(reviewResponse?.responseMessage)
      overlayState?.toggleLoader()
      trackErrorInInstana({
        errorData: reviewResponse,
        errorReport: 'Google Pay - Review Order Error',
      })
      await googlePayContainer.handleGooglePayClientError(reviewResponse)
    }
  }

  /**
   * Creates a Google Pay client instance and initializes it with the provided client instance.
   */
  createGooglePayClient = async (clientInstance = null) => {
    const googlePayEnvironment =
      APPConfig.getAppConfig?.()?.googlePayEnvironment || 'sandbox'
    const googlePayMerchantId =
      APPConfig.getAppConfig?.()?.googlePayMerchantId || ''

    try {
      const paymentsClient = new google.payments.api.PaymentsClient({
        environment: googlePayEnvironment?.toUpperCase(),
      })
      const xpressPaymentClient = new google.payments.api.PaymentsClient({
        environment: googlePayEnvironment?.toUpperCase(),
        paymentDataCallbacks: {
          onPaymentDataChanged: googlePayContainer.onPaymentDataChanged,
          onPaymentAuthorized: googlePayContainer.onPaymentAuthorized,
        },
      })
      braintree.googlePayment.create(
        {
          client: clientInstance,
          googlePayVersion: 2,
          googleMerchantId: googlePayMerchantId,
        },
        async function (googlePaymentErr, googlePaymentInstance) {
          if (googlePaymentErr) {
            console.log(
              'Error in creating Google payment Instance',
              googlePaymentErr
            )
            await googlePayContainer.handleGooglePayClientError(
              googlePaymentErr,
              true
            )
            return
          }

          const readyToPayPayload = {
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods:
              googlePaymentInstance.createPaymentDataRequest()
                .allowedPaymentMethods,
            existingPaymentMethodRequired: true,
          }

          if (googlePayContainer.isExpressCheckout) {
            xpressPaymentClient
              .isReadyToPay(readyToPayPayload)
              .then(function (response) {
                if (response?.result) {
                  googlePayContainer.googlePaymentInstance =
                    googlePaymentInstance
                  googlePayContainer.expressPaymentClient = xpressPaymentClient
                  googlePayContainer.isToEnableSubmitOrder = true
                }
              })
              .catch(async function (err) {
                console.log('Error in Creating Payment Client', err)
                await googlePayContainer.handleGooglePayClientError(err, true)
              })
          }

          paymentsClient
            .isReadyToPay(readyToPayPayload)
            .then(function (response) {
              console.log('regular payment client created')
              if (response?.result) {
                googlePayContainer.googlePaymentInstance = googlePaymentInstance
                googlePayContainer.paymentsClient = paymentsClient
                googlePayContainer.isToEnableSubmitOrder = true
              }
            })
            .catch(async function (err) {
              console.log('Error in Creating Payment Client', err)
              await googlePayContainer.handleGooglePayClientError(err, true)
            })
        }
      )
    } catch (e) {
      console.log('Error in Creating GooglePay Payment Client', e)
      await googlePayContainer.handleGooglePayClientError(e, true)
      return
    }
  }

  /**
   * Initiates a Google Pay payment flow.
   */
  initiateGooglePayPayment = async (
    clientErr = null,
    clientInstance = null,
    expressCheckout = false
  ) => {
    if (clientErr && IS_BROWSER) {
      console.error('Error creating GooglePay Client:', clientErr)
      await googlePayContainer.handleGooglePayClientError(clientErr, true)
      return
    }
    googlePayContainer.isExpressCheckout = expressCheckout
    googlePayContainer.createGooglePayClient(clientInstance)
  }

  /**
   * Generates an array of display items for Google Pay checkout, including subtotal, tax, shipping, and discounts.
   * Additional items such as Total SV and Total CV are included based on user registration status and role.
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
   * @returns {Array<Object>} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
   */
  getDisplayItems = () => {
    const priceFacet = cartContainer?.cartResponse?.value?.priceFacets
    const userRole = customerContainer.profileResponse?.userrole || ''

    const displayItems = [
      {
        label: 'Subtotal',
        type: 'SUBTOTAL',
        price:
          cartContainer?.cartResponse?.value?.priceAfterMarkdown?.toString(),
        status: 'FINAL',
      },
      {
        label: 'Tax',
        type: 'LINE_ITEM',
        price: cartContainer?.cartResponse?.value?.overAllTax?.toString(),
        status: 'FINAL',
      },
      {
        label: 'Shipping',
        type: 'LINE_ITEM',
        price:
          cartContainer?.cartResponse?.value?.totalDeliveryCharge?.toString(),
        status: 'FINAL',
      },
      {
        label: 'Discounts',
        type: 'LINE_ITEM',
        price: cartContainer?.cartResponse?.value?.overAllDiscount?.toString(),
        status: 'FINAL',
      },
    ]

    if (
      customerContainer?.isRegisterUser &&
      localStorage.getItem('accountType')?.includes('Brand Affiliate')
    ) {
      displayItems.push({
        label: 'Total SV',
        type: 'LINE_ITEM',
        price: priceFacet?.PV?.PVAfterDiscount?.toString() || 0.0,
        status: 'FINAL',
      })
    }
    if (
      customerContainer?.isRegisterUser &&
      userRole !== 'ROLE_ACCOUNT_BUYER_ADMIN'
    ) {
      displayItems.push({
        label: 'Total CV',
        type: 'LINE_ITEM',
        price: priceFacet?.CV?.CVAfterDiscount?.toString() || 0.0,
        status: 'FINAL',
      })
    }

    return displayItems
  }

  /**
   * Calculates the total price details for Google Pay.
   *
   * @param {boolean} [isEstimatedPrice=false] - Indicates if the price is estimated or final.
   * @returns {Object} An object containing total price details:
   * - totalPriceLabel: {string} Label for the total price.
   * - currencyCode: {string} Currency code based on the store ID.
   * - totalPrice: {string} The total amount unpaid as a string.
   * - totalPriceStatus: {string} Status of the total price, either 'ESTIMATED' or 'FINAL'.
   * - displayItems: {Array} List of items to be displayed in Google Pay.
   */
  getTotalPrice = (isEstimatedPrice = false) => {
    return {
      totalPriceLabel: 'Total',
      currencyCode: storeContainer?.storeIDValue === 'US' ? 'USD' : 'CAD',
      totalPrice:
        cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid?.toString(),
      totalPriceStatus: isEstimatedPrice ? 'ESTIMATED' : 'FINAL',
      displayItems: googlePayContainer.getDisplayItems(),
    }
  }

  /**
   * Retrieves existing promo codes from the cart response.
   *
   * @returns {Array<Object>} An array of promo code objects, each containing a redemption code and an empty description.
   */
  getExistingPromoCodes = () => {
    const promoCodes =
      cartContainer?.cartResponse?.promotionDetails?.codes || []
    return (
      promoCodes?.map(promoCode => {
        return { redemptionCode: promoCode?.code, description: '' }
      }) || []
    )
  }

  /**
   * Constructs the Google Pay data request for express checkout.
   *
   * @returns {Object} The payment request data for Google Pay.
   */
  getExpressGpayDataRequest = () => {
    const locale =
      getLocaleCodeFromUrl()?.split('_')?.[1]?.toUpperCase() || 'US'
    const allowedCountryCodes =
      localStorage?.getItem('accountType')?.includes('Retail Customer') ||
      !customerContainer?.isRegisterUser
        ? [locale]
        : ['US', 'CA']

    const paymentRequestData = {
      transactionInfo: googlePayContainer.getTotalPrice(true),
      emailRequired: true,
      shippingAddressRequired: true,
      shippingAddressParameters: {
        allowedCountryCodes: allowedCountryCodes,
        phoneNumberRequired: true,
      },
      shippingOptionRequired: true,
      callbackIntents: [
        'SHIPPING_ADDRESS',
        'SHIPPING_OPTION',
        'PAYMENT_AUTHORIZATION',
        'OFFER',
      ],
    }
    if (googlePayContainer?.getExistingPromoCodes()?.length) {
      paymentRequestData['offerInfo'] = {
        offers: googlePayContainer?.getExistingPromoCodes(),
      }
    }

    return paymentRequestData
  }

  /**
   * Creates an express Google Pay order.
   * Show Google Pay payment sheet when Google Pay payment button is clicked
   * This function sets up the Google Pay express checkout process, initiates the payment data request,
   * and handles the response from Google Pay.
   *
   * @async
   * @function createExpressGooglePayOrder
   * @returns {Promise<void>} A promise that resolves when the order creation process is complete.
   *
   * @throws Will throw an error if there is an issue with loading payment data or parsing the payment response.
   */
  createExpressGooglePayOrder = async () => {
    googlePayContainer.isExpressCheckout = true
    const googlePaymentInstance = googlePayContainer.googlePaymentInstance
    const paymentsClient = googlePayContainer.expressPaymentClient
    const paymentDataRequest =
      googlePaymentInstance?.createPaymentDataRequest(
        googlePayContainer.getExpressGpayDataRequest()
      ) || {}
    const cardPaymentMethod =
      paymentDataRequest?.allowedPaymentMethods?.[0] || {}
    if (cardPaymentMethod.parameters) {
      cardPaymentMethod.parameters.billingAddressRequired = true
      cardPaymentMethod.parameters.billingAddressParameters = {
        format: 'FULL',
        phoneNumberRequired: true,
      }
    }

    trackErrorInInstana({
      errorData: paymentDataRequest,
      errorReport: 'Google Pay - PaymentDataRequest',
    })

    paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then(function (paymentData) {
        trackErrorInInstana({
          errorData: paymentData,
          errorReport: 'Google Pay - PaymentData',
        })
        googlePaymentInstance.parseResponse(
          paymentData,
          async function (err, result) {
            trackErrorInInstana({
              errorData: result,
              errorReport: 'Google Pay - Nonce Token',
            })
            if (result?.nonce) {
              result.postalCode =
                paymentData?.paymentMethodData?.info?.billingAddress
                  ?.postalCode || ''
              await googlePayContainer.handleSubmitOrder(result)
            } else {
              trackErrorInInstana({
                errorData: err,
                errorReport: 'Google Pay - Nonce Error',
              })
              console.log('Error in parsing Payment Data', err)
              await googlePayContainer.handleGooglePayClientError(err, true)
            }
          }
        )
      })
      .catch(async function (err) {
        trackErrorInInstana({
          errorData: err,
          errorReport: 'Google Pay - Payment Data Error',
        })
        console.log('Error in loading Payment Data', err)
        await googlePayContainer.handleGooglePayClientError(err, true)
      })
  }

  /**
   * Handles changes in payment data during the Google Pay checkout process.
   *
   * @param {Object} event - The event object containing payment data changes.
   * @param {Object} event.shippingAddress - The shipping address provided by the user.
   * @param {Object} event.shippingOptionData - The selected shipping option data.
   * @param {string} event.callbackTrigger - The trigger for the callback (e.g., 'INITIALIZE', 'SHIPPING_ADDRESS', 'SHIPPING_OPTION', 'OFFER').
   * @param {Object} event.offerData - The offer data containing redemption codes.
   * @returns {Promise<Object>} A promise that resolves with the updated payment data request or an error object.
   */
  onPaymentDataChanged(event) {
    console.log('Payment data changed', event)
    return new Promise(async (resolve, reject) => {
      const shippingAddress = event.shippingAddress
      const shippingOptionData = event.shippingOptionData
      let paymentDataRequestUpdate = {}
      let redemptionCodes = []
      if (
        event.callbackTrigger == 'INITIALIZE' ||
        event.callbackTrigger == 'SHIPPING_ADDRESS'
      ) {
        const profileDetail = customerContainer?.profileResponse
        const formData = {
          firstName: profileDetail?.firstName,
          lastName: profileDetail?.lastName,
          email: profileDetail?.email,
          phone: profileDetail?.phoneNumber,
          addressLine1: shippingAddress?.locality,
          country: shippingAddress?.countryCode,
          city: shippingAddress?.administrativeArea,
          state: shippingAddress?.administrativeArea,
          zip: shippingAddress?.postalCode,
        }
        await checkoutContainer.setShippingAddress(formData)
        const payload = {
          newTransactionInfo: googlePayContainer.getTotalPrice(),
          newShippingOptionParameters: {
            defaultSelectedOptionId:
              cartContainer?.cartResponse?.deliveryDetails?.methods?.find(
                method => method.isSelected
              )?.id,
            shippingOptions:
              cartContainer?.cartResponse?.deliveryDetails?.methods?.map(
                method => {
                  return {
                    id: method.id,
                    label: `${method.name} $${method.cost}`,
                    description: method.name,
                  }
                }
              ),
          },
        }
        resolve(payload)
      }

      if (event.callbackTrigger == 'SHIPPING_OPTION') {
        await checkoutContainer.updateShippingMethod(shippingOptionData.id)
        resolve({
          newTransactionInfo: googlePayContainer.getTotalPrice(),
        })
      }
      if (event?.callbackTrigger == 'OFFER') {
        if (typeof event?.offerData != 'undefined') {
          redemptionCodes = Array.from(
            new Set(event?.offerData?.redemptionCodes)
          )
          const uniqueCodes = [
            ...(redemptionCodes?.filter(
              code => !checkoutContainer?.appliedCouponCodes?.includes(code)
            ) || []),
            ...(checkoutContainer?.appliedCouponCodes?.filter(
              code => !redemptionCodes?.includes(code)
            ) || []),
          ]

          if (redemptionCodes?.length > 1) {
            return resolve({
              error: {
                reason: 'OFFER_INVALID',
                message:
                  'Oops! It looks like that promo code can’t be used right now—you can only use one promo code at a time. Please apply the desired promo code to continue',
                intent: 'OFFER',
              },
              newOfferInfo: {
                offers: googlePayContainer.getExistingPromoCodes(),
              },
            })
          }
          if (uniqueCodes?.length > 0) {
            let couponCode = uniqueCodes[0]
            const message = googlePayContainer
              ?.getExistingPromoCodes()
              ?.some(
                code =>
                  code?.redemptionCode?.toLowerCase() ===
                  couponCode?.toLowerCase()
              )
              ? `promo code ${couponCode} already applied.`
              : `${couponCode} promo code is not valid.`
            if (
              (redemptionCodes?.length ?? 0) >
              (checkoutContainer?.appliedCouponCodes?.length ?? 0)
            ) {
              const addPromoResponse = await checkoutContainer.addPromo(
                couponCode
              )
              trackCheckoutCouponCodeEvents({
                coupon: couponCode,
              })
              if (checkoutContainer.isSuccessResponse(addPromoResponse)) {
                resolve({
                  newTransactionInfo: googlePayContainer.getTotalPrice(),
                })
              } else {
                resolve({
                  error: {
                    reason: 'OFFER_INVALID',
                    message: message,
                    intent: 'OFFER',
                  },
                  newOfferInfo: {
                    offers: googlePayContainer.getExistingPromoCodes(),
                  },
                })
              }
            } else if (
              (redemptionCodes?.length ?? 0) <
              (checkoutContainer?.appliedCouponCodes?.length ?? 0)
            ) {
              const deletePromoResponse = await checkoutContainer.deletePromo(
                couponCode
              )
              if (checkoutContainer.isSuccessResponse(deletePromoResponse)) {
                resolve({
                  newTransactionInfo: googlePayContainer.getTotalPrice(),
                  newOfferInfo: {
                    offers: googlePayContainer.getExistingPromoCodes(),
                  },
                })
              } else {
                resolve({
                  error: {
                    reason: 'OFFER_INVALID',
                    message: message,
                    intent: 'OFFER',
                  },
                  newOfferInfo: {
                    offers: googlePayContainer.getExistingPromoCodes(),
                  },
                })
              }
            } else {
              resolve({
                newTransactionInfo: googlePayContainer.getTotalPrice(),
                newOfferInfo: {
                  offers: googlePayContainer.getExistingPromoCodes(),
                },
              })
            }
          } else {
            // paymentDataRequestUpdate.error = 'No unique promo codes'
            console.log('No unique promo codes')
          }
        }
      }
      resolve({})
    })
  }

  /**
   * Handles the payment authorization event from Google Pay.
   *
   * @param {Object} event - The payment authorization event object.
   * @returns {Promise<Object>} A promise that resolves when the payment process is complete.
   */
  onPaymentAuthorized(event) {
    const { tokenizationData = {} } = event?.paymentMethodData || {}
    const { token = '' } = tokenizationData
    const tokenInfo = JSON.parse(token) || {}
    const nonceToken = tokenInfo?.androidPayCards?.[0]?.nonce || ''
    if (!nonceToken) {
      return
    }
    return new Promise(async function (resolve, reject) {
      try {
        const handleChange = async () => {
          const fullName = event?.shippingAddress?.name || ''
          const [firstName, ...lastNameParts] = fullName?.split(' ')
          const lastName = lastNameParts?.join(' ')
          const formData = {
            firstName: firstName,
            lastName: lastName,
            email: event?.email,
            phone: event?.shippingAddress?.phoneNumber,
            addressLine1: event?.shippingAddress?.address1,
            country: event?.shippingAddress?.countryCode,
            city: event?.shippingAddress?.administrativeArea,
            state: event?.shippingAddress?.administrativeArea,
            zip: event?.shippingAddress?.postalCode,
          }
          await checkoutContainer?.setShippingAddress(formData)
        }
        await handleChange()

        const paymentResponse =
          await checkoutContainer.handleExpressGpayPostData('GOOGLEPAY')
        if (checkoutContainer.isSuccessResponse(paymentResponse)) {
          const reviewResponse = await checkoutContainer.reviewOrder()
          trackErrorInInstana({
            errorData: reviewResponse,
            errorReport: 'Google Pay - Review Order Response',
          })
          if (!checkoutContainer.isSuccessResponse(reviewResponse)) {
            await googlePayContainer.handleGooglePayClientError(
              reviewResponse,
              true
            )
          } else {
            await googlePayContainer.handleSubmitOrder({
              nonce: nonceToken,
            })
          }
        } else {
          await googlePayContainer.handleGooglePayClientError(
            paymentResponse,
            true
          )
        }
        overlayState?.hideLoader()
        return resolve({ transactionState: 'SUCCESS' })
      } catch (error) {
        overlayState?.hideLoader()
        return resolve({ transactionState: 'ERROR' })
      }
    })
  }
}
const googlePayContainer = new GooglePayContainer()

export { GooglePayContainer, googlePayContainer }
export default googlePayContainer
