import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Card, Badge } from 'react-bootstrap'
import { CustomPrice, ImageComponent } from 'src/views/components'
import { currencyFormat, i18nTranslate, isExpressCheckout } from 'src/utils'
import { customerContainer } from 'src/models'
import {
  getSubscriptionFrequencyLabel,
  getSubscriptionLabel,
} from '../../FlexibleSubscription/ProductCard/fixture'
import ViewCartRefreshIcon from 'src/views/components/Icons/ViewCartRefreshIcon'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import './styles.scss'

@observer
class MiniCartProductBlock extends React.Component {
  render() {
    const {
      name = '',
      imageURL = '',
      quantity = 0,
      combineFreeGiftQuantity = 0,
      isPreOrder = false,
      isSubscription = false,
      itemTotalOriginalPrice = 0,
      itemTotalPriceAfterDiscount = 0,
      itemTotalPV = 0,
      itemTotalPV_AfterDiscount = 0,
      subscriptionFrequency = '',
      overAllDiscount = 0,
    } = this.props

    const userRole = customerContainer.profileResponse?.accounts?.type || ''
    const priceFreePromotion = itemTotalPriceAfterDiscount == 0 ? true : false
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false

    const miniCartImage = () => {
      return (
        <>
          {/* <Card.Img
        data-testid="qa-product-image"
        src={imageURL}
        alt={name}
        onError={e => (
          (e.target.onerror = null), (e.target.src = imagePlaceholder)
        )}
      /> */}
          <ImageComponent
            testid="qa-product-image"
            src={imageURL}
            alt={name}
            height="100px"
            width="100px"
          />
        </>
      )
    }
    const cartItemContent = () => {
      return (
        <Row
          noGutters
          className="justify-content-between mini-cart-product-info-row">
          {isSubscription &&
            !isExpressCheckout() &&
            isFlexibleSubscriptionEnabled &&
            subscriptionFrequency && (
              <Badge className="subscription-frequency-badge" pill>
                <ViewCartRefreshIcon
                  height={8}
                  width={8}
                  className="mr-1"
                  fillColor="#252525"
                />
                {getSubscriptionFrequencyLabel(subscriptionFrequency)}
              </Badge>
            )}
          <Col xs={12} md={isExpressCheckout() ? 5 : 12}>
            <Card.Title
              className="mb-1 product-name-flex-subs text-truncate paragraph-m"
              data-testid="qa-product-name">
              {name}
              {isSubscription &&
                isExpressCheckout() &&
                isFlexibleSubscriptionEnabled &&
                subscriptionFrequency && (
                  <Col xs={12} className="p-0 mt-1">
                    <Badge className="subscription-frequency-badge" pill>
                      <ViewCartRefreshIcon
                        height={8}
                        width={8}
                        className="mr-1"
                        fillColor="#252525"
                      />
                      {getSubscriptionFrequencyLabel(subscriptionFrequency)}
                    </Badge>
                  </Col>
                )}
            </Card.Title>
          </Col>
          {isExpressCheckout() ? (
            <Col
              xs={6}
              md={3}
              className="minicart-product d-flex align-items-top pl-2">
              <span data-testid="qa-checkout-product-quantity">
                {i18nTranslate('product.qty', 'Qty', {
                  nameSpace: 'ssr-resource',
                })}
              </span>
              <span>:</span>
              <span data-testid="qa-checkout-product-quantity-value">
                {quantity}
              </span>
            </Col>
          ) : (
            <div className="mt-1">
              <span data-testid="qa-checkout-product-quantity">
                {i18nTranslate('product.quantity', 'Quantity', {
                  nameSpace: 'ssr-resource',
                })}
              </span>
              <span>:</span>
              <span data-testid="qa-checkout-product-quantity-value">
                {quantity}
              </span>
            </div>
          )}

          {userRole?.includes('Brand Affiliate') ? (
            <>
              {isExpressCheckout() ? (
                <Col xs={6} md={3} className="">
                  <CustomPrice
                    custompriceValue={itemTotalOriginalPrice}
                    customDiscountPriceValue={itemTotalPriceAfterDiscount}
                    custompriceLabel={'Price'}
                    isCurrencySymbol={true}
                    dataTestId="qa-pdp-price"
                    isSubscription={isSubscription}
                    title="order_overview"
                    priceFreePromotion={priceFreePromotion}
                  />
                </Col>
              ) : (
                <div className="">
                  <CustomPrice
                    custompriceValue={itemTotalOriginalPrice}
                    customDiscountPriceValue={itemTotalPriceAfterDiscount}
                    custompriceLabel={'Price'}
                    isCurrencySymbol={true}
                    dataTestId="qa-pdp-price"
                    isSubscription={isSubscription}
                    title="order_overview"
                    priceFreePromotion={priceFreePromotion}
                  />
                </div>
              )}

              <Col xs={12} className="font-weight-bold">
                <CustomPrice
                  custompriceValue={itemTotalPV}
                  customDiscountPriceValue={itemTotalPV_AfterDiscount}
                  custompriceLabel={i18nTranslate('pdp.svprice', 'SV', {
                    nameSpace: 'ssr-resource',
                  })}
                  dataTestId="qa-pdp-pvprice"
                  isSubscription={isSubscription}
                />
                {/* <CustomPrice
            custompriceValue={cv}
            customDiscountPriceValue={cvAfterDiscount}
            custompriceLabel={i18nTranslate('pdp.cvprice', 'CV')}
            dataTestId="qa-pdp-pvprice"
            isSubscription={isSubscription}
          /> */}
              </Col>
            </>
          ) : (
            <Col xs={6} md={isExpressCheckout() ? 4 : 6}>
              <CustomPrice
                custompriceValue={itemTotalOriginalPrice}
                customDiscountPriceValue={itemTotalPriceAfterDiscount}
                custompriceLabel={'Price'}
                isCurrencySymbol={true}
                dataTestId="qa-pdp-price"
                isSubscription={isSubscription}
                title="order_overview"
              />
            </Col>
          )}
          {isFlexibleSubscriptionEnabled &&
            isSubscription &&
            overAllDiscount > 0 && (
              <span className="product-promotion mt-2 mb-2 px-2 text-right">
                {`${i18nTranslate(
                  'checkout.subscriptionDiscountTextStart',
                  'You save'
                )} ${currencyFormat({
                  price: overAllDiscount,
                })} ${i18nTranslate(
                  'checkout.subscriptionDiscountTextEnd',
                  'for subscribing!'
                )}`}
              </span>
            )}
          {isPreOrder && (
            <Badge variant="info" className="mt-1">
              {i18nTranslate('checkout.preOrder', 'Preorder')}
            </Badge>
          )}
        </Row>
      )
    }

    return (
      <div
        className="mini-cart-product  border-bottom pb-3 mb-3"
        data-testid="qa-products-container">
        {isExpressCheckout() ? (
          <div
            class={`express-cart-item ${
              isFlexibleSubscriptionEnabled ? 'mini-cart-flex-subs' : ''
            }`}>
            <div className="cart-image">{miniCartImage()}</div>
            <div className="mini-cart-product-info w-100">
              {cartItemContent()}
            </div>
          </div>
        ) : (
          <Row
            noGutters
            className={`m-0 ${
              isFlexibleSubscriptionEnabled ? 'mini-cart-flex-subs' : ''
            }`}>
            <Col xs={12} className="p-0">
              <Row noGutters>
                <Col xs={3} className="expressCheckoutSummary">
                  {miniCartImage()}
                </Col>
                <Col xs={9} className="pl-2 pr-0 mini-cart-product-info">
                  {cartItemContent()}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

export { MiniCartProductBlock }
export default MiniCartProductBlock
