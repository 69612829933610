export const renderFormInput = () => {
  const fieldConfig = [
    [
      {
        name: 'PONumber',
        type: 'text',
        columnType: {
          lg: '4',
          md: '6',
        },
        customClassName: 'px-2 pt-4',
        dataTestId: 'qa-purchase-order',
      },
    ],
  ]
  return fieldConfig
}
