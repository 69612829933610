import React from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap'
import {
  APPConfig,
  IS_BROWSER,
  getLocalStorage,
  getSeparateCartAndCheckout,
} from 'config/appConfig'
import { sessionContainer, cartContainer } from 'src/models'
import {
  getShoppingContext,
  isB2BAccount,
  setLocalStorage,
  trackGTMEvents,
  convertToBoolean,
  getLiveEventStatus,
  isGuestCheckoutEnabledForWeb,
  isExpressCheckout,
} from 'src/utils'
import { Link } from 'react-router-dom'
import { SignInForm } from 'src/views/components'
import { pageNames } from 'src/routes/pathParams'
import { signInwithRedirect } from 'src/utils/signInUtils'
import { i18nTranslate, jitsuSignUpRedirect } from 'src/utils'
import GuestCheckoutV2 from './GuestCheckoutV2'
import GuestCheckout from './GuestCheckout'
import './styles.scss'

class SignInAccordion extends React.Component {
  handleSignIn = async formData => {
    const { password } = formData
    const email = isB2BAccount() ? formData.email : formData.userName

    const response = await sessionContainer.login({
      identity: email,
      password,
    })

    if (sessionContainer.isSuccessResponse(response)) {
      if (getSeparateCartAndCheckout() === true) {
        this.props.history.push(pageNames.checkout)
      } else {
        if (typeof window === 'object') {
          window.location.reload()
        }
      }
    }
  }
  enableJITSU = convertToBoolean(APPConfig?.getAppConfig()?.enableJITSU)
  signupcheck = () => {
    if (this.enableJITSU) {
      jitsuSignUpRedirect()
    } else {
      trackGTMEvents({ event: 'Signup', eventType: 'simpCustColdFormStart' })
      this.props?.onContinue?.()
      setLocalStorage('mysiteCheckout', 'mysiteCheckout')
    }
  }

  render() {
    const checkout = 'mysiteCheckout'
    const signInType =
      APPConfig.getConfigFromJSON()?.defaultProps?.signInType || 'default'
    let isCartEligibleForGuest = false
    const isSubscribed = cartContainer?.cartResponse?.items?.some(item => {
      return item?.isSubscription || false
    })

    //Todo: Validate in nativeApp flow once for guest checkout
    let enableGuestForWeb = isGuestCheckoutEnabledForWeb()
    const enableGuestForPoMysite = convertToBoolean(
      APPConfig?.getAppConfig()?.enableGuestCheckoutForPOMysite
    )
    const enableNewGuestModal =
      convertToBoolean(APPConfig.getAppConfig()?.enableNewGuestModal) || false
    let isToShowGuestCheckoutForm = false
    if (IS_BROWSER && typeof window !== 'undefined') {
      const shoppingContext = getShoppingContext() || {}
      const wholesale =
        shoppingContext?.overrides?.showWholeSalePricing || false
      const isMySiteOrPO = Object.keys(shoppingContext).length > 0

      if (!isSubscribed) {
        if (
          (isMySiteOrPO && !wholesale && enableGuestForPoMysite) ||
          (!isMySiteOrPO && enableGuestForWeb)
        ) {
          isToShowGuestCheckoutForm = true //To check pomysite or web
          const isUserTypeNotEligible =
            cartContainer?.isProductUserTypeNotEligibleForGuestCheckout() //should be false
          isCartEligibleForGuest = !isUserTypeNotEligible //To check whether cart is eligible for guest checkout
        } else {
          isCartEligibleForGuest = false
          isToShowGuestCheckoutForm = false
        }
      } else {
        isCartEligibleForGuest = false
        isToShowGuestCheckoutForm = false
      }
    }
    const currentPageUrl = getLocalStorage('curPageUrl') || {}
    const enableLiveEvent = getLiveEventStatus()

    return isCartEligibleForGuest &&
      isToShowGuestCheckoutForm &&
      (currentPageUrl?.includes('viewCart') ||
        (isExpressCheckout() &&
          currentPageUrl?.includes(
            pageNames.expressCheckout || pageNames?.expressPDP
          ))) ? (
      enableNewGuestModal ? (
        <GuestCheckoutV2 checkoutUrl={this.props.checkoutUrl} />
      ) : (
        <GuestCheckout checkoutUrl={this.props.checkoutUrl} />
      )
    ) : (
      <Container
        className={`py-2 checkout-signin-container ${
          enableLiveEvent === 'true' ? 'live-event-enabled' : ''
        }`}>
        <Row noGutters>
          <Col
            sm={12}
            xs={12}
            lg={enableLiveEvent !== 'true' ? 6 : 12}
            className="signin-checkout-container pl-4 pr-4 signin-container">
            <h6 className="text-left mb-4 text-uppercase font-weight-bold signin-title">
              {i18nTranslate('signin.heading', 'Sign-In')}
            </h6>
            <h6 className="mt-0 ">
              {i18nTranslate(
                'signin.signInMessage',
                'Have an account already? Please sign in.'
              )}
            </h6>

            {signInType === 'default' ? (
              <SignInForm onSignIn={this.handleSignIn} />
            ) : (
              <>
                <br />
                <button
                  className="okta-button"
                  data-testid="qa-okta-login"
                  onClick={() => signInwithRedirect(checkout)}>
                  {i18nTranslate('signin.okta', 'Sign In')}
                </button>
              </>
            )}
          </Col>

          {/* <div className="vl"></div> */}
          {enableLiveEvent !== 'true' && (
            <Col
              sm={12}
              xs={12}
              lg={6}
              className="signup-checkout-container pl-4 pr-4">
              <h6 className="text-left mb-4 text-uppercase font-weight-bold signin-title">
                {i18nTranslate('header.signUp', 'Sign-Up', {
                  nameSpace: 'ssr-resource',
                })}
              </h6>
              <h6 className="mt-0 ">
                {i18nTranslate(
                  'signin.signupMessage',
                  'New user? Please Sign up to continue '
                )}
              </h6>
              <br />

              <Link
                role="button"
                aria-label={i18nTranslate(
                  'signin.createAccount',
                  'Create Account'
                )}
                to={this.enableJITSU ? '#' : pageNames.signUp}>
                <Button
                  block
                  variant="outline-primary"
                  data-testid="qa-createaccount-createbutton"
                  onClick={this.signupcheck}
                  className="create-account-button">
                  {i18nTranslate('signin.createAccount', 'Create Account')}
                </Button>
              </Link>
            </Col>
          )}
        </Row>
      </Container>
    )
  }
}

export { SignInAccordion }
export default SignInAccordion
