import React from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { i18nTranslate } from 'src/utils'
import { Row, Col, Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { ProductOption, Price, Quantity } from 'src/views/components'
import { productDeps, dateFormatDeps } from 'src/deps'
import { PDPBundleProductType, enableQuantityForBundle } from 'config/appConfig'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { getImageFromProperty } from 'src/utils'
import './styles.scss'

@observer
class SubProduct extends React.Component {
  handleSkuSelection = selectedProp => {
    const { subProduct, index, type, handleSkuSelection } = this.props
    subProduct.skuSelected[selectedProp.type] = selectedProp.value
    const productChanges = productDeps.changeSku({
      product: subProduct,
      selectedProp,
    })

    handleSkuSelection(productChanges, index, type)
    productState.isBundleSkuChange = true
    productState.makePromotionCallsForBundleKits(
      productState.product,
      productState.productCount
    )
  }
  showErrorForSkuSelection = type => {
    const ErrorText = i18nTranslate('product.selectSkuError', 'Select the', {
      nameSpace: 'ssr-resource',
    })
    const errorView =
      this.props.subProduct.skuSelected[type] === '' ? (
        <div className="font-weight-bold text-danger">
          {ErrorText} {type}
        </div>
      ) : (
        <React.Fragment />
      )
    return errorView
  }
  addOrRemoveBundleItem = () => {
    const { subProduct } = this.props
    if (productDeps.optionalData.includes(subProduct.identifier)) {
      productDeps.optionalData.splice(
        productDeps.optionalData.indexOf(subProduct.identifier),
        1
      )
    } else {
      productDeps.optionalData.push(subProduct.identifier)
    }
    productState.makePromotionCallsForBundleKits(
      productState.product,
      productState.productCount
    )
  }

  renderImage = () => {
    const { subProduct } = this.props
    const productImgUrl =
      subProduct?.properties?.imageURL ||
      getImageFromProperty(subProduct?.properties)
    const imageUrl =
      productImgUrl || getImageFromProperty(subProduct?.selectedSku?.properties)
    const subProductName = subProduct?.properties?.name || ''

    return (
      <Row className="kitProduct" noGutters>
        <Link
          className="image-wrapper d-flex justify-content-center align-items-center"
          aria-label="productimage"
          to={productDeps.getSubProductLink(subProduct)}>
          <LazyLoadImage
            data-testid="qa-product-image"
            className={'mw-100 mh-100'}
            src={imageUrl || imagePlaceholder}
            alt={subProductName ? subProductName : 'sub product image'}
            onError={e => {
              if (e.target.src != imagePlaceholder) {
                e.target.onerror = null
                e.target.src = imagePlaceholder
              }
            }}
          />
        </Link>
      </Row>
    )
  }

  renderDetails = () => {
    const { subProduct, type = '' } = this.props
    return (
      <div>
        {/* <Row noGutters className="font-weight-bold">
          {subProduct?.previousSku?.properties?.brand || ''}
        </Row> */}
        <Row noGutters data-testid="qa-product-name">
          <Link
            className="text-dark"
            to={productDeps.getSubProductLink(subProduct)}>
            <span>
              <h6 className="font-weight-bold">
                {subProduct?.previousSku?.properties?.name || ''}
              </h6>
            </span>
          </Link>
        </Row>
        {!enableQuantityForBundle && (
          <Row noGutters data-testid="qa-product-quantity-wrapper">
            <span className="font-weight-bold">
              {i18nTranslate('bundle.fixedQuantityTitle', 'Quantity', {
                nameSpace: 'ssr-resource',
              })}
              :
            </span>
            <span className="pl-2" data-testid="qa-product-quantity">
              {subProduct?.properties?.quantity}
            </span>
          </Row>
        )}
        <ProductOption
          options={subProduct.productOption}
          handleSkuSelection={this.handleSkuSelection}
          showError={this.showErrorForSkuSelection}
          skuSelected={subProduct.skuSelected}
        />
        {type.toLowerCase() != 'optional' && this.renderInventoryStatus()}
      </div>
    )
  }

  renderDescription = (renderTitle = true) => {
    const { subProduct } = this.props
    const description = subProduct?.properties?.description || ''

    const shortDescription =
      subProduct?.properties?.shortDescription || description

    return (
      shortDescription && (
        <div>
          {renderTitle && (
            <h6 className="font-weight-bold">
              {i18nTranslate('bundle.productDetailTitle', 'Product Details', {
                nameSpace: 'ssr-resource',
              })}
            </h6>
          )}
          <Row
            noGutters
            className="pb-2 text-justify"
            dangerouslySetInnerHTML={{
              __html: `${shortDescription.split(' ').slice(0, 20).join(' ')}
          ${shortDescription.split(' ').length > 20 ? '...' : ''}`,
            }}
          />
        </div>
      )
    )
  }

  // @##pdp_button_disable_and_status_display_logic
  shouldDisableAddToBundle = () => {
    /* CX12-8823 handle OOS for bundle and kit */

    const { subProduct } = this.props
    const inventory = subProduct?.selectedSku?.inventory?.toLowerCase() || ''
    const status =
      subProduct?.selectedSku?.properties?.status?.toLowerCase() || ''
    // todo for backorder and atpqty checks, inactive item checks
    return (
      status === 'inactive' ||
      inventory === 'unavailable' ||
      inventory === 'out of stock'
    )
  }

  getBackOrderInfo = props => {
    const {
      productCount = 0,
      atpQty = 0,
      outOfStockThreshold = 0,
      inventoryProperties,
      backOrderedQty,
    } = props || {}
    let inventoryStatusView = ''
    let currentDateTimeStamp = Date.now()
    let backorderTime = ''
    let flagInventory = false

    if (
      inventoryProperties?.expectedBackOrderAvailabilityDate?.toString?.()
        ?.length <= 10
    ) {
      backorderTime =
        inventoryProperties.expectedBackOrderAvailabilityDate + '000'
    } else {
      backorderTime = inventoryProperties.expectedBackOrderAvailabilityDate
    }
    backorderTime = Number(backorderTime)
    /**
     * @Note
     * EQH-94 - Back order date shows up incorrectly on EQ
     * Changed to UTC Format, as per BackEnd Team Elangovan's Request
     */
    let { date, month, year } = dateFormatDeps.convertTimeStampToDate(
      backorderTime,
      true
    )
    const requestedQtyNotAvailable = i18nTranslate(
      'pdp.OOSQtyTxt',
      'OUT OF STOCK',
      {
        nameSpace: 'ssr-resource',
      }
    )

    // @##backorder_logic_pdp_message_or_button

    if (backOrderedQty > 0) {
      let expectedbackorder = productCount - (atpQty - outOfStockThreshold)
      if (
        backorderTime >= currentDateTimeStamp &&
        expectedbackorder <= backOrderedQty
      ) {
        inventoryStatusView =
          i18nTranslate('backordered', 'Ships by', {
            nameSpace: 'ssr-resource',
          }) +
          ' ' +
          `${month}/${date}/${year}`
      } else {
        flagInventory = true
        inventoryStatusView = requestedQtyNotAvailable
      }
    } else {
      flagInventory = true
      inventoryStatusView = requestedQtyNotAvailable
    }
    return { inventoryStatusView, flagInventory }
  }

  // @##pdp_button_disable_and_status_display_logic
  getInventoryStatus = () => {
    const { subProduct } = this.props
    const status = subProduct?.selectedSku?.inventory?.toLowerCase() || ''
    const productStatus =
      subProduct?.selectedSku?.properties?.productStatus?.toLowerCase() || ''
    const inventoryProperties =
      subProduct?.selectedSku?.inventoryProperties || {}
    const atpQty = inventoryProperties.atpQty || ''
    // subProduct?.properties?.quantity has the qty of the subproduct
    // we need to multiply with current incrementor value
    // and then compare it with the stock
    const productCount =
      (productState.productCount || 1) * (subProduct?.properties?.quantity || 1)
    console.log('stock', productCount)
    let outOfStockThreshold = inventoryProperties.outOfStockThreshold || ''
    let backOrderedQty = inventoryProperties.backOrderedQty || ''
    let inventoryStatusView = ''
    let flagInventory = ''

    if (status === 'test') {
      inventoryStatusView = 'This is a test sku'
    }
    switch (productStatus.toLowerCase()) {
      case 'replacement':
        inventoryStatusView = i18nTranslate(
          'bundle.replacement',
          'This is a replacement product and not available for purchase',
          {
            nameSpace: 'ssr-resource',
          }
        )
        flagInventory = true
        break
      case 'preview product':
      case 'preview':
        inventoryStatusView = i18nTranslate(
          'bundle.preview',
          'Preview product and will be launched soon',
          {
            nameSpace: 'ssr-resource',
          }
        )
        flagInventory = true
        break
      case 'stopped':
        inventoryStatusView = i18nTranslate(
          'bundle.stopped',
          'This product is temporarily stopped',
          {
            nameSpace: 'ssr-resource',
          }
        )
        flagInventory = true
        break
      case 'discontinued':
        inventoryStatusView = i18nTranslate(
          'bundle.discontinuedV2Message',
          'Discontinued - This product or promotion has been discontinued',
          {
            nameSpace: 'ssr-resource',
          }
        )
        flagInventory = true
        break
    }
    // }
    const dangerousGoods =
      subProduct?.selectedSku?.properties?.dangerousGoods === 'true'

    if (inventoryStatusView === '') {
      if (status?.toLowerCase() === 'unavailable') {
        inventoryStatusView = i18nTranslate('coming.Soon', 'Coming soon..', {
          nameSpace: 'ssr-resource',
        })
        flagInventory = true
      } else if (status?.toLowerCase() === 'out of stock') {
        inventoryStatusView = i18nTranslate('cart.outOfStock', 'OUT OF STOCK')
        flagInventory = true
      } else if (atpQty <= 0 && backOrderedQty <= 0) {
        inventoryStatusView = i18nTranslate(
          'item.notAvailable',
          'Item currently not available'
        )
        flagInventory = true
      } else if (dangerousGoods) {
        inventoryStatusView = i18nTranslate(
          'pdp.dangerousGoodsError',
          'Dangerous Goods cannot be added to Cart or Subscribed',
          {
            nameSpace: 'ssr-resource',
          }
        )
        flagInventory = true
      } else {
        flagInventory = false
        if (productCount > atpQty - outOfStockThreshold) {
          return this.getBackOrderInfo({
            productCount,
            atpQty,
            outOfStockThreshold,
            inventoryProperties,
            backOrderedQty,
          })
        }
      }
    }

    return { inventoryStatusView, flagInventory }
  }

  renderInventoryStatus = (flagInventory = '', inventoryStatusView = '') => {
    const { subProduct } = this.props
    if (inventoryStatusView) {
      return (
        <Row noGutters data-testid="qa-add-item-status">
          <p
            className={`${
              !flagInventory
                ? 'availability-text text-success font-weight-bold p-0 mt-1'
                : 'availability-text font-weight-bold text-bundle-danger p-0 mt-1'
            }`}>
            {inventoryStatusView}
          </p>
        </Row>
      )
    }
    return ''
  }

  renderOptionalProductButton = (buttonClassName = '') => {
    const { subProduct } = this.props
    const { flagInventory = '', inventoryStatusView = '' } =
      this.getInventoryStatus()
    const selected = productDeps?.optionalData?.includes(subProduct.identifier)
    return (
      <div>
        <Row noGutters className="py-2" data-testid="qa-add-item">
          <Button
            variant=""
            disabled={flagInventory}
            onClick={this.addOrRemoveBundleItem}
            className={`${buttonClassName ? buttonClassName : 'btn-primary'}`}>
            {!selected
              ? i18nTranslate('bundle.addToBundle', 'Add to bundle', {
                  nameSpace: 'ssr-resource',
                })
              : i18nTranslate('bundle.removeFromBundle', 'Remove from bundle', {
                  nameSpace: 'ssr-resource',
                })}
          </Button>
        </Row>
        {this.renderInventoryStatus(flagInventory, inventoryStatusView)}
      </div>
    )
  }

  handleQuantityChange = count => {
    const { subProduct } = this.props
    const isDataAvl = productDeps?.optionalData?.includes(subProduct.identifier)
    if (count === 0 || (count === 1 && !isDataAvl)) {
      this.addOrRemoveBundleItem()
    }
    if (count > 0) {
      subProduct['quantity'] = count
      this.props.updateBundleOptionalProducts()
    }
  }

  renderPriceAndQty = () => {
    const { subProduct, type = '' } = this.props
    return (
      <div>
        <Price
          classStyles={'mb-3'}
          transactionPrice={subProduct?.previousSku?.prices?.[0] || ''}
        />
        <Quantity
          minValue={type === 'mandatory' ? 1 : 0}
          defaultValue={type === 'mandatory' ? 1 : 0}
          onQuantityChange={this.handleQuantityChange}
        />
      </div>
    )
  }

  render() {
    const { type = '', subProduct } = this.props
    if (PDPBundleProductType === 'grid') {
      const selected = productDeps?.optionalData?.includes(
        subProduct.identifier
      )
      const buttonClassName = `w-100 ${
        selected ? 'btn-outline-primary' : 'btn-primary'
      }`
      return (
        <Row noGutters className="p-2 w-100 h-100 border flex-column">
          <div>{this.renderImage()}</div>
          <div className="pt-2 d-flex flex-column flex-grow-1">
            {this.renderDetails()}
            <div
              data-testid="qa-product-details"
              className="d-flex flex-column flex-grow-1 justify-content-between">
              {this.renderDescription(false)}
              {enableQuantityForBundle && this.renderPriceAndQty()}
              {type.toLowerCase() === 'optional' &&
                this.renderOptionalProductButton(buttonClassName)}
            </div>
          </div>
        </Row>
      )
    } else {
      return (
        <Row
          noGutters
          className="subproduct-wrapper border-top border-dark py-2 w-100">
          <Col lg={4} md={6} sm={12} xs={12}>
            {this.renderImage()}
          </Col>
          <Col lg={8} md={6} sm={12} xs={12} className="px-md-3 pt-2 pt-md-0">
            <Row className="pt-md-2">
              <Col lg={6} xs={12}>
                {this.renderDetails()}
              </Col>
              <Col
                lg={6}
                xs={12}
                className="px-md-3"
                data-testid="qa-product-details">
                {this.renderDescription()}
                {enableQuantityForBundle && this.renderPriceAndQty()}
                {type.toLowerCase() === 'optional' &&
                  this.renderOptionalProductButton()}
              </Col>
            </Row>
          </Col>
        </Row>
      )
    }
  }
}

export { SubProduct }
