import React from 'react'
import { Form } from 'react-bootstrap'
import isEmpty from 'lodash/isEmpty'
import { FieldArray } from 'formik'

const SelectWithCheckbox = props => {
  const {
    values,
    isInvalid,
    name,
    disabled,
    handleRef,
    handleDropDownClick,
    index,
    options,
    'data-testid': dataTestId,
  } = props

  const customClassName = index !== undefined ? index : ''
  const checkFormValuesLength =
    !isEmpty(values) && values[name] !== undefined ? values[name].length : 0
  const errorClassName =
    isInvalid === false && checkFormValuesLength === 0
      ? ''
      : isInvalid === true
      ? 'is-invalid'
      : 'is-valid'

  const selectOPtionName =
    values[name] && values[name].length
      ? Object.values(Object.assign({}, ...values[name])).toString()
      : `Select ${name}`
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div
          className={`multiselect ${errorClassName} w-100`}
          name={name}
          ref={handleRef}>
          <div
            className={`selectBox`}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              handleDropDownClick(e)
            }}>
            <Form.Control
              as={'select'}
              className={`select-wrapper font-weight-normal text-truncate ${errorClassName}`}
              disabled={disabled}>
              <option>{selectOPtionName}</option>
            </Form.Control>
            <div
              className="overSelect"
              name={name}
              id={name}
              data-testid={dataTestId}
            />
          </div>
          <div
            id={`checkboxes${customClassName}`}
            className="checkBoxes col-lg-12"
            name={name}>
            {options.map((list, index) => {
              const findSelectedListIndex =
                !isEmpty(values) &&
                values[name] !== undefined &&
                name !== undefined &&
                values[name].findIndex(
                  checkBoxList =>
                    checkBoxList[`${list.label}_${index}`] === list.label
                )
              const defaultChecked =
                findSelectedListIndex !== -1 && findSelectedListIndex !== false
                  ? true
                  : false

              return (
                <Form.Label
                  className="dropdown-checkbox-label d-flex pl-2"
                  data-testid="qa-options"
                  key={`${list.label}-${index}`}>
                  <Form.Check
                    className="dropdown-checkbox-wrapper"
                    name={name}
                    label={list.label}
                    key={`${list.label}-${index}`}
                    type="checkbox"
                    id={`${list.label}-${index}`}
                    onClick={e => {
                      // e.preventDefault()
                      e.stopPropagation()
                    }}
                    value={list.value}
                    onChange={e => {
                      e.stopPropagation()
                      if (e.target.checked) {
                        const arrayHelpersObjectKey = `${list.label}_${index}`
                        arrayHelpers.push({
                          [arrayHelpersObjectKey]: list.label,
                        })
                      } else {
                        const unSelectedIndex = values[name].findIndex(
                          unSelectedList =>
                            unSelectedList[`${list.label}_${index}`] ===
                            list.label
                        )
                        arrayHelpers.remove(unSelectedIndex)
                      }
                    }}
                    checked={defaultChecked}
                  />
                </Form.Label>
              )
            })}
          </div>
        </div>
      )}
    />
  )
}

export { SelectWithCheckbox }
