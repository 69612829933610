import React from 'react'

const CheckoutNuskinIcon = (props = {}) => {
  const {
    className = '',
    width = '',
    height = '',
    fill = 'none',
    viewBox = '0 0 51 52',
    dataTestId = 'view-cart-nuskin-icon',
    ariaLabel = 'View Cart Nuskin Icon',
    fillColor = '',
  } = props || {}
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      className={className}
      viewBox={viewBox}
      data-testid={dataTestId}
      aria-label={ariaLabel}>
      <path
        d="M50.041 20.6645C49.3218 18.9872 48.2764 17.4698 46.9662 16.2003C44.1811 13.5805 40.2549 12.5601 36.5481 13.4929C32.8413 14.4257 29.8642 17.1832 28.6486 20.8098C28.0296 22.5024 27.7126 24.2906 27.7116 26.0929C27.7116 33.9648 27.7116 41.8411 27.7116 49.7216C27.7116 51.0424 27.7116 51.0424 26.2599 50.9763C26.2599 50.7254 26.2599 50.4481 26.2599 50.1838C26.2599 41.2818 26.2599 32.3841 26.2599 23.491C26.0387 17.9401 29.4097 12.8766 34.6136 10.9436C35.2421 10.724 35.886 10.5518 36.5405 10.4284C37.1737 10.2964 37.8602 10.2435 38.4934 10.1643C37.6885 6.47933 30.9842 2.23962 25.547 1.98868C19.7457 1.84925 14.3682 5.01885 11.677 10.1643C12.2972 10.2435 12.9044 10.2832 13.4849 10.3888C18.8789 11.3104 23.0428 15.6436 23.7525 21.0739C23.8991 22.0622 23.974 23.0598 23.9766 24.0589C23.9766 32.7498 23.9766 41.4403 23.9766 50.1311C23.9766 50.8178 23.8182 51.1085 23.0793 51.0291C22.3401 50.95 22.4194 50.7254 22.4194 50.2369C22.4194 47.4762 22.4194 44.7158 22.4194 41.9422C22.4194 36.4742 22.4194 31.0062 22.4194 25.5382C22.4658 22.3249 21.2911 19.214 19.1332 16.8342C16.5801 14.0727 12.8165 12.7711 9.10424 13.3655C5.39204 13.96 2.22225 16.3718 0.657194 19.7928C0.485803 20.123 0.340436 20.4664 0.103027 20.9551C2.27367 8.88027 12.7463 0.076315 25.0052 0.0212557C37.2638 -0.0338037 47.8152 8.6757 50.0937 20.7305"
        fill={fillColor}
      />
      <path
        d="M33.7936 49.431C33.7936 49.1801 33.7936 49.0083 33.7936 48.8367C33.7936 44.1873 33.7936 39.5913 33.7936 34.8761C33.7088 32.6202 34.5699 30.4318 36.1692 28.84C38.1948 26.8986 41.2056 26.4052 43.7444 27.5985C46.2721 28.5447 47.9495 30.9598 47.9543 33.6608C48.0301 36.4771 47.0132 39.213 45.1168 41.2948C42.3431 44.8074 38.7052 47.5382 34.5591 49.2195C34.3125 49.3078 34.0614 49.3828 33.8069 49.4441"
        fill={fillColor}
      />
      <path
        d="M16.4134 49.6159C14.7636 48.7573 13.2065 48.0706 11.7681 47.1856C8.4545 45.2276 5.68241 42.4717 3.70443 39.1685C2.56468 37.3567 2.09748 35.2014 2.38472 33.0799C2.7694 29.7983 5.45008 27.2654 8.74587 27.0702C12.2156 26.6181 15.4457 28.923 16.1493 32.3534C16.3906 33.3479 16.5147 34.3676 16.5188 35.3911C16.5188 39.7762 16.5188 44.1743 16.5188 48.5988C16.5188 48.8498 16.5188 49.0877 16.4397 49.5762"
        fill={fillColor}
      />
      <path
        d="M20.1482 50.4215C18.8285 50.4215 18.7095 50.329 18.7095 49.1931V30.3324C18.8828 26.8908 16.8673 23.7145 13.6814 22.4077C10.1653 20.926 6.10269 21.7193 3.40106 24.4153C1.68238 26.3654 0.742587 28.8813 0.76163 31.4814C0.767026 31.7585 0.698784 32.0319 0.563576 32.274C0.418211 31.64 0.24682 30.9531 0.167472 30.372C-0.284175 27.8833 0.184929 25.3151 1.48719 23.1473C2.39143 21.6203 3.78764 20.4465 5.44632 19.819C8.97191 18.434 12.9285 18.6806 16.2551 20.4926C18.706 21.7224 20.2237 24.2611 20.1482 27.004C20.2142 34.6776 20.1482 42.3513 20.1482 50.012C20.1482 50.1705 20.1482 50.3157 20.1482 50.4612"
        fill={fillColor}
      />
      <path
        d="M29.9422 50.448V49.497C29.9422 42.4179 30.0213 35.3385 29.9422 28.259C29.8629 22.5268 33.0698 20.057 37.5834 19.1456C39.8521 18.6892 42.2024 18.8628 44.3797 19.6476C48.243 21.1143 50.6295 25.0063 50.1867 29.1175C50.0944 30.1917 49.9134 31.2563 49.6456 32.3005C49.4212 30.9798 49.3288 29.6591 48.9724 28.3383C48.2014 25.4523 46.0698 23.1256 43.2638 22.1071C40.4577 21.0885 37.3314 21.5066 34.891 23.2269C32.5944 24.7881 31.2823 27.4392 31.4333 30.2138C31.4333 36.5008 31.4333 42.7877 31.4333 49.0745C31.4333 50.3953 31.4333 50.3953 29.9159 50.3953"
        fill={fillColor}
      />
    </svg>
  )
}

export default CheckoutNuskinIcon
export { CheckoutNuskinIcon }
