import React, { lazy, Suspense } from 'react'
import { i18nTranslate } from 'src/utils'
import { Tabs, Tab, Button, Form, Row } from 'react-bootstrap'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import pickBy from 'lodash/pickBy'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import { toastState } from 'src/views/components'
import { stringifyAddress } from 'src/deps'
import {
  cartContainer,
  checkoutContainer,
  customerContainer,
  commonContainer,
  storeContainer,
} from 'src/models'
import { ShippingAddressList } from './ShippingAddressComponents'
import { countriesWithoutPostalCodes } from 'src/static/geography'
import { IS_BROWSER } from 'config/appConfig'

@observer
class MultipleShippingAddressForm extends React.Component {
  @observable currentlySelectedAddress = ''

  constructor(props) {
    super(props)
    const isPrefillAddressAvailable =
      props.addressToSelectOrPrefill &&
      props.addressToSelectOrPrefill.addressLine1
    if (isPrefillAddressAvailable) {
      this.currentlySelectedAddress = props.addressToSelectOrPrefill
    }
    const { cartItemId, newAddressList } = props
    const shouldSelectNewAddressFormTab = this.newFormSelectionChecker(props)
    const addressType = isPrefillAddressAvailable
      ? shouldSelectNewAddressFormTab
        ? 'newAddress'
        : 'savedAddress'
      : props.addressList.length > 0
      ? 'savedAddress'
      : 'newAddress'

    newAddressList[cartItemId] = {
      ...(newAddressList[cartItemId] || {}),
      addressType,
    }
  }

  setShippingAddress = async () => {
    if (this.currentlySelectedAddress && this.currentlySelectedAddress.zip) {
      if (!this.currentlySelectedAddress.email) {
        this.currentlySelectedAddress['email'] =
          customerContainer.profileResponse.email
      }
      const response = await checkoutContainer.setShippingAddress(
        this.currentlySelectedAddress,
        this.props.cartItemId
      )
      if (!commonContainer.isSuccessResponse(response)) {
        toastState.setToastMessage(response.message || response.responseMessage)
      }
    }
  }

  newFormSelectionChecker = props => {
    // this flow will be executed only if addressList.length > 0
    let selectedAddressIndex = -1
    if (props.addressToSelectOrPrefill === '') {
      // if there is no address is in cart
      // but has addressList
      // select addresslist tab
      return false
    } else {
      // has address in cart
      // check whether cart address(addressToSelectOrPrefill) is in `addressList`
      selectedAddressIndex = props.addressList.findIndex(
        accountAddress =>
          stringifyAddress(accountAddress) ===
          stringifyAddress(props.addressToSelectOrPrefill)
      )
      // if selectedIndex === -1
      // then address is not available in addressList of myaccount
      // so choose new address form
      return selectedAddressIndex === -1
    }
  }

  handleSelect = accountAddress => {
    this.currentlySelectedAddress = accountAddress
    // make item level shipping api here
    this.setShippingAddress()
  }

  onBlur = props => {
    const { values, isFormValid } = props
    if (isFormValid) {
      // make item level shipping address
      const { stateList, ...remainingProps } = values
      const shippingAddress = remainingProps
      if (!shippingAddress.email) {
        shippingAddress.email = customerContainer.profileResponse.email
      }
      if (stateList) {
        shippingAddress['state'] = stateList
      }
      if (
        !isEqual(
          pickBy(this.currentlySelectedAddress, isString),
          shippingAddress
        )
      ) {
        this.currentlySelectedAddress = shippingAddress
        this.setShippingAddress()
      }
    }
  }

  handleNewFormSubmit = formData => {
    // make item level shipping address
    const { addressList = [] } = this.props
    const { state, country, stateList, ...remainingFormData } = formData
    const stateValue = this.isStateFieldDropDown === true ? stateList : state
    const transformedFormData = {
      state: stateValue,
      country: country.value ? country.value : country,
      ...(countriesWithoutPostalCodes && {
        zip: formData.postalCode || '000',
      }),
      ...remainingFormData,
    }

    if (!isArray(addressList) || addressList.length < 1000) {
      const shippingAddress = transformedFormData
      if (!shippingAddress.email) {
        shippingAddress.email = customerContainer.profileResponse.email
      }
      if (
        !isEqual(
          pickBy(this.currentlySelectedAddress, isString),
          shippingAddress
        )
      ) {
        this.currentlySelectedAddress = shippingAddress
        this.setShippingAddress()
      }
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'checkoutAddress.limitExceeded',
          'Maximum number of addresses that can be setup is already reached'
        ),
        false
      )
    }
  }

  makeShippingMethod = shippingId => {
    checkoutContainer.updateShippingMethod(shippingId, this.props.cartItemId)
  }

  renderShippingMethods = shippingMethods => {
    const currencySymbol = cartContainer?.cartResponse?.currencySign || '$'
    return (
      shippingMethods &&
      shippingMethods.map(shippingMethod => {
        const { name, id, cost, isSelected } = shippingMethod
        const shippingCost = cost
          ? `- ${currencySymbol} ${cost}`
          : `- ${currencySymbol} 0`
        return (
          <div
            key={`shippingmethod-${name}`}
            className="mb-2"
            data-testid={
              isSelected ? 'qa-ship-method-selected' : 'qa-ship-method'
            }>
            <Form.Check
              id={`methods-${name}`}
              label={`${name} ${shippingCost}`}
              type="radio"
              checked={isSelected}
              onChange={() => this.makeShippingMethod(id)}
            />
          </div>
        )
      })
    )
  }

  renderAddAddressButton = () => {
    return (
      <Row noGutters className="justify-content-end">
        <Button variant="primary" type="submit">
          {i18nTranslate('multipleShipping.addAddress', 'Add Address')}
        </Button>
      </Row>
    )
  }

  addNewAddressList = props => {
    const { cartItemId, newAddressList } = this.props
    const { validateForm, values, setTouched, isValid } = props
    newAddressList[cartItemId] = {
      ...newAddressList[cartItemId],
      validateForm,
      values,
      setTouched,
      isValid,
    }
  }

  handleSelectedTab = eventKey => {
    const { cartItemId, newAddressList } = this.props
    newAddressList[cartItemId]['addressType'] = eventKey
  }

  renderShippingForm = () => {
    const { addressToSelectOrPrefill, addressList } = this.props
    const shouldSelectNewAddressFormTab = this.newFormSelectionChecker(
      this.props
    )
    let AddressForm = <></>

    let currentMarket = storeContainer?.defaultShippingRegion
      ? 'Address' + storeContainer?.defaultShippingRegion
      : 'AddressUS'
    AddressForm = lazy(() =>
      import('src/views/addresses/' + currentMarket + '/' + currentMarket)
    )
    if (IS_BROWSER) {
      return (
        <Suspense fallback="">
          <AddressForm
            addressInfo={
              shouldSelectNewAddressFormTab
                ? addressToSelectOrPrefill || ''
                : ''
            }
            onSubmit={this.handleNewFormSubmit}
            renderSubmitButton={this.renderAddAddressButton}
            shippingAddresses={addressList}
            addNewAddressList={this.addNewAddressList}
            type="checkout"
          />
        </Suspense>
      )
    } else {
      return <></>
    }
  }

  renderShippingAddress = () => {
    const { addressList } = this.props
    if (isArray(this.props.addressList) && this.props.addressList.length > 0) {
      const shouldSelectNewAddressFormTab = this.newFormSelectionChecker(
        this.props
      )
      const savedAddress = i18nTranslate(
        'checkout.savedAddress',
        'Saved Address'
      )
      const newAddress = i18nTranslate(
        'checkout.addNewAddress',
        'Add New Address'
      )
      return (
        <Tabs
          className="my-3"
          defaultActiveKey={
            shouldSelectNewAddressFormTab ? 'newAddress' : 'savedAddress'
          }
          onSelect={this.handleSelectedTab}
          id="">
          <Tab
            eventKey="savedAddress"
            title={savedAddress}
            className="w-50"
            data-testid="qa-address-container">
            <ShippingAddressList
              currentlySelectedAddress={this.currentlySelectedAddress}
              addressList={addressList}
              handleSelect={this.handleSelect}
              isFromItemLevelShipping={true}
              {...this.props}
            />
          </Tab>
          <Tab eventKey="newAddress" title={newAddress}>
            {this.renderShippingForm()}
          </Tab>
        </Tabs>
      )
    } else {
      return this.renderShippingForm()
    }
  }

  render() {
    const { shippingMethods } = this.props
    return (
      <div>
        {this.renderShippingAddress()}
        {shippingMethods && shippingMethods.length > 0 && (
          <>
            <div className="font-weight-bold my-3">
              {i18nTranslate('checkout.shippingMethod', 'Shipping Method')}
            </div>
            {this.renderShippingMethods(shippingMethods)}
          </>
        )}
      </div>
    )
  }
}

export { MultipleShippingAddressForm }
