export const formInput = props => {
  const { giftMessage = '', handleChangeValue } = props
  return [
    {
      name: 'giftMessage',
      dataTestId: 'qa-gift-message',
      defaultValue: giftMessage || '',
      placeholderKey: 'giftMessage',
      columnType: {
        lg: '12',
      },
      maxLength: 254,
      type: 'text',
      isToHideLabel: true,
      ariaLabel: 'giftMessage',
      onInput: handleChangeValue,
      className: 'gift-message',
    },
  ]
}
