import React from 'react'
import debounce from 'lodash/debounce'
import { Quantity } from 'src/views/components'
import { APPConfig } from 'config/appConfig'
import { i18nTranslate } from 'src/utils'

export function SubscriptionQuantity(props) {
  const {
    product = {},
    handleQuantityChange = function () {},
    handleQuantityChangePromotion = function () {},
    decrementCheck = function () {},
    productQuantityCount,
    type = '',
  } = props || {}
  const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99

  const {
    quantity = 1,
    maxCartItemLimit = maxLimit,
    cartItemId = '',
    productCount = 1,
  } = product

  return (
    <div className="w-50 cart-quantity-wrapper">
      <div className="quantity-txt">
        {i18nTranslate('product.quantity', 'Quantity', {
          nameSpace: 'ssr-resource',
        })}
        :
      </div>
      {type == 'cart' ? (
        <Quantity
          onQuantityChange={debounce(async (count, cbk) => {
            const response = await handleQuantityChange(count, cartItemId)
            if (cbk) {
              cbk(response)
            }
          }, 700)}
          decrementCheck={decrementCheck}
          currentPage="cart"
          resetQtyOnFailure={true}
          defaultValue={quantity}
          maxValue={maxCartItemLimit}
          handleQuantityChangePromotion={handleQuantityChangePromotion}
          product={product}
        />
      ) : (
        <Quantity
          currentPage="pdp"
          maxValue={maxLimit}
          onQuantityChange={handleQuantityChange}
          handleQuantityChangePromotion={handleQuantityChangePromotion}
          product={product}
          defaultValue={productQuantityCount || productCount}
        />
      )}
    </div>
  )
}
