import { observable } from 'mobx'
import { CommonContainer } from 'src/models/Common'
import { savedCardsContainer } from 'src/models'
import { toastState } from 'src/views/components'
import { isExpressCheckout } from 'src/utils'

/**
 * TokenExCvvContainer class extends CommonContainer.
 * Used for handling TokenEx CVV tokenization.
 */
class TokenExCvvContainer extends CommonContainer {
  @observable tokenExCvvData = {}
  @observable tokenCvvValidData = {}
  @observable tokenExCvvAuthKey = {}
  @observable isTokenExError = true
  tokenExCvvIns = null
  errorSchemaValidationCallBack = null
  formSubmitCallBack = null
  containerId = null
  cvvIframeData = {}

  constructor(props) {
    super(props)
  }

  /**
   * Logs TokenEx errors to the console
   * and reports them to Instana monitoring
   * @param {Object} options - The error options
   * @param {string} options.errorReport - The error report message
   * @param {Object} options.errorData - Metadata about the error
   */
  trackErrorInInstana = options => {
    const { errorReport, errorData } = options
    console.warn(errorReport, errorData)
    ineum('reportEvent', `TokenEx: ${errorReport}`, {
      meta: errorData,
    })
  }

  /**
   * Retries initializing the TokenEx CVV session
   * by calling loadTokenExCvvIframe() again with
   * the previously saved iframe data.
   */
  retryTokenExSession = async () => {
    const { iframeID, tokenValidate, cardProvider } = this.cvvIframeData || {}
    this.loadTokenExCvvIframe(iframeID, tokenValidate, cardProvider)
  }

  /**
   * Gets the token from the TokenEx CVV iframe instance
   * and sets the form submit callback.
   *
   * @param {Function} cbk - The form submit callback
   */
  getToken = cbk => {
    if (this.tokenExCvvIns) {
      this.tokenExCvvIns.tokenize()
      this.formSubmitCallBack = cbk
    }
  }

  loadTokenExCvvIframe = async (iframeID, tokenValidate, cardProvider) => {
    // const { authKey, timestamp, tokenExID } =
    //   await savedCardsContainer.tokenExCvvAuthKey
    this.cvvIframeData = {
      iframeID,
      tokenValidate,
      cardProvider,
    }
    const { authKey, timestamp, tokenExID } =
      (await savedCardsContainer.getTokenExCvvAuthKeyFromSFO(tokenValidate)) ||
      {}

    let tokenExCvvOriginUrl
    if (typeof location !== 'undefined') {
      tokenExCvvOriginUrl = `https://${location?.host}`
    }
    const cvvConfig = {
      origin: tokenExCvvOriginUrl,
      authenticationKey: authKey,
      timestamp: timestamp,
      tokenExID: tokenExID,
      tokenScheme: 'PCI',
      token: tokenValidate, //Added Line by TokenEx //In CVV Only mode, the token the CVV is associated with must be provided.
      pci: true,
      cvv: true,
      cvvOnly: true, //Added Line by TokenEx //Must be set to true to enable this mode.x
      cardType: cardProvider, //Added Line by TokenEx //In CVV Only mode, a card type must be provided to validate the CVV length.
      cvvContainerID: iframeID,
      inputType: 'password',
      placeholder: 'CVV',
      // expiresInSeconds: 120,
      styles: {
        base: `padding: 0 10px;border-radius:${
          isExpressCheckout() ? '4' : '0'
        }px !important;border: 1px solid ${
          isExpressCheckout() ? '#cccccc' : '#252525'
        };margin: 0;width: 98%;font-size: 16px;line-height: 22px;height: 44px;box-sizing: border-box;-moz-box-sizing: border-box;color:#252525`,
        focus:
          'box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);border: 3px solid #94D6E9;outline: 0;',
        error:
          'box-shadow: 0 0 2px 0 rgb(224 57 57 / 0%);border: 1px solid rgb(220 53 69);',
      },
    }
    const saveTokenExCvvData = (data = {}) => {
      this.tokenExCvvData = {
        ...this.tokenExCvvData,
        ...data,
      }
      savedCardsContainer.tokenExCvvData = this.tokenExCvvData

      if (this.formSubmitCallBack) {
        this.formSubmitCallBack?.(this.tokenExCvvData)
        this.formSubmitCallBack = null
      }
    }
    const saveTokenCvvValidData = (data = {}) => {
      this.tokenCvvValidData = {
        ...this.tokenCvvValidData,
        ...data,
      }
      savedCardsContainer.tokenCvvValidData = this.tokenCvvValidData
      // this.isTokenExError = false
      if (this.errorValidationCallBack) {
        this.errorValidationCallBack(this.containerId, data)
      }
    }

    const saveTokenExErrorData = (data = {}) => {
      const sessionExpired = 'Session expired'
      if (data?.error?.includes(sessionExpired)) {
        this.tokenExCvvIns?.remove?.()
        this.retryTokenExSession()
      }
      this.isTokenExError = true

      this.trackErrorInInstana({
        errorReport: 'Error TokenEx iframe',
        errorData: data,
      })

      if (this.errorValidationCallBack) {
        this.errorValidationCallBack(this.containerId, { isValid: false })
      }
      toastState.setToastMessage(data?.error)
    }

    try {
      const tokenExCvvEl = document.getElementById(iframeID)
      if (tokenExCvvEl) {
        this.tokenExCvvIns = new TokenEx.Iframe(tokenExCvvEl.id, cvvConfig)
        // the final value of the tokenEx form
        this.tokenExCvvIns.on('tokenize', saveTokenExCvvData)
        // // after form validation
        this.tokenExCvvIns.on('validate', saveTokenCvvValidData)
        this.tokenExCvvIns.on('focus', saveTokenCvvValidData)
        this.tokenExCvvIns.on('blur', saveTokenCvvValidData)
        this.tokenExCvvIns.on('error', saveTokenExErrorData)

        // validate the tokenEx form fields
        this.tokenExCvvIns.on('change', () => {
          this.tokenExCvvIns.focus()
          this.tokenExCvvIns.blur()
          this.tokenExCvvIns.validate()
          // this.tokenExCvvIns.tokenize()
        })

        // initialize the tokenEx
        this.tokenExCvvIns.load()
      }
    } catch (e) {
      this.trackErrorInInstana({
        errorReport: 'Error in TokenEx iframe config',
        errorData: e,
      })
    }
  }
}
export { TokenExCvvContainer }
