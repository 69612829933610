import { i18nTranslate } from 'src/utils'

const firstNameErrorMsg = () => {
  return i18nTranslate(
    'validation.firstNameErrorMsg',
    'Please enter valid first name'
  )
}

const lastNameErrorMsg = () => {
  return i18nTranslate(
    'validation.lastNameErrorMsg',
    'Please enter valid last name'
  )
}

const emailErrorMsg = () => {
  return i18nTranslate(
    'validation.emailErrorText',
    'Please enter a valid email'
  )
}

const emailDomainErrorMsg = () => {
  return i18nTranslate(
    'validation.emailDomainErrorMsg',
    'Please enter a valid Email domain'
  )
}

const addressUserNameErrorMsg = () => {
  return i18nTranslate(
    'validation.addressUserName',
    'Please enter a valid name'
  )
}

const zipCodeErrorMsg = () => {
  return i18nTranslate(
    'validation.zipCodeErrorText',
    'Please enter a valid zip code'
  )
}

const zipCodeRequiredMsg = () => {
  return i18nTranslate(
    'validation.zipCodeRequiredText',
    'Please enter zip code'
  )
}

const cityErrorMsg = () => {
  return i18nTranslate('validation.cityErrorText', 'Please enter a valid city')
}

const cityRequiredMsg = () => {
  return i18nTranslate('validation.cityRequiredText', 'Please enter city')
}

const wardRequiredMsg = () => {
  return i18nTranslate(
    'validation.wardRequiredText',
    'Please enter a valid ward'
  )
}

const wardErrorMsg = () => {
  return i18nTranslate('validation.wardErrorText', 'Please enter a valid ward')
}

const townCityErrorMsg = () => {
  return i18nTranslate(
    'validation.townCityErrorText',
    'Please enter a valid town/city'
  )
}

const townCityRequiredMsg = () => {
  return i18nTranslate(
    'validation.townCityRequiredText',
    'Please enter town/city'
  )
}

const townCityLocalityErrorMsg = () => {
  return i18nTranslate(
    'validation.townCityLocalityErrorText',
    'Please enter a valid town/city/locality'
  )
}

const townCityLocalityRequiredMsg = () => {
  return i18nTranslate(
    'validation.townCityLocalityRequiredText',
    'Please enter town/city/locality'
  )
}

const districtRequiredMsg = () => {
  return i18nTranslate(
    'validation.districtRequiredText',
    'Please select a district'
  )
}

const districtErrorMsg = () => {
  return i18nTranslate(
    'validation.districtErrorText',
    'Please select a valid district'
  )
}

const districtInputRequiredMsg = () => {
  return i18nTranslate(
    'validation.districtInputRequiredText',
    'Please enter suburb'
  )
}

const districtInputErrorMsg = () => {
  return i18nTranslate(
    'validation.districtInputErrorText',
    'Please enter a valid suburb'
  )
}

const suburbRequiredMsg = () => {
  return i18nTranslate('validation.suburbRequiredText', 'Please enter suburb')
}

const suburbErrorMsg = () => {
  return i18nTranslate(
    'validation.suburbErrorText',
    'Please enter a valid suburb'
  )
}

const stateRequiredMsg = () => {
  return i18nTranslate('validation.stateErrorText', 'Please select a state')
}

const stateErrorMsg = () => {
  return i18nTranslate(
    'validation.stateRequiredText',
    'Please select a valid state'
  )
}

const stateInputRequiredMsg = () => {
  return i18nTranslate('validation.stateInputErrorText', 'Please enter a state')
}

const stateInputErrorMsg = () => {
  return i18nTranslate(
    'validation.stateInputRequiredText',
    'Please enter a valid state'
  )
}

const regionRequiredMsg = () => {
  return i18nTranslate('validation.regionErrorText', 'Please select a region')
}

const regionErrorMsg = () => {
  return i18nTranslate(
    'validation.regionRequiredText',
    'Please select a valid region'
  )
}

const addressErrorMsg = () => {
  return i18nTranslate(
    'validation.addressErrorText',
    'Please enter a valid address'
  )
}

const postalCodeErrorMsg = () => {
  return i18nTranslate(
    'validation.postalCodeErrorText',
    'Please enter a valid postal code'
  )
}

const postalCodeRequiredMsg = () => {
  return i18nTranslate(
    'validation.postalCodeRequiredText',
    'Please enter postal code'
  )
}

const provinceErrorMsg = () => {
  return i18nTranslate(
    'validation.provinceErrorText',
    'Please enter a valid province'
  )
}

const provinceRequiredMsg = () => {
  return i18nTranslate(
    'validation.provinceRequiredText',
    'Please enter province'
  )
}

const phoneNumberErrorMsg = () => {
  return i18nTranslate(
    'validation.phoneErrorText',
    'Mobile Phone number is not valid'
  )
}

export {
  emailErrorMsg,
  firstNameErrorMsg,
  lastNameErrorMsg,
  addressUserNameErrorMsg,
  zipCodeErrorMsg,
  zipCodeRequiredMsg,
  cityErrorMsg,
  districtInputRequiredMsg,
  districtInputErrorMsg,
  cityRequiredMsg,
  townCityErrorMsg,
  townCityRequiredMsg,
  townCityLocalityErrorMsg,
  townCityLocalityRequiredMsg,
  regionRequiredMsg,
  regionErrorMsg,
  addressErrorMsg,
  districtRequiredMsg,
  districtErrorMsg,
  stateRequiredMsg,
  stateErrorMsg,
  postalCodeErrorMsg,
  postalCodeRequiredMsg,
  provinceErrorMsg,
  provinceRequiredMsg,
  wardRequiredMsg,
  wardErrorMsg,
  suburbRequiredMsg,
  suburbErrorMsg,
  stateInputRequiredMsg,
  stateInputErrorMsg,
  emailDomainErrorMsg,
  phoneNumberErrorMsg,
}
