import { CommonContainer } from 'src/models/Common'
import { observable } from 'mobx'

/**
 * ContentStackContainer class extends CommonContainer.
 * This class represents a container for ContentStack data.
 */
class ContentStackContainer extends CommonContainer {
  @observable marketingData = {}

  constructor(props) {
    super(props)
  }

  /**
   * Sets the marketingData property to the provided props.
   *
   * @param {Object} props - The marketing data to set.
   */
  setMarketingData = props => {
    this.marketingData = props
  }
}

const contentStackContainer = new ContentStackContainer()

export { contentStackContainer }
export default contentStackContainer
