import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import {
  LinkWithLocale,
  ImageComponent,
  FrequencyDropdownV2,
  getProductDetailsForCart,
  AccordionBlock,
} from 'src/views/components'
//import { CartScanCardWithInfo } from 'src/views/components/SubscriptionComponents/CartScanCardWithInfo'
import { getScanQualifiedCountSumForBundleSubItems } from 'src/deps'
import { i18nTranslate } from 'src/utils'
import QuantityAndTotalDetails from './QuantityAndTotalDetails'
import ProductTitleAndSkuId from './ProductTitleAndSkuId'
import SubscriptionProductPrice from './SubscriptionProductPrice'
import SubscriptionDatePicker from './SubscriptionDatePicker'
import { pageNames } from 'src/routes/pathParams'
import { Col } from 'react-bootstrap'
import './styles.scss'

@observer
class SubscriptionProductDetails extends React.Component {
  @observable isQuantityPromotionCallInProgress = false
  constructor(props) {
    super(props)
  }
  componentDidMount() {}

  /**
   * Handles the change in the selected date in the subscription date picker.
   *
   * @param {string} [selectedDate=''] - The selected date, if any.
   * @returns {void}
   */
  handleCalenderSelectedDateChange = (selectedDate = '') => {
    const itemId = this.props?.subscriptionProduct?.skuId || ''
    const { handleCalenderDateChange = function () {} } = this.props
    handleCalenderDateChange(selectedDate, itemId)
  }

  /**
   * Handles the change in the selected subscription frequency.
   *
   * @param {string} [selectedValue=''] - The selected subscription frequency value, if any.
   * @returns {void}
   */
  handleSubscriptionFrequency = (selectedValue = '') => {
    const itemId = this.props?.subscriptionProduct?.skuId || ''
    const { handleSubscriptionFrequency } = this.props
    handleSubscriptionFrequency(selectedValue, itemId)
  }

  /**
   * Handles the change in the quantity of the subscription product.
   *
   * @param {number} [count=0] - The new quantity value.
   * @returns {void}
   */
  handleQuantityChange = (count = 0) => {
    const itemId = this.props?.subscriptionProduct?.skuId || ''
    this.props?.handleQuantityChange?.(count, itemId)
  }

  /**
   * Handles the change in the quantity of the subscription product, including any applicable promotions.
   *
   * @async
   * @returns {void}
   */
  handleQuantityChangePromotion = async () => {
    const { subscriptionProduct = [] } = this.props
    this.isQuantityPromotionCallInProgress = true
    const itemId = subscriptionProduct?.skuId || ''
    const quantity = subscriptionProduct?.quantity || 0
    await this.props.handleQuantityChangePromotion?.(
      subscriptionProduct,
      quantity,
      itemId
    )
    this.isQuantityPromotionCallInProgress = false
  }

  /**
   * Renders a product image component with a link to the product page.
   *
   * @param {string} [name=''] - The name of the product.
   * @param {string} [imageURL=''] - The URL of the product image.
   * @param {string} [productLink=''] - The link to the product page.
   * @returns {React.ReactElement} - The rendered product image component.
   */
  renderProductImage = (name = '', imageURL = '', productLink = '') => {
    return (
      <div className="product-image mr-2">
        <LinkWithLocale
          to={{
            pathname: productLink || '',
          }}
          className={`prod-image-wrapper w-100`}>
          <ImageComponent
            width="100px"
            height="100px"
            optimizeImage={true}
            src={imageURL}
            alt={name}
            className={`mw-100 mh-100 prod-img`}
            data-testid="qa-user-sub-product-image"
          />
        </LinkWithLocale>
      </div>
    )
  }

  /**
   * Renders the quantity and total details for a subscription product.
   *
   * @param {Object} subscriptionProduct - The subscription product object.
   * @returns {React.ReactElement} - The rendered quantity and total details component.
   */
  renderQuantityAndTotal = (subscriptionProduct = {}) => {
    return (
      <QuantityAndTotalDetails
        subscriptionProduct={subscriptionProduct}
        handleQuantityChange={this.handleQuantityChange}
        handleQuantityChangePromotion={this.handleQuantityChangePromotion}
        isToDisableQuantityIcons={this.isQuantityPromotionCallInProgress}
      />
    )
  }

  /**
   * Renders a product title and SKU ID component with a link to the product page.
   *
   * @param {string} [name=''] - The name of the product.
   * @param {string} [skuId=''] - The SKU ID of the product.
   * @param {string} [productLink=''] - The link to the product page.
   * @returns {React.ReactElement} - The rendered product title and SKU ID component.
   */
  renderProductTitleAndSkuId = (name = '', skuId = '', productLink = '') => {
    return (
      <ProductTitleAndSkuId
        name={name}
        skuId={skuId}
        productLink={productLink}
      />
    )
  }

  /**
   * Renders the subscription product price component with the provided price information.
   *
   * @param {Object} priceInfo - The price information for the subscription product.
   * @param {string} [type=''] - The type of the subscription product.
   * @param {boolean} [isBundle=false] - Whether the subscription product is a bundle.
   * @returns {React.ReactElement} - The rendered subscription product price component.
   */
  renderPrice = (priceInfo = {}, type = '', isBundle = false) => {
    return (
      <SubscriptionProductPrice
        priceInfo={priceInfo}
        isCurrencySymbolRequired={true}
        type
        isBundle
      />
    )
  }

  /**
   * Renders the frequency details component for a subscription product.
   *
   * @param {string} [frequencyPeriod=''] - The frequency period for the subscription product.
   * @param {string} [frequencyType=''] - The frequency type for the subscription product.
   * @returns {React.ReactElement} - The rendered frequency details component.
   */
  renderFrequencyDetails = (frequencyPeriod = '', frequencyType = '') => {
    return (
      <Col className="cart-drawer-frequency-wrapper">
        <FrequencyDropdownV2
          title={i18nTranslate('cart.frequency', 'Frequency')}
          frequencyPeriod={frequencyPeriod}
          frequencyType={frequencyType}
          orderType="VARIABLE_MULTI_ORDER"
          handleSubscriptionFrequency={this.handleSubscriptionFrequency}
          isFromPDP={true}
        />
      </Col>
    )
  }

  /**
   * Renders a CartScanCardWithInfo component with the necessary product details for a cart item.
   *
   * @param {Object} product - The product object containing the necessary details.
   * @returns {React.ReactElement} - The rendered CartScanCardWithInfo component.
   */
  renderScanCardBtn = (product = {}) => {
    const { bundleInfo = {}, isBundle = false } = product
    let productDetails = getProductDetailsForCart(product)
    return (
      <CartScanCardWithInfo
        bundleInfo={bundleInfo}
        page="cart"
        isBundle={isBundle}
        isPopupForMovingCartProductsToDashboard={true}
        {...productDetails}
      />
    )
  }

  /**
   * Renders a subscription date picker component.
   *
   * @returns {JSX.Element} The subscription date picker component.
   */
  renderDatePickerForSubscription = () => {
    const orderProcessedFullDate =
      this.props?.subscriptionProduct?.orderProcessedFullDate || ''
    const {
      availableSubscriptionDatesOfMonthLimit = [],
      minDateDetails = {},
      maxDateDetails = {},
      alreadyProcessedOrderDates = [],
      availableOrderDates = [],
    } = this.props
    return (
      <SubscriptionDatePicker
        orderProcessedFullDate={orderProcessedFullDate}
        availableSubscriptionDatesOfMonthLimit={
          availableSubscriptionDatesOfMonthLimit
        }
        minDateDetails={minDateDetails}
        maxDateDetails={maxDateDetails}
        availableOrderDates={availableOrderDates}
        alreadyProcessedOrderDates={alreadyProcessedOrderDates}
        handleCalenderDateChange={this.handleCalenderSelectedDateChange}
      />
    )
  }

  /**
   * Renders a div element with the quantity of a subscription bundle sub-item.
   *
   * @param {string} quantity - The quantity of the subscription bundle sub-item.
   * @returns {JSX.Element} - A div element containing the quantity of the subscription bundle sub-item.
   */
  renderSubItemQty = (quantity = '') => {
    return (
      <div className="sub-item-qty d-flex">
        <span className="bundle-quantity-label">
          {i18nTranslate('bundle.fixedQuantityTitleV2', 'Item Quantity', {
            nameSpace: 'ssr-resource',
          })}
        </span>
        <span className="bundle-quantity-colon">:</span>
        <span className="bundle-quantity-value ml-1">{quantity}</span>
      </div>
    )
  }

  /**
   * Renders a list of bundle sub-items for a subscription product.
   *
   * @param {Object[]} subItemsofBundle - An array of bundle sub-items.
   * @returns {JSX.Element[]} - An array of JSX elements representing the bundle sub-items.
   */
  getBundlesView = (subItemsofBundle = []) => {
    return subItemsofBundle?.map(sku => {
      const { skuProperties = {}, skuQuantity = '', skuId = '' } = sku || {}
      const { name = '', slug = '', imageURL = '' } = skuProperties || {}
      let productLink = `${pageNames.product}/${slug}` || '' //Need to update with productUrl
      return (
        <div className="bundle-sub-item">
          <div className="bundle-subitem-details d-flex">
            {this.renderProductImage(name, imageURL, productLink)}
            <div className="product-title-section">
              {this.renderProductTitleAndSkuId(name, skuId, productLink)}
              {this.renderSubItemQty(skuQuantity)}
            </div>
          </div>
        </div>
      )
    })
  }

  /**
   * Renders a special product UI component that displays a list of bundle sub-items.
   *
   * @param {Object} productDetails - An object containing details about the product.
   * @returns {JSX.Element} - A JSX element representing the special product UI component.
   */
  renderSpecialProductUI = (productDetails = {}) => {
    const { skusExceptMainSku = [], skus = [] } = productDetails
    const title =
      i18nTranslate('bundle.includedItems', 'Included Items') +
      ' (' +
      skusExceptMainSku.length +
      ')'

    return (
      <AccordionBlock
        className="cart-drawer-special-products-accordion"
        title={title}
        dataTestId="qa-cart-view">
        {this.getBundlesView(skusExceptMainSku)}
      </AccordionBlock>
    )
  }

  /**
   * Retrieves the subscription SKU information for a subscription product.
   *
   * @returns {Object} - An object containing the subscription SKU information.
   */
  getSubscriptionSkuInfo = () => {
    const {
      properties = {},
      skus = [],
      totalValue = {},
      unitValue = {},
      subscription = {},
      skuInfo = {},
      isBundle = false,
    } = this.props?.subscriptionProduct || {}
    const { slug = '' } = properties || {}

    const {
      skuProperties = {},
      skuId = '',
      title = '',
      type = '',
    } = skuInfo || {}

    let {
      name = '',
      imageURL = '',
      scanQualifiedCount = '',
    } = skuProperties || {}
    let productLink = `${pageNames.product}/${slug}` || '' //Need to update with productUrl
    const { frequencyPeriod = '', frequencyType = '' } = subscription || {}

    let bundleInfo = {}
    if (isBundle) {
      scanQualifiedCount = getScanQualifiedCountSumForBundleSubItems(skus || [])
      bundleInfo = {
        isBundle: true,
        bundleScanCount: scanQualifiedCount,
      }
    }
    //isAlreadySubscribed checked for greyed out products which will not be dispayed with scan card button - existing modal behavior
    let isToShowScanCard =
      !this.props?.isAlreadySubscribed && scanQualifiedCount > 0

    return {
      imageURL,
      name,
      skuId,
      productLink,
      unitValue,
      totalValue,
      type,
      frequencyPeriod,
      frequencyType,
      isBundle,
      isToShowScanCard,
      bundleInfo,
      ...this.props?.subscriptionProduct,
    }
  }

  render() {
    const { subscriptionProduct = [], isAlreadySubscribed = false } = this.props
    let productDetails = this.getSubscriptionSkuInfo() || {}
    const {
      imageURL = '',
      name = '',
      skuId = '',
      productLink = '',
      unitValue = {},
      totalValue = {},
      frequencyPeriod = '',
      frequencyType = '',
      type = '',
      isBundle = false,
      isToShowScanCard = false,
      bundleInfo = {},
      skus = [],
    } = productDetails || {}

    const customClass = isAlreadySubscribed ? 'disabled-card' : ''
    return (
      <div className={`${customClass} cart-drawer-product-detail-wrapper`}>
        <div className="d-flex cart-drawer-product-section">
          {this.renderProductImage(name, imageURL, productLink)}
          <div className="product-title-section">
            {this.renderProductTitleAndSkuId(name, skuId, productLink)}
            {this.renderPrice(unitValue, type, isBundle)}
          </div>
        </div>
        {this.renderQuantityAndTotal(subscriptionProduct)}
        {this.renderFrequencyDetails(frequencyPeriod, frequencyType)}
        {this.renderDatePickerForSubscription()}
        {isBundle && this.renderSpecialProductUI(productDetails)}
        {/* Scancard functionality in drawer is removed as per BA confirmation */}
        {/* {isToShowScanCard && this.renderScanCardBtn(productDetails)} */}
      </div>
    )
  }
}

export default SubscriptionProductDetails
export { SubscriptionProductDetails }
