import React from 'react'
import { formatPrice } from './deps'

class PriceSubscription extends React.Component {
  getPrice = () => {
    const { transactionPrice, price = 0, discountedPrice = 0 } = this.props
    const { totalValue = {} } = transactionPrice || {}
    const formattedPrice = formatPrice({
      transactionPrice,
      // currencyData: currency,
      price,
      totalValue,
    })
    return formattedPrice
  }
  renderMinMaxPrice = () => {
    const formattedPrice = this.getPrice()
    const price =
      formattedPrice.minPrice === formattedPrice.maxPrice
        ? formattedPrice.minPrice
        : formattedPrice.minPrice + ' - ' + formattedPrice.maxPrice
    return (
      <>
        {this.renderPrice({
          className: '',
          dataTestId: 'qa-product-range-price',
          price,
        })}
      </>
    )
  }

  renderRegSalePrice = formattedPrice => {
    const { transactionPrice } = this.props
    let isNegativePromotion = false
    if (
      transactionPrice &&
      transactionPrice?.price < transactionPrice?.discountedPrice
    ) {
      isNegativePromotion = true
    }
    return (
      <>
        {formattedPrice &&
          formattedPrice.salePrice &&
          this.renderPrice({
            className: 'sale',
            dataTestId: 'qa-product-sale-price',
            price: formattedPrice.salePrice,
          })}
        {formattedPrice &&
          formattedPrice.regularPrice &&
          !isNegativePromotion &&
          this.renderPrice({
            className: `original ${formattedPrice.regularPrice ? 'pr-2' : ''} ${
              formattedPrice.hasBothPriceAvail ? ' strikeout-price' : ''
            }`,
            dataTestId: 'qa-product-reg-price',
            price: formattedPrice.regularPrice,
            isToStrikeOut: formattedPrice.hasBothPriceAvail ? true : false,
          })}
      </>
    )
  }
  renderPrice = props => {
    const { className, price, isToStrikeOut, dataTestId } = props
    const customPrice = isToStrikeOut ? <del>{price}</del> : price
    const { priceLable = false } = this.props
    return (
      <>
        {customPrice && priceLable && (
          <span className="price-label pr-1">Price:</span>
        )}
        <span className={className} data-testid={dataTestId}>
          {customPrice}
        </span>
      </>
    )
  }
  renderPriceComponent = () => {
    const formattedPrice = this.getPrice()
    if (formattedPrice.salePrice || formattedPrice.regularPrice) {
      return this.renderRegSalePrice(formattedPrice)
    }
    return this.renderMinMaxPrice()
  }
  // #BundlePriceLogic
  renderDirectPrice = () => {
    const formattedPrice = this.getPrice()
    return this.renderPrice({
      className: 'sale',
      price: formattedPrice,
    })
  }
  render() {
    return (
      <div className={'price-wrapper'}>
        {this.props.price
          ? this.renderDirectPrice()
          : this.props.showPriceRange
          ? this.renderMinMaxPrice()
          : this.renderPriceComponent()}
      </div>
    )
  }
}

export { PriceSubscription }
export default PriceSubscription
