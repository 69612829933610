import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Row, Container } from 'react-bootstrap'
import { AccordionBlockPDP } from 'src/views/components/AccordionBlockPDP'
import { ImageComponent } from 'src/views/components/ImageComponent'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import Modal from 'react-bootstrap/Modal'
import { getProductPDPTitle } from 'src/utils/commonUtils'
import { observable } from 'mobx'
import { APPConfig } from 'config/appConfig'
import './styles.scss'

@observer
class ProductIngrediants extends React.Component {
  nameSpace = 'ssr-resource'
  @observable modalShow = false
  @observable nutritionInformationImage = {}

  renderTitle = () => {
    const { isAccordionType = false } = this.props
    const ingredientsLable = getProductPDPTitle(
      this.props.product?.tempProperties,
      'ingredients'
    )
    return (
      <Row
        className={`about-title ${
          isAccordionType ? 'text-uppercase' : 'border-light-grey'
        }`}
        noGutters>
        {ingredientsLable ||
          i18nTranslate('pdp.ingredients', 'Ingredients', {
            nameSpace: this.nameSpace,
          })}
      </Row>
    )
  }

  handleShow = nutritionInformationImage => {
    this.nutritionInformationImage = nutritionInformationImage
    this.modalShow = true
  }

  handleClose = () => {
    this.nutritionInformationImage = {}
    this.modalShow = false
  }
  enableNewPDPStyle = APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'

  renderKeyIngredients = keyIngredients => {
    return (
      <>
        <h2 className="subhead-m">Key ingredients</h2>
        <div className="d-flex flex-wrap align-items-center keyingredian-main">
          {keyIngredients?.length > 0 &&
            keyIngredients?.map((ingredientsdet, idx) => {
              return (
                <div
                  className={`key-ingredients-inner d-flex flex-row align-items-center ${
                    this.enableNewPDPStyle ? '' : 'mb-4'
                  }`}
                  key={idx}>
                  {ingredientsdet?.image?.url && (
                    <div className="mr-2">
                      <img
                        src={ingredientsdet?.image?.url || imagePlaceholder}
                        alt=""
                        aria-hidden="true"
                        className="keyimage"
                        onError={e => {
                          if (e.target.src != imagePlaceholder) {
                            e.target.onerror = null
                            e.target.src = imagePlaceholder
                          }
                        }}
                      />
                    </div>
                  )}
                  <div>
                    <h4 className="heading-5">
                      {ingredientsdet['name'] || ''}
                    </h4>
                    <p className="paragraph-m my-0">
                      {ingredientsdet['description'] || ''}
                    </p>
                  </div>
                </div>
              )
            })}
        </div>
      </>
    )
  }

  renderContent = () => {
    let ingredients = []
    let showIngredients = false
    const product = this.props?.product || {}
    try {
      ingredients = JSON.parse(product?.ingredients || '[]')
    } catch (e) {
      console.log('ingredient data parsing error ' + e)
    }
    return ingredients.map(ingredient => {
      const {
        activeIngredients = '',
        inactiveIngredients = '',
        allIngredients = '',
        keyIngredients = [],
        nutritionInformationImage = '',
        nutritionFacts = '',
        otherIngredients = '',
        ingredientDisclaimers = '',
        productName = '',
      } = ingredient || {}
      if (
        activeIngredients != '' ||
        allIngredients != '' ||
        inactiveIngredients != '' ||
        ingredientDisclaimers?.length > 0 ||
        keyIngredients?.length > 0 ||
        (nutritionInformationImage != null &&
          nutritionInformationImage != '') ||
        otherIngredients != '' ||
        (nutritionFacts != '' && nutritionFacts != undefined)
      ) {
        showIngredients = true
      }
      const infoClassName = !this.enableNewPDPStyle
        ? 'v2-all-ingredient-info my-0'
        : 'all-ingredient-info paragraph-m my-0'

      return (
        <Container className="pdp-accordion-content-container ingredient-wrapper">
          <div
            className="key-ingredients-accordion pr-0 pr-md-4"
            data-testid="qa-pdp-all-ingredients">
            {productName && (
              <div className="key-ingredients-productname">{productName}</div>
            )}
            {keyIngredients?.length > 0 &&
              !this.enableNewPDPStyle &&
              this.renderKeyIngredients(keyIngredients)}

            {allIngredients && (
              <div className="all-ingredient">
                <h4 className="heading-5 pb-2">All Ingredients</h4>
                <p className={infoClassName}>{allIngredients}</p>
              </div>
            )}
            {activeIngredients && (
              <div className="all-ingredient mt-2">
                <h4 className="heading-5 pb-2">Active Ingredients</h4>
                <p className={infoClassName}>{activeIngredients}</p>
              </div>
            )}
            {inactiveIngredients && (
              <div className="all-ingredient mt-2">
                <h4 className="heading-5 pb-2">Inactive Ingredients</h4>
                <p className={infoClassName}>{inactiveIngredients}</p>
              </div>
            )}
            {keyIngredients?.length > 0 && this.enableNewPDPStyle && (
              <div className="mt-2">
                {this.renderKeyIngredients(keyIngredients)}
              </div>
            )}
            {(nutritionInformationImage || otherIngredients) && (
              <div className="other-ingredient mt-2 d-flex flex-lg-row flex-sm-column flex-xs-column">
                {nutritionInformationImage &&
                  nutritionInformationImage != null && (
                    <div
                      onClick={() => this.handleShow(nutritionInformationImage)}
                      className="Otheringredient-img-wrapper mt-2 col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-0">
                      <div className="Otheringredient-img">
                        <ImageComponent
                          src={nutritionInformationImage?.url}
                          alt={nutritionInformationImage?.alt || ''}
                          // height={'400px'}
                          // width={'400px'}
                          scrSet={[200, 300, 1500]}
                        />
                      </div>
                    </div>
                  )}
                {otherIngredients && otherIngredients?.length > 0 && (
                  <div className="mt-2 col-lg-6 col-md-12 col-sm-12 col-xs-12 ingredients-ipad-padding">
                    <div className="all-ingredient">
                      <h4 className="heading-5 pb-2">Other Ingredients</h4>
                      <p className={infoClassName}>{otherIngredients}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
            {ingredientDisclaimers?.length > 0 && (
              <div className="ingredient-disclaimer mt-4">
                <p className="ingredient-disclaimer-content">
                  {ingredientDisclaimers}
                </p>
              </div>
            )}
            <div></div>
          </div>
        </Container>
      )
    })
  }

  render() {
    let ingredients = []
    let showIngredients = false
    const product = this.props?.product || {}
    try {
      ingredients = JSON.parse(product?.ingredients || '[]')
    } catch (e) {
      console.log('ingredient data parsing error ' + e)
    }
    const {
      activeIngredients = '',
      inactiveIngredients = '',
      allIngredients = '',
      keyIngredients = [],
      nutritionInformationImage = '',
      nutritionFacts = '',
      otherIngredients = '',
    } = ingredients[0] || {}
    if (
      (activeIngredients !== '' && activeIngredients !== null) ||
      (allIngredients !== '' && allIngredients !== null) ||
      (inactiveIngredients !== '' && inactiveIngredients !== null) ||
      keyIngredients?.length > 0 ||
      (nutritionInformationImage !== null &&
        nutritionInformationImage !== '') ||
      (otherIngredients !== '' && otherIngredients !== null) ||
      (nutritionFacts !== '' && nutritionFacts !== undefined)
    ) {
      showIngredients = true
    }
    return (
      <>
        {showIngredients && (
          <div className="product-accordion-wrapper ">
            <AccordionBlockPDP
              title={this.renderTitle()}
              isDefaultOpen={false}
              className="accordion-description"
              dataTestId="qa-pdp-product-ingredients">
              {this.renderContent()}
            </AccordionBlockPDP>
          </div>
        )}
        <Modal
          className="modal-max-width pl-0"
          show={this.modalShow}
          onHide={this.handleClose}
          centered>
          <Modal.Header
            className="border-bottom-0 px-4 pb-0"
            closeButton></Modal.Header>
          <Modal.Body className="border-bottom-0 px-4 pt-4">
            <ImageComponent
              src={this.nutritionInformationImage?.url}
              alt={this.nutritionInformationImage?.alt || ''}
              // height={'400px'}
              // width={'400px'}
              scrSet={[200, 300, 1500]}
            />
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export { ProductIngrediants }
