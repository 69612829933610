import { i18nTranslate } from 'src/utils'

const industryErrorMsg = () => {
  return i18nTranslate(
    'validation.industryErrorText',
    'Please choose an industry'
  )
}

const industrySizeErrorMsg = () => {
  return i18nTranslate(
    'validation.industrySizeErrorText',
    'Please select the number of employees'
  )
}

const emailErrorMsg = () => {
  return i18nTranslate(
    'validation.emailErrorText',
    'Please enter a valid Email'
  )
}

const emailDomainErrorMsg = () => {
  return i18nTranslate(
    'validation.emailDomainErrorMsg',
    'Please enter a valid Email domain'
  )
}

const passwordErrorMsg = () => {
  return i18nTranslate('validation.passwordErrorText', 'This field is required')
}

const confirmPasswordErrorMsg = () => {
  return i18nTranslate(
    'validation.confirmPasswordErrorText',
    'Passwords must match'
  )
}

const firstNameErrorMsg = () => {
  return i18nTranslate(
    'validation.firstNameErrorMsg',
    'Please enter valid first name'
  )
}

const userExistErrorMessage = () => {
  return i18nTranslate(
    'validation.userExistErrorMessage',
    'Email Id already exists, please enter a new email id to create an account'
  )
}

const userExistRequiredErrorMessage = () => {
  return i18nTranslate(
    'validation.userExistRequiredErrorMessage',
    'Please enter valid email address'
  )
}

const lastNameErrorMsg = () => {
  return i18nTranslate(
    'validation.lastNameErrorMsg',
    'Please enter valid last name'
  )
}

const phoneNumberErrorMsg = () => {
  return i18nTranslate(
    'validation.phoneErrorText',
    'Mobile Phone number is not valid'
  )
}

const securityQuestionErrorMsg = () => {
  return i18nTranslate(
    'validation.securityErrorText',
    'Please select a question'
  )
}

const securityAnswerErrorMsg = () => {
  return i18nTranslate('validation.answerErrorText', 'Please enter your answer')
}

const addressErrorMsg = () => {
  return i18nTranslate(
    'validation.addressErrorText',
    'Please enter a valid address'
  )
}

const cityErrorMsg = () => {
  return i18nTranslate('validation.cityErrorText', 'Please enter a valid city')
}

const stateErrorMsg = () => {
  return i18nTranslate(
    'validation.stateErrorText',
    'Please enter a valid state'
  )
}

const countryErrorMsg = () => {
  return i18nTranslate(
    'validation.countryErrorText',
    'Please enter a valid country'
  )
}

const requiredErrorMsg = () => {
  return i18nTranslate(
    'validation.requiredErrorText',
    'Please enter required field'
  )
}

const maxLengthErrorMsg = length => {
  return `${i18nTranslate(
    'validation.maxLengthError',
    'Max allowed characters is'
  )} ${length}`
}

const invalidCouponErrorMsg = () => {
  return i18nTranslate(
    'validation.couponErrorText',
    'Please enter valid coupon code'
  )
}

const invalidPromocodeErrorMsg = () => {
  return i18nTranslate(
    'validation.promoErrorText',
    'Please enter valid promo code'
  )
}

const invalidLoyaltyErrorMsg = () => {
  return i18nTranslate(
    'validation.loyaltyErrorText',
    'Please enter a valid amount'
  )
}

const invalidLoyaltyAmountErrorMsg = () => {
  return i18nTranslate(
    'validation.loyaltyAmountErrorText',
    'The provided value is more than the redeemable point'
  )
}
const invalidLoyaltyZeroErrorMsg = () => {
  return i18nTranslate(
    'validation.loyaltyAmountZeroErrorText',
    'The provided value should be more than Zero'
  )
}

const expiryMonthErrorMsg = () => {
  return i18nTranslate(
    'validation.expiryMonthErrorText',
    'Please Enter valid month'
  )
}

const expiryYearErrorMsg = () => {
  return i18nTranslate(
    'validation.expiryYearErrorText',
    'Please Enter valid year'
  )
}
const expiryDateErrorMsg = () => {
  return i18nTranslate(
    'validation.expiryDateErrorText',
    'Please enter valid expiry date'
  )
}
const CardErrorMsg = () => {
  return i18nTranslate(
    'validation.cardNumberErrorText',
    'Please Enter valid card number'
  )
}

const cardCvvErrorMsg = () => {
  return i18nTranslate(
    'validation.cardCvvErrorText',
    'Please enter valid security code'
  )
}

const zipCodeRequiredMsg = () => {
  return i18nTranslate(
    'validation.zipCodeRequiredText',
    'Please enter zip code'
  )
}

const zipCodeErrorMsg = () => {
  return i18nTranslate(
    'validation.zipCodeErrorText',
    'Please enter a valid zip code'
  )
}
const postalCodeErrorMsg = () => {
  return i18nTranslate(
    'validation.postalCodeErrorText',
    'Please enter a valid postal code'
  )
}
const poNumberError = () => {
  return i18nTranslate('validation.PONumber', 'Please enter a valid PO Number')
}
const budgetNumberError = () => {
  return i18nTranslate(
    'validation.totalBudget',
    'Please enter the Budget Amount'
  )
}
const organizationRequiredErrorMsg = () => {
  return i18nTranslate(
    'validation.organizationErrorMessage',
    'Please enter a valid organization Name'
  )
}
const taxIdErrorMsg = () => {
  return i18nTranslate(
    'validation.taxIdErrorText',
    'Please enter a valid Social Security Number'
  )
}
const taxIdEmployeeErrorMsg = () => {
  return i18nTranslate(
    'validation.taxIdEmployeeIdErrorText',
    'Please enter a valid Employer Identification Number'
  )
}
const dunsErrorMsg = () => {
  return i18nTranslate('validation.dunsErrorText', 'Please enter a valid DUNS')
}
const teamNameErrorMsg = () => {
  return i18nTranslate(
    'validation.teamNameErrorText',
    'Please enter a Team Name'
  )
}

const accountTypeErrorMsg = () => {
  return i18nTranslate(
    'validation.accountTypeErrorText',
    'Please choose an account type'
  )
}
const costCenterErrorMsg = () => {
  return i18nTranslate(
    'validation.costCenterErrorText',
    'Please select a Cost Center Id'
  )
}
const addressUserNameErrorMsg = () => {
  return i18nTranslate(
    'validation.addressUserName',
    'Please enter a valid name'
  )
}
const bEFirstNameErrorMessage = () => {
  return i18nTranslate('validation.bEFirstName', 'Please enter a valid name')
}
const userNameErrorMsg = () => {
  return i18nTranslate(
    'validation.userNameErrorText',
    'Please enter a valid email / Mobile Phone Number'
  )
}
const listNameErrorMsg = () => {
  return i18nTranslate(
    'validation.listNameErrorText',
    'Please enter a valid list Name'
  )
}

const reasonCodeErrorMsg = () => {
  return i18nTranslate(
    'validation.reasonCodeErrorText',
    'Please enter a valid Reason'
  )
}
const integerNumberErrorMsg = () => {
  return i18nTranslate(
    'validation.integerNumberErrorMsg',
    'Please enter a valid number'
  )
}
const alphaNumericErrorMessage = () => {
  return i18nTranslate(
    'validation.alphaNumericErrorMessage',
    'Please enter a valid string'
  )
}
const dateErrorMsg = () => {
  return i18nTranslate('validation.dateErrorMsg', 'Please select a date')
}

const alphaErrorMsg = () => {
  return i18nTranslate(
    'validation.alphaErrorMsg',
    'Please enter a valid string'
  )
}
const regexErrorMsg = () => {
  return i18nTranslate(
    'validation.regexErrorMsg',
    'Please enter a valid regex number'
  )
}
const urlErrorMsg = () => {
  return i18nTranslate('validation.regexErrorMsg', 'Please enter a valid a url')
}
const sponsorIdErrorMsg = () => {
  return i18nTranslate(
    'validation.sponsorIdErrorText',
    'Please enter a valid Sponsor Id'
  )
}

const sponsorIdRetailErrorMsg = () => {
  return i18nTranslate(
    'validation.sponsorIdRetailErrorText',
    'Sponsor Account entered is incorrect, please enter a valid Sponsor Account. If you do not have a Sponsor id, please skip this field and proceed.'
  )
}

const dateOfBirthErrorMsg = () => {
  return i18nTranslate(
    'validation.dateOfBirthErrorText',
    'To Register as a Brand Affiliate , You should satisfy the min Age requirement 18 Years.'
  )
}

const dateOfBirthRestrictErrorMsg = () => {
  return i18nTranslate(
    'validation.dateOfBirthAgeRestrictError',
    'You must be at least 18 years old to join as a Brand Affiliate.'
  )
}

const selectBusinessTaxIDErrorMsg = () => {
  return i18nTranslate(
    'validation.selectBusinessTaxIDErrorMsg',
    'Please select a Business TaxId Type'
  )
}
const refundAndPrivacyErrorMsg = () => {
  return i18nTranslate(
    'validation.refundAndPrivacyErrorText',
    'Please acknowledge refund and privacy'
  )
}

const addPaymentToProfileErrorMsg = () => {
  return i18nTranslate(
    'validation.addPaymentToProfileErrorMsg',
    'For subscription, payment type has to be saved'
  )
}

const savePaymentToProfileErrorMsg = () => {
  return i18nTranslate(
    'validation.savePaymentToProfileErrorMsg',
    'Payment type has to be saved'
  )
}

const refundAndPrivacyBrandErrorMsg = () => {
  return i18nTranslate(
    'validation.refundAndPrivacyBrandErrorText',
    'Please acknowledge refund and privacy'
  )
}

const termsOfUseBrandErrorMsg = () => {
  return i18nTranslate(
    'validation.termsOfUseBrandErrorText',
    'Please read and agree to the terms of Use'
  )
}

const termsOfUseErrorMsg = () => {
  return i18nTranslate(
    'validation.termsOfUseErrorText',
    'Please read and agree to the terms of Use'
  )
}

const marketingEmailErrorMsg = () => {
  return i18nTranslate(
    'validation.marketingEmailErrorText',
    'Please agree to receive marketing email'
  )
}

const signupSmsErrorMsg = () => {
  return i18nTranslate(
    'validation.signupSmsErrorText',
    'Please agree to receive text message'
  )
}

const signupRewardsErrorMsg = () => {
  return i18nTranslate(
    'validation.signupRewardsErrorText',
    'Please agree to enjoy the rewards'
  )
}

const signupTaxIdErrorMsg = () => {
  return i18nTranslate(
    'validation.signupTaxIdErrorText',
    'Please enter a valid Social Security Number'
  )
}
const signupAddressLine1ErrorMsg = () => {
  return i18nTranslate(
    'validation.signupAddressLine1ErrorText',
    'Please enter a valid Address Line1'
  )
}

const signupCityErrorMsg = () => {
  return i18nTranslate(
    'validation.signupCityErrorText',
    'Please enter a valid City'
  )
}
const signupStateErrorMsg = () => {
  return i18nTranslate(
    'validation.signupStateErrorText',
    'Please enter a valid State'
  )
}
const signupPostalCodeErrorMsg = () => {
  return i18nTranslate(
    'validation.signupPostalCodeErrorText',
    'Please enter a valid postal Code'
  )
}
const signupZipCodeErrorMsg = () => {
  return i18nTranslate(
    'validation.signupZipCodeErrorText',
    'Please enter a valid Zip Code'
  )
}

const invalidStoreCreditsMsg = () => {
  return i18nTranslate(
    'validation.storeCreditsErrorText',
    'Please enter a valid amount, Ex: 1 or 0.1 or 0.01'
  )
}

const insufficientStoreCreditsMsg = () => {
  return i18nTranslate(
    'validation.insufficientStoreCredits',
    'In-sufficient store credit balance'
  )
}

const storeCreditsExceedingCartTotalMsg = () => {
  return i18nTranslate(
    'validation.storeCreditsExceedingCartTotal',
    'Provided value is more than the grand total'
  )
}

export {
  emailErrorMsg,
  passwordErrorMsg,
  confirmPasswordErrorMsg,
  firstNameErrorMsg,
  lastNameErrorMsg,
  phoneNumberErrorMsg,
  securityQuestionErrorMsg,
  securityAnswerErrorMsg,
  addressErrorMsg,
  cityErrorMsg,
  stateErrorMsg,
  countryErrorMsg,
  requiredErrorMsg,
  invalidCouponErrorMsg,
  invalidLoyaltyErrorMsg,
  invalidLoyaltyAmountErrorMsg,
  expiryMonthErrorMsg,
  expiryYearErrorMsg,
  CardErrorMsg,
  cardCvvErrorMsg,
  zipCodeErrorMsg,
  postalCodeErrorMsg,
  poNumberError,
  organizationRequiredErrorMsg,
  taxIdErrorMsg,
  taxIdEmployeeErrorMsg,
  expiryDateErrorMsg,
  dunsErrorMsg,
  budgetNumberError,
  teamNameErrorMsg,
  accountTypeErrorMsg,
  costCenterErrorMsg,
  userNameErrorMsg,
  listNameErrorMsg,
  reasonCodeErrorMsg,
  industryErrorMsg,
  industrySizeErrorMsg,
  addressUserNameErrorMsg,
  bEFirstNameErrorMessage,
  maxLengthErrorMsg,
  integerNumberErrorMsg,
  dateErrorMsg,
  alphaNumericErrorMessage,
  alphaErrorMsg,
  regexErrorMsg,
  urlErrorMsg,
  zipCodeRequiredMsg,
  userExistErrorMessage,
  userExistRequiredErrorMessage,
  sponsorIdErrorMsg,
  sponsorIdRetailErrorMsg,
  dateOfBirthErrorMsg,
  dateOfBirthRestrictErrorMsg,
  selectBusinessTaxIDErrorMsg,
  signupTaxIdErrorMsg,
  signupAddressLine1ErrorMsg,
  signupCityErrorMsg,
  signupPostalCodeErrorMsg,
  signupZipCodeErrorMsg,
  signupStateErrorMsg,
  refundAndPrivacyErrorMsg,
  termsOfUseErrorMsg,
  marketingEmailErrorMsg,
  signupSmsErrorMsg,
  signupRewardsErrorMsg,
  termsOfUseBrandErrorMsg,
  refundAndPrivacyBrandErrorMsg,
  invalidStoreCreditsMsg,
  insufficientStoreCreditsMsg,
  storeCreditsExceedingCartTotalMsg,
  addPaymentToProfileErrorMsg,
  savePaymentToProfileErrorMsg,
  emailDomainErrorMsg,
  invalidLoyaltyZeroErrorMsg,
  invalidPromocodeErrorMsg,
}
