import { getLocaleCodeFromUrl } from 'src/utils'
import { i18nTranslate } from 'src/utils'

const localeValue = getLocaleCodeFromUrl({
  defaultLocale: 'en_US',
  isReverseType: true,
})

const baseUrl = `/content/nuskin/${localeValue}`

export const staticDropDownLinks = () => {
  // @todo
  // @vetri
  // https://nuskin.atlassian.net/browse/CX12-8479
  const localeFileName = 'ssr-resource'
  if (localeValue?.includes('_US')) {
    return [
      {
        parentId: 'skinandbeauty',
        label: i18nTranslate('menu.mostPopular', 'Most Popular', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-skin-and-beauty/popular.html`,
        },
      },
      {
        parentId: 'skinandbeauty',
        label: i18nTranslate('menu.science', 'Science', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/science/explore-nu-skin-science.html`,
        },
      },
      {
        parentId: 'skinandbeauty',
        label: i18nTranslate('menu.kitsAndBundles', 'Kits & Bundles', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/nuskin-bundles.html`,
        },
      },
      {
        parentId: 'nutrition',
        label: i18nTranslate('menu.mostPopular', 'Most Popular', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-nutrition/popular.html`,
        },
      },
      {
        parentId: 'nutrition',
        label: i18nTranslate('menu.science', 'Science', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/science/explore-nu-skin-science.html`,
        },
      },
      // {
      //   parentId: 'nutrition',
      //   label: i18nTranslate('menu.kitsAndBundles', 'Kits & Bundles',
      // nameSpace: localeFileName,
      // }),
      //   identifier: 'nutrition_kits_and_bundles',
      //   linkInfo: {
      //     link: `${baseUrl}/pharmanex-bundles.html`,
      //   },
      // },
      {
        parentId: 'productlines',
        label: i18nTranslate('menu.mostPopular', 'Most Popular', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-product-lines/popular.html`,
        },
      },
      // {
      //   parentId: 'productlines',
      //   label: i18nTranslate('menu.kits', 'Kits',
      // nameSpace: localeFileName,
      // }),
      //   identifier: 'product_lines_kits_and_bundles',
      //   linkInfo: {
      //     link: `${baseUrl}/topnav-product-lines/kits.html`,
      //   },
      // },
      // {
      //   parentId: 'productlines',
      //   label: i18nTranslate('menu.promotions', 'Promotions',
      // nameSpace: localeFileName,
      // }),
      //   identifier: 'productlines_promotions',
      //   linkInfo: {
      //     link: `${baseUrl}/topnav-product-lines/promotions/current-promotions.html`,
      //   },
      // },
      // {
      //   parentId: 'productlines',
      //   label: i18nTranslate('menu.nuSkinSwag', 'Nu Skin Swag',
      // nameSpace: localeFileName,
      // }),
      //   linkInfo: {
      //     link: `${baseUrl}/topnav-product-lines/swag-items.html`,
      //   },
      // },
      {
        parentId: 'productlines',
        label: i18nTranslate('menu.lastChance', 'Last Chance', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-product-lines/last-chance.html`,
        },
      },
    ]
  } else {
    return [
      {
        parentId: 'skinandbeauty',
        label: i18nTranslate('menu.mostPopular', 'Most Popular', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-skin-and-beauty/popular.html`,
        },
      },
      {
        parentId: 'skinandbeauty',
        label: i18nTranslate('menu.science', 'Science', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-skin-and-beauty/science.html`,
        },
      },
      {
        parentId: 'skinandbeauty',
        label: i18nTranslate(
          'menu.beatuyFocusCollagen',
          'Beauty Focus Collagen+',
          {
            nameSpace: localeFileName,
          }
        ),
        linkInfo: {
          link: `${baseUrl}/topnav-product-lines/pharmanex/collagen/collagen.html`,
        },
      },
      {
        parentId: 'nutrition',
        label: i18nTranslate('menu.mostPopular', 'Most Popular', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-nutrition/popular.html`,
        },
      },
      {
        parentId: 'nutrition',
        label: i18nTranslate('menu.science', 'Science', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-skin-and-beauty/science.html`,
        },
      },
      {
        parentId: 'nutrition',
        label: i18nTranslate(
          'menu.beatuyFocusCollagen',
          'Beauty Focus Collagen+',
          {
            nameSpace: localeFileName,
          }
        ),
        linkInfo: {
          link: `${baseUrl}/topnav-product-lines/pharmanex/collagen/collagen.html`,
        },
      },
      {
        parentId: 'productlines',
        label: i18nTranslate('menu.mostPopular', 'Most Popular', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-product-lines/popular.html`,
        },
      },
      // {
      //   parentId: 'productlines',
      //   label: i18nTranslate('menu.kits', 'Kits',
      // nameSpace: localeFileName,
      // }),
      //   linkInfo: {
      //     link: `${baseUrl}/topnav-product-lines/kits.html`,
      //   },
      // },
      // {
      //   parentId: 'productlines',
      //   label: i18nTranslate('menu.promotions', 'Promotions',
      // nameSpace: localeFileName,
      // }),
      //   linkInfo: {
      //     link: `${baseUrl}/topnav-product-lines/promotions/current-promotions.html`,
      //   },
      // },
      // {
      //   parentId: 'productlines',
      //   label: i18nTranslate('menu.nuSkinSwag', 'Nu Skin Swag',
      // nameSpace: localeFileName,
      // }),
      //   linkInfo: {
      //     link: `${baseUrl}/topnav-product-lines/swag-items.html`,
      //   },
      // },
      {
        parentId: 'productlines',
        label: i18nTranslate('menu.lastChance', 'Last Chance', {
          nameSpace: localeFileName,
        }),
        linkInfo: {
          link: `${baseUrl}/topnav-product-lines/last-chance.html`,
        },
      },
    ]
  }
}
/**
 * Returns the locale-specific link for the 'Chat With Us' page.
 * Constructs the link based on the current locale code, default locale,
 * and whether locale should be reversed in the URL.
 */
export const getLocaleBasedLink = () => {
  const link = `${baseUrl}/corporate/help/chat.html`
  return link
}
