import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import { i18nTranslate } from 'src/utils'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
// import { Droppable } from 'react-beautiful-dnd'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import { IoMdClose as CloseIcon } from 'react-icons/io'
import { MdShoppingCart as ShoppingCartIcon } from 'react-icons/md'
import { outFitsContainer } from 'src/models'
import { toggleRemoveModal } from '../RemoveModal'
import { outfitModalState } from '../OutFitsModel'
import { DraggableContainer } from './OutfitGridLayout'
import './styles.scss'

@observer
class Outfits extends React.Component {
  @action
  deleteOutfitItem = async (outFitId, outfitItemProduct) => {
    if ((outfitItemProduct?.outFitItem || '') === 'new') {
      const listItems = outFitsContainer.currentOutFit.listItems
      const { updateRowItems } = this.props
      outFitsContainer.currentOutFit.listItems = listItems.filter(
        product => outfitItemProduct.key !== product.key
      )
      isFunction(updateRowItems) && updateRowItems()
      return
    }
    await outFitsContainer.deleteOutFitItem(outFitId, outfitItemProduct.id)
    outFitsContainer.currentOutFit.listItems.length === 0 &&
      outfitModalState.setModalVisibility(false)
  }

  handleDelete = props => {
    const { outfitItemProduct, outFitId, index } = props
    const removeText = i18nTranslate(
      'outfit.removeOutfitItem',
      'Are you sure want to remove the item from outfit ?'
    )
    if ((outfitItemProduct?.outFitItem || '') === 'new') {
      this.deleteOutfitItem(outFitId, outfitItemProduct, index)
    } else {
      toggleRemoveModal(removeText, () => {
        this.deleteOutfitItem(outFitId, outfitItemProduct, index)
      })
    }
  }

  renderOutfitItemImages = (outfitItemProducts, outFitId, outfitRowIndex) => {
    const { isOutFitView = false, isSharedOutfitView = false } = this.props

    if (isOutFitView) {
      const outfitItemImages = outfitItemProducts.map(
        (outfitItemProduct, index) => {
          return (
            <DraggableContainer
              className="outfit-image"
              id={outfitItemProduct.id}
              key={outfitItemProduct.id}
              outFitId={outFitId}
              index={index}>
              <div
                className={`${outfitItemProduct.properties.type} outfit-item-image position-relative`}>
                <CloseIcon
                  size="1rem"
                  className="outfit-item-close-icon"
                  onClick={() => {
                    this.handleDelete({ outfitItemProduct, outFitId, index })
                  }}
                />
                {/* {(outfitItemProduct.properties.outFitFrom || '') ===
                    'wishlist' && (
                    <Button
                      variant="primary"
                      className="wishlist-button"
                      onClick={() => {
                        outFitsContainer.addToCart(outfitItemProduct, outFitId)
                      }}>
                      <ShoppingCartIcon size="1.5rem" />
                    </Button>
                  )} */}
                {outfitItemProduct.outfitItemType === 'recommended' &&
                  outFitsContainer.getSingleRecommendation && (
                    <div className="bg-success text-white text-center position-absolute w-100 py-2">
                      {i18nTranslate('outfit.recommended', 'Perfect Match')}
                    </div>
                  )}
                <Link
                  to={`/product/${outfitItemProduct.properties.productId}`}
                  className="d-flex justify-content-center align-items-center h-100 w-100">
                  <img
                    className="outfit-item-img"
                    src={outfitItemProduct.properties.imageURL}
                    alt="outfit-item"
                  />
                </Link>
              </div>
            </DraggableContainer>
          )
        }
      )

      return (
        <div className={`outfit-products`}>
          {outfitItemImages}
          {provided.placeholder}
        </div>
      )

      // return (
      //   <Droppable
      //     isDropDisabled={false}
      //     droppableId={`outfit-droppable-${outfitRowIndex}`}
      //     // droppableId={`outfit-droppable-${
      //     //   outFitsContainer.currentOutFit.id
      //     // }-${outfitRowIndex}`}
      //     type="outfit-drop">
      //     {provided => (
      //       <div
      //         {...provided.droppableProps}
      //         ref={provided.innerRef}
      //         className={`outfit-products`}>
      //         {outfitItemImages}
      //         {provided.placeholder}
      //       </div>
      //     )}
      //   </Droppable>
      // )
    } else {
      if (outfitItemProducts.length) {
        const outfitItemImages = outfitItemProducts.map(
          (outfitItemProduct, idx) => {
            if (isSharedOutfitView) {
              return (
                <Link
                  key={`of-img-${idx}`}
                  to={`/product/${outfitItemProduct.properties.productId}`}
                  className="h-100 w-100">
                  {this.renderOutfitView(outfitItemProduct)}
                </Link>
              )
            } else {
              return this.renderOutfitView(outfitItemProduct)
            }
          }
        )
        return outfitItemImages
      }
    }
    return <></>
  }

  renderOutfitView = outfitItemProduct => {
    return (
      <div
        className={`outfit-item-image d-flex justify-content-center align-items-center position-relative ${outfitItemProduct.properties.type} `}>
        <img
          className="outfit-item-img"
          src={outfitItemProduct.properties.imageURL}
          alt="outfit-item"
        />
      </div>
    )
  }

  handleClick = () => {
    const { isOutFitView = false, toggleModal, outfitItem, index } = this.props
    if (!isOutFitView) {
      isFunction(toggleModal) && toggleModal()
      outFitsContainer.setCurrentOutFit(outfitItem.id)
      outFitsContainer.currentSlideIndex = index
      outFitsContainer.setListType('outFit')
    }
  }

  getProducts = () => {
    const { outfitItem: item, isOutFitView = false } = this.props
    const { currentOutFit } = outFitsContainer
    // const outfitItem =
    //   isOutFitView &&
    //   currentOutFit.isOutFitsEdited &&
    //   currentOutFit.id === item.id
    //     ? currentOutFit
    //     : item
    const outfitItem = isOutFitView ? currentOutFit : item
    const outfitItemProducts = outfitItem?.listItems || []

    if (isOutFitView && currentOutFit.isOutFitsEdited) {
      const { row1Products, row2Products } = this.props
      return {
        row1Products,
        row2Products,
      }
    } else {
      const mainProducts =
        outfitItemProducts.filter(outfitItemProduct => {
          return (
            get(outfitItemProduct, 'properties.type', '') === 'shirts' ||
            get(outfitItemProduct, 'properties.type', '') === 'pants'
          )
        }) || []

      const accessoriesProducts =
        outfitItemProducts.filter(outfitItemProduct => {
          return (
            (outfitItemProduct?.properties?.type || '') !== 'shirts' &&
            (outfitItemProduct?.properties?.type || '') !== 'pants'
          )
        }) || []
      return {
        accessoriesOutfits: accessoriesProducts,
        mainOutfits: mainProducts,
      }
    }
  }

  render() {
    const {
      outfitItem: item,
      className,
      isOutFitView = false,
      isSharedOutfitView = false,
    } = this.props
    const { currentOutFit, listShowType } = outFitsContainer
    // const outfitItem =
    //   isOutFitView &&
    //   currentOutFit.isOutFitsEdited &&
    //   currentOutFit.id === item.id
    //     ? currentOutFit
    //     : item
    const outfitItem = isOutFitView ? currentOutFit : item

    const products = this.getProducts()
    const removeConfirmationText = i18nTranslate(
      'outfit.removeOutfit',
      'Are you sure want to remove the outfit ?'
    )
    const showCloseIcon = !isOutFitView && !isSharedOutfitView
    return (
      <div className="d-flex justify-content-center">
        <div
          className={`outfit-item-wrapper position-relative p-1 ${className} ${
            isOutFitView ? '' : 'border'
          } ${outFitsContainer.listShowType}`}
          key={outfitItem.listId}
          onClick={this.handleClick}>
          {showCloseIcon && (
            <div
              className="close-icon-outfit"
              onClick={event => {
                event.stopPropagation()
                toggleRemoveModal(removeConfirmationText, () => {
                  outFitsContainer.deleteOutfit(outfitItem.listId)
                })
              }}>
              <CloseIcon size="1rem" />
            </div>
          )}
          {/* {isOutFitView ? (
            <OutfitGridLayout products={outfitItemProducts} />
          ) : ( */}
          {Object.entries(products).map((data, index) => {
            const [key, outfit] = data
            return (
              <div key={key} className={`outfit-image-wrapper ${key}`}>
                {this.renderOutfitItemImages(outfit, outfitItem.id, index)}
              </div>
            )
          })}
          {isOutFitView && listShowType === 'outFit' && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-add-outfit">
                  {i18nTranslate('outfit.addToCart', 'Add Outfit to cart')}
                </Tooltip>
              }>
              <Button
                variant=""
                className="p-0 m-0 position-absolute add-all-outfit text-dark"
                onClick={async () => {
                  await outFitsContainer.addOutfitToCart()
                  outfitModalState.setModalVisibility(false)
                }}>
                <ShoppingCartIcon size="2rem" />
              </Button>
            </OverlayTrigger>
          )}
        </div>
      </div>
    )
  }
}

class OutFitsList extends React.Component {
  render() {
    const outFitItems = outFitsContainer.outFitsList
    const outFits = outFitItems.map((outfitItem, index) => {
      return (
        <div key={outfitItem.id}>
          <Outfits
            className="all-outfits"
            key={outfitItem.id}
            index={index}
            outfitItem={outfitItem}
            {...this.props}
          />
          <div className="outfit-name my-2">{outfitItem.name}</div>
        </div>
      )
    })
    return <div className="outfit-items-wrapper">{outFits}</div>
  }
}

export default Outfits
export { Outfits, OutFitsList }
