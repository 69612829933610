import React from 'react'
import { Modal } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import './styles.scss'

/**
 * DrawerModal component.
 * Renders a modal dialog with custom styling.
 * @param {Object} props
 * @param {boolean} props.show - Whether to show the modal
 * @param {Function} props.onHide - Callback when modal is hidden
 * @param {Function} props.renderView - Renders modal content
 */
class DrawerModal extends React.Component {
  render() {
    const {
      show = false,
      onHide = function () {},
      renderView = function () {},
      showCloseButton = true,
      customClass = '',
      title = '',
      label = 'edit subscription',
      testId = 'qa-subscription-edit-modal-header-wrapper',
    } = this.props
    return (
      <div>
        <Modal
          show={show}
          tabIndex={0}
          onHide={() => onHide()}
          dialogClassName={`modal-dialog-custom-right drawer-model-wrapper ${customClass} `}
          aria-label={label}>
          <Modal.Header data-testid={testId} closeButton={showCloseButton}>
            <Modal.Title>
              {title
                ? title
                : i18nTranslate(
                    'subscription.drawerPopupTitle',
                    'Add to Subscription'
                  )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{renderView()}</Modal.Body>
        </Modal>
      </div>
    )
  }
}
export default DrawerModal
export { DrawerModal }
