import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Link } from 'react-router-dom'
import { pageNames } from 'src/routes/pathParams'
import { APPConfig } from 'config/appConfig'
import { getCategoryLinks } from '../deps'
import { renderLinkCheck } from 'src/utils'

class SubCategories extends React.PureComponent {
  nameSpace = 'ssr-resource'
  handleClick = () => {
    this.props.handleToggle()
  }

  hardcodedCategoryLinks = {
    sustainability:
      'https://www.nuskin.com/content/globalffg/sustainability/home.html',
    socialimpact: 'https://www.nuskin.com/content/globalffg/home.html',
  }

  LinkOrA = (identifier, children, navigateTo = '', className = '') => {
    const linkObj = renderLinkCheck(navigateTo)
    if (this.hardcodedCategoryLinks[identifier] || linkObj?.isThirdPartyPage) {
      return (
        <a
          className={className}
          href={linkObj?.link || this.hardcodedCategoryLinks[identifier]}
          target="_blank"
          rel="noopener noreferrer">
          {children}
        </a>
      )
    }

    return (
      <Link
        className={className}
        onClick={() => this.handleClick(identifier)}
        to={identifier ? navigateTo : '#'}>
        {children}
      </Link>
    )
  }

  renderSubCategoryList = subCategories => {
    const identifier = subCategories?.identifier || ''
    const navigateTo = getCategoryLinks(subCategories, false)
    const subCategoryName = subCategories?.properties?.name || ''
    return (
      <div
        className="sub-item"
        key={`${identifier}_${Math.floor(Math.random() * 100)}`}>
        {this.LinkOrA(
          identifier,
          <span data-testid="qa-category">{subCategoryName}</span>,
          navigateTo
        )}
      </div>
    )
  }

  render() {
    const { subCategory } = this.props
    const { properties, subCategories = [] } = subCategory
    const {
      name: subCategoryName = '',
      meta_description = i18nTranslate(
        'menu.description',
        'Description not available for this product/category.',
        { nameSpace: this.nameSpace }
      ),
      imageURL = '',
    } = properties

    // Fix for CX121-4435
    const subCategoryId = subCategory?.identifier?.toLowerCase() || ''
    let isToHideCategory =
      subCategoryId == 'skin_and_beauty_categories' ||
      subCategoryId == 'nutrition_categories' ||
      subCategory?.subCategories?.[0]?.isFromOurImpact

    // related to CX121-4435
    // pdn fix requested by Kas
    // discussed with Anto/Poovarasan
    const parentCategoryRedirectionMapping =
      APPConfig?.getAppConfig()?.parentCategoryRedirectionMapping || {}
    const shouldRedirectToParentCategoryFromStoreConfig =
      APPConfig?.getAppConfig()?.shouldRedirectToParentCategory === 'true'
    const isToRedirectToCategoryLanding =
      shouldRedirectToParentCategoryFromStoreConfig &&
      Object.keys(parentCategoryRedirectionMapping).some(category => {
        return subCategoryId == category
      })

    const navigateTo = getCategoryLinks(subCategory, false)
    const subCategoryJSX = (className = '') => (
      <div className={`${className} sub-item`}>
        {this.LinkOrA(
          subCategory.identifier,
          <span data-testid="qa-category">{subCategoryName}</span>,
          navigateTo
        )}
      </div>
    )

    const element = document.querySelector('.ourimpact')
    const offsetLeft = element?.getBoundingClientRect?.()?.left || 0
    const ourImpactStyle = {
      position: 'fixed',
      left: offsetLeft,
      width: 'max-content',
    }

    const subCategoryDisplayDom = (
      <span data-testid="qa-category">{subCategoryName}</span>
    )
    return (
      <React.Fragment key={`category_${subCategoryName}`}>
        {subCategories.length === 0 && (
          <>
            {/* {imageURL && (
              <Row className="my-5">
                <Col className="text-right">
                  <Image src={imageURL} height="200px" width="auto"></Image>
                </Col>
                <Col className="text-left">
                  {subCategoryJSX('font-weight-bold')}
                  <span className="nu-menu-subcategory-desc">
                    {meta_description}
                  </span>
                </Col>
              </Row>
            )} */}

            {/* {!imageURL && subCategoryJSX()} */}
            {subCategoryJSX()}
          </>
        )}

        {subCategories.length > 0 && (
          <>
            <div className="font-weight-bold sub-item text-uppercase">
              {/*
               * https://nuskin.atlassian.net/browse/CX32-6916
               * https://nuskin.atlassian.net/browse/CX32-7086
               * for our impacts, isDummyCategory will be true,
               * links should not be clickable
               */}
              {!isToRedirectToCategoryLanding
                ? subCategory?.subCategories?.[0]?.isFromOurImpact
                  ? subCategoryDisplayDom
                  : this.LinkOrA(
                      subCategory.identifier,
                      subCategoryDisplayDom,
                      navigateTo
                    )
                : this.LinkOrA(
                    subCategory.identifier,
                    subCategoryDisplayDom,
                    `${pageNames.categoryLanding}/${parentCategoryRedirectionMapping[subCategoryId]}`
                  )}
            </div>

            {subCategory?.identifier === 'ourimpact' && (
              <div style={ourImpactStyle}>
                {subCategories.map(this.renderSubCategoryList)}
              </div>
            )}
            {subCategory.identifier !== 'ourimpact' && (
              <>
                {subCategories.map(this.renderSubCategoryList)}
                {!isToHideCategory &&
                  this.LinkOrA(
                    subCategory.identifier,
                    <span data-testid="qa-category">
                      {i18nTranslate('menu.seeAll', 'See All', {
                        nameSpace: this.nameSpace,
                      })}{' '}
                      {subCategoryName}
                    </span>,
                    navigateTo,
                    'nu-showall-link'
                  )}
              </>
            )}
          </>
        )}
      </React.Fragment>
    )
  }
}

export { SubCategories }
