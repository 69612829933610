import { observable } from 'mobx'
import { i18nTranslate } from 'src/utils'

import { CommonContainer } from 'src/models/Common'
import { toastState } from 'src/views/components'

/**
 * CostCenterContainer class extends CommonContainer.
 * This appears to be a model class representing a cost center entity.
 */
class CostCenterContainer extends CommonContainer {
  // @observable accountId = ''
  @observable costCenterList = []
  @observable costCentreWithoutSizeData = []
  /**
   * Deletes a cost center by ID.
   *
   * Fetches the account ID. Constructs a request to the deleteCostCenter API endpoint.
   * On success, shows a success toast and refetches all cost centers.
   * On failure, shows an error toast.
   */
  deleteCostCenter = async costCenterId => {
    const accountId = await this.getAccountId()
    if (accountId !== '') {
      const loadParams = {
        endPointName: 'deleteCostCenter',
        pathParams: `${accountId}/costcentre/${costCenterId}`,
      }
      const response = await this.fetchResponse(loadParams)
      if (this.isSuccessResponse(response)) {
        toastState.setToastMessage(
          i18nTranslate(
            'costcenter.deleteSuccess',
            'Cost center deleted successfully'
          ),
          true
        )
        return await this.getAllCostCenter()
      } else {
        toastState.setToastMessage(response.message, false)
      }
    }
  }
  /**
   * Creates a new cost center.
   *
   * Takes in cost center data. Gets the account ID.
   * Calls the createCostCenter API endpoint to create the cost center server-side.
   * Shows a success toast message on success.
   * Shows an error toast message on failure.
   * Refetches all cost centers after creating.
   */
  createCostCenter = async data => {
    const accountId = await this.getAccountId()
    const loadParams = {
      endPointName: 'createCostCenter',
      pathParams: `${accountId}/costcentre`,
      postData: data,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'costcenter.createSuccess',
          'Cost center created successfully'
        ),
        true
      )
      return await this.getAllCostCenter()
    } else {
      toastState.setToastMessage(response.message, false)
    }
  }
  /**
   * Constructs a filter string for querying cost centers based on the provided filter parameters.
   *
   * Checks for a searchTerm parameter to filter on cost center ID or name.
   * Checks for a selectTeamStatus parameter to filter on status.
   * Combines filters into a filter string.
   * Returns filter object with filter string if any filters are set, otherwise empty object.
   */
  constructTeamFilter = (filterParams = {}) => {
    let filterString = ''
    if (filterParams.searchTerm) {
      if (isNaN(filterParams.searchTerm) === false) {
        filterString += `id IN "${filterParams.searchTerm}"`
      } else {
        filterString += `name LIKE "%*${filterParams.searchTerm}*%"`
      }
    }
    if (
      filterParams.selectTeamStatus &&
      filterParams.selectTeamStatus !== 'All'
    ) {
      filterString += `status IN "${filterParams.selectTeamStatus}"`
    }
    if (filterString) {
      return {
        filter: filterString,
      }
    }
    return {}
  }
  /**
   * Fetches all cost centers filtered by the provided parameters.
   *
   * Gets the account ID.
   * Sets default pagination.
   * Constructs filter string from parameters.
   * Makes API request with filters, pagination.
   * Updates component state with response.
   * Shows error toast on failure.
   * Returns response.
   */
  getAllCostCenter = async filterParams => {
    const accountId = await this.getAccountId()
    const page = filterParams?.page || 1

    if (accountId !== '') {
      const filterProps = this.constructTeamFilter(filterParams)
      const params = {
        page: page,
        size: 5,
        ...filterProps,
      }
      const loadParams = {
        endPointName: 'getAllCostCenter',
        pathParams: `${accountId}/costcentre`,
        queryParams: params,
      }
      const response = await this.fetchResponse(loadParams)
      this.costCenterList = response
      if (!this.isSuccessResponse(response)) {
        // const message =
        //   response && response.message
        //     ? response.message
        //     : i18nTranslate('costcenter.failure', 'Costcenter get failed')
        toastState.setToastMessage(
          i18nTranslate('costcenter.failure', 'No results found'),
          false
        )
      }
      return response
    }
    return []
  }
  /**
   * Fetches all cost centers without specifying page size.
   *
   * Gets the account ID.
   * Sets default page size to total cost center count.
   * Constructs request parameters with page size and page number.
   * Makes API request with page size and page number.
   */
  getAllCostCenterWithoutSize = async () => {
    const accountId = await this.getAccountId()
    const size = this.costCenterList?.pageableInfo?.totalCount || 20
    if (accountId !== '') {
      const params = {
        size,
        page: 1,
      }
      const loadParams = {
        endPointName: 'getAllCostCenter',
        pathParams: `${accountId}/costcentre`,
        queryParams: params,
      }
      const response = await this.fetchResponse(loadParams)
      this.costCentreWithoutSizeData = response
      // return response
    }
  }
  /**
   * Fetches details of a specific cost center by ID.
   *
   * Gets the account ID.
   * Constructs request parameters with cost center ID.
   * Makes API request to get cost center details.
   * Returns the API response.
   */
  getOneCostCenter = async costCenterId => {
    const accountId = await this.getAccountId()
    if (accountId !== '') {
      const loadParams = {
        endPointName: 'getCostCenter',
        pathParams: `${accountId}/costcentre/${costCenterId}`,
      }
      const response = await this.fetchResponse(loadParams)
      return response
    }
    return []
  }
  // deleteACostCenter = () => {}
  /**
   * Updates a cost center.
   */
  updateCostCenter = async data => {
    const accountId = await this.getAccountId()
    const { costCenterId, ...remainingProps } = data
    const loadParams = {
      endPointName: 'updateCostCenter',
      pathParams: `${accountId}/costcentre/${costCenterId}`,
      postData: remainingProps,
    }
    // To remove once patch support to delete available cost center Start
    await this.getBudgets(data, accountId)
    // To remove once patch support to delete available cost center End
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'costcenter.updateSuccess',
          'Cost center updated successfully'
        )
      )
      return await this.getAllCostCenter()
    } else {
      toastState.setToastMessage(response.message, false)
      return response
    }
  }
  // To remove once patch support to delete available cost center Start
  /**
   * Fetches all budgets for a cost center and deletes any budgets not in the provided data.
   *
   * Gets the cost center ID from the provided data.
   * Constructs request parameters to get all budgets for the cost center.
   * Makes API request to get all budgets.
   * Loops through returned budgets, checking if each budget is in the provided data.
   * Any budgets not in the provided data are added to a delete array.
   * Loops through the delete array and deletes each budget.
   */
  getBudgets = async (data, accountId) => {
    const { costCenterId } = data
    const loadParams = {
      endPointName: 'getAllBudget',
      pathParams: `${accountId}/costcentre/${costCenterId}/budgets`,
    }
    const response = await this.fetchResponse(loadParams)
    const budgetArray = []
    response.forEach(budgetList => {
      let flag = false
      data.budgets.forEach(availableBudgets => {
        if (availableBudgets.id && budgetList.id === availableBudgets.id)
          flag = true
      })
      !flag && budgetArray.push(budgetList.id)
    })
    budgetArray.forEach(id => this.deleteBudget(data, id))
  }
  /**
   * Deletes a budget for a cost center.
   *
   * Gets the account ID and cost center ID from the provided data.
   * Constructs request parameters with endpoint, account ID, cost center ID,
   * and budget ID to delete.
   * Makes API request to delete the budget.
   */
  deleteBudget = async (data, budgetId) => {
    const accountId = await this.getAccountId()
    const { costCenterId } = data
    const loadParams = {
      endPointName: 'deleteBudget',
      pathParams: `${accountId}/costcentre/${costCenterId}/budgets/${budgetId}`,
    }
    await this.fetchResponse(loadParams)
  }
  // To remove once patch support to delete available cost center End
}
const costCenterContainer = new CostCenterContainer()
export { costCenterContainer }
export default costCenterContainer
