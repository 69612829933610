import { APPConfig } from 'config/appConfig'
import React, { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
function Timer(props) {
  const { onCancel = function () {} } = props
  const klarnaCheckoutTimeLimit =
    Number(APPConfig.getAppConfig().klarnaTransactionTimer) || 300
  const time = new Date()
  let expiryTimestamp = time.setSeconds(
    time.getSeconds() + klarnaCheckoutTimeLimit
  )
  const { seconds, minutes } = useTimer({
    expiryTimestamp: expiryTimestamp,
    onExpire: () => onCancel(),
  })

  return (
    <div className="align-item-center">
      <div className="timer-wrapper">
        <span>{minutes}</span>:<span>{seconds}</span>
      </div>
    </div>
  )
}

export default Timer
