import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { setLocalStorage } from 'src/utils'
import { useState } from 'react'
import { useEffect } from 'react'
import { i18nTranslate, convertToBoolean } from 'src/utils'
import { APPConfig } from 'config/appConfig'
import { FormInputLabels } from 'src/deps'
import {
  createSiteObject,
  getIroncladAcceptanceData,
  checkboxValidationCheck,
  loadClickWrapContainer,
  toggleIronCladCheck,
} from 'src/utils/signUpUtils'
import {
  IronCladContractPage2,
  IronCladContractPage3,
} from 'src/views/components/IronClad'
import { accountsContainer } from 'src/models'
import './styles.scss'
import './custom_aos.scss'

function Terms(props) {
  const { getConsent, showPhoneVerificationPopup } = props || {}
  const [show, setShow] = useState(true)
  const [term1, setTerm1] = useState(false)
  const [term2, setTerm2] = useState(false)
  const [isIronCladChecked, setIronCladChecked] = useState(false)
  const [isToShowStep2IronCladModal, SetToShowStep2IronCladModal] =
    useState(false)
  const [isToShowStep3IronCladModal, SetToShowStep3IronCladModal] =
    useState(false)
  const handleClose = (isToShowPhonePopup = false) => {
    setShow(false)
    setLocalStorage('check', false)
    if (!isToShowPhonePopup) {
      showPhoneVerificationPopup()
    }
  }
  const formInputLabels = new FormInputLabels()

  function openDocument(documentLink) {
    if (typeof window !== 'undefined') {
      window.open(documentLink)
    }
  }

  const [disable, setDisable] = useState(true)
  const [ironCladCheckedString, setIsIronCladChecked] = useState('')

  const isToEnableIronClad =
    APPConfig?.getAppConfig()?.FEATURE_IRONCLAD_IMPLEMENTATION === 'true' ||
    false
  const brandAffiliateICGroupKey =
    APPConfig?.getAppConfig()?.brandAffiliateICGroupKey || 'embedded-npi-usvqn'

  useEffect(() => {
    loadClickWrapContainer()
    createSiteObject(brandAffiliateICGroupKey, 'contractPage-1')
  }, [])
  useEffect(() => {
    if (_ps) {
      _ps.on('checked', element => {
        const isIronCladString = toggleIronCladCheck(true, element)
        setIsIronCladChecked(isIronCladString)
      })
      _ps.on('unchecked', element => {
        const isIronCladString = toggleIronCladCheck(false, element)
        setIsIronCladChecked(isIronCladString)
      })
    }
  }, [accountsContainer?.accountsTermCheck])

  useEffect(() => {
    if ((term1 && term2) || isToEnableIronClad) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }, [term1, term2])

  const agreementOne = () => {
    setTerm1(!term1)
  }
  const agreementTwo = () => {
    setTerm2(!term2)
  }

  async function verify() {
    if (isToEnableIronClad) {
      if (accountsContainer?.accountsContractCheck === false) {
        checkboxValidationCheck('add')
        return false
      }
      showIronCladPopup('contractPage-2')
      handleClose(true)
    } else {
      if (term1 && term2) {
        getConsent?.(term1, term2)
        handleClose()
      }
    }
  }
  const activeEnvironment = APPConfig.getLocalEnv()
  const handleIronCladContractClose = async contractId => {
    accountsContainer.accountsContractCheck = false
    SetToShowStep2IronCladModal(false)
    SetToShowStep3IronCladModal(false)
    showPhoneVerificationPopup()
  }

  const handleProceed = async contractId => {
    const response = await getIroncladAcceptanceData(brandAffiliateICGroupKey)
    const enableJitSuIroncladFeature = convertToBoolean(
      APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
    )
    if (contractId === 'contractPage-4') {
      if (enableJitSuIroncladFeature) {
        const brandAffiliateGroupKeyPage3 =
          APPConfig?.getAppConfig()?.ironCladBACWG2Key
        accountsContainer.acceptanceContractData2 = response
        const baPage2Response = await getIroncladAcceptanceData(
          brandAffiliateGroupKeyPage3
        )
        accountsContainer.acceptanceContractData3 = baPage2Response
      } else {
        accountsContainer.acceptanceContractData3 = response
      }
      const acceptancePayload =
        [
          accountsContainer?.acceptanceContractData1,
          accountsContainer?.acceptanceContractData2,
          accountsContainer?.acceptanceContractData3,
        ] || []
      getConsent?.(term1, term2, acceptancePayload)
      showPhoneVerificationPopup()
    }
    SetToShowStep2IronCladModal(false)
    SetToShowStep3IronCladModal(false)
  }

  const showIronCladPopup = async contractPageId => {
    const enableJitSuIroncladFeature = convertToBoolean(
      APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
    )
    accountsContainer.accountsContractCheck = false
    const response = await getIroncladAcceptanceData(brandAffiliateICGroupKey)
    if (contractPageId === 'contractPage-2') {
      accountsContainer.acceptanceContractData1 = response
    } else {
      accountsContainer.acceptanceContractData2 = response
    }

    createSiteObject(brandAffiliateICGroupKey, contractPageId)
    if (enableJitSuIroncladFeature) {
      const groupKeyPage3 = APPConfig?.getAppConfig()?.ironCladBACWG2Key
      createSiteObject(groupKeyPage3, 'contractPage-3')
    }
    if (contractPageId === 'contractPage-2') {
      console.log('2')
      setTimeout(() => {
        SetToShowStep2IronCladModal(true)
        SetToShowStep3IronCladModal(false)
      }, 1500)
    } else if (contractPageId === 'contractPage-3') {
      console.log('3')
      setTimeout(() => {
        SetToShowStep2IronCladModal(false)
        SetToShowStep3IronCladModal(true)
      }, 1500)
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        className="modal_box">
        <Modal.Header closeButton>
          <Modal.Title>
            {i18nTranslate(
              'nuskinEnjoyRewards.termsCondition',
              'Terms and Conditions.',
              { nameSpace: 'ssr-resource' }
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="LoyaltyCheckBox">
            <div>
              <p className="alertTemp">
                {i18nTranslate(
                  'signup.temporaryaccount',
                  'You are currently using a temporary account and need to complete the sign-up process below in order to continue operating as a Brand Affiliate. If you do not complete this sign-up process   within 60 days, all business activities on your account will be suspended and you will no longer be eligible to earn commissions. All temporary accounts will be terminated after 90 days.',
                  { nameSpace: 'ssr-resource' }
                )}
              </p>
            </div>
            {isToEnableIronClad ? (
              <>
                <div
                  id="clickwrap-container-1"
                  className="click-wrap-container"></div>
                <div className="clickwrapContainerError">
                  {i18nTranslate(
                    'ironClad.termsAndConditions',
                    'Please accept the terms & conditions*',
                    { nameSpace: 'ssr-resource' }
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="optInBox">
                  <input type="checkbox" id="region-select" checked={term1} />
                  <label htmlFor="region-select" onClick={agreementOne}>
                    <span>
                      {i18nTranslate(
                        'studioPage.acknowledge1.1',
                        'I acknowledge I have read and understand the',
                        { nameSpace: 'ssr-resource' }
                      )}
                    </span>{' '}
                  </label>
                  <div className="checkbox-modal">
                    <a
                      name="link"
                      target="_blank"
                      href={
                        formInputLabels.brandAffiliateLinks
                          ?.salesPerformancePlan
                      }
                      rel="noopener noreferrer"
                      onClick={event => {
                        event.preventDefault()
                        openDocument(
                          formInputLabels.brandAffiliateLinks
                            ?.salesPerformancePlan
                        )
                      }}>
                      {' '}
                      <u>
                        {i18nTranslate(
                          'studioPage.acknowledge1.2',
                          `Sales Performance Plan`
                        )}
                      </u>
                    </a>
                    ,{' '}
                    <a
                      name="link"
                      target="_blank"
                      href={formInputLabels.brandAffiliateLinks?.privacyNotice}
                      rel="noopener noreferrer"
                      onClick={event => {
                        event.preventDefault()
                        openDocument(
                          formInputLabels.brandAffiliateLinks?.privacyNotice
                        )
                      }}>
                      {' '}
                      <u>
                        {i18nTranslate(
                          'studioPage.acknowledge1.3',
                          `Privacy Notice`
                        )}
                      </u>
                    </a>{' '}
                    <a
                      name="link"
                      target="_blank"
                      href={formInputLabels.brandAffiliateLinks?.refundPolicy}
                      rel="noopener noreferrer"
                      onClick={event => {
                        event.preventDefault()
                        openDocument(
                          formInputLabels.brandAffiliateLinks?.refundPolicy
                        )
                      }}>
                      <u>
                        {i18nTranslate(
                          'studioPage.acknowledge1.4',
                          'Refund Policy',
                          { nameSpace: 'ssr-resource' }
                        )}
                      </u>
                    </a>{' '}
                    {i18nTranslate('signup.and', 'and', {
                      nameSpace: 'ssr-resource',
                    })}
                    <a
                      name="link"
                      target="_blank"
                      href={
                        formInputLabels.brandAffiliateLinks
                          ?.salesCompensationSummary
                      }
                      rel="noopener noreferrer"
                      onClick={event => {
                        event.preventDefault()
                        openDocument(
                          formInputLabels.brandAffiliateLinks
                            ?.salesCompensationSummary
                        )
                      }}>
                      {' '}
                      <u>
                        {i18nTranslate(
                          'studioPage.acknowledge1.5',
                          ` {' '}
                      Sales Compensation Summary`
                        )}
                      </u>
                    </a>
                    <span>*</span>
                  </div>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="region-select"
                    className="sm"
                    checked={term2}
                  />
                  <label onClick={agreementTwo}>
                    <span>
                      {i18nTranslate(
                        'studioPage.acknowledge1.6',
                        'I have read, understand and agree to the',
                        { nameSpace: 'ssr-resource' }
                      )}
                    </span>
                  </label>
                  <div className="checkbox-modal">
                    <a
                      name="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        formInputLabels.brandAffiliateLinks
                          ?.brandAffiliateAgreement
                      }
                      onClick={event => {
                        event.preventDefault()
                        openDocument(
                          formInputLabels.brandAffiliateLinks
                            ?.brandAffiliateAgreement
                        )
                      }}>
                      <u>
                        {i18nTranslate(
                          'studioPage.acknowledge1.7',
                          'Brand Affiliate Agreement',
                          { nameSpace: 'ssr-resource' }
                        )}
                      </u>
                    </a>{' '}
                    <a
                      name="link"
                      target="_blank"
                      href={
                        formInputLabels.brandAffiliateLinks
                          ?.policiesAndProcedures
                      }
                      rel="noopener noreferrer"
                      onClick={event => {
                        event.preventDefault()
                        openDocument(
                          formInputLabels.brandAffiliateLinks
                            ?.policiesAndProcedures
                        )
                      }}>
                      <u>
                        {i18nTranslate(
                          'studioPage.acknowledge1.8',
                          'Policies and Procedures',
                          { nameSpace: 'ssr-resource' }
                        )}
                      </u>
                    </a>
                    {i18nTranslate('signup.and', 'and', {
                      nameSpace: 'ssr-resource',
                    })}
                    <a
                      name="link"
                      target="_blank"
                      href={formInputLabels.retailCustomerLinks?.termsOfUse}
                      rel="noopener noreferrer"
                      onClick={event => {
                        event.preventDefault()
                        openDocument(
                          formInputLabels.retailCustomerLinks?.termsOfUse
                        )
                      }}>
                      <u>
                        {' '}
                        {i18nTranslate('signup.termsOfUse', ' Terms of Use', {
                          nameSpace: 'ssr-resource',
                        })}
                      </u>
                    </a>{' '}
                    <span>
                      {i18nTranslate(
                        'studioPage.acknowledge1.9',
                        ' including the Limitation of Liability and Arbitration Policies.*',
                        { nameSpace: 'ssr-resource' }
                      )}
                    </span>
                    *
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            {i18nTranslate('myprofile.decline', 'Decline', {
              nameSpace: 'ssr-resource',
            })}
          </Button>
          <Button variant="primary" onClick={() => verify()} disabled={disable}>
            {i18nTranslate('button.acceptContinue', 'Accept and Continue', {
              nameSpace: 'ssr-resource',
            })}
          </Button>
        </Modal.Footer>
      </Modal>
      <IronCladContractPage2
        showIronCladPopup={() => showIronCladPopup('contractPage-3')}
        isToShowStep2={isToShowStep2IronCladModal}
        handleIronCladContractClose={handleIronCladContractClose}
        isFromAccounts={true}
        isFromSignup={false}
        isFromTerms={true}
        handleProceed={() => handleProceed('contractPage-4')}
        dataIroncladRerender={String(ironCladCheckedString)}
      />
      <IronCladContractPage3
        isToShowStep3={isToShowStep3IronCladModal}
        handleIronCladContractClose={handleIronCladContractClose}
        handleProceed={() => handleProceed('contractPage-4')}
        isFromAccounts={true}
        isFromSignup={false}
        isFromTerms={true}
      />
    </>
  )
}

export { Terms }
export default Terms
