import React from 'react'
import { observer } from 'mobx-react'
import {
  getLiveEventStatus,
  i18nTranslate,
  jitsuSignUpRedirect,
} from 'src/utils'
import { AiOutlineUser } from 'react-icons/ai'
import NuskinAccountIcon from './NuskinAccountIcon'
import { Link } from 'react-router-dom'
import {
  getAppConfig,
  getCookie,
  APPConfig,
  REGION_SELECTOR_URL,
} from 'config/appConfig'
import { customerContainer, listsContainer } from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import {
  application,
  isB2BAccount,
  isB2B2C,
  getLocalStorage,
  getLocaleCodeFromUrl,
  IS_BROWSER,
  checkMysiteOrPersonalOffer,
  trackGTMEvents,
  convertToBoolean,
} from 'src/utils'
import { mobileMenuState } from '../mobileMenuState'
import { signInwithRedirect, signOutWithRedirect } from 'src/utils/signInUtils'
import { Button, Modal } from 'react-bootstrap'
import { observable } from 'mobx'
import { SigninModal } from 'src/views/components'
import './styles.scss'

@observer
class UserMenu extends React.Component {
  @observable show = false
  @observable isToShowSignInModal = false
  @observable modalIgnored = false
  enableJITSU = convertToBoolean(getAppConfig()?.enableJITSU)

  constructor(props) {
    super(props)
  }

  handleMobileMenu = () => {
    if (this.enableJITSU) {
      jitsuSignUpRedirect()
    } else {
      if (application.isTabletOrSmaller) {
        mobileMenuState.toggleMenuVisibility()
      } else {
        trackGTMEvents({ event: 'Signup', eventType: 'simpCustColdFormStart' })
      }
    }
  }

  componentDidMount() {
    this.initializeListAPI()
  }

  initializeListAPI = () => {
    const customerResponse =
      customerContainer?.profileResponse?.customer ||
      customerContainer?.profileResponse

    if (customerResponse?.id) {
      this.getListCallInit()
      this.countrySelectionModal()
    } else {
      setTimeout(() => {
        this.initializeListAPI()
      }, 300)
    }
  }

  /**
   * GetList API should be triggered only after Customer API
   * @date 10/11/2023 - 4:57:06 PM
   */
  getListCallInit = () => {
    if (getCookie('x-role-user')) {
      listsContainer.initFavoritesAndSaveForLater(true)
    }
  }

  countrySelectionModal() {
    const currentPageURl = this?.props?.location?.pathname || '/'

    const isAuthenticated = getCookie('x-role-user') ? true : false
    const homeCountry = customerContainer?.getUserHomeCountry() || ''
    const pageURl = window.location.href || ''
    const accountType = getLocalStorage('accountType') || ''

    const curLocale = getLocaleCodeFromUrl({
      url: pageURl,
      at: 'pathParam',
      defaultLocale: APPConfig.getAppConfig().defaultLocale || 'en_US',
      isReverseType: true,
    })
    const [language, activeCountryCode] = curLocale.includes('_')
      ? curLocale.split('_')
      : curLocale.split('-')

    /**
     * @info
     * validating currentPageURL in the below condition to prevent
     * the popup from showing in /logout/callback route
     */
    let isNativeApp = window?.isNativeApp || false
    if (
      isAuthenticated === true &&
      homeCountry !== activeCountryCode &&
      currentPageURl !== '/logout/callback' &&
      currentPageURl !== '/login/callback' &&
      accountType === 'Retail Customer' &&
      isNativeApp === false &&
      homeCountry !== ''
    ) {
      this.show = true
    }
  }

  componentDidUpdate() {
    const customerResponse =
      customerContainer?.profileResponse?.customer ||
      customerContainer?.profileResponse

    if (customerResponse?.id) {
      if (!this.modalIgnored) {
        this.countrySelectionModal()
      }
    }
  }

  handleSignOutClick = async () => {
    await customerContainer.logout()
    // reloading page hence intiate the applicaton
    if (typeof window === 'object') {
      window.location.reload()
      window.location.href = window.location.origin
    }
  }

  accountIcon = () => {
    return this.props?.isNuskinHeader ? (
      <NuskinAccountIcon
        className="mr-lg-1"
        style={{ fontSize: '24px', verticalAlign: 'middle' }}
      />
    ) : (
      <AiOutlineUser
        className="mr-lg-1"
        style={{ fontSize: '20px', verticalAlign: 'middle' }}
      />
    )
  }

  showMobileLogin = () => {
    this.isToShowSignInModal = true
  }

  handleHideSignInModal = () => {
    this.isToShowSignInModal = false
  }

  signInComponent = () => {
    const { isNuskinHeader = false } = this.props
    return (
      <>
        {APPConfig.getAppConfig().signInType === 'okta' ? (
          <>
            <button
              className={`${
                isNuskinHeader && 'nu-sign-text px-1'
              } header-oktasignout-text des-display-user pl-0`}
              onClick={signInwithRedirect}>
              {!isNuskinHeader && this.accountIcon()}
              {i18nTranslate('header.signIn', 'Sign In', {
                nameSpace: 'ssr-resource',
              })}
            </button>
            <div
              className="text-center mob-display-user"
              onClick={this.showMobileLogin}
              data-testid="qa-signin">
              {this.accountIcon()}
            </div>
          </>
        ) : (
          <>
            {/* <Link
              to={pageNames.signIn}
              className="header-sign-text mob-display-user"
              // onClick={this.handleMobileMenu}
              data-testid="qa-signin">
              {this.accountIcon()}
              <span className="sr-only">Sign In</span>
            </Link>
            <Link
              to={pageNames.signIn}
              className={`${
                isNuskinHeader && 'nu-sign-text px-1'
              } header-sign-text des-display-user`}
              onClick={this.handleMobileMenu}
              data-testid="qa-signin">
              {!isNuskinHeader && this.accountIcon()}
              {i18nTranslate('header.signIn', 'Sign In')}
            </Link> */}
          </>
        )}
      </>
    )
  }

  signUpComponent = () => {
    // console.log(`signUpComponent >>>>`, getAppConfig(), isB2B2C())
    const { isNuskinHeader = false } = this.props
    const { businessRelationship } = getAppConfig()
    let pageLink = ''

    if (businessRelationship === 'B2B' || isB2B2C()) {
      pageLink = pageNames.signUp
    } else {
      pageLink = pageNames.createAccount
    }
    let mysite
    try {
      if (typeof window !== 'undefined') {
        const shoppingContext = getLocalStorage('shoppingContext') || {}
        if (Object.keys(shoppingContext).length > 0) {
          const contextValue = shoppingContext?.context || ''
          if (contextValue == 'storefront') {
            mysite = {
              display: 'none',
            }
          }
        }
      }
    } catch (e) {
      console.error('JSON.parse may be an error SignUpForm.formInputOkta', e)
    }
    return (
      <>
        <Link
          to={this.enableJITSU ? '#' : pageLink}
          className={`${
            isNuskinHeader && 'nu-sign-text mx-1 d-lg-inline-block'
          } header-signup-text`}
          onClick={this.handleMobileMenu}
          data-testid="qa-signup"
          role="button"
          aria-label={i18nTranslate('header.signUp', 'Sign Up', {
            nameSpace: 'ssr-resource',
          })}
          style={mysite}>
          {isNuskinHeader && this.accountIcon()}
          {i18nTranslate('header.signUp', 'Sign Up', {
            nameSpace: 'ssr-resource',
          })}
        </Link>
      </>
    )
  }

  signOutComponent = () => {
    const { isNuskinHeader = false } = this.props
    return (
      <a
        // href="javascript:;"
        to={pageNames.home}
        className={`${
          isNuskinHeader ? 'nu-sign-text' : ''
        } header-signout-text`}
        onClick={this.handleSignOutClick}
        data-testid="qa-signout">
        {i18nTranslate('header.signOut', 'Sign Out', {
          nameSpace: 'ssr-resource',
        })}
      </a>
    )
  }

  oktaSignoutComponent = () => {
    const { isNuskinHeader = false } = this.props
    return (
      <button
        className={`${
          isNuskinHeader ? 'nu-sign-text' : ''
        } header-oktasignout-text`}
        onClick={signOutWithRedirect}
        data-testid="qa-sign-out">
        {i18nTranslate('header.signOut', 'Sign Out', {
          nameSpace: 'ssr-resource',
        })}
      </button>
    )
  }

  myFirstName = text => {
    let rtext = ''
    let len = String(text).length
    if (len > 12) {
      rtext = String(text).slice(0, 11).concat('...')
    } else {
      rtext = text
    }
    return rtext
  }

  myAccountComponent = () => {
    let redirectPageName =
      isB2BAccount() === true
        ? pageNames.b2bMyProfile
        : pageNames.myaccountprofile

    // CN locale users might get migrated for the July event site
    // My profile is disabled for them for non-event sites
    const enableLiveEvent = getLiveEventStatus()
    let disableMyAccount = ''
    if (enableLiveEvent !== 'true') {
      const homeCountry = customerContainer?.getUserHomeCountry()
      const isUserFromCN = homeCountry === 'CN'
      if (isUserFromCN) {
        redirectPageName = '#'
        disableMyAccount = 'event-user-disable-profile'
      }
    }

    const { isNuskinHeader = false } = this.props
    let contextValue = checkMysiteOrPersonalOffer()
    const ariaLabel =
      i18nTranslate('header.welcome', 'Hi', {
        nameSpace: 'ssr-resource',
      }) +
      ' ' +
      this.myFirstName(customerContainer.profileResponse.firstName)
    return (
      <Link
        to={redirectPageName}
        aria-label={ariaLabel}
        className={`${
          isNuskinHeader && contextValue !== ''
            ? 'nu-header-account-text-underline'
            : isNuskinHeader
            ? 'nu-header-account-text'
            : 'header-account-text'
        } ${disableMyAccount}`}
        // onClick={this.handleMobileMenu}
        data-testid="qa-account">
        {this.accountIcon()}
        {isNuskinHeader ? (
          <span className={`m-1 nu-sign-text des-display-user`}>
            {i18nTranslate('header.welcome', 'Hi', {
              nameSpace: 'ssr-resource',
            })}
            <span> </span>
            {this.myFirstName(customerContainer.profileResponse.firstName)}
          </span>
        ) : (
          <div className="header-account-text ml-1 display-desktop">
            <div className="user-name">
              {i18nTranslate('header.welcome', 'Hi', {
                nameSpace: 'ssr-resource',
              })}
            </div>
            <div className="user-firstName">
              {this.myFirstName(customerContainer.profileResponse.firstName)}
            </div>
          </div>
        )}
      </Link>
    )
  }

  userMenuDivider = () => {
    const { isNuskinHeader = false } = this.props
    return isNuskinHeader ? (
      <span className="signin-line nu-sign-text">/ </span>
    ) : (
      <span className="px-1 signin-line">|</span>
    )
  }

  registerUserComponent = () => {
    return (
      <>
        {this.myAccountComponent()}
        {this.userMenuDivider()}
        {customerContainer.isOktaUser || localStorage?.getItem('isOktaUser')
          ? this.oktaSignoutComponent()
          : this.signOutComponent()}
      </>
    )
  }

  registerUserOktaComponent = () => {
    const isAuthenticated = localStorage?.getItem('IsAuthenticated') || false

    /**
     * @info
     * currently making the localStorage of IsAuthenticated as true , because when user is
     * logged in via CSR this localStroage will be false ,this localstorage helps to render
     * the registerUserOktaComponents without lag during Locale change
     *
     */

    if (isAuthenticated !== true) {
      localStorage?.setItem('IsAuthenticated', true)
    }

    /**
     * @info
     *  using email to validate here , since for some users
     * firstname is null in customerAPI Responses which causes issue in
     * rendering the component
     *
     */

    const email =
      customerContainer?.profileResponse?.email ||
      customerContainer?.profileResponse?.phoneNumber ||
      customerContainer?.profileResponse?.userName ||
      customerContainer?.profileResponse?.userrole ||
      ''

    if (email) {
      return (
        <>
          {this.myAccountComponent()}
          {this.userMenuDivider()}
          {this.oktaSignoutComponent()}
        </>
      )
    }
  }

  guestUserComponent = () => {
    const { isNuskinHeader = false, enableLiveEvent = 'false' } = this.props
    const searchParams = new URLSearchParams(this.props?.location?.search || '')
    const code = searchParams?.get('code') || ''
    const state = searchParams?.get('state') || ''
    let contextValue = checkMysiteOrPersonalOffer()
    return (
      <div className={IS_BROWSER && !code && !state ? '' : 'd-none'}>
        {enableLiveEvent !== 'true' ? (
          <>
            {isNuskinHeader
              ? contextValue == '' && this.signUpComponent()
              : this.signInComponent()}
            {contextValue == '' && this.userMenuDivider()}
            {isNuskinHeader
              ? this.signInComponent()
              : contextValue == '' && this.signUpComponent()}
          </>
        ) : (
          isNuskinHeader && (
            <>
              {this.accountIcon()}
              {this.signInComponent()}
            </>
          )
        )}
      </div>
    )
  }

  showCountrySelectionModal = () => {
    const homeMarket = customerContainer?.getUserHomeCountry() || ''
    return (
      <Modal
        className="home-country-modal"
        show={this.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body>
          <span>
            {i18nTranslate('homeCountry.info', 'Your home market is', {
              nameSpace: 'ssr-resource',
            })}{' '}
            <strong>{homeMarket || ' '}</strong>.{' '}
            {i18nTranslate(
              'homeCountry.navigate',
              ` Please navigate to your home market website to shop with us and complete your purchase. `,
              {
                nameSpace: 'ssr-resource',
              }
            )}{' '}
            <u>
              <strong>
                <a
                  href={REGION_SELECTOR_URL}
                  title={i18nTranslate('homeCountry.region', 'Select Region', {
                    nameSpace: 'ssr-resource',
                  })}>
                  {i18nTranslate('homeCountry.region', 'Select Region', {
                    nameSpace: 'ssr-resource',
                  })}
                </a>
              </strong>
            </u>{' '}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              this.modalIgnored = true
              this.show = false
            }}>
            {i18nTranslate('cancelOrderForm.cancel', 'Cancel', {
              nameSpace: 'ssr-resource',
            })}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const { isNuskinHeader = false } = this.props
    const renderSignInSignUpComponent = customerContainer.isRegisterUser
      ? this.registerUserComponent
      : this.guestUserComponent

    const identified =
      customerContainer.profileResponse?.id ||
      customerContainer.profileResponse?.customer?.id ||
      undefined

    let renderOktaSignInSignUpComponent = () => ''

    if (identified) {
      /**
       * @info
       * Below condition of isRegisetered user is added in order to prevent Guest component
       * from loading for a second before actual registered user comp is renderd
       *
       */

      // const isRegisterUser =
      //   (customerContainer?.profileResponse?.email ||
      //     customerContainer?.profileResponse?.phoneNumber ||
      //     customerContainer?.profileResponse?.userName ||
      //     customerContainer?.profileResponse?.userrole ||
      //     false) &&
      //   (customerContainer?.profileResponse?.referenceAttribute != null ||
      //     false)

      let isGuestUser = customerContainer?.profileResponse?.guest || false
      const isRegisterUser =
        (customerContainer?.profileResponse?.email ||
          customerContainer?.profileResponse?.phoneNumber ||
          customerContainer?.profileResponse?.userName ||
          customerContainer?.profileResponse?.userrole ||
          false) &&
        customerContainer?.profileResponse?.referenceAttribute != null &&
        !isGuestUser

      renderOktaSignInSignUpComponent = isRegisterUser
        ? this.registerUserOktaComponent
        : this.guestUserComponent
    }

    return (
      <>
        <div
          className={`${
            isNuskinHeader ? '' : 'header-text'
          } mr-0 p-0 p-xs-0 p-lg-0 user-icon`}>
          {APPConfig.getAppConfig().signInType === 'okta'
            ? renderOktaSignInSignUpComponent()
            : renderSignInSignUpComponent()}
        </div>
        {this.show && this.showCountrySelectionModal()}
        <SigninModal
          isVisible={this.isToShowSignInModal}
          onHideSignInModal={this.handleHideSignInModal}
        />
      </>
    )
  }
}

export default UserMenu
export { UserMenu }
