import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import querystring from 'querystring'
import { APPConfig } from 'config/appConfig'

/**
 * Parses the query string from the given location object's search property.
 * Returns an empty object if no search property exists.
 * Uses querystring.parse to parse the search string into an object.
 */
function getFilterParams(location) {
  return location.search ? querystring.parse(location.search.slice(1)) : {}
}
/**
 * Constructs a new query parameter object from the given queryParam and isFilterSelected values.
 * If isFilterSelected is false and queryParam has a page property, includes that page property.
 * Otherwise, copies over any filter, refine, or sort properties from queryParam.
 * If isFilterSelected, removes any page property from the queryParam.
 * Returns a new object with the selected properties from queryParam and optionally the page property.
 */
function getObject(queryParam, isFilterSelected) {
  const newParam =
    !isFilterSelected && queryParam.page
      ? { page: queryParam.page }
      : queryParam.filter
      ? { filter: queryParam.filter }
      : queryParam.refine
      ? { refine: queryParam.refine }
      : queryParam.sort
      ? { sort: queryParam.sort }
      : {}

  isFilterSelected && delete queryParam.page

  return {
    ...queryParam,
    ...newParam,
  }
}
/**
 * Constructs a new query parameter string from the given props,
 * queryParam, and isFilterSelected values. Gets a new query param
 * object using the getObject helper. Optionally deletes any
 * existing 'page' property if isFilterSelected is true.
 * Stringifies the new query param object and appends it to the
 * match url from props, encoding if needed. Pushes the new url
 * with params to history unless props is empty.
 */
function constructQueryParam(props, queryParam, isFilterSelected) {
  const newParam = getObject(queryParam, isFilterSelected)
  if (isFilterSelected) {
    delete newParam['page']
  }
  !isEmpty(props) &&
    props.history.push(
      (props.match.url.includes('%25')
        ? encodeURI(props.match.url)
        : props.match.url) +
        '?' +
        querystring.stringify(newParam)
    )
}

/**
 * Constructs a filter params object for a given filter name and value.
 * Checks if the name starts with 'range_facet_' to determine if it is a price filter.
 * Sets the operation to 'BETWEEN' for price filters, 'IN' otherwise.
 * Returns object with field, operation, and value properties.
 */
function constructFilterParams(name, value) {
  let priceName = name?.startsWith('range_facet_') || false
  return {
    field: name,
    operation:
      // name === 'range_facet_price' || name === 'range_facet_WholesalePrice'
      priceName ? 'BETWEEN' : 'IN',
    value: value.name,
  }
}

/**
 * Sorts an array of attributes by their 'sequence' property, with empty
 * sequences moved to the end. Attributes without a sequence are sorted
 * alphabetically by name/label.
 * @param {Object[]} sortableArray - Array of attribute objects
 * @returns {Object[]} Sorted array
 */
function getSortedAttributes(sortableArray) {
  let emptySequences = sortableArray.filter(
    attribute => attribute.sequence === ''
  )
  let valueSequences = sortableArray.filter(
    attribute => attribute.sequence !== ''
  )
  valueSequences.sort((firstAttr, secondAttr) => {
    if (firstAttr.sequence === secondAttr.sequence) {
      let firstText =
        (firstAttr.name && firstAttr.name.toLowerCase()) ||
        (firstAttr.label && firstAttr.label.toLowerCase())
      let secondText =
        (secondAttr.name && secondAttr.name.toLowerCase()) ||
        (secondAttr.label && secondAttr.label.toLowerCase())
      return firstText < secondText ? -1 : firstText > secondText ? 1 : 0
    } else {
      return firstAttr.sequence > secondAttr.sequence ? 1 : -1
    }
  })
  return [...valueSequences, ...emptySequences]
}

/**
 * Adds or removes a value from a comma-separated filter value string.
 * If the selected value already exists in the string, it will be removed.
 * If it doesn't exist, it will be added, concatenated with a comma.
 *
 * @param {string} filterValue - The comma-separated filter value string
 * @param {string} selectedVal - The value to add or remove
 * @returns {string} The updated filter value string
 */
function addOrRemoveFilterValues(filterValue = '', selectedVal) {
  const filterValues = filterValue?.split(',')
  if (filterValues.includes(selectedVal)) {
    // remove selectedVal from filterValues
    // and then convert into comma separated string
    const index = filterValues.indexOf(selectedVal)
    filterValues.splice(index, 1)
    return filterValues.join(',')
  } else {
    return filterValue.concat(',', selectedVal)
  }
}

/**
 * Handles updating the selected filters array and calling the filter handler
 * function.
 *
 * @param {Object} data - Object containing:
 * - props - Object with handleFilter and filterProps
 * - nameValue - Object with name and value of selected filter
 * - makeApi - Whether to call filter handler function
 * - selectedFilter - Existing selected filters array
 *
 * Parses existing selected filters array from filterProps.
 * Finds existing filter with same name and updates value.
 * If no existing filter, adds new one to array.
 * Calls handleFilter function if makeApi is true and array is updated.
 */
function handleFilterCbk(data) {
  const { props, nameValue, makeApi = true, selectedFilter } = data
  const { handleFilter, filterProps } = props
  const { name, value } = nameValue

  let selectedFilterArray = []

  try {
    selectedFilterArray = selectedFilter
      ? selectedFilter
      : filterProps
      ? JSON.parse(filterProps).filters
      : []
  } catch (e) {
    selectedFilterArray = []
    console.error('parsing may failed >>> fn.handleFilterCbk', e)
  }

  const filterArray = selectedFilterArray.find(filter => filter.field === name)
  const isPriceSliderFilterEnabled =
    APPConfig?.getAppConfig()?.shouldShowPriceSliderFilter &&
    APPConfig?.getAppConfig()?.shouldShowPriceSliderFilter === 'true'
  const isPriceSliderFilter =
    isPriceSliderFilterEnabled &&
    (name?.toLowerCase?.() == 'range_facet_price' ||
      name?.toLowerCase?.() == 'range_facet_wholesaleprice')
  if (filterArray) {
    filterArray.value = isPriceSliderFilter
      ? value.name
      : addOrRemoveFilterValues(filterArray.value, value.name)
    if (!filterArray.value) {
      selectedFilterArray.splice(
        selectedFilterArray.findIndex(
          filterValue => filterValue.field === filterArray.field
        ),
        1
      )
    }
  } else {
    selectedFilterArray.push(constructFilterParams(name, value))
  }
  if (makeApi) {
    if (isFunction(handleFilter)) {
      if (selectedFilterArray.length === 0) {
        handleFilter()
      } else {
        handleFilter(selectedFilterArray)
      }
    }
  } else {
    return selectedFilterArray
  }
}

export {
  handleFilterCbk,
  getFilterParams,
  constructQueryParam,
  getSortedAttributes,
}
