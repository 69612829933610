import React, { useEffect, useState } from 'react'
import { checkoutContainer, cartContainer } from 'src/models'
import { Button } from 'react-bootstrap'
import { CommonForm, toastState } from 'src/views/components'
import { loyaltyContainer } from 'src/models/Loyalty/LoyaltyContainer'
import { IS_BROWSER } from 'src/utils'
import './styles.scss'
import { schema } from './validationSchema'
import { formInput } from './formInput'
import { i18nTranslate } from 'src/utils'

function LoyaltyPoint() {
  const [inputValue, setInputValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isError, setIsError] = useState(false)
  const [walletBalance, setWalletBalance] = useState('')
  const [discountModelAvailable, setDiscountModelAvailable] = useState(false)
  const [redeemablePoints, setRedeemablePoints] = useState(0)
  const [undoBtn, setUndoBtn] = useState(false)

  useEffect(() => {
    const getResult = async () => {
      await loyaltyContainer.getLoyaltyPoints(true)
      const loyalty = loyaltyContainer.getOpenLoyaltyPoints()
      const rem = await cartContainer.getRedeemLoyaltyPoints()
      setWalletBalance(loyalty.toFixed(2))
      const discountModel = loyaltyContainer.getDiscountModelRewardTypes()
      setDiscountModelAvailable(discountModel)
      setRedeemablePoints(rem)
    }
    getResult()
  }, [])

  useEffect(() => {
    const getResult = async () => {
      const rem =
        (await cartContainer?.cartResponse?.value?.totalLoyaltyPointsToPay) || 0
      if (rem != null) {
        setRedeemablePoints(rem)
      }
    }
    getResult()
  }, [cartContainer.cartResponse])

  //payment through loyalty Points Post Call
  const handleApplyLoyalty = async formData => {
    let loyaltyPoint = formData?.loyaltyPoint
    let response = ''
    // const response = await checkoutContainer.addLoyaltyToCart(formData)
    if (discountModelAvailable) {
      response = await checkoutContainer.addDiscountReward(formData)
    } else {
      response = await checkoutContainer.addLoyaltyToCart(formData)
    }
    toastState.setToastMessage(response.message)
    if (checkoutContainer.isSuccessResponse(response)) {
      if (walletBalance > 0) {
        let balance = walletBalance - loyaltyPoint
        setWalletBalance(balance.toFixed(2))
      }
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.SuccessLoyalty',
            'Loyalty points applied successfully'
          ),
        true
      )
    } else {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.failureLoyalty',
            'unable to add the loyalty points'
          ),
        false
      )
    }
    return response
  }
  //Delete Applied Loyalty points from CartView
  const handleDeleteLoyalty = async () => {
    // const response = await checkoutContainer.deletePayment()
    let response = ''
    const loyalty = await loyaltyContainer.getOpenLoyaltyPoints()

    if (discountModelAvailable) {
      response = await checkoutContainer.deleteDiscountReward()
      toastState.setToastMessage(response.message)
    } else {
      response = await checkoutContainer.deletePayment()
      toastState.setToastMessage(response.message)
    }
    if (checkoutContainer.isSuccessResponse(response)) {
      setWalletBalance((loyalty - inputValue).toFixed(2))
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.deletedLoyalty',
            'Loyalty points removed successfully'
          ),
        true
      )
    } else {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.failureDelete',
            'unable to remove the coupon code'
          ),
        false
      )
    }
    return response
  }

  //Toggle Between Apply and Undo
  // const toggleFunction = formData => {
  const toggleFunction = async formData => {
    if (formData && IS_BROWSER) {
      let el = document.getElementById('button')
      let currText = el.innerText
      el.innerText = currText?.toLocaleLowerCase() == 'undo' ? 'Apply' : 'undo'

      if (currText?.toLocaleLowerCase() == 'apply') {
        // handleApplyLoyalty(formData)
        // document.getElementById('inputField').disabled = true
        const response = await handleApplyLoyalty(formData)
        if (isSuccessResponse(response)) {
          setUndoBtn(true)
          document.getElementById('inputField').disabled = true
        }
      } else {
        // handleDeleteLoyalty()
        // document.getElementById('inputField').removeAttribute('disabled')
        const response = await handleDeleteLoyalty()
        if (isSuccessResponse(response)) {
          setUndoBtn(false)
          document.getElementById('inputField').removeAttribute('disabled')
        }
      }
    }
  }

  //to check if response is success or failed
  const isSuccessResponse = response => {
    if (response) {
      const status = response?.status || ''
      return status === 'success' || status === ''
    }
    return false
  }

  //Validation for Input Field
  const handleError = val => {
    let data = parseFloat(val)
    const valueFormat = /^(?:\d*\.)?\d+$/
    if (!val.match(valueFormat) || data <= 0) {
      setErrorMessage('Please enter a valid amount')
      setIsError(true)
    } else if (data > +redeemablePoints) {
      setErrorMessage('The provided value is more than the redeemable point')
      setIsError(true)
    } else {
      setErrorMessage('')
      setIsError(false)
    }
  }

  let invalid = {}
  if (isError) {
    invalid = { className: 'form-control mb-2 inputError' }
  } else {
    invalid = { className: 'form-control mb-2' }
  }

  const renderSubmitButton = () => {
    return (
      <Button
        data-testid="qa-loyalty-apply"
        id="button"
        className="loyalty-btn w-100 text-capitalize mt-sm-0 btn-bg-blue"
        // disabled={walletBalance === '0.00'}
        variant="primary"
        type="submit">
        {undoBtn
          ? i18nTranslate('loyalityCode.submit.undo', 'undo')
          : i18nTranslate('loyalityCode.submit', 'apply')}
      </Button>
    )
  }

  const formInputJson = formInput({
    walletBalance: walletBalance,
  })

  const loyaltySchema = schema()

  return (
    <div className={`loyalty-code-container py-3`}>
      <span
        className="loyalty-title font-weight-bold text-black-50 pb-3"
        data-testid="loyalty-title-text">
        {i18nTranslate(
          'loyalty.usePoints',
          'Do you want to use Loyalty Points ?'
        )}
      </span>
      <div data-testid="loyalty-form-wrapper">
        <label className="loyalty-heading text-capitalize font-weight-normal">
          {i18nTranslate('loyalty.balance', 'Wallet Balance ')} :{' '}
          {`${walletBalance}`} {i18nTranslate('loyalty.points', 'Points')}
        </label>
        <div data-testid="loyalty-form-wrapper">
          <label className="loyalty-heading mb-2 font-weight-normal">
            {i18nTranslate(
              'loyalty.redeemPoints',
              'Redeemable Points on this Order '
            )}{' '}
            : {`${redeemablePoints?.toFixed(2) || redeemablePoints}`} Points
          </label>
          <CommonForm
            schema={loyaltySchema}
            formTypeJson={formInputJson}
            SubmitButtonProps={renderSubmitButton}
            onSubmit={toggleFunction}
            hasInlineSubmitButton={true}
            onBlur={e => {
              setInputValue(e.target.value)
              handleError(e.target.value)
            }}
          />
        </div>
        {/* NOTE : (CX121-3462) the below piece of code is replaced by <CommonForm> , will be removed once qa testing is validated */}
        {/* <div className="form-row">
          <div className="mb-2 col-lg-4 col-md-6">
            <input
              type="number"
              className="form-control mb-2"
              aria-label="Default"
              id="inputField"
              aria-describedby="inputGroup-sizing-default"
              data-testid="qa-Loyalty-Input"
              disabled={walletBalance === '0.00'}
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value)
              }}
              onBlur={e => {
                setInputValue(e.target.value)
                handleError(e.target.value)
              }}
              {...invalid}></input>
            <span id="inputGroup-sizing-default" className="error">
              {errorMessage}
            </span>
          </div>
          <div className="col-lg-2 col-md-3">
            <button
              type="button"
              className="btn btn-primary w-100 text-capitalize mt-sm-0 loyalty-btn btn-bg-blue"
              data-testid="qa-loyalty-apply"
              id="button"
              disabled={walletBalance === '0.00'}
              onClick={() =>
                inputValue && !isError && toggleFunction(inputValue)
              }>
              Apply
            </button>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export { LoyaltyPoint }
export default LoyaltyPoint
