import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import { getLocaleCodeFromUrl } from 'src/utils'
import { APPConfig } from 'config/appConfig'
// import { KlarnaDepsContainer } from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import './styles.scss'

/**
 * KlarnaScript
 *
 * Conditionally returns a Helmet component with a script tag
 * to load the Klarna script, based on app config settings.
 *
 * Checks if Klarna payment is enabled and Klarna script URL exists.
 * If so, returns Helmet component with Klarna script, setting
 * attributes based on app config values for environment, client ID etc.
 *
 * Handles calling Klarna refresh method on script load.
 */
function KlarnaScript() {
  const appConfigs = APPConfig.getAppConfig()

  if (
    appConfigs?.enableKlarnaPayment == 'true' &&
    appConfigs?.klarnaScriptURL
  ) {
    const klarnaScriptURL = appConfigs?.klarnaScriptURL || ''
    const klarnaClientId = appConfigs?.klarnaClientId || ''
    const klarnaEnvironment = appConfigs?.klarnaEnvironment || ''
    return (
      <Helmet>
        <script>
          {`function klarnaScriptLoaded() {window?.Klarna?.OnsiteMessaging?.refresh()}`}
        </script>
        <script
          async
          id="klarna-script-element"
          data-environment={`${klarnaEnvironment}`}
          src={`${klarnaScriptURL}`}
          data-client-id={`${klarnaClientId}`}
          onLoad="klarnaScriptLoaded()"
        />
      </Helmet>
    )
  }
  return <></>
}
/**
 * Checks if the current page is the home page.
 *
 * @param {Object} props - The props passed to the component.
 * @returns {boolean} - Returns true if the current page is the home page.
 */
function isHomePage(props) {
  const { location } = props || {}
  return pageNames?.home == location?.pathname
}

class KlarnaMessage extends Component {
  // constructor(props) {
  //   super(props)
  //   if (IS_BROWSER) {
  //     klarnaDepsContainer.checkAndLoadKlarnaScript()
  //   }
  // }

  componentDidMount() {
    this.klarnaRefresh()
  }

  componentDidUpdate() {
    this.klarnaRefresh()
  }

  klarnaRefresh = () => {
    window?.Klarna?.OnsiteMessaging?.refresh()
  }

  renderKlarnaMessage = () => {
    const { price = 0, isFromHomePage = false } = this.props
    const selectedLocale =
      getLocaleCodeFromUrl({
        defaultLocale: APPConfig?.getActiveAppConfig()?.defaultLocale, //en_US
      })?.replace('_', '-') || 'en-US'
    const klarnaSupportedLocales =
      APPConfig?.getAppConfig()?.klarnaSupportedLocales
    const isSelectedLocaleSupported =
      klarnaSupportedLocales?.includes(selectedLocale) || false
    const selectedCountry = selectedLocale?.split('-')?.[1] || 'US'
    // const className = `klarna-message-wrapper ${
    //   this.props.customClassName || ''
    // } ${
    //   KlarnaDepsContainer?.isKlarnaScriptLoaded ? 'klarna-script-loaded' : ''
    // }`
    // KlarnaDepsContainer?.isKlarnaScriptLoaded is used just to refresh the component once script loads

    const className = `klarna-message-wrapper ${
      this.props?.customClassName || ''
    }`

    const propsForKlarna = {
      id: this.props.id || 'klarna-osm',
      'data-key': this.props['data-key'] || 'credit-promotion-badge',
      'data-locale': isSelectedLocaleSupported
        ? selectedLocale
        : `en-${selectedCountry}`,
    }

    if (!isFromHomePage) {
      // propsForKlarna['data-inline'] = 'false'
      propsForKlarna['data-purchase-amount'] = parseInt(price * 100)
    }
    console.log('** propsForKlarna', propsForKlarna)

    return (
      <div className={className} data-testid="qa-pdp-klarna-message">
        <klarna-placement {...propsForKlarna}></klarna-placement>
      </div>
    )
  }

  render() {
    const enableKlarnaPayment =
      APPConfig?.getAppConfig()?.enableKlarnaPayment == 'true'
    const enableKlarnaHomeMessage =
      APPConfig?.getAppConfig()?.enableHeaderOption == 'klarna'
    const shouldDisplayKlarnaMessage = this.props.isFromHomePage
      ? enableKlarnaPayment && enableKlarnaHomeMessage && isHomePage(this.props)
      : enableKlarnaPayment
    if (shouldDisplayKlarnaMessage) {
      return this.renderKlarnaMessage()
    }
    return <></>
  }
}

export default KlarnaMessage
export { KlarnaMessage, KlarnaScript, isHomePage }
