import React from 'react'
import { withTranslation } from 'react-i18next'
import { observable, action, computed, reaction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import {
  i18nTranslate,
  getLiveEventStatus,
  getTranslatedShippingMethod,
  isGuestCheckoutEnabledForWeb,
  convertToBoolean,
  trackExpressCheckoutEvents,
  getCheckoutGTMPageData,
} from 'src/utils'
import { IoIosRemove, IoIosAdd } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { MdCheckCircle } from 'react-icons/md'
import isEmpty from 'lodash/isEmpty'
import {
  isToEnableItemLevelShipping,
  enableStorePickup,
  getSessionStorage,
} from 'config/appConfig'
import {
  getLocalStorage,
  currencyFormat,
  trackGTMEvents,
  getShoppingContext,
  trackErrorInInstana,
  IS_BROWSER,
  isGPSEnabled,
  deleteFromLocalStorage,
} from 'src/utils'
import { APPConfig } from 'config/appConfig'
import { pageNames } from 'src/routes/pathParams'
import {
  addressContainer,
  cartContainer,
  checkoutContainer,
  customerContainer,
  tokenExContainer,
  loyaltyContainer,
  storeContainer,
  accountsAddressContainer,
  venmoContainer,
  paypalContainer,
  googlePayContainer,
  applepayContainer,
  nuskinSubscriptionContainer,
} from 'src/models'
import {
  EmptyCart,
  AccordionBlock,
  storePickupState,
  StorePickup,
  ScrollToTopComponent,
  SingleLineAddress,
  toastState,
  StoreCredits,
  overlayState,
  MetaDecorator,
  ShippingAccordion,
  ShippingMethodAccordion,
  CouponCode,
  GlobalPaymentAccordion,
  PaymentAccordion,
  PickupAccordion,
  SubscriptionAccordion,
  tokenExIframe,
  modalState,
  PaymentIcon,
  PickUpAddress,
} from 'src/views/components'
import {
  SubmitOrder,
  SignInAccordion,
} from 'src/views/components/CheckoutAccordions'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'
import {
  LoyaltyForm,
  isLoyEnableInProf,
  switchActive,
} from 'src/views/pages/MyAccount/MyAccountProfile/LoyaltyForm'
import {
  getTabKeys,
  flowPaths,
  flowSkipPaths,
  loyaltyRewardsFormData,
} from 'src/views/pages/CheckoutView2/fixture'
import { FloatingRightBar } from 'src/views/pages/CheckoutView2/FloatingRightBar'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import { isExpressCheckout } from 'src/utils'
import NotFound from '../NotFound'
import './styles.scss'

@observer
class ExpressCheckout extends React.Component {
  //@observable activePath = flowPaths[0]
  @observable isModalVisible = false
  @observable isMultipleAdressActive = false
  @observable alipayInitiate = false
  // @observable paypalInitiate = false
  @observable paypalToken = ''
  @observable isAddressChecked = false
  @observable show = false
  // @observable venmoInitiate = false
  @observable venmoToken = ''
  @observable termsVal = false
  @observable isToEnablePaymentAccordion = false
  @observable products = []
  @observable googlePayToken = ''
  @observable applepayToken = ''
  enableLiveEvent = getLiveEventStatus()
  @observable pickUpLocationResponse = ''
  @observable isCurrentDeviceSupportedApplePay = false
  skipPaths = []
  flowPaths = []
  scrollTab = ''
  accordianClicked = false
  isDefaultSkip = false
  showZeroCheckoutWithPaymentAlert = true
  reInitCounter = {
    googlePay: 0,
    paypalPay: 0,
  }
  @action
  toggleModal = (props = {}) => {
    const { visibility } = props
    this.isModalVisible =
      visibility !== undefined ? visibility : !this.isModalVisible
  }

  constructor(props) {
    super(props)
    this.getSkipPaths()
    // checkoutContainer.activePath = this.flowPaths[0]

    /**
     * @note
     * loading tokenExContainer dependencies here
     */
    tokenExContainer.loadTokenExScript()
    // tokenExContainer.getTokenExAuthKeyFromSFO()
    checkoutContainer.loadBraintreeClientScript()
    checkoutContainer.loadBraintreeCheckoutScript()

    //Venmo Scripts

    checkoutContainer.loadBraintreeVenmoClientScript()
    checkoutContainer.loadBraintreeVenmoDataCollectorScript()
    checkoutContainer.loadBraintreeVenmoScript()

    // GooglePay Scripts
    checkoutContainer.loadGooglePayClientScript()
    checkoutContainer.loadGooglePayBrainTreeScript()

    //ApplePay Scripts
    checkoutContainer.loadApplePayScripts()
  }

  async componentDidMount() {
    this.hasProducts()
    this.initializeCheckoutFlow()
    if (this.enableLiveEvent === 'true') {
      this.makePickUpLocationCall()
    }
    this.handleGpayExpress()
    this.handleApplePayExpress()
    this.handlePaypalExpress()
    this.applyPromoCodeFromURL()
  }

  applyPromoCodeFromURL = async () => {
    const urlSearchString = window?.location?.search
    const params = new URLSearchParams(urlSearchString)
    const urlPromoCode = params?.get('promo')
    if (
      urlPromoCode &&
      !cartContainer?.cartResponse?.promotionDetails?.codes?.some(
        promoCode =>
          promoCode?.code?.toString()?.toUpperCase() ===
          urlPromoCode?.toUpperCase()
      )
    ) {
      await checkoutContainer.addPromo(urlPromoCode)
    }
  }

  delay = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  handleGpayExpress = async (isExpressClient = true) => {
    if (IS_BROWSER && !window?.google) {
      await this.delay(3000)
      this.reInitCounter.googlePay++
      if (this.reInitCounter.googlePay < 5) {
        this.handleGpayExpress()
      }
    } else {
      const googlePayToken = googlePayContainer.googlePayPaymentToken
        ? {
            token: googlePayContainer.googlePayPaymentToken,
          }
        : (await checkoutContainer.getBrainTreePaymentToken('GOOGLEPAY')) || {}
      await this.handleGooglePayInitiate(googlePayToken?.token, isExpressClient)
      if (typeof braintree !== 'undefined' && braintree?.client) {
        braintree.client?.create(
          {
            authorization: googlePayToken?.token,
          },
          function (clientErr, clientInstance) {
            console.log('** inside clientInstance Function - googlepay')
            googlePayContainer.initiateGooglePayPayment(
              clientErr,
              clientInstance,
              isExpressClient
            )
          }
        )
      }
    }
  }

  handlePaypalExpress = async () => {
    if (!document?.getElementById('express-paypal-container')) {
      await this.delay(3000)
      this.reInitCounter.paypalPay++
      if (this.reInitCounter.paypalPay < 5) {
        this.handlePaypalExpress()
      }
    } else {
      const braintreeResponce =
        (await checkoutContainer.getBrainTreePaymentToken('PAYPAL')) || {}
      this.handlePaypalInitate(braintreeResponce?.token, true)

      if (typeof braintree !== 'undefined' && braintree?.client) {
        braintree.client?.create(
          {
            authorization: braintreeResponce.token,
          },
          function (clientErr, clientInstance) {
            paypalContainer.handlePayPalRedirection(clientErr, clientInstance)
          }
        )
        paypalContainer.paypalClientCreated = true
      }
    }
  }

  async handleApplePayExpress() {
    const applePayToken =
      (await checkoutContainer.getBrainTreePaymentToken('APPLEPAY')) || {}
    await this.initateApplePay(applePayToken?.token, true)
    if (typeof braintree !== 'undefined' && braintree?.client) {
      this.isCurrentDeviceSupportedApplePay =
        applepayContainer.checkIfDeviceSupportsApplePay()
      braintree.client?.create(
        {
          authorization: applePayToken?.token,
        },
        function (clientErr, clientInstance) {
          console.log('** inside clientInstance Function')
          applepayContainer.initializeApplePayPayment(clientErr, clientInstance)
        }
      )
      applepayContainer.applepayClientCreated = true
    }
  }

  initateApplePay(token = '', disableInitate = false) {
    if (!disableInitate) {
      applepayContainer.applepayInitiate = true
    }
    applepayContainer.applepayToken = token
    this.applepayToken = token
  }

  handleGooglePayInitiate = async (token = '', disableInitate = false) => {
    if (!disableInitate) {
      googlePayContainer.isToInitiateGooglePayPayment = true
    }
    googlePayContainer.isExpressCheckout = disableInitate
    googlePayContainer.googlePayPaymentToken = token
    this.googlePayToken = token
  }

  handlePaypalInitate = (token = '', disableInitate = false) => {
    if (!disableInitate) {
      paypalContainer.paypalInitiate = true
    }
    paypalContainer.paypalToken = token
    this.paypalToken = token
  }

  hasProducts() {
    const urlSearchString = window.location.search
    const params = new URLSearchParams(urlSearchString)
    const productId = params.get('productId')
    this.products = productId?.split(',')
  }

  initializeCheckoutFlow = () => {
    const customerResponse =
      customerContainer?.profileResponse?.customer ||
      customerContainer?.profileResponse
    if (customerResponse?.id) {
      this.initializePaymentFlow()
    } else {
      setTimeout(() => {
        this.initializeCheckoutFlow()
      }, 300)
    }
  }

  makePickUpLocationCall = async () => {
    this.pickUpLocationResponse = await checkoutContainer.getPickUpLocations(
      APPConfig?.getAppConfig()?.liveEventLocationId
    )
    // Update pick up location as default address delivery details
    checkoutContainer.setPickupLocationAsDefault(
      this.pickUpLocationResponse?.address
    )
  }

  initializePaymentFlow = async () => {
    checkoutContainer.isToDisableSubmitOrderButton = true
    this.handleVenmoBackNavigation()
    await this.checkoutInitialFunctionality()
    this.renderZeroCheckoutWithPaymentAlert()
    customerContainer?.paymentResponse &&
      trackGTMEvents({
        event: 'checkout.payment.has-saved-payment',
        data: customerContainer?.paymentResponse?.payments?.length > 0,
      })
  }

  handleCheckoutRedirectionForVenmo = async () => {
    let isFromVenmoPayment = getLocalStorage('isFromVenmoPayment') || false
    if (isFromVenmoPayment) {
      this.handleLoading(true)
      trackErrorInInstana({
        errorReport: `Venmo - On Browser Back Enabling Shipping Address accordion`,
        errorData: cartContainer.cartCount || 0,
      })
      deleteFromLocalStorage('isFromVenmoPayment')
      this.handleLoading(false)
      checkoutContainer.activePath = 'shipping'
      // this.props.history?.replace(`${pageNames.viewCart}`)
    }
  }

  handleVenmoBackNavigation = () => {
    try {
      if (typeof window === 'object') {
        // this.backListener = this.props.history?.listen((loc, action) => {
        //   if (action === 'POP' && loc.pathname === pageNames.checkout) {
        //     this.handleCheckoutRedirectionForVenmo()
        //   }
        // })
        // For IOS
        window.addEventListener('pageshow', event => {
          this.handleCheckoutRedirectionForVenmo()
        })
        // For Android
        window.addEventListener('focus', event => {
          console.log('** focus', this.props.history)
          this.handleCheckoutRedirectionForVenmo()
        })
      }
    } catch (e) {
      console.log('** Unable to Handle Venmo Back Navigation', e)
    }
  }

  componentWillUnmount() {
    checkoutContainer.activePath = ''
    checkoutContainer.giftPayLoad = false
    checkoutContainer.dropShippingPayLoad = false
    checkoutContainer.giftMessage = ''
    this.toggleModal({ visibility: false })
  }

  hasDefaultAddress = () => {
    const accountAddress =
      accountsAddressContainer.addressData?.length > 0
        ? accountsAddressContainer.addressData
        : []
    const addressData =
      addressContainer.addressData?.length > 0
        ? addressContainer.addressData
        : accountAddress
    let defaultAddress = addressData?.some(
      address => address?.isDefault === true
    )
    return defaultAddress
  }

  checkCommunicationPreference = async () => {
    const communicationResponse =
      await checkoutContainer.setCommunicationPreferenceForZeroCheckout()
    let isError = false
    if (!checkoutContainer.isSuccessResponse(communicationResponse)) {
      toastState.setToastMessage(
        communicationResponse?.message || communicationResponse?.errorMessage
      )
      isError = true
      this.isToDisableShippingContinueBtn = false
    }
    return isError
  }

  handleLoading(isLoading) {
    overlayState.toggleLoader()
    if (isLoading) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }

  handleAlipayPaymentRedirection = async () => {
    const alipayRedirectUrlQueryParam =
      this.props?.history?.location?.queryParams || {}

    // ALIPAY Redirection after Payment - Failure scenario
    if (
      alipayRedirectUrlQueryParam?.partner_name &&
      alipayRedirectUrlQueryParam?.ssl_result != 0
    ) {
      const alipayFailureRedirectStatus =
        'Alipay Payment - Failure/Cancel Redirect'
      const alipayFailureRedirectMessage = i18nTranslate(
        'order.fail',
        'Sorry, your order cannot be placed. Please try a different payment method.'
      )
      await this.handleAlipayFailureRedirection(
        alipayFailureRedirectStatus,
        alipayFailureRedirectMessage
      )
      return false
    }

    // ALIPAY Redirection after Payment - Success scenario
    if (alipayRedirectUrlQueryParam?.ssl_result == 0) {
      const userId = customerContainer?.userId || ''
      let cartResponse = (await cartContainer.viewCart()) || {}
      let cartCount = cartResponse?.value?.count || 0
      if (cartCount > 0) {
        let isAlipayPaymentSuccess = await this.handleAlipaySuccessRedirection()
        return isAlipayPaymentSuccess
      } else {
        trackErrorInInstana({
          errorReport: `Alipay - As CartCount is Zero, Navigating back to CartPage for User ${userId}`,
          errorData: cartContainer.cartCount || 0,
        })
        this.props.history?.replace(`${pageNames.viewCart}`)
        return true
      }
    }
  }

  handleAlipaySuccessRedirection = async () => {
    checkoutContainer.alipayInCart = true
    this.handleLoading(true)
    const userId = customerContainer?.userId || ''
    const submitOrderResponse = await checkoutContainer.submitOrder()
    if (checkoutContainer.isSuccessResponse(submitOrderResponse)) {
      this.handleLoading(false)
      toastState.setToastMessage(
        i18nTranslate('order.submitSuccess', 'Your order has been submitted.'),
        true
      )
      trackErrorInInstana({
        errorReport: `Alipay Submit Order API - Success for User ${userId}`,
        errorData: submitOrderResponse,
      })
      checkoutContainer.alipayInCart = false
      let isNativeApp = checkIsNativeApp() || false
      if (isNativeApp) {
        this.props.history?.push(
          `${pageNames.orderConfirmation}?isNativeApp=true`
        )
      } else {
        this.props.history?.push(`${pageNames.orderConfirmation}`)
      }
      await cartContainer.viewCart()
      customerContainer.isRegisterUser &&
        (await loyaltyContainer.getLoyaltyPoints())
      return true
    } else {
      const alipaySubmitOrderFailureMessage =
        submitOrderResponse?.responseMessage ||
        submitOrderResponse.message ||
        i18nTranslate(
          'order.generalError',
          'Sorry,we are unable to place order. Please try again.'
        )
      const alipaySubmitOrderFailureStatus = 'Alipay Submit Order API - Failed'
      this.handleLoading(false)
      await this.handleAlipayFailureRedirection(
        alipaySubmitOrderFailureStatus,
        alipaySubmitOrderFailureMessage
      )
      return false
    }
  }

  handleAlipayFailureRedirection = async (
    alipayPaymentStatus,
    alipayPaymentMessage
  ) => {
    const userId = customerContainer?.userId || ''
    this.handleLoading(true)
    checkoutContainer.alipayInCart = false
    this.isToEnablePaymentAccordion = true
    trackErrorInInstana({
      errorReport: `${alipayPaymentStatus} for User ${userId}`,
      errorData: alipayPaymentMessage,
    })
    toastState.setToastMessage(alipayPaymentMessage, false)
    // To remove the queryParam alipay queryParam from URL
    this.handleLoading(false)
    this.props?.history?.push(`${pageNames.checkout}`)
  }

  handleKlarnaPaymentRedirection = async () => {
    const klarnaRedirectUrlQueryParam =
      this.props?.history?.location?.queryParams || {}
    const klarnaStatus = klarnaRedirectUrlQueryParam?.klarna_status || ''
    const userId = customerContainer?.userId || ''
    // KLARNA Redirection after Payment - Success Scenario
    if (klarnaStatus === 'success') {
      let cartResponse = (await cartContainer.viewCart()) || {}
      let cartCount = cartResponse?.value?.count || 0
      if (cartCount > 0) {
        const isKlarnaOrderSuccess =
          await this.handleKlarnaSubmitAfterRedirection()
        return isKlarnaOrderSuccess
      } else {
        trackErrorInInstana({
          errorReport: `Klarna - As CartCount is Zero, Navigating back to HomePage for User ${userId}`,
          errorData: cartContainer.cartCount || 0,
        })
        this.props.history?.replace(`${pageNames.home}`)
        return true
      }
    }

    // KLARNA Redirection after Payment - Failure Scenario
    if (klarnaStatus === 'failure') {
      const klarnaPaymentStatus = `Klarna Payment Failure for User ${userId}`
      const klarnaFailureMessage = i18nTranslate(
        'checkout.klarnaFailureMessage',
        "We are sorry, your Klarna payment can't successful. Please select another payment option and try again."
      )

      await this.handleKlarnaFailureAfterRedirection(
        klarnaPaymentStatus,
        klarnaFailureMessage
      )
    }

    // KLARNA Redirection after Payment - Cancel Scenario
    if (klarnaStatus === 'cancel') {
      const klarnaPaymentStatus = `Klarna Payment Cancelled for User ${userId}`
      const klarnaFailureMessage = i18nTranslate(
        'checkout.klarnaCancelMessage',
        'Your Klarna payment request was successfully cancelled. Please select a payment option and try again.'
      )

      await this.handleKlarnaFailureAfterRedirection(
        klarnaPaymentStatus,
        klarnaFailureMessage
      )
    }
  }

  handleKlarnaSubmitAfterRedirection = async () => {
    checkoutContainer.isKlarnaPaymentAdded = true
    this.handleLoading(true)
    const userId = customerContainer?.userId || ''
    const submitOrderResponse = await checkoutContainer.submitOrder()
    if (checkoutContainer.isSuccessResponse(submitOrderResponse)) {
      trackErrorInInstana({
        errorReport: `Klarna Submit Order API - Success for User ${userId}`,
        errorData: submitOrderResponse,
      })
      this.handleLoading(false)
      toastState.setToastMessage(
        i18nTranslate('order.submitSuccess', 'Your order has been submitted.'),
        true
      )
      checkoutContainer.isKlarnaPaymentAdded = false
      let isNativeApp = checkIsNativeApp() || false
      if (isNativeApp) {
        this.props.history?.push(
          `${pageNames.orderConfirmation}?isNativeApp=true`
        )
      } else {
        this.props.history?.push(`${pageNames.orderConfirmation}`)
      }
      await cartContainer.viewCart()
      return true
    } else {
      let errorMessage = ''
      if (submitOrderResponse?.code == 'ECKLPY0001') {
        errorMessage = i18nTranslate(
          'checkout.paymentGeneralError',
          'Sorry, we were unable to process your payment. Please try again.'
        )
      } else {
        errorMessage =
          submitOrderResponse?.responseMessage ||
          submitOrderResponse?.message ||
          i18nTranslate(
            'order.generalError',
            'Sorry,we are unable to place order. Please try again.'
          )
      }
      checkoutContainer.isKlarnaPaymentAdded = false
      toastState.setToastMessage(errorMessage)
      const sessionId = getLocalStorage('sessionId')
      trackErrorInInstana({
        errorReport: `Klarna Session - ${sessionId} - ${userId}`,
        errorData: { errorMessage },
      })
      trackErrorInInstana({
        errorReport: `Klarna Submit Order API - Failed for User ${userId}`,
        errorData: { errorMessage },
      })
      this.handleLoading(false)
      this.isToEnablePaymentAccordion = true
      // To remove the queryParam klarna_status from URL
      this.props?.history?.push(`${pageNames.checkout}`)
      return false
    }
  }

  handleKlarnaFailureAfterRedirection = (
    klarnaPaymentStatus,
    klarnaFailureMessage
  ) => {
    this.handleLoading(true)
    this.isToEnablePaymentAccordion = true
    trackErrorInInstana({
      errorReport: klarnaPaymentStatus,
      errorData: klarnaFailureMessage,
    })
    checkoutContainer.isKlarnaPaymentAdded = false
    toastState.setToastMessage(klarnaFailureMessage)

    // To remove the queryParam klarna_status from URL
    this.props?.history?.push(`${pageNames.checkout}`)
    this.handleLoading(false)
  }

  isToNavigateBackToViewCart = () => {
    try {
      let isToAllowGuestCheckout =
        cartContainer.isGuestCheckoutEligible() || false
      let isRegisteredUser = customerContainer.isRegisterUser || false
      if (IS_BROWSER && !isToAllowGuestCheckout && !isRegisteredUser) {
        const userId = customerContainer?.userId || ''
        trackErrorInInstana({
          errorReport: `Redirecting Back To CartPage as GuestCheckout is not supported for User ${userId}`,
          errorData: !isToAllowGuestCheckout,
        })
        this.props?.history?.replace(pageNames?.viewCart)
        return true
      }
      return false
    } catch (e) {
      console.log('Unable to navigate to Cart page', e)
    }
  }

  checkoutInitialFunctionality = async () => {
    try {
      if (IS_BROWSER) {
        const userId = customerContainer?.userId || ''
        trackErrorInInstana({
          errorReport: `Redirected Checkout URL for USER ${userId}`,
          errorData: window?.location?.href,
        })
        const isKlarnaPaymentSuccess =
          (await this.handleKlarnaPaymentRedirection()) || false
        const isAlipayPaymentSuccess =
          (await this.handleAlipayPaymentRedirection()) || false
        if (isKlarnaPaymentSuccess || isAlipayPaymentSuccess) {
          return
        }
      }
    } catch (error) {
      console.log('Klarna/Alipay Redirection Issue', error)
    }
    this.handleLoading(true)
    checkoutContainer.isToInitiateGlobalPayment = false
    await addressContainer.getProfileAddress()
    if (!this.props.isFromPDP) {
      await cartContainer.viewCart()
    }
    await checkoutContainer.deleteMultiplePaymentById()
    let isToRedirectToCartPage = await this.isToNavigateBackToViewCart()
    this.handleLoading(false)
    if (isToRedirectToCartPage) {
      return
    }
    if (isExpressCheckout()) {
      const guestEmail = toJS(customerContainer?.profileResponse)
      if (
        !customerContainer?.isRegisterUser &&
        isGuestCheckoutEnabledForWeb() &&
        guestEmail?.email === null &&
        cartContainer?.cartCount > 0 &&
        !location.href.includes(pageNames?.expressPDP)
      ) {
        this.toggleModal({
          visibility: false,
        })
      }
    }
    venmoContainer.venmoInitiate = false
    venmoContainer.venmoClientCreated = false
    googlePayContainer.isToInitiateGooglePayPayment = false
    applepayContainer.applepayInitiate = false
    applepayContainer.applepayClientCreated = false
    deleteFromLocalStorage('isFromVenmoPayment')
    const defaultAddress =
      addressContainer?.addressData.find(address => {
        return address.isDefault === true
      }) || ''

    if (
      !isEmpty(defaultAddress) &&
      !this.accordianClicked &&
      !checkoutContainer.paypalCheckout &&
      !this.isToEnablePaymentAccordion
    ) {
      this.isDefaultSkip = true
      paypalContainer.paypalInitiate = false
      // let response = await checkoutContainer.handleAddressPayPalCartCheckout()
      let response = await checkoutContainer.handleAddressExpressCheckout()
      if (checkoutContainer.isSuccessResponse(response)) {
        const isErrorInPreference = await this.checkCommunicationPreference()
        if (isErrorInPreference) {
          checkoutContainer.activePath = 'shipping'
          return
        }
        checkoutContainer.activePath = 'shippingMethods'

        if (this.enableLiveEvent === 'true') {
          checkoutContainer.activePath = this.isStoreCreditPointsAvailable()
            ? 'storecredit'
            : 'payment'
        }
      } else {
        toastState.setToastMessage(
          response?.message || response?.responseMessage
        )
        checkoutContainer.activePath = 'shipping'
      }
    }
    if (
      checkoutContainer.paypalCheckout &&
      addressContainer?.addressData?.length !== 0 &&
      this.hasDefaultAddress()
    ) {
      overlayState.toggleLoader()
      let addressResponse =
        await checkoutContainer.handleAddressPayPalCartCheckout()

      if (checkoutContainer.isSuccessResponse(addressResponse)) {
        checkoutContainer.activePath = ''

        const response = await checkoutContainer.handlePayPalCartCheckout()
        if (checkoutContainer.isSuccessResponse(response)) {
          const paypalToken = await checkoutContainer.getPayPalToken()
          this.handlePaypalInitiate(paypalToken?.token)
          overlayState.toggleLoader()
          cartContainer.viewCart()
          checkoutContainer.isToDisableSubmitOrderButton = false
        } else {
          overlayState.toggleLoader()
          checkoutContainer.activePath = 'payment'
          toastState.setToastMessage(
            response?.message || response?.responseMessage
          )
        }
      } else {
        overlayState.toggleLoader()
        toastState.setToastMessage(
          addressResponse?.message || addressResponse?.responseMessage
        )
        checkoutContainer.activePath = 'shipping'
      }
    } else if (this.isToEnablePaymentAccordion) {
      checkoutContainer.activePath = 'payment'
      this.isToEnablePaymentAccordion = false
    } else {
      if (!this.isDefaultSkip) {
        checkoutContainer.activePath = 'shipping'
        if (this.enableLiveEvent === 'true') {
          checkoutContainer.activePath = this.isStoreCreditPointsAvailable()
            ? 'storecredit'
            : 'payment'
        }
      }
    }

    const cartItems = cartContainer.cartResponse?.items || []

    const stateReactionCbk = (data, stateReaction) => {
      checkoutContainer.activePath = this.isAllDataAdded
        ? ''
        : checkoutContainer.activePath

      this.isMultipleAdressActive = cartItems.some(item => {
        return Object.keys(item?.deliveryDetails.address || {}).length > 0
      })

      const oldFlowPaths = this.flowPaths || []
      this.getSkipPaths()
      const paymentFail = getLocalStorage('paymentFail') || ''
      if (paymentFail === 'true' || paymentFail === true) {
        // this scenario is in case of payment window is closed
        checkoutContainer.activePath = flowPaths[6]
        toastState.setToastMessage(
          i18nTranslate('payment.fail', 'Payment was cancelled'),
          false
        )
        localStorage.removeItem('paymentFail')
      } else {
        try {
          if (JSON.stringify(oldFlowPaths) !== JSON.stringify(this.flowPaths)) {
            checkoutContainer.activePath = flowPaths[0]
            // reverting Ashwanth's code
            // Caution, old code, causes regression when changed
            // https://nuskin.atlassian.net/browse/CX121-2428
            // https://jira.skava.com/browse/NUSKIN-1834
          }
          stateReaction.dispose()
        } catch (e) {
          console.log('JSON.stringify may be an error', e)
        }
      }
      const paymentError = getLocalStorage('paymentError') || ''
      if (paymentError === 'true' || paymentError === true) {
        checkoutContainer.activePath = flowPaths[6]
        toastState.setToastMessage(
          i18nTranslate(
            'order.paypalError',
            'Sorry, your PayPal payment failed. Please try a different payment method.'
          ),
          false
        )
        localStorage.removeItem('paymentError')
      }
    }

    if (cartItems.length === 0) {
      reaction(() => cartContainer.cartResponse, stateReactionCbk)
    }

    customerContainer?.isRegisterUser && (await this.getLoyaltyPoints())
    await this.getStoreCredits()
  }

  getLoyaltyPoints = async () => {
    await loyaltyContainer.getLoyaltyPoints(true)
  }

  getStoreCredits = async () => {
    this.storeCredits = await loyaltyContainer.getStoreCredits()
  }

  termsCheck = val => {
    this.termsVal = val
    checkoutContainer.isToDisableSubmitOrderButton = !this.termsVal
  }

  @computed
  get address() {
    return cartContainer?.cartResponse?.deliveryDetails?.address || {}
  }

  @computed
  get selectedShippingMethod() {
    return (
      (cartContainer.cartResponse?.deliveryDetails?.methods || []).find(
        method => method.isSelected === true
      ) || {}
    )
  }

  @computed
  get isAmountAdded() {
    return (
      Math.abs(cartContainer.cartResponse?.paymentValue?.totalAmountUnpaid) ===
      0
    )
  }

  @computed
  get isTermsAgreed() {
    return this.termsVal
  }

  @computed
  get hasStoreCreditPayment() {
    let payments = cartContainer?.cartResponse?.payments || []
    return payments?.some(item => {
      return item.type === 'STORECREDIT'
    })
  }

  @computed
  get isItemLevelAddressAvl() {
    return (cartContainer.cartResponse?.items || []).every(item => {
      return !isEmpty(item?.deliveryDetails?.methods)
    })
  }

  @computed
  get isAllDataAdded() {
    // Data conditions for Live Event - Start
    if (this.enableLiveEvent === 'true') {
      if (this.isMultipleAdressActive) {
        return (
          this.isItemLevelAddressAvl &&
          this.isAmountAdded &&
          cartContainer.cartButtonDisable === false &&
          (!cartContainer.hasSubscriptionProduct() || this.termsVal)
        )
      } else {
        return (
          this.pickUpLocationResponse?.address &&
          Object.keys(this.pickUpLocationResponse?.address).length > 0 &&
          this.isAmountAdded &&
          cartContainer.cartButtonDisable === false &&
          (!cartContainer.hasSubscriptionProduct() || this.termsVal)
        )
      }
    }
    // End

    if (this.isMultipleAdressActive) {
      return (
        this.isItemLevelAddressAvl &&
        this.isAmountAdded &&
        cartContainer.cartButtonDisable === false &&
        (!cartContainer.hasSubscriptionProduct() || this.termsVal)
      )
    } else {
      return (
        Object.keys(this.address).length > 0 &&
        !isEmpty(this.selectedShippingMethod) &&
        (!cartContainer.hasSubscriptionProduct() || this.termsVal)
      )
    }
  }

  getSkipPaths = () => {
    let hideCouponCode = false
    /** hide coupon code for personal offer page */
    try {
      if (typeof window !== 'undefined') {
        // const shoppingContext = getLocalStorage('shoppingContext') || {}
        const shoppingContext = getShoppingContext()
        if (Object.keys(shoppingContext)?.length > 0) {
          const contextValue = shoppingContext?.context || ''
          /**
           * @description Hide coupon code in Po site if promotion is added to cart [CX16-10005]
           * @date 9/29/2023 - 2:27:06 PM
           */
          if (contextValue === 'personal_offer') {
            const personalOfferCode = getSessionStorage('personalOffer') || {}
            const hideCouponCodeInPOCheckout = !(
              APPConfig?.getAppConfig()?.enableCouponCodeForPoSite == 'true'
            )
            const promotions =
              (cartContainer.cartResponse?.promotionDetails &&
                cartContainer.cartResponse?.promotionDetails?.codes) ||
              []
            const promotionCheck =
              promotions?.some(
                promotion =>
                  promotion?.code === personalOfferCode?.discount?.code
              ) || false
            if (hideCouponCodeInPOCheckout) {
              hideCouponCode = true
            } else {
              hideCouponCode = promotionCheck
            }
          }
          /**
           * @description Hide Coupon code in Mysite if the user type doesn't match any value in enableCouponCodeForMySite of appConfig[CX16-9759]
           * @date 9/29/2023 - 2:29:55 PM
           */
          if (contextValue === 'storefront') {
            const enableCouponCodeForMySite =
              APPConfig?.getAppConfig()?.enableCouponCodeForMySite || []
            const accType =
              customerContainer?.profileResponse?.accounts?.accountType || ''
            if (!customerContainer.isRegisterUser) {
              if (!enableCouponCodeForMySite?.includes('Guest')) {
                hideCouponCode = true
              }
            } else if (customerContainer.isRegisterUser) {
              if (!enableCouponCodeForMySite?.includes(accType)) {
                hideCouponCode = true
              }
            }
          }
        } else {
          hideCouponCode = false
        }
      }
    } catch (e) {
      console.error('JSON.parse may be an error ExpressCheckout', e)
    }

    const pickUpType = enableStorePickup
      ? this.pickupStoreView()
      : 'shipmentonly'
    // const emptyArray = []
    const getOpenLoyaltyPoints = loyaltyContainer.getOpenLoyaltyPoints()
    this.storeCredits = loyaltyContainer.getStoreCredits()
    const hideLoyaltyAlways =
      APPConfig?.getAppConfig()?.hideLoyaltyAlways === 'true'
    const hideLoyaltyForUS =
      APPConfig?.getAppConfig()?.hideLoyaltyForUS === 'true'
    const storeIdBasedOnCountry =
      storeContainer?.storeIDValue?.toLowerCase() || ''
    const isUS = storeIdBasedOnCountry === 'us'
    const isToHideLoyaltyForUS = hideLoyaltyForUS && isUS
    this.skipPaths = [
      (isToHideLoyaltyForUS ||
        hideLoyaltyAlways ||
        !customerContainer.isRegisterUser ||
        (!isLoyEnableInProf() && !switchActive && getOpenLoyaltyPoints === 0) ||
        cartContainer.getRedeemLoyaltyPoints() === 0) &&
        flowSkipPaths.hideLoyalty,
      checkoutContainer.isZeroCheckout()
        ? cartContainer.hasSubscriptionProduct()
          ? flowSkipPaths.zeroCheckoutSkipWithPayment
          : flowSkipPaths.zeroCheckoutSkipWithoutPayment
        : [],
      this.isMultipleAdressActive && flowSkipPaths.itemLevelAddress,
      pickUpType === 'shipmentonly' && flowSkipPaths.shipmentOnly,
      pickUpType === 'pickuponly' && flowSkipPaths.pickUpOnly,
      (this.storeCredits <= 0 || cartContainer.hasSubscriptionProduct()) &&
        flowSkipPaths.hideStoreCredit,
      hideCouponCode && flowSkipPaths.hideCouponCode,
      !cartContainer.hasSubscriptionProduct() && flowSkipPaths.hideSubscription,
      checkoutContainer.isCouponFullPayment()
        ? cartContainer.hasSubscriptionProduct()
          ? flowSkipPaths.isCouponFullPaymentWithSubscription
          : flowSkipPaths.isCouponFullPayment
        : [],
      checkoutContainer.isLoyaltyOrCouponFullPayment()
        ? cartContainer.hasSubscriptionProduct()
          ? flowSkipPaths.isLoyaltyOrCouponFullPaymentWithSubscription
          : flowSkipPaths.isLoyaltyOrCouponFullPayment
        : [],
      checkoutContainer.isLoyaltyOrCouponOrStoreCreditsFullPayment()
        ? cartContainer.hasSubscriptionProduct()
          ? []
          : flowSkipPaths.isLoyaltyOrCouponOrStoreCreditsFullPayment
        : [],
      this.enableLiveEvent === 'true' && flowSkipPaths.enableLiveEvent,
    ]
      .filter(Boolean) // removes null/undefined/x values from array
      .flat() // two dimensional array merged into single array

    // this.skipPaths = [
    //   ...(customerContainer.isRegisterUser
    //     ? emptyArray
    //     : flowSkipPaths.hideLoyalty),
    //   ...(this.isMultipleAdressActive
    //     ? flowSkipPaths.itemLevelAddress
    //     : emptyArray),
    //   ...(pickUpType === 'shipmentonly'
    //     ? flowSkipPaths.shipmentOnly
    //     : emptyArray),
    //   ...(pickUpType === 'pickuponly' ? flowSkipPaths.pickUpOnly : emptyArray),
    //   ...(isBrandAffiliateTypeProfile() && !isLoyEnableInProf()
    //     ? flowSkipPaths.hideLoyalty
    //     : emptyArray),
    //   ...(this.storeCredits <= 0 || cartContainer.hasSubscriptionProduct()
    //     ? flowSkipPaths.hideStoreCredit
    //     : emptyArray),
    //   ...(hideCouponCode ? flowSkipPaths.hideCouponCode : emptyArray),
    // ]

    this.flowPaths = flowPaths.filter(path => !this.skipPaths.includes(path))
  }

  handlePaypalButton = flag => {
    paypalContainer.paypalInitiate = flag
  }

  @action
  toggleMultipleAddress = () => {
    if (isToEnableItemLevelShipping) {
      this.isMultipleAdressActive = !this.isMultipleAdressActive
      this.getSkipPaths()
    }
  }

  handleClick = params => {
    const { index } = params
    const activeIndex = this.isAllDataAdded
      ? this.flowPaths?.length
      : this.flowPaths?.indexOf(checkoutContainer.activePath)

    if (activeIndex > this.flowPaths?.indexOf(index)) {
      checkoutContainer.activePath = index
    }
    this.accordianClicked = params?.isAccordianClicked || false
    this.isAddressChecked = true
    tokenExIframe.isValidCard = true
    tokenExIframe.isCvvValidCard = true
  }

  handleNext = async () => {
    const currentIndex = this.flowPaths.indexOf(checkoutContainer.activePath)
    if (
      checkoutContainer.paypalCheckout &&
      checkoutContainer.activePath === 'loyalty'
    ) {
      checkoutContainer.activePath = ''

      const response = await checkoutContainer.handlePayPalCartCheckout()
      if (checkoutContainer.isSuccessResponse(response)) {
        const paypalToken = await checkoutContainer.getPayPalToken()
        this.handlePaypalInitiate(paypalToken.token)
        cartContainer.viewCart()
      }
    } else {
      checkoutContainer.activePath =
        currentIndex === this.flowPaths.length - 1
          ? 'sumbitOrder'
          : this.flowPaths[currentIndex + 1] || ''
    }
    if (checkoutContainer.activePath === 'sumbitOrder') {
      checkoutContainer.isToDisableSubmitOrderButton = false
      const section = document.querySelector('#checkout-floating-bar')
      section?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  handleAlipayInitiate = () => {
    this.alipayInitiate = true
  }

  handlePaypalInitiate = token => {
    paypalContainer.paypalInitiate = true
    this.paypalToken = token
  }
  handleVenmoInitiate = token => {
    venmoContainer.venmoInitiate = true
    this.venmoToken = token
  }
  closeAlipayInitiate = () => {
    this.alipayInitiate = false
  }

  handleKlarnaInitiate = () => {
    checkoutContainer.isToInitiateKlarnaPayment = true
  }

  pickupStoreView = () => {
    const pickupProducts = Object.assign(
      getLocalStorage('storeProducts') || {},
      storePickupState.storePickupProducts
    )
    const pickupProductsArray = Object.keys(pickupProducts) || []
    const cartLength = cartContainer.cartResponse?.items || []
    const pickupShipment =
      cartLength === pickupProductsArray.length
        ? 'pickuponly'
        : pickupProductsArray.length > 0
        ? 'pickupandshipment'
        : 'shipmentonly'

    return pickupShipment
  }

  isStoreCreditPointsAvailable = async () => {
    this.storeCredits = await loyaltyContainer.getStoreCredits()
    return this.storeCredits > 0 && !cartContainer.hasSubscriptionProduct()
  }

  renderPaymentOptions = (title, isFromPDP) => {
    const paymentOptions = (
      <div
        className={`caw-section ${
          isFromPDP ? 'mb-2 p-4' : 'mb-2 mb-md-0 p-4 p-md-3'
        }`}
        data-testid="qa-checkout-payment-accordion">
        <PaymentAccordion
          onContinue={this.handleNext}
          title={title}
          onEdit={this.handleClick}
          isFromCheckout={true}
          isMultipleAdressActive={this.isMultipleAdressActive}
          handleAlipayInitiate={this.handleAlipayInitiate}
          handlePaypalInitiate={this.handlePaypalInitiate}
          handleVenmoInitiate={this.handleVenmoInitiate}
          handleKlarnaInitiate={this.handleKlarnaInitiate}
          handleGooglePayInitiate={this.handleGooglePayInitiate}
          handleApplePayInitiate={this.initateApplePay}
        />
      </div>
    )
    const totalAmountUnpaid =
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || 0
    const isStoreCreditAvailable =
      cartContainer?.cartResponse?.payments?.filter(
        payment => payment?.type === 'STORECREDIT'
      )?.length > 0
    if (
      isStoreCreditAvailable &&
      totalAmountUnpaid === 0 &&
      cartContainer?.cartResponse?.payments?.length === 1
    ) {
      return <></>
    }
    return paymentOptions
  }

  renderExpressCheckoutView = (activePath, title = '') => {
    const enableGlobalPaymentSystem = isGPSEnabled()
    const gpsCheckoutURL = '/checkoutGps'
    const isGPSCheckoutURL = window?.location?.href?.includes(
      `${gpsCheckoutURL}`
    )
    const isToEnableGlobalPaymentFlow =
      enableGlobalPaymentSystem && isGPSCheckoutURL
    const { isFromPDP } = this.props
    return (
      <>
        <div
          className={`caw-section ${
            isFromPDP ? 'mb-2 p-4' : 'mb-2 mb-md-0 p-4 p-md-3'
          }`}>
          <ShippingAccordion
            onContinue={this.handleNext}
            isRegisteredUser={customerContainer.isRegisterUser}
            isAddressChecked={this.isAddressChecked}
            accordianClicked={this.accordianClicked || false}
            toggleMultipleAddress={this.toggleMultipleAddress}
            isMultipleAdressActive={this.isMultipleAdressActive}
            isDefaultSkip={this.isDefaultSkip || false}
            title={title}
            onSwitchConfirmPopup={this.onSwitchConfirmPopup}
            history={this.props.history}
            isFromPDP={isFromPDP || false}
          />
        </div>
        <div
          className={`caw-section ${
            isFromPDP ? 'mb-2 p-4' : 'mb-2 mb-md-0 p-4 p-md-3'
          }`}>
          <ShippingMethodAccordion
            onContinue={this.handleNext}
            isRegisteredUser={customerContainer.isRegisterUser}
            title={title}
            isCheckOutPage={true}
            handlePaypalButton={this.handlePaypalButton}
            {...this.props}
          />
        </div>
        {this.isStoreCreditPointsAvailable() && this.storeCredits > 0 && (
          <div
            className={`caw-section ${
              isFromPDP ? 'mb-2 p-4' : 'mb-2 mb-md-0 p-4 p-md-3'
            }`}>
            <StoreCredits
              onContinue={this.handleNext}
              title={title}
              isCheckOutPage={true}
              handlePaypalButton={this.handlePaypalButton}
            />
          </div>
        )}
        {isToEnableGlobalPaymentFlow && (
          <div
            className={`caw-section ${
              isFromPDP ? 'mb-2 p-4' : 'mb-2 mb-md-0 p-4 p-md-3'
            }`}>
            <GlobalPaymentAccordion
              onContinue={this.handleNext}
              title={title}
              onEdit={this.handleClick}
              isFromCheckout={true}
              isMultipleAdressActive={this.isMultipleAdressActive}
              handleAlipayInitiate={this.handleAlipayInitiate}
              handlePaypalInitiate={this.handlePaypalInitiate}
              handleVenmoInitiate={this.handleVenmoInitiate}
              handleKlarnaInitiate={this.handleKlarnaInitiate}
              handleGooglePayInitiate={this.handleGooglePayInitiate}
              handleApplePayInitiate={this.initateApplePay}
            />
          </div>
        )}
        {this.renderPaymentOptions(title, isFromPDP)}
        <div
          data-testid="qa-checkout-right-bar"
          className={`caw-section ${
            isFromPDP
              ? 'd-block mb-2 p-4'
              : 'd-block d-md-none mb-2 mb-md-0 p-4 p-md-3'
          }`}>
          <FloatingRightBar
            onEdit={this.handleClick}
            enableSubmit={this.isAllDataAdded}
            history={this.props.history}
            isMultipleAdressActive={this.isMultipleAdressActive}
            alipayInitiate={this.alipayInitiate}
            closeAlipayInitiate={this.closeAlipayInitiate}
            // paypalInitiate={this.paypalInitiate}
            paypalToken={this.paypalToken}
            venmoToken={this.venmoToken}
            handleNext={this.handleNext}
            isCheckout={true}
            isFromPDP={isFromPDP || false}
            {...this.props}
          />
        </div>
        <div className={`caw-section  ${isFromPDP ? 'p-4' : 'p-4 p-md-3'}`}>
          {cartContainer.hasSubscriptionProduct() && (
            <SubscriptionAccordion termsCheck={this.termsCheck} />
          )}
          <SubmitOrder
            onEdit={this.handleClick}
            history={this.props.history}
            enableSubmit={this.isAllDataAdded}
            alipayInitiate={this.alipayInitiate}
            closeAlipayInitiate={this.closeAlipayInitiate}
            // paypalInitiate={this.props?.paypalInitiate}
            paypalToken={this.paypalToken}
            venmoToken={this.venmoToken}
            handleVenmoInitiate={this.handleVenmoInitiate}
          />
        </div>
      </>
    )
  }

  getAccordionSelectedValues = (key, isActive) => {
    if (isActive) {
      return false
    }

    let payments = cartContainer?.cartResponse?.payments || []

    if (key === 'shipping') {
      const address = this.address || []
      let pickUpResponse = this.pickUpLocationResponse || {}
      return this.enableLiveEvent === 'true' ? (
        <PickUpAddress
          address={pickUpResponse?.address || {}}
          email={customerContainer?.profileResponse?.email || ''}
        />
      ) : (
        Object.keys(address).length > 0 && (
          <SingleLineAddress address={address} className={`font-weight-bold`} />
        )
      )
    } else if (key === 'shippingMethods') {
      const selectedMethod = this.selectedShippingMethod || {}
      const selectedMethodName =
        getTranslatedShippingMethod(selectedMethod?.id) || ''
      return (
        !isEmpty(selectedMethod) && (
          <div className="font-weight-bold">{`${selectedMethodName} ${currencyFormat(
            { price: selectedMethod.cost }
          )}`}</div>
        )
      )
    } else if (key === 'CouponCode') {
      this.CouponCode = true
    } else if (key === 'loyalty') {
      this.loyaltySelected = true
      return (
        <p>
          {i18nTranslate('checkout.appliedLoyalty', 'Loyalty Points Applied :')}
          {currencyFormat({ price: cartContainer.redeemedPoints() })}
        </p>
      )
    } else if (key === 'storecredit') {
      this.storecreditSelected = true

      let storeCreditPayment = payments?.find(option => {
        return option.type === 'STORECREDIT'
      })

      return (
        this.hasStoreCreditPayment && (
          <div className="font-weight-bold">
            Store Credits Applied :{'$'}
            {storeCreditPayment ? storeCreditPayment?.amount || 0 : ''}
          </div>
        )
      )
    } else if (key === 'payment') {
      return this.renderPaymentDetails()
    }
  }

  renderCardDetails = (payments = {}) => {
    let card = payments?.accountInfo || {}
    const provider = card?.provider?.toLowerCase() || ''
    const cardNumber = card?.cardNumber || ''
    const endingText = i18nTranslate('payment.cardEndingTxt', '-****')
    if (!cardNumber) {
      return <></>
    }

    return (
      <div
        className="d-flex payment-details-wrapper"
        data-testid="payment-details-wrapper">
        <PaymentIcon type={provider} />
        <div className="d-flex font-weight-bold">
          <span className="selected-card-info ml-1 d-flex align-items-center font-weight-bold text-capitalize">
            {provider} {endingText} {cardNumber}
          </span>
        </div>
      </div>
    )
  }

  renderPaymentDetails = () => {
    let payments = cartContainer?.cartResponse?.payments || []
    let paymentsSupported = [
      'creditcard',
      'debitcard',
      'paypal',
      'venmo',
      'klarna',
      'alipay',
      'googlepay',
      'applepay',
    ]
    return payments?.map(payment => {
      const paymentType = payment?.type?.toLowerCase() || ''
      if (paymentsSupported?.includes(paymentType)) {
        if (paymentType === 'creditcard' || paymentType === 'debitcard')
          return this.renderCardDetails(payment)
        else {
          return (
            <div
              className="d-flex align-items-center"
              data-testid="payment-details-wrapper">
              <span>{<PaymentIcon type={paymentType} />}</span>
              <span className="ml-1">{paymentType?.toUpperCase()}</span>
            </div>
          )
        }
      }
    })
  }

  accordionView = () => {
    return (
      <div className="checkout-accordion-wrapper h-100">
        {this.renderExpressCheckoutView()}
      </div>
    )
  }

  handleShow() {
    this.show = !this.show
  }

  handleClose() {
    this.show = false
  }

  renderZeroCheckoutWithPaymentAlert = () => {
    if (
      checkoutContainer.isZeroCheckout() &&
      cartContainer.hasSubscriptionProduct() &&
      this.showZeroCheckoutWithPaymentAlert
    ) {
      return (
        modalState.setModalMessage(
          i18nTranslate(
            'checkoutPage.zeroCheckoutWithPaymentAlert',
            'Subscription orders needs payments to do the recursion order.'
          ),
          i18nTranslate('modaView.buttonText', 'OK')
        ),
        (this.showZeroCheckoutWithPaymentAlert = false)
      )
    }
  }

  showInventoryConfirmModal = () => {
    return (
      <Modal
        className="confirm-modal"
        show={cartContainer.inventoryConfirm}
        size="md"
        aria-labelledby="confirm-modal-title-vcenter"
        centered>
        <Modal.Body>
          <span>
            {i18nTranslate(
              'confirm.info',
              `“Your item is out of Stock for the given address, please remove the item to proceed”`
            )}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Link to={pageNames.viewCart}>
            <Button
              onClick={() => {
                cartContainer.toggleinventoryConfirm()
              }}>
              {i18nTranslate('confirmPoup.ok', 'OK')}
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    )
  }

  renderSignInAccordion = () => {
    return <SignInAccordion {...this.props} toggleModal={this.toggleModal} />
  }

  gPayExpressCheckout = async () => {
    await googlePayContainer.createExpressGooglePayOrder()
    trackExpressCheckoutEvents({
      link_text: 'googlepay',
      link_url: window?.location?.pathname,
      session_id: getLocalStorage('sessionId'),
      ...getCheckoutGTMPageData({
        pageTitle: 'Express Payment',
      }),
    })
  }

  applePayCheckout = async () => {
    applepayContainer.isExpressPayment = true
    applepayContainer.isToShowApplePayConfirmationModal = false
    await applepayContainer.createApplePayPaymentSession(
      applepayContainer.applepayCheckoutInstance
    )
    trackExpressCheckoutEvents({
      link_text: 'applepay',
      link_url: window?.location?.pathname,
      session_id: getLocalStorage('sessionId'),
      ...getCheckoutGTMPageData({
        pageTitle: 'Express Payment',
      }),
    })
  }

  render() {
    if (APPConfig.getAppConfig()?.enableExpressCheckout !== 'true') {
      return <NotFound />
    }
    if (isEmpty(cartContainer.cartResponse)) {
      return <></>
    }
    if (
      cartContainer.hasSubscriptionProduct() &&
      nuskinSubscriptionContainer.isUserSubscribedBefore()
    ) {
      this.props?.history?.replace(`${pageNames?.viewCart}`)
    }
    const checkoutTitle = i18nTranslate(
      'checkoutPage.title',
      'Checkout | Nu Skin'
    )
    const customClassName =
      cartContainer.cartCount == 0 ? 'd-block' : 'd-flex justify-content-center'
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false

    const { isFromPDP } = this.props
    return (
      <div className="express-checkout">
        <Modal
          data-qa="qa-attribute-checkout-dialoge"
          centered={true}
          size="lg"
          className="modal-wrapper p-0"
          show={this.isModalVisible}
          onHide={() => this.toggleModal(false)}
          dialogClassName="checkout-dialog">
          <Modal.Header closeButton className="border-bottom-0" />
          <Modal.Body>{this.renderSignInAccordion()}</Modal.Body>
        </Modal>
        <MetaDecorator
          title={checkoutTitle}
          imageUrl=""
          imageAlt=""
          description="Checkout View page"
          location={this.props}
        />

        <div
          className={`checkout-wrapper-container ${customClassName} ${
            isFlexibleSubscriptionEnabled
              ? 'checkout-wrapper-container-flex-subs'
              : ''
          }`}>
          {!isEmpty(cartContainer.cartResponse) &&
          cartContainer.cartCount == 0 ? (
            <EmptyCart isCheckout={true} />
          ) : (
            <Row className={`${isFromPDP ? 'p-0 ' : 'p-0  p-md-3'}  w-100 m-0`}>
              <Col
                xs={12}
                md={isFromPDP ? 12 : 7}
                className="align-items-stretch checkout-wrapper d-flex flex-column h-100 p-0">
                {!cartContainer.hasSubscriptionProduct() && (
                  <div
                    className={`payment-container ${
                      !isFromPDP ? 'bg-white' : ''
                    }`}>
                    <p className="pt-2 mb-0 font-weight-600">
                      {i18nTranslate(
                        'expressCheckout.expressCheckoutOptions',
                        'Express Checkout Options'
                      )}
                    </p>
                    <Row className={`w-100 ${isFromPDP ? 'mb-2' : 'mt-2'}`}>
                      {this.isCurrentDeviceSupportedApplePay && (
                        <Col xs="12" className="p-2">
                          <Button
                            onClick={this.applePayCheckout}
                            className="express-apple-btn w-100"
                            data-qa="express-apple-pay"
                            aria-label={i18nTranslate(
                              'checkout.applepayText',
                              'applepay'
                            )}>
                            <PaymentIcon type="express-apple-pay-white-label" />
                          </Button>
                        </Col>
                      )}

                      <Col xs="6" className="p-2">
                        <Button
                          onClick={this.gPayExpressCheckout}
                          className={`express-gpay-btn  w-100 ${
                            isFromPDP ? 'button-bg-gray' : 'button-bg-white'
                          }`}
                          data-qa="express-google-pay"
                          aria-label={i18nTranslate(
                            'checkout.googlepayText',
                            'googlepay'
                          )}>
                          <PaymentIcon type="express-gpay-btn-white-label" />
                        </Button>
                      </Col>
                      <Col xs="6" className="p-2">
                        <div id="express-paypal-container">
                          <Button
                            className={`express-paypal-btn  w-100 ${
                              isFromPDP ? 'button-bg-gray' : 'button-bg-white'
                            }`}
                            data-qa="express-paypal-pay"
                            aria-label={i18nTranslate(
                              'checkout.paypalText',
                              'paypal'
                            )}>
                            <PaymentIcon type="paypalCart" />
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    {!isFromPDP && (
                      <div className="or">
                        <hr />
                        <span>OR</span>
                      </div>
                    )}
                  </div>
                )}
                {this.accordionView()}
              </Col>
              <Col
                xs={12}
                md={isFromPDP ? 12 : 5}
                className={`p-0  ${
                  isFromPDP ? 'd-none' : 'd-none d-md-block'
                }`}>
                <FloatingRightBar
                  onEdit={this.handleClick}
                  enableSubmit={this.isAllDataAdded}
                  history={this.props.history}
                  isMultipleAdressActive={this.isMultipleAdressActive}
                  alipayInitiate={this.alipayInitiate}
                  closeAlipayInitiate={this.closeAlipayInitiate}
                  // paypalInitiate={this.paypalInitiate}
                  paypalToken={this.paypalToken}
                  venmoToken={this.venmoToken}
                  handleNext={this.handleNext}
                  isCheckout={true}
                  isFromPDP={isFromPDP || false}
                  {...this.props}
                />
              </Col>
            </Row>
          )}
        </div>
        {enableStorePickup && (
          <StorePickup show={storePickupState.showStorePickup} />
        )}
        {cartContainer.inventoryConfirm && this.showInventoryConfirmModal()}
      </div>
    )
  }
}

export { ExpressCheckout }
// export default ExpressCheckout
export default withTranslation(['csr-resource'])(ExpressCheckout)
