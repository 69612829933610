import { observable } from 'mobx'
import {
  IS_BROWSER,
  setResponsiveValues,
  trackEnhancedProductImpression,
  trackEnhancedProductDetailImpression,
  getLocaleCodeFromUrl,
} from 'src/utils'
import { CommonContainer } from 'src/models/Common'
import { builder } from './builder'
import { PLPProductsPerPage, APPConfig } from 'config/appConfig'
import isArray from 'lodash/isArray'
import { customerContainer, storeContainer } from 'src/models'
import contentStack from 'src/api/contentStack'

/**
 * @description This container will be used to fetch the catalog data from the API.
 * Mobx is used to manage the catalog data to the component.
 * @date 2023/8/18 - 16:27:22
 *
 * @class CatalogContainer
 * @extends {CommonContainer}
 */
/**
 * CatalogContainer class that extends CommonContainer.
 */
class CatalogContainer extends CommonContainer {
  @observable categoryResponse = {}
  @observable productCompareList = []
  @observable catalogResponse = {}
  @observable shouldShowPlaceholder = false
  @observable searchSuggestionsResponse = []
  @observable searchProductResponse = {}
  @observable shouldShowMPPPlaceholder = true
  @observable replacementProductClick = false

  get productCompareLimit() {
    return setResponsiveValues(4, 3, 2)
  }

  /**
   * Gets Fastly cache headers based on customer type.
   *
   * Checks if browser and gets customer type from customerContainer profile,
   * otherwise gets customer type from SSR context.
   *
   * Sets Fastly cache header 'X-Customer-Type' to customer type
   * or 'guest' if no customer type.
   *
   * @param {Object} props - Component props object
   * @returns {Object} - Fastly cache headers object
   */
  getFastlyHeaders = props => {
    // This header is used to cache the response from fastly
    let customerType = ''
    if (IS_BROWSER) {
      customerType =
        customerContainer.profileResponse?.accounts?.type ||
        (window?.customerType ? decodeURI(window?.customerType) : '')
      /* SECTEM-8336 */
    } else {
      customerType = props?.context?.customerType
    }
    const fastlyHeaders = {
      // vary: 'X-Customer-Type',
      'X-Customer-Type': customerType || 'guest',
    }
    return fastlyHeaders
  }

  /**
   * Fetches top category data from API or cache.
   *
   * Checks if on browser or SSR.
   * On SSR, fetches data from API and loads into context.
   * On browser, fetches data from API directly.
   *
   * Also optionally fetches top nav data from ContentStack if enabled.
   *
   * Returns the category response.
   */
  getCategory = async props => {
    const loadParams = {
      endPointName: 'topCategory',
      queryParams: {
        isFromSSR: true,
      },
      contextName: props.contextName,
      curLocaleFromUrl: props.context?.curLocaleFromUrl,
      sessionCookies: props.context?.sessionCookies,
    }
    const getImpactDataFromCS =
      APPConfig?.getAppConfig()?.enableImpactSectionFromCS == 'true'
    let response = {}
    if (!IS_BROWSER && props.context && !props.context.data) {
      props.context.load(this.fetchResponse(loadParams))

      if (getImpactDataFromCS) {
        this.getTopNavFromCS(props)
      }
      return
    } else if (IS_BROWSER) {
      response = await this.fetchResponse(loadParams)
    }
    if (this.isSuccessResponse(response)) {
      if (getImpactDataFromCS) {
        this.categoryResponse = await this.getTopNavFromCS({
          ...props,
          response,
        })
      } else {
        this.categoryResponse = response
      }
    }

    return response
  }

  /**
   * Fetches top navigation data from ContentStack based on the current locale.
   *
   * Checks if on browser or server side render.
   * On server side, fetches data from ContentStack API and loads into context.
   * On browser, fetches data directly from ContentStack.
   *
   * Transforms the response into expected category response format using transformCategoryResponse().
   */
  getTopNavFromCS = async props => {
    const { response } = props || {}
    const currentLocale =
      props.context?.curLocaleFromUrl || getLocaleCodeFromUrl() || ''

    const localValue =
      currentLocale?.split('_')?.reverse()?.join('-') || 'US-en'

    if (IS_BROWSER) {
      const contentStackRes = await contentStack.getEntry(
        'ns_default_header',
        localValue
      )
      let csResponse = this.transformCategoryResponse(contentStackRes)
      return response?.category?.subCategories.push(csResponse)
    } else {
      props?.context?.load(
        contentStack
          ?.getEntry('ns_default_header', localValue)
          .then(csResponse => {
            return Promise.resolve({ [props.csContextName]: csResponse })
          })
      )
    }
  }

  /**
   * Transforms the ContentStack response for top navigation
   * into the expected category response format.
   *
   * Parses the menu title, menu contents, and link data from
   * the ContentStack response to build up the category model used in the website.
   *
   * The transformed model contains properties, parent IDs, subCategories etc.
   */
  transformCategoryResponse = response => {
    let title =
      response?.navbar_component?.[0]?.navigation_menu?.menu_title || ''
    let subMenus =
      response?.navbar_component?.[0]?.navigation_menu?.menu_contents
    let ourImpactDataModel = {
      properties: {},
      parentId: 'top',
      semantics: [],
      subCategories: [],
      milestone: [],
      identifier: '',
      isFromContentStack: true,
      isDummyCategory: true,
    }
    ourImpactDataModel['properties']['name'] =
      response?.navbar_component?.[0]?.navigation_menu?.menu_title || ''
    ourImpactDataModel['properties']['identifier'] =
      response?.navbar_component?.[0]?.navigation_menu?.menu_title || ''
    ourImpactDataModel['identifier'] =
      response?.navbar_component?.[0]?.navigation_menu?.menu_title || ''

    if (subMenus) {
      subMenus?.forEach(menu => {
        let subCateMainObj = {
          properties: {},
          parentId: '',
          semantics: [],
          subCategories: [],
          milestone: [],
          identifier: '',
        }
        subCateMainObj['parentId'] = menu?.links_with_title?.links_title
        subCateMainObj['properties']['name'] =
          menu?.links_with_title?.links_title

        if (menu?.links_with_title?.links.length > 0) {
          menu?.links_with_title?.links?.forEach(link => {
            let subCateObj = {
              properties: {},
              parentId: '',
              semantics: [],
              milestone: [],
              identifier: '',
              isFromOurImpact: true,
            }

            subCateObj['properties']['name'] = link?.title
            subCateObj['properties']['href'] = link?.href
            subCateObj['identifier'] = menu?.links_with_title?.links_title
            subCateObj['parentId'] = menu?.links_with_title?.links_title

            subCateMainObj['subCategories'].push(subCateObj)
          })
        }
        ourImpactDataModel['subCategories'].push(subCateMainObj)
      })
    }
    return ourImpactDataModel
  }

  /**
   * Fetches catalog data for a category.
   *
   * Accepts category ID, query params, context info, etc. as props.
   * Constructs request params based on props.
   * Handles request differently for SSR vs browser.
   * For browser, caches response to avoid duplicate requests.
   * Transforms response before returning.
   */
  getCatalog = async props => {
    const {
      categoryId,
      queryParams = {},
      size = PLPProductsPerPage,
      contextName,
      page,
      contextUrlPathFromSSR = '',
      contextQueryParamsFromSSR = '',
    } = props
    const queryParam = {
      ...queryParams,
      size,
      ...(page && { page }),
    }
    const loadParams = {
      endPointName: 'getCatalog',
      pathParams: `${categoryId}/products`,
      queryParams: queryParam,
      contextName,
      contextUrlPathFromSSR,
      contextQueryParamsFromSSR,
      curLocaleFromUrl: props.context?.curLocaleFromUrl,
      sessionCookies: props.context?.sessionCookies,
      headers: this.getFastlyHeaders(),
    }
    const url = props.location.pathname + props.location.search
    let response = {}
    // For SSR
    if (!IS_BROWSER && props.context && !props.context.data) {
      props.context.load(this.fetchResponse(loadParams))
      return
    } else if (IS_BROWSER) {
      if (this.catalogResponse.url === url) {
        response = this.catalogResponse.data
        this.shouldShowPlaceholder = false
      } else {
        this.shouldShowPlaceholder = true
        response = await this.fetchResponse(loadParams)
        this.shouldShowPlaceholder = false
      }
    }
    this.catalogResponse = {
      url,
      data: this.transformCatalog(response, queryParam),
    }
    trackEnhancedProductImpression(response)
    return this.transformCatalog(response, queryParam)
  }
  /**
   * Transforms the API response into the catalog data structure needed by the UI.
   *
   * Checks if the API response is successful, and if so, uses the response transformer
   * to convert the raw API data into the normalized catalog format.
   *
   * @param {Object} response - The raw API response object
   * @param {Object} queryParam - The query parameters for the API request
   * @returns {Object} The transformed catalog data object if successful, otherwise the original response
   */
  transformCatalog = (response, queryParam) => {
    if (this.isSuccessResponse(response)) {
      const catalogResponse = builder.transformCatalog(response, queryParam)
      return catalogResponse
    }
    return response
  }
  /**
   * Fetches search results from the API.
   *
   * Accepts search query parameters and other context like locale and cookies.
   * Constructs the API request params and headers.
   * Handles request differently for SSR vs browser.
   * For SSR, tells the context to load the response.
   * In browser, checks if response is cached before making API call.
   * Shows loading placeholder state.
   * Transforms and caches response.
   * Returns final transformed search results response.
   */
  getSearchProducts = async props => {
    const {
      queryParams,
      contextName,
      size = PLPProductsPerPage,
      contextUrlPathFromSSR = '',
      contextQueryParamsFromSSR = '',
      filterValue,
    } = props
    const queryParam = queryParams
    if (filterValue) {
      queryParam.filter = JSON.stringify(filterValue)
    }
    const loadParams = {
      endPointName: 'search',
      queryParams: { size, ...queryParam },
      contextName,
      contextUrlPathFromSSR,
      contextQueryParamsFromSSR,
      curLocaleFromUrl: props.context?.curLocaleFromUrl,
      sessionCookies: props.context?.sessionCookies,
      headers: this.getFastlyHeaders(),
    }
    let response = {}
    if (!IS_BROWSER && props.context && !props.context.data) {
      props.context.load(this.fetchResponse(loadParams))
      this.shouldShowPlaceholder = false
      return
    } else if (IS_BROWSER) {
      this.shouldShowPlaceholder = true
      response = await this.fetchResponse(loadParams)
      this.shouldShowPlaceholder = false
    }
    this.searchSuggestionsResponse = response
    return response
  }
  /**
   * Constructs a filter string to search for multiple product IDs in Elasticsearch.
   * Joins the provided array of IDs into an OR query across productId and skuId fields.
   * Escapes the string and returns it for use in a filter parameter.
   */
  constructAwsFilterParam = (ids = []) => {
    // '\\''index_key_productId="01001782" OR index_key_skuId="01001782" OR index_key_productId="01001964" OR index_key_skuId="01001964" OR index_key_productId="01110258" OR index_key_skuId="01110258"'\''
    let filterString = ''
    ids?.forEach((id, index) => {
      filterString += `index_key_productId="${id}" OR index_key_skuId="${id}" OR `
    })
    // remove the last OR
    filterString = filterString?.substring(0, filterString?.length - 4)
    filterString = `\\''${filterString}'\\''`
    // console.log('filter', filterString)
    return filterString
  }

  /**
   * Retrieves product info for a list of product IDs.
   * Accepts props containing the product IDs, item type,
   * Constructs a filter string for the search API based on the inputs.
   * Fetches the search response and transforms the result.
   * Returns the search response containing product info.
   */
  getProductsInfo = async props => {
    const {
      itemIds,
      itemType = 'productId',
      isFromAwsRecommendations = false,
      limit = 10,
      page = 1,
    } = props || {}

    let filterAsString = ''
    if (isFromAwsRecommendations) {
      filterAsString = this.constructAwsFilterParam(itemIds)
    } else {
      let filterParams = [
        `{"field":"index_key_${itemType}","operation":"IN","value":"[${itemIds}]"}`,
      ]
      filterAsString = `{"filters":[${filterParams}]}`
    }
    const loadParams = {
      endPointName: 'search',
    }
    if (filterAsString) {
      loadParams.queryParams = { filter: filterAsString, size: limit, page }
    }
    let response = {}
    if (IS_BROWSER) {
      this.shouldShowMPPPlaceholder = true
      response = await this.fetchResponse(loadParams)
      response = builder.transformSearchedProducts(response)
      this.searchProductResponse = response && response['product']
      this.shouldShowMPPPlaceholder = false
    }
    return response
  }

  /**
   * Retrieves product data for a given product ID.
   *
   * Accepts props containing the product ID, category ID, and context.
   * Constructs API request parameters based on the inputs.
   * Fetches product data from the API.
   * Transforms the response into a product object.
   * Returns the product data.
   */
  getProduct = async props => {
    const { productId, categoryId, contextName } = props
    const isRegisterUser = customerContainer.isRegisterUser
    let profileResponse = customerContainer.profileResponse
    const price_accountTypes = storeContainer.priceAccountTypes
    /**
     * @note
     * Commenting redundant calls for refactoring
     */

    let accounts = profileResponse?.accounts || {}
    let accountType = accounts?.type || ''

    const loadParams = {
      endPointName: 'getProducts',
      pathParams: `${productId}`,
      queryParams: { categoryId: categoryId },
      contextName,
      curLocaleFromUrl: props.context?.curLocaleFromUrl,
      sessionCookies: props.context?.sessionCookies,
    }

    if (accountType && price_accountTypes.includes(accountType)) {
      loadParams.queryParams.priceParam = JSON.stringify({
        [storeContainer.accountKey]: accountType,
      })
    }

    let response = {}
    if (!IS_BROWSER && props.context && !props.context.data) {
      // @todo enable it after store config
      // this.getProductFromSolr(props)
      props.context.load(this.fetchResponse(loadParams))
      return
    } else if (IS_BROWSER) {
      response = await this.fetchResponse(loadParams)
    }
    /* for CSR*/
    if (this.replacementProductClick) {
      response.replacementProductClick = true
    }
    /* for CSR*/
    trackEnhancedProductDetailImpression(response)
    this.replacementProductClick = false
    return this.transformProduct(response, {
      curLocaleFromUrl: props?.context?.curLocaleFromUrl,
      ...props,
    })
  }
  /**
   * Fetches product data from Solr based on the product ID.
   *
   * Accepts props containing the product ID.
   * Constructs API request parameters to query Solr for the product.
   * Fetches product data from Solr.
   * Returns the Solr response.
   */
  getProductFromSolr = async props => {
    const { productId } = props
    if (productId) {
      const loadParams = {
        endPointName: 'productFromSolr',
        queryParams: { q: `index_key_semanticId%3A${productId}` },
        contextName: 'solrProduct',
        curLocaleFromUrl: props.context?.curLocaleFromUrl,
      }
      if (!IS_BROWSER && props.context && !props.context.data) {
        props.context.load(this.fetchResponse(loadParams))
      }
    }
  }
  /**
   * Transforms the product response from the API.
   *
   * Accepts the API response and component props.
   * Checks if the response is successful.
   * If successful, transforms the product using the builder.
   * Returns the transformed product response or original response.
   */
  transformProduct = (response, props) => {
    if (this.isSuccessResponse(response)) {
      return builder.transformProduct(response, props)
    }
    return response
  }
  /**
   * Fetches inventory data for a SKU from the API.
   *
   * Accepts the SKU ID.
   * Constructs the API request parameters to get inventory for the SKU.
   * Calls fetchResponse() to make the API request.
   * Returns the API response.
   */
  getInventoryData = async skuId => {
    const loadParams = {
      endPointName: 'getInventory',
      pathParams: `${skuId}`,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Fetches search suggestion results from the API.
   *
   * Accepts the search term, page number, and page size.
   * Constructs the API request parameters.
   * Calls fetchResponse() to make the API request.
   * Returns the API response.
   */
  getSearchSuggestions = async (searchTerm, page, size) => {
    const loadParams = {
      endPointName: 'suggestions',
      queryParams: {
        /*
         * https://nuskin.atlassian.net/browse/CX26-1606
         * commenting encode here as suggestion is not working with encode
         * while making any changes to search, please check for both search and search suggestion
         *
         * unit test case terms:
         *
         * Tru Face Revealing Gel Bundle US
         * ageLOC Gentle Cleanse & Tone
         * Nutricentials® Bioadaptive Skin Care™ Clear & Balanced Kit
         * ageLOC® Youth (2 pack)
         * ageLOC® Youth, Lifepak® & Vitality Subscription Package
         * ageLOC® Youth + Nano + g3 Subscription
         * Beauty Bulk Kit #2
         * LifePak® / Estera® Phase II Subscription Package
         */

        // searchTerm: encodeURIComponent(searchTerm),
        searchTerm: searchTerm,
        page: page,
        size: size,
      },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Parses a semantics mapper string to extract the semantic URL for the given locale.
   *
   * Accepts a semantics mapper string and a default value.
   * Tries to parse the mapper string as JSON.
   * Extracts the semantic ID array for the given locale.
   * Returns either the last semantic URL in the array,
   * the string value if not an array, or the default value.
   */
  getSemanticsMapper = (semanticsMapper, defaultValue) => {
    let semanticURL = ''
    const locale = ''
    if (semanticsMapper === '') {
      return defaultValue
    } else {
      let semanticsMapperObj = {}
      try {
        semanticsMapperObj = JSON.parse(semanticsMapper)
      } catch (e) {
        return false
      }
      const semanticIdArr = semanticsMapperObj[locale]
      if (isArray(semanticIdArr)) {
        semanticURL = semanticIdArr[semanticIdArr.length - 1]
      } else if (semanticIdArr === 'string') {
        semanticURL = semanticIdArr
      }
      return semanticURL
    }
  }

  /**
   * Transforms an array of product recommendation objects
   * into a normalized form for use in the application.
   *
   * Maps over the input array, extracting the ProductId, Price
   * and remaining properties into a new object shape.
   *
   * @param {Object[]} products - The product recommendations array
   * @returns {Object[]} The transformed product recommendations
   */
  transformRecommendedProduct = (products = []) => {
    return products.map(product => {
      const { ProductId, Price, ...remainingProps } = product
      return {
        identifier: ProductId,
        properties: remainingProps,
        prices: [{ price: Price }],
      }
    })
  }

  /**
   * Fetches cross-sell product recommendations from a remote API based on the provided product ID.
   *
   * Encodes the product ID into the request URL. Sends a GET request to the recommendations API.
   * Transforms the response into a normalized array of products.
   *
   * @async
   * @param {string} productId - The ID of the product to fetch recommendations for.
   * @returns {Promise<Object[]>} A promise resolving to the array of recommended product objects.
   */
  getCrossSellProducts = async productId => {
    const skuIds = encodeURIComponent(JSON.stringify([productId]))
    let requestOptions = {
      method: 'GET',
    }
    const url =
      'https://us-central1-db-inspiredstylist.cloudfunctions.net/Get_BestBets_closet'

    try {
      const response = await fetch(
        `${url}?CustomerId=${customerContainer.profileResponse.id}&SkuIds=${skuIds}&Recommendations=4`,
        requestOptions
      )
        .then(result => result.json())
        .then(result => {
          return result
        })
      return this.transformRecommendedProduct(response.SkuRecos)
    } catch (e) {
      return []
    }
  }

  /**
   * Gets the current market locale code from the browser URL.
   *
   * Checks if in browser and gets locale code from URL,
   * otherwise defaults to 'US'. Splits on '_' and returns second part.
   *
   * @returns {String} The current market locale code
   */
  getCurrentMarket = () => {
    let locale = 'US'
    if (IS_BROWSER) {
      locale = getLocaleCodeFromUrl()
      locale = locale?.split('_')?.[1]
    }
    return locale
  }

  /**
   * Retrieves the Okta access token from local storage
   * if in the browser, otherwise warns that it's unavailable.
   *
   * Constructs and returns the authorization header with
   * the access token.
   */
  getBearerToken = () => {
    let oktaAccessToken = ''
    if (IS_BROWSER) {
      oktaAccessToken = localStorage.getItem('accessToken')
    } else {
      console.warn('oktaAccessToken is not available in browser')
    }

    return {
      Authorization: `Bearer ${oktaAccessToken}`,
    }
  }

  /**
   *   /**
   * Retrieves product recommendations from AWS based on a given SKU.
   * Fetches a list of products commonly viewed with the specified SKU.
   * Returns product info for recommended products, sorted based on original order from AWS.
   * Limits number of returned products based on given limit.
   *
   * @description This asynchronous function retrieves product recommendations from AWS (Amazon Web Services) based on the "customer" behavior.
   * It fetches a list of products that are commonly viewed together with a specified SKU (Stock Keeping Unit) and returns information about those products.
   *
   * @date 2023/8/18 - 16:29:39
   *
   * @async
   * @function
   * @param { Object } props
   * @param { 'myRecommendedProducts' | 'getAwsMostPopularProducts' | 'productsAlsoViewed' }
   *[props.recommendationType='myRecommendedProducts'] - The type of recommendations to fetch.
   * This parameter is optional and defaults to fetching my recommended products.
   * @param { String } props.skuId - Sku id for which you want to get also viewed products
   * @param { Number } [props.limit=20] - Number of recommended products to return
   * @param { limitItemsAfterFilter } - Number of items to be returned after filtering
   * @returns { Object }
   */

  getAWSRecommendations = async props => {
    const {
      limit = 15,
      skuId,
      recommendationType = 'myRecommendedProducts',
      limitItemsAfterFilter,
    } = props || {}

    let response = {}
    const market = this.getCurrentMarket()

    const pathParams = {
      myRecommendedProducts: `${market}/myRecommendedProducts`,
      getAwsMostPopularProducts: `${market}/productsMostViewed`,
      productsAlsoViewed: `${market}/product/${skuId}/productsAlsoViewed`,
    }

    const loadParams = {
      endPointName: 'getAwsRecommendedProducts',
      queryParams: {
        limit,
      },
      credentials: 'omit',
      pathParams: pathParams[recommendationType],
      headers: this.getBearerToken(),
    }
    const awsRecommendations = await this.fetchResponse(loadParams)

    if (awsRecommendations?.productIds) {
      response = await this.getProductsInfo({
        itemIds: awsRecommendations?.productIds,
        itemType: 'skuId',
        isFromAwsRecommendations: true,
        limit,
      })

      if (response?.product) {
        response.product = builder.sortRecommendedItems({
          products: response?.product,
          productIds: awsRecommendations?.productIds,
        })

        if (limitItemsAfterFilter) {
          response.product = response.product?.slice(0, limitItemsAfterFilter)
        }
      }

      response['awsRecommendations'] = awsRecommendations
    }

    return response
  }
}

const catalogContainer = new CatalogContainer()

export { catalogContainer, CatalogContainer }
export default catalogContainer
