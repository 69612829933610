import { i18nTranslate } from 'src/utils'
import { observable } from 'mobx'
import {
  CommonContainer,
  customerContainer,
  storeContainer,
  taxIDContainer,
  userContainer,
  checkoutContainer,
} from 'src/models'
import { toastState, modalState, overlayState } from 'src/views/components'
import {
  getLocalStorage,
  isB2B2C,
  getCountryCode,
  trackGTMEvents,
  convertToBoolean,
  getUTMInfo,
} from 'src/utils'
import { getFraudDetectionProperties } from 'src/utils/signUpUtils'
import {
  APPConfig,
  consentIpAddress,
  getSessionStorage,
  IS_BROWSER,
} from 'config/appConfig'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import dayjs from 'dayjs'

/**
 * AccountsContainer class extends CommonContainer.
 * This appears to be a model class for managing account state and data.
 */
class AccountsContainer extends CommonContainer {
  @observable isRegisterUser = false
  @observable profileResponse = {}
  @observable paymentResponse = []
  @observable activeContract = {}
  @observable isActive = false
  @observable costId = ''
  @observable contractsResponse = []
  @observable contractIdResponse = []
  @observable buyerRoles = []
  @observable accountSettings = {}
  @observable accountTypes = {}
  @observable nexGenAccountTypes = {}
  @observable accountAttributes = {}
  @observable accountAttributesWithoutSize = {}
  @observable tempUser = false
  @observable selectedAccountType = ''
  @observable selectedMarket = storeContainer.defaultShippingRegion
  @observable selectedtaxIDOption = 'businessTaxIdDropdown'
  @observable selectedtaxIdPlaceHolder = 'businessTaxIdDropdownPlaceHolder'
  @observable maxLengthOption = 11
  @observable ssnType = 'Social Security Number'
  @observable duplicateEmailExist = false
  @observable duplicateTaxIdExist = false
  @observable phoneNumberIsValid = null
  @observable countryDropdownValue = 'US'
  @observable onSubmitInValid = false
  @observable submitCountValid = false
  @observable sponsorValidationFalse = false
  @observable isDateOfBirthInValid = false
  @observable originValueInEvent = 'Web'
  @observable convertAccoutType = ''
  @observable IronCladGroupId = ''
  @observable accountsContractCheck = false
  @observable accountsTermCheck = false
  @observable acceptanceContractData1 = ''
  @observable acceptanceContractData2 = ''
  @observable acceptanceContractData3 = ''
  @observable isSaveChangesBtnClicked = false
  @observable signUpIronCladCheck = {
    clickWrap1: false,
    clickWrap2: false,
    clickWrap3: false,
  }
  isSessionExpired = false

  constructor(props) {
    super(props)
  }

  /**
   * Converts a list of enum values into a string by extracting the values,
   * converting to an array, and joining into a string.
   *
   * This appears to be a utility function used internally to format enum
   * values for API requests or display.
   */
  getListValue = list => {
    const value = Object.values(Object.assign({}, ...list))
    return value.toString()
  }

  /**
   * Extracts account attribute values from a properties object.
   * Loops through the account attributes configuration, checks if required attributes exist in the properties,
   * gets the attribute value, converts to string if needed, and adds to the accountAttributeList array to return.
   */
  getProperties = properties => {
    const attributes = this.accountAttributesWithoutSize?.attributes || []
    const accountAttributeList = []
    attributes.forEach(list => {
      const { identifier, fieldtype, required, status } = list
      const isRequiredType =
        properties.length > 0
          ? Object.keys(properties[0]).includes(list.identifier) &&
            required === true &&
            status.toLowerCase() === 'active'
          : false

      if (isRequiredType) {
        const attributeValue = properties[0][list.identifier]
        const value =
          fieldtype.toLowerCase() === 'enummultiselect'
            ? this.getListValue(attributeValue)
            : attributeValue

        const valueToString =
          fieldtype.toLowerCase() === 'date'
            ? value
            : value !== undefined
            ? value.toString()
            : ''

        accountAttributeList.push({
          attributeId: identifier,
          value: valueToString,
        })
      }
    })

    return accountAttributeList
  }

  /**
   * Constructs the post data for creating a new account.
   *
   * Takes in the sign up details entered by the user and returns an object
   * with the data formatted for the API request to create the account.
   * Return customer data, account data, and loyalty data.
   */
  constructPostData = signUpDetails => {
    /** @note- As per BA comments - Here password set as static
     * because using same pwd for both
     * account creation and user activation
     * gives password frequently used error
     */
    const {
      addressUserName,
      email,
      phone,
      city,
      country,
      duns,
      organizationName,
      stateList,
      taxId,
      zip,
      industry,
      industrySize,
      addressLine1,
      addressLine2,
      properties,
      password,
      lastName,
      //securityQuestions,
      loyaltyOpt,
    } = signUpDetails

    const Addressobj = isB2B2C()
      ? null
      : [
          {
            firstName: addressUserName,
            city,
            state: stateList,
            zip,
            country,
            phone,
            //email,
            addressLine1,
            addressLine2,
            //validated: false,
            isDefault: true,
            type: 'Local',
          },
        ]
    return {
      customer: {
        firstName: addressUserName,
        lastName,
        phoneNumber: phone,
        isGuestCustomer: false,
        email,
        credentials: {
          password: password ? password : 'Skavab2b@123',
          //securityQuestions,
        },
      },
      account: {
        name: organizationName ? organizationName : addressUserName,
        type: industry,
        size: industrySize ? industrySize : '<500',
        phoneNumber: phone,
        taxNumber: taxId ? taxId : '121212',
        dunsNumber: duns ? duns : '',
        properties: this.getProperties(properties),
        addresses: Addressobj,
      },
      loyalty: {
        optIn: loyaltyOpt,
        rewardType: 'LOYALTY',
      },
    }
  }
  /*old register */
  /**
   * Registers a new user account asynchronously based on the provided signUpDetails.
   * Constructs the account post data, makes a request to create the account, handles the response,
   * activates the user if needed, and displays success/error messages.
   *
   * @param {object} signUpDetails - Object containing user-entered sign up details.
   * @returns {object} Response from the account creation API request.
   */
  register = async signUpDetails => {
    const postData = this.constructPostData(signUpDetails)
    const password = signUpDetails?.password || ''
    const loadParams = {
      endPointName: 'accounts',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      if (!isB2B2C()) {
        this.isRegisterUser = true
        const activationParam = response?.userActivationParam || ''
        const postBody = {
          activationParam,
          password,
        }
        const userResponse = customerContainer.userActivation(postBody)
        if (this.isSuccessResponse(userResponse)) {
          toastState.setToastMessage(
            i18nTranslate(
              'accountUserActivation.success',
              'Your email has been verified. Use it to log in next time.'
            ),
            true
          )
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'accountUserActivation.failure',
              'Sorry, your email address could not be verified. Please try again.'
            ),
            false
          )
        }
        modalState.setModalMessage(
          i18nTranslate(
            'signup.accountCreationSuccess',
            'Your account has been created and submitted for approval.'
          ),
          i18nTranslate('modaView.buttonText', 'OK')
        )
      } else {
        toastState.setToastMessage(
          i18nTranslate('signup.success', 'Your account has been created.'),
          true
        )
      }
    } else {
      const errorMessage =
        response.message ||
        i18nTranslate(
          'signup.failure',
          'Sorry, we are unable to create your account. Please try again.'
        )
      toastState.setToastMessage(errorMessage, false)
    }
    return response
  }
  constructPostDataOkta = signUpDetails => {
    /** @note- As per BA comments - Here password set as static
     * because using same pwd for both
     * account creation and user activation
     * gives password frequently used error
     */

    //preferred Language Dynamic Flow
    // const localValue = getLocalStorage('locale')
    let localValue = getLocaleCodeFromUrl() || ''
    localValue = localValue?.toLowerCase()?.split('_')

    let preferredDisplayLanguage

    switch (localValue?.[0]) {
      case 'en':
        preferredDisplayLanguage = 'English'
        break
      case 'es':
        preferredDisplayLanguage = 'Spanish'
        break
      case 'zh':
        preferredDisplayLanguage = 'Chinese'
        break
      case 'fr':
        preferredDisplayLanguage = 'French'
        break
      case 'vi':
        preferredDisplayLanguage = 'Vietnamese'
        break
      default:
        preferredDisplayLanguage = 'English'
        break
    }

    const {
      email,
      phone,
      password,
      lastName,
      firstName,
      accountType,
      dateOfBirth,
      sponsorId,
      signupTaxId,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      zip,
      signupRewards,
      //securityQuestions,
    } = signUpDetails
    const name = firstName + ' ' + lastName

    let tokenExTaxData = ''
    let dupCharTokenExTaxData = ''
    let localCountry = storeContainer.storeIDValue === 'Canada' ? 'CA' : 'US'
    let addressType =
      localCountry === 'US' || localCountry === 'CA' ? 'Local' : 'HOME'
    if (
      taxIDContainer &&
      taxIDContainer?.taxTokenExData?.token !== undefined &&
      taxIDContainer?.taxTokenExData?.success === 'true'
    ) {
      tokenExTaxData = taxIDContainer?.taxTokenExData?.token
      dupCharTokenExTaxData = taxIDContainer?.taxTokenExDupCharData?.token
    }
    const locale = APPConfig?.getActiveAppConfig()?.defaultLocale //en_US
    const language = locale?.split('_')?.pop().toLowerCase() // us

    const sponsorIdAttribute = {
      attributeId: 'sponsorId',
      value: sponsorId?.toUpperCase(),
    }

    const signUpOrigin = {
      attributeId: 'signupOrigin',
      value: this.originValueInEvent,
    }

    const taxIdAttribute = {
      attributeId: 'taxType',
      value:
        language === 'us'
          ? 'Social Security Number'
          : 'Social Insurance Number',
    }

    const taxNumUnformatted = {
      attributeId: 'taxNumberUnformatted',
      value: dupCharTokenExTaxData,
    }

    return {
      customer: {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phone,
        isGuestCustomer: false,
        dateOfBirth: dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : '',
        email: email?.toLowerCase(),
        customProperties: {
          preferredLanguage: preferredDisplayLanguage,
        },
        credentials: {
          password: password ? password : 'Skavab2b@123',
          // SECTEM-8232
          // securityQuestions: [
          //   {
          //     question: 'In what city or town were you born?',
          //     answer: 'Pune',
          //   },
          // ],
        },
      },
      account: {
        name,
        type: accountType,
        size: '<500',
        phoneNumber: phone,
        taxNumber: accountType === 'Brand Affiliate' ? tokenExTaxData : '',
        dunsNumber: '',
        properties:
          accountType === 'Brand Affiliate'
            ? [
                sponsorIdAttribute,
                taxIdAttribute,
                signUpOrigin,
                taxNumUnformatted,
              ]
            : sponsorId !== ''
            ? [sponsorIdAttribute, signUpOrigin]
            : [signUpOrigin],
        addresses:
          accountType === 'Retail Customer' ||
          accountType === 'Preferred Customer/Member'
            ? null
            : [
                {
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  addressLine1: addressLine1,
                  addressLine2: addressLine2,
                  city: city,
                  state: state,
                  county: '',
                  phone: phone,
                  country: getCountryCode({
                    countryCode: storeContainer.storeIDValue,
                  }),
                  zip: postalCode ? postalCode.toUpperCase() : zip,
                  type: addressType,
                },
              ],
      },
      loyalty: { optIn: signupRewards, rewardType: 'LOYALTY' },
    }
  }
  /*okta register */
  registerOkta = async signUpDetails => {
    overlayState.toggleLoader()
    let postData = this.constructPostDataOkta(signUpDetails)
    const {
      marketingEmail,
      signupSms,
      signupSmsBrand,
      termsOfUse,
      signupRewards,
      signupBrandRepresentative,
      refundAndPrivacy,
      refundAndPrivacyBrand,
      termsOfUseBrand,
      accountType,
      sponsorId,
      acceptancePayload = '',
    } = signUpDetails

    const { consents } = await customerContainer.signupConsents()
    const otherInfo = {
      ipAddress: consentIpAddress,
      geoLocation:
        storeContainer.currentRegion || getLocalStorage('currentRegion') || '',
      browser: 'chrome',
      device: 'mobile',
    }
    const isToEnableIronClad =
      APPConfig?.getAppConfig()?.FEATURE_IRONCLAD_IMPLEMENTATION === 'true' ||
      false
    const signupFraudCheckEnabled =
      APPConfig?.getAppConfig()?.SignupFraudCheckEnabled === 'true' || ''
    const signUpConsents = []
    const acceptanceInfoPage1 = {
      ...otherInfo,
      id: this.IronCladGroupId, // to be modified accordingly
      title: 'contracts_ironclad_page1',
      acceptanceData: acceptancePayload?.[0],
    }
    const acceptanceInfoPage2 = {
      ...otherInfo,
      id: this.IronCladGroupId, // to be modified accordingly
      title: 'contracts_ironclad_page2',
      acceptanceData: acceptancePayload?.[1],
    }
    const acceptanceInfoPage3 = {
      ...otherInfo,
      id: this.IronCladGroupId, // to be modified accordingly
      title: 'contracts_ironclad_page3',
      acceptanceData: acceptancePayload?.[2],
    }

    const enableJitSuIroncladFeature = convertToBoolean(
      APPConfig?.getAppConfig()?.enableJitSuIroncladFeature
    )

    isToEnableIronClad && accountType === 'Brand Affiliate'
      ? signUpConsents.push(
          acceptanceInfoPage1,
          acceptanceInfoPage2,
          acceptanceInfoPage3
        )
      : isToEnableIronClad &&
        enableJitSuIroncladFeature &&
        accountType === 'Preferred Customer/Member'
      ? signUpConsents.push(acceptanceInfoPage1, acceptanceInfoPage2)
      : isToEnableIronClad && accountType !== 'Brand Affiliate'
      ? signUpConsents.push(acceptanceInfoPage1)
      : ''
    let consentInfo
    consents?.forEach(consent => {
      const Id = consent.id
      //1.
      if (
        refundAndPrivacyBrand &&
        consent.title === 'SALES_COMPENSATION_SUMMARY'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (
        refundAndPrivacyBrand &&
        consent.title === 'I acknowledge I have read Sales performance'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (
        (refundAndPrivacy || refundAndPrivacyBrand) &&
        consent.title === 'REFUND_POLICY'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (
        (refundAndPrivacy || refundAndPrivacyBrand) &&
        consent.title === 'PRIVACY_NOTICE'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      }

      //2. Terms of Use
      if (
        (termsOfUse || termsOfUseBrand) &&
        consent.title === 'Terms & Conditions'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (
        termsOfUseBrand &&
        consent.title === 'I have Read, Understand, Agree to BA agreement'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (termsOfUseBrand && consent.title === 'POLICY_PROCEDURES') {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      }

      //3.Opt in to Marketing Email
      if (marketingEmail && consent.title === 'promotional_optIn_email') {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (
        (!marketingEmail || marketingEmail === undefined) &&
        consent.title === 'promotional_optOut_email'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      }

      //4.Opt in to SMS
      if (
        (signupSms || signupSmsBrand) &&
        consent.title === 'promotional_optIn_SMS'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (
        (!signupSms || signupSms === undefined) &&
        consent.title === 'promotional_optOut_SMS'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      }

      //5.Opt in to NuSkin Rewards

      if (signupRewards && consent.title === 'webSignup_T&C') {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      }

      if (signupRewards && consent.title === 'loyalty_optIn') {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (
        (!signupRewards || signupRewards === undefined) &&
        consent.title === 'loyalty_optOut'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      }
      if (
        (!signupBrandRepresentative ||
          signupBrandRepresentative === undefined) &&
        (accountType === 'Retail Customer' ||
          accountType === 'Preferred Customer/Member') &&
        sponsorId === '' &&
        consent.title === 'privacy_contactinfo&name~2'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (
        signupBrandRepresentative &&
        sponsorId === '' &&
        consent.title === 'privacy_noprivacy~0'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      } else if (
        (!signupBrandRepresentative ||
          signupBrandRepresentative === undefined) &&
        sponsorId !== '' &&
        consent.title === 'privacy_noprivacy~0'
      ) {
        consentInfo = { ...consent, ...otherInfo, consentId: Id }
        signUpConsents.push(consentInfo)
      }
    })
    const accertifyProps = (await getFraudDetectionProperties()) || {}
    postData.consents = signUpConsents
    const password = signUpDetails?.password || ''
    if (signupFraudCheckEnabled) {
      postData.customer.customProperties = {
        ...postData.customer.customProperties,
        ...accertifyProps,
      }
    }
    let loadParams = {
      endPointName: 'registerNextGen',
      postData,
    }

    if (userContainer.associateActivation !== '') {
      const activeParam = userContainer.associateActivation
      const customProperties = {
        taxNumber: postData?.account?.taxNumber || '',
      }
      const {
        phoneNumber = '',
        firstName = '',
        lastName = '',
        email = '',
        dateOfBirth = '',
        credentials = {},
      } = postData?.customer
      const { addresses = [] } = postData?.account
      delete postData?.customer
      delete postData?.account
      delete postData?.loyalty
      postData = {
        activeParam,
        ...postData,
        customProperties,
        phoneNumber,
        firstName,
        lastName,
        dateOfBirth,
        email,
        address: addresses?.[0] || {},
      }
      loadParams = {
        endPointName: 'patchAssociateInviteLink',
        postData,
      }
    }

    const response = await this.fetchResponse(loadParams)
    if (
      this.isSuccessResponse(response) &&
      userContainer.associateActivation === ''
    ) {
      if (!isB2B2C()) {
        this.isRegisterUser = true
        const activationParam = response?.userActivationParam || ''
        const postBody = {
          activationParam,
          password,
        }
        const userResponse = customerContainer.userActivation(postBody)
        if (this.isSuccessResponse(userResponse)) {
          toastState.setToastMessage(
            i18nTranslate(
              'accountUserActivation.success',
              'Your email has been verified. Use it to log in next time.'
            ),
            true
          )
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'accountUserActivation.failure',
              'Sorry, your email address could not be verified. Please try again.'
            ),
            false
          )
        }
        modalState.setModalMessage(
          i18nTranslate(
            'signup.accountCreationSuccess',
            'Your account has been created and submitted for approval'
          ),
          i18nTranslate('modaView.buttonText', 'OK')
        )
      } else {
        trackGTMEvents({
          event: 'Signup',
          eventType: 'Simplified signup event',
        })
        toastState.setToastMessage(
          i18nTranslate('signup.success', 'Your account has been created.'),
          true
        )
      }
    } else if (userContainer.associateActivation === '') {
      overlayState.toggleLoader()
      const responseCode = response?.code
      const errorMessage =
        responseCode === 'EOACOKTA001'
          ? i18nTranslate(
              'signup.accountCreationFailure',
              'Something went wrong'
            )
          : response.message ||
            i18nTranslate(
              'signup.failure',
              'Sorry, we are unable to create your account. Please try again.'
            )

      toastState.setToastMessage(errorMessage, false)

      this.duplicateEmailExist =
        responseCode === 'EOUS0004' || responseCode === 'EOACOKTA001'
          ? true
          : false

      this.duplicateTaxIdExist =
        responseCode === 'EOACSU000015' || responseCode === 'NXUSER_231'
          ? true
          : false

      if (this.duplicateEmailExist) {
        let domScrollElement = document?.querySelector('[name="email"]')
        domScrollElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      } else if (this.duplicateTaxIdExist) {
        let domScrollElement = document?.querySelector('[name="signupTaxId"]')
        domScrollElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    } else if (userContainer.associateActivation != '') {
      const responseCode = response?.code

      this.duplicateTaxIdExist =
        responseCode === 'EOACSU000015' || responseCode === 'NXUSER_231'
          ? true
          : false
      if (this.duplicateTaxIdExist) {
        let domScrollElement = document?.querySelector('[name="signupTaxId"]')
        domScrollElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
    return response
  }
  /**
   * Fetches the account profile for the given account ID.
   * Makes an API call to the accountProfile endpoint.
   * Checks if the response is successful.
   * If successful, extracts some properties from the response to set class properties.
   * Returns the API response.
   */
  getProfile = async accountId => {
    if (customerContainer.isRegisterUser) {
      const loadParams = {
        endPointName: 'accountProfile',
        pathParams: accountId,
      }
      const response = await this.fetchResponse(loadParams)
      if (this.isSuccessResponse(response)) {
        this.profileResponse = response
        const tar = this.profileResponse.properties
        const personType =
          this.profileResponse?.customer?.customProperties?.personType?.toLowerCase() ||
          ''
        const spid =
          tar && tar.find(list => list.attributeId === 'signUpStatus')

        const status = spid?.value?.toLowerCase() || ''

        this.tempUser = status === 'temp' && personType === 'primary'
        const params =
          IS_BROWSER && new URLSearchParams(window?.location?.search)
        const customerType = params?.get('type')?.toLowerCase()
        if (customerType === 'member') {
          this.selectedAccountType = 'Preferred Customer/Member'
        } else if (customerType === 'brand_affiliate') {
          this.selectedAccountType = 'Brand Affiliate'
        }
      }
      return response
    }
    return ''
  }

  /**
   * Constructs a filter string to be used in the API request
   * based on the provided filter parameters.
   *
   * Checks for searchContract, status filter values and constructs
   * the filter string accordingly.
   *
   * Returns the filter object with the constructed filter string
   * or an empty object if no filters are provided.
   */
  constructTeamFilter = (filterParams = {}) => {
    let filterString = ''
    if (filterParams.searchContract) {
      if (isNaN(filterParams.searchContract) === false) {
        filterString += `id IN "${filterParams.searchContract}"`
      } else {
        filterString += `name LIKE "%${filterParams.searchContract}%"`
      }
    }
    if (filterParams.status) {
      filterString += 'status:' + filterParams.status + ','
    }
    if (filterString) {
      return {
        filter: filterString,
      }
    }
    return {}
  }
  /**
   * Fetches contract details by ID for the authenticated account.
   *
   * @param {string} contractId - The ID of the contract to fetch.
   * @returns {Promise} Promise resolving to the contract response object.
   */
  getContractById = async contractid => {
    const accountId = await this.getAccountId()
    const contractId = contractid
    if (accountId !== '') {
      const loadParams = {
        endPointName: 'accountProfile',
        pathParams: `${accountId}/contract/${contractId}`,
      }
      const response = await this.fetchResponse(loadParams)

      if (this.isSuccessResponse(response)) {
        this.contractIdResponse = response
      } else {
        this.contractIdResponse = []
      }
      // this.activeContract = response.find(contract=> contract.status === 'active')

      return response
    }
  }
  /**
   * Fetches contracts for the authenticated account based on filter parameters.
   *
   * Accepts a filterParams object containing search/filter criteria. Constructs
   * API request parameters based on the filterParams, calls the API endpoint,
   * and returns the response. Handles pagination and constructing the filter string.
   *
   * On success, saves the response to contractsResponse and sets the first contract
   * as activeContract. On failure, clears contractsResponse and shows a toast
   * message if display is true.
   */
  getContracts = async filterParams => {
    const { display = true } = filterParams || {}
    const page = filterParams?.page || 1
    const accountId = await this.getAccountId()
    if (accountId !== '') {
      const filterProps = this.constructTeamFilter(filterParams)
      const params = {
        page: page,
        size: 5,
        ...filterProps,
      }
      const loadParams = {
        endPointName: 'accountProfile',
        pathParams: `${accountId}/contracts`,
        queryParams: params,
      }
      const response = await this.fetchResponse(loadParams)
      const isSuccessResponse = this.isSuccessResponse(response)
      if (isSuccessResponse) {
        this.contractsResponse = response
        // this.activeContract = response.find(contract=> contract.status === 'active')
        this.activeContract = response.contracts[0]
      } else {
        this.contractsResponse = []
        if (display) {
          toastState.setToastMessage(response.message)
        }
      }
      return response
    }
  }

  /**
   * Fetches all buyer roles for the authenticated account.
   *
   * Constructs API request parameters, calls the getAllBuyerRoles endpoint,
   * and returns the response containing the buyer roles array.
   */
  getAllBuyerRoles = async () => {
    const loadParams = {
      endPointName: 'getAllBuyerRoles',
      pathParams: `${customerContainer.accountId}/buyers/roles`,
    }
    const response = await this.fetchResponse(loadParams)
    this.buyerRoles = response
    return response
  }

  /**
   * Updates the account information for the authenticated user.
   *
   * Takes in the updated account info. Gets the industry and account types from settings.
   * Constructs the properties and post data from the info.
   * Calls the API to update the account with the post data.
   * Shows success/error toasts based on the API response.
   * Refetches the profile on success.
   */
  updateAccountInfo = async info => {
    const { industry = '', industrySize = '', accountType } = info
    const { accountTypes = [] } = this.accountSettings
    const industryType =
      accountTypes && accountTypes.find(list => list.name === industry)
    const accType =
      accountTypes && accountTypes.find(list => list.name === accountType)
    const properties = this.getProperties([info])
    const postData = {
      typeId: isB2B2C() ? accType.id : industryType.id,
      size: industrySize,
      properties,
    }

    const accountId = customerContainer.accountId

    const params = {
      endPointName: 'updateAccountInfo',
      pathParams: accountId,
      postData,
    }
    const response = await this.fetchResponse(params)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'accountProfile.updateSuccess',
          'Your account has been updated.'
        ),
        true
      )
      await this.getProfile(accountId)
    } else {
      const message = response?.message || ''
      message
        ? toastState.setToastMessage(message)
        : toastState.setToastMessage(
            i18nTranslate(
              'accountProfile.updateFailure',
              'Sorry, we are unable to update your account. Please try again.'
            ),
            false
          )
    }
  }

  /**
   * Updates the sponsor account details by making an API call.
   *
   * @param {Object} updatedDetails - Object containing updated account properties and ID
   * @param {boolean} isFromConversion - Whether this call is from a conversion flow
   * @returns {Promise} - The API response
   */
  updateSponsorAccount = async (updatedDetails, isFromConversion = false) => {
    const { properties = [], accountId = '' } = updatedDetails
    const postData = { properties: [...properties] }
    const loadParams = {
      endPointName: 'sponsorUpdateAccount',
      pathParams: `${accountId}/properties`,
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    if (!isFromConversion) {
      if (this.isSuccessResponse(response)) {
        toastState?.setToastMessage(
          i18nTranslate('sponsorChange.changed', 'Sponsor changed'),
          true
        )
      } else {
        toastState?.setToastMessage(
          response?.message ||
            i18nTranslate(
              'updateSponsor.failure',
              'We are unable to change sponsor'
            ),
          false
        )
      }
    }
    return response
  }

  /**
   * Fetches the account settings by calling the API endpoint if the user is registered.
   */
  getAccountSettings = async () => {
    if (customerContainer.isRegisterUser) {
      const loadParams = {
        endPointName: 'getAccountSettings',
      }
      this.accountSettings = await this.fetchResponse(loadParams)
    }
  }
  /**
   * Fetches available account types from the API.
   *
   * @returns {Promise} Promise resolving to account types array.
   */
  getAccountTypes = async () => {
    const loadParams = {
      endPointName: 'getAccountTypes',
    }
    this.accountTypes = await this.fetchResponse(loadParams)
  }
  /**
   * Fetches available next gen account types from the API.
   *
   * @returns {Promise} Promise resolving to next gen account types array.
   */
  getNexGenAccountTypes = async () => {
    const loadParams = {
      endPointName: 'nexGenAccountTypes',
    }
    this.nexGenAccountTypes = await this.fetchResponse(loadParams)
  }
  /**
   * Fetches all account attributes from the API.
   *
   * Sets the accountAttributes property with the response.
   */
  getAllAttributes = async () => {
    const loadParams = {
      endPointName: 'getAllAttributes',
      queryParams: {
        size: 20,
      },
    }
    this.accountAttributes = await this.fetchResponse(loadParams)
  }

  /**
   * Fetches all account attributes from the API without specifying the size query parameter.
   *
   * The size defaults to the total count of attributes returned in a previous request, or 20.
   *
   * Sets the accountAttributesWithoutSize property with the response.
   */
  getAllAttributesWithoutSize = async () => {
    const size = this.accountAttributes?.pageableInfo?.totalCount || 20
    const loadParams = {
      endPointName: 'getAllAttributes',
      queryParams: {
        size,
      },
    }
    this.accountAttributesWithoutSize = await this.fetchResponse(loadParams)
  }

  checkPhoneNumberUniqueness = async (phoneNumber, marketRegion) => {
    const getPostData = {
      query:
        '{checkPhoneNumberUniqueness(input: { phoneNumber: "' +
        phoneNumber +
        '", market: "' +
        marketRegion +
        '" }) {  isUnique }}',
    }
    const loadParams = {
      endPointName: 'checkPhoneNumberUniqueness',
      isToSendStoreId: false,
      postData: getPostData,
      headers: {
        'x-api-key': APPConfig?.getActiveAppConfig()?.xApiKeyForPhoneUniqueness,
      },
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  sendPhoneVerificationCode = async (phoneNumber, locale) => {
    const postData = {
      to: phoneNumber,
      locale: locale,
    }
    const loadParams = {
      endPointName: 'sendPhoneVerificationCode',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  checkPhoneVerificationCode = async (phoneNumber, code) => {
    const postData = {
      to: phoneNumber,
      code: code,
    }
    const loadParams = {
      endPointName: 'checkPhoneVerificationCode',
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  getAccountSponsorId = () => {
    const accProperties = this.profileResponse?.properties || {}
    const spId =
      accProperties.length > 0 &&
      accProperties.find(list => list?.attributeId === 'sponsorId')
    return spId?.value
  }
  getUserTypeSponsorId = () => {
    return customerContainer?.isRegisterUser
      ? accountsContainer?.getAccountSponsorId()
      : this.getGuestSponsorId()
  }

  /**
   * Retrieves the sponsor ID based on the UTM source or a default value.
   *
   * This function checks the UTM information stored in the session storage to determine the sponsor ID.
   * If a UTM source is present, it looks up the corresponding sponsor ID in the `inHouseSponsor` configuration.
   * If no UTM source is found, it returns the default sponsor ID of 'RETFLOAT'.
   *
   * @returns {string} The sponsor ID based on the UTM source or the default value.
   */
  getGuestSponsorId = () => {
    const utmInfo = getUTMInfo()
    const { inHouseSponsor } = APPConfig?.getAppConfig()
    let sponsorId = ''
    let vipCheckboxStatus = getSessionStorage('vipCheckboxStatus') || false
    if (vipCheckboxStatus) {
      sponsorId = 'RETFLOAT'
    } else if (utmInfo?.utm_medium) {
      sponsorId =
        inHouseSponsor[utmInfo?.utm_medium] || inHouseSponsor?.utmDefault
    } else {
      sponsorId = inHouseSponsor?.defaultSponsor
    }

    return sponsorId
  }
}

const accountsContainer = new AccountsContainer()

export { accountsContainer }
export default accountsContainer
