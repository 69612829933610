import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { Link } from 'react-router-dom'
import { Price, CustomPrice, ImageComponent } from 'src/views/components'
import { checkMysiteOrPersonalOffer, application } from 'src/utils'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import './styles.scss'

const Checkoutfreegifts = (props = {}) => {
  const renderProductDetail = (pdtProps = {}) => {
    let {
      productUrl = '',
      imageURL = '',
      productName = '',
      skuId = '',
      skuSlug = '',
      variantLabel = '',
      isNativeApp = false,
      quantity = 0,
      combineFreeGiftQuantity = 0,
      giftOutOfStockQty = 0,
      combineFreeGiftOOSQty = 0,
    } = pdtProps
    if (isNativeApp) {
      // Disabling product link for products in VERA & STELA app
      productUrl = '#'
    }
    const slugVariant = skuSlug || skuId || ''
    const slugVariantLabel = variantLabel || ''
    let navigateURL = slugVariantLabel
      ? `${productUrl}?variant=${slugVariant}`
      : `${productUrl}`
    const freeGiftQty = combineFreeGiftQuantity || quantity
    const freeGiftOOSQty = combineFreeGiftOOSQty || giftOutOfStockQty
    const availableQty = freeGiftQty - freeGiftOOSQty
    // let shoppingContext = getLocalStorage('shoppingContext') || {}
    // let contextText = shoppingContext?.context || ''
    const contextText = checkMysiteOrPersonalOffer()
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    return (
      <Row noGutters>
        <Col
          lg={4}
          md={4}
          sm={5}
          xs={5}
          className="p-0 free-gift-image-wrapper">
          {contextText === '' ? (
            <Link to={navigateURL}>
              <ImageComponent
                testid="qa-product-image"
                src={imageURL}
                alt={productName}
                height={'100px'}
                width={'100px'}
              />
            </Link>
          ) : (
            <ImageComponent
              testid="qa-product-image"
              src={imageURL}
              alt={productName}
              height={'100px'}
              width={'100px'}
            />
          )}
        </Col>
        <Col
          lg={8}
          md={8}
          sm={7}
          xs={7}
          className="pl-2 pr-0 free-gift-title-wrapper">
          <Row noGutters>
            <Col lg={12} className="p-0 mt-2 free-gift-label-wrapper">
              {contextText === '' ? (
                <Link to={navigateURL} className="text-dark">
                  <Card.Title
                    data-testid="qa-product-name"
                    className="cart-free-gift-product-name">
                    {productName}
                  </Card.Title>
                </Link>
              ) : (
                <Card.Title
                  data-testid="qa-product-name"
                  className="cart-free-gift-product-name">
                  {productName}
                </Card.Title>
              )}

              {application.isMobile && isFlexibleSubscriptionEnabled ? (
                <div>
                  {renderQuantity(props)}
                  {renderPrice(props)}
                </div>
              ) : (
                <></>
              )}
            </Col>
            {freeGiftOOSQty && availableQty > 0 ? (
              <Col lg={12} className="p-0">
                <div className="font-weight-bold text-danger">
                  {i18nTranslate(
                    'pdp.sorryFreeGiftOOSTxt',
                    'Sorry, few gifts are out of stock',
                    {
                      nameSpace: 'ssr-resource',
                    }
                  )}
                </div>
              </Col>
            ) : (
              <></>
            )}
            {freeGiftOOSQty && availableQty <= 0 ? (
              <Col lg={12} className="p-0">
                <div className="font-weight-bold text-danger">
                  {i18nTranslate('pdp.freeGiftOOSTxt', 'Out of Stock', {
                    nameSpace: 'ssr-resource',
                  })}
                </div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Col>
      </Row>
    )
  }

  const renderQuantity = (qtyProps = {}) => {
    const {
      quantity = 0,
      combineFreeGiftQuantity = 0,
      giftOutOfStockQty = 0,
      combineFreeGiftOOSQty = 0,
    } = qtyProps
    const freeGiftQty = combineFreeGiftQuantity || quantity
    const freeGiftOOSQty = combineFreeGiftOOSQty || giftOutOfStockQty
    const availableQty = freeGiftQty - freeGiftOOSQty
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const quantityLabel = isFlexibleSubscriptionEnabled
      ? i18nTranslate('quantity', 'Quantity')
      : i18nTranslate('cart.quantityWithColon', 'QTY')
    const customClassMobile =
      application.isMobile && isFlexibleSubscriptionEnabled
        ? 'justify-content-start'
        : 'justify-content-center'
    return (
      <Col
        lg={4}
        md={2}
        sm={6}
        xs={6}
        className="cart-quantity-wrapper cart-quantity-freegift p-0 mt-lg-3 mt-md-3">
        {freeGiftOOSQty && availableQty > 0 ? (
          <>
            <Row
              noGutters
              className={`font-weight-bolder quantity-title ${customClassMobile}`}>
              <span className="free-gift-quantity-label">
                {i18nTranslate('pdp.AvailableQtyTxt', 'Available', {
                  nameSpace: 'ssr-resource',
                })}
                :
              </span>
              <span className="ml-1 cart-free-gift-quantity">
                {availableQty}
              </span>
            </Row>
            <Row
              noGutters
              className={`font-weight-bolder quantity-title ${customClassMobile}`}>
              <span className="free-gift-quantity-label">
                {i18nTranslate('cart.outOfStock', 'Out of stock')}:
              </span>
              <span className="ml-1 cart-free-gift-quantity">
                {freeGiftOOSQty}
              </span>
            </Row>
          </>
        ) : (
          <Row
            noGutters
            className={`font-weight-bolder quantity-title ${customClassMobile}`}>
            <span className="free-gift-quantity-label">{quantityLabel}:</span>
            <span className="ml-1 cart-free-gift-quantity">
              {availableQty < 0 ? '0' : availableQty}
            </span>
          </Row>
        )}
      </Col>
    )
  }

  const renderPrice = (priceProps = {}) => {
    const { currency = '', totalValue = {} } = priceProps
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    //CX16-11124 - GWP price display in cart
    const isGWPOffer =
      totalValue?.priceAfterDiscount === 0 &&
      totalValue?.discount === totalValue?.originalPrice
        ? true
        : false
    return (
      <Col
        md={3}
        sm={6}
        xs={6}
        className={`${
          !isGWPOffer ? 'col-lg-2' : ''
        } cart-price-wrapper cart-price-freegift justify-content-md-center justify-content-lg-center p-0 m-md-0 m-lg-0 mt-md-3 mt-lg-3`}>
        {isGWPOffer ? (
          <CustomPrice
            custompriceValue={totalValue?.originalPrice}
            customDiscountPriceValue={totalValue?.priceAfterDiscount}
            custompriceLabel={'Price'}
            isCurrencySymbol={true}
            dataTestId="qa-pdp-price"
            title="gwp_offer"
            priceFreePromotion={isGWPOffer}
          />
        ) : (
          <Price
            transactionPrice={'0'}
            currency={currency}
            classStyles={`d-flex ${
              application.isMobile && isFlexibleSubscriptionEnabled
                ? 'justify-content-start'
                : 'justify-content-center'
            } `}
            price={'0'}
            priceLable={isFlexibleSubscriptionEnabled}
          />
        )}
      </Col>
    )
  }

  return (
    <Card
      className={`cart-product-wrapper p-2 checkout-container w-100 free-gift-item-wrapper`}
      data-testid="qa-checkout-free-product">
      <Row
        noGutters
        // className={isSaveForLater ? 'justify-content-between' : ''}
      >
        <Col
          lg={5}
          md={6}
          sm={12}
          xs={12}
          className="p-0"
          data-testid="qa-free-products-container">
          {renderProductDetail(props)}
        </Col>
        {!application.isMobile ? renderQuantity(props) : <></>}
        {!application.isMobile ? renderPrice(props) : <></>}
      </Row>
    </Card>
  )
}
export { Checkoutfreegifts }
export default Checkoutfreegifts
