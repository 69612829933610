import React from 'react'
import { observer } from 'mobx-react'
import { FaExchangeAlt as ChangeIcon } from 'react-icons/fa'
import { isMerchPLP } from 'config/appConfig'

@observer
class MerchAlternateImages extends React.Component {
  render() {
    const { imageArrayProperties, handleCurrentImage } = this.props
    const { images } = imageArrayProperties

    return (
      isMerchPLP && (
        <ChangeIcon
          onClick={handleCurrentImage}
          size="1.5rem"
          className={`allow-merchplp image-change-icon ${
            images.length <= 1 ? 'disabled' : ''
          }`}
        />
      )
    )
  }
}

export { MerchAlternateImages }
