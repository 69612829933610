import React from 'react'
import { withRouter } from 'react-router'
import Links from '../NuSkinFooter/Links'
import SocialIcon from '../NuSkinFooter/SocialIcons'
import CopyRights from '../NuSkinFooter/CopyRights'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import './styles.scss'
import { APPConfig } from 'config/appConfig'
import { getLocaleCodeFromUrl, ApiContent } from 'src/utils'
import { footerContainer } from 'src/models'
import nuskinLogo from 'src/images/logo_horizontal.png'

@observer
class LiveEventFooter extends React.Component {
  @observable footerResponse = {}
  @observable partnerLogos = []
  static contextType = ApiContent

  constructor(props, context) {
    super(props)
    this.context = context
    this.contextName = 'footerData'
    this.currentLocale =
      this.context?.curLocaleFromUrl ||
      getLocaleCodeFromUrl({
        locationProps: props,
        at: 'pathParam',
        defaultLocale: 'en_US',
        isReverseType: true,
      })
    this.getDataFromSource()
  }

  getDataFromSource = async () => {
    let footerData = this.context?.data?.footerData || {}
    if (Object.keys(footerData).length > 0) {
      const getFooterDataFromCS =
        APPConfig?.getAppConfig()?.enableFooterFromCS == 'true'

      footerData = getFooterDataFromCS
        ? footerContainer.transformFooter(footerData)
        : footerData
      this.setFooterData(footerData)
    } else {
      await this.getFooterDataFromAPI()
    }
  }

  setFooterData = response => {
    this.footerResponse = response
    footerContainer.footerResponse = response
    this.partnerLogos = response?.affiliated_navigation_?.links || []
  }

  async getFooterDataFromAPI() {
    this.footerResponse = await footerContainer.getFooterData({
      context: this.context,
      contextName: this.contextName,
      queryParams: {
        locale: this.currentLocale,
      },
    })
    this.setFooterData(this.footerResponse)
  }

  render() {
    return (
      <footer className="eventfooter-container">
        <div className="eventfooter-socials-container">
          <div className="eventfooter-socials-logo">
            <img className="eventfooter-nuskinlogo-img" src={nuskinLogo} />
          </div>
          <SocialIcon socialLinks={this.footerResponse} />
        </div>
        <div className="eventfooter-links-container">
          <Links data={this.footerResponse} enableLiveEvent={true} />
        </div>
        <div className="eventfooter-terms-conatiner">
          <CopyRights
            data={this.footerResponse}
            partnerLogos={this.partnerLogos}
          />
        </div>
      </footer>
    )
  }
}

export default withRouter(LiveEventFooter)
