import { observable } from 'mobx'

class OverlayState {
  @observable isLoading = false

  toggleLoader = () => {
    this.isLoading = !this.isLoading
  }

  showLoader = () => {
    this.isLoading = true
  }

  hideLoader = () => {
    this.isLoading = false
  }
}

const overlayState = new OverlayState()

export { overlayState }
