export function formInput() {
  return [
    [
      {
        name: 'promoCode',
        isToHideLabel: true,
        dataTestId: 'qa-pay-promocode',
        maxLength: '64',
        customClassName: 'promo-code-field col-8',
        ariaLabel: 'promoCode',
        placeholderKey: 'promoCode',
      },
      {
        name: 'applyButton',
        type: 'inlineSubmitButton',
        dataTestId: 'qa-pay-promocode-apply',
        customClassName: 'promo-code-field col-4',
      },
    ],
  ]
}
