import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import {
  LocaleModal,
  UserMenu,
  CartIcon,
  NuskinCartIcon,
  mobileMenuState,
} from 'src/views/components'
import { EventMobileMenu } from './EventMobileMenu'
import { CommonHeader } from 'src/views/components/Header/CommonHeader'
import {
  i18nTranslate,
  getLiveEventStatus,
  IS_BROWSER,
  setLocalStorage,
  getEventNotificationTimeline,
  convertToBoolean,
} from 'src/utils'
import nuskinLogo from 'src/images/live-event-nuskin-logo.png'
import eventLogo from 'src/images/live-event-logo.png'
import { AiOutlineMenu as HamburgIcon } from 'react-icons/ai'
import { pageNames } from 'src/routes/pathParams'
import { observer } from 'mobx-react'
import {
  toastState,
  enableEventNotifications,
  EventModal,
  getTranslationKeyForCurrentTime,
} from 'src/views/components'
import { customerContainer } from 'src/models'
import { KlarnaMessage, KlarnaScript } from 'src/views/components/KlarnaMessage'
import { Row } from 'react-bootstrap'
import { APPConfig } from 'config/appConfig'
import './styles.scss'
import { Helmet } from 'react-helmet'

@observer
class LiveEventHeader extends CommonHeader {
  nameSpace = 'ssr-resource'
  enableLiveEvent = getLiveEventStatus()
  isEventRegisteredUser = ''
  currentPath = ''
  nonRegErrorPath = ''

  constructor(props) {
    super(props)
    this.state = {
      showEventModal: false,
      modalTitle: '',
      modalMessage: '',
      showRedirection: true,
    }
  }

  async checkEventRegisteredUser() {
    const profileResponse = (await customerContainer?.profileResponse) || ''
    const accountProperties =
      (await profileResponse?.accounts?.properties) || []
    const userProperty = accountProperties?.filter(
      property => property?.attributeId === 'registeredEvent'
    )
    return userProperty?.[0]?.value || 'false'
  }

  showCookieStatus = () => {
    if (IS_BROWSER && !navigator.cookieEnabled) {
      return (
        <div className="no-cookie">
          {i18nTranslate(
            'cookie.disabled',
            'This website requires cookie to work properly. To continue, please enable cookie in your browser.'(
              { nameSpace: this.nameSpace }
            )
          )}
        </div>
      )
    }
    return ''
  }

  // Show Event Popup
  enableEventModal = (
    modalMessage = '',
    modalTitle = '',
    showRedirection = true
  ) => {
    if (this.state?.showEventModal !== true) {
      this.setState({ showEventModal: true })
      this.setState({ modalTitle: modalTitle })
      this.setState({ modalMessage: modalMessage })
      this.setState({ showRedirection: showRedirection })
    }
  }

  // Hide Event Popup
  disableEventModal = () => {
    if (this.state?.showEventModal !== false) {
      this.setState({ showEventModal: false })
    }
    // Get current event timeline key for local storage
    let eventNotificationTimeline = getEventNotificationTimeline() || []
    let key = getTranslationKeyForCurrentTime(eventNotificationTimeline) || ''

    // Show popup but no toast for 'eventOffline'
    if (key !== 'eventOffline') {
      // Set storage for showing popup only once
      setLocalStorage('popupShown' + key, true)

      // Show toast after popup is closed
      this.showTimelineNotification(true)
    }
  }

  // Initiate Event Notification
  showTimelineNotification = (fromAlertModal = false) => {
    // Don't show toast if the path is same
    // If the trigger is from closing modal, then show toast
    if (
      !fromAlertModal &&
      this.props?.history?.location?.pathname === this.currentPath
    ) {
      return
    }
    // Get event timeline array
    let eventNotificationTimeline = getEventNotificationTimeline() || []

    // Show popup/toast
    enableEventNotifications(
      eventNotificationTimeline,
      this.enableEventModal,
      this.disableEventModal
    )
    //  Update the path for event timeline toast
    this.currentPath = this.props?.history?.location?.pathname || ''
  }

  // Cart icon is disabled for below mentioned keys
  shouldDisableCartIcon = () => {
    const { enableCartForLiveEvent } = APPConfig?.getAppConfig()

    const eventNotificationTimeline = getEventNotificationTimeline() || []
    const evntKeysToDisable = [
      'beforeEvent',
      'afterEvent1',
      'afterEvent2',
      'eventOffline',
    ]
    const key = getTranslationKeyForCurrentTime(eventNotificationTimeline) || ''

    if (convertToBoolean(enableCartForLiveEvent)) {
      return false
    }
    return evntKeysToDisable.includes(key) || false
  }

  // Show toast for non registered user
  showNonRegisteredUserToast = async () => {
    // Don't show toast if the path is same
    if (this.props?.history?.location?.pathname === this.nonRegErrorPath) {
      return
    }
    this.isEventRegisteredUser = await this.checkEventRegisteredUser()
    if (
      this.enableLiveEvent === 'true' &&
      customerContainer.isRegisterUser &&
      this.isEventRegisteredUser === 'false'
    ) {
      toastState.setToastMessage(
        i18nTranslate(
          'event.errorMessage',
          'Sorry, it seems you are not registered for the event.'
        ),
        false,
        10000,
        '',
        false,
        true,
        i18nTranslate(
          'event.errorMessagetwo',
          'Access to the event-exclusive shopping is limited to registered attendees. If you believe this is a mistake, please contact event organizers for assistance.'
        ),
        'live-event-toast-error'
      )
      //  Update the path for non registered user toast
      this.nonRegErrorPath = this.props?.history?.location?.pathname
    }
  }

  render() {
    // Initiate Event Notification
    this.showTimelineNotification()
    this.showNonRegisteredUserToast()
    const defaultNuskinLogo =
      'https://media.nuskin.com/m/5759f4991d36b1fe/original/nu-skin-logo.svg'

    const disableCarticon = this.shouldDisableCartIcon()
    return (
      <>
        <Helmet>
          <link
            href={'https://fonts.googleapis.com/css2?family=Lora:wght@400;700'}
            rel="stylesheet"
          />
        </Helmet>
        <EventModal
          show={this.state.showEventModal}
          modalTitle={this.state.modalTitle}
          modalMessage={this.state.modalMessage}
          showRedirection={this.state.showRedirection}
          enableEventModal={this.enableEventModal}
          disableEventModal={this.disableEventModal}
        />
        <Row noGutters className="w-100">
          {this.renderAdminTopBar()}
        </Row>
        <header className="eventheader-container nu-header-container">
          <noscript>
            <div className="no-script">
              {i18nTranslate(
                'script.disabled',
                'This website requires JavaScript to work properly. To continue, please enable JavaScript in your browser.',
                { nameSpace: this.nameSpace }
              )}
            </div>
          </noscript>
          {this.showCookieStatus()}
          <KlarnaScript />

          <div className="eventheader-eventlogo">
            <Link to={pageNames?.home}>
              <img className="eventheader-eventlogo-img" src={eventLogo} />
            </Link>
          </div>
          <div className="eventheader-right-section">
            <div className="eventheader-right-upper">
              <div className="eventheader-localeselector">
                <LocaleModal
                  history={this.props.history}
                  isNuskinHeader
                  {...this.props}
                />
              </div>
              <div className="eventheader-nuskinlogo">
                <img
                  className="eventheader-nuskinlogo-img"
                  src={defaultNuskinLogo}
                />
              </div>
            </div>
            <div className="eventheader-right-lower">
              <div className="eventheader-shop">
                <Link to="/catalog/products">
                  {i18nTranslate('event.shopProducts', 'Shop Products', {
                    nameSpace: 'ssr-resource',
                  })}
                </Link>
              </div>
              <div className="eventheader-right-seperator"></div>
              <div className="eventheader-signin">
                <UserMenu
                  {...this.props}
                  isNuskinHeader
                  enableLiveEvent={this.enableLiveEvent}
                />
              </div>
              <div className="eventheader-cart">
                {disableCarticon ? (
                  <NuskinCartIcon className="eventheader-cart-icon-disabled" />
                ) : (
                  <CartIcon {...this.props} isNuskinHeader />
                )}
              </div>
              <div className="eventheader-hamburger">
                <button
                  onClick={() => {
                    mobileMenuState?.toggleMenuVisibility()
                  }}
                  className="hide-on-desktop nu-header-pancake-button px-0 header-mobile-view  bg-transparent border-0 pl-0"
                  aria-label="navigation menu"
                  title="navigation menu">
                  <HamburgIcon aria-label="navigation menu" size={24} />
                </button>
              </div>
            </div>
          </div>

          <EventMobileMenu
            {...this.props}
            isNuskinHeader
            enableLiveEvent={this.enableLiveEvent}
          />
        </header>
      </>
    )
  }
}

export default withRouter(LiveEventHeader)
