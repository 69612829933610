import React from 'react'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { i18nTranslate } from 'src/utils'
import './styles.scss'
import { APPConfig } from 'config/appConfig'

class ProductInfobar extends React.Component {
  nameSpace = 'ssr-resource'
  render() {
    const product = this.props?.product || {}
    const featuredet = product.features || '[]'
    let image = {}
    let subtitle = ''
    let features = []
    let alt = ''
    let backgroundColor = ''
    let textColor = '#ffffff'

    try {
      ;({
        image = {},
        subtitle = '',
        features = [],
        alt = '',
        backgroundColor = '',
        textColor = '#ffffff',
      } = JSON.parse(featuredet)[0] || {})
    } catch (e) {
      console.log('Data parse error::: ProductInfobar' + e)
    }

    const backgroundColor1 = {
      backgroundColor: `${backgroundColor}`,
      color: `${textColor}`,
    }
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'

    return (
      <>
        {features?.length > 0 && (
          <div
            className="product-infobar clearfix w-100"
            style={backgroundColor1}>
            <div className="product-infobar-img-container">
              <LazyLoadImage
                src={image?.url || imagePlaceholder}
                alt={image?.alt || 'image-infobar'}
                onError={e => {
                  if (e.target.src != imagePlaceholder) {
                    e.target.onerror = null
                    e.target.src = imagePlaceholder
                  }
                }}
              />
            </div>
            <div className="product-infobar-content" style={backgroundColor1}>
              <div
                className={`${
                  enableNewPDPStyle
                    ? 'v2-product_right_content'
                    : 'product_right_content'
                }`}>
                <h2 className="heading-2 why-you-will-love-it-title">
                  {i18nTranslate('productInfobar.title', "Why You'll Love It", {
                    nameSpace: this.nameSpace,
                  })}
                </h2>
                {subtitle ? (
                  <h4 className="heading-5 why-you-will-love-it-subtitle">
                    {subtitle}
                  </h4>
                ) : (
                  ''
                )}
                <ul>
                  {features.length > 0 &&
                    features.map((feature, index) => (
                      <li key={`feature-${index}`}>{feature}</li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export { ProductInfobar }
