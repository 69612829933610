import { i18nTranslate } from 'src/utils'
import { catalogDeps } from 'src/deps'
import { cartContainer, customerContainer } from 'src/models'
import { getImageFromProperty } from 'src/utils'
import { productState } from 'src/views/components/BaseProductComponent'

/**
 * @description addtoCartDisable function determines if the Add to Cart button should be disabled for a product
 * based on various product properties and eligibility rules.
 * It checks properties like out of stock status, starter kit status, availability, etc.
 * It also checks cart items to enforce limits for exclusive products.
 * Returns an object with button label, message, and boolean to disable button or not.
 */
const addtoCartDisable = (product = {}, language = {}) => {
  const returnValue = {
    btnLabel: language.SEARCH_CARD_ADD,
    message: '',
    isToDisableAddButton: false,
  }

  // Taken from Subscription Page - Start
  const selectedSKU = catalogDeps.getDefaultSelectedSku(product)
  const productDetails = selectedSKU?.[0] || product || {}

  const isStarterKit =
    productDetails?.properties?.isstarterkit || '' === 'true' ? true : false
  const inventoryStatus = productDetails?.inventory || ''
  const availableInStore = productDetails?.properties?.availableinstore || ''
  const isUnavailableInStore = availableInStore === '0'
  const isInActiveItem =
    availableInStore === '3' || availableInStore === '5' ? true : false
  const isOOSItem = inventoryStatus?.toLowerCase() === 'out of stock' || false
  const dangerousGoods =
    productDetails?.skuProperties?.dangerousGoods === 'true' ||
    productDetails?.properties?.dangerousGoods === 'true' ||
    product?.properties?.dangerousGoods === 'true' ||
    false
  const isSkuBundleOnly =
    productDetails?.properties?.productStatus === 'bundleOnly' ||
    product?.properties?.productStatus === 'bundleOnly'
      ? true
      : false

  if (isInActiveItem || isOOSItem) {
    returnValue.btnLabel = language.SEARCH_CARD_OOS
    returnValue.message = i18nTranslate('cart.outOfStock', 'Out of stock')
  } else if (isStarterKit || isUnavailableInStore) {
    returnValue.btnLabel = language.SEARCH_CARD_UNAVAILABLE
  }
  // Taken from Subscription Page - End

  // Taken from PDP - Start
  const isExclusive = productDetails?.properties?.isExclusive || ''
  const productId = product?.identifier || selectedSKU[0]?.identifier || ''

  // condition to check quantity limit for exclusive products based on cart items
  if (isExclusive) {
    const cartItems = cartContainer?.cartResponse?.items || []
    let maxProductQty
    const productQualificationResponse =
      customerContainer?.qualificationResponse?.qualification?.filter(p => {
        return p?.productId === productId
      })
    if (
      productQualificationResponse &&
      productQualificationResponse?.length > 0
    ) {
      if (cartItems && cartItems?.length > 0) {
        cartItems.forEach(item => {
          if (item?.skus?.[0]?.productId == productId) {
            maxProductQty =
              productQualificationResponse?.[0]?.quantity - item?.quantity
          }
        })
        if (maxProductQty <= 0 || 1 > maxProductQty) {
          returnValue.isToDisableAddButton = true
        }
      }
    }
  }

  const isToDisableAddToCart = getSkuEligible(product)

  const { isItemEligible } = productState.getQualificationMessage({
    productId,
    isExclusive,
  })
  // Taken from PDP - End

  returnValue.isToDisableAddButton =
    isStarterKit ||
    isInActiveItem ||
    dangerousGoods ||
    isUnavailableInStore ||
    isOOSItem ||
    isToDisableAddToCart ||
    !isItemEligible ||
    isSkuBundleOnly

  return returnValue
}

/**
 * @description getPdtImage function returns the product image URL from given data.
 * @param {object} product - The product data
 * @returns {string} URL of the product image
 */
const getPdtImage = (product = {}) => {
  if (product?.type == 'kit' || product?.type === 'bundle') {
    return getImageFromProperty(product?.properties)
  }
  const defaultSku = catalogDeps.getDefaultSelectedSku(product)
  return getImageFromProperty(defaultSku?.[0]?.properties)
}

// @##pdp_button_disable_and_status_display_logic
/**
 * @description Checks if the given product sku is eligible for sell
 * @param {object} product - The product object
 * @returns {boolean} - Returns true if sku is not eligible, false if eligible
 */
const getSkuEligible = (product = {}) => {
  // @todo - to check for bundle products
  const productCount = 1
  const selectedSku = catalogDeps.getDefaultSelectedSku(product)?.[0]
  const inventory = selectedSku?.inventoryProperties || {}

  let atpQty = inventory?.atpQty
  let outOfStockThreshold = inventory?.outOfStockThreshold
  let backOrderedQty = inventory?.backOrderedQty
  const status = selectedSku?.properties?.status
  const inventoryStatus = selectedSku?.inventory || ''
  const productStatus = selectedSku?.properties?.productStatus

  if (
    productStatus == 'preview' ||
    (atpQty <= 0 && backOrderedQty <= 0) ||
    status === 'inactive' ||
    inventoryStatus?.toLowerCase() === 'out of stock' ||
    inventoryStatus?.toLowerCase() === 'unavailable'
  ) {
    return true
  } else {
    if (productCount > atpQty - outOfStockThreshold) {
      let currentDateTimeStamp = Date.now()
      let backorderTime = ''
      if (
        inventory?.expectedBackOrderAvailabilityDate?.toString?.().length <= 10
      ) {
        backorderTime = inventory?.expectedBackOrderAvailabilityDate + '000'
      } else {
        backorderTime = inventory?.expectedBackOrderAvailabilityDate
      }
      backorderTime = Number(backorderTime)

      // @##backorder_logic_pdp_message_or_button

      if (backOrderedQty > 0) {
        let expectedbackorder = productCount - (atpQty - outOfStockThreshold)
        if (expectedbackorder <= backOrderedQty) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }
}

export { addtoCartDisable, getSkuEligible, getPdtImage }
