import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
// import get from 'lodash/get'
import { i18nTranslate } from 'src/utils'
import { Dropdown, Row, Col, DropdownButton } from 'react-bootstrap'
import { isB2B2C } from 'src/utils'
import {
  costCenterContainer,
  checkoutContainer,
  cartContainer,
  subscriptionContainer,
} from 'src/models'
import './styles.scss'

@observer
class CostCenterDropDown extends React.Component {
  @observable selectedCostCenter = i18nTranslate(
    'costCenter.select',
    'Select the Cost Center'
  )
  @observable costCenterList = []
  @observable selectedAvailableBudget = 0
  @observable selectedCostcenter = {}

  transformCostList = response => {
    const costCenter = []
    response &&
      response.costCentres &&
      response.costCentres.length &&
      response.costCentres.forEach(item => {
        if (item.status.toLowerCase() === 'active') {
          const budgets = item?.budgets || []
          const availableBudgets = budgets.filter(
            budget => budget.endDate >= Date.now()
          )
          let availableBudget = 0
          if (availableBudgets.length > 0) {
            availableBudget =
              availableBudgets[0].totalBudget - availableBudgets[0].usedBudget
          }
          costCenter.push({
            label: item.name,
            value: item.id,
            availableBudget,
          })
        }
      })
    this.costCenterList = costCenter
  }
  componentDidMount() {
    this.getCostCenter()
  }

  handleB2B2CCostCenterSelection = async () => {
    this.costCenterList.forEach(costcenter => {
      console.log(costcenter)
      const details = costcenter?.availableBudget || 0
      if (details > 0) {
        this.selectedCostcenter = costcenter
      }
    })
    await this.handleCostCenterSelection(this.selectedCostcenter)
  }

  getCostCenter = async () => {
    const { isFromSubscription = false } = this.props
    const response = await checkoutContainer.getAllCostCenter()
    this.transformCostList(response)
    const costCenterId = isFromSubscription
      ? subscriptionContainer?.costCenterId || ''
      : cartContainer?.cartResponse?.costCenterId || ''
    if (isB2B2C()) {
      this.handleB2B2CCostCenterSelection()
    }
    if (costCenterId) {
      const response = await costCenterContainer.getOneCostCenter(costCenterId)
      this.selectedCostCenter = response.name
      let selectCostCenter = this.costCenterList.filter(
        costCenter => costCenter.label === this.selectedCostCenter
      )
      this.selectedAvailableBudget = selectCostCenter[0].availableBudget
    }
  }

  handleCostCenterSelection = async list => {
    const { isFromSubscription = false } = this.props

    if (list.value) {
      const response = isFromSubscription
        ? await this.props.handleCostCenterSelection(list.value)
        : await checkoutContainer.updateCostCenterToCart(list.value)

      if (checkoutContainer.isSuccessResponse(response)) {
        this.selectedCostCenter = list.label
        this.selectedAvailableBudget = list.availableBudget
      }
    }
  }

  renderCostList = list => {
    return (
      <Dropdown.Item
        onSelect={() => {
          this.handleCostCenterSelection(list)
        }}
        key={list.value}>
        {list.label}
      </Dropdown.Item>
    )
  }

  render() {
    const { className } = this.props
    const view = this.costCenterList.map(this.renderCostList)
    const availableBudget = `$${this.selectedAvailableBudget.toFixed(2)}`
    if (isB2B2C()) {
      return <></>
    }
    return (
      <Col
        lg={12}
        className={`d-flex cost-center-list-wrapper flex-column ${className}`}>
        <Row noGutters className="cost-center-label-list-wrapper flex-column">
          <Row noGutters>
            <span className="text-secondary cost-center-title">
              {i18nTranslate('costDropdown.title', 'Cost Center')}
            </span>
            {!this.props.isReviewSection && (
              <span className="cost-center-avail-budget">
                {i18nTranslate(
                  'costDropdown.checkoutTitle',
                  'Available Budget'
                )}{' '}
                : {availableBudget}
              </span>
            )}
          </Row>
          <Dropdown
            className="border cost-center-dropdown font-weight-bold d-flex flex-column mt-2 mb-2 w-100"
            disabled={this.props.isReviewSection}>
            <DropdownButton
              title={this.selectedCostCenter}
              variant="none"
              className="cost-center-dropdownbutton-wrapper"
              disabled={this.props.isReviewSection}>
              {view}
            </DropdownButton>
          </Dropdown>
        </Row>
      </Col>
    )
  }
}

export { CostCenterDropDown }
export default CostCenterDropDown
