import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
// import { getProfileAddress } from 'src/static/fixtures'

@observer
class ShippingAddressSuggestionModal extends React.Component {
  @observable show = true
  @observable selectedIndex = 0

  handleSelection = (address, index) => {
    this.selectedIndex = index
  }
  removeEllipsis = addressLine => {
    //  test 1 ... 99 S 1 ... 100 Ave --> test 1 S 1 Ave
    // ' ... 99' and ' ... 100' will be removed
    const splittedAddress = addressLine.split(' ... ')
    // splittedAddress = ['test 1','99 S 1', '100 Ave']
    // excluding index=0,
    // '99' in '99 S 1' , '100' from '100 Ave' has to removed
    const ellipsisSuffixRemoved = splittedAddress.map(
      (splittedValue, index) => {
        if (index > 0) {
          const splittedBasedOnSpace = splittedValue.split(' ')
          // splittedBasedOnSpace = ['99','S','1']
          splittedBasedOnSpace.splice(0, 1)
          // splittedBasedOnSpace = ['S','1']
          return splittedBasedOnSpace.join(' ')
          // 'S 1'
        } else {
          return splittedValue
        }
      }
    )
    //ellipsisSuffixRemoved =  ['test 1','S 1', 'Ave']
    return ellipsisSuffixRemoved.join(' ')
    // address.addressLine1 = test 1 S 1 Ave
  }
  checkAndRemoveEllipsis = address => {
    if (address.addressLine1 && address.addressLine1.includes('...')) {
      address.addressLine1 = this.removeEllipsis(address.addressLine1)
    }
    if (address.addressLine2 && address.addressLine2.includes('...')) {
      address.addressLine2 = this.removeEllipsis(address.addressLine2)
    }
    return address
  }
  getSuggestionListView = () => {
    const addressList = this.props.recommendations
    const suggestionListView = addressList.map((addressReceived, index) => {
      const addressToModify = { ...addressReceived }
      /* const id = get(
        addressToModify,
        'id',
        get(addressToModify, 'addressId', '')
      ) */
      const address = this.checkAndRemoveEllipsis(addressToModify)
      return (
        <Form.Check
          className="my-2"
          id={`address-${index}`}
          custom
          key={index}
          label={this.props.renderShippingAddressComponent(address, true)}
          type="radio"
          checked={index == this.selectedIndex}
          onChange={() => {
            this.handleSelection(address, index)
          }}
        />
      )
    })
    return suggestionListView
  }
  getOriginalListView = () => {
    const address = this.props?.originalAddress || ''

    return (
      <Form.Check
        className="my-2"
        id={`address-original`}
        custom
        label={this.props.renderShippingAddressComponent(address, true)}
        type="radio"
        checked={'original' == this.selectedIndex}
        onChange={() => {
          this.handleSelection(address, 'original')
        }}
      />
    )
  }
  handleToggleView = () => {
    this.show = !this.show
    this.props.toggleSuggestionView()
  }

  handleSelectSuggestion = () => {
    this.handleToggleView()
    const selectedRecommendation =
      this.props.recommendations[this.selectedIndex]
    let modifiedRecommendation =
      selectedRecommendation &&
      this.checkAndRemoveEllipsis(selectedRecommendation)
    if (this.selectedIndex == 'original') {
      modifiedRecommendation = this.checkAndRemoveEllipsis(
        this.props.originalAddress
      )
    }
    this.props.handleAddressContinue(
      modifiedRecommendation,
      true,
      this.selectedIndex
    )
  }
  updateSelectedIndex = selectedId => {
    const addressList = this.props.recommendations
    const suggestionListView = addressList.find((addressReceived, index) => {
      const id =
        addressReceived?.id ||
        addressReceived?.addressId ||
        addressReceived?.shippingMethodId ||
        ''
      if (parseInt(id) === parseInt(selectedId)) {
        this.selectedIndex = index
        return true
      }
    })
    return suggestionListView
  }
  componentDidMount() {
    const { selectedId = '' } = this.props
    selectedId && this.updateSelectedIndex(selectedId)
  }

  render() {
    const suggestionListView = this.getSuggestionListView()
    const originalListView = this.getOriginalListView()
    const { customModalTitle, customSaveButtonLabel } = this.props
    return (
      <Modal
        aria-hidden={false}
        aria-labelledby="suggestion-modal-header"
        data-testid="qa-address-suggestion-overlay"
        show={this.show}
        key="address-suggestion-modal"
        onHide={this.handleToggleView}
        className="suggestion-modal">
        <Modal.Header className="address-suggestion-modal-header" closeButton>
          <Row noGutters>
            <h1 className="suggestion-modal-header">
              {customModalTitle
                ? customModalTitle
                : i18nTranslate(
                    'addressSuggestion.verifytitle',
                    'Verify Address'
                  )}
            </h1>
            <p className="address-verify m-0">
              {i18nTranslate(
                'addressSuggestion.verifytext',
                'There appears to be a problem with your address. Please verify it is correct or select one below.'
              )}
            </p>
          </Row>
        </Modal.Header>
        <Modal.Body bsPrefix="suggestions-modal-body">
          <Row noGutters className="d-flex">
            <Col lg={6} md={6} sm={12} xs={12} className="">
              <p className="m-0 pt-2 cart-product-count">
                {i18nTranslate('addressSuggestion.originaltitle', 'ORIGINAL')}
              </p>
              <fieldset className="pr-3">{originalListView}</fieldset>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="">
              <p className="m-0 pt-2 cart-product-count">
                {i18nTranslate(
                  'addressSuggestion.suggestiontitle',
                  'SUGGESTIONS'
                )}
              </p>
              <fieldset className="pr-3">{suggestionListView}</fieldset>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-start pl-3">
          <Button
            variant="secondary"
            className="modal-button semi-solid-button semi-solid-button text-uppercase rounded-0 mt-2 mt-md-0 suggestion-modal-save-button"
            onClick={this.handleToggleView}
            data-testid="qa-suggestion-close">
            {i18nTranslate('editShippingAddress.close', 'Close')}
          </Button>
          <Button
            variant="primary"
            className="modal-button semi-solid-button semi-solid-button text-uppercase rounded-0 mt-2 mt-md-0"
            onClick={this.handleSelectSuggestion}
            data-testid="qa-suggestion-save">
            {`${
              customSaveButtonLabel ? customSaveButtonLabel : 'Save Changes'
            }`}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
export { ShippingAddressSuggestionModal }
export default ShippingAddressSuggestionModal
