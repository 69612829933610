import React from 'react'

const ViewCartRefreshIcon = (props = {}) => {
  const {
    className = '',
    width = '',
    height = '',
    fill = 'none',
    viewBox = '0 0 24 24',
    dataTestId = 'view-cart-refresh-icon',
    ariaLabel = 'View Cart Refresh Icon',
    fillColor = '',
  } = props || {}
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      className={className}
      viewBox={viewBox}
      data-testid={dataTestId}
      aria-label={ariaLabel}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.60412 5.18414C6.99683 2.26756 10.9457 0.855265 14.8118 1.78258C19.7571 2.96874 23.0411 7.61684 22.4263 12.6219C22.359 13.1701 21.86 13.5599 21.3118 13.4925C20.7637 13.4252 20.3739 12.9262 20.4412 12.3781C20.9292 8.40542 18.324 4.68174 14.3453 3.72741C11.2716 2.99014 8.14907 4.09165 6.22888 6.35976L7.09468 6.02942C7.61068 5.83254 8.18859 6.09125 8.38546 6.60725C8.58234 7.12325 8.32364 7.70115 7.80763 7.89803L4.29544 9.23808C4.00598 9.34852 3.68167 9.31856 3.41735 9.15695C3.15302 8.99534 2.97852 8.72035 2.94488 8.41237L2.50591 4.39405C2.44594 3.84503 2.84239 3.35134 3.39141 3.29137C3.94042 3.23139 4.43411 3.62784 4.49409 4.17686L4.60412 5.18414Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3959 18.8159C17.0032 21.7324 13.0543 23.1447 9.18819 22.2174C4.24291 21.0313 0.958876 16.3832 1.57369 11.3781C1.64103 10.8299 2.13999 10.4401 2.68815 10.5075C3.23632 10.5748 3.62611 11.0738 3.55877 11.6219C3.07078 15.5946 5.67596 19.3183 9.65467 20.2726C12.7284 21.0099 15.8509 19.9084 17.7711 17.6403L16.9053 17.9706C16.3893 18.1675 15.8114 17.9088 15.6145 17.3928C15.4177 16.8768 15.6764 16.2989 16.1924 16.102L19.7046 14.7619C19.994 14.6515 20.3183 14.6815 20.5827 14.8431C20.847 15.0047 21.0215 15.2797 21.0551 15.5876L21.4941 19.606C21.5541 20.155 21.1576 20.6487 20.6086 20.7086C20.0596 20.7686 19.5659 20.3722 19.5059 19.8232L19.3959 18.8159Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default ViewCartRefreshIcon
export { ViewCartRefreshIcon }
