import React from 'react'
import { observer } from 'mobx-react'
import { reaction, observable } from 'mobx'
import { i18nTranslate, checkForSkusArrayInfo } from 'src/utils'
import { SubscriptionProductDetails } from './SubscriptionProductDetails/SubscriptionProductDetails'
import { SubscriptionAgreementInfo } from './SubscriptionAgreementInfo/SubscriptionAgreementInfo'
import { nuskinSubscriptionContainer, cartContainer } from 'src/models'
import { getProductDetailsForCart } from 'src/views/components'
import {
  isToShowTwoMonthCalender,
  getUniqueSubscriptionDatesOfMonthLimit,
  getNonProcessedSubscriptionDates,
  getAllSubscriptionProcessingDatesOfMonthLimit,
  getMinDateDetailsToBeSelectedV2,
  getMaxDateDetailsToBeSelectedV2,
  getSubscriptionOrderSuccessDatesOfAllMonth,
  getMinAvailableDateV2,
  getDateDetailsV2,
} from 'src/deps'
import { cartSubscriptionState } from './CartSubscriptionState'
import { IoIosInformationCircle as InformationIcon } from 'react-icons/io'
import { Button } from 'react-bootstrap'
import './styles.scss'

@observer
class MultiSubscriptionCartDrawer extends React.Component {
  @observable availableSubscriptionDatesOfMonthLimit = []
  @observable uniqueSubscriptionDatesOfMonthLimit = []
  @observable selectedMinFullDate = ''
  alreadyProcessedOrderDates = []
  minDateDetails = {}
  maxDateDetails = {}

  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    //state management and api fetch if needed
    const { subscriptionItems = [] } = this.props

    nuskinSubscriptionContainer.cartSubscriptionDrawerData = {}
    let newDrawerItemsList = []
    //TODO : Need to restrict gift items
    subscriptionItems.map((subscriptionProduct, index) => {
      const {
        skus = [],
        subscription = {},
        properties = {},
        quantity = '',
        totalValue = {},
        unitValue = {},
        itemId = '',
      } = subscriptionProduct

      let skuInfoTransformed = checkForSkusArrayInfo(skus)

      const {
        skuInfo = {},
        isNormalSku = false,
        isBundleSku = false,
        skusExceptMainSku = [],
      } = skuInfoTransformed?.[0] || {}

      const {
        skuId = '',
        title = '',
        type = '',
        productId = '',
      } = skuInfo || {}
      const { frequencyPeriod = '', frequencyType = '' } = subscription || {}

      let frequency = frequencyPeriod + '-' + frequencyType

      const isToShowTwoMonthProcessingDates =
        isToShowTwoMonthCalender() || false
      const subscriptionResponse =
        nuskinSubscriptionContainer.subscriptionPreferenceResponse
          ?.selectedSubscriptions || []
      const alreadyProcessedSubscriptionDates =
        getSubscriptionOrderSuccessDatesOfAllMonth(subscriptionResponse) || []
      this.alreadyProcessedOrderDates = alreadyProcessedSubscriptionDates
      this.minDateDetails = getMinDateDetailsToBeSelectedV2() || {}
      this.maxDateDetails =
        getMaxDateDetailsToBeSelectedV2(alreadyProcessedSubscriptionDates) || {}
      const allSubscriptionDatesOfTwoMonthLimit =
        getAllSubscriptionProcessingDatesOfMonthLimit(
          subscriptionResponse,
          this.minDateDetails,
          this.maxDateDetails
        ) || []
      const nonProcessedSubscriptionDatesOfMonthLimit =
        getNonProcessedSubscriptionDates(
          alreadyProcessedSubscriptionDates,
          allSubscriptionDatesOfTwoMonthLimit
        ) || []
      const uniqueSubscriptionDatesOfMonthLimit =
        getUniqueSubscriptionDatesOfMonthLimit(
          nonProcessedSubscriptionDatesOfMonthLimit
        ) || []
      this.uniqueSubscriptionDatesOfMonthLimit =
        uniqueSubscriptionDatesOfMonthLimit
      this.availableSubscriptionDatesOfMonthLimit =
        isToShowTwoMonthProcessingDates
          ? nonProcessedSubscriptionDatesOfMonthLimit
          : uniqueSubscriptionDatesOfMonthLimit
      const availableMinDate =
        getMinAvailableDateV2(
          this.alreadyProcessedOrderDates,
          this.minDateDetails,
          this.maxDateDetails
        ) || ''
      this.selectedMinFullDate = availableMinDate
      let { date = 1, month = 0 } = getDateDetailsV2(this.selectedMinFullDate)

      let skuDetails = {
        itemId,
        quantity,
        skus,
        subscription,
        orderProcessedDate: date,
        orderProcessedFullDate: this.selectedMinFullDate,
        orderProcessedMonth: month + 1,
        skuId,
        properties,
        title,
        totalValue,
        unitValue,
        frequency: frequency || '1-Months',
        isBundle: isBundleSku,
        productId,
        skuInfo,
        skusExceptMainSku,
        isNormalSku,
      }

      newDrawerItemsList[skuId] = skuDetails
    })

    //update state
    for (const skuId in newDrawerItemsList) {
      nuskinSubscriptionContainer.cartSubscriptionDrawerData[skuId] = {
        ...newDrawerItemsList[skuId],
      }
    }

    //Check for disabled items
    this.checkForDisabledItemsAndUpdateState(subscriptionItems)
  }

  componentWillUnmount() {
    nuskinSubscriptionContainer.cartSubscriptionDrawerData = {}
    nuskinSubscriptionContainer.showModalContent = true
  }

  /**
   * Checks if all subscription items in the cart are already subscribed, and updates the state accordingly.
   *
   * @param {Array} subscriptionItems - An array of subscription items in the cart.
   * @returns {void}
   */
  checkForDisabledItemsAndUpdateState = (subscriptionItems = []) => {
    const reactions = reaction(
      () => {
        const allProductsDisabled = subscriptionItems?.every(
          (product, index) => {
            const productDetails = getProductDetailsForCart(product)
            const isAlreadySubscribed =
              nuskinSubscriptionContainer.isAlreadySubscribedProduct(
                productDetails
              ) || false
            return isAlreadySubscribed === true
          }
        )
        nuskinSubscriptionContainer.showModalContent = !allProductsDisabled
        return allProductsDisabled
      },
      (allProductsDisabled, reaction) => {
        nuskinSubscriptionContainer.showModalContent = !allProductsDisabled
      }
    )
  }

  /**
   * Updates the recurrenceOrderProcessingDate property of the cart subscription drawer data for the specified item ID.
   *
   * @param {string} [selectedDate=''] - The new recurrence order processing date.
   * @param {string} [itemId=''] - The ID of the cart item to update.
   */
  handleCalenderDateChange = (selectedDate = '', itemId = '') => {
    nuskinSubscriptionContainer.cartSubscriptionDrawerData[
      itemId
    ].orderProcessedDate = selectedDate?.getDate()
    nuskinSubscriptionContainer.cartSubscriptionDrawerData[
      itemId
    ].orderProcessedMonth = selectedDate?.getMonth() + 1
    nuskinSubscriptionContainer.cartSubscriptionDrawerData[
      itemId
    ].orderProcessedFullDate = selectedDate
  }

  /**
   * Updates the frequency period and frequency type for a subscription
   * in the cart drawer data when the frequency dropdown value changes.
   *
   * @param {string} value - The selected frequency value, e.g. '3-Months'
   * @param {string} itemId - The id of the subscription item to update
   */
  handleSubscriptionFrequency = (value = '', itemId = '') => {
    const [frequencyPeriod, frequencyType] = value?.split?.('-')
    nuskinSubscriptionContainer.cartSubscriptionDrawerData[
      itemId
    ].subscription = { frequencyPeriod, frequencyType }
  }

  /**
   * Updates the quantity for the given itemId in the cart subscription drawer data.
   * @param {number} count - The new quantity value
   * @param {string} itemId - The id of the item to update
   */
  handleQuantityChange = (count = 0, itemId = '') => {
    nuskinSubscriptionContainer.cartSubscriptionDrawerData[itemId].quantity =
      count
  }

  /**
   * Updates the totalValue and unitValue for a subscription item
   * in the cart drawer data after retrieving updated promotion details.
   *
   * @param {Array} subscriptionProduct - The subscription product array
   * @param {number} quantity - The quantity of the subscription item
   * @param {string} itemId - The id of the subscription item to update
   */
  handleQuantityChangePromotion = async (
    subscriptionProduct = [],
    quantity = 0,
    itemId = ''
  ) => {
    const response =
      await cartSubscriptionState.getSubscriptionPromotionDetails(
        subscriptionProduct,
        quantity
      )
    if (cartContainer.isSuccessResponse(response)) {
      nuskinSubscriptionContainer.cartSubscriptionDrawerData[
        itemId
      ].totalValue = response?.totalValue
      nuskinSubscriptionContainer.cartSubscriptionDrawerData[itemId].unitValue =
        response?.unitValue
    }
  }

  /**
   * Renders a message indicating the item is already subscribed.
   * Includes an info icon with tooltip.
   */
  renderAlreadySubscribedMsg = () => {
    return (
      <div className="already-subscribed-msg d-flex">
        {i18nTranslate(
          'cart.alreadySubscribedMsg',
          'Already subscribed. Manage this product in the subscription dashboard. This will be removed from your cart.'
        )}
        <span className="info-icon">
          <InformationIcon
            size="1.5rem"
            id="info-icon-drawer"
            className="info-icon"
            aria-label="Info Icon"
          />
        </span>
      </div>
    )
  }

  /**
   * Renders the subscription product details for each item
   * in the cart drawer data. Checks if the product is already subscribed,
   * and conditionally renders a message for those products.
   */
  renderSubscriptionProductDetails = () => {
    const items = nuskinSubscriptionContainer.cartSubscriptionDrawerData || {}
    return Object.values(items)?.map((subscriptionProduct, key) => {
      let isAlreadySubscribed =
        nuskinSubscriptionContainer.isAlreadySubscribedProduct(
          subscriptionProduct
        ) || false
      return (
        <div className="subscription-product-details" key={key}>
          <SubscriptionProductDetails
            key={key}
            subscriptionProduct={subscriptionProduct}
            handleQuantityChange={this.handleQuantityChange}
            handleQuantityChangePromotion={this.handleQuantityChangePromotion}
            handleSubscriptionFrequency={this.handleSubscriptionFrequency}
            handleCalenderDateChange={this.handleCalenderDateChange}
            isAlreadySubscribed={isAlreadySubscribed}
            availableSubscriptionDatesOfMonthLimit={
              this.availableSubscriptionDatesOfMonthLimit
            }
            minDateDetails={this.minDateDetails}
            maxDateDetails={this.maxDateDetails}
            alreadyProcessedOrderDates={this.alreadyProcessedOrderDates}
            availableOrderDates={this.uniqueSubscriptionDatesOfMonthLimit}
          />
          {isAlreadySubscribed && this.renderAlreadySubscribedMsg()}
        </div>
      )
    })
  }

  /**
   * Renders the subscription agreement terms component.
   */
  renderSubscriptionAgreementTerms = () => {
    return <SubscriptionAgreementInfo {...this.props} />
  }

  /**
   * Handles the removal of already subscribed items (greyed-out items) from the cart.
   * This function makes an API call to remove the already subscribed items from the cart.
   * If the API call is successful, it toggles the subscription modal.
   */
  handleDisabledSubscription = async () => {
    //Make api for remove cart of already subscribed items(greyed-out items)
    let subscriptionItems = this.props?.subscriptionItems || []
    const response =
      await nuskinSubscriptionContainer.removeAlreadySubscribedItem(
        subscriptionItems
      )

    if (nuskinSubscriptionContainer.isSuccessResponse(response)) {
      await cartContainer.viewCart()
      this.props?.toggleSubscriptionModal()
    }
  }

  /**
   * Renders a button that, when clicked, handles disabling the subscription for the provided subscription items.
   *
   * @param {Object[]} subscriptionItems - The subscription items to handle disabling the subscription for.
   * @returns {React.ReactElement} - A button element that, when clicked, handles disabling the subscription for the provided subscription items.
   */
  renderReturnToCartButton = () => {
    return (
      <Button
        onClick={() => this.handleDisabledSubscription()}
        variant="primary btn-goToCart w-100 text-uppercase">
        {i18nTranslate('subscriptionModal.returnToCart', 'Return To Cart')}
      </Button>
    )
  }

  render() {
    return (
      <div className="cart-drawer-wrapper">
        <div className="cart-drawer-title">
          {i18nTranslate('cart.subDraweTitle', 'Subscribe to these items?')}
        </div>
        <div className="cart-item-details-section">
          {this.renderSubscriptionProductDetails()}
        </div>
        {nuskinSubscriptionContainer.showModalContent
          ? this.renderSubscriptionAgreementTerms()
          : this.renderReturnToCartButton()}
      </div>
    )
  }
}

export default MultiSubscriptionCartDrawer
export { MultiSubscriptionCartDrawer }
