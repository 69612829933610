import { observable } from 'mobx'
import TagManager from 'react-gtm-module'
import isArray from 'lodash/isArray'
import {
  CommonContainer,
  customerContainer,
  addressContainer,
  accountsContainer,
  taxIDContainer,
  storeContainer,
} from 'src/models'
import {
  deleteFromLocalStorage,
  getLocalStorage,
  setLocalStorage,
  scrollState,
  trackAccountUpgrade,
  enablePhoneVerification,
  enableJITSU,
} from 'src/utils'
import { toastState, modalState } from 'src/views/components'
import { i18nTranslate } from 'src/utils'
import { dateFormatDeps } from 'src/deps'
import { APPConfig, deleteFromSessionStorage } from 'config/appConfig'
import dayjs from 'dayjs'
import { pageNames } from 'src/routes/pathParams'
import { alertMessageKeys } from 'src/views/components/CartBlock/fixture'
import { getFraudDetectionProperties } from 'src/utils/signUpUtils'

/**
 * AccountsAddressContainer class extends CommonContainer.
 * This is likely the container class for managing account addresses.
 */
class AccountsAddressContainer extends CommonContainer {
  @observable addressData = []
  @observable pageProfileRedirect = false
  @observable currentAddressFormValues = {}
  @observable isAccountAddressEmpty = false
  @observable isConvertAccountAddressEmpty = false
  @observable currentConvertAddressFormValues = {}
  isFirstAddress = false
  consent = {}

  /**
   * Fetches all addresses associated with the logged in customer's account.
   *
   * Makes API call to get all addresses for the account.
   * Sets addressData to the response array.
   * Sets isFirstAddress based on whether addressData is empty.
   */
  getAllAccountAddress = async () => {
    const accountId = customerContainer?.accountId || ''
    const loadParams = {
      endPointName: 'getAllAccountAddress',
      pathParams: `${accountId}/addresses`,
    }
    this.addressData = await this.fetchResponse(loadParams)
    this.isFirstAddress =
      isArray(this.addressData) && this.addressData.length === 0
  }

  /**
   * Transforms address data into the format expected by the API.
   *
   * Takes in an address object and returns a transformed version with
   * keys renamed and values processed as needed for the API call.
   *
   * Handles splitting street lines, converting state to abbreviation,
   * lowercasing email, and adding fraud detection properties if enabled.
   */
  transformAddressData = async (address = {}) => {
    const {
      addressLine1,
      addressLine2,
      addressLine3,
      addressUserName,
      stateList,
      zip,
      postalCode,
      email,
      ...remainingProps
    } = address
    const data = {
      firstName: addressUserName,
      email: email?.toLowerCase(),
      street1: addressLine1,
      street2: addressLine2,
      street3: addressLine3,
      state: stateList,
      type: 'Local',
      zipCode: zip || postalCode,
      ...remainingProps,
    }
    const accertifyProps = (await getFraudDetectionProperties()) || {}
    const profileFraudCheckEnabled =
      APPConfig?.getAppConfig()?.ProfileFraudCheckEnabled === 'true' || false
    if (profileFraudCheckEnabled) {
      data.additionalProperties = accertifyProps || {}
    }
    return data
  }

  /**
   * Adds a new address for the logged in account.
   *
   * Takes in address info, calls API to add it, displays success/error toast messages,
   * and refetches all addresses for account on success.
   *
   * @param {Object} addressInfo - Address details
   * @param {boolean} showToastMessage - Whether to show toast on success
   */
  addAddress = async (addressInfo, showToastMessage = false) => {
    delete addressInfo.submitButton
    const { setDefaultAddress = false, ...remainingProps } = addressInfo
    remainingProps.default = setDefaultAddress
    const postData = (await this.transformAddressData(remainingProps)) || {}
    const accountId = customerContainer.accountId
    const loadParams = {
      endPointName: 'addAccountAddress',
      pathParams: `${accountId}/addresses`,
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      showToastMessage &&
        toastState.setToastMessage(
          i18nTranslate(
            'accounts.addAddressSuccess',
            'Your address has been added.'
          ),
          true
        )
      await this.getAllAccountAddress(accountId)
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'accounts.addAddressFailure',
          'Sorry, we are unable to add your address. Please try again.'
        ),
        false
      )
    }
  }

  /**
   * Removes an address for the logged in account.
   *
   * Takes in address ID to remove, calls API to remove it,
   * displays success/error toast message, and refetches addresses on success.
   *
   * @param {Object} postData - Request body with address ID to remove
   */
  removeAddress = async postData => {}

  /**
   * Validates an updated address for the logged in account.
   *
   * Takes in updated address form data and existing address info.
   * Calls API to validate updated address.
   * On success, updates address via updateAccountAddress() and returns response.
   * On failure, displays error toast message and returns response.
   */
  validateUpdatedAddress = async (formdata, addressInfo) => {
    let addressAddResponse = {}
    let response = await addressContainer.originalAddressApiCall(formdata)
    if (this.isSuccessResponse(response)) {
      addressAddResponse = this.updateAccountAddress(formdata, addressInfo)
      return addressAddResponse
    } else {
      if (response.message === 'input.validation.failed') {
        response.message = alertMessageKeys(
          'Please correct the address to proceed.'
        )
      }
      toastState.setToastMessage(response.message, false)
      return response
    }
  }

  /**
   * Validates an original address submitted for the logged in account.
   *
   * Takes in address form data and existing address info.
   * Calls API to validate address.
   * On success, adds address via addAddress() and returns response.
   * On failure, displays error toast message and returns response.
   */
  validateOriginalAccountAddress = async (formdata, addressInfo) => {
    let addressAddResponse = {}
    let response = await addressContainer?.originalAddressApiCall(formdata)
    if (this.isSuccessResponse(response)) {
      addressAddResponse = this.addAddress(formdata, addressInfo)
      return addressAddResponse
    } else {
      if (response?.message === 'input.validation.failed') {
        response.message = alertMessageKeys(
          'Please correct the address to proceed.'
        )
      }
      toastState.setToastMessage(response?.message, false)
      return response
    }
  }

  /**
   * Updates the address for the given account ID by calling the API.
   *
   * Takes the address info object and a flag indicating if the address was updated.
   * Gets the address ID from the info object.
   * Transforms the address data and deletes the addressId property.
   * Calls the API endpoint with path params for account ID and address ID.
   * On success, shows a success toast and refreshes account data.
   * On failure, shows an error toast.
   */
  updateAccountAddress = async (addressInfo, isAddressUpadated = true) => {
    const addressId = addressInfo?.addressId || addressInfo.id
    const postData = (await this.transformAddressData(addressInfo)) || {}
    delete postData?.addressId
    const loadParams = {
      endPointName: 'updateAccountAddress',
      pathParams: `${customerContainer.accountId}/addresses/${addressId}`,
      postData,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      isAddressUpadated &&
        toastState.setToastMessage(
          i18nTranslate(
            'accounts.updateAddressSuccess',
            'Your address has been updated.'
          ),
          true
        )
      await this.getAllAccountAddress(customerContainer.accountId)
      await accountsContainer.getProfile(customerContainer.accountId)
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'accounts.updateAddressFailure',
          'Sorry, we are unable to update your address. Please try again.'
        ),
        false
      )
    }
  }

  /**
   * Updates the address for an account.
   *
   * Takes the form data and existing address info.
   * Extracts the setDefaultAddress flag into its own variable.
   * Sets the default field in the remaining form data based on the flag.
   * Gets the address ID from the existing address info.
   * Calls updateAccountAddress() to update the address via API with the combined info.
   */
  updateAddress = (formData, addressInfo) => {
    delete formData.submitButton
    const {
      firstName,
      lastName,
      setDefaultAddress = false,
      ...remainingProps
    } = formData

    remainingProps.default = setDefaultAddress
    remainingProps.firstName = firstName?.trim()
    remainingProps.lastName = lastName?.trim()
    const { addressId, id } = addressInfo
    this.updateAccountAddress({ ...remainingProps, id: addressId || id })
  }

  /**
   * Updates the default address for an account.
   *
   * Takes the address info object.
   * Creates a new object with the default property inverted.
   * Calls updateAccountAddress() with the new default address object.
   */
  updateDefaultAddress = addressInfo => {
    const defaultAddress = { ...addressInfo, default: !addressInfo.default }
    this.updateAccountAddress(defaultAddress)
  }

  /**
   * Converts a user account type string to an abbreviated code.
   *
   * @param {string} value - The account type string
   * @returns {string} The abbreviated account type code
   */
  getConversionType = value => {
    if (value === 'Retail Customer') {
      return 'RC'
    } else if (value === 'Preferred Customer/Member') {
      return 'PM'
    } else if (value === 'Brand Affiliate') {
      return 'BA'
    } else {
      return 'BE'
    }
  }

  /**
   * Fetches user consent data from the API.
   *
   * Calls fetchResponse() with the 'userConsent' endpoint.
   * Saves the response to this.consent.
   * Returns the API response.
   */
  getUserConsent = async () => {
    const loadParams = {
      endPointName: 'userConsent',
    }

    const response = await this.fetchResponse(loadParams)
    this.consent = response
    return response
  }
  /**
   * Updates the account type with the provided form data and profile data.
   * @param {Object} formData - The form data.
   * @param {Object} profileData - The profile data.
   * @returns {Promise} - A promise that resolves when the account type is updated.
   */
  updateAccountType = async (formData, profileData) => {
    const addressInfo = formData
    const { taxIdOptions } = formData
    const { consents } = await customerContainer.signupConsents()

    const addressId = addressInfo?.addressId || addressInfo.id
    const postData = (await this.transformAddressData(addressInfo)) || {}
    const {
      firstName = '',
      lastName = '',
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state = '',
      zip = '',
      country = '',
    } = addressInfo || ''

    // const { sponsorIDConvert } = profileData
    const accProperties = accountsContainer?.profileResponse?.properties || {}
    const spId =
      accProperties?.length > 0 &&
      accProperties?.find(list => list?.attributeId === 'sponsorId')

    const sponsorIDValue = spId?.value || ''
    let dateOfBirth = ''
    if (profileData?.dateOfBirth !== '' || profileData?.dateOfBirth !== null) {
      dateOfBirth =
        dateFormatDeps?.getNormalFormattedDate(profileData?.dateOfBirth) !==
          'NaN-NaN-NaN' &&
        dateFormatDeps?.getNormalFormattedDate(profileData?.dateOfBirth)
    }

    dateOfBirth = dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : ''
    const { createdBy, type1 } =
      accountsContainer?.profileResponse?.addresses?.[0] || ''
    const userName = profileData?.bEFirstName
      ? profileData?.bEFirstName
      : customerContainer?.profileResponse?.accounts?.name || ''
    const { default1 } =
      accountsContainer?.profileResponse?.addresses?.[0] || true
    const { validated } =
      accountsContainer?.profileResponse?.addresses?.[0] || false
    const id = customerContainer.accountId
    const type =
      this.getConversionType(customerContainer.type) +
      '_2_' +
      this.getConversionType(profileData.accountType)
    const { accountTypes } = accountsContainer?.nexGenAccountTypes
    let acList =
      accountTypes &&
      accountTypes.find(list => list.name === profileData.accountType)
    const accountIdConvert = acList?.id || ''

    const isToEnableIronClad =
      APPConfig?.getAppConfig()?.FEATURE_IRONCLAD_IMPLEMENTATION === 'true' ||
      false

    if (
      isToEnableIronClad ||
      (profileData?.refundAndPrivacyBrand && profileData?.termsOfUseBrand) ||
      (profileData?.refundAndPrivacy && profileData?.termsOfUse)
    ) {
      const consentInfo_1 = consents.find(
        consent =>
          consent.title === 'I acknowledge I have read Sales performance'
      )
      const consentInfo_2 = consents.find(
        consent =>
          consent.title === 'I have Read, Understand, Agree to BA agreement'
      )
      const consentInfo_3 = consents.find(
        consent => consent.title === 'PRIVACY_NOTICE'
      )
      const consentInfo_4 = consents.find(
        consent => consent.title === 'REFUND_POLICY'
      )

      const consentInfo_5 = consents.find(
        consent => consent.title === 'SALES_COMPENSATION_SUMMARY'
      )
      const consentInfo_6 = consents.find(
        consent => consent.title === 'POLICY_PROCEDURES'
      )
      const consentInfo_7 = consents.find(
        consent => consent.title === 'Terms & Conditions'
      )
      let userConsent = []

      const IronCladGroupId = accountsContainer?.IronCladGroupId
      const otherInfo = {
        browser: 'chrome',
        device: 'mobile',
        geoLocation:
          storeContainer.currentRegion ||
          getLocalStorage('currentRegion') ||
          '',
        ipAddress: this.consent.IPv4,
        id: IronCladGroupId,
      }
      const acceptanceInfoPage1 = {
        ...otherInfo,
        title: 'contracts_ironclad_page1',
        acceptanceData: accountsContainer?.acceptanceContractData1 || [],
      }
      const acceptanceInfoPage2 = {
        ...otherInfo,
        title: 'contracts_ironclad_page2',
        acceptanceData: accountsContainer?.acceptanceContractData2 || [],
      }
      const acceptanceInfoPage3 = {
        ...otherInfo,
        title: 'contracts_ironclad_page3',
        acceptanceData: accountsContainer?.acceptanceContractData3 || [],
      }

      if (
        consentInfo_1 &&
        consentInfo_2 &&
        consentInfo_3 &&
        !isToEnableIronClad
      ) {
        userConsent = [
          {
            id: consentInfo_1.id,
            consentId: consentInfo_1.id,
            browser: 'chrome',
            device: 'mobile',
            geoLocation:
              storeContainer.currentRegion ||
              getLocalStorage('currentRegion') ||
              '',
            ipAddress: this.consent.IPv4,
            createdTimestamp: new Date(),
            updatedTimestamp: new Date(),
          },
          {
            id: consentInfo_2.id,
            consentId: consentInfo_2.id,
            browser: 'chrome',
            device: 'mobile',
            geoLocation:
              storeContainer.currentRegion ||
              getLocalStorage('currentRegion') ||
              '',
            ipAddress: this.consent.IPv4,
            createdTimestamp: new Date(),
            updatedTimestamp: new Date(),
          },
          {
            id: consentInfo_3.id,
            consentId: consentInfo_3.id,
            browser: 'chrome',
            device: 'mobile',
            geoLocation:
              storeContainer.currentRegion ||
              getLocalStorage('currentRegion') ||
              '',
            ipAddress: this.consent.IPv4,
            createdTimestamp: new Date(),
            updatedTimestamp: new Date(),
          },
          {
            id: consentInfo_4.id,
            consentId: consentInfo_4.id,
            browser: 'chrome',
            device: 'mobile',
            geoLocation:
              storeContainer.currentRegion ||
              getLocalStorage('currentRegion') ||
              '',
            ipAddress: this.consent.IPv4,
            createdTimestamp: new Date(),
            updatedTimestamp: new Date(),
          },
          {
            id: consentInfo_5.id,
            consentId: consentInfo_5.id,
            browser: 'chrome',
            device: 'mobile',
            geoLocation:
              storeContainer.currentRegion ||
              getLocalStorage('currentRegion') ||
              '',
            ipAddress: this.consent.IPv4,
            createdTimestamp: new Date(),
            updatedTimestamp: new Date(),
          },
          {
            id: consentInfo_6.id,
            consentId: consentInfo_6.id,
            browser: 'chrome',
            device: 'mobile',
            geoLocation:
              storeContainer.currentRegion ||
              getLocalStorage('currentRegion') ||
              '',
            ipAddress: this.consent.IPv4,
            createdTimestamp: new Date(),
            updatedTimestamp: new Date(),
          },
          {
            id: consentInfo_7.id,
            consentId: consentInfo_7.id,
            browser: 'chrome',
            device: 'mobile',
            geoLocation:
              storeContainer.currentRegion ||
              getLocalStorage('currentRegion') ||
              '',
            ipAddress: this.consent.IPv4,
            createdTimestamp: new Date(),
            updatedTimestamp: new Date(),
          },
        ]
      }
      isToEnableIronClad &&
      accountsContainer?.convertAccoutType != 'Preferred Customer/Member'
        ? userConsent.push(
            acceptanceInfoPage1,
            acceptanceInfoPage2,
            acceptanceInfoPage3
          )
        : isToEnableIronClad
        ? userConsent.push(acceptanceInfoPage1)
        : ''

      const locale = APPConfig?.getActiveAppConfig()?.defaultLocale //en_US
      const language = locale?.split('_')?.pop().toLowerCase() // us
      let email, phone, tokenExTaxData, dupCharTokenExTaxData
      let customer = {}

      const sponsorIdAttribute = {
        attributeId: 'sponsorId',
        value: sponsorIDValue,
      }

      const taxIdAttribute = {
        attributeId: 'taxType',
        value:
          type === 'RC_2_BA' || type === 'PM_2_BA'
            ? language === 'us'
              ? 'Social Security Number'
              : 'Social Insurance Number'
            : language === 'us'
            ? 'Employer Identification Number'
            : taxIdOptions || profileData?.taxIdOptions,
      }
      let localCountry = storeContainer.storeIDValue === 'Canada' ? 'CA' : 'US'
      let addressType =
        localCountry === 'US' || localCountry === 'CA' ? 'Local' : 'HOME'
      if (type === 'RC_2_BA' || type === 'PM_2_BA') {
        if (
          taxIDContainer &&
          taxIDContainer?.taxTokenExData?.token !== undefined &&
          taxIDContainer?.taxTokenExData?.success === 'true'
        ) {
          tokenExTaxData = taxIDContainer?.taxTokenExData?.token
          dupCharTokenExTaxData = taxIDContainer?.taxTokenExDupCharData?.token
        }
        ;(email = addressInfo?.email),
          (phone = addressInfo?.phone),
          (customer = {
            dateOfBirth,
          })
      } else if (type === 'BA_2_BE') {
        if (
          taxIDContainer &&
          taxIDContainer?.taxTokenExData?.token !== undefined &&
          taxIDContainer?.taxTokenExData?.success === 'true'
        ) {
          tokenExTaxData = taxIDContainer?.taxTokenExData?.token
          dupCharTokenExTaxData = taxIDContainer?.taxTokenExDupCharData?.token
        }
        ;(email = addressInfo?.businessEmail),
          (phone =
            addressInfo?.businessPhoneNumber ||
            profileData?.businessPhoneNumber),
          (customer = { dateOfBirth })
      } else if (type === 'RC_2_PM') {
        phone = profileData?.phone
      }
      const taxNumUnformatted = {
        attributeId: 'taxNumberUnformatted',
        value: dupCharTokenExTaxData,
      }
      const accertifyProps = (await getFraudDetectionProperties()) || {}
      const profileFraudCheckEnabled =
        APPConfig?.getAppConfig()?.ProfileFraudCheckEnabled === 'true' || false
      if (profileFraudCheckEnabled) {
        customer.customProperties = accertifyProps || {}
      }
      const postDataObj = {
        conversionType: type,
        userConsent: userConsent,
        account: {
          id: id,
          name: userName,
          typeId: accountIdConvert,
          type: profileData.accountType,
          ...(type !== 'RC_2_PM' ? { taxNumber: tokenExTaxData || '' } : {}),
          phoneNumber: phone,
          ...(type !== 'RC_2_PM'
            ? {
                addresses: [
                  {
                    firstName,
                    lastName,
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    country,
                    zip,
                    email,
                    phone,
                    type: addressType,
                    validated: false,
                  },
                ],
              }
            : { addresses: [] }),
          properties:
            type !== 'RC_2_PM'
              ? [sponsorIdAttribute, taxIdAttribute, taxNumUnformatted]
              : [sponsorIdAttribute],
          customer: customer || {},
        },
      }

      const postDataObjSMSVerify = {
        conversionType: type,
        userConsent: userConsent,
        account: {
          id: id,
          name: userName,
          typeId: accountIdConvert,
          type: profileData.accountType,
          phoneNumber:
            customerContainer?.profileResponse?.accounts?.phoneNumber,
          properties: [sponsorIdAttribute],
          customer: { customProperties: accertifyProps } || {},
        },
      }

      const loadParams = {
        endPointName: 'updateAccountType',
        pathParams: `${id}/upgrade`,
        postData: enablePhoneVerification()
          ? postDataObjSMSVerify
          : postDataObj,
      }
      const response = await this.fetchResponse(loadParams)
      trackAccountUpgrade(
        enablePhoneVerification() ? postDataObjSMSVerify : postDataObj
      )

      if (this.isSuccessResponse(response)) {
        deleteFromSessionStorage('conv-sponsor')
        deleteFromSessionStorage('switchsponsor')
        deleteFromSessionStorage('mem')

        accountsContainer.signUpIronCladCheck = {
          clickWrap1: false,
          clickWrap2: false,
          clickWrap3: false,
        }
        accountsContainer.convertAccoutType = ''
        const previousAccountType = getLocalStorage('accountType') || ''
        deleteFromLocalStorage('accountType')
        setLocalStorage('accountType', profileData.accountType)
        toastState.setToastMessage(
          i18nTranslate(
            'accounts.updateAccountSuccess',
            'Account type updated successfully'
          ),
          true,
          6000
        )
        await this.getAllAccountAddress(customerContainer.accountId)
        await accountsContainer?.getProfile(customerContainer.accountId)
        await customerContainer?.getProfile()
        TagManager.dataLayer({
          dataLayer: {
            event: 'AccountTypeConversion',
            pagePath: pageNames.b2bAccountTypeConvert,
            pageTitle: 'Account Type Convert',
            previousAccountType,
            currentAccountType: response.accountType || null,
            accountid: response.id || null,
            name: response.name || null,
            platform: 'equinox',
          },
        })
        const taxInfoMsg = i18nTranslate(
          'accounts.taxInfo',
          'A Business Tax Number is required to be paid. Please visit V&G Preferences to add.'
        )

        if (type === 'BA_2_BE' && enableJITSU()) {
          modalState.setModalMessage(
            taxInfoMsg,
            i18nTranslate('modaView.buttonText', 'OK'),
            (this.props = { customClassName: 'tax-info-modal-message' })
          )
        }

        this.pageProfileRedirect = true
        scrollState.scrollToTop(0)
      } else {
        const responseCode = response?.code || ''
        let updgradeDuplicateTaxId = false
        updgradeDuplicateTaxId =
          responseCode === 'EOACSU000015' || responseCode === 'NXUSER_231'
            ? true
            : false
        if (updgradeDuplicateTaxId) {
          let classElement = document?.querySelector(
            '[customclassname="tax-info"]'
          )
          classElement?.parentElement?.classList?.add('duplicate-info-exist')
        }

        const errorMessage =
          response?.message ||
          i18nTranslate(
            'accounts.updateAccountFailure',
            'Unable to update account type, please try again'
          )
        toastState.setToastMessage(errorMessage, false)
        this.pageProfileRedirect = false
      }
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'accounts.selectConsent',
          'Select terms and condition to continue.'
        ),
        false
      )
    }
  }
}

const accountsAddressContainer = new AccountsAddressContainer()

export { accountsAddressContainer }
export default accountsAddressContainer
