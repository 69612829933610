import { i18nTranslate } from 'src/utils'
import isArray from 'lodash/isArray'
import { observable } from 'mobx'
import { CommonContainer } from 'src/models/Common'
import { toastState } from 'src/views/components'

/**
 * OrderHistoryContainer class that extends CommonContainer.
 * This is likely the container component for the OrderHistory view.
 */
class OrderHistoryContainer extends CommonContainer {
  @observable otherUsers = false
  @observable orderDetailsByIdResponse = {}
  @observable isGetOrderDetailsApiIsInProgress = false
  /**
   * Retrieves all orders based on the provided parameters.
   *
   * @param {Object} orderRefineData - Filter parameters to refine the orders returned
   * @param {Object} params - Pagination parameters
   * @param {boolean} showToast - Whether to show toast message on error
   * @returns {Promise} Response from the API
   */
  getAllOrders = async (orderRefineData, params, showToast = true) => {
    let filterParams = params
    if (orderRefineData) {
      filterParams.filter = orderRefineData
    }
    const loadParams = {
      endPointName: 'getAllOrders',
      pathParams: '',
      queryParams: filterParams,
    }
    const response = await this.fetchResponse(loadParams)
    if (!this.isSuccessResponse(response) && showToast) {
      const message = response?.message || ''

      message && toastState.setToastMessage(message)
    }
    return response
  }

  /**
   * Retrieves suggested orders based on the provided parameters.
   *
   * @returns {Promise} Response from the API containing suggested orders
   */
  getSuggestedOrders = async () => {
    const params = {
      page: 1,
      size: 1,
    }
    const loadParams = {
      endPointName: 'suggestedOrders',
      pathParams: '',
      queryParams: params,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }
  /**
   * Retrieves an order by ID.
   *
   * @param {string} orderId - The ID of the order to retrieve.
   * @returns {Promise} Response from the API containing the order details if found, error otherwise.
   */
  getOrderById = async orderId => {
    const loadParams = {
      endPointName: 'getOrderById',
      pathParams: orderId,
      queryParams: { lookup: false },
    }
    const response = await this.fetchResponse(loadParams)
    if (!this.isSuccessResponse(response)) {
      let message = ''
      if (response?.code === 'OMS_ORD_0001') {
        message = `${i18nTranslate(
          'orderHistory.orderWithId',
          'Order with id'
        )} ${orderId} ${i18nTranslate(
          'orderHistory.isNotFound',
          'is not found'
        )}`
      } else {
        message = response?.message || ''
      }
      message && toastState.setToastMessage(message)
    }
    this.orderDetailsByIdResponse = response
    return response
  }

  /**
   * Sets the otherUsers property to the provided isOtherUsers value.
   */
  otherUser(isOtherUsers) {
    this.otherUsers = isOtherUsers
  }

  /**
   * Cancels an item in an order.
   *
   * @param {Object} orderDetails - Details of the order containing the item to cancel
   * @param {Object} params - Parameters for the cancel item request
   * @returns {Promise} Response from the API after attempting to cancel the item
   */
  cancelItem = async (orderDetails, params) => {
    const loadParams = {
      endPointName: 'cancelItem',
      postData: params,
      pathParams: `${orderDetails.orderId}/orderitems/${orderDetails.itemId}`,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'order.cancelItemSuccess',
          'An item in your order has been canceled.'
        ),
        true
      )
    } else {
      const errorMessage =
        (response && response.message) ||
        i18nTranslate(
          'order.cancelItemFailure',
          'Sorry, we are unable to cancel your order.'
        )
      toastState.setToastMessage(errorMessage, false)
    }
    return response
  }

  /**
   * Submits a return request for an item in an order.
   *
   * @param {Object} orderDetails - Details of the order containing the item to return
   * @param {Object} params - Parameters for the return item request
   * @returns {Promise} Response from the API after attempting to return the item
   */
  returnItem = async (orderDetails, params) => {
    const loadParams = {
      endPointName: 'returnItem',
      postData: params,
      pathParams: `${orderDetails.orderId}/orderitems/${orderDetails.itemId}/returns`,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'order.returnItemSuccess',
          'An item in your order has been submitted for a return.'
        ),
        true
      )
    } else {
      const errorMessage =
        (response && response.message) ||
        i18nTranslate(
          'order.returnItemFailure',
          "Sorry, this item isn't available for a return."
        )
      toastState.setToastMessage(errorMessage, false)
    }
    return response
  }
  /**
   * Cancels an order.
   *
   * @param {Object} orderDetails - Details of the order to cancel
   * @param {Object} params - Parameters for the cancel order request
   * @returns {Promise} Response from the API after attempting to cancel the order
   */
  cancelOrder = async (orderDetails, params) => {
    const loadParams = {
      endPointName: 'cancelItem',
      postData: params,
      pathParams: `${orderDetails.orderId}`,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate('order.cancelSuccess', 'Your order has been canceled.'),
        true
      )
    } else {
      const errorMessage =
        (response && response.message) ||
        i18nTranslate(
          'order.cancelFailure',
          'Sorry, we are unable to cancel your order. Please try again.'
        )
      toastState.setToastMessage(errorMessage, false)
    }
    return response
  }

  /**
   * Gets payload data for foreign order limit API by looping through order items.
   * Checks if item is a gift item, if not calls getProductDetailsForForeignOrderLimitAPI
   * to get product details and adds to postData array.
   *
   * @param {Array} orderItems - Array of order items
   * @returns {Promise} Promise resolving to array of product details for foreign order limit API payload
   */
  getPayloadDataForForeignOrderLimitAPI = (orderItems = []) => {
    const postData = []
    if (isArray(orderItems) && orderItems?.length > 0) {
      orderItems?.forEach((orderItem, key) => {
        const itemInfo = orderItem?.item?.itemInfo || {}
        const { isGiftItem = false } = itemInfo?.otherProperties || {}
        if (!isGiftItem) {
          const productDetails =
            this.getProductDetailsForForeignOrderLimitAPI(itemInfo) || []
          postData?.push(...productDetails)
        }
      })
    }
    return postData
  }

  /**
   * Retrieves product details for the Foreign Order Limit API.
   *
   * Constructs the expected payload by getting SKU info from the provided itemInfo.
   * Handles cases where there are special product SKUs that need to be included.
   *
   * @param {Object} itemInfo - Details about the item from the order
   * @returns {Array} Array of SKU payload objects
   */
  getProductDetailsForForeignOrderLimitAPI = (itemInfo = {}) => {
    const postData = []
    const getSkuData = (sku = {}, productType = '') => {
      return {
        skuId: sku?.skuId || '',
        productId: sku?.productId || '',
        type: productType || sku?.type || '',
      }
    }
    const specialProduct = itemInfo?.specialProducts || []
    if (specialProduct?.length > 0) {
      const skuInfo = getSkuData(itemInfo?.sku, itemInfo?.productType)
      postData.push(skuInfo)
      specialProduct?.forEach((sku, index) => {
        const specialProductSkuInfo = getSkuData(sku)
        postData.push(specialProductSkuInfo)
      })
    } else {
      const normalSkuInfo = getSkuData(itemInfo?.sku, itemInfo?.productType)
      postData.push(normalSkuInfo)
    }
    return postData
  }
}

const orderHistoryContainer = new OrderHistoryContainer()

export { OrderHistoryContainer, orderHistoryContainer }
export default orderHistoryContainer
