import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { productDeps } from 'src/deps'
import {
  ProductAddToList,
  // ProductSubscribeButton,
  ProductAddToCart,
} from './ProductButtons'
import { ProductQuantity } from './ProductQuantity'
import { ProductInventory } from './ProductInventory'
import { NuskinSubscriptionButton } from 'src/views/components/SubscriptionComponents/NuskinSubscriptionButton'
import { checkEnableButton, getLiveEventStatus } from 'src/utils'
import { cartContainer } from 'src/models'
import { APPConfig } from 'config/appConfig'
import { observable } from 'mobx'

@observer
class IndividualProductQuantityAndButtons extends React.Component {
  @observable isCartDisabled = false
  @observable isSubscribeBtnDisabled = false
  enableLiveEvent = getLiveEventStatus()

  componentDidMount() {
    if (cartContainer.isDisableAddToCart) {
      this.isCartDisabled = true
    }
    if (cartContainer.isDisableSubscribeBtn) {
      this.isSubscribeBtnDisabled = true
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.isAddToCartDisable !== cartContainer.isDisableAddToCart) {
      if (cartContainer.isDisableAddToCart) {
        this.isCartDisabled = true
      } else {
        this.isCartDisabled = false
      }

      if (
        prevProps?.isSubscribeBtnDisabled !==
        cartContainer.isDisableSubscribeBtn
      ) {
        this.isSubscribeBtnDisabled = true
      } else {
        this.isSubscribeBtnDisabled = false
      }
      if (cartContainer.isDisableSubscribeBtn) {
        this.isSubscribeBtnDisabled = true
      }
      this.renderQtyField()
    }
  }
  renderButtonGroup = (isEnableAddToCartBtn, isSkuBundleOnly) => {
    const inventory = this.props?.product?.inventoryProperties || {}
    const inventoryForQty = this.props.inventory
    const { product, ...remainingProps } = this.props
    const { subscriptions } = product
    const selectedSku = productDeps.getUserSelectedSku(product)
    const isToEnableSubscription = productDeps.isToEnableSubscription(
      this.props
    )
    const isReplacementItem = productDeps.getIsReplacementItem(selectedSku)
    return (
      !this.props.isFromSubscription && (
        <Row noGutters className="w-100 my-3">
          {this.enableLiveEvent !== 'true' && (
            <div className="d-flex align-items-end flex-column cr-gap flex-sm-column flex-md-row mb-2 w-100">
              <div className="w-100">
                {/* <ProductSubscribeButton {...this.props} isQuickview="true" /> */}
                <NuskinSubscriptionButton
                  isSkuBundleOnly={isSkuBundleOnly}
                  isToEnableSubscription={isToEnableSubscription}
                  {...this.props}
                  isQuickview="true"
                  isFromQuickView={this.props.isFromQuickView}
                />
              </div>

              <div className="w-100">
                <ProductAddToList
                  {...this.props}
                  disabled={isSkuBundleOnly || isReplacementItem}
                />
              </div>
            </div>
          )}

          <Col className="add-to-cart-button w-100">
            <ProductAddToCart
              isSkuBundleOnly={isSkuBundleOnly}
              inventories={inventory}
              {...product}
              {...remainingProps}
              isQuickview="true"
              isEnableAddToCartBtn={isEnableAddToCartBtn}
            />
            {isSkuBundleOnly && (
              <p className="availability-text paragraph-m-bold text-danger">
                Item not available for individual purchase
              </p>
            )}
          </Col>
        </Row>
      )
    )
  }

  renderQtyField = (isSkuBundleOnly = false) => {
    const inventory = this.props.inventory
    const { product } = this.props
    const selectedSku = productDeps.getUserSelectedSku(product)
    let availableChannelsList = ''
    if (selectedSku) {
      availableChannelsList =
        selectedSku?.skuProperties?.availableChannels || ''
    } else {
      availableChannelsList = product.availableChannels || ''
    }
    const isEnableAddToCartBtn = checkEnableButton(availableChannelsList, 'web')
    const isEnableSubscriptionBtn = checkEnableButton(
      availableChannelsList,
      'subscription'
    )
    const isReplacementItem = productDeps.getIsReplacementItem(selectedSku)

    let qtyEnable =
      !isSkuBundleOnly &&
      !isReplacementItem &&
      (isEnableAddToCartBtn === true ||
        isEnableSubscriptionBtn === true ||
        !this.isCartDisabled ||
        !this.isSubscribeBtnDisabled)

    return (
      qtyEnable && (
        <Row className="align-items-center position-relative" noGutters>
          {
            <ProductQuantity
              {...this.props}
              inventory={inventory}
              edit={this.props.edit}
            />
          }
        </Row>
      )
    )
  }

  render() {
    const { isQuickView = false, product, productCount } = this.props
    const inventory = this.props.inventory
    const { isFromEditSubscription, isFromSubscription } = this.props
    const disableClassName =
      isFromEditSubscription || isFromSubscription ? 'disable-selection' : ''

    const { isBundle, isCollection, transactionPrice } = product

    const selectedSku = productDeps.getUserSelectedSku(product)
    let availableChannelsList = ''
    if (selectedSku) {
      availableChannelsList =
        selectedSku?.skuProperties?.availableChannels || ''
    } else {
      availableChannelsList = product.availableChannels || ''
    }
    const isEnableAddToCartBtn = checkEnableButton(availableChannelsList, 'web')
    const isEnableSubscriptionBtn = checkEnableButton(
      availableChannelsList,
      'subscription'
    )

    const isSkuBundleOnly =
      selectedSku?.properties?.productStatus === 'bundleOnly' ? true : false

    if (isBundle || isCollection) {
      return ''
    }
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    return (
      <section className={disableClassName + ' quantity-sec'}>
        {this.renderQtyField(isSkuBundleOnly)}
        <ProductInventory {...this.props} inventory={inventory} />
        {/* {enableStorePickup && !isQuickView && !isFromSubscription && (
          <StorePickUpOption {...product} />
        )} */}
        {/* {(isEnableAddToCartBtn === true || isEnableSubscriptionBtn === true) && */}
        {this.renderButtonGroup(isEnableAddToCartBtn, isSkuBundleOnly)}
      </section>
    )
  }
}

export { IndividualProductQuantityAndButtons }
