import React from 'react'
import { Link } from 'react-router-dom'
import { ApiContent } from 'src/utils'
import { observer } from 'mobx-react'

@observer
class LinkWithLocale extends React.Component {
  static contextType = ApiContent
  constructor(props, context) {
    super(props)
    this.context = context
  }
  render() {
    let modifiedTo = {}
    modifiedTo = this.props.to.pathname || this.props.to

    return (
      <Link {...this.props} to={modifiedTo}>
        {this.props.children}
      </Link>
    )
  }
}

export { LinkWithLocale }
