import * as yup from 'yup'
import i18n from 'i18next'

import {
  accountsContainer,
  cartContainer,
  customerContainer,
  taxIDContainer,
  userContainer,
} from 'src/models'
import { acceptedCreditCards } from './CardDeps'

import {
  emailErrorMsg,
  emailDomainErrorMsg,
  passwordErrorMsg,
  confirmPasswordErrorMsg,
  firstNameErrorMsg,
  lastNameErrorMsg,
  phoneNumberErrorMsg,
  securityQuestionErrorMsg,
  securityAnswerErrorMsg,
  accountTypeErrorMsg,
  addressErrorMsg,
  cityErrorMsg,
  stateErrorMsg,
  countryErrorMsg,
  requiredErrorMsg,
  invalidCouponErrorMsg,
  invalidLoyaltyErrorMsg,
  invalidLoyaltyAmountErrorMsg,
  CardErrorMsg,
  cardCvvErrorMsg,
  expiryDateErrorMsg,
  zipCodeErrorMsg,
  poNumberError,
  budgetNumberError,
  organizationRequiredErrorMsg,
  taxIdErrorMsg,
  taxIdEmployeeErrorMsg,
  dunsErrorMsg,
  teamNameErrorMsg,
  costCenterErrorMsg,
  listNameErrorMsg,
  userNameErrorMsg,
  reasonCodeErrorMsg,
  industryErrorMsg,
  industrySizeErrorMsg,
  addressUserNameErrorMsg,
  bEFirstNameErrorMessage,
  maxLengthErrorMsg,
  integerNumberErrorMsg,
  dateErrorMsg,
  alphaErrorMsg,
  regexErrorMsg,
  urlErrorMsg,
  zipCodeRequiredMsg,
  postalCodeErrorMsg,
  // userExistErrorMessage,
  userExistRequiredErrorMessage,
  sponsorIdErrorMsg,
  sponsorIdRetailErrorMsg,
  dateOfBirthErrorMsg,
  dateOfBirthRestrictErrorMsg,
  signupTaxIdErrorMsg,
  signupAddressLine1ErrorMsg,
  signupCityErrorMsg,
  signupPostalCodeErrorMsg,
  signupZipCodeErrorMsg,
  signupStateErrorMsg,
  refundAndPrivacyErrorMsg,
  termsOfUseErrorMsg,
  // marketingEmailErrorMsg,
  // signupSmsErrorMsg,
  // signupRewardsErrorMsg,
  refundAndPrivacyBrandErrorMsg,
  termsOfUseBrandErrorMsg,
  invalidStoreCreditsMsg,
  insufficientStoreCreditsMsg,
  storeCreditsExceedingCartTotalMsg,
  invalidLoyaltyZeroErrorMsg,
} from './validationErrorMessages'
import { countryZipcodesRegex } from './zipcodeRegex'
import { getLocaleCodeFromUrl, isLATAM } from 'src/utils/localeUtils'
import {
  parsePhoneNumberField,
  validLibMobilePhoneNumber,
} from 'src/utils/signUpUtils'
import {
  emailValidation,
  getInputFormJSON,
  getLiveEventStatus,
} from 'src/utils'
import { countryCodes } from 'src/static/geography'
import { APPConfig, convertToBoolean } from 'config/appConfig'
import dateFormatDeps from './DateFormatDeps'

/**
 * Generates a Yup schema to validate an email input.
 *
 * @param {Object} props - Options for the email schema
 * @param {string} [props.requiredErrorText] - Error message for required validation
 * @param {string} [props.invalidErrorText] - Error message for invalid email format
 * @param {string} [props.invalidDomainErrorText] - Error message for invalid email domain
 * @param {string} [props.inputName='email'] - Key name for the email input in the schema
 *
 * @returns {Object} Yup schema object to validate an email input
 */
function getEmailSchema(props) {
  const emailSchema = {}

  const {
    requiredErrorText = emailErrorMsg,
    invalidErrorText = emailErrorMsg,
    invalidDomainErrorText = emailDomainErrorMsg,
    inputName = inputName || 'email',
  } = props || {}

  emailSchema[inputName] = yup
    .string()
    .email(invalidErrorText)
    .max(50, invalidErrorText)
    .required(requiredErrorText)
    .test('emailValidation', invalidErrorText, function (value) {
      if (!emailValidation(value)) {
        return this.createError({ message: invalidErrorText })
      } else if (value) {
        return validateEmailDomain(
          value,
          this.createError({ message: invalidDomainErrorText })
        )
      }
      return false
    })
  return emailSchema
}

/**
 * Generates a Yup schema to validate a business email input.
 *
 * @param {Object} props - Options for the business email schema
 * @returns {Object} Yup schema object to validate a business email input
 */
function getBusinessEmailSchema(props) {
  const emailSchema = {}

  const {
    requiredErrorText = emailErrorMsg,
    invalidErrorText = emailErrorMsg,
    inputName = inputName || 'businessEmail',
  } = props || {}

  emailSchema[inputName] = yup
    .string()
    .email(invalidErrorText)
    .max(50, invalidErrorText)
    .required(requiredErrorText)
  return emailSchema
}

/**
 * Generates a Yup schema to validate if a user already exists with the given email.
 *
 * @param {Object} props - Options for the user exists schema
 * @returns {Object} Yup schema object to validate if a user exists
 */
function getUserExistSchema(props) {
  let userExistSchema = {}

  const {
    requiredErrorText = userExistRequiredErrorMessage,
    // invalidErrorText = userExistErrorMessage,
    inputName = inputName || 'email',
  } = props || {}

  // let repeatedEmail = ''
  // let response = ''
  // let regexEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

  userExistSchema[inputName] = yup
    .string()
    .email(requiredErrorText)
    .max(50, requiredErrorText)
    .required(requiredErrorText)

  //Commenting as per ticket 3285 ---Email Valid Check is handled from backend
  // .test('emailValidCheck', invalidErrorText, async value => {
  //   if (repeatedEmail != value && regexEmail.test(value)) {
  //     repeatedEmail = value
  //     response = await customerContainer?.signupEmailIdValidation(value)
  //   }

  //   return response.userExists ? false : true
  // })

  return userExistSchema
}

/**
 * Validates if the given email domain has valid MX records.
 *
 * @param {string} email - The email to validate
 * @returns {Promise<boolean>} Promise resolving to true if email domain has valid MX records, false otherwise
 */
function validateEmailDomain(email, createError) {
  return new Promise(async (resolve, reject) => {
    let response = 'false'
    if (email) {
      const postData = {
        email,
      }
      response = await customerContainer?.validateEmailMxLookup({ postData })
    }
    resolve(
      !response?.isEmailValid && !response?.mxRecordExist ? createError : true
    )
  })
}

/**
 * Validates store credit input.
 * - Accepts numbers with up to 2 decimal places.
 * - Value must be greater than 0.
 * - Value cannot exceed customer's current store credit points.
 * - Value cannot exceed total unpaid cart amount.
 * @param {Object} props - Validation schema props
 * @returns {Object} Yup validation schema
 */
function getStoreCredits(props) {
  const StoreCreditsSchema = {}
  // accepts upto 2 digit decimal numbers like "1", "1.5" , "1.50"
  //Reference:https://stackoverflow.com/questions/8609714/regex-greater-than-zero-with-2-decimal-places
  const priceFormat = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/

  const {
    requiredErrorText = invalidStoreCreditsMsg,
    invalidErrorText = invalidStoreCreditsMsg,
    inputName = 'storeCredit',
    currentPoints = 0,
    totalAmountUnpaid = 0,
  } = props || {}

  StoreCreditsSchema[inputName] = yup
    .string()
    .test('storeCreditTest', invalidErrorText, function (value) {
      return parseFloat(value) > 0
    })
    .test('storeCreditTest', insufficientStoreCreditsMsg, function (value) {
      return !(parseFloat(value) > currentPoints)
    })
    .test(
      'storeCreditTest',
      storeCreditsExceedingCartTotalMsg,
      function (value) {
        return !(parseFloat(value) > totalAmountUnpaid)
      }
    )
    .matches(priceFormat, invalidErrorText)
    .required(requiredErrorText)

  return StoreCreditsSchema
}

/**
 * Generates Yup schema to validate password input.
 * Checks for:
 * - At least one lowercase letter
 * - At least one uppercase letter
 * - At least one number
 * - At least one special character
 * - Minimum 8 characters length
 */
function getPasswordSchema(props) {
  const passwordSchema = {}

  const {
    requiredErrorText = passwordErrorMsg,
    invalidErrorText = passwordErrorMsg,
    inputName = 'password',
  } = props || {}

  const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-])[A-Za-z\d!-\/:-@[-`{-~]{8,}$/

  passwordSchema[inputName] = yup
    .string()
    .matches(passwordRegExp, invalidErrorText)
    .max(90, invalidErrorText)
    .required(requiredErrorText)

  return passwordSchema
}

/**
 * Generates Yup schema to validate credit card first name input.
 * Checks for:
 * - Only alphabets and spaces allowed
 * - Minimum 2 characters length
 * - Maximum 35 characters length
 * - Required field
 */
function getCardNameSchema(props) {
  const cardNameSchema = {}
  const {
    requiredErrorText = bEFirstNameErrorMessage,
    invalidErrorText = bEFirstNameErrorMessage,
    inputName = 'cardFirstName',
  } = props || {}

  const alphabetsAndSpacesRegEx =
    /^[a-zA-ZÀ-ú-ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ'\s]*$/

  const enableLiveEvent = convertToBoolean(getLiveEventStatus())

  if (enableLiveEvent) {
    cardNameSchema[inputName] = yup
      .string()
      .min(2, invalidErrorText)
      .max(35, invalidErrorText)
      .required(requiredErrorText)
  } else {
    cardNameSchema[inputName] = yup
      .string()
      .matches(alphabetsAndSpacesRegEx, invalidErrorText)
      .min(2, invalidErrorText)
      .max(35, invalidErrorText)
      .required(requiredErrorText)
  }

  return cardNameSchema
}

/**
 * Generates Yup schema to validate confirm password input.
 * Checks that confirm password input matches password input.
 *
 * @param {Object} props - Options
 * @param {string} [props.requiredErrorText] - Error text if value missing
 * @param {string} [props.invalidErrorText] - Error text if values don't match
 * @param {string} [props.inputName='confirmPassword'] - Name of confirm password input
 * @param {string} [props.passwordInputName='password'] - Name of password input
 * @returns {Object} Yup schema
 */
function getConfirmPasswordSchema(props) {
  const confirmPasswordSchema = {}

  const {
    requiredErrorText = confirmPasswordErrorMsg,
    invalidErrorText = confirmPasswordErrorMsg,
    inputName = 'confirmPassword',
    passwordInputName = 'password',
  } = props || {}

  confirmPasswordSchema[inputName] = yup
    .string()
    .oneOf([yup.ref(passwordInputName), null], invalidErrorText)
    .max(90, invalidErrorText)
    .required(requiredErrorText)

  return confirmPasswordSchema
}

// @todo need to use as name schema for first & last name
/**
 * Generates Yup schema to validate first name, last name or address user name input.
 * Checks for:
 * - Only alphabets and spaces allowed
 * - Minimum 1 character length
 * - Maximum 35 characters length
 * - Required field
 * @param {Object} props - Options to configure the schema
 * @param {boolean} [props.isLastName] - Whether this is for a last name field
 * @param {boolean} [props.isAddressUserName] - Whether this is for an address user name field
 * @param {string} [props.requiredErrorText] - Error text if required validation fails
 * @param {string} [props.invalidErrorText] - Error text if other validation fails
 * @param {string} [props.inputName='firstName'] - Name of the input field
 * @returns {Object} Yup schema
 */
function getNameSchema(props) {
  const nameSchema = {}
  const isLastName = props?.isLastName || false
  const isAddressUserName = props?.isAddressUserName || false
  const errorMessage = isAddressUserName
    ? addressUserNameErrorMsg
    : isLastName
    ? lastNameErrorMsg
    : firstNameErrorMsg
  const {
    requiredErrorText = errorMessage,
    invalidErrorText = errorMessage,
    inputName = 'firstName',
  } = props || {}
  const locale = APPConfig?.getActiveAppConfig()?.defaultLocale //en_US
  const getRegionInputJSON = getInputFormJSON(locale) || {}
  const isLatamRegion = isLATAM(locale)
  const alphabetsAndSpacesRegEx =
    /^[a-zA-ZÀ-ú-ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ'\s]*$/
  //Adding Above Regex for vietnamese characters
  const enableLiveEvent = convertToBoolean(getLiveEventStatus())

  if (enableLiveEvent) {
    nameSchema[inputName] = yup
      .string()
      .min(1, invalidErrorText)
      .max(35, invalidErrorText)
      .required(requiredErrorText)
  } else if (isLatamRegion) {
    nameSchema[inputName] = yup
      .string()
      .matches(getRegionInputJSON?.name?.regEx || invalidErrorText)
      .min(1, invalidErrorText)
      .max(40, invalidErrorText)
      .required(requiredErrorText)
  } else {
    nameSchema[inputName] = yup
      .string()
      .matches(alphabetsAndSpacesRegEx, invalidErrorText)
      .min(1, invalidErrorText)
      .max(35, invalidErrorText)
      .required(requiredErrorText)
  }

  return nameSchema
}

/**
 * Validates the business legal first name input.
 * @param {Object} props - The props object containing validation rules.
 * @returns {Object} businessLegalNameSchema - The schema for validating the business legal first name.
 */
function getBusinessLegalNameSchema(props) {
  const businessLegalNameSchema = {}
  const errorMessage = bEFirstNameErrorMessage
  const { invalidErrorText = errorMessage, inputName = 'bEFirstName' } =
    props || {}

  businessLegalNameSchema[inputName] = yup
    .string()
    .min(2, invalidErrorText)
    .max(120, invalidErrorText)
    .required(invalidErrorText)

  return businessLegalNameSchema
}

/**
 * Validates the organization name input.
 * @param {Object} props - The props object containing validation rules.
 * @returns {Object} nameSchema - The schema for validating the organization name.
 */
function getOrganizationNameSchema(props) {
  const nameSchema = {}
  const {
    requiredErrorText = organizationRequiredErrorMsg,
    invalidErrorText = organizationRequiredErrorMsg,
    inputName = 'organizationName',
  } = props || {}

  const alphabetsAndSpacesRegEx = /^[a-zA-Z0-9 ]*$/

  nameSchema[inputName] = yup
    .string()
    .matches(alphabetsAndSpacesRegEx, invalidErrorText)
    .min(3, invalidErrorText)
    .max(15, invalidErrorText)
    .required(requiredErrorText)

  return nameSchema
}

/**
 * Validates a phone number input.
 * @param {Object} props - The props containing the validation rules and schema.
 * @returns {Object} phoneSchema - The schema for validating the phone number.
 */
function getPhoneSchema(props) {
  const phoneSchema = {}

  const {
    requiredErrorText = phoneNumberErrorMsg,
    invalidErrorText = phoneNumberErrorMsg,
    inputName = inputName || 'phoneNumber',
    min = min || 9,
    max = max || 15,
  } = props || {}

  const phoneRegExp = /^[+0-9-() ]*$/

  phoneSchema[inputName] = yup
    .string()
    .matches(phoneRegExp, invalidErrorText)
    .test('phoneNumberValid', invalidErrorText, async function (phoneNumber) {
      // For Live Event no custom phone validations
      const enableLiveEvent = convertToBoolean(getLiveEventStatus())
      if (enableLiveEvent) {
        return true
      }

      const countryCode = accountsContainer.countryDropdownValue || 'US'
      if (phoneNumber !== '' && countryCode !== '') {
        const phoneNumberCode = await parsePhoneNumberField(
          phoneNumber,
          countryCode
        )
        let isValidMobile = await validLibMobilePhoneNumber(phoneNumberCode)
        accountsContainer.phoneNumberIsValid = isValidMobile
      }

      return !(accountsContainer?.phoneNumberIsValid === false)
    })
    .required(requiredErrorText)

  return phoneSchema
}

function getOptionalPhoneNumberSchema(props) {
  const phoneSchema = {}

  const {
    invalidErrorText = phoneNumberErrorMsg,
    inputName = inputName || 'phoneNumber',
    min = min || 9,
    max = max || 15,
  } = props || {}

  const phoneRegExp = /^[+0-9-() ]*$/

  phoneSchema[inputName] = yup
    .string()
    .matches(phoneRegExp, invalidErrorText)
    .test('phoneNumberValid', invalidErrorText, function () {
      return !(accountsContainer?.phoneNumberIsValid === false)
    })

  return phoneSchema
}

/**
 * Validates a mobile phone number input.
 * @param {Object} props - Validation schema props.
 * @param {string} [props.requiredErrorText] - Error message if value is not supplied.
 * @param {string} [props.invalidErrorText] - Error message if value fails validation.
 * @param {string} [props.inputName] - Input name to apply schema to.
 * @returns {Object} Validation schema for Yup.
 */
function getMobilePhoneSchema(props) {
  const phoneSchema = {}

  const {
    requiredErrorText = phoneNumberErrorMsg,
    invalidErrorText = phoneNumberErrorMsg,
    inputName = inputName || 'mobilePhoneNumber',
  } = props || {}

  const phoneRegExp = /^[+0-9]*$/

  phoneSchema[inputName] = yup
    .string()
    .matches(phoneRegExp, invalidErrorText)
    .min(9, invalidErrorText)
    .max(15, invalidErrorText)
    .required(requiredErrorText)

  return phoneSchema
}
/**
 * Generates a Yup schema to validate a business phone number input.
 * @param {Object} props - Validation schema configuration.
 * @param {string} [props.requiredErrorText] - Error text if value is missing.
 * @param {string} [props.invalidErrorText] - Error text if value fails validation.
 * @param {string} [props.inputName] - Input name to apply schema to.
 * @returns {Object} Yup validation schema.
 */
function getBusinessPhoneSchema(props) {
  const phoneSchema = {}

  const {
    requiredErrorText = phoneNumberErrorMsg,
    invalidErrorText = phoneNumberErrorMsg,
    inputName = inputName || 'businessPhoneNumber',
  } = props || {}

  const phoneRegExp = /^[+0-9-() ]*$/
  /* hot fix for phonenumber while converting user to BA */
  // const phoneRegExp = /^[+0-9]*$/

  phoneSchema[inputName] = yup
    .string()
    .matches(phoneRegExp, invalidErrorText)
    .min(9, invalidErrorText)
    .max(15, invalidErrorText)
    .required(requiredErrorText)

  return phoneSchema
}

/**
 * Generates a Yup schema to validate a tax ID input.
 * @param {Object} props - Validation schema configuration.
 * @param {string} [props.requiredErrorText] - Error text if value is missing.
 * @param {string} [props.invalidErrorText] - Error text if value fails validation.
 * @param {string} [props.inputName] - Input name to apply schema to.
 * @returns {Object} Yup validation schema.
 */
function getTaxIdSchema(props) {
  const taxIdSchema = {}

  const {
    requiredErrorText = taxIdErrorMsg,
    invalidErrorText = taxIdErrorMsg,
    inputName = 'taxId',
  } = props || {}

  const taxIdRegExp = /^[0-9]*$/

  taxIdSchema[inputName] = yup
    .string()
    .matches(taxIdRegExp, invalidErrorText)
    .required(requiredErrorText)

  return taxIdSchema
}

/**
 * Generates a Yup schema to validate a DUNS number input.
 * @param {Object} props - Validation schema configuration.
 * @param {string} [props.requiredErrorText] - Error text if value is missing.
 * @param {string} [props.invalidErrorText] - Error text if value fails validation.
 * @param {string} [props.inputName] - Input name to apply schema to.
 * @returns {Object} Yup validation schema.
 */
function getDunsSchema(props) {
  const dunsSchema = {}

  const {
    requiredErrorText = dunsErrorMsg,
    invalidErrorText = dunsErrorMsg,
    inputName = 'duns',
  } = props || {}

  const dunsRegExp = /^[0-9]*$/

  dunsSchema[inputName] = yup
    .string()
    .matches(dunsRegExp, invalidErrorText)
    .required(requiredErrorText)

  return dunsSchema
}

/**
 * Generates a Yup schema to validate that an input is present and meets length requirements.
 * @param {Object} props - Validation schema configuration
 * @param {string} props.requiredErrorText - Error text if value is missing
 * @param {string} props.inputName - Input name to apply schema to
 * @param {string} props.requiredType - Input type, used to set default error text
 * @param {number} props.maxLength - Maximum length allowed
 * @returns {Object} Yup validation schema
 */
function getRequiredSchema(props) {
  const requiredSchema = {}
  const {
    requiredErrorText,
    inputName = '',
    requiredType = '',
    maxLength,
  } = props || {}

  let validationErrorText = requiredErrorText

  const length = maxLength || 50
  const maxLengthError = maxLengthErrorMsg(length)

  if (!requiredErrorText && requiredType == 'securityQuestion') {
    validationErrorText = securityQuestionErrorMsg
  } else if (!requiredErrorText && requiredType == 'securityAnswer') {
    validationErrorText = securityAnswerErrorMsg
  } else if (!requiredErrorText && requiredType == 'accountType') {
    validationErrorText = accountTypeErrorMsg
  } else if (!requiredErrorText && requiredType == 'address') {
    validationErrorText = addressErrorMsg
  } else if (!requiredErrorText && requiredType == 'city') {
    validationErrorText = cityErrorMsg
  } else if (!requiredErrorText && requiredType == 'state') {
    validationErrorText = stateErrorMsg
  } else if (!requiredErrorText && requiredType == 'country') {
    validationErrorText = countryErrorMsg
  } else if (!requiredErrorText && requiredType == 'teamName') {
    validationErrorText = teamNameErrorMsg
  } else if (!requiredErrorText && requiredType == 'costCenter') {
    validationErrorText = costCenterErrorMsg
  } else if (!requiredErrorText && requiredType == 'listName') {
    validationErrorText = listNameErrorMsg
  } else if (!requiredErrorText && requiredType == 'reasonCode') {
    validationErrorText = reasonCodeErrorMsg
  } else if (!requiredErrorText && requiredType == 'industry') {
    validationErrorText = industryErrorMsg
  } else if (!requiredErrorText && requiredType == 'industrySize') {
    validationErrorText = industrySizeErrorMsg
  } else if (!requiredErrorText) {
    validationErrorText = requiredErrorMsg
  }
  if (requiredType === 'securityQuestion') {
    requiredSchema[inputName] = yup.string().required(validationErrorText)
  } else {
    requiredSchema[inputName] = yup
      .string()
      .max(length, maxLengthError)
      .required(validationErrorText)
  }

  return requiredSchema
}
/**
 * Generates a Yup schema to validate an alphanumeric input.
 *
 * @param {Object} props - Options for configuring the schema
 * @param {string} props.requiredErrorText - Error text if required validation fails
 * @param {string} props.invalidErrorText - Error text if invalid validation fails
 * @param {string} props.inputName - Name of the input
 * @param {Array<number>} props.minMaxValidation - Min and max length validation
 * @returns {Object} Yup schema object
 */
function getAlphaNumericSchema(props) {
  const alphaNumericSchema = {}
  // const alphaNumericCharacter = /^[ A-Za-z0-9_@$./#&-]*$/

  const {
    requiredErrorText = alphaErrorMsg,
    invalidErrorText = alphaErrorMsg,
    inputName = inputName,
    minMaxValidation = minMaxValidation,
  } = props || {}

  if (minMaxValidation != '') {
    const min = minMaxValidation[0]
    const max = minMaxValidation[1]
    const errorMessage = `Please enter a valid string from ${min}to${max}`
    alphaNumericSchema[inputName] = yup
      .string()
      // .matches(alphaNumericCharacter, invalidErrorText)
      .min(min, errorMessage)
      .max(max, errorMessage)
      .required(requiredErrorText)
  } else {
    alphaNumericSchema[inputName] = yup
      .string()
      .matches(alphaNumericCharacter, invalidErrorText)
      .required(requiredErrorText)
  }

  return alphaNumericSchema
}

// invalidLoyaltyAmountErrorMsg
// invalidLoyaltyErrorMsg
/**
 * Generates a Yup schema to validate a loyalty code input.
 *
 * @param {Object} props - Options for configuring the schema
 * @param {string} props.requiredErrorText - Error text if required validation fails
 * @param {string} props.invalidErrorText - Error text if invalid validation fails
 * @param {string} props.invalidAmoutErrorText - Error text if invalid amount validation fails
 * @param {string} props.inputName - Name of the input
 * @returns {Object} Yup schema object
 */
function getLoyaltyCode(props) {
  const loyaltySchema = {}
  const alphaNumericCharacter = /^(?:\d*\.)?\d+$/

  const {
    requiredErrorText = invalidLoyaltyErrorMsg,
    invalidErrorText = invalidLoyaltyAmountErrorMsg,
    invalidAmoutErrorText = invalidLoyaltyZeroErrorMsg,
    inputName = inputName || 'couponCode',
  } = props || {}

  loyaltySchema[inputName] = yup
    .string()
    .matches(alphaNumericCharacter, requiredErrorText)
    .required(requiredErrorText)
    .test('loyaltyPoint', invalidErrorText, function (loyaltyPoint) {
      return !(
        loyaltyPoint &&
        Number(loyaltyPoint) > cartContainer.getRedeemLoyaltyPoints()
      )
    })
    .test('loyaltyPoint', invalidAmoutErrorText, function (loyaltyPoint) {
      return !(loyaltyPoint && Number(loyaltyPoint) <= 0)
    })

  return loyaltySchema
}

/**
 * Validates a coupon code input.
 * @param {Object} props - Validation schema props.
 * @param {string} [props.requiredErrorText] - Error message for required validation.
 * @param {string} [props.invalidErrorText] - Error message for invalid coupon code.
 * @param {string} [props.inputName='couponCode'] - Name of input field.
 * @returns {Object} Validation schema for coupon code input.
 */
function getCouponCode(props) {
  const couponSchema = {}
  // const alphaNumericCharacter = /^[a-zA-Z0-9]*$/
  // NUSKIN-1330
  const {
    requiredErrorText = invalidCouponErrorMsg,
    invalidErrorText = invalidCouponErrorMsg,
    inputName = inputName || 'couponCode',
  } = props || {}

  couponSchema[inputName] = yup
    .string()
    // .matches(alphaNumericCharacter, invalidErrorText)
    // NUSKIN-1330
    .min(3, invalidErrorText)
    .max(64, invalidErrorText)
    .required(requiredErrorText)

  return couponSchema
}

/**
 * Validates a team selection input.
 * @param {Object} props - Validation schema props.
 * @param {string} [props.requiredErrorText] - Error message for required validation.
 * @param {string} [props.invalidErrorText] - Error message for invalid team selection.
 * @param {string} [props.inputName='userTeam'] - Name of input field.
 * @returns {Object} Validation schema for team selection input.
 */
function getTeamSchema(props) {
  const teamSchema = {}
  const {
    requiredErrorText = requiredErrorMsg,
    invalidErrorText = requiredErrorMsg,
    inputName = inputName || 'userTeam',
  } = props || {}

  teamSchema[inputName] = yup
    .array()
    .test('teamOptionValidation', invalidErrorText, function (teams) {
      return teams && teams.length > 0
    })
    .required(requiredErrorText)

  return teamSchema
}

/**
 * Validates an expiry date input.
 * @param {Object} props - Validation schema props.
 * @param {string} props.requiredErrorText - Error message for required validation.
 * @param {string} props.invalidErrorText - Error message for invalid expiry date.
 * @param {string} [props.inputName='expiryDate'] - Name of input field.
 * @returns {Object} Validation schema for expiry date input.
 */
function getExpiryDate(props) {
  const expiryDateSchema = {}
  const {
    requiredErrorText = expiryDateErrorMsg,
    invalidErrorText = expiryDateErrorMsg,
    inputName = 'expiryDate',
  } = props || {}

  const phoneRegExp = /^[0-9/]*$/
  const reg = yup.string()
  // const regex = String(reg).slice(0, 2)
  expiryDateSchema[inputName] = yup
    .string()
    .matches(phoneRegExp, requiredErrorText)
    .test('expiryDate', invalidErrorText, function (expiryDate) {
      const currentDateTimeStamp = Date.now()
      const { month, year } =
        dateFormatDeps.convertTimeStampToDate(currentDateTimeStamp)
      const newYear = parseInt(String(year).slice(2, 4))
      const monthInput = parseInt(expiryDate?.split('/')[0])
      const yearInput = parseInt(expiryDate?.split('/')[1])

      if (monthInput > 0 && monthInput <= 12) {
        if (newYear < yearInput) {
          return true
        }

        return newYear == yearInput ? month <= monthInput : false
      } else {
        return false
      }
    })
    .required(requiredErrorText)
  return expiryDateSchema
}

/**
 * Validates credit card number input.
 * @param {Object} props - Options for validation
 * @param {string} props.requiredErrorText - Error text if field is required
 * @param {string} props.invalidErrorText - Error text if invalid input
 * @param {string} props.inputName - Name of input field
 * @returns {Object} yup schema object for credit card validation
 */
function getCardValidation(props) {
  const cardShema = {}
  const {
    requiredErrorText = CardErrorMsg,
    invalidErrorText = CardErrorMsg,
    inputName = 'cardNumber',
  } = props || {}

  /**
   * for credit card validation
   * Regex is constructed for visa, master, amex, discover, can be tested based on the cards in the below url
   * @see https://www.cybersource.com/developers/other_resources/quick_references/test_cc_numbers/
   */
  const matchFullCreditCard =
    /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
  cardShema[inputName] = yup
    .string()
    .matches(matchFullCreditCard, invalidErrorText)
    .required(requiredErrorText)
  return cardShema
}

/**
 * Validates credit card CVV input.
 * @param {Object} props - Input validation schema props
 * @param {string} [props.requiredErrorText] - Required error message
 * @param {string} [props.invalidErrorText] - Invalid error message
 * @param {string} [props.inputName='cardCVV'] - Input name
 * @param {string} [props.cardInputName='cardNumber'] - Card number input name
 * @param {string} [props.cardType=''] - Detected card type
 * @returns {Object} yup validation schema
 */
function getCreditCardCVV(props) {
  const cvvSchema = {}
  const {
    requiredErrorText = cardCvvErrorMsg,
    invalidErrorText = cardCvvErrorMsg,
    inputName = 'cardCVV',
    cardInputName = 'cardNumber',
    cardType = '',
  } = props || {}

  const cvvLengthRegEx = /^[0-9]{3,4}$/
  cvvSchema[inputName] = yup
    .string()
    .matches(cvvLengthRegEx, invalidErrorText)
    .test('cvvValidation', invalidErrorText, function (cvvValue) {
      if (cvvValue) {
        const cvv = cvvValue
        // parseInt(cvvValue) makes 009 to 9
        // ^ which shows 009 as invalid cvv
        // as checked with amazon only length validation is done
        // 000 is accepted as valid CVV in UI
        const cardNumber = this.resolve(yup.ref(cardInputName))

        // @see https://medium.com/hootsuite-engineering/a-comprehensive-guide-to-validating-and-formatting-credit-cards-b9fa63ec7863
        if (cardNumber || cardType) {
          const isAmex =
            acceptedCreditCards.amex.test(cardNumber) ||
            cardType === 'AMERICAN_EXPRESS'
          if (isAmex) {
            // american express should have 4 digits cvv
            return /^\d{4}$/.test(cvv)
          } else {
            // others should have 3 digits cvv
            return /^\d{3}$/.test(cvv)
          }
        }
        // if card number is not entered, validate cvv length alone
        return cvvLengthRegEx.test(cvv)
      }
      // here cvvValue is undefined or ''
      return false
    })
    .required(requiredErrorText)
  return cvvSchema
}
/**
 * Creates a Yup schema for validating a postal code input.
 * @param {Object} props - Schema configuration options
 * @param {string} props.requiredErrorText - Error message for required validation
 * @param {string} props.invalidErrorText - Error message for invalid format
 * @param {string} [props.inputName] - Name of input field
 * @returns {Object} Yup schema
 */
function getPostalCodeSchema(props) {
  const postalCodeSchema = {}
  const {
    requiredErrorText = postalCodeErrorMsg,
    invalidErrorText = postalCodeErrorMsg,
    inputName = inputName || 'postalCode',
  } = props || {}
  const postalCodeRegExp =
    /^[A-Za-z]{1}[0-9]{1}[A-Za-z]{1} [0-9]{1}[A-Za-z]{1}[0-9]{1}$/
  postalCodeSchema[inputName] = yup
    .string()
    .matches(postalCodeRegExp, invalidErrorText)
    .required(requiredErrorText)
  return postalCodeSchema
}
/**
 * Generates a Yup schema for validating a ZIP code input, with country-specific validation rules.
 *
 * Accepts an options object with:
 * - requiredErrorText: Error message if ZIP code is required but not provided
 * - invalidErrorText: Error message if provided ZIP code fails validation
 * - inputName: Name of the ZIP code input
 * - selectedCountry: ISO country code to use for validation rules
 *
 * Returns a Yup schema object with ZIP code validation applied.
 */
function getZipCodeSchema(props) {
  const zipCodeSchema = {}
  const {
    requiredErrorText = zipCodeRequiredMsg,
    invalidErrorText = zipCodeErrorMsg,
    inputName = 'zipCode',
    selectedCountry = '',
  } = props || {}

  const country = () => {
    try {
      // const locale = getLocalStorage('locale')
      const locale = getLocaleCodeFromUrl({
        at: 'pathParam',
        defaultLocale: 'en_US',
        isReverseType: true,
      })
      return locale.split('_')[1] || i18n.language.split('_')[1]
    } catch (e) {
      return 'US'
    }
  }

  const zipcodeValidation = countryVal => {
    return countryZipcodesRegex[countryVal]
      ? countryZipcodesRegex[countryVal]
      : countryZipcodesRegex['DEFAULT']
  }

  zipCodeSchema[inputName] = yup
    .string()
    .test('teamOptionValidation', invalidErrorText, function (zipcode) {
      const countryVal = selectedCountry || country()
      if (countryCodes.includes(countryVal)) {
        return true
      }
      return zipcodeValidation(countryVal)?.test(zipcode)
    })
    .required(requiredErrorText)

  return zipCodeSchema
}

/**
 * Returns a Yup schema for validating a ZIP code input, tailored to the user's country.
 *
 * @param {Object} props - Schema configuration options
 * @param {string} props.requiredErrorText - Error message if ZIP code is required but not provided
 * @param {string} props.invalidErrorText - Error message if provided ZIP code is invalid
 * @param {string} props.inputName - Name of the ZIP code input in the form values
 * @returns {Object} A Yup schema for validating the ZIP code input
 */
function getZipCodeAccountSchema(props) {
  const zipCodeSchema = {}
  const {
    requiredErrorText = zipCodeRequiredMsg,
    invalidErrorText = zipCodeErrorMsg,
    inputName = 'zipCode',
  } = props || {}

  const country = () => {
    try {
      const locale = 'en-US'
      return locale.split('-')[1] || i18n.language.split('-')[1]
    } catch (e) {
      return 'US'
    }
  }

  const zipcodeValidation = countryVal => {
    return countryZipcodesRegex[countryVal]
  }

  zipCodeSchema[inputName] = yup
    .string()
    .test('teamOptionValidation', invalidErrorText, function (zipcode) {
      const countryVal = country()
      if (countryCodes.includes(countryVal)) {
        return true
      }
      return zipcodeValidation(countryVal)?.test(zipcode)
    })
    .required(requiredErrorText)

  return zipCodeSchema
}

/**
 * Returns a Yup schema for optionally validating a postal code input.
 *
 * @param {Object} props - Schema configuration options
 * @param {string} props.requiredErrorText - Error message if postal code is required but not provided
 * @param {string} props.inputName - Name of the postal code input in the form values
 * @returns {Object} A Yup schema for validating the postal code input
 */
function getPostalOptionalCodeSchema(props) {
  const postalCodeSchema = {}
  const {
    requiredErrorText = postalCodeErrorMsg,
    inputName = inputName || 'postalCode',
  } = props || {}
  const postalCodeRegExp =
    /^[A-Za-z]{1}[0-9]{1}[A-Za-z]{1} [0-9]{1}[A-Za-z]{1}[0-9]{1}$/
  postalCodeSchema[inputName] = yup
    .string()
    .matches(postalCodeRegExp, requiredErrorText)
    .when('addressLine1', {
      is: value => value && value.length > 0,
      then: yup.string().required(requiredErrorText),
      otherwise: yup.string().notRequired(),
    })
  return postalCodeSchema
}

/**
 * Generates a Yup schema for validating an optional zip code input.
 * Zip code is required if address line 1 is provided, otherwise not required.
 * Applies country-specific zip code validation based on locale.
 *
 * @param {Object} props - Schema configuration props
 * @param {string} props.requiredErrorText - Error text if required but not provided
 * @param {string} props.invalidErrorText - Error text if invalid format
 * @param {string} props.inputName - Name of the zip code input
 * @returns {Object} Yup schema
 */
function getZipCodeOptionalAccountSchema(props) {
  const zipCodeSchema = {}
  const {
    requiredErrorText = zipCodeRequiredMsg,
    invalidErrorText = zipCodeErrorMsg,
    inputName = 'zipCode',
  } = props || {}

  const country = () => {
    try {
      const locale = 'en-US'
      return locale.split('-')[1] || i18n.language.split('-')[1]
    } catch (e) {
      return 'US'
    }
  }

  const zipcodeValidation = countryVal => {
    return countryZipcodesRegex[countryVal]
  }

  zipCodeSchema[inputName] = yup.string().when('addressLine1', {
    is: value => value && value.length > 0,
    then: yup
      .string()
      .test('teamOptionValidation', invalidErrorText, function (zipcode) {
        const countryVal = country()
        if (countryCodes.includes(countryVal)) {
          return true
        }
        return zipcodeValidation(countryVal)?.test(zipcode)
      })
      .required(requiredErrorText),
    otherwise: yup.string().notRequired(),
  })

  return zipCodeSchema
}

/**
 * Generates a Yup schema to validate a city input.
 * Applies regex validation for alphanumeric characters with spaces and dashes only.
 *
 * @param {Object} props - Schema configuration props
 * @param {string} props.requiredErrorText - Error text if required but not provided
 * @param {string} props.inputName - Name of the city input
 * @returns {Object} Yup schema
 */
function getCitySchema(props) {
  const citySchema = {}
  const { requiredErrorText = cityErrorMsg, inputName = 'city' } = props || {}

  const cityRegExp = /^[A-Za-z\s-]*$/
  citySchema[inputName] = yup
    .string()
    .matches(cityRegExp, requiredErrorText)
    .required(requiredErrorText)
  return citySchema
}

/**
 * Generates a Yup schema object for validating a date input.
 * @param {Object} props - Optional properties to customize the schema
 * @param {string} props.requiredErrorText - Custom error message for required validation
 * @param {string} props.inputName - Name of the date input
 * @returns {Object} The Yup schema object for the date input
 */
function getDateSchema(props) {
  const dateSchema = {}
  const { requiredErrorText = dateErrorMsg, inputName = 'date' } = props || {}

  dateSchema[inputName] = yup.date().required(requiredErrorText).nullable()

  return dateSchema
}

/**
 * Generates a Yup schema to validate a purchase order (PO) number input.
 * Applies regex validation to allow only numeric characters.
 *
 * @param {Object} props - Schema configuration props
 * @param {string} props.requiredErrorText - Error text if required but not provided
 * @param {string} props.invalidErrorText - Error text if invalid format
 * @param {string} props.inputName - Name of the PO number input
 * @returns {Object} Yup schema
 */
function getPONumber(props) {
  const POSchema = {}
  const {
    requiredErrorText = poNumberError,
    invalidErrorText = poNumberError,
    inputName = 'PONumber',
  } = props || {}

  const onlyNumeric = /^[0-9]*$/

  POSchema[inputName] = yup
    .string()
    .matches(onlyNumeric, invalidErrorText)
    .required(requiredErrorText)

  return POSchema
}

/**
 * Generates a Yup schema to validate a cost center budget input.
 * Applies regex and custom validation to allow only valid numeric budget values.
 *
 * @param {Object} props - Schema configuration props
 * @param {string} requiredErrorText - Error text if required but not provided
 * @param {string} invalidErrorText - Error text if invalid format
 * @param {string} inputName - Name of the budget input
 * @param {string} currencyCode - Currency code to split value
 * @returns {Object} Yup schema
 */
function getCostCenterBudget(props) {
  const CostCenterBudget = {}
  const {
    requiredErrorText = budgetNumberError,
    invalidErrorText = budgetNumberError,
    inputName = 'totalBudget',
    currencyCode = '$',
  } = props || {}
  const onlyNumeric = /^[0-9]*$/

  CostCenterBudget[inputName] = yup
    .string()
    // .matches(onlyNumeric, invalidErrorText)
    .test('budgetCostValidataion', invalidErrorText, function (totalBudget) {
      if (totalBudget) {
        const budget = totalBudget.split(currencyCode)
        if (budget.length > 2) {
          return false
        }
        if (budget[0] && parseInt(budget[0]) > 0) {
          return onlyNumeric.test(budget[0])
        } else if (budget[1] && parseInt(budget[1]) > 0) {
          return onlyNumeric.test(budget[1])
        }
        return false
      }
      return false
    })
    .required(requiredErrorText)

  return CostCenterBudget
}

/**
 * Generates a Yup schema to validate a user name input.
 * Allows letters, numbers, hyphens, underscores, periods.
 * Validates if input matches email or phone number format.
 */
function getUserNameSchema(props) {
  const userNameSchema = {}

  const {
    requiredErrorText = userNameErrorMsg,
    invalidErrorText = userNameErrorMsg,
    inputName = 'userName',
  } = props || {}

  const emailRegExp = /^([a-zA-Z0-9_\-\.])@([a-zA-Z0-9_\-\.])\.([a-zA-Z]{2,5})$/
  const phoneRegExp = /^[0-9]*$/

  userNameSchema[inputName] = yup
    .string()
    .test('emailTest', invalidErrorText, function (value) {
      if (phoneRegExp.test(value) || emailRegExp.test(value)) {
        return true
      }
      return false
    })
    .required(requiredErrorText)

  return userNameSchema
}

/**
 * Generates a Yup schema to validate an integer number input.
 * Allows only integers. Validates value is within min and max.
 * @param {Object} props - schema configuration
 * @param {string} props.requiredErrorText - error text if required
 * @param {string} props.invalidErrorText - error if not integer
 * @param {string} props.inputName - name of input
 * @param {Array} props.minMaxValidation - [min, max] values
 * @returns {Object} integer number validation schema
 */
function getIntegerSchema(props) {
  const integerNumberSchema = {}
  const {
    requiredErrorText = integerNumberErrorMsg,
    invalidErrorText = integerNumberErrorMsg,
    inputName = inputName,
    minMaxValidation = minMaxValidation,
  } = props || {}
  const errorMessage = `Please enter a number from ${minMaxValidation[0]}to${minMaxValidation[1]}`
  integerNumberSchema[inputName] = yup
    .number()
    .integer(invalidErrorText)
    .min(minMaxValidation[0], errorMessage)
    .max(minMaxValidation[1], errorMessage)
    .required(requiredErrorText)
  return integerNumberSchema
}

/**
 * Generates a Yup schema to validate input matches a regex.
 * Allows input that matches the provided regex format.
 * Validates if input matches format.
 */
function getRegexSchema(props) {
  const regexSchema = {}
  const {
    requiredErrorText = regexErrorMsg,
    invalidErrorText = regexErrorMsg,
    inputName = inputName,
  } = props || {}
  const regexFormat = /^[0-9]$/
  regexSchema[inputName] = yup
    .string()
    .matches(regexFormat, invalidErrorText)
    .required(requiredErrorText)
  return regexSchema
}

/**
 * Validates a URL input against a regex pattern
 * @param {Object} props - Options for validation
 * @param {string} [requiredErrorText] - Error message if URL is required but not provided
 * @param {string} [invalidErrorText] - Error message if provided URL does not match expected pattern
 * @param {string} [inputName] - Name of input field to apply validation rule to
 * @returns {Object} - Yup validation schema for URL input
 */
function getUrlSchema(props) {
  const urlSchema = {}
  const {
    requiredErrorText = urlErrorMsg,
    invalidErrorText = urlErrorMsg,
    inputName = inputName,
  } = props || {}
  const emailRegExp =
    /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\.~#?&//=]*)$/
  urlSchema[inputName] = yup
    .string()
    .matches(emailRegExp, invalidErrorText)
    .required(requiredErrorText)
  return urlSchema
}

/**
 * Generates a Yup schema to validate a decimal number input.
 * Allows a decimal number within a min and max range.
 * Validates if input is a decimal number within expected range.
 */
function getDecimalSchema(props) {
  const decimalNumberSchema = {}
  const {
    requiredErrorText = integerNumberErrorMsg,
    inputName = inputName,
    minMaxValidation = minMaxValidation,
  } = props || {}
  const min = minMaxValidation[0]
  const max = minMaxValidation[1].split('-')
  const errorMessage = `Please enter a valid number from ${min} to ${minMaxValidation[1]}`
  decimalNumberSchema[inputName] = yup
    .number()
    .min(min, errorMessage)
    .max(max[0], errorMessage)
    .test('is-decimal', errorMessage, function (value) {
      if (max.length > 1) {
        const splitDecimal =
          value !== undefined ? value.toString().split('.') : []
        const decimalLength = splitDecimal.length > 1 && splitDecimal[1].length
        if (decimalLength <= max[1]) {
          return true
        }
      }
      return false
    })
    .required(requiredErrorText)

  return decimalNumberSchema
}

/**
 * Validates a sponsor ID input value using Yup.
 * Handles different validation logic for brand affiliate vs retail account types.
 * @param {Object} options - Options object
 * @param {boolean} [options.isFromBrandAffiliate=false] - True if validating for a brand affiliate account
 * @param {string} [options.requiredErrorText=sponsorIdErrorMsg] - Error text if required validation fails
 * @param {string} [options.invalidErrorText=sponsorIdRetailErrorMsg] - Error text if custom validation fails
 * @returns {Object} Yup validation schema
 */
function sponsorIdYup(options) {
  const {
    isFromBrandAffiliate = false,
    requiredErrorText = sponsorIdErrorMsg,
    invalidErrorText = sponsorIdRetailErrorMsg,
  } = options || {}

  //*fix* added extra condition as it is asking mandatory for retail

  const yupValidator = isFromBrandAffiliate
    ? yup
        .string()
        .test('sponsorIdCheck', requiredErrorText, value => {
          if (value) {
            return validateSponsorId({
              isFromBrandAffiliate: true,
              value,
            })
          }
          return false
        })
        .required(requiredErrorText)
    : yup
        .string()
        .test('sponsorIdCheck', invalidErrorText, value => {
          if (value) {
            return validateSponsorId({
              value,
            })
          }
          return true
        })
        .notRequired()
  return yupValidator
}

/**
 * Validates a sponsor ID based on the provided options.
 *
 * @param {Object} options - Options for validation
 * @param {boolean} [options.isFromBrandAffiliate=false] - Whether the sponsor ID is from a brand affiliate account
 * @param {string} options.value - The sponsor ID value to validate
 * @returns {Promise} A promise that resolves to a boolean indicating if the sponsor ID is valid
 */
function validateSponsorId(options) {
  return new Promise(async (resolve, reject) => {
    const { isFromBrandAffiliate = false, value } = options
    let response
    if (value) {
      response = await customerContainer?.sponsorIdValidation(value)
    }
    if (isFromBrandAffiliate) {
      resolve(response?.status === 'success')
    } else {
      resolve(response?.status === 'success' || value == undefined)
    }
  })
}

/**
 * Generates a Yup schema object for validating a sponsor ID input.
 *
 * @param {Object} props - Options for configuring the schema
 * @param {string} [props.inputName='sponsorId'] - The key to use in the schema
 * @returns {Object} A Yup schema object for validating sponsor ID input
 */
function getSponsorIdSchema(props) {
  const sponsorIdSchema = {}
  const {
    inputName = inputName || 'sponsorId',
    // requiredErrorText = sponsorIdErrorMsg,
    // invalidErrorText = sponsorIdRetailErrorMsg,
  } = props || {}

  // let repeatedSponsorId = ''
  // let response = ''
  // Fix for https://nuskin.atlassian.net/browse/CX121-2426 as we dont have min length and it might have two alpha too
  // let sponsorRegex = /^[A-Z]{3}[0-9]{7}$/

  sponsorIdSchema[inputName] = yup.string().when('accountType', {
    is: value => value && value === 'Brand Affiliate',
    then: sponsorIdYup({ isFromBrandAffiliate: true, ...props }),
    otherwise: sponsorIdYup({ ...props }),
  })
  return sponsorIdSchema
}

/**
 * Generates a Yup schema object for validating a sponsor ID input for account conversion.
 *
 * @param {Object} props - Options for configuring the schema
 * @returns {Object} A Yup schema object for validating sponsor ID input
 */
function getAccountConvertSponsorIdSchema(props) {
  const sponsorIdSchema = {}

  const {
    requiredErrorText = sponsorIdErrorMsg,
    invalidErrorText = sponsorIdErrorMsg,
    inputName = inputName || 'sponsorIDConvert',
  } = props || {}

  sponsorIdSchema[inputName] = yup.string().required(requiredErrorText)

  return sponsorIdSchema
}

/**
 * Generates a Yup schema object for validating a date of birth input.
 *
 * @param {Object} props - Options for configuring the schema
 * @param {string} props.inputName - The key to use in the schema, default 'dateOfBirth'
 * @param {string} props.requiredErrorText - Error text if required value is missing
 * @returns {Object} A Yup schema object for validating date of birth input
 */
function getDateOfBirthSchema(props) {
  const dateOfBirthSchema = {}
  const {
    requiredErrorText = dateOfBirthErrorMsg,
    // invalidErrorText = dateOfBirthErrorMsg,
    inputName = 'dateOfBirth',
  } = props || {}

  dateOfBirthSchema[inputName] = yup.string().when('accountType', {
    is: value => value && value === 'Brand Affiliate',
    then: yup
      .string()
      .required(requiredErrorText)
      .test('dobCheck', dateOfBirthRestrictErrorMsg, value => {
        const year = value?.substr(0, 4)
        const month = value?.substr(5, 2)
        const day = value?.substr(8, 2)
        const ALLOWED_YEAR = new Date()?.getFullYear() - 18
        const lastDayOfMonth = new Date(year, month, 0).getDate()
        if (
          value != null &&
          year.length == 4 &&
          (year < 1901 || year > ALLOWED_YEAR)
        ) {
          return false
        } else if (value != null && month > 12) {
          return false
        } else if (value != null && day > lastDayOfMonth) {
          return false
        } else {
          return true
        }
      }),
    otherwise: yup.string().notRequired(),
  })

  return dateOfBirthSchema
}

/**
 * Returns a Yup schema for validating the termsOfUse field.
 *
 * @param {Object} props - Optional properties to customize the schema
 * @param {string} props.requiredErrorText - Custom error message for required validation
 * @param {string} props.inputName - Custom name of the termsOfUse field
 * @returns {Object} - Yup schema object for termsOfUse validation
 */
function getTermsOfUseSchema(props) {
  const termsOfUseSchema = {}

  const {
    requiredErrorText = termsOfUseErrorMsg,
    // invalidErrorText = termsOfUseErrorMsg,
    inputName = 'termsOfUse',
  } = props || {}

  termsOfUseSchema[inputName] = yup.bool().when('accountType', {
    is: value =>
      value &&
      (value === 'Retail Customer' || value === 'Preferred Customer/Member'),
    then: yup.bool().required().oneOf([true], requiredErrorText),
    otherwise: yup.bool().notRequired().oneOf([false], requiredErrorText),
  })

  return termsOfUseSchema
}

/**
 * Generates a Yup schema object for validating the marketing email preference.
 * @param {Object} props - Optional properties to customize the schema
 * @param {string} props.inputName - Name of the form input
 * @returns {Object} - Yup schema object containing the marketing email validation
 */
function getMarketingEmailSchema(props) {
  const marketingEmailSchema = {}

  const {
    // requiredErrorText = marketingEmailErrorMsg,
    // invalidErrorText = marketingEmailErrorMsg,
    inputName = 'marketingEmail',
  } = props || {}

  marketingEmailSchema[inputName] = yup.bool().required()

  return marketingEmailSchema
}

/**
 * Generates a Yup schema object for validating the SMS signup preference.
 * @param {Object} props - Optional properties to customize the schema
 * @param {string} props.inputName - Name of the form input
 * @returns {Object} - Yup schema object containing the SMS signup validation
 */
function getSignupSmsSchema(props) {
  const signupSmsSchema = {}

  const {
    // requiredErrorText = signupSmsErrorMsg,
    // invalidErrorText = signupSmsErrorMsg,
    inputName = 'signupSms',
  } = props || {}

  signupSmsSchema[inputName] = yup.bool().required()

  return signupSmsSchema
}

/**
 * Generates a Yup schema object for validating the rewards signup preference.
 * @param {Object} props - Optional properties to customize the schema
 * @param {string} props.inputName - Name of the form input
 * @returns {Object} - Yup schema object containing the rewards signup validation
 */
function getSignupRewardsSchema(props) {
  const signupRewardsSchema = {}

  const {
    // requiredErrorText = signupRewardsErrorMsg,
    // invalidErrorText = signupRewardsErrorMsg,
    inputName = 'signupRewards',
  } = props || {}

  signupRewardsSchema[inputName] = yup.bool().required()

  return signupRewardsSchema
}

/**
 * Generates a Yup schema object for validating the tax ID on signup.
 * @param {Object} props - Properties to customize the schema
 * @param {string} props.requiredErrorText - Error text if value is required but not provided
 * @param {string} props.invalidErrorText - Error text if value fails validation checks
 * @param {string} props.inputName - Name of the form input
 * @returns {Object} - Yup schema object for validating the signup tax ID input
 */
function getSignupTaxIdSchema(props) {
  const locale = APPConfig?.getActiveAppConfig()?.defaultLocale //en_US
  const language = locale?.split('_')?.pop().toLowerCase()
  let signupTaxIdSchema = {}
  const {
    requiredErrorText = signupTaxIdErrorMsg,
    invalidErrorText = signupTaxIdErrorMsg,
    inputName = 'signupTaxId',
  } = props || {}
  let exp = /^[0-9]{3}\-[0-9]{2}\-[0-9]{4}$/
  if (language === 'us') {
    exp = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
  } else {
    exp = /^[0-9]{3}\-[0-9]{3}\-[0-9]{3}$/
  }
  // /^(?!666){3}(?!00){2}^(?!00){2}^(?!999){3}(?!\d*0000$){4}^(?!9){1}^(?!000){3}\d{9}$/
  const signupTaxIdRegExp = exp
  let repeatTaxIdValue = ''
  let response = ''
  if (userContainer.associateActivation === '') {
    signupTaxIdSchema[inputName] = yup.string().when('accountType', {
      is: value => value && value === 'Brand Affiliate',
      then: yup
        .string()
        .matches(signupTaxIdRegExp, requiredErrorText)
        .test('taxIdCheck', invalidErrorText, value => {
          if (value != repeatTaxIdValue && signupTaxIdRegExp.test(value)) {
            repeatTaxIdValue = value
            response = getUniqueTaxId(value)
            return response
          }
          return true
        })
        .required(requiredErrorText),
    })
  } else {
    signupTaxIdSchema[inputName] = yup
      .string()
      .matches(signupTaxIdRegExp, requiredErrorText)
      .test('taxIdCheck', invalidErrorText, value => {
        if (value != repeatTaxIdValue && signupTaxIdRegExp.test(value)) {
          repeatTaxIdValue = value
          response = getUniqueTaxId(value)
          return response
        }
        return true
      })
      .required(requiredErrorText)
  }

  return signupTaxIdSchema
}

/**
 * Validates tax ID uniqueness by calling tax ID API.
 * Returns a promise that resolves to a boolean indicating if the tax ID is unique.
 *
 * @param {string} finalValue - Tax ID to validate
 */
function getUniqueTaxId(finalValue) {
  return new Promise(async (resolve, reject) => {
    let response = 'false'
    if (finalValue) {
      let taxUnFormattedValue = finalValue.replaceAll('-', '')
      await taxIDContainer?.postTaxTokenExInfo(finalValue)
      await taxIDContainer?.postDupCharTaxTokenExInfo(taxUnFormattedValue)
      response = await taxIDContainer?.taxTokenExData?.success
    }
    resolve(response === 'true' ? true : false)
  })
}

/**
 * Generates a Yup schema for validating a tax ID input,
 * with custom error messages and regex based on locale.
 *
 * @param {Object} props - Options for configuring the schema
 * @param {string} props.requiredErrorText - Error text if required value missing
 * @param {string} props.inputName - Name of input to apply schema to
 * @returns {Object} Yup schema object
 */
function getNewTaxIdSchema(props) {
  const locale = APPConfig?.getActiveAppConfig()?.defaultLocale //en_US
  const language = locale?.split('_')?.pop().toLowerCase()
  const signupTaxIdSchema = {}
  const {
    requiredErrorText = taxIdErrorMsg,
    inputName = inputName || 'taxId',
  } = props || {}

  let exp = ''
  if (language === 'us') {
    exp = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
  } else {
    exp = /^[0-9]{3}\-[0-9]{3}\-[0-9]{3}$/
  }
  const signupTaxIdRegExp =
    // /^(?!666){3}(?!00){2}^(?!00){2}^(?!999){3}(?!\d*0000$){4}^(?!9){1}^(?!000){3}\d{9}$/
    exp
  signupTaxIdSchema[inputName] = yup
    .string()
    .matches(signupTaxIdRegExp, requiredErrorText)
    .required(requiredErrorText)

  return signupTaxIdSchema
}
/**
 * Generates a Yup schema for validating a business tax ID input,
 * with custom error messages and regex based on locale.
 *
 * @param {Object} props - Options for configuring the schema
 * @returns {Object} Yup schema object
 */
function getBusinessTaxIdSchema(props) {
  const locale = APPConfig?.getActiveAppConfig()?.defaultLocale //en_US
  const language = locale?.split('_')?.pop().toLowerCase()
  const signupTaxIdSchema = {}
  const {
    requiredErrorText = taxIdErrorMsg,
    inputName = inputName || 'taxId',
  } = props || {}

  let exp = /^[0-9]{3}\-[0-9]{3}\-[0-9]{3}$/
  const signupTaxIdRegExp =
    // /^(?!666){3}(?!00){2}^(?!00){2}^(?!999){3}(?!\d*0000$){4}^(?!9){1}^(?!000){3}\d{9}$/
    exp
  signupTaxIdSchema[inputName] = yup.string().when('taxIdOptions', {
    is: value => value && value === 'Social Insurance Number',
    then: yup
      .string()
      .matches(signupTaxIdRegExp, requiredErrorText)
      .required(requiredErrorText),
    otherwise: yup.string().notRequired(),
  })

  return signupTaxIdSchema
}

/**
 * Generates a Yup schema to validate an employee tax ID input
 * @param {Object} props - Optional properties
 * @param {string} [props.inputName] - Name of the input field
 * @param {string} [props.requiredErrorText] - Error text if required
 * @returns {Object} Yup schema
 */
/**
 * Generates a Yup schema to validate an employee tax ID input for a B2B account
 * @param {Object} props - Optional properties
 * @param {string} [props.inputName] - Name of the input field
 * @returns {Object} Yup schema
 */
function getBA2BEEmployeeTaxIdSchema(props) {
  const signupTaxIdSchema = {}
  const { inputName = inputName || 'taxId' } = props || {}

  let exp = /^[0-9]{2}\-[0-9]{7}$/
  let requiredErrorText = taxIdEmployeeErrorMsg

  const signupTaxIdRegExp =
    // /^(?!666){3}(?!00){2}^(?!00){2}^(?!999){3}(?!\d*0000$){4}^(?!9){1}^(?!000){3}\d{9}$/
    exp
  signupTaxIdSchema[inputName] = yup
    .string()
    .matches(signupTaxIdRegExp, requiredErrorText)
    .required(requiredErrorText)

  return signupTaxIdSchema
}

/**
 * Generates a Yup schema to validate an employee tax ID input
 * @param {Object} props - Properties for the schema
 * @param {string} [props.inputName] - Name of the input field
 * @returns {Object} Yup schema
 */
function getEmployeeTaxIdSchema(props) {
  const locale = APPConfig?.getActiveAppConfig()?.defaultLocale //en_US
  const language = locale?.split('_')?.pop().toLowerCase()
  const signupTaxIdSchema = {}
  const { inputName = inputName || 'taxId' } = props || {}

  let exp = /^[0-9]{9}\-[a-zA-Z]{2}\-[0-9]{4}$/
  let requiredErrorText = taxIdErrorMsg
  const signupTaxIdRegExp =
    // /^(?!666){3}(?!00){2}^(?!00){2}^(?!999){3}(?!\d*0000$){4}^(?!9){1}^(?!000){3}\d{9}$/
    exp
  signupTaxIdSchema[inputName] = yup.string().when('taxIdOptions', {
    is: value => value && value === 'Business Number',
    then: yup
      .string()
      .matches(signupTaxIdRegExp, requiredErrorText)
      .required(requiredErrorText),
    otherwise: yup.string().notRequired(),
  })

  return signupTaxIdSchema
}

/**
 * Generates a Yup schema to validate the refund and privacy policy checkbox for brand affiliate account signups
 * @param {Object} props - Properties for the schema
 * @param {string} props.requiredErrorText - Error message if not checked when required
 * @param {string} props.invalidErrorText - Error message if invalid value
 * @param {string} props.inputName - Name of the input field
 * @returns {Object} Yup schema
 */
function getRefundAndPrivacyBrandSchema(props) {
  const refundAndPrivacyBrandSchema = {}
  const {
    requiredErrorText = refundAndPrivacyBrandErrorMsg,
    invalidErrorText = refundAndPrivacyBrandErrorMsg,
    inputName = 'refundAndPrivacyBrand',
  } = props || {}
  refundAndPrivacyBrandSchema[inputName] = yup.bool().when('accountType', {
    is: value => value && value === 'Brand Affiliate',
    then: yup.bool().required().oneOf([true], requiredErrorText),
    otherwise: yup.bool().notRequired().oneOf([false], requiredErrorText),
  })
  return refundAndPrivacyBrandSchema
}

/**
 * Generates a Yup schema to validate the terms of use checkbox for brand affiliate account signups
 * @param {Object} props - Properties for the schema
 * @param {string} props.requiredErrorText - Error message if not checked when required
 * @param {string} props.invalidErrorText - Error message if invalid value
 * @param {string} props.inputName - Name of the input field
 * @returns {Object} Yup schema
 */
function getTermsOfUseBranBrandSchema(props) {
  const termsOfUseBrandSchema = {}

  const {
    requiredErrorText = termsOfUseBrandErrorMsg,
    invalidErrorText = termsOfUseBrandErrorMsg,
    inputName = 'termsOfUseBrand',
  } = props || {}

  termsOfUseBrandSchema[inputName] = yup.bool().when('accountType', {
    is: value => value && value === 'Brand Affiliate',
    then: yup.bool().required().oneOf([true], requiredErrorText),
    otherwise: yup.bool().notRequired().oneOf([false], requiredErrorText),
  })

  return termsOfUseBrandSchema
}

/**
 * Generates a Yup schema to validate the address line 1 field for brand affiliate account signups.
 * If the account type is 'Brand Affiliate', address line 1 is required. Otherwise it is optional.
 */
function getSignupAddressLine1Schema(props) {
  const signupAddressLine1Schema = {}
  const {
    requiredErrorText = signupAddressLine1ErrorMsg,
    invalidErrorText = signupAddressLine1ErrorMsg,
    inputName = 'signupAddressLine1',
  } = props || {}
  signupAddressLine1Schema[inputName] = yup.string().when('accountType', {
    is: value => value && value === 'Brand Affiliate',
    then: yup.string().required(requiredErrorText),
    otherwise: yup.string().notRequired(),
  })
  return signupAddressLine1Schema
}
/**
 * Gets Yup schema for signup city input validation based on account type.
 * @param {Object} props - Options
 * @param {string} props.requiredErrorText - Error text if required
 * @param {string} props.invalidErrorText - Error text if invalid
 * @param {string} props.inputName - Name of input
 * @returns {Object} Yup schema
 */
function getSignupCitySchema(props) {
  const signupCitySchema = {}
  const {
    requiredErrorText = signupCityErrorMsg,
    invalidErrorText = signupCityErrorMsg,
    inputName = 'signupCity',
  } = props || {}
  signupCitySchema[inputName] = yup.string().when('accountType', {
    is: value => value && value === 'Brand Affiliate',
    then: yup.string().required(requiredErrorText),
    otherwise: yup.string().notRequired(),
  })

  return signupCitySchema
}
/**
 * Gets Yup schema for signup state input validation based on account type.
 * @param {Object} props - Options
 * @param {string} props.requiredErrorText - Error text if required
 * @param {string} props.invalidErrorText - Error text if invalid
 * @param {string} props.inputName - Name of input
 * @returns {Object} Yup schema
 */
function getSignupStateSchema(props) {
  const signupStateSchema = {}
  const {
    requiredErrorText = signupStateErrorMsg,
    invalidErrorText = signupStateErrorMsg,
    inputName = 'signupState',
  } = props || {}
  signupStateSchema[inputName] = yup.string().when('accountType', {
    is: value => value && value === 'Brand Affiliate',
    then: yup.string().required(requiredErrorText),
    otherwise: yup.string().notRequired(),
  })
  return signupStateSchema
}
/**
 * Gets Yup schema for signup postal code input validation based on account type.
 * @param {Object} props - Options
 * @param {string} props.requiredErrorText - Error text if required
 * @param {string} props.invalidErrorText - Error text if invalid
 * @param {string} props.inputName - Name of input
 * @returns {Object} Yup schema
 */
function getSignupPostalCodeSchema(props) {
  const signupPostalCodeSchema = {}
  const {
    requiredErrorText = signupPostalCodeErrorMsg,
    invalidErrorText = signupPostalCodeErrorMsg,
    inputName = 'signupPostalCode',
  } = props || {}
  const postalCodeRegExp =
    /^[A-Za-z]{1}[0-9]{1}[A-Za-z]{1} [0-9]{1}[A-Za-z]{1}[0-9]{1}$/
  signupPostalCodeSchema[inputName] = yup
    .string()
    .matches(postalCodeRegExp, invalidErrorText)
    .min(5, invalidErrorText)
    .max(7, invalidErrorText)
    .when('accountType', {
      is: value => value && value === 'Brand Affiliate',
      then: yup.string().required(requiredErrorText),
      otherwise: yup.string().notRequired(),
    })
  return signupPostalCodeSchema
}

/**
 * Gets Yup schema for signup zip code input validation based on account type.
 * @param {Object} props - Options
 * @param {string} props.requiredErrorText - Error text if required
 * @param {string} props.invalidErrorText - Error text if invalid
 * @param {string} props.inputName - Name of input
 * @returns {Object} Yup schema
 */
function getSignupZipCodeSchema(props) {
  const signupZipCodeSchema = {}
  const {
    requiredErrorText = signupZipCodeErrorMsg,
    invalidErrorText = signupZipCodeErrorMsg,
    inputName = 'signupZip',
  } = props || {}
  const zipRegExp = /^[0-9-]*$/
  signupZipCodeSchema[inputName] = yup
    .string()
    .matches(zipRegExp, invalidErrorText)
    .min(5, invalidErrorText)
    .max(10, invalidErrorText)
    .when('accountType', {
      is: value => value && value === 'Brand Affiliate',
      then: yup.string().required(requiredErrorText),
      otherwise: yup.string().notRequired(),
    })
  return signupZipCodeSchema
}
/**
 * Gets Yup schema for optional signup address line 1 input validation.
 * @param {Object} props - Options
 * @param {string} props.requiredErrorText - Error text if required
 * @param {string} props.inputName - Name of input
 * @returns {Object} Yup schema
 */
function getSignupAddressLine1OptionalSchema(props) {
  const signupAddressOptionalLine1Schema = {}
  const {
    requiredErrorText = signupAddressLine1ErrorMsg,
    inputName = 'addressLine1',
  } = props || {}
  signupAddressOptionalLine1Schema[inputName] = yup
    .string()
    .notRequired(requiredErrorText)

  return signupAddressOptionalLine1Schema
}

/**
 * Gets Yup schema for optional city input validation for signup form.
 * Schema requires city if address line 1 is provided.
 * @param {Object} props - Options
 * @param {string} props.requiredErrorText - Error text if required
 * @param {string} props.inputName - Name of input
 * @returns {Object} Yup schema
 */
function getSignupCityOptionalSchema(props) {
  const signupCitySchema = {}
  const { requiredErrorText = signupCityErrorMsg, inputName = 'city' } =
    props || {}

  signupCitySchema[inputName] = yup.string().when('addressLine1', {
    is: value => value && value.length > 0,
    then: yup.string().required(requiredErrorText),
    otherwise: yup.string().notRequired(),
  })

  return signupCitySchema
}

/**
 * Gets Yup schema for optional signup state input validation.
 * @param {Object} props - Options
 * @param {string} props.requiredErrorText - Error text if required
 * @param {string} props.inputName - Name of input
 * @returns {Object} Yup schema
 */
function getSignupStateOptionalSchema(props) {
  const signupStateSchema = {}
  const { requiredErrorText = signupStateErrorMsg, inputName = 'state' } =
    props || {}

  signupStateSchema[inputName] = yup.string().when('addressLine1', {
    is: value => value && value.length > 0,
    then: yup.string().required(requiredErrorText),
    otherwise: yup.string().notRequired(),
  })

  return signupStateSchema
}

/**
 * Gets Yup schema to validate if refund and privacy policy is accepted.
 * Schema requires acceptance if account type is Retail or Preferred Customer.
 * @param {Object} props - Options
 * @param {string} props.requiredErrorText - Error text if required
 * @param {string} props.invalidErrorText - Error text if invalid
 * @param {string} props.inputName - Name of input
 * @returns {Object} Yup schema
 */
function getRefundAndPrivacySchema(props) {
  const refundAndPrivacySchema = {}

  const {
    requiredErrorText = refundAndPrivacyErrorMsg,
    invalidErrorText = refundAndPrivacyErrorMsg,
    inputName = 'refundAndPrivacy',
  } = props || {}
  refundAndPrivacySchema[inputName] = yup.bool().when('accountType', {
    is: value =>
      value &&
      (value === 'Retail Customer' || value === 'Preferred Customer/Member'),
    then: yup.bool().required().oneOf([true], requiredErrorText),
    otherwise: yup.bool().notRequired().oneOf([false], requiredErrorText),
  })
  return refundAndPrivacySchema
}

/**
 * Gets Yup schema for required checkbox input validation.
 * @param {Object} props - Options
 * @param {string} props.requiredErrorText - Error text if required
 * @param {string} props.invalidErrorText - Error text if invalid
 * @param {string} props.inputName - Name of input
 * @returns {Object} Yup schema
 */
function getRequiredCheckboxSchema(props) {
  const requiredCheckboxSchema = {}
  const {
    requiredErrorText,
    invalidErrorText,
    inputName = 'refundAndPrivacy',
  } = props || {}
  requiredCheckboxSchema[inputName] = yup
    .bool()
    .required(requiredErrorText)
    .oneOf([true], requiredErrorText)
  return requiredCheckboxSchema
}
export {
  getCostCenterBudget,
  getCouponCode,
  getLoyaltyCode,
  getCardNameSchema,
  getEmailSchema,
  getBusinessEmailSchema,
  getPasswordSchema,
  getConfirmPasswordSchema,
  getNameSchema,
  getBusinessLegalNameSchema,
  getPhoneSchema,
  getMobilePhoneSchema,
  getBusinessPhoneSchema,
  getRequiredSchema,
  getZipCodeSchema,
  getCitySchema,
  getPostalCodeSchema,
  getExpiryDate,
  getCardValidation,
  getCreditCardCVV,
  getPONumber,
  getTaxIdSchema,
  getDunsSchema,
  getOrganizationNameSchema,
  getUserNameSchema,
  getTeamSchema,
  getIntegerSchema,
  getAlphaNumericSchema,
  getDecimalSchema,
  getDateSchema,
  getRegexSchema,
  getUrlSchema,
  getStoreCredits,
  getUserExistSchema,
  getSponsorIdSchema,
  getAccountConvertSponsorIdSchema,
  getDateOfBirthSchema,
  getSignupTaxIdSchema,
  getNewTaxIdSchema,
  getBA2BEEmployeeTaxIdSchema,
  getEmployeeTaxIdSchema,
  getBusinessTaxIdSchema,
  getSignupAddressLine1Schema,
  getSignupCitySchema,
  getSignupPostalCodeSchema,
  getSignupZipCodeSchema,
  getSignupStateSchema,
  getRefundAndPrivacySchema,
  getTermsOfUseSchema,
  getMarketingEmailSchema,
  getSignupSmsSchema,
  getSignupRewardsSchema,
  getZipCodeAccountSchema,
  getSignupCityOptionalSchema,
  getSignupAddressLine1OptionalSchema,
  getSignupStateOptionalSchema,
  getZipCodeOptionalAccountSchema,
  getPostalOptionalCodeSchema,
  getRefundAndPrivacyBrandSchema,
  getTermsOfUseBranBrandSchema,
  getRequiredCheckboxSchema,
  getOptionalPhoneNumberSchema,
}
