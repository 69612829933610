import React from 'react'
import { i18nTranslate } from 'src/utils'
import isArray from 'lodash/isArray'
import { Col, Row } from 'react-bootstrap'
import { tryJSONParse } from 'src/utils'
import { AccordionBlock } from 'src/views/components'
import './styles.scss'

class ProductSpecification extends React.Component {
  renderTitle = () => {
    const { isAccordionType = false } = this.props
    return (
      <span
        className={`specification-title ${
          isAccordionType
            ? 'text-uppercase'
            : 'border-top border-dark pt-4 pb-3'
        } text-dark d-flex font-weight-bold`}>
        {i18nTranslate('pdp.detailsText', 'Details', {
          nameSpace: 'ssr-resource',
        })}
      </span>
    )
  }

  renderSpecification = (parsedSpecification = []) => {
    return parsedSpecification.map(data => {
      if (data?.label) {
        return (
          <Col className="specification p-0 mb-4" key={data.label}>
            <Row
              key={data.label}
              noGutters
              className={`border-bottom pb-2 mb-2 text-dark`}>
              <Col sm={6} md={3} xs className="font-weight-bold">
                {data?.label || ''}
              </Col>
              <Col> {data?.value || ''}</Col>
            </Row>
          </Col>
        )
      } else {
        return (
          <Col className="specification p-0 mb-4" key={data}>
            {data}
          </Col>
        )
      }
    })
  }

  render() {
    const { specification = '', isAccordionType = false } = this.props
    const parsedSpecification = tryJSONParse(specification)

    return (
      isArray(parsedSpecification) &&
      parsedSpecification.length > 0 && (
        <Col className="px-0">
          {' '}
          {isAccordionType ? (
            <AccordionBlock
              title={this.renderTitle()}
              isDefaultOpen={true}
              className="accordion-specification py-3">
              {this.renderSpecification(parsedSpecification)}
            </AccordionBlock>
          ) : (
            <>
              {this.renderTitle()}
              {this.renderSpecification(parsedSpecification)}
            </>
          )}{' '}
        </Col>
      )
    )
  }
}
export { ProductSpecification }
