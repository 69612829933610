import React, { Component } from 'react'
import './styles.scss'

class ProductBadge extends Component {
  render() {
    const { productBadge = '', badgeRendertype = 'diagonal' } = this.props || {}
    if (!productBadge) {
      return <></>
    }
    return (
      <div
        className={`product-badge-wrap ${
          badgeRendertype === 'diagonal'
            ? 'product-badge-wrap-diagonal text-capitalize'
            : 'product-badge-wrap-horizontal p-2 text-uppercase'
        }`}>
        {badgeRendertype === 'diagonal' ? (
          <span>{productBadge}</span>
        ) : (
          productBadge
        )}
      </div>
    )
  }
}

export { ProductBadge }
