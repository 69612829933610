import React from 'react'
import { observer } from 'mobx-react'
// import get from 'lodash/get'
import { Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { pageNames } from 'src/routes/pathParams'
import { i18nTranslate } from 'src/utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { Quantity } from 'src/views/components/QuantityIncrementer'
import { Price, ProductSkuOption } from 'src/views/components'

@observer
class ProductDetails extends React.Component {
  handleQuantityChange = count => {
    const { index } = this.props
    this.props.handleQuantityChange(count, index)
  }
  renderProductDetails = () => {
    const { item, index } = this.props
    const image = item?.properties?.imageURL || ''
    const name = item?.properties?.name || ''
    const identifier = item?.identifier || ''
    const productOption = item?.productOption || ''
    const description = item?.properties?.description || ''
    const shortDescription = item?.properties?.shortDescription || description

    return (
      <Row
        noGutters
        className="pt-2 mb-3 border-top  kitProduct border-secondary"
        key={index}>
        <Col md={4} xs={12}>
          <Link
            className="image-wrapper d-flex justify-content-center align-items-center"
            to={`${pageNames.product}/${identifier}`}>
            <LazyLoadImage
              data-testid="qa-product-image"
              src={image || imagePlaceholder}
              className={'mw-100 mh-100'}
              alt={name}
              onError={e => {
                if (e.target.src != imagePlaceholder) {
                  e.target.onerror = null
                  e.target.src = imagePlaceholder
                }
              }}
            />
          </Link>
        </Col>
        <Col md={4} xs={12} className="my-sm-0 my-3 px-md-3 sku-wrapper">
          <Row noGutters data-testid="qa-product-price" className="flex-column">
            <span>{item?.previousSku?.properties?.brand || ''}</span>
            <Link
              className="text-dark"
              to={`${pageNames.product}/${identifier}`}>
              <span>
                <h6
                  className="collection-name font-weight-bold"
                  data-testid="qa-product-name">
                  {name}
                </h6>
              </span>
            </Link>
            <Price
              transactionPrice={item?.previousSku?.prices?.[0]}
              currency={item?.currency}
            />
          </Row>
          <ProductSkuOption
            options={productOption}
            index={index}
            handleSkuSelection={this.props.handleSkuSelection}
            skuSelected={item.skuSelected}
          />
        </Col>
        <Col
          md={4}
          xs={12}
          className="collectionProduct-details d-flex flex-column ml-auto"
          data-testid="qa-product-details">
          {shortDescription && (
            <>
              <h6 className="font-weight-bold">
                {i18nTranslate('bundle.productDetailTitle', 'Product Details', {
                  nameSpace: 'ssr-resource',
                })}
              </h6>
              <Row
                noGutters
                className="mb-2 text-justify description"
                dangerouslySetInnerHTML={{ __html: shortDescription }}
              />
            </>
          )}
          <div className="pt-md-2 mt-md-2">{this.renderQtyAndButton()}</div>
        </Col>
      </Row>
    )
  }

  renderQtyAndButton = () => {
    const { index } = this.props
    return (
      <>
        <Row noGutters className="flex-column">
          <h6 className="font-weight-bold">
            {i18nTranslate('collection.quantity', 'Quantity', {
              nameSpace: 'ssr-resource',
            })}
            :
          </h6>
          <Quantity onQuantityChange={this.handleQuantityChange} />
        </Row>
        <Col className="d-flex p-0" md={12} lg={12} xs={12} sm={12}>
          <Button
            className="mt-3 w-100 add-collection-to-cart"
            data-testid="qa-addcart"
            onClick={() => {
              this.props.handleAddToCart(index)
            }}>
            {i18nTranslate('collection.addToCart', 'Add to Cart', {
              nameSpace: 'ssr-resource',
            })}
          </Button>
        </Col>
      </>
    )
  }

  render() {
    return <> {this.renderProductDetails()} </>
  }
}

export { ProductDetails }
export default ProductDetails
