/**
 * Generates schema data for the header logo based on the provided props.
 * @param {Object} props - The props to use for generating the schema.
 * @param {string} [props.requestDomain="/"] - The request domain to use for the logo url.
 * @returns {Object} The generated schema data.
 */
function headerLogoSchema(props) {
  const { requestDomain = '/' } = props
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: requestDomain,
    logo: '',
  }
  return schemaData
}

export { headerLogoSchema }
