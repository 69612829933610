import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Col, Row, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Price, ImageComponent } from 'src/views/components'
import { cartContainer } from 'src/models'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import './CartProductBlock/styles.scss'

@observer
class FreeGiftCartProductBlock extends React.Component {
  render() {
    const {
      productName = '',
      productUrl = '',
      skuId = '',
      skuSlug = '',
      variantLabel = '',
      imageURL = '',
      isCheckout = false,
      transactionPrice = {},
      quantity = 0,
      combineFreeGiftQuantity = 0,
      giftOutOfStockQty = 0,
      combineFreeGiftOOSQty = 0,
      skuInventoryAvailable = false,
      currency = '',
    } = this.props
    const dynamicClassName = isCheckout
      ? 'checkout-container'
      : 'cart-container'
    const freeGiftQty = combineFreeGiftQuantity || quantity
    const freeGiftOOSQty = combineFreeGiftOOSQty || giftOutOfStockQty
    const availableQty = freeGiftQty - freeGiftOOSQty
    const slugVariant = skuSlug || skuId || ''
    const slugVariantLabel = variantLabel || ''
    let navigateURL = slugVariantLabel
      ? `${productUrl}?variant=${slugVariant}`
      : `${productUrl}`

    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const subscriptionClassName = isFlexibleSubscriptionEnabled
      ? 'cart-free-gift-container'
      : ''
    const quantityTitle = isFlexibleSubscriptionEnabled
      ? i18nTranslate('quantity', 'Quantity')
      : i18nTranslate('cart.quantityWithColon', 'Qty')
    const imageColumnSpan = isFlexibleSubscriptionEnabled ? '3' : '4'
    const columnSpan = isFlexibleSubscriptionEnabled ? '9' : '8'

    return (
      <Card
        className={`cart-product-wrapper mb-3 p-2 ${dynamicClassName} ${subscriptionClassName}`}
        data-testid="qa-products-container">
        <Row noGutters className="m-0">
          <Col lg={12} className="p-0">
            <Row noGutters>
              <Col lg={imageColumnSpan} md={imageColumnSpan}>
                <Link
                  onClick={cartContainer.toggleCartVisibility}
                  to={navigateURL}>
                  <ImageComponent
                    testId="qa-product-image"
                    src={imageURL}
                    alt={productName}
                    className="free-gift-product-image"
                  />
                </Link>
              </Col>
              <Col
                lg={columnSpan}
                md={columnSpan}
                className="free-gift-product-container pl-2 pr-0">
                <Row noGutters>
                  <Col lg={12} className="free-gift-product-title">
                    <Link to={navigateURL} className="text-dark">
                      <Card.Title
                        className="cart-product-name"
                        data-testid="qa-product-name">
                        {productName}
                      </Card.Title>
                    </Link>
                  </Col>
                  <Col lg={12} className="free-gift-product-price">
                    <Row noGutters>
                      <Price
                        price={String(transactionPrice.price)}
                        currency={currency}
                        priceLable={isFlexibleSubscriptionEnabled}
                      />
                    </Row>
                  </Col>
                  <Col lg={12} className="free-gift-product-quantity">
                    <Row noGutters>
                      {!freeGiftOOSQty && (
                        <Col
                          lg={8}
                          md={8}
                          className="d-flex align-items-center text-uppercase font-weight-bold">
                          <span className="free-gift-quantity-label">
                            {quantityTitle}
                          </span>
                          <span className="free-gift-quantity-colon">:</span>
                          <span className="ml-1 cart-free-gift-quantity">
                            {freeGiftQty}
                          </span>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col
                    lg={12}
                    className="pb-2 font-weight-bold free-gift-error-message">
                    {freeGiftOOSQty && availableQty > 0 ? (
                      <>
                        <Row noGutters>
                          <Col
                            lg={12}
                            md={12}
                            className="d-flex align-items-center font-weight-bold text-danger">
                            {i18nTranslate(
                              'pdp.sorryFreeGiftOOSTxt',
                              'Sorry, few gifts are out of stock',
                              {
                                nameSpace: 'ssr-resource',
                              }
                            )}
                          </Col>
                          <Col
                            lg={8}
                            md={8}
                            className="d-flex align-items-center text-uppercase font-weight-bold">
                            {i18nTranslate('pdp.AvailableQtyTxt', 'Available', {
                              nameSpace: 'ssr-resource',
                            })}
                            :<span className="ml-1">{availableQty}</span>
                          </Col>
                          <Col
                            lg={8}
                            md={8}
                            className="d-flex align-items-center text-uppercase font-weight-bold">
                            {i18nTranslate('pdp.OOSQtyTxt', 'Out of stock', {
                              nameSpace: 'ssr-resource',
                            })}
                            :<span className="ml-1">{freeGiftOOSQty}</span>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                    {freeGiftOOSQty && availableQty <= 0 ? (
                      <Row noGutters>
                        <Col
                          lg={12}
                          md={12}
                          className="d-flex align-items-center font-weight-bold text-danger">
                          {i18nTranslate('pdp.freeGiftOOSTxt', 'Out Of Stock', {
                            nameSpace: 'ssr-resource',
                          })}
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    )
  }
}

export { FreeGiftCartProductBlock }
export default FreeGiftCartProductBlock
