import React from 'react'
import { formatPrice } from './deps'
import { i18nTranslate } from 'src/utils'

class Price extends React.Component {
  getPrice = () => {
    const { transactionPrice = {}, price = 0, totalValue = {} } = this.props
    const formattedPrice = formatPrice({
      transactionPrice,
      // currencyData: currency,
      price,
      totalValue,
    })
    return formattedPrice
  }
  renderMinMaxPrice = () => {
    const formattedPrice = this.getPrice() || {}
    const price =
      formattedPrice.minPrice === formattedPrice.maxPrice
        ? formattedPrice.minPrice
        : formattedPrice.minPrice + ' - ' + formattedPrice.maxPrice
    return (
      <>
        {this.renderPrice({
          className: '',
          dataTestId: 'qa-product-range-price',
          price,
        })}
      </>
    )
  }

  renderRegSalePrice = (formattedPrice = {}) => {
    return (
      <>
        {formattedPrice &&
          formattedPrice.salePrice &&
          this.renderPrice({
            className: `sales pl-1 ${
              formattedPrice.regularPrice ? 'pr-2 color-blue' : ''
            }`,
            dataTestId: 'qa-product-sale-price',
            price: formattedPrice.salePrice,
          })}
        {formattedPrice &&
          formattedPrice.regularPrice &&
          this.renderPrice({
            className: 'original',
            dataTestId: 'qa-product-reg-price',
            price: formattedPrice.regularPrice,
            isToStrikeOut: formattedPrice.hasBothPriceAvail ? true : false,
          })}
      </>
    )
  }
  renderPrice = props => {
    const {
      className = '',
      price = '',
      isToStrikeOut = false,
      dataTestId = '',
    } = props
    const customPrice = isToStrikeOut ? <del>{price}</del> : ' ' + price
    const { priceLable = false } = this.props
    return (
      <div className="d-flex qty-minicart">
        {customPrice && priceLable && (
          <span className="price-label">
            <span className="price-text mr-1">
              {i18nTranslate('plp.price', 'Price', {
                nameSpace: 'ssr-resource',
              })}
            </span>
            <span className="price-colon">:</span>
          </span>
        )}
        <span className={`${className}`} data-testid={dataTestId}>
          {customPrice}
        </span>
      </div>
    )
  }
  renderPriceComponent = () => {
    const formattedPrice = this.getPrice() || {}
    if (formattedPrice.salePrice || formattedPrice.regularPrice) {
      return this.renderRegSalePrice(formattedPrice)
    }
    return this.renderMinMaxPrice()
  }
  // #BundlePriceLogic
  renderDirectPrice = () => {
    const formattedPrice = this.getPrice() || {}
    return this.renderPrice({
      className: 'sale',
      price: formattedPrice,
    })
  }
  render() {
    return (
      <div className={this.props.classStyles || ''}>
        {this.props.price
          ? this.renderDirectPrice()
          : this.props.showPriceRange
          ? this.renderMinMaxPrice()
          : this.renderPriceComponent()}
      </div>
    )
  }
}

export { Price }
export default Price
