import React from 'react'
import { observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import TagManager from 'react-gtm-module'
import { customerContainer, nuskinSubscriptionContainer } from 'src/models'
import { IS_BROWSER } from 'src/utils'
import SubscriptionProductButton from './SubscriptionProductButton'
import SingleProductSubscriptionModal from '../SingleProductSubscriptionModal'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { quickViewState } from 'src/views/components/QuickView/state'
import isEmpty from 'lodash/isEmpty'

@observer
class NuskinSubscriptionButton extends React.Component {
  @observable showSingleProductModal = false

  constructor(props) {
    super(props)
    // this.init(props)
  }

  componentDidMount() {
    if (customerContainer.isProfileCallOver) {
      this.init(this.props)
    } else {
      const reactions = reaction(
        () => customerContainer.isProfileCallOver,

        (isProfileCallOverNewValue, reaction) => {
          this.init(this.props)
          reaction.dispose() // reaction is disposed ( reaction happens only once )
        }
      )
    }
  }

  init = async props => {
    await nuskinSubscriptionContainer.init(props)
  }

  openSingleSubscribeProductModal = () => {
    nuskinSubscriptionContainer.setActiveProductSkuId(
      this.props?.product?.skuId || '',
      this.props?.product?.productId
    )
    this.showSingleProductModal = true
  }

  removeNoScrollForPage = () => {
    if (document.body.classList.contains('no-scroll') && IS_BROWSER) {
      document.body.classList.remove('no-scroll')
    }
  }

  hideSingleSubscribeProductModal = () => {
    this.showSingleProductModal = false

    // if (singleProductItemState) {
    //   const prevProductQuantity = singleProductItemState.prevProductQuantity
    //   singleProductItemState.updateQuantity(prevProductQuantity)
    //   singleProductItemState.resetFrequency()
    // }
    this.removeNoScrollForPage()
  }
  getTotalPriceForAnalytics = () => {
    const product = this.props?.product || {}
    const totalValue = product?.isBundle
      ? productState.bundle_totalValueForSubscriptionPopup
      : this.props.isFromQuickView
      ? quickViewState.normalPdt_totalValueForSubscriptionPopup
      : productState?.normalPdt_totalValueForSubscriptionPopup
    const isPriceEmpty = isEmpty(totalValue)
    if (isPriceEmpty) {
      return ''
    }
    const priceAfterDiscount = totalValue?.priceAfterDiscount || 0
    return priceAfterDiscount
  }

  doSubscribe = async modalData => {
    let postParameters =
      nuskinSubscriptionContainer.getPostBodyForAddSubscription(
        modalData,
        this.props?.scanCount || ''
      )

    /**
     * @info
     * GTM Event for Add to subscription Cart
     *triggers on click of subscribe button in pdp page
     */
    if (postParameters?.[0]) {
      postParameters[0].price = this.getTotalPriceForAnalytics()
    }
    let path = ''
    try {
      const urlPath = window?.location?.pathname || ''
      const pathParts = urlPath?.split?.('/') || []
      pathParts.splice(0, 2)
      path = pathParts.join('/')
    } catch {
      console.log('error while fetching path for addToSubscriptionCart')
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'addToSubscriptionCart',
        pagePath: path || `/product/:id`,
        pageTitle: 'Product Details',
        subscriptionInfo: postParameters,
        platform: 'equinox',
      },
    })

    this.hideSingleSubscribeProductModal()

    const checkForBackOrderArray = []

    await nuskinSubscriptionContainer.addSubscription(
      postParameters,
      this.props?.product || {}
    )
  }

  render() {
    let isUserSubscribedBefore =
      nuskinSubscriptionContainer.isUserSubscribedBefore()
    const product = {
      ...this.props.product,
      quantity: this.props.productCount,
    }
    return (
      <div>
        {this.showSingleProductModal && (
          <SingleProductSubscriptionModal
            product={product}
            isSubscribedBefore={isUserSubscribedBefore}
            isSignedUser={customerContainer.isRegisterUser}
            //subscriptionId and accountId should be passed
            onUserSubscribe={this.doSubscribe}
            show={this.showSingleProductModal}
            onHide={this.hideSingleSubscribeProductModal}
            isFromQuickView={this.props?.isFromQuickView}
            isModalFromPDP={true}
          />
        )}
        <SubscriptionProductButton
          openSingleSubscribeProductModal={this.openSingleSubscribeProductModal}
          {...this.props}
          isFromQuickView={this.props.isFromQuickView}
        />
      </div>
    )
  }
}

export { NuskinSubscriptionButton }
export default NuskinSubscriptionButton
