import * as React from 'react'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import './styles.scss'

class ImageComponent extends React.Component {
  getSrcSet = () => {
    let srcSet = []
    if (this.props?.srcSet?.length > 0 && this.props?.src) {
      srcSet = this.props?.srcSet?.map(imageSrc => {
        const url = `${this.props.src}?width=${imageSrc} ${imageSrc}w`
        return url
      })
    }
    return srcSet.join(' , ')
  }
  render() {
    const {
      src = '',
      name,
      className = '',
      testId,
      width = 'auto',
      height = 'auto',
      ariaLabel = '',
      loadingType = 'lazy',
      optimizeImage = false,
      title,
      alt,
      dataAltSrc,
      customParentClassName = '',
      overrideParentClass = false,
    } = this.props

    const parentClass = overrideParentClass
      ? customParentClassName
      : 'image-wrapper col-12 p-0 m-0 '
    const widthValue = width?.replace(/[A-Z]/gi, '') || ''
    const widthQueryParam = `width=${widthValue}`
    const concatenateSymbol = src.includes('?') ? '&' : '?'

    const ImageUrl =
      src + (optimizeImage ? `${concatenateSymbol}${widthQueryParam}` : '')

    return (
      <div
        className={parentClass}
        style={{
          width: `${width}`,
          height: `${height}`,
        }}>
        <img
          data-testid={testId}
          onError={e => {
            if (e.target.src != imagePlaceholder) {
              e.target.onerror = null
              e.target.src = imagePlaceholder
            }
          }}
          src={ImageUrl || imagePlaceholder}
          data-src={ImageUrl || imagePlaceholder}
          srcSet={this.getSrcSet()}
          loading={loadingType}
          alt={alt || name}
          height={height}
          width={width}
          title={title}
          className={className}
          aria-label={ariaLabel}
          data-alt-text-resolution={dataAltSrc}
        />
      </div>
    )
  }
}

export { ImageComponent }
export default ImageComponent
