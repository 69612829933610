import React from 'react'
import { observer } from 'mobx-react'
import ReactSlick from 'react-slick'
import i18n from 'i18next'
import { GoPrimitiveDot } from 'react-icons/go'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { Container } from 'react-bootstrap'
import { JsonLd, ProductCard } from 'src/views/components'
import { safeParseInt, setResponsiveValues } from 'src/utils'
import { productSchema } from 'src/models'
import './styles.scss'

@observer
class ProductCarousel extends React.Component {
  static defaultProps = {
    list: [],
    gridGap: 10,
    desktopColSpan: 4,
    tabletColSpan: 3,
    mobileColSpan: 2,
    hasArrows: true,
    hasBullets: true,
  }

  renderItem = productDetails => {
    return <ProductCard product={productDetails} showLazyLoad={false} />
  }

  render() {
    const {
      hasArrows = defaultProps.hasArrows,
      hasBullets = defaultProps.hasBullets,
      desktopColSpan = defaultProps.desktopColSpan,
      tabletColSpan = defaultProps.tabletColSpan,
      mobileColSpan = defaultProps.mobileColSpan,
      list = defaultProps.list,
    } = this.props || {}
    const slides = safeParseInt(
      setResponsiveValues(desktopColSpan, tabletColSpan, mobileColSpan)
    )
    const schemaData = list.map(listItem => {
      return productSchema(listItem)
    })
    const ForwardIcon =
      i18n.dir() === 'rtl' ? IoIosArrowBack : IoIosArrowForward
    const BackIcon = i18n.dir() === 'rtl' ? IoIosArrowForward : IoIosArrowBack

    return (
      <Container className="product-carousel p-0">
        <ReactSlick
          arrows={hasArrows === 'true' || hasArrows === true}
          dots={hasBullets === 'true' || hasBullets === true}
          draggable={true}
          infinite={false}
          speed={500}
          slidesToShow={slides}
          slidesToScroll={slides}
          initialSlide={0}
          nextArrow={<ForwardIcon size="2rem" />}
          prevArrow={<BackIcon size="2rem" />}
          dotsClass="d-flex justify-content-center dots-style pt-3 px-0"
          customPaging={() => {
            return (
              <span className="dot-class">
                <GoPrimitiveDot size="1.5rem" />
              </span>
            )
          }}>
          {list.map(this.renderItem)}
        </ReactSlick>
        <JsonLd data={schemaData} key="schema-carousel" />
      </Container>
    )
  }
}

export default ProductCarousel
export { ProductCarousel }
