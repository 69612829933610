/**
 * Generates a social media profile schema for an organization
 * @param {Object} params - Parameters for the schema
 * @param {string} [params.name] - Name of the organization
 * @returns {Object} The social media schema object
 */
function socialProfileSchema(params) {
  const name = params?.name
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: name,
    url: 'https://www.skava.com',
    // sameAs: params,
  }
}

export { socialProfileSchema }
