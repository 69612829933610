import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router'
import { action } from 'mobx'
import loadable from '@loadable/component'
import querystring from 'querystring'
import pathParams, { pageNames } from 'src/routes/pathParams'
import {
  IS_BROWSER,
  application,
  bindBrowserScroll,
  setLocalStorage,
  getLocalStorage,
  deleteFromLocalStorage,
  checkMysiteOrPersonalOffer,
  getRedirectUrl,
  isGPSEnabled,
  isGPSDefaultPayment,
  getLiveEventStatus,
} from 'src/utils'
import {
  // Header,
  Toast,
  AppLoader,
  ModalView,
  Overlay,
  KlarnaOverlay,
  ErrorBoundary,
  toastState,
} from 'src/views/components'

import NuskinHeader from 'src/views/components/Header/NuskinHeader'
import NuSkinFooter from 'src/views/components/NuSkinFooter'
import { PersonalHeaderWithRouter } from 'src/views/pages/PersonalHeader'
// import { getLocalStorage, setLocalStorage } from 'src/utils'
import { PersonalFooter } from 'src/views/pages/PersonalFooter'
import LiveEventHeader from 'src/views/components/Header/LiveEventHeader'
import LiveEventFooter from 'src/views/components/LiveEventFooter'
import { ScrollToTopWithRouter } from './ScrollToTop'
import { customerContainer, subscriptionContainer } from 'src/models'
import xss from 'xss'

// import { localeList } from 'src/views/components/LocaleDropdown/fixture'

// @Refer https://webpack.js.org/api/module-methods/
const LoadableComponent = loadable(props =>
  import(`src/views/pages/${props.pageComponentName}`)
)
const UnSupported = loadable(props =>
  import(`src/views/components/UnSupported/UnSupported`)
)

function TrackPageViews() {
  let location = useLocation()
  useEffect(() => {
    // console.log('current page location is >>> ', { location })
    /**
     * @fix for NUSKIN-1109, PLT813-4813, PLT813-4815
     * after signin required to redirect to prev location
     * so saving the current page location at localStorage
     */
    if (
      IS_BROWSER &&
      !location.pathname.includes(pageNames.loginCallback) &&
      !location.pathname.includes(pageNames.logoutCallback)
    ) {
      const curPageUrl = `${location.pathname}${location.search}`
      const prevPageUrl = getLocalStorage('curPageUrl') || ''
      if (curPageUrl !== prevPageUrl) {
        setLocalStorage('prevPageUrl', prevPageUrl)
        setLocalStorage('curPageUrl', curPageUrl)
        const isProfileRelatedPage =
          curPageUrl?.includes('myprofile') ||
          curPageUrl?.includes('accountprofile')
        if (!isProfileRelatedPage) {
          // check and reset session if not profile related page
          // for profile page, call is made on MyAccountSection.jsx componentDidMount
          customerContainer?.getProfile({ isToCheckSession: true })
        }
        if (prevPageUrl.includes(pageNames.myaccountsubscriptions)) {
          deleteFromLocalStorage('subscriptionActiveTab')
          deleteFromLocalStorage('scheduleOrderSelectedDate')
          deleteFromLocalStorage('subscriptionNextOrderSelectedDate')
          subscriptionContainer.subscriptionSelectedDate = ''
        }
      }
      checkAndShowCommonToast()
    }
  }, [location])

  return <></>
}

/**
 * Checks for any error info in local storage
 * and displays toast message if found.
 * Use errorInfo to show toast message once the page is redirected
 *
 * @returns {void}
 */
function checkAndShowCommonToast() {
  const errorInfo = getLocalStorage('errorInfo')

  if (errorInfo) {
    toastState?.setToastMessage(errorInfo, false)
    deleteFromLocalStorage('errorInfo')
  }
}

function renderRoutes(routes, locale) {
  // @todo array is referencing unshift will create duplicate
  routes.unshift({
    path: '/' + locale,
    pageComponentName: 'StudioPage',
  })

  const sanitizeParams = routeProps => {
    let locationQueryString = routeProps?.location?.search || ''
    if (locationQueryString === '') {
      return locationQueryString
    }
    try {
      /**
       * @note fix for: [SECTEM-8208] security bug
       * where the URL can be encoded N number of times
       * and user can inject XSS in it
       * so, to avoid that decoding the url based on the count that user made
       *
       */
      const decodeAll = uri => {
        let decodedURL = uri
        while (decodedURL !== decodeURIComponent(decodedURL)) {
          decodedURL = decodeURIComponent(decodedURL)
        }
        return decodedURL
      }

      let decodeLocationQueryString = decodeAll(locationQueryString)
      let queryValues = decodeLocationQueryString.slice(1)
      let sanitizeValues = xss(queryValues, {
        whiteList: {},
        stripIgnoreTag: true, // filter out all HTML not in the whilelist
        stripIgnoreTagBody: ['script'],
      })

      return `?${sanitizeValues}`
    } catch (ex) {
      console.error(ex)
    }
    return locationQueryString
  }

  // Global Payment System - Start
  // Code to check if GPS payment is enabled and based on that update path
  const isGPSEnabledAndDefault = isGPSEnabled() && isGPSDefaultPayment()
  let modifiedRoutes = routes
  if (isGPSEnabledAndDefault) {
    pageNames.checkout = '/checkoutGps'
    modifiedRoutes = routes.map(route => {
      if (route.path === '/checkout') {
        return { ...route, path: '/checkoutGps' }
      }
      return route
    })
  }
  // Global Payment System - End

  return modifiedRoutes.map((route, index) => {
    const { path, isExact = true } = route
    return (
      <Route
        key={route.key || `route-${index}`}
        path={path}
        exact={isExact}
        strict
        render={routeProps => {
          const locationQueryString = sanitizeParams(routeProps)
          const domainName = routeProps?.staticContext?.requestDomain || ''
          const queryParams = querystring.parse(locationQueryString.slice(1))
          routeProps.location.queryParams = queryParams
          routeProps.location.domainName = domainName

          if (IS_BROWSER) {
            const redirectUrl = getRedirectUrl({
              pageUrl: routeProps?.location?.pathname,
            })
            if (redirectUrl) {
              // Timeout keep the render pure function
              setTimeout(() => {
                routeProps?.history?.replace(redirectUrl)
              }, 200)
              return ''
            }

            if (typeof window?.ineum !== 'undefined') {
              let pageName = route.pageComponentName
              if (route?.path == '/search/:searchTerm') {
                window.ineum('page', 'Search')
                // since pageComponentName is same for search and catalog
              } else {
                window.ineum('page', pageName)
              }
            }
          }

          return (
            <ErrorBoundary>
              <LoadableComponent {...routeProps} {...route} />
            </ErrorBoundary>
          )
        }}
      />
    )
  })
}

class Routes extends React.Component {
  constructor(props) {
    super(props)
  }

  enableLiveEvent = getLiveEventStatus()

  @action
  componentDidMount() {
    application.IsAppLoading = false
    bindBrowserScroll()
  }

  render() {
    let personalOffer
    // let shoppingCarts
    const locale = this.props.basename
    const isMySiteUrls = [
      pageNames.signUp,
      pageNames.loginCallback,
      pageNames.viewCart,
      pageNames.checkout,
      pageNames.orderConfirmation,
      pageNames.myaccountsubscriptions,
    ]
    try {
      if (typeof window !== 'undefined') {
        // const shoppingContext = localStorage.getItem('shoppingContext')
        // const shoppingContext = getShoppingContext()
        let userDetails = sessionStorage.getItem('storefront')
        if (userDetails) {
          userDetails = JSON.parse(userDetails)
        }
        let userId = userDetails?.sapId || ''

        let isMySite = isMySiteUrls.some(url => {
          if (location.pathname.includes(url)) {
            return true
          }
        })
        // if (shoppingContext) {
        //   shoppingCarts = JSON.parse(shoppingContext)
        // }
        // const contextValue = shoppingCarts?.context
        const contextValue = checkMysiteOrPersonalOffer()
        if (
          (contextValue === 'storefront' ||
            contextValue === 'personal_offer') &&
          isMySite
        ) {
          personalOffer = true
        }
      }
    } catch (e) {
      console.error('JSON.parse may failed >>> Routes', e)
    }
    // US CA market header&footer should not be loaded for mysite
    // so disabling SSR for mysite related pages
    if (!IS_BROWSER) {
      if (this.props.urlWithoutLocale) {
        let disableSSRForMysiteRelatedPages = isMySiteUrls.some(url => {
          return this.props.urlWithoutLocale.includes?.(url)
        })
        if (disableSSRForMysiteRelatedPages) {
          return <></>
        }
      }
    }
    return (
      <React.Fragment>
        <Overlay position="fixed" textToShow="" />
        <KlarnaOverlay position="fixed"></KlarnaOverlay>
        <AppLoader />
        <Toast />
        <UnSupported />
        <ModalView />
        {this.enableLiveEvent !== 'true' ? (
          personalOffer ? (
            <PersonalHeaderWithRouter />
          ) : (
            <NuskinHeader />
          )
        ) : (
          <LiveEventHeader />
        )}
        <section id="id-app-wrapper" className="app-wrapper position-relative">
          <ScrollToTopWithRouter>
            <TrackPageViews />
            <Switch>{renderRoutes(pathParams, locale)}</Switch>
          </ScrollToTopWithRouter>
        </section>
        {this.enableLiveEvent !== 'true' ? (
          personalOffer ? (
            <PersonalFooter />
          ) : (
            <NuSkinFooter />
          )
        ) : (
          <LiveEventFooter />
        )}
      </React.Fragment>
    )
  }
}

export { Routes, renderRoutes }
export default Routes
