import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Row, Container } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import { tryJSONParse } from 'src/utils'
import { AccordionBlockPDP } from 'src/views/components/AccordionBlockPDP'
import { getProductPDPTitle } from 'src/utils/commonUtils'
import { APPConfig } from 'config/appConfig'
import './styles.scss'

@observer
class ProductHowtoUseit extends React.Component {
  nameSpace = 'ssr-resource'
  handleHowToUseVideoPlay = () => {
    const product = this.props?.product || {}
    const { name, productId, skuId } = product

    /**
     * @info
     * GTM Datalayer - Send event when vide play button is clicked on PDP - How To Use It Section.
     */
    TagManager.dataLayer({
      dataLayer: {
        event: 'videoPlayed',
        name: name || '',
        productId: productId || '',
        skuId: skuId || '',
        section: 'PDP How To Use It',
        pagePath: `/product/:id`,
        pageTitle: 'Product Details Page',
        platform: 'equinox',
      },
    })
  }
  getHowUseVideo = (videoUrl = '') => {
    if (videoUrl != '') {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = videoUrl.match(regExp)
      let videoId = match?.[2].length === 11 ? match?.[2] : null
      if (videoUrl.match(regExp)) {
        const youtubeUrl = 'https://www.youtube.com/embed/' + videoId
        return (
          <iframe
            src={youtubeUrl}
            aria-label={this.props?.product?.title || ''}
            className="mt-3"
            width="300"
            height="200"></iframe>
        )
      } else {
        return (
          <video
            src={videoUrl}
            onPlay={this.handleHowToUseVideoPlay}
            type="video"
            controls="true"
            className="mt-3"
            width="300"
            height="200"
            aria-label={this.props?.product?.title || ''}></video>
        )
      }
    }
  }
  renderTitle = () => {
    const { isAccordionType = false } = this.props
    const howToUseItLabel = getProductPDPTitle(
      this.props.product?.tempProperties,
      'usage'
    )
    return (
      <Row
        className={`about-title ${
          isAccordionType ? 'text-uppercase' : 'border-light-grey'
        }`}
        noGutters>
        {howToUseItLabel ||
          i18nTranslate('pdp.howToUseIt', 'How to Use It', {
            nameSpace: this.nameSpace,
          })}
      </Row>
    )
  }

  renderDescription = (Data = '[]') => {
    const parsedDescription = tryJSONParse(Data)
    const isArray = Array.isArray(parsedDescription)
    if (isArray) {
      return (
        <ul>
          {parsedDescription.map((desc, index) => {
            if (typeof desc === 'object') {
              return <li key={`desc-${index}`}>{desc?.description}</li>
            } else if (desc) {
              return <li key={`desc-${index}`}>{desc}</li>
            }
            return ''
          })}
        </ul>
      )
    }
    return (
      <Container>
        <Row
          className="about-product pb-3 text-dark"
          noGutters
          dangerouslySetInnerHTML={{
            __html: Data,
          }}
        />
      </Container>
    )
  }
  renderProductWarnings = warnings => {
    return (
      <>
        {warnings && (
          // <div>
          //   <div className="d-flex items-center">
          //      <div>
          //       {<Icons
          //         name="product-icon"
          //         icontype="danger-warning"
          //         width="20px"
          //         color="#fd983b"
          //       /> }
          //       {warnings && warnings?.length > 0 && (
          //         <h5 className="subhead-m">
          //           Warnings<span className="mr-1">: </span>
          //         </h5>
          //       )}
          //     </div> }
          <div className="font-italic">{warnings}</div>
          //   </div>
          // </div>
        )}
      </>
    )
  }

  render() {
    const product = this.props?.product || {}
    const usage = product.usage || '{}'
    let video = {}
    let additionalText = []
    let recommendations = []
    let steps = []
    let warnings = ''
    try {
      ;({
        video = {},
        additionalText = [],
        recommendations = [],
        steps = [],
        warnings = '',
      } = JSON.parse(usage))
    } catch (e) {
      console.log('Data parse error::: ProductHowtoUseit' + e)
    }
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    return (
      <>
        {(steps?.length > 0 ||
          video?.url?.length > 0 ||
          additionalText?.length > 0 ||
          recommendations?.length > 0) && (
          <div className="product-accordion-wrapper">
            <AccordionBlockPDP
              title={this.renderTitle()}
              isDefaultOpen={false}
              className="accordion-description"
              dataTestId="qa-pdp-how-to-use">
              {video?.url && (
                <div className="pdp-videos container">
                  {this.getHowUseVideo(video.url)}
                </div>
              )}
              <Container className="pdp-accordion-content-container">
                <div className="how-to-use-accordion align-items-start justify-content-start flex-wrap">
                  {steps?.length > 0 && (
                    <div
                      className="use-steps p-0"
                      data-testid="qa-pdp-how-to-use-description">
                      {!enableNewPDPStyle && (
                        <h4 className="subhead-m">STEPS</h4>
                      )}
                      <ul className="paragraph-l">
                        {steps.map((step, index) => (
                          <div
                            key={index}
                            className="how-to-use"
                            data-testid={`qa-pdp-how-to-use-info-${index + 1}`}>
                            <div className="how-to-use-steps-index">
                              {index + 1}
                            </div>
                            <div className="how-to-use-steps">{step}</div>
                          </div>
                        ))}
                      </ul>
                    </div>
                  )}
                  {recommendations?.length > 0 && (
                    <div className="recommendations">
                      <h4
                        className="subhead-m"
                        data-testid="qa-pdp-how-to-use-recommendations">
                        Recommendations
                      </h4>
                      <ul className="p-0 paragraph-m">
                        {recommendations.map((recom, index) => (
                          <li key={index}>{recom}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {additionalText?.length > 0 && (
                    <div
                      className="additional-text"
                      data-testid="qa-pdp-how-to-use-additional-text">
                      <ul className="p-0 paragraph-m">
                        {additionalText.map((additional, index) => (
                          <li key={index}>{additional}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {warnings?.length > 0 && this.renderProductWarnings(warnings)}
                  {/* CX121-3606 ^ warning support added */}
                </div>
              </Container>
            </AccordionBlockPDP>
          </div>
        )}
      </>
    )
  }
}

export { ProductHowtoUseit }
