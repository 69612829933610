import React from 'react'
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'react-bootstrap'
import { JsonLd } from 'src/views/components/JsonLd'
import { breadcrumbSchema } from 'src/models/Schema'
import { Link } from 'react-router-dom'
import isMerchPLP from 'config/appConfig'
import './styles.scss'

class BreadCrumb extends React.Component {
  getBreadCrumbList = (list = {}) => {
    if (list.label !== '') {
      return (
        <React.Fragment key={list.label}>
          {list.isLastElement ? (
            <BreadcrumbItem
              className="breadcrumb-item-list text-truncate"
              data-testid="qa-breadcrumb"
              aria-current="page"
              active>
              {list.label}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem
              key={list.label}
              className="breadcrumb-item-list text-truncate flex-shrink-0"
              data-testid="qa-breadcrumb"
              linkProps={{ to: list.value }}
              linkAs={Link}>
              <span>{list.label}</span>
            </BreadcrumbItem>
          )}
        </React.Fragment>
      )
    }
    return <></>
  }
  render() {
    if (isMerchPLP) {
      return <></>
    }
    const { breadCrumbList = [], location = '' } = this.props || {}
    const view = breadCrumbList.map(this.getBreadCrumbList)
    const schemaData = breadcrumbSchema(breadCrumbList, location)

    return (
      <Row noGutters>
        <Col className="mw-max-content">
          <Breadcrumb
            className="px-3 d-flex breadcrumb mb-0 p-0 rounded-0"
            listProps={{
              className:
                'container m-0 h-auto p-0 align-items-center py-2 text-truncate flex-nowrap',
            }}>
            {view}
            {this.props.children || <></>}
          </Breadcrumb>
          <JsonLd data={schemaData} key="schema-breadcrumb" />
        </Col>
      </Row>
    )
  }
}

export { BreadCrumb }
export default { BreadCrumb }
