import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row } from 'react-bootstrap'
import { APPConfig, getLocalStorage } from 'config/appConfig'
import { i18nTranslate, currencyFormat } from 'src/utils'
import { Quantity } from 'src/views/components'
import './styles.scss'

@observer
class QuantityAndTotalDetails extends React.Component {
  constructor(props) {
    super(props)
  }

  /**
   * Renders the quantity selector and total quantity text.
   *
   * Uses the Quantity component to render an input for updating quantity.
   * Also shows the current total quantity.
   *
   * @param {Object} subscriptionProduct - The product object
   * @param {function} handleQuantityChange - Handler for quantity change
   * @param {function} handleQuantityChangePromotion - Handler for quantity change from promotion
   * @param {boolean} isToDisableQuantityIcons - Whether to disable the +/- buttons
   */
  renderQuantity = () => {
    const {
      subscriptionProduct = [],
      handleQuantityChangePromotion,
      handleQuantityChange,
      isToDisableQuantityIcons,
    } = this.props
    const selectedQuantity = subscriptionProduct?.quantity || 1
    const maxLimit = APPConfig?.getAppConfig()?.cartMaxLimit || 99
    return (
      <Row
        noGutters
        className="align-items-center cart-drawer-quantity-wrapper">
        <div className="cart-drawer-quantity-text">
          {i18nTranslate('cart.quantity', 'Qty.')}
        </div>
        <Quantity
          maxValue={maxLimit}
          onQuantityChange={handleQuantityChange}
          handleQuantityChangePromotion={handleQuantityChangePromotion}
          product={subscriptionProduct}
          defaultValue={selectedQuantity}
          isV2QuantityStyle={true}
          isToDisableIcons={isToDisableQuantityIcons}
        />
      </Row>
    )
  }

  /**
   * Renders the total price text.
   *
   * Calculates the total price based on the original price, discounts,
   * and price after discounts. Handles cases where the price is free due to promotions.
   *
   * @param {Object} subscriptionProduct - The product object
   */
  renderTotal = subscriptionProduct => {
    const {
      originalPrice = 0,
      discount = 0,
      priceAfterDiscount = 0,
      totaldiscount = 0,
      priceFacets = {},
    } = subscriptionProduct?.totalValue || {}
    const totalSV = this.getPrice(priceFacets?.PV?.PVAfterDiscount) || 0
    const accType = getLocalStorage('accountType') || ''
    const isBAUser =
      accType === 'Brand Affiliate' ||
      accType === 'Brand Affiliate - Business Entity' ||
      false
    const totalPriceDiscount = priceAfterDiscount || totaldiscount || 0
    const grandTotal = priceAfterDiscount
      ? currencyFormat({ price: priceAfterDiscount })
      : ''
    const totalPrice = originalPrice
      ? currencyFormat({ price: originalPrice })
      : ''

    const priceFreePromotion =
      originalPrice - totalPriceDiscount == priceAfterDiscount &&
      priceAfterDiscount == 0
        ? true
        : false

    return (
      totalPrice && (
        <Row noGutters className="cart-drawer-total-price-wrapper">
          <Col lg={12} className="flex-wrap">
            <span className="break-word">
              {i18nTranslate('cart.total', 'Total')}
            </span>
            {':'}
            <span className="pl-1 break-word" aria-live="polite">
              {priceFreePromotion
                ? '$0.00'
                : grandTotal != ''
                ? grandTotal
                : totalPrice}
            </span>
          </Col>
          {totalSV !== 0 && isBAUser && (
            <div className="cart-drawer-total-sv-wrapper">
              <span className="cart-drawer-sv" aria-live="polite">
                {i18nTranslate('cart.itemTotalSV', 'SV')} : {totalSV}
              </span>
            </div>
          )}
        </Row>
      )
    )
  }

  getPrice = (priceValue = 0) => {
    const formattedPrice = currencyFormat({
      price: priceValue,
    })
    const formattedCustomPrice = formattedPrice?.replace('$', '')
    return formattedCustomPrice
  }

  render() {
    const { subscriptionProduct = [] } = this.props
    return (
      <div className="v2-cart-qty-total-wrapper d-flex align-items-center">
        <div className="v2-cart-qty-wrapper">{this.renderQuantity()}</div>
        <div className="v2-cart-total-wrapper ml-1">
          {this.renderTotal(subscriptionProduct)}
        </div>
      </div>
    )
  }
}

export default QuantityAndTotalDetails
export { QuantityAndTotalDetails }
