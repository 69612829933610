import React from 'react'
import { observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import { Row, Col, Button, Container, Modal } from 'react-bootstrap'
import { IoMdClose as CloseIcon, IoIosAdd as AddIcon } from 'react-icons/io'
import ReactSlick from 'react-slick'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { shouldShowAddedBundleDetails } from 'config/appConfig'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import {
  application,
  getLocalStorage,
  checkEnableButton,
  modifyAndGetCustomerType,
  modifyAndGetAccountType,
  checkCustomerTypeEligibility,
} from 'src/utils'
import { productDeps, dateFormatDeps } from 'src/deps'
import { Quantity, CustomPrice, KlarnaMessage } from 'src/views/components'
import { i18nTranslate } from 'src/utils'
import {
  cartContainer,
  customerContainer,
  nuskinSubscriptionContainer,
} from 'src/models'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { NuskinSubscriptionButton } from 'src/views/components/SubscriptionComponents/NuskinSubscriptionButton'
import { PayLaterMessage, isPaylaterMessageRequired } from '../PayPal'
import './styles.scss'

@observer
class BundleFloatingBar extends React.Component {
  @observable fixedBar = true
  @observable isToShowBundle = false
  @observable isUserSubscribedBefore = false

  constructor(props) {
    super(props)
  }
  async componentDidMount() {
    if (
      application.isTabletOrSmaller ||
      shouldShowAddedBundleDetails === false
    ) {
      this.fixedBar = true
    } else {
      this.fixedBar = false
    }
    reaction(
      () => [
        cartContainer.subscriptionResponse?.totalSubscriptionCount,
        cartContainer.subscriptionResponse?.isSubscriptionPreference,
      ],
      () => {
        this.isUserSubscribedBefore =
          nuskinSubscriptionContainer.isUserSubscribedBefore()
        this.forceUpdate()
      }
    )
  }

  handleRemove = identifier => {
    productDeps.optionalData.splice(
      productDeps.optionalData.indexOf(identifier),
      1
    )
    productState.makePromotionCallsForBundleKits(
      productState.product,
      productState.productCount
    )
  }
  handleClick = () => {
    this.isToShowBundle = !this.isToShowBundle
    if (this.isToShowBundle) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }
  switchBetween = () => {
    if (!application.isMobile && !application.isTablet) {
      document.body.classList.remove('no-scroll')
    } else if (this.isToShowBundle) {
      document.body.classList.add('no-scroll')
    }
  }
  getImageList = item => {
    return {
      identifier: item?.identifier,
      imageURL: item?.selectedSku?.properties?.imageURL || '',
      type: 'mandatory',
    }
  }

  getData = () => {
    const {
      bundleMandatoryProducts = [],
      bundleOptionalProducts = [],
      skuKit = [],
    } = this.props?.product || {}
    let mandatoryProducts = bundleMandatoryProducts?.length
      ? bundleMandatoryProducts?.map(this.getImageList)
      : skuKit?.length && skuKit?.map(this.getImageList)
    const optionalProducts = []
    productDeps.optionalData.forEach(data => {
      bundleOptionalProducts.length &&
        bundleOptionalProducts.forEach(optionalData => {
          if (optionalData.identifier === data) {
            optionalProducts.push({
              imageURL: optionalData?.selectedSku?.properties?.imageURL || '',
              identifier: optionalData?.identifier,
              type: 'optional',
            })
          }
        })
    })
    return {
      optionalProducts,
      mandatoryProducts,
    }
  }
  renderFixedOrOptionalBundle = (data, index) => {
    return (
      <div
        className="floatingBar-wrapper image-wrapper  h-100"
        key={`bundle-floating-${index}`}>
        <LazyLoadImage
          className={'bundle-image-wrap  mw-100 mh-100'}
          src={data?.imageURL || imagePlaceholder}
          alt="bundle items"
          onError={e => {
            if (e.target.src != imagePlaceholder) {
              e.target.onerror = null
              e.target.src = imagePlaceholder
            }
          }}
        />
        <div
          className={`text-center ${
            data.type === 'optional' ? 'visible' : 'invisible'
          }`}>
          <Button
            onClick={() => this.handleRemove(data.identifier)}
            variant="light"
            className="text-primary bg-transparent border-0 py-0">
            {i18nTranslate('bundleOptionalItem.remove', 'Remove', {
              nameSpace: 'ssr-resource',
            })}
          </Button>
        </div>
      </div>
    )
  }

  renderLeftContainer = () => {
    const { mandatoryProducts, optionalProducts } = this.getData()
    return (
      <div>
        <div className="pb-3">
          {this.renderTitleForMandatoryProducts(mandatoryProducts.length)}
        </div>
        <Row noGutters className="bundle-wrap">
          {this.renderCarousel(mandatoryProducts, 3)}
        </Row>
        {!isEmpty(optionalProducts) && (
          <>
            <Col md={12} className="p-0 d-flex">
              {this.renderAddIcon()}
              {this.renderTitleForOptionalProducts(optionalProducts.length)}
            </Col>
            <Row noGutters className="bundle-wrap">
              {this.renderCarousel(optionalProducts, 3)}
            </Row>
          </>
        )}
      </div>
    )
  }

  checkButtonEnable = product => {
    let selectedSku = productDeps.getBundleData(product)
    let enableATBBtn
    let enableSubscriptionBtn
    const skuList = selectedSku?.skus

    enableATBBtn = skuList?.every(item =>
      item?.availableChannels?.includes('web')
    )
    enableSubscriptionBtn = skuList?.every(item =>
      item?.availableChannels?.includes('subscription')
    )

    return { atbBtn: enableATBBtn, subscriptionBtn: enableSubscriptionBtn }
  }

  isDangerousGoods = () => {
    return (
      this.props.product?.dangerousGoods === 'true' ||
      this.props.product?.bundleMandatoryProducts?.filter(
        mandatoryProduct =>
          mandatoryProduct?.selectedSku?.properties?.dangerousGoods === 'true'
      )?.length > 0 ||
      productState.bundlePromotionResponse?.dangerousGoods === 'true' ||
      productState.bundlePromotionResponse?.bundleMandatoryProducts?.filter(
        mandatoryProduct =>
          mandatoryProduct?.properties?.dangerousGoods === 'true'
      )?.length > 0
    )
  }

  // @##pdp_button_disable_and_status_display_logic
  // subProductLevelBackOrderMessage is not used
  // we show productlevel message below addtocart button based on isBackOrderError
  getSkuEligibilities = (inventory = {}, inventoryStatus = '') => {
    const productCount =
      (this.props?.productCount || 1) * (inventory.quantityPerIncrement || 1)
    let atpQty = inventory?.atpQty || 0
    let outOfStockThreshold = inventory?.outOfStockThreshold || 0
    const productLevelStatus = this.props.product?.status || ''
    let backOrderedQty = inventory?.backOrderedQty
    const productLevelProductStatus = this.props?.product?.productStatus || ''
    if (inventory?.inventoryPropertiesUnavailable) {
      // this is a fallback
      // for existing environment,
      // sometimes we are not receiving inventory for bundle subproducts
      return { isEligible: true }
    }
    if (
      productLevelProductStatus == 'preview' ||
      (atpQty <= 0 && backOrderedQty <= 0) ||
      productLevelStatus === 'inactive' ||
      inventoryStatus?.toLowerCase() === 'out of stock' ||
      inventoryStatus?.toLowerCase() === 'unavailable'
    ) {
      return { isEligible: false }
    } else {
      if (productCount > atpQty - outOfStockThreshold) {
        let currentDateTimeStamp = Date.now()
        let backorderTime = ''
        if (
          inventory?.expectedBackOrderAvailabilityDate?.toString?.().length <=
          10
        ) {
          backorderTime = inventory?.expectedBackOrderAvailabilityDate + '000'
        } else {
          backorderTime = inventory?.expectedBackOrderAvailabilityDate
        }
        const requestedQtyNotAvailable = i18nTranslate(
          'pdp.OOSQtyTxt',
          'OUT OF STOCK',
          {
            nameSpace: 'ssr-resource',
          }
        )

        // subProductLevelBackOrderMessage is not used
        // we show productlevel message below addtocart button based on isBackOrderError

        // @##backorder_logic_pdp_message_or_button

        if (backOrderedQty > 0) {
          let expectedbackorder = productCount - (atpQty - outOfStockThreshold)
          if (
            backorderTime >= currentDateTimeStamp &&
            expectedbackorder <= backOrderedQty
          ) {
            let { date, month, year } = dateFormatDeps.convertTimeStampToDate(
              backorderTime,
              true
            )
            return {
              isEligible: true,
              shouldShowBackOrderDateMessage: true,
              backorderTime: backorderTime,
              subProductLevelBackOrderMessage:
                i18nTranslate('backordered', 'Ships by') +
                ' ' +
                `${month}/${date}/${year}`,
              // subProductLevelBackOrderMessage is not used
              // we show productlevel message below addtocart button based on isBackOrderError
            }
          } else {
            return {
              isEligible: false,
              isBackOrderError: true,
              // subProductLevelBackOrderMessage is not used
              // we show productlevel message below addtocart button based on isBackOrderError
              subProductLevelBackOrderMessage: requestedQtyNotAvailable,
            }
          }
        } else {
          return {
            isEligible: false,
            isBackOrderError: true,
            // subProductLevelBackOrderMessage is not used
            // we show productlevel message below addtocart button based on isBackOrderError
            subProductLevelBackOrderMessage: requestedQtyNotAvailable,
          }
        }
      } else {
        return { isEligible: true }
      }
    }
  }

  // @##pdp_button_disable_and_status_display_logic
  getInventoryStatus = () => {
    const { inventoryStatus, productCount, product } = this.props
    const { status = '', productStatus = '' } = this.props.product
    const customerTypeResult =
      modifyAndGetCustomerType(this.props?.product?.customerTypes) || ''
    let accoutTypeResult =
      modifyAndGetAccountType(
        customerContainer?.profileResponse?.accounts?.accountType
      ) || ''
    const isCustomerEligible = checkCustomerTypeEligibility(
      customerTypeResult,
      accoutTypeResult
    )
    let disableAddToCartBtn = false
    let disableSubscriptionButton = false
    let disableReason = ''
    let inventoryStatusView = ''
    const inventory = this.props.inventory
    const skusEligibilityDetails =
      inventory?.map((skuInventory, index) => {
        return this.getSkuEligibilities(skuInventory, inventoryStatus[index])
      }) || []

    const isAllSkusEligible = skusEligibilityDetails?.every(
      skuDetail => skuDetail?.isEligible === true
    )

    const checkInventoryAtpQty = inventory?.find(e => e?.atpQty <= 0)
    let flagInventory = ''

    const isOutOfStock = inventoryStatus?.find(
      status => status === 'OUT OF STOCK'
    )

    let isBackOrderError = false
    let shouldShowBackOrderDateMessage = false
    isBackOrderError = inventoryStatus?.find(status => status === 'BACKORDER')
    if (!isBackOrderError) {
      isBackOrderError = skusEligibilityDetails.some(
        skuDetail => skuDetail.isBackOrderError
      )
    }
    shouldShowBackOrderDateMessage = skusEligibilityDetails.some(
      skuDetail => skuDetail.shouldShowBackOrderDateMessage
    )
    if (!isAllSkusEligible) {
      disableAddToCartBtn = true
      flagInventory = true
    }
    if (isOutOfStock === 'OUT OF STOCK') {
      disableAddToCartBtn = true
      disableSubscriptionButton = !this.isUserSubscribedBefore
      disableReason = 'OUT OF STOCK'
      inventoryStatusView = i18nTranslate('cart.outOfStock', 'Out Of Stock')
      flagInventory = true
    }
    const dangerousGoods = this.isDangerousGoods()

    if (dangerousGoods) {
      disableAddToCartBtn = true
      disableSubscriptionButton = true
      disableReason = 'dangerousGoods'
      inventoryStatusView = i18nTranslate(
        'pdp.dangerousGoodsError',
        'Dangerous Goods cannot be added to Cart or Subscribed',
        {
          nameSpace: 'ssr-resource',
        }
      )

      flagInventory = true
    }

    const isReplacementItem = productDeps.getIsReplacementItem(product)
    if (isReplacementItem) {
      disableAddToCartBtn = true
      disableSubscriptionButton = true
      disableReason = 'replacement'
      inventoryStatusView = i18nTranslate(
        'bundle.replacement',
        'This is a replacement product and not available for purchase',
        {
          nameSpace: 'ssr-resource',
        }
      )

      flagInventory = true
    }

    if (
      status?.toLowerCase() === 'active' &&
      productStatus?.toLowerCase() === 'test'
    ) {
      inventoryStatusView = 'This is a test sku'
    }
    // as per discussion on 08/04/2024 with poovarsan and vinoth
    // for discontinued "productStatus", we should not check "status"
    if (productStatus?.toLowerCase() == 'discontinued') {
      disableAddToCartBtn = true
      disableSubscriptionButton = true
      disableReason = 'productStatus discontinued'
      flagInventory = true
      inventoryStatusView = i18nTranslate(
        'bundle.discontinuedV2Message',
        'Discontinued - This product or promotion has been discontinued',
        {
          nameSpace: 'ssr-resource',
        }
      )
    }

    if (inventoryStatusView == '') {
      if (status?.toLowerCase() === 'inactive') {
        disableAddToCartBtn = true
        disableSubscriptionButton = true
        disableReason = 'status inactive'
        const productStatusMsgs = {
          replacement: i18nTranslate(
            'bundle.replacement',
            'This is a replacement product and not available for purchase',
            {
              nameSpace: 'ssr-resource',
            }
          ),
          'preview product': i18nTranslate(
            'bundle.preview',
            'Preview product and will be launched soon',
            {
              nameSpace: 'ssr-resource',
            }
          ),
          stopped: i18nTranslate(
            'bundle.stopped',
            'This product is temporarily stopped',
            {
              nameSpace: 'ssr-resource',
            }
          ),
          sellable: i18nTranslate(
            'bundle.discontinued',
            'This product is no longer available for purchase',
            {
              nameSpace: 'ssr-resource',
            }
          ),
        }
        inventoryStatusView = productStatusMsgs[productStatus?.toLowerCase()]
        flagInventory = true
      } else if (!isCustomerEligible) {
        disableAddToCartBtn = true
        disableReason = 'not eligible to purchase'
        disableSubscriptionButton = !this.isUserSubscribedBefore
        inventoryStatusView = i18nTranslate(
          'pdp.userEligible',
          'This user is not eligible to purchase this product',
          {
            nameSpace: 'ssr-resource',
          }
        )
        flagInventory = true
      } else if (shouldShowBackOrderDateMessage) {
        // find the farthest backorder date among all the sub product which are backorder
        // example:
        //  Sub product A is backorder with date 10 Aug
        //  Sub product B is backorder with date 12 Aug
        // 12 is the farthest date, so show message below button as Backordered till {12 Aug}
        // set inventoryStatusView with the subProductLevelBackOrderMessage from the sub product which has the farthest backorder date
        const backOrderBasedInventories =
          skusEligibilityDetails.filter(
            skuDetail => skuDetail.shouldShowBackOrderDateMessage || false
          ) || []
        let farthestBackOrderDate = 0
        let farthestBackOrderMessage =
          backOrderBasedInventories[0]?.subProductLevelBackOrderMessage || ''

        backOrderBasedInventories.forEach(inventory => {
          if (
            inventory.backorderTime &&
            farthestBackOrderDate < inventory.backorderTime
          ) {
            farthestBackOrderDate = inventory.backorderTime
            farthestBackOrderMessage =
              inventory.subProductLevelBackOrderMessage || ''
          }
        })
        if (farthestBackOrderDate && farthestBackOrderMessage) {
          inventoryStatusView = farthestBackOrderMessage
        }
      } else if (isBackOrderError) {
        disableReason = 'not enough backorder'
        disableAddToCartBtn = true
        disableSubscriptionButton = !this.isUserSubscribedBefore
        flagInventory = true
        inventoryStatusView = i18nTranslate('pdp.OOSQtyTxt', 'OUT OF STOCK', {
          nameSpace: 'ssr-resource',
        })
      } else if (checkInventoryAtpQty) {
        disableAddToCartBtn = true
        disableSubscriptionButton = !this.isUserSubscribedBefore
        flagInventory = true
        disableReason = 'checkInventoryAtpQty atpQty less than 0'
        inventoryStatusView = i18nTranslate(
          'item.notAvailable',
          'Item currently not available'
        )
      }
    }

    return {
      disableAddToCartBtn,
      disableSubscriptionButton,
      disableReason,
      inventoryStatusView,
      flagInventory,
      isBackOrderError,
    }
  }

  enablePurchaseButtons = () => {
    let enableBtn = {}

    if (this.props?.product?.properties?.availableChannels) {
      const availableChannels =
        this.props.product?.properties?.availableChannels || ''
      const isEnableAddToCartBtn = checkEnableButton(availableChannels, 'web')
      const isEnableSubscriptionBtn = checkEnableButton(
        availableChannels,
        'subscription'
      )
      enableBtn = {
        atbBtn: isEnableAddToCartBtn,
        subscriptionBtn: isEnableSubscriptionBtn,
      }
    }
    return enableBtn
  }

  renderRightContainer = () => {
    // #BundlePriceLogic
    const bundleResponse = productState.bundlePromotionResponse?.totalValue
    const bundleDefaultPrices =
      productState.bundlePromotionResponse?.prices?.[0]?.price
    const bundleDefaultPriceFacets =
      productState.bundlePromotionResponse?.priceFacets || {}

    // let disableAddToCartBtn = false
    // let disableSubscriptionButton = false
    // let disableReason = ''
    const {
      //transactionPrice,
      handleQuantityChange,
      handleAddToBag,
      product,
      productCount,
      isBundleView = false,
    } = this.props
    const CVPriceOriginal =
      bundleResponse?.priceFacets?.CV?.CV || bundleDefaultPriceFacets?.CV || ''
    const CVDiscountPrice = bundleResponse?.priceFacets?.CV?.CVAfterDiscount
    const PVPriceOriginal =
      bundleResponse?.priceFacets?.PV?.PV || bundleDefaultPriceFacets?.PV || ''
    const PVDiscountPrice = bundleResponse?.priceFacets?.PV?.PVAfterDiscount

    const offerPricefromPromotion =
      bundleResponse?.priceAfterDiscount ||
      product?.totalValue?.priceAfterDiscount ||
      0

    const originalPricefromPromotion =
      bundleResponse?.originalPrice ||
      bundleDefaultPrices ||
      product?.totalValue?.originalPrice ||
      0

    const totalDiscount =
      bundleResponse?.totaldiscount ||
      bundleResponse?.discount ||
      product?.totalValue?.totaldiscount ||
      this.props?.totalValue?.discount ||
      0

    const priceFreeValue = originalPricefromPromotion - totalDiscount

    const priceFreePromotion =
      offerPricefromPromotion == 0 && priceFreeValue == 0 ? true : false

    const localvalue = getLocalStorage('accountType')
    const userRole = customerContainer?.profileResponse.userrole || ''

    const type = this.props.product?.type || ''
    const title =
      type && type?.toLowerCase() === 'kit'
        ? i18nTranslate('product.kittitle', 'Kit', {
            nameSpace: 'ssr-resource',
          })
        : i18nTranslate('product.bundletitle', 'Bundle', {
            nameSpace: 'ssr-resource',
          })

    const {
      inventoryStatusView,
      disableAddToCartBtn = false,
      disableSubscriptionButton = false,
      disableReason = '',
      flagInventory,
    } = this.getInventoryStatus()

    const enableBtn = this.enablePurchaseButtons()
    const userCountry = customerContainer.getUsersCountry()

    return (
      <Row noGutters>
        {/* @##pdp_price total price for kit */}
        <Col xs={12} className="d-flex justify-content-end my-1 pdpBundlePrice">
          <CustomPrice
            custompriceValue={originalPricefromPromotion}
            customDiscountPriceValue={offerPricefromPromotion}
            custompriceLabel={i18nTranslate('pdpbundle.price', 'Price', {
              nameSpace: 'ssr-resource',
            })}
            dataTestId="qa-pdpbundle-pvprice"
            classStyles={'price'}
            isCurrencySymbol={true}
            priceFreePromotion={priceFreePromotion}
            type={this.props.product?.type}
            isFromPDP={true}
          />
        </Col>
        {localvalue?.includes('Brand Affiliate') && userRole?.length > 0 && (
          <>
            <Col
              xs={12}
              className="d-flex justify-content-end my-1 pdpBundlePrice">
              <CustomPrice
                custompriceValue={PVPriceOriginal}
                customDiscountPriceValue={PVDiscountPrice}
                custompriceLabel={i18nTranslate('pdpbundle.svprice', 'SV', {
                  nameSpace: 'ssr-resource',
                })}
                dataTestId="qa-pdp-pvprice"
              />
            </Col>
          </>
        )}

        {(enableBtn.atbBtn === true || enableBtn.subscriptionBtn === true) && (
          <Col
            lg={12}
            className={`d-flex my-2 align-items-center justify-content-end`}>
            <h6 className="mr-2">
              {i18nTranslate('product.quantity', 'Quantity', {
                nameSpace: 'ssr-resource',
              })}
            </h6>
            <Quantity
              onQuantityChange={handleQuantityChange}
              defaultValue={productCount}
              handleQuantityChangePromotion={
                productState.makePromotionCallsForBundleKits
              }
              product={this.props.product}
            />
          </Col>
        )}
        {enableBtn.atbBtn && enableBtn.atbBtn === true && (
          <Col
            as={Button}
            onClick={handleAddToBag}
            disabled={disableAddToCartBtn}
            disableReason={disableReason}
            lg={{ offset: 0, span: 12 }}
            sm={{ offset: 0, span: 12 }}
            data-testid={`qa-add-${type?.toLowerCase()}-to-cart`}
            className="justify-content-end add-bundle-btn p-0">
            {i18nTranslate('bundle.add', 'Add', {
              nameSpace: 'ssr-resource',
            })}{' '}
            {title}{' '}
            {i18nTranslate('bundle.Cart', 'to Cart', {
              nameSpace: 'ssr-resource',
            })}
          </Col>
        )}
        {enableBtn.subscriptionBtn && enableBtn.subscriptionBtn === true && (
          <Col
            lg={{ offset: 0, span: 12 }}
            sm={{ offset: 0, span: 12 }}
            className="bundle-Subscription p-0">
            <NuskinSubscriptionButton
              product={this.props.product}
              title={title}
              disableSubscriptionButton={disableSubscriptionButton}
              isBundle
              {...this.props}
            />
          </Col>
        )}
        {/* Klarna Placement for Bundle and Kit */}
        {enableBtn?.atbBtn && (
          <div className="klarna-bundle-total-price-wrapper">
            <KlarnaMessage
              price={offerPricefromPromotion || 0}
              id={'klarna-pdp-message-bundle-total-price'}
              data-key={'credit-promotion-badge'}
            />
          </div>
        )}
        {/* Paypal paylater message for Bundle and Kit */}
        {isPaylaterMessageRequired(userCountry) &&
          offerPricefromPromotion &&
          offerPricefromPromotion > 0 && (
            <div className={'paylater-bundle-total-price-wrapper'}>
              <PayLaterMessage
                amount={offerPricefromPromotion?.toString()}
                key={'paylater-bundle-total-price-wrapper'}
                id={'paylater-bundle-total-price-wrapper'}
              />
            </div>
          )}
        {this.renderInventoryStatus({ flagInventory, inventoryStatusView })}
      </Row>
    )
  }

  renderInventoryStatus = inventoryProps => {
    const { flagInventory, inventoryStatusView } = inventoryProps || {}
    if (inventoryStatusView) {
      return (
        <p
          className={`${
            !flagInventory
              ? 'availability-text mt-2 text-success font-weight-bold p-0'
              : 'availability-text mt-2 font-weight-bold text-bundle-danger  p-0'
          }`}>
          {inventoryStatusView}
        </p>
      )
    }
    return ''
  }

  renderYourBundle = () => {
    return (
      <Modal
        className="bundle-modal d-flex align-items-center p-0"
        dialogClassName="w-100 mw-100 mh-100 m-0"
        show={this.isToShowBundle}
        onHide={this.handleClick}
        key="bundle-modal">
        <Row
          noGutters
          className="flex-column p-3 h-100 overflow-auto flex-md-row">
          <Col xs={12} noGutters className="d-flex justify-content-end">
            <Button
              variant="light"
              className="bg-transparent border-0 p-0"
              onClick={this.handleClick}>
              <CloseIcon size="2rem" />
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <div className="floating-bar-mobile-wrap">
              {this.renderLeftContainer()}
            </div>
          </Col>
          <Col xs={12} md={6} className="flex-column quantity-wrapper">
            {this.renderRightContainer()}
          </Col>
        </Row>
      </Modal>
    )
  }

  renderCarousel = (product, desktopColSpan) => {
    return (
      <div className="floating-bar-image-wrap kitProduct mt-2 col-12">
        <ReactSlick
          arrows={true}
          infinite={false}
          dots={false}
          speed={500}
          slidesToScroll={desktopColSpan}
          slidesToShow={desktopColSpan}>
          {isArray(product) && product.map(this.renderFixedOrOptionalBundle)}
        </ReactSlick>
      </div>
    )
  }

  renderTitleForOptionalProducts = count => {
    return (
      <div>
        <span className="font-weight-bold pr-1 my-auto">
          {i18nTranslate('bundle.floating-add-on-title', 'Added Items', {
            nameSpace: 'ssr-resource',
          })}
        </span>
        <span className="my-auto">
          ({`${count}`}
          {i18nTranslate('product.itemsText', 'items', {
            nameSpace: 'ssr-resource',
          })}
          )
        </span>
      </div>
    )
  }

  renderTitleForMandatoryProducts = count => {
    const type = this.props.product?.type || ''
    const title =
      type && type.toLowerCase() === 'kit'
        ? i18nTranslate('product.kittitle', 'Kit', {
            nameSpace: 'ssr-resource',
          })
        : i18nTranslate('product.bundletitle', 'Bundle', {
            nameSpace: 'ssr-resource',
          })
    return (
      <div>
        <span className="font-weight-bold pr-1">
          {title}
          {i18nTranslate('bundle.floating-bundle-title', ' Items', {
            nameSpace: 'ssr-resource',
          })}
        </span>
        <span>
          ({`${count}`}
          {i18nTranslate('product.itemsText', ' items', {
            nameSpace: 'ssr-resource',
          })}
          )
        </span>
      </div>
    )
  }
  renderAddIcon = () => {
    return (
      <div className="my-auto bundle-optional-plus-icon h-auto mx-3">
        <AddIcon className="w-100 h-auto" />
      </div>
    )
  }
  renderMobileOrTabView = () => {
    const enableBtn = this.enablePurchaseButtons()

    const {
      inventoryStatusView,
      disableAddToCartBtn = false,
      disableSubscriptionButton = false,
      disableReason = '',
      flagInventory,
      isBackOrderError = false,
    } = this.getInventoryStatus()

    return (
      <Row noGutters className="flex-column">
        {enableBtn.atbBtn == true && (
          /* since disabling is handled based on qty selection and thresholds,
            it is handled inside pop-up shown while clicking "your bundle" button */
          <Button
            onClick={this.handleClick}
            className="your-bundle-btn"
            // disabled={disableAddToCartBtn}>
          >
            {i18nTranslate('bundle.bundlePopUpTitle', 'Your Bundle', {
              nameSpace: 'ssr-resource',
            })}
          </Button>
        )}
        {enableBtn.subscriptionBtn && enableBtn.subscriptionBtn == true && (
          <Col
            lg={{ offset: 0, span: 12 }}
            sm={{ offset: 0, span: 12 }}
            className="bundle-Subscription p-0">
            {/* since disabling is handled based on qty selection and thresholds,
            it is handled inside pop-up shown while clicking "your bundle" button */}

            <NuskinSubscriptionButton
              // disableSubscriptionButton={disableSubscriptionButton}
              product={this.props.product}
              isBundle
              {...this.props}
            />
          </Col>
        )}
        {/* for mobile view backorder error will be handled inside your bundle pop-up
         * since quantity incrementor is inside "your bundle" pop-up
         * other generic errors are displayed here
         */}
        {!isBackOrderError &&
          this.renderInventoryStatus({ flagInventory, inventoryStatusView })}
      </Row>
    )
  }
  renderDesktopFloatingBar = () => {
    const { optionalProducts, mandatoryProducts } = this.getData()
    return (
      <Row noGutters className="justify-content-between">
        <Col lg={9} className="d-flex my-auto">
          {!isEmpty(optionalProducts) ? (
            <>
              <Col className="floating-bar-image-wrap p-0 mr-3" lg={5}>
                {this.renderTitleForMandatoryProducts(mandatoryProducts.length)}
                {this.renderCarousel(mandatoryProducts, 3)}
              </Col>
              {this.renderAddIcon()}
              <Col className="floating-bar-image-wrap p-0 ml-3" lg={5}>
                {this.renderTitleForOptionalProducts(optionalProducts.length)}
                {this.renderCarousel(optionalProducts, 3)}
              </Col>
            </>
          ) : (
            <div className="floating-bar-image-wrap p-0 mr-3 col-lg-10">
              {this.renderTitleForMandatoryProducts(mandatoryProducts.length)}
              {this.renderCarousel(mandatoryProducts, 6)}
            </div>
          )}
        </Col>
        <Col lg={3} className="mt-md-0 mt-3 d-flex flex-column pl-3 ">
          {this.renderRightContainer()}
        </Col>
      </Row>
    )
  }
  render() {
    const isToRenderPopUp = application.isMobile || application.isTablet
    const shouldUseFixedElement = shouldShowAddedBundleDetails
      ? !application.isDesktop
      : true
    const className =
      shouldUseFixedElement && this.fixedBar ? 'sticky-floating-bar' : ''

    this.switchBetween()
    return (
      <>
        <div
          className={`${className} floating-bar-container bg-light border-top border-secondary w-100 py-3`}>
          {shouldShowAddedBundleDetails ? (
            <Container>
              {isToRenderPopUp
                ? !this.isToShowBundle && this.renderMobileOrTabView()
                : this.renderDesktopFloatingBar()}
            </Container>
          ) : (
            <Button
              variant="primary"
              className="px-4 d-block ml-auto"
              onClick={this.props.handleAddToBag}>
              {i18nTranslate('bundle.checkout', 'Continue to Checkout', {
                nameSpace: 'ssr-resource',
              })}
            </Button>
          )}
        </div>
        {isToRenderPopUp && this.isToShowBundle && this.renderYourBundle()}
      </>
    )
  }
}

export { BundleFloatingBar }
export default BundleFloatingBar
