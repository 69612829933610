import React from 'react'
import { tryJSONParse } from 'src/utils/commonUtils'
import './styles.scss'

class ProductDetailDesc extends React.Component {
  constructor() {
    super()
    this.state = {
      additionalAttributes: [
        'features',
        'productDetailsDescription',
        'includedItems',
        // 'highlights',
        // 'warnings',
        'benefits',
        'results',
        'usage',
        'resources',
        'ingredients',
        'disclaimers',
        'disclaimerWarnings',
        // 'seoInformation',
        // 'generalDisclaimer',
        'refund',
        'shipping',
      ],
    }
  }

  getFeatureSection = attributeDesc => {
    let values = tryJSONParse(attributeDesc, {})
    const { subtitle, features } = values ? values[0] : []

    return (
      <div>
        {features && (
          <>
            <p>{subtitle}</p>
            <ul>
              {features.map((feature, idx) => (
                <li key={`feature-${idx}`}>{feature}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    )
  }

  getProductDetailsDescription = attributeDesc => {
    return <p>{attributeDesc}</p>
  }

  getBenefitsSection = attributeDesc => {
    let benefits = tryJSONParse(attributeDesc, [])

    return (
      <div>
        {benefits && (
          <ul>
            {benefits[0]?.benefits?.map((item, idx) => (
              <li key={`benefits-${idx}`}>{item}</li>
            ))}
          </ul>
        )}
      </div>
    )
  }

  getResultDetails = attributeDesc => {
    let values = tryJSONParse(attributeDesc, {})
    const { summary, results, report } = values

    return (
      <div>
        <p>{summary}</p>
        {results && (
          <ul>
            {results.map((key, value) => (
              <li key={`res-${key}`}>
                {key} : {value}
              </li>
            ))}
          </ul>
        )}
        <a href={report.url}>{report.text}</a>
      </div>
    )
  }

  getUsageSection = attributeDesc => {
    let values = tryJSONParse(attributeDesc, {})
    const { video, additionalText, recommendations, steps, warnings } = values

    return (
      <div>
        {recommendations && (
          <ul>
            {recommendations.map((recom, idx) => (
              <li key={`recom-${idx}`}>{recom}</li>
            ))}
          </ul>
        )}
        {additionalText && (
          <ul>
            {additionalText.map((aText, idx) => (
              <li key={`aText-${idx}`}>{aText}</li>
            ))}
          </ul>
        )}
        {steps && (
          <ul>
            {steps.map((step, idx) => (
              <li key={`aText-${idx}`}>{step}</li>
            ))}
          </ul>
        )}
        {warnings && (
          <ul>
            {warnings.map((warning, idx) => (
              <li key={`warn-${idx}`}>{warning}</li>
            ))}
          </ul>
        )}
        {video.url && (
          <div content-type="videos" className="ns-product-detail-info">
            <h3 className="ns-product-detail-info__title" />{' '}
            <div className="ns-product-detail-info__body">
              <video
                className="aem-video"
                src={video.url}
                type="video"
                controls="true"
                width="400"
                height="300"
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  getResources = attributeDesc => {
    let resources = tryJSONParse(attributeDesc, [])

    return (
      <div>
        {resources &&
          resources.map((resource, idx) => (
            <p key={`reso-${idx}`}>
              <a href={resource.url}>
                {resource.title}
                <br></br>
              </a>
            </p>
          ))}
      </div>
    )
  }

  getIngredients = attributeDesc => {
    let values = tryJSONParse(attributeDesc, {})

    const {
      activeIngredients,
      inactiveIngredients,
      allIngredients,
      keyIngredients,
      nutritionFacts,
      otherIngredients,
    } = values ? values[0] : {}

    return (
      <div>
        {activeIngredients && (
          <>
            <h6>Active Ingredients : </h6>
            <p>{activeIngredients}</p>
          </>
        )}
        {inactiveIngredients && (
          <>
            <h6>Inactive Ingredients : </h6>
            <p>{inactiveIngredients}</p>
          </>
        )}
        {allIngredients && (
          <>
            <h6>All Ingredients : </h6>
            <p>{allIngredients}</p>
          </>
        )}
        {keyIngredients && (
          <>
            <h6>Key Ingredients :</h6>
            <ul>
              {keyIngredients.map((kIngredient, idx) => (
                <li key={`kIngredient-${idx}`}>
                  {kIngredient.name} : {kIngredient.description}
                </li>
              ))}
            </ul>
          </>
        )}
        {otherIngredients && (
          <>
            <h6>Other Ingredients : </h6>
            <p>{otherIngredients}</p>
          </>
        )}
        {nutritionFacts && (
          <>
            <h6>Other Ingredients : </h6>
            <p>{nutritionFacts}</p>
          </>
        )}
      </div>
    )
  }

  getDisclaimers = attributeDesc => {
    return attributeDesc
  }

  getDisclaimerWarn = attributeDesc => {
    return ''
  }

  getSeoInformation = attributeDesc => {
    return tryJSONParse(attributeDesc, {})?.metaDescription || ''
  }

  getRefundDetails = attributeDescription => {
    let values = tryJSONParse(attributeDescription, {})

    let { linkText, linkUrl, message } = values
    message = message.replace('{linkText}', '')

    return (
      <div>
        <p>
          {message} <a href={linkUrl}>{linkText}</a>
        </p>
      </div>
    )
  }

  getShippingDetails = attributeDesc => {
    let values = tryJSONParse(attributeDesc, {})
    let { message, boldText } = values
    message = message.replace('{boldText}', '')

    return (
      <div>
        <p>
          <span className="shipping-text">{boldText}</span>
          {message}
        </p>
      </div>
    )
  }

  getDetailedDesc = (attributeTitle, attributeDesc) => {
    switch (attributeTitle) {
      case 'features':
        return this.getFeatureSection(attributeDesc)
      case 'productDetailsDescription':
        return this.getProductDetailsDescription(attributeDesc)
      case 'includedItems':
        return '' // includedItems is not in API Response. if it is available, bind it here.
      case 'benefits':
        return this.getBenefitsSection(attributeDesc)
      case 'results':
        return this.getResultDetails(attributeDesc)
      case 'usage':
        return this.getUsageSection(attributeDesc)
      case 'resources':
        return this.getResources(attributeDesc)
      case 'ingredients':
        return this.getIngredients(attributeDesc)
      case 'disclaimers':
        return this.getDisclaimers(attributeDesc)
      case 'disclaimerWarnings':
        return this.getDisclaimerWarn(attributeDesc)
      case 'seoInformation':
        return this.getSeoInformation(attributeDesc)
      case 'generalDisclaimer':
        return this.getDisclaimerWarn('')
      case 'refund':
        return this.getRefundDetails(attributeDesc)
      case 'shipping':
        return this.getShippingDetails(attributeDesc)
      default:
        return ''
    }
  }

  renderDesc = () => {
    return this.state?.additionalAttributes?.map((key, index) => {
      if (this.props?.product[key] && this.props?.product[key] !== '') {
        return (
          <div className="product-detail-info-inner ">
            <h2>{key}</h2>
            {this.getDetailedDesc(key, this.props.product[key])}
          </div>
        )
      }
      return <></>
    })
  }

  render() {
    return (
      <div className="product-detail-info">
        <div className="product-detail-info-attributes">
          {this.renderDesc()}
        </div>
      </div>
    )
  }
}

export { ProductDetailDesc }
export default ProductDetailDesc
