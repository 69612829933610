import React, { useState, useEffect } from 'react'
import { Modal, ModalTitle, Button } from 'react-bootstrap'
import { IoIosClose as InformationCloseIcon } from 'react-icons/io'
import { cartContainer } from 'src/models'
import { toastState, overlayState } from 'src/views/components'
import { ModalCloseIcon } from 'src/views/components/Icons/ModalCloseIcon'
import { i18nTranslate } from 'src/utils'
import { getScanQualifiedCountSumForBundleSubItems } from 'src/deps'
import { MdDelete as DeleteIcon } from 'react-icons/md'
import './styles.scss'

const CartScanCard = (props = {}) => {
  const [state, setState] = useState({
    cardNumber: '',
    cardName: '',
    cardList: [],
  })

  const [errorMsg, setErrorMsg] = useState({
    cardNumber: '',
    cardName: '',
  })

  const scanQualifiedCount = () => {
    const {
      page = '',
      quantity = 0,
      isBundle = false,
      isPopupForMovingCartProductsToDashboard = false,
      scanCardProductDetails = {},
      bundleInfo = {},
      bundle = false,
      bundleScanCount = '',
      productInfo = {},
      data = {},
    } = props || {}
    let count = 0
    if (page === 'cart') {
      if (isBundle) {
        let scanCardCount = 0
        if (isPopupForMovingCartProductsToDashboard) {
          const type =
            scanCardProductDetails?.bundleProducts?.products?.[0]
              ?.transactionPrice?.type
          if (type === 'MANDATORY' || type === 'OPTIONAL') {
            // count =
            //   props?.scanCardProductDetails?.bundleProducts?.products?.[0]
            //     ?.transactionPrice?.skuQuantity *
            //   props?.scanCardProductDetails?.bundleProducts?.products?.[0]
            //     ?.transactionPrice?.skuProperties?.scanQualifiedCount *
            //   quantity
            count = bundleInfo?.isBundle ? bundleInfo?.bundleScanCount : 0
          }
        } else {
          scanCardProductDetails?.[0]?.skus?.forEach(item => {
            if (item?.type === 'MANDATORY' || item?.type === 'OPTIONAL') {
              let totalQuantity =
                item?.skuQuantity * item?.skuProperties?.scanQualifiedCount
              count = count + totalQuantity
            }
          })
          count = count * quantity
        }
      } else {
        let scanQualified =
          scanCardProductDetails?.transactionPrice?.skuProperties
            ?.scanQualifiedCount || 0

        count = quantity * scanQualified
      }
    }
    if (page === 'subscription') {
      let quantity = data?.itemInfo?.[0]?.quantity || 0

      let scanQualified =
        (bundle ? bundleScanCount : productInfo?.scanQualifiedCount) || 0
      count = quantity * scanQualified
    }

    return count
  }

  useEffect(() => {
    const fetchData = async () => {
      if (props.page === 'subscription') {
        const response = (await cartContainer.scanCardList()) || []
        let subscriptionId = props.data?.id
        let scanSkuId = props?.bundle
          ? props?.productInfo?.id
          : props?.data?.itemInfo?.[0]?.skus?.[0]?.id

        /* Filtering only current subscription and sku which was used to add scan card
         * - Normal sku/bundle main sku(header level)
         * - Component level skus will be skipped here as per CX15-7931 and EQH-231
         */
        let data = response.filter(
          card =>
            card?.subscriptionId === subscriptionId &&
            card?.properties?.scanCardSkuId === scanSkuId
        )
        setState(prevState => ({
          ...prevState,
          cardList: data,
        }))
      }

      if (props.page === 'cart') {
        let cartItems = cartContainer.cartResponse?.items || {}
        let item = cartItems?.filter(
          itemFilter => itemFilter?.itemId == props.cartItemId
        )
        let properties = item?.[0]?.properties || {}
        try {
          let scanArr = properties.scanCardDetails
            ? JSON.parse(properties.scanCardDetails)
            : []
          setState(prevState => ({
            ...prevState,
            cardList: scanArr,
          }))
        } catch (e) {
          console.log(e)
        }
      }
      cartContainer.updateScanvisibilityCount(scanQualifiedCount())
    }
    fetchData().catch(console.error)
  }, [])

  const handleChangeName = e => {
    const { name, value } = e.target
    const validation = (scValue = '') => {
      const regexName = /^[a-zA-Z][a-zA-Z ]*$/
      if (scValue != '') {
        if (!regexName.test(scValue)) {
          setErrorMsg(prevState => ({
            ...prevState,
            cardName: i18nTranslate(
              'scanerror.alphabets',
              'Only alphabets allowed'
            ),
          }))
          return false
        } else {
          if (scValue.length > 50) {
            setErrorMsg(prevState => ({
              ...prevState,
              cardName: i18nTranslate(
                'scanerror.characters50',
                'Only 50 characters allowed'
              ),
            }))
            return false
          } else {
            setErrorMsg(prevState => ({
              ...prevState,
              cardName: '',
            }))
            return true
          }
        }
      } else {
        setErrorMsg(prevState => ({
          ...prevState,
          cardName: '',
        }))
        return true
      }
    }
    if (validation(value)) {
      setState(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleChangeNumber = e => {
    const { name, value } = e.target
    const validation = (scValue = '') => {
      const regexNumber = /^[0-9]+$/
      if (scValue != '') {
        if (!regexNumber.test(scValue)) {
          setErrorMsg(prevState => ({
            ...prevState,
            cardNumber: i18nTranslate(
              'scanerror.numbers',
              'Only numbers allowed'
            ),
          }))
          return false
        } else {
          if (scValue.length > 50) {
            setErrorMsg(prevState => ({
              ...prevState,
              cardNumber: i18nTranslate(
                'scanerror.characters50',
                'Only 50 characters allowed'
              ),
            }))
            return false
          } else {
            setErrorMsg(prevState => ({
              ...prevState,
              cardNumber: '',
            }))
            return true
          }
        }
      } else {
        setErrorMsg(prevState => ({
          ...prevState,
          cardNumber: '',
        }))
        return true
      }
    }
    if (validation(value)) {
      setState(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const cartApiPatch = (method = '', scanCardDetails = {}) => {
    let cartItems = cartContainer.cartResponse?.items || {}
    let items = cartItems.filter(item => item?.itemId == props.cartItemId) || []
    let properties = items?.[0]?.properties || {}
    try {
      let scanArr = properties
        ? properties?.scanCardDetails
          ? JSON.parse(properties?.scanCardDetails)
          : []
        : []

      if (method === 'add') {
        //duplicate check
        let scanCards = cartItems.map(item =>
          item?.properties?.scanCardDetails
            ? JSON.parse(item?.properties?.scanCardDetails)
            : []
        )
        let duplicate = scanCards?.map(card => {
          return card.some(item => item?.scancardNumber == state.cardNumber)
        })

        if (duplicate.includes(true)) {
          toastState.setToastMessage(
            i18nTranslate('scan.duplicate', 'Duplicate scan card entry'),
            false
          )
        } else {
          //Add
          let details = {
            scancardName: state.cardName,
            scancardNumber: state.cardNumber,
            scanCardSkuId: items?.[0]?.skus?.[0]?.skuId,
          }
          scanArr.push(details)
          cartContainer.updateCart({
            id: props.cartItemId,
            properties: {
              scanCardDetails: JSON.stringify(scanArr),
            },
          })
        }
      }

      if (method === 'delete') {
        let removeIndex
        scanArr.forEach(item => {
          if (item.scancardNumber === scanCardDetails.scancardNumber) {
            removeIndex = scanArr.indexOf(item)
          }
        })
        scanArr.splice(removeIndex, 1)
        cartContainer.updateCart({
          id: props.cartItemId,
          properties: {
            scanCardDetails: JSON.stringify(scanArr),
          },
        })
      }

      setState(prevState => ({
        ...prevState,
        cardList: scanArr,
      }))
    } catch (e) {
      console.log(e)
    }
  }

  const handleClick = async () => {
    if (state.cardName != '' && state.cardNumber != '') {
      props.page === 'subscription' && overlayState.toggleLoader()
      let response = await cartContainer.scanCardApply(state)
      if (response?.valid) {
        //Manage scan card from Cart
        {
          if (props.page === 'cart') {
            toastState.setToastMessage(
              i18nTranslate('scan.success', 'Your scan card has been updated.'),
              true
            )
            cartApiPatch('add')
          }
        }

        //Manage scan card from Subscription Dashboard
        try {
          if (props.page === 'subscription') {
            let scanSkuId = props.bundle
              ? props?.productInfo?.id
              : props?.data?.itemInfo?.[0]?.skus?.[0]?.id
            let scanDetails = {
              accountId: props.data?.accountId,
              subscriptionId: JSON.parse(props.data?.id),
              scancardName: state.cardName,
              scancardNumber: state.cardNumber,
              properties: {
                scanCardSkuId: scanSkuId,
              },
            }
            let createScanCardResponse = await cartContainer.createScanCard(
              scanDetails
            )
            if (cartContainer.isSuccessResponse(createScanCardResponse)) {
              toastState.setToastMessage(
                i18nTranslate(
                  'scan.success',
                  'Your scan card has been updated.'
                ),
                true
              )
            } else {
              const errorCode =
                createScanCardResponse?.responseCode ||
                createScanCardResponse?.code ||
                ''
              const editFreezeErrorCode =
                errorCode === 'error.scancard.editfreeze.date.reached'
              if (editFreezeErrorCode) {
                toastState.setToastMessage(
                  i18nTranslate(
                    'subscription.editFreezeErrorMessage',
                    'This subscription order is currently being processed and at this time no changes will be allowed. Please return after your subscription order has shipped to make any changes to your next order.'
                  )
                )
              }
            }
            const response = (await cartContainer.scanCardList()) || []
            let subscriptionId = props.data?.id || ''
            let data = response.filter(
              card =>
                card?.subscriptionId === subscriptionId &&
                card?.properties?.scanCardSkuId === scanSkuId
            )
            setState(prevState => ({
              ...prevState,
              cardList: data,
            }))
          }
        } catch (e) {
          console.error('JSON.parse error at CartScanCard.handleClick >>> ', e)
        }

        setState(prevState => ({
          ...prevState,
          cardNumber: '',
          cardName: '',
        }))
      } else {
        if (response?.message) {
          toastState.setToastMessage(response.message)
        } else {
          if (response?.responseMessage) {
            toastState.setToastMessage(response?.responseMessage)
          } else {
            toastState.setToastMessage(
              i18nTranslate('scan.invalid', 'Invalid scan card.'),
              false
            )
          }
        }
      }
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'scan.input',
          'Sorry, we are unable to verify your scan card. Please type in manually.'
        ),
        false
      )
      if (state.cardName == '') {
        setErrorMsg(prevState => ({
          ...prevState,
          cardName: i18nTranslate(
            'scanerror.input',
            'Please enter a valid input'
          ),
        }))
      }
      if (state.cardNumber == '') {
        setErrorMsg(prevState => ({
          ...prevState,
          cardNumber: i18nTranslate(
            'scanerror.input',
            'Please enter a valid input'
          ),
        }))
      }
      return false
    }
    props.page === 'subscription' && overlayState.toggleLoader()
  }

  const clearScanCard = () => {
    setState(prevState => ({
      ...prevState,
      cardNumber: '',
      cardName: '',
    }))
  }

  const handleRemove = async cardDetails => {
    //Manage scan card from Cart
    if (props.page === 'cart') {
      cartApiPatch('delete', cardDetails)
      toastState.setToastMessage(
        i18nTranslate('scan.remove', 'Scan card removed successfully'),
        true
      )
    }

    //Manage scan card from Subscription Dashboard
    if (props.page === 'subscription') {
      overlayState.toggleLoader()
      const removeScanCardResponse = await cartContainer.removeScanCard(
        cardDetails?.id
      )
      const response = (await cartContainer.scanCardList()) || []
      let subscriptionId = props.data?.id || ''

      let scanSkuId = props?.bundle
        ? props?.productInfo?.id
        : props?.data?.itemInfo?.[0]?.skus?.[0]?.id

      let data = response.filter(
        card =>
          card?.subscriptionId === subscriptionId &&
          card?.properties?.scanCardSkuId === scanSkuId
      )
      setState(prevState => ({
        ...prevState,
        cardList: data,
      }))
      if (cartContainer.isSuccessResponse(removeScanCardResponse)) {
        toastState.setToastMessage(
          i18nTranslate('scan.remove', 'Scan card removed successfully'),
          true
        )
      } else {
        const errorCode =
          removeScanCardResponse?.responseCode ||
          removeScanCardResponse?.code ||
          ''
        const editFreezeErrorCode =
          errorCode === 'error.scancard.editfreeze.date.reached'
        if (editFreezeErrorCode) {
          toastState.setToastMessage(
            i18nTranslate(
              'subscription.editFreezeErrorMessage',
              'This subscription order is currently being processed and at this time no changes will be allowed. Please return after your subscription order has shipped to make any changes to your next order.'
            )
          )
        }
      }
      overlayState.toggleLoader()
    }
  }

  const renderScanCard = () => {
    const displayCount = scanQualifiedCount()
    cartContainer.updateScanvisibilityCount(
      displayCount - (state.cardList?.length || 0)
    )
    const scancard =
      cartContainer.scanCardVisibilityCount > 0 &&
      displayCount >= cartContainer.scanCardVisibilityCount ? (
        <div className="d-inline-block w-100">
          <div className="card-number-scan">
            <label for="scanCardNumber">
              {i18nTranslate('scan.number', 'Scan Card Number')}
            </label>
            <input
              type="text"
              className="scanCardField form-control"
              name="cardNumber"
              value={state.cardNumber}
              data-testid="qa-scan-card-number"
              id="scanCardNumber"
              onChange={handleChangeNumber}
            />
            <p className="feedback-msg">{errorMsg.cardNumber}</p>
          </div>

          <div className="card-name-scan">
            <label for="scanCardName">
              {i18nTranslate('scan.name', 'Scan Card Name')}
            </label>
            <input
              type="text"
              className="scanCardField form-control"
              name="cardName"
              data-testid="qa-scan-card-name"
              value={state.cardName}
              onChange={handleChangeName}
              id="scanCardName"
            />
            <p className="feedback-msg">{errorMsg.cardName}</p>
          </div>

          <div className="apply-clear-btn">
            <Button
              className="clear-scancard-inputs btn-outline-primary"
              data-testid="qa-scan-card-clear"
              onClick={clearScanCard}>
              {i18nTranslate('scanCard.clear', 'Clear')}
            </Button>
            <Button
              type="primary"
              className="apply-scancard"
              data-testid="qa-scan-card-apply"
              onClick={handleClick}>
              {i18nTranslate('addnewscancard.submit', 'Apply')}
            </Button>
          </div>
        </div>
      ) : (
        <p>
          {i18nTranslate(
            'subscription.scanCardLimit',
            'The product is currently limited to'
          )}{' '}
          {displayCount}{' '}
          {i18nTranslate('subscription.scanCards', 'scan card(s)')}
        </p>
      )
    return scancard
  }

  const renderRedeemedList = () => {
    let scanArr = state.cardList || []
    return (
      scanArr &&
      scanArr.length > 0 && (
        <div
          className={`d-inline-block w-100 ${
            !props?.isFlexibleSubscription ? 'mt-4' : ''
          }`}>
          <h5>
            {i18nTranslate('subscription.redeemedCard', 'Redeemed Scan Cards')}
          </h5>
          <div className="mt-4">
            {scanArr.map(item => (
              <div
                className={`${
                  props?.isFlexibleSubscription ? 'mb-2' : 'mt-2'
                } d-flex flex-wrap flex-md-nowrap align-items-end"`}>
                <input
                  className="scan-card-input mr-2 "
                  type="number"
                  value={item?.scancardNumber}
                  disabled></input>
                <input
                  className="scan-card-input mt-2 mt-md-0"
                  type="text"
                  value={item?.scancardName}
                  disabled></input>
                <div className="btn-outline-primary btn border-0 p-0 ">
                  <DeleteIcon
                    className=" ml-2 w-24 scan-card-remove-btn"
                    onClick={() => {
                      handleRemove(item)
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    )
  }

  const renderMaxCardsMsg = () => {
    return (
      <p className="max-scan-msg pt-2 mb-0">
        {i18nTranslate(
          'scanCard.maximumMessage',
          'Maximum scan card(s) allowed'
        )}
        : {scanQualifiedCount()}
      </p>
    )
  }

  const closeSCInfoModal = (props = {}) => {
    props?.toggleQuickView()
  }
  const className = props?.isFlexibleSubscription
    ? 'flexible-scancard-details'
    : 'quick-view-dialog'

  const modalHeader = props?.isFlexibleSubscription ? (
    <Modal.Header className="border-0">
      <ModalTitle className="add-scancard-title">
        {i18nTranslate('addnewscancard.title', 'Add New Scan Card')}
        <ModalCloseIcon
          customClass="scancard-close"
          onClose={() => closeSCInfoModal(props)}
        />
      </ModalTitle>
    </Modal.Header>
  ) : (
    <Modal.Header className="border-0" closeButton>
      <ModalTitle className="font-weight-bold signin-title">
        {i18nTranslate('addnewscancard.title', 'Add New Scan Card')}
      </ModalTitle>
    </Modal.Header>
  )
  return (
    <div className="scan-card-pop-up">
      <Modal
        centered={true}
        show={props.isScanModalOpen}
        dialogClassName={className}
        onHide={props.toggleQuickView}>
        {modalHeader}
        <Modal.Body>
          {renderScanCard()}
          {renderRedeemedList()}
          {renderMaxCardsMsg()}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export { CartScanCard }

export default CartScanCard
