import React from 'react'

class JsonLd extends React.PureComponent {
  toInnerHTML = html => {
    return { __html: html }
  }
  toStringifiedInnerHTML = props => {
    const stringified = JSON.stringify(props.data)
    const html = this.toInnerHTML(stringified)
    return html
  }
  render() {
    const html = this.toStringifiedInnerHTML(this.props)
    return <script type="application/ld+json" dangerouslySetInnerHTML={html} />
  }
}

export default JsonLd
export { JsonLd }
