import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { withRouter } from 'react-router'
import { wishListContainer, customerContainer } from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import { CreateListForm } from './CreateListForm'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import './styles.scss'

@observer
class MoveToListDropDown extends React.Component {
  static defaultProps = {
    isFromListPage: false,
  }

  handleMoveToList = async (listName, listId) => {
    const {
      listId: oldListId,
      listItemId,
      ...remainingProps
    } = this.props.listItemProps
    const product = this.props?.product || {}

    if (this.props.isFromListPage) {
      await wishListContainer.moveToAnotherList({
        listItemProps: remainingProps,
        addListProps: { listName, listId },
        deleteListProps: { listItemId, listId: oldListId },
      })
      this.props.toggleAccordion()
    } else {
      wishListContainer.addItemToWishList({
        listName,
        listId,
        ...remainingProps,
        ...product,
      })
    }
  }

  handleCreateList = async (listName, properties = {}) => {
    if (!isEmpty(listName)) {
      const { listId, listItemId, ...remainingProps } = this.props.listItemProps
      const { isFromListPage } = this.props
      const {
        productId,
        quantity = 1,
        categoryId = '',
        slug = '',
        type = '',
        name = '',
        skuId = '',
      } = properties
      const listProps =
        listId || listItemId
          ? remainingProps
          : {
              productId,
              quantity,
              categoryId,
              type,
              skuId,
              slug,
              name,
            }
      const props = {
        name: listName,
        listItemProps: {
          ...listProps,
        },
        listPageProps: { listId, listItemId, isFromListPage },
        properties: {
          productId,
          quantity,
          categoryId,
          type,
          skuId,
          name,
        },
      }
      await wishListContainer.createWishList(props)
      if (isFromListPage) {
        this.props.toggleAccordion()
      }
    }
  }

  renderDropDownHeader = () => {
    const { location } = this.props
    if (location.pathname.includes(pageNames.myaccountlists)) {
      return i18nTranslate('wishList.move', 'Move')
    }
    return i18nTranslate('wishList.addToList', 'Add to List')
  }

  render() {
    const {
      location,
      history,
      listName,
      isFromListPage,
      className = '',
      disabled = false,
    } = this.props
    let addToListDisable = disabled
    const isExclusive =
      this.props?.product?.sku?.[0]?.properties?.isExclusive || ''
    const productId = this.props?.product?.productId
    const { isItemEligible } = productState.getQualificationMessage({
      productId,
      isExclusive,
    })
    if (customerContainer?.qualificationStoreResponse === true) {
      if (isExclusive == 'true') {
        addToListDisable = !isItemEligible
      }
    }
    return (
      <Dropdown
        disabled={addToListDisable}
        data-testid="qa-dropdown"
        className={`list-dropdown flex-grow-1 bg-white border-0 p-0 ${className}`}>
        <Dropdown.Toggle
          disabled={addToListDisable}
          variant=""
          className="btn-addToList w-100 d-flex justify-content-between align-items-center border-black">
          {this.renderDropDownHeader()}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100 listname-dropdown">
          {wishListContainer.wishLists.map(list => {
            if (isFromListPage && list.name === listName) {
              return
            }
            return (
              <Dropdown.Item
                key={list.name}
                as="button"
                className="name-of-list"
                onClick={event =>
                  this.handleMoveToList(event.target.textContent, list.listId)
                }>
                {list.name}
              </Dropdown.Item>
            )
          })}
          {wishListContainer.wishLists.length > 0 && (
            <Dropdown.Divider className="m-0" />
          )}
          <Dropdown.Item
            className="create-list"
            as="button"
            onKeyUp={event => {
              if (event.key === ' ') event.preventDefault()
            }}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}>
            <CreateListForm
              {...this.props}
              history={history}
              location={location}
              moveToList={this.handleCreateList}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default withRouter(MoveToListDropDown)
