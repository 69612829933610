import React from 'react'
import { observer } from 'mobx-react'
import { overlayState } from './state'
import { Loader } from '../Loader'
import './styles.scss'

@observer
class Overlay extends React.Component {
  render() {
    const { textToShow, position = 'absolute' } = this.props

    return (
      <>
        {overlayState.isLoading && (
          <>
            <div className={`w-100 h-100 position-${position} modal-overlay`} />
            <div className="position-fixed overlay-loader d-flex flex-column align-items-center">
              <Loader />
              <div className="pt-3 text-white font-weight-bold">
                {textToShow}
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}

export { Overlay }
export default Overlay
