import React from 'react'
import { observable } from 'mobx'
// import get from 'lodash/get'
import { Container } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import i18n from 'i18next'
import isMerchPLP, {
  isMerchandising,
  useStickyTop,
  isStudioPreview,
  getAppConfig,
  setLocalStorage,
  getLocalStorage,
  isB2BAccount,
  IS_BROWSER,
  isB2B2C,
  APPConfig,
} from 'config/appConfig'
import { ApiContent, scrollState } from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import {
  sessionContainer,
  catalogContainer,
  customerContainer,
} from 'src/models'

class CommonHeader extends React.Component {
  nameSpace = 'ssr-resource'
  @observable categoryResponse = {}
  header = ''

  static contextType = ApiContent
  constructor(props, context) {
    super(props)
    this.context = context
    this.contextName = 'categoryData'
    this.csContextName = 'csTopNavData'
    if (!isB2BAccount() || isB2B2C()) {
      this.getDataFromSource()
    }
    if (IS_BROWSER) {
      document.dir = i18n.dir()
    }
  }

  getDataFromSource = async () => {
    const categoryData = this.context?.data?.[this.contextName] || {}

    if (categoryData && categoryData.category) {
      const getCSTopNav =
        APPConfig.getAppConfig()?.enableImpactSectionFromCS == 'true'

      if (getCSTopNav && !IS_BROWSER) {
        const csTopNav = this.context?.data?.[this.csContextName] || {}
        const transformedResponse =
          catalogContainer.transformCategoryResponse(csTopNav)
        categoryData.category?.subCategories.push(transformedResponse)
      }
      this.categoryResponse = categoryData
      catalogContainer.categoryResponse = categoryData
    } else {
      /**
       * @todo
       * !!! temp fix for to make a top category visible
       * !!! need to find the right place for getProfile call before top call
       */
      /**
       * @note
       * Removing Customer call to prevent Multiple Call Triggering
       */
      // await customerContainer.getProfile()
      await this.getCategoryResponse()
    }
  }

  componentDidUpdate() {
    if (
      isB2BAccount() &&
      customerContainer.isRegisterUser &&
      !this.categoryResponse.category
    ) {
      /**
       * @note
       * Removing TopCategory call to prevent Multiple Call Triggering
       */
      // this.getDataFromSource()
    }
  }

  calculateHeaderSpace = () => {
    const header = this.header
    const height = header.offsetHeight
    scrollState.headerHeight = height
    if (useStickyTop) {
      document.getElementById('app-root').style.paddingTop = `${height}px`
    }
  }

  componentDidMount() {
    // console.log({
    //   isStudioPreview,
    //   isMerchPLP,
    //   isMerchandising,
    //   isB2BAccount: isB2BAccount(),
    //   isB2B2C: isB2B2C(),
    // })
    if (
      (isStudioPreview && isB2BAccount()) ||
      (!isStudioPreview && !isMerchPLP && !isMerchandising)
    ) {
      this.initializeHeaderApi()
    }

    this.calculateHeaderSpace()
  }

  getCategoryResponse = async () => {
    const { defaultLocale } = getAppConfig()

    this.categoryResponse = await catalogContainer.getCategory({
      context: this.context,
      contextName: this.contextName,
      csContextName: this.csContextName,
      queryParams: {
        locale: defaultLocale,
      },
    })

    catalogContainer.categoryResponse = this.categoryResponse
  }

  initializeHeaderApi = async () => {
    const token = this.props?.location?.queryParams?.token || ''
    const csrAdminName = getLocalStorage('csrAdminName') || ''

    if (token) {
      const userId = this.props?.location?.queryParams?.uid || ''
      const storeId = this.props?.location?.queryParams?.storeid || ''
      setLocalStorage('dynamicStoreId', storeId)
      await sessionContainer.loginAsCSR({ token, userId, storeId })
      this.props.history.push(pageNames.home)
    }

    if (csrAdminName) {
      await sessionContainer.init()
    } else {
      await sessionContainer.init()

      // console.log({
      //   isSessionExpired: customerContainer.isSessionExpired,
      //   sessionAvailable: customerContainer.isSessionAvailable,
      // })

      if (customerContainer.isSessionExpired === true) {
        this.checkAndRedirectPage()
      }
    }
  }

  checkAndRedirectPage = () => {
    const { pathname, search } = this.props.location
    const { myaccount } = pageNames

    const isRegisterUser = customerContainer.isRegisterUser
    const isSignInPage = pathname === pageNames.signIn
    const authorizedPagePrefix = myaccount.split('/')[1]

    const commonPage =
      pathname === pageNames.configs ||
      pathname === pageNames.status ||
      pathname === pageNames.resetPassword ||
      pathname === pageNames.userActivation

    const isUserAuthorizedPage = pathname.includes(`/${authorizedPagePrefix}/`)
    const isGuestB2B = customerContainer.isGuestB2B
      ? isB2B2C()
        ? false
        : true
      : false

    if (isRegisterUser && isSignInPage) {
      this.props.history.push(`${pageNames.home}`)
    }
    if (!isRegisterUser && isUserAuthorizedPage) {
      this.props.history.push(
        `${pageNames.signIn}?targetURL=${pathname}${search}`
      )
    }
    if (isGuestB2B && !commonPage) {
      this.props.history.push(`${pageNames.signIn}`)
    }
  }

  renderAdminTopBar = () => {
    // const csrAdminName = get(sessionContainer, 'csrAdminName', '')
    const csrAdminName = getLocalStorage('csrAdminName') || ''
    return (
      !csrAdminName.includes('undefined undefined') &&
      csrAdminName !== undefined &&
      csrAdminName !== '' && (
        <section className="header-user-bar w-100 py-2 px-3 px-lg-0">
          <Container className="px-0 logged-as-user text-right text-white">
            {i18nTranslate('header.loggedAs', 'Logged in as', {
              nameSpace: this.nameSpace,
            })}
            <span className="font-weight-bold ml-1">{csrAdminName}</span>
          </Container>
        </section>
      )
    )
  }
}

export default CommonHeader
export { CommonHeader }
