import { CommonContainer } from 'src/models/Common'
import { transformStudioPage, transformPreviewPage } from './transform'
import { observable } from 'mobx'
import { localeList } from 'src/App/localeList'
import { IS_BROWSER, convertToBoolean, getLiveEventStatus } from 'src/utils'
import { storeContainer } from 'src/models/Store'
// import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
// import Stack from './entry'
// import { APPConfig } from 'config/appConfig'

/**
 * Creates an object mapping locale values to studio page IDs using
 * localeList(). Uses reduce() to build up the localeObject, mapping
 * the locale value to the locale's studioPageId if defined, or the
 * index + 1.
 */
const studioPageIds = localeList().reduce((localeObject, locale, index) => {
  return { [locale.value]: locale.studioPageId || index + 1, ...localeObject }
}, {})

/**
 * StudioContainer class, extends CommonContainer.
 * Used to contain studio data and related functionality.
 */
class StudioContainer extends CommonContainer {
  @observable studioData = {}
  // @observable homePageData = {}
  @observable enableLiveEvent = getLiveEventStatus()

  constructor(props) {
    super(props)
  }

  // getContentStackResponse = async props => {
  //   const countyLang =
  //     APPConfig.getActiveAppConfig()
  //       ?.defaultLocale?.split('_')
  //       .reverse()
  //       .join('-') || 'US-en'
  //   const response = await Stack.getEntry('homepage', countyLang)

  //   return response && response?.[0] && response?.[0]?.sections
  //     ? response?.[0]?.sections
  //     : []
  //   // const [{ sections = [] }] = response || [{ sections: [] }]
  //   // return sections || []
  // }

  // getStudioPage = async props => {
  //   // const locale = getLocalStorage('locale')
  //   const locale = getLocaleCodeFromUrl({
  //     defaultLocale: 'en_US',
  //     isReverseType: true,
  //   })
  //   const pageId =
  //     props.pageId !== undefined ? props.pageId : studioPageIds[locale] || 1
  //   const loadParams = {
  //     endPointName: 'getStudioPage',
  //     pathParams: `${pageId}/${application.deviceType}`,
  //     queryParams: {
  //       skcrossdomain: true,
  //     },
  //     ignoreCommonHeader: true,
  //     contextName: props.contextName,
  //     curLocaleFromUrl: props.context?.curLocaleFromUrl,
  //   }
  //   let response = {}
  //   if (!IS_BROWSER && props.context && !props.context.data) {
  //     props.context.load(this.fetchResponse(loadParams))
  //     return
  //   } else if (IS_BROWSER) {
  //     response = await this.fetchResponse(loadParams)
  //   }
  //   if (this.isSuccessResponse(response)) {
  //     this.studioData = transformStudioPage(response)
  //     this.homePageData = response.page.facets
  //     return this.studioData
  //   } else return {}
  // }

  /**
   * Fetches studio data asynchronously.
   * @param {Object} props - The component props.
   * @returns {Promise} A promise that resolves to the studio data response.
   */
  getStudioResponse = async props => {
    const enableLiveEvent = convertToBoolean(this.enableLiveEvent)
    const loadParams = {
      endPointName: enableLiveEvent ? 'liveEventStudioData' : 'studioData',
      contextName: props.contextName,
      curLocaleFromUrl: props.context?.curLocaleFromUrl,
    }
    let response = {}
    if (!IS_BROWSER) {
      props.context.load(this.fetchResponse(loadParams))
      return
    } else if (IS_BROWSER) {
      response = await this.fetchResponse(loadParams)
    }
    if (this.isSuccessResponse(response)) {
      this.studioData = response
    }
    return response
  }
}

const studioContainer = new StudioContainer()

export { studioContainer, transformStudioPage, transformPreviewPage }
export default studioContainer
