import isEmpty from 'lodash/isEmpty'
import { PLPProductsPerPage } from 'config/appConfig'
import { ProductBuilder } from 'src/models'

/**
 * MerchBuilder class. Used to build merch product models.
 */
class MerchBuilder {
  /**
   * Gets the value of the given property from the array of properties.
   *
   * @param {Object[]} propertyArray - The array of property objects
   * @param {string} property - The name or ID of the property to get
   * @param {string} [defaultValue=''] - The default value if property not found
   * @returns {string} The value of the property, or defaultValue if not found
   */
  getPropertyValue = (propertyArray, property, defaultValue = '') => {
    const found = propertyArray?.find(
      item => item.attribute_id === property || item.name === property
    )
    return found && found.value ? found.value : ''
  }
  /**
   * Transforms the price properties from a product into a simplified price object.
   *
   * @param {Object[]} properties - The array of property objects for the product
   * @returns {Object} The transformed price object
   */
  transformPrices = properties => {
    return {
      price: parseFloat(this.getPropertyValue(properties, 'price', 0.0)),
      regularPrice: parseFloat(
        this.getPropertyValue(properties, 'regularPrice', 0.0)
      ),
    }
  }
  /**
   * Transforms a SKU object into a simplified SKU object.
   *
   * @param {Object} sku - The SKU object to transform
   * @returns {Object} The transformed SKU object
   */
  transfromSkus = sku => {
    const { properties } = sku
    return {
      // default: true,
      identifier: sku?.identifier || sku?.skuId,
      prices: [this.transformPrices(properties)],
      promotion: [],
      properties: this.transformProperties(properties),
    }
  }
  /**
   * Transforms the array of product property objects into a simplified properties object.
   * Maps the attribute ID or name to the property value for each property.
   *
   * @param {Object[]} properties - The array of property objects
   * @returns {Object} The transformed properties object
   */
  transformProperties = properties => {
    return properties.reduce(function (value, currentValue) {
      return {
        ...value,
        [currentValue.attribute_id ||
        currentValue.attributeId ||
        currentValue.name]: currentValue.value,
      }
    }, {})
  }

  /**
   * Transforms a product object into a simplified product object.
   * Maps the product ID to the identifier property.
   * Transforms the SKUs, properties, prices into simplified objects.
   *
   * @param {Object} product - The product object to transform
   * @param {number} index - The index of the product
   * @returns {Object} The transformed product object
   */
  transformProduct = (product, index) => {
    // for collection and bundle and skukit
    const transformedSkus =
      product.skus && Array.isArray(product.skus)
        ? product.skus.map(this.transfromSkus)
        : []
    const { properties } = product
    const transformedProperties = this.transformProperties(properties)
    return {
      identifier: product?.productId,
      properties: transformedProperties,
      sku: transformedSkus,
      type: transformedProperties.producttype || 'default',
      prices: this.transformPrices(properties),
    }
  }

  /**
   * Transforms the facet filter data from the API response into a simplified array of facet objects.
   * Maps the facet positions to the facet filter objects and adds visibility.
   * Returns only visible facets in their positioned order.
   */
  transformFilter = (uxData, facetFilter) => {
    const facetPositions = uxData?.facetPositions || []
    const facetFilterObj = {}
    const facetFilterArray = []

    facetFilter?.map(item => {
      facetFilterObj[item.name.toLowerCase()] = item
    })

    facetPositions?.map(item => {
      if (!isEmpty(item)) {
        const facetData = facetFilterObj[item.facetId.toLowerCase()]
        if (!isEmpty(facetData)) {
          facetData.isVisible = item.visible
          facetFilterArray.push(facetData)
          delete facetFilterObj[item.facetId.toLowerCase()]
        }
      }
    })

    Object.keys(facetFilterObj).forEach(key =>
      facetFilterArray.push(facetFilterObj[key])
    )

    return facetFilterArray
  }

  /**
   * Transforms the API response into the normalized product response shape needed by the UI.
   * - Maps the product positions to the products
   * - Transforms the products into the simplified UI format
   * - Transforms the facets into a simplified array
   * - Returns the transformed products, facets and pageable info
   */
  transformResponse = response => {
    const { products, facets, uxData = {}, pageableInfo = {} } = response
    const positions = uxData?.positions || []

    const productPositions = ProductBuilder.transformProductPositions({
      productPositions: positions,
      pageableInfo,
      product: products.slice(0, PLPProductsPerPage),
    })
    const productResponse = {
      product: productPositions.map(this.transformProduct),
      facetFilter: this.transformFilter(uxData, facets),
      pageableInfo,
    }
    return productResponse
  }
}
const merchBuilder = new MerchBuilder()

export default merchBuilder
export { merchBuilder }
