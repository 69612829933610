import React, { Component } from 'react'
import { i18nTranslate, trackSignUpEvents } from 'src/utils'
import { Button, Modal } from 'react-bootstrap'
import { checkboxValidationCheck } from 'src/utils/signUpUtils'
import { accountsContainer } from 'src/models'
import './styles.scss'

class IronCladContractPage3 extends Component {
  constructor(props) {
    super(props)
  }
  validationChecks = () => {
    if (
      accountsContainer?.accountsContractCheck === false &&
      this.props?.isFromAccounts
    ) {
      checkboxValidationCheck('add')
      return false
    } else {
      trackSignUpEvents({
        eventType: this.props?.isFromModal
          ? 'eqDistInviteComplete'
          : 'eqDistColdComplete',
      })
      this.props?.handleProceed()
    }
  }
  render() {
    const { isFromSignup = false, isFromTerms = false } = this.props
    const buttonRenderedText = isFromSignup
      ? i18nTranslate('signup.submit', 'Create My Account')
      : i18nTranslate('button.acceptContinue', 'Accept and Continue')
    const isCancelText = i18nTranslate('ironclad.nevermind', 'Return to Home')
    return (
      <Modal
        show={this.props?.isToShowStep3}
        onHide={() => this.props?.handleIronCladContractClose()}
        backdrop="static"
        className="ps-contracts">
        <Modal.Header closeButton>Page 3 of 3</Modal.Header>
        <Modal.Title className="p-3">
          {i18nTranslate('ironClad.letsfinishup', "Let's finish up!")}
        </Modal.Title>
        <Modal.Body>
          <div className="modal-scroll">
            <h3>
              {i18nTranslate('ironClad.legalAgreement', 'LEGAL AGREEMENT')}
            </h3>
            <p>
              {i18nTranslate(
                'ironClad.baPolicies',
                'The Brand Affiliate Agreement and the Policies and Procedures constitute a'
              )}{' '}
              {i18nTranslate(
                'ironClad.legallybinding',
                'legally binding agreement'
              )}{' '}
              {i18nTranslate(
                'ironClad.betweenYou',
                'between you and Nu Skin that sets forth your obligations and rights as an independent Nu Skin Brand Affiliate.'
              )}
            </p>
            <p>
              {i18nTranslate(
                'ironClad.pleaseBeAdivsed',
                'Please be advised that the Brand Affiliate Agreement contains limitations on Nu Skin’s liability'
              )}{' '}
              <u>
                {' '}
                {i18nTranslate(
                  'ironClad.pleaseBeAdivsedtwo',
                  '(see Section F(6)(e))'
                )}
              </u>{' '}
              {i18nTranslate(
                'ironClad.pleaseBeAdivsedthree',
                ', as well as an arbitration agreement'
              )}{' '}
              <u>
                {' '}
                {i18nTranslate(
                  'ironClad.pleaseBeAdivsedFour',
                  '(see Section E).'
                )}
              </u>{' '}
              {i18nTranslate(
                'ironClad.pleaseBeAdivsedFive',
                'The arbitration agreement requires you to submit any claims you have against Nu Skin to binding and final arbitration on an individual basis and not as a plaintiff or class member in any class, group, or representative action or proceeding.'
              )}
            </p>
            <p>
              {i18nTranslate(
                'ironClad.boundByThem',
                'YOU SHOULD CAREFULLY REVIEW THE BRAND AFFILIATE AGREEMENT AND THE POLICIES AND PROCEDURES BY CLICKING ON THE LINKS BELOW BEFORE AGREEING TO THEM.'
              )}
            </p>
            <p>
              {i18nTranslate(
                'ironClad.homeButton',
                'If you do not agree, please click on the “Return to Home” button, and you will be returned to the Nu Skin home page.'
              )}
            </p>
          </div>
          <div
            id="clickwrap-container-3"
            className="click-wrap-container"></div>
          <div className="clickwrapContainerError">
            {' '}
            {i18nTranslate(
              'ironClad.termsAndConditions',
              'Please accept the terms & conditions*'
            )}
          </div>
          <Button
            data-testid="qa-createaccount-createbutton"
            className="mt-3 create-account-btn"
            variant="primary"
            type="submit"
            disabled={this.props.disableButton}
            onClick={() => {
              this.validationChecks()
            }}
            block>
            {buttonRenderedText}
          </Button>
          <Modal.Footer className="ironclad-popup">
            <Button
              variant="primary"
              className="mt-3 btn-outline-primary"
              onClick={() =>
                isFromTerms
                  ? this.props?.handleIronCladContractClose()
                  : this.props?.history?.push('/')
              }
              block>
              {isCancelText}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    )
  }
}

export default IronCladContractPage3
export { IronCladContractPage3 }
