import React from 'react'
import { Col, InputGroup, Row } from 'react-bootstrap'
import { IoIosCloseCircle } from 'react-icons/io'
import { i18nTranslate } from 'src/utils'

const PhoneNotVerified = props => {
  const className = props.isInvalid ? 'is-invalid' : ''
  return (
    <InputGroup className="phoneNotVerified alert-danger">
      <Row className="dTextInvalidEmail flex-column">
        <Col className="phoneNotVerifiedClass">
          <IoIosCloseCircle className="checkNotVal" />
          <div
            class=" invalidPhonenumberText"
            data-testid="qa-phone-not-verified">
            {i18nTranslate(
              'PhoneNotVerified',
              'Phone is not verified. Use the button below to verify.'
            )}
          </div>
        </Col>
      </Row>
    </InputGroup>
  )
}

export { PhoneNotVerified }
