import * as yup from 'yup'
import {
  customerContainer,
  storeContainer,
  accountsContainer,
} from 'src/models'
import {
  emailErrorMsg,
  firstNameErrorMsg,
  lastNameErrorMsg,
  addressUserNameErrorMsg,
  addressErrorMsg,
  emailDomainErrorMsg,
  phoneNumberErrorMsg,
} from './addressValidationErrorMsg'
import {
  emailValidation,
  getInputFormJSON,
  getLiveEventStatus,
  getLocaleCodeFromUrl,
  isLATAM,
} from 'src/utils'
import { APPConfig, convertToBoolean } from 'config/appConfig'

const invalidCharacterRegex =
  /^(?=.*[a-zA-Z0-9.!@#&*\-\u0080-\u052F])[a-zA-Z0-9\s.!@#&*'/(),\-\u0080-\u052F]*$/

function getEmailSchema(props) {
  const emailSchema = {}

  const {
    requiredErrorText = emailErrorMsg,
    invalidErrorText = emailErrorMsg,
    invalidDomainErrorText = emailDomainErrorMsg,
    inputName = inputName || 'email',
  } = props || {}

  emailSchema[inputName] = yup
    .string()
    .email(invalidErrorText)
    .max(50, invalidErrorText)
    .required(requiredErrorText)
    .test('emailValidation', invalidErrorText, function (value) {
      if (!emailValidation(value)) {
        return this.createError({ message: invalidErrorText })
      } else if (value) {
        return validateEmailDomain(
          value,
          this.createError({ message: invalidDomainErrorText })
        )
      }
      return false
    })
  return emailSchema
}

function validateEmailDomain(email, createError) {
  return new Promise(async (resolve, reject) => {
    let response = 'false'
    if (email) {
      const postData = {
        email,
      }
      response = await customerContainer?.validateEmailMxLookup({ postData })
    }
    resolve(
      !response?.isEmailValid && !response?.mxRecordExist ? createError : true
    )
  })
}

function getNameSchema(props = {}) {
  const nameSchema = {}
  const isLastName = props?.isLastName || false
  const isAddressUserName = props?.isAddressUserName || false
  const errorMessage = isAddressUserName
    ? addressUserNameErrorMsg
    : isLastName
    ? lastNameErrorMsg
    : firstNameErrorMsg
  const {
    requiredErrorText = errorMessage,
    invalidErrorText = errorMessage,
    inputName = 'firstName',
  } = props

  const alphabetsAndSpacesRegEx =
    /^[a-zA-ZÀ-ú-ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ'\s]*$/
  //Adding Above Regex for vietnamese characters

  /*
  Adding Japanese characters to the regex for Live Event
  \u3040-\u309F - Hiragana
  \u30A0-\u30FF - Katakana
  \u4E00-\u9FFF - CJK
  */
  const enableLiveEvent = convertToBoolean(getLiveEventStatus())
  const eventNameRegex =
    /^[a-zA-ZÀ-ú-ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ'\s\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]*$/
  const locale = APPConfig?.getActiveAppConfig()?.defaultLocale //en_US
  const commonConfigJSON = getInputFormJSON(locale) || {}
  const isLatamRegion = isLATAM(locale)
  if (enableLiveEvent) {
    nameSchema[inputName] = yup
      .string()
      .min(1, invalidErrorText)
      .max(35, invalidErrorText)
      .required(requiredErrorText)
  } else if (isLatamRegion) {
    nameSchema[inputName] = yup
      .string()
      .matches(commonConfigJSON?.name?.regEx || invalidErrorText)
      .min(1, invalidErrorText)
      .max(40, invalidErrorText)
      .required(requiredErrorText)
  } else {
    nameSchema[inputName] = yup
      .string()
      .matches(alphabetsAndSpacesRegEx, invalidErrorText)
      .min(1, invalidErrorText)
      .max(35, invalidErrorText)
      .required(requiredErrorText)
  }

  return nameSchema
}

function getAddressLine1Schema(props) {
  const addressLine1Schema = {}

  const {
    requiredErrorText = addressErrorMsg,
    invalidErrorText = addressErrorMsg,
    inputName = inputName || 'addressLine1',
  } = props || {}

  const enableLiveEvent = convertToBoolean(getLiveEventStatus())

  // No regex validation for Live event
  if (enableLiveEvent) {
    addressLine1Schema[inputName] = yup.string().required(requiredErrorText)
  } else {
    addressLine1Schema[inputName] = yup
      .string()
      .matches(invalidCharacterRegex, invalidErrorText)
      .required(requiredErrorText)
  }
  return addressLine1Schema
}

function getAddressLine2Schema(props) {
  const addressLine2Schema = {}

  const {
    invalidErrorText = addressErrorMsg,
    inputName = inputName || 'addressLine2',
  } = props || {}

  const enableLiveEvent = convertToBoolean(getLiveEventStatus())

  if (enableLiveEvent) {
    addressLine2Schema[inputName] = yup.string()
  } else {
    addressLine2Schema[inputName] = yup
      .string()
      .matches(invalidCharacterRegex, invalidErrorText)
  }
  return addressLine2Schema
}

function getOptionalPhoneNumberSchema(props) {
  const phoneSchema = {}

  const {
    invalidErrorText = phoneNumberErrorMsg,
    inputName = inputName || 'phoneNumber',
    min = min || 9,
    max = max || 15,
  } = props || {}

  const phoneRegExp = /^[+0-9-() ]*$/

  phoneSchema[inputName] = yup
    .string()
    .matches(phoneRegExp, invalidErrorText)
    .test('phoneNumberValid', invalidErrorText, function (number) {
      const enableLiveEvent = convertToBoolean(getLiveEventStatus())
      if (enableLiveEvent) {
        if (number?.length) {
          return number?.length > 9 && number?.length <= 15
        } else {
          // Return true if number is undefined (Optional case)
          return true
        }
      }
      return !(accountsContainer?.phoneNumberIsValid === false)
    })

  return phoneSchema
}

const isToHaveOptionalPhoneSchema = type => {
  const selectedMarket = storeContainer.storeIDValue || 'us'
  const phoneNumberOptionalCountries =
    APPConfig?.getAppConfig()?.phoneNumberOptionalCountries
  const enablePhoneNumberOptionalForCheckoutAndSubscription =
    APPConfig?.getAppConfig()
      ?.enablePhoneNumberOptionalForCheckoutAndSubscription === 'true'
  const enablePhoneOptionalForCheckout =
    APPConfig?.getAppConfig()?.enablePhoneOptionalForCheckout === 'true'
  const enableOptionalForSubscription =
    APPConfig?.getAppConfig()?.enableOptionalForSubscription === 'true'
  const isPhoneNumberOptional =
    phoneNumberOptionalCountries?.includes(selectedMarket?.toLowerCase()) &&
    enablePhoneNumberOptionalForCheckoutAndSubscription

  if (type === 'checkout') {
    return isPhoneNumberOptional && enablePhoneOptionalForCheckout
  }

  if (type === 'subscription')
    return isPhoneNumberOptional && enableOptionalForSubscription

  return false
}

const enableLiveEvent = () => {
  return convertToBoolean(getLiveEventStatus())
}

export {
  getEmailSchema,
  getNameSchema,
  getAddressLine1Schema,
  getAddressLine2Schema,
  getOptionalPhoneNumberSchema,
  isToHaveOptionalPhoneSchema,
  enableLiveEvent,
}
