import React from 'react'
import { i18nTranslate, convertTime } from 'src/utils'

/**
 * Renders the pickup location details by destructuring the response object
 * and calling helper components to render the address, notes, and hours.
 *
 * @param {Object} pickUpLocationResponse - Response from the pickup location API
 * @returns {JSX.Element} - JSX displaying the pickup location details
 */
const showLocationDetails = (pickUpLocationResponse = {}) => {
  const { address = {}, hours = {}, properties = [] } = pickUpLocationResponse
  return (
    <div className="pickup-address-wrapper pl-4 mb-1">
      {renderPickUpAddress(address)}
      {renderNoteDetails(properties)}
      {renderHourDetails(hours)}
    </div>
  )
}

/**
 * Renders the pickup address details by destructuring the address object
 * and returning a JSX element with the address parts.
 *
 * @param {Object} address - The address object
 * @returns {JSX.Element} - JSX div with address details
 */
const renderPickUpAddress = address => {
  const {
    address1 = '',
    address2 = '',
    address3 = '',
    city = '',
    state = '',
    country = '',
    postalCode = '',
  } = address
  return (
    <div className="mt-2 pickup-address-section">
      {address1 && <p className="m-0 font-weight-bold">{address1}</p>}
      {address2 && <p className="m-0">{address2}</p>}
      {address3 && <p className="m-0">{address3}</p>}
      {city && <span className="m-0">{city},</span>}
      {state && <span className="m-0 pl-1">{state}</span>}
      {country && <p className="m-0">{country}</p>}
      {postalCode && <p className="m-0">{postalCode}</p>}
    </div>
  )
}

/**
 * Renders any note details from the properties array.
 * Filters for properties with name 'Please note'
 * and maps over them to display the name and value.
 *
 * @param {Array} properties - The array of property objects
 * @returns {JSX.Element} - JSX displaying any 'Please note' properties
 */
const renderNoteDetails = (properties = []) => {
  let property = properties?.filter(prop => prop.name === 'Please note')
  return (
    property &&
    Object.keys(property).length !== 0 &&
    property.map((notes, key) => {
      const { name = '', value = '' } = notes
      return (
        <div className="mt-2 pickup-notes-section" key={`notes-${key}`}>
          {name && <span className="m-0 font-weight-bold pr-1">{name}:</span>}
          {value && <span className="m-0">{value}</span>}
        </div>
      )
    })
  )
}

/**
 * Renders the hours of operation details from the hours object.
 * Maps over the hours array and renders the standard hours for each day.
 * Displays the open and closed times for each day.
 */
const renderHourDetails = (hours = {}) => {
  return (
    hours &&
    Object.keys(hours).length !== 0 &&
    hours.map((hour, key) => {
      let { standardHours = {} } = hour
      return (
        <div className="mt-2 pickup-hour-section" key={`hour-${key}`}>
          <p className="m-0 hours-title font-weight-bold">Hours of Operation</p>
          {standardHours &&
            Object.keys(standardHours).map(day => {
              const dayValue = standardHours[day]
              return (
                dayValue &&
                dayValue.map((time, idx) => {
                  const { startTime = '', endTime = '', type = '' } = time
                  return type === 'open' ? (
                    <div key={`time-${idx}`}>
                      {day && (
                        <span className="pr-1 text-capitalize">{day}:</span>
                      )}
                      {startTime && (
                        <span className="pr-1">{convertTime(startTime)}</span>
                      )}
                      -
                      {endTime && (
                        <span className="pl-1">{convertTime(endTime)}</span>
                      )}
                    </div>
                  ) : type === 'closed' ? (
                    //CX16-9671 - Hours of Operation will be displayed on hoilday also
                    <div key={`time-${idx}`}>
                      {day && (
                        <span className="pr-1 text-capitalize">
                          {day}: {type}
                        </span>
                      )}
                    </div>
                  ) : (
                    ''
                  )
                })
              )
            })}
        </div>
      )
    })
  )
}

/**
 * Retrieves the translated shipping method message based on the provided shipping method ID.
 *
 * @param {string} [id=''] - The shipping method ID.
 * @returns {string} The translated shipping method message.
 */
const getTranslatedShippingMethod = (id = '', isExpressCheckout = false) => {
  let shippingMethodMessage = ''
  let shippingMethodForExpressCheckout = []
  const shippingId = id?.toLowerCase() || ''
  switch (shippingId) {
    case 'standard':
      shippingMethodMessage = i18nTranslate(
        'shipping.standard',
        'Standard (7-10 Business Day)'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.standard.key', 'Standard'),
        i18nTranslate('shipping.standard.value', '7-10 Business Day'),
      ]
      break
    case 'usmail':
      shippingMethodMessage = i18nTranslate('shipping.usmail', 'US Mail')
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.usmail.key', 'US Mail'),
        i18nTranslate('shipping.usmail.value', ''),
      ]
      break
    case 'express':
      shippingMethodMessage = i18nTranslate(
        'shipping.express',
        'Express (1-3 Business Day)'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.express.key', 'Express'),
        i18nTranslate('shipping.express.value', '1-3 Business Day'),
      ]
      break
    case 'oneday':
      shippingMethodMessage = i18nTranslate('shipping.oneday', 'One Day')
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.oneday.key', 'One Day'),
        i18nTranslate('shipping.oneday.value', ''),
      ]
      break
    case 'onedaysaturday':
      shippingMethodMessage = i18nTranslate(
        'shipping.onedaysaturday',
        'One Day Saturday'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.onedaysaturday.key', 'One Day Saturday'),
        i18nTranslate('shipping.onedaysaturday.value', ''),
      ]
      break
    case 'pp':
      shippingMethodMessage = i18nTranslate('shipping.pp', 'PP(Canada Post)')
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.pp.key', 'PP'),
        i18nTranslate('shipping.pp.value', 'Canada Post'),
      ]
      break
    case 'upsground':
      shippingMethodMessage = i18nTranslate('shipping.upsground', 'UPS Ground')
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.upsground.key', 'UPS Ground'),
        i18nTranslate('shipping.upsground.value', ''),
      ]
      break
    case 'pickupontario':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickupontario',
        'Pick Up - Mississauga Ontario'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.pickupontario.key', 'Pick Up'),
        i18nTranslate('shipping.pickupontario.value', 'Mississauga Ontario'),
      ]
      break
    case 'pickupbc':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickupbc',
        'Pick Up - Richmond British Columbia'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.pickupbc.key', 'Pick Up'),
        i18nTranslate('shipping.pickupbc.value', 'Richmond British Columbia'),
      ]
      break

    // Mexico

    case 'pickupleon':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickupleon',
        'Pick Up - LEON PICK UP'
      )
      break

    //Argentina

    case 'pickuptom':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickuptom',
        'Pick Up - Tortugas Open Mall'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.pickuptom.key', 'Pick Up'),
        i18nTranslate('shipping.pickuptom.value', 'Tortugas Open Mall'),
      ]
      break
    case 'pickuprum':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickuprum',
        'Pick Up - Recoleta Urban Mall'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.pickuprum.key', 'Pick Up'),
        i18nTranslate('shipping.pickuprum.value', 'Recoleta Urban Mall'),
      ]
      break

    //Chile

    case 'pickupmpet':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickupmpet',
        'Pick Up - Mall Plaza el Trébo'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.pickupmpet.key', 'Pick Up'),
        i18nTranslate('shipping.pickupmpet.value', 'Mall Plaza el Trébo'),
      ]
      break
    case 'pickupmpld':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickupmpld',
        'Pick Up - Mall Plaza Los Dominicos'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.pickupmpld.key', 'Pick Up'),
        i18nTranslate('shipping.pickupmpld.value', 'Mall Plaza Los Dominicos'),
      ]
      break
    case 'pickupwarehouse':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickupwareHouse',
        'Pick Up - Pick Up Warehouse'
      )
      break

    // colomiba

    case 'pickupsfcc':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickupsfcc',
        'Pick up - Sante Fe Commercial Center'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.pickupsfcc.key', 'Pick up'),
        i18nTranslate(
          'shipping.pickupsfcc.value',
          'Sante Fe Commercial Center'
        ),
      ]
      break
    case 'pickuplqcc':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickuplqcc',
        'Pick up - La Quinta Commercial Center'
      )
      shippingMethodForExpressCheckout = [
        i18nTranslate('shipping.pickuplqcc.key', 'Pick up'),
        i18nTranslate(
          'shipping.pickuplqcc.value',
          'La Quinta Commercial Center'
        ),
      ]
      break
    case 'pickuppdc':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickuppdc',
        'Pick up - Portobello Design Centre'
      )
      break
    case 'pickuplqsc':
      shippingMethodMessage = i18nTranslate(
        'shipping.pickuplqsc',
        'Pick up - La Quinta Shopping Center'
      )
      break
  }
  return isExpressCheckout
    ? shippingMethodForExpressCheckout
    : shippingMethodMessage
}

export {
  showLocationDetails,
  renderPickUpAddress,
  renderNoteDetails,
  renderHourDetails,
  getTranslatedShippingMethod,
}
