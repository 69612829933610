import { IS_BROWSER } from 'src/utils/application'
import { pageNames } from 'src/routes/pathParams'

/**
 * Generates a Product schema object for SEO purposes.
 * @param {Object} props - The product data.
 * @returns {Object} The Product schema object.
 */
function productSchema(props) {
  const {
    imageURL = '',
    productId = '',
    brand = '',
    name = '',
    description = '',
    currency = '',
    price = '',
    location = {},
  } = props || {}

  const url = location
    ? location.domainName
    : IS_BROWSER && window.location.pathname.includes(pageNames.product)
    ? window.location.href
    : IS_BROWSER
    ? `${window.location.origin}${pageNames.product}/${productId}`
    : ''

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: name,
    image: imageURL,
    description: description,
    identifier: productId,
    brand: {
      '@type': 'Thing',
      name: brand,
    },
    offers: {
      '@type': 'Offer',
      priceCurrency: currency,
      price,
      itemCondition: 'https://schema.org/NewCondition',
      availability: 'https://schema.org/InStock',
      url,
    },
  }
}

export { productSchema }
