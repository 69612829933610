import React from 'react'
import { observer } from 'mobx-react'
/* import { observable } from 'mobx'
import { Trans } from 'react-i18next' */
import { i18nTranslate } from 'src/utils'
import { Icons } from 'src/views/components'
import { Dropdown } from 'react-bootstrap'
import debounce from 'lodash/debounce'
import { subscriptionType as subscriptionFrequency } from './fixture'
import { pageNames } from 'src/routes/pathParams'
import { APPConfig } from 'config/appConfig'
import './styles.scss'

@observer
class SubscriptionDropDown extends React.Component {
  constructor(props) {
    super(props)
    const isToEnableOneTimeFrequency =
      APPConfig?.getAppConfig()?.oneTimeFrequencyEnabled === 'true'
    let {
      frequencyPeriod = 1,
      frequencyType = 'MONTHS',
      isSubscriptionPage = false,
      orderType = 'VARIABLE_MULTI_ORDER',
    } = this.props
    const frequencyValue = `${frequencyPeriod}-${frequencyType}`
    let subscriptionType = subscriptionFrequency()
    let value = subscriptionType[0].value
    let label = subscriptionType[0].label
    let isOneTime = false
    if (!isSubscriptionPage || !isToEnableOneTimeFrequency) {
      subscriptionType = subscriptionType?.filter(item => {
        return !(item?.isOneTime === true)
      })
      value = subscriptionType[0].value
      label = subscriptionType[0].label
    }
    const subscriptionVal = subscriptionType.filter(
      item => frequencyValue == item.value
    )

    if (subscriptionVal.length) {
      if (subscriptionVal.length === 1) {
        value = subscriptionVal[0].value
        label = subscriptionVal[0].label
      } else {
        if (orderType === 'VARIABLE_SINGLE_ORDER') {
          value = subscriptionVal[1].value
          label = subscriptionVal[1].label
          isOneTime = subscriptionVal?.[1]?.isOneTime || false
        } else {
          value = subscriptionVal[0].value
          label = subscriptionVal[0].label
        }
      }
    }
    this.state = {
      selectedValue: value,
      selectedLabel: label,
      isSelectedOneTime: isOneTime,
      subscriptionType,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Update the frequency value from modal to tile on dash
    if (
      this.props?.isSubscriptionPage &&
      this.props?.isDataFromSubModal &&
      (this.props?.frequencyPeriod !== prevProps?.frequencyPeriod ||
        this.props?.orderType !== prevProps?.orderType)
    ) {
      const subscriptionType = subscriptionFrequency()
      if (this.props?.orderType === 'VARIABLE_SINGLE_ORDER') {
        this.setState({
          selectedValue: subscriptionType?.[3]?.value,
          selectedLabel: subscriptionType?.[3]?.label,
          isSelectedOneTime: subscriptionType?.[3]?.isOneTime || false,
        })
      } else {
        this.setState({
          selectedValue:
            subscriptionType?.[this.props?.frequencyPeriod - 1]?.value,
          selectedLabel:
            subscriptionType?.[this.props?.frequencyPeriod - 1]?.label,
          isSelectedOneTime:
            subscriptionType?.[this.props?.frequencyPeriod - 1]?.isOneTime ||
            false,
        })
      }
    }
  }

  setDropDown = async (
    value = '1-MONTHS',
    label = 'Monthly',
    isOneTime = false,
    isMonthly = false
  ) => {
    const [frequencyPeriod, frequencyType] = value.split('-')
    const subscription = { frequencyPeriod, frequencyType }
    let response = false
    const subscriptionReq = {
      quantity: this.props.quantity,
      isSubscription: true,
      subscription,
    }
    /**
     * note
     * EQPLAT-20393 - reverting the fix  due to blocker issue
     * */
    if (
      this.props.showAllFrequency !== 'undefined' &&
      this.props.showAllFrequency === true
    ) {
      // response = await this.props.handleSubscriptionChange(value, this.props.subscriptionProduct)
      if (
        this.state.selectedValue !== value ||
        this.state.isSelectedOneTime !== isOneTime
      )
        response = await this.props.handleSubscriptionChange(
          value,
          this.props.subscriptionProduct
        )
    } else {
      // response = await this.props.handleSubscriptionChange(
      //   this.props.quantity,
      //   this.props.itemId,
      //   subscriptionReq
      // )
      if (
        this.state.selectedValue !== value ||
        this.state.isSelectedOneTime !== isOneTime ||
        isMonthly
      )
        response = await this.props.handleSubscriptionChange(
          this.props.quantity,
          this.props.itemId,
          subscriptionReq,
          isOneTime
        )
    }
    if (this.props.isFromModal && this.props.isFromModal === true) {
      this.setState({ selectedValue: value, selectedLabel: label })
    } else {
      if (await response) {
        this.setState({ selectedValue: value, selectedLabel: label })
      }
    }
  }

  render() {
    const {
      disableActions = false,
      itemType = '',
      isProductStatusInactive = false,
      isToShowNewFrequencyStyling = false,
    } = this.props
    const items = this.state.subscriptionType.map(item => {
      const isSelectedClass =
        item.value === this.props.selectedValue ? 'selected' : ''
      return (
        <Dropdown.Item
          as="button"
          key={item.label}
          value={item.isOneTime === true ? 'MONTHLY' : item.value}
          className={isSelectedClass}
          disabled={
            this.props?.isFromModal &&
            item?.isOneTime === true &&
            itemType === 'VARIABLE_SINGLE_ORDER'
          }
          // tabIndex={0}
          onClick={debounce(event => {
            this.setDropDown(
              item.value,
              item.label,
              item?.isOneTime,
              item?.isMonthly
            )
          }, 700)}
          onKeyPress={debounce(event => {
            this.setDropDown(
              item.value,
              item.label,
              item?.isOneTime,
              item?.isMonthly
            )
          }, 700)}
          data-testid="qa-subscription-label">
          {item.label}
        </Dropdown.Item>
      )
    })
    const showFrequencyLabel =
      this.props.showAllFrequency !== 'undefined' &&
      this.props.showAllFrequency === true
        ? ' d-none'
        : ''
    const isSubscriptionPage = this.props?.isSubscriptionModal
      ? false
      : window.location.pathname.includes(pageNames.myaccountsubscriptions)
    const responsiveDropdown = !isSubscriptionPage ? 'd-flex' : ''
    const largerLabel = !isSubscriptionPage ? 'subscription-label-large' : ''
    return (
      <div
        className={`subscription-dropdown-container ${responsiveDropdown} p-0 flex-sm-row flex-column`}>
        {!isSubscriptionPage && (
          <div className="mr-1">
            <span className="mr-2 frequency-title">
              {i18nTranslate('cart.frequency', 'Frequency')}:
            </span>
          </div>
        )}
        <Dropdown
          className={'subscription-dropdown position-unset'}
          data-testid="qa-user-sub-product-qty-dropdown">
          <Dropdown.Toggle
            tabIndex={isProductStatusInactive ? -1 : 0}
            variant=""
            role="combobox"
            // id="dropdown-basic"
            className={`subscription-dropdown-toggle justify-content-between align-items-center`}
            disabled={disableActions || this.props.isGift}>
            {!isToShowNewFrequencyStyling && (
              <span
                className={`mb-0 quantity-title paragraph-m`}
                aria-label="quantity"></span>
            )}
            <span
              className={`selected-subscription-value ${largerLabel} paragraph-m`}>
              {this.state.selectedLabel}
            </span>
            <span className={`subscription-dropdown-arrow  paragraph-m`}>
              <Icons
                name="arrows"
                icontype="downarrow"
                alt={i18nTranslate('quantity.toggle', 'Toggle quantity box')}
                disableicon={true}
                ariaHidden={true}
              />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="subscription-dropdownmenu z-1">
            {items}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
}

export { SubscriptionDropDown }
export default SubscriptionDropDown
