import React from 'react'
import { Icons } from 'src/views/components'
import './styles.scss'

function SubscriptionToastMessage(props) {
  const { message = '', enableWarningIcon = false } = props
  return (
    <div
      className="sub-toast-message justify-content-center mt-2 mb-3 flex-nowrap"
      role="alert">
      {enableWarningIcon && (
        <Icons
          name="product-icon"
          icontype="danger-warning"
          width="20px"
          color="#fd983b"
        />
      )}
      {message}
    </div>
  )
}

export { SubscriptionToastMessage }
export default SubscriptionToastMessage
