import { observable } from 'mobx'
import { i18nTranslate } from 'src/utils'
import omit from 'lodash/omit'
import { CommonContainer } from 'src/models/Common'
import { filterWhiteLabelEmails } from 'src/utils'
import { APPConfig } from 'config/appConfig'
import { customerContainer, storeContainer } from 'src/models'
import isArray from 'lodash/isArray'
import { toastState } from 'src/views/components'
import { alertMessageKeys } from 'src/views/components/CartBlock/fixture'
import { getFraudDetectionProperties } from 'src/utils/signUpUtils'

/**
 * AddressContainer class extends CommonContainer.
 * This is likely the container class for customer address data and operations.
 */
class AddressContainer extends CommonContainer {
  @observable addressData = []
  isFirstAddress = false

  constructor(props) {
    super(props)
  }

  /**
   * Retrieves the customer's profile addresses from the API.
   *
   * Checks if the user is registered, then gets the addresses from the API using the
   * getProfileAddress endpoint. Applies country and address type filters.
   * Handles the response and stores the addresses in addressData.
   * Sets isFirstAddress based on whether addressData is empty.
   */
  getProfileAddress = async () => {
    if (customerContainer.isRegisterUser) {
      /**
       * @note
       * NUSKIN-1050 - Add country filter for getAddress call based on current country
       */
      let country = storeContainer.storeIDValue
      country = country?.toLowerCase() === 'canada' ? 'CA' : country
      const loadParams = {
        endPointName: 'getProfileAddress',
        queryParams: {
          search: `country:${country},type:shipping`,
        },
        // to fix the issue in updateInitialDefaultAddress, "type" filter added here
        // in updateInitialDefaultAddress function, type is not checked before patching default
        // so "local" type address is updated to default address ( with type "shipping" )
        // to avoid using "local" type address, here we are filtering the address while fetching in API itself
      }
      const response = await this.fetchResponse(loadParams)

      if (response?.length > 0) {
        response?.forEach(address => {
          address.email = filterWhiteLabelEmails({ email: address?.email })
        })
      }

      if (this.isSuccessResponse(response)) {
        this.addressData = response
      } else {
        // as API return 400 for Empty Address
        this.addressData = []
      }
      this.isFirstAddress =
        isArray(this.addressData) && this.addressData.length === 0
      return response
    }
    return ''
  }

  /**
   * Makes an API call to add an original address.
   *
   * @param {Object} address - The address object to add.
   * @returns {Promise} The API response.
   */
  originalAddressApiCall = async address => {
    const { shippingInstructions = '' } = address || {}
    if (shippingInstructions) {
      address.deliveryInstruction = shippingInstructions
      delete address?.shippingInstructions
    }

    const loadParams = {
      endPointName: 'addOriginalAddress',
      postData: address,
    }
    const response = await this.fetchResponse(loadParams)
    return response
  }

  /**
   * Validates an original address and adds it if valid.
   *
   * @param {Object} address - The address object to validate and add.
   * @param {string} addedFrom - The source the address is being added from.
   * @returns {Object|boolean} The API response if invalid, or the added address object if valid.
   */
  validateOriginalAddress = async (address, addedFrom = '') => {
    let addressAddResponse = {}
    let response = await this.originalAddressApiCall(address)
    if (this.isSuccessResponse(response)) {
      addressAddResponse = this.addAddress(address, addedFrom)
      return addressAddResponse
    } else {
      if (response.message === 'input.validation.failed') {
        response.message = alertMessageKeys(
          'Please correct the address to proceed.'
        )
      } else if (response.code === 'ONESOURCE_ADPLGIN_VAL_ERROR') {
        response.message = i18nTranslate(
          'address.invalidAddress',
          'Address provided is invalid'
        )
      }
      toastState.setToastMessage(response.message, false)
      return response
    }
  }

  /**
   * Validates an address entered during signup.
   *
   * @param {Object} address - The address object to validate.
   * @returns {Object|boolean} The API response if invalid, or true if valid.
   */
  validateSignUpOriginalAddress = async address => {
    let response = await this.originalAddressApiCall(address)
    if (this.isSuccessResponse(response)) {
      return true
    } else {
      toastState.setToastMessage(response.message, false)
      return response
    }
  }

  /**
   * Adds a new profile address asynchronously.
   *
   * Validates the address data, calls the API to add the address,
   * and shows success/error toasts. Also refreshes profile addresses.
   *
   * @param {Object} addressData - The address object to add
   * @param {string} addedFrom - Where the add request originated
   * @returns {Promise} The API response
   */
  addProfileAddress = async (addressData = {}, addedFrom = '') => {
    addressData.type = 'shipping'
    const loadParams = {
      endPointName: 'addProfileAddress',
      postData: addressData,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      if (addedFrom === 'SubscriptionShipping') {
        if (addressData.isDefault) {
          toastState.setToastMessage(
            i18nTranslate(
              'address.addShippingAddressSuccessSetSubsDefault',
              'Shipping address saved successfully and set as subscription default'
            ),
            true
          )
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'address.addShippingAddressSuccess',
              'Shipping address saved successfully'
            ),
            true
          )
        }
      } else {
        toastState.setToastMessage(
          i18nTranslate(
            'address.addAddressSuccess',
            'Your address has been added.'
          ),
          true
        )
      }
      await this.getProfileAddress()
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'address.addAddressFailure',
          'Sorry, we are unable to add your address. Please try again.'
        ),
        false
      )
    }
    return response
  }

  /**
   * Validates an address.
   *
   * @param {Object} addressData - The address data to validate
   * @returns {Promise} A promise that resolves to the API response
   */
  validateAddress = async addressData => {
    const reqBody = {
      addressLine3: '',
      canReceiveSMS: false,
      isDefault: addressData?.setDefaultAddress || false,
      type: 'ShippingAddreess',
      deliveryInstruction: addressData?.shippingInstructions || '',
      ...addressData,
    }
    delete reqBody['default']
    delete reqBody['continue']
    delete reqBody['street1']
    delete reqBody['street2']
    delete reqBody['zipCode']
    delete reqBody['submitButton']
    delete reqBody['setDefaultAddress']
    delete reqBody['shippingInstructions']
    const loadParams = {
      endPointName: 'validateProfileAddress',
      postData: reqBody,
    }
    const response = await this.fetchResponse(loadParams)

    return response
  }

  /**
   * Updates a user profile address.
   *
   * @param {Object} addressInfo - The address information to update
   * @param {boolean} disableToast - Whether to disable toast notifications
   * @returns {Promise} A promise that resolves to the API response
   */
  updateProfileAddress = async (addressInfo, disableToast = false) => {
    addressInfo.deliveryInstruction =
      addressInfo?.shippingInstructions ||
      addressInfo?.deliveryInstruction ||
      ''
    delete addressInfo?.shippingInstructions

    const { addressId, ...remainingProps } = addressInfo
    let addressData = omit(remainingProps, ['submitButton'])
    addressData.type = 'shipping'
    const accertifyProps = (await getFraudDetectionProperties()) || {}
    const profileFraudCheckEnabled =
      APPConfig?.getAppConfig()?.ProfileFraudCheckEnabled === 'true' || false
    if (profileFraudCheckEnabled) {
      addressData.additionalProperties = accertifyProps || {}
    }
    const loadParams = {
      endPointName: 'updateProfileAddress',
      pathParams: addressId,
      postData: addressData,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      !disableToast &&
        toastState.setToastMessage(
          i18nTranslate(
            'address.updateAddressSuccess',
            'Your address has been updated.'
          ),
          true
        )
      await this.getProfileAddress()
    } else if (response?.code === 'AOACHLD0001') {
      toastState.setToastMessage(
        i18nTranslate(
          'myaccount.userHoldStatus',
          'There is an issue with your account. Please contact you local account manager.'
        ),
        false
      )
      return
    } else {
      !disableToast &&
        toastState.setToastMessage(
          i18nTranslate(
            'address.updateAddressFailure',
            'Sorry, we are unable to update your address. Please try again.'
          ),
          false
        )
    }
    return response
  }

  /**
   * Deletes a customer profile address asynchronously.
   *
   * @param {Object} addressInfo - Address information object
   * @returns {Promise} Promise that resolves to API response
   */
  deleteProfileAddress = async addressInfo => {
    const loadParams = {
      endPointName: 'deleteProfileAddress',
      pathParams: addressInfo.addressId,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate(
          'address.removeAddressSuccess',
          'Address removed successfully'
        ),
        true
      )
      this.updateDefaultAddress(addressInfo)
    } else if (response?.code === 'EO0003') {
      toastState.setToastMessage(
        i18nTranslate(
          'address.cannotRemoveAddress',
          'Cannot delete this address as it is already associated with subscription. Kindly update your subscription address and then proceed with removal'
        )
      )
    } else {
      toastState.setToastMessage(
        response && response.message
          ? response.message
          : i18nTranslate(
              'address.removeAddressFailure',
              'Unable to remove address, please try again'
            ),
        false
      )
    }
  }

  /**
   * Updates the default address for the customer profile.
   *
   * @param {Object} addressInfo - The address object to update as default
   * Checks if the address is not already default, creates a copy of the address object,
   * sets isDefault to true on the copy, and calls updateProfileAddress() to update it on the server.
   */
  async updateDefault(addressInfo) {
    delete addressInfo.submitButton
    if (!addressInfo.isDefault) {
      const transformedAddress = { ...addressInfo }
      transformedAddress.isDefault = !transformedAddress.isDefault
      await this.updateProfileAddress(transformedAddress)
    }
  }

  /**
   * Updates the default address for the customer profile.
   *
   * If the address passed in is already the default, and there are multiple addresses,
   * it finds the first non-default address and sets that to default with type 'shipping'.
   *
   * Otherwise, it simply retrieves the profile addresses again.
   */
  async updateDefaultAddress(addressInfo) {
    if (addressInfo.isDefault && this.addressData.length > 1) {
      const defaultAddress = this.addressData.find(address => {
        return address.isDefault === false
      })
      defaultAddress.isDefault = true
      defaultAddress.type = 'shipping'
      await this.updateProfileAddress(defaultAddress, true)
    } else {
      await this.getProfileAddress()
    }
  }

  /**
   * Updates the initial default shipping address for the customer profile.
   *
   * Gets the existing shipping addresses from the profile.
   * If there is no existing default shipping address,
   * sets the first address in the list to be the default.
   */
  async updateInitialDefaultAddress() {
    const shippingAddresses = this.addressData
    if (isArray(shippingAddresses) && shippingAddresses.length) {
      const defaultAddress = shippingAddresses.find(address => {
        return address.isDefault === true
      })
      if (!defaultAddress) {
        shippingAddresses[0].isDefault = true
        await this.updateProfileAddress(shippingAddresses[0], true)
      }
    }
  }

  /**
   * Adds a new address to the customer profile.
   *
   * Deletes any submitButton property from the formData.
   * Sets the deliveryInstruction property from shippingInstructions or existing deliveryInstruction.
   * Deletes the shippingInstructions property.
   * Checks if this is the first address being added. If so, sets isDefault to true on the formData.
   * Otherwise, sets isDefault based on the setDefaultAddress/setDefaultShippingAddress flags.
   * Removes the setDefault* flags from the formData.
   * If setting as default, adds the customer email to the formData.
   * Gets fraud detection properties and adds to formData if enabled.
   * Calls addProfileAddress API with the formData and addedFrom info.
   * Returns the API response.
   */
  async addAddress(formData, addedFrom = '') {
    delete formData.submitButton
    formData.deliveryInstruction =
      formData?.shippingInstructions || formData?.deliveryInstruction || ''
    delete formData?.shippingInstructions

    const { setDefaultAddress = false, setDefaultShippingAddress = false } =
      formData
    if (this.isFirstAddress) {
      formData.isDefault = true
    } else {
      formData.isDefault = setDefaultAddress || setDefaultShippingAddress
    }
    delete formData.setDefaultAddress
    delete formData.setDefaultShippingAddress
    if (formData?.isDefault) {
      formData['email'] =
        formData?.email || customerContainer?.profileResponse?.email
    }
    const accertifyProps = (await getFraudDetectionProperties()) || {}
    const profileFraudCheckEnabled =
      APPConfig?.getAppConfig()?.ProfileFraudCheckEnabled === 'true' || false
    if (profileFraudCheckEnabled) {
      formData.additionalProperties = accertifyProps || {}
    }
    const response = await this.addProfileAddress(formData, addedFrom)
    return response
  }

  /**
   * Validates an address form submission.
   *
   * Deletes any submitButton property from the formData.
   * Checks if this is the first address being added. If so, sets isDefault to true on the formData.
   * Otherwise, sets isDefault based on the setDefaultAddress flag.
   * Calls validateAddress API with the formData.
   * Returns the API response.
   */
  async validateAddress(formData) {
    delete formData.submitButton
    const { setDefaultAddress = false } = formData
    if (this.isFirstAddress) {
      formData.isDefault = true
    } else {
      formData.isDefault = setDefaultAddress
    }
    const response = await this.validateAddress(formData)
    return response
  }
  /**
   * Validates an updated address submission.
   *
   * Calls originalAddressApiCall() with the formData.
   * If successful, calls updateAddress() to save the changes.
   * If validation fails, updates the error message and returns the response.
   * Handles invalid address errors from OneSource plugin.
   * Shows toast message on error.
   */
  async validateUpdatedAddress(formdata, addressInfo) {
    let addressAddResponse = {}
    let response = await this.originalAddressApiCall(formdata)
    if (this.isSuccessResponse(response)) {
      addressAddResponse = await this.updateAddress(formdata, addressInfo)
      return addressAddResponse
    } else {
      if (response.message === 'input.validation.failed') {
        response.message = alertMessageKeys(
          'Please correct the address to proceed.'
        )
      } else if (response?.code === 'ONESOURCE_ADPLGIN_VAL_ERROR') {
        response.message = i18nTranslate(
          'address.invalidAddress',
          'Address provided is invalid'
        )
      }
      toastState.setToastMessage(response.message, false)
      return response
    }
  }

  /**
   * Updates a customer address.
   *
   * Destructures formData to extract setDefaultAddress, setDefaultShippingAddress and remainingProps.
   * Sets addressId and isDefault on remainingProps.
   * Calls updateProfileAddress API with remainingProps.
   * Returns the API response.
   */
  async updateAddress(formData, addressInfo) {
    const {
      firstName,
      lastName,
      setDefaultAddress = false,
      setDefaultShippingAddress = false,
      ...remainingProps
    } = formData

    remainingProps.firstName = firstName?.trim()
    remainingProps.lastName = lastName?.trim()
    remainingProps.addressId = await addressInfo?.addressId
    remainingProps.isDefault = setDefaultAddress || setDefaultShippingAddress
    const response = await this.updateProfileAddress(remainingProps)
    return response
  }

  /**
   * Gets postal code lookup data for the given country and postal code.
   *
   * @param {string} country - The country code.
   * @param {number} [postalCode=11330] - The postal code to look up.
   * @returns {Promise} Response from the postal code lookup API.
   */
  getPostalCodeLookup = async (countryCode = '', postalCode = '') => {
    const loadParams = {
      endPointName: 'postalCodeLookup',
      postData: {
        country: countryCode?.toUpperCase(),
        postcode: postalCode?.toUpperCase(),
      },
    }

    const response = await this.fetchResponse(loadParams)
    return response
  }
}

const addressContainer = new AddressContainer()
export { addressContainer }
