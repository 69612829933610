function getB2BEndPoints(API_BASE_PATH) {
  const API_URL_CONFIG_B2B = {
    accountsBasePath: `${API_BASE_PATH}/accounts`,
    accountTypePath: `${API_BASE_PATH}/accounts/nextgen`,
  }

  return {
    accounts: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'POST',
    },
    updateAccountInfo: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'PATCH',
    },
    getAllAccountAddress: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'GET',
    },
    addAccountAddress: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'POST',
    },
    deleteAccountAddress: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'POST',
    },
    updateAccountAddress: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'PATCH',
    },
    updateAccountType: {
      url: `${API_URL_CONFIG_B2B.accountTypePath}`,
      method: 'PUT',
    },
    userConsent: {
      url: 'https://geolocation-db.com/json',
      method: 'GET',
    },
    accountProfile: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'GET',
    },
    userInvite: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'PATCH',
    },
    getAllCostCenter: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'GET',
    },
    getCostCenter: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'GET',
    },
    validateCostCenter: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'GET',
    },
    createCostCenter: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'POST',
    },
    updateCostCenter: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'PATCH',
    },
    deleteBudget: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'DELETE',
    },
    getAllBudget: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'GET',
    },
    deleteCostCenter: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'DELETE',
    },
    getAllTeams: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'GET',
    },
    createTeam: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'POST',
    },
    updateTeam: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'PATCH',
    },
    deleteTeam: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'DELETE',
    },
    getAllBuyerRoles: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}`,
      method: 'GET',
    },
    getAccountSettings: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}/settings`,
      method: 'GET',
    },
    getAllAttributes: {
      url: `${API_URL_CONFIG_B2B.accountsBasePath}/attributes`,
      method: 'GET',
    },
  }
}
export { getB2BEndPoints }
