import * as yup from 'yup'
import { isB2BAccount } from 'src/utils'
import { getEmailSchema, getUserNameSchema, getPasswordSchema } from 'src/deps'

let signInSchema = {}
if (isB2BAccount()) {
  signInSchema = Object.assign({}, getEmailSchema(), getPasswordSchema())
} else {
  signInSchema = Object.assign({}, getUserNameSchema(), getPasswordSchema())
}
export const schema = yup.object(signInSchema)
