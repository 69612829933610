import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import './styles.scss'
import { customerContainer } from 'src/models'
import { i18nTranslate } from 'src/utils'

class SuccessModal extends Component {
  render() {
    return (
      <div className="success-modal">
        <h2 className="nice-text">
          {i18nTranslate('phoneVerify.text', 'Nice job!')}
        </h2>
        <p className="verified-number">
          {i18nTranslate(
            'phoneVerify.textDescription',
            'Your number has been verified and saved to your account'
          )}
        </p>
        <Button
          className="w-100 mt-2 finishBtn"
          onClick={() => {
            this.props.handleFinish()
          }}>
          {i18nTranslate('phoneVerify.finish', 'Finish')}
        </Button>
      </div>
    )
  }
}

export default SuccessModal
export { SuccessModal }
