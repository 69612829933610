import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { ProductCarousel } from 'src/views/components'
import './styles.scss'

@observer
class CrossSellUpSell extends React.Component {
  render() {
    const { upSellProducts = [], crossSellProducts = [] } = this.props
    const titleClassName = 'related-title py-4 text-center'
    return (
      <React.Fragment>
        {crossSellProducts.length > 0 && (
          <React.Fragment>
            <h1 className={titleClassName}>
              {i18nTranslate('pdp.relatedproducts', 'Related Products', {
                nameSpace: 'ssr-resource',
              })}
            </h1>
            <ProductCarousel list={crossSellProducts} hasBullets={false} />
          </React.Fragment>
        )}
        {upSellProducts.length > 0 && (
          <React.Fragment>
            <h1 className={titleClassName}>
              {i18nTranslate(
                'pdp.recommendedproducts',
                'Recommended Products',
                {
                  nameSpace: 'ssr-resource',
                }
              )}
            </h1>
            <ProductCarousel list={upSellProducts} hasBullets={false} />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}
export { CrossSellUpSell }
export default CrossSellUpSell
