import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { Modal, Button, Form } from 'react-bootstrap'
import { i18nTranslate, trackSignUpEvents } from 'src/utils'
import './styles.scss'

class AddressValidation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: this.props.open,
      selectedAddress: {},
      selectedIndexValue: 0,
      disabled: false,
    }
  }

  componentDidMount() {
    const suggestedFirstAddress = {
      ...this.props.recommendations[1],
      setDefaultAddress:
        this.props.recommendations?.[0]?.setDefaultAddress || false,
      setDefaultShippingAddress:
        this.props.recommendations?.[0]?.setDefaultShippingAddress || false,
    }
    this.setState({
      selectedAddress: !this.props.addressInvalidFlag
        ? suggestedFirstAddress
        : this.props.recommendations[0],
      selectedIndexValue: !this.props.addressInvalidFlag ? 1 : 0,
    })
  }

  handleClickOnPro() {
    if (this.state.disabled) {
      return
    }

    this.setState({ disabled: true })

    const temp = {
      ...(this.state.selectedAddress || this.props.recommendations[0]),
      deliveryInstruction:
        this.props.recommendations?.[0]?.shippingInstructions,
      selectedIndex: this.state.selectedIndexValue,
    }
    temp.zip = temp.zip || temp.postalCode
    delete temp?.postalCode
    if (this.props?.type === 'oktaSignUp') {
      trackSignUpEvents({
        eventType: this.props.isFromModal
          ? 'eqDistInviteVerifyAddress'
          : 'eqDistColdVerifyAddress',
      })
    }
    if (this.props.addressInvalidFlag) {
      const originalAddressData = this.props.recommendations?.[0] || {}
      const originalFormData = {
        ...originalAddressData,
        selectedIndex: this.state.selectedIndexValue,
      }
      this.props.handleClickOnPro(originalFormData)
    } else {
      this.props.handleClickOnPro(temp)
    }
  }

  handleChange = async (data, Idx) => {
    const selectedAddress = {
      ...data,
      setDefaultAddress:
        this.props.recommendations?.[0]?.setDefaultAddress || false,
      setDefaultShippingAddress:
        this.props.recommendations?.[0]?.setDefaultShippingAddress || false,
    }

    this.setState({ selectedAddress, selectedIndexValue: Idx }, () => {})
  }

  renderSubmitAndCancelButtons = () => {
    return (
      <div className="d-flex align-items-start flex-column mt-4">
        <Button
          className="proceed-btn mb-2 text-uppercase"
          variant="primary"
          onClick={() => this.handleClickOnPro()}
          disabled={this.state.disabled}
          data-testid="qa-suggestion-save">
          {i18nTranslate('modal.proceedbutton', 'Use this address')}
        </Button>
        <Button
          className="cancel-address text-uppercase"
          variant="outline-primary"
          onClick={() => this.props.handleClose()}
          data-testid="qa-suggestion-cancel">
          {i18nTranslate('modal.cancelbutton', 'Cancel')}
        </Button>
      </div>
    )
  }

  renderEditButton = () => {
    return (
      <Button
        className="edit-btn"
        onClick={() => this.props?.handleClose()}
        variant="link">
        {i18nTranslate('modal.Editbutton', 'Edit')}
      </Button>
    )
  }

  renderAddress = (address = {}) => {
    const {
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state = '',
      county = '',
      country = '',
      zip = '',
    } = address

    return (
      <div className="address-label">
        <div className="p-0 col">{addressLine1}</div>
        <div className="p-0 col">{addressLine2}</div>
        <div className="p-0 col">
          {`${city ? city + ',' : ''}${county ? county + ',' : ''}${
            state ? state + ',' : ''
          }${country}`}
        </div>
        <div className="p-0 col">{zip}</div>
      </div>
    )
  }

  renderAddressRadioOption = (
    address = {},
    index = 0,
    isSelectedOption = false,
    isToShowEditButton = false
  ) => {
    const addressId = `address-${index + 1}`
    const addressKey = `address-key-${index + 1}`
    return (
      <>
        <input
          type="radio"
          name="suggestedAddressRadio"
          id={addressId}
          onChange={() => this.handleChange(address, index)}
          key={addressKey}
          checked={isSelectedOption}
          className="payment-options"
          data-testid={`qa-address-radio-${index + 1}`}
        />
        <label htmlFor={addressId} className="payment-label">
          <div>
            {this.renderAddress(address)}
            {isToShowEditButton && this.renderEditButton()}
          </div>
        </label>
      </>
    )
  }

  renderNoSuggestionFoundText = () => {
    return (
      <div className="address-error">
        <h4>
          {i18nTranslate(
            'address.notvalid',
            'We were unable to find any suggestions.'
          )}
        </h4>
        <p>
          {i18nTranslate(
            'addresscontent.notvalid',
            `Please carefully confirm your address above before proceeding without automatic verification.`
          )}
        </p>
      </div>
    )
  }

  renderSuggestedAddressTitle = () => {
    return (
      <h3>
        {i18nTranslate('addressverification.suggested', 'Suggested Addresses')}
      </h3>
    )
  }

  renderOriginalAddressTitle = () => {
    return (
      <h3>
        {i18nTranslate('addressverification.original', 'Original Address')}
      </h3>
    )
  }

  renderSuggestedAddress = () => {
    const suggestedAddress = this.props?.recommendations?.slice?.(1) || []
    return (
      <div className="address-title">
        <h3>{this.renderSuggestedAddressTitle()}</h3>
        {this.props.addressInvalidFlag && this.renderNoSuggestionFoundText()}
        <div className="my-2 radio-btn-input">
          {!this.props.addressInvalidFlag &&
            suggestedAddress?.map((address = {}, index) => {
              if (!isEmpty(address)) {
                const isSelectedOption =
                  index + 1 === this.state.selectedIndexValue
                return this.renderAddressRadioOption(
                  address,
                  index + 1,
                  isSelectedOption
                )
              }
            })}
        </div>
      </div>
    )
  }

  renderOriginalAddress = () => {
    let originalAddress = this.props?.recommendations?.[0] || {}
    let originalAddressIndex = 0
    let isToSelectOriginalAddress = this.props.addressInvalidFlag
      ? this.props.addressInvalidFlag
      : originalAddressIndex === this.state.selectedIndexValue
    return (
      <div className="address-title">
        {this.renderOriginalAddressTitle()}
        <div className="position-relative my-4">
          {this.renderAddressRadioOption(
            originalAddress,
            originalAddressIndex,
            isToSelectOriginalAddress,
            true
          )}
        </div>
      </div>
    )
  }

  renderSuggestionModalHeader = () => {
    return (
      <>
        <h2 className="text-capitalize">
          {i18nTranslate('addressverification.title', 'Verify Your Address')}
        </h2>
        <p className="m-0">
          {i18nTranslate(
            'addressverification.content',
            `We were unable to find the address entered. Please select your correct address from the options below.`
          )}
        </p>
      </>
    )
  }

  render() {
    return (
      <Modal show={this.props.open} animation={false}>
        <div
          className="address-verification"
          data-testid="qa-address-suggestion-overlay">
          <Modal.Header closeButton className="pb-0">
            <Modal.Title className="border-bottom pb-3">
              {this.renderSuggestionModalHeader()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="address-suggestion-modal-body">
            <div className="address-original">
              {this.renderOriginalAddress()}
            </div>
            <div className="my-2 address-suggested-wrapper">
              {this.renderSuggestedAddress()}
            </div>
            {this.renderSubmitAndCancelButtons()}
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}

export { AddressValidation }
export default AddressValidation
