export const formInput = props => {
  return [
    [
      {
        name: 'loyaltyPoint',
        type: 'number',
        id: 'inputField',
        ariaDescribedby: 'inputGroup-sizing-default',
        isToHideLabel: true,
        dataTestId: 'qa-Loyalty-Input',
        maxLength: '64',
        columnType: {
          lg: '4',
          md: '6',
        },
        customClassName: 'mb-2',
        disabled: props.walletBalance === '0.00',
        ariaLabel: 'loyaltyPoint',
      },
      {
        name: 'applyButton',
        type: 'inlineSubmitButton',
        dataTestId: 'qa-loyalty-apply',
        columnType: {
          lg: '2',
          md: '3',
        },
      },
    ],
  ]
}
