import DEFAULT_APP_CONFIG from './defaultConfig.json'
import ConfigKey from './ConfigKey'
import { IS_BROWSER, getCookie } from './configUtils'

class AppConfig {
  isFrozen = false
  refKeysMap = {
    catalogApiDomain: 'apiDomainName',
    // secretKey: 'x-skava',
    // apiKey: 'x-api-key',
    storeId: 'x-store-id',
    // globalStoreId: 'x-globalstore-id',
    defaultLocale: 'locale',
  }
  commonHeadersMap = [
    // 'secretKey',
    // 'apiKey',
    'storeId',
    // 'globalStoreId',
    'defaultLocale',
  ]
  cookieProps = []
  configKeyList = []

  constructor() {
    this.reInit()
  }

  getActiveAppConfig(locale) {
    if (typeof global === 'object') {
      if (locale && global?.APP_CONFIG_WITH_LOCALE) {
        return global?.APP_CONFIG_WITH_LOCALE[locale] || {}
      }
      return global?.APP_CONFIG || {}
    } else if (typeof window === 'object') {
      return window?.APP_CONFIG
    }
    return {}
    // return (
    //   (typeof global === 'object'
    //     ? global?.APP_CONFIG
    //     : typeof window === 'object'
    //     ? window?.APP_CONFIG
    //     : {}) || {}
    // )
  }

  reInit() {
    const appConfig = this.getActiveAppConfig()

    if (
      Object.keys(this.configKeyList || {}).length === 0 &&
      this.isFrozen === false &&
      Object.keys(appConfig).length > 0
    ) {
      this.freezeAndAssignToConfigKeyList(appConfig)
    }
  }

  resetToDefault() {
    if (this.configKeyList) {
      this.configKeyList.forEach(configKey => configKey.reset())
    }
  }

  clearStorages() {
    if (this.configKeyList) {
      this.configKeyList.forEach(configKey => configKey.clear())
    }
  }

  updateConfigKeyList(newProps) {
    if (this.configKeyList) {
      this.configKeyList.forEach(configKey => {
        let key = configKey.key
        let value = newProps[key]
        let refValue = value

        if (key === 'defaultLocale') {
          refValue = value?.replace('_', '-')
        }

        if (value) {
          configKey.set(value, refValue)
        }
      })
    }
  }

  reCollectProps(appConfigs) {
    const config = {}
    const collectedConfigs = appConfigs || this.configKeyList

    if (collectedConfigs === undefined || collectedConfigs?.length === 0) {
      this.isFrozen = false
      this.reInit()
    }

    config.headersToPassFromCookieToAPI = []
    config.commonHeaders = {
      'Content-Type': 'application/json',
    }

    collectedConfigs?.forEach(configKey => {
      const val = configKey.get()

      if (configKey.isCommonHeaders) {
        config.commonHeaders[configKey.refKey] = val
      }

      config[configKey.key] = val

      if (configKey.shouldStoreBothKeys) {
        config[configKey.refKey] = val
      }
    })

    return config
  }

  getConfigFromJSON() {
    return DEFAULT_APP_CONFIG
  }

  getDefaultProps() {
    return DEFAULT_APP_CONFIG.defaultProps || {}
  }

  getLocalEnv() {
    return DEFAULT_APP_CONFIG.activeEnvironment
  }

  setAppConfig(config, shouldFreeze = false, curLocaleFromUrl) {
    const isBrowser = typeof window === 'object'
    const isNode = typeof window === 'undefined'
    let updatedConfig = config

    const configKeyList = this.freezeAndAssignToConfigKeyList(config, true)
    updatedConfig = this.reCollectProps(configKeyList)

    if (isBrowser === true) {
      window.APP_CONFIG = updatedConfig
    }

    if (isNode === true) {
      global.APP_CONFIG = updatedConfig
      if (curLocaleFromUrl) {
        if (!global.APP_CONFIG_WITH_LOCALE) {
          global.APP_CONFIG_WITH_LOCALE = {}
        }
        global.APP_CONFIG_WITH_LOCALE[curLocaleFromUrl] = updatedConfig
      }
    }
  }

  getAppConfig = () => {
    // Added support to use cookie values. Enabled cookie values only for CSR.
    // Need to add support for SSR.
    const appConfig = this.reCollectProps()
    if (IS_BROWSER && appConfig.cookieOverride?.length > 0) {
      appConfig.cookieOverride.forEach(configName => {
        const cookieValue = getCookie(configName)
        if (cookieValue) {
          appConfig[configName] = cookieValue
        }
      })
    }
    return appConfig
    // return this.reCollectProps()
  }

  updateAppConfig(config, shouldUpdateConfigKey = false, currentLocaleFromUrl) {
    this.setAppConfig(config, false, currentLocaleFromUrl)

    if (shouldUpdateConfigKey === true) {
      this.updateConfigKeyList(config)
    }
  }

  freezeAndAssignToConfigKeyList(config, isConfigWithLocale = false) {
    if (this.isFrozen === false || isConfigWithLocale) {
      let configKeys = []
      // this.configKeyList = []
      configKeys = Object.keys(config).map(key => {
        const value = config[key]
        const storage = this.cookieProps.includes(key)
          ? 'cookie'
          : key === 'defaultLocale'
          ? 'both'
          : 'ls'
        const isCommonHeaders = this.commonHeadersMap.includes(key)

        let refValue = value
        if (key === 'defaultLocale') {
          refValue = value?.replace('_', '-')
        }

        return new ConfigKey({
          key,
          value,
          refKey: this.refKeysMap[key] || '',
          refValue: refValue,
          defValue: value,
          defRefValue: value,
          storage,
          isCommonHeaders,
        })
      })

      configKeys.push(new ConfigKey('dynamicStoreId', config.storeId))
      this.isFrozen = true
      this.configKeyList = configKeys
      return configKeys
    }
  }
}

const APPConfig = new AppConfig()
export { APPConfig }
