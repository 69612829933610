import * as yup from 'yup'
import {
  getCardNameSchema,
  getExpiryDate,
  getRequiredSchema,
  getRequiredCheckboxSchema,
} from 'src/deps'
import {
  addPaymentToProfileErrorMsg,
  savePaymentToProfileErrorMsg,
} from 'src/deps/validationErrorMessages'
import { tokenExContainer, customerContainer } from 'src/models'
export const schema = (props = {}) => {
  const {
    isFromEditPayment = false,
    shouldRenderAddressForm = false,
    isFromCheckout = false,
    isFromEditSubscription = false,
    hasSubscriptionProduct = false,
    isFromPayment,
  } = props
  const schemaData = {}

  if (!isFromEditPayment) {
    Object.assign(schemaData, getCardNameSchema({ inputName: 'cardFirstName' }))
  }
  Object.assign(schemaData, getExpiryDate())
  tokenExContainer.tokenExSavePaymentTypeValid =
    isFromCheckout && hasSubscriptionProduct

  if (
    customerContainer.isRegisterUser &&
    ((isFromCheckout && hasSubscriptionProduct) ||
      isFromEditSubscription ||
      isFromPayment)
  ) {
    Object.assign(
      schemaData,
      getRequiredCheckboxSchema({
        inputName: 'addPaymentToProfile',
        requiredErrorText:
          isFromPayment || isFromCheckout
            ? savePaymentToProfileErrorMsg
            : addPaymentToProfileErrorMsg,
      })
    )
  }
  if (shouldRenderAddressForm) {
    Object.assign(
      schemaData,
      getRequiredSchema({
        inputName: 'country',
        requiredType: 'country',
      })
    )
  }

  return yup.object(schemaData)
}
