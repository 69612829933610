import React from 'react'
import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { SignInAccordion } from 'src/views/components/CheckoutAccordions'
import { getLiveEventStatus, setLocalStorage } from 'src/utils'

@observer
class SigninModal extends React.Component {
  // @observable isVisible = false

  handleContinue = () => {
    this?.props?.onHideSignInModal?.()
    this.props?.onContinue?.()
  }

  handleHide = () => {
    setLocalStorage('IsCheckoutClicked', false)
    this?.props?.onHideSignInModal?.()
  }

  render() {
    const enableLiveEvent = getLiveEventStatus()
    if (!this.props.isVisible) {
      return <></>
    }
    return (
      <Modal
        centered={true}
        size={enableLiveEvent !== 'true' ? 'lg' : 'md'}
        className="align-modal"
        show={this.props?.isVisible || false}
        onHide={this.handleHide}
        dialogClassName={`signin-dialog ${
          enableLiveEvent === 'true' ? 'live-event-enabled' : ''
        }`}>
        <Modal.Header
          closeButton
          className={'guest-form-close border-bottom-0'}
        />
        <Modal.Body>
          <SignInAccordion {...this.props} onContinue={this.handleContinue} />
        </Modal.Body>
      </Modal>
    )
  }
}

export { SigninModal }
