import React from 'react'
import { i18nTranslate } from 'src/utils'
import { currencyFormat } from 'src/utils'

const SubscribeAndSaveText = props => {
  const { subscribeAndSave = 0 } = props
  const getFormattedPrice = priceValue => {
    const formattedPrice = currencyFormat({
      price: priceValue,
    })
    return formattedPrice
  }
  const priceAfterDiscount = getFormattedPrice(subscribeAndSave)

  return (
    subscribeAndSave > 0 && (
      <div
        data-testid="qa-subscribeandsave-text"
        className="d-flex flex-column cls-subscribeAndSave">
        <span>
          {i18nTranslate('pdp.subscribeAndSave', 'Subscribe and Save', {
            nameSpace: 'ssr-resource',
          })}
          : {priceAfterDiscount}
        </span>
      </div>
    )
  )
}
export { SubscribeAndSaveText }
export default SubscribeAndSaveText
