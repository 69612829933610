import { APPConfig } from './AppConfig'
// import { trackCookie } from 'src/utils'

const IS_BROWSER = typeof window === 'object'
let enableUserInvite = false

/**
 * @important @note
 * we can't import apiUtils here since appconfig is imported in apiUtils,
 * if so it will be recursive.
 */
function getLocalStorage(storageName) {
  try {
    if (typeof window !== 'undefined') {
      const storage = localStorage.getItem(storageName)
      try {
        return JSON.parse(storage)
      } catch (e) {
        return storage
      }
    }
  } catch (e) {
    console.error('Exception in storage', e)
  }
}

function getSessionStorage(storageName) {
  try {
    if (typeof window !== 'undefined') {
      const storage = sessionStorage.getItem(storageName)
      try {
        return JSON.parse(storage)
      } catch (e) {
        return storage
      }
    }
  } catch (e) {
    console.error('Exception in storage', e)
  }
}

function setLocalStorage(storageName, storageItem) {
  try {
    if (typeof window !== 'undefined') {
      localStorage.setItem(storageName, JSON.stringify(storageItem))
    }
  } catch (e) {
    console.error('Exception in storage', e)
  }
}

function setSessionStorage(storageName, storageItem) {
  try {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(storageName, JSON.stringify(storageItem))
    }
  } catch (e) {
    console.error('Exception in storage', e)
  }
}

function deleteFromLocalStorage(storageName) {
  try {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(storageName)
    }
  } catch (e) {
    console.error('Exception in storage', e)
  }
}

/**
 * @description used to remove items from session storage
 * @date 10/20/2023 - 3:58:45 PM
 *
 * @param {*} storageName
 */
function deleteFromSessionStorage(storageName) {
  try {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem(storageName)
    }
  } catch (e) {
    console.error('Exception in storage', e)
  }
}

function setCookie(props) {
  if (IS_BROWSER) {
    const {
      cookieName,
      cookieValue,
      expirydays = 30,
      expirySeconds,
      isSessionCookie = false,
    } = props

    if (!cookieName || !cookieValue) {
      return
    }

    const date = new Date()
    const expiryMilliSeconds = expirydays * 24 * 60 * 60 * 1000
    let expiryTime = expiryMilliSeconds

    if (expirySeconds) {
      expiryTime = expirySeconds * 1000
    }

    date.setTime(date.getTime() + expiryTime)
    const expires = isSessionCookie ? '' : 'expires=' + date.toUTCString()
    const secureCookie = document.domain !== 'local.nuskin.com' ? 'secure;' : ''
    document.cookie = `${cookieName}=${cookieValue};${expires};${secureCookie}path=/`
    // trackCookie(cookieName, cookieValue)
  }
}

function getCookie(name) {
  if (IS_BROWSER) {
    var value = '; ' + document.cookie
    var parts = value.split('; ' + name + '=')
    if (parts.length == 2) return parts.pop().split(';').shift()
  }
}

function deleteCookie(cookieName) {
  if (IS_BROWSER) {
    document.cookie =
      cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}

function getStudioPreview() {
  try {
    return location.href.includes('studioPreview=true')
  } catch (e) {
    return false
  }
}

function getMerchPlp() {
  try {
    return location.href.includes('isMerchPLP=true')
  } catch (e) {
    return false
  }
}

function getIsMerchandising() {
  try {
    return location.href.includes('isMerchandising=true')
  } catch (e) {
    return false
  }
}

function convertToBoolean(booleanValue) {
  try {
    if (booleanValue) {
      return !!JSON.parse(String(booleanValue).toLowerCase())
    }
  } catch (e) {
    console.error('JSON.parse may failed at convertToBoolean >>>', e)
  }
  return false
}

function getAppConfig() {
  return APPConfig.getAppConfig()
}

function getActiveAppConfig(currentLocale) {
  return APPConfig.getActiveAppConfig(currentLocale)
}

function isB2BAccount() {
  /**
   * @todo
   * !!! needs to be updated in dynamic way
   */
  // return getAppConfig()?.businessRelationship === 'B2B'
  return true
}

function isB2BAdmin() {
  const userRole = getCookie('x-role-user')
  return isB2BAccount() === true && userRole === 'ROLE_ACCOUNT_BUYER_ADMIN'
}

function isB2BUser() {
  const userRole = getCookie('x-role-user')
  return (
    isB2BAccount() === true &&
    // (userRole === 'ROLE_ACCOUNT_BUYER' ||
    userRole === 'ROLE_ACCOUNT_BUYER_USER'
  )
}

function isB2B2C() {
  return isB2BAccount() && APPConfig.getActiveAppConfig().activateB2B2C
    ? true
    : false
}

function isB2B2CAdmin() {
  return isB2BAdmin()
}

function enableB2B2CUserInviteCreate(isUserInvite) {
  enableUserInvite = isUserInvite
  return enableUserInvite
}

function isenableUserInvite() {
  return enableUserInvite
}

function getIsB2B() {
  return APPConfig.getAppConfig()?.businessRelationship === 'B2B'
}

function getSeparateCartAndCheckout() {
  const shouldUseSeparateCart = true
  return getIsB2B() ? true : shouldUseSeparateCart
}

function getShowMiniCart() {
  return getIsB2B() ? false : true
}
function shouldMakeB2BCalls() {
  return isB2BAdmin() === true && isB2B2C() === false
}

export {
  IS_BROWSER,
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
  deleteFromLocalStorage,
  deleteFromSessionStorage,
  setCookie,
  getCookie,
  deleteCookie,
  getStudioPreview,
  getMerchPlp,
  getIsMerchandising,
  convertToBoolean,
  getAppConfig,
  isB2BAccount,
  isB2BAdmin,
  isB2BUser,
  isB2B2C,
  isB2B2CAdmin,
  enableB2B2CUserInviteCreate,
  isenableUserInvite,
  getIsB2B,
  getSeparateCartAndCheckout,
  getShowMiniCart,
  shouldMakeB2BCalls,
  getActiveAppConfig,
}
