import React from 'react'
import { Row } from 'react-bootstrap'
import { CustomPrice } from 'src/views/components'
import { i18nTranslate } from 'src/utils'
import { getLocalStorage } from 'src/utils'
import { customerContainer, promotionContainer } from 'src/models'
import { productState } from 'src/views/components/BaseProductComponent'
import { productDeps } from 'src/deps'
import { observer } from 'mobx-react'
import './styles.scss'

@observer
class V2ProductSVCV extends React.Component {
  render() {
    const localvalue = getLocalStorage('accountType')
    const unitValue = productDeps.getUnitValue(this.props)

    const CVPriceOriginal =
      productState.currentlySelectedPriceOption === 'one-time-price-button'
        ? unitValue?.priceFacets?.CV?.CVAfterDiscount ||
          unitValue?.priceFacets?.CV?.CV
        : unitValue?.priceFacets?.['Sub CV']?.['Sub CVAfterDiscount'] ||
          unitValue?.priceFacets?.['Sub CV']?.['Sub CV']

    const PVPriceOriginal =
      productState.currentlySelectedPriceOption === 'one-time-price-button'
        ? unitValue?.priceFacets?.PV?.PVAfterDiscount ||
          unitValue?.priceFacets?.PV?.PV
        : unitValue?.priceFacets?.['Sub PV']?.['Sub PVAfterDiscount'] ||
          unitValue?.priceFacets?.['Sub PV']?.['Sub PV']

    const userRole = customerContainer?.profileResponse?.userrole || ''

    if (localvalue?.includes('Brand Affiliate') && userRole?.length > 0) {
      return (
        <Row noGutters className="pvcv-price-container">
          <CustomPrice
            custompriceValue={PVPriceOriginal}
            custompriceLabel={i18nTranslate('pdp.svprice', 'SV', {
              nameSpace: 'ssr-resource',
            })}
            dataTestId="qa-pdp-pvprice"
            isPVCV={true}
            enableNewPDPStyle={true}
          />
          <CustomPrice
            custompriceValue={CVPriceOriginal}
            custompriceLabel={i18nTranslate('pdp.cvprice', 'CV', {
              nameSpace: 'ssr-resource',
            })}
            dataTestId="qa-pdp-pvprice"
            isPVCV={true}
            enableNewPDPStyle={true}
          />
        </Row>
      )
    }
    return <></>
  }
}

export { V2ProductSVCV }
export default V2ProductSVCV
