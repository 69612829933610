import React from 'react'
//import parsePhoneNumber from 'libphonenumber-js'

class SingleLineAddress extends React.Component {
  render() {
    const { address = {}, className = '' } = this.props || {}
    const {
      firstName = '',
      lastName = '',
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state = '',
      country = '',
      zip = '',
      email = '',
      phone = '',
    } = address

    // to convert phone number to international format
    let phoneNumberCode = ''
    if (phone && phone != null) {
      const phoneNumberCode = async function (phone) {
        const { parsePhoneNumber } = await import('libphonenumber-js')
        parsePhoneNumber(phone)?.formatInternational() || ''
      }
    }

    // end to convert phone number to international format

    return (
      <span
        data-testid="qa-address"
        className={`item-level-address ${className}`}>
        <div className="fname-bold-shiping">{`${
          firstName === null ? '' : firstName
        } ${lastName === null ? '' : lastName}`}</div>
        <span className="fname-normal-shiping">{`${addressLine1},${
          addressLine2 ? ` ${addressLine2},` : ''
        } ${city}, ${state}, ${country}, ${zip}`}</span>
        <div className="fname-normal-shiping to-lower">{`${email || ''}`}</div>
        {phoneNumberCode && (
          <div className="fname-normal-shiping">{phoneNumberCode}</div>
        )}
      </span>
    )
  }
}

export { SingleLineAddress }
