import React, { useEffect, useState } from 'react'
import { Col, InputGroup, Row } from 'react-bootstrap'
import { IoIosClose } from 'react-icons/io'
import { i18nTranslate } from 'src/utils'
import { customerContainer } from 'src/models'
import { APPConfig } from 'config/appConfig'

const EmailNotVerified = props => {
  let [isToEnableEmailVerification, setEmailVerification] = useState(false)
  useEffect(() => {
    isToEnableEmailVerification =
      APPConfig?.getAppConfig()?.enableEmailVerification == 'true' || false
    setEmailVerification(isToEnableEmailVerification)
  }, [])

  const className = props.isInvalid ? 'is-invalid' : ''

  const sendEmailNotification = e => {
    e.stopPropagation()
    e.preventDefault()
    if (e.code === 'Enter') {
      customerContainer.emailNotification()
    }
    if (e.type === 'click') {
      customerContainer.emailNotification()
    }
  }

  const isEmailVerifiedFlag =
    customerContainer?.profileResponse?.customProperties
      ?.isEmailVerifiedFlag === 'true' || false

  if (!isToEnableEmailVerification) {
    return ''
  }



  return (
    <InputGroup className={(className, 'checkValBox flex-column')}>
      <IoIosClose className="checkNotVal" />
      <Row className="dTextInvalid flex-column">
        <Col>
          {isEmailVerifiedFlag
            ? i18nTranslate('emailVerified', 'Email is verified')
            : i18nTranslate('emailNotVerified', 'Email is not verified')}
        </Col>
        <Col className="ancher-default-accounts email-notverified">
          <div
            tabIndex="0"
            onKeyPress={e => {
              sendEmailNotification(e)
            }}
            onClick={() => customerContainer.emailNotification()}>
            {isEmailVerifiedFlag
              ? i18nTranslate('emailVerifiedLink', 'Already Verified')
              : i18nTranslate(
                  'emailNotVerifiedLink',
                  'Click here to verify your email'
                )}
          </div>
        </Col>
      </Row>
    </InputGroup>
  )
}

export { EmailNotVerified }
