import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker'
import { FaChevronDown as DownArrow } from 'react-icons/fa'
import {
  IoIosArrowDropleft as LeftArrow,
  IoIosArrowDropright as RightArrow,
} from 'react-icons/io'
import { i18nTranslate, getLocaleCodeFromUrl, IS_BROWSER } from 'src/utils'
import { isPastDateV2, subscriptionBlackOutDatesV2 } from 'src/deps'
import './styles.scss'

@observer
class DatePickerV2 extends React.Component {
  @observable defaultSelectedDateCalendar = new Date()
  defaultDate = new Date().getDate()
  defaultMonth = new Date().getMonth()
  defaultYear = new Date().getFullYear()
  minDate = new Date()
  maxDate = new Date()
  usedLanguage = ''
  tabLoopRef = React.createRef()

  async componentDidMount() {
    const { minDate, maxDate } = this.props
    const today = new Date()
    today.setDate(today.getDate() + 1)
    this.defaultSelectedDateCalendar =
      this.props?.selectedDateCalendar || minDate || today
    const date = new Date()
    const month = date.getMonth()
    const year = date.getFullYear()
    const lastDate = new Date(year, month + 1, 0)
    this.minDate = minDate ? minDate : today
    this.maxDate = maxDate ? maxDate : lastDate
    const { enUS, es, fr, vi, zhCN } = await import('date-fns/locale')
    if (IS_BROWSER) {
      let locale = getLocaleCodeFromUrl() || ''
      locale = locale?.toLowerCase()?.split('_')

      this.usedLanguage =
        locale[0] === 'zh'
          ? zhCN
          : locale[0] === 'en'
          ? enUS
          : locale[0] === 'fr'
          ? fr
          : locale[0] === 'es'
          ? es
          : locale[0] === 'vi'
          ? vi
          : enUS
    }
    registerLocale(`${this.usedLanguage}`, this.usedLanguage)
    setDefaultLocale(this.usedLanguage)
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps?.minDate?.getDate() !== this.props?.minDate?.getDate() ||
      prevProps?.maxDate?.getDate() !== this.props?.maxDate?.getDate() ||
      prevProps?.selectedDateCalendar !== this.props?.selectedDateCalendar
    ) {
      const { minDate, maxDate } = this.props
      this.defaultSelectedDateCalendar =
        this.props?.selectedDateCalendar || minDate
    }
  }

  handleChangeCalendar = timeStamp => {
    const { handleChangeCalendar } = this.props
    handleChangeCalendar(timeStamp)
  }
  renderCustomInput = () => {
    const {
      isOneTime = false,
      includeDateOnly = [],
      UTCclock = false,
    } = this.props
    const day = UTCclock
      ? this.defaultSelectedDateCalendar.getUTCDate()
      : this.defaultSelectedDateCalendar.getDate()
    let ordinalDate = date => {
      return (
        date +
        (date % 10 == 1 && date != 11
          ? 'st'
          : date % 10 == 2 && date != 12
          ? 'nd'
          : date % 10 == 3 && date != 13
          ? 'rd'
          : 'th')
      )
    }
    const displayDate =
      isOneTime && includeDateOnly.length === 0 ? (
        <div className="no-date">
          {i18nTranslate('subscription.noSelectedDates', 'no selected dates')}
        </div>
      ) : (
        ordinalDate(day)
      )
    return (
      <div className="custom-header-section">
        <div
          className="custom-date-header-section d-flex justify-content-between"
          data-testid="qa-date-picker-v2">
          <div className="custom-date d-flex flex-column">
            <span className="date-picker-title">
              {i18nTranslate('subscription.ProcessingDate', 'Processing Day')}
            </span>
            <span className="date">{displayDate}</span>
          </div>
          <div className="custom-edit-icon">
            <DownArrow />
          </div>
        </div>
      </div>
    )
  }

  renderHeader = ({ decreaseMonth, increaseMonth, date }) => {
    const { disableShowNextMonth = true } = this.props
    const monthName = date.toLocaleString(this.usedLanguage?.code, {
      month: 'long',
    })
    const month = date.getMonth()
    const year = date.getFullYear()
    const firstDate = new Date(year, month, 1)
    const lastDate = new Date(year, month + 1, 0)
    const enableNextMonth = lastDate < this.maxDate
    const enablePreviousMonth = firstDate > this.minDate
    return (
      <div className="date-picker-header-container">
        <div>
          {enablePreviousMonth && (
            <LeftArrow
              role="button"
              onClick={() => decreaseMonth()}
              size={25}
              className="ml-3"
            />
          )}
        </div>
        <span className="date-picker-current-month">
          {monthName} {year}
        </span>
        <div>
          {disableShowNextMonth && enableNextMonth && (
            <RightArrow
              role="button"
              onClick={() => increaseMonth()}
              size={25}
              className="ml-3"
            />
          )}
        </div>
      </div>
    )
  }

  /**
   * Checks if a given date is disabled based on a list of processed dates.
   *
   * @param {Array<Date>} subscriptionProcessedDates - An array of processed dates.
   * @param {Date} date - The date to check.
   * @returns {number} The date of the disabled date, or an empty string if the date is not disabled.
   */
  getAlreadyProcessedDates = (subscriptionProcessedDates = [], date = '') => {
    let disableAlreadyProcessedDates = subscriptionProcessedDates?.find(
      processedDate => {
        return (
          processedDate?.getFullYear() === date?.getFullYear() &&
          processedDate?.getMonth() === date?.getMonth() &&
          processedDate?.getDate() === date?.getDate()
        )
      }
    )
    return disableAlreadyProcessedDates?.getDate() || ''
  }

  checkDisableCurrentMonthUnprocessedDatesInNextMonth = date => {
    const { availableOrderDates = [] } = this.props

    const isNextMonthDate = date.getMonth() === new Date().getMonth() + 1

    if (isNextMonthDate) {
      const currentMonthDate = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        date.getDate()
      )
      // if (!isPastDateV2(currentMonthDate)) return true
      if (availableOrderDates?.length) {
        const isCurrentMonthDatePresentInHighlightDates =
          availableOrderDates?.some(
            item =>
              item?.getDate() === currentMonthDate?.getDate() &&
              item?.getMonth() === currentMonthDate?.getMonth()
          )
        return !isCurrentMonthDatePresentInHighlightDates
      }
    }
    return true
  }

  filterDate = (date = '') => {
    const {
      includeDateOnly = [],
      subscriptionProcessedDates = [],
      highlightDates = [],
      isOneTime = false,
      disableCurrentMonthUnprocessedDatesInNextMonth = false,
    } = this.props
    const disableAlreadyProcessedDates =
      this.getAlreadyProcessedDates(subscriptionProcessedDates, date) || ''
    const day = date?.getDate() || ''

    let returnValue = true
    if (isOneTime && includeDateOnly.length === 0) {
      return false
    } else if (includeDateOnly && includeDateOnly?.length > 0) {
      returnValue = includeDateOnly?.some(
        availableDate =>
          availableDate?.getFullYear() === date?.getFullYear() &&
          availableDate?.getMonth() === date?.getMonth() &&
          availableDate?.getDate() === date?.getDate()
      )
    } else if (
      subscriptionBlackOutDatesV2?.includes(day) ||
      day === disableAlreadyProcessedDates
    ) {
      returnValue = false
    } else if (isPastDateV2(date)) {
      const isHighlightDate = highlightDates?.some(
        item =>
          item?.getDate() === date?.getDate() &&
          item?.getMonth() === date?.getMonth()
      )
      returnValue = isHighlightDate
    } else if (disableCurrentMonthUnprocessedDatesInNextMonth && returnValue) {
      // Disable current month unprocessed order dates in next month
      returnValue =
        this.checkDisableCurrentMonthUnprocessedDatesInNextMonth(date)
    }

    return returnValue
  }
  getExcludeDates = () => {
    const dates = subscriptionBlackOutDatesV2.map(day => {
      return new Date(this.defaultYear, this.defaultMonth, day)
    })
    return dates
  }

  onKeyDown = e => {
    if (e?.keyCode === 9 || e?.which === 9) {
      this.tabLoopRef?.current?.setOpen(false)
    } else if (e?.keyCode === 13 || e?.which === 13) {
      event.preventDefault()
      this.tabLoopRef?.current?.setOpen(true)
    }
  }
  render() {
    const {
      includeDateOnly = [],
      isOneTime = false,
      disableCurrentMonthUnprocessedDatesInNextMonth = false,
      disableTabIndex = 0,
    } = this.props
    let today = new Date()
    const excludeDates = this.getExcludeDates()
    const highlightDates = this.props?.highlightDates || []
    if (
      highlightDates &&
      highlightDates.some(
        item =>
          item.getDate() === today.getDate() &&
          item.getMonth() === today.getMonth()
      )
    ) {
      today = []
    }
    let selectedDate = ''
    if (isOneTime && includeDateOnly.length === 0) {
      selectedDate = null
    } else if (this.defaultSelectedDateCalendar > this.maxDate) {
      selectedDate = null
    } else {
      selectedDate = this.defaultSelectedDateCalendar
    }
    return (
      <div className="date-picker-container">
        <ReactDatePicker
          ref={this.tabLoopRef}
          onKeyDown={this.onKeyDown}
          tabIndex={disableTabIndex}
          preventOpenOnFocus={true}
          onClickOutside={e => e.preventDefault()}
          locale={this.usedLanguage}
          className="date-picker-version2"
          data-testid="qa-datepicker-input-container"
          popperClassName="datepicker-z-index"
          selected={selectedDate}
          onChange={this.handleChangeCalendar}
          excludeDates={[today]}
          minDate={this.minDate}
          maxDate={this.maxDate}
          filterDate={this.filterDate}
          customInput={this.renderCustomInput()}
          renderCustomHeader={this.renderHeader}
          monthsShown={1}
          highlightDates={highlightDates}
          formatWeekDay={nameOfDay => nameOfDay?.substr(0, 1)}
          dayClassName={day => {
            const isStrikeOut =
              (day < today &&
                !excludeDates?.find(item => {
                  return item?.getTime() == day?.getTime()
                })) ||
              (disableCurrentMonthUnprocessedDatesInNextMonth &&
                !this.checkDisableCurrentMonthUnprocessedDatesInNextMonth(day))

            return isStrikeOut ? 'strikethrough-date' : undefined
          }}
        />
      </div>
    )
  }
}
export default DatePickerV2
export { DatePickerV2 }
