import { i18nTranslate } from 'src/utils'
import { dateFormatDeps } from 'src/deps'
import {
  modifyAndGetCustomerType,
  modifyAndGetAccountType,
  checkCustomerTypeEligibility,
  getLiveEventStatus,
  convertToBoolean,
} from 'src/utils'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { customerContainer, cartContainer } from 'src/models'
import { productDeps } from 'src/deps'

class ProductStatusDeps {
  getComponentLevelInventoryStatusForLooping = (
    product = {},
    currentlySelectedQty = 1
  ) => {
    const inventoryNodeOfSku =
      product?.selectedSku?.inventory?.toLowerCase() || ''
    const productStatusNodeInsidePropertiesOfSku =
      product?.selectedSku?.properties?.productStatus || ''

    // product?.properties?.quantity has the qty of the subproduct
    // we need to multiply with current incrementor value
    // and then compare it with the stock

    const productCount =
      currentlySelectedQty * (product?.properties?.quantity || 1)

    const inventoryProperties = product?.selectedSku?.inventoryProperties || {}
    const atpQty = inventoryProperties.atpQty || ''
    let outOfStockThreshold = inventoryProperties.outOfStockThreshold || ''
    let backOrderedQty = inventoryProperties.backOrderedQty || ''

    let messageToDisplay = ''
    let errorTypeForLooping = ''

    if (
      productStatusNodeInsidePropertiesOfSku.toLowerCase() ==
        'preview product' ||
      productStatusNodeInsidePropertiesOfSku.toLowerCase() == 'preview'
    ) {
      messageToDisplay = i18nTranslate(
        'bundle.preview',
        'Preview product and will be launched soon',
        {
          nameSpace: 'ssr-resource',
        }
      )
      errorTypeForLooping = 'subProductPreviewError'
    } else if (
      productStatusNodeInsidePropertiesOfSku?.toLowerCase() === 'discontinued'
    ) {
      messageToDisplay = i18nTranslate(
        'bundle.discontinuedV2Message',
        'Discontinued - This product or promotion has been discontinued',
        {
          nameSpace: 'ssr-resource',
        }
      )
      errorTypeForLooping = 'subProductDiscontinuedError'
    } else if (inventoryNodeOfSku?.toLowerCase() === 'unavailable') {
      messageToDisplay = i18nTranslate('coming.Soon', 'Coming soon..', {
        nameSpace: 'ssr-resource',
      })
      errorTypeForLooping = 'subProductUnavailableComingSoonError'
    } else if (inventoryNodeOfSku?.toLowerCase() === 'out of stock') {
      messageToDisplay = i18nTranslate('cart.outOfStock', 'Out of stock', {
        nameSpace: 'ssr-resource',
      })
      // for out of stock, subscription will be enabled
      if (
        productState.currentlySelectedPriceOption == 'one-time-price-button'
      ) {
        errorTypeForLooping = 'subProductOutOfStockError'
      }
    } else if (product?.selectedSku?.properties?.dangerousGoods === 'true') {
      messageToDisplay = i18nTranslate(
        'pdp.dangerousGoodsError',
        'Dangerous Goods cannot be added to Cart or Subscribed',
        {
          nameSpace: 'ssr-resource',
        }
      )
      errorTypeForLooping = 'subProductDangerousGoodsError'
    } else if (
      (atpQty <= 0 && backOrderedQty <= 0) ||
      inventoryNodeOfSku?.toLowerCase() === 'unavailable'
    ) {
      messageToDisplay = i18nTranslate(
        'item.notAvailable',
        'Item currently not available',
        {
          nameSpace: 'ssr-resource',
        }
      )
      errorTypeForLooping = 'subProductItemNotAvailableError'
    } else {
      if (productCount > atpQty - outOfStockThreshold) {
        const backorderSubProductStatus = this.getBackOrderInfo({
          productCount,
          atpQty,
          outOfStockThreshold,
          inventoryProperties,
          backOrderedQty,
          currentlySelectedQty,
          isFromSubProduct: true,
        })
        return {
          ...backorderSubProductStatus,
        }
      }
    }

    return {
      errorTypeForLooping,
      messageToDisplay,
    }
  }

  loopingSubProductsForError = (product = {}, currentlySelectedQty = 1) => {
    const { status = '', productStatus = '' } = product
    const currentSubProducts =
      productDeps.getCurrentBundleDataForInventoryCheck(product)

    const subProductInventoryDataForLooping =
      currentSubProducts?.map((subProduct, index) => {
        return this.getComponentLevelInventoryStatusForLooping(
          subProduct,
          currentlySelectedQty
        )
      }) || []
    // loop through sub product errors based on priority
    const isAllSkusEligible = subProductInventoryDataForLooping?.every(
      skuDetail => skuDetail?.errorTypeForLooping === ''
    )
    if (isAllSkusEligible) {
      return {}
    } else {
      const subProductOutOfStockError =
        subProductInventoryDataForLooping?.find(
          skuDetail =>
            skuDetail?.errorTypeForLooping === 'subProductOutOfStockError'
        ) || false
      const subProductDangerousGoodsError =
        subProductInventoryDataForLooping?.find(
          skuDetail =>
            skuDetail?.errorTypeForLooping === 'subProductDangerousGoodsError'
        ) || false

      const subProductUnavailableComingSoonError =
        subProductInventoryDataForLooping?.find(
          skuDetail =>
            skuDetail?.errorTypeForLooping ===
            'subProductUnavailableComingSoonError'
        ) || false

      const subProductDiscontinuedError =
        subProductInventoryDataForLooping?.find(
          skuDetail =>
            skuDetail?.errorTypeForLooping === 'subProductDiscontinuedError'
        ) || false

      const subProductItemNotAvailableError =
        subProductInventoryDataForLooping?.find(
          skuDetail =>
            skuDetail?.errorTypeForLooping === 'subProductItemNotAvailableError'
        ) || false

      const subProductPreviewError =
        subProductInventoryDataForLooping?.find(
          skuDetail =>
            skuDetail?.errorTypeForLooping === 'subProductPreviewError'
        ) || false

      if (subProductOutOfStockError) {
        return {
          disableBundleKitOneTime: true,
          disableBundleKitSubscription: false,
          // for out of stock, subscription will be enabled
          messageToDisplay: subProductOutOfStockError.messageToDisplay,
        }
      } else if (subProductItemNotAvailableError) {
        return {
          disableBundleKitOneTime: true,
          disableBundleKitSubscription: true,
          messageToDisplay: subProductItemNotAvailableError.messageToDisplay,
        }
      } else if (subProductUnavailableComingSoonError) {
        return {
          disableBundleKitOneTime: true,
          disableBundleKitSubscription: true,
          messageToDisplay:
            subProductUnavailableComingSoonError.messageToDisplay,
        }
      } else if (subProductDiscontinuedError) {
        return {
          disableBundleKitOneTime: true,
          disableBundleKitSubscription: true,
          messageToDisplay: subProductDiscontinuedError.messageToDisplay,
        }
      } else if (subProductDangerousGoodsError) {
        return {
          disableBundleKitOneTime: true,
          disableBundleKitSubscription: true,
          messageToDisplay: subProductDangerousGoodsError.messageToDisplay,
        }
      } else if (subProductPreviewError) {
        return {
          disableBundleKitOneTime: true,
          disableBundleKitSubscription: true,
          messageToDisplay: subProductPreviewError.messageToDisplay,
        }
      } else {
        // check back order as last one
        // check for error first
        // and then check for green message

        // find the farthest backorder date among all the sub product which are backorder
        // example:
        //  Sub product A is backorder with date 10 Aug
        //  Sub product B is backorder with date 12 Aug
        // 12 is the farthest date, so show message below button as Backordered till {12 Aug}
        // set inventoryStatusView with the subProductLevelBackOrderMessage from the sub product which has the farthest backorder date

        const subProductBackOrderExhausted_requestedQtyNotAvailable =
          subProductInventoryDataForLooping?.find(
            skuDetail =>
              skuDetail?.errorTypeForLooping ===
              'backOrderExhausted_requestedQtyNotAvailable'
          ) || false

        const subProductBackOrderGreenMessages =
          subProductInventoryDataForLooping?.filter(
            skuDetail => skuDetail?.isGreenBackOrderMessage
          ) || []

        if (subProductBackOrderExhausted_requestedQtyNotAvailable) {
          const backorderData = {
            disableBundleKitOneTime:
              subProductBackOrderExhausted_requestedQtyNotAvailable.disableOneTime ||
              false,
            disableBundleKitSubscription:
              subProductBackOrderExhausted_requestedQtyNotAvailable.disableSubscription ||
              false,
            // ^ changes based on currently selected price button
            // so using from disableSubscription getBackOrderInfo
            messageToDisplay:
              subProductBackOrderExhausted_requestedQtyNotAvailable.messageToDisplay ||
              '',
            isGreenBackOrderMessage:
              subProductBackOrderExhausted_requestedQtyNotAvailable.isGreenBackOrderMessage ||
              '',
          }
          return backorderData
        } else if (subProductBackOrderGreenMessages.length) {
          let farthestBackOrderDate = 0
          let farthestBackOrderMessage =
            subProductBackOrderGreenMessages[0]?.messageToDisplay || ''

          subProductBackOrderGreenMessages.forEach(inventory => {
            if (
              inventory.backOrderTimeForFarthestComparison &&
              farthestBackOrderDate <
                inventory.backOrderTimeForFarthestComparison
            ) {
              farthestBackOrderDate =
                inventory.backOrderTimeForFarthestComparison
              farthestBackOrderMessage = inventory.messageToDisplay || ''
            }
          })
          if (farthestBackOrderDate && farthestBackOrderMessage) {
            return {
              messageToDisplay: farthestBackOrderMessage,
              isGreenBackOrderMessage: true,
            }
          }
        }

        const backOrderBasedInventories =
          subProductInventoryDataForLooping.filter(
            skuDetail => skuDetail.shouldShowBackOrderDateMessage || false
          ) || []
        let farthestBackOrderDate = 0
        let farthestBackOrderMessage =
          backOrderBasedInventories[0]?.subProductLevelBackOrderMessage || ''

        backOrderBasedInventories.forEach(inventory => {
          if (
            inventory.backorderTime &&
            farthestBackOrderDate < inventory.backorderTime
          ) {
            farthestBackOrderDate = inventory.backorderTime
            farthestBackOrderMessage =
              inventory.subProductLevelBackOrderMessage || ''
          }
        })
        if (farthestBackOrderDate && farthestBackOrderMessage) {
          inventoryStatusView = farthestBackOrderMessage
        }
      }
    }

    return {
      disableBundleKitOneTime: false,
      disableBundleKitSubscription: false,
      messageToDisplay: '',
    }
  }

  getBackOrderInfo = props => {
    const {
      productCount = 0,
      // ^ multiplied qty for subproduct/ currentqty for normal pdts
      atpQty = 0,
      outOfStockThreshold = 0,
      inventoryProperties,
      backOrderedQty,
      currentlySelectedQty,
      // from input box for subproduct message
      isFromSubProduct = false,
    } = props || {}
    let messageToDisplay = ''
    let currentDateTimeStamp = Date.now()
    let backorderTime = ''
    let disableOneTime = false
    let disableSubscription = false
    let disableOptionalProductAddToCart = false
    let isGreenBackOrderMessage = false
    let errorTypeForLooping = false
    let backOrderTimeForFarthestComparison = ''
    if (
      inventoryProperties?.expectedBackOrderAvailabilityDate?.toString?.()
        ?.length <= 10
    ) {
      backorderTime =
        inventoryProperties.expectedBackOrderAvailabilityDate + '000'
    } else {
      backorderTime = inventoryProperties.expectedBackOrderAvailabilityDate
    }
    backorderTime = Number(backorderTime)
    /**
     * @Note
     * EQH-94 - Back order date shows up incorrectly on EQ
     * Changed to UTC Format, as per BackEnd Team Elangovan's Request
     */
    let { date, month, year } = dateFormatDeps.convertTimeStampToDate(
      backorderTime,
      true
    )

    const requestedQtyNotAvailable = i18nTranslate(
      'pdp.OOSQtyTxt',
      'OUT OF STOCK',
      {
        nameSpace: 'ssr-resource',
      }
    )

    // @##backorder_logic_pdp_message_or_button

    // scenario #1:
    // when atpQty is 0, when outOfStockThreshold is 2, productCount is 10
    // qtyExpectedFromBackorder = 10
    //
    // scenario #2:
    // when atpQty is 3, when outOfStockThreshold is 2, productCount is 10
    // qtyExpectedFromBackorder = 10 - ( 3 - 2 )
    // qtyExpectedFromBackorder = 9

    if (backOrderedQty > 0) {
      let qtyExpectedFromBackorder =
        atpQty > outOfStockThreshold
          ? productCount - (atpQty - outOfStockThreshold)
          : productCount
      const isValidFutureBackOrderDate = backorderTime >= currentDateTimeStamp
      const isValidDate =
        productState.currentlySelectedPriceOption == 'one-time-price-button'
          ? isValidFutureBackOrderDate
          : true
      // as per https://nuskin.atlassian.net/browse/INP-1
      // date is not considered for subscribe button
      if (isValidDate && qtyExpectedFromBackorder <= backOrderedQty) {
        messageToDisplay =
          i18nTranslate('backordered', 'Ships by', {
            nameSpace: 'ssr-resource',
          }) +
          ' ' +
          `${month}/${date}/${year}`

        isGreenBackOrderMessage = true
        backOrderTimeForFarthestComparison = backorderTime
      } else {
        disableOneTime = true
        disableSubscription = true
        disableOptionalProductAddToCart = true
        messageToDisplay = requestedQtyNotAvailable
        errorTypeForLooping = 'backOrderExhausted_requestedQtyNotAvailable'
      }
    } else {
      disableOneTime = true
      disableSubscription = true
      disableOptionalProductAddToCart = true
      messageToDisplay = requestedQtyNotAvailable
      errorTypeForLooping = 'backOrderExhausted_requestedQtyNotAvailable'
    }
    return {
      errorTypeForLooping,
      backOrderTimeForFarthestComparison,
      messageToDisplay,
      disableOneTime,
      disableSubscription,
      isGreenBackOrderMessage,
      disableOptionalProductAddToCart,
    }
  }

  shouldShowExclusiveErrorBasedOnCartQuantity = (productId, productCount) => {
    const cartItems = cartContainer?.cartResponse?.items || []
    let maxProductQty
    const productQualificationResponse =
      customerContainer?.qualificationResponse?.qualification?.filter(p => {
        return p?.productId === productId
      })
    if (
      productQualificationResponse &&
      productQualificationResponse.length > 0
    ) {
      if (cartItems && cartItems.length > 0) {
        cartItems.forEach(item => {
          if (item?.skus?.[0]?.productId == productId) {
            maxProductQty =
              productQualificationResponse?.[0]?.quantity - item?.quantity
          }
        })
        if (maxProductQty <= 0 || productCount > maxProductQty) {
          return true
        }
      }
    }
    return false
  }

  getV2SubProductComponentLevelInventoryStatus = (
    product = {},
    currentlySelectedQty = 1,
    isOptional = false,
    currentlyClickedOptionalSkuId = ''
  ) => {
    let selectedSku = ''
    if (isOptional) {
      selectedSku = product.sku?.find(
        sku => sku?.identifier == currentlyClickedOptionalSkuId
      )
    }
    if (!selectedSku) {
      selectedSku = product?.selectedSku || {}
    }
    const inventoryNodeOfSku = selectedSku.inventory?.toLowerCase() || ''
    const productStatusNodeInsidePropertiesOfSku =
      selectedSku.properties?.productStatus || ''

    // product?.properties?.quantity has the qty of the subproduct
    // we need to multiply with current incrementor value
    // and then compare it with the stock

    const productCount =
      currentlySelectedQty * (product?.properties?.quantity || 1)

    const inventoryProperties = selectedSku?.inventoryProperties || {}

    const atpQty = inventoryProperties.atpQty || ''
    let outOfStockThreshold = inventoryProperties.outOfStockThreshold || ''
    let backOrderedQty = inventoryProperties.backOrderedQty || ''

    let messageToDisplay = ''
    let disableOptionalProductAddToCart = false
    switch (productStatusNodeInsidePropertiesOfSku.toLowerCase()) {
      case 'test':
        messageToDisplay = i18nTranslate(
          'bundle.testSku',
          'This is a test sku',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
      case 'replacement':
        messageToDisplay = i18nTranslate(
          'bundle.replacement',
          'This is a replacement product and not available for purchase',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOptionalProductAddToCart = true
        break
      case 'preview product':
      case 'preview':
        messageToDisplay = i18nTranslate(
          'bundle.preview',
          'Preview product and will be launched soon',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOptionalProductAddToCart = true
        break
      case 'stopped':
        messageToDisplay = i18nTranslate(
          'bundle.stopped',
          'This product is temporarily stopped',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOptionalProductAddToCart = true
        break
      case 'discontinued':
        messageToDisplay = i18nTranslate(
          'bundle.discontinuedV2Message',
          'Discontinued - This product or promotion has been discontinued',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOptionalProductAddToCart = true
        break
    }
    const dangerousGoods = selectedSku?.properties?.dangerousGoods === 'true'

    if (messageToDisplay === '') {
      if (inventoryNodeOfSku?.toLowerCase() === 'unavailable') {
        messageToDisplay = i18nTranslate('coming.Soon', 'Coming soon..', {
          nameSpace: 'ssr-resource',
        })
        disableOptionalProductAddToCart = true
      } else if (inventoryNodeOfSku?.toLowerCase() === 'out of stock') {
        messageToDisplay = i18nTranslate('cart.outOfStock', 'Out of stock', {
          nameSpace: 'ssr-resource',
        })
        // for out of stock, subscription will be enabled
        if (
          productState.currentlySelectedPriceOption == 'one-time-price-button'
        ) {
          disableOptionalProductAddToCart = true
        }
      } else if (dangerousGoods) {
        messageToDisplay = i18nTranslate(
          'pdp.dangerousGoodsError',
          'Dangerous Goods cannot be added to Cart or Subscribed',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOptionalProductAddToCart = true
      } else if (atpQty <= 0 && backOrderedQty <= 0) {
        messageToDisplay = i18nTranslate(
          'item.notAvailable',
          'Item currently not available',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOptionalProductAddToCart = true
      } else {
        if (productCount > atpQty - outOfStockThreshold) {
          const backorderSubProductStatus = this.getBackOrderInfo({
            productCount,
            atpQty,
            outOfStockThreshold,
            inventoryProperties,
            backOrderedQty,
            currentlySelectedQty,
            isFromSubProduct: true,
          })
          return {
            ...backorderSubProductStatus,
          }
        }
      }
    }

    return {
      messageToDisplay,
      disableOptionalProductAddToCart,
    }
  }

  getBundleKitProductLevelInventoryStatus = (
    product = {},
    currentlySelectedQty = 1
  ) => {
    // check for product level
    // also loop through subproduct level

    const productLevelProductStatusNode =
      product?.properties?.productStatus || ''

    //  note the difference in status vs productStatus
    const productLevelStatusNode = product?.properties?.status || ''

    const productLevelInventoryStatusNode =
      product?.properties?.inventoryStatus?.toLowerCase() || ''

    const productCount = currentlySelectedQty

    let messageToDisplay = ''
    let disableBundleKitOneTime = false
    let disableBundleKitSubscription = false
    /* product level  check */
    if (
      productLevelStatusNode.toLowerCase() == 'active' &&
      productLevelProductStatusNode.toLowerCase() == 'test'
    ) {
      messageToDisplay = i18nTranslate('bundle.testSku', 'This is a test sku', {
        nameSpace: 'ssr-resource',
      })
    }
    if (productLevelProductStatusNode.toLowerCase() == 'discontinued') {
      // as per discussion on 08/04/2024 with poovarsan and vinoth
      // for discontinued "productStatus", we should not check "status"
      messageToDisplay = i18nTranslate(
        'bundle.discontinuedV2Message',
        'Discontinued - This product or promotion has been discontinued',
        {
          nameSpace: 'ssr-resource',
        }
      )
      disableBundleKitOneTime = true
      disableBundleKitSubscription = true
    }

    if (productLevelProductStatusNode.toLowerCase() == 'replacement') {
      // for replacement status alone,
      // in old view,
      // we haven't checked whether productLevelStatusNode is inactive
      // so adding extra code here to replicate the same behaviors"
      messageToDisplay = i18nTranslate(
        'bundle.replacement',
        'This is a replacement product and not available for purchase',
        {
          nameSpace: 'ssr-resource',
        }
      )
      disableBundleKitOneTime = true
      disableBundleKitSubscription = true
    }

    if (productLevelStatusNode.toLowerCase() == 'inactive') {
      switch (productLevelProductStatusNode.toLowerCase()) {
        case 'replacement':
          messageToDisplay = i18nTranslate(
            'bundle.replacement',
            'This is a replacement product and not available for purchase',
            {
              nameSpace: 'ssr-resource',
            }
          )
          disableBundleKitOneTime = true
          disableBundleKitSubscription = true
          break
        case 'preview product':
        case 'preview':
          messageToDisplay = i18nTranslate(
            'bundle.preview',
            'Preview product and will be launched soon',
            {
              nameSpace: 'ssr-resource',
            }
          )

          disableBundleKitOneTime = true
          disableBundleKitSubscription = true
          break
        case 'stopped':
          messageToDisplay = i18nTranslate(
            'bundle.stopped',
            'This product is temporarily stopped',
            {
              nameSpace: 'ssr-resource',
            }
          )
          disableBundleKitOneTime = true
          disableBundleKitSubscription = true
          break
        /*
            for a bundle/kit, 
            default sku of mandatory products are looped
            and inactive status is set with productStatus "sellable"

            so below code was causing issue
            issue: bundle status not changing after changing variant of mandatory product
            
            hence commenting this
        */
        /*case 'sellable':
          messageToDisplay = i18nTranslate(
            'bundle.discontinuedV2Message',
            'Discontinued - This product or promotion has been discontinued',
            {
              nameSpace: 'ssr-resource',
            }
          )

          disableBundleKitOneTime = true
          disableBundleKitSubscription = true
          break */
      }
    }
    const dangerousGoods = product?.properties?.dangerousGoods === 'true'

    const eligibleCustomerTypeFromProductResponse =
      modifyAndGetCustomerType(product?.properties?.customerTypes) || ''
    let currentUserAccountType =
      modifyAndGetAccountType(
        customerContainer?.profileResponse?.accounts?.accountType
      ) || ''

    const isEligibleCustomerType = checkCustomerTypeEligibility(
      eligibleCustomerTypeFromProductResponse,
      currentUserAccountType
    )
    if (!isEligibleCustomerType) {
      messageToDisplay = i18nTranslate(
        'pdp.userEligible',
        'This user is not eligible to purchase this product',
        {
          nameSpace: 'ssr-resource',
        }
      )
      disableBundleKitOneTime = true
      disableBundleKitSubscription = true
    }

    if (messageToDisplay === '') {
      if (productLevelInventoryStatusNode?.toLowerCase() === 'unavailable') {
        messageToDisplay = i18nTranslate('coming.Soon', 'Coming soon..', {
          nameSpace: 'ssr-resource',
        })

        disableBundleKitOneTime = true
        disableBundleKitSubscription = true
      } else if (
        productLevelInventoryStatusNode?.toLowerCase() === 'out of stock'
      ) {
        messageToDisplay = i18nTranslate('cart.outOfStock', 'Out of stock', {
          nameSpace: 'ssr-resource',
        })
        disableBundleKitOneTime = true
        disableBundleKitSubscription = false
        // for out of stock, subscription will be enabled
      } else if (dangerousGoods) {
        messageToDisplay = i18nTranslate(
          'pdp.dangerousGoodsError',
          'Dangerous Goods cannot be added to Cart or Subscribed',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableBundleKitOneTime = true
        disableBundleKitSubscription = true
      }
    }
    /* end of productlevel  check */
    /* if there is not product level error, then check subproducts */
    if (messageToDisplay) {
      return {
        messageToDisplay,
        disableBundleKitOneTime,
        disableBundleKitSubscription,
      }
    } else {
      // if there is no product level error message
      // then check for subproduct level
      // return {}
      return this.loopingSubProductsForError(product, currentlySelectedQty)
    }
  }

  getV2IndividualProductInventoryStatus = (
    product = {},
    currentlySelectedQty = 1
  ) => {
    const selectedSku =
      product?.selectedSku ||
      productDeps.getUserSelectedSku({
        sku: product?.sku,
        skuId: product?.skuId,
      })

    const inventoryNodeOfSku = selectedSku?.inventory?.toLowerCase() || ''
    const productStatusNodeInsidePropertiesOfSku =
      selectedSku?.properties?.productStatus || ''
    const productCount = currentlySelectedQty

    const inventoryProperties = selectedSku?.inventoryProperties || {}
    const atpQty = inventoryProperties.atpQty || ''
    let outOfStockThreshold = inventoryProperties.outOfStockThreshold || ''
    let backOrderedQty = inventoryProperties.backOrderedQty || ''

    let messageToDisplay = ''
    let disableOneTime = false
    let disableSubscription = false
    let dataTestId = ''

    let updatedProductPropertiesOfSku =
      productStatusNodeInsidePropertiesOfSku?.toLowerCase() || ''

    // Replaced eventbundleonly to bundleOnly for live events storefront
    if (
      convertToBoolean(getLiveEventStatus()) &&
      updatedProductPropertiesOfSku == 'eventbundleonly'
    ) {
      updatedProductPropertiesOfSku = 'bundleonly'
    }

    switch (updatedProductPropertiesOfSku) {
      case 'test':
        messageToDisplay = i18nTranslate(
          'bundle.testSku',
          'This is a test sku',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
      case 'replacement':
        messageToDisplay = i18nTranslate(
          'bundle.replacement',
          'This is a replacement product and not available for purchase',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOneTime = true
        disableSubscription = true
        break
      case 'preview product':
      case 'preview':
        messageToDisplay = i18nTranslate(
          'bundle.preview',
          'Preview product and will be launched soon',
          {
            nameSpace: 'ssr-resource',
          }
        )

        disableOneTime = true
        disableSubscription = true
        break
      case 'stopped':
        messageToDisplay = i18nTranslate(
          'bundle.stopped',
          'This product is temporarily stopped',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOneTime = true
        disableSubscription = true
        break
      case 'discontinued':
        messageToDisplay = i18nTranslate(
          'bundle.discontinuedV2Message',
          'Discontinued - This product or promotion has been discontinued',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOneTime = true
        disableSubscription = true
        break
      case 'bundleonly':
        messageToDisplay = i18nTranslate(
          'pdp.bundleOnly',
          'Item not available for individual purchase',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOneTime = true
        disableSubscription = true
        break
    }
    const dangerousGoods =
      selectedSku?.properties?.dangerousGoods === 'true' ||
      product?.properties?.dangerousGoods === 'true'

    const eligibleCustomerTypeFromProductResponse =
      modifyAndGetCustomerType(product?.customerTypes) || ''
    let currentUserAccountType =
      modifyAndGetAccountType(
        customerContainer?.profileResponse?.accounts?.accountType
      ) || ''

    // condition to check quantity limit for exclusive products based on cart items
    const isExclusive = selectedSku?.properties?.isExclusive || ''

    if (isExclusive == 'true') {
      // https://nuskin.atlassian.net/browse/BRW-2624
      // to be handled for bundles and kits later, not handled in old design too
      if (!customerContainer.isRegisterUser) {
        // disable exclusive product for guest user
        disableOneTime = true
        disableSubscription = true
      } else {
        const { isItemEligible } = productState.getQualificationMessage({
          productId: product.productId,
          isExclusive,
        })
        if (!isItemEligible) {
          disableOneTime = true
          disableSubscription = true
        }
      }
    }
    if (
      isExclusive == 'true' &&
      this.shouldShowExclusiveErrorBasedOnCartQuantity(
        product.productId,
        productCount
      )
    ) {
      messageToDisplay = i18nTranslate(
        'pdp.userEligible',
        'This user is not eligible to purchase this product',
        {
          nameSpace: 'ssr-resource',
        }
      )
      disableOneTime = true
      disableSubscription = true
      // https://nuskin.atlassian.net/browse/BRW-2624
      // to be handled later
    }

    const isEligibleCustomerType = checkCustomerTypeEligibility(
      eligibleCustomerTypeFromProductResponse,
      currentUserAccountType
    )
    if (!isEligibleCustomerType) {
      messageToDisplay = i18nTranslate(
        'pdp.userEligible',
        'This user is not eligible to purchase this product',
        {
          nameSpace: 'ssr-resource',
        }
      )
      disableOneTime = true
      disableSubscription = true
    }

    if (messageToDisplay === '') {
      if (inventoryNodeOfSku?.toLowerCase() === 'unavailable') {
        messageToDisplay = i18nTranslate('coming.Soon', 'Coming soon..', {
          nameSpace: 'ssr-resource',
        })

        disableOneTime = true
        disableSubscription = true
        dataTestId = 'qa-pdp-coming-soon-message'
      } else if (inventoryNodeOfSku?.toLowerCase() === 'out of stock') {
        messageToDisplay = i18nTranslate('cart.outOfStock', 'Out of stock', {
          nameSpace: 'ssr-resource',
        })
        disableOneTime = true
        dataTestId = 'qa-pdp-oos-message'
        // disableSubscription = true
        // if OOS, we need not disable subscription
      } else if (dangerousGoods) {
        messageToDisplay = i18nTranslate(
          'pdp.dangerousGoodsError',
          'Dangerous Goods cannot be added to Cart or Subscribed',
          {
            nameSpace: 'ssr-resource',
          }
        )
        disableOneTime = true
        disableSubscription = true
        dataTestId = 'qa-pdp-dangerous-good-message'
      } else if (atpQty <= 0 && backOrderedQty <= 0) {
        messageToDisplay = i18nTranslate(
          'item.notAvailable',
          'Item currently not available',
          {
            nameSpace: 'ssr-resource',
          }
        )

        disableOneTime = true
        disableSubscription = true
      } else {
        if (productCount > atpQty - outOfStockThreshold) {
          return this.getBackOrderInfo({
            productCount,
            atpQty,
            outOfStockThreshold,
            inventoryProperties,
            backOrderedQty,
            currentlySelectedQty,
          })
        }
      }
    }

    return {
      messageToDisplay,
      disableOneTime,
      disableSubscription,
      dataTestId,
    }
  }

  getV2ProductLevelInventoryStatus = (
    product = {},
    currentlySelectedQty = 1
  ) => {
    if (product.type == 'bundle' || product.type == 'kit') {
      const inventoryData = this.getBundleKitProductLevelInventoryStatus(
        product,
        currentlySelectedQty
      )
      return inventoryData
    } else {
      const inventoryData = this.getV2IndividualProductInventoryStatus(
        product,
        currentlySelectedQty
      )
      return inventoryData
    }
  }
}

const productStatusDeps = new ProductStatusDeps()
export default productStatusDeps
export { productStatusDeps }
