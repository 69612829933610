import { i18nTranslate } from 'src/utils'

const subscriptionType = (
  isFromPDP = false,
  isToHideMonthlyFrequency = false
) => {
  let dropdownOptions = []
  let monthlyFrequency = [
    {
      label: i18nTranslate('subscription.monthLabel', 'Every month'),
      value: '1-MONTHS',
      isMonthly: true,
    },
    {
      label: i18nTranslate('subscription.twoMonth', 'Every 2 Months'),
      value: '2-MONTHS',
    },
    {
      label: i18nTranslate('subscription.threeMonth', 'Every 3 Months'),
      value: '3-MONTHS',
    },
  ]
  if (!isToHideMonthlyFrequency) {
    dropdownOptions.push(...monthlyFrequency)
  }
  if (!isFromPDP) {
    dropdownOptions.push({
      label: i18nTranslate('subscription.oneTime', 'One-time item'),
      value: '1-MONTHS',
      isOneTime: true,
    })
  }
  return dropdownOptions
}

export { subscriptionType }
