import { CommonContainer } from 'src/models/Common'
import { observable } from 'mobx'
import { pageNames } from 'src/routes/pathParams'
import {
  getLocaleCodeFromUrl,
  i18nTranslate,
  isExpressCheckout,
  trackErrorInInstana,
} from 'src/utils'
import { APPConfig } from 'config/appConfig'
import {
  cartContainer,
  checkoutContainer,
  storeContainer,
  sessionContainer,
  customerContainer,
  loyaltyContainer,
} from 'src/models'
import { toastState, overlayState } from 'src/views/components'
import { IS_BROWSER } from 'src/utils/application'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'

class ApplePayContainer extends CommonContainer {
  @observable applepayToken = ''
  @observable applepayInitiate = false
  @observable applepayClientCreated = false
  @observable submitOrderEnable = false
  @observable applepayCheckoutInstance = {}
  @observable isToShowApplePayConfirmationModal = false
  @observable isExpressPayment = false
  applePayVersion = APPConfig?.getAppConfig()?.defaultApplePayVersion || 3
  closeConfirmationModalOnTimeout = null
  submitOrderProps = {}

  /**
   * Checks if the current device supports Apple Pay.
   * @returns {boolean} `true` if the device supports Apple Pay, `false` otherwise.
   */
  checkIfDeviceSupportsApplePay = () => {
    if (IS_BROWSER && window?.ApplePaySession) {
      // const userAgent = window?.navigator?.userAgent?.toLowerCase()
      // const matchesArr = userAgent?.match(/mac.*version\/([0-9\.]*)/i)
      // const deviceVersion = matchesArr ? parseInt?.(matchesArr[1]) : undefined

      /*
       * Now checked supportsVersion() for version 3 as a minimum version
       * as some deviceVersion taken from userAgent is not supporting ApplePay
       */
      trackErrorInInstana({
        errorData: window?.ApplePaySession?.supportsVersion?.(
          applepayContainer.applePayVersion
        ),
        errorReport: 'Apple Pay - Is Default Version Supported',
      })
      trackErrorInInstana({
        errorData: window?.ApplePaySession?.canMakePayments?.(),
        errorReport: 'Apple Pay - canMakePayments',
      })
      return (
        window?.ApplePaySession?.supportsVersion?.(
          applepayContainer.applePayVersion
        ) && window?.ApplePaySession?.canMakePayments?.()
      )
    }
    return false
  }

  /**
   * Submits an Apple Pay order and handles the response.
   *
   * @param {object} props - The props passed to the function.
   * @returns {void}
   */
  reviewApplePayOrder = async props => {
    applepayContainer.submitOrderProps = props
    overlayState.toggleLoader()

    let reviewResponse = await checkoutContainer.reviewOrder()
    trackErrorInInstana({
      errorData: reviewResponse,
      errorReport: 'Apple Pay - Review Order Response',
    })

    if (checkoutContainer.isSuccessResponse(reviewResponse)) {
      overlayState.toggleLoader()
      if (customerContainer?.isRegisterUser) {
        const sessionResponse = await sessionContainer.refreshSession()
        trackErrorInInstana({
          errorData: sessionResponse,
          errorReport: 'Apple Pay - Session Response',
        })
        if (sessionContainer.isSuccessResponse(sessionResponse)) {
          //Show Apple Pay Confirmation Modal because of apple pay session async/await issue
          applepayContainer.handleApplePayPopupRedirection()
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'checkout.paymentGeneralError',
              'Sorry, we were unable to process your payment. Please try again.'
            )
          )
          applepayContainer.handleError(sessionResponse)
        }
      } else {
        // Show confirmation modal for Guest checkout - CX17-11758
        applepayContainer.handleApplePayPopupRedirection()
      }
    } else {
      toastState.setToastMessage(reviewResponse?.errorMessage)
      overlayState.toggleLoader()
      applepayContainer.handleError(reviewResponse)
    }
  }

  handleApplePayPopupRedirection = () => {
    applepayContainer.isToShowApplePayConfirmationModal = true

    //Modal gets closed if no activity from the user after 1min
    this.closeConfirmationModalOnTimeout = setTimeout(() => {
      applepayContainer.isToShowApplePayConfirmationModal = false
    }, 60000)
    //Trigger close modal manually after 1min
    window.addEventListener('click', () => {
      clearTimeout(this.closeConfirmationModalOnTimeout)
    })
  }

  handleApplePayModalClose = () => {
    applepayContainer.isToShowApplePayConfirmationModal = false
    const errorMessage = {
      errorcode: 'applepay_canceled',
      errorMessage: 'Apple Pay Confirmation Popup Canceled',
    }
    applepayContainer.handleError(errorMessage)
  }

  /**
   * Creates an Apple Pay payment client instance using the provided Braintree client instance.
   *
   * @param {?BraintreeClient} clientInstance - The Braintree client instance to use for creating the Apple Pay payment client.
   * @returns {void}
   */
  createApplePayPaymentClient = (clientInstance = null) => {
    if (
      typeof braintree !== 'undefined' &&
      typeof clientInstance !== 'undefined'
    )
      // Create a ApplePay Checkout component.
      try {
        braintree?.applePay?.create(
          {
            client: clientInstance,
          },
          async function (applePayErr, applePayInstance) {
            if (applePayErr) {
              overlayState.toggleLoader()
              applepayContainer.handleError(applePayErr)
              return
            }
            applepayContainer.applepayCheckoutInstance = applePayInstance
            applepayContainer.submitOrderEnable = true
            trackErrorInInstana({
              errorData: applePayInstance,
              errorReport:
                'Apple Pay - Submit Order Button Enabled with applePayInstance',
            })
          }
        )
      } catch (e) {
        applepayContainer.handleError(e)
      }
  }

  getPaymentRequest = countryCode => {
    const currencyCode = countryCode === 'US' ? 'USD' : 'CAD'
    const supportedNetworks =
      countryCode === 'US'
        ? ['visa', 'mastercard', 'discover', 'amex']
        : ['visa', 'mastercard', 'amex']

    const merchantCapabilities = [
      'supports3DS',
      'supportsCredit',
      'supportsDebit',
    ]
    const promoCodes =
      cartContainer?.cartResponse?.promotionDetails?.codes || []
    const locale =
      getLocaleCodeFromUrl()?.split('_')?.[1]?.toUpperCase() || 'US'
    const supportedCountries =
      localStorage?.getItem('accountType')?.includes('Retail Customer') ||
      !customerContainer?.isRegisterUser
        ? [locale]
        : ['US', 'CA']

    return this.isExpressPayment
      ? {
          countryCode: countryCode,
          currencyCode: currencyCode,
          supportedNetworks: supportedNetworks,
          merchantCapabilities: merchantCapabilities,
          supportedCountries: supportedCountries,
          shippingMethods: [],
          supportsCouponCode: true,
          couponCode: promoCodes?.[0]?.code || '',
          total: {
            label: 'Total',
            type: 'final',
            amount:
              cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid?.toString(),
          },
          requiredShippingContactFields: [
            'postalAddress',
            'name',
            'phone',
            'email',
          ],
          requiredBillingContactFields: ['postalAddress'],
        }
      : {
          countryCode: countryCode,
          currencyCode: currencyCode,
          supportedNetworks: supportedNetworks,
          merchantCapabilities: merchantCapabilities,
          merchantCapabilities: merchantCapabilities,
          total: {
            label: 'Apple Pay Payment',
            type: 'final',
            amount:
              cartContainer?.cartResponse?.paymentValue?.totalAmountPaid?.toString(),
          },
          requiredBillingContactFields: ['postalAddress'],
        }
  }

  /**
   * Creates an Apple Pay payment request object.
   *
   * @param {object} applePayInstance - An instance of the Apple Pay API.
   * @returns {object} An Apple Pay payment request object.
   */
  createApplePayPaymentRequest = (applePayInstance = null) => {
    const countryCode =
      storeContainer.storeIDValue?.toLowerCase() === 'canada' ? 'CA' : 'US'
    const paymentRequest = applepayContainer.getPaymentRequest(countryCode)
    return applePayInstance.createPaymentRequest(paymentRequest)
  }

  /**
   * Creates an Apple Pay payment session and sets up the necessary handlers.
   *
   * @param {?ApplePayInstance} applePayInstance - An optional instance of the ApplePayInstance class.
   * @returns {void}
   * @throws {Error} - If an error occurs during the creation or setup of the Apple Pay payment session.
   */
  createApplePayPaymentSession = (applePayInstance = null) => {
    try {
      const paymentRequest =
        applepayContainer.createApplePayPaymentRequest(applePayInstance)
      trackErrorInInstana({
        errorData: paymentRequest,
        errorReport: 'Apple Pay - Payment Request Data',
      })
      // Create Session when a valid paymentRequest is received
      var session = new ApplePaySession(
        applepayContainer.applePayVersion,
        paymentRequest
      ) //first argument will be the apple pay version
      applepayContainer.validateApplePayMerchant(session, applePayInstance)
      applepayContainer.authorApplePayPayment(session, applePayInstance)
    } catch (e) {
      applepayContainer.handleError(e)
    }
  }

  /**
   * Validates the Apple Pay merchant session.
   *
   * @param {object} [session=null] - The Apple Pay session object.
   * @param {object} [applePayInstance=null] - The Apple Pay instance.
   * @returns {void}
   */
  validateApplePayMerchant = (session = null, applePayInstance = null) => {
    try {
      session.onvalidatemerchant = function (event) {
        applePayInstance.performValidation(
          {
            validationURL: event.validationURL,
            displayName: 'Apple Pay Merchant',
          },
          function (err, merchantSession) {
            if (err) {
              toastState.setToastMessage(
                i18nTranslate(
                  'checkout.paymentGeneralError',
                  'Sorry, we were unable to process your payment. Please try again.'
                )
              )
              overlayState.toggleLoader()
              applepayContainer.handleError(err)
              return
            }
            session.completeMerchantValidation(merchantSession)
          }
        )
      }
      overlayState.toggleLoader()
    } catch (e) {
      applepayContainer.handleError(e)
    }
  }

  getLineItems = () => {
    const priceFacet = cartContainer?.cartResponse?.value?.priceFacets
    const userRole = customerContainer.profileResponse?.userrole || ''

    const displayItems = [
      {
        label: 'Subtotal',
        type: 'final',
        amount:
          cartContainer?.cartResponse?.value?.priceAfterMarkdown?.toString(),
      },
      {
        label: 'Tax',
        type: 'final',
        amount: cartContainer?.cartResponse?.value?.overAllTax?.toString(),
      },
      {
        label: 'Shipping',
        type: 'final',
        amount:
          cartContainer?.cartResponse?.value?.totalDeliveryCharge?.toString(),
      },
      {
        label: 'Discounts',
        type: 'final',
        amount: cartContainer?.cartResponse?.value?.overAllDiscount?.toString(),
      },
    ]

    if (
      customerContainer?.isRegisterUser &&
      localStorage.getItem('accountType')?.includes('Brand Affiliate')
    ) {
      displayItems.push({
        label: 'Total SV',
        type: 'final',
        amount: priceFacet?.PV?.PVAfterDiscount?.toString() || 0.0,
      })
    }
    if (
      customerContainer?.isRegisterUser &&
      userRole !== 'ROLE_ACCOUNT_BUYER_ADMIN'
    ) {
      displayItems.push({
        label: 'Total CV',
        type: 'final',
        amount: priceFacet?.CV?.CVAfterDiscount?.toString() || 0.0,
      })
    }

    return displayItems
  }

  getTotalUnpaidAmount = () => {
    return {
      label: 'Total',
      type: 'final',
      amount:
        cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid?.toString(),
    }
  }

  /**
   * Authorizes an Apple Pay payment session and handles the payment token.
   *
   * @param {ApplePaySession} session - The Apple Pay session object.
   * @param {ApplePayPaymentRequest} applePayInstance - The Apple Pay payment request instance.
   * @returns {void}
   */
  authorApplePayPayment = (session = null, applePayInstance = null) => {
    try {
      if (applepayContainer.isExpressPayment) {
        session.onshippingcontactselected = async function (event) {
          const shippingDetails = event.shippingContact
          const profileDetail = customerContainer?.profileResponse
          const formData = {
            firstName: shippingDetails?.givenName || profileDetail?.firstName,
            lastName: shippingDetails?.familyName || profileDetail?.lastName,
            email: shippingDetails?.givenName || profileDetail.email,
            phone: shippingDetails?.givenName || profileDetail.phoneNumber,
            addressLine1: shippingDetails?.locality,
            country: shippingDetails?.countryCode,
            city: shippingDetails?.administrativeArea,
            state: shippingDetails?.administrativeArea,
            zip: shippingDetails?.postalCode,
          }
          await checkoutContainer.setShippingAddress(formData)
          // Update shipping methods based on the selected address
          const updatedShippingMethods =
            cartContainer?.cartResponse?.deliveryDetails?.methods?.map(
              method => {
                return {
                  label: method.name,
                  amount: method.cost,
                  identifier: method.id,
                  detail: method.name,
                }
              }
            )
          session.completeShippingContactSelection({
            newShippingMethods: updatedShippingMethods,
            newLineItems: applepayContainer.getLineItems(),
            newTotal: applepayContainer.getTotalUnpaidAmount(),
          })
        }

        session.onshippingmethodselected = async function (event) {
          await checkoutContainer.updateShippingMethod(
            event.shippingMethod.identifier
          )
          session.completeShippingMethodSelection({
            newLineItems: applepayContainer.getLineItems(),
            newTotal: applepayContainer.getTotalUnpaidAmount(),
          })
        }
      }

      session.onpaymentauthorized = function (event) {
        // Send Apple Pay token to your server to process the payment.
        applePayInstance.tokenize(
          {
            token: event.payment.token,
          },
          async function (tokenizeErr, payload) {
            if (tokenizeErr) {
              toastState.setToastMessage(
                i18nTranslate(
                  'checkout.paymentGeneralError',
                  'Sorry, we were unable to process your payment. Please try again.'
                )
              )
              //PUR-173  Token generation error in Apple Pay recorded with different instana
              trackErrorInInstana({
                errorData: tokenizeErr,
                errorReport:
                  'Apple Pay - Tokenize Error for nonce token generation',
              })
              session.completePayment(ApplePaySession.STATUS_FAILURE)
              return
            }

            if (!isExpressCheckout()) {
              payload.postalCode =
                event?.payment?.billingContact?.postalCode || ''
              // Send payload.nonce to your server.
              applepayContainer.handleSubmitOrder(payload)
              //For billingAddress needs to be checked later

              // After you have transacted with the payload.nonce,
              // call `completePayment` to dismiss the Apple Pay sheet.
              session.completePayment(ApplePaySession.STATUS_SUCCESS)
              return
            }

            try {
              if (applepayContainer.isExpressPayment) {
                const paymentData = event.payment.shippingContact
                const formData = {
                  firstName: paymentData.givenName,
                  lastName: paymentData.familyName,
                  email: paymentData?.emailAddress,
                  phone: paymentData?.phoneNumber,
                  addressLine1: paymentData?.addressLines?.[0],
                  country: paymentData?.countryCode,
                  city: paymentData?.locality,
                  state:
                    paymentData?.administrativeArea ||
                    paymentData?.subLocality ||
                    paymentData?.locality,
                  zip: paymentData?.postalCode,
                }
                await checkoutContainer.setShippingAddress(formData)
                await checkoutContainer.setCommunicationPreference(
                  cartContainer?.cartResponse?.deliveryDetails?.address || {}
                )
                const bagValue = Math.abs(
                  cartContainer?.cartResponse?.paymentValue
                    ?.totalAmountUnpaid || ''
                )
                const postData = [
                  {
                    name: 'applepay',
                    status: 'active',
                    type: 'APPLEPAY',
                    amount: bagValue,
                    isValid: true,
                  },
                ]
                await checkoutContainer.addAlternatePayment(postData)
              }
              payload.postalCode =
                event?.payment?.billingContact?.postalCode || ''
              // Send payload.nonce to your server.
              applepayContainer.handleSubmitOrder(payload)
              session.completePayment(ApplePaySession.STATUS_SUCCESS)
            } catch (error) {
              console.log('error', error)
              session.completePayment(ApplePaySession.STATUS_FAILURE)
            }
          }
        )
      }
      session.oncouponcodechanged = async function (event) {
        console.log('couponcodechanged', event)
        const existingCoupens =
          cartContainer?.cartResponse?.promotionDetails?.codes?.map(
            promoCode => promoCode?.code
          ) || []
        const error = []
        if (event.couponCode) {
          const coupenResponse = await checkoutContainer.addPromo(
            event.couponCode
          )
          if (!checkoutContainer.isSuccessResponse(coupenResponse)) {
            error.push(new ApplePayError('couponCodeInvalid'))
          }
          await cartContainer.viewCart()
          const updatedPayload = {
            newLineItems: applepayContainer.getLineItems(),
            newTotal: applepayContainer.getTotalUnpaidAmount(),
          }
          if (error?.length > 0) {
            updatedPayload['errors'] = error
          }
          session.completeCouponCodeChange(updatedPayload)
        } else if (existingCoupens?.length) {
          cartContainer?.cartResponse?.promotionDetails?.codes
          const coupenResponse = await checkoutContainer.deletePromo(
            existingCoupens[0]
          )
          await cartContainer.viewCart()
          const updatedPayload = {
            newLineItems: applepayContainer.getLineItems(),
            newTotal: applepayContainer.getTotalUnpaidAmount(),
          }
          session.completeCouponCodeChange(updatedPayload)
        } else {
          session.completeCouponCodeChange({})
        }
      }
      /**
       * Handles the cancellation of an Apple Pay payment session.
       *
       * This function is called when the user cancels the Apple Pay payment session.
       * It sets a toast message to indicate that the payment was cancelled and toggles
       * the loader state to hide the loader.
       */
      session.oncancel = function (event) {
        const message = i18nTranslate(
          'checkout.applepayCancelMessage',
          'Your Apple Pay payment request was successfully cancelled. Please select a payment option and try again.'
        )
        toastState.setToastMessage(message)
        overlayState.toggleLoader()
        applepayContainer.handleError(event)
      }
      trackErrorInInstana({
        errorData: session,
        errorReport: 'ApplePay - sessions call made',
      })
      session?.begin() //interchanged as per braintree doc - to show the Payment Sheet
    } catch (e) {
      applepayContainer.handleError(e)
    }
  }

  /**
   * Handles the submission of an order, including updating the order status, displaying a success message, and redirecting the user to the order confirmation page.
   *
   * @param {Object} [payload=null] - An optional payload object containing the nonce token for the Apple Pay transaction.
   * @returns {Promise<void>} - A Promise that resolves when the order submission process is complete.
   */
  handleSubmitOrder = async (payload = null) => {
    overlayState?.toggleLoader()
    const submitOrderResponse = await checkoutContainer.submitOrder({
      properties: {
        nonceToken: payload?.nonce || '',
        postalCode: payload?.postalCode || '',
      },
    })
    if (checkoutContainer.isSuccessResponse(submitOrderResponse)) {
      await toastState.setToastMessage(
        i18nTranslate('order.submitSuccess', 'Your order has been submitted.'),
        true
      )
      trackErrorInInstana({
        errorData: submitOrderResponse,
        errorReport: 'ApplePay - Order Placed Successfully',
      })

      if (typeof window !== 'undefined') {
        localStorage?.setItem('paymentSuccess', submitOrderResponse?.orderId)
        overlayState?.toggleLoader()
        let isNativeApp = checkIsNativeApp() || false
        if (applepayContainer.submitOrderProps?.history) {
          if (isNativeApp) {
            applepayContainer.submitOrderProps?.history?.push?.(
              `${pageNames.orderConfirmation}?isNativeApp=true`
            )
          } else {
            applepayContainer.submitOrderProps?.history?.push?.(
              pageNames.orderConfirmation
            )
          }
          applepayContainer.applepayInitiate = false
          applepayContainer.submitOrderEnable = false
          applepayContainer.applepayClientCreated = false
          overlayState?.toggleLoader()
          await cartContainer.viewCart()
          await loyaltyContainer.getLoyaltyPoints()
        } else {
          const urlMatch = [
            pageNames.checkout,
            pageNames.expressCheckout,
            pageNames.expressPDP,
          ].find(page => window?.location?.href?.includes(page))
          const arr = window?.location?.href?.split(urlMatch)
          if (isNativeApp) {
            window?.location?.replace?.(
              `${arr[0]}${pageNames.orderConfirmation}?isNativeApp=true`
            )
          } else {
            window?.location?.replace?.(
              `${arr[0]}${pageNames.orderConfirmation}`
            )
          }
        }
      }
    } else {
      overlayState?.toggleLoader()
      toastState.setToastMessage(
        //submitOrderResponse?.message || submitOrderResponse?.responseMessage
        i18nTranslate(
          'checkout.paymentGeneralError',
          'Sorry, we were unable to process your payment. Please try again.'
        )
      )

      applepayContainer.handleError(submitOrderResponse)
      overlayState?.toggleLoader()
    }
  }

  /**
   * Handles errors that occur during the Apple Pay payment process.
   *
   * This function is called when an error occurs during the Apple Pay payment process.
   * It logs the error to the console, deletes the payment from the checkout container,
   * and resets the state of the Apple Pay container.
   *
   * @param {?any} errorResponse - The error response object, if available.
   * @returns {Promise<void>} - A Promise that resolves when the error handling is complete.
   */
  handleError = async (errorResponse = null) => {
    trackErrorInInstana({
      errorData: errorResponse,
      errorReport: 'Apple Pay - Payment failed',
    })
    await checkoutContainer.deletePayment()
    await cartContainer.viewCart()
    applepayContainer.applepayInitiate = false
    applepayContainer.applepayClientCreated = false
    applepayContainer.submitOrderEnable = false
    checkoutContainer.activePath = 'payment'
  }

  /**
   * Initializes the ApplePay payment process.
   *
   * @param {Error|null} clientErr - An optional error that occurred when creating the ApplePay client.
   * @param {Object|null} clientInstance - An optional instance of the ApplePay client.
   * @returns {Promise<void>} - A Promise that resolves when the ApplePay payment process is initialized.
   */
  initializeApplePayPayment = async (
    clientErr = null,
    clientInstance = null
  ) => {
    /**
     * @description
     * Handle errors when creating ApplePay client
     */
    if (clientErr && IS_BROWSER) {
      applepayContainer.handleError(clientErr)
    }
    // Create ApplePay payment client with clientInstance
    applepayContainer.createApplePayPaymentClient(clientInstance)
  }
}

const applepayContainer = new ApplePayContainer()
export { applepayContainer }
export default applepayContainer
