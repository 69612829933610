import { i18nTranslate } from 'src/utils'

export const geographyJson = (isToSendAsCA = false) => {
  return {
    country: [
      {
        label: i18nTranslate('country.default', 'Select Your Country'),
        value: '',
      },
      {
        label: i18nTranslate('country.US', 'US'),
        value: 'US',
      },
      {
        label: i18nTranslate('country.canada', 'Canada'),
        value: isToSendAsCA ? 'CA' : 'Canada',
      },
      // {
      //   label: i18nTranslate('country.china', 'China'),
      //   value: 'China',
      // },
      // {
      //   label: i18nTranslate('country.Kuwait', 'Kuwait'),
      //   value: 'Kuwait',
      // },
    ],
    states: {
      US: [
        {
          label: i18nTranslate('state.default', 'Select Your State'),
          value: '',
        },
        {
          label: i18nTranslate('state.Alabama', 'Alabama'),
          value: 'AL',
        },
        {
          label: i18nTranslate('state.Alaska', 'Alaska'),
          value: 'AK',
        },
        {
          label: i18nTranslate('state.AmericanSamoa', 'American Samoa'),
          value: 'AS',
        },
        {
          label: i18nTranslate('state.Arizona', 'Arizona'),
          value: 'AZ',
        },
        {
          label: i18nTranslate('state.Arkansas', 'Arkansas'),
          value: 'AR',
        },
        {
          label: i18nTranslate('state.California', 'California'),
          value: 'CA',
        },
        {
          label: i18nTranslate('state.Colorado', 'Colorado'),
          value: 'CO',
        },
        {
          label: i18nTranslate('state.Connecticut', 'Connecticut'),
          value: 'CT',
        },
        {
          label: i18nTranslate('state.Delaware', 'Delaware'),
          value: 'DE',
        },
        {
          label: i18nTranslate(
            'state.FederatedStatesofMicronesia',
            'Federated States of Micronesia'
          ),
          value: 'FM',
        },
        {
          label: i18nTranslate('state.Florida', 'Florida'),
          value: 'FL',
        },
        {
          label: i18nTranslate('state.Georgia', 'Georgia'),
          value: 'GA',
        },
        {
          label: i18nTranslate('state.Guam', 'Guam'),
          value: 'GU',
        },
        {
          label: i18nTranslate('state.Hawaii', 'Hawaii'),
          value: 'HI',
        },
        {
          label: i18nTranslate('state.Idaho', 'Idaho'),
          value: 'ID',
        },
        {
          label: i18nTranslate('state.Illinois', 'Illinois'),
          value: 'IL',
        },
        {
          label: i18nTranslate('state.Indiana', 'Indiana'),
          value: 'IN',
        },
        {
          label: i18nTranslate('state.Iowa', 'Iowa'),
          value: 'IA',
        },
        {
          label: i18nTranslate('state.Kansas', 'Kansas'),
          value: 'KS',
        },
        {
          label: i18nTranslate('state.Kentucky', 'Kentucky'),
          value: 'KY',
        },
        {
          label: i18nTranslate('state.Louisiana', 'Louisiana'),
          value: 'LA',
        },
        {
          label: i18nTranslate('state.Maine', 'Maine'),
          value: 'ME',
        },
        {
          label: i18nTranslate('state.Marshall-Islands', 'Marshall Islands'),
          value: 'MH',
        },
        {
          label: i18nTranslate('state.Maryland', 'Maryland'),
          value: 'MD',
        },
        {
          label: i18nTranslate('state.Massachusetts', 'Massachusetts'),
          value: 'MA',
        },
        {
          label: i18nTranslate('state.Michigan', 'Michigan'),
          value: 'MI',
        },
        {
          label: i18nTranslate('state.Minnesota', 'Minnesota'),
          value: 'MN',
        },
        {
          label: i18nTranslate('state.Mississippi', 'Mississippi'),
          value: 'MS',
        },
        {
          label: i18nTranslate('state.Missouri', 'Missouri'),
          value: 'MO',
        },
        {
          label: i18nTranslate('state.Montana', 'Montana'),
          value: 'MT',
        },
        {
          label: i18nTranslate('state.Nebraska', 'Nebraska'),
          value: 'NE',
        },
        {
          label: i18nTranslate('state.Nevada', 'Nevada'),
          value: 'NV',
        },
        {
          label: i18nTranslate('state.New-Hampshire', 'New Hampshire'),
          value: 'NH',
        },
        {
          label: i18nTranslate('state.New-Jersey', 'New Jersey'),
          value: 'NJ',
        },
        {
          label: i18nTranslate('state.New-Mexico', 'New Mexico'),
          value: 'NM',
        },
        {
          label: i18nTranslate('state.New-York', 'New York'),
          value: 'NY',
        },
        {
          label: i18nTranslate(
            'state.Northern-Mariana-Islands',
            'Northern Mariana Islands'
          ),
          value: 'MP',
        },
        {
          label: i18nTranslate('state.North-Carolina', 'North Carolina'),
          value: 'NC',
        },
        {
          label: i18nTranslate('state.North-Dakota', 'North Dakota'),
          value: 'ND',
        },
        {
          label: i18nTranslate('state.Ohio', 'Ohio'),
          value: 'OH',
        },
        {
          label: i18nTranslate('state.Oklahoma', 'Oklahoma'),
          value: 'OK',
        },
        {
          label: i18nTranslate('state.Oregon', 'Oregon'),
          value: 'OR',
        },
        {
          label: i18nTranslate('state.Palau', 'Palau'),
          value: 'PW',
        },
        {
          label: i18nTranslate('state.Pennsylvania', 'Pennsylvania'),
          value: 'PA',
        },
        {
          label: i18nTranslate('state.PuertoRico', 'Puerto Rico'),
          value: 'PR',
        },
        {
          label: i18nTranslate('state.Rhode Island', 'Rhode Island'),
          value: 'RI',
        },
        {
          label: i18nTranslate('state.South-Carolina', 'South Carolina'),
          value: 'SC',
        },
        {
          label: i18nTranslate('state.South-Dakota', 'South Dakota'),
          value: 'SD',
        },
        {
          label: i18nTranslate('state.Tennessee', 'Tennessee'),
          value: 'TN',
        },
        {
          label: i18nTranslate('state.Texas', 'Texas'),
          value: 'TX',
        },
        {
          label: i18nTranslate('state.Utah', 'Utah'),
          value: 'UT',
        },
        {
          label: i18nTranslate(
            'state.U.S Armed Forces-Americas',
            'U.S Armed Forces-Americas'
          ),
          value: 'AA',
        },
        {
          label: i18nTranslate(
            'state.U.S Armed Forces-Europe',
            'U.S Armed Forces-Europe'
          ),
          value: 'AE',
        },
        {
          label: i18nTranslate(
            'state.U.S Armed Forces-Pacific',
            'U.S Armed Forces-Pacific'
          ),
          value: 'AP',
        },
        {
          label: i18nTranslate('state.Vermont', 'Vermont'),
          value: 'VT',
        },
        {
          label: i18nTranslate('state.Virginia', 'Virginia'),
          value: 'VA',
        },
        {
          label: i18nTranslate('state.Washington', 'Washington'),
          value: 'WA',
        },
        {
          label: i18nTranslate('state.WashingtonDC', 'Washington DC'),
          value: 'DC',
        },
        {
          label: i18nTranslate('state.West-Virginia', 'West Virginia'),
          value: 'WV',
        },
        {
          label: i18nTranslate('state.Wisconsin', 'Wisconsin'),
          value: 'WI',
        },
        {
          label: i18nTranslate('state.Wyoming', 'Wyoming'),
          value: 'WY',
        },
      ],
      CA: [
        {
          label: i18nTranslate('state.default', 'Select Your State'),
          value: '',
        },
        {
          label: i18nTranslate('state.Alberta', 'Alberta'),
          value: 'AB',
        },
        {
          label: i18nTranslate('state.British-Columbia', 'British Columbia'),
          value: 'BC',
        },
        {
          label: i18nTranslate('state.Manitoba', 'Manitoba'),
          value: 'MB',
        },
        {
          label: i18nTranslate('state.New-Brunswick', 'New Brunswick'),
          value: 'NB',
        },
        {
          label: i18nTranslate(
            'state.Newfoundland-and-Labrador',
            'Newfoundland and Labrador'
          ),
          value: 'NL',
        },
        {
          label: i18nTranslate('state.Nova-Scotia', 'Nova Scotia'),
          value: 'NS',
        },
        {
          label: i18nTranslate('state.Ontario', 'Ontario'),
          value: 'ON',
        },
        {
          label: i18nTranslate(
            'state.Prince-Edward-Island',
            'Prince Edward Island'
          ),
          value: 'PE',
        },
        {
          label: i18nTranslate('state.Quebec', 'Quebec'),
          value: 'QC',
        },
        {
          label: i18nTranslate('state.Saskatchewan', 'Saskatchewan'),
          value: 'SK',
        },
        {
          label: i18nTranslate(
            'state.Northwest-Territories',
            'Northwest Territories'
          ),
          value: 'NT',
        },
        {
          label: i18nTranslate('state.Nunavut', 'Nunavut'),
          value: 'NU',
        },
        {
          label: i18nTranslate('state.Yukon', 'Yukon'),
          value: 'YT',
        },
      ],
    },
  }
}

export const countriesWithoutPostalCodes = ['Qatar', 'UAE']
export const countryCodes = ['QA', 'AE']
