import React from 'react'
import { i18nTranslate } from 'src/utils'
import { GLOBAL_PRIVACY_LINK } from 'src/static/fixtures/links'

class BASponsorMessage extends React.Component {
  RETAIL_CUSTOMER = 'Retail Customer'
  PREFERRED_MEMBER = 'Preferred Customer/Member'
  BRAND_AFFILIATE = 'Brand Affiliate'

  openDocument = documentLink => {
    if (typeof window !== 'undefined') {
      window.open(documentLink)
    }
  }

  messageToShow = acccount => {
    const SPONSORTOOLTIP = i18nTranslate(
      'signup.whatIsThisMessage',
      'A Brand Affiliate is a Nu Skin sales representative who sells Nu Skin products. If someone referred you to Nu Skin but you don’t know their ID, you can ask them for their number or for a special invite link to create your account. Nu Skin will link your account to the Brand Affiliate you identify, and they may reach out to you to provide product support based on your order history. Please see our '
    )

    const BASPONSORTOOLTIP = i18nTranslate(
      'signup.whatIsThisBAMessage',
      'A Brand Affiliate is a Nu Skin sales representative who sells Nu Skin® products. If someone referred you, enter their Nu Skin member ID here or ask them for a special link to create your account. We will link your account to the Brand Affiliate you identify, and they may contact you to provide product support based on your order history. See our '
    )

    if (this.props?.showBAMessage) {
      return BASPONSORTOOLTIP
    } else {
      return SPONSORTOOLTIP
    }
  }
  render() {
    const message = this.messageToShow(this.props?.account)
    return (
      <>
        {message}
        <a
          aria-label={message}
          name="link"
          id={
            this.props?.isFromSignup
              ? 'signupSponsorPrivacyLinkId'
              : 'accountSponsorPrivacyLinkId'
          }
          href={GLOBAL_PRIVACY_LINK}
          target="_blank"
          rel="noopener noreferrer"
          onClick={event => {
            event.preventDefault()
            this.openDocument(GLOBAL_PRIVACY_LINK)
          }}>
          {i18nTranslate('signup.privacyNotice', 'Privacy Notice')}
        </a>
        {this.props?.showBAMessage
          ? i18nTranslate('privacyMessage.moreInfo', ' for more info.')
          : i18nTranslate('privacyMessage.moreDetails', ' for more details.')}
      </>
    )
  }
}

export default BASponsorMessage
export { BASponsorMessage }
