import React from 'react'
import { Collapse, Button, Container } from 'react-bootstrap'
import { action, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import {
  IoIosArrowDown as DownwardArrowIcon,
  IoIosArrowUp as UpwardArrowIcon,
} from 'react-icons/io'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import './styles.scss'

@observer
class AccordionBlockPDP extends React.Component {
  @observable open = this.props?.isDefaultOpen || false
  static defaultProps = {
    title: 'submit',
    onClick: () => {},
    index: 1,
    isActive: '',
    hasArrow: true,
    disabled: false,
    isOutsideAccordion: false,
  }

  handleClick = event => {
    event.persist()
    // prop type for value not from text content
    const { isActive, index, onClick, type } = this.props
    const selectedFacet = event.currentTarget.textContent
    this.open = isActive !== '' ? !isActive : !this.open
    const eventData = {
      index,
      isOpen: this.open,
      selectedFacet,
      type,
    }
    onClick(eventData)
  }

  render() {
    const {
      isActive,
      dataTestId,
      title,
      hasArrow,
      children,
      isMerchPLP,
      renderIcon,
      disabled,
    } = this.props
    const isOpen = isActive !== '' ? isActive : this.open
    const isVisible = isMerchPLP ? (isOpen ? 'active' : 'disable') : ''
    const updateIcon = renderIcon ? (
      renderIcon(isOpen)
    ) : isOpen ? (
      <UpwardArrowIcon size={'2em'} aria-hidden={true} />
    ) : (
      <DownwardArrowIcon size={'2em'} aria-hidden={true} />
    )
    const className = this.props.className
      ? this.props.className
      : 'mb-2 px-2 py-3'

    if (isExpressCheckout()) {
      return (
        <div className="express-description-heading">
          <h5 className="express-description-heading-container font-weight-bold">
            {title}
          </h5>
          <div>{children}</div>
        </div>
      )
    }

    return (
      <>
        <div noGutters className={`accordion-block-wrapper ${className}`}>
          <div className="pdp-accordion-title accordion-title">
            <Container className="pdp-accordion-title-container">
              <Button
                data-testid={dataTestId}
                variant="light"
                disabled={disabled}
                className="py-2 px-md-0 accordion-block-title-button justify-content-between d-flex bg-transparent shadow-none border-0"
                size="lg"
                block
                onClick={this.handleClick}
                aria-expanded={this.open}>
                <div
                  className={`accordion-block-text ${isVisible} text-left text-capitalize ${this.props.textClass} text-break`}>
                  {title}
                </div>
                {hasArrow && (
                  <div
                    className="accordion-block-icon justify-content-end d-flex align-items-center"
                    data-testid={`${dataTestId}-icon`}>
                    {updateIcon}
                  </div>
                )}
              </Button>
            </Container>
          </div>
          {this.props.isOutsideAccordion === false && (
            <Collapse
              in={isOpen}
              className="accordion-block-collapse-section pdp-accordion w-100">
              <div id="collapse-text">{children}</div>
            </Collapse>
          )}
        </div>
        {this.props.isOutsideAccordion && (
          <Collapse
            timeout={300}
            in={isOpen}
            className="accordion-block-collapse-section pdp-accordion mt-2 w-100 mb-2 p-0">
            <div id="collapse-text" className="accordian-description">
              {children}
            </div>
          </Collapse>
        )}
      </>
    )
  }
}

export default AccordionBlockPDP
export { AccordionBlockPDP }
