import React from 'react'
import { InputGroup, Form } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import { APPConfig } from 'config/appConfig'
import { customerContainer } from 'src/models'
import { getInputFormJSON, isLATAM } from 'src/utils'
//import { AsYouType } from 'libphonenumber-js'

const MaskInput = props => {
  const locale = APPConfig?.getActiveAppConfig()?.defaultLocale //en_US
  const language = locale?.split('_')?.pop().toLowerCase() // us
  const isLatamRegion = isLATAM(locale)
  const commonConfigJSON = getInputFormJSON(locale) || {}
  const name = props?.name
  const phoneCountryCode = props?.values?.phoneCountryCode
  const isEnablePhoneNumberFormat =
    APPConfig?.getAppConfig()?.enablePhoneNumberFormat === 'true' || false
  let maskedValue = []
  const className = props?.isInvalid
    ? 'is-invalid mask-input-control'
    : props?.isValid
    ? 'is-valid mask-input-control'
    : 'mask-input-control'

  const asYouTypeInput = async event => {
    if (props?.verifyBtnLabel) {
      const verifyBtnLabelDom = document.querySelector(
        '[name="' + props?.verifyBtnLabel + '"]'
      )
      const parentDOM = verifyBtnLabelDom.parentNode
      if (event?.target?.value) {
        parentDOM.classList.remove('disable')
      } else {
        parentDOM.classList.add('disable')
      }
    }
    if (
      event?.nativeEvent?.inputType === 'deleteContentBackward' &&
      event?.target?.value?.length < 7
    ) {
      return false
    }
    const inputNumber = event?.target?.value
    const { AsYouType } = await import('libphonenumber-js')
    event.target.value = new AsYouType(phoneCountryCode)?.input(inputNumber)
    customerContainer.setVerifyPhone.phoneNumber = event?.target?.value
  }

  if (name === 'postalCode') {
    maskedValue = [/[a-z,A-Z]/, /\d/, /[a-z,A-Z]/, ' ', /\d/, /[a-z,A-Z]/, /\d/]
  } else if (name === 'expiryDate') {
    maskedValue = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]
  } else {
    if (language === 'us') {
      maskedValue =
        name === 'signupTaxId' ||
        name === 'businessTaxIdDropdown' ||
        name === 'taxId'
          ? [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
          : [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    } else if (language === 'ca') {
      maskedValue =
        name === 'signupTaxId' ||
        name === 'businessTaxIdDropdown' ||
        name === 'taxId'
          ? [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
          : [
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /[a-z,A-Z]/,
              /[a-z,A-Z]/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]
    } else if (language === 'pe') {
      maskedValue =
        name === 'signupTaxId' ||
        name === 'businessTaxIdDropdown' ||
        name === 'taxId'
          ? [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d?/]
          : [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    } else if (isLatamRegion) {
      maskedValue = commonConfigJSON?.maskValue || [
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]
    }
  }

  const maskedPossiblePhoneNames = [
    'phoneNumber',
    'mobilePhoneNumber',
    'businessPhoneNumber',
    'phone',
  ]

  return maskedPossiblePhoneNames?.includes(name) ? (
    isEnablePhoneNumberFormat ? (
      <InputGroup className={className}>
        {isLatamRegion ? (
          <MaskedInput mask={maskedValue} {...props} onInput={asYouTypeInput} />
        ) : (
          <Form.Control {...props} onInput={asYouTypeInput} />
        )}
      </InputGroup>
    ) : (
      <InputGroup className={className}>
        <Form.Control {...props} />
      </InputGroup>
    )
  ) : (
    <InputGroup className={className}>
      <MaskedInput mask={maskedValue} {...props} />
    </InputGroup>
  )
}

export { MaskInput }
