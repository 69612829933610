import React from 'react'
import { Col, InputGroup, Row } from 'react-bootstrap'
import { IoIosCheckmark } from 'react-icons/io'
import { i18nTranslate } from 'src/utils'

const EmailVerified = props => {
  const className = props.isInvalid ? 'is-invalid' : ''
  return (
    <InputGroup className={(className, 'checkValBox')}>
      <IoIosCheckmark className="checkVal" />
      <Row className="dTextValid">
        <Col className="fitToContent">
          {i18nTranslate('emailVerified', 'Email is Verified')}
        </Col>
      </Row>
    </InputGroup>
  )
}

export { EmailVerified }
