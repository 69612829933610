export const formInput = () => {
  return [
    {
      name: 'emailRecipients',
      type: 'textarea',
      columnType: {
        lg: '12',
      },
      isToHideLabel: true,
      placeholderKey: 'textAreaEmailPlaceholder',
      className: 'email-text-area',
      dataTestId: 'qa-email-recipients',
    },
    [
      {
        name: 'ShareSendMeCopy',
        type: 'checkbox',
        isToHideLabel: true,
        columnType: {
          lg: '6',
          md: '7',
          xs: '12',
        },
        dataTestId: 'qa-email-recipients',
      },
    ],
    [
      {
        name: 'buttonGroup',
        type: 'inlineSubmitButton',
        columnType: {
          lg: '12',
        },
      },
    ],
  ]
}
