import { i18nTranslate } from 'src/utils'

/**
 * Generates an array of industry option objects from the given accountTypes array.
 * Each option object contains a label and value property generated from the name property
 * of each object in the accountTypes array. A default "Choose" option is added as the first
 * element of the returned array.
 *
 * @param {Array} accountTypes - Array of account type objects
 * @returns {Array} Array of industry option objects
 */
const accountIndustryOptions = accountTypes => {
  const types = [
    {
      label: i18nTranslate('industry.default', 'Choose'),
      value: '',
    },
  ]

  accountTypes &&
    accountTypes.map(list => {
      const { name } = list
      types.push({
        label: name,
        value: name,
      })
    })
  return types
}

/**
 * Generates an array of industry size option objects from the given collectionProp object.
 * The collectionProp is expected to contain an accountSize property.
 * The accountSize value string is split into an array, and each value is used to generate
 * a size option object containing a label and value property.
 * A default "Select" option is added as the first element.
 *
 * @param {Object} collectionProp - Collection property object
 * @returns {Array} Array of industry size option objects
 */
const accountIndustrySizeOptions = collectionProp => {
  const accountSize =
    collectionProp &&
    collectionProp.find(list => list.name.toLowerCase() === 'accountsize')
  const sizeStringToArray = accountSize && accountSize.value.split(',')
  const size = [
    {
      label: i18nTranslate('industrySize.default', 'Select'),
      value: '',
    },
  ]
  sizeStringToArray &&
    sizeStringToArray.map(value =>
      size.push({
        label: value,
        value,
      })
    )
  return size
}

/**
 * Translates an account type string to a localized string.
 *
 * @param {string} type - The account type string
 * @returns {string} The localized account type string
 */
const accountTypeTranslation = (type = '') => {
  switch (type) {
    case 'Retail Customer':
      return i18nTranslate('signup.retailCustomer', 'Retail Customer')

    case 'Preferred Customer/Member':
      return i18nTranslate(
        'signup.preferredCustomer',
        'Preferred Customer/Member'
      )

    case 'Brand Affiliate':
      return i18nTranslate('signup.brandAffiliate', 'Brand Affiliate')

    case 'Brand Affiliate - Business Entity':
      return i18nTranslate(
        'account.businessEntity',
        'Brand Affiliate - Business Entity'
      )

    default:
      return ''
  }
}

export {
  accountIndustryOptions,
  accountIndustrySizeOptions,
  accountTypeTranslation,
}
