import { CommonContainer } from 'src/models/Common'
import { observable } from 'mobx'
import { pageNames } from 'src/routes/pathParams'
import {
  checkoutContainer,
  sessionContainer,
  customerContainer,
} from 'src/models'
import { toastState, overlayState } from 'src/views/components'
import { i18nTranslate } from 'src/utils'
import { IS_BROWSER } from 'src/utils/application'
import {
  trackErrorInInstana,
  application,
  setLocalStorage,
  deleteFromLocalStorage,
} from 'src/utils'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'

/**
 * VenmoContainer class extends CommonContainer.
 * This appears to be a class that encapsulates logic and state related to Venmo payments.
 */
class VenmoContainer extends CommonContainer {
  @observable submitOrderEnable = false
  @observable venmoInitiate = false
  @observable venmoClientCreated = false
  @observable venmoCheckoutInstance = {}
  deviceData = {}
  submitOrderProps = {}

  /**
   * Handles Venmo payment errors by logging, updating state,
   * displaying errors, and redirecting as needed.
   *
   * @param {object} tokenizeErr - The Venmo tokenization error object
   */
  handleVenmoError = async tokenizeErr => {
    trackErrorInInstana({
      errorData: tokenizeErr,
      errorReport: 'Venmo Payment - Error',
    })
    overlayState.showLoader()
    deleteFromLocalStorage('isFromVenmoPayment')
    await checkoutContainer.deletePayment()
    venmoContainer.venmoClientCreated = false
    venmoContainer.venmoInitiate = false
    venmoContainer.submitOrderEnable = false
    checkoutContainer.activePath = 'payment'

    const knownErrorCodes = [
      'VENMO_TOKENIZATION_CANCELED_BY_MERCHANT',
      'VENMO_CANCELED',
      'VENMO_APP_CANCELED',
      'FRAME_SERVICE_FRAME_CLOSED',
    ]

    overlayState.hideLoader()
    const commonErrorMsg = i18nTranslate(
      'checkout.venmoCommonError',
      'Something went wrong in Venmo Payment. Please try again later.'
    )

    let errorMessage = ''

    if (tokenizeErr?.code === 'FRAME_SERVICE_FRAME_OPEN_FAILED') {
      trackErrorInInstana({
        errorData: tokenizeErr?.code,
        errorReport: 'Popup blocked by the browser',
      })
      errorMessage = i18nTranslate(
        'checkout.popupBlocked',
        'Enable popup window and try again'
      )
    } else if (knownErrorCodes?.includes(tokenizeErr?.code)) {
      trackErrorInInstana({
        errorData: tokenizeErr?.code,
        errorReport: 'Venmo is currently not available - Please try again',
      })
    } else {
      errorMessage = tokenizeErr?.message
      trackErrorInInstana({
        errorData: errorMessage,
        errorReport: 'Venmo - An error occurred:',
      })
    }

    if (errorMessage) {
      toastState.setToastMessage(errorMessage, false)
    }

    // let arr = window?.location?.href?.split('/checkout')
    // window?.location?.replace(` ${arr[0]}${pageNames?.viewCart}`)
  }

  /**
   * Handles Venmo order errors by logging, updating state,
   * displaying errors, and redirecting as needed.
   *
   * @param {Object} props - The props object
   * @param {boolean} props.isSubmitOrderError - Whether there was a submit order error
   * @param {Object} props.submitOrderResponse - The submit order response object
   */
  handleVenmoOrderError = async props => {
    const { isSubmitOrderError, submitOrderResponse = {} } = props || {}
    trackErrorInInstana({
      errorData: submitOrderResponse,
      errorReport: 'Venmo Submit Order Failure Response',
    })
    venmoContainer.submitOrderEnable = false
    venmoContainer.venmoClientCreated = false
    overlayState.toggleLoader()
    toastState.setToastMessage(
      submitOrderResponse?.message || submitOrderResponse?.responseMessage
    )
    checkoutContainer.activePath = 'payment'
    if (!isSubmitOrderError) {
      await checkoutContainer.deletePayment()
      // venmoContainer.venmoInitiate = false
    }
  }

  /**
   * handleVenmoSuccess handles a successful Venmo payment nonce response.
   * It submits the order, redirects to confirmation page, and handles errors.
   */
  handleVenmoSuccess = async payload => {
    trackErrorInInstana({
      errorData: payload.nonce,
      errorReport: 'Venmo Payment - nonce Token Received',
    })
    overlayState.toggleLoader()
    deleteFromLocalStorage('isFromVenmoPayment')
    const submitOrderResponse = await checkoutContainer.submitOrder({
      properties: {
        nonceToken: payload?.nonce || '',
        correlationId: venmoContainer?.deviceData || '',
        deviceToken: venmoContainer?.deviceData || '',
      },
    })

    if (checkoutContainer.isSuccessResponse(submitOrderResponse)) {
      trackErrorInInstana({
        errorData: submitOrderResponse,
        errorReport: 'Venmo - Order Placed Successfully',
      })
      await toastState.setToastMessage(
        i18nTranslate('order.submitSuccess', 'Your order has been submitted.'),
        true
      )
      if (typeof window !== 'undefined') {
        localStorage.setItem('paymentSuccess', submitOrderResponse.orderId)
        overlayState.toggleLoader()
        let isNativeApp = checkIsNativeApp() || false
        if (venmoContainer.submitOrderProps?.history) {
          if (isNativeApp) {
            venmoContainer.submitOrderProps?.history?.push(
              `${pageNames.orderConfirmation}?isNativeApp=true`
            )
          } else {
            venmoContainer.submitOrderProps?.history?.push(
              pageNames.orderConfirmation
            )
          }
        } else {
          let arr = window?.location?.href.split('/checkout')
          if (isNativeApp) {
            window?.location?.replace(
              `${arr[0]}${pageNames.orderConfirmation}?isNativeApp=true`
            )
          } else {
            window?.location?.replace(`${arr[0]}${pageNames.orderConfirmation}`)
          }
        }
        venmoContainer.submitOrderEnable = false
        venmoContainer.venmoClientCreated = false
        // The line below is not executing after the page redirection, so the below code is commented.
        // overlayState.toggleLoader()
        // await cartContainer.viewCart()
        // await loyaltyContainer.getLoyaltyPoints()
      } else {
        venmoContainer.handleVenmoOrderError({ submitOrderResponse })
      }
    } else {
      venmoContainer.handleVenmoOrderError({
        isSubmitOrderError: true,
        submitOrderResponse,
      })
    }
  }

  /**
   * createVenmoOrder asynchronously calls the tokenize method on the venmoCheckoutInstance object.
   * This will attempt to generate a nonce payload for the Venmo order.
   * The tokenize callback checks for any errors, logs and handles them if so.
   * If successful, it logs the success and passes the payload to handleVenmoSuccess.
   */
  createVenmoOrder = async () => {
    return venmoContainer.venmoCheckoutInstance?.tokenize(function (
      tokenizeErr,
      payload
    ) {
      // venmoContainer.submitOrderEnable = true
      trackErrorInInstana({
        errorData: tokenizeErr,
        errorReport: 'Venmo - Checkout Instance Tokenize Error',
      })
      if (tokenizeErr) {
        venmoContainer.submitOrderEnable = false
        venmoContainer.handleVenmoError(tokenizeErr)
      } else if (payload && payload?.nonce) {
        trackErrorInInstana({
          errorData: payload,
          errorReport: 'Venmo - Tokenize Success',
        })
        venmoContainer.handleVenmoSuccess(payload)
      }
    })
  }

  /**
   * reviewVenmoOrder asynchronously reviews the order and attempts to create a Venmo order.
   *
   * It takes in props, toggles the loader, disables submit order, and reviews the order.
   *
   * If the review is successful, it toggles the loader again, refreshes the session if a registered user,
   * and calls createVenmoOrder.
   *
   * If the review fails, it sets a toast message with the response, toggles the loader,
   * deletes payment, disables Venmo client, and redirects to the cart page.
   */
  reviewVenmoOrder = async props => {
    venmoContainer.submitOrderProps = props
    overlayState.toggleLoader()
    venmoContainer.submitOrderEnable = false
    const reviewResponse = await checkoutContainer.reviewOrder()
    trackErrorInInstana({
      errorData: reviewResponse,
      errorReport: 'Venmo - Review Order Response',
    })
    if (checkoutContainer.isSuccessResponse(reviewResponse)) {
      overlayState.toggleLoader()
      if (customerContainer?.isRegisterUser) {
        const sessionResponse = await sessionContainer.refreshSession()
        trackErrorInInstana({
          errorData: sessionResponse,
          errorReport: 'Venmo - Refresh Session Response',
        })
      }

      application.isMobile && setLocalStorage('isFromVenmoPayment', true)
      return venmoContainer.createVenmoOrder()
    } else {
      trackErrorInInstana({
        errorData: reviewResponse,
        errorReport: 'Venmo - Review Order Failed',
      })
      toastState.setToastMessage(reviewResponse?.responseMessage)
      overlayState.toggleLoader()
      await checkoutContainer.deletePayment()
      venmoContainer.venmoClientCreated = false
      let arr = window?.location?.href?.split('/checkout')
      window?.location?.replace(` ${arr[0]}${pageNames?.viewCart}`)
    }
  }

  /**
   * Displays the Venmo button and enables order submission.
   *
   * This is an async function that sets submitOrderEnable to true,
   * allowing the order to be submitted after the Venmo button renders.
   */
  displayVenmoButton = async () => {
    this.submitOrderEnable = true
  }

  /**
   * getDataCollector creates a Braintree data collector instance to collect device data for Venmo transactions.
   *
   * It takes a Braintree client instance as input, and uses it to create a data collector.
   * The callback checks for errors, and if none, saves the deviceData to the venmoContainer.
   */
  getDataCollector = clientInstance => {
    braintree.dataCollector.create(
      {
        client: clientInstance,
        venmo: true,
      },
      function (dataCollectorErr, dataCollectorInstance) {
        if (dataCollectorErr) {
          trackErrorInInstana({
            errorReport: 'Error in Venmo data collection',
            errorData: dataCollectorErr,
          })
          return
        }
        venmoContainer.deviceData = dataCollectorInstance.deviceData
      }
    )
  }

  /**
   * Creates the Venmo checkout component to allow users to pay with Venmo.
   *
   * This is an async function that takes the Braintree client instance as a parameter.
   * It initializes the Venmo checkout UI which allows users to pay directly from their Venmo account.
   *
   * The function handles errors and success states to manage the checkout flow accordingly.
   */
  createVenmoCheckout = async clientInstance => {
    if (
      typeof braintree !== 'undefined' &&
      typeof clientInstance !== 'undefined'
    )
      // Create a Venmo Checkout component.
      braintree?.venmo?.create(
        {
          client: clientInstance,
          allowDesktop: true,
          mobileWebFallBack: true,
          allowDesktopWebLogin: true,
          paymentMethodUsage: 'single_use',
        },
        async function (venmoErr, venmoInstance) {
          if (venmoErr) {
            trackErrorInInstana({
              errorReport: 'Error in creating Venmo Instance',
              errorData: venmoErr,
            })
            overlayState.toggleLoader()
            await checkoutContainer.deletePayment()
            venmoContainer.venmoClientCreated = false
            venmoContainer.submitOrderEnable = false
            window?.location?.replace(`${window?.location?.href}`)
            return
          }
          venmoContainer.venmoCheckoutInstance = venmoInstance
          venmoContainer?.displayVenmoButton()
          trackErrorInInstana({
            errorReport: 'Venmo - Payment Instance Created',
            errorData: venmoInstance,
          })
          if (venmoInstance?.hasTokenizationResult()) {
            venmoInstance?.tokenize(function (tokenizeErr, payload) {
              overlayState.toggleLoader()
              if (tokenizeErr) {
                venmoContainer?.handleVenmoError(tokenizeErr)
              } else if (payload && payload?.nonce) {
                venmoContainer?.handleVenmoSuccess(payload)
              }
            })
            return
          }
        }
      )
  }

  /**
   * Creates the Venmo checkout component.
   *
   * This is an async function that initializes the Venmo checkout UI.
   *
   * It first checks for any client errors.
   * If there are errors it handles them by toggling the loader, deleting payment, etc.
   *
   * If no errors, it gets the data collector and calls createVenmoCheckout to initialize the component.
   *
   * It catches any errors during data collection and logs them.
   */
  createVenmoCheckoutComponent = async (clientErr, clientInstance) => {
    if (clientErr && IS_BROWSER) {
      trackErrorInInstana({
        errorReport: 'Error creating Venmo Client:',
        errorData: clientErr,
      })
      overlayState.toggleLoader()
      await checkoutContainer.deletePayment()
      venmoContainer.venmoClientCreated = false
      venmoContainer.submitOrderEnable = false
      window?.location?.replace(`${window?.location?.href}`)
      return
    }

    try {
      trackErrorInInstana({
        errorReport: 'Venmo - Client Instance Created',
        errorData: clientInstance,
      })
      venmoContainer?.getDataCollector(clientInstance)
    } catch (e) {
      trackErrorInInstana({
        errorReport: 'Error in Venmo data collection',
        errorData: e,
      })
    }

    this.createVenmoCheckout(clientInstance)
  }
}

const venmoContainer = new VenmoContainer()

export { VenmoContainer, venmoContainer }
export default venmoContainer
