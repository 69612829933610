import { tryJSONParse } from 'src/utils'

/**
 * CatalogState class.
 * Appears to manage state related to product catalog.
 */
class CatalogState {
  isToEnableCompare = false

  /**
   * Gets an array of image URLs for the given product.
   *
   * Tries to get the main image URL from the product's imageURL property.
   * Falls back to an empty array if not available.
   *
   * Also tries to parse alternate image URLs from the altImage property.
   * Pushes any successfully parsed URLs onto the array after the main image.
   *
   * Returns the array of image URLs.
   */
  getImageArray = product => {
    const imageArray = []
    try {
      const imageURL = product?.properties?.imageURL || '[]'

      imageArray.push(imageURL)
      imageArray.push(...JSON.parse(product?.properties?.altImage || '[]'))
    } catch (e) {}
    return imageArray
  }

  /**
   * Handles getting the index of the next image in an image array,
   * based on the current image index and total number of images.
   *
   * If at the last image, returns 0 to go back to the first image.
   * Otherwise increments the index by 1.
   */
  handleCurrentImage = imageArrayProperties => {
    const { currentIndex, images } = imageArrayProperties
    if (images.length > 1) {
      if (currentIndex === images.length - 1) {
        return 0
      } else {
        return currentIndex + 1
      }
    }
  }

  /**
   * Checks if a product is comparable based on:
   * - The category's comparable property
   * - The product's comparable property
   * - The global isToEnableCompare flag
   *
   * Returns a boolean indicating if the product is comparable.
   */
  isProductComparable = (catalogResponse, productResponse) => {
    return (
      (catalogResponse.category?.properties?.comparable &&
        tryJSONParse(
          catalogResponse.category?.properties?.comparable,
          false
        )) ||
      (productResponse?.properties?.comparable &&
        tryJSONParse(productResponse?.properties?.comparable, false)) ||
      this.isToEnableCompare
    )
  }
}

const catalogState = new CatalogState()
export { catalogState }
