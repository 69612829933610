import React from 'react'
import { Button } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'

const NuskinArrowLeft = props => {
  const {
    className,
    styles = {},
    onClick,
    ariaLabel = i18nTranslate('arrow.prev', 'Previous Image', {
      nameSpace: 'ssr-resource',
    }),
    customIcon,
  } = props

  const ada = {
    'aria-label': ariaLabel,
  }

  return (
    <Button
      {...ada}
      onClick={onClick}
      className={`${className} icon`}
      data-testid="qa-pdp-left-arrow">
      {customIcon ? (
        customIcon
      ) : (
        <svg
          data-v-36192c72=""
          data-v-7c6384a4=""
          viewBox="0 0 24 24"
          preserveAspectRatio="xMidYMid meet"
          focusable="false"
          size="icon-sm"
          // className={`${className} icon`}
          icon-size="icon-lg"
          style={{
            display: 'block',
          }}>
          <g
            data-v-36192c72=""
            id="icon-narrow-angle-left-24"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round">
            <g transform="translate(-196, -226)" stroke="currentColor">
              <polyline
                transform="translate(209.000000, 237.500000) scale(-1.2, 1.2) rotate(90.000000) translate(-209.000000, -237.500000) "
                points="200 242 209 233 218 242"></polyline>
            </g>
          </g>
        </svg>
      )}
    </Button>
  )
}

export { NuskinArrowLeft }
export default NuskinArrowLeft
