import { i18nTranslate } from 'src/utils'

export const renderFormInput = props => {
  const fieldConfig = [
    [
      {
        name: 'cardCVV',
        labelKey: 'cardCVV',
        type: 'password',
        dataTestId: 'qa-pay-code',
        RenderProps: props.renderCvvNumber(),
        ariaLabel: i18nTranslate('inputs.cvv', 'CVV number input textbox'),
        maxLength: 4,
        columnType: {
          sm: '12',
        },
        placeholderKey: 'cardcvvPlaceHolder',
        customAttributes: {
          autocomplete: 'new-password',
        },
        required: true,
      },
    ],
  ]
  return fieldConfig
}
