import React from 'react'
// import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import isNumber from 'lodash/isNumber'
import { i18nTranslate } from 'src/utils'
import { observer } from 'mobx-react'
import { observable, reaction } from 'mobx'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { toastState } from 'src/views/components'
import { Form, Table, ButtonGroup, Button, Badge } from 'react-bootstrap'
import { pageNames } from 'src/routes/pathParams'
import { cartContainer, checkoutContainer, customerContainer } from 'src/models'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import './styles.scss'
import { VARIANT_NODES as skuLabels, APPConfig } from 'config/appConfig'
import { getBackOrderInfoForOrderHistory } from '../ProductComponents/ProductSkuDetails/ProductInventory'
import {
  getLocaleString,
  getInventoryMessage,
  tryJSONParse,
  getBundleReorderInfo,
  modifyAndGetAccountType,
  modifyAndGetCustomerType,
  checkCustomerTypeEligibility,
  checkProductBackOrdered,
  getOrderHistoryBackOrderedDate,
  isBundleHasDiscontinuedSubItem,
} from 'src/utils'

@observer
class SkuTable extends React.Component {
  @observable errorMessage = {}
  @observable selectedSkuDataStatus = false
  @observable activeRowIndex = isNumber(this.props.activeRowIndex)
    ? this.props.activeRowIndex
    : ''
  @observable lastUpdatedReorderIndex = ''
  /**
   * @info
   * observable exclusiveAvailableOrNotAvailableItemsArray is to get the exclusive eligible/not eligible items from conditions logic
   * observable reOrderQty - to be passed from reorder page for condition checking of user qty
   */
  @observable exclusiveAvailableOrNotAvailableItemsArray = []
  disposer = () => {}
  /**
   * Get the available and not available exclusive items to purchase again from order history
   * @date 1/18/2024 - 11:57:34 AM
   */
  componentDidMount() {
    cartContainer.resetRestrictedItemStatus()
    if (!cartContainer.isOrderIdAndRestrictionCallInProgress) {
      this.checkConditionsForReOrderRestrictions(this.qtyInputNode)
      this.checkConditionsForExclusive(this.qtyInputNode)
    }

    /**
     * @info reaction is used here for disable/enable reorder qty and addtocart button based on cart changes
     * triggering reaction will call function of conditions logic, on every update cartContainer.cartResponse to
     * check the qty of that particular product cart
     */
    this.disposer = reaction(
      () => [
        cartContainer.isOrderIdAndRestrictionCallInProgress,
        cartContainer.cartResponse,
      ],
      () => {
        if (!cartContainer.isOrderIdAndRestrictionCallInProgress) {
          this.checkConditionsForReOrderRestrictions(this.qtyInputNode)
          this.checkConditionsForExclusive(this.qtyInputNode)
        }
      }
    )
  }
  componentWillUnmount() {
    //reactions disposed here
    if (isFunction(this.disposer)) {
      this.disposer()
    }
  }
  /**
   * @info set the available and not available items for exclusive based on conditions logic
   * @param {} [reorderQty=0] current value of qty inout passing as reorderQty param from reorder page
   */
  checkConditionsForReOrderRestrictions = (
    reorderQty = [],
    orderItemEditIndex,
    onEditInput = false
  ) => {
    onEditInput
      ? cartContainer.updateRestrictionStatusForItem(
          reorderQty,
          orderItemEditIndex
        )
      : cartContainer.checkConditionsForForeignOrderLimit(
          this.sortedSku,
          reorderQty,
          orderItemEditIndex
        )
  }
  checkConditionsForExclusive = (
    reorderQty = [],
    orderItemEditIndex,
    onEditInput = false
  ) => {
    let exclusiveReorderIndexQty = onEditInput
      ? reorderQty[orderItemEditIndex]?.value
      : reorderQty[this.lastUpdatedReorderIndex]?.value
    this.exclusiveAvailableOrNotAvailableItemsArray =
      customerContainer.checkConditionsForExclusiveItems(
        this.sortedSku,
        exclusiveReorderIndexQty
      )
  }

  setCurrentRowIndex = index => {
    this.activeRowIndex = index
  }

  maxValue = APPConfig?.getAppConfig()?.cartMaxLimit || 99

  getTitles = () => {
    const title = []

    if (this.props.isFromOrderHistory) {
      title.push({
        name: i18nTranslate('skuTable.skuImage', 'image'),
        apiPropertyName: 'image',
      })
    }

    title.push({
      name: i18nTranslate('skuTable.name', 'name'),
      apiPropertyName: 'name',
    })

    skuLabels.forEach(sku => {
      title.push({
        name: getLocaleString()[sku],
        apiPropertyName: sku,
      })
    })

    return title
  }

  sortedSku = []
  orderSkuList = []
  constructor(props) {
    super(props)
    this.qtyInputNode = []
  }

  handleData = async (isCustomerEligible = false) => {
    const { history, isFromOrderHistory = false, costCenterId } = this.props
    const selectedSkuData = this.getSkuValues(isCustomerEligible)
    if (this.selectedSkuDataStatus === true) {
      if (selectedSkuData?.length) {
        const response = await cartContainer?.addToCart({
          cartArray: selectedSkuData,
        })
        if (isFromOrderHistory && cartContainer?.isSuccessResponse(response)) {
          if (costCenterId !== '') {
            await checkoutContainer?.updateCostCenterToCart(costCenterId)
          }
          history.push(`${pageNames.viewCart}`)
        }
      } else {
        toastState.setToastMessage(
          i18nTranslate(
            'quantity.enterQuantity',
            'Quantity not set for sku. Please enter the quantity'
          ),
          false
        )
      }
    }
    this.selectedSkuDataStatus = false
  }

  renderButton = (
    props,
    isCustomerEligible = false,
    isToDisableAddToCartBtn = false
  ) => {
    if (props.renderButton) {
      return props.renderButton(this.handleData(isCustomerEligible))
    }
    const availableChannel =
      this.checkProductListAvailableChannelsForWeb() || []
    //Filtering the bundle Info
    const orderList = this.props?.orderList || []
    const { checkIfBundleIsEligible = false, checkForBundleReorder = [] } =
      getBundleReorderInfo(orderList) || {}

    const orderListWithoutGiftItem =
      orderList?.filter(
        item => item?.item?.itemInfo?.otherProperties?.isGiftItem !== 'true'
      ) || []

    const orderListArrayValues =
      orderListWithoutGiftItem?.map(
        val =>
          val?.item?.itemInfo?.productStatus === 'INACTIVE' ||
          (val?.item?.itemInfo?.sku?.inventory === 'OUT OF STOCK' &&
            val?.item?.itemInfo?.sku?.inventoryProperties?.atpQty === 0) ||
          (checkForBundleReorder?.length > 0 && !checkIfBundleIsEligible) ||
          !isCustomerEligible ||
          (availableChannel?.length > 0 && !availableChannel?.includes(true)) ||
          val?.item?.itemInfo?.otherProperties?.productStatus?.toLowerCase() !=
            'sellable' ||
          val?.item?.itemInfo?.specialProducts?.some(
            pdt =>
              pdt?.otherproperties?.productStatus?.toLowerCase() ===
              'discontinued'
          ) ||
          val?.item?.itemInfo?.otherProperties?.dangerousGoods === 'true'
      ) || []
    const enableAddToCartButton =
      orderListArrayValues?.some(value => value === false) || false
    return (
      <ButtonGroup className="button-group py-3 mx-0 flex-sm-column d-flex">
        <Button
          onClick={this.handleData}
          className="btn-addToCart border-0"
          data-testid="qa-addcart"
          disabled={
            !enableAddToCartButton ||
            isToDisableAddToCartBtn ||
            cartContainer.isOrderIdAndRestrictionCallInProgress
          }>
          {i18nTranslate('pdp.addToCart', 'Add To Cart', {
            nameSpace: 'ssr-resource',
          })}
        </Button>
      </ButtonGroup>
    )
  }

  renderTitle = titleJSON => {
    return (
      <th
        key={titleJSON.name}
        className="text-capitalize reorder-align-row"
        data-testid={`qa-reorder-item-${titleJSON.name?.toLowerCase()}-title`}>
        {titleJSON.name}
      </th>
    )
  }

  /**
   * @description renderBundleNotEligible - to check the bundle availability
   * @date 12/11/2023 - 8:28:30 PM
   */
  renderBundleNotEligible = (data = {}) => {
    const specialProduct = data?.item?.itemInfo?.specialProducts || []
    const bundleInfo =
      specialProduct?.map(value => {
        return value?.inventoryProperties?.available
      }) || []
    return bundleInfo?.some(value => value === false) || false
  }

  renderData = (
    isCustomerEligible = false,
    title,
    data,
    index,
    isExclusivePurchaseLimitReached = false,
    restrictionMessage = ''
  ) => {
    const dateOfAvailablity = data?.properties?.dateOfAvailablity || ''
    const {
      sku = {},
      otherProperties = {},
      quantity = 0,
      specialProducts = [],
    } = data?.item?.itemInfo || {}
    const preOrderDateTimeStamp = new Date(dateOfAvailablity).getTime()
    const currentDateTimeStamp = Date.now()
    const isPreOrder =
      preOrderDateTimeStamp >= currentDateTimeStamp
        ? data?.properties?.isPreOrder || false
        : false

    const value = this.props.isFromOrderHistory
      ? sku?.skuInfo || {}
      : data?.properties || {}
    let productStatus = ''
    const productCount = quantity || 0
    const inventory = sku?.inventoryProperties || {}
    const {
      atpQty = 0,
      outOfStockThreshold = 0,
      backOrderedQty = 0,
    } = inventory
    const {
      bundleInfo = {},
      isBundleBackOrdered = false,
      singleProductBackOrderedStatus = false,
      getBundleBackOrderedInventory = {},
      backOrderDate = '',
    } = getOrderHistoryBackOrderedDate(data) || {}

    const isToShowOrderPlacedBackOrderDateForOrderhistory =
      singleProductBackOrderedStatus && backOrderDate !== '' ? true : false

    let inventoryStatusView =
      (singleProductBackOrderedStatus &&
        getBackOrderInfoForOrderHistory({
          isToShowOrderPlacedBackOrderDateForOrderhistory,
          backOrderDate,
        })) ||
      ''
    const bundleBackOrdered = getBundleBackOrderedInventory?.[0] || {}
    let inventoryStatusForBundle =
      (bundleInfo?.length > 0 &&
        getBackOrderInfoForOrderHistory({
          isToShowOrderPlacedBackOrderDateForOrderhistory:
            isBundleBackOrdered &&
            bundleBackOrdered?.otherproperties?.backOrderDate !== ''
              ? true
              : false,
          backOrderDate:
            bundleBackOrdered?.otherproperties?.backOrderDate || '',
        })) ||
      ''
    let inventoryMessage = ''
    if (data?.item?.itemInfo?.productStatus === 'INACTIVE') {
      productStatus = 'INACTIVE'
    } else if (data?.item?.itemInfo?.sku?.inventory === 'OUT OF STOCK') {
      productStatus = 'OUT OF STOCK'
    } else if (this.renderBundleNotEligible(data)) {
      productStatus = 'BUNDLE OOS'
    } else if (!isCustomerEligible) {
      inventoryMessage = i18nTranslate(
        'pdp.userEligible',
        'This user is not eligible to purchase this product',
        {
          nameSpace: 'ssr-resource',
        }
      )
    } else {
      inventoryMessage = getInventoryMessage(data?.item?.itemInfo) || ''
      if (inventoryMessage === '') {
        inventoryMessage =
          bundleInfo?.length > 0 && isBundleBackOrdered
            ? inventoryStatusForBundle
            : singleProductBackOrderedStatus
            ? inventoryStatusView
            : ''
      }
    }

    if (title && title.toLowerCase() === 'image') {
      return (
        <td key={`${title}-${index}`} className="reorder-align-row">
          <div
            className="image-wrap d-flex justify-content-center align-items-center"
            data-testid="qa-reorder-item-image-wrapper">
            <LazyLoadImage
              src={value[title] || imagePlaceholder}
              alt={imagePlaceholder}
              className="mw-100 mh-100"
              data-testid="qa-reorder-item-image"
              onError={e => {
                if (e.target.src != imagePlaceholder) {
                  e.target.onerror = null
                  e.target.src = imagePlaceholder
                }
              }}
            />
          </div>
        </td>
      )
    } else if (title && title.toLowerCase() === 'name') {
      return (
        <>
          <td
            className="reorder-align-row"
            key={`${title}-${index}`}
            data-testid="qa-reorder-item-name">
            {value[title]}
            <br />
            {productStatus === 'INACTIVE' && (
              <span
                className="border border-danger text-danger"
                data-testid="qa-reorder-item-status-inactive">
                {i18nTranslate(
                  'subscription.searchCardUnavailable',
                  'Unavailable'
                )}
              </span>
            )}
            {(productStatus === 'OUT OF STOCK' ||
              productStatus === 'BUNDLE OOS') && (
              <p
                className="border border-danger text-danger d-inline-block p-1 mt-1"
                data-testid="qa-reorder-item-out-of-stock">
                {i18nTranslate('cart.outOfStock', 'OUT OF STOCK')}
              </p>
            )}
            {inventoryMessage !== '' && (
              <p
                className="web-inventory-danger"
                data-testid="qa-reorder-item-inventory-message">
                {inventoryMessage}
              </p>
            )}
            {isExclusivePurchaseLimitReached && (
              <p
                className="text-danger"
                data-testid="qa-reorder-item-limit-reached">
                {i18nTranslate(
                  'exclusive.excedeedLimitMsg',
                  'Exceeded your purchase limit for this product'
                )}
              </p>
            )}
            {restrictionMessage !== '' && (
              <p
                className="text-danger"
                data-testid="qa-reorder-item-not-eligible">
                {restrictionMessage}
              </p>
            )}
          </td>
          {tryJSONParse(isPreOrder) === true && title === 'name' && (
            <td className="reorder-align-row">
              <Badge variant="info" className="order-product-type-label ml-2">
                {i18nTranslate('pdp.preOrder', 'Preorder', {
                  nameSpace: 'ssr-resource',
                })}
              </Badge>
            </td>
          )}
        </>
      )
    }
    return (
      <>
        <td className="reorder-align-row" key={`${title}-${index}`}>
          {value[title]}
        </td>
        {JSON.parse(isPreOrder) === true && title === 'name' && (
          <td className="reorder-align-row">
            <Badge variant="info" className="order-product-type-label ml-2">
              {i18nTranslate('pdp.preOrder', 'Preorder', {
                nameSpace: 'ssr-resource',
              })}
            </Badge>
          </td>
        )}
      </>
    )
  }

  getSkuValues = (isCustomerEligible = false) => {
    const selectedSkuData = []
    this.sortedSku.forEach((sku, index) => {
      const specialProduct = sku?.item?.itemInfo?.specialProducts || []
      const productStatus = sku?.item?.itemInfo?.productStatus || ''
      const productOutofStock = sku?.item?.itemInfo?.sku?.inventory || ''
      const isNotEligibleToAdd =
        this.exclusiveAvailableOrNotAvailableItemsArray?.not_available?.some(
          item => item === sku?.item?.itemInfo?.sku?.productId
        )
      const isRestricted = cartContainer.restrictedItems?.some(
        item =>
          item?.skuId === sku?.item?.itemInfo?.sku?.skuId &&
          item?.isForeignOrderRestricted
      )

      const bundleProductsAvailability =
        specialProduct?.map(value => {
          return value?.inventoryProperties?.available || false
        }) || []
      const checkIfBundleIsEligible = bundleProductsAvailability?.some(
        value => value === false
      )
      const isSellable =
        (sku?.item?.itemInfo?.otherProperties?.productStatus?.toLowerCase() ||
          '') === 'sellable'

      const isDangerousGoods =
        sku?.item?.itemInfo?.otherProperties?.dangerousGoods === 'true' || false
      const skus = []
      if (
        productStatus.toLowerCase() === 'active' &&
        productOutofStock.toLowerCase() != 'out of stock' &&
        isCustomerEligible &&
        this.checkIndexBasedAvailableChannelsForWeb(index) &&
        isSellable &&
        !isBundleHasDiscontinuedSubItem?.(specialProduct) &&
        !checkIfBundleIsEligible &&
        !isDangerousGoods &&
        !isNotEligibleToAdd &&
        !isRestricted
      ) {
        if (this.qtyInputNode?.[index]?.value && specialProduct?.length > 0) {
          skus.push({
            skuId:
              (this.props.isFromOrderHistory
                ? sku?.item?.itemInfo?.sku?.skuId
                : sku?.identifier) || '',
            productId:
              (this.props.isFromOrderHistory
                ? sku?.item?.itemInfo?.sku?.productId
                : this.props.productId) || '',
            type: sku?.item?.itemInfo?.productType || '',
          })
          specialProduct.forEach(skuItem => {
            skus.push({
              skuId:
                (this.props.isFromOrderHistory
                  ? skuItem?.skuId
                  : skuItem?.identifier) || '',
              productId:
                (this.props.isFromOrderHistory
                  ? skuItem?.productId
                  : this.props.productId) || '',
              type: skuItem?.type || '',
              skuQuantity: skuItem?.skuQuantity || '',
            })
          })
          selectedSkuData.push({
            quantity: this.qtyInputNode?.[index]?.value || '',
            isBackOrdered: sku?.item?.itemInfo?.backordered || false,
            skus,
            categoryId: sku?.item?.itemInfo?.otherProperties?.categoryId || '',
          })
        } else if (this.qtyInputNode?.[index]?.value) {
          selectedSkuData.push({
            quantity: this.qtyInputNode?.[index]?.value,
            isBackOrdered:
              sku?.item?.itemInfo?.sku?.inventoryProperties?.backOrdered ||
              false,
            skuId:
              (this.props.isFromOrderHistory
                ? sku?.item?.itemInfo?.sku?.skuId
                : sku?.identifier) || '',
            productId:
              (this.props.isFromOrderHistory
                ? sku?.item?.itemInfo?.sku?.productId
                : this.props?.productId) || '',
            categoryId: sku?.item?.itemInfo?.otherProperties?.categoryId || '',
          })
        }
      }
    })
    this.selectedSkuDataStatus = true
    return selectedSkuData
  }

  validationQuantity = event => {
    if (isNaN(event.target?.value) || event.target?.value <= 0) {
      event.target.value = ''
    }
  }

  handleSubmit = event => {
    event.preventDefault()
  }

  handleInput = (event = {}, index = 0) => {
    const value = event.target.value
    if (value.match(/\D/)) {
      event.target.value = event.target.value.slice(0, -1)
    } else if (value.match(/\d/) && value > this.maxValue) {
      event.target.value = this.maxValue
    }

    /**
     * @info
     * set the reOrderQty with current user input value
     * set the available/not available items based on the reOrderQty
     */
    if (event.target.value !== '') {
      this.lastUpdatedReorderIndex = index
      this.checkConditionsForReOrderRestrictions(this.qtyInputNode, index, true)
      this.checkConditionsForExclusive(this.qtyInputNode, index, true)
    }
  }

  /**
   * @description For availablechannel checking for 'web' included
   * @date 11/24/2023 - 4:42:34 PM
   */
  checkProductListAvailableChannelsForWeb = () => {
    const skuListOrders = this.props?.orderList || []
    const availableChannel =
      skuListOrders.map(value => {
        return value?.item?.itemInfo?.otherProperties?.availableChannels?.includes(
          'web'
        )
      }) || []
    return availableChannel
  }

  /**
   * @description initially channel getting undefined so check if true only need to show 'web'
   * @date 11/24/2023 - 4:47:53 PM
   */
  checkIndexBasedAvailableChannelsForWeb = index => {
    const availableChannel =
      this.checkProductListAvailableChannelsForWeb() || []
    // returning "true" to avoid returning undefined for initial rendering instance
    return typeof availableChannel?.[index] === 'undefined'
      ? true
      : availableChannel?.[index]
  }
  /**
   * Returns an array of restricted sku details from the cart.
   * Filters the cart restrictedItems array for items where isForeignOrderRestricted is true.
   */
  getRestrictedSkuDetails = () => {
    let restrictedSkusArray = []
    if (
      Array.isArray(cartContainer.restrictedItems) &&
      cartContainer.restrictedItems?.length > 0
    ) {
      restrictedSkusArray = cartContainer.restrictedItems?.filter(
        restrictedProduct => {
          return restrictedProduct?.isForeignOrderRestricted
        }
      )
    }
    return restrictedSkusArray
  }

  renderValue = (data, index) => {
    /**
     *@info isExclusivePurchaseLimitReached - disable qty input based on the not available items compared with current item
     */

    let isExclusivePurchaseLimitReached = false
    const pdtId = data?.item?.itemInfo?.sku?.productId || ''
    const skuId = data?.item?.itemInfo?.sku?.skuId || ''
    const notAvailableArray =
      this.exclusiveAvailableOrNotAvailableItemsArray?.not_available || []
    if (notAvailableArray?.length > 0) {
      notAvailableArray.forEach(item => {
        isExclusivePurchaseLimitReached = item === pdtId ? true : false
      })
    }
    const restrictedSkus = this.getRestrictedSkuDetails() || []
    const restrictionMessage =
      restrictedSkus?.filter(sku => sku?.skuId === skuId)[0]?.message || ''
    let isCustomerEligible = false
    const quantity = data?.item?.itemInfo?.quantity || ''
    const titles = this.checkIfSkuAvailable()
    const { currentRowCallback } = this.props
    const productStatus = data?.item?.itemInfo?.productStatus || ''
    const productOutofStock = data?.item?.itemInfo?.sku?.inventory || ''
    // const setErrorMessage =
    //   this.errorMessage.index === index ? this.errorMessage.error : ''
    // const isInValid = setErrorMessage === 'd-block' ? true : false
    const isFreeGift = data?.item?.itemInfo?.otherProperties?.isGiftItem
    const { specialProducts = [] } = data?.item?.itemInfo || {}

    const nonSellable =
      (data?.item?.itemInfo?.otherProperties?.productStatus &&
        data?.item?.itemInfo?.otherProperties?.productStatus?.toLowerCase() !=
          'sellable') ||
      isBundleHasDiscontinuedSubItem(specialProducts) ||
      false
    const isDangerousGoods =
      (data?.item?.itemInfo?.otherProperties?.dangerousGoods &&
        data?.item?.itemInfo?.otherProperties?.dangerousGoods === 'true') ||
      false
    const customerTypeResult =
      modifyAndGetCustomerType(
        data?.item?.itemInfo?.otherProperties?.customerTypes
      ) || ''
    const accoutTypeResult =
      modifyAndGetAccountType(
        customerContainer?.profileResponse?.accounts?.accountType
      ) || ''
    isCustomerEligible = checkCustomerTypeEligibility(
      customerTypeResult,
      accoutTypeResult
    )
    return (
      <tr
        className={
          this.activeRowIndex === index
            ? productStatus === 'INACTIVE' ||
              productOutofStock === 'OUT OF STOCK' ||
              !isCustomerEligible
              ? 'unavailable-product active-row'
              : 'active-row'
            : productStatus === 'INACTIVE' ||
              productOutofStock === 'OUT OF STOCK' ||
              this.renderBundleNotEligible(data) ||
              !isCustomerEligible ||
              !this.checkIndexBasedAvailableChannelsForWeb(index) ||
              nonSellable ||
              isDangerousGoods
            ? 'unavailable-product inactivity'
            : ''
        }
        key={`sku-${index}`}
        {...(isFunction(currentRowCallback) && {
          onClick: () => {
            this.setCurrentRowIndex(index)
            currentRowCallback(data)
          },
        })}>
        {titles.map(titleJson =>
          this.renderData(
            isCustomerEligible,
            titleJson.apiPropertyName,
            data,
            index,
            isExclusivePurchaseLimitReached,
            restrictionMessage
          )
        )}
        <td
          key={`qty-form${index}`}
          className="quantity-input-box reorder-align-row">
          <Form onSubmit={this.handleSubmit}>
            <Form.Control
              type="text"
              // min="1"
              maxLength="4"
              aria-label="quantity"
              className="reorderQtyStyle"
              data-testid="qa-reorder-item-quantity"
              disabled={
                isFreeGift
                  ? true
                  : isExclusivePurchaseLimitReached
                  ? true
                  : restrictionMessage !== ''
                  ? true
                  : cartContainer.isOrderIdAndRestrictionCallInProgress
                  ? true
                  : false
              }
              defaultValue={isFreeGift ? '' : quantity}
              onClick={event => event.stopPropagation()}
              onInput={event => this.handleInput(event, index)}
              onBlur={event => {
                // this.errorMessage = this.validationQuantity(data, index)
                this.validationQuantity(event, index)
              }}
              ref={node => (this.qtyInputNode[index] = node)}
            />
            {/* <div className={`invalid-feedback ${setErrorMessage}`}>
              <Trans i18nKey="plp.quantityErrorText">Please enter valid number</Trans>
            </div> */}
          </Form>
        </td>
      </tr>
    )
  }
  /*This function is used for removing the duplicates of combined order and to add the quantity of same sku item
And Display that single line item with increased/combined quantity*/
  filterSkuListArr = (skuList = []) => {
    function mergeQtyForSameSku(skuList) {
      const mergedMap = {}
      // Iterate through each item
      skuList?.forEach(item => {
        const { skuId = '' } = item?.item?.itemInfo?.sku || {}
        const { quantity = '' } = item?.item?.itemInfo || {}
        // If the SKU is already in the map, add the quantity
        if (mergedMap.hasOwnProperty(skuId)) {
          mergedMap[skuId] += parseInt(quantity) || 0
        } else {
          // Otherwise, initialize the SKU with its initial quantity
          mergedMap[skuId] = parseInt(quantity) || 0
        }
      })

      return mergedMap
    }
    const mergedMap = mergeQtyForSameSku(skuList) || {}
    const skuIdInitialArr =
      skuList?.map(items => items?.item?.itemInfo?.sku?.skuId) || []
    const duplicateItemRemovedArr = skuList?.filter(
      (items, index) =>
        !skuIdInitialArr?.includes(items?.item?.itemInfo?.sku?.skuId, index + 1)
    )
    // Update the quantities
    const updatedArray = duplicateItemRemovedArr?.map(items => {
      // Modify the quantity based on your requirements
      const updatedQuantity =
        mergedMap[items?.item?.itemInfo?.sku?.skuId] ||
        items?.item?.itemInfo?.quantity ||
        ''
      // Create a new object with the updated quantity
      return {
        ...items,
        item: {
          ...items?.item,
          itemInfo: {
            ...items?.item?.itemInfo,
            quantity: updatedQuantity,
          },
        },
      }
    })

    return updatedArray
  }

  sortSkuList = () => {
    const skuList = this.props?.skuList || []
    const isFromOrderHistory = this.props?.isFromOrderHistory
    const filterArr = this.filterSkuListArr(skuList)
    this.sortedSku = filterArr.sort(function (a, b) {
      const value1 = isFromOrderHistory
        ? a?.item?.itemInfo?.sku?.skuInfo || {}
        : a?.properties || {}
      const value2 = isFromOrderHistory
        ? b?.item?.itemInfo?.sku?.skuInfo || {}
        : b?.properties || {}
      if (value1 && value1.color < value2 && value2.color) return -1
      if (value1 && value1.color > value2 && value2.color) return 1
      return 0
    })
    return this.sortedSku?.map(this.renderValue)
  }

  checkIfSkuAvailable = () => {
    const VARIANT_NODES = this.getTitles()
    const skuList = this.props?.skuList || []
    let newSkuList = []
    VARIANT_NODES.forEach(title => {
      const hasTitle = skuList.find(sku => {
        if (this.props?.isFromOrderHistory) {
          return sku.item?.itemInfo?.sku?.skuInfo?.[title.apiPropertyName]
        } else {
          return (
            sku && sku?.properties && sku?.properties?.[title.apiPropertyName]
          )
        }
      })
      if (hasTitle) {
        newSkuList.push(title)
      }
    })

    return newSkuList
  }

  /**
   * Returns the length of the sorted SKU list, excluding any gift items.
   * Iterates through the sorted SKU list and filters out any items where
   * the isGiftItem property is truthy.
   */
  getOrderLengthExcludingGift = () => {
    let normalItemList = []
    //Used sortedSku here, as sortedSku has duplicated removed data
    if (this.sortedSku?.length > 0) {
      this.sortedSku?.map(order => {
        const isGiftItem =
          order?.item?.itemInfo?.otherProperties?.isGiftItem || ''
        if (!isGiftItem) {
          normalItemList.push(order)
        }
      })
    }
    return normalItemList.length || 0
  }
  checkAndDisableAddtoCartBtn = () => {
    const orderItemsLength = this.getOrderLengthExcludingGift()
    const purchaseLimitReachedItemsLength =
      this.exclusiveAvailableOrNotAvailableItemsArray?.not_available?.length ||
      0
    const restrictedSkuArray = this.getRestrictedSkuDetails() || []
    return orderItemsLength === purchaseLimitReachedItemsLength &&
      purchaseLimitReachedItemsLength > 0
      ? true
      : restrictedSkuArray?.length > 0 &&
        orderItemsLength === restrictedSkuArray?.length
      ? true
      : false
  }

  render() {
    const VARIANT_NODES = this.checkIfSkuAvailable()
    const isToDisableAddToCartBtn = this.checkAndDisableAddtoCartBtn()
    const tableTitle = VARIANT_NODES?.map(this.renderTitle)
    this.orderSkuList = this.props.skuList || []
    let isAddBtnEnable = 0
    let isCustomerEligible = false
    this.orderSkuList.forEach((sku, index) => {
      let customerTypeResult =
        modifyAndGetCustomerType(
          sku?.item?.itemInfo?.otherProperties?.customerTypes
        ) || ''
      let accoutTypeResult =
        modifyAndGetAccountType(
          customerContainer?.profileResponse?.accounts?.accountType
        ) || ''
      isCustomerEligible = checkCustomerTypeEligibility(
        customerTypeResult,
        accoutTypeResult
      )
      isCustomerEligible ? (isAddBtnEnable = isAddBtnEnable + 1) : 0
      if (isAddBtnEnable >= 1) {
        isCustomerEligible = true
      }
    })
    return (
      <section className={`${this.props.className}`}>
        <div className="table-wrapper">
          <Table
            className="table-container"
            aria-hidden="true"
            data-testid="qa-reorder-item-list">
            <thead>
              <tr>
                {tableTitle}
                <th
                  key="table-qty"
                  className="reorder-align-row"
                  data-testid="qa-reorder-item-quantity-title">
                  {i18nTranslate('pdp.quantityText', 'Quantity', {
                    nameSpace: 'ssr-resource',
                  })}
                </th>
              </tr>
            </thead>
            <tbody>{this.sortSkuList()}</tbody>
          </Table>
        </div>
        {this.renderButton(
          this.props,
          isCustomerEligible,
          isToDisableAddToCartBtn
        )}
      </section>
    )
  }
}

export { SkuTable }
