import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Modal, Button, ButtonGroup } from 'react-bootstrap'
import { modalState, toggleRemoveModal } from './state'

@observer
class RemoveModal extends React.Component {
  constructor(props) {
    super(props)
    modalState.isCancelModalVisible = false
  }

  handleSubmit = () => {
    modalState.callback()
    toggleRemoveModal()
  }

  render() {
    return (
      <Modal
        size="sm"
        centered={true}
        className="modal"
        show={modalState.isCancelModalVisible}
        onHide={toggleRemoveModal}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="mb-3">{modalState.text}</div>
          <ButtonGroup className="d-flex">
            <Button variant="primary" onClick={this.handleSubmit}>
              {i18nTranslate('outfit.remove', 'Remove')}
            </Button>
            <Button
              variant="outline-primary"
              onClick={toggleRemoveModal}
              className="ml-2">
              {i18nTranslate('outfit.cancel', 'Cancel')}
            </Button>
          </ButtonGroup>
        </Modal.Body>
      </Modal>
    )
  }
}

export { RemoveModal }
