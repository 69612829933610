import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { i18nTranslate } from 'src/utils'
import { Button, Row, Col } from 'react-bootstrap'
import { customerContainer, nuskinSubscriptionContainer } from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import './styles.scss'
import { IS_BROWSER } from 'config/appConfig'
import {
  getMysiteCartUrl,
  getShoppingContext,
  checkMysiteOrPersonalOffer,
} from 'src/utils'

function NavigationButtons(props) {
  const buttonText =
    props?.buttonText ||
    i18nTranslate('cart.continueShopping', 'Continue Shopping')
  const history = useHistory()
  const [hasSubscriptions, setHasSubscriptions] = useState(false)
  const contextText = checkMysiteOrPersonalOffer() || ''

  const handleSubscriptionNavigation = () => {
    history.push(pageNames.myaccountsubscriptions)
  }

  const handleHomeNavigation = () => {
    try {
      if (IS_BROWSER) {
        let contextValue
        // const shoppingContext = getLocalStorage('shoppingContext') || {}
        const shoppingContext = getShoppingContext()
        if (Object.keys(shoppingContext).length > 0) {
          contextValue = shoppingContext?.context || ''
          let redirectUrl = getMysiteCartUrl()
          window.location.href = redirectUrl
        } else {
          history?.push(pageNames.home)
        }
      }
    } catch (error) {
      console.error(
        'JSON.parse may be an error NavigationButtons.handleHomeNavigation ',
        error
      )
    }
  }

  useEffect(() => {
    if (
      customerContainer.isRegisterUser &&
      nuskinSubscriptionContainer.hasSubscriptionPreference()
    ) {
      setHasSubscriptions(true)
    }
  }, [])

  return (
    <Row className="d-flex justify-content-center mb-3">
      <Col
        className={`d-flex ${
          props?.buttonCustomClassName || 'justify-content-md-end'
        }`}>
        <Button
          variant={props?.darkButtons ? 'primary' : 'outline-primary'}
          className="sub-btn"
          onClick={handleHomeNavigation}>
          {buttonText}
        </Button>
      </Col>
      {hasSubscriptions && contextText === '' && (
        <Col className="d-flex justify-content-center manage-subscriptions-button">
          <Button
            variant="primary"
            className="sub-btn"
            onClick={handleSubscriptionNavigation}>
            {i18nTranslate(
              'subscriptions.manageSubscriptionText',
              ' Manage Your Subscription'
            )}
          </Button>
        </Col>
      )}
    </Row>
  )
}

export { NavigationButtons }
export default NavigationButtons
