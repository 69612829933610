import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Form, Row, Button } from 'react-bootstrap'
import { cartContainer, loyaltyContainer } from 'src/models'
import { currencyFormat } from 'src/utils'

@observer
class MultiplePayment extends React.Component {
  @observable isDebitOrCredit = true
  @observable currentPayment = {}

  constructor(props) {
    super(props)
    this.paymentCredits = []
    const paymentCredits = props.paymentCredits
    this.paymentCredits = [
      ...paymentCredits,
      { type: 'CREDITCARD', isDefault: true },
    ]
    this.handleDefaultPayment()
  }

  formatPrice = price => {
    const finalCost = currencyFormat({
      // currency: currencyCode,
      price,
      // defaultLocale: locale,
    })
    return finalCost
  }

  handleDefaultPayment = () => {
    const bagValue = Math.abs(
      cartContainer?.cartResponse?.paymentValue?.totalAmountUnpaid || '0.0'
    )

    const creditPoints = loyaltyContainer?.loyaltyResponse?.userMath || {}
    this.paymentCredits &&
      this.paymentCredits.forEach((item, index) => {
        if (item.type.toLowerCase() !== 'creditcard') {
          const {
            properties: { rewardType = '' },
          } = item
          item.price =
            creditPoints && creditPoints[rewardType]
              ? creditPoints[rewardType].total.openToRedeem
              : 0.0

          if (bagValue <= item.price) {
            item.isDisable = false
            this.isDebitOrCredit = false
            if (index === 0 || !this.isSetDefaultPayment) {
              this.isSetDefaultPayment = true
              this.currentPayment = item
              this.paymentCredits[index].isDefault = true
            } else {
              this.paymentCredits[index].isDefault = false
            }
          } else {
            item.isDisable = true
            this.paymentCredits[index].isDefault = false
          }
        } else {
          if (this.isDebitOrCredit) {
            this.paymentCredits[index].isDefault = true
          } else {
            this.paymentCredits[index].isDefault = false
          }
          item.isDisable = false
        }
      })
  }
  handleSelectPayment = creditItem => {
    this.currentPayment = creditItem
    this.paymentCredits.forEach((credits, index) => {
      if (credits.type.toLowerCase() === creditItem.type.toLowerCase()) {
        this.paymentCredits[index].isDefault = true
      } else {
        this.paymentCredits[index].isDefault = false
      }
    })
    if (creditItem.type.toLowerCase() === 'creditcard') {
      this.isDebitOrCredit = true
    } else {
      this.isDebitOrCredit = false
    }
  }
  renderMultiplePaymentOption = () => {
    return this.paymentCredits.map(credits => {
      const {
        isDefault = false,
        type,
        // name,
        price: amount,
        isDisable = false,
      } = credits
      const creditAmount =
        type.toLowerCase() === 'loyalty'
          ? `${amount} Points`
          : this.formatPrice(amount)
      const title = credits?.properties?.rewardType || ''
      const paymentType =
        type.toLowerCase() === 'creditcard'
          ? 'Debit/Credit Card'
          : `${title.toLowerCase()} (${creditAmount})`

      return (
        <Form.Check
          className={`payment-credits-radio-button my-2 text-capitalize`}
          id={`payment-${type}`}
          key={type.toLowerCase()}
          label={paymentType}
          type="radio"
          checked={isDefault}
          onClick={() => {
            this.handleSelectPayment(credits)
          }}
          disabled={isDisable}
        />
      )
    })
  }
  render() {
    return (
      <>
        {this.renderMultiplePaymentOption()}
        {this.isDebitOrCredit && this.props.renderTabs()}
        {!this.isDebitOrCredit && (
          <Row noGutters className="justify-content-end">
            <Button
              className="user-credits-button p-2"
              onClick={() => {
                this.props.handlePayment({
                  ...this.currentPayment,
                  isRewardPoints: true,
                })
              }}>
              {i18nTranslate('userCredits.continueButton', 'Continue')}
            </Button>
          </Row>
        )}
      </>
    )
  }
}

export { MultiplePayment }
export default MultiplePayment
