import React from 'react'
import { observer } from 'mobx-react'
import isArray from 'lodash/isArray'
import uniqBy from 'lodash/uniqBy'
import { Row, Col, Button } from 'react-bootstrap'
import {
  i18nTranslate,
  getLiveEventStatus,
  getLocalStorage,
  setLocalStorage,
  isB2BAccount,
  isB2B2C,
  currencyFormat,
  checkMysiteOrPersonalOffer,
  isGPSEnabled,
  isGPSDefaultPayment,
  trackEnhancedCheckoutEvent,
  trackGTMEvents,
  convertToBoolean,
} from 'src/utils'
import {
  cartContainer,
  saveForLaterContainer,
  customerContainer,
  checkoutContainer,
  nuskinSubscriptionContainer,
  applepayContainer,
  googlePayContainer,
} from 'src/models'
import {
  toastState,
  storePickupState,
  productState,
  Summary,
  EmptyCart,
  SigninModal,
  KlarnaMessage,
  ViewCartSearch,
  DrawerModal,
} from 'src/views/components'
import { Link } from 'react-router-dom'
import { getProductDetailsForCart, getProductDetailsForSavedCart } from './deps'
import { CartProductBlock } from './CartProductBlock'
import { FreeGiftCartProductBlock } from './FreeGiftCartProductBlock'
import {
  CheckoutProductBlock,
  renderCartProductsHeader,
} from './CheckoutProductBlock'
import { pageNames } from 'src/routes/pathParams'
import {
  getSeparateCartAndCheckout,
  getSessionStorage,
  useCartTableView,
  APPConfig,
} from 'config/appConfig'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import TagManager from 'react-gtm-module'
import { alertMessageKeys, cartCategoryType } from './fixture'
import { Checkoutfreegifts } from './CheckoutProductBlock/CheckoutFreeGift'
import { CheckoutNuskinIcon } from 'src/views/components/Icons/CheckoutNuSkinIcon'
import { observable, action } from 'mobx'
import { SignInAccordion } from '../CheckoutAccordions'
import { sendNeedToSignIn } from 'src/utils/reactNativeAppUtils'
import { trackErrorInInstana } from 'src/utils/commonUtils'
import { PayLaterMessage } from '../PayPal/PayLaterMessage'
import { isPaylaterMessageRequired } from '../PayPal'
import { getAvailablePaymentsForAddNewSections } from 'src/views/components/PaymentListWithForm/fixture'
import PaymentIcon from '../PaymentIcon'
import { MultipleProductSubscriptionModal } from '../SubscriptionComponents/MultipleProductSubscriptionModal'
import './styles.scss'
import { MultiSubscriptionCartDrawer } from '../FlexibleSubscriptionCart'

@observer
class CartProducts extends React.Component {
  freeGiftProductsList = []
  @observable isModalVisible = false
  @observable enableBtn = false
  @observable isCheckoutInventory = false
  @observable isToShowSubscriptionModal =
    nuskinSubscriptionContainer.showSubscriptionModal() ||
    nuskinSubscriptionContainer.enableGoToCart
  @observable enableLiveEvent = getLiveEventStatus()
  isEventRegisteredUser = this.checkEventRegisteredUser()

  checkEventRegisteredUser() {
    const profileResponse = customerContainer?.profileResponse
    const accountProperties = profileResponse?.accounts?.properties || []
    const userProperty = accountProperties?.filter(
      property => property?.attributeId === 'registeredEvent'
    )
    return userProperty?.[0]?.value || 'false'
  }

  @action
  toggleModal = (props = {}) => {
    const { visibility } = props

    this.isModalVisible =
      visibility !== undefined ? visibility : !this.isModalVisible
  }

  toggleSubscriptionModal = () => {
    if (nuskinSubscriptionContainer.isToShowDisabledModal) {
      nuskinSubscriptionContainer.isToShowDisabledModal = false
    } else {
      this.isToShowSubscriptionModal = !this.isToShowSubscriptionModal
      nuskinSubscriptionContainer.enableCartConvertMessage = false
      nuskinSubscriptionContainer.enableGoToCart = false
    }
  }

  formatPrice = (priceLabel = '', value = {}) => {
    let price = value?.[priceLabel] || '0.00'
    if (isNaN(price)) {
      price = '0.00'
    }
    const finalCost = currencyFormat({
      price,
    })
    return finalCost
  }

  componentWillUnmount() {
    this.toggleModal({ visibility: false })
  }

  isCallInProgress = false

  constructor(props) {
    super(props)
    this.quantity = 1
  }

  componentDidMount() {
    this.handlePersonalOfferPromroCode()
  }

  @action
  renderKlarnaMessaging = () => {
    const grandTotal = cartContainer.cartResponse?.value?.grandTotal || 0
    // const grandTotalForKlarna = grandTotal?.toFixed(2)
    return (
      cartContainer.cartCount > 0 && (
        <div className="klarna-message">
          <KlarnaMessage
            id="klarna-cart-message"
            data-key="credit-promotion-badge"
            price={grandTotal}></KlarnaMessage>
        </div>
      )
    )
  }

  handleCartQuantityChange = async (count = 0, itemId = 0) => {
    const cartResponse = cartContainer?.cartResponse?.items || []
    const { isSaveForLater = false } = this.props
    let inventoryerrorcode = cartResponse?.find(
      e => e.errorMessage == 'Item is BackOrdered'
    )
    let inventoryitemId = inventoryerrorcode?.itemId
    if (isSaveForLater) {
      this.quantity = count
    } else {
      if (inventoryitemId) {
        await cartContainer.updateCart({
          id: inventoryitemId,
          quantity: count,
          properties: {
            isBackOrdered: true,
          },
        })
      } else {
        const response = await cartContainer.updateCart({
          id: itemId,
          quantity: count,
        })
        const InventoryResponse = cartContainer?.inventoryResponse || {}
        if (InventoryResponse.message == 'Item is BackOrdered') {
          await cartContainer.updateCart({
            id: itemId,
            quantity: count,
            properties: {
              isBackOrdered: true,
            },
          })
        }
        return response
      }
    }
  }

  scanQualifiedCount = () => {
    let scanQualifiedProducts = cartContainer?.scanQualifiedProducts || []
    let quantityChange = this.scanCount || 0
    let counts = []
    let scanDetails = {}
    let scanCounts = []
    let n = 0
    scanQualifiedProducts?.map(item => {
      let skuScanCount = 0
      item?.skus?.forEach(sku => {
        skuScanCount = sku?.skuProperties
          ? sku?.skuProperties?.scanQualifiedCount
          : skuScanCount
      })
      scanDetails = {
        itemId: item?.itemId || '',
        scanCount: skuScanCount || 0,
        quantity: item?.quantity || 0,
      }
      if (item?.itemId === quantityChange?.itemId) {
        scanCounts.push(parseInt(skuScanCount) * quantityChange?.quantity)
        if (quantityChange?.quantity > item?.quantity) {
          let diff =
            quantityChange?.quantity > item?.quantity
              ? quantityChange?.quantity - item?.quantity
              : item?.quantity - quantityChange?.quantity
          n =
            cartContainer?.scanCardVisibilityCount +
            parseInt(skuScanCount) * diff
          cartContainer.updateScanvisibilityCount(n)
        } else {
          if (quantityChange?.quantity < item?.quantity) {
            let diff = item?.quantity - quantityChange?.quantity
            let count = parseInt(skuScanCount) * diff
            cartContainer.updateScanvisibilityCount(n)
            n =
              cartContainer?.scanCardVisibilityCount > count
                ? cartContainer?.scanCardVisibilityCount - count
                : count - cartContainer?.scanCardVisibilityCount
            //cartContainer.scanCardVisibilityCount - count
          }
        }
        scanDetails.quantity = quantityChange?.quantity
      } else {
        scanCounts.push(parseInt(skuScanCount) * item?.quantity)
      }
      counts.push(scanDetails)
    })

    let scanCountSum = scanCounts.reduce(function (a, b) {
      return parseInt(a) + parseInt(b)
    }, 0)
    return scanCountSum
  }

  handleSubscriptionChange = async (
    count = 0,
    itemId = 0,
    subscription = {}
  ) => {
    const response = await cartContainer.updateCart({
      id: itemId,
      quantity: count,
      subscription: subscription,
    })
    return cartContainer.isSuccessResponse(response) ? true : false
  }
  handlePickupDelete = (skuId = '') => {
    const storeProductsInCookie = getLocalStorage('storeProducts') || {}
    if (storePickupState.storePickupProducts.hasOwnProperty(skuId)) {
      productState.selectPickupType = 'ship-it'
      delete storePickupState.storePickupProducts[skuId]
    }
    if (storeProductsInCookie && storeProductsInCookie.hasOwnProperty(skuId)) {
      delete storeProductsInCookie[skuId]
      setLocalStorage('storeProducts', storeProductsInCookie)
    }
  }

  handleSubscribeCheckboxClick = async (
    subscriptionData = {},
    enableToastMessage = true
  ) => {
    const response = await cartContainer.updateCart(
      subscriptionData,
      enableToastMessage
    )
    const { id = '', type = '' } = subscriptionData || {}
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    if (
      cartContainer.isSuccessResponse(response) &&
      type === 'subscription' &&
      isFlexibleSubscriptionEnabled
    ) {
      const items = cartContainer.cartResponse?.items || []
      const cartItem = items.find(item => item.itemId === id)
      const {
        itemId = '',
        totalValue = {},
        quantity = '',
        subscription = {},
        isSubscription = false,
      } = cartItem || {}

      trackGTMEvents({
        event: 'subscriptionSubscribeCheck',
        item_id: itemId,
        discount: totalValue?.discount,
        total: totalValue?.grandTotal,
        quantity: quantity,
        frequency:
          subscription?.frequencyPeriod + '-' + subscription?.frequencyType,
      })
    }
  }

  handleDeleteCartItem = async (props = {}) => {
    const {
      isSaveForLater = false,
      listItemId = '',
      cartItemId = 0,
      skuId = '',
      productId = '',
      slug,
      productName,
      isSubscription = false,
      currency = '',
      categoryId = '',
      totalPrice = '',
      variantLabel = '',
      quantity = '',
    } = props
    if (isSaveForLater) {
      await saveForLaterContainer.deleteProductFromList(listItemId)
    } else {
      this.handlePickupDelete(skuId)
      const productData = {
        skuId,
        productName,
        currency,
        categoryId,
        totalPrice,
        quantity,
        variantLabel,
      }
      await cartContainer.deleteCartItem(
        productId,
        isSubscription,
        cartItemId,
        true,
        skuId,
        slug,
        productName,
        productData
      )
    }
  }
  handleSaveForLaterItem = async (props = {}) => {
    const {
      isSaveForLater = false,
      listItemId = '',
      cartItemId = 0,
      quantity = 0,
      isCheckout = false,
      ...remainingProps
    } = props
    if (this.isCallInProgress === false) {
      this.isCallInProgress = true
      if (isSaveForLater) {
        // Move to Cart
        const moveToCartProps = [
          {
            quantity,
            ...remainingProps,
          },
        ]
        const response = await cartContainer.addToCart(
          { cartArray: moveToCartProps, isCheckout, isShowToast: false },
          false
        )
        if (cartContainer.isSuccessResponse(response)) {
          await saveForLaterContainer.deleteProductFromList(listItemId, false)
          toastState.setToastMessage(
            i18nTranslate(
              'cart.moveToCartSuccess',
              'Product added to cart successfully'
            ),
            true
          )
        } else {
          let errorMessage =
            (response && response?.message) ||
            i18nTranslate(
              'cart.moveToCartFailure',
              'Unable to add product to cart, please try again'
            )
          if (response?.code == 'EOCA0000013') {
            errorMessage = i18nTranslate(
              'cart.moveToCartBackorderMsg',
              'Backordered item cannot be added to cart from save for later and list section'
            )
          } else if (response?.code === 'EOCACG0001') {
            errorMessage = i18nTranslate(
              'cart.unableToProcessError',
              'We are currently unable to process your request or it may already be processed. Please refresh your screen and try again'
            )
          }
          toastState.setToastMessage(errorMessage, false)
        }
      } else {
        // Save For Later
        const response = await saveForLaterContainer.addProductToList({
          ...remainingProps,
          quantity,
        })
        trackErrorInInstana({
          errorReport: 'Save For Later Response',
          errorData: response,
        })
        if (saveForLaterContainer.isSuccessResponse(response)) {
          const isShowToast = false
          await cartContainer.deleteCartItem(
            '',
            '',
            cartItemId,
            isShowToast,
            false
          )
          trackErrorInInstana({
            errorReport: `Save For Later Success - ${cartItemId}`,
            errorData: response,
          })
          toastState.setToastMessage(
            i18nTranslate(
              'cart.savedToCartSuccess',
              'Product moved to save for later successfully'
            ),
            true
          )
        } else if (response?.code === 'EOLISIT00001') {
          trackErrorInInstana({
            errorReport: 'Save For Later Failure',
            errorData: response,
          })
          await cartContainer.deleteCartItem('', '', cartItemId, false)
          toastState.setToastMessage(response?.message, false)
        } else if (response?.code === 'EOCACG0001') {
          trackErrorInInstana({
            errorReport: 'Save For Later Failure',
            errorData: response,
          })
          const unProcessableRequestMessage = i18nTranslate(
            'cart.unableToProcessError',
            'We are currently unable to process your request or it may already be processed. Please refresh your screen and try again'
          )
          toastState.setToastMessage(unProcessableRequestMessage, false)
        } else {
          trackErrorInInstana({
            errorReport: 'Save For Later Failure',
            errorData: response,
          })
          const errorMessage =
            response?.message ||
            i18nTranslate(
              'cart.savedToCartFailure',
              'Unable to move product to save for later, please try again'
            )
          toastState.setToastMessage(errorMessage, false)
        }
      }
      this.isCallInProgress = false
    }
  }

  handleSaveForLater = (props = {}) => {
    const { productName = '', ...remainingProps } = props
    this.handleSaveForLaterItem({
      ...remainingProps,
      name: productName,
    })
    customerContainer.isSubscriptionAdded = false
  }

  getHandlers = () => {
    const handlers = {
      handleCartQuantityChange: this.handleCartQuantityChange,
      handleDeleteCartItem: this.handleDeleteCartItem,
      handleSaveForLater: this.handleSaveForLater,
      handleSubscriptionChange: this.handleSubscriptionChange,
      handleSubscribeCheckboxClick: this.handleSubscribeCheckboxClick,
    }
    return handlers
  }

  renderProductBlock = (cartProduct = {}) => {
    const { isSaveForLater = false } = this.props
    const productDetails = isSaveForLater
      ? getProductDetailsForSavedCart(cartProduct, this.props)
      : getProductDetailsForCart(cartProduct, this.props)
    const skus = cartProduct?.skus || []
    const type = cartContainer.getSpecialProductType(skus)
    if (productDetails?.isCheckout) {
      const { isReviewSection = false } = this.props
      if (productDetails?.isFreeGift) {
        let skuId = cartProduct?.skus?.[0]?.skuId || ''
        return (
          <Checkoutfreegifts
            {...productDetails}
            {...cartContainer.getCombineFreeGiftQty(
              skuId,
              this.freeGiftProductsList
            )}
            isNativeApp={this.props?.isNativeApp}
          />
        )
      }
      return (
        <CheckoutProductBlock
          key={`checkout-${cartProduct?.itemId}`}
          isReviewSection={isReviewSection}
          {...productDetails}
          {...this.getHandlers()}
          updateCheckoutBtn={this.props?.updateCheckoutBtn}
          type={type}
          freeGiftProductsList={this.freeGiftProductsList}
          isNativeApp={this.props?.isNativeApp}
        />
      )
    } else {
      if (productDetails?.isFreeGift) {
        let skuId = cartProduct?.skus?.[0]?.skuId || ''
        return (
          <FreeGiftCartProductBlock
            {...productDetails}
            {...cartContainer.getCombineFreeGiftQty(
              skuId,
              this.freeGiftProductsList
            )}
          />
        )
      }
      return (
        <CartProductBlock
          key={`cart-${cartProduct?.itemId}`}
          {...productDetails}
          {...this.getHandlers()}
          type={type}
          isViewCart={true}
          freeGiftProductsList={this.freeGiftProductsList}
        />
      )
    }
  }

  renderProductBlockView = () => {
    const { isSaveForLater = false } = this.props
    const items = isSaveForLater
      ? saveForLaterContainer?.saveForLaterListProducts || []
      : cartContainer?.cartResponse?.items || []
    const typeObj = {}
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false

    let filterProductByType = function (typeObject, type, item) {
      if (type in typeObject) {
        let subProd = typeObject[type]
        subProd.push(item)
        typeObject[type] = subProd
      } else {
        let subProd = []
        subProd.push(item)
        typeObject[type] = subProd
      }
    }
    items?.map(item => {
      if (item?.properties?.isGiftItem) {
        filterProductByType(typeObj, 'typeGift', item)
      } else if (item?.isSubscription) {
        filterProductByType(typeObj, 'subscriptionType', item)
      } else {
        filterProductByType(typeObj, 'oneTimeType', item)
      }
    })
    const orderedObj = Object.keys(typeObj)
      ?.sort()
      ?.reduce((obj, key) => {
        obj[key] = typeObj[key]
        return obj
      }, {})
    this.freeGiftProductsList = orderedObj?.typeGift || []
    if (
      typeof orderedObj?.typeGift !== 'undefined' &&
      orderedObj?.typeGift?.length > 0
    ) {
      orderedObj.typeGift = uniqBy(
        orderedObj?.typeGift,
        freeGift => freeGift?.skus?.[0]?.skuId
      )
    }
    const categoryProduct = []
    for (let key in orderedObj) {
      if (orderedObj.hasOwnProperty(key)) {
        let value = orderedObj[key]
        const freeGiftClass = key == 'typeGift' ? 'text-success' : ''
        if (isArray(value) && value?.length > 0) {
          categoryProduct.push(
            <>
              <div
                className={`cart-item-category-heading view-cart-category font-weight-bold h4 heading-5 mt-2 mb-2 ${freeGiftClass}`}
                data-testid="qa-minicart-title">
                {cartCategoryType(isFlexibleSubscriptionEnabled)[key].value}
              </div>
              {value?.map(this.renderProductBlock)}
            </>
          )
        }
      }
    }
    return categoryProduct
  }

  handlePersonalOfferPromroCode = async () => {
    try {
      if (typeof window !== 'undefined') {
        const personalOfferCode = getSessionStorage('personalOffer') || {}

        if (
          typeof personalOfferCode?.discount?.code !== 'undefined' &&
          personalOfferCode?.discount?.code !== ''
        ) {
          await checkoutContainer.addPromo(personalOfferCode?.discount?.code)
        }
      }
    } catch (e) {
      console.error('JSON.parse error at handlePersonalOfferPromroCode >>> ', e)
    }
  }

  renderSignInAccordion = () => {
    return <SignInAccordion {...this.props} onContinue={this.onContinue} />
  }

  onContinue = () => {
    this.props.history.push(pageNames.checkout)
  }
  paypalCheckout = async () => {
    await checkoutContainer.paypalCheckoutEnable(true)
    checkoutContainer.applePayCheckout = false
    checkoutContainer.gPayCheckout = false
    this.handleClick()
  }
  applePayCheckout = async () => {
    await checkoutContainer.applePayCheckoutEnable(true)
    checkoutContainer.paypalCheckout = false
    checkoutContainer.gPayCheckout = false
    this.handleClick()
  }
  gPayCheckout = async () => {
    await checkoutContainer.gPayCheckoutEnable(true)
    checkoutContainer.paypalCheckout = false
    checkoutContainer.applePayCheckout = false
    this.handleClick()
  }
  checkoutDisable = () => {
    const checkoutDisableInfo = { isDisabled: false, message: '' }
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false

    if (this.props?.orderHoldStatus) {
      checkoutDisableInfo.isDisabled = true
      checkoutDisableInfo.message = i18nTranslate(
        'account.onhold',
        'Account is on hold'
      )
    } else if (
      // enable checkout button when cart is having subscription items
      // and user is having subscription dashboard already
      // and when flexible subscription flag is enabled
      nuskinSubscriptionContainer.isUserSubscribedBefore() &&
      cartContainer.hasSubscriptionProduct() &&
      isFlexibleSubscriptionEnabled
    ) {
      checkoutDisableInfo.isDisabled = false
    } else {
      const itemdetails = cartContainer?.cartResponse?.items || []
      const isSkuNotAvailable = (inventory = {}) =>
        inventory?.available === false &&
        (inventory?.backOrdered === false || inventory?.backOrdered === null)

      const isSomeSkuNotAvailable = itemdetails?.some(item => {
        const { skus = [] } = item
        return skus?.some(newitem => {
          return isSkuNotAvailable(newitem?.inventoryProperties)
        })
      })

      if (isSomeSkuNotAvailable) {
        checkoutDisableInfo.isDisabled = true
        checkoutDisableInfo.message = i18nTranslate(
          'some.items.unavailable',
          'Some of the items are not available.'
        )
      } else if (cartContainer?.cartResponse?.errorCode) {
        checkoutDisableInfo.isDisabled = true
        if (cartContainer.cartResponse?.errorCode === 'EOPLOR001') {
          checkoutDisableInfo.message = i18nTranslate(
            'cart.homeMarket',
            'Please switch to your home market to make a purchase.'
          )
        } else if (cartContainer.cartResponse?.errorCode === 'EOSKUPS002') {
          checkoutDisableInfo.message = i18nTranslate(
            'bundle.discontinuedV2Message',
            'Discontinued - This product or promotion has been discontinued',
            {
              nameSpace: 'ssr-resource',
            }
          )
        } else {
          checkoutDisableInfo.message = cartContainer.cartResponse.errorMessage
        }
      }

      // Disable checkout conditions for Live Event
      // CN locale users might get migrated for the July event site
      // Checkout is disabled for them for non-event sites
      const homeCountry = customerContainer?.getUserHomeCountry()
      const isUserFromCN = homeCountry === 'CN'
      if (this.enableLiveEvent !== 'true') {
        if (isUserFromCN) {
          checkoutDisableInfo.isDisabled = true
          checkoutDisableInfo.message = i18nTranslate(
            'cart.homeMarket',
            'Please switch to your home market to make a purchase.'
          )
        }
      }
      if (this.enableLiveEvent === 'true') {
        const isEventUnregisteredUser =
          customerContainer.isRegisterUser &&
          this.isEventRegisteredUser === 'false'
        const isRetailUser =
          customerContainer?.profileResponse?.accounts?.accountType ===
          'Retail Customer'

        // Disable checkout in event for unregistered users and retail users from CN
        if (isEventUnregisteredUser || (isUserFromCN && isRetailUser)) {
          checkoutDisableInfo.isDisabled = true
        }
      }
    }
    return checkoutDisableInfo
  }
  renderCartViewButtons = () => {
    let contextValue
    let hideContinueShopBtn
    try {
      if (typeof window !== 'undefined') {
        // const shoppingContext = getLocalStorage('shoppingContext') || {}
        contextValue = checkMysiteOrPersonalOffer()
        // if (Object.keys(shoppingContext).length > 0) {
        // contextValue = shoppingContext?.context || ''
        if (
          contextValue === 'personal_offer' ||
          contextValue === 'storefront'
        ) {
          /**
           *style for hide continue shopping button
           */
          hideContinueShopBtn = {
            display: 'none',
          }
        }
        // }
      }
    } catch (e) {
      console.error('JSON.parse error at renderCartViewButtons >>> ', e)
    }

    const checkoutDisableInfo = this.checkoutDisable()
    let subsctiptionItemsTotal =
      cartContainer.cartResponse?.properties?.subsctiptionItemsTotal || '0'
    let grandTotal = cartContainer?.cartResponse?.value?.grandTotal || ''
    const cartProductsCount = cartContainer?.cartCount
    const userCountry = customerContainer.getUsersCountry()
    const isGPSEnabledAndDefault = isGPSEnabled() && isGPSDefaultPayment()
    let hasSubscriptionProductInCart =
      cartContainer.hasSubscriptionProduct() || false
    const isToEnableExpressPaymentOptions =
      APPConfig?.getAppConfig()?.expressPayments || []

    const isToEnableExpressPaymentOption = (paymentType = '') => {
      return isToEnableExpressPaymentOptions?.includes?.(
        paymentType?.toUpperCase()
      )
    }
    const isToEnablePaypal =
      getAvailablePaymentsForAddNewSections({
        paymentType: 'payPal',
      }) || false

    const isToEnableApplePay =
      getAvailablePaymentsForAddNewSections({
        paymentType: 'applePay',
      }) || false

    const isToEnableGooglePayPayment =
      getAvailablePaymentsForAddNewSections({
        paymentType: 'googlePay',
      }) || false

    const isToEnableGooglePayForNativeApps =
      convertToBoolean(
        APPConfig?.getAppConfig()?.enableGooglePayPaymentForNativeApps
      ) || false
    let isNativeApp = this.props?.isNativeApp || false

    let isToEnableGooglePay = isNativeApp
      ? isToEnableGooglePayForNativeApps && isToEnableGooglePayPayment
      : isToEnableGooglePayPayment

    const isCurrentDeviceSupportedApplePay =
      applepayContainer.checkIfDeviceSupportsApplePay()

    const isToShowExpressPayPal =
      isToEnablePaypal &&
      !hasSubscriptionProductInCart &&
      !isGPSEnabledAndDefault &&
      isToEnableExpressPaymentOption('PAYPAL')

    const isToShowExpressApplePay =
      isToEnableApplePay &&
      !hasSubscriptionProductInCart &&
      !isGPSEnabledAndDefault &&
      isCurrentDeviceSupportedApplePay &&
      isToEnableExpressPaymentOption('APPLEPAY')

    const isToShowExpressGooglePay =
      isToEnableGooglePay &&
      !hasSubscriptionProductInCart &&
      !isGPSEnabledAndDefault &&
      isToEnableExpressPaymentOption('GOOGLEPAY')

    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false

    const isToShowExpressPayments =
      grandTotal > 0 &&
      (isToShowExpressPayPal ||
        isToShowExpressApplePay ||
        isToShowExpressGooglePay)

    return (
      <div className="cart-view-button-wrapper">
        {!this.props?.isNativeApp && cartProductsCount === 0 && (
          <Row noGutters className=" mt-3 flex-row justify-content-end w-100">
            <Col lg={12} className="px-2">
              {/* dont use anchor tags use link or history push and fix any issue in it*/}
              <Link
                to={pageNames.home}
                // onClick={() => (window.location.href = window.location.origin)}
                // href="javascript:;"
                className="cart-view-button-link mr-lg-3">
                {/* <Link to={pageNames.home} className="cart-view-button-link mr-lg-3"> */}
                <Button
                  variant="primary"
                  className="cart-view-continue-shopping-button w-100 mr-2 mb-2"
                  style={hideContinueShopBtn}
                  data-testid="qa-cart-continue-shopping">
                  {i18nTranslate('cart.continueShopping', 'Continue Shopping')}
                </Button>
              </Link>
            </Col>
          </Row>
        )}
        <Row noGutters className="flex-row justify-content-end w-100">
          <Col lg={12}>
            <Button
              variant="primary"
              disabled={checkoutDisableInfo?.isDisabled}
              className="cart-view-checkout-button mr-2 w-100"
              data-testid="qa-cart-checkout"
              onClick={() => {
                this.handleClick()
                checkoutContainer.paypalCheckoutEnable(false)
                checkoutContainer.applePayCheckoutEnable(false)
                checkoutContainer.gPayCheckoutEnable(false)
              }}>
              {isFlexibleSubscriptionEnabled ? (
                <div>
                  <span>
                    <CheckoutNuskinIcon
                      className="view-cart-nuskin"
                      width="24px"
                      height="24px"
                      fill="#ffffff"
                    />
                  </span>
                  <span>{i18nTranslate('cart.checkout', 'Checkout')}</span>
                </div>
              ) : (
                i18nTranslate('cart.checkout', 'Checkout')
              )}
            </Button>
            <p className="text-danger view-cart-error-message">
              {alertMessageKeys(checkoutDisableInfo?.message)}
            </p>
          </Col>
          {isToShowExpressPayments && (
            <Col lg={12}>
              <span className="divider-viewcart">Or</span>
            </Col>
          )}

          {isToShowExpressApplePay && grandTotal > 0 && (
            <Col lg={12}>
              <Button
                variant="outline-primary"
                onClick={this.applePayCheckout}
                className="express-applepay-btn mb-2"
                disabled={checkoutDisableInfo?.isDisabled}
                aria-label={i18nTranslate('checkout.applepayText', 'applepay')}>
                <PaymentIcon type="express-applepay-btn" />
              </Button>
            </Col>
          )}
          {isToShowExpressGooglePay && grandTotal > 0 && (
            <Col lg={12}>
              <Button
                variant="outline-primary"
                onClick={this.gPayCheckout}
                className="express-gpay-btn mb-2"
                data-testid="qa-cart-express-google-pay"
                disabled={checkoutDisableInfo?.isDisabled}
                aria-label={i18nTranslate(
                  'checkout.googlepayText',
                  'googlepay'
                )}>
                <PaymentIcon type="express-gpay-btn" />
              </Button>
            </Col>
          )}

          {isToShowExpressPayPal && grandTotal > 0 && (
            <>
              {this.enableLiveEvent !== 'true' && (
                <Col lg={12}>
                  <Button
                    variant="outline-primary"
                    onClick={this.paypalCheckout}
                    className="paypal-btn mb-2"
                    disabled={checkoutDisableInfo?.isDisabled}
                    data-testid="qa-cart-express-paypal"
                    aria-label={i18nTranslate('checkout.paypalText', 'PayPal')}>
                    <PaymentIcon type="paypalCart" />
                  </Button>
                </Col>
              )}
              <Col lg={12}>
                {isPaylaterMessageRequired(userCountry) && (
                  <PayLaterMessage
                    amount={grandTotal}
                    id={'cart-paylater'}
                    key={'cart-paylater'}
                  />
                )}
              </Col>
            </>
          )}
        </Row>
      </div>
    )
  }

  /**
   * Renders the flexible subscription modal if there are any
   * subscription items in the cart.
   *
   * Gets the cart items, filters for subscription items,
   * checks if there are any, and conditionally renders the
   * MultiSubscriptionCartDrawer component passing the subscription
   * items and props.
   */
  renderFlexibleSubscriptionModal = () => {
    const cartItems = cartContainer?.cartResponse?.items || []
    if (Array.isArray(cartItems) && cartItems?.length > 0) {
      let subscriptionItems = cartItems?.filter(
        item => item?.isSubscription === true
      )

      const isCartHasSubscriptionProducts =
        cartContainer.hasSubscriptionProduct() || false

      return (
        isCartHasSubscriptionProducts &&
        subscriptionItems.length > 0 && (
          <MultiSubscriptionCartDrawer
            subscriptionItems={[...subscriptionItems]}
            toggleSubscriptionModal={this.toggleSubscriptionModal}
            {...this.props}
          />
        )
      )
    }
  }

  handleClick = () => {
    setLocalStorage('IsCheckoutClicked', true)
    const itemdetails = cartContainer?.cartResponse?.items || {}
    itemdetails
      ?.filter(itemdata => itemdata?.errorMessage === 'Item is BackOrdered')
      ?.map(
        async filtereddata =>
          await cartContainer.updateCart({
            id: filtereddata?.itemId,
            properties: {
              isBackOrdered: true,
            },
          })
      )
    const cartResponse = cartContainer?.cartResponse || {}
    //CX16-9906 - Show subscription modal(including oos) when user is having subscription dashboard already
    // and cart contains subscription items and flexible subscription feature is enabled
    // remaining error conditions handled in else if part

    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    if (
      nuskinSubscriptionContainer.isUserSubscribedBefore() &&
      cartContainer.hasSubscriptionProduct() &&
      isFlexibleSubscriptionEnabled
    ) {
      this.toggleSubscriptionModal()
    } else if (cartResponse?.errorCode) {
      this.isCheckoutInventory = true
      checkoutContainer.paypalCheckoutEnable(false)
      checkoutContainer.applePayCheckoutEnable(false)
      checkoutContainer.gPayCheckoutEnable(false)
    }

    if (!this.isCheckoutInventory) {
      itemdetails?.forEach(item => {
        const { skus = [], errorCode = '' } = item
        skus.forEach(newitem => {
          let inventory = newitem?.inventoryProperties
          let inventoryStatus =
            inventory?.available === false &&
            inventory?.backOrdered === true &&
            errorCode
          this.isCheckoutInventory = inventoryStatus
          if (inventoryStatus === true) {
            toastState.setToastMessage('Item is BackOrdered')
            checkoutContainer.paypalCheckoutEnable(false)
            checkoutContainer.applePayCheckoutEnable(false)
            checkoutContainer.gPayCheckoutEnable(false)
          }
        })
      })
    }
    if (!this.isCheckoutInventory) {
      if (isB2BAccount() && !isB2B2C()) {
        const costCenterId = cartContainer?.cartResponse?.costCenterId || ''
        if (costCenterId) {
          this.props.history.push(pageNames.checkout)
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'cart.costcentererrormessage',
              'Please choose a cost center to proceed'
            )
          )
          checkoutContainer.paypalCheckoutEnable(false)
          checkoutContainer.applePayCheckoutEnable(false)
          checkoutContainer.gPayCheckoutEnable(false)
        }
      } else if (getSeparateCartAndCheckout() === true) {
        if (customerContainer?.isRegisterUser) {
          if (
            nuskinSubscriptionContainer.showSubscriptionModal() ||
            nuskinSubscriptionContainer.enableGoToCart
          ) {
            this.isToShowSubscriptionModal = true
          } else {
            this.isToShowSubscriptionModal = false
            if (this.props?.isNativeApp === true) {
              this.props.history?.push(`${pageNames.checkout}?isNativeApp=true`)
            } else {
              this.props.history?.push(pageNames.checkout)
            }

            if (this.enableLiveEvent === 'true') {
              const values = cartContainer?.cartResponse?.value || {}
              trackEnhancedCheckoutEvent({
                type: 'begin_checkout',
                action: { step: '1' },
                products: itemdetails,
                value: values?.grandTotal || '',
              })
            }
            TagManager.dataLayer({
              dataLayer: {
                event: 'checkout button',
                pageTitle: 'viewCart',
                pagePath: '/viewCart',
                userName: cartResponse?.userName || '',
                cartType: cartResponse?.cartType || '',
                productLength: cartResponse?.value?.count || '',
                totalPrice:
                  cartResponse?.currencySign +
                    cartResponse?.value?.grandTotal || '',
                cartDiscount:
                  cartResponse?.currencySign +
                    cartResponse?.value?.itemDiscount || '',
                deliveryCharge:
                  cartResponse?.currencySign +
                    cartResponse?.value?.cartDeliveryCharge || '',
                platform: 'equinox',
              },
            })
          }
        } else {
          if (this.props?.isNativeApp === true) {
            try {
              trackErrorInInstana({
                errorReport: 'Triggering Signin Event',
                errorData: {
                  isRegisterUser: customerContainer?.isRegisterUser,
                  customerResponse: JSON.parse(
                    JSON.stringify(customerContainer?.profileResponse)
                  ),
                },
              })
            } catch (e) {
              console.error('error in instana', e)
            }
            sendNeedToSignIn(true)
          } else {
            this.toggleModal({
              visibility: true,
            })
            //Commented this as payment type should be pre-selected for guest and signup user for express payment
            /*checkoutContainer.paypalCheckoutEnable(false)
            checkoutContainer.applePayCheckoutEnable(false)
            checkoutContainer.gPayCheckoutEnable(false)*/
          }
        }
      }
    }
  }

  renderSubscriptionManageText = () => {
    return (
      <div className="view-cart-subscription-text mb-2">
        <div className="pb-2">
          {i18nTranslate(
            'cart.subscriptionSubTotalText',
            'Subtotal does not include shipping or taxes.'
          )}
        </div>
        <div>
          {i18nTranslate(
            'cart.subscriptionManageText',
            'You may change or cancel your Subscriptions at any time through your Subscription Dashboard (processing times apply).'
          )}
        </div>
      </div>
    )
  }
  render() {
    const cartResponse = cartContainer.cartResponse || {}
    const subsctiptionItemsTotal =
      cartContainer.cartResponse?.properties?.subsctiptionItemsTotal
    const loyaltyAppliedResult = cartResponse?.payments?.find(
      item => item?.type?.toLocaleLowerCase() == 'loyalty'
    )
    const storeCredit = cartContainer.redeemedStoreCreditPoints()
    const currencyCode = cartContainer.cartResponse?.currencyCode || ''
    const promotionDetails =
      cartContainer.cartResponse?.promotionDetails?.promotions || []
    const value = cartContainer.cartResponse?.value || ''
    const balance = cartContainer.cartResponse?.loyalty?.totalPoints || 0
    const initialInput = loyaltyAppliedResult?.amount || 0
    const redeemable =
      cartContainer.cartResponse?.value?.totalLoyaltyPointsToPay || 0
    const {
      className = '',
      isSaveForLater = false,
      isFromViewCart = false,
      isNativeApp = false,
    } = this.props

    const orderTotalLabel =
      this.enableLiveEvent === 'true'
        ? i18nTranslate('cart.orderTotalEvent', 'Order Subtotal')
        : i18nTranslate('cart.orderTotal', 'Order Total')
    const orderTotalTestId =
      this.enableLiveEvent === 'true'
        ? 'qa-cart-order-subtotal'
        : 'qa-cart-order-total'
    const orderTotal = this.formatPrice('grandTotal', value)

    const subscriptionTotalLabel = i18nTranslate(
      'cart.subscriptionTotal',
      'Subscription Subtotal'
    )
    const isCartHasSubscriptionProducts =
      cartContainer.hasSubscriptionProduct() || false
    const isGPSEnabledAndDefault = isGPSEnabled() && isGPSDefaultPayment()
    const isToEnableKlarna =
      getAvailablePaymentsForAddNewSections({
        paymentType: 'klarna',
      }) || false
    const isToShowKlarnaOnSiteMessage =
      !isCartHasSubscriptionProducts &&
      isFromViewCart &&
      !isGPSEnabledAndDefault &&
      isToEnableKlarna
    const enableCartSearchBox =
      APPConfig.getAppConfig()?.enableCartSearchBox == 'true'
    let contextValue
    let isMySiteOrPersonalOfferSite = false
    try {
      if (typeof window !== 'undefined') {
        contextValue = checkMysiteOrPersonalOffer()
        if (
          contextValue === 'personal_offer' ||
          contextValue === 'storefront'
        ) {
          isMySiteOrPersonalOfferSite = true
        }
      }
    } catch (e) {
      console.log('Unable to JSON.parse at renderCartViewButtons >>> ', e)
    }

    const isToShowOTPSearchBox =
      isFromViewCart &&
      enableCartSearchBox &&
      !isNativeApp &&
      !isMySiteOrPersonalOfferSite

    const itemPromotions = []
    cartContainer?.cartResponse?.items?.forEach(item => {
      if (
        item?.promotionDetails &&
        item?.promotionDetails?.promotions &&
        isArray(item?.promotionDetails?.promotions)
      ) {
        itemPromotions.push(...item?.promotionDetails?.promotions)
      }
    })
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    let isRegisterUser = customerContainer?.isRegisterUser || false
    const isToShowFlexibleSubscriptionDrawer =
      ((isFromViewCart && this.isToShowSubscriptionModal) ||
        nuskinSubscriptionContainer.isToShowDisabledModal) &&
      isCartHasSubscriptionProducts &&
      !cartContainer.isCartResponseLoading &&
      isRegisterUser &&
      !nuskinSubscriptionContainer.isSubscriptionApiInProgress

    return (
      <>
        {!useCartTableView && renderCartProductsHeader(isSaveForLater)}
        {cartContainer?.cartCount == 0 &&
        !isSaveForLater &&
        !nuskinSubscriptionContainer.enableGoToCart ? (
          <EmptyCart isCheckout={true} isViewCartPage={isFromViewCart} />
        ) : (
          <>
            <SigninModal
              isVisible={this.isModalVisible}
              onHideSignInModal={() => this.toggleModal(false)}
              checkoutUrl={this.props}
            />
            {/* <Modal
              centered={true}
              size={'lg'}
              className={`modal-wrapper p-0`}
              show={this.isModalVisible}
              onHide={() => this.toggleModal(false)}
              dialogClassName="checkout-dialog">
              <Modal.Header closeButton className={'border-bottom-0'} />
              <Modal.Body>{this.renderSignInAccordion()}</Modal.Body>
            </Modal> */}
            <div
              data-testid="qa-cart-products-container"
              className={`${
                this.props?.isSaveForLater ? 'pb-2 w-100' : 'pb-2'
              } ${
                this.enableLiveEvent === 'true' ? 'live-event-enabled' : ''
              }`}>
              {/* <Container className="d-flex summary-button-wrapper justify-content-between flex-column p-2">
                {isB2BAccount() && (
                  <Col
                    lg={{ offset: 8, span: 4 }}
                    md={{ offset: 6, span: 6 }}
                    xs={12}> */}
              {/*<CostCenterDropDown />*/}
              {/* </Col>
                )}
              </Container> */}
              <Row
                noGutters
                className={`product-wrapper ${className} w-100 product-bg ${
                  isToShowOTPSearchBox ? 'cart-search-wrapper' : ''
                }`}
                data-testid="qa-grouped-products-wrapper">
                <div className="cart-product-collection">
                  {isToShowOTPSearchBox && this.enableLiveEvent !== 'true' && (
                    <ViewCartSearch />
                  )}
                  {this.renderProductBlockView()}
                  {isFromViewCart &&
                    isFlexibleSubscriptionEnabled &&
                    isCartHasSubscriptionProducts &&
                    this.renderSubscriptionManageText()}
                </div>
                {this.props?.isSaveForLater ? (
                  <></>
                ) : (
                  <div className="cart-order-summary">
                    <div className="ordr-sumary" aria-live="polite">
                      <div
                        className="order-sumary-title"
                        data-testid="qa-cart-order-summary">
                        {i18nTranslate('cart.orderSummary', 'Order Summary')}
                      </div>
                      <div className="order-sub-total d-flex">
                        <div
                          className="ordr-sub-txt paragraph-m-bold"
                          // aria-hidden={true}
                          data-testid={`${orderTotalTestId}`}>
                          {orderTotalLabel}
                        </div>
                        <div
                          className="ordr-sub-price"
                          data-testid="qa-cart-order-total-price"
                          aria-label={`${orderTotalLabel} ${orderTotal}`}>
                          {' '}
                          {orderTotal}
                        </div>
                      </div>

                      {subsctiptionItemsTotal > 0 && (
                        <>
                          <div className="ordrs-price-info d-flex">
                            <div
                              className="ordr-total-txt paragraph-l"
                              // aria-hidden={true}
                              data-testid="qa-cart-subscription-sub-total">
                              {subscriptionTotalLabel}
                            </div>
                            <div
                              className="ordr-total-price paragraph-l"
                              data-testid="qa-cart-subscription-total-price"
                              aria-label={`${subscriptionTotalLabel} ${subsctiptionItemsTotal}`}>
                              ${subsctiptionItemsTotal}
                            </div>
                          </div>
                        </>
                      )}

                      <div className="mt-2">
                        <Summary
                          isCheckout={true}
                          customClass="p-0 view-cart-summary-wrapper"
                          itemsPromotions={itemPromotions}
                          math={{
                            ...value,
                            openLoyaltyPoints: balance,
                            redeemedLoyaltyPoints: initialInput,
                            redeemableLoyaltyPoints: redeemable,
                            storeCredit: storeCredit,
                          }}
                          promotionDetails={promotionDetails}
                          currencyCode={currencyCode}
                          showRedeemableSection={true}
                          totalValue={value.priceFacets}
                        />
                      </div>
                      <>{this.renderCartViewButtons()}</>
                      {isToShowKlarnaOnSiteMessage &&
                        this.renderKlarnaMessaging()}
                      <>
                        {isFlexibleSubscriptionEnabled
                          ? isToShowFlexibleSubscriptionDrawer && (
                              <DrawerModal
                                renderView={
                                  this.renderFlexibleSubscriptionModal
                                }
                                show={this.isToShowSubscriptionModal}
                                onHide={this.toggleSubscriptionModal}
                                customClass="cart-single-subscription-modal"
                              />
                            )
                          : ((isFromViewCart &&
                              this.isToShowSubscriptionModal) ||
                              nuskinSubscriptionContainer.isToShowDisabledModal) && (
                              <MultipleProductSubscriptionModal
                                isToShowModal={
                                  this.isToShowSubscriptionModal ||
                                  nuskinSubscriptionContainer.isToShowDisabledModal
                                }
                                toggleSubscriptionModal={
                                  this.toggleSubscriptionModal
                                }
                                type="cart"
                              />
                            )}
                      </>
                    </div>
                  </div>
                )}
              </Row>
            </div>
          </>
        )}
      </>
    )
  }
}

export default CartProducts
export { CartProducts }
