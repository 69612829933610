import { IS_BROWSER } from 'config/appConfig'

/**
 * Converts a locale code object with "lang" and "country" properties
 * into different string formats based on the "type" parameter.
 *
 * @param {Object} localeCode - Object with "lang" and "country" properties
 * @param {string} [type="queryParam"] - Format to convert to.
 * "pathParam", "queryParam", "cookie", or "all"
 * @returns {string|Object} Locale code in the requested format,
 * or an object with all formats if type is "all"
 */
function convertLocaleCode(localeCode, type = 'queryParam') {
  const { lang = '', country = '' } = localeCode

  let localModels = {
    pathParam: `${lang}/${country}`.toLowerCase(),
    queryParam: `${lang}_${country.toUpperCase()}`,
    cookie: `${lang}-${country.toUpperCase()}`,
  }

  return type === 'all' ? localModels : localModels[type]
}

/**
 * Parses the locale code from a URL based on the provided options.
 *
 * Checks the pathname for a locale code in pathParam format,
 * or the searchParams for a locale code in queryParam format.
 *
 * Falls back to the provided defaultLocale if no locale code is found.
 *
 * Returns the locale code in queryParam format e.g. "en_US".
 */
function getLocaleCodeFromUrl(options) {
  const {
    url,
    locationProps,
    at = 'pathParam',
    defaultLocale = '',
    isReverseType = true,
  } = options || {}
  /**
   * @note
   * if any new locale required to be supported then
   * we need to add in this list otherwise fails
   */
  const existingLocales = [
    'cs_CZ',
    'da_DK',
    'de_AT',
    'de_CH',
    'de_DE',
    'de_LU',
    'en_AT',
    'en_AU',
    'en_BE',
    'en_BN',
    'en_CA',
    'en_CH',
    'en_CZ',
    'en_DE',
    'en_DK',
    'en_ES',
    'en_FI',
    'en_FR',
    'en_GB',
    'en_HU',
    'en_ID',
    'en_IE',
    'en_IL',
    'en_IS',
    'en_IT',
    'en_LU',
    'en_MY',
    'en_NL',
    'en_NO',
    'en_NZ',
    'en_PH',
    'en_PL',
    'en_PT',
    'en_RO',
    'en_SE',
    'en_SG',
    'en_SK',
    'en_TH',
    'en_UA',
    'en_US',
    'en_VN',
    'en_ZA',
    'es_AR',
    'es_CL',
    'es_CO',
    'es_ES',
    'es_MX',
    'es_PE',
    'es_US',
    'fi_FI',
    'fr_BE',
    'fr_CA',
    'fr_CH',
    'fr_FR',
    'fr_LU',
    'fr_NC',
    'fr_PF',
    'hu_HU',
    'in_ID',
    'it_CH',
    'it_IT',
    'iw_IL',
    'ja_JP',
    'ja_US',
    'ko_KR',
    'ms_MY',
    'nl_BE',
    'nl_NL',
    'no_NO',
    'pl_PL',
    'pt_PT',
    'ro_RO',
    'ru_UA',
    'sk_SK',
    'sv_FI',
    'sv_SE',
    'th_TH',
    'uk_UA',
    'vi_US',
    'vi_VN',
    'zh_CA',
    'zh_CN',
    'zh_MY',
    'zh_US',
  ]

  let tempUrl = url

  let localeAsArr = defaultLocale?.split('_')

  if (locationProps?.staticContext) {
    tempUrl = locationProps?.staticContext?.originalUrl
  } else if (IS_BROWSER) {
    tempUrl = location.href
  }

  if (tempUrl) {
    // adding dummy domain since express originalUrl will not have request domain
    if (!tempUrl?.startsWith('https://')) {
      tempUrl = `https://domain.com${tempUrl}`
    }
    tempUrl = new URL(tempUrl)

    if (at === 'pathParam') {
      const pathParams = tempUrl.pathname
      if (pathParams) {
        let pathList = pathParams?.split('/')?.filter(Boolean)
        if (pathList.length > 1) {
          localeAsArr = pathList
        }
      }
    } else if (at === 'queryParam') {
      const searchParams = tempUrl.searchParams
      const urlLocale = searchParams?.get('locale')
      if (urlLocale) {
        localeAsArr = urlLocale?.split('_')
      }
    }
  }

  if (localeAsArr.length > 2) {
    /**
     * @note
     * when the pathParam comes more than 3
     * we need to slice two
     */
    localeAsArr = localeAsArr.slice(0, 2)
  }

  if (isReverseType === true) {
    localeAsArr.reverse()
  }

  /**
   * @todo
   * !!! beware, when the URL doesn't have the locale params
   * !!! it will fail to fetch the locale and storeId
   * !!! example: https://domain.com/login/callback
   */

  const localPattern = `${localeAsArr[0]}_${localeAsArr[1]?.toUpperCase()}`

  if (existingLocales.includes(localPattern) === false) {
    localeAsArr = defaultLocale.split('_')
  }

  return `${localeAsArr[0]}_${localeAsArr[1]?.toUpperCase()}`
}

function isLATAM(locale) {
  const LATAMLocales = ['es_AR', 'es_CL', 'es_CO', 'es_ES', 'es_MX', 'es_PE']
  return LATAMLocales.includes(locale)
}

export { convertLocaleCode, getLocaleCodeFromUrl, isLATAM }
