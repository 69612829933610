import React from 'react'
const renderTitle = () => {
  return <div>$</div>
}

export function formInput(props) {
  const {
    isCheckOutPage = false,
    handleValueChange = '',
    appliedStoreCreditPoints = '',
    isAppliedTextShown = false,
  } = props
  const fieldConfig = []
  // let checkoutStoreCreditField = []
  const storeCreditDollarLabel = {
    name: 'storeCreditTitle',
    isToHideLabel: true,
    dataTestId: 'qa-storeCredit-title',
    maxLength: '64',
    columnType: {
      lg: '0',
      sm: '0',
      xs: '0',
    },
    customClassName: 'mb-2 p-2 text-right pr-2',
    ariaLabel: 'storeCredit',
    RenderProps: renderTitle(),
    //placeholderKey: 'checkoutCouponOrPromotional',
  }
  const storeCreditInputField = {
    name: 'storeCredit',
    isToHideLabel: true,
    dataTestId: 'qa-storeCredit',
    maxLength: '64',
    columnType: {
      lg: isCheckOutPage ? (isAppliedTextShown ? '4' : '5') : '10',
      sm: isCheckOutPage ? (isAppliedTextShown ? '4' : '5') : '10',
      xs: isCheckOutPage ? (isAppliedTextShown ? '4' : '5') : '10',
    },
    customClassName: 'mb-2 p-0',
    ariaLabel: 'storeCredit',
    type: 'text',
    //pattern: '[0-9]*',
    inputMode: 'decimal',
    //placeholderKey: 'checkoutCouponOrPromotional',
    onChange: handleValueChange,
    defaultValue: appliedStoreCreditPoints,
    customAttributes: {
      autoComplete: 'off',
    },
  }

  const applyCreditButton = {
    name: 'applyButton',
    type: 'inlineSubmitButton',
    dataTestId: 'qa-store-credit-apply',
    customClassName: 'p-0 mb-2',
    columnType: {
      lg: isCheckOutPage ? (isAppliedTextShown ? '7' : '6') : '11',
      sm: isCheckOutPage ? (isAppliedTextShown ? '7' : '6') : '11',
      xs: isCheckOutPage ? (isAppliedTextShown ? '7' : '6') : '11',
    },
  }

  isCheckOutPage
    ? fieldConfig.push([
        storeCreditDollarLabel,
        storeCreditInputField,
        applyCreditButton,
      ])
    : fieldConfig.push(
        [storeCreditDollarLabel, storeCreditInputField],
        applyCreditButton
      )

  return fieldConfig
}
