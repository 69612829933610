import * as yup from 'yup'
// import { getCreditCardCVV } from 'src/deps'

export function cardCVVSchema() {
  const cvvSchema = Object.assign(
    {}
    // getCreditCardCVV({ cardType: card.provider })
  )
  return yup.object(cvvSchema)
}
