import * as yup from 'yup'
import { i18nTranslate } from 'src/utils'

export const schema = () => {
  const errorText = i18nTranslate(
    'list.emailInvalid',
    'Please enter valid emails seperated by comma'
  )
  const emptyErrorText = i18nTranslate(
    'list.emailInvalidEmpty',
    'Please enter email address of people seperated by comma'
  )
  const schemaData = Object.assign(
    {},
    {
      emailRecipients: yup
        .string()
        .test('emailArrayValidation', errorText, function (value) {
          const emailArray = value ? value.split(',') : []
          return emailArray.every(email => {
            return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
              email.trim()
            )
          })
        })
        .required(emptyErrorText),
    }
  )
  return yup.object(schemaData)
}
