import React from 'react'
import { observer } from 'mobx-react'
import { application } from 'src/utils'
import { FooterLinks, AdditionalLinks } from './Links'
import {
  AiOutlineUser as AccountIconNew,
  AiFillCaretUp as ToggleUp,
  AiOutlineClose as CloseButton,
  AiFillCaretDown as ToggleDown,
} from 'react-icons/ai'
import { customerContainer } from 'src/models'
import { Link } from 'react-router-dom'
import { i18nTranslate } from 'src/utils'
import { mobileMenuState, MobileMenuCategories } from 'src/views/components'
import { signInwithRedirect, signOutWithRedirect } from 'src/utils/signInUtils'
import { APPConfig } from 'config/appConfig'
import { Button, Col, Row } from 'react-bootstrap'
import { observable } from 'mobx'
import './styles.scss'

@observer
class MobileMenu extends React.Component {
  @observable toggleIcon = false
  handleSignOutClick = async () => {
    if (APPConfig.getAppConfig().signInType === 'okta') {
      await signOutWithRedirect()
    } else {
      await customerContainer.logout()
    }
    // reloading page hence intiate the applicaton
    if (typeof window === 'object') {
      window.location.reload()
    }
  }
  handleMobileMenu = () => {
    if (application.isTabletOrSmaller) {
      mobileMenuState.toggleMenuVisibility()
    }
  }

  render() {
    if (application.isTablet && application.isPhone) {
      return <></>
    }
    return (
      <>
        <div
          className={`overlay fixed-top h-100 ${
            mobileMenuState.isMenuVisible ? 'd-block' : 'd-none'
          }
          `}
          onClick={mobileMenuState.toggleMenuVisibility}
        />
        <aside
          className={`pancake fixed-top h-100 overflow-auto bg-white ${
            mobileMenuState.isMenuVisible ? 'open' : 'closed'
          }`}>
          {/* <UserMenu {...this.props} /> */}
          {customerContainer.isRegisterUser ? (
            <span className="mobile-auth-logout" data-testid="qa-signout">
              <AccountIconNew
                className="mr-2"
                style={{ fontSize: '20px', verticalAlign: 'middle' }}
              />
              <span className="pt-2" onClick={signOutWithRedirect}>
                {i18nTranslate('header.signOut', 'Sign Out', {
                  nameSpace: 'ssr-resource',
                })}
              </span>
            </span>
          ) : (
            <>
              <div className="auth-border">
                <Row className="mobile-auth  align-items-center">
                  <Col className="auth-text" sm={9} xs={9}>
                    <span>
                      {i18nTranslate('header.authText', 'Sign Up / Sign In', {
                        nameSpace: 'ssr-resource',
                      })}
                    </span>
                    {this.toggleIcon ? (
                      <ToggleUp
                        className="toggle-icon"
                        onClick={() => {
                          this.toggleIcon = !this.toggleIcon
                        }}
                      />
                    ) : (
                      <ToggleDown
                        className="toggle-icon"
                        onClick={() => {
                          this.toggleIcon = !this.toggleIcon
                        }}
                      />
                    )}
                  </Col>
                  <Col sm={3} xs={3}>
                    <CloseButton
                      className="close-icon"
                      onClick={mobileMenuState.toggleMenuVisibility}
                    />
                  </Col>
                </Row>
                <Row
                  className={
                    this.toggleIcon === false ? '. d-none' : 'show-auth'
                  }>
                  <Col xs={12} sm={12}>
                    <Button
                      className="signin-custom"
                      onClick={signInwithRedirect}>
                      Sign In{' '}
                    </Button>
                  </Col>
                  <Col className="signup-text" xs={12} sm={12}>
                    <span>
                      New customer?{' '}
                      <Link
                        to="/signup"
                        onClick={mobileMenuState.toggleMenuVisibility}>
                        <b>sign up today!</b>
                      </Link>
                    </span>
                  </Col>
                </Row>
              </div>
            </>
          )}
          <MobileMenuCategories {...this.props} />
          <div className="mt-auto mobile-footer-links">
            <AdditionalLinks />
            <FooterLinks />
          </div>
        </aside>
      </>
    )
  }
}

export default MobileMenu
export { MobileMenu }
