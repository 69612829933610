import { createContext } from 'react'

export const ApiContent = createContext({ load() {} })

/**
 * DataLoading is a utility class that handles loading and caching async data.
 * It tracks promises, aggregates results, and provides conveniences like clearing cached data.
 */
export class DataLoading {
  promiseSet = new Set()
  result = []

  /**
   * load() adds a promise to the internal promiseSet to be tracked.
   *
   * When load() is called, the provided promise will be added to
   * the promiseSet. This allows all loaded promises to be aggregated
   * and waited on later using the all() method.
   */
  load(promise) {
    this.promiseSet.add(promise)
  }

  /**
   * hasPromises returns whether there are any promises currently being tracked.
   *
   * Checks if the size of the promiseSet is greater than 0 to determine if any promises are being tracked.
   */
  get hasPromises() {
    return this.promiseSet.size > 0
  }

  /**
   * all() fetches all the promised data that has been loaded into the DataLoading
   * instance. It first checks if there is already cached result data and returns
   * that. Otherwise, it runs Promise.all() on the internal promiseSet to get all
   * the promised data, caches it in this.result, and returns the promised values.
   */
  all() {
    if (this.result.length) {
      return Promise.resolve(this.result)
    }

    const loading = Promise.all(this.promiseSet)
    loading.then(result => {
      this.result = result
    })

    return loading
  }

  /**
   * Clears the cached data and promises.
   * Sets result to empty array and clears the promiseSet.
   */
  clear() {
    this.result = []
    this.promiseSet.clear()
  }
}

/**
 * getDataFromTree fetches data from a context object and returns it with keys.
 *
 * It gets the context value from the contextObj argument, calls .all() on it to get the data,
 * converts the data to have keys using getDataFromArray(), clears the context,
 * and returns the data with keys.
 *
 * This allows fetching and caching data from a context provider in a tree.
 */
export async function getDataFromTree(contextObj) {
  const contextValue = contextObj
  const data = await contextValue.all()
  const dataWithKeys = await getDataFromArray(data)

  contextValue.clear()
  return dataWithKeys
}

/**
 * getDataFromArray takes an array of data and returns an object with the data keyed by the object keys.
 * It loops through the array, gets the keys from each item, and assigns the values to a new object.
 * If no data is passed in, the original data is returned.
 */
export async function getDataFromArray(data) {
  const dataWithKeys = {}
  if (data && data.length) {
    await data.forEach(item => {
      const key = Object.keys(item)
      dataWithKeys[key] = item[key]
    })
    return dataWithKeys
  } else {
    return data
  }
}
