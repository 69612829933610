import {
  IoMdShirt as TshirtIcon,
  IoMdWatch as WatchIcon,
  IoIosMore,
} from 'react-icons/io'
import {
  GiRunningShoe as ShoeIcon,
  GiArmoredPants as PantIcon,
  GiSunglasses as SunGlassIcon,
} from 'react-icons/gi'
import { i18nTranslate } from 'src/utils'

function getCategoryList() {
  return [
    {
      name: i18nTranslate('outfits.top', 'Tops', { nameSpace: 'ssr-resource' }),
      icon: TshirtIcon,
      categoryId: 'shirts',
    },
    {
      name: i18nTranslate('outfits.bottoms', 'Bottoms', {
        nameSpace: 'ssr-resource',
      }),
      icon: PantIcon,
      categoryId: 'pants',
    },
    {
      name: i18nTranslate('outfits.shoes', 'Shoes', {
        nameSpace: 'ssr-resource',
      }),
      icon: ShoeIcon,
      categoryId: 'shoes',
    },
    {
      name: i18nTranslate('outfits.watch', 'Watch', {
        nameSpace: 'ssr-resource',
      }),
      icon: WatchIcon,
      categoryId: 'watch',
    },
    {
      name: i18nTranslate('outfits.sunglass', 'Sunglasses', {
        nameSpace: 'ssr-resource',
      }),
      icon: SunGlassIcon,
      categoryId: 'sunglass',
    },
    {
      name: i18nTranslate('outfits.accessories', 'Accessories', {
        nameSpace: 'ssr-resource',
      }),
      icon: IoIosMore,
      categoryId: 'accessories',
    },
  ]
}

const searchDate = () => [
  {
    key: i18nTranslate('outfitSearch.allDates', 'All Items'),
    value: 0,
  },
  {
    key: i18nTranslate('outfitSearch.threeMonths', 'Last 3 Months'),
    value: 3,
  },
  {
    key: i18nTranslate('outfitSearch.sixMonths', 'Last 6 Months'),
    value: 6,
  },
  {
    key: i18nTranslate('outfitSearch.twelveMonths', 'Last 1 Year'),
    value: 12,
  },
]

const mainCategories = ['shirts', 'pants', 'shoes', 'watch', 'sunglass']

const recommendedProducts = [
  {
    key: 'outs33',
    id: 'outs33',
    name: "U-TURN Men's Cotton Solid Half Sleeve Shirt",
    outfitItemType: 'recommended',
    outFitItem: 'new',
    properties: {
      itemType: 'SKU',
      quantity: '1',
      productId: 'outp33',
      imageURL:
        'https://images-na.ssl-images-amazon.com/images/I/71pE6NHd-2L._AC_UL1500_.jpg',
      type: 'shirts',
      categoryId: 'tshirts',
    },
  },
  {
    key: 'outs3',
    id: 'outs3',
    name: "layasa Men's Air Series Mesh Casual,Walking,Running/Gymwear Shoes",
    outfitItemType: 'recommended',
    outFitItem: 'new',
    properties: {
      itemType: 'SKU',
      quantity: '1',
      productId: 'outp3',
      imageURL:
        'https://images-na.ssl-images-amazon.com/images/I/41juN%2BzGOKL.jpg',
      type: 'shoes',
      categoryId: 'shoes',
    },
  },
  {
    key: 'outs40',
    id: 'outs40',
    name: 'RUF & TUF Solid Navy Blue Coloured Cotton Blend Jeans',
    outfitItemType: 'recommended',
    outFitItem: 'new',
    properties: {
      itemType: 'SKU',
      quantity: '1',
      productId: 'outp40',
      imageURL:
        'https://images-na.ssl-images-amazon.com/images/I/61fgbeyqe8L._AC_UL1100_.jpg',
      type: 'pants',
      categoryId: 'tracks',
    },
  },
  {
    key: 'outs10',
    id: 'outs10',
    name: "Men's Slim Runway Quartz Watch with Stainless Steel Strap",
    outfitItemType: 'recommended',
    outFitItem: 'new',
    properties: {
      itemType: 'SKU',
      quantity: '1',
      productId: 'outp10',
      imageURL:
        'https://images-na.ssl-images-amazon.com/images/I/81b63Ck2AiL._UX679_.jpg',
      type: 'watch',
      categoryId: 'watch',
    },
  },
  {
    key: 'outs11',
    id: 'outs11',
    name: 'In Style Eyes Lovin Maui Wrap Polarized Nearly invisible Line Bifocal Sunglasses',
    outfitItemType: 'recommended',
    outFitItem: 'new',
    properties: {
      itemType: 'SKU',
      quantity: '1',
      productId: 'outp11',
      imageURL:
        'https://images-na.ssl-images-amazon.com/images/I/61MbIqlU4vL._UY500_.jpg',
      type: 'sunglass',
      categoryId: 'sunglass',
    },
  },
]

export { getCategoryList, searchDate, recommendedProducts, mainCategories }
