export const prefillFieldData = ({
  fieldName = '',
  fieldValue = '',
  fieldArray = [],
  setFieldValue = function () {},
  setFieldTouched = function () {},
  isToDisableField = true,
}) => {
  if (fieldValue) {
    setFieldValue(fieldName, fieldValue)
    setFieldTouched(fieldName, true)
    fieldArray.disabled = isToDisableField
  } else {
    setFieldValue(fieldName, fieldValue)
    setFieldTouched(fieldName, false)
    fieldArray.disabled = false
  }
}

export const getFieldDataToPrefill = (formjson = [], fieldName = '') => {
  let selectedFieldArray = formjson?.find(formFields =>
    formFields?.find(field => field?.name === fieldName)
  )
  let selectedField = selectedFieldArray?.find(
    field => field?.name === fieldName
  )
  return selectedField
}
