import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import {
  i18nTranslate,
  checkMysiteOrPersonalOffer,
  trackErrorInInstana,
  isExpressCheckout,
} from 'src/utils'
import isArray from 'lodash/isArray'
import uniqBy from 'lodash/uniqBy'
import { IoIosRemove, IoIosAdd } from 'react-icons/io'
import {
  tryJSONParse,
  getImageFromProperty,
  getLiveEventStatus,
} from 'src/utils'
import { cartContainer, checkoutContainer } from 'src/models'
import {
  Summary,
  MiniCartProductBlock,
  PromoWalletCheckout,
  AccordionBlock,
  CouponCode,
} from 'src/views/components'
import {
  cartCategoryType,
  cartCategoryTypeFlexSubs,
} from 'src/views/components/CartBlock/fixture'
import { SubmitOrder } from 'src/views/components/CheckoutAccordions'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import { isPromoWalletEnabled } from 'src/views/components/CheckoutAccordions/deps'
import './styles.scss'

@observer
class FloatingRightBar extends React.Component {
  freeGiftProductsList = []
  enableLiveEvent = getLiveEventStatus()
  @observable openAccordion = true

  @computed
  get cartproducts() {
    return (cartContainer?.cartResponse?.items || []).map(item => {
      const cartProduct = {
        name: item?.skus?.[0]?.skuProperties?.name || '',
        // imageURL: item?.skus?.[0]?.skuProperties?.imageURL || '',
        imageURL: getImageFromProperty(item?.skus?.[0]?.skuProperties),
        quantity: item.quantity || 0,
        isPreOrder: tryJSONParse(
          item?.skus?.[0]?.skuProperties?.isPreOrder || false
        ),
        isSubscription: item?.isSubscription || false,
        properties: item?.properties || {},
        itemTotalOriginalPrice: item?.totalValue?.originalPrice || 0,
        itemTotalPriceAfterDiscount: item?.totalValue?.priceAfterDiscount || 0,
        itemTotalPV: item?.totalValue?.priceFacets?.PV?.PV || 0,
        itemTotalPV_AfterDiscount:
          item?.totalValue?.priceFacets?.PV?.PVAfterDiscount || 0,
        skuId: item?.skus?.[0]?.skuId || '',
      }
      //CX16-9904 - Flexible Subscription - Start
      if (isFlexibleSubscriptionFlagEnabled() || false) {
        cartProduct.subscriptionFrequency =
          `${item?.subscription?.frequencyPeriod}-${item?.subscription?.frequencyType}` ||
          ''
        cartProduct.overAllDiscount = item?.totalValue?.overAllDiscount || 0
      }
      return cartProduct
      //CX16-9904 - Flexible Subscription - End
    })
  }

  renderIcon = isOpen => {
    if (isOpen) {
      return (
        <IoIosRemove
          aria-label={i18nTranslate('icons.upwardarrow', 'upward arrow icon', {
            nameSpace: 'ssr-resource',
          })}
        />
      )
    }

    return (
      <IoIosAdd
        style={{
          opacity: '1',
        }}
        aria-label={i18nTranslate(
          'icons.downwardarrow',
          'downward arrow icon',
          {
            nameSpace: 'ssr-resource',
          }
        )}
      />
    )
  }
  render() {
    const {
      isMultipleAdressActive,
      handleClick,
      history,
      enableSubmit,
      isCheckout = false,
      handleNext,
      isFromPDP,
    } = this.props || {}
    const title = ''
    const cartResponse = cartContainer?.cartResponse || {}
    const subsctiptionItemsTotal =
      cartContainer.cartResponse?.properties?.subsctiptionItemsTotal || 0
    const loyaltyAppliedResult = cartResponse.payments?.find(
      item => item?.type?.toLocaleLowerCase() === 'loyalty'
    )
    const redeemedLoyaltyPoints = loyaltyAppliedResult?.amount || 0
    const balance = cartContainer?.cartResponse?.loyalty?.totalPoints || 0
    const value = cartContainer?.cartResponse?.value || ''
    const storeCredit = cartContainer.redeemedStoreCreditPoints()
    const otherChargesIncludingTax = value?.otherChargesIncludingTax || 0
    const totalInclusiveTax =
      cartContainer?.cartResponse?.value?.totalInclusiveTax || 0
    let isPromoWallet = isPromoWalletEnabled() || false

    if (isExpressCheckout()) {
      isPromoWallet = false
    }

    const summaryValues = {
      priceAfterMarkdown: value.priceAfterMarkdown,
      cartDeliveryCharge: value.cartDeliveryCharge,
      totalDiscount: value.totalDiscount,
      overAllTax: value.overAllTax,
      grandTotal: value.grandTotal - redeemedLoyaltyPoints - storeCredit,
      overAllDiscount: value.overAllDiscount,
      redeemedLoyaltyPoints: redeemedLoyaltyPoints,
      redeemableLoyaltyPoints: value.totalLoyaltyPointsToPay,
      openLoyaltyPoints: balance,
      count: value.count,
      cartDeliveryChargeDiscount: value.cartDeliveryChargeDiscount,
      storeCredit: storeCredit,
      cartDeliveryChargeAfterDiscount: value.cartDeliveryChargeAfterDiscount,
      loyaltyDiscount: value?.loyaltyDiscount,
      otherChargesIncludingTax: otherChargesIncludingTax,
      otherCharges: value?.otherCharges,
      totalInclusiveTax: totalInclusiveTax,
    }

    const items = this.cartproducts

    //CX16-9904 - Flexible Subscription - Start
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    let typeObj = {}
    if (isFlexibleSubscriptionEnabled) {
      typeObj = { oneTimeType: [], subscriptionType: [], giftType: [] }
    }
    //CX16-9904 - Flexible Subscription - End

    items.map(item => {
      if (item.properties && item.properties.isGiftItem) {
        if ('giftType' in typeObj) {
          let subProd = typeObj['giftType']
          subProd.push(item)
          typeObj['giftType'] = subProd
        } else {
          let subProd = []
          subProd.push(item)
          typeObj['giftType'] = subProd
        }
      } else if (item.isSubscription) {
        if ('subscriptionType' in typeObj) {
          let subProd = typeObj['subscriptionType']
          subProd.push(item)
          typeObj['subscriptionType'] = subProd
        } else {
          let subProd = []
          subProd.push(item)
          typeObj['subscriptionType'] = subProd
        }
      } else {
        if ('oneTimeType' in typeObj) {
          let subProd = typeObj['oneTimeType']
          subProd.push(item)
          typeObj['oneTimeType'] = subProd
        } else {
          let subProd = []
          subProd.push(item)
          typeObj['oneTimeType'] = subProd
        }
      }
    })

    this.freeGiftProductsList = typeObj?.giftType || []
    typeObj.giftType =
      (typeof typeObj?.giftType !== 'undefined' &&
        typeObj?.giftType.length > 0 &&
        uniqBy(typeObj?.giftType, freeGift => freeGift?.skuId)) ||
      []

    let productTypeArray = []
    for (let key in typeObj) {
      if (typeObj.hasOwnProperty(key) && typeObj[key].length > 0) {
        productTypeArray.push(key)
      }
    }

    const itemPromotions = []
    cartContainer?.cartResponse?.items?.forEach(item => {
      if (
        item?.promotionDetails &&
        item?.promotionDetails?.promotions &&
        isArray(item?.promotionDetails?.promotions)
      ) {
        itemPromotions.push(...item?.promotionDetails?.promotions)
      }
    })

    let isToDisplayPromoWallet = false
    if (isPromoWalletEnabled()) {
      isToDisplayPromoWallet = true
      let contextValue = checkMysiteOrPersonalOffer()
      if (contextValue === 'personal_offer' || contextValue === 'storefront') {
        isToDisplayPromoWallet =
          !checkoutContainer.hideCouponCodeForPOAndMysite() //Check for PO and Mysite Flags to hide PromoWallet
      }
    }

    trackErrorInInstana({
      errorReport:
        'Is PromoWallet Section Displayed in Checkout Page >> FloatingRightBar',
      errorData: isToDisplayPromoWallet,
    })

    return (
      <div className="checkout-floating-bar-wrapper">
        {isToDisplayPromoWallet && (
          <AccordionBlock
            hasArrow={true}
            dataTestId="qa-promo-view"
            isActive={this.openAccordion}
            onClick={() => (this.openAccordion = !this.openAccordion)}
            renderIcon={this.renderIcon}
            aria-expanded={this.openAccordion}
            title={i18nTranslate('checkout.promocode', 'Promo Code')}
            className="promo-accordion">
            <PromoWalletCheckout
              math={summaryValues}
              onContinue={this.props?.onContinue}
              isStoreCredit={this.props?.isStoreCredit}
              updateFlowPaths={this.props?.updateFlowPaths}
            />
          </AccordionBlock>
        )}
        <div
          id="checkout-floating-bar"
          className={`summary-wrapper d-flex flex-column ${
            isExpressCheckout()
              ? `checkout-bar ${
                  isFromPDP ? 'checkout-bar-pdp p-0' : 'p-0 p-md-3'
                }`
              : 'checkout-floating-bar p-3'
          }`}>
          {!isFlexibleSubscriptionEnabled && (
            <div className="font-weight-bold text-uppercase pb-3 paragraph-m-bold">
              {this.enableLiveEvent === 'true'
                ? ''
                : i18nTranslate('checkout.summary', 'Order Overview')}
            </div>
          )}
          {isExpressCheckout() && (
            <h5
              className={`${
                isFromPDP ? 'd-block' : 'd-block d-md-none'
              } font-weight-bold mb-3`}>
              {i18nTranslate('cart.orderSummary', 'Order Summary')}
            </h5>
          )}
          {isArray(productTypeArray) &&
            productTypeArray.map((val, idx) => {
              const prClassName =
                'review-item-category-heading font-weight-bold mb-2' +
                (val === 'giftType' ? ' text-success' : '') +
                (this.enableLiveEvent === 'true'
                  ? ' review-item-category-heading-event'
                  : '')

              return (
                <Fragment key={`product-type-arr-${idx}`}>
                  <div className={prClassName} data-testid="qa-minicart-title">
                    {this.enableLiveEvent === 'true'
                      ? i18nTranslate('checkout.summary.event', 'Summary')
                      : isFlexibleSubscriptionEnabled
                      ? cartCategoryTypeFlexSubs()[val]?.value
                      : cartCategoryType[val]
                      ? cartCategoryType[val].value
                      : ''}
                  </div>
                  {isArray(typeObj[val]) &&
                    typeObj[val].map((product, index) => {
                      return (
                        <MiniCartProductBlock
                          key={`mini-product-${index}`}
                          {...cartContainer.getCombineFreeGiftQty(
                            product?.skuId || '',
                            this.freeGiftProductsList
                          )}
                          {...product}
                        />
                      )
                    })}
                </Fragment>
              )
            })}

          {!isExpressCheckout() &&
            (this.enableLiveEvent === 'true' ? (
              <div className="font-weight-bold pb-3 text-uppercase paragraph-m-bold">
                {i18nTranslate('checkout.summary', 'Order Overview')}
              </div>
            ) : isFlexibleSubscriptionEnabled ? (
              <div
                className="order-summary-title"
                data-testid="qa-checkout-order-summary">
                {i18nTranslate('cart.orderSummary', 'Order Summary')}
              </div>
            ) : (
              <div
                className="font-weight-bold pb-3 text-uppercase paragraph-m-bold"
                data-testid="qa-checkout-order-overview">
                {i18nTranslate('checkout.summary', 'Order Overview')}
              </div>
            ))}
          {isExpressCheckout() && (
            <CouponCode
              onContinue={handleNext}
              title={title}
              {...this.props}
              isCheckout={isCheckout}
            />
          )}
          {subsctiptionItemsTotal > 0 && (
            <div className="ordrs-price-info d-flex m-0">
              <div className="ordr-total-txt paragraph-l">
                {isFlexibleSubscriptionEnabled
                  ? i18nTranslate(
                      'cart.subscriptionItemsTotal',
                      'Subscription items subtotal'
                    )
                  : i18nTranslate(
                      'cart.subscriptionTotal',
                      'Subscription Subtotal'
                    )}
              </div>
              <div className="ordr-total-price paragraph-l">
                ${subsctiptionItemsTotal}
              </div>
            </div>
          )}
          <Summary
            showColon={false}
            isMultipleAdressActive={isMultipleAdressActive}
            isReviewSection={true}
            math={summaryValues}
            itemsPromotions={itemPromotions}
            promotionDetails={
              cartContainer?.cartResponse?.promotionDetails?.promotions || []
            }
            customPriceView={cartContainer?.cartResponse?.properties || ''}
            showRedeemableSection={true}
            totalValue={value.priceFacets}
            isFromPDP={isFromPDP || false}
          />
          {/* {isB2BAccount() && (
          <CostCenterDropDown isReviewSection={true} className="my-3" />
        )} */}
          {!isExpressCheckout() && (
            <SubmitOrder
              onEdit={handleClick}
              history={history}
              enableSubmit={enableSubmit}
              alipayInitiate={this.props?.alipayInitiate}
              closeAlipayInitiate={this.props?.closeAlipayInitiate}
              // paypalInitiate={this.props?.paypalInitiate}
              paypalToken={this.props?.paypalToken}
              venmoToken={this.props?.venmoToken}
              googlePayToken={this.props?.googlePayToken}
              applepayToken={this.props?.applepayToken}
            />
          )}
        </div>
      </div>
    )
  }
}

export { FloatingRightBar }
// export default FloatingRightBar
export default withTranslation(['csr-resource'])(FloatingRightBar)
