import React from 'react'
import { Button, Form, Image } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import {
  getLocaleString,
  IS_BROWSER,
  checkMysiteOrPersonalOffer,
} from 'src/utils'
import {
  SubscriptionDropDown,
  Icons,
  ConfirmationModal,
} from 'src/views/components'
import { VARIANT_NODES } from 'config/appConfig'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { ProductPrice } from 'src/views/components/ProductComponents'
import { CartScanCardWithInfo } from '../CartScanCardWithInfo'
import { SubscriptionQuantity } from './SubscriptionQuantity'
import { Link } from 'react-router-dom'
import { pageNames } from 'src/routes/pathParams'
import { nuskinSubscriptionContainer } from 'src/models'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { quickViewState } from 'src/views/components/QuickView/state'
import isEmpty from 'lodash/isEmpty'
import './styles.scss'

@observer
class SubscriptionProductItem extends React.Component {
  @observable isToShowConfirmationModal = false
  @observable isSubscribeCheckboxChecked = false
  @observable isSubscriptionPage = false

  constructor(props) {
    super(props)

    if (IS_BROWSER) {
      this.isSubscriptionPage = window?.location?.pathname?.includes(
        pageNames.myaccountsubscriptions
      )
    }
  }

  renderSubscribedMessage = () => {
    return (
      <div className="subscribed-message">
        <p className="mb-0 text-center">
          {i18nTranslate(
            'subscription.subscribedMessage',
            'You already subscribed to this item. Go to Subscription Manage to edit this product. The product will be removed from your cart.'
          )}
        </p>
      </div>
    )
  }

  handleMoveToCart = async (cartItemId, productName, clickedProductSkuId) => {
    const sameProductBlockCount =
      nuskinSubscriptionContainer.getClickedProductCountInCart(
        clickedProductSkuId
      )

    if (sameProductBlockCount.length > 1) {
      nuskinSubscriptionContainer.handleAlreadyOTPToast()
    } else {
      this.isSubscribeCheckboxChecked = true
      await nuskinSubscriptionContainer.oneTimeProductConversion(
        cartItemId,
        productName
      )
    }
  }

  renderMoveToCartCheckbox = product => {
    const { cartItemId = '', productName = '' } = product
    const { tabIndex = false, isHidden = false } = this.props
    const clickedProductSkuId =
      product?.skuId ||
      product?.product?.skuId ||
      product?.product?.productId ||
      product?.productId ||
      ''

    return (
      <div className={'p-0 mt-2 subscription-move-to-cart'}>
        <Form.Check
          type="checkbox"
          tabIndex={isHidden ? -1 : 0}
          className="pl-0 py-2"
          label={i18nTranslate('label.moveToCart', 'Move To Cart')}
          id={cartItemId}
          checked={this.isSubscribeCheckboxChecked}
          onChange={() => {
            this.handleMoveToCart(cartItemId, productName, clickedProductSkuId)
          }}
        />
      </div>
    )
  }

  renderVariantDetails = productDetails => {
    return VARIANT_NODES?.map(skuKey => {
      const skuValue = productDetails[skuKey]
      if (skuValue) {
        return (
          <div
            key={`variant-${skuKey}`}
            className="p-0 paragraph-m"
            data-testid={`qa-product-${skuKey}`}>
            {getLocaleString()[skuKey]}: {skuValue}
          </div>
        )
      }
      return <></>
    })
  }

  renderTotalFromProductPromotionResponse() {
    const product = this.props?.product || {}
    const currencySymbol = product?.transactionPrice?.currencySymbol || '$'
    const totalValue = product?.isBundle
      ? productState.bundle_totalValueForSubscriptionPopup
      : this.props.isFromQuickView
      ? quickViewState.normalPdt_totalValueForSubscriptionPopup
      : productState?.normalPdt_totalValueForSubscriptionPopup
    const isPriceEmpty = isEmpty(totalValue)
    if (isPriceEmpty) {
      return ''
    }
    let totaldiscount = totalValue?.totaldiscount || 0
    const originalPrice = totalValue?.originalPrice || 0
    const priceAfterDiscount = totalValue?.priceAfterDiscount || 0

    const originalPriceWithCurrencySymbol =
      currencySymbol + originalPrice.toFixed(2)
    const priceAfterDiscountWithCurrencySymbol =
      currencySymbol + priceAfterDiscount.toFixed(2)
    const overallPriceForAria =
      i18nTranslate('strikeThroughPrice', 'Strike through ') +
      originalPriceWithCurrencySymbol +
      ' ' +
      priceAfterDiscountWithCurrencySymbol +
      ' ' +
      'Total'
    let isShowStrikeThroughPrice = true
    if (
      originalPrice &&
      priceAfterDiscount &&
      originalPrice < priceAfterDiscount
    ) {
      // for negative promotion
      isShowStrikeThroughPrice = false
    }
    /* @##pdp_price_subscriptionModal total price */
    /* for both pdp and quickview */
    /* for both normal and kit related Modals */

    if (
      (this.props.product?.type === 'bundle' ||
        this.props.product?.type === 'kit') &&
      this.props.isModalFromPDP
    ) {
      isShowStrikeThroughPrice = false
      // CX12-10498, CX12-8898
    }

    return (
      <>
        <span className="mr-1" aria-hidden="true">
          {i18nTranslate('subscriptionModal.subTotal', 'Subtotal')}
          {':'}
        </span>
        {totaldiscount && priceAfterDiscount ? (
          <>
            {isShowStrikeThroughPrice && (
              <span
                className="strikeThrough pr-2"
                aria-label={overallPriceForAria}>
                {originalPriceWithCurrencySymbol}
              </span>
            )}
            <span aria-hidden="true">
              {priceAfterDiscountWithCurrencySymbol}
            </span>
          </>
        ) : priceAfterDiscount === 0 ? (
          <>
            <span
              className="strikeThrough pr-2"
              aria-label={overallPriceForAria}>
              {originalPriceWithCurrencySymbol}
            </span>
            <span aria-hidden="true">
              {priceAfterDiscountWithCurrencySymbol}
            </span>
          </>
        ) : (
          <span aria-label={overallPriceForAria}>
            {originalPriceWithCurrencySymbol}
          </span>
        )}
      </>
    )
  }

  renderProductDetails = product => {
    const {
      name = '',
      productName = '',
      skuId = '',
      type = '',
      productId = '',
      slug = '',
      productUrl = '',
      properties = {},
    } = product
    const enablePDPNavigation = this.props?.enablePDPNavigation || false
    const isAlreadySubscribed = this.props?.isAlreadySubscribed || false
    const productOrSlugId = slug || properties?.slug || productId || ''

    let productLink = ''

    if (IS_BROWSER) {
      productLink = productUrl || `${pageNames.product}/${productOrSlugId}`
    }

    /* @##pdp_price_subscriptionModal unit price*/
    /* for pdp, bundles, quickview, cart subscribe checkbox, quickadd in dashboard */

    // @##pdp_price_todo qty 1 calls to be removed, and used from normalPdt_unitValueForSubscriptionPopup
    const unitValue = product?.isBundle
      ? productState.bundle_unitValueForSubscriptionPopup
      : this.props.isFromQuickView
      ? quickViewState.normalPdt_unitValueForSubscriptionPopup
      : productState?.normalPdt_unitValueForSubscriptionPopup
    const { isHidden = false } = this.props
    const contextText = checkMysiteOrPersonalOffer()
    const isToEnableProductNavigation =
      enablePDPNavigation && !isAlreadySubscribed && contextText == ''

    return (
      <>
        <div
          data-testid="qa-product-name"
          className={`subs-product-name  ${
            isToEnableProductNavigation ? '' : 'disable-navigation'
          }`}>
          <Link
            to={isToEnableProductNavigation ? `${productLink}` : '#'}
            tabIndex={
              !isHidden && enablePDPNavigation && !isAlreadySubscribed ? 0 : -1
            }>
            {name || productName}
          </Link>
        </div>
        {skuId && (
          <div data-testid="qa-product-number" className="p-0 product-sku-id">
            <span className="paragraph-m">
              {i18nTranslate('subscription.itemcode', 'Item')} #{' '}
            </span>
            :<span className="ml-1 paragraph-m">{skuId}</span>
          </div>
        )}
        {this.renderVariantDetails(product)}
        {
          <>
            {/* for pdp, bundles, quickview, cart subscribe checkbox, quickadd in dashboard */}
            {/* @##pdp_price_subscriptionModal unit price*/}
            <ProductPrice
              {...product}
              isBundle={product?.isBundle}
              totalValue={
                this.props.isPopupForMovingCartProductsToDashboard
                  ? product?.totalValue
                  : unitValue
              }
              /* ^ value for price */
              isSubscriptionModalUnitPrice={true}
              isModalFromPDP={this.props?.isModalFromPDP || false}
            />
          </>
        }
        {(type === 'bundle' || type === 'kit') && this.isSubscriptionPage && (
          <div className="pt-2">
            <Link to={`${productLink}`}>
              <u>
                {i18nTranslate(
                  'subscriptionModal.productItem.viewItems',
                  'View Items'
                )}
              </u>
            </Link>
          </div>
        )}
      </>
    )
  }

  toggleDeleteConfirmationModal = () => {
    this.isToShowConfirmationModal = !this.isToShowConfirmationModal
  }

  renderDeleteIcon = props => {
    const {
      isAlreadySubscribed = false,
      product = '',
      isHidden = false,
    } = this.props
    const productName = product?.productName || ''

    return (
      <div className="product-delete-icon-wrapper d-flex justify-content-end align-items-end p-0 m-0">
        <Button
          tabIndex={isHidden ? -1 : 0}
          aria-label={`${i18nTranslate(
            'cart.remove',
            'Remove'
          )} ${productName}`}
          className="product-delete-button pr-0 pb-0 "
          onClick={() => this.toggleDeleteConfirmationModal()}
          variant="transparent"
          // disabled={isAlreadySubscribed} // CX15-8945
          data-testid="qa-product-remove">
          <Icons
            name="CRUD"
            icontype="delete"
            customicon="Delete"
            className="sub-product-delete-icon"
            width="20px"
            alt={i18nTranslate('subscription.trashIcon', 'trash icon')}
            fill="#3a3a3a"
          />
        </Button>
      </div>
    )
  }

  renderProductImage = (props, type) => {
    const {
      imageURL = '',
      productName = '',
      primaryimage = '',
      productUrl = '',
      slug = '',
      productId = '',
      isBundle = false,
      properties = {},
    } = props
    const { isHidden = false } = this.props

    const imageSrc = primaryimage || imageURL || imagePlaceholder
    const enablePDPNavigation = this.props?.enablePDPNavigation || false
    const isAlreadySubscribed = this.props?.isAlreadySubscribed || false
    const productOrSlugId = slug || properties?.slug || productId || ''
    const productLink = productUrl || `${pageNames.product}/${productOrSlugId}`
    const contextText = checkMysiteOrPersonalOffer()
    const shouldEnableNavigation =
      enablePDPNavigation && !isAlreadySubscribed && contextText == ''

    return (
      <div
        className={`w-50 subs-product-img pr-3 ${
          shouldEnableNavigation ? '' : 'disable-navigation'
        }`}>
        <Link
          to={shouldEnableNavigation ? `${productLink}` : '#'}
          tabIndex={isHidden || !shouldEnableNavigation ? -1 : 0}>
          <Image
            data-testid="qa-product-image"
            src={imageSrc}
            onError={e => {
              if (e.target.src != imagePlaceholder) {
                e.target.onerror = null
                e.target.src = imagePlaceholder
              }
            }}
            alt={productName}
          />
        </Link>
        {isBundle &&
          this.props.isPopupForMovingCartProductsToDashboard &&
          !isAlreadySubscribed &&
          contextText == '' && (
            <div className="text-center view-items pt-2">
              <Link to={`${productLink}`} tabIndex={isHidden ? -1 : 0}>
                <u>
                  {i18nTranslate(
                    'subscriptionModal.productItem.viewItems',
                    'View Items'
                  )}
                </u>
              </Link>
            </div>
          )}
      </div>
    )
  }

  renderProductInfoSection = props => {
    const {
      product = {},
      enableMoveToCart = false,
      enableShowScanCard = false,
      type = '',
      bundleInfo = {},
      isPopupForMovingCartProductsToDashboard = false,
    } = props
    return (
      <div className="subs-product-details w-100 d-flex">
        {this.renderProductImage(product, type)}
        <div className="w-50 product-wrapper">
          {this.renderProductDetails(product)}
          {enableMoveToCart && this.renderMoveToCartCheckbox(product)}
          {enableShowScanCard && (
            <CartScanCardWithInfo
              {...product}
              bundleInfo={bundleInfo}
              page={type}
              isPopupForMovingCartProductsToDashboard={
                isPopupForMovingCartProductsToDashboard
              }
            />
          )}
        </div>
      </div>
    )
  }

  handleFrequencyChange = (quantity, itemId, subscriptionReq, isOneTime) => {
    this.props?.singleProductItemState.updateFrequency(
      quantity,
      itemId,
      subscriptionReq,
      isOneTime
    )
  }

  handleQuantityChange = async count => {
    await this.props?.singleProductItemState?.updateQuantity(count)
  }

  render() {
    let {
      product = {},
      isAlreadySubscribed = false,
      handleDeleteCartItem = function () {},
      isHidden = false,
      itemType = '',
    } = this.props || {}
    const { subscription = {}, cartItemId = '', quantity = 1 } = product
    const { frequencyPeriod = '', frequencyType = '' } = subscription

    try {
      product = JSON.parse(JSON.stringify(product)) // was getting one console error on assigning any nodes to product json directly
      product.isFromSubscriptionModal = true
      product.isSubscriptionPromotion = true
    } catch (e) {
      console.log('error in JSON file', e)
    }

    return (
      <>
        <div className="subs-product-wrapper">
          <div className="w-100 d-flex mb-3">
            {this.props?.isPopupForMovingCartProductsToDashboard &&
              !isAlreadySubscribed &&
              this.renderDeleteIcon(this.props)}
            {this.renderProductInfoSection(this.props)}
          </div>
          {!isAlreadySubscribed && (
            <div
              className={`w-100 quantity-freq-wrapper d-flex justify-content-between ${
                isHidden ? 'invisible' : 'visible'
              }`}>
              <SubscriptionQuantity
                {...this.props}
                productQuantityCount={
                  this.props?.singleProductItemState?.productQuantity ||
                  quantity
                }
                handleQuantityChange={count => {
                  const cartItemId = this.props.product.cartItemId || ''
                  this.handleQuantityChange(count)
                  this.props?.handleQuantityChange &&
                    this.props?.handleQuantityChange(count, cartItemId)
                }}
                handleQuantityChangePromotion={
                  this.props?.handleQuantityChangePromotion
                }
                product={product}
              />
              <SubscriptionDropDown
                isSubscriptionModal={true}
                isSubscriptionPage={this.isSubscriptionPage}
                frequencyPeriod={frequencyPeriod}
                frequencyType={frequencyType}
                itemId={cartItemId}
                quantity={
                  this.props?.singleProductItemState?.productQuantity ||
                  quantity
                }
                handleSubscriptionChange={
                  this.props?.handleFrequencyChange ||
                  this.handleFrequencyChange
                }
                itemType={itemType}
                isFromModal={true}
              />
            </div>
          )}
          {!this.props?.isPopupForMovingCartProductsToDashboard && (
            <div className="p-0 font-weight-bold subscription-total mt-4">
              {this.renderTotalFromProductPromotionResponse()}
            </div>
          )}
          {isAlreadySubscribed === true && (
            <>
              <div className="ssm-product-mask" />
              {this.renderSubscribedMessage()}
            </>
          )}
        </div>
        {this.isToShowConfirmationModal && (
          <ConfirmationModal
            showConfirmationModal={this.isToShowConfirmationModal}
            handleConfirm={() => {
              this.toggleDeleteConfirmationModal()
              handleDeleteCartItem(this.props)
            }}
            handleCancel={this.toggleDeleteConfirmationModal}
            handleHide={this.toggleDeleteConfirmationModal}
            modalMessage={i18nTranslate(
              'subscriptionModal.productItem.sureToDeleteItem',
              'Are you sure you want to delete this item?'
            )}
            confirmButtonText={i18nTranslate('subscription.yes', 'YES')}
            cancelButtonText={i18nTranslate('subscription.cancel', 'CANCEL')}
            isFromSubscriptionProductItem={true}
          />
        )}
      </>
    )
  }
}

export { SubscriptionProductItem }
export default SubscriptionProductItem
