import React from 'react'
import { Container } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { CommonForm } from 'src/views/components'
import {
  addressContainer,
  tokenExContainer,
  storeContainer,
  checkoutContainer,
  cartContainer,
  accountsAddressContainer,
  subscriptionContainer,
  customerContainer,
} from 'src/models'
import { renderFormInput } from './formInput'
import { schema } from './validationSchema'
import { isExpressCheckout } from 'src/utils'
import './styles.scss'

@observer
class PaymentForm extends React.Component {
  @observable shouldRenderAddressForm = false
  @observable addPaymentToProfile = true
  @observable addressChangeFlag = {}
  @observable country = ''
  @observable cardExipry = ''
  @observable cardFirstname = ''
  @observable cardnumber = ''
  @observable expiryFlag = true
  @observable firstNameFlag = true
  @observable cardnumberFlag = true
  @observable savePaymentCheckboxFlag = true
  @observable addressCheckboxFlag = true
  @observable defaultPaymentCheckbox = false
  @observable defaultPaymentCheckboxFlag = false
  @observable selectedCountry =
    this.props?.paymentData?.billingAddress?.country ||
    storeContainer.storeIDValue

  static defaultProps = {
    paymentData: [],
    isFromPayment: false,
    isFromEditPayment: false,
  }

  constructor(props) {
    super(props)
    this.shouldRenderAddressForm =
      props.isMultipleAdressActive ||
      (!props.isFromEditPayment && !this.hasDefaultAddress()) ||
      false
  }

  hasDefaultAddress = () => {
    const {
      isFromCheckout = false,
      isFromEditSubscription = false,
      billingAddress = {},
      editUpdateModalEnabled = false,
    } = this.props
    const accountAddress =
      accountsAddressContainer.addressData?.length > 0
        ? accountsAddressContainer.addressData
        : []
    const addressData =
      addressContainer.addressData?.length > 0
        ? addressContainer.addressData
        : accountAddress
    let defaultAddress = addressData?.some(
      address => address?.isDefault === true
    )
    let checkoutDeliveryAddress =
      cartContainer?.cartResponse?.deliveryDetails?.address || {}
    let isShippingAddressAvailable =
      checkoutDeliveryAddress &&
      Object.keys(checkoutDeliveryAddress) &&
      Object.keys(checkoutDeliveryAddress).length > 0
        ? true
        : defaultAddress
    let subscriptionShippingAddress =
      subscriptionContainer.subscriptionPreferenceResponse?.addresses || {}
    let isSubscriptionAddressAvailable =
      subscriptionShippingAddress &&
      Object.keys(subscriptionShippingAddress) &&
      Object.keys(subscriptionShippingAddress).length > 0
        ? true
        : defaultAddress
    let isBillingAddressAvailable =
      billingAddress &&
      Object.keys(billingAddress) &&
      Object.keys(billingAddress)?.length > 0
        ? true
        : defaultAddress

    let isAddressAvailable = editUpdateModalEnabled
      ? isBillingAddressAvailable
      : isFromCheckout
      ? isShippingAddressAvailable
      : isFromEditSubscription
      ? isSubscriptionAddressAvailable
      : defaultAddress
    return isAddressAvailable
  }

  componentDidMount() {
    tokenExContainer.loadTokenExIframe()
    tokenExContainer.tokenExValidData = false
    const { isFromEditPayment = false, paymentData = {} } = this.props
    checkoutContainer.paymentBillingSameAsShippingFlag = isFromEditPayment
      ? this.addressCheckboxFlag
      : this.hasDefaultAddress()
    if (isExpressCheckout() && !customerContainer?.isRegisterUser) {
      checkoutContainer.paymentBillingSameAsShippingFlag = true
    }
    let country =
      paymentData?.billingAddress?.country ||
      storeContainer.defaultShippingRegion
    checkoutContainer.selectedMarket =
      country?.toLowerCase() === 'canada' ? 'CA' : country
  }

  inputToValue = event => {
    this.expiryFlag = false
    if (event?.target?.value > 1 && event?.target?.value?.length > 2) {
      this.cardExipry = `${event.target.value.slice(
        0,
        2
      )}/${event.target.value.slice(2, 4)}`
    } else if (event?.target?.value?.length <= 3) {
      if (event?.target?.value > 1 && event?.target?.value?.length == 1) {
        this.cardExipry = `0${event.target.value.slice(
          0,
          2
        )}/${event.target.value.slice(2, 4)}`
      } else this.cardExipry = event.target.value.slice(0, 2)
    } else this.cardExipry = event.target.value
  }

  inputFirstname = event => {
    this.firstNameFlag = false
    this.cardFirstname = event.target.value
  }

  handleSubmit = formData => {
    const { onSubmit = function () {} } = this.props
    if (typeof onSubmit === 'function') {
      onSubmit(formData)
    }
  }

  handleAddressChanges = event => {
    this.addressChangeFlag = {
      [event.target.name]: true,
    }
    this.country = event.target.value
    checkoutContainer.selectedMarket = event.target.value
  }

  handleAddressCheckbox = () => {
    this.shouldRenderAddressForm = !this.shouldRenderAddressForm
    this.addressCheckboxFlag = !this.addressCheckboxFlag
    checkoutContainer.paymentBillingSameAsShippingFlag =
      !checkoutContainer.paymentBillingSameAsShippingFlag
  }

  handleAddPaymentCheckbox = () => {
    this.addPaymentToProfile = !this.addPaymentToProfile
    this.savePaymentCheckboxFlag = !this.savePaymentCheckboxFlag
  }
  handleDefaultPaymentCheckbox = () => {
    this.defaultPaymentCheckbox = !this.defaultPaymentCheckbox
    this.defaultPaymentCheckboxFlag = !this.defaultPaymentCheckboxFlag
  }

  render() {
    const {
      isFromEditPayment = false,
      className = '',
      paymentData = {},
      refId = '',
      isFromCheckout = false,
      billingAddress = {},
    } = this.props
    const addressToDisplay = paymentData?.billingAddress || {}
    const {
      firstName = '',
      lastName = '',
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state = '',
      country = '',
    } = addressToDisplay
    const hasSubscriptionProduct = cartContainer.hasSubscriptionProduct()
    const constructStyleForPaymentForm = {
      formColumnClassName: 'pb-lg-3',
      formClassName: isExpressCheckout() ? 'expressCheckoutForm' : '',
      formRowClassName: '',
    }
    return (
      <Container
        className={`form-container  ${className} ${
          isExpressCheckout() ? 'p-2' : 'px-0 bg-white'
        }`}
        data-testid={
          isFromEditPayment
            ? 'qa-edit-payment-container'
            : 'qa-add-payment-container'
        }>
        <CommonForm
          schema={schema({
            shouldRenderAddressForm: this.shouldRenderAddressForm,
            countryValue: this.selectedCountry,
            hasSubscriptionProduct,
            ...this.props,
          })}
          formTypeJson={renderFormInput({
            handleAddressCheckbox: this.handleAddressCheckbox,
            handleAddPaymentCheckbox: this.handleAddPaymentCheckbox,
            shouldRenderAddressForm: this.shouldRenderAddressForm,
            formatFunction: this.cardExipry,
            inputToValue: this.inputToValue,
            expiryFlag: this.expiryFlag,
            addressCheckboxFlag: this.addressCheckboxFlag,
            savePaymentCheckboxFlag: this.savePaymentCheckboxFlag,
            handleDefaultPaymentCheckbox: this.handleDefaultPaymentCheckbox,
            defaultPaymentCheckbox: this.defaultPaymentCheckbox,
            defaultPaymentCheckboxFlag: this.defaultPaymentCheckboxFlag,
            cardnumber: this.cardnumber,
            addressChangeFlag: this.addressChangeFlag,
            cardnumberFlag: this.cardnumberFlag,
            cardFirstname: this.cardFirstname,
            addressValues: this.addressValues,
            firstNameFlag: this.firstNameFlag,
            inputFirstname: this.inputFirstname,
            handlePaymentAddressChanges: this.handleAddressChanges,
            isFromCheckout: isFromCheckout || false,
            countryVal: this.country,
            selectedBillingAddress: billingAddress,
            hideDefaultSubPaymentCheckbox:
              this.props.hideDefaultSubPaymentCheckbox || false,
            hasSubscriptionProduct,
            isToHideLabel: true,
            ...this.props,
          })}
          reference={refId}
          hasInlineSubmitButton={true}
          id="payment-form"
          onSubmit={formData => this.handleSubmit(formData)}
          classNameValue={constructStyleForPaymentForm}
        />

        {this.shouldRenderAddressForm == false && (
          <div data-testid="qa-default-address" className="ml-2">
            {addressToDisplay !== '' && (
              <div id="shipping-display">
                <div className="d-flex">
                  <p className="shipping-line-1">{firstName}</p>
                  <p className="shipping-line-1">{lastName}</p>
                </div>
                <p>{addressLine1}</p>
                <p>{addressLine2}</p>
                <p>{city}</p>
                <p>{state}</p>
                <p>{country}</p>
              </div>
            )}
          </div>
        )}
      </Container>
    )
  }
}

export default PaymentForm
export { PaymentForm }
