import { action, observable } from 'mobx'

class ListShareState {
  @observable listDetails = {
    name: '',
    listId: '',
    accessType: '',
  }
  @observable showModal = false
  @observable isFromOutfit = false

  @action
  setListDetails = (props, isFromOutfit = false) => {
    this.isFromOutfit = isFromOutfit
    const { listId, name, accessType } = props
    this.listDetails = {
      name,
      listId,
      accessType,
    }
  }

  @action
  toggleModalVisiblity = () => {
    this.showModal = !this.showModal
  }
}

const listShareState = new ListShareState()

export { listShareState }
