import React, { Suspense, lazy, useEffect, useState } from 'react'

export const BillingAddressForm = props => {
  const [AddressForm, setAddressForm] = useState(
    lazy(() =>
      import(
        'src/views/addresses/' +
          'Address' +
          props?.defaultMarket +
          '/' +
          'Address' +
          props?.defaultMarket
      )
    )
  )

  useEffect(() => {
    setAddressForm(
      lazy(() =>
        import(
          'src/views/addresses/' +
            'Address' +
            props?.selectedMarket +
            '/' +
            'Address' +
            props?.selectedMarket
        )
      )
    )
  }, [props.selectedMarket])

  return (
    <Suspense fallback="">
      <AddressForm
        onSubmit={props?.onSubmit}
        refId={props?.refId}
        isFromSubscriptionPayment={props?.isFromSubscriptionPayment}
        addressInfo={props?.addressInfo}
        type="payment"
        isFromCheckout={props?.isFromCheckout}
        billingAddressType={props?.billingAddressType}
      />
    </Suspense>
  )
}
