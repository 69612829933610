import React from 'react'
import uniqBy from 'lodash/uniqBy'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import { observer } from 'mobx-react'
import { OverlayTrigger } from 'react-bootstrap'
import { IoIosInformationCircleOutline as InformationIcon } from 'react-icons/io'
import './styles.scss'
import { trackPromotionImpression } from 'src/utils'

@observer
class ProductPromotion extends React.Component {
  renderPromotionsToolTip = promotions => {
    /* to display cartLevelPromotions as tooltip*/
    if (promotions?.length) {
      const promotionsView = this.renderCartPromotions(promotions)
      return (
        <>
          <OverlayTrigger
            placement="top-start"
            delay={{ show: 100, hide: 250 }}
            overlay={
              <div className="promotions-tooltip">{promotionsView}</div>
            }>
            <InformationIcon className="plp-promo-info-icon" />
          </OverlayTrigger>
        </>
      )
    }
    return <></>
  }

  renderCartPromotions = promoBreakup =>
    promoBreakup.map((promotion, index) => {
      const displayMessage = promotion?.displayMessage || ''
      const message = promotion?.message || displayMessage
      return (
        <div className="plp-promotions-text" key={index}>
          {message}
        </div>
      )
    })

  getSinglePromo = singlePromoProps => {
    const { uniquePromotion } = singlePromoProps || {}
    return (
      <div
        data-testid="qa-product-promotion"
        className="product-promotion-wrapper d-flex flex-column mt-2">
        {uniquePromotion.map((promotionDetails, index) => {
          const message =
            promotionDetails?.message || promotionDetails?.displayMessage || ''
          return (
            <span className="product-promotion" key={index}>
              {message}
            </span>
          )
        })}
      </div>
    )
  }

  getMultiplePromo = multiPromoProps => {
    const { uniquePromotion, isDiscount } = multiPromoProps || {}
    return (
      <div
        data-testid="qa-product-promotion"
        className="product-promotion-wrapper d-flex flex-column mt-2">
        <span
          className={`${
            isDiscount ? 'discount-promotion' : 'product-promotion'
          }`}>
          {isDiscount
            ? i18nTranslate('pdp.discountLabelTxt', 'Discount', {
                nameSpace: 'ssr-resource',
              })
            : i18nTranslate('pdp.pdpPromotionsLabelTxt', 'Promotions', {
                nameSpace: 'ssr-resource',
              })}
          {this.renderPromotionsToolTip(uniquePromotion)}
        </span>
      </div>
    )
  }

  renderPromotionOverImage = promoOnImageProps => {
    const { uniquePromotion } = promoOnImageProps || {}
    const { isProductCard } = this.props
    let promotionTagStyle = isProductCard
      ? 'plp-promotion-tag'
      : 'pdt-promotion-tag'
    if (isExpressCheckout()) promotionTagStyle += ' express-checkout-promo'
    let promotionDetails = []
    uniquePromotion.map(promotion => {
      promotionDetails.push({
        id: promotion?.offerId || '',
        name: promotion?.message || '',
      })
    })
    trackPromotionImpression({ promotionDetails })
    return (
      <div className="position-absolute pdt-promotion-tag-wrapper">
        {uniquePromotion.map((promotionDetails, index) => {
          let message =
            promotionDetails?.message || promotionDetails?.displayMessage || ''
          {
            message =
              message && message.length > 20
                ? message.slice(0, 20).concat('...')
                : message
          }
          return (
            <div
              key={`promo-${index}`}
              className={`text-center ${promotionTagStyle}`}
              data-testid="qa-promotion-tag">
              {message}
            </div>
          )
        })}
      </div>
    )
  }

  renderPromotions = () => {
    const { promotion, isProductCard, isSubscription, isDiscount } = this.props
    //console.log('promotion:', JSON.parse(JSON.stringify(promotion)))
    const uniquePromotion =
      (promotion?.length > 0 &&
        uniqBy(promotion, promo =>
          isSubscription || isDiscount ? promo.title : promo.message
        )) ||
      []
    //console.log('uniquePromotion:', JSON.parse(JSON.stringify(uniquePromotion)))
    if (uniquePromotion.length > 0) {
      // Fix for https://nuskin.atlassian.net/browse/CX121-2303
      if (this.props.isToShowPromoOnImage) {
        return this.renderPromotionOverImage({ uniquePromotion })
      } else if (
        ((uniquePromotion.length == 1 && isProductCard) || !isProductCard) &&
        !isDiscount
      ) {
        return this.getSinglePromo({ uniquePromotion })
      }
      return this.getMultiplePromo({ uniquePromotion })
    }

    return <></>
  }

  render() {
    return this.renderPromotions()
  }
}

export { ProductPromotion }
