import React from 'react'
import {
  Form,
  InputGroup,
  Tooltip,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import './styles.scss'
const PreferedNameToolTip = props => {
  const { type } = props
  const className = props.isInvalid ? 'is-invalid' : ''

  return (
    <InputGroup className={className}>
      <Form.Control variant="secondary" {...props} type={type} />
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        overlay={
          <Tooltip id={`tooltip`}>
            {i18nTranslate(
              'preferedNameTooltip',
              'Please tell us the name you would like us to use when addressing you on our website.',
              {
                nameSpace: 'ssr-resource',
              }
            )}
          </Tooltip>
        }>
        <div className="info">
          <Button
            aria-label="more information about preferred Name"
            className="infoIconBtn">
            <IoIosInformationCircleOutline className=" infoIcon" />
          </Button>
        </div>
      </OverlayTrigger>
    </InputGroup>
  )
}

export { PreferedNameToolTip }
