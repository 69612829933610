import { countryData } from 'src/views/components/SignUpForms/countryCodeData'
import { customerContainer } from 'src/models'
import { application } from 'src/utils'
import { getLocalStorage, i18nTranslate } from 'src/utils'

const formInput = (isError, hideError, getProfilePhone) => {
  const acctype1 = getLocalStorage('accountType')
  const isBusinessEntity = acctype1 === 'Brand Affiliate - Business Entity'
  let phoneCountryCodeOptions = []
  countryData()?.forEach(val => phoneCountryCodeOptions.push(val))
  const phoneLable = isBusinessEntity
    ? 'businessPhoneNumber'
    : 'mobilePhoneNumber'
  const phonePlaceHolder = isBusinessEntity
    ? 'businessPhoneNumberPlaceHolder'
    : 'phoneNumberPlaceHolder'
  const personType =
    customerContainer?.profileResponse?.customProperties?.personType?.toLowerCase()

  return [
    [
      {
        name: 'phoneCountryCode',
        type: 'select',
        options: [...phoneCountryCodeOptions],
        columnType: {
          lg: `${
            application.width > 1024 && application.width < 1080 ? '6' : '5'
          }`,
        },
        dataTestId: 'qa-dropdown-countrycode',
        defaultValue:
          customerContainer?.setVerifyPhone?.phoneCountryCode ||
          getProfilePhone?.phoneCountryCode ||
          'US',
        ariaLabel: 'phoneCountryCode',
        required: true,
        phoneNumberDropDown: true,
        customClassName: `phoneVerifyInput`,
        // onFocus: () => {
        //   hideError()
        // },
        onInput: event => {
          customerContainer.setVerifyPhone.phoneCountryCode =
            event?.target?.value
        },
      },
      {
        name: phoneLable,
        columnType: {
          lg: `${
            application.width > 1024 && application.width < 1080 ? '6' : '7'
          }`,
        },
        dataTestId: 'qa-create-account-phone',
        placeholderKey: phonePlaceHolder,
        defaultValue:
          customerContainer?.setVerifyPhone?.phoneNumber ||
          getProfilePhone?.phoneNumber ||
          '',
        ariaLabel: phoneLable,
        required: true,
        customClassName: `phoneVerifyInput ${
          application.width > 1024 && application.width < 1080 ? 'pt-2' : ''
        }`,
        type: 'mask',
        onFocus: () => {
          hideError()
        },
      },
      {
        type: 'div',
        columnType: {
          lg: '12',
        },
        customClassName: 'labelInfoText',
        labelInfo: i18nTranslate(
          'phoneLabel.labelInfoText',
          "We'll text a code to verify this number. Fees may apply."
        ),
      },
      {
        type: 'div',
        name: i18nTranslate(
          'phoneVerifyError',
          'Must be a unique mobile number, try again'
        ),
        columnType: {
          lg: '12',
        },
        customClassName: 'my-1 phoneVerifyError' + (isError ? '' : ' d-none'),
      },
    ],
  ]
}

export { formInput }
