function getB2CEndPoints(API_BASE_PATH) {
  const API_URL_CONFIG_B2C = {
    customerBasePath: `${API_BASE_PATH}/customers`,
    listsBasePath: `${API_BASE_PATH}/listservice/lists`,
  }

  return {
    userActivation: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/activation`,
      method: 'PATCH',
    },
    addProfileAddress: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/addresses`,
      method: 'POST',
    },
    addOriginalAddress: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/plugin/validateAddress`,
      method: 'POST',
    },
    validateProfileAddress: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/validateAddress`,
      method: 'POST',
    },
    getProfileAddress: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/addresses`,
      method: 'GET',
    },
    deleteProfileAddress: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/addresses`,
      method: 'DELETE',
    },
    updateProfileAddress: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/addresses`,
      method: 'PATCH',
    },
    getPayment: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/payments`,
      method: 'GET',
    },
    addPayment: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/payments`,
      method: 'POST',
    },
    deletePayment: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/payments`,
      method: 'DELETE',
    },
    updatePayment: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/payments`,
      method: 'PATCH',
    },
    updateExpiryPayment: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/payments`,
      method: 'PUT',
    },
    createList: {
      url: `${API_URL_CONFIG_B2C.listsBasePath}`,
      method: 'POST',
    },
    getLists: {
      url: `${API_URL_CONFIG_B2C.listsBasePath}`,
      method: 'GET',
    },
    addToList: {
      url: `${API_URL_CONFIG_B2C.listsBasePath}`,
      method: 'POST',
    },
    updateList: {
      url: `${API_URL_CONFIG_B2C.listsBasePath}`,
      method: 'PATCH',
    },
    deleteFromList: {
      url: `${API_URL_CONFIG_B2C.listsBasePath}`,
      method: 'DELETE',
    },
    deleteList: {
      url: `${API_URL_CONFIG_B2C.listsBasePath}`,
      method: 'DELETE',
    },
    shareList: {
      url: `${API_URL_CONFIG_B2C.listsBasePath}`,
      method: 'POST',
    },
    updateListItem: {
      url: `${API_URL_CONFIG_B2C.listsBasePath}`,
      method: 'PATCH',
    },
    userEmailActivation: {
      url: `${API_URL_CONFIG_B2C.customerBasePath}/emailverification`,
      method: 'POST',
    },
  }
}
export { getB2CEndPoints }
