import React from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import { Row, Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import {
  V2ProductOption,
  Price,
  Quantity,
  // CustomPrice,
} from 'src/views/components'
import { productDeps } from 'src/deps'
import {
  PDPBundleProductType,
  enableQuantityForBundle,
  APPConfig,
} from 'config/appConfig'
import { promotionContainer } from 'src/models'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { getImageFromProperty } from 'src/utils'
import { productStatusDeps } from 'src/views/components/ProductComponents/V2ProductQuantityAndButtons/ProductStatusDeps'
import { observable } from 'mobx'
@observer
class V2SubProduct extends React.Component {
  /*
    https://nuskin.atlassian.net/browse/BRW-3647

    optional product variant (added in bundle) should not change when changed, 
    should change only when "add to bundle" button is clicked

    but error has to be shown and button has to be disabled when variant is changed,
    hence getV2SubProductComponentLevelInventoryStatus is sent with currentlyClickedOptionalSkuId
  */

  @observable currentlyClickedOptionalSkuId = ''
  @observable currentlyClickedOptionalSkuData = {}
  @observable currentlyAddedOptionalSkuId = ''

  constructor(props, context) {
    super(props, context)
  }
  componentDidMount = () => {
    const { subProduct, type } = this.props
    if (type?.toLowerCase() === 'optional') {
      this.currentlyClickedOptionalSkuId =
        subProduct.selectedSku.identifier || ''
      this.currentlyClickedOptionalSkuData = {}
    }
  }

  changeSkuState = selectedProp => {
    const { subProduct, index, type, handleSkuSelection } = this.props
    const productChanges = productDeps.changeSku({
      product: subProduct,
      selectedProp,
    })
    handleSkuSelection(productChanges, index, type)
  }

  handleSkuSelection = selectedProp => {
    const { subProduct, type } = this.props
    subProduct.skuSelected[selectedProp.type] = selectedProp.value

    if (type.toLowerCase() === 'optional') {
      this.currentlyClickedOptionalSkuId = selectedProp.identifier
      this.currentlyClickedOptionalSkuData = selectedProp
    } else {
      this.changeSkuState(selectedProp)
      promotionContainer.makeV2PromotionCall(
        productState.product,
        productState.productCount
      )
    }
  }
  showErrorForSkuSelection = type => {
    const ErrorText = i18nTranslate('product.selectSkuError', 'Select the', {
      nameSpace: 'ssr-resource',
    })
    const errorView =
      this.props.subProduct.skuSelected[type] === '' ? (
        <div className="font-weight-bold text-danger">
          {ErrorText} {type}
        </div>
      ) : (
        <React.Fragment />
      )
    return errorView
  }
  addOrRemoveOptionalBundleItem = () => {
    const { subProduct } = this.props
    this.changeSkuState(this.currentlyClickedOptionalSkuData)
    const isSubProductAlreadyAdded = this.currentlyAddedOptionalSkuId != ''
    const isCurrentlySelectedSkuAdded =
      this.currentlyAddedOptionalSkuId === this.currentlyClickedOptionalSkuId
    if (isSubProductAlreadyAdded) {
      if (isCurrentlySelectedSkuAdded) {
        // remove optional product
        this.currentlyAddedOptionalSkuId = ''
        productDeps.optionalData.splice(
          productDeps.optionalData.indexOf(subProduct.identifier),
          1
        )
      } else {
        // do not remove optional product
        // just change the sku
        this.currentlyAddedOptionalSkuId = this.currentlyClickedOptionalSkuId
      }
    } else {
      this.currentlyAddedOptionalSkuId = this.currentlyClickedOptionalSkuId
      productDeps.optionalData.push(subProduct.identifier)
    }
    promotionContainer.makeV2PromotionCall(
      productState.product,
      productState.productCount
    )
  }

  renderImage = () => {
    const { subProduct } = this.props
    const productImgUrl =
      subProduct?.properties?.imageURL ||
      getImageFromProperty(subProduct?.properties)
    const imageUrl =
      productImgUrl || getImageFromProperty(subProduct?.selectedSku?.properties)
    const subProductName =
      subProduct?.selectedSku?.properties?.name ||
      subProduct?.properties?.name ||
      ''

    return (
      <Row className="kitProduct" noGutters>
        <Link
          className="d-flex justify-content-center align-items-center image-wrapper"
          to={productDeps.getSubProductLink(subProduct)}>
          <LazyLoadImage
            data-testid="qa-product-image"
            className={'mw-100 mh-100'}
            src={imageUrl || imagePlaceholder}
            alt={subProductName ? subProductName : 'sub product image'}
            onError={e => {
              if (e.target.src != imagePlaceholder) {
                e.target.onerror = null
                e.target.src = imagePlaceholder
              }
            }}
          />
        </Link>
      </Row>
    )
  }

  renderDetails = (
    disableOptionalProductAddToCart = '',
    messageToDisplay = ''
  ) => {
    const { subProduct = {}, type = '' } = this.props
    const variantLabel = subProduct?.skuSelected?.variantLabel || ''
    const labelForOption =
      subProduct?.productOption?.[0]?.values?.length > 1
        ? i18nTranslate('product.selectOption', 'Select your option', {
            nameSpace: 'ssr-resource',
          })
        : i18nTranslate('product.yourOption', 'Your option')
    const price = subProduct.selectedSku?.price || ''

    const fixedQuantityTitle = isExpressCheckout()
      ? i18nTranslate(('bundle.fixedQuantityTitle', 'Quantity'), {
          nameSpace: 'ssr-resource',
        })
      : `${i18nTranslate(('bundle.fixedQuantityTitleV2', 'Item Quantity'), {
          nameSpace: 'ssr-resource',
        })} :`
    return (
      <>
        {/* <Row noGutters className="font-weight-bold">
          {subProduct?.previousSku?.properties?.brand || ''}
        </Row> */}
        <Row noGutters data-testid="qa-product-name">
          <Link
            className="text-dark"
            to={productDeps.getSubProductLink(subProduct)}>
            <span className="v2-sub-product-title">
              {subProduct?.previousSku?.properties?.name || ''}
            </span>
          </Link>
        </Row>
        {variantLabel && (
          <Row noGutters className="v2-selected-option-display-wrapper py-2">
            <span className="selected-option-label">
              {labelForOption}:&nbsp;
            </span>
            {variantLabel}
          </Row>
        )}
        {/* sub product unit price */}
        {/* <Row noGutters className="subproduct-unit-price mb-2">
          <CustomPrice
            custompriceValue={price}
            isCurrencySymbol={true}
            dataTestId="qa-subproduct-unit-price"
          />
        </Row> */}
        {!enableQuantityForBundle && (
          <Row
            noGutters
            data-testid="qa-subproduct-quantity"
            className="subproduct-quantity">
            <span>{fixedQuantityTitle}</span>
            <span
              className="pl-2 product-quantity-value"
              data-testid="qa-product-quantity">
              {subProduct?.properties?.quantity}
            </span>
          </Row>
        )}

        {/* status is shown for optional product only */}
        {type == 'optional' &&
          this.renderInventoryStatus(
            disableOptionalProductAddToCart,
            messageToDisplay
          )}
      </>
    )
  }

  renderDescription = (renderTitle = true) => {
    const { subProduct } = this.props
    const description = subProduct?.properties?.description || ''

    const shortDescription =
      subProduct?.properties?.shortDescription || description

    return (
      shortDescription && (
        <div>
          {renderTitle && (
            <h6 className="font-weight-bold">
              {i18nTranslate('bundle.productDetailTitle', 'Product Details', {
                nameSpace: 'ssr-resource',
              })}
            </h6>
          )}
          <Row
            noGutters
            className="pb-2 text-justify"
            dangerouslySetInnerHTML={{
              __html: `${shortDescription.split(' ').slice(0, 20).join(' ')}
          ${shortDescription.split(' ').length > 20 ? '...' : ''}`,
            }}
          />
        </div>
      )
    )
  }

  // @##pdp_button_disable_and_status_display_logic
  shouldDisableAddToBundle = () => {
    /* CX12-8823 handle OOS for bundle and kit */

    const { subProduct } = this.props
    const inventory = subProduct?.selectedSku?.inventory?.toLowerCase() || ''
    const status =
      subProduct?.selectedSku?.properties?.status?.toLowerCase() || ''
    // todo for backorder and atpqty checks, inactive item checks
    return (
      status === 'inactive' ||
      inventory === 'unavailable' ||
      inventory === 'out of stock'
    )
  }

  renderInventoryStatus = (
    disableOptionalProductAddToCart = '',
    messageToDisplay = ''
  ) => {
    const { subProduct } = this.props
    if (messageToDisplay) {
      return (
        <Row noGutters data-testid="qa-add-item-status">
          <p
            className={`${
              !disableOptionalProductAddToCart
                ? 'availability-text text-success font-weight-bold p-0 mt-1'
                : 'availability-text font-weight-bold text-bundle-danger p-0 mt-1'
            }`}
            aria-label={
              i18nTranslate('bundle.addToBundleV2', 'Add Product to Bundle') +
              ' ' +
              messageToDisplay
            }
            /* above line is added to indicate that only optional product has error,
               not entire product*/
            aria-live="polite"
            role="alert">
            {messageToDisplay}
          </p>
        </Row>
      )
    }
    return ''
  }

  renderOptionalProductButton = (
    disableOptionalProductAddToCart = '',
    messageToDisplay = ''
  ) => {
    const { subProduct } = this.props
    const isSubProductAlreadyAdded = this.currentlyAddedOptionalSkuId != ''
    const alreadyAddedToBundle =
      isSubProductAlreadyAdded &&
      this.currentlyAddedOptionalSkuId === this.currentlyClickedOptionalSkuId

    return (
      <div className="w-100">
        <Row
          noGutters
          className="py-2 col-md-6 col-12 m-auto"
          data-testid="qa-add-item">
          <Button
            variant=""
            disabled={!alreadyAddedToBundle && disableOptionalProductAddToCart}
            onClick={this.addOrRemoveOptionalBundleItem}
            className={`optional-product-btn w-100 ${
              alreadyAddedToBundle ? 'added' : ''
            }`}>
            {!alreadyAddedToBundle
              ? i18nTranslate('bundle.addToBundleV2', 'Add Product to Bundle')
              : i18nTranslate(
                  'bundle.removeFromBundleV2',
                  'Remove item from bundle',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )}
          </Button>
        </Row>
      </div>
    )
  }

  handleQuantityChange = count => {
    const { subProduct } = this.props
    const isDataAvl = productDeps?.optionalData?.includes(subProduct.identifier)
    if (count === 0 || (count === 1 && !isDataAvl)) {
      this.addOrRemoveOptionalBundleItem()
    }
    if (count > 0) {
      subProduct['quantity'] = count
      this.props.updateBundleOptionalProducts()
    }
  }

  renderPriceAndQty = () => {
    const { subProduct, type = '' } = this.props
    return (
      <div>
        <Price
          classStyles={'mb-3'}
          transactionPrice={subProduct?.previousSku?.prices?.[0] || ''}
        />
        <Quantity
          minValue={type === 'mandatory' ? 1 : 0}
          defaultValue={type === 'mandatory' ? 1 : 0}
          onQuantityChange={this.handleQuantityChange}
        />
      </div>
    )
  }

  render() {
    const { type = '', subProduct } = this.props
    const isOptional = type.toLowerCase() === 'optional'
    const { disableOptionalProductAddToCart = '', messageToDisplay = '' } =
      productStatusDeps.getV2SubProductComponentLevelInventoryStatus(
        subProduct,
        productState.productCount,
        isOptional,
        this.currentlyClickedOptionalSkuId
      )

    if (PDPBundleProductType === 'grid') {
      return (
        <Row noGutters className="p-2 w-100 h-100 border flex-column">
          <div>{this.renderImage()}</div>
          <div className="pt-2 d-flex flex-column flex-grow-1">
            {this.renderDetails()}
          </div>
        </Row>
      )
    } else {
      return (
        <Row
          noGutters
          className={`subproduct-wrapper py-2 w-100 subproduct-${
            this.props?.bundleType ? this.props?.bundleType : ''
          }`}>
          <Row noGutters className="w-100">
            {this.renderImage()}
            {
              <div className="v2-sub-product-details">
                {this.renderDetails(
                  disableOptionalProductAddToCart,
                  messageToDisplay
                )}
              </div>
            }
          </Row>
          <V2ProductOption
            options={subProduct.productOption}
            handleSkuSelection={this.handleSkuSelection}
            showError={this.showErrorForSkuSelection}
            skuSelected={subProduct.skuSelected}
            isFromSubProduct={true}
          />
          {isOptional &&
            this.renderOptionalProductButton(
              disableOptionalProductAddToCart,
              messageToDisplay
            )}
        </Row>
      )
    }
  }
}

export { V2SubProduct }
