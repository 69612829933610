import { APPConfig } from 'config/appConfig'
import { convertToBoolean } from 'src/utils'

const isToShowTwoMonthCalender = () => {
  return convertToBoolean(APPConfig?.getAppConfig()?.enableTwoMonthCalender)
}

const subscriptionBlackOutDatesV2 = isToShowTwoMonthCalender()
  ? [29, 30, 31]
  : [29, 30, 31, 1]

const isFlexibleSubscriptionFlagEnabled = () => {
  return APPConfig?.getAppConfig()?.enableFlexibleSubscription === 'true'
}

const getDateDetailsV2 = (selectedDate = '') => {
  const date = selectedDate?.getDate() || 1
  const month = selectedDate?.getMonth() || 0
  const year = selectedDate?.getFullYear() || ''

  return { date, month, year }
}

const getTodayDateV2 = () => {
  let date = new Date()
  return date
}

const currentMonthV2 = () => {
  let month = new Date()?.getMonth() || 0
  return month
}

const currentYearV2 = () => {
  let year = new Date()?.getFullYear() || 0
  return year
}

const constructDateV2 = (year = '', month = 0, date = 1) => {
  return new Date(year, month, date)
}

const isPastDateV2 = (inputDate = '') => {
  let today = getTodayDateV2()
  today.setHours(0, 0, 0, 0)

  let comparisonDate = new Date(inputDate?.getTime())
  comparisonDate.setHours(0, 0, 0, 0)

  return comparisonDate < today
}

const isTwoDatesEqualV2 = (firstDate = '', secondDate = '') => {
  return (
    firstDate?.getDate() === secondDate?.getDate() &&
    firstDate?.getMonth() === secondDate?.getMonth() &&
    firstDate?.getFullYear() === secondDate?.getFullYear()
  )
}

const getMinDateDetailsToBeSelectedV2 = () => {
  const todayDate = getTodayDateV2() || ''
  let isToEnableNextMonth = isToShowTwoMonthCalender() || false
  const { date = 1, month = 0, year = '' } = getDateDetailsV2(todayDate) || {}
  let minDate = isToEnableNextMonth
    ? constructDateV2(year, month, 1)
    : constructDateV2(year, month, date + 1)
  let minDateDetails = getDateDetailsV2(minDate) || {}

  return {
    minFullDate: minDate || '',
    minDate: minDateDetails?.date || '',
    minMonth: minDateDetails?.month || '',
    minYear: minDateDetails?.year || '',
  }
}

const getMaxDateDetailsToBeSelectedV2 = (
  alreadyProcessedSubscriptionDates = []
) => {
  const todayDate = getTodayDateV2() || ''
  const { date = 1, month = 0, year = '' } = getDateDetailsV2(todayDate) || {}
  let isToEnableNextMonth = isToShowTwoMonthCalender() || false
  let isTodayAlreadyProcessed =
    alreadyProcessedSubscriptionDates?.some(processedDate => {
      return isTwoDatesEqualV2(processedDate, todayDate)
    }) || false
  let nonProcessedMaxDate = isTodayAlreadyProcessed
    ? constructDateV2(year, month + 1, date)
    : constructDateV2(year, month + 1, date - 1)
  let maxDate = isToEnableNextMonth
    ? constructDateV2(year, month + 2, 0)
    : nonProcessedMaxDate
  let maxDateDetails = getDateDetailsV2(maxDate) || {}
  return {
    maxFullDate: maxDate || '',
    maxDate: maxDateDetails?.date || '',
    maxMonth: maxDateDetails?.month || '',
    maxYear: maxDateDetails?.year || '',
  }
}

const getSubscriptionOrderSuccessDatesOfAllMonth = (
  subscriptionsResponse = []
) => {
  let subscriptionList = subscriptionsResponse || []
  const todayDate = getTodayDateV2() || ''
  const { month: currentMonth = 0, year: currentYear = '' } =
    getDateDetailsV2(todayDate)
  let orderSuccessDatesArray = []
  if (subscriptionList?.length > 0) {
    orderSuccessDatesArray = subscriptionList?.reduce(
      (orderSuccessDatesList, subscriptionResponse) => {
        let orderDateString = subscriptionResponse?.orderSuccessDates || ''
        let selectedMonth = subscriptionResponse?.selectedMonth || 1
        let alreadyProcessedDates =
          orderDateString?.split?.(',')?.filter?.(date => date !== '') || []
        let isNextYearMonth = selectedMonth - 1 < currentMonth
        let selectedYear = isNextYearMonth ? currentYear + 1 : currentYear
        alreadyProcessedDates?.forEach(orderDate => {
          orderSuccessDatesList.push(
            constructDateV2(selectedYear, selectedMonth - 1, orderDate)
          )
        })
        return orderSuccessDatesList
      },
      []
    )
  }
  return orderSuccessDatesArray
}

const getOrderSuccessDatesOfMonthLimit = (
  subscriptionsResponse = [],
  minimumDate = {},
  maximumDate = {}
) => {
  let subscriptionList =
    getSelectedSubscriptionDetailsForTwoMonths(
      subscriptionsResponse,
      minimumDate,
      maximumDate
    ) || []
  let { minMonth = 0, minYear = '' } = minimumDate || {}
  let { maxMonth = 0, maxYear = '' } = maximumDate || {}
  let orderSuccessDatesArray = []
  if (subscriptionList?.length > 0) {
    orderSuccessDatesArray = subscriptionList?.reduce(
      (orderSuccessDatesList, subscriptionResponse) => {
        let orderDateString = subscriptionResponse?.orderSuccessDates || ''
        let selectedMonth = subscriptionResponse?.selectedMonth || 1
        let alreadyProcessedDates =
          orderDateString?.split?.(',')?.filter?.(date => date !== '') || []
        alreadyProcessedDates?.forEach(orderDate => {
          if (selectedMonth == minMonth + 1) {
            orderSuccessDatesList.push(
              constructDateV2(minYear, minMonth, orderDate)
            )
          } else if (selectedMonth == maxMonth + 1) {
            orderSuccessDatesList.push(
              constructDateV2(maxYear, maxMonth, orderDate)
            )
          }
        })
        return orderSuccessDatesList
      },
      []
    )
  }
  return orderSuccessDatesArray
}

const getAllSubscriptionProcessingDatesOfAllMonths = (
  subscriptionsResponse = []
) => {
  let selectedUpcomingSubscriptionDates = []
  if (subscriptionsResponse?.length > 0) {
    selectedUpcomingSubscriptionDates = subscriptionsResponse?.reduce(
      (upcomingSubscriptionDates, subscriptionDetails) => {
        let { subscriptionGroup = {}, selectedMonth = 1 } =
          subscriptionDetails || {}
        let upcomingProcessingDates =
          (Object.keys(subscriptionGroup)?.length > 0 &&
            Object.keys(subscriptionGroup)) ||
          []
        let eligibleProcessingDates =
          getSubscriptionDatesForNextFiveMonths(
            upcomingProcessingDates,
            selectedMonth
          ) || []
        if (eligibleProcessingDates?.length > 0) {
          upcomingSubscriptionDates?.push(...eligibleProcessingDates)
        }
        return upcomingSubscriptionDates
      },
      []
    )
  }
  return selectedUpcomingSubscriptionDates
}

const getSubscriptionDatesForNextFiveMonths = (
  subscriptionsList = [],
  selectedMonth = 1
) => {
  const todayDate = getTodayDateV2() || ''
  const { month: currentMonth = 0, year: currentYear = '' } =
    getDateDetailsV2(todayDate)
  let selectedEligibleSubscriptionDates = []
  if (subscriptionsList?.length > 0) {
    selectedEligibleSubscriptionDates = subscriptionsList?.reduce(
      (eligibleSubscriptionDates, subscriptionDate) => {
        let isNextYearMonth = selectedMonth - 1 < currentMonth
        let selectedYear = isNextYearMonth ? currentYear + 1 : currentYear
        eligibleSubscriptionDates?.push(
          constructDateV2(selectedYear, selectedMonth - 1, subscriptionDate)
        )
        return eligibleSubscriptionDates
      },
      []
    )
  }

  return selectedEligibleSubscriptionDates
}

const getSelectedSubscriptionDetailsForTwoMonths = (
  subscriptionsList = [],
  minimumDate = {},
  maximumDate = {}
) => {
  let { minMonth = 0 } = minimumDate || {}
  let { maxMonth = 0 } = maximumDate || {}
  let selectedSubscriptionsList =
    subscriptionsList?.filter(subscriptionDetails => {
      let subscriptionMonth = subscriptionDetails?.selectedMonth || 1
      return (
        subscriptionMonth === minMonth + 1 || subscriptionMonth === maxMonth + 1
      )
    }) || []
  return selectedSubscriptionsList
}

const getAllSubscriptionProcessingDatesOfMonthLimit = (
  subscriptionsResponse = [],
  minimumDate = {},
  maximumDate = {}
) => {
  let subscriptionList =
    getSelectedSubscriptionDetailsForTwoMonths(
      subscriptionsResponse,
      minimumDate,
      maximumDate
    ) || []
  let selectedUpcomingSubscriptionDates = []
  if (subscriptionList?.length > 0) {
    selectedUpcomingSubscriptionDates = subscriptionList?.reduce(
      (upcomingSubscriptionDates, subscriptionDetails) => {
        let { subscriptionGroup = {}, selectedMonth = 1 } =
          subscriptionDetails || {}
        let upcomingProcessingDates =
          (Object.keys(subscriptionGroup)?.length > 0 &&
            Object.keys(subscriptionGroup)) ||
          []
        let eligibleProcessingDates =
          getSubscriptionDatesWithinMonthLimit(
            upcomingProcessingDates,
            selectedMonth,
            minimumDate,
            maximumDate
          ) || []
        if (eligibleProcessingDates?.length > 0) {
          upcomingSubscriptionDates?.push(...eligibleProcessingDates)
        }
        return upcomingSubscriptionDates
      },
      []
    )
  }

  return selectedUpcomingSubscriptionDates
}

const getSubscriptionDatesWithinMonthLimit = (
  subscriptionsList = [],
  selectedMonth = 1,
  minimumDate = {},
  maximumDate = {}
) => {
  let { minMonth = 0, minYear = '' } = minimumDate || {}
  let { maxDate = 0, maxMonth = '', maxYear = '' } = maximumDate || {}
  const lastDateInMinMonth =
    constructDateV2(minYear, minMonth, 0)?.getDate() || 1

  let selectedEligibleSubscriptionDates = []
  if (subscriptionsList?.length > 0) {
    selectedEligibleSubscriptionDates = subscriptionsList?.reduce(
      (eligibleSubscriptionDates, subscriptionDate) => {
        if (
          subscriptionDate >= 1 &&
          subscriptionDate <= lastDateInMinMonth &&
          selectedMonth == minMonth + 1 &&
          !subscriptionBlackOutDatesV2?.some(date => date == subscriptionDate)
        ) {
          eligibleSubscriptionDates?.push(
            constructDateV2(minYear, minMonth, subscriptionDate)
          )
        } else if (
          selectedMonth == maxMonth + 1 &&
          minMonth !== maxMonth &&
          subscriptionDate <= maxDate &&
          !subscriptionBlackOutDatesV2?.some(date => date == subscriptionDate)
        ) {
          eligibleSubscriptionDates?.push(
            constructDateV2(maxYear, maxMonth, subscriptionDate)
          )
        }
        return eligibleSubscriptionDates
      },
      []
    )
  }

  return selectedEligibleSubscriptionDates
}

const getNonProcessedSubscriptionDates = (
  alreadyProcessedSubscriptionDates = [],
  subscriptionProcessingDates = []
) => {
  let nonProcessedFutureSubscriptionDates = []
  if (subscriptionProcessingDates?.length > 0) {
    nonProcessedFutureSubscriptionDates = subscriptionProcessingDates?.filter(
      subscriptionProcessingDate => {
        return !alreadyProcessedSubscriptionDates?.some(
          alreadyProcessedDate => {
            return isTwoDatesEqualV2(
              alreadyProcessedDate,
              subscriptionProcessingDate
            )
          }
        )
      }
    )
  }
  return nonProcessedFutureSubscriptionDates
}

const getUniqueSubscriptionDatesOfMonthLimit = (
  subscriptionProcessingDates = []
) => {
  let uniqueSubscriptionDatesOfMonthLimit = []
  if (subscriptionProcessingDates?.length > 0) {
    uniqueSubscriptionDatesOfMonthLimit = subscriptionProcessingDates?.reduce(
      (uniqueSubscriptionDatesOfMonthLimit, subscriptionFullDate) => {
        if (
          !uniqueSubscriptionDatesOfMonthLimit?.some?.(
            uniqueSubscriptionFullDate => {
              let uniqueSubscriptionDate =
                uniqueSubscriptionFullDate?.getDate() || ''
              let subscriptionDate = subscriptionFullDate?.getDate() || ''
              return uniqueSubscriptionDate === subscriptionDate
            }
          )
        ) {
          uniqueSubscriptionDatesOfMonthLimit?.push(subscriptionFullDate)
        }
        return uniqueSubscriptionDatesOfMonthLimit
      },
      []
    )
  }

  return uniqueSubscriptionDatesOfMonthLimit
}

const getPastSubscriptionNonProcessedDates = (
  subscriptionProcessingDates = [],
  minimumDate = {}
) => {
  let minFullDate = minimumDate?.minFullDate || ''
  if (subscriptionProcessingDates?.length) {
    let pastOrderDates = subscriptionProcessingDates?.filter(
      subscriptionDate => subscriptionDate < minFullDate
    )
    return pastOrderDates
  }
  return []
}

const getFutureSubscriptionDates = (
  subscriptionProcessingDates = [],
  minimumDate = {}
) => {
  let minFullDate = minimumDate?.minFullDate || ''
  if (subscriptionProcessingDates?.length) {
    let futureDates = subscriptionProcessingDates?.filter(
      subscriptionDate => subscriptionDate >= minFullDate
    )
    return futureDates
  }
  return []
}

const getMinAvailableDateV2 = (
  alreadyProcessedSubscriptionDates = [],
  minimumDate = {},
  maximumDate = {}
) => {
  const todayDate = getTodayDateV2() || ''
  const { date, month, year } = getDateDetailsV2(todayDate) || {}
  let minDateObj = constructDateV2(year, month, date + 1)
  let minDateToBeSelected = minDateObj || ''
  let maxDate = maximumDate?.maxFullDate || ''

  const isSelectedDateAlreadyProcessed = (date = '') =>
    subscriptionBlackOutDatesV2?.includes(date?.getDate()) ||
    alreadyProcessedSubscriptionDates?.some(processedDate => {
      return isTwoDatesEqualV2(processedDate, date)
    })

  while (isSelectedDateAlreadyProcessed(minDateToBeSelected)) {
    minDateToBeSelected?.setDate(minDateToBeSelected?.getDate() + 1)
    if (isTwoDatesEqualV2(minDateToBeSelected, maxDate)) {
      break
    }
  }
  return minDateToBeSelected
}

const getLastDateOfCurrentMonth = (minimumDate = {}) => {
  let { month = 0, year = '' } = getDateDetailsV2(minimumDate) || {}
  let maxDate = constructDateV2(year, month + 1, 0)
  return maxDate
}

/**
 * Checks if the subscription can be skipped or deleted or change frequency to one-time.
 * @param {Object[]} subscriptionsArray - The array of subscription details.
 * @param {Object} product - The product details.
 * @returns {Object} - An object containing the flags to skip or delete the subscription.
 */
const canAllowToSkipDeleteChange = (subscriptionsArray = [], product = {}) => {
  const isOneTime = product?.type === 'VARIABLE_SINGLE_ORDER'
  let canSkipOrDelete = isOneTime
  let canChangeFrequencyToOneTime = !isOneTime
  if (!isOneTime) {
    const remainArray =
      subscriptionsArray?.filter(item => item?.id !== product?.id) || []
    const hasOneTime =
      remainArray?.some(data => data?.type === 'VARIABLE_SINGLE_ORDER') || false
    const hasMultiTime =
      remainArray?.some(data => {
        return (
          data?.type === 'VARIABLE_MULTI_ORDER' &&
          data?.properties?.isSkipped === 'false'
        )
      }) || false
    canSkipOrDelete = !hasOneTime || hasMultiTime
    canChangeFrequencyToOneTime = hasMultiTime
  }
  return { canSkipOrDelete, canChangeFrequencyToOneTime }
}

export {
  subscriptionBlackOutDatesV2,
  isFlexibleSubscriptionFlagEnabled,
  getDateDetailsV2,
  getTodayDateV2,
  currentMonthV2,
  currentYearV2,
  constructDateV2,
  isPastDateV2,
  isTwoDatesEqualV2,
  getMinDateDetailsToBeSelectedV2,
  getMaxDateDetailsToBeSelectedV2,
  getSubscriptionOrderSuccessDatesOfAllMonth,
  getOrderSuccessDatesOfMonthLimit,
  getAllSubscriptionProcessingDatesOfAllMonths,
  getSubscriptionDatesForNextFiveMonths,
  getSelectedSubscriptionDetailsForTwoMonths,
  getAllSubscriptionProcessingDatesOfMonthLimit,
  getSubscriptionDatesWithinMonthLimit,
  getNonProcessedSubscriptionDates,
  getUniqueSubscriptionDatesOfMonthLimit,
  getPastSubscriptionNonProcessedDates,
  getFutureSubscriptionDates,
  getMinAvailableDateV2,
  getLastDateOfCurrentMonth,
  isToShowTwoMonthCalender,
  canAllowToSkipDeleteChange,
}
