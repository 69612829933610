import React from 'react'
import { observer } from 'mobx-react'
import { reaction } from 'mobx'
import { i18nTranslate } from 'src/utils'
import Slider from 'react-slick'
import TagManager from 'react-gtm-module'
import { nuskinSubscriptionContainer, cartContainer } from 'src/models'
import { getProductDetailsForCart } from 'src/views/components/CartBlock/deps'
import NuskinArrowRight from 'src/views/components/Icons/NuskinArrowRight'
import NuskinArrowLeft from 'src/views/components/Icons/NuskinArrowLeft'
import { IS_BROWSER } from 'config/appConfig'
import { SubscriptionProductItem } from '../SubscriptionProductItem'
import { application } from 'src/utils'
import { getScanQualifiedCountSumForBundleSubItems } from 'src/deps'
import './styles.scss'

@observer
class MultipleProductSubscription extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlide: 0,
    }
    this.slider = React.createRef(null)
  }

  mailTitle = 'multiProductSection'

  componentDidMount() {
    const reactions = reaction(
      () => {
        let subscriptionItems = this.props?.subscriptionItems || {}
        const allProductsDisabled = subscriptionItems.every(
          (product, index) => {
            const productDetails = getProductDetailsForCart(product)
            const isAlreadySubscribed =
              nuskinSubscriptionContainer.isAlreadySubscribedProduct(
                productDetails
              ) || false
            return isAlreadySubscribed === true
          }
        )
        nuskinSubscriptionContainer.showModalContent = !allProductsDisabled
        return allProductsDisabled
      },
      (allProductsDisabled, reaction) => {
        nuskinSubscriptionContainer.showModalContent = !allProductsDisabled
      }
    )
  }

  componentDidUpdate() {
    if (this.slider.current) {
      this.slider.current.slickGoTo(0, true)
    }
  }

  handleQuantityChange = async (count = 0, itemId = 0) => {
    await nuskinSubscriptionContainer.subscriptionItemQuantityChange(
      count,
      itemId
    )
  }

  handleDeleteCartItem = async props => {
    await nuskinSubscriptionContainer.deleteSubscriptionItem(props)
  }

  handleFrequencyChange = async (count = 0, itemId = 0, subscription = {}) => {
    return await nuskinSubscriptionContainer.subscriptionItemFrequencyChange(
      count,
      itemId,
      subscription
    )
  }

  decrementCheck = async (count = 0, itemId = 0) => {
    await nuskinSubscriptionContainer.subscriptionItemDecrementCheck(
      count,
      itemId
    )
  }

  // fix for ADA as child anchor tags are also included in tab navigation
  // inside child elements
  methodToReuseAdaFix = () => {
    if (IS_BROWSER) {
      let anchorTags
      this.setTimer = setTimeout(() => {
        anchorTags = document.querySelectorAll(
          `#${this.mailTitle} .slick-list a`
        )
      }, 700)

      if (anchorTags?.length > 0) {
        this.handleADA()
      } else {
        if (this.setTimer) {
          clearTimeout(this.setTimer)
        }

        this.setTimer = setTimeout(() => {
          this.handleADA()
        }, 700)
      }
    }
  }

  handleADA = () => {
    const slickLists = document.querySelectorAll(
      `#${this.mailTitle} .slick-list .slick-slide`
    )
    slickLists?.forEach(elem => {
      const isHidden = elem.getAttribute('aria-hidden') || ''
      elem.setAttribute('aria-hidden', 'false')

      /* below code is commented as tabindex is handled based on disabled slide scenario*/

      /* const aTags = elem.getElementsByTagName('a')[0] || ''
      if (isHidden === 'true') {
        if (aTags) {
          aTags.setAttribute('tabindex', '-1')
        }
      } else if (isHidden === 'false') {
        if (aTags) {
          aTags.removeAttribute('tabindex')
        }
      } */
    })
  }

  handleTabIndex = () => {
    this.methodToReuseAdaFix()
  }

  handleTabIndexAndGTMCarouselClick = () => {
    this.methodToReuseAdaFix()
    TagManager.dataLayer({
      dataLayer: {
        event: 'carouselClicked',
        pagePath: '/',
        pageTitle: 'Home',
        section: 'Nu Skin In The News',
        platform: 'equinox',
      },
    })
  }

  onSwipe = () => {
    if (this.slider?.current) {
      this.slider.current.innerSlider.clickable = true
    }
  }

  getStyleGuides = subscriptionItems => {
    return {
      dots: true,
      appendDots: dots => this.getCustomDots(dots),
      infinite: false,
      speed: 200,
      slidesToShow: 2,
      swipeToSlide: true,
      slidesToScroll: 1,
      onInit: this.handleTabIndex,
      afterChange: this.handleTabIndexAndGTMCarouselClick,
      arrows: false,
      beforeChange: (prev, next) => {
        this.setState({ currentSlide: next })
      },
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
          },
        },
        {
          breakpoint: 870,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '10px',
            dots: true,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '10px',
            dots: true,
          },
        },
      ],
      onSwipe: this.onSwipe.bind(this),
    }
  }

  getCustomDots = dots => {
    const customDots = []
    let subscriptionItems = this.props?.subscriptionItems || {}
    subscriptionItems?.forEach((block, index) => {
      customDots.push(
        <li key={`productDot_${index}`} {...dots?.[index]?.props}>
          <button
            aria-label={`product image ${index + 1} of ${
              subscriptionItems?.length
            }`}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })
    application.isDesktop && customDots.pop() //to remove extra dot, since its non-continuous loop carousel
    return <ul className="slick-dots">{customDots}</ul>
  }

  renderArrows = subscriptionItems => {
    return (
      subscriptionItems?.length > 2 && (
        <div className="slider-arrow">
          <div className="arrow-btn prev">
            <NuskinArrowLeft onClick={() => this.slider.slickPrev()} />
          </div>
          <div className="arrow-btn next">
            <NuskinArrowRight
              isDisabled={
                this.state.currentSlide === subscriptionItems.length - 2
                  ? true
                  : false
              }
              onClick={() => this.slider.slickNext()}
            />
          </div>
        </div>
      )
    )
  }

  renderSubTotal = subscriptionItems => {
    const subTotal = subscriptionItems.reduce((total, item) => {
      let productDetails = getProductDetailsForCart(item)
      const {
        originalPrice = 0,
        grandTotalvalue = '',
        TotalVal = {},
      } = productDetails || {}
      let isAlreadySubscribed =
        nuskinSubscriptionContainer.isAlreadySubscribedProduct(
          productDetails
        ) || false
      const priceFreeValue = TotalVal?.priceAfterDiscount || 0
      const itemPrice =
        grandTotalvalue === '' && priceFreeValue === 0 ? 0 : grandTotalvalue
      const finalPrice = isAlreadySubscribed
        ? 0
        : itemPrice != ''
        ? itemPrice
        : originalPrice
      return total + finalPrice
    }, 0)
    const currencySymbol = cartContainer?.cartResponse?.currencySymbol || '$'

    return (
      subTotal > 0 && (
        <div className="subscription-sub-total pt-4 m-auto font-weight-bold">
          <span>
            {i18nTranslate(
              'subscriptionModal.combinedSubTotal',
              'Combined SubTotal'
            )}
          </span>
          <span className="mr-1">:</span>
          <span>{currencySymbol + subTotal.toFixed(2)}</span>
        </div>
      )
    )
  }

  render() {
    let subscriptionItems = this.props?.subscriptionItems || {}
    const settings = this.getStyleGuides(subscriptionItems)

    return (
      <div id="multiProductSection">
        <div className="product-section subscription-modal-slider-section">
          <div className="products-section-inner">
            {this.renderArrows(subscriptionItems)}
            <div className="product-slider py-3">
              <Slider
                focusOnSelect={true}
                accessibility={true}
                {...settings}
                dots={true}
                ref={slider => (this.slider = slider)}>
                {subscriptionItems.map((product, index) => {
                  let bundleInfo = {}
                  let productDetails = getProductDetailsForCart(product)
                  let isAlreadySubscribed =
                    nuskinSubscriptionContainer.isAlreadySubscribedProduct(
                      productDetails
                    ) || false
                  let scanQualifiedCount =
                    productDetails?.transactionPrice?.skuProperties
                      ?.scanQualifiedCount
                  if (productDetails?.isBundle) {
                    scanQualifiedCount =
                      getScanQualifiedCountSumForBundleSubItems(
                        product?.skus || []
                      )
                    bundleInfo = {
                      isBundle: true,
                      bundleScanCount: scanQualifiedCount,
                    }
                  }
                  let enableScanCard =
                    !isAlreadySubscribed && scanQualifiedCount > 0
                  return (
                    <div
                      className="subscription-slider"
                      key={`product-${product.itemId}`}>
                      <SubscriptionProductItem
                        isPopupForMovingCartProductsToDashboard={true}
                        // ^ use this prop to identify the multi item modal
                        type="cart"
                        // don't change this "type" property,
                        // it is passed and used in subscriptionquantity and scancard related components
                        key={`subscription-${product.itemId}`}
                        product={productDetails}
                        isAlreadySubscribed={isAlreadySubscribed}
                        enableMoveToCart={!isAlreadySubscribed}
                        enableShowScanCard={enableScanCard}
                        handleQuantityChange={this.handleQuantityChange}
                        handleDeleteCartItem={this.handleDeleteCartItem}
                        handleFrequencyChange={this.handleFrequencyChange}
                        decrementCheck={this.decrementCheck}
                        enablePDPNavigation={true}
                        bundleInfo={bundleInfo}
                      />
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
          {this.renderSubTotal(subscriptionItems)}
        </div>
      </div>
    )
  }
}

export { MultipleProductSubscription }
export default MultipleProductSubscription
