import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Card, Row, Col } from 'react-bootstrap'
// import get from 'lodash/get'
import { i18nTranslate } from 'src/utils'
import isFunction from 'lodash/isFunction'
import union from 'lodash/union'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { isMerchPLP } from 'config/appConfig'
import {
  Price,
  ProductBadge,
  ProductPromotion,
  ProductFavorites,
  CustomPrice,
} from 'src/views/components'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { pageNames } from 'src/routes/pathParams'
import { ProductCompare } from '../ProductCompare'
import { MerchAlternateImages } from '../MerchAlternateImages'
import { catalogState } from 'src/models/Catalog/catalogState'
import './styles.scss'

@observer
class ProductListView extends React.Component {
  @observable imageArrayProperties = { images: [], currentIndex: 0 }
  productCard = ''

  constructor(props) {
    super(props)
    if (isMerchPLP) {
      this.imageArrayProperties.images = catalogState.getImageArray(
        props.product
      )
    }
  }

  handleCurrentImage = () => {
    const index = catalogState.handleCurrentImage(this.imageArrayProperties)
    this.imageArrayProperties.currentIndex = index
  }

  renderPriceAndPromotions = props => {
    const { product = {} } = this.props
    const PVPrice = product?.sku?.[0]?.priceFacets?.PV || 0
    const productPromotion = props?.productPromotion || []
    const promotionDetails = union(
      props?.product?.promotion || [],
      productPromotion
    )
    const skuPrice = props?.product?.prices
    const productOrSKUPrice = props?.product?.transactionPrice || skuPrice
    const transactionPrice =
      productOrSKUPrice && productOrSKUPrice.length > 0
        ? productOrSKUPrice[0]
        : productOrSKUPrice
    return (
      <>
        <Price
          currency={props?.product?.currency || ''}
          transactionPrice={transactionPrice}
        />
        <CustomPrice
          custompriceValue={PVPrice}
          custompriceLabel={i18nTranslate('pdp.svprice', 'SV', {
            nameSpace: 'ssr-resource',
          })}
          dataTestId="qa-pdp-pvprice"
        />
        {/* CX121-258 & CX121-435 CV shouldn't display it to the user
        <CustomPrice
          custompriceValue={CVPrice}
          custompriceLabel={i18nTranslate('pdp.cvprice', 'CV')}
          dataTestId="qa-pdp-pvprice"
        /> */}
        <ProductPromotion promotion={promotionDetails} />
      </>
    )
  }

  render() {
    const {
      brand,
      name,
      imageURL: image,
    } = this.props?.product?.properties || {}
    const { identifier } = this.props?.product || {}
    const { currentIndex, images } = this.imageArrayProperties
    const imageURL = isMerchPLP ? images[currentIndex] : image
    const { categoryId, productId, productBadge, isProductComparable } =
      this.props
    // const url = categoryId
    //   ? `${pageNames.product}/${productId}`
    //   : `${pageNames.product}/${productId}`
    const url = `${pageNames.product}/${productId}`

    const isLinkEnabled = isMerchPLP ? 'disabled' : ''
    return (
      <Card
        className={`flex-column list-view mb-2 p-3 position-relative rounded"
        data-testid="qa-product-container ${isLinkEnabled}`}
        ref={element => (this.productCard = element)}
        {...(isMerchPLP === true && {
          onClickCapture: e => {
            if (!e.target.classList.contains('allow-merchplp')) {
              e.stopPropagation()
            }
          },
        })}>
        <ProductBadge productBadge={productBadge} />
        <Row noGutters>
          <Col md={3} xs={5} className="d-flex align-items-center">
            <Link
              to={url}
              className="image-wrapper d-flex justify-content-center align-items-center">
              <LazyLoadImage
                data-testid="qa-product-image"
                src={imageURL || imagePlaceholder}
                alt={name}
                className={'mw-100 mh-100'}
                onError={e => {
                  if (e.target.src != imagePlaceholder) {
                    e.target.onerror = null
                    e.target.src = imagePlaceholder
                  }
                }}
                {...(isMerchPLP && {
                  afterLoad: () => {
                    const { handleSizeCalc } = this.props
                    isFunction(handleSizeCalc) &&
                      handleSizeCalc(this.productCard.offsetHeight)
                  },
                })}
              />
            </Link>
            <MerchAlternateImages
              imageArrayProperties={this.imageArrayProperties}
              handleCurrentImage={this.handleCurrentImage}
            />
          </Col>
          <Col md={9} xs={7} className="pl-3 pl-md-4">
            <Row noGutters>
              <Col sm={11} xs={10}>
                <Card.Title data-testid="qa-product-brand">{brand}</Card.Title>
                <Link to={url} className="text-decoration-none">
                  <Card.Subtitle
                    data-testid="qa-product-name"
                    className="mb-2 text-dark">
                    {name}
                  </Card.Subtitle>
                </Link>
                {this.renderPriceAndPromotions(this.props)}
                {isProductComparable && (
                  <ProductCompare
                    imageURL={imageURL}
                    productId={productId}
                    categoryId={categoryId}
                  />
                )}
              </Col>
              <Col sm={1} xs={2}>
                <ProductFavorites
                  {...{ productId: productId || identifier, name, categoryId }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default ProductListView
export { ProductListView }
