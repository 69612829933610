import React from 'react'
import { Card, Row, Button, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  currencyFormat,
  trackGTMEvents,
  IS_BROWSER,
  application,
} from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import ImageComponent from '../../ImageComponent'
import { i18nTranslate } from 'src/utils'
import { catalogContainer } from 'src/models/Catalog'
import './styles.scss'

class ProductReplacement extends React.Component {
  constructor(props) {
    super(props)
  }

  getPrice = (custompriceValue = '') => {
    const formattedPrice = currencyFormat({
      price: custompriceValue,
    })
    return formattedPrice
  }

  /* fix for ADA as child anchor tags are also included in tab navigation
  *@param {string} domainName 
  inside child elements */

  getDomainWithLocale = url => {
    try {
      const parsedUrl = new URL(url)
      const pathnameParts = parsedUrl?.pathname?.split('/').filter(Boolean)
      const locale = pathnameParts?.slice(0, 2).join('/')
      return `${parsedUrl.origin}/${locale}`
    } catch (error) {
      console.error('Invalid URL:', error)
      return ''
    }
  }
  handleReplacementProductClick = ({
    price = '',
    url = '',
    replacementProductName = '',
    replacementProductId = '',
    categoryName = '',
  }) => {
    let domainName = ''
    if (IS_BROWSER) {
      domainName = window?.location?.href
    } else {
      domainName = new URL(location?.href)
    }
    const domainWithLocal = this.getDomainWithLocale(domainName)
    const cliclUrl = `${domainWithLocal}${url}`
    trackGTMEvents({
      event: 'replaced_product_click',
      ecommerce: {
        click: {
          actionField: {
            list: '',
            action: 'click',
            products: [
              {
                name: replacementProductName,
                id: replacementProductId,
                price: price,
                brand: 'Nuskin',
                category: categoryName,
                item_type: 'replacement_product',
                discontinued_product_name: this.props?.product?.name || '',
                discontinued_product_id: this.props?.product?.skuId || '',
                page_url: domainName,
                click_url: cliclUrl,
              },
            ],
          },
        },
      },
    })
    catalogContainer.replacementProductClick = true
  }
  render() {
    const { product = {} } = this.props
    const recommendedProducts = product?.replacementProduct?.[0] || []
    const replacementProductTitle =
      recommendedProducts?.sku?.[0]?.properties?.title || ''
    const imageUrl = recommendedProducts?.sku?.[0]?.properties?.imageURL || ''
    const priceInfo = recommendedProducts?.sku?.[0]?.prices?.[0]?.price || ''
    const price = priceInfo ? this.getPrice(priceInfo) : ''
    const replacementTitle = i18nTranslate(
      'pdp.ReplacementTitle',
      'TRY THIS INSTEAD!',
      {
        nameSpace: 'ssr-resource',
      }
    )
    let description =
      recommendedProducts?.sku?.[0]?.properties?.description || ''

    const hasMoreThanOneSku = (recommendedProducts?.sku?.[0]?.length || 1) > 1
    const skuId = recommendedProducts?.sku?.[0]?.identifier || ''
    const skuSlug = recommendedProducts?.properties?.slug || ''
    const variantParameter = skuSlug || skuId || ''
    const productOrSlugId = skuSlug
      ? skuSlug
      : recommendedProducts?.sku?.[0]?.identifier || ''
    const shouldAppendVariant = hasMoreThanOneSku && variantParameter

    let url = ''
    if (shouldAppendVariant) {
      url = `${pageNames.product}/${productOrSlugId}?variant=${variantParameter}`
    } else {
      url = `${pageNames.product}/${productOrSlugId}`
    }
    const alt = i18nTranslate(
      'pdp.defaultAltReplacementProductImg',
      'Replacement Product image',
      {
        nameSpace: 'ssr-resource',
      }
    )
    const replacementProductStatus =
      recommendedProducts?.sku?.[0]?.properties?.productStatus
        ?.toLowerCase?.()
        .trim() === 'discontinued'
    const props = {
      isReplacementProduct: true,
    }
    let messageToDisplay = ''
    const replacementPdpStatus =
      recommendedProducts?.sku?.[0]?.properties?.productStatus
        ?.toLowerCase?.()
        .trim()
    switch (replacementPdpStatus) {
      case 'test':
        messageToDisplay = i18nTranslate(
          'bundle.testSku',
          'This is a test sku',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
      case 'preview product':
      case 'preview':
        messageToDisplay = i18nTranslate(
          'bundle.preview',
          'Preview product and will be launched soon',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
      case 'stopped':
        messageToDisplay = i18nTranslate(
          'bundle.stopped',
          'This product is temporarily stopped',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
      case 'replacement':
        messageToDisplay = i18nTranslate(
          'bundle.replacement',
          'This is a replacement product and not available for purchase',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
      case 'bundleonly':
      case 'bundle only':
        messageToDisplay = i18nTranslate(
          'pdp.bundleOnly',
          'Item not available for individual purchase',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
    }
    return (
      <Card className="mb-3 replacement-product">
        <Row
          className={`g-0 p-3 ${
            replacementProductStatus ? 'replacement-product-discontinued' : ''
          }`}>
          <Col md={4}>
            <Link
              to={url}
              className="text-decoration-none"
              onClick={() => {
                this.handleReplacementProductClick({
                  price: price,
                  url,
                  replacementProductName: replacementProductTitle,
                  replacementProductId:
                    recommendedProducts?.sku?.[0]?.identifier,
                  categoryName:
                    recommendedProducts?.[0]?.category?.[0]?.properties?.name ||
                    '',
                })
              }}>
              {application.isMobile && (
                <Card.Title className="replacementContTitle text-center">
                  {replacementTitle}
                </Card.Title>
              )}
              <div className="discontinue-parent-div">
                {replacementProductStatus && (
                  <div className="replament-product-discontinued-text">
                    {i18nTranslate(
                      'pdp.ItemDiscontinued',
                      'Item Discontinued',
                      {
                        nameSpace: 'ssr-resource',
                      }
                    )}
                  </div>
                )}

                <ImageComponent
                  testId="qa-replacement-discontinued-image"
                  className="plp-product-image"
                  src={imageUrl}
                  alt={alt}
                  optimizeImage={true}
                  height={'200px'}
                  width={'200px'}
                />
              </div>
            </Link>
          </Col>
          <Col md={8} className="p-0">
            <Card.Body className="discontinued">
              <Link
                to={url}
                onClick={() => {
                  this.handleReplacementProductClick({
                    price: price,
                    url,
                    replacementProductName: replacementProductTitle,
                    replacementProductId:
                      recommendedProducts?.sku?.[0]?.identifier,
                    categoryName:
                      recommendedProducts?.[0]?.category?.[0]?.properties
                        ?.name || '',
                  })
                }}
                className="text-decoration-none">
                {!application.isMobile && (
                  <Card.Title className="replacementContTitle">
                    {replacementTitle}
                  </Card.Title>
                )}
                <Card.Text className="replacementPdpTitle">
                  {replacementProductTitle}
                </Card.Text>
                <Card.Text>
                  {
                    (description =
                      description && description?.length > 104
                        ? description?.slice(0, 104)?.concat('...')
                        : description)
                  }
                </Card.Text>
                <Card.Text
                  className="availability-text font-weight-bold p-0 m-0 text-bundle-danger"
                  aria-live="polite"
                  role="alert">
                  {messageToDisplay}
                </Card.Text>
              </Link>
              <Button
                className=" addToCardBtn"
                disabled={
                  replacementProductStatus ||
                  (messageToDisplay != '' && replacementPdpStatus != 'test')
                }
                onClick={() => this.props.handleAddToCartClick(props)}>
                {i18nTranslate('addToCart', 'Add to cart', {
                  nameSpace: 'ssr-resource',
                })?.toUpperCase()}{' '}
                {price}
              </Button>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    )
  }
}

export { ProductReplacement }
