import React from 'react'
import { Row } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { APPConfig } from 'config/appConfig'
import './styles.scss'

@observer
class ProductItemId extends React.Component {
  render() {
    const product = this.props
    let skuId = product?.skuId || ''
    let productId = product?.productId || ''
    let itemSize = product?.size || ''
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    /*todo SSR locale*/
    const textForDisplay = enableNewPDPStyle
      ? product.isBundle
        ? i18nTranslate('pdp.itemCode', 'Item', {
            nameSpace: 'ssr-resource',
          }) +
          ': ' +
          productId
        : i18nTranslate('pdp.sku', 'Sku') + ': ' + skuId
      : i18nTranslate('pdp.itemCode', 'Item', {
          nameSpace: 'ssr-resource',
        }) +
        ' # ' +
        skuId
    const textCaseClassName = enableNewPDPStyle
      ? product.isBundle
        ? 'text-capitalize'
        : 'text-uppercase'
      : 'text-capitalize'

    if (this.props?.isExpressPDP) {
      return null
    }
    return (
      <Row noGutters className="flex-column">
        <h2
          className={`itemno heading-5 ${textCaseClassName}`}
          data-testid="Item#">
          {textForDisplay}
        </h2>
        {product?.sku && itemSize && (
          <p className="product-size-near-id paragraph-m">{itemSize}</p>
        )}
      </Row>
    )
  }
}

export { ProductItemId }
