import React from 'react'
import { Pagination as BsPagination } from 'react-bootstrap'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { setResponsiveValues } from 'src/utils'
import { i18nTranslate } from 'src/utils'
import './styles.scss'

@observer
class Pagination extends React.Component {
  nameSpace = 'ssr-resource'
  @observable active = this.props.active
  @observable start = this.props.startPage
  @observable end = this.calculateRange()

  static defaultProps = {
    className: '',
    totalPages: 10,
    startPage: 1,
    numOfPaginationsToShow: setResponsiveValues(5, 4, 3),
    handlePagination: () => {},
  }
  componentDidMount() {
    this.getCurrentPageNumber()
  }
  componentDidUpdate(prevProps) {
    this.checkAndUpdateComponent(prevProps)
  }
  checkAndUpdateComponent(prevProps) {
    const { active, totalPages } = this.props
    if (prevProps.totalPages !== totalPages) {
      this.start = this.props.startPage
      this.active = this.props.active
      this.end = this.calculateRange()
    }
    if (prevProps.active !== active) {
      this.active = active
      this.getCurrentPageNumber()
    }
  }

  getCurrentPageNumber() {
    const totalPagesToShow = this.calculateRange()
    const acutalPaginationsToShow =
      totalPagesToShow <= this.props.numOfPaginationsToShow
        ? totalPagesToShow
        : this.props.numOfPaginationsToShow

    const addedPageCount = this.active + acutalPaginationsToShow - 1

    if (this.active === 1 || addedPageCount <= this.props.totalPages) {
      this.start = this.active
    } else if (addedPageCount >= totalPagesToShow) {
      const remainingPagesCount = addedPageCount - this.props.totalPages
      this.start = Math.abs(this.active - remainingPagesCount)
    }
    this.end = this.start + (acutalPaginationsToShow - 1)
  }
  calculateRange() {
    return this.props.totalPages >= this.props.numOfPaginationsToShow
      ? this.props.numOfPaginationsToShow
      : this.props.totalPages
  }

  handleClickEvent = value => {
    event.preventDefault()
    const { startPage, totalPages } = this.props
    switch (value) {
      case 'prevIndex':
        this.active = this.active === startPage ? startPage : --this.active
        break
      case 'nextIndex':
        this.active = this.active === totalPages ? totalPages : ++this.active
        break
      case 'firstIndex':
        this.active = startPage
        break
      case 'lastIndex':
        this.active = totalPages
        break
      default:
        this.active = value
    }
    this.getCurrentPageNumber()
    this.props.handlePagination(this.active)
  }

  ellipsis = props => {
    if (props === 'start' && this.start > 1) {
      return <BsPagination.Ellipsis />
    }
    if (props === 'end' && this.end < this.props.totalPages) {
      return <BsPagination.Ellipsis />
    }
  }
  renderPageNumbers = () => {
    const items = []
    for (let number = this.start; number <= this.end; number++) {
      items.push(
        <BsPagination.Item
          className="item-wrapper text-align-center"
          key={number}
          aria-label={`Gotopage ${number}`}
          onClick={() => this.handleClickEvent(number)}
          active={number == this.active}
          data-testid={`qa-plp-page-number-${number}`}>
          {number}
        </BsPagination.Item>
      )
    }
    return (
      <>
        {this.ellipsis('start')}
        {items}
        {this.ellipsis('end')}
      </>
    )
  }
  render() {
    const isFirstTriggerDisabled =
      this.start === 1 && this.active === 1 && 'd-none'
    const isLastTriggerDisabled = this.active == this.end && 'd-none'
    const { totalPages } = this.props
    if (totalPages <= 1) {
      return <></>
    }
    return (
      <BsPagination
        className={`pagination-wrapper mt-3 ${
          this.props.alignPagination === 'start'
            ? 'justify-content-start'
            : this.props.alignPagination === 'center'
            ? 'justify-content-center'
            : 'justify-content-end'
        } ${this.props.className}`}
        data-testid="qa-pagination-wrapper">
        <BsPagination.First
          className={isFirstTriggerDisabled}
          data-testid="qa-first-trigger"
          onClick={() => this.handleClickEvent('firstIndex')}
          aria-details={i18nTranslate('catalog.FirstPage', 'first page', {
            nameSpace: this.nameSpace,
          })}
        />
        <BsPagination.Prev
          className={isFirstTriggerDisabled}
          data-testid="qa-prev-trigger"
          onClick={() => this.handleClickEvent('prevIndex')}
          aria-details={i18nTranslate('catalog.PreviousPage', 'previous page', {
            nameSpace: this.nameSpace,
          })}
        />
        {this.renderPageNumbers()}
        <BsPagination.Next
          className={isLastTriggerDisabled}
          data-testid="qa-next-trigger"
          onClick={() => this.handleClickEvent('nextIndex')}
          aria-details={i18nTranslate('catalog.NextPage', 'next page', {
            nameSpace: this.nameSpace,
          })}
        />
        <BsPagination.Last
          className={isLastTriggerDisabled}
          data-testid="qa-last-trigger"
          onClick={() => this.handleClickEvent('lastIndex')}
          aria-details={i18nTranslate('catalog.LastPage', 'last page', {
            nameSpace: this.nameSpace,
          })}
        />
      </BsPagination>
    )
  }
}

export default Pagination
export { Pagination }
