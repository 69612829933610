import * as yup from 'yup'

import { getCouponCode } from 'src/deps'
import { invalidPromocodeErrorMsg } from 'src/deps/validationErrorMessages'

const couponCodeSchema = Object.assign(
  {},
  getCouponCode({
    inputName: 'promoCode',
    requiredErrorText: invalidPromocodeErrorMsg,
    invalidErrorText: invalidPromocodeErrorMsg,
  })
)
export const schema = yup.object(couponCodeSchema)
