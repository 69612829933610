import React from 'react'
import { observer } from 'mobx-react'
import { Button, Container } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import { cartContainer, saveForLaterContainer } from 'src/models'
import {
  Summary,
  CartProducts,
  EmptyCart,
  SaveForLater,
} from 'src/views/components'
import { isFlexibleSubscriptionFlagEnabled } from 'src/deps'
import { CheckoutNuskinIcon } from 'src/views/components/Icons/CheckoutNuSkinIcon'
import './styles.scss'
import { getSeparateCartAndCheckout } from 'config/appConfig'

@observer
class Cart extends React.Component {
  constructor(props) {
    super(props)
  }
  handleCartToggle = async () => {
    cartContainer.toggleCartVisibility()
    if (cartContainer.isCartVisible) {
      await cartContainer.viewCart()
    }
  }

  escKeyToggleHandler = event => {
    if (event.key === 'Escape') {
      this.handleCartToggle()
    }
  }

  /**
   * Navigates to the view cart page or checkout page
   * depending on the separateCartAndCheckout config value.
   */
  navigateToViewCartOrCheckout = () => {
    this.handleCartToggle()
    this.props.history?.push(
      getSeparateCartAndCheckout() ? pageNames.viewCart : pageNames.checkout
    )
  }

  /**
   * Navigates back to the home page when called.
   * Toggles cart visibility off before navigating.
   */
  navigateToHome = () => {
    this.handleCartToggle()
    this.props.history?.push(pageNames.home)
  }

  renderButton() {
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const checkoutButton = (
      //Commenting below code for ADA and link feature achieved with Button itself - CX16-9515
      /*<Link
        className="w-100 d-flex justify-content-end my-4"
        to={
          getSeparateCartAndCheckout() ? pageNames.viewCart : pageNames.checkout
        }
        onClick={this.handleCartToggle}>
        <Button
          autoFocus
          data-testid="qa-cartcheckout"
          className="checkout-button w-100">
          {i18nTranslate('cart.viewCartCheckout', 'View Cart/Checkout')}
        </Button>
      </Link>*/
      <div className="my-4 mini-cart-button-wrapper">
        <Button
          autoFocus
          type="button"
          data-testid="qa-cartcheckout"
          className="checkout-button w-100"
          onClick={this.navigateToViewCartOrCheckout}>
          {isFlexibleSubscriptionEnabled ? (
            <div>
              <span>
                <CheckoutNuskinIcon
                  className="mini-cart-nuskin mr-2"
                  width="24px"
                  height="24px"
                  fill="#ffffff"
                />
              </span>
              <span className="view-cart-checkout-text">
                {i18nTranslate('cart.viewCartCheckout', 'View Cart/Checkout')}
              </span>
            </div>
          ) : (
            i18nTranslate('cart.viewCartCheckout', 'View Cart/Checkout')
          )}
        </Button>
      </div>
    )

    const startShoppingButton = (
      //Commenting below code for ADA and link feature achieved with Button itself - CX16-9515
      /*<Link
        to={pageNames.home}
        className="d-flex justify-content-end py-4"
        onClick={this.handleCartToggle}>
        <Button
          className="w-100 checkout-button"
          data-testid="qa-start-shopping">
          {i18nTranslate('cart.startShopping', 'Start Shopping')}
        </Button>
      </Link>*/
      <div className="py-4">
        <Button
          autoFocus
          className="w-100 checkout-button"
          data-testid="qa-start-shopping"
          onClick={this.navigateToHome}>
          {i18nTranslate('cart.startShopping', 'Start Shopping')}
        </Button>
      </div>
    )

    const button =
      cartContainer.cartCount > 0 ? checkoutButton : startShoppingButton
    return button
  }

  renderCartSummary = () => {
    const currencyCode = cartContainer?.cartResponse?.currencyCode || ''
    const redeemedLoyaltyPoints = cartContainer.redeemedPoints()
    const storeCredit = cartContainer.redeemedStoreCreditPoints()
    const value = cartContainer?.cartResponse?.value || ''
    const TotalPVCV = cartContainer?.cartResponse?.value?.priceFacets || ''
    const balance = cartContainer?.cartResponse?.loyalty?.totalPoints || 0
    const otherChargesIncludingTax = value?.otherChargesIncludingTax || 0
    const summaryValues = {
      priceAfterMarkdown: value.priceAfterMarkdown,
      cartDeliveryCharge: value.cartDeliveryCharge,
      cartDeliveryChargeDiscount: value.cartDeliveryChargeDiscount,
      totalDiscount: value.totalDiscount,
      overAllTax: value.overAllTax,
      grandTotal: value.grandTotal - redeemedLoyaltyPoints - storeCredit,
      overAllDiscount: value.overAllDiscount,
      redeemedLoyaltyPoints: redeemedLoyaltyPoints,
      loyaltyDiscount: value?.loyaltyDiscount,
      openLoyaltyPoints: balance,
      count: value.count,
      storeCredit: storeCredit,
      cartDeliveryChargeAfterDiscount: value.cartDeliveryChargeAfterDiscount,
      otherChargesIncludingTax: otherChargesIncludingTax,
    }
    const promotionDetails =
      cartContainer?.cartResponse?.promotionDetails?.promotions || []

    return (
      <Container
        className="shopping-cart-container"
        role="dialog"
        aria-modal="true"
        aria-labelledby="dialog1_label">
        <div
          className="h4 mt-4 mb-2 mini-cart-total-title"
          data-testid="qa-minicart-title"
          id="dialog1_label">
          {i18nTranslate('cart.subtotalTitle', 'Cart Subtotal')}
        </div>
        <Summary
          isMiniCart={true}
          customClass="p-0 summary-wrapper"
          math={summaryValues}
          currencyCode={currencyCode}
          promotionDetails={promotionDetails}
          showRedeemableSection={true}
          totalValue={TotalPVCV}
        />
        {this.renderButton()}
      </Container>
    )
  }
  render() {
    const { saveForLaterListProducts } = saveForLaterContainer
    const isEmptySaveForLater =
      saveForLaterListProducts && saveForLaterListProducts.length === 0
    const items = cartContainer?.cartResponse?.items || []
    const isEmptyCart = items.length === 0
    const asideClassName = cartContainer.isCartVisible
      ? 'open d-flex flex-direction-column'
      : 'closed d-none'
    const overlayClassName = cartContainer.isCartVisible
      ? 'visible d-block'
      : ''
    const isFlexibleSubscriptionEnabled =
      isFlexibleSubscriptionFlagEnabled() || false
    const flexibleSubscriptionClassName = isFlexibleSubscriptionEnabled
      ? 'mini-cart-flexible-wrapper'
      : ''

    // Reducing unwanted DOM rendering
    if (!cartContainer.isCartVisible) {
      return <></>
    }

    return (
      <div>
        <div
          className={`overlay d-none ${overlayClassName}`}
          onClick={this.handleCartToggle}
        />
        <aside
          className={`sidebar ${asideClassName} ${flexibleSubscriptionClassName}`}>
          <Container
            data-testid="qa-minicart-container"
            className="minicart mini-cart-wrapper"
            onKeyDown={this.escKeyToggleHandler}>
            {this.renderCartSummary()}
            {!isEmptyCart && (
              <>
                <CartProducts
                  isCheckout={false}
                  isSaveForLater={false}
                  className="my-2"
                  isMiniCart={true}
                />
                {/*<EstimateShipping />*/}
              </>
            )}
            {isEmptyCart && <EmptyCart isCheckout={false} />}
            {!isEmptySaveForLater && (
              <SaveForLater isCheckout={false} history={this.props.history} />
            )}
          </Container>
        </aside>
      </div>
    )
  }
}

export { Cart }
export default Cart
