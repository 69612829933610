import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import { tryJSONParse } from 'src/utils'

function getImageList(product) {
  const imageList = product?.properties?.altImage || ''
  const imageURL = product?.properties?.imageURL || ''
  const parsedImageList = []
  parsedImageList.push(imageURL)
  if (imageList) {
    try {
      const imageListParsed = JSON.parse(imageList)
      parsedImageList.push(...imageListParsed)
    } catch (err) {
      parsedImageList.push(imageList)
    }
  }
  return parsedImageList
}

function ImageListWithVideo(product) {
  const imageListWithoutVideo = getImageList(product).map(image => {
    return { type: 'image', source: image }
  })

  const parsedVideo = tryJSONParse(product.properties?.video ?? [])
  const video =
    isArray(parsedVideo) || isString(parsedVideo)
      ? isArray(parsedVideo)
        ? parsedVideo.map(props => {
            return { source: props, type: 'video' }
          })
        : [{ source: product.video, type: 'video' }]
      : []
  return [...imageListWithoutVideo, ...video]
}

function getProductProperties(product, additionalProps) {
  const selectedSku = product?.previousSku || {}

  const {
    properties: productProps = {},
    identifier: productId = '',
    ...productProperties
  } = product

  const {
    properties: skuProps = {},
    identifier: skuId = '',
    ...skuProperties
  } = selectedSku

  const isBundleOrCollection =
    additionalProps.isBundle || additionalProps.isCollection

  const productType =
    product?.properties?.fit || product?.properties?.itemType || ''
  const productSlug = product?.properties?.slug || ''

  const {
    prices = [{}],
    promotion: skuPromotion = [],
    ...remainingSkuProps
  } = skuProperties

  const {
    transactionPrice: productTransactionPrice,
    promotion: productPromotion = [],
    ...remainingProductProps
  } = productProperties

  return {
    selectedSku,
    productId,
    skuId,
    productType,
    productSlug,
    imageListWithVideo: ImageListWithVideo(
      isEmpty(selectedSku) ? product : selectedSku
    ),
    promotion: skuPromotion.concat(productPromotion),
    transactionPrice: isBundleOrCollection
      ? productTransactionPrice
      : prices[0],
    ...additionalProps,
    ...(productProps ?? {}),
    ...(remainingProductProps ?? {}),
    ...(skuProps ?? {}),
    ...(remainingSkuProps ?? {}),
  }
}

export { getProductProperties, ImageListWithVideo }
