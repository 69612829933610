import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { MetaDecorator } from 'src/views/components'
import { pageNames } from 'src/routes/pathParams'
import { IS_BROWSER } from 'src/utils/application'
import './styles.scss'

class NotFound extends React.PureComponent {
  componentDidMount() {
    // Global Payment System - Start
    // If /checkoutGps route not found, redirect to /checkout, or vice versa
    try {
      if (IS_BROWSER) {
        const location = window?.location?.toString()
        if (
          location?.endsWith('/checkout') ||
          location?.endsWith('/checkoutGps')
        ) {
          this.props.history?.replace(pageNames.checkout)
        }
      }
    } catch (e) {
      console.log('Unable to redirect to checkout page')
    }
    // Global Payment System - End
  }

  render() {
    const notFoundTitle = i18nTranslate(
      'notFoundPage.title',
      'Nu Skin | Page Not Found',
      {
        nameSpace: 'ssr-resource',
      }
    )
    const wording = {
      text: i18nTranslate(
        'notFoundPage.text',
        'THE PAGE YOU’RE LOOKING FOR ISN’T HERE.',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      emptyTitle: i18nTranslate(
        'notFoundPage.emptyTitle',
        'Currently, there are no products to list within this category. You may try others.',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      goBack: i18nTranslate('notFoundPage.goBack', 'Go Back', {
        nameSpace: 'ssr-resource',
      }),
      continueShopping: i18nTranslate(
        'notFoundPage.continueShopping',
        'Continue Shopping',
        {
          nameSpace: 'ssr-resource',
        }
      ),
      imageUrl: 'https://raderain.sirv.com/tgiant/tshirts/T-Shrit.png',
    }

    const {
      text = wording.text,
      emptyTitle = wording.emptyTitle,
      goBack = wording.goBack,
      continueShopping = wording.continueShopping,
      imageUrl = wording.imageUrl,
    } = this.props
    return (
      <>
        <MetaDecorator
          title={notFoundTitle}
          imageUrl=""
          imageAlt=""
          description={i18nTranslate('notFound.desc', 'Page Not Found', {
            nameSpace: 'ssr-resource',
          })}
          location={this.props}
        />
        <Container>
          <Row
            noGutters
            className="pt-3 pt-md-5 pb-4 flex-nowrap flex-column flex-lg-row">
            {/*<Col className="flex-grow-0 flex-shrink-1 notfound-image-wrapper px-3 px-md-0 px-sm-5">
              <Image
                src={imageUrl}
                alt="Image not found"
                rounded
                onError={e => (e.target.src = imagePlaceholder)}
              />
              </Col>*/}
            <Col className="pl-lg-4">
              <div className="content-text-title font-weight-bold pb-4 pt-5 text-center">
                {text}
              </div>
              <Link to="/">
                <Button variant="primary" className="btn-font-size">
                  {continueShopping}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export { NotFound }

// export default NotFound
export default NotFound
