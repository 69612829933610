import React from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { Row, Container } from 'react-bootstrap'
import { BsCloudDownload as ResourceDownload } from 'react-icons/bs'
import { AccordionBlockPDP } from 'src/views/components/AccordionBlockPDP'
import ImageComponent from 'src/views/components/ImageComponent'
import { getProductPDPTitle } from 'src/utils/commonUtils'
import { APPConfig } from 'config/appConfig'
import './styles.scss'

@observer
class ProductResources extends React.Component {
  nameSpace = 'ssr-resource'
  renderTitle = () => {
    const { isAccordionType = false } = this.props || {}
    const resourcesLable = getProductPDPTitle(
      this.props.product?.tempProperties,
      'resources'
    )
    return (
      <Row
        className={`about-title ${
          isAccordionType ? 'text-uppercase' : 'border-light-grey'
        }`}
        noGutters>
        {resourcesLable ||
          i18nTranslate('pdp.resources', 'Resources', {
            nameSpace: this.nameSpace,
          })}
      </Row>
    )
  }

  resourceDownloadIconV2 = () => {
    return (
      <svg
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40">
        <g
          id="cloud-down"
          transform="matrix(0.6265349984169006, 0, 0, 0.6215910315513611, 4.6522827148437536, 4.640667915344238)">
          <path
            id="icon"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.0778 0.400108L16.0661 0.399902L16.0544 0.39997C13.9868 0.41197 12.0715 1.43162 10.9038 3.10349C10.1492 2.63407 9.27632 2.37728 8.37853 2.36801L8.36436 2.36786L8.35019 2.36812C5.72234 2.41545 3.60645 4.49352 3.47428 7.08075C1.73854 8.03281 0.659577 9.87489 0.696217 11.8742C0.699766 13.4314 1.51498 14.7696 2.57265 15.6902C3.62858 16.6093 5.0309 17.208 6.40021 17.208H10.7442C11.2965 17.208 11.7442 16.7602 11.7442 16.208C11.7442 15.6557 11.2965 15.208 10.7442 15.208H6.40021C5.58551 15.208 4.63583 14.8346 3.88576 14.1817C3.13974 13.5323 2.69621 12.7024 2.69621 11.864V11.8539L2.696 11.8438C2.66909 10.5098 3.43496 9.28658 4.64673 8.72816L4.68158 8.7121L4.7151 8.69342C5.18944 8.429 5.47892 7.92443 5.46805 7.38179C5.44468 5.74982 6.74169 4.40509 8.372 4.36808C9.03899 4.37824 9.68186 4.61997 10.1904 5.05206C10.5064 5.3229 10.9283 5.43575 11.3374 5.35866L11.3483 5.3566L11.3592 5.3543C11.7637 5.26872 12.1083 5.00693 12.2993 4.64127C13.0537 3.2703 14.4896 2.4133 16.0543 2.40002C18.4912 2.44914 20.4329 4.45597 20.4002 6.89455V7.31797C20.3691 7.91369 20.7211 8.46611 21.2788 8.68765C22.547 9.2123 23.3618 10.4634 23.3286 11.8357C23.2693 13.6653 21.8057 15.137 19.978 15.208H16.0602C15.5079 15.208 15.0602 15.6557 15.0602 16.208C15.0602 16.7602 15.5079 17.208 16.0602 17.208H20.0133L20.0305 17.2074C22.9197 17.1083 25.2367 14.7849 25.3277 11.8955L25.3279 11.8891C25.3799 9.81969 24.2284 7.92136 22.4002 7.00252V6.91439C22.4439 3.37383 19.6189 0.462336 16.0778 0.400108ZM13.4562 12.244C14.0085 12.244 14.4562 12.6917 14.4562 13.244V20.1952L15.8677 18.7782C16.2575 18.3869 16.8907 18.3857 17.2819 18.7755C17.6732 19.1653 17.6744 19.7984 17.2846 20.1897L14.1646 23.3217C13.977 23.5101 13.7221 23.616 13.4562 23.616C13.1903 23.616 12.9354 23.5101 12.7477 23.3217L9.62772 20.1897C9.23794 19.7984 9.23916 19.1653 9.63043 18.7755C10.0217 18.3857 10.6549 18.3869 11.0446 18.7782L12.4562 20.1952V13.244C12.4562 12.6917 12.9039 12.244 13.4562 12.244Z"
            fill="#252525"
          />
        </g>
        <circle
          cx="16.255"
          cy="12.169"
          r="19"
          stroke="#252525"
          stroke-width="2"
          transform="matrix(0.6204200387001038, 0, 0, 0.601360023021698, 2.860244989395145, 4.6875901222229)"
        />
      </svg>
    )
  }

  render() {
    const product = this.props?.product || {}
    const resource = product.resources || []
    let resourcedata = []
    try {
      resourcedata = JSON.parse(resource)
    } catch (e) {
      console.log('Data parse error::: ProductResources' + e)
    }
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'

    if (resourcedata?.length > 0) {
      return (
        <>
          <div className="product-accordion-wrapper ">
            <AccordionBlockPDP
              title={this.renderTitle()}
              isDefaultOpen={false}
              className="accordion-description"
              dataTestId="qa-pdp-product-resources">
              <Container className="pdp-accordion-content-container">
                <div className="sustainability-accordion d-flex flex-wrap align-items-start justify-content-between">
                  <div className="resource-inner">
                    <div className="resources-det-lists">
                      <ul
                        className={`flex-container d-flex align-items-start justify-content-start flex-wrap text-center ${
                          enableNewPDPStyle ? '' : 'p-0'
                        }`}>
                        {resourcedata?.length > 0 &&
                          resourcedata.map((resourcedet, index) => {
                            return resourcedet?.title ? (
                              <a
                                href={resourcedet?.url}
                                target="_blank"
                                key={index}
                                rel="noreferrer">
                                <li>
                                  {resourcedet?.image?.url ? (
                                    <ImageComponent
                                      testid="qa-product-image"
                                      src={resourcedet?.image?.url}
                                      alt={resourcedet?.image?.alt}
                                      height="50px"
                                      // width="50px"
                                    />
                                  ) : enableNewPDPStyle ? (
                                    <span data-testid="qa-pdp-resources-download-icon">
                                      {this.resourceDownloadIconV2()}
                                    </span>
                                  ) : (
                                    <ResourceDownload className="resourcedownload" />
                                  )}

                                  <p
                                    className="resource-name paragraph-s-underline res-p-size"
                                    title={resourcedet?.title}>
                                    <span
                                      aria-label="Download"
                                      className="resource_link">
                                      {resourcedet?.title}
                                    </span>
                                  </p>
                                </li>
                              </a>
                            ) : (
                              <></>
                            )
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </Container>
            </AccordionBlockPDP>
          </div>
        </>
      )
    }
    return <></>
  }
}

export { ProductResources }
