import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import { i18nTranslate } from 'src/utils'
import { Dropdown } from 'react-bootstrap'
// import { Droppable } from 'react-beautiful-dnd'
import { getCategoryList } from '../fixture'

@observer
class RecommendedSection extends Component {
  handleChange = value => {
    this.props.changeCategory(value)
  }

  @computed
  get products() {
    const { recommendedProducts, categorySelected } = this.props
    if (categorySelected) {
      return recommendedProducts.filter(
        product => product.properties.type === categorySelected
      )
    }
    return recommendedProducts
  }

  render() {
    const { renderImages, categorySelected } = this.props
    const allItems = i18nTranslate('outfit.allItems', 'All Items')
    const categoryList = getCategoryList()
    const category = categoryList.find(
      data => data.categoryId === categorySelected
    )

    return (
      <>
        <Dropdown className="text-center py-2" onSelect={this.handleChange}>
          <Dropdown.Toggle variant="" id="dropdown-basic">
            {category && category.name ? category.name : allItems}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {[{ name: allItems, categoryId: '' }]
              .concat(categoryList)
              .map((category, index) => {
                return (
                  <Dropdown.Item
                    key={`${category}-${index}`}
                    value={category.categoryId}
                    eventKey={category.categoryId}>
                    {category.name}
                  </Dropdown.Item>
                )
              })}
          </Dropdown.Menu>
        </Dropdown>
        <div className="mb-3 text-center font-weight-bold text-success">
          {i18nTranslate('outfit.recommendedTitle', 'Recommended')}
        </div>
        <div className="d-flex justify-content-center">
          {/* <Droppable
            isDropDisabled={true}
            droppableId={`recommend-droppable`}
            type="outfit-drop">
            {provided => ( */}
          <div
            // {...provided.droppableProps}
            // ref={provided.innerRef}
            className="grid-products">
            {this.products.length > 0 ? (
              this.products.map((item, index) =>
                renderImages(item, index, 'recommend')
              )
            ) : (
              <div className="d-flex justify-content-center">
                {i18nTranslate('outfit.noRecommend', 'No products found')}
              </div>
            )}
          </div>
          {/* )}
          </Droppable> */}
        </div>
      </>
    )
  }
}

export { RecommendedSection }
