import React, { Component } from 'react'
import { i18nTranslate } from 'src/utils'
import { Button, Image } from 'react-bootstrap'
import { observer } from 'mobx-react'
import cart from 'src/images/cart-add.png'
import { checkoutContainer, cartContainer, customerContainer } from 'src/models'
import { observable } from 'mobx'
import { PayLaterMessage } from 'src/views/components/PayPal/PayLaterMessage'
import { productState } from 'src/views/components/BaseProductComponent/ProductState'
import { isPaylaterMessageRequired } from 'src/views/components/PayPal'
import { productDeps, dateFormatDeps } from 'src/deps'
import {
  modifyAndGetCustomerType,
  modifyAndGetAccountType,
  checkCustomerTypeEligibility,
} from 'src/utils'
import { APPConfig } from 'config/appConfig'
import { KlarnaMessage } from 'src/views/components/KlarnaMessage'
import { getLiveEventStatus } from 'src/utils'
// import { ProductFavoritesWithText } from '../ProductFavoritesWithText'
import './styles.scss'

@observer
class ProductAddToCart extends Component {
  @observable totalPrice = ''
  constructor(props) {
    super(props)
    checkoutContainer.loadBraintreeCheckoutScript()
    checkoutContainer.loadBraintreeClientScript()
  }
  async componentDidMount() {
    this.renderTotal()
    cartContainer.isDisableAddToCart = this.addtoCartDisable()
  }
  enableLiveEvent = getLiveEventStatus()
  isEventRegisteredUser = this.checkEventRegisteredUser()

  checkEventRegisteredUser() {
    const profileResponse = customerContainer?.profileResponse
    const accountProperties = profileResponse?.accounts?.properties || []
    const userProperty = accountProperties?.filter(
      property => property?.attributeId === 'registeredEvent'
    )
    return userProperty?.[0]?.value || 'false'
  }
  renderTotal() {
    const product = this.props
    const { transactionPrice, totalValue = {} } = product
    const regprice =
      transactionPrice && transactionPrice.price ? transactionPrice.price : ''
    const quantity = this.props?.productCount
    const totalcount = quantity * regprice
    this.totalPrice = totalcount.toFixed(2)
    const priceAfterDiscount =
      totalValue?.totaldiscount && totalValue?.priceAfterDiscount
        ? totalValue?.priceAfterDiscount
        : 0
    const totalPriceAfterDiscount = quantity * priceAfterDiscount
    return totalValue?.totaldiscount && totalValue?.priceAfterDiscount ? (
      <>
        <span className="strikeThrough pr-2">
          {'$' + totalcount.toFixed(2)}
        </span>
        <span>{'$' + totalPriceAfterDiscount}</span>
      </>
    ) : (
      '$' + totalcount.toFixed(2)
    )
  }

  // @##pdp_button_disable_and_status_display_logic
  // Adding condition to check if SKU is eligible to be added to cart
  getSkuEligible = () => {
    const productCount = this.props?.productCount
    const inventory = this.props?.inventories || {}
    const product = this.props
    const customerTypeResult =
      modifyAndGetCustomerType(this.props?.customerTypes) || ''
    let accoutTypeResult =
      modifyAndGetAccountType(
        customerContainer?.profileResponse?.accounts?.accountType
      ) || ''
    const isCustomerEligible = checkCustomerTypeEligibility(
      customerTypeResult,
      accoutTypeResult
    )
    let atpQty = inventory?.atpQty
    let outOfStockThreshold = inventory?.outOfStockThreshold
    const status = product.status
    let backOrderedQty = inventory?.backOrderedQty
    const selectedSku = productDeps.getUserSelectedSku(this.props)
    const inventoryStatus = selectedSku?.inventory || ''
    const productStatus = selectedSku?.properties?.productStatus?.toLowerCase()

    if (
      productStatus == 'preview' ||
      (atpQty <= 0 && backOrderedQty <= 0) ||
      productStatus == 'discontinued' ||
      status === 'inactive' ||
      inventoryStatus?.toLowerCase() === 'out of stock' ||
      !isCustomerEligible ||
      inventoryStatus?.toLowerCase() === 'unavailable'
    ) {
      return true
    } else {
      if (productCount > atpQty - outOfStockThreshold) {
        let currentDateTimeStamp = Date.now()
        let backorderTime = ''
        if (
          inventory?.expectedBackOrderAvailabilityDate?.toString?.().length <=
          10
        ) {
          backorderTime = inventory?.expectedBackOrderAvailabilityDate + '000'
        } else {
          backorderTime = inventory?.expectedBackOrderAvailabilityDate
        }
        backorderTime = Number(backorderTime)

        // @##backorder_logic_pdp_message_or_button

        if (backOrderedQty > 0) {
          let expectedbackorder = productCount - (atpQty - outOfStockThreshold)
          let backorderTime = ''
          if (
            inventory?.expectedBackOrderAvailabilityDate?.toString?.().length <=
            10
          ) {
            backorderTime = inventory.expectedBackOrderAvailabilityDate + '000'
          } else {
            backorderTime = inventory.expectedBackOrderAvailabilityDate
          }
          backorderTime = Number(backorderTime)
          let { date, month, year } = dateFormatDeps.convertTimeStampToDate(
            backorderTime,
            true
          )
          if (
            expectedbackorder <= backOrderedQty &&
            backorderTime >= currentDateTimeStamp
          ) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return false
      }
    }
  }

  addtoCartDisable() {
    const selectedSku = productDeps.getUserSelectedSku(this.props)
    const { isEnableAddToCartBtn } = this.props
    if (this.props.isSkuBundleOnly) {
      return this.props.isSkuBundleOnly
    }
    const dangerousGoods =
      selectedSku?.properties?.dangerousGoods === 'true' ||
      this.props?.properties?.dangerousGoods === 'true'
    if (dangerousGoods || isEnableAddToCartBtn !== true) {
      return true
    }

    const isExclusive = selectedSku?.properties?.isExclusive || ''

    // condition to check quantity limit for exclusive products based on cart items
    if (isExclusive) {
      const productId = this.props?.productId
      const cartItems = cartContainer?.cartResponse?.items || []
      let maxProductQty
      const productQualificationResponse =
        customerContainer?.qualificationResponse?.qualification?.filter(p => {
          return p?.productId === productId
        })
      if (
        productQualificationResponse &&
        productQualificationResponse.length > 0
      ) {
        if (cartItems && cartItems.length > 0) {
          cartItems.forEach(item => {
            if (item?.skus?.[0]?.productId == productId) {
              maxProductQty =
                productQualificationResponse?.[0]?.quantity - item?.quantity
            }
          })
          if (maxProductQty <= 0 || this.props?.productCount > maxProductQty) {
            return true
          }
        }
      }
    }
    const isQualificationEligible =
      this.props?.isQuickview == 'true'
        ? this.props?.isQualificationEligble
        : this.props?.properties?.isQualificationEligble || ''
    const isToDisableAddToCart = this.getSkuEligible()

    const { isItemEligible } = productState.getQualificationMessage({
      productId: this.props?.productId,
      isExclusive,
    })

    if (isToDisableAddToCart) {
      return isToDisableAddToCart
    }
    return !isItemEligible
  }

  render() {
    const { dateOfAvailablity, isPreOrder = false } = this.props
    const product = this.props
    const preOrderDateTimeStamp = new Date(dateOfAvailablity).getTime()
    const currentDateTimeStamp = Date.now()
    const isPreOrderProduct =
      preOrderDateTimeStamp >= currentDateTimeStamp ? isPreOrder : false
    const {
      isBundle = '',
      prices = '',
      transactionPrice = '',
      totalValue = '',
    } = product
    const regprice =
      transactionPrice && transactionPrice?.price ? transactionPrice?.price : ''
    const quantity = this.props?.productCount || ''
    const totalcount = quantity * regprice
    const totalPrice = totalcount?.toFixed(2)
    const priceAfterDiscount =
      totalValue?.totaldiscount && totalValue?.priceAfterDiscount
        ? totalValue?.priceAfterDiscount
        : 0
    const totalPriceAfterDiscount = quantity * priceAfterDiscount
    const modifiedTransPrice = isBundle ? prices[0] : transactionPrice
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    let buttonType = ''
    try {
      buttonType =
        JSON.parse(isPreOrderProduct) === true
          ? i18nTranslate('pdp.b2cPreOrder', 'Preorder', {
              nameSpace: 'ssr-resource',
            })
          : i18nTranslate('pdp.b2cAddToCart', 'Add to Cart', {
              nameSpace: 'ssr-resource',
            })
    } catch (e) {
      console.error(
        'JSON.parse may be an error > ProductAddToCart.render() >>> ',
        e
      )
    }
    const userCountry = customerContainer.getUsersCountry()

    return (
      <div className="add-cart-btn">
        <Button
          variant="primary"
          onClick={this.props.handleAddToBag}
          disabled={
            this.enableLiveEvent === 'true'
              ? this.addtoCartDisable() ||
                (customerContainer.isRegisterUser &&
                  this.isEventRegisteredUser === 'false')
              : this.addtoCartDisable()
          }
          className="btn-primary btn-addToCart border-0 w-100 text-uppercase d-flex justify-content-center align-items-center align-content-center mb-2"
          data-testid="qa-addcart">
          {!enableNewPDPStyle && (
            <Image
              src={cart}
              alt={i18nTranslate('addToCart', 'Add to cart', {
                nameSpace: 'ssr-resource',
              })}
              // className="header-cart-icon"
              style={{ fontSize: '20px', verticalAlign: 'top' }}
            />
          )}
          <span className="pl-1">{buttonType}</span>
        </Button>

        {productDeps.isEnableAddToCartBtn(product) && (
          <div className="klarna-pdp-unit-price-wrapper">
            <KlarnaMessage
              price={
                totalValue?.totaldiscount && totalValue?.priceAfterDiscount
                  ? totalPriceAfterDiscount
                  : totalPrice
              }
              id={'klarna-pdp-message'}
              data-key={'credit-promotion-badge'}
            />
          </div>
        )}

        {isPaylaterMessageRequired(userCountry || 'US') && (
          <div className="paylater-pdp-unit-price-wrapper">
            <PayLaterMessage
              amount={
                totalValue?.totaldiscount && totalValue?.priceAfterDiscount
                  ? totalPriceAfterDiscount?.toString()
                  : totalPrice
              }
              key={'paylater-pdp-unit-price-wrapper'}
              id={'paylater-pdp-unit-price-wrapper'}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ProductAddToCart
export { ProductAddToCart }
