import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { tryJSONParse } from 'src/utils'
import './styles.scss'

@observer
class PreOrderTime extends Component {
  render() {
    const {
      dateOfAvailablity = 0,
      className = '',
      isPreOrder = false,
    } = this.props
    const preOrderDateTimeStamp = new Date(dateOfAvailablity).getTime()
    const currentDateTimeStamp = Date.now()

    if (
      tryJSONParse(isPreOrder) === false ||
      preOrderDateTimeStamp <= currentDateTimeStamp
    ) {
      return ''
    }

    return (
      <div className={className ? className : 'p-3 my-3 preorder-time'}>
        {i18nTranslate(
          'pdp.PreOrderTime',
          'This pre-order item will be available to ship on approx',
          {
            nameSpace: 'ssr-resource',
          }
        )}
        {new Date(dateOfAvailablity).toLocaleDateString()}
      </div>
    )
  }
}

export { PreOrderTime }
