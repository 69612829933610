import React from 'react'

const NuskinDropDownArrowIcon = props => (
  <svg
    {...props}
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.70702 7.62379C7.3165 8.01432 6.68334 8.01432 6.29281 7.62379L0.459477 1.79046C0.0689526 1.39994 0.0689526 0.766772 0.459477 0.376247C0.850001 -0.014277 1.48317 -0.014277 1.87369 0.376247L6.99992 5.50247L12.1261 0.376247C12.5167 -0.014277 13.1498 -0.014277 13.5404 0.376247C13.9309 0.766772 13.9309 1.39994 13.5404 1.79046L7.70702 7.62379Z"
      fill="#5F5F5F"
    />
  </svg>
)

export default NuskinDropDownArrowIcon
export { NuskinDropDownArrowIcon }
