import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { customerContainer } from 'src/models'
import {
  modifyAndGetCustomerType,
  modifyAndGetAccountType,
  checkCustomerTypeEligibility,
} from 'src/utils'
import { dateFormatDeps, productDeps } from 'src/deps'

function getBackOrderInfo(props) {
  const {
    productCount = 0,
    atpQty = 0,
    outOfStockThreshold = 0,
    inventory,
    backOrderedQty,
  } = props || {}
  let inventoryStatusView = ''
  let currentDateTimeStamp = Date.now()
  let backorderTime = ''

  if (inventory?.expectedBackOrderAvailabilityDate?.toString?.().length <= 10) {
    backorderTime = inventory?.expectedBackOrderAvailabilityDate + '000'
  } else {
    backorderTime = inventory?.expectedBackOrderAvailabilityDate
  }

  backorderTime = Number(backorderTime)
  /**
   * @Note
   * EQH-94 - Back order date shows up incorrectly on EQ
   * Changed to UTC Format, as per BackEnd Team Elangovan's Request
   */
  let { date, month, year } = dateFormatDeps.convertTimeStampToDate(
    backorderTime,
    true
  )
  const requestedQtyNotAvailable = i18nTranslate(
    'pdp.OOSQtyTxt',
    'OUT OF STOCK',
    {
      nameSpace: 'ssr-resource',
    }
  )

  // @##backorder_logic_pdp_message_or_button

  if (backOrderedQty > 0) {
    let expectedbackorder = productCount - (atpQty - outOfStockThreshold)
    if (
      backorderTime >= currentDateTimeStamp &&
      expectedbackorder <= backOrderedQty
    ) {
      inventoryStatusView =
        i18nTranslate('backordered', 'Ships by') +
        ' ' +
        `${month}/${date}/${year}`
    } else {
      inventoryStatusView = requestedQtyNotAvailable
    }
  } else {
    inventoryStatusView = requestedQtyNotAvailable
  }
  return inventoryStatusView
}

function getBackOrderInfoForOrderHistory(props) {
  const {
    isToShowOrderPlacedBackOrderDateForOrderhistory = false,
    backOrderDate = '',
  } = props || {}

  let inventoryStatusView = ''
  let currentDateTimeStamp = Date.now()
  let backorderTime = ''
  if (isToShowOrderPlacedBackOrderDateForOrderhistory && backOrderDate !== '') {
    if (backOrderDate?.toString?.().length <= 10) {
      backorderTime = backOrderDate + '000'
    } else {
      backorderTime = backOrderDate
    }

    backorderTime = Number(backorderTime)
    /**
     * @Note
     * EQH-94 - Back order date shows up incorrectly on EQ
     * Changed to UTC Format, as per BackEnd Team Elangovan's Request
     */
    let { date, month, year } = dateFormatDeps.convertTimeStampToDate(
      backorderTime,
      true
    )
    const requestedQtyNotAvailable = i18nTranslate(
      'pdp.OOSQtyTxt',
      'OUT OF STOCK',
      {
        nameSpace: 'ssr-resource',
      }
    )

    if (backorderTime >= currentDateTimeStamp) {
      inventoryStatusView =
        i18nTranslate('backordered', 'Ships by') +
        ' ' +
        `${month}/${date}/${year}`
    } else if (backorderTime <= currentDateTimeStamp) {
      inventoryStatusView =
        i18nTranslate('orderHistory.backOrdered', 'Back ordered') +
        ' ' +
        `${month}/${date}/${year}`
    } else {
      inventoryStatusView = requestedQtyNotAvailable
    }
  }
  return inventoryStatusView
}
// @##pdp_button_disable_and_status_display_logic
@observer
class ProductInventory extends Component {
  render() {
    const { productCount = '1', product = {} } = this.props || {}
    const selectedSku =
      product?.selectedSku ||
      productDeps.getUserSelectedSku({
        sku: product?.sku,
        skuId: this.props.product?.skuId,
      })

    const inventory = selectedSku?.inventoryProperties || {}
    const atpQty = inventory.atpQty || ''
    let outOfStockThreshold = inventory.outOfStockThreshold || ''
    let backOrderedQty = inventory.backOrderedQty || ''
    let inventoryStatusView = ''
    let flagInventory = ''

    const { status = '', productStatus = '' } = selectedSku?.skuProperties || {}

    if (
      status.toLowerCase() === 'active' &&
      productStatus.toLowerCase() === 'test'
    ) {
      inventoryStatusView = 'This is a test sku'
    }
    switch (productStatus.toLowerCase()) {
      case 'replacement':
        inventoryStatusView = i18nTranslate(
          'bundle.replacement',
          'This is a replacement product and not available for purchase',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
      case 'preview product':
      case 'preview':
        inventoryStatusView = i18nTranslate(
          'bundle.preview',
          'Preview product and will be launched soon',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
      case 'stopped':
        inventoryStatusView = i18nTranslate(
          'bundle.stopped',
          'This product is temporarily stopped',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
      case 'discontinued':
        inventoryStatusView = i18nTranslate(
          'bundle.discontinuedV2Message',
          'Discontinued - This product or promotion has been discontinued',
          {
            nameSpace: 'ssr-resource',
          }
        )
        break
    }
    // }
    const customerTypeResult =
      modifyAndGetCustomerType(this.props?.product?.customerTypes) || ''
    let accoutTypeResult =
      modifyAndGetAccountType(
        customerContainer?.profileResponse?.accounts?.accountType
      ) || ''
    const isCustomerEligible = checkCustomerTypeEligibility(
      customerTypeResult,
      accoutTypeResult
    )
    const inventoryState = selectedSku?.inventory || ''
    const dangerousGoods =
      selectedSku?.properties?.dangerousGoods === 'true' ||
      product?.properties?.dangerousGoods === 'true'

    if (inventoryStatusView === '') {
      if (inventoryState?.toLowerCase() === 'unavailable') {
        inventoryStatusView = i18nTranslate('coming.Soon', 'Coming soon..', {
          nameSpace: 'ssr-resource',
        })
        flagInventory = true
      } else if (inventoryState?.toLowerCase() === 'out of stock') {
        inventoryStatusView = i18nTranslate('cart.outOfStock', 'OUT OF STOCK')
        flagInventory = true
      } else if (!isCustomerEligible) {
        inventoryStatusView = i18nTranslate(
          'pdp.userEligible',
          'This user is not eligible to purchase this product',
          {
            nameSpace: 'ssr-resource',
          }
        )
      } else if (atpQty <= 0 && backOrderedQty <= 0) {
        inventoryStatusView = i18nTranslate(
          'item.notAvailable',
          'Item currently not available'
        )
        flagInventory = true
      } else if (dangerousGoods) {
        inventoryStatusView = i18nTranslate(
          'pdp.dangerousGoodsError',
          'Dangerous Goods cannot be added to Cart or Subscribed',
          {
            nameSpace: 'ssr-resource',
          }
        )
        flagInventory = true
      } else {
        flagInventory = false
        if (productCount > atpQty - outOfStockThreshold) {
          inventoryStatusView = getBackOrderInfo({
            productCount,
            atpQty,
            outOfStockThreshold,
            inventory,
            backOrderedQty,
          })
        } else {
          inventoryStatusView = ''
        }
      }
    }

    return (
      <>
        {inventoryStatusView && (
          <Col
            id="qa-product-availability"
            className={`${
              !flagInventory
                ? 'availability-text paragraph-m-bold pt-2 p-0'
                : 'availability-text paragraph-m-bold pt-2 text-danger p-0'
            }`}
            data-testid="qa-product-availability">
            {inventoryStatusView}
          </Col>
        )}
      </>
    )
  }
}

export default ProductInventory
export { ProductInventory, getBackOrderInfo, getBackOrderInfoForOrderHistory }
