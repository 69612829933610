import { APPConfig } from 'config/appConfig'
import {
  storeContainer,
  cartContainer,
  customerContainer,
  applepayContainer,
} from 'src/models'
import { convertToBoolean } from 'src/utils'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'

export function cardIcons() {
  return {
    visa: {
      src: 'https://raderain-cdn.sirv.com/cards/visacard.png',
      alt: 'Visa card icon',
    },
    mastercard: {
      src: 'https://raderain-cdn.sirv.com/cards/mastercard.png',
      alt: 'Master card icon',
    },
    discover: {
      src: 'https://raderain-cdn.sirv.com/cards/discover.png',
      alt: 'Discover card icon',
    },
    american_express: {
      src: 'https://raderain-cdn.sirv.com/cards/amex.png',
      alt: 'American express card icon',
    },
    paypal: {
      src: 'https://raderain-cdn.sirv.com/cards/PayPal%20(Inverted).png',
      alt: 'Paypal icon',
    },
    diners_club: {
      src: 'https://raderain-cdn.sirv.com/cards/diners.png',
      alt: 'Diners club card icon',
    },
    jcb: {
      src: 'https://raderain-cdn.sirv.com/cards/JCB-payment.svg',
      alt: 'JCB card icon',
    },
    bankTransfer: {
      src: 'https://media.nuskin.com/m/69e64c39bc30ac7c/original/CC-25.png',
      alt: 'Bank Transfer icon',
    },
  }
}

export function getValueByPath(obj = {}, path = '') {
  const keys = path.split('.')
  let result = obj
  for (const key of keys) {
    if (result[key] !== undefined) {
      result = result[key]
    } else {
      return false
    }
  }
  return result
}

export function getAvailablePaymentsForAddNewSections(props) {
  const { paymentType } = props
  const cartCount = cartContainer?.cartResponse?.items?.length
  const appConfig = APPConfig?.getAppConfig()
  let isToEnablePayment = false
  const bankTransferConfig =
    APPConfig?.getAppConfig()?.bankTransferPayment || {}
  const { enable } = bankTransferConfig || {}
  const paymentConfig = {
    klarna: {
      configName: 'enableKlarnaPayment',
      checkoutLimitConfigName: 'KlarnaLimit',
    },
    payPal: {
      configName: 'enablePaypal',
      checkoutLimitConfigName: 'payPalLimit',
    },
    aliPay: {
      configName: 'enableAlipay',
      checkoutLimitConfigName: 'aliPayLimit',
    },
    venmo: {
      configName: 'enableVenmoPayment',
      checkoutLimitConfigName: 'venmoLimit',
    },
    googlePay: {
      configName: 'enableGooglePayPayment',
      checkoutLimitConfigName: 'googlePayLimit',
    },
    applePay: {
      configName: 'enableApplePayPayment',
      checkoutLimitConfigName: 'applePayLimit',
    },
    bankTransfer: {
      configName: 'bankTransferPayment.enable',
      checkoutLimitConfigName: 'bankTransferLimit',
    },
  }

  const currentPayment = paymentConfig[paymentType]
  const isPaymenConfigEnabled = convertToBoolean(
    getValueByPath(appConfig, currentPayment?.configName)
  )

  let paymentLimit = -1

  try {
    paymentLimit =
      parseInt(
        appConfig?.checkoutLimit?.[currentPayment?.checkoutLimitConfigName],
        10
      ) || -1
  } catch (error) {
    paymentLimit = -1
    console.log(error)
  }

  if (isPaymenConfigEnabled) {
    if (paymentLimit == -1 || cartCount <= paymentLimit) {
      isToEnablePayment = true
    }
  }
  return isToEnablePayment
}

/**
 * Returns an array of payment options to be displayed in the "Add New Payment" section.
 * The options are determined based on the app configuration, subscription products in cart and the user's existing payment methods.
 * @returns {Array} An array of payment option objects, each with properties like `type`, `displayName`, `iconType`, `dataTestId`, `paymentId`, `isToShowPaymentOption`, and `isToShowDescription`.
 */
export const getPaymentsForAddNewSection = () => {
  const isToEnableKlarnaPayment = getAvailablePaymentsForAddNewSections({
    paymentType: 'klarna',
  })

  const isToEnableVenmoPayment = getAvailablePaymentsForAddNewSections({
    paymentType: 'venmo',
  })

  const isToEnableAlipay = getAvailablePaymentsForAddNewSections({
    paymentType: 'aliPay',
  })

  const isToEnableGooglePayPayment = getAvailablePaymentsForAddNewSections({
    paymentType: 'googlePay',
  })

  const isToEnablePaypal = getAvailablePaymentsForAddNewSections({
    paymentType: 'payPal',
  })

  const isToEnableBankTransfer = getAvailablePaymentsForAddNewSections({
    paymentType: 'bankTransfer',
  })

  let isNativeApp = checkIsNativeApp() || false
  const isToEnableGooglePayForNativeApps =
    convertToBoolean(
      APPConfig?.getAppConfig()?.enableGooglePayPaymentForNativeApps
    ) || false

  let isToEnableGooglePay = isNativeApp
    ? isToEnableGooglePayForNativeApps && isToEnableGooglePayPayment
    : isToEnableGooglePayPayment

  //ApplePay starts
  const isConfigApplyePayEnabled = getAvailablePaymentsForAddNewSections({
    paymentType: 'applePay',
  })

  const isCurrentDeviceSupportedApplePay = () => {
    return applepayContainer.checkIfDeviceSupportsApplePay()
  }

  const isToEnableApplePayPayment =
    (isConfigApplyePayEnabled && isCurrentDeviceSupportedApplePay()) || false

  //ApplePay Ends

  let hasSubscriptionProductInCart =
    cartContainer.hasSubscriptionProduct() || false

  const isPaymentAlreadyAddedInProfile = (paymentType = '') => {
    return customerContainer.paymentResponse?.payments?.find?.(cards => {
      return cards?.type?.toLowerCase() === paymentType
    })
  }

  const addNewPaymentOptions = [
    {
      type: 'CREDITCARD',
      displayName: 'Credit Card',
      iconType: 'creditcard',
      dataTestId: 'qa-credit-card-payment-option',
      paymentId: 'paymentType-creditcard-option',
      isToShowPaymentOption: true,
      isToShowDescription: false,
    },
    {
      type: 'ALIPAY',
      displayName: 'Alipay',
      iconType: 'alipay',
      dataTestId: 'qa-alipay-payment-option',
      paymentId: 'paymentType-alipay-option',
      isToShowPaymentOption:
        isToEnableAlipay &&
        storeContainer.storeIDValue === 'Canada' &&
        !isPaymentAlreadyAddedInProfile('alipay') &&
        !hasSubscriptionProductInCart,
      isToShowDescription: false,
    },
    {
      type: 'PAYPAL',
      displayName: 'PayPal',
      iconType: 'paypal',
      dataTestId: 'qa-paypal-payment-option',
      paymentId: 'paymentType-paypal-option',
      isToShowPaymentOption:
        isToEnablePaypal &&
        !isPaymentAlreadyAddedInProfile('paypal') &&
        !hasSubscriptionProductInCart,
      isToShowDescription: false,
    },
    {
      type: 'VENMO',
      displayName: 'Venmo',
      iconType: 'venmo',
      dataTestId: 'qa-venmo-payment-option',
      paymentId: 'paymentType-venmo-option',
      isToShowPaymentOption:
        storeContainer.storeIDValue === 'US' &&
        !hasSubscriptionProductInCart &&
        isToEnableVenmoPayment &&
        !isPaymentAlreadyAddedInProfile('venmo'),
      isToShowDescription: false,
    },
    {
      type: 'KLARNA',
      displayName: 'Klarna',
      iconType: 'klarna',
      dataTestId: 'qa-klarna-payment-option',
      paymentId: 'paymentType-klarna-option',
      isToShowPaymentOption:
        !isPaymentAlreadyAddedInProfile('klarna') &&
        !hasSubscriptionProductInCart &&
        isToEnableKlarnaPayment,
      isToShowDescription: true,
    },
    {
      type: 'GOOGLEPAY',
      displayName: 'Google Pay',
      iconType: 'googlepay',
      dataTestId: 'qa-gpay-payment-option',
      paymentId: 'paymentType-gpay-option',
      isToShowPaymentOption:
        !isPaymentAlreadyAddedInProfile('gpay') &&
        !hasSubscriptionProductInCart &&
        isToEnableGooglePay,
      isToShowDescription: false,
    },
    {
      type: 'APPLEPAY',
      displayName: 'Apple Pay',
      iconType: 'applepay',
      dataTestId: 'qa-applepay-payment-option',
      paymentId: 'paymentType-applepay-option',
      isToShowPaymentOption:
        isToEnableApplePayPayment && !hasSubscriptionProductInCart, //need to add additional conditions
      isToShowDescription: false,
    },
    {
      type: 'BANKTRANSFER',
      displayName: 'Bank Deposit',
      iconType: 'bankTransfer',
      dataTestId: 'qa-bank-transfer-payment-option',
      paymentId: 'paymentType-bank-transfer-option',
      isToShowPaymentOption:
        isToEnableBankTransfer &&
        !isPaymentAlreadyAddedInProfile('bankTransfer') &&
        !hasSubscriptionProductInCart,
      isToShowDescription: false,
    },
  ]

  return addNewPaymentOptions
}
