import { observable } from 'mobx'
import { checkEnableButton, IS_BROWSER, i18nTranslate } from 'src/utils'
import { VARIANT_NODES, APPConfig } from 'config/appConfig'
import { pageNames } from 'src/routes/pathParams'
import { promotionContainer } from 'src/models'
import { isEmpty } from 'lodash'

/**
 * ProductDeps class.
 * Likely contains product related dependencies and utilities.
 */
class ProductDeps {
  @observable optionalData = []
  /**
   * updateData formats the given data object into the expected structure for a bundle/kit product's skus array.
   *
   * @param {object} data - The product data object to format
   * @param {string} type - Either "Mandatory" or "Optional" indicating if this is a required or optional product in the bundle
   * @returns {object} The formatted sku data
   */
  updateData = (data, type, backorderParams) => {
    const skuQuantity = (
      data?.quantity ||
      data?.properties?.quantity ||
      1
    ).toString()
    const formattedData = {
      productId: data.identifier,
      skuId: data?.selectedSku?.identifier || '',
      type: type.toUpperCase(),
      [type?.toLowerCase() === 'optional'
        ? 'optionalSkuQuantity'
        : 'skuQuantity']: skuQuantity,
      availableChannels: data?.selectedSku?.skuProperties?.availableChannels,
      backOrdered: data?.selectedSku?.inventoryProperties?.backOrdered,
      slug: data?.properties?.slug || '',
    }
    if (
      backorderParams?.compareAndSetBackorderBasedOnQuantity &&
      backorderParams?.currentlySelectedQuantity
    ) {
      // ^ for the main "bundle" product, quantity will not be present in cartArray
      // for main product in the array, we need not check the backorder logic
      // all attached skus are checked below
      const quantityForSubProduct =
        backorderParams.currentlySelectedQuantity * skuQuantity
      // has the qty of the subproduct
      // we need to multiply with current incrementor value
      // and then compare it with the stock
      const isSubProductBackOrdered =
        productDeps.checkBackOrderBasedOnCurrentQuantityForCartFlag(
          {
            inventoryProperties: data?.selectedSku?.inventoryProperties || {},
            properties: data?.selectedSku?.properties || {},
            inventory: data?.selectedSku?.inventory || '',
          },
          quantityForSubProduct
        )

      if (isSubProductBackOrdered) {
        formattedData.isBackOrdered = true
        formattedData.backOrdered = true
      }
    }
    return formattedData
  }
  /**
   * Gets the wrapper type for a given product type.
   * Maps product types to expected wrapper types.
   *
   * @param {string} type - The product type
   * @returns {string} The mapped wrapper type
   */
  getWrapperType = type => {
    switch (type?.toLowerCase()) {
      case 'fixedpricebundle':
        return 'FIXED_BUNDLE'
      case 'bundle':
        return 'BUNDLE'
      case 'kit':
        return 'SKUKIT'
    }
  }

  /**
   * Gets the bundle data for the given product.
   * Formats the product data into the expected structure for bundle/kit products.
   * @param {object} product - The product data
   * @returns {object} The formatted bundle data
   */
  getCurrentBundleDataForInventoryCheck = product => {
    const bundleData = []
    const type = this.getWrapperType(product?.type) || ''
    if (type.toLowerCase() === 'skukit') {
      const skuKitProduct = product?.skuKit || []
      skuKitProduct?.forEach(data => {
        bundleData.push(data)
      })
    } else {
      const mandatoryProduct = product?.bundleMandatoryProducts || []
      const optionalProduct = product?.bundleOptionalProducts || []
      mandatoryProduct?.forEach(data => {
        if (data.selectedSku) {
          bundleData.push(data)
        }
      })
      optionalProduct.forEach(data => {
        if (data.selectedSku && this.optionalData?.includes(data.identifier)) {
          bundleData.push(data)
        }
      })
    }
    return bundleData
  }

  getBundleData = (
    product,
    backorderParams = {
      compareAndSetBackorderBasedOnQuantity: false,
    }
  ) => {
    const type = this.getWrapperType(product.type)
    const skuId = product.productId
    if (skuId) {
      const bundleData = [
        {
          productId: skuId,
          skuId,
          type,
          availableChannels: product?.properties?.availableChannels,
        },
      ]
      if (type.toLowerCase() === 'skukit') {
        const skuKitProduct = product?.skuKit || []
        skuKitProduct?.filter(data => {
          bundleData.push(this.updateData(data, 'Mandatory', backorderParams))
        })
        return { skus: bundleData }
      } else {
        const mandatoryProduct = product?.bundleMandatoryProducts || []
        const optionalProduct = product?.bundleOptionalProducts || []
        mandatoryProduct?.filter(data => {
          if (data.selectedSku) {
            bundleData.push(this.updateData(data, 'Mandatory', backorderParams))
          }
        })
        optionalProduct.filter(data => {
          if (
            data.selectedSku &&
            this.optionalData?.includes(data.identifier)
          ) {
            bundleData.push(this.updateData(data, 'Optional', backorderParams))
          }
        })
        const bundleLength = mandatoryProduct.length + this.optionalData?.length
        if (bundleData.length - 1 === bundleLength) {
          return { skus: bundleData }
        }
      }
    }
  }
  /**
   * Transforms the product response to select the appropriate SKU
   * and format bundle/collection products.
   * @param {Object} response - The product response
   * @param {string} skuId - The SKU ID to select
   * @param {Object} props - The component props
   * @param {string} defaultVariantForQuickView - The default variant for quick view
   * @returns {Object} The transformed product response
   */
  transformProductResponse = (
    response,
    skuId,
    props,
    defaultVariantForQuickView
  ) => {
    const product = response?.product?.[0]
    if (this.isBundleData(product) || this.isCollectionData(product)) {
      response.product[0] = this.transformForBundleOrCollection(product)
    } else {
      response.product[0] = this.getSelectedSku(
        product,
        skuId,
        props,
        defaultVariantForQuickView
      )
    }
    return response
  }

  // bundleProducts & collection product transform
  /**
   * Transforms bundle and collection products by mapping
   * over the sub-products and selecting the appropriate SKU for each.
   *
   * @param {Object} data - The product data
   * @returns {Object} The transformed product data
   */
  transformForBundleOrCollection = data => {
    const mandatoryProductArray = data?.bundleMandatoryProducts || []
    const optionalProductArray = data?.bundleOptionalProducts || []
    const subProductsArray = data?.subProducts || []
    const skuKitProductArray = data?.skuKit || []

    if (mandatoryProductArray.length) {
      data.bundleMandatoryProducts = mandatoryProductArray?.map(product => {
        return this.getSelectedSku(product)
      })
    }
    if (optionalProductArray.length) {
      data.bundleOptionalProducts = optionalProductArray?.map(product => {
        return this.getSelectedSku(product)
      })
    }
    if (subProductsArray.length) {
      data.subProducts = subProductsArray?.map(product => {
        return this.getSelectedSku(product)
      })
    }
    if (skuKitProductArray.length) {
      data.skuKit = skuKitProductArray?.map(product => {
        return this.getSelectedSku(product)
      })
    }
    return data
  }

  // Default Sku selection
  /**
   * Selects the appropriate SKU for a product based on given parameters.
   *
   * @param {Object} response - Response object containing product data
   * @param {string} skuId - ID of selected SKU
   * @param {Object} props - Component props object
   * @param {string} defaultVariantForQuickView - Default variant slug for quick view
   * @returns {Object} Updated response object with selected SKU data
   */
  getSelectedSku = (
    response,
    skuId,
    props,
    defaultVariantForQuickView = ''
  ) => {
    const skuObject = {}
    const productOption = response?.productOption || []
    let selectedSku = response?.selectedSku || {}
    // skuId = skuId ? skuId : response?.properties?.defaultSkuId || ''
    const defaultSkuId = skuId || response?.properties?.defaultSkuId || ''
    const skuList = response?.sku || []
    let foundSku
    let variantQueryParam = props?.location?.queryParams?.variant

    if (IS_BROWSER && !variantQueryParam) {
      const currentUrl = new URL(location.href)
      variantQueryParam = currentUrl?.searchParams?.get('variant')
    }

    if (skuId) {
      foundSku = skuList?.find(sku => sku.identifier === skuId)
    } else if (defaultVariantForQuickView) {
      /* @variant_autoselect */
      foundSku = skuList?.find(
        sku =>
          (sku?.properties?.slug &&
            sku?.properties?.slug === defaultVariantForQuickView) ||
          sku?.identifier === defaultVariantForQuickView
      )
    } else if (variantQueryParam) {
      /* @variant_autoselect */
      foundSku = skuList?.find(
        sku =>
          (sku?.properties?.slug &&
            sku?.properties?.slug === variantQueryParam) ||
          sku?.identifier === variantQueryParam
      )
    } else if (defaultSkuId) {
      foundSku = skuList?.find(sku => sku.identifier === defaultSkuId)
    } else {
      foundSku = skuList?.find(sku => sku.default === true)
    }

    if (foundSku) {
      selectedSku = foundSku
      response.selectedSku = selectedSku
    }

    if (selectedSku) {
      productOption &&
        productOption.find(option => {
          option.values?.find(value => {
            if (value.label === selectedSku.properties[option.type]) {
              skuObject[option.type] = value.label
            }
          })
        })
    } else {
      productOption &&
        productOption.find(option => {
          option.values?.find(value => {
            skuObject[option.type] = value.label
          })
        })
    }
    if (selectedSku) {
      response.previousSku = selectedSku
    }

    response.skuSelected = skuObject
    response.productOption = this.disableEnableSku(response, response.sku)
    return response
  }

  /**
   * disableEnableSku updates the product options based on the currently selected SKU, to disable options that are incompatible.
   *
   * It takes in the product data, list of SKUs, and currently selected SKU.
   *
   * It finds all compatible SKU options based on the currently selected SKU, then loops through the product options to disable any values that are not compatible.
   *
   * It returns the updated product options.
   */
  disableEnableSku = (product, skuList, currentlySelectedSku) => {
    const { productOption, skuSelected } = product

    const availableSkus = Object.entries(
      currentlySelectedSku
        ? { [currentlySelectedSku.type]: currentlySelectedSku.value }
        : skuSelected
    ).reduce((acc, typeValue) => {
      const [type, value] = typeValue

      skuList?.forEach(sku => {
        if (sku?.properties?.[type] === value) {
          VARIANT_NODES?.forEach(skuName => {
            if (skuName !== type) {
              if (sku.properties[skuName]) {
                if (acc[skuName]) {
                  const name = sku.properties[skuName]
                  if (acc[skuName].indexOf(name) === -1) acc[skuName].push(name)
                } else {
                  acc[skuName] = [sku.properties[skuName]]
                }
              }
            }
          })
        }
      })
      return acc
    }, {})
    productOption &&
      productOption.forEach(option => {
        if (currentlySelectedSku && option.type !== currentlySelectedSku.type) {
          if (!availableSkus[option.type].includes(skuSelected[option.type])) {
            skuSelected[option.type] = availableSkus[option.type][0] || ''
          }
          option.values?.forEach(optionValues => {
            optionValues.disable = !availableSkus[option.type].includes(
              optionValues.label
            )
              ? true
              : false
          })
        } else {
          option.values?.forEach(optionValues => {
            optionValues.disable = false
          })
        }
      })
    return (product.productOption = productOption)
  }

  changeVariantInUrlForPDP = (context, variantParameter = '', props = {}) => {
    /*
     * when variant selection happens,
     * url should be updated
     * https://nuskin.atlassian.net/browse/CX26-659
     */
    /* @variant labelchange */
    const { isFromSubProduct = false } = props
    if (context?.isPDP && !isFromSubProduct) {
      // doesn't work in local, to be fixed based on url and pagenames
      try {
        if (IS_BROWSER && variantParameter !== '') {
          let currentUrlParms = new URLSearchParams(
            window?.location?.search || ''
          )
          currentUrlParms?.set('variant', variantParameter)
          props.history?.replace({
            search: currentUrlParms?.toString(),
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  // Change Sku Selection
  /**
   * Updates the product state when the selected SKU changes.
   *
   * @param {Object} props - The component props.
   * @param {Object} props.product - The product data.
   * @param {Object} props.selectedProp - The newly selected SKU property.
   *
   * @returns {Object} The updated product state with the new selected SKU.
   */
  changeSku = props => {
    const { product, selectedProp } = props
    const currentlySelectedSku = {
      type: selectedProp.type,
      value: selectedProp.value,
    }
    const skuList = product.skuList || product.sku

    product.productOption = this.disableEnableSku(
      product,
      skuList,
      currentlySelectedSku
    )
    product.selectedSku = skuList?.find(sku => {
      const flag = VARIANT_NODES.some(defaultSku => {
        return sku.properties[defaultSku] !== product.skuSelected[defaultSku]
      })
      return flag === false
    })
    product.previousSku = product.selectedSku
      ? product.selectedSku
      : product.previousSku
    return product
  }

  /**
   * Constructs breadcrumb list item for a category.
   *
   * @param {Object[]} parentCategories - Array of parent category objects
   * @param {boolean} isInitialCategory - Whether this is the top level category
   * @returns {Object} Breadcrumb list item object with label and url value
   */

  constructList = (parentCategories, isInitialCategory) => {
    const identifier = parentCategories?.[0]?.identifier || ''
    const parentCategoryRedirectionMapping =
      APPConfig?.getAppConfig()?.parentCategoryRedirectionMapping || {}
    const shouldRedirectToParentCategoryFromStoreConfig =
      APPConfig?.getAppConfig()?.shouldRedirectToParentCategory === 'true'
    const isToRedirectToCategoryLanding =
      shouldRedirectToParentCategoryFromStoreConfig &&
      Object.keys(parentCategoryRedirectionMapping).some(category => {
        return identifier == category
      })
    const pageUrl =
      isInitialCategory || isToRedirectToCategoryLanding
        ? pageNames.categoryLanding
        : pageNames.catalog

    return {
      label: parentCategories?.[0]?.properties?.name || '',
      value: `${pageUrl}/${
        isToRedirectToCategoryLanding
          ? parentCategoryRedirectionMapping[identifier]
          : identifier
      }`,
    }
  }
  /**
   * Recursively builds a breadcrumb list from a category tree.
   * @param {Object[]} parentCategories - Array of parent category nodes
   * @param {Object[]} listArray - Breadcrumb array accumulator
   * @param {boolean} isInitialCategory - Whether currently at the top-level category
   * @returns {Object[]} - Built breadcrumb list array
   */
  getParentCategories(
    parentCategories,
    listArray = [],
    isInitialCategory = false
  ) {
    const childOfParentCategories = parentCategories?.[0]?.parentCategories

    if (childOfParentCategories) {
      isInitialCategory = childOfParentCategories?.[0]?.identifier == 'top'
      listArray.push(this.constructList(parentCategories, isInitialCategory))
      this.getParentCategories(childOfParentCategories, listArray)
    } else {
      listArray.push(this.constructList(parentCategories, isInitialCategory))
    }
    return listArray
  }
  /**
   * Returns the default breadcrumb list item for the home page.
   *
   * @returns {Object} Breadcrumb list item object with label and url value
   */
  defaultBreadCrumb = () => {
    return {
      label: i18nTranslate('breadCrumb.home', 'Home', {
        nameSpace: 'ssr-resource',
      }),
      value: '/',
    }
  }
  /**
   * Builds a breadcrumb navigation list for categories and product detail pages.
   * @param {Object} categories - Category tree object
   * @param {string} productName - Name of product if on PDP, undefined if on category page
   * @returns {Object[]} Built breadcrumb list array
   */
  getBreadCrumbList = (categories, productName) => {
    const parentCategories = categories?.parentCategories
    let list = [this.defaultBreadCrumb()]
    if (parentCategories) {
      const listArray = this.getParentCategories(parentCategories, [])
      list = list.concat(listArray.reverse())
    }
    const categoryLink = categories?.identifier || ''
    const finalCategory = {
      label: categories?.properties?.name || '',
      value: productName ? `/catalog/${categoryLink}` : '/',
      isLastElement: productName ? false : true,
    }
    list.push(finalCategory)

    if (productName) {
      const productTitle = {
        label: productName,
        value: '/',
        isLastElement: true,
      }
      list.push(productTitle)
    }
    return list
  }
  /**
   * Checks if the response data is for a bundle product by looking at the
   * product type value. Returns true if the product type matches bundle,
   * fixedpricebundle or kit.
   */
  isBundleData = response => {
    const productType = response?.type || 'product'
    switch (productType.toLowerCase()) {
      case 'bundle':
      case 'fixedpricebundle':
      case 'kit':
        return true
      default:
        return false
    }
  }
  /**
   * Checks if the response data is for a collection product type.
   * @param {Object} response - The product response data
   * @returns {boolean} True if the product type is 'collection', false otherwise
   */
  isCollectionData = response => {
    const productType = response?.type || 'product'
    return productType.toLowerCase() == 'collection' ? true : false
  }
  // to remove "calculatePrice" if price logic handled in SFO - #BundlePriceLogic
  /**
   * Calculates the total price for a bundle product based on the prices of the mandatory and optional products.
   * @param {Object} data - The bundle product data
   * @returns {number} The total calculated price
   */
  calculatePrice = data => {
    const productType = data?.type || ''
    if (productType.toLowerCase() === 'bundle') {
      const mandatoryProductArray = data?.bundleMandatoryProducts || []
      const optionalProductArray = data?.bundleOptionalProducts || []
      let price = 0
      mandatoryProductArray?.forEach(product => {
        price =
          price +
          parseFloat(product?.selectedSku?.prices?.[0]?.price || 0) *
            (product.quantity || product.properties.quantity)
      })
      optionalProductArray?.forEach(product => {
        if (this.optionalData?.includes(product.identifier)) {
          price =
            price +
            parseFloat(product?.selectedSku?.prices?.[0]?.price || 0) *
              (product?.quantity || product?.properties?.quantity)
        }
      })
      return price
    }
  }
  /**
   * Gets the selected SKU from the given props
   * @param {Object} props - The component props
   * @returns {Object} The selected SKU object
   */
  getUserSelectedSku = props => {
    const { sku = [], skuId } = props || {}
    const selectedSku = sku.find(currentSku => currentSku.identifier === skuId)
    return selectedSku
  }
  /**
   * Checks if subscription is enabled for the given product
   * @param {Object} props - The component props
   * @returns {boolean} True if subscription is enabled, false otherwise
   */
  isToEnableSubscription = props => {
    const product = props?.product || {}
    const selectedSku = this.getUserSelectedSku(product)

    let availableChannelsList = ''
    if (selectedSku) {
      availableChannelsList =
        selectedSku?.skuProperties?.availableChannels || ''
    } else {
      availableChannelsList = product.availableChannels || ''
    }
    const isToEnableSubscription = checkEnableButton(
      availableChannelsList,
      'subscription'
    )
    const isExclusive = product?.isExclusive
    return isToEnableSubscription && isExclusive != 'true'
  }

  /**
   *  Function to check Add to cart button is enabled or not
   * @date 10/11/2023 - 4:40:53 PM
   *
   * @param {*} product - product details
   * @returns {*} boolean - true if enabled else false
   */
  /**
   * Checks if Add to Cart button should be enabled for given product
   * @param {Object} product - Product details
   * @returns {boolean} True if Add to Cart should be enabled, false otherwise
   */
  isEnableAddToCartBtn = product => {
    const selectedSku = this.getUserSelectedSku(product)
    let availableChannelsList = ''
    if (selectedSku) {
      availableChannelsList =
        selectedSku?.skuProperties?.availableChannels || ''
    } else {
      availableChannelsList = product.availableChannels || ''
    }
    const shouldEnableAddToCart = checkEnableButton(
      availableChannelsList,
      'web'
    )
    return shouldEnableAddToCart
  }
  /**
   *  Function to check product is replacement product or not
   * @date 10/24/2023 - 2:40:53 PM
   *
   * @param {*} selectedSku - selected product details
   * @returns {*} boolean - true if enabled else false
   */

  /**
   * Checks if the given SKU is for a replacement product.
   *
   * @param {Object} selectedSku - The selected SKU to check
   * @returns {Boolean} True if the SKU is for a replacement product, false otherwise
   */
  getIsReplacementItem = selectedSku => {
    const isRepalcementItem =
      selectedSku?.properties?.productStatus?.toLowerCase?.() === 'replacement'
    return isRepalcementItem || false
  }

  getSubProductLink = subProduct => {
    /* variant_autoselect */
    const productOrSlugId = subProduct?.properties?.slug
      ? subProduct?.properties?.slug
      : subProduct?.identifier

    let link = `${pageNames?.product}/${productOrSlugId}`
    const skuId = subProduct?.previousSku?.identifier || ''
    const skuSlug = subProduct?.previousSku?.properties?.slug || ''
    const hasMoreThanOneSku = (subProduct?.sku?.length || 1) > 1
    const variantParameter = skuSlug || skuId || ''
    /* @variant_autoselect */
    const hasVariant = subProduct?.productOption?.length > 0
    // we have to append even when there is one variant,
    // as same product's PDP can have multiple
    if ((hasMoreThanOneSku || hasVariant) && variantParameter) {
      link += `?variant=${variantParameter}`
    }
    /*
    unit test case: where the sub product has only one variant, and pdp has multiple variant
    https://test.nuskin.com/us/en/product/lifepak-nano-and-tegreen-120-subscription-US
    https://test.nuskin.com/us/en/product/kickstart-your-business-px-month-1-US

    sub product with multiple variant:
    https://test.nuskin.com/us/en/product/tyler-bundle
    */
    return link
  }

  checkBackOrderBasedOnCurrentQuantityForCartFlag = (
    selectedSku = {},
    currentlySelectedQuantity = 1
  ) => {
    // @##backorder_logic_cart
    let shouldSendBackOrderFlagToCart = false
    const inventory = selectedSku?.inventoryProperties || {}

    let atpQty = inventory?.atpQty
    let outOfStockThreshold = inventory?.outOfStockThreshold
    let backOrderedQty = inventory?.backOrderedQty
    const inventoryStatus = selectedSku?.inventory || ''
    const productStatus = selectedSku?.properties?.productStatus
    if (selectedSku?.inventory == 'BACKORDER') {
      shouldSendBackOrderFlagToCart = true
    } else if (inventory.backOrdered) {
      shouldSendBackOrderFlagToCart = true
    } else if (currentlySelectedQuantity > atpQty - outOfStockThreshold) {
      let currentDateTimeStamp = Date.now()
      let backorderTime = ''
      if (
        inventory?.expectedBackOrderAvailabilityDate?.toString?.().length <= 10
      ) {
        backorderTime = inventory?.expectedBackOrderAvailabilityDate + '000'
      } else {
        backorderTime = inventory?.expectedBackOrderAvailabilityDate
      }
      backorderTime = Number(backorderTime)

      if (backOrderedQty > 0) {
        let expectedbackorder =
          currentlySelectedQuantity - (atpQty - outOfStockThreshold)
        if (
          backorderTime >= currentDateTimeStamp &&
          expectedbackorder <= backOrderedQty
        ) {
          shouldSendBackOrderFlagToCart = true
        }
      }
    }
    return shouldSendBackOrderFlagToCart
  }

  getUnitValue = (props = {}) => {
    let unitValue = {}
    const isBundleOrKit =
      props.product?.type === 'bundle' || props.product?.type === 'kit'
    if (isEmpty(promotionContainer.subscriptionV2PromoResponse)) {
      if (isBundleOrKit) {
        unitValue = props.product?.totalValue || {}
      } else {
        unitValue = props.product?.selectedSku?.totalValue || {}
      }
    } else {
      unitValue = promotionContainer.subscriptionV2PromoResponse.unitValue || {}
    }
    return unitValue
  }

  getPromotionNode = (props = {}, productState) => {
    let product = {}
    const isBundleOrKit =
      props.product?.type === 'bundle' || props.product?.type === 'kit'
    if (isEmpty(promotionContainer.subscriptionV2PromoResponse)) {
      if (isBundleOrKit) {
        product = props.product || {}
      } else {
        product = props.product || {}
      }
    } else {
      product = promotionContainer.subscriptionV2PromoResponse || {}
    }
    if (productState.currentlySelectedPriceOption === 'one-time-price-button') {
      return product.promotion || []
    } else {
      return product.subscriptionPromotion || []
    }
  }

  getTotalValueNode = (props = {}) => {
    let totalValue = {}
    const isBundleOrKit =
      props.product?.type === 'bundle' || props.product?.type == 'kit'
    if (isEmpty(promotionContainer.subscriptionV2PromoResponse)) {
      if (isBundleOrKit) {
        totalValue = props.product?.totalValue || {}
      } else {
        totalValue = props.product?.selectedSku?.totalValue || {}
      }
    } else {
      totalValue =
        promotionContainer.subscriptionV2PromoResponse.totalValue || {}
    }

    return totalValue
  }
}

const productDeps = new ProductDeps()
export default productDeps
export { productDeps }
