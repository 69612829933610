import React, { Component } from 'react'
import { i18nTranslate } from 'src/utils'
import { IoIosClose as CloseIcon } from 'react-icons/io'
import { Button } from 'react-bootstrap'

class TopNotice extends Component {
  nameSpace = 'ssr-resource'
  constructor(props, context) {
    super(props, context)
  }

  removeRow1 = () => {
    let x = document.getElementById('myDIV')
    if (x.style.display === 'none') {
      x.style.display = 'block'
    } else {
      x.style.display = 'none'
    }
  }

  render() {
    const { topNotice } = this.props
    return (
      <div className="row1" id="myDIV">
        <div className="d-flex justify-content-between ">
          <div className="cross-btn-desk text-center nu-dynamic-info-font">
            <a className="row1-link" href={topNotice?.href}>
              <>{topNotice?.title}</>
            </a>
          </div>
          <div className="text-right header-close">
            <Button className="close-btn " onClick={this.removeRow1}>
              <CloseIcon
                size="32px"
                aria-label={i18nTranslate('icons.close', 'close icon', {
                  nameSpace: this.nameSpace,
                })}
              />
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default TopNotice
