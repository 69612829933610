import React from 'react'
import { observer } from 'mobx-react'
import { isBrandAffiliateTypeProfile } from 'src/views/pages/MyAccount/MyAccountProfile/LoyaltyForm/fixture'
import { customerContainer } from 'src/models'
import { ProductEarnPointText } from './ProductEarnPointText'
import { ProductRedeemPointText } from './ProductRedeemPointText'
import './styles.scss'

@observer
class ProductEarnAndRedeemText extends React.Component {
  render() {
    const {
      selectedSku = {},
      pointEarn = 'false',
      pointRedeem = 'false',
      isBundle,
      isCollection,
    } = this.props

    const { skuProperties = {} } = this.props
    const pointEarnVal =
      isBundle || isCollection ? pointEarn : skuProperties.pointEarn || 'false'
    const pointEarnStringify =
      typeof pointEarnVal === 'boolean'
        ? String(pointEarn).toLowerCase()
        : pointEarn

    const pointRedeemVal =
      isBundle || isCollection
        ? pointRedeem
        : selectedSku?.properties?.pointRedeem || 'false'
    const pointRedeemStringify =
      typeof pointRedeemVal === 'boolean'
        ? String(pointRedeem).toLowerCase()
        : pointRedeem
    //commented for CX121-2283 sl.no: 6
    // if (
    //   pointEarnStringify === 'false' &&
    //   pointRedeemStringify === 'true' &&
    //   (isBrandAffiliateTypeProfile() || !isBrandAffiliateTypeProfile())
    // ) {
    //   if (
    //     (isLoyEnableInProf() &&
    //       isAllowedRewardTypeForOptIn() &&
    //       customerContainer.isRegisterUser) ||
    //     (isBuyerUser() && isLoyEnableInProf())
    //   ) {
    //     return (
    //       <div
    //         data-testid="qa-product-earnPointAndRedeemTxt"
    //         className="product-earnPointTxt-wrapper d-flex flex-column mt-2">
    //         <span className="product-earnPointTxt font-weight-bolder text-info">
    //           {i18nTranslate(
    //             'pdp.pdpLoyaltyEarnAndRedeemPointTxt',
    //             'Nu Skin Rewards available!'
    //           )}
    //         </span>
    //       </div>
    //     )
    //   }
    // }
    if (
      pointEarnStringify === 'true' &&
      pointRedeemStringify === 'true' &&
      (isBrandAffiliateTypeProfile() || !isBrandAffiliateTypeProfile())
    ) {
      return <ProductRedeemPointText {...this.props} />
    }
    // commented for CX121-2283 sl.no: 6
    // if (
    //   pointEarnStringify === 'false' &&
    //   pointRedeemStringify === 'false' &&
    //   /!isBrandAffiliateTypeProfile()
    // ) {
    //   if (
    //     (isLoyEnableInProf() &&
    //       isAllowedRewardTypeForOptIn() &&
    //       customerContainer.isRegisterUser) ||
    //     (isBuyerUser() && isLoyEnableInProf())
    //   ) {
    //     return (
    //       <div
    //         data-testid="qa-product-earnPointAndRedeemTxt"
    //         className="product-earnPointTxt-wrapper d-flex flex-column mt-2">
    //         <span className="product-earnPointTxt font-weight-bolder text-secondary">
    //           {i18nTranslate(
    //             'pdp.pdpLoyaltyEarnRedeemNotAvailableTxt',
    //             'Nu Skin Rewards not available!'
    //           )}
    //         </span>
    //       </div>
    //     )
    //   }
    // }
    if (
      pointEarnStringify === 'true' &&
      pointRedeemStringify === 'false' &&
      customerContainer.isRegisterUser
    ) {
      return <ProductEarnPointText {...this.props} />
    }
    if (
      pointEarnStringify === 'false' &&
      pointRedeemStringify === 'true' &&
      customerContainer.isRegisterUser
    ) {
      return <ProductRedeemPointText {...this.props} />
    }
    if (!customerContainer.isRegisterUser) {
      return <ProductEarnPointText {...this.props} />
    }
    return ''
  }
}

export { ProductEarnAndRedeemText }
