import React from 'react'
import { cartContainer, catalogContainer, customerContainer } from 'src/models'
import { catalogDeps } from '.'

export class SearchDeps {
  /**
   * Handles the process of adding a product to the cart.
   * copied from viewcartSearch -> addtocart function, we are not useing same function because few attribute in API response are not same as viewcartSearch
   * This function is responsible for preparing the necessary data and making the appropriate API calls to add a product to the cart. It handles both regular products and bundle/kit products, ensuring the correct data structure is used for each type.
   *
   * @param {object} product - The product object to be added to the cart.
   * @returns {Promise<void>} - A Promise that resolves when the product has been added to the cart.
   */
  async handleAddtoCart(product) {
    const isRegularProduct =
      product?.properties?.customProductType === 'Regular SKU'
    const postData = {
      quantity: 1,
    }
    if (!isRegularProduct) {
      // Product call made for bundle/kit product, because we do not have required sku data available in search call response.
      const response = await catalogContainer.getProduct({
        productId: product?.identifier,
      })
      const {
        bundleMandatoryProducts = [],
        skuKit = [],
        identifier = '',
        type = 'DEFAULT',
        properties: {
          availableChannels = '',
          scanQualifiedCount = 0,
          name = '',
          slug = '',
        },
      } = response?.product?.[0] || {}
      const productType = type == 'kit' ? skuKit?.[0]?.type : type || 'DEFAULT'

      postData['skus'] = [
        {
          productId: identifier,
          skuId: identifier,
          type: productType?.toUpperCase(),
          availableChannels,
        },
      ]
      const mandatoryProducts =
        type == 'bundle' ? bundleMandatoryProducts : skuKit

      let isBundleBackOrdered = false
      mandatoryProducts.forEach(mandatoryProduct => {
        const defaultSku =
          catalogDeps.getDefaultSelectedSku(mandatoryProduct)?.[0] || {}
        postData['skus'].push({
          productId: mandatoryProduct?.identifier || '',
          skuId: defaultSku?.identifier || '',
          type: 'MANDATORY',
          skuQuantity: mandatoryProduct?.properties?.quantity || 1,
          availableChannels: defaultSku?.properties?.availableChannels || '',
          backOrdered: defaultSku?.inventoryProperties?.backOrdered || false,
        })
        if (
          !isBundleBackOrdered &&
          defaultSku?.inventoryProperties?.backOrdered == true
        )
          isBundleBackOrdered = true
      })

      postData['properties'] = {
        categoryId: response?.category?.identifier || '',
        slug,
        scanQualifiedCount: Number(scanQualifiedCount || 0),
        isBackOrdered: isBundleBackOrdered,
        name,
        productId: identifier,
      }
    } else {
      postData['skus'] = [
        {
          skuId: product?.sku?.[0]?.identifier || '',
          productId: product?.identifier || '',
          type: product?.properties?.producttype || 'DEFAULT',
        },
      ]
      postData['properties'] = {
        categoryId:
          (product?.properties?.productCategory || '').split(',')?.[0] || '',
        slug: product?.properties?.slug,
        scanQualifiedCount:
          product?.sku?.[0]?.properties?.scanQualifiedCount || 0,
        isBackOrdered:
          product?.sku?.[0]?.properties?.inventoryStatus === 'BACKORDER' ||
          false,
      }
    }
    const isToShowViewCartButton = true
    cartContainer.addToCartFromViewCart([postData], isToShowViewCartButton)
  }
  getButtonDisableStatus(props) {
    //button will be dsiabled if the product is exclusive or not sellable or dangerous goods or other than in stock.
    const isInStock = props?.properties?.inventoryStatus === 'IN STOCK' || false
    const isSellable =
      props?.properties?.productStatus?.toLowerCase() === 'sellable' || false
    const isDangerousGoods = props?.properties?.dangerousGoods || false
    const isExclusiveItem = props?.properties?.isExclusive || false
    const productId = props?.identifier || ''
    if (isExclusiveItem) {
      const cartItems = cartContainer?.cartResponse?.items || []
      let maxProductQty
      const productQualificationResponse =
        customerContainer?.qualificationResponse?.qualification?.filter(p => {
          return p?.productId === productId
        })
      if (
        productQualificationResponse &&
        productQualificationResponse?.length > 0
      ) {
        if (cartItems && cartItems?.length > 0) {
          cartItems.forEach(item => {
            if (item?.skus?.[0]?.productId == productId) {
              maxProductQty =
                productQualificationResponse?.[0]?.quantity - item?.quantity
            }
          })
          if (maxProductQty <= 0 || 1 > maxProductQty) {
            return true
          }
        }
      }
    }
    return !isInStock || !isSellable || isDangerousGoods || isExclusiveItem
  }
  /**
   * Determines if the given product is disabled for adding to the cart.
   */
  isAddToCartDisabled(product) {
    let isToDisableButton = false
    if (product?.type === 'bundle' || product?.type === 'kit') {
      isToDisableButton = this.getButtonDisableStatus(product)
      return isToDisableButton
    } else {
      const selectedSKU = catalogDeps.getDefaultSelectedSku(product)
      const productSKU = selectedSKU?.[0] || product?.sku?.[0] || {}
      isToDisableButton = this.getButtonDisableStatus(productSKU)
      return isToDisableButton
    }
  }
}

const searchDeps = new SearchDeps()
export default searchDeps
export { searchDeps }
