import React, { Component } from 'react'
import { i18nTranslate } from 'src/utils'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import {
  enableStorePickup,
  enableOutfits,
  isMerchandising,
  APPConfig,
} from 'config/appConfig'
import {
  ApiContent,
  tryJSONParse,
  checkIsSaleStarted,
  trackEnhancedProductDetailImpression,
} from 'src/utils'
import { getLocaleCodeFromUrl } from 'src/utils/localeUtils'
import { productSchema } from 'src/models'
import {
  EmptyProduct,
  BreadCrumb,
  BundlePDP,
  BundleFloatingBar,
  V2BundleFloatingBar,
  CollectionProduct,
  StorePickup,
  storePickupState,
  toastState,
} from 'src/views/components'
import { OutFitsModel } from 'src/views/components/OutfitComponents/OutFitsModel'
import { RemoveModal } from 'src/views/components/OutfitComponents/RemoveModal'
import { catalogDeps, productDeps } from 'src/deps'
import { productState } from './ProductState'
import './styles.scss'
import MetaDecorator from '../MetaDecorator'

@observer
class ProductComponent extends Component {
  static contextType = ApiContent
  @observable quantity = 1
  backgroundRefreshInterval

  constructor(props, context) {
    super(props)
    this.context = context
    this.contextName = 'productData'
    this.curLocaleFromUrl = getLocaleCodeFromUrl({
      locationProps: props,
    })
    !isMerchandising && this.getDataFromSource()
  }

  getDataFromSource = () => {
    const productData = catalogDeps.getProductResponseFromContext({
      context: this.context,
      contextName: this.contextName,
      props: { curLocaleFromUrl: this.curLocaleFromUrl, ...this.props },
    })

    const skuId = this.props?.location?.queryParams?.skuId || ''
    const productIdFromURL = this.props?.match?.params?.productId || '110001'
    const productIdFromResponse = productData?.product?.[0]?.identifier
    const semanticId =
      productData?.product?.[0]?.semantics?.length > 0
        ? productData?.product?.[0]?.semantics?.find(
            semanticId => productIdFromURL == semanticId
          )
        : ''
    const slug = productData?.product?.[0]?.properties?.slug
    if (
      productData &&
      (productIdFromURL === productIdFromResponse ||
        productIdFromURL === semanticId ||
        productIdFromURL === slug)
    ) {
      productState.constructDataAndSetProductResponse(
        productData,
        skuId,
        this.props
      )
      /* for SSR*/
      trackEnhancedProductDetailImpression(productData)
    } else {
      productState.getProduct(this.props, {
        context: this.context,
        contextName: this.contextName,
      })
    }
  }

  // getMerchData = () => {
  //   if (IS_BROWSER) {
  //     window.addEventListener('message', event => {
  //       productState.handleMerchEvent(event)
  //     })
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps?.location) !==
      JSON.stringify(this.props?.location)
    ) {
      productState.getProduct(this.props)
    }
  }

  componentDidMount() {
    this.registerTimerForSale()
  }

  componentWillUnmount() {
    productState.resetValues()
    this.clearSaleTimer()
  }

  clearSaleTimer = () => {
    if (this.backgroundRefreshInterval) {
      clearInterval(this.backgroundRefreshInterval)
    }
  }

  registerTimerForSale = () => {
    let saleEventStartTime
    const eventProperties = checkIsSaleStarted()

    if (eventProperties?.isSaleRegistered) {
      // one second
      const intervalTime = 1000
      this.backgroundRefreshInterval = setInterval(() => {
        const eventProperties = checkIsSaleStarted()
        if (eventProperties?.isSaleStarted) {
          this.backgroundRefresh()
        } else if (
          eventProperties?.isSaleInProgress ||
          eventProperties?.isSaleEnded
        ) {
          this.clearSaleTimer()
        }
      }, intervalTime)
    }
  }

  backgroundRefresh = async () => {
    this.clearSaleTimer()
    await productState.getProduct(this.props)
    toastState.setToastMessage(
      i18nTranslate('pdp.saleStarted', 'Event has started', {
        nameSpace: 'ssr-resource',
      }),
      true
    )
    productState.handleQuantityChangePromotion(
      productState.product,
      productState.productCount
    )
  }

  handleAddToBag = () => {
    if (document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll')
    }
    productState.handleAddToBag()
  }

  handleSkuSelection = product => {
    // @##makenewpromo
    productState.updateSku(product)
    productState.handleQuantityChangePromotion(
      productState.product,
      productState.productCount
    )
  }

  selectSkuById = skuId => {
    productState.selectSkuById(skuId)
  }

  handleQuantityChange = count => {
    productState.productCount = count
    this.quantity = count
    this.props?.onSubscriptionQuantityChange &&
      this.props?.onSubscriptionQuantityChange(count)
  }

  renderCollection = () => {
    return <CollectionProduct product={productState.product?.subProducts} />
  }

  setPickUpType = type => {
    productState.setPickUpType(type)
  }
  renderBundlePriceAndButtons = (isExpressPDP = false) => {
    const product = productState.product || {}
    const inventory = productState.inventory || {}
    const inventoryStatus = productState.inventoryStatus || []
    const currency = product?.currency || ''
    const bundleType = product?.type || 'product'
    const transactionPrice = product?.transactionPrice
    const isBundleView = this.props?.isBundleView || false
    const handleBundleView = this.props?.handleBundleView || {}
    return (
      <V2BundleFloatingBar
        {...this.props}
        isExpressPDP={this.props.isExpressPDP || isExpressPDP}
        product={product}
        handleQuantityChange={this.handleQuantityChange}
        productCount={this.quantity}
        handleAddToBag={this.handleAddToBag}
        transactionPrice={transactionPrice}
        currency={currency}
        bundleType={bundleType}
        price={product?.transactionPrice?.bundlePrice || ''}
        isBundleView={isBundleView}
        handleBundleView={handleBundleView}
        inventory={inventory}
        inventoryStatus={inventoryStatus}
      />
    )
  }

  renderBundleData = (isExpressPDP = false) => {
    console.log('renderBundleData', this.props, isExpressPDP)
    const product = productState.product || {}
    const inventory = productState.inventory || {}
    const inventoryStatus = productState.inventoryStatus || []
    const skuLevelSubProductStatus = productState.skuLevelSubProductStatus || []
    const currency = product?.currency || ''
    const bundleType = product?.type || 'product'
    const transactionPrice = product?.transactionPrice
    const isBundleView = this.props?.isBundleView || false
    const handleBundleView = this.props?.handleBundleView || {}
    // productState.makePromotionCallsForBundleKits(this.props)
    return (
      <>
        <BundlePDP
          {...this.props}
          product={product}
          bundleType={bundleType}
          isBundleView={isBundleView}
          inventory={inventory}
        />
        <BundleFloatingBar
          {...this.props}
          product={product}
          handleQuantityChange={this.handleQuantityChange}
          productCount={this.quantity}
          handleAddToBag={this.handleAddToBag}
          transactionPrice={transactionPrice}
          currency={currency}
          bundleType={bundleType}
          price={product?.transactionPrice?.bundlePrice || ''}
          isBundleView={isBundleView}
          handleBundleView={handleBundleView}
          inventory={inventory}
          inventoryStatus={inventoryStatus}
          skuLevelSubProductStatus={skuLevelSubProductStatus}
        />
      </>
    )
  }

  // renderTitle = () => {
  //   // return <ProductTitle {...productState.product} />
  // }

  render() {
    const {
      product = {},
      productResponse = {},
      productCount = 1,
      inventory = {},
      subscribeAndSave = 0,
    } = productState
    const { status = '', category = {} } = productResponse
    const { name: productName, description: metaDescription } = product
    const breadCrumbList = productDeps.getBreadCrumbList(category, productName)
    const currency = productState.product?.currency || ''
    const productSchemaData = {
      ...productState.product,
      currency,
      location: this.props?.location,
    }
    const schemaData = productSchema(productSchemaData)
    const titlePDP = productName ? productName : 'Product Not Found'
    const slug = productResponse?.product?.properties?.slug
    const getSeoInformation = attributeDesc => {
      return tryJSONParse(attributeDesc, {}) || {}
    }
    const metaAssets =
      getSeoInformation(product?.properties?.seoInformation) || {}
    const metaTitlePDPInit =
      (metaAssets?.metaTitle ? metaAssets?.metaTitle : titlePDP) || ''
    const metaTitlePDP =
      metaTitlePDPInit
        ?.replace?.(' | Nu Skin', '')
        ?.replace?.('Nu Skin | ', '') + ' | Nu Skin'
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    return (
      <>
        <MetaDecorator
          title={metaTitlePDP}
          imageUrl=""
          imageAlt=""
          description={metaAssets?.metaDescription || ''}
          canonicalUrl={metaAssets?.canonicalURL || ''}
          location={this.props}
        />
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(schemaData)}
          </script>
          {enableNewPDPStyle && (
            <link
              href={
                'https://fonts.googleapis.com/css2?family=Lora:wght@400;700'
              }
              rel="stylesheet"
            />
          )}
          {enableNewPDPStyle && (
            <link
              href={
                'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800'
              }
              rel="stylesheet"
            />
          )}
        </Helmet>
        <BreadCrumb breadCrumbList={breadCrumbList} />
        <div
          id="pdp-content"
          className={`${this.template ? this.template : ''} ${
            enableNewPDPStyle ? 'v2-pdp-style' : ''
          }`}>
          {status &&
            (status === 'failure' ? (
              <EmptyProduct />
            ) : (
              <>
                {enableOutfits && (
                  <>
                    <RemoveModal />
                    <OutFitsModel />
                  </>
                )}
                {enableStorePickup && (
                  <StorePickup show={storePickupState?.showStorePickup} />
                )}
                {this.renderProductView(
                  productResponse,
                  product,
                  productCount,
                  inventory,
                  subscribeAndSave
                )}
              </>
            ))}
        </div>
      </>
    )
  }
}

export { ProductComponent }
