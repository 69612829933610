import { observable, action } from 'mobx'

class ModalState {
  @observable toastMessage = ''
  @observable isToShow = false
  @observable buttonText = ''
  @observable pageProps = ''
  @observable URLtoLoad = ''

  @action
  hideVisibility = () => {
    this.isToShow = false
    this.pageProps !== '' &&
      this.URLtoLoad !== '' &&
      this.pageProps?.history?.push(this.URLtoLoad)
  }

  @action
  setModalMessage = (message, buttonText, pageProps = {}, URLtoLoad = '') => {
    this.toastMessage = message
    this.isToShow = true
    this.buttonText = buttonText
    this.pageProps = pageProps
    this.URLtoLoad = URLtoLoad
  }
}

const modalState = new ModalState()

export { modalState }
