import { observable } from 'mobx'

import { i18nTranslate } from 'src/utils'
import { CommonContainer } from 'src/models/Common'
import { toastState } from 'src/views/components'

/**
 * TeamContainer class that extends CommonContainer.
 * This is likely the container component for rendering Team data.
 */
class TeamContainer extends CommonContainer {
  constructor(props) {
    super(props)
  }
  @observable teamsData = []
  @observable filteredTeamsData = []
  @observable teamsDataWithoutSize = []

  /**
   * Constructs a filter string for querying teams based on the provided filter parameters.
   *
   * Checks for cost center, status, and search term filter values and constructs a SQL
   * filter string accordingly. Returns an object with the filter string, or an empty object
   * if no filters are provided.
   */
  constructTeamFilter = (filterParams = {}) => {
    let filterString = ''
    const isSelectCostCenterAvailable =
      filterParams.selectCostCenter && filterParams.selectCostCenter !== 'All'
    if (isSelectCostCenterAvailable) {
      filterString += `costCentreId IN "${filterParams.selectCostCenter}"`
    }
    if (filterParams.status && filterParams.status !== 'All') {
      if (isSelectCostCenterAvailable === true) {
        filterString += ` AND status IN "${filterParams.status}"`
      } else {
        filterString += `status IN "${filterParams.status}"`
      }
    }
    if (filterParams.searchTerm) {
      if (isNaN(filterParams.searchTerm) === false) {
        filterString += `id IN "${filterParams.searchTerm}"`
      } else {
        filterString += `name LIKE "%*${filterParams.searchTerm}*%"`
      }
    }
    if (filterString) {
      return {
        filter: filterString,
      }
    }
    return {}
  }
  /**
   * Retrieves all teams without specifying page size.
   *
   * Gets the account ID, sets default page size to total team count,
   * constructs query params and endpoint params, then calls fetchResponse()
   * to get the teams data without limiting page size. Saves response to teamsDataWithoutSize
   */
  getAllTeamsWithoutSize = async () => {
    const accountId = await this.getAccountId()
    const size = this.teamsData?.pageableInfo?.totalCount || 10
    const queryParams = {
      size,
      page: 1,
    }
    const loadParams = {
      endPointName: 'getAllTeams',
      pathParams: `${accountId}/team`,
      queryParams,
    }
    this.teamsDataWithoutSize = await this.fetchResponse(loadParams)
  }
  /**
   * Retrieves teams based on filter parameters.
   *
   * Calls getAccountId() to get the account ID.
   * Sets default paging.
   * Calls constructTeamFilter() to build the filter string.
   * Builds the request parameters and endpoint config.
   * Calls fetchResponse() to make the API request.
   * Handles success and error response cases.
   * Saves response data to teamsData.
   * Returns API response.
   */
  getAllTeams = async filterParams => {
    try {
      const page = filterParams?.page || 1
      const accountId = await this.getAccountId()
      if (accountId !== '') {
        const filterProps = this.constructTeamFilter(filterParams)
        const params = {
          page: page,
          size: 5,
          ...filterProps,
        }
        const loadParams = {
          endPointName: 'getAllTeams',
          pathParams: `${accountId}/team`,
          queryParams: params,
        }
        const response = await this.fetchResponse(loadParams)
        if (this.isSuccessResponse(response)) {
          // is from init or normal get
          this.filteredTeamsData = response
          this.teamsData = response
        } else {
          this.filteredTeamsData = []
          this.teamsData = []
          // const message =
          //   response && response.message
          //     ? response.message
          //     : i18nTranslate('team.failure', 'Team get failed')
          toastState.setToastMessage(
            i18nTranslate('order.NoResults', 'No results found')
          )
        }
        return response
      }
    } catch (e) {
      console.log(e)
      return []
    }
  }
  /**
   * Creates a new team.
   *
   * Calls getAccountId() to get the account ID.
   * Builds request parameters with account ID, endpoint, and team data.
   * Calls fetchResponse() to make the API request.
   * Handles success and error cases, setting toast messages.
   * On success, calls getAllTeams() to refresh teams list.
   * Returns the API response.
   */
  createTeam = async teamData => {
    try {
      const accountId = await this.getAccountId()
      if (accountId !== '') {
        const loadParams = {
          endPointName: 'createTeam',
          pathParams: `${accountId}/team`,
          postData: teamData,
        }
        const response = await this.fetchResponse(loadParams)
        if (this.isSuccessResponse(response)) {
          toastState.setToastMessage(
            i18nTranslate(
              'team.createTeamSuccess',
              'Team created successfully'
            ),
            true
          )
          await this.getAllTeams()
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'team.createTeamFailure',
              'Unable to create team, please try again'
            ),
            false
          )
        }
        return response
      }
    } catch (e) {
      console.log(e)
      return []
    }
  }
  /**
   * Deletes a team by ID.
   *
   * Gets the account ID.
   * Builds request parameters with account ID, endpoint, and team ID.
   * Calls fetchResponse() to make the API request.
   * On success, sets a success toast message and refreshes teams list.
   * On failure, sets an error toast message.
   * Returns the API response.
   */
  deleteTeam = async teamId => {
    try {
      const accountId = await this.getAccountId()
      if (accountId !== '') {
        const loadParams = {
          endPointName: 'deleteTeam',
          pathParams: `${accountId}/team/${teamId}`,
        }
        const response = await this.fetchResponse(loadParams)
        if (this.isSuccessResponse(response)) {
          toastState.setToastMessage(
            i18nTranslate(
              'team.removeTeamSuccess',
              'Team removed successfully'
            ),
            true
          )
          await this.getAllTeams()
        } else {
          toastState.setToastMessage(
            i18nTranslate(
              'team.removeTeamFailure',
              'Unable to remove team, please try again'
            ),
            false
          )
        }
        return response
      }
    } catch (e) {
      console.log(e)
      return []
    }
  }

  /**
   * Updates a team with new data.
   *
   * Gets the account ID.
   * Builds request parameters with account ID, endpoint, team ID and edited data.
   * Calls fetchResponse() to make the API request.
   * On success, sets a success toast message and refreshes teams list.
   * On failure, sets an error toast message.
   * Returns the API response.
   */
  updateTeam = async teamData => {
    const accountId = await this.getAccountId()
    const teamId = teamData?.id
    const editedData = teamData?.editedData
    const loadParams = {
      endPointName: 'updateTeam',
      pathParams: `${accountId}/team/${teamId}`,
      postData: editedData,
    }
    const response = await this.fetchResponse(loadParams)
    if (this.isSuccessResponse(response)) {
      toastState.setToastMessage(
        i18nTranslate('team.updateTeamSuccess', 'Team updated successfully')
      )
      await this.getAllTeams()
    } else {
      toastState.setToastMessage(
        i18nTranslate(
          'team.updateTeamFailure',
          'Unable to update team, please try again'
        ),
        false
      )
    }
    return response
  }
}

const teamContainer = new TeamContainer()

export default teamContainer
export { TeamContainer, teamContainer }
