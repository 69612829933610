import React from 'react'
import { observer } from 'mobx-react'
import { getDeviceType, i18nTranslate } from 'src/utils'
import { Row, Container, Col } from 'react-bootstrap'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { AccordionBlockPDP } from 'src/views/components/AccordionBlockPDP'
import { getProductPDPTitle } from 'src/utils/commonUtils'
import Slider from 'react-slick'
import { APPConfig } from 'config/appConfig'
import { MarkdownComponent } from 'src/views/components/MarkdownComponent'
import './styles.scss'

@observer
class ProductProvenResults extends React.Component {
  nameSpace = 'ssr-resource'
  constructor(props) {
    super(props)
    this.slider = React.createRef(null)
    this.state = { isDragging: false }
  }
  awardData = [
    // {
    //   image: imagePlaceholder,
    //   name: 'Nu Skin Enterprises',
    //   info: "World's #1 Brand for Beauty Device Systems",
    //   subInfo: 'Euromonitor International Limited',
    // },
    // {
    //   image: imagePlaceholder,
    //   name: 'ageLOC® LumiSpa',
    //   info: '2021 Best Cleansing Device',
    //   subInfo: 'NewBeauty Awards',
    // },
    // {
    //   image: imagePlaceholder,
    //   name: 'ageLOC® Boost',
    //   info: '2021 Bronze Winner',
    //   subInfo: 'Edison Awards',
    // },
  ]

  getProductProvenVideo = (vedioUrl = '') => {
    if (vedioUrl != '') {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = vedioUrl.match(regExp)
      let videoId = match?.[2].length === 11 ? match?.[2] : null
      if (vedioUrl.match(regExp)) {
        return 'https://www.youtube.com/embed/' + videoId
      } else {
        return vedioUrl
      }
    }
  }
  renderTitle = () => {
    const { isAccordionType = false } = this.props
    const provenResultsLable = getProductPDPTitle(
      this.props.product?.tempProperties,
      'results'
    )
    return (
      <Row
        className={`about-title ${
          isAccordionType ? 'text-uppercase' : 'border-light-grey'
        }`}
        noGutters>
        {provenResultsLable ||
          i18nTranslate('pdp.provenResults', 'Proven Results', {
            nameSpace: this.nameSpace,
          })}
      </Row>
    )
  }
  getSliderSettings = () => {
    return {
      dots: true,
      appendDots: dots => this.getCustomDots(dots),
      infinite: false,
      speed: 200,
      useCSS: true,
      // cssEase: 'linear',
      swipeToSlide: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      // onInit: this.handleTabIndex,
      // afterChange: this.handleTabIndexAndGTMCarouselClick,
      beforeChange: () => {
        this.setState({ isDragging: true })
      },
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            // centerPadding: '40px',
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            // centerMode: true,
            // centerPadding: '55px',
            variableWidth: true,
          },
        },
      ],
      onSwipe: this.onSwipe.bind(this),
    }
  }
  onSwipe = () => {
    if (this.slider?.current?.innerSlider) {
      this.slider.current.innerSlider.clickable = true
    }
  }
  getCustomDots = dots => {
    const customDots = []
    this.awardData?.forEach((block, index) => {
      customDots.push(
        <li key={`awardDot_${index}`} {...dots?.[index]?.props}>
          <button
            aria-label={`award image ${index + 1} of ${this.awardData?.length}`}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })

    return <ul className="slick-dots">{customDots}</ul>
  }
  renderAwardData = (item, idx) => {
    return (
      <div key={'awards-item' + idx} className="awards-item">
        <img className="awards-item-img" src={item?.image} alt={item?.info} />
        <div className="award-detail">
          <div className="award-name">{item?.name}</div>
          <div className="award-info">{item?.info}</div>
          <div className="award-from">{item?.subInfo}</div>
        </div>
      </div>
    )
  }

  renderResultSummery = props => {
    const { resultsdatadet, Markdown } = props
    return (
      <div>
        {resultsdatadet?.summary &&
          resultsdatadet?.summary?.map((item, index) => {
            return (
              <Markdown
                components={{ strong: 'h6' }}
                className="result-summary">
                {item}
              </Markdown>
            )
          })}
      </div>
    )
  }

  render() {
    const product = this.props?.product || {}
    const resultsdata = product.results || '{}'
    let resultsdatadet
    let results
    try {
      resultsdatadet = JSON.parse(resultsdata)
      results = resultsdatadet?.results || []
    } catch (e) {
      console.log('Data parse error::: ProductProvenResults' + e)
    }
    const enableNewPDPStyle =
      APPConfig?.getAppConfig()?.enableNewPDPStyle === 'true'
    const settings = this.getSliderSettings()

    return (
      <>
        {(resultsdatadet?.image?.url ||
          resultsdatadet?.summary?.length > 0 ||
          resultsdatadet?.results?.length > 0) && (
          <div className="product-accordion-wrapper ">
            <AccordionBlockPDP
              title={this.renderTitle()}
              isDefaultOpen={false}
              className="accordion-description"
              dataTestId="qa-pdp-product-proven-results">
              <Container className="pdp-accordion-content-container">
                <div className="proven-result-accordion">
                  {resultsdatadet?.youTubeVideoId ? (
                    <div className="pdp-videos">
                      <object
                        aria-labelledby="pdp-videos-frame"
                        data={this.getProductProvenVideo(
                          `https://www.youtube.com/watch?v=${resultsdatadet?.youTubeVideoId}`
                        )}
                        width="300"
                        height="200"></object>
                    </div>
                  ) : (
                    <>
                      {resultsdatadet?.image?.url && (
                        <div className="proven-img-container">
                          <img
                            src={resultsdatadet?.image?.url || imagePlaceholder}
                            alt=""
                            aria-hidden="true"
                            className="proven-img"
                            onError={e => {
                              if (e.target.src != imagePlaceholder) {
                                e.target.onerror = null
                                e.target.src = imagePlaceholder
                              }
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {resultsdatadet?.summary &&
                    (enableNewPDPStyle ? (
                      <MarkdownComponent>
                        {({ default: Markdown }) =>
                          this.renderResultSummery({
                            resultsdatadet,
                            Markdown,
                          })
                        }
                      </MarkdownComponent>
                    ) : (
                      <p className="paragraph-m">{resultsdatadet?.summary} </p>
                    ))}

                  <div className="proven-result-inner">
                    {resultsdatadet?.results && (
                      <article>
                        {results?.map((list, value) => (
                          <div
                            className="d-flex proven-list align-items-center"
                            key={`list-${value}`}>
                            <h4 className="heading-5">{list?.percentage}</h4>
                            <p className="paragraph-m">{list?.text}</p>
                          </div>
                        ))}
                      </article>
                    )}
                  </div>
                  {resultsdatadet?.report &&
                    resultsdatadet?.report?.length > 0 && (
                      <div className="proven-result-report pt-2">
                        <a href={resultsdatadet?.report?.url}>
                          <h4 className="heading-5">
                            {resultsdatadet?.report?.text}
                          </h4>
                        </a>
                      </div>
                    )}
                  {/* awards section  */}
                  {enableNewPDPStyle && this.awardData?.length > 0 && (
                    <div className="awards-recognition-list">
                      {getDeviceType() === 'mobile' ? (
                        <Slider
                          {...settings}
                          dots={true}
                          className="award-slider"
                          ref={slider => (this.slider.current = slider)}>
                          {this.awardData?.map(this.renderAwardData)}
                        </Slider>
                      ) : (
                        this.awardData?.map(this.renderAwardData)
                      )}
                    </div>
                  )}
                </div>
              </Container>
            </AccordionBlockPDP>
          </div>
        )}
      </>
    )
  }
}

export { ProductProvenResults }
