import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Container, Col, Button } from 'react-bootstrap'
import { i18nTranslate } from 'src/utils'
import { productDeps } from 'src/deps'
import { cartContainer } from 'src/models'
import { ProductDetails } from './productDetails'
import './styles.scss'

@observer
class CollectionProduct extends React.Component {
  @observable selectedSku = {}
  @observable collectionResponse = []
  @observable isValidCollection = true

  componentDidMount() {
    this.collectionResponse = this.props?.product || []
  }
  handleSelectedSku = (value, type) => {
    this.selectedSku = {
      type: type,
      value: value,
    }
  }

  handleAddToCart = (index = '') => {
    const skuId =
      this.collectionResponse?.[index]?.selectedSku?.identifier || ''

    const productId = this.collectionResponse?.[index]?.identifier || ''
    const quantity = this.collectionResponse?.[index]?.quantity || 1
    if (skuId) {
      const params = {
        cartArray: [
          {
            skuId,
            productId,
            quantity,
          },
        ],
      }
      cartContainer.addToCart(params)
    }
  }
  handleAllAddToCart = () => {
    const params = {
      cartArray: [],
    }
    this.collectionResponse.forEach(response => {
      const skuId = response?.selectedSku?.identifier || ''
      const productId = response?.identifier || ''
      const quantity = response?.quantity || 1
      if (skuId && this.isValidCollection) {
        const data = {
          skuId,
          productId,
          quantity,
        }
        params.cartArray.push(data)
      } else {
        this.isValidCollection = false
      }
    })
    this.isValidCollection && cartContainer.addToCart(params)
    this.isValidCollection = true
  }
  handleSkuSelection = (selectedProp, index) => {
    this.collectionResponse[index].skuSelected[selectedProp.type] =
      selectedProp.value

    const selectedItem = productDeps.changeSku({
      product: this.collectionResponse[index],
      selectedProp,
      isCollection: true,
    })
    this.collectionResponse[index] = selectedItem
  }
  handleQuantityChange = (count, index) => {
    this.collectionResponse[index].quantity = count
  }
  renderProductDetails = () => {
    const data =
      this.collectionResponse &&
      this.collectionResponse.map((item, index) => {
        return (
          <ProductDetails
            key={`productContainer_${index}`}
            item={item}
            index={index}
            handleSkuSelection={this.handleSkuSelection}
            handleAddToCart={this.handleAddToCart}
            handleQuantityChange={this.handleQuantityChange}
          />
        )
      })

    return data
  }
  render() {
    const products = this.props?.product || []
    const subProductsCount = products.length
    const productDetails = this.renderProductDetails()
    return (
      <Container
        data-testid="qa-collection-container"
        className="mw-100 px-0 pt-2">
        <Row noGutters className="collection-items-wrapper">
          <Col
            className="col-10 d-flex align-items-center"
            data-testid="qa-collection-heading">
            <h4 className="m-0">
              {i18nTranslate(
                'collection.collectionitemtitle',
                'Items in this Collection',
                {
                  nameSpace: 'ssr-resource',
                }
              )}
            </h4>
          </Col>
          <Col
            className="col-2 justify-content-end d-flex align-items-center"
            data-testid="qa-count">
            <span className="pr-2">{subProductsCount}</span>
            <span>
              {i18nTranslate('collection.itemCountText', 'items', {
                nameSpace: 'ssr-resource',
              })}
            </span>
          </Col>
        </Row>
        {productDetails}
        <Row
          noGutters
          className="border-top border-secondary justify-content-end">
          <Col
            lg={4}
            md={4}
            sm={4}
            xs={12}
            className="mt-2 text-left text-md-right collection-addtocart">
            <Button
              className="w-100 add-all-to-cart"
              onClick={this.handleAllAddToCart}>
              {i18nTranslate('collection.addAllToCart', 'Add All to Cart', {
                nameSpace: 'ssr-resource',
              })}
            </Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

export { CollectionProduct }
export default CollectionProduct
