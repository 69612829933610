import { observable } from 'mobx'
import { i18nTranslate } from 'src/utils'
import { trackErrorInInstana } from 'src/utils/commonUtils'
import { CommonContainer } from 'src/models/Common'
import { toastState } from 'src/views/components'
import { listsContainer } from './Lists'

/**
 * SaveForLaterContainer class extends CommonContainer.
 * Manages the state and operations related to the user's Saved for Later list.
 */
class SaveForLaterContainer extends CommonContainer {
  saveForLaterListId = ''
  @observable
  saveForLaterListProducts = []

  /**
   * Fetches the save for later list from the listsContainer
   * using the saveForLaterListId and returns the response.
   */
  getList = async () => {
    const response = await listsContainer.getList({
      listId: this.saveForLaterListId,
    })
    return response
  }
  /**
   * Fetches the list items for the save for later list from the listsContainer
   * and updates the local saveForLaterListProducts with the response.
   */
  getAndUpdateList = async () => {
    const response = await listsContainer.getListItems({
      listId: this.saveForLaterListId,
    })
    if (this.isSuccessResponse(response)) {
      this.saveForLaterListProducts = response.listItems
    } else {
      this.saveForLaterListProducts = []
    }
  }

  /**
   * Deletes a product from the save for later list.
   * @param {string} listItemId - The ID of the list item to delete
   * @param {boolean} toShowToast - Whether to show a toast message about the result
   * @returns {Promise} The API response from deleting the list item
   */
  deleteProductFromList = async (listItemId, toShowToast = true) => {
    if (this.saveForLaterListId != '') {
      const response = await listsContainer.deleteItemFromList({
        listId: this.saveForLaterListId,
        listItemId,
      })
      if (this.isSuccessResponse(response)) {
        toShowToast &&
          toastState.setToastMessage(
            i18nTranslate(
              'saveForLater.removeProductSuccess',
              'Product successfully removed from “save for later”'
            ),
            true
          )
        await this.getAndUpdateList()
      } else {
        toShowToast &&
          toastState.setToastMessage(
            i18nTranslate(
              'saveForLater.removeProductFailure',
              'Sorry, this item cannot be removed from saved for later. Please try again.'
            ),
            false
          )
      }
      return response
    }
  }

  /**
   * Adds a product to the save for later list.
   * @param {Object} productData - The product data to add, containing skuId, name, productId, quantity, categoryId
   * @returns {Promise} The API response from adding the product to the list
   */
  addProductToList = async productData => {
    const { skuId, name, productId, quantity, categoryId } = productData
    const listData = {
      skuId,
      name,
      productId,
      quantity,
      categoryId,
      itemKey: skuId,
    }
    if (this.saveForLaterListId != '') {
      trackErrorInInstana({
        errorReport: 'Save For Later Payload',
        errorData: {
          listId: this.saveForLaterListId,
          listData,
        },
      })
      const response = await listsContainer.addItemToList({
        listId: this.saveForLaterListId,
        listData,
      })
      if (this.isSuccessResponse(response)) {
        await this.getAndUpdateList()
      }

      return response
    }
  }

  /**
   * Checks if a save for later list exists, and creates one if not.
   * Initializes the saveForLaterListId and saveForLaterListProducts properties.
   */
  checkAndCreateSaveForLater = async () => {
    const { availableLists } = listsContainer
    if (availableLists && availableLists.length > 0) {
      const found = availableLists.find(list => list.type === 'SAVEFORLATER')
      if (found != undefined) {
        this.saveForLaterListId = found.id
        await this.getAndUpdateList()
        return false
      }
    }

    this.saveForLaterListProducts = []
    this.saveForLaterListId = ''

    const response = await listsContainer.createList({
      name: 'MySaveForLater',
      type: 'SAVEFORLATER',
      itemType: 'SKU',
    })
    if (this.isSuccessResponse(response)) {
      this.saveForLaterListId = response.listId
    }
    return response
  }
}

const saveForLaterContainer = new SaveForLaterContainer()

export { SaveForLaterContainer, saveForLaterContainer }
export default saveForLaterContainer
