import React from 'react'
import { Container, Card } from 'react-bootstrap'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { currencyFormat, getImageFromProperty } from 'src/utils'
import { pageNames } from 'src/routes/pathParams'
import { IS_BROWSER } from 'config/appConfig'
import NuskinArrowRight from 'src/views/components/Icons/NuskinArrowRight'
import NuskinArrowLeft from 'src/views/components/Icons/NuskinArrowLeft'
import { ProductPromotion } from 'src/views/components'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { catalogDeps } from 'src/deps'
import { i18nTranslate } from 'src/utils'
import './styles.scss'

class ProductSimilar extends React.Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef(null)
    this.state = { isDragging: false }
  }

  componentDidMount() {
    const { product = {} } = this.props
    const recommendedProducts = product.crossSellProducts || []
    return recommendedProducts.length > 4
  }

  getPrice = (custompriceValue = '') => {
    const formattedPrice = currencyFormat({
      price: custompriceValue,
    })
    return formattedPrice
  }
  /* fix for ADA as child anchor tags are also included in tab navigation

  inside child elements */

  methodToReuseAdaFix = () => {
    if (IS_BROWSER) {
      const mailTitle = 'similar-products-section-id'
        ?.toLowerCase()
        ?.replace(/ /g, '-')
      document
        .querySelectorAll(`#${mailTitle} .slick-list a`)
        .forEach(anchorTag => anchorTag.setAttribute('tabindex', '-1'))
      document
        .querySelectorAll(`#${mailTitle} div[aria-hidden=false] a`)
        .forEach(anchorTag => anchorTag.removeAttribute('tabindex', '-1'))
    }
  }

  handleTabIndex = () => {
    this.methodToReuseAdaFix()
  }

  handleTabIndexAndGTMCarouselClick = () => {
    this.setState({ isDragging: false })
    this.methodToReuseAdaFix()
  }

  getCustomDots = dots => {
    const customDots = []
    const { product = {} } = this.props
    // const recommendedProducts = product.crossSellProducts || []
    dots?.forEach((block, index) => {
      customDots.push(
        <li key={`productDot_${index}`} {...dots?.[index]?.props}>
          <button
            aria-label={`products slide ${index + 1} of ${dots.length}`}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })

    return <ul className="slick-dots">{customDots}</ul>
  }

  onSwipe = () => {
    if (this.slider?.current?.innerSlider) {
      this.slider.current.innerSlider.clickable = true
    }
  }

  render() {
    const { product = {} } = this.props
    const recommendedProducts = product.crossSellProducts || []

    if (recommendedProducts.length <= 0) {
      return ''
    }

    const settings = {
      className: 'product-similar-slider',
      swipeToSlide: true,
      dots: true,
      arrows: true,
      infinite: true,
      autoplay: false,
      nextArrow: (
        <NuskinArrowLeft
          customIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.2071 11.2929C16.5976 11.6834 16.5976 12.3166 16.2071 12.7071L9.20711 19.7071C8.81658 20.0976 8.18342 20.0976 7.79289 19.7071C7.40237 19.3166 7.40237 18.6834 7.79289 18.2929L14.0858 12L7.79289 5.70711C7.40237 5.31658 7.40237 4.68342 7.79289 4.29289C8.18342 3.90237 8.81658 3.90237 9.20711 4.29289L16.2071 11.2929Z"
                fill="#252525"
              />
            </svg>
          }
        />
      ),
      prevArrow: (
        <NuskinArrowRight
          customIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.79289 12.7071C7.40237 12.3166 7.40237 11.6834 7.79289 11.2929L14.7929 4.29289C15.1834 3.90237 15.8166 3.90237 16.2071 4.29289C16.5976 4.68342 16.5976 5.31658 16.2071 5.70711L9.91421 12L16.2071 18.2929C16.5976 18.6834 16.5976 19.3166 16.2071 19.7071C15.8166 20.0976 15.1834 20.0976 14.7929 19.7071L7.79289 12.7071Z"
                fill="#252525"
              />
            </svg>
          }
        />
      ),
      slidesToShow:
        recommendedProducts?.length == 1
          ? 1
          : recommendedProducts?.length > 2
          ? this.props.isToEnableAWSRecommendation
            ? 3
            : 4
          : 2,
      // when there is only one product, then infinite loop is causing issue
      // hence setting slidesToShow based on length
      slidesToScroll: 1,
      variableWidth: true,
      useCSS: true,
      cssEase: 'linear',
      onInit: this.handleTabIndex,
      afterChange: this.handleTabIndexAndGTMCarouselClick,
      beforeChange: () => {
        this.setState({ isDragging: true })
      },
      onSwipe: this.onSwipe.bind(this),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    }
    const carouselTitle =
      this.props?.title ||
      i18nTranslate('pdp.youMayAlsoLike', 'You may also like')
    return (
      <div className="similar-products-wrapper similar-border">
        <div className="similar-heading">
          <Container className="similar-products-header-container">
            <h6 className="similar-heading-text">{carouselTitle}</h6>
          </Container>
        </div>
        <Container className="similar-products-container">
          <div
            className="similar-products-section"
            id="similar-products-section-id">
            <Slider
              {...settings}
              appendDots={dots => this.getCustomDots(dots)}
              ref={slider => (this.slider.current = slider)}>
              {recommendedProducts.length > 0 &&
                recommendedProducts.map((item, index) => {
                  const priceInfo = item?.sku?.[0]?.prices?.[0]?.price
                  const price = priceInfo ? this.getPrice(priceInfo) : ''

                  const defaultSku = catalogDeps.getDefaultSelectedSku(item)
                  const productLevelPromotion = item?.promotion || []
                  const skuLevelPromotion =
                    defaultSku && defaultSku.length
                      ? defaultSku[0]?.promotion || []
                      : []
                  const productAndSkuLevelPromotion = [
                    ...skuLevelPromotion,
                    ...productLevelPromotion,
                  ]
                  const slug = item?.properties?.slug || ''
                  const identifier = item?.identifier || ''
                  const hasMoreThanOneSku = (item?.sku?.length || 1) > 1
                  const productOrSlugId = slug ? slug : identifier
                  const skuId = defaultSku?.[0]?.identifier || ''
                  const skuSlug = defaultSku?.[0]?.properties?.slug || ''
                  const variantParameter = skuSlug || skuId || ''
                  /* @variant_autoselect */
                  const shouldAppendVariant =
                    hasMoreThanOneSku && variantParameter
                  let url = ''
                  if (shouldAppendVariant) {
                    url = `${pageNames.product}/${productOrSlugId}?variant=${variantParameter}`
                  } else {
                    url = `${pageNames.product}/${productOrSlugId}`
                  }
                  return (
                    <Card key={item?.identifier} className="similar-card">
                      <div className="similar-image-box">
                        {/* @variant_autoselect @todo */}
                        <Link
                          onClick={e =>
                            this.state.isDragging && e.preventDefault()
                          }
                          to={url}
                          className="similar-image-wrapper-link text-decoration-none">
                          <ProductPromotion
                            promotion={productAndSkuLevelPromotion}
                            isProductCard={true}
                            isToShowPromoOnImage={true}
                          />
                          <LazyLoadImage
                            className="similar-img"
                            src={
                              getImageFromProperty(item?.properties) ||
                              getImageFromProperty(
                                defaultSku?.[0]?.properties || ''
                              ) ||
                              imagePlaceholder
                            }
                            alt={item?.properties?.globalProductName}
                            aria-label={item?.properties?.globalProductName}
                            onError={e => {
                              if (e.target.src != imagePlaceholder) {
                                e.target.onerror = null
                                e.target.src = imagePlaceholder
                              }
                            }}
                          />
                        </Link>
                      </div>
                      <Card.Body className="similar-card-body">
                        <Link
                          onClick={e =>
                            this.state.isDragging && e.preventDefault()
                          }
                          to={url}
                          className="text-decoration-none">
                          <Card.Subtitle className="similar-products-name paragraph-m">
                            {item?.properties?.globalProductName}
                          </Card.Subtitle>
                        </Link>
                        <Card.Text>
                          <p className="similar-price heading-5">{price}</p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )
                })}
            </Slider>
          </div>
        </Container>
      </div>
    )
  }
}

export { ProductSimilar }
