import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { currencyFormat } from 'src/utils'
import './styles.scss'

class PriceButton extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      className = '',
      label,
      price = '$0.00',
      id = '',
      isSelected = false,
      dataTestId = '',
    } = this.props

    return (
      <Button
        className={`price-button ${className} ${
          isSelected ? 'price-selected' : ''
        }`}
        onClick={() => this.props.onPriceButtonClick?.(id)}>
        <div className="label-and-price-wrapper">
          <span
            className="button-label-wrapper"
            data-testid={`${dataTestId}-text`}>
            {label}
          </span>
          <span
            className="button-price-wrapper"
            data-testid={`${dataTestId}-value`}>
            {currencyFormat({
              price: price,
            })}
          </span>
        </div>
      </Button>
    )
  }
}

export { PriceButton }
export default PriceButton
