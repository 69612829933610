import { observable } from 'mobx'
import React, { Component } from 'react'
import { checkoutContainer, customerContainer } from 'src/models'
import { IS_BROWSER } from 'src/utils'
import './styles.scss'

class PayLaterMessage extends Component {
  @observable paypaltoken = ' '

  constructor(props) {
    super(props)
  }

  initPaylaterMount = async () => {
    if (!this.paypaltoken?.token) {
      this.paypaltoken = await checkoutContainer.getPayPalToken()
    }
    const onPaypalLoadSDK = (paypalCheckoutErr, paypalCheckoutInstance) => {
      /**
       * @description
       * Stop if there was a problem creating PayPal Checkout.
       * This could happen if there was a network error or if it's incorrectly configured.
       */
      if (paypalCheckoutErr) {
        console.error('Error creating PayPal Checkout:', paypalCheckoutErr)
        return
      }

      paypalCheckoutInstance?.loadPayPalSDK(
        {
          components: 'buttons,messages',
          'enable-funding': 'paylater',
          // Other config options here
        },
        function () {
          const button = paypal?.Buttons({
            fundingSource: paypal?.FUNDING.PAYLATER,
          })
          //button.render('#pay-later-button')
        }
      )
    }

    const createPaypalCheckout = clientInstance => {
      // Create a PayPal Checkout component.
      braintree?.paypalCheckout?.create(
        {
          client: clientInstance,
        },
        function (paypalCheckoutErr, paypalCheckoutInstance) {
          onPaypalLoadSDK(paypalCheckoutErr, paypalCheckoutInstance)
        }
      )
    }

    const createPayPalCheckoutComponent = (clientErr, clientInstance) => {
      /**
       * @description
       * Stop if there was a problem creating the client.
       * This could happen if there is a network error or if the authorization
       * is invalid.
       */
      if (clientErr) {
        console.error('Error creating client:', clientErr)
        return
      }
      createPaypalCheckout(clientInstance)
    }

    // after braintree is also ready
    // after dom is ready
    if (typeof braintree !== 'undefined' && braintree?.client) {
      /**
       * @note
       * where we creating braintree instance
       *
       */

      braintree?.client?.create(
        {
          authorization: this.paypaltoken?.token,
        },
        function (clientErr, clientInstance) {
          createPayPalCheckoutComponent(clientErr, clientInstance)
        }
      )
    } else {
      console.log('paylater no braintree ')
    }
  }

  checkAndInitPaylater = () => {
    const customerResponse =
      customerContainer?.profileResponse?.customer ||
      customerContainer?.profileResponse
    if (IS_BROWSER) {
      if (
        customerResponse?.id &&
        typeof braintree !== 'undefined' &&
        braintree?.client
      ) {
        this.initPaylaterMount()
      } else {
        // used timer since we don't have direct connection between customer and product state
        setTimeout(() => {
          this.checkAndInitPaylater()
        }, 300)
      }
    }
  }
  componentDidMount() {
    this.checkAndInitPaylater()
  }

  componentDidUpdate = prevProps => {
    try {
      if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
        console.log('paylater Update !=', prevProps, this.props)
      } else {
        console.log('paylater Update ==', prevProps, this.props)
      }
    } catch (e) {
      console.log('paylater parse error', e)
    }
  }
  render() {
    return (
      <div
        data-pp-message
        data-pp-placement="product"
        data-pp-layout="text"
        data-pp-text-color="black"
        data-pp-logo-type="inline"
        data-pp-amount={this.props?.amount || ''}
        className={'paylater-wrapper'}
        id={this.props.id || 'paylater_id'}
        data-testid="qa-pdp-paypal-message"></div>
    )
  }
}
export { PayLaterMessage }
