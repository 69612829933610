import React from 'react'
import { withRouter } from 'react-router'
import { observer } from 'mobx-react'
import { Col, Container, Row } from 'react-bootstrap'
import {
  getCookie,
  useStickyTop,
  QUALIFICATION_URL,
  APPConfig,
  getAppConfig,
} from 'config/appConfig'

import {
  customerContainer,
  accountsContainer,
  footerContainer,
  contentStackContainer,
} from 'src/models'
import { pageNames } from 'src/routes/pathParams'
import {
  getLocalStorage,
  getLocaleCodeFromUrl,
  ApiContent,
  IS_BROWSER,
  i18nTranslate,
} from 'src/utils'
import { CommonHeader } from '../CommonHeader'
import TopNotice from './TopNotice'
import SearchSection from './SearchSection'
import MegaMenuSection from './MegaMenuSection'
import ScrollingInfoSection from './ScrollingInfoSection'
import LocaleSection from './LocaleSection'
import leadership from 'src/images/Leadership.png'
import Terms from 'src/views/pages/StudioPage/Terms'
import { getConsent } from '../deps'
import { observable } from 'mobx'
import ContentStack from 'src/api/contentStack'
import { SkipLink } from '../Components'
import { KlarnaMessage, KlarnaScript } from 'src/views/components/KlarnaMessage'
import { checkIsNativeApp } from 'src/utils/reactNativeAppUtils'
import { isHomePage } from '../../KlarnaMessage/KlarnaMessage'
import { getResourceFrom3rdPartyUrl } from 'src/api/localeResources'
import { HeaderMarketing } from './HeaderMarketing'
import { PhoneVerificationModal } from 'src/views/components/PhoneVerificationModal/PhoneVerificationModal'
import './styles.scss'

@observer
class NuskinHeader extends CommonHeader {
  nameSpace = 'ssr-resource'
  @observable data = []
  @observable topNotice = {}
  @observable scrollingInfo = []
  @observable headerLogoUrl = ''

  @observable isToShowPhoneVerification = true

  static contextType = ApiContent
  constructor(props, context) {
    super(props, context)
    this.currentLocale = getLocaleCodeFromUrl({
      locationProps: props,
      at: 'pathParam',
      defaultLocale: 'en_US',
      isReverseType: true,
    })
    this.context = context
    this.contextName = 'marketingData'
    this.getDataFromSource()
  }

  getMarketingData = async () => {
    let localeValue =
      this.currentLocale?.split('_')?.reverse()?.join('-') || 'US-en'
    let uid = 'bltcb3502baadc6a2d9'
    if (IS_BROWSER) {
      ContentStack.getQualificationReference(
        'header_prototype',
        uid,
        localeValue
      ).then(response => {
        if (Object.keys(response).length > 0) {
          contentStackContainer.setMarketingData(response)
          this.topNotice = response?.emergency_statement || {}
          this.scrollingInfo =
            response?.bottom_bar_marketing_statements_carousel || []
          this.headerLogoUrl =
            response?.logo?.url[0]?.derivatives?.original || ''
          footerContainer.nuskinLogoURL = this.headerLogoUrl
        }
      })
    } else {
      this.context.load(
        ContentStack.getQualificationReference(
          'header_prototype',
          uid,
          localeValue
        ).then(response => {
          return Promise.resolve({ [this.contextName]: response })
        })
      )
    }
  }

  getDataFromSource = async () => {
    const marketingData = this.context?.data?.marketingData || {}
    if (Object.keys(marketingData).length > 0) {
      contentStackContainer.setMarketingData(marketingData)
      this.topNotice = marketingData?.emergency_statement || {}
      this.scrollingInfo =
        marketingData?.bottom_bar_marketing_statements_carousel || []
      this.headerLogoUrl =
        marketingData?.logo?.url[0]?.derivatives?.original || ''
      footerContainer.nuskinLogoURL = this.headerLogoUrl
    } else {
      await this.getMarketingData()
    }
  }

  componentDidMount() {
    super.componentDidMount()
  }

  componentDidUpdate() {
    super.componentDidUpdate()
    if (useStickyTop) {
      this.calculateHeaderSpace()
    }
    const tempUser = accountsContainer?.tempUser || ''
    const check = getLocalStorage('check')
    const accountType = customerContainer?.profileResponse?.accounts?.type || ''
    if (
      this.showTempPopup() &&
      tempUser &&
      check &&
      (accountType === 'Brand Affiliate' ||
        accountType === 'Brand Affiliate - Business Entity')
    ) {
      this.isToShowPhoneVerification = false
    }
  }

  showPhoneVerificationPopup = () => {
    this.isToShowPhoneVerification = true
  }
  // headerLinks = async () => {
  //   // let cookieValue = getLocalStorage('locale')
  //   // let finalcookieValue = cookieValue.replace('-', '_')
  //   return `https://www.nuskin.com/apps/hello/services/marketheader.${this.currentLocale}.json/content/nuskin/${this.currentLocale}/home.html.json`
  // }

  // We are not using S3 header json
  // getHeaderData = async () => {
  //   const json =
  //     (await getResourceFrom3rdPartyUrl({
  //       locale: (this.currentLocale || 'en_US').replace('_', '-'),
  //       domainName:
  //         getAppConfig().localeResourceFilesDomain || 'dev.nuksin.com',
  //       fileName: 'header',
  //     })) || {}

  //   this.data = json
  // }

  getqualification = async () => {
    const role = getCookie('x-role-user') || false
    if (role) {
      await customerContainer.qualificationUser()
    }
  }

  getQualificationUrl = () => {
    return QUALIFICATION_URL + this.currentLocale
  }

  // backgroundSignIn = () => {
  //   if (IS_BROWSER) {
  //     const topLocation = top.location.href
  //     const selfLocation = self.location.href
  //     if (
  //       customerContainer.isToCheckBackgroundSignIn &&
  //       topLocation == selfLocation
  //     ) {
  //       let locale = getLocaleCodeFromUrl()
  //       locale = locale?.toLowerCase()?.split('_')
  //       const iframeUrl = `https://${document.domain}/${locale?.[1]}/${locale?.[0]}${pageNames.loginCallback}?isBackgroundSignIn=true`
  //       return <iframe className="background-signin" src={iframeUrl}></iframe>
  //     }
  //   }
  //   return ''
  // }

  showCookieStatus = () => {
    if (IS_BROWSER && !navigator.cookieEnabled) {
      return (
        <div className="no-cookie">
          {i18nTranslate(
            'cookie.disabled',
            'This website requires cookie to work properly. To continue, please enable cookie in your browser.'(
              { nameSpace: this.nameSpace }
            )
          )}
        </div>
      )
    }
    return ''
  }

  showTempPopup = () => {
    let isToShowTempPopup = false
    const selectedLocaleCode = getLocaleCodeFromUrl()?.split('_')?.[1]
    const eqMarketLocales = APPConfig?.getAppConfig()?.eqMarketLocales || ['US']
    let userCountry = ''
    customerContainer?.profileResponse?.accounts?.properties?.forEach(
      element => {
        if (element.attributeId === 'countryCode') {
          userCountry = element.value
        }
      }
    )

    if (selectedLocaleCode === 'undefined') {
      isToShowTempPopup = false
    } else if (selectedLocaleCode != userCountry) {
      isToShowTempPopup = false
    } else {
      isToShowTempPopup = eqMarketLocales?.includes(userCountry?.toLowerCase())
    }
    return isToShowTempPopup
  }

  render() {
    // let cookieValue = getLocalStorage('locale') || ''
    // let finalcookieValue = cookieValue.replace('-', '_')
    const tempUser = accountsContainer?.tempUser || ''
    const check = getLocalStorage('check')
    const phoneVerifyFromSession = getLocalStorage('phoneVerifyFromSession')
    const accountType = customerContainer?.profileResponse?.accounts?.type || ''

    const isRegionPage =
      this.props?.location?.pathname === pageNames.SelectRegion
    const isKlarnaCheckoutPage =
      this.props?.location?.pathname === pageNames.klarnaCheckout
    const searchParams = this.props.location?.search || ''
    let isNativeAppQueryParamString = new URLSearchParams(searchParams)?.get(
      'isNativeApp'
    )
    const isURLNativeApp = isNativeAppQueryParamString == 'true'
    const isNativeApp = checkIsNativeApp() || false
    // Header should not be rendered for Stella/Vera APP.
    const isToHideHeader =
      isRegionPage || isURLNativeApp || isNativeApp || isKlarnaCheckoutPage
    if (isToHideHeader) {
      return <></>
    }
    const enableKlarnaHomeMessage =
      getAppConfig()?.enableHeaderOption == 'klarna'
    const enableMarketingMessage =
      getAppConfig()?.enableHeaderOption == 'marketing'
    const handleSkipLink = () => {
      if (IS_BROWSER) {
        window.scrollTo(0, 0)
      }
    }

    return (
      <header
        className={`nu-header-container h-100`}
        ref={element => (this.header = element)}>
        <noscript>
          <div className="no-script">
            {i18nTranslate(
              'script.disabled',
              'This website requires JavaScript to work properly. To continue, please enable JavaScript in your browser.',
              { nameSpace: this.nameSpace }
            )}
          </div>
        </noscript>
        {this.showCookieStatus()}
        <KlarnaScript />
        <Row noGutters className="w-100">
          {this.renderAdminTopBar()}
        </Row>
        <div className="bg-white">
          <SkipLink
            className="skip-link text-primary bg-white"
            skipTo="#id-header-bottom-container">
            <a href="#">
              {i18nTranslate('skipNav', 'Skip to navigation', {
                nameSpace: this.nameSpace,
              })}
            </a>
          </SkipLink>
          <SkipLink
            handleClick={handleSkipLink}
            className="skip-link text-primary bg-white"
            skipTo="#id-app-wrapper">
            <a href="#">
              {i18nTranslate('skipMain', 'Skip to main content', {
                nameSpace: this.nameSpace,
              })}
            </a>
          </SkipLink>
          <SkipLink
            className="skip-link text-primary bg-white"
            skipTo="#id-footer-container">
            <a href="#">
              {i18nTranslate('skipFooter', 'Skip to footer', {
                nameSpace: this.nameSpace,
              })}
            </a>
          </SkipLink>
        </div>
        {this.topNotice?.title && <TopNotice topNotice={this.topNotice} />}
        {/* klarna message */}
        {enableKlarnaHomeMessage && (
          <div
            className={`${
              isHomePage(this.props) && enableKlarnaHomeMessage
                ? 'home-klarna-top-strip-height'
                : 'home-klarna-top-strip'
            }`}>
            <KlarnaMessage
              id="klarna-home-top-strip"
              data-key="top-strip-promotion-auto-size"
              isFromHomePage={true}
              {...this.props}></KlarnaMessage>
          </div>
        )}
        {enableMarketingMessage && isHomePage(this.props) && (
          <HeaderMarketing {...this.props} />
        )}
        <LocaleSection {...this.props} />
        <SearchSection {...this.props} headerLogoUrl={this.headerLogoUrl} />
        <MegaMenuSection
          {...this.props}
          // data={this.data}
          categoryResponse={this.categoryResponse}
        />
        {this.scrollingInfo?.length > 0 && (
          <ScrollingInfoSection scrollingInfo={this.scrollingInfo} />
        )}
        <Container fluid>
          <Row>
            <Col className="p-0">
              {customerContainer?.qualificationResponse
                ?.isQualificationEligible === true &&
                customerContainer?.qualificationStoreResponse === true && (
                  <div className="header-preview-text">
                    <p className="paragraph-s">
                      <span>
                        <img src={leadership} className="pr-1" alt="" />
                      </span>
                      {customerContainer?.qualificationData
                        ?.early_access_message || ''}

                      <a
                        href={this.getQualificationUrl()}
                        className="paragraph-s-underline pl-1"
                        //CX121-4490 - Open link in same tab
                        // target="_blank"
                        rel="noreferrer">
                        {customerContainer?.qualificationData
                          ?.early_access_link || ''}
                      </a>
                    </p>
                  </div>
                )}
            </Col>
          </Row>
        </Container>
        <div className="home-page">
          {this.showTempPopup() &&
            tempUser &&
            check &&
            (accountType === 'Brand Affiliate' ||
              accountType === 'Brand Affiliate - Business Entity') && (
              <Terms
                getConsent={getConsent}
                showPhoneVerificationPopup={this.showPhoneVerificationPopup}
              />
            )}
          {this.showTempPopup() &&
            this.isToShowPhoneVerification &&
            customerContainer.fromSessionVerifyPhonePopup &&
            phoneVerifyFromSession && (
              <PhoneVerificationModal
                fromSessionCheck={true}
                showModal={customerContainer.fromSessionVerifyPhonePopup}
              />
            )}
        </div>
      </header>
    )
  }
}

export default withRouter(NuskinHeader)
