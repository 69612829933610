import React from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  i18nTranslate,
  isExpressCheckout,
  trackCheckoutCouponCodeEvents,
} from 'src/utils'
import { checkoutContainer, cartContainer, customerContainer } from 'src/models'
import { IS_BROWSER, getLocalStorage, trackEnhancedCheckout } from 'src/utils'
import { CommonForm, toastState } from 'src/views/components'
import { dateFormatDeps } from 'src/deps'
import { formInput } from './formInput'
import {
  IoIosClose as CloseIcon,
  IoIosArrowDown as DownwardArrowIcon,
  IoIosArrowUp as UpwardArrowIcon,
} from 'react-icons/io'
import { schema } from './validationSchema'
import './styles.scss'

@observer
class PromoWalletCheckout extends React.Component {
  @observable showMore = true
  showMoreDefaultLength = 2
  @observable promoList = []
  @observable isCallInProgress = false

  async componentDidMount() {
    await cartContainer.promoCodeList()
    checkoutContainer.isToDisableSubmitOrderButton = true
    await this.handlePaymentOnPromoAddOrRemove({ action: 'init' })
  }
  handleApplyCouponCode = async formData => {
    this.isCallInProgress = true
    let promoCode = formData?.promoCode?.trim?.() || ''
    const response = await checkoutContainer.addPromo(promoCode)
    trackCheckoutCouponCodeEvents({
      coupon: promoCode,
    })
    if (checkoutContainer.isSuccessResponse(response)) {
      formData.promoCode = ''
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.SuccessPromocode',
            'Promo code applied successfully'
          ),
        true
      )

      await this.handlePaymentOnPromoAddOrRemove({ action: 'add' })
      // TODO EX: need to check if this is required
      if (isExpressCheckout()) {
        await cartContainer.viewCart()
      }
      this.isCallInProgress = false
    } else {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.failurePromocode',
            'unable to add the promo code'
          ),
        false
      )
      this.isCallInProgress = false
    }
    await cartContainer.promoCodeList()
  }

  handleDeleteCouponCode = async promoCode => {
    const response = await checkoutContainer.deletePromo(promoCode)
    if (checkoutContainer.isSuccessResponse(response)) {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.deletePromocode',
            'Promo code removed successfully'
          ),
        true
      )
      await this.handlePaymentOnPromoAddOrRemove({ action: 'remove' })
    } else {
      toastState.setToastMessage(
        response?.message ||
          i18nTranslate(
            'response.failureDeletePromo',
            'unable remove the promo code'
          ),
        false
      )
    }
    await cartContainer.promoCodeList()
  }

  renderSubmitButton = () => {
    return (
      <Button
        data-testid="qa-pay-promo-apply"
        className="rounded-0 promo-apply-button promo-code w-100 text-capitalize mt-sm-0 btn-bg-blue"
        variant="primary"
        disabled={this.disablePromotion()}
        type="submit">
        {i18nTranslate('couponCode.submit', 'apply')}
      </Button>
    )
  }

  renderPromos = promotionDetails => {
    return (
      <Button
        className="promo-code mr-1 mb-1"
        variant="outline-dark"
        data-testid="qa-promo-remove"
        onClick={() => this.handleDeleteCouponCode(promotionDetails.code)}>
        <span> {promotionDetails.code}</span>
        <CloseIcon size="2rem" className="promo-close-icon" />
      </Button>
    )
  }

  handlePaymentOnPromoAddOrRemove = async (options = {}) => {
    const { action = '' } = options
    const {
      isStoreCredit = false,
      onContinue = function () {},
      updateFlowPaths = function () {},
    } = this.props

    const { payments = [], value = {} } =
      (await cartContainer?.cartResponse) || {}
    let grandTotal = value?.grandTotal || 0

    let activePath = checkoutContainer.activePath

    let paymentDeleted = false
    if (Array.isArray(payments) && payments.length > 0) {
      await checkoutContainer.deletePayment()
      paymentDeleted = true
    }
    let isCartHavingSubscriptionProduct = cartContainer.hasSubscriptionProduct()

    let paymentOption = isStoreCredit ? 'storecredit' : 'payment'
    if (action === 'add' || action === 'remove') {
      if (grandTotal > 0) {
        updateFlowPaths()
        if (
          activePath === 'submitOrder' ||
          activePath === 6 ||
          (activePath === 'subscription' && isCartHavingSubscriptionProduct) ||
          (activePath === '' && paymentDeleted)
        ) {
          checkoutContainer.activePath = paymentOption
        }
      } else if (grandTotal === 0) {
        updateFlowPaths()
        if (
          isCartHavingSubscriptionProduct &&
          (activePath === 'payment' || activePath === 'subscription')
        ) {
          checkoutContainer.activePath = 'payment'
        } else if (activePath === 'payment' || activePath === 'storecredit') {
          checkoutContainer.activePath = 'shippingMethods'
        } else if (
          activePath === 'submitOrder' ||
          activePath === 6 ||
          (activePath === '' && paymentDeleted)
        ) {
          checkoutContainer.activePath = 'shippingMethods'
          onContinue()
        }
      }
    }
  }

  disablePromotion = () => {
    const { math = {} } = this.props
    let grandTotal = math['grandTotal'] || 0
    if (this.isCallInProgress) {
      return true
    }
    if (grandTotal > 0) {
      return false
    } else {
      return true
    }
  }

  handleShowMore = () => {
    if (!this.showMore) {
      const section = document.querySelector('.promo-accordion')
      section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    this.showMore = !this.showMore
  }
  renderPromoCodeList = () => {
    const appliedPromoCodeList =
      cartContainer?.cartResponse?.promotionDetails?.codes || ''
    const promoCodeList = cartContainer?.promoCodesList || []
    const formInputJson = formInput()
    const promoListMaxLength = this.showMore
      ? this.showMoreDefaultLength
      : promoCodeList.length

    return (
      <div className="promowallet-code-wrapper">
        <div
          data-testid="promocode-form-wrapper"
          className="promowallet-form-wrapper">
          <CommonForm
            schema={schema}
            formTypeJson={formInputJson}
            SubmitButtonProps={this.renderSubmitButton}
            onSubmit={this.handleApplyCouponCode}
            hasInlineSubmitButton={true}
          />
          {appliedPromoCodeList && (
            <div
              className="promowallet-code-list"
              data-testid="qa-applied-promocode">
              {appliedPromoCodeList.map(this.renderPromos)}
            </div>
          )}
        </div>
        {promoCodeList?.length > 0 && (
          <div className="promo-code-list-wrapper">
            <div className="my-promo-title" tabIndex={0}>
              {i18nTranslate('promocode.listHeading', 'My Promos')}
            </div>
            {promoCodeList?.length > 0 &&
              promoCodeList
                ?.slice(0, promoListMaxLength)
                ?.map((promoCodeData, index) => {
                  const isApplied =
                    appliedPromoCodeList != '' &&
                    appliedPromoCodeList?.find(
                      promo => promo.code === promoCodeData.code
                    )

                  let {
                    date = '',
                    month = '',
                    year = '',
                  } = dateFormatDeps.convertTimeStampToDate(
                    promoCodeData.expiryDate,
                    true
                  )
                  return (
                    <div className="promo-code-list-container">
                      <div tabIndex={0}>
                        <div
                          className="promo-code-name"
                          data-testid="qa-promo-code-name">
                          {promoCodeData.code}
                        </div>
                        <div
                          className="promo-code-exp-date"
                          data-testid="qa-promo-code-exp-date">
                          {i18nTranslate('promoCode.expire', 'Expire')}:{' '}
                          {month + '/' + date + '/' + year}
                        </div>
                      </div>
                      <div>
                        <Button
                          data-testid="qa-pay-promocode-apply"
                          className={`promo-apply-btn text-capitalize ${
                            isApplied?.code ? 'promo-apply-btn-disabled' : ''
                          }`}
                          variant="primary"
                          disabled={isApplied?.code || this.disablePromotion()}
                          onClick={() =>
                            this.handleApplyCouponCode({
                              promoCode: promoCodeData.code,
                            })
                          }>
                          {isApplied?.code
                            ? i18nTranslate('promocode.Applied', 'Applied')
                            : i18nTranslate('promocode.Apply', 'Apply')}
                        </Button>
                      </div>
                    </div>
                  )
                })}
            {promoCodeList?.length > this.showMoreDefaultLength && (
              <div className="show-more-container">
                {' '}
                <Button
                  data-testid="qa-pay-promo-showmore"
                  className={`show-more-btn`}
                  variant="primary"
                  onClick={this.handleShowMore}>
                  {' '}
                  {this.showMore
                    ? i18nTranslate('promocode.seeMore', 'See more')
                    : i18nTranslate('promocode.seeLess', 'See less')}{' '}
                  {this.showMore ? (
                    <DownwardArrowIcon aria-hidden={true} />
                  ) : (
                    <UpwardArrowIcon aria-hidden={true} />
                  )}{' '}
                </Button>{' '}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  render() {
    return <>{this.renderPromoCodeList()}</>
  }
}

export { PromoWalletCheckout }
export default PromoWalletCheckout
