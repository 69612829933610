import { observable } from 'mobx'
import { outFitsContainer } from 'src/models'

class OutfitModalState {
  @observable isModalVisible = false

  toggleModal = () => {
    this.isModalVisible = !this.isModalVisible
    if (this.isModalVisible === false) {
      outFitsContainer.removeNewOutfit()
    }
  }

  setModalVisibility = visibility => {
    this.isModalVisible = visibility
  }
}

const outfitModalState = new OutfitModalState()

export { outfitModalState }
