import React from 'react'
import { Link } from 'react-router-dom'
import { i18nTranslate } from 'src/utils'

class ProductTitleAndSkuId extends React.Component {
  render() {
    const { name = '', skuId = '', productLink = '' } = this.props || {}
    return (
      <div className="mb-2">
        <div className="product-title">
          <Link to={productLink}>{name}</Link>
        </div>
        <div className="sku-id">
          {i18nTranslate('cart.itemId', 'SKU')}
          {': '} {skuId}
        </div>
      </div>
    )
  }
}

export default ProductTitleAndSkuId
export { ProductTitleAndSkuId }
