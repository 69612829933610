import React from 'react'
import { observable, reaction } from 'mobx'
import isFunction from 'lodash/isFunction'
import { observer } from 'mobx-react'
import { i18nTranslate, isExpressCheckout } from 'src/utils'
import { Row, Col } from 'react-bootstrap'
import { application, isB2BUser } from 'src/utils'
import { addressContainer, accountsAddressContainer } from 'src/models'
import { countriesWithoutPostalCodes } from 'src/static/geography'
//import parsePhoneNumber from 'libphonenumber-js'
import './styles.scss'

@observer
class AddressDetail2 extends React.Component {
  @observable toggleRemoveConfirmation = false
  @observable data = {}
  disposer = () => {}

  ADDRESS_DETAILS = 'ADDRESS_DETAILS'
  DEFAULT_VIEW = 'DEFAULT_VIEW'
  CONFIRMATION_VIEW = 'CONFIRMATION_VIEW'

  toggleRemoveAddress = () => {
    this.toggleRemoveConfirmation = !this.toggleRemoveConfirmation
  }

  handleRemove = async () => {
    if (isB2BUser() /*|| isB2BAdmin()*/) {
      await accountsAddressContainer.deleteAccountAddress(
        this.props.addressInfo
      )
    } else {
      await addressContainer.deleteProfileAddress(this.props.addressInfo)
    }
    this.toggleRemoveAddress()
  }

  async getDataModal(dataKey) {
    switch (dataKey) {
      case this.ADDRESS_DETAILS:
        const addressInfo = this.props?.addressInfo || {}

        // start to convert phone number to international format
        let phoneNumberCode = ''
        try {
          if (addressInfo?.phone !== '') {
            const { parsePhoneNumber } = await import('libphonenumber-js')
            phoneNumberCode =
              parsePhoneNumber(addressInfo?.phone)?.formatInternational() || ''
          }
        } catch (e) {
          console.log('Phone number Parse exception ', e)
        }

        // end to convert phone number to international format

        return {
          addressInfo,
          isDefault: this.props?.addressInfo.isDefault || false,
          phoneNumberCode,
          defaultAddressText: i18nTranslate(
            'addressBook.defaultTitle',
            'Default Address'
          ),
        }
      case this.DEFAULT_VIEW:
        return {
          removeButtonText: i18nTranslate('address.removeButton', 'Remove'),
        }
      case this.CONFIRMATION_VIEW:
        return {
          removeAddressText: `${i18nTranslate(
            'address.remove',
            'Remove Address'
          )}?`,
          yesText: i18nTranslate('address.yesLabel', 'Yes'),
          noText: i18nTranslate('address.noLabel', 'No'),
        }

      default:
        return {}
    }
  }

  getStyleGuides(dataKey) {
    switch (dataKey) {
      case this.DEFAULT_VIEW:
      case this.CONFIRMATION_VIEW:
        return {
          size: application.isMobile ? 'md' : 'lg',
        }

      default:
        return {}
    }
  }
  async setDataResponse() {
    const addressData = await this.getDataModal(this.ADDRESS_DETAILS)
    let promise = new Promise((resolve, reject) => {
      if (addressData?.addressInfo) {
        resolve(addressData)
      } else {
        reject(Error('Promise rejected'))
      }
    })

    promise.then(
      result => {
        this.data = result
      },
      function (error) {
        this.data = error
      }
    )
  }
  async componentDidMount() {
    await this.setDataResponse()
    /**
     * @info reactions is called when props.addressInfo is changed and
     * to update the address when the address data is updated.
     */
    this.disposer = reaction(
      () => this.props.addressInfo,
      async () => {
        await this.setDataResponse()
      }
    )
  }
  componentWillUnmount() {
    //reactions disposed here
    if (isFunction(this.disposer)) {
      this.disposer()
    }
  }
  render() {
    //const data = this.getDataModal(this.ADDRESS_DETAILS)
    return (
      <React.Fragment>
        {this.data?.addressInfo && (
          <Row
            noGutters
            className="flex-row text-capitalize"
            style={{ width: '100%' }}>
            <Col className="d-flex flex-column p-0 w-100">
              <span className="address-list p-0 paragraph-s">
                <span className="fname-bold-shiping">
                  {this.data?.addressInfo?.firstName === null
                    ? ''
                    : this.data?.addressInfo?.firstName}
                </span>{' '}
                <span className="fname-bold-shiping">
                  {this.data?.addressInfo?.lastName === null
                    ? ''
                    : this.data?.addressInfo?.lastName}
                </span>
              </span>
              <span className="d-flex flex-column express-address">
                <span className="fname-normal-shiping" data-testid="qa-street">
                  {this.data?.addressInfo?.addressLine1 || ''}
                </span>
                <span className="fname-normal-shiping" data-testid="qa-street">
                  {this.data?.addressInfo?.addressLine2 === ''
                    ? this.data?.addressInfo?.addressLine2 || ''
                    : `${this.data?.addressInfo?.addressLine2}, ` || ''}
                  {this.data?.addressInfo?.city || ''},{' '}
                  {this.data?.addressInfo?.state || ''},{' '}
                  {!countriesWithoutPostalCodes.includes(
                    this.data?.addressInfo?.country || ''
                  ) && (
                    <span className="fname-normal-shiping" data-testid="qa-zip">
                      {this.data?.addressInfo?.zip || ''},{' '}
                    </span>
                  )}
                </span>
                <span className="fname-normal-shiping" data-testid="qa-country">
                  {this.data?.addressInfo?.country || ''}
                </span>
                <span
                  className="fname-normal-shiping text-lowercase"
                  data-testid="qa-country">
                  {this.data?.addressInfo?.email || ''}
                </span>
                {this.data?.addressInfo?.phone && (
                  <span
                    className="fname-normal-shiping"
                    data-testid="qa-country">
                    {this.data?.phoneNumberCode}
                  </span>
                )}
              </span>
            </Col>
            <Row noGutters className="w-100">
              {this.data?.isDefault === true && (
                <span className="default-address-title-shipping pt-2 p-0 col">
                  {this.data?.defaultAddressText}
                </span>
              )}
            </Row>
          </Row>
        )}
      </React.Fragment>
    )
  }
}
export { AddressDetail2 }
export default AddressDetail2
