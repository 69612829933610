import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { APPConfig } from 'config/appConfig'
import { Icons } from 'src/views/components'
import debounce from 'lodash/debounce'
import { FaChevronDown as DownArrow } from 'react-icons/fa'
import { subscriptionType as subscriptionFrequency } from './fixture'
import './styles.scss'

class FrequencyDropdownV2 extends React.Component {
  constructor(props) {
    super(props)
    const isToEnableOneTimeFrequency =
      APPConfig?.getAppConfig()?.oneTimeFrequencyEnabled === 'true'
    let {
      frequencyPeriod = 1,
      frequencyType = 'Every month',
      orderType = 'VARIABLE_MULTI_ORDER',
      isFromPDP = false,
      isToHideMonthlyFrequency = false,
    } = this.props
    const frequencyValue = `${frequencyPeriod}-${frequencyType}`
    let subscriptionType = subscriptionFrequency(
      isFromPDP,
      isToHideMonthlyFrequency
    )
    let value = subscriptionType[0].value
    let label = subscriptionType[0].label
    let isOneTime = false
    if (!isToEnableOneTimeFrequency) {
      subscriptionType = subscriptionType?.filter(item => {
        return !(item?.isOneTime === true)
      })
      value = subscriptionType[0].value
      label = subscriptionType[0].label
    }
    const subscriptionVal = subscriptionType.filter(
      item => frequencyValue == item.value
    )

    if (subscriptionVal.length) {
      if (subscriptionVal.length === 1) {
        value = subscriptionVal[0].value
        label = subscriptionVal[0].label
      } else {
        if (orderType === 'VARIABLE_SINGLE_ORDER') {
          value = subscriptionVal[1].value
          label = subscriptionVal[1].label
          isOneTime = subscriptionVal?.[1]?.isOneTime || false
        } else {
          value = subscriptionVal[0].value
          label = subscriptionVal[0].label
        }
      }
    }
    this.state = {
      selectedValue: value,
      selectedLabel: label,
      isSelectedOneTime: isOneTime,
      subscriptionType,
    }
  }
  // using the callback function from props to set and reset values
  componentDidMount = () => {
    const { handleFrequencyDidMount } = this.props
    typeof handleFrequencyDidMount === 'function' && handleFrequencyDidMount()
  }

  componentWillUnMount = () => {
    const { handleFrequencyUnmount } = this.props
    typeof handleFrequencyUnmount === 'function' && handleFrequencyUnmount()
  }
  setDropDown = async (
    value = '1-MONTHS',
    label = 'Monthly',
    isOneTime = false,
    isMonthly = false,
    event
  ) => {
    const { handleSubscriptionFrequency = () => {}, isValidateReturn = false } =
      this.props
    if (isValidateReturn) {
      if (handleSubscriptionFrequency(value, isOneTime)) {
        this.setState({ selectedValue: value, selectedLabel: label })
      }
    } else {
      this.setState({ selectedValue: value, selectedLabel: label })
      typeof handleSubscriptionFrequency === 'function' &&
        handleSubscriptionFrequency(value, isOneTime)
    }
  }
  render() {
    const { title, disableTabIndex = 0 } = this.props
    const items = this.state.subscriptionType.map(item => {
      const isSelectedClass =
        item.value === this.props.selectedValue ? 'selected' : ''
      return (
        <Dropdown.Item
          as="button"
          key={item.label}
          value={item.isOneTime === true ? 'MONTHLY' : item.value}
          className={isSelectedClass}
          disabled={
            this.props?.isFromModal &&
            item?.isOneTime === true &&
            itemType === 'VARIABLE_SINGLE_ORDER'
          }
          // tabIndex={0}
          onClick={debounce(event => {
            this.setDropDown(
              item.value,
              item.label,
              item?.isOneTime,
              item?.isMonthly,
              event
            )
          }, 700)}
          onKeyPress={debounce(event => {
            this.setDropDown(
              item.value,
              item.label,
              item?.isOneTime,
              item?.isMonthly,
              event
            )
          }, 700)}
          data-testid="qa-subscription-label">
          {item.label}
        </Dropdown.Item>
      )
    })
    return (
      <Dropdown
        className="subscription-dropdown-nuskin"
        data-testid="frequency-dropdown">
        <Dropdown.Toggle
          className="d-flex subscription-dropdown-toggle"
          tabIndex={disableTabIndex}>
          <div className="dropdown-left-section">
            <span className="dropdown-nuskin-title">{title}</span>
            <span
              className="dropdown-nuskin-value mt-1"
              data-testid="frequency-value">
              {this.state.selectedLabel}
            </span>
          </div>
          <div className="dropdown-right-section">
            <DownArrow name="arrows" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="subscription-dropdownmenu z-1">
          {items}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}
export default FrequencyDropdownV2
export { FrequencyDropdownV2 }
